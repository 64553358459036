import { ActivatedRoute } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { ModalsService } from '@core/services/modals/modals.service';
import { ContentStoreDispatcher } from '@common/dispatchers/content.dispatcher';
import { ProductAddModalComponent } from '@shared/modals/product-add-modal/product-add-modal.component';

@Component({
  template: ``,
  selector: 'app-product-add-page',
})
export class ProductAddPageComponent implements OnInit, OnDestroy {
  @Input() allowAddToCart = true;

  constructor(
    private readonly location: LocationStrategy,
    private readonly modalsService: ModalsService,
    private readonly activatedRoute: ActivatedRoute,
    private contentDispatcher: ContentStoreDispatcher,
  ) {}

  ngOnInit(): void {
    this.contentDispatcher.updateProduct({
      shopIsNotActive: false,
      shopId: this.activatedRoute.snapshot.params.shopId,
      productId: this.activatedRoute.snapshot.params.productId,
    });

    ProductAddModalComponent.allowAddToCart = this.allowAddToCart;
    ProductAddModalComponent.onClose = (): void => {
      this.location.back();
      ProductAddModalComponent.onClose = undefined;
    };
    this.modalsService.open(ProductAddModalComponent.handle);
  }

  ngOnDestroy(): void {
    this.modalsService.close(ProductAddModalComponent.handle);
  }
}
