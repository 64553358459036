<app-modal-filler-atom [index]="1000"></app-modal-filler-atom>
<div
  class="fixed mx-auto right-0 left-0 shadow overflow-y-auto bottom-0 max-h-85-screen max-w-screen-sm lg:flex lg:justify-center lg:items-center lg:w-full lg:h-full lg:top-0 lg:left-0'"
  [style.z-index]="1051"
>
  <div class="p-5 md:p-7 bg-white rounded-t-3xl lg:rounded-3xl">
    <div class="mb-6">
      <h1
        class="font-accent font-bold text-2xl mx-auto leading-normal mb-2"
        translate="no-connection-modal.heading"
      ></h1>
      <p class="text-neutral-800 font-base text-base pb-4">
        {{ 'no-connection-modal.description' | translate }}
      </p>
      <div
        class="flex flex-row align-middle pb-4"
        *ngIf="retryInProgress$ | async"
      >
        <img
          src="assets/images/spinner.svg"
          alt=""
          class="mr-2"
        />
        <p class="text-neutral-800 font-base text-base">
          {{ 'no-connection-modal.retry-in-progress' | translate }}
        </p>
      </div>
    </div>

    <div class="flex flex-col gap-10">
      <app-button-atom
        size="M"
        [label]="'no-connection-modal.reload-page-button' | translate"
        type="light"
        [full]="true"
        (click)="reload()"
      ></app-button-atom>
    </div>
  </div>
</div>
