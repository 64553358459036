import { createFeature, createSelector } from '@ngrx/store';

import { SETTINGS_STORE_KEY } from './settings.state';
import { SETTINGS_STORE_REDUCER } from './settings.reducer';

const SETTINGS_STORE_FEATURE = createFeature({
  name: SETTINGS_STORE_KEY,
  reducer: SETTINGS_STORE_REDUCER,
});

export const {
  selectApp: SELECT_SETTINGS_APP,
  selectKiosk: SELECT_SETTINGS_KIOSK,
  selectDevice: SELECT_SETTINGS_DEVICE,
  selectParameters: SELECT_SETTINGS_PARAMETERS,
  selectSettingsState: SELECT_SETTINGS_STATE,
} = SETTINGS_STORE_FEATURE;

// App
export const SELECT_SETTINGS_APP_LOCALE = createSelector(SELECT_SETTINGS_APP, app => app.locale);

// Device
export const SELECT_SETTINGS_DEVICE_SCREEN = createSelector(
  SELECT_SETTINGS_DEVICE,
  device => device.screen,
);
