<div class="p-5 md:p-7 bg-white rounded-t-3xl lg:rounded-3xl">
  <div class="flex justify-between items-center pb-6">
    <h2 class="font-accent font-bold text-3xs lg:text-32px">
      {{ 'tip-modal.title' | translate }}
    </h2>
  </div>
  <div class="text-neutral-800 leading-5 pb-6">
    <app-input-custom-number
      [suffix]="true"
      [symbol]="currency"
      [error]="error"
      [isAutoFocused]="true"
      (valueEmitter)="onTip($event)"
    ></app-input-custom-number>
    <p
      *ngIf="error"
      class="py-2 text-xs font-base font-normal text-error-700"
    >
      {{ 'tip-modal.error-amount' | translate }}
    </p>
  </div>
  <div class="pt-1 flex">
    <div class="mr-2 xm:pr-1 w-full xm:w-1/2">
      <app-button-atom
        size="M"
        [label]="'tip-modal.cancel' | translate"
        color="primary"
        (click)="closeTip.emit()"
        type="light"
        [full]="true"
      ></app-button-atom>
    </div>
    <div class="xm:pt-0 xm:pl-1 w-full xm:w-1/2">
      <app-button-atom
        size="M"
        [label]="'tip-modal.add' | translate"
        (click)="applyTip.emit(tip)"
        type="primary"
        [full]="true"
      ></app-button-atom>
    </div>
  </div>
</div>
