import { Component, Input } from '@angular/core';

import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-hamburger-menu-atom',
  templateUrl: './hamburger-menu-atom.component.html',
  styleUrls: ['./hamburger-menu-atom.component.scss'],
})
export class HamburgerMenuAtomComponent {
  @Input() light = false;
  @Input() size: 'S' | 'M' = 'M';
  @Input() color?: IContentBuilderFieldColor;
}
