import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { PlaceResult } from '@core/models/google-maps.model';
// TODO: Move this ts file inside app folder
import { googleMapsSilverTheme } from '@styles/googleMapsSilverTheme';

import { GoogleMapsApiHelper } from '@common/helpers/google-maps-api.helper';
import { selectedMarkerMarkerConfig } from '@shared/blocks/shops-map-block/config/shops-map-block.config';

@Component({
  selector: 'app-map-location-block',
  templateUrl: './map-location-block.component.html',
})
export class MapLocationBlockComponent implements OnInit, OnDestroy {
  private _destroyRef = new Subject<boolean>();

  @Input() name!: string;
  @Input() address!: PlaceResult;

  isMapLoaded = false;

  mapZoom = 13;
  mapMinZoom = this.mapZoom;
  mapMaxZoom = this.mapZoom;
  mapStyle = googleMapsSilverTheme;
  mapOptions: google.maps.MapOptions = {
    zoom: this.mapZoom,
    styles: this.mapStyle,
    disableDefaultUI: true,
    maxZoom: this.mapMaxZoom,
    minZoom: this.mapMinZoom,
    center: this.address?.geometry?.location,
  };

  mapOptionCenter = this.address?.geometry?.location as unknown as
    | google.maps.LatLngLiteral
    | google.maps.LatLng;

  markerIcon = selectedMarkerMarkerConfig as google.maps.Icon | google.maps.Symbol;
  markerOptions: google.maps.MarkerOptions = { draggable: false };

  constructor(private googleMapsHelper: GoogleMapsApiHelper) {}

  ngOnInit(): void {
    this.googleMapsHelper.initialize();
    this.googleMapsHelper.onLoaded
      .pipe(
        filter(x => x),
        takeUntil(this._destroyRef),
      )
      .subscribe(isLoaded => {
        if (!this.isMapLoaded && isLoaded) {
          this.mapOptions = {
            ...this.mapOptions,
            center: this.address?.geometry?.location,
          };
          this.mapOptionCenter = this.address?.geometry?.location as unknown as
            | google.maps.LatLngLiteral
            | google.maps.LatLng;
          this.markerOptions = {
            ...this.markerOptions,
            label: {
              ...Object(this.markerOptions.label ?? {}),
              text: this.name,
            },
          };
          this.isMapLoaded = isLoaded;
        }
      });
  }

  ngOnDestroy(): void {
    this._destroyRef.next(true);
    this._destroyRef.complete();
  }
}
