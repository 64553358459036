import { DateUtils } from '../date/date.utils';
import { Dayjs } from 'dayjs';

export class DaySlotsUtils {
  static generateDaySlots = ({
    interval,
    maxDays,
    minDays,
  }: {
    interval: number;
    maxDays: number;
    minDays?: number;
  }): Date[] => {
    let tmpHourFix = 0;
    if (
      DateUtils.dayjsInMarketplaceTimezone().daysInMonth() === 31 &&
      DateUtils.dayjsInMarketplaceTimezone().month() === 2
    ) {
      tmpHourFix = 1;
    }

    const startDate = DateUtils.dayjsInMarketplaceTimezone()
      .add(minDays ? minDays : 0, 'days')
      .set('hour', tmpHourFix)
      .set('minute', 0)
      .set('second', 0);
    const endDate = DateUtils.dayjsInMarketplaceTimezone()
      .add(maxDays, 'days')
      .set('hour', 23)
      .set('minute', 59)
      .set('second', 59);

    const step = (date: Dayjs): any => date.add(interval, 'days');

    let cursor = startDate;
    const blocks: Date[] = [];
    while (cursor.isBefore(endDate)) {
      blocks.push(new Date(DateUtils.dayjsInMarketplaceTimezone(cursor).format()));
      cursor = step(cursor);
    }
    return blocks;
  };
}
