import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LegalsFooterLinkItemBlockComponent } from '../../blocks/footer/legals-footer/legals-footer-link-block/legals-footer-link-item-block.component';

@Component({
  selector: 'app-default-dood-legal-footer',
  templateUrl: './default-dood-legal-footer.component.html',
})
export class DefaultDoodLegalFooterComponent {
  links: unknown[];

  constructor(private translate: TranslateService) {
    this.links = [
      {
        componentClass: LegalsFooterLinkItemBlockComponent,
        data: {
          name: this.translate.instant('legal-links.terms-of-sale.label'),
          link: this.translate.instant('legal-links.terms-of-sale.link'),
        },
      },
      {
        componentClass: LegalsFooterLinkItemBlockComponent,
        data: {
          name: this.translate.instant('legal-links.terms-of-use.label'),
          link: this.translate.instant('legal-links.terms-of-use.link'),
        },
      },
      {
        componentClass: LegalsFooterLinkItemBlockComponent,
        data: {
          name: this.translate.instant('legal-links.privacy-policy.label'),
          link: this.translate.instant('legal-links.privacy-policy.link'),
        },
      },
      {
        componentClass: LegalsFooterLinkItemBlockComponent,
        data: {
          name: this.translate.instant('legal-links.legal-notice.label'),
          link: this.translate.instant('legal-links.legal-notice.link'),
        },
      },
    ];
  }
}
