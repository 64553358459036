<div
  *ngIf="image"
  [style.backgroundColor]="backgroundColor | contentBuilderColor"
  class="w-full"
>
  <div class="container mx-auto max-w-640 pb-5">
    <img
      *ngIf="property === 'percent'"
      [src]="image.url"
      [style.width.%]="width"
      class="mx-auto max-w-full"
    />
    <img
      *ngIf="property === 'pixel'"
      [src]="image.url"
      [style.width.px]="width"
      class="mx-auto max-w-full"
    />
  </div>
</div>
