<a
  class="m-3 text-md"
  href="{{ linkUrl }}"
  target="_blank"
>
  <i
    class="icon icon-{{ icon }}"
    [style.color]="textColor | contentBuilderColor"
  ></i>
</a>
