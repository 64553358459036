import { AbstractControl, ValidatorFn } from '@angular/forms';
import { PatternUtil } from '../../utils/pattern/pattern.utils';

export function emailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: unknown } | null => {
    const email = control.value;
    if (!email) {
      return null;
    }

    const emailRegexp = PatternUtil.email;
    if (!emailRegexp.test(email)) {
      return { format: true };
    }
    return null;
  };
}
