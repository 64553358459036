import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ISimpleItem } from '@core/models/simple-item.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-custom-switch-atom',
  templateUrl: './custom-switch-atom.component.html',
  styleUrls: ['./custom-switch-atom.component.scss'],
})
export class CustomSwitchAtomComponent implements OnInit, OnChanges {
  @Input() items!: ISimpleItem[];
  @Input() selectedValue!: string;
  @Input() type!: string;
  @Output() switchItem = new EventEmitter();

  selected = 0;

  constructor(translate: TranslateService) {}

  selectItem(index: number): void {
    this.selected = index;
    this.switchItem.emit(this.items[index]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedValue) {
      this.updateSelectedValue();
    }
  }

  ngOnInit(): void {
    this.updateSelectedValue();
  }

  private updateSelectedValue(): void {
    if (this.selectedValue) {
      this.selected = this.items.findIndex(item => item.value === this.selectedValue);
    }
  }
}
