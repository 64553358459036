import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationHistoryService } from '@core/services/navigation-history/navigation-history.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-shops-custom-map-page',
  templateUrl: './shops-custom-map-page.component.html',
})
export class ShopsCustomMapPageComponent implements OnInit, OnDestroy {
  @Input() body: any;
  @Input() metaDescription?: string;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly navigationHistoryService: NavigationHistoryService,
    private readonly meta: Meta,
  ) {}

  ngOnInit(): void {
    this.navigationHistoryService.pushStateFromActivatedRouteSnapshot(
      'map',
      this.activatedRoute.snapshot,
    );
    const bodyElement = document.body;
    bodyElement.classList.add('block-vertical-scroll');

    if (this.metaDescription) {
      this.meta.updateTag({
        name: 'description',
        content: this.metaDescription,
      });
    }
  }

  ngOnDestroy(): void {
    const bodyElement = document.body;
    bodyElement.classList.remove('block-vertical-scroll');
  }
}
