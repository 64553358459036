import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DOOD_API } from '@config/ws.config';
import { IOrder } from '@core/models/order.model';
import { DoodProductModel } from '@core/models/product.model';
import { DoodUserModel } from '@store/authentication/authentication.model';
import { ComoAttachUserResponse, ComoSignOn, ComoUser } from '@core/models/como.model';

import { DoodOrderModel } from '@store/order/order.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ComoApiService {
  constructor(private readonly httpClient: HttpClient) {}

  public getComoUser(
    order: Partial<IOrder> & { user: string; marketplace: string },
  ): Observable<ComoUser> {
    return this.httpClient.post<ComoUser>(`${DOOD_API}/plugins/como/get-user-info`, {
      order,
    });
  }

  sendPaymentVerificationCode$(cart: any): Observable<DoodOrderModel> {
    return this.httpClient.post<DoodOrderModel>(
      `${DOOD_API}/plugins/como/payment-verification-code`,
      cart,
    );
  }

  createComoUser(
    marketplaceId: string,
    phoneOrEmail: string,
    legalAccepted = false,
    dateOfBirth: string | null = null,
    marketingAccepted = false,
  ): Observable<DoodUserModel> {
    let body: any = {
      phone_or_email: phoneOrEmail,
      marketing_accepted: marketingAccepted,
    };

    if (legalAccepted) {
      body.legal_accepted = legalAccepted;
    }

    if (dateOfBirth) {
      body.date_of_birth = dateOfBirth;
    }

    return this.httpClient.post<DoodUserModel>(
      `${DOOD_API}/marketplaces/${marketplaceId}/como/user`,
      body,
    );
  }

  attachUserWithEmail$(marketplaceId: string): Observable<boolean> {
    const url = `${DOOD_API}/marketplaces/${marketplaceId}/como/attach-user-with-email`;

    return this.httpClient.post<{ found: boolean }>(url, {}).pipe(map(response => response.found));
  }

  linkComoUser$(
    marketplaceId: string,
    phoneOrEmail?: string,
    identification_code?: string,
  ): Observable<ComoAttachUserResponse> {
    let body: any = {
      phone_or_email: phoneOrEmail,
    };

    if (identification_code) {
      body.identification_code = identification_code;
    }

    return this.httpClient.post<ComoAttachUserResponse>(
      `${DOOD_API}/marketplaces/${marketplaceId}/como/attach-user`,
      body,
    );
  }

  public getComoUserFromTemporaryToken$(
    temporaryToken: string,
    marketplace: string,
    legalAccepted = false,
    email?: string,
  ): Observable<ComoSignOn> {
    return this.httpClient.post<ComoSignOn>(
      `${DOOD_API}/plugins/como/sign-on-with-temporary-token`,
      {
        temporary_token: temporaryToken,
        marketplace,
        legal_accepted: legalAccepted,
        email,
      },
    );
  }

  allowedProducts$(
    marketplaceId: string,
    externalIds: string[],
    shopId?: string,
  ): Observable<DoodProductModel[]> {
    const params = new URLSearchParams();
    externalIds.forEach(externalId => params.append('externalIds[]', externalId));
    if (shopId) {
      params.append('shopId', shopId);
    }

    const queryParamsString = params.toString();
    return this.httpClient.get<DoodProductModel[]>(
      `${DOOD_API}/marketplaces/${marketplaceId}/como/allowed-products?${queryParamsString}`,
    );
  }
}
