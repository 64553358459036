<div class="mb-8xs">
  <app-resume-order-atom
    [title]="'#' + order[orderKeys.Number]"
    [shopName]="order.shop?.name ?? ''"
    [description]="order.shop?.name ?? ''"
    [orderAmount]="order.final_price"
    [orderDate]="order.ready_at"
    [orderStatus]="satisfyTS(order.status)"
    [nbProducts]="order.products.length"
    (cardClick)="goToOrderDetails()"
  ></app-resume-order-atom>
</div>
