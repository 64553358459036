import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

import { OnSiteLocationStoreSelector } from '@app/common/selectors/on-site-location.selector';
import { DoodOnSiteLocationModel } from '@core/models/on-site-location.model';

@Injectable({ providedIn: 'root' })
export class OnSiteLocationStoreRefiner {
  constructor(private _selector: OnSiteLocationStoreSelector) {}

  selectByShopId(id?: string): Observable<DoodOnSiteLocationModel[]> {
    if (!id) return of([]);
    return this._selector.selectLocations.pipe(
      map(_locations => _locations.filter(_location => _location.shop_id === id)),
    );
  }
}
