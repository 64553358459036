import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { OrderErrorState, ORDER_STORE_KEY } from './order.state';
import { DoodOrderModel, DoodValidateOrderQuery } from './order.model';

import { DoodApiError } from '@shared/interfaces/error.interface';
import { createActiveStoreActions } from '@store/_abstract/active';
import { createHydratedStoreActions } from '@store/_abstract/hydrated';

export const ORDER_STORE_API_ACTIONS = createActionGroup({
  source: 'Dood/API/Order',
  events: {
    'Fetch Orders': props<{ userId: string; marketplaceId: string }>(),
    'Fetch Orders Success': props<{ orders: DoodOrderModel[] }>(),
    'Fetch Orders Failure': props<{ error: DoodApiError }>(),
    'Fetch Orders Complete': emptyProps(),
  },
});

export const ORDER_STORE_LOCAL_ACTIONS = createActionGroup({
  source: 'Dood/LOCAL/Order',
  events: {
    // Validation
    'Reset Validation': emptyProps(),
    'Update Validation': props<{ validation: DoodValidateOrderQuery | null }>(),
    'Update Is Validating': props<{ isValidating: boolean }>(),

    // Status
    'Update Is Loaded': props<{ isLoaded: boolean }>(),
    'Update Is Loading': props<{ isLoading: boolean }>(),

    // Error
    'Reset Errors': emptyProps(),
    'Update Errors': props<{ errors: Partial<OrderErrorState> }>(),

    // Misc
    'reset': emptyProps(),
  },
});

export const ORDER_STORE_COMPONENT_ACTIONS = createActionGroup({
  source: 'Dood/CUSTOM/Order',
  events: {
    'Update Validation Data': props<{
      errors: DoodApiError[];
      isValidating?: boolean;
      validation?: DoodValidateOrderQuery | null;
    }>(),
  },
});

export const ORDER_STORE_ACTIVE_ACTIONS = createActiveStoreActions<DoodOrderModel>(ORDER_STORE_KEY);

export const ORDER_STORE_HYDRATION_ACTIONS = createHydratedStoreActions(ORDER_STORE_KEY);
