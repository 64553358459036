import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-push-modal-button-atom',
  templateUrl: './push-modal-button-atom.component.html',
})
export class PushModalButtonAtomComponent {
  @Input() title!: string;
  @Input() text!: string;
  @Input() imgUrl!: string;
  @Input() isSafari!: boolean;

  @Output() yesButtonClick = new EventEmitter<Event>();
  @Output() noButtonClick = new EventEmitter<Event>();
}
