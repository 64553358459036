import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Component } from '@angular/core';

import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { ModalsService } from '@core/services/modals/modals.service';
import { ModalStoreSelector } from '@common/selectors/modal.selector';

@Component({
  selector: 'app-stripe-terminal-reader-picker-modal',
  templateUrl: './stripe-terminal-reader-picker-modal.component.html',
})
export class StripeTerminalReaderPickerModalComponent extends DestroyerBase {
  static handle = 'stripe-terminal-reader-picker-modal';

  index$: Observable<number> = this.modalSelector
    .selectModal(StripeTerminalReaderPickerModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  constructor(
    private modalSelector: ModalStoreSelector,
    private readonly modalsService: ModalsService,
  ) {
    super();
  }

  close(): void {
    this.modalsService.close(StripeTerminalReaderPickerModalComponent.handle);
  }
}
