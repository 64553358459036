import { IPaymentMethod } from '@config/payment-methods.config';

import { IPaymentMethodDescriptor } from '@core/models/payment.model';
import { PaymentService } from '@core/services/payment/payment.service';

enum StripePaymentMethod {
  apple = 'STRIPE_APPLE_PAY',
  google = 'STRIPE_GOOGLE_PAY',
}

export class PaymentUtils {
  static mapMethodsWithDescriptors(
    descriptors: IPaymentMethodDescriptor[],
    methods: IPaymentMethod[],
  ): IPaymentMethod[] {
    return descriptors
      ?.map(_descriptor => {
        const _method = methods.find(p => p.handle === _descriptor.handle);
        if (!_method) return null;

        const { handle } = _method;

        if (
          ((handle as string) === StripePaymentMethod.google && !PaymentService.stripeGooglePayAvailable) ||
          ((handle as string) === StripePaymentMethod.apple && !PaymentService.stripeApplePayAvailable)
        ) {
          return null;
        }

        _method.data = _descriptor;
        return _method;
      })
      .filter((p): p is IPaymentMethod => p !== null);
  }
}
