import { Injectable } from '@angular/core';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { CartStoreSelector } from '@app/common/selectors/cart.selector';

@Injectable({ providedIn: 'root' })
export class CartStoreRefiner {
  // isCartExist$
  selectHasCart = this._selector.selectCarts.pipe(
    map(carts => !!carts.length),
    distinctUntilChanged(),
  );

  // cartProducts$
  selectActiveCartProducts = this._selector.selectActive.pipe(
    map(_active => _active?.products ?? []),
  );

  // deliveryAddress$
  selectActiveCartDeliveryAddress = this._selector.selectActive.pipe(
    map(_active => _active?.delivery_address),
  );

  // paymentPhoneNumber$
  selectActiveCartPaymentPhoneNumber = this._selector.selectActive.pipe(
    map(_active => _active?.PaymentPhoneNumber),
  );

  // cartCoupon$
  selectActiveCartCoupon = this._selector.selectActive.pipe(
    map(_active =>
      _active?.coupons && _active?.coupons.length
        ? (_active?.coupons[0]?.code ?? undefined)
        : undefined,
    ),
  );

  constructor(private _selector: CartStoreSelector) {}
}
