<div class="mb-8xs">
  <app-resume-check-atom
    [title]="check[checkKeys.OnSiteLocation]?.name"
    [shopName]="check[checkKeys.Shop].name"
    [checkAmount]="check[checkKeys.FinalPrice]"
    [checkDate]="check[checkKeys.OpenedAt]"
    [checkOutsandingAmount]="check[checkKeys.OutstandingAmount]"
    [nbItems]="check[checkKeys.Items].length"
    (cardClick)="goToCheckDetails(check[checkKeys.Id])"
  ></app-resume-check-atom>
</div>
