<div class="bg-white">
  <div
    class="mb-4 block items-center justify-between md:mb-6 md:flex xl:mb-8"
    *ngIf="!showAll"
  >
    <h2 class="font-accent text-4.5xl font-light xl:text-4.5xl">
      {{ title }}
    </h2>
    <div class="flex items-center justify-between">
      <a class="font-bold text-primary-600">Tout afficher</a>
      <div class="ml-6 hidden xl:block">
        <app-rounded-icon-button
          *ngIf="!hidePrevBtn"
          icon="icon-arrow-left mr-3"
          color="primary"
          [outlined]="true"
          (onClick)="prevPage()"
        ></app-rounded-icon-button>
        <app-rounded-icon-button
          *ngIf="!hideNextBtn"
          icon="icon-arrow-right"
          color="primary"
          [outlined]="true"
          (onClick)="nextPage()"
        ></app-rounded-icon-button>
      </div>
    </div>
  </div>
  <div
    class="md:3 gap-2 xl:gap-4 xl:overflow-x-hidden"
    [ngClass]="{
      'flex overflow-x-auto': !showAll,
      'grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4': showAll,
    }"
  >
    <div
      class="block min-w-256 origin-top-right xl:min-w-24"
      [ngClass]="{ 'flex-basis-25': !showAll }"
      [id]="'slide_' + (index + 1)"
      *ngFor="let shop of shops; let index = index"
    >
      <app-push-shop-card-v2-atom
        [title]="shop[ShopKeys.Name]"
        [distance]="forceType(shop[ShopKeys.Distance])"
        [stars]="forceType(shop[ShopKeys.Stars])"
        [availableAt]="forceType(shop[ShopKeys.AvailableAt])"
        [isFavorite]="forceType(shop[ShopKeys.Favorite])"
        [imgUrl]="forceType(shop[ShopKeys.OverviewImage])?.url"
        [linkUrl]="shopsService.getUrl(shop)"
        (favoriteChange)="favoriteChange.emit($event)"
      ></app-push-shop-card-v2-atom>
    </div>
  </div>
</div>
