import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, of, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';
import { MarketplaceProductsService } from '@core/services/marketplace-products/marketplace-products.service';

@Component({
  selector: 'app-marketplace-products-preloader-block',
  template: ``,
})
export class MarketplaceProductsPreloaderBlockComponent implements OnInit, OnDestroy {
  isMarketplaceAvailable$ = this.marketplaceSelector.selectMarketplaceAvailable;

  private readonly destroyed$ = new Subject<boolean>();

  constructor(
    private marketplaceSelector: MarketplaceStoreSelector,
    private readonly marketplaceProductsService: MarketplaceProductsService,
  ) {}

  ngOnInit(): void {
    combineLatest([this.marketplaceSelector.selectMarketplaceId, this.isMarketplaceAvailable$])
      .pipe(
        takeUntil(this.destroyed$),
        switchMap(([marketplaceId, isAvailable]) =>
          isAvailable ? this.marketplaceProductsService.loadProducts$(marketplaceId) : of(null),
        ),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
