<button
  class="px-2 py-1 rounded-md text-xs font-bold h-full"
  [class.w-full]="full"
  [ngClass]="isActive ? 'bg-primary-600 text-white' : 'bg-primary-100 text-primary-600'"
  (click)="buttonClick.emit(amount ? amount : value)"
>
  <span *ngIf="text && !otherAmmount">
    {{ text }}
  </span>
  <div *ngIf="otherAmmount">
    <span class="text-base mr-0.5">
      {{ otherAmmount }}
    </span>
    <span class="text-base">
      <app-currency-atom></app-currency-atom>
    </span>
    <br />
    <span>{{ 'tip-modal.edit' | translate }}</span>
  </div>
  <div *ngIf="value && !text">
    <span class="text-base mr-1">{{ value }}</span>
    <span
      *ngIf="type === 'percent'"
      class="text-base"
      >%</span
    >
    <span
      *ngIf="type === 'currency'"
      class="text-base"
      ><app-currency-atom></app-currency-atom
    ></span>
    <br />
    <span
      *ngIf="amount"
      class="text-xs mr-1"
      >{{ amount }}</span
    >
    <span
      *ngIf="amount"
      class="text-xs"
      ><app-currency-atom></app-currency-atom
    ></span>
  </div>
</button>
