<app-modal-filler-atom
  (click)="closeAdvertising()"
  [index]="((index$ | async) ?? 0) + 10"
></app-modal-filler-atom>
<div
  class="fixed left-0 right-0 overflow-y-auto shadow"
  [ngClass]="
    settingsSelector.isMobileScreen
      ? 'bottom-0 max-h-85-screen max-w-screen-sm rounded-t-3xl'
      : 'flex justify-center items-center w-full h-full top-0 left-0'
  "
  [style.z-index]="((index$ | async) ?? 0) + 60"
>
  <div class="bg-white p-5 md:rounded-3xl">
    <div class="flex items-center justify-between">
      <h2 class="mx-auto font-accent text-3xs font-bold">
        {{ shopOpensAt }}
      </h2>
    </div>
    <p
      *ngIf="distributionMode$ | async as distribution"
      class="my-2 w-full text-center font-base text-neutral-600"
    >
      {{ 'capabilities.the-order' | translate }}
      {{ 'capabilities.not-available' | translate }}.
    </p>
    <div class="w-full pt-1">
      <app-button-atom
        size="M"
        [label]="'capabilities.see-the-products' | translate"
        color="primary"
        (click)="closeAdvertising()"
        type="light"
        [full]="true"
      ></app-button-atom>
    </div>
  </div>
</div>
