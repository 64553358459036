import { Component, Input } from '@angular/core';

import { IContentBuilderFieldImage } from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-image-two-cols-block',
  templateUrl: './image-two-cols-block.component.html',
})
export class ImageTwoColsBlockComponent {
  @Input() imageLeft?: IContentBuilderFieldImage;
  @Input() imageRight?: IContentBuilderFieldImage;
}
