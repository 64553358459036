import { Component } from '@angular/core';

import { AnchorStoreSelector } from '@common/selectors/anchor.selector';

@Component({
  selector: 'app-header-anchor-list-block',
  templateUrl: './header-anchor-list-block.component.html',
})
export class HeaderAnchorListBlockComponent {
  anchors$ = this.anchorSelector.selectAnchors;
  constructor(private anchorSelector: AnchorStoreSelector) {}
}
