import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { Component, Input } from '@angular/core';

import { DoodEntityCategory } from '@core/models/entity.model';
import { EntitiesService } from '@core/services/entities/entities.service';
import { FilterStoreDispatcher } from '@common/dispatchers/filter.dispatcher';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

@Component({
  selector: 'app-shop-type-picker-block',
  templateUrl: './shop-type-picker-block.component.html',
})
export class ShopTypePickerBlockComponent {
  @Input() displayOnDesktop = true;

  marketplaceId$: Observable<string> = this.marketplaceSelector.selectMarketplaceId;

  categoryItems!: DoodEntityCategory[];

  constructor(
    private filterDispatcher: FilterStoreDispatcher,
    private readonly entitiesService: EntitiesService,
    private marketplaceSelector: MarketplaceStoreSelector,
  ) {
    this.marketplaceId$
      .pipe(
        take(1),
        switchMap(id => this.entitiesService.loadEntitiesCategories$(id)),
      )
      .subscribe(result => (this.categoryItems = result));
  }

  selectCategory(category: DoodEntityCategory): void {
    this.filterDispatcher.addCategory(category);
  }
}
