import { Component, Input } from '@angular/core';

declare const cookieconsent: any;

declare const window: any;

@Component({
  selector: 'app-cookie-consent-link-item-block',
  templateUrl: './cookie-consent-link-item-block.component.html',
})
export class CookieConsentLinkItemBlockComponent {
  @Input() label!: string;
  @Input() type!: string;

  openPreferencesCenter(): void {
    cookieconsent.openPreferencesCenter();
  }

  openCookieConsent(): void {
    this.deleteAllCookies();
    cookieconsent.run(window.cookieConsentConfig);
  }

  private getCookies(): { name: string; value: string }[] {
    const cookies: { name: string; value: string }[] = [];

    document.cookie.split(';').forEach(cookie => {
      const [name, value] = cookie.split('=').map(c => c.trim());
      cookies.push({ name, value });
    });

    return cookies;
  }

  private deleteCookie(name: string): void {
    const date = new Date();
    date.setTime(date.getTime() - 1);
    const expires = `;expires=${date.toUTCString()}`;
    document.cookie = `${name}=;${expires};path=/`;
  }

  deleteAllCookies(): void {
    const cookiesToDelete = [
      'cookie_consent_level',
      'cookie_consent_user_consent_token',
      'cookie_consent_user_accepted',
    ];
    const cookies = this.getCookies();

    cookies.forEach(cookie => {
      if (cookiesToDelete.includes(cookie.name)) {
        this.deleteCookie(cookie.name);
      }
    });
  }
}
