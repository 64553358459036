import dayjs from 'dayjs';
import { combineLatest } from 'rxjs';
import weekday from 'dayjs/plugin/weekday';
import { map, take, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit } from '@angular/core';

import { EntityKeys } from '@config/keys/entity.keys';
import { DistributionModeKeys, ShopKeys } from '@config/keys/shop.keys';

import { CartStoreSelector } from '@common/selectors/cart.selector';
import { ShopStoreSelector } from '@common/selectors/shop.selector';
import { BasketStoreRefiner } from '@common/refiners/basket.refiner';
import { BasketStoreSelector } from '@common/selectors/basket.selector';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { DeliveryDistributionModes } from '@config/values/order.values';
import { GroupedOrderModalsAttributes } from '@config/modals/grouped-order-modals.config';

import { DoodShopModel } from '@core/models/shop.model';
import { DoodEntityModel } from '@core/models/entity.model';
import {
  IContentBuilderFieldColor,
  IContentBuilderFieldImage,
} from 'src/app/core/models/content-builder-fields.model';

import { ShopsService } from '@core/services/shops/shops.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { OrderTypeService } from '@core/services/order-type/order-type.service';
import { ResaNinjaService } from '@core/services/resa-ninja/resa-ninja.service';
import { AuthFirebaseService } from '@core/services/api/auth-firebase/auth-firebase.service';
import { NavigationHistoryService } from '@core/services/navigation-history/navigation-history.service';

import { AlertModalComponent } from '@shared/modals/alert-modal/alert-modal.component';
import { AuthenticationModalComponent } from '@shared/modals/authentication-modal/authentication-modal.component';
import { CartParametersModalComponent } from '@shared/modals/cart-parameters-modal/cart-parameters-modal.component';
import { ShopInformationsModalComponent } from '@shared/modals/shop-informations-modal/shop-informations-modal.component';
import { ShareGroupedOrderModalComponent } from '@shared/modals/share-grouped-order-modal/share-grouped-order-modal.component';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-shop-hero-image-block',
  templateUrl: './shop-hero-image-block.component.html',
})
export class ShopHeroImageBlockComponent extends DestroyerBase implements OnInit {
  @Input() displayImage = true;
  @Input() displayAddress = true;
  @Input() displayCategory = true;
  @Input() displayMoreInfoButton = true;
  @Input() overrideImage?: IContentBuilderFieldImage;
  @Input() titleSize: 'XS' | 'S' | 'M' | 'L' = 'M';
  @Input() titleFont: 'accent' | 'base' | 'brand' = 'accent';

  @Input() backgroundColor: IContentBuilderFieldColor = {
    value: 'transparent',
    type: ColorFieldTypesValues.Hex,
  };
  @Input() shopNameColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() textColor: IContentBuilderFieldColor = {
    value: 'neutral-600',
    type: ColorFieldTypesValues.Palette,
  };

  selectFirebaseToken$ = this.authSelector.selectStatusToken;

  entity?: DoodEntityModel;
  shop: DoodShopModel | null = null;
  isShopLoading$ = this.shopSelector.selectIsLoading;

  basketId$ = this.basketSelector.selectId;

  shopKeys = ShopKeys;
  entityKeys = EntityKeys;
  shopImageCover?: string;
  closedLabel?: string;
  isPreorderingAllowed?: boolean;
  isGroupedOrderAllowed?: boolean;
  isBigHeaderImage?: boolean;
  displayBookButton = false;

  isGroupedOrderActive = false;

  get imageSrcUrl(): string | undefined {
    return this.overrideImage?.url ?? this.shopImageCover;
  }

  constructor(
    private authSelector: AuthStoreSelector,
    private cartSelector: CartStoreSelector,
    private shopSelector: ShopStoreSelector,
    private basketRefiner: BasketStoreRefiner,
    private basketSelector: BasketStoreSelector,
    private readonly shopsService: ShopsService,
    private readonly modalsService: ModalsService,
    private readonly orderTypeService: OrderTypeService,
    private marketplaceSelector: MarketplaceStoreSelector,
    private readonly resaNinjaService: ResaNinjaService,
    private readonly translateService: TranslateService,
    private readonly authFirebaseService: AuthFirebaseService,
    private readonly navigationHistoryService: NavigationHistoryService,
  ) {
    super();
    dayjs.extend(weekday);
  }

  ngOnInit(): void {
    combineLatest([
      this.shopSelector.selectActive,
      this.cartSelector.selectActive,
      this.marketplaceSelector.selectMarketplace.pipe(map(marketplace => marketplace.blocks)),
    ])
      .pipe(
        takeUntil(this._destroyerRef),
        map(([shop, cart, marketplaceBlocks]) => {
          this.shop = shop;
          if (!shop) {
            return;
          }

          this.isBigHeaderImage = !!marketplaceBlocks?.find(el => el.type === 'HeaderBigLogoBlock');
          this.shopImageCover = shop?.images?.cover?.url;
          this.isPreorderingAllowed =
            this.orderTypeService.getCapabilities(cart?.type).preorderingAllowed &&
            this.orderTypeService.getCapabilities(cart?.type).useOpenHours &&
            this.shop?.[ShopKeys.Available];

          const distributionModeFound = shop?.[ShopKeys.DistributionModes].find(
            distributionMode =>
              DeliveryDistributionModes.includes(distributionMode.type) ||
              distributionMode.type === this.cartSelector.active?.type,
          );
          this.isGroupedOrderAllowed =
            distributionModeFound?.[DistributionModeKeys.AllowGroupedOrder] || false;

          const orderType = this.cartSelector.active?.type;
          this.closedLabel = this.shopsService.getClosedMessage(shop, null, orderType);

          // Legacy image
          if (!this.shopImageCover && shop?.cover) {
            // TODO: Use environment variable instead
            this.shopImageCover = 'https://api.dood.com' + shop?.cover;
          }

          const resaNinjaPlugin = this.resaNinjaService.getPlugin(this.shop);
          if (resaNinjaPlugin) {
            this.displayBookButton = true;
          }
        }),
      )
      .subscribe();

    this.authSelector.selectStatusToken
      .pipe(
        takeUntil(this._destroyerRef),
        map(token => !!token),
      )
      .subscribe(token =>
        token && this.isGroupedOrderActive
          ? this.modalsService.open(
              ShareGroupedOrderModalComponent.handle,
              GroupedOrderModalsAttributes.ShareLink,
            )
          : null,
      );
  }

  goBack(): void {
    this.navigationHistoryService.goBack();
  }

  openShopInformationsModal(): void {
    this.modalsService.open(ShopInformationsModalComponent.handle);
  }

  openGroupOrderModal(): void {
    this.isGroupedOrderActive = true;
    combineLatest([
      this.authFirebaseService.isAuthenticate$().pipe(
        take(1),
        map(v => !!v),
      ),
      this.authSelector.selectStatusToken.pipe(
        take(1),
        map(token => !!token),
      ),
      this.authSelector.selectUserId.pipe(
        take(1),
        map(id => !!id),
      ),
      this.basketRefiner.selectHasBasket,
    ])
      .pipe(
        take(1),
        map(([firebaseUser, firebaseToken, userId, isBasketExist]) => [
          firebaseUser && firebaseToken && userId,
          isBasketExist,
        ]),
        map(([logged, isBasketExist]) => {
          if (logged && isBasketExist) {
            this.modalsService.open(
              ShareGroupedOrderModalComponent.handle,
              GroupedOrderModalsAttributes.GeneratedLink,
            );
          }
          if (logged) {
            this.modalsService.open(
              ShareGroupedOrderModalComponent.handle,
              GroupedOrderModalsAttributes.ShareLink,
            );
          } else {
            this.modalsService.open(AuthenticationModalComponent.handle);
          }
        }),
      )
      .subscribe();
  }

  onOpenCartParameters(): void {
    this.modalsService.open(CartParametersModalComponent.handle);
  }

  openBookUrl(): void {
    const resaNinjaPlugin = this.resaNinjaService.getPlugin(this.shop);
    if (!resaNinjaPlugin) {
      return;
    }

    const bookUrl = this.resaNinjaService.getBookUrl(this.shop);

    if (bookUrl) {
      window.location.href = bookUrl;
    } else {
      this.modalsService.open(AlertModalComponent.handle);
      this.modalsService.setData(AlertModalComponent.handle, {
        message: this.translateService.instant('shop-page.service-not-available'),
      });
    }
  }
}
