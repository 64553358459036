import { AddPaymentModalComponent } from '@shared/modals/add-payment-modal/add-payment-modal.component';
import { ComoCreateOrLinkAccountModalComponent } from '@shared/modals/como/como-create-or-link-account-modal/como-create-or-link-account-modal.component';
import { ComoCreateAccountModalComponent } from '@shared/modals/como/como-create-account-modal/como-create-account-modal.component';
import { ComoLinkAccountModalComponent } from '@shared/modals/como/como-link-account-modal/como-link-account-modal.component';
import { ComoUseCreditBalanceModalComponent } from '@shared/modals/como/como-use-credit-balance-modal/como-use-credit-balance-modal.component';
import { CartOverviewModalComponent } from '@shared/modals/cart-overview-modal/cart-overview-modal.component';
import { SidebarNavigationModalComponent } from '@shared/modals/sidebar-navigation-modal/sidebar-navigation-modal.component';
import { AuthenticationModalComponent } from '@shared/modals/authentication-modal/authentication-modal.component';
import { CartFooterModalComponent } from '@shared/modals/cart-footer-modal/cart-footer-modal.component';
import { CartVoucherModalComponent } from '@shared/modals/cart-voucher-modal/cart-voucher-modal.component';
import { CartCommentModalComponent } from '@shared/modals/cart-comment-modal/cart-comment-modal.component';
import { ProductAddModalComponent } from '@shared/modals/product-add-modal/product-add-modal.component';
import { ItemEditModalComponent } from '@shared/modals/item-edit-modal/item-edit-modal.component';
import { ShopSearchParametersModalComponent } from '@shared/modals/shop-search-parameters-modal/shop-search-parameters-modal.component';
import { AddHomeScreenModalComponent } from '@shared/modals/add-home-screen-modal/add-home-screen-modal.component';
import { FiltersModalComponent } from '@shared/modals/filters-modal/filters-modal.component';
import { CompoundItemModalComponent } from '@shared/modals/compound-item-modal/compound-item-modal.component';
import { CartUserInformationsModalComponent } from '@shared/modals/cart-user-informations-modal/cart-user-informations-modal.component';
import { CartParametersModalComponent } from '@shared/modals/cart-parameters-modal/cart-parameters-modal.component';
import { ShopInformationsModalComponent } from '@shared/modals/shop-informations-modal/shop-informations-modal.component';
import { NavigateToMapButtonModalComponent } from '@shared/modals/navigate-to-map-button-modal/navigate-to-map-button-modal.component';
import { ShopCapabilitiesModalComponent } from '@shared/modals/shop-capabilities-modal/shop-capabilities-modal.component';
import { StripeTerminalReaderPickerModalComponent } from '@shared/modals/stripe-terminal-reader-picker-modal/stripe-terminal-reader-picker-modal.component';
import { ShareGroupedOrderModalComponent } from '@shared/modals/share-grouped-order-modal/share-grouped-order-modal.component';
import { WaitingModalComponent } from '@shared/modals/waiting-modal/waiting-modal.component';
import { ComoSsoLoadingModalComponent } from '@shared/modals/como/como-sso-loading-modal/como-sso-loading-modal.component';
import { ComoConfirmUsePointModalComponent } from '@shared/modals/como/como-confirm-use-point-modal/como-confirm-use-point-modal.component';
import { CartTipPickerModalComponent } from '@shared/modals/cart-tip-picker-modal/cart-tip-picker-modal.component';
import { KioskCartResetTimerModalComponent } from '@shared/modals/kiosk-cart-reset-timer-modal/kiosk-cart-reset-timer-modal.component';
import { ZerosixCreateOrLinkAccountModalComponent } from '@shared/modals/zerosix/zerosix-create-or-link-account-modal/zerosix-create-or-link-account-modal.component';
import { ZerosixCreateAccountModalComponent } from '@shared/modals/zerosix/zerosix-create-account-modal/zerosix-create-account-modal.component';
import { ZerosixLinkAccountModalComponent } from '@shared/modals/zerosix/zerosix-link-account-modal/zerosix-link-account-modal.component';
import { ZerosixOfferedProductPickerModalComponent } from '@shared/modals/zerosix/zerosix-offered-product-picker-modal/zerosix-offered-product-picker-modal.component';
import { CartParametersModalLauncherBlockComponent } from '@shared/blocks/modal-launchers/cart-parameters-modal-launcher-block/cart-parameters-modal-launcher-block.component';
import { LydiaPaymentPhoneNumberModalComponent } from '@shared/modals/lydia-payment-phone-number-modal/lydia-payment-phone-number-modal.component';
import { GroupedOrderModalLauncherBlockComponent } from '@shared/blocks/modal-launchers/grouped-order-modal-launcher-block/grouped-order-modal-launcher-block.component';
import { DeliveryAddressesModalComponent } from '@shared/modals/delivery-addresses-modal/delivery-addresses-modal.component';
import { NoConnectionModalComponent } from '@shared/modals/no-connection-modal/no-connection-modal.component';
import { KioskPickerModalComponent } from '@shared/modals/kiosk-picker-modal/kiosk-picker-modal.component';
import { BancontactPaymentFormModalComponent } from '@shared/modals/bancontact-payment-form-modal/bancontact-payment-form-modal.component';
import { ConfirmModalComponent } from '@shared/modals/confirm-modal/confirm-modal.component';
import { AlertModalComponent } from '@shared/modals/alert-modal/alert-modal.component';
import { PongoCreateOrLinkAccountModalComponent } from '@shared/modals/pongo/pongo-create-or-link-account-modal/pongo-create-or-link-account-modal.component';
import { PongoOfferedProductPickerModalComponent } from '@shared/modals/pongo/pongo-offered-product-picker-modal/pongo-offered-product-picker-modal.component';
import { PatSelectProductsModalComponent } from '@shared/modals/pat-select-products-modal/pat-select-products-modal.component';
import { PatDivideAmountModalComponent } from '@shared/modals/pat-divide-amount-modal/pat-divide-amount-modal.component';
import { PatFreeAmountModalComponent } from '@shared/modals/pat-free-amount-modal/pat-free-amount-modal.component';
import { ComoRegistrationModalComponent } from '@shared/modals/como/como-registration-modal/como-registration-modal.component';
import { ComoProductsSelectionModalComponent } from '@shared/modals/como/como-products-selection-modal/como-products-selection-modal.component';
import { ShareUserFeedbackModalComponent } from '@shared/modals/share-user-feedback-modal/share-user-feedback-modal.component';
import { SplashScreenImageModalComponent } from '@shared/modals/splash-screen-image-modal/splash-screen-image-modal.component';
import { ReceiveReceiptModalComponent } from '@shared/modals/receive-receipt-modal/receive-receipt-modal.component';
import { KioskSuggestionsModalComponent } from '@shared/blocks/kiosk/kiosk-suggestions-modal/kiosk-suggestions-modal.component';
import { KioskSelectSimpleProductModalComponent } from '@shared/modals/kiosk-select-simple-product-modal/kiosk-select-simple-product-modal.component';
import { PatAvailabilityModalComponent } from '@shared/modals/pat-availability-modal/pat-availability-modal.component';
import { KioskProductOverviewModalComponent } from '@shared/modals/kiosk-product-overview-modal/kiosk-product-overview-modal.component';
import { KioskAlertModalComponent } from '@shared/modals/kiosk-alert-modal/kiosk-alert-modal.component';

export const DEFAULT_MODALS = [
  {
    handle: CartOverviewModalComponent.handle,
    block: {
      type: 'CartOverviewModalComponent',
      data: {},
    },
  },
  {
    handle: SidebarNavigationModalComponent.handle,
    block: {
      type: 'SidebarNavigationModalComponent',
      data: {
        heading: 'Menu',
      },
    },
  },
  {
    handle: AuthenticationModalComponent.handle,
    block: {
      type: 'AuthenticationModalComponent',
      data: {},
    },
  },
  {
    handle: CartFooterModalComponent.handle,
    block: {
      type: 'CartFooterModalComponent',
      data: {},
    },
  },
  {
    handle: CartVoucherModalComponent.handle,
    block: {
      type: 'CartVoucherModalComponent',
      data: {},
    },
  },
  {
    handle: CartCommentModalComponent.handle,
    block: {
      type: 'CartCommentModalComponent',
      data: {},
    },
  },
  {
    handle: ProductAddModalComponent.handle,
    block: {
      type: 'ProductAddModalComponent',
      data: {},
    },
  },
  {
    handle: ItemEditModalComponent.handle,
    block: {
      type: 'ItemEditModalComponent',
      data: {},
    },
  },
  {
    handle: AddHomeScreenModalComponent.handle,
    block: {
      type: 'AddHomeScreenModalComponent',
      data: {},
    },
  },
  {
    handle: ShopSearchParametersModalComponent.handle,
    block: {
      type: 'ShopSearchParametersModalComponent',
      data: {},
    },
  },
  {
    handle: CartParametersModalComponent.handle,
    block: {
      type: 'CartParametersModalComponent',
      data: {},
    },
  },
  {
    handle: CartUserInformationsModalComponent.handle,
    block: {
      type: 'CartUserInformationsModalComponent',
      data: {},
    },
  },
  {
    handle: CartTipPickerModalComponent.handle,
    block: {
      type: 'CartTipPickerModalComponent',
      data: {},
    },
  },
  {
    handle: StripeTerminalReaderPickerModalComponent.handle,
    block: {
      type: 'StripeTerminalReaderPickerModalComponent',
      data: {},
    },
  },
  {
    handle: FiltersModalComponent.handle,
    block: {
      type: 'FiltersModalComponent',
      data: {
        filters: [
          {
            id: 'distance',
            label: 'filters.distance.title',
            type: 'radio',
            values: [
              {
                label: 'filters.distance.less-than-05',
                value: '500',
              },
              {
                label: 'filters.distance.less-than-1',
                value: '1000',
              },
              {
                label: 'filters.distance.less-than-2',
                value: '2000',
              },
              {
                label: 'filters.distance.less-than-5',
                value: '5000',
              },
              {
                label: 'filters.distance.less-than-10',
                value: '10000',
              },
              {
                label: 'filters.distance.less-than-15',
                value: '15000',
              },
            ],
          },
          {
            id: 'price-range',
            label: 'filters.price-range.title',
            type: 'custom-radio',
            multiSelect: true,
            values: [
              {
                label: '€',
                value: 'cheap',
              },
              {
                label: '€€',
                value: 'moderate',
              },
              {
                label: '€€€',
                value: 'expensive',
              },
            ],
          },
        ],
      },
    },
  },
  {
    handle: CompoundItemModalComponent.handle,
    block: {
      type: 'CompoundItemModalComponent',
      data: {},
    },
  },
  {
    handle: ComoUseCreditBalanceModalComponent.handle,
    block: {
      type: 'ComoUseCreditBalanceModalComponent',
      data: {},
    },
  },
  {
    handle: ComoCreateAccountModalComponent.handle,
    block: {
      type: 'ComoCreateAccountModalComponent',
      data: {},
    },
  },
  {
    handle: ComoLinkAccountModalComponent.handle,
    block: {
      type: 'ComoLinkAccountModalComponent',
      data: {},
    },
  },
  {
    handle: ComoCreateOrLinkAccountModalComponent.handle,
    block: {
      type: 'ComoCreateOrLinkAccountModalComponent',
      data: {},
    },
  },
  {
    handle: ZerosixCreateOrLinkAccountModalComponent.handle,
    block: {
      type: 'ZerosixCreateOrLinkAccountModalComponent',
      data: {},
    },
  },
  {
    handle: ZerosixCreateAccountModalComponent.handle,
    block: {
      type: 'ZerosixCreateAccountModalComponent',
      data: {},
    },
  },
  {
    handle: ZerosixLinkAccountModalComponent.handle,
    block: {
      type: 'ZerosixLinkAccountModalComponent',
      data: {},
    },
  },
  {
    handle: ZerosixOfferedProductPickerModalComponent.handle,
    block: {
      type: 'ZerosixOfferedProductPickerModalComponent',
      data: {},
    },
  },
  {
    handle: PongoCreateOrLinkAccountModalComponent.handle,
    block: {
      type: 'PongoCreateOrLinkAccountModalComponent',
      data: {},
    },
  },
  {
    handle: PongoOfferedProductPickerModalComponent.handle,
    block: {
      type: 'PongoOfferedProductPickerModalComponent',
      data: {},
    },
  },
  {
    handle: ShopInformationsModalComponent.handle,
    block: {
      type: 'ShopInformationsModalComponent',
      data: {},
    },
  },
  {
    handle: ShareGroupedOrderModalComponent.handle,
    block: {
      type: 'ShareGroupedOrderModalComponent',
      data: {},
    },
  },
  {
    handle: ShopCapabilitiesModalComponent.handle,
    block: {
      type: 'ShopCapabilitiesModalComponent',
      data: {},
    },
  },
  {
    handle: NavigateToMapButtonModalComponent.handle,
    block: {
      type: 'NavigateToMapButtonModalComponent',
      data: {},
    },
  },
  {
    handle: KioskCartResetTimerModalComponent.handle,
    block: {
      type: 'KioskCartResetTimerModalComponent',
      data: {},
    },
  },
  {
    handle: WaitingModalComponent.handle,
    block: {
      type: 'WaitingModalComponent',
      data: {},
    },
  },
  {
    handle: AddPaymentModalComponent.handle,
    block: {
      type: 'AddPaymentModalComponent',
      data: {},
    },
  },
  {
    handle: ComoSsoLoadingModalComponent.handle,
    block: {
      type: 'ComoSsoLoadingModalComponent',
      data: {},
    },
  },
  {
    handle: ComoConfirmUsePointModalComponent.handle,
    block: {
      type: 'ComoConfirmUsePointModalComponent',
      data: {},
    },
  },
  {
    handle: ComoRegistrationModalComponent.handle,
    block: {
      type: 'ComoRegistrationModalComponent',
      data: {},
    },
  },
  {
    handle: ComoProductsSelectionModalComponent.handle,
    block: {
      type: 'ComoProductsSelectionModalComponent',
      data: {},
    },
  },
  {
    handle: CartParametersModalLauncherBlockComponent.handle,
    block: {
      type: 'CartParametersModalLauncherBlockComponent',
      data: {},
    },
  },
  {
    handle: GroupedOrderModalLauncherBlockComponent.handle,
    block: {
      type: 'GroupedOrderModalLauncherBlockComponent',
      data: {},
    },
  },
  {
    handle: DeliveryAddressesModalComponent.handle,
    block: {
      type: 'DeliveryAddressesModalComponent',
      data: {},
    },
  },
  {
    handle: LydiaPaymentPhoneNumberModalComponent.handle,
    block: {
      type: 'LydiaPaymentPhoneNumberModalComponent',
      data: {},
    },
  },
  {
    handle: KioskPickerModalComponent.handle,
    block: {
      type: 'KioskPickerModalComponent',
      data: {},
    },
  },
  {
    handle: KioskProductOverviewModalComponent.handle,
    block: {
      type: 'KioskProductOverviewModalComponent',
      data: {},
    },
  },
  {
    handle: KioskSelectSimpleProductModalComponent.handle,
    block: {
      type: 'KioskSelectSimpleProductModalComponent',
      data: {},
    },
  },
  {
    handle: BancontactPaymentFormModalComponent.handle,
    block: {
      type: 'BancontactPaymentFormModalComponent',
      data: {},
    },
  },
  {
    handle: NoConnectionModalComponent.handle,
    block: {
      type: 'NoConnectionModalComponent',
      data: {},
    },
  },
  {
    handle: ConfirmModalComponent.handle,
    block: {
      type: 'ConfirmModalComponent',
    },
  },
  {
    handle: AlertModalComponent.handle,
    block: {
      type: 'AlertModalComponent',
    },
  },
  {
    handle: KioskAlertModalComponent.handle,
    block: {
      type: 'KioskAlertModalComponent',
    },
  },
  {
    handle: PatSelectProductsModalComponent.handle,
    block: {
      type: 'PatSelectProductsModalComponent',
    },
  },
  {
    handle: PatDivideAmountModalComponent.handle,
    block: {
      type: 'PatDivideAmountModalComponent',
    },
  },
  {
    handle: PatFreeAmountModalComponent.handle,
    block: {
      type: 'PatFreeAmountModalComponent',
    },
  },
  {
    handle: ShareUserFeedbackModalComponent.handle,
    block: {
      type: 'ShareUserFeedbackModalComponent',
    },
  },
  {
    handle: SplashScreenImageModalComponent.handle,
    block: {
      type: 'SplashScreenImageModalComponent',
    },
  },
  {
    handle: ReceiveReceiptModalComponent.handle,
    block: {
      type: 'ReceiveReceiptModalComponent',
    },
  },
  {
    handle: KioskSuggestionsModalComponent.handle,
    block: {
      type: 'KioskSuggestionsModalComponent',
    },
  },
  {
    handle: PatAvailabilityModalComponent.handle,
    block: {
      type: 'PatAvailabilityModalComponent',
    },
  },
];
