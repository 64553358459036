import { DateUtils } from 'src/app/shared/utils/date/date.utils';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { OrderStatusLabels } from '@config/labels/order.labels';

@Component({
  selector: 'app-resume-order-atom',
  templateUrl: './resume-order-atom.component.html',
})
export class ResumeOrderAtomComponent implements OnChanges {
  @Input() title!: string;
  @Input() description!: string;
  @Input() shopName!: string;
  @Input() orderStatus!: keyof typeof OrderStatusLabels;
  @Input() orderDate: Date | null = null;
  @Input() orderAmount!: number;
  @Input() actionName!: string;
  @Input() nbProducts!: number;

  @Output() action = new EventEmitter();
  @Output() cardClick = new EventEmitter();

  orderStatusLabels = OrderStatusLabels;

  order_date!: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.orderDate) {
      this.order_date = DateUtils.dayjsInMarketplaceTimezone(this.orderDate).format('DD/MM/YYYY');
    }
  }
}
