<app-modal-filler-atom
  (click)="closeVoucher()"
  [index]="(index$ | async) ?? 0"
></app-modal-filler-atom>
<div
  class="fixed left-0 right-0 overflow-y-auto shadow"
  [ngClass]="
    (settingsSelector.selectDeviceIsMobileScreen | async)
      ? 'bottom-0 max-h-85-screen max-w-screen-sm'
      : 'flex justify-center items-center w-full h-full top-0 left-0'
  "
  [style.z-index]="((index$ | async) ?? 0) + 50"
>
  <app-input-voucher-atom
    (applyVoucher)="applyVoucher($event)"
    (closeVoucher)="closeVoucher()"
    [error]="errorVoucher ?? ''"
    [isValidating]="isValidating"
  ></app-input-voucher-atom>
</div>
