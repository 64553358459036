import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-kiosk-confirmation-step',
  template: `
    @if (user$ | async; as user) {
      <div class="flex flex-col items-center justify-center gap-12">
        <h1
          class="w-full text-center text-4xl font-bold"
          [style.color]="accentColor | contentBuilderColor"
        >
          {{
            'como.create-or-link-account.kiosk-confirmation-step.title'
              | translate: { firstname: user.firstname ? user.firstname : '' }
          }}
        </h1>
        <i
          class="icon icon-n-check text-9xl"
          [style.color]="accentColor | contentBuilderColor"
        ></i>
        <div class="w-full">
          <app-button-atom
            size="L"
            [label]="'como.create-or-link-account.kiosk-confirmation-step.continue' | translate"
            type="kiosk-primary"
            [overrideBgColor]="accentColor"
            (click)="onCompleteStep()"
            [full]="true"
          ></app-button-atom>
        </div>
      </div>
    }
  `,
})
export class KioskConfirmationStepComponent {
  @Output() onComplete = new EventEmitter<void>();

  @Input() accentColor?: IContentBuilderFieldColor;

  user$ = this.authSelector.selectUser;

  constructor(private readonly authSelector: AuthStoreSelector) {}

  onCompleteStep(): void {
    this.onComplete.next();
  }
}
