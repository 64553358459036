import { Component, OnInit } from '@angular/core';
import { switchMap, take, tap } from 'rxjs/operators';

import { UserService } from '@core/services/user/user.service';
import { AuthFirebaseService } from '@core/services/api/auth-firebase/auth-firebase.service';
import { UserCredential } from 'firebase/auth';

@Component({
  template: ``,
  selector: 'app-anonymous-authentication-preloader-block',
})
export class AnonymousAuthenticationPreloaderBlockComponent implements OnInit {
  constructor(
    private readonly authFirebaseService: AuthFirebaseService,
    private readonly userService: UserService,
  ) {}

  ngOnInit(): void {
    this.authFirebaseService
      .isAuthenticate$()
      .pipe(
        take(1),
        tap(isAuthenticated => {
          if (!isAuthenticated) {
            console.log('[AnonymousAuthenticationPreloader] Start authentication');
            return this.authFirebaseService
              .signInAnonymously()
              .pipe(
                switchMap((userCredential: UserCredential) =>
                  this.authFirebaseService.saveFirebaseTokenInSessionStore$(userCredential),
                ),
                switchMap(() => this.userService.createAnonymousUser$()),
                switchMap(() => this.authFirebaseService.loadUser$()),
                tap(() =>
                  console.log('[AnonymousAuthenticationPreloader] Authentication succeeded'),
                ),
              )
              .subscribe();
          }
        }),
      )
      .subscribe();
  }
}
