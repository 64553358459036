import { Observable } from 'rxjs';
import { skipWhile } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { DoodProductModel } from '@core/models/product.model';
import { ProductStoreRefiner } from '@common/refiners/product.refiner';
import { ProductStoreSelector } from '@common/selectors/product.selector';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(
    private productRefiner: ProductStoreRefiner,
    private productSelector: ProductStoreSelector,
  ) {}

  selectByShopAndProduct$(
    shopId: string | null,
    productId: string | null,
  ): Observable<DoodProductModel | undefined> {
    return this.productRefiner
      .selectShopProductById(shopId, productId)
      .pipe(skipWhile(_product => !_product));
  }

  selectAll$(): Observable<DoodProductModel[]> {
    return this.productSelector.selectProducts;
  }
}
