import { Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationHistoryService } from '@core/services/navigation-history/navigation-history.service';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { CartService } from '@core/services/cart/cart.service';

@Component({
  selector: 'app-kiosk-loyalty-connection-page',
  templateUrl: './kiosk-loyalty-connection-page.component.html',
})
export class KioskLoyaltyConnectionPageComponent implements OnInit {
  @Input() header?: unknown[];
  @Input() body: any;
  @Input() footer: any;

  @Input() backgroundColor?: IContentBuilderFieldColor;
  @Input() textColor?: IContentBuilderFieldColor;

  @HostBinding('class') classes = 'h-screen flex';

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly navigationHistoryService: NavigationHistoryService,
    private readonly el: ElementRef,
    private readonly cartService: CartService,
  ) {
    this.cartService.loadShopsOfCart$().subscribe();
  }

  ngOnInit(): void {
    this.navigationHistoryService.pushStateFromActivatedRouteSnapshot(
      '/' + this.activatedRoute.snapshot.url.join('/'),
      this.activatedRoute.snapshot,
    );

    if (this.textColor?.value) {
      this.setTextOverrideColor();
    }
  }

  setTextOverrideColor(): void {
    const textColor = this.textColor?.value;
    this.el.nativeElement.style.setProperty('--my-class-bg-color', textColor);
  }

  getBackgroundStyles() {
    return {
      'background-color': this.backgroundColor ? this.backgroundColor : null,
    };
  }
}
