import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-input-voucher-atom',
  templateUrl: './input-voucher-atom.component.html',
})
export class InputVoucherAtomComponent {
  @Input() error!: string;
  @Input() voucher!: string;
  @Input() isValidating = false;

  @Output() closeVoucher = new EventEmitter();
  @Output() applyVoucher = new EventEmitter<string>();
}
