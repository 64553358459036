import { timer } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

import { EntityKeys } from '@config/keys/entity.keys';
import { DoodEntityModel, DoodEntityType } from '@core/models/entity.model';

import { ShopsService } from '@core/services/shops/shops.service';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { DistanceUtils } from '@shared/utils/distance/distance.utils';
import { ShopSearchParametersService } from '@core/services/shop-search-parameters/shop-search-parameters.service';
import { TemplateLayoutKeys } from '@config/keys/template-layout.keys';

@Component({
  selector: 'app-push-shop-card-v2-atom',
  templateUrl: './push-shop-card-v2-atom.component.html',
  styleUrls: ['./push-shop-card-v2-atom.component.scss'],
})
export class PushShopCardV2AtomComponent extends DestroyerBase implements OnInit {
  @Input() entity!: DoodEntityModel;
  @Input() title!: string;
  @Input() distance!: number;
  @Input() availableAt!: string;
  @Input() stars!: string;
  @Input() isFavorite!: boolean;
  @Input() imgUrl!: string;
  @Input() category!: string;
  @Input() budget!: string;
  @Input() currency = 'EUR';
  @Input() shortDescription!: string;
  @Input() linkUrl?: string;
  @Input() address?: string;
  @Input() displayEntityImage = true;
  @Input() displayEntityDistance = true;
  @Input() displayEntityPricing = true;
  @Input() displayEntityAddress = false;
  @Input() displayEntityCategory = true;
  @Input() entityNameSize = 'lg';
  @Input() increaseHeight = false;
  @Input() wantedAt: Date | null = null;
  @Input() templateLayout = TemplateLayoutKeys.DefaultLayout;

  @HostBinding('style.--kiosk-card-aspect-ratio')
  @Input()
  kioskCardAspectRatio = '16/9';

  @Output() favoriteChange = new EventEmitter<boolean>();

  themeLayoutKeys = TemplateLayoutKeys;

  closedLabel?: string;
  formattedDistance!: string;

  constructor(
    private readonly shopsService: ShopsService,
    private readonly shopSearchParametersService: ShopSearchParametersService,
  ) {
    super();
  }

  onFavoriteClick(event: Event): void {
    event.stopPropagation();
    this.favoriteChange.emit(!this.isFavorite);
  }

  ngOnInit(): void {
    this.formattedDistance = DistanceUtils.formatDistance(this.distance);
    this.updateClosedLabel();
  }

  updateClosedLabel(): void {
    if (this.entity[EntityKeys.EntityType] === DoodEntityType.Marketplace) {
      return;
    }

    timer(1, 1000)
      .pipe(
        takeUntil(this._destroyerRef),
        tap(() => {
          const distributionMode = this.shopSearchParametersService.getDistributionMode();
          this.closedLabel = this.shopsService.getClosedMessage(
            this.entity,
            this.wantedAt,
            distributionMode,
          );
        }),
      )
      .subscribe();
  }
}
