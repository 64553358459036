<h1 class="text-neutral-800 font-brand font-bold text-28px pb-5">
  {{ 'como.create-or-link-account.otp-step.title' | translate }}
</h1>
<form
  [formGroup]="form"
  class="pb-6"
>
  <label
    for="authCode"
    class="block mb-1 text-sm leading-5 font-base font-normal text-neutral-600"
    translate="como.link-account.enter-auth-code"
  ></label>
  <input
    id="authCode"
    type="text"
    formControlName="authCode"
    (keyup.enter)="onSubmit()"
    class="flex w-full py-8xs px-5 bg-neutral-50 text-base font-base font-normal leading-5 border border-solid border-neutral-300"
    [class.border-error-700]="
      authCodeErrorMessage ||
      (form.controls?.authCode?.errors &&
        (form.controls?.authCode?.dirty || form.controls?.authCode?.touched))
    "
  />
  <ng-container
    *ngIf="
      form.controls?.authCode?.invalid &&
      (form.controls?.authCode?.dirty || form.controls?.authCode?.touched)
    "
  >
    <span
      class="block absolute bottom-1 text-xs font-base font-normal text-error-700"
      *ngIf="form.controls?.authCode?.errors"
      translate="como.link-account.errors.invalid-auth-code"
    ></span>
  </ng-container>
  <ng-container *ngIf="authCodeErrorMessage">
    <span
      class="text-xs font-base font-normal text-error-700"
      [translate]="authCodeErrorMessage"
    ></span>
  </ng-container>
</form>

<div class="flex flex-col gap-2">
  <div
    *ngIf="errorMessage"
    class="self-center"
  >
    <span
      class="text-xs font-base font-normal text-error-700"
      [translate]="errorMessage"
    ></span>
  </div>
  <div class="flex flex-col gap-8">
    <div class="w-full">
      <app-button-atom
        [iconUrl]="isValidating ? 'assets/images/spinner.svg' : ''"
        [disabled]="isValidating || this.form.invalid"
        size="M"
        [label]="'como.validate' | translate"
        type="primary"
        [full]="true"
        (click)="onSubmit()"
      ></app-button-atom>
    </div>
  </div>
</div>
