<app-product-category-atom
  *ngIf="category"
  [id]="category[categoryKeys.Id]"
  [heading]="category[categoryKeys.Name]"
  [description]="category[categoryKeys.Description] ?? ''"
  [categoryNameColor]="categoryNameColor"
  [categoryDescriptionColor]="categoryDescriptionColor"
></app-product-category-atom>

<app-product-push-list-atom
  displayContext="shop_page"
  [products]="products"
  [isOrderingAllowed]="isOrderingAllowed"
  [productNameColor]="productNameColor"
  [productDescriptionColor]="productDescriptionColor"
  [productPriceColor]="productPriceColor"
  [productImagePlaceholderColor]="productImagePlaceholderColor"
  [productBorderColor]="productBorderColor"
  [productBackgroundColor]="productBackgroundColor"
  [tagColors]="tagColors"
  (navigateTo)="onNavigateTo($event)"
></app-product-push-list-atom>
