<div class="flex items-center justify-center align-middle">
  <div class="mb-3 flex flex-col rounded-m bg-neutral-100 p-5 text-center md:mb-8 md:p-6">
    <p class="font-accent text-md font-bold text-neutral-800 md:text-29px">
      {{ error | translate }}
    </p>
  </div>
</div>
<div
  *ngIf="displayRetryButton"
  class="flex w-full justify-center pt-10"
>
  <app-button-atom
    class="mx-auto"
    [disabled]="isLoading"
    [icon]="isLoading ? '' : 'icon-repeat-circle'"
    [iconUrl]="isLoading ? 'assets/images/spinner.svg' : ''"
    [label]="'pay-at-table.error.retry' | translate"
    [size]="'M'"
    (click)="retry()"
  ></app-button-atom>
</div>
