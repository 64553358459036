<app-modal-filler-atom
  (click)="closeModal()"
  [index]="(index$ | async) ?? 0"
></app-modal-filler-atom>
<div
  class="fixed left-0 right-0 z-100 overflow-y-auto shadow"
  [ngClass]="
    (settingsSelector.selectDeviceIsMobileScreen | async)
      ? 'bottom-0 max-h-85-screen max-w-screen-sm'
      : 'flex justify-center items-center w-full h-full top-0 left-0'
  "
  [style.z-index]="((index$ | async) ?? 0) + 50"
>
  <div class="rounded-t-3xl bg-white p-5 md:p-7 lg:rounded-3xl">
    <div class="flex items-center justify-between">
      <h2 class="font-accent text-3xs font-bold">
        {{ 'personnal-informations-modal.title' | translate }}
      </h2>
    </div>
    <p class="mb-2 mt-5 font-base text-neutral-600">
      {{ 'personnal-informations-modal.phone' | translate }}
    </p>
    <div class="pb-6 leading-5 text-neutral-800">
      <app-input-country-phone
        id="phone"
        [phone]="userPhone"
        [selectedIso]="
          ((marketplaceCountry$ | async) ? (marketplaceCountry$ | async) : countryIso.France) ?? ''
        "
        [alternativeStyle]="true"
        [listOnTop]="true"
        (updatePhone)="onUpdatePhone($event)"
      ></app-input-country-phone>
    </div>
    <div class="flex pt-1">
      <div class="mr-2 w-full xm:w-1/2 xm:pr-1">
        <app-button-atom
          size="M"
          [label]="'personnal-informations-modal.cancel' | translate"
          color="primary"
          (click)="closeModal()"
          type="light"
          [full]="true"
        ></app-button-atom>
      </div>
      <div class="w-full xm:w-1/2 xm:pl-1 xm:pt-0">
        <app-button-atom
          size="M"
          [label]="'personnal-informations-modal.add' | translate"
          (click)="updateInformation()"
          type="primary"
          [full]="true"
        ></app-button-atom>
      </div>
    </div>
  </div>
</div>
