import {
  catchError,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { TitleCasePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, of, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Meta, Title } from '@angular/platform-browser';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

import { ShopKeys } from '@config/keys/shop.keys';
import { UrlParamsKeys } from '@config/keys/url-params.keys';
import { VenueAddressKeys, VenueKeys } from '@config/keys/venue.keys';

import { SlugUtils } from '@shared/utils/slug/slug.utils';
import { OrderTypeValues } from '@config/values/order.values';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';

import { CartStoreDispatcher } from '@common/dispatchers/cart.dispatcher';
import { BasketStoreDispatcher } from '@common/dispatchers/basket.dispatcher';

import { ShopStoreSelector } from '@common/selectors/shop.selector';
import { CartStoreSelector } from '@common/selectors/cart.selector';
import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { BasketStoreSelector } from '@common/selectors/basket.selector';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

import { DoodCartModel } from '@core/models/cart.model';
import { DoodShopModel } from '@core/models/shop.model';
import { ISimpleItem } from '@core/models/simple-item.model';
import {
  IContentBuilderFieldColor,
  IContentBuilderFieldImage,
} from '@core/models/content-builder-fields.model';

import { Paths } from '@config/paths.config';
import { GroupedOrderModalsAttributes } from '@config/modals/grouped-order-modals.config';

import { CartService } from '@core/services/cart/cart.service';
import { ShopsService } from '@core/services/shops/shops.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { BasketService } from '@core/services/basket/basket.service';
import { CanonicalService } from '@core/services/canonical/canonical.service';
import { EntityStatusService } from '@core/services/entity-status/entity-status.service';
import { EventTrackerService } from '@core/services/event-tracker/event-tracker.service';
import { OnSiteLocationsService } from '@core/services/on-site-locations/on-site-locations.service';
import { NavigationHistoryService } from '@core/services/navigation-history/navigation-history.service';

import { AlertModalComponent } from '@shared/modals/alert-modal/alert-modal.component';
import { CartOverviewModalComponent } from '@shared/modals/cart-overview-modal/cart-overview-modal.component';
import { AuthenticationModalComponent } from '@shared/modals/authentication-modal/authentication-modal.component';
import { CartParametersModalComponent } from '@shared/modals/cart-parameters-modal/cart-parameters-modal.component';
import { ShareGroupedOrderModalComponent } from '@shared/modals/share-grouped-order-modal/share-grouped-order-modal.component';
import { ShopStoreDispatcher } from '@common/dispatchers/shop.dispatcher';
import { AnalyticsService } from '@core/services/app/analytics.service';

@Component({
  selector: 'app-shop-page',
  templateUrl: './shop-page.component.html',
})
export class ShopPageComponent extends DestroyerBase implements OnInit, OnDestroy {
  @Input() body!: unknown[];
  @Input() metaDescription?: string;
  @Input() backgroundColor?: IContentBuilderFieldColor;
  @Input() backgroundImage?: IContentBuilderFieldImage;

  cart?: DoodCartModel;

  // private initialMeta?: string;
  private storeNames: string[] = [];
  private readonly destroyed$ = new Subject<boolean>();

  private userId$ = this.authSelector.selectUserId;

  constructor(
    private title: Title,
    private readonly meta: Meta,
    private cartSelector: CartStoreSelector,
    private shopSelector: ShopStoreSelector,
    private authSelector: AuthStoreSelector,
    private modalSelector: ModalStoreSelector,
    private readonly cartService: CartService,
    private cartDispatcher: CartStoreDispatcher,
    private shopDispatcher: ShopStoreDispatcher,
    private basketSelector: BasketStoreSelector,
    private readonly shopsService: ShopsService,
    private readonly modalsService: ModalsService,
    private readonly titleCasePipe: TitleCasePipe,
    private readonly basketService: BasketService,
    private readonly ordersService: OrdersService,
    private basketDispatcher: BasketStoreDispatcher,
    private settingsSelector: SettingsStoreSelector,
    private readonly activatedRoute: ActivatedRoute,
    private readonly canonicalService: CanonicalService,
    private readonly translateService: TranslateService,
    private marketplaceSelector: MarketplaceStoreSelector,
    private readonly entityStatusService: EntityStatusService,
    private readonly eventTrackerService: EventTrackerService,
    private readonly localizeRouterService: LocalizeRouterService,
    private readonly onSiteLocationsService: OnSiteLocationsService,
    private readonly navigationHistoryService: NavigationHistoryService,
    private readonly analyticsService: AnalyticsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.navigationHistoryService.pushStateFromActivatedRouteSnapshot(
      'shop',
      this.activatedRoute.snapshot,
    );

    this.activatedRoute.params
      .pipe(
        takeUntil(this.destroyed$),
        // Get the shopSlug from URL to handle overridden routes in the content builder
        map(
          () => this.activatedRoute.snapshot.url[this.activatedRoute.snapshot.url.length - 1].path,
        ),
        distinctUntilChanged(),
      )
      .subscribe(shopSlug => {
        const lastRoute =
          this.navigationHistoryService.getHistory()[
            this.navigationHistoryService.getHistory().length - 1
          ].route;
        const actualRoute =
          this.navigationHistoryService.getHistory()[
            this.navigationHistoryService.getHistory().length - 2
          ]?.route;

        let areDifferent = false;
        if (lastRoute && actualRoute) {
          areDifferent = this.areShopNamesDifferent(lastRoute, actualRoute);
        }

        if (
          this.navigationHistoryService.getHistory()[
            this.navigationHistoryService.getHistory().length - 1
          ].identifier !==
            this.navigationHistoryService.getHistory()[
              this.navigationHistoryService.getHistory().length - 2
            ]?.identifier ||
          areDifferent
        ) {
          this.loadShop(shopSlug);
        } else {
          this.modalsService.open(CartOverviewModalComponent.handle);
        }
      });

    combineLatest([
      this.authSelector.selectStatusToken,
      this.basketSelector.select.pipe(distinctUntilKeyChanged('joined')),
      this.userId$,
    ])
      .pipe(
        takeUntil(this.destroyed$),
        switchMap(([token, basket, userId]) => {
          if (!token && basket.joined) {
            this.modalsService.open(AuthenticationModalComponent.handle);
            return of(null);
          } else if (token && basket.id && basket.joined && basket.share_code && userId) {
            return this.basketService.getBasket$(basket.id, basket.share_code).pipe(
              catchError(() => {
                this.modalsService.open(
                  ShareGroupedOrderModalComponent.handle,
                  GroupedOrderModalsAttributes.NotValidOrder,
                );
                return of(null);
              }),
              switchMap(newBasket =>
                this.shopsService.loadShopById$(
                  newBasket?.shop.id ? newBasket.shop.id : newBasket?.shop,
                ),
              ),
            );
          }
          return of(null);
        }),
      )
      .subscribe();

    /* Create shared order link if user is authenticate and if he start share link" */
    combineLatest([
      this.authSelector.selectStatusToken,
      this.basketSelector.select.pipe(distinctUntilKeyChanged('created')),
      this.userId$,
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([token, basket, userId]) => {
        if (token && basket.created && !basket?.id && userId) {
          this.modalsService.open(
            ShareGroupedOrderModalComponent.handle,
            GroupedOrderModalsAttributes.ShareLink,
          );
        }
      });

    this.checkIfGroupedOrderExist();
  }

  ngOnDestroy(): void {
    this.modalsService.close(CartOverviewModalComponent.handle);
    this.modalsService.close(CartParametersModalComponent.handle);
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  loadShop(shopSlug: string): void {
    this.shopDispatcher.updateIsLoading(true);
    this.shopsService
      .loadShopBySlug$(shopSlug)
      .pipe(
        takeUntil(this.destroyed$),
        take(1),
        tap(shop => this.entityStatusService.getPreOrderWarningModal(shop)),
        tap(shop => this.setSEO(shop)),
        tap(shop => this.shopLoaded(shop)),
        // switchMap((shop) => this.shopSelector.selectShop(shop?.id)),
        // switchMap(() => this.ordersService.loadShopSlots$())
      )
      .subscribe();
  }

  private shopLoaded(shop: DoodShopModel): void {
    const whiteLabelFlag = this.activatedRoute.snapshot.queryParams.wl === '1';
    const defaultMarketplaceDomain = shop[ShopKeys.DefaultMarketplace]?.domains?.[0];

    if (whiteLabelFlag && defaultMarketplaceDomain) {
      // TODO: Add this to util/helper
      window.location.href = `https://${defaultMarketplaceDomain}/${Paths.Shops}/${SlugUtils.formatSlug(shop[ShopKeys.Type]?.name)}/${shop[ShopKeys.Slug]}`;
      return;
    }

    this.analyticsService.trackViewShop(shop);

    // this.shop = shop;
    this.shopDispatcher.insertActive(shop);
    this.shopDispatcher.updateIsLoading(false);
    this.eventTrackerService.dispatch(EventTrackerService.EventShopViewed, shop);

    this.cartService
      .getCartEntityDistributionModes$(this.marketplaceSelector.marketplace, shop[ShopKeys.Id])
      .pipe(take(1))
      .subscribe(allowedDistributionMode => {
        this.createOrSetActiveCart(shop, allowedDistributionMode);
      });

    this.modalsService.open(CartOverviewModalComponent.handle);

    /* Open grouped order join modal if queryParams exists OR open cart parameters modal */
    combineLatest([
      this.activatedRoute.queryParams,
      this.settingsSelector.selectParameters,
      this.basketSelector.select,
      this.userId$,
      this.modalSelector.selectModal('share-grouped-order-modal'),
      this.cartSelector.selectActive,
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([queryParams, settings, basket, userId, modal, cart]) => {
        const isModalNotValid = modal?.attribute;
        if (
          basket?.id &&
          queryParams.hasOwnProperty(UrlParamsKeys.JoinCartId) &&
          basket?.id !== queryParams?.[UrlParamsKeys.JoinCartId]
        ) {
          this.basketService.clearBasket();
          this.cartDispatcher.updateActive({
            type: undefined,
            products: [],
            on_site_location_id: '',
            on_site_location_name: '',
          });
        }
        if (
          queryParams &&
          queryParams.hasOwnProperty(UrlParamsKeys.JoinCartId) &&
          queryParams.hasOwnProperty(UrlParamsKeys.ShareCode) &&
          !basket.id &&
          !!!isModalNotValid
        ) {
          this.modalsService.open(
            ShareGroupedOrderModalComponent.handle,
            GroupedOrderModalsAttributes.JoinOrder,
          );

          this.basketDispatcher.updateBasket({
            id: queryParams[UrlParamsKeys.JoinCartId],
            share_code: queryParams[UrlParamsKeys.ShareCode],
          });
        } else if (
          !settings.is_distribution_mode_defined &&
          !queryParams.hasOwnProperty(UrlParamsKeys.JoinCartId) &&
          !cart?.is_user_selected_distribution_mode &&
          (!basket || !basket.user?.id || basket.user?.id === userId)
        ) {
          this.modalsService.open(CartParametersModalComponent.handle);
        }
      });

    this.cartSelector.selectActive
      .pipe(
        takeUntil(this.destroyed$),
        filter(cart => cart?.type === OrderTypeValues.OnSite),
        take(1),
        switchMap(() => this.onSiteLocationsService.loadOnSiteLocations$(shop[ShopKeys.Id])),
      )
      .subscribe();

    this.getShopSlots();
  }

  private createOrSetActiveCart(shop: DoodShopModel, allowedDistributionMode: ISimpleItem[]): void {
    const marketplace = this.marketplaceSelector.marketplace;
    const cartEntityHandle = this.cartService.calculateCartHandle(marketplace, shop);
    const parameters = this.settingsSelector.parameters;
    const allowedDistributionModeHandles = allowedDistributionMode.map(item => item.value);

    if (!allowedDistributionModeHandles.length) {
      this.modalsService.open(AlertModalComponent.handle);
      this.modalsService.setData(AlertModalComponent.handle, {
        message: this.translateService.instant('shop-page.service-not-available'),
      });
    }

    if (!cartEntityHandle) {
      console.error("Unable to create cart, cart handle can't be calculated");
      return;
    }

    // TODO!: Check if this is correct
    this.cartSelector
      .selectCart(cartEntityHandle)
      .pipe(take(1))
      .subscribe(cart => {
        this.cart = cart;

        if (!this.cart) {
          let orderType = parameters.distribution_mode;

          if (!allowedDistributionModeHandles.includes(orderType)) {
            orderType = allowedDistributionModeHandles[0];
          }

          this.cartService.createCartForActiveShop(orderType);
          this.cart = this.cartSelector.carts.find(cart => cart.handle === cartEntityHandle);
        }

        this.cartDispatcher.setActive(cartEntityHandle);

        const lastNavigationPage =
          this.navigationHistoryService.getHistory()[
            this.navigationHistoryService.getHistory().length - 2
          ];
        if (
          parameters.is_distribution_mode_defined &&
          this.cart?.type !== parameters.distribution_mode &&
          (lastNavigationPage?.identifier === 'shop-search' ||
            lastNavigationPage?.identifier === '/shops') &&
          allowedDistributionModeHandles.includes(parameters.distribution_mode)
        ) {
          this.modalsService.open(CartParametersModalComponent.handle);
          this.modalsService.setData(CartParametersModalComponent.handle, {
            text: this.translateService.instant('parameters.warning-existing-cart'),
          });
        }
      });
  }

  private setSEO(shop: DoodShopModel): void {
    const shopCity = this.titleCasePipe.transform(
      shop[ShopKeys.Venue][VenueKeys.Address]?.[VenueAddressKeys.City],
    );
    const shopCategory = this.titleCasePipe.transform(shop[ShopKeys.Type]?.name);
    const shopName = this.titleCasePipe.transform(shop[ShopKeys.Name]);
    const citySlug = SlugUtils.formatSlug(shopCity);
    const typeSlug = SlugUtils.formatSlug(shopCategory);
    const shopSlug = shop[ShopKeys.Slug];

    // this.initialMeta = this.meta.getTag('name=description')?.content;
    for (let i = 0; i < 3; i++) {
      if (shop[ShopKeys.Store][i]) {
        this.storeNames.push(shop[ShopKeys.Store][i].name.toLocaleLowerCase());
      }
    }
    const seoTitle = `${shopCategory} ${shopName} à ${shopCity} - Commande en ligne ${this.storeNames.join(
      ', ',
    )}...`;
    this.title.setTitle(seoTitle);
    this.meta.updateTag({
      name: 'description',
      content: this.metaDescription ? this.metaDescription : seoTitle,
    });

    const canonicalRoute = this.localizeRouterService.translateRoute([
      `/${citySlug}/${typeSlug}/${shopSlug}`,
    ]);
    this.canonicalService.createCanonicalLink(canonicalRoute[0]);
  }

  private checkIfGroupedOrderExist(): void {
    const basket = this.basketSelector.basket;
    const shopActive = this.shopSelector.active;
    const cartType = this.cartSelector.active?.type;

    if (
      !basket ||
      (typeof basket.shop !== 'string' && basket.shop?.id !== (!shopActive || shopActive.id)) ||
      basket.type !== cartType
    ) {
      return;
    }

    this.basketService.getBasket$(basket.id, basket?.share_code).pipe(take(1)).subscribe();
  }

  private getShopSlots(): void {
    this.ordersService.loadShopSlots$().pipe(take(1)).subscribe();
  }

  private areShopNamesDifferent(route1: string, route2: string): boolean {
    const parts1 = route1.split('/');
    const parts2 = route2.split('/');

    const shopName1 = parts1[parts1.length - 1];
    const shopName2 = parts2[parts2.length - 1];

    return shopName1 !== shopName2;
  }

  getBackgroundStyles() {
    return {
      'background-image': this.backgroundImage ? `url(${this.backgroundImage.url})` : null,
      'background-size': this.backgroundImage ? 'cover' : null,
    };
  }
}
