import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-simple-small-hero-atom',
  templateUrl: './simple-small-hero-atom.component.html',
})
export class SimpleSmallHeroAtomComponent {
  @Input() heading!: string;
  @Input() description!: string;
  @Input() category!: string;
  @Input() titleSize: 'XS' | 'S' | 'M' | 'L' = 'M';
}
