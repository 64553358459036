<div
  class="min-h-screen"
  *ngIf="order$ | async as order"
>
  <h1 class="hidden py-12 text-center font-accent text-5xl font-bold lg:block">
    {{ 'order-details.title' | translate }}
  </h1>
  <div
    class="mb-6 flex h-30 items-center bg-primary-600 md:h-40 md:justify-center lg:mx-auto lg:w-8/12 lg:rounded-lg"
  >
    <i class="icon icon-shop pl-6 pr-4 text-5xl text-white md:pr-5 md:text-6.5xl"></i>
    <div class="flex flex-col font-accent">
      <div class="text-28px font-bold leading-8 text-white md:text-4xl lg:leading-11">
        {{ order[orderKeys.Shop]?.name }}
      </div>
      <div
        *ngIf="
          orderReadyAt &&
          order.type !== orderTypeValues.OnSite &&
          order.type !== orderTypeValues.EatIn
        "
        class="text-sm text-white md:text-md"
      >
        {{ orderReadyAt }}
      </div>
    </div>
  </div>
  <div class="mx-auto max-w-screen-sm lg:hidden">
    <div class="mb-5 px-5">
      <div class="rounded-lg border border-neutral-300">
        <app-order-status-atom-v2
          [orderStatus]="order[orderKeys.Status]"
          [shopName]="order[orderKeys.Shop]?.name ?? ''"
          [orderNumber]="order[orderKeys.Number]"
          [order]="order"
          [displayDetailsButton]="false"
          [locationName]="(onSiteLocationName$ | async) ?? ''"
        ></app-order-status-atom-v2>
      </div>
    </div>
    <div *ngIf="order[orderKeys.LineItems]">
      <div class="px-5 py-4">
        <h1 class="font-accent text-2xl font-bold text-neutral-800 lg:text-3xl">
          {{ 'order-details.overview' | translate }}
        </h1>
      </div>
      <div class="mb-4">
        <app-detail-order-atom
          [lineItems]="asDoodLineItemModel(order[orderKeys.LineItems])"
          [totalAmount]="order[orderKeys.FinalPrice]"
          [tips]="order[orderKeys.Tip]?.amount ?? 0"
          [paidAmount]="order[orderKeys.PaidAmount] ?? 0"
        ></app-detail-order-atom>
      </div>
      <div>
        <app-detail-order-voucher-block
          *ngIf="order[orderKeys.Coupons]"
        ></app-detail-order-voucher-block>
      </div>
    </div>
    <div class="p-5">
      <app-button-atom
        [full]="true"
        [type]="'primary'"
        [size]="'M'"
        [label]="'order-details.new-order' | translate"
        [icon]="'icon-redo-arrow'"
        (click)="renewOrder(order)"
      ></app-button-atom>
    </div>
  </div>
  <div class="mx-auto hidden grid-cols-2 gap-8 lg:grid lg:w-8/12">
    <div
      class="rounded-lg bg-neutral-100"
      *ngIf="order[orderKeys.LineItems]"
    >
      <div class="px-5 py-4 lg:p-6">
        <h1 class="font-accent text-2xl font-bold text-neutral-800 lg:text-3xl">
          {{ 'order-details.overview' | translate }}
        </h1>
      </div>
      <div>
        <app-detail-order-atom
          [lineItems]="asDoodLineItemModel(order[orderKeys.LineItems])"
          [totalAmount]="order[orderKeys.FinalPrice]"
          [tips]="order[orderKeys.Tip]?.amount ?? 0"
          [paidAmount]="order[orderKeys.PaidAmount] ?? 0"
        ></app-detail-order-atom>
      </div>
      <div class="px-5 pb-5">
        <app-button-atom
          [full]="true"
          [type]="'primary'"
          [size]="'M'"
          [label]="'order-details.new-order' | translate"
          [icon]="'icon-redo-arrow'"
          (click)="renewOrder(order)"
        ></app-button-atom>
      </div>
    </div>
    <div>
      <div class="mb-8 rounded-lg border border-neutral-300">
        <app-order-status-atom-v2
          [displayDetailsButton]="false"
          [orderStatus]="order[orderKeys.Status]"
          [shopName]="order[orderKeys.Shop]?.name ?? ''"
          [orderNumber]="order[orderKeys.Number]"
          [order]="order"
        ></app-order-status-atom-v2>
      </div>
      <div>
        <app-detail-order-voucher-block
          *ngIf="order[orderKeys.Coupons]"
        ></app-detail-order-voucher-block>
      </div>
    </div>
  </div>
</div>
