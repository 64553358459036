<div
  class="flex flex-col gap-5 ml-5"
  [style.color]="textColor | contentBuilderColor"
>
  <div
    class="flex w-full cursor-pointer items-center gap-2.5"
    (click)="back()"
  >
    <i class="text-xs rotate-90 icon icon-chevron-down"></i>
    <div class="text-2xl font-bold">Retour</div>
  </div>
  <app-block-list [blockIdList]="body"></app-block-list>
</div>
