<div>
  <div class="fixed h-full top-0 bottom-0 left-0 right-0 bg-white z-50 flex flex-col">
    <div
      class="flex flex-col items-center justify-center mx-auto p-8 text-center"
      style="flex-grow: 1"
    >
      <h1
        class="font-accent font-bold text-2xl pb-6 text-neutral-800 font-bold"
        *ngIf="!displayPaymentLoadingScreen"
      >
        Création de la commande&hellip;
      </h1>

      <h1
        class="font-accent font-bold text-2xl pb-6 text-neutral-800 font-bold"
        *ngIf="displayPaymentLoadingScreen"
      >
        Vérification du paiement en cours&hellip;
      </h1>

      <p
        *ngIf="longTimeSinceVisibleAgain"
        class="pb-6 text-base"
      >
        Si le paiement n'a pas été finalisé ou a échoué sur l'écran précédent, vous pouvez annuler
        la commande en cliquant sur le bouton ci-dessous.<br /><br />
        <b
          >Cependant, si le paiement a été confirmé, veuillez ne pas annuler la commande et attendre
          la confirmation de paiement.</b
        >
      </p>

      <app-button-atom
        size="M"
        label="Annuler"
        color="primary"
        (click)="cancelOrder()"
        icon="icon-cross"
        type="light"
        [full]="true"
        *ngIf="!displayPaymentLoadingScreen || longTimeSinceVisibleAgain"
      ></app-button-atom>
    </div>
  </div>
</div>
