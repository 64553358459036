<div
  class="flex flex-col gap-14"
  [style.color]="color | contentBuilderColor"
>
  <div
    class="relative max-w-screen-sm mx-auto border-4 rounded-md"
    [style.borderColor]="color | contentBuilderColor"
  >
    <input
      type="text"
      [value]="pagerValue.padEnd(pagerSize)"
      readonly
      class="w-full text-6xl font-bold text-center bg-transparent border-none cursor-not-allowed focus:ring-0"
      [style.color]="color | contentBuilderColor"
      (change)="onInputChange()"
    />
  </div>

  <div class="grid grid-cols-3 gap-6 m-auto max-w-72">
    <ng-container *ngFor="let item of [].constructor(9); let index = index">
      <div
        class="flex items-center justify-center w-20 h-20 font-bold border-4 rounded-full"
        [style.borderColor]="color | contentBuilderColor"
        (click)="onNumberClick(index + 1)"
      >
        <p class="text-3xl">{{ index + 1 }}</p>
      </div>
    </ng-container>

    <div
      class="flex items-center justify-center w-20 h-20 col-start-2 font-bold border-4 rounded-full"
      [style.borderColor]="color | contentBuilderColor"
      (click)="onNumberClick('0')"
    >
      <p class="text-3xl">0</p>
    </div>

    <div
      class="flex items-center justify-center w-20 h-20 font-bold border-4 rounded-full"
      [style.borderColor]="color | contentBuilderColor"
      (click)="onDeleteClick()"
    >
      <p class="text-3xl">x</p>
    </div>
  </div>
</div>
