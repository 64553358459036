import { ReducerTypes, ActionCreator, on } from '@ngrx/store';

import { createHydratedStorePiece } from '@store/_abstract/hydrated';

import {
  OLS_STORE_SETTINGS_INITIAL_STATE,
  OnSiteLocationState,
  OSL_ENTITY_STORE_ADAPTER,
  OSL_STORE_INITIAL_STATE,
  OSL_STORE_KEY,
} from './on-site-location.state';
import { OSL_STORE_HYDRATION_ACTIONS, OSL_STORE_LOCAL_ACTIONS } from './on-site-location.action';

export const OSL_STORE_API_PIECE: ReducerTypes<OnSiteLocationState, readonly ActionCreator[]>[] =
  [];

export const OSL_STORE_LOCAL_PIECE: ReducerTypes<OnSiteLocationState, readonly ActionCreator[]>[] =
  [
    // Multiple
    on(OSL_STORE_LOCAL_ACTIONS.addLocations, (state, { locations }): OnSiteLocationState => {
      return OSL_ENTITY_STORE_ADAPTER.addMany(locations, state);
    }),
    on(OSL_STORE_LOCAL_ACTIONS.removeLocations, (state, { ids }): OnSiteLocationState => {
      return OSL_ENTITY_STORE_ADAPTER.removeMany(ids, state);
    }),
    on(OSL_STORE_LOCAL_ACTIONS.upsertLocations, (state, { locations }): OnSiteLocationState => {
      return OSL_ENTITY_STORE_ADAPTER.upsertMany(locations, state);
    }),
    on(OSL_STORE_LOCAL_ACTIONS.setLocations, (state, { locations }): OnSiteLocationState => {
      return OSL_ENTITY_STORE_ADAPTER.setAll(locations, state);
    }),
    on(OSL_STORE_LOCAL_ACTIONS.resetLocations, (state): OnSiteLocationState => {
      return OSL_ENTITY_STORE_ADAPTER.removeAll(state);
    }),
    // Single
    on(OSL_STORE_LOCAL_ACTIONS.addLocation, (state, { location }): OnSiteLocationState => {
      return OSL_ENTITY_STORE_ADAPTER.addOne(location, state);
    }),
    on(OSL_STORE_LOCAL_ACTIONS.removeLocation, (state, { id }): OnSiteLocationState => {
      return OSL_ENTITY_STORE_ADAPTER.removeOne(id, state);
    }),
    on(OSL_STORE_LOCAL_ACTIONS.upsertLocation, (state, { location }): OnSiteLocationState => {
      return OSL_ENTITY_STORE_ADAPTER.upsertOne(location, state);
    }),
    on(OSL_STORE_LOCAL_ACTIONS.updateLocation, (state, { location }): OnSiteLocationState => {
      return OSL_ENTITY_STORE_ADAPTER.updateOne(location, state);
    }),
    on(OSL_STORE_LOCAL_ACTIONS.mapLocation, (state, { location }): OnSiteLocationState => {
      return OSL_ENTITY_STORE_ADAPTER.mapOne(location, state);
    }),
    // Active
    on(OSL_STORE_LOCAL_ACTIONS.updateActive, (state, { active }): OnSiteLocationState => {
      return { ...state, active };
    }),
    on(OSL_STORE_LOCAL_ACTIONS.resetActive, (state): OnSiteLocationState => {
      return { ...state, active: null };
    }),
    // Settings
    on(OSL_STORE_LOCAL_ACTIONS.updateSettings, (state, { settings }): OnSiteLocationState => {
      return { ...state, settings: { ...state.settings, ...settings } };
    }),
    on(OSL_STORE_LOCAL_ACTIONS.resetSettings, (state): OnSiteLocationState => {
      return { ...state, settings: OLS_STORE_SETTINGS_INITIAL_STATE };
    }),
    // Status
    on(OSL_STORE_LOCAL_ACTIONS.updateIsLoaded, (state, { isLoaded }): OnSiteLocationState => {
      return { ...state, isLoaded };
    }),
    on(OSL_STORE_LOCAL_ACTIONS.updateIsLoading, (state, { isLoading }): OnSiteLocationState => {
      return { ...state, isLoading };
    }),
    // Error
    on(OSL_STORE_LOCAL_ACTIONS.updateError, (state, { error }): OnSiteLocationState => {
      return { ...state, error };
    }),
    on(OSL_STORE_LOCAL_ACTIONS.resetError, (state): OnSiteLocationState => {
      return { ...state, error: null };
    }),
    // Misc
    on(OSL_STORE_LOCAL_ACTIONS.reset, (): OnSiteLocationState => {
      return OSL_STORE_INITIAL_STATE;
    }),
  ];

export const OSL_STORE_HYDRATION_PIECE = createHydratedStorePiece<OnSiteLocationState>(
  OSL_STORE_KEY,
  OSL_STORE_HYDRATION_ACTIONS,
);
