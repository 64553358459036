import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import {
  BasketCartState,
  BASKET_STORE_LOCAL_ACTIONS,
  BASKET_STORE_HYDRATION_ACTIONS,
} from '@store/basket';

@Injectable({ providedIn: 'root' })
export class BasketStoreDispatcher {
  constructor(private store: Store) {}

  updateBasket(changes: Partial<BasketCartState>): void {
    this.store.dispatch(BASKET_STORE_LOCAL_ACTIONS.updateBasket({ changes }));
  }

  reset(): void {
    this.store.dispatch(BASKET_STORE_LOCAL_ACTIONS.reset());
  }

  clear(): void {
    this.store.dispatch(BASKET_STORE_HYDRATION_ACTIONS.clear());
  }
}
