import { Capacitor } from '@capacitor/core';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-big-footer-body-download-apps-block',
  templateUrl: './big-footer-body-download-apps-block.component.html',
})
export class BigFooterBodyDownloadAppsBlockComponent implements OnInit {
  @Input()
  heading!: string;
  @Input()
  appStoreLink!: string;
  @Input()
  playStoreLink!: string;

  isNative = false;

  constructor() {}

  ngOnInit(): void {
    this.isNative = Capacitor.isNativePlatform();
  }
}
