import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { Injectable } from '@angular/core';
import isToday from 'dayjs/plugin/isToday';
import { combineLatest, Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { skipWhile, switchMap, take, tap } from 'rxjs/operators';

import { DoodShopModel, IDistributionMode } from '@core/models/shop.model';
import { DoodEntityModel, ScheduleDateTime } from '@core/models/entity.model';

import { SlugUtils } from '@shared/utils/slug/slug.utils';
import { DateUtils } from '@shared/utils/date/date.utils';

import { CartKeys } from '@config/keys/cart.keys';
import { EntityKeys } from '@config/keys/entity.keys';
import { MarketplaceKeys, ShopKeys } from '@config/keys/shop.keys';

import { Paths } from '@config/paths.config';
import { ProductUtils } from '@app/utilities/product.util';
import { MarketplaceCartScopeValues } from '@config/values/marketplace.values';

import { ShopStoreDispatcher } from '@common/dispatchers/shop.dispatcher';
import { ProductStoreDispatcher } from '@common/dispatchers/product.dispatcher';

import { CartStoreSelector } from '@common/selectors/cart.selector';
import { ShopStoreSelector } from '@common/selectors/shop.selector';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

import { ShopsApiService } from '@core/services/api/shops/shops-api.service';
import { ShopAvailableAtService } from '@core/services/shops/shop-available-at.service';
import { OrderTypeService } from '@core/services/order-type/order-type.service';

@Injectable({
  providedIn: 'root',
})
export class ShopsService {
  constructor(
    private cartSelector: CartStoreSelector,
    private shopSelector: ShopStoreSelector,
    private shopDispatcher: ShopStoreDispatcher,
    private settingsSelector: SettingsStoreSelector,
    private productDispatcher: ProductStoreDispatcher,
    private readonly shopsApiService: ShopsApiService,
    private readonly translateService: TranslateService,
    private marketplaceSelector: MarketplaceStoreSelector,
    private readonly shopAvailableAtService: ShopAvailableAtService,
    private readonly orderTypeService: OrderTypeService,
  ) {
    dayjs.extend(isToday);
  }

  loadShopBySlug$(shopSlug: string, forceOrderType?: string): Observable<DoodShopModel> {
    return combineLatest([
      this.cartSelector.selectActive,
      this.settingsSelector.selectParameters,
      this.marketplaceSelector.selectMarketplace,
    ]).pipe(
      take(1),
      switchMap(([cart, parameters, marketplace]) => {
        let orderType;

        if (
          cart &&
          cart[CartKeys.Marketplace] === marketplace[MarketplaceKeys.Id] &&
          marketplace[MarketplaceKeys.CartScope] === MarketplaceCartScopeValues.Marketplace
        ) {
          orderType = cart[CartKeys.Type];
        }

        if (cart && cart[CartKeys.ShopSlug] === shopSlug) {
          orderType = cart[CartKeys.Type];
        }

        if (!orderType && parameters.distribution_mode && parameters.is_distribution_mode_defined) {
          orderType = parameters.distribution_mode;
        }

        if (forceOrderType) {
          orderType = forceOrderType;
        }

        if (!orderType) {
          return combineLatest([
            this.shopsApiService.getShopBySlug$(shopSlug, marketplace[MarketplaceKeys.Id]),
            of(null),
          ]);
        }
        return combineLatest([
          this.shopsApiService.getShopBySlug$(
            shopSlug,
            marketplace[MarketplaceKeys.Id],
            orderType,
            cart?.[CartKeys.WantedAt],
          ),
          of(orderType),
        ]);
      }),
      switchMap(([shop, loadedOrderType]) => {
        if (!loadedOrderType) {
          const marketplace = this.marketplaceSelector.marketplace;
          // @fixme: send order type, not distribution mode
          const orderType =
            marketplace[MarketplaceKeys.CartScope] === 'SHOP'
              ? shop[ShopKeys.DistributionModes]?.filter(mode => mode.enabled)?.[0]?.type
              : marketplace[ShopKeys.DistributionModes]?.filter(mode => mode.enabled)?.[0]?.type;

          return this.shopsApiService.getShopBySlug$(
            shopSlug,
            marketplace[MarketplaceKeys.Id],
            orderType,
          );
        } else {
          shop[ShopKeys.LoadedOrderType] = loadedOrderType;
          return of(shop);
        }
      }),
      tap(shop => {
        this.shopDispatcher.upsertOne(shop);
      }),
      tap(shop => {
        const products = ProductUtils.denormalizeShopProducts(shop);
        this.productDispatcher.upsertMany(products);
      }),
    );
  }

  loadShopById$(shopId: string | null): Observable<DoodShopModel | undefined> {
    if (!shopId) {
      return of(undefined);
    }

    return combineLatest([
      this.cartSelector.selectActive,
      this.marketplaceSelector.selectMarketplaceId,
    ]).pipe(
      take(1),
      switchMap(([cart, marketplaceId]) =>
        this.shopsApiService
          .getShopById$(shopId, marketplaceId, cart?.[CartKeys.Type], cart?.[CartKeys.WantedAt])
          .pipe(
            tap(shop => {
              this.shopDispatcher.upsertOne(shop);
            }),
            tap(shop => {
              const products = ProductUtils.denormalizeShopProducts(shop);
              this.productDispatcher.upsertMany(products);
            }),
          ),
      ),
    );
  }

  selectById$(shopId?: string): Observable<DoodShopModel | undefined> {
    if (!shopId) {
      return of(undefined);
    }
    return this.shopSelector.selectShop(shopId).pipe(skipWhile(s => !s));
  }

  updateShopDistributionMode$(shopSlug: string): Observable<DoodShopModel> {
    this.shopDispatcher.updateIsLoading(true);
    return this.loadShopBySlug$(shopSlug).pipe(
      take(1),
      tap(shop => {
        this.shopDispatcher.insertActive(shop);
        this.shopDispatcher.updateIsLoading(false);
      }),
    );
  }

  getShopScheduleOpensAtDate(
    shop?: DoodShopModel | DoodEntityModel,
    date?: Date,
    orderType?: string,
  ): ScheduleDateTime | undefined {
    if (!shop?.[ShopKeys.Schedules]) {
      return undefined;
    }

    if (!date) {
      date = new Date();
    }

    const schedulesDateTime: ScheduleDateTime[] = [];
    let openedSchedules = shop?.schedules
      ?.filter(shopSchedule => shopSchedule.opened)
      ?.sort((a, b) => a.start - b.start);
    if (orderType) {
      const filterDistributionModes =
        this.orderTypeService.convertOrderTypeToDistributionModes(orderType);
      openedSchedules = openedSchedules?.filter(
        s =>
          s?.allowed_distribution_modes === null ||
          s?.allowed_distribution_modes?.some(mode =>
            filterDistributionModes.includes(mode.distribution_mode),
          ),
      );
    }
    openedSchedules?.forEach(s => {
      schedulesDateTime.push({
        schedule: s,
        startDateTime: this.minutesFromStartOfWeekToDateTime(s.start),
        endDateTime: this.minutesFromStartOfWeekToDateTime(s.end),
      });
    });
    openedSchedules?.forEach(s => {
      schedulesDateTime.push({
        schedule: s,
        startDateTime: this.minutesFromStartOfWeekToDateTime(s.start).add(1, 'week'),
        endDateTime: this.minutesFromStartOfWeekToDateTime(s.end).add(1, 'week'),
      });
    });

    const schedule = schedulesDateTime.find(sdt =>
      sdt.endDateTime.isAfter(DateUtils.dayjsInMarketplaceTimezone(date)),
    );
    if (!schedule) {
      return undefined;
    }

    return schedule;
  }

  getEnabledShopDistributionModeFromOrderType(
    shop?: DoodShopModel | DoodEntityModel,
    orderType?: string,
  ): IDistributionMode | null {
    if (!orderType || !shop || !shop.distribution_modes) {
      return null;
    }

    const possibleDistributionModesFromOrderType =
      this.orderTypeService.convertOrderTypeToDistributionModes(orderType);
    for (const distributionMode of shop.distribution_modes) {
      if (
        distributionMode.enabled &&
        possibleDistributionModesFromOrderType.includes(distributionMode.type)
      ) {
        return distributionMode;
      }
    }
    return null;
  }

  getClosedMessage(
    entity?: DoodShopModel | DoodEntityModel,
    wantedAt: Date | null = null,
    orderType?: string,
  ): string {
    let compareWithDate = wantedAt;
    if (!compareWithDate) {
      compareWithDate = new Date();
    }
    const distributionMode = this.getEnabledShopDistributionModeFromOrderType(entity, orderType);
    const compareWith = DateUtils.dayjsInMarketplaceTimezone(compareWithDate).add(2, 'second');
    const dateTimeAvailableAt = DateUtils.dayjsInMarketplaceTimezone(entity?.available_at);
    const available =
      entity?.available ||
      dateTimeAvailableAt.isBefore(compareWith) ||
      dateTimeAvailableAt.isSame(compareWith);
    const openSchedule = this.getShopScheduleOpensAtDate(entity, compareWithDate, orderType);
    const openScheduleStartTime = openSchedule?.startDateTime;
    const openScheduleEndTime = openSchedule?.endDateTime;
    const isNotAvailable = !available;
    const scheduleIsClosed = openScheduleStartTime?.isAfter(compareWith);
    const scheduleOpensToday = available && openScheduleStartTime?.isSame(compareWith, 'day');
    let orderingWallTime = openScheduleEndTime;
    let label = '';

    if (distributionMode?.preparation_strategy.type === 'fixedDuration') {
      orderingWallTime = orderingWallTime?.subtract(
        distributionMode?.preparation_strategy.duration,
        'seconds',
      );
    }
    const orderingWallTimeIsBeforeCompareDateTime = orderingWallTime?.isBefore(compareWith);
    const availableAtDuration = this.shopAvailableAtService.getAvailableAtDisplayString(
      entity as DoodShopModel,
    );

    if (availableAtDuration) {
      label = this.translateService.instant('shop-page.back-in-duration', {
        duration: availableAtDuration,
      });
    } else if (orderingWallTimeIsBeforeCompareDateTime) {
      label = this.translateService.instant('shop-page.schedules.closed');
    } else if (!openScheduleStartTime || isNotAvailable) {
      if (wantedAt) {
        label = this.translateService.instant('shop-page.schedules.closed-at-selected-time');
      } else {
        label = this.translateService.instant('shop-page.schedules.closed');
      }
    } else if (scheduleIsClosed && scheduleOpensToday) {
      label = `${this.translateService.instant('shop-page.schedules.open-at')} ${openScheduleStartTime?.format(this.translateService.instant('shop-page.schedules.format'))}`;
    } else if (scheduleIsClosed && !scheduleOpensToday) {
      if (wantedAt) {
        label = this.translateService.instant('shop-page.schedules.closed-at-selected-time');
      } else {
        label = this.translateService.instant('shop-page.schedules.closed-rest-of-day');
      }
    }
    return label;
  }

  getUrl(shop: DoodShopModel): string {
    return `${Paths.Shops}/${SlugUtils.formatSlug(shop[EntityKeys.Type][EntityKeys.Name])}/${shop[EntityKeys.Slug]}`;
  }

  private minutesFromStartOfWeekToDateTime(minutes: number): Dayjs {
    const endMinute = minutes % 60;
    const endRemainingHours = (minutes - endMinute) / 60;
    const endHour = endRemainingHours % 24;
    const endDay = (minutes - endMinute - endHour * 60) / (60 * 24);
    return DateUtils.dayjsInMarketplaceTimezone()
      .day(endDay)
      .hour(endHour)
      .minute(endMinute)
      .second(0)
      .millisecond(0);
  }
}
