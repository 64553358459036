<div class="w-full md:mx-auto md:max-w-md">
  <div
    *ngIf="amountLeft !== 0; else loading"
    class="mt-3 px-5"
  >
    <div class="mt-6 flex justify-between pb-3">
      <span class="font-base font-bold text-neutral-800">
        {{ 'group-payments.total' | translate }}
      </span>
      <app-price-atom
        [amount]="totalAmount"
        [format]="true"
      ></app-price-atom>
    </div>
    <div
      *ngIf="(transactions$ | async)?.length === 0"
      class="mt-28"
    >
      <p>
        {{ 'group-payments.empty-list-message' | translate }}
      </p>
      <p>
        {{ 'group-payments.empty-list-message-2' | translate }}
      </p>
    </div>
    <ng-container *ngIf="transactions$ | async as groupedTransactions">
      <ul *ngIf="groupedTransactions.length > 0">
        <li
          class="pt-7"
          *ngFor="let groupedTransaction of groupedTransactions"
        >
          <p class="pb-3 text-sm text-neutral-600">
            <ng-container *ngIf="isString(groupedTransaction.user); else fullname">{{
              'basket.anonymous' | translate
            }}</ng-container>
            <ng-template #fullname>
              <ng-container *ngIf="!isString(groupedTransaction.user)">
                {{ groupedTransaction.user.firstname }}
                {{ groupedTransaction.user.lastname }}
              </ng-container>
            </ng-template>
            <ng-container
              *ngIf="
                (userId$ | async) ===
                (!isString(groupedTransaction.user) && groupedTransaction.user.id)
              "
            >
              ({{ 'basket.you' | translate }})
            </ng-container>
          </p>
          <div
            class="flex justify-between pb-2"
            *ngFor="let transaction of groupedTransaction.transactions"
          >
            {{ transaction.payment_method | paymentMethodLabel | translate }}
            <app-price-atom
              [amount]="-transaction.amount"
              [bold]="false"
              [format]="true"
            ></app-price-atom>
          </div>
        </li>
      </ul>
      <div
        *ngIf="groupedTransactions.length > 0"
        class="w-full pt-8 text-center text-sm text-neutral-600"
        translate="group-payments.automatic-redirect-notice"
      ></div>
    </ng-container>
    <div class="hidden md:block">
      <div class="flex justify-between pb-4">
        <span class="font-base font-bold text-neutral-800">
          {{ 'group-payments.amount-left' | translate }}
        </span>
        <app-price-atom
          [amount]="amountLeft"
          [format]="true"
        ></app-price-atom>
      </div>
      <div>
        <app-button-atom
          [disabled]="amountLeft === 0"
          [label]="'group-payments.add-payment' | translate"
          [type]="'primary'"
          [size]="'L'"
          (click)="addPayment()"
        ></app-button-atom>
      </div>
    </div>
  </div>

  <ng-template #loading>
    <div class="mt-3 px-5">
      <div
        class="relative mb-2 animate-pulse overflow-hidden rounded-lg bg-neutral-100 py-1.5"
      ></div>
      <div class="mb- flex w-full justify-between">
        <p class="mb-1.5 w-4/12 animate-pulse bg-neutral-100 py-1.5"></p>
        <p class="w-2/12 animate-pulse bg-neutral-100 py-1"></p>
      </div>
    </div>
    <div class="mt-28 px-5">
      <p class="w-full animate-pulse bg-neutral-50 py-1"></p>
      <p class="w-2/12 animate-pulse bg-neutral-50 py-1"></p>
      <div class="mb- flex w-full justify-between">
        <p class="mb-1.5 w-4/12 animate-pulse bg-neutral-100 py-1.5"></p>
        <p class="w-2/12 animate-pulse bg-neutral-100 py-1"></p>
      </div>
    </div>
  </ng-template>
</div>

<div class="fixed bottom-0 left-0 right-0 z-30 bg-white shadow shadow-top md:hidden">
  <div class="mx-auto w-full p-4 pt-4.5 align-middle">
    <div class="flex justify-between pb-4">
      <span class="font-base font-bold text-neutral-800">
        {{ 'group-payments.amount-left' | translate }}
      </span>
      <img
        *ngIf="!isLoaded || amountLeft === 0"
        src="assets/images/spinner.svg"
        alt=""
        class="mr-2 p-2"
      />
      <app-price-atom
        *ngIf="isLoaded && amountLeft > 0"
        [amount]="amountLeft"
        [format]="true"
      ></app-price-atom>
    </div>
    <div>
      <app-button-atom
        [disabled]="amountLeft === 0"
        [label]="'group-payments.add-payment' | translate"
        [type]="'primary'"
        [size]="'L'"
        (click)="addPayment()"
      ></app-button-atom>
    </div>
  </div>
</div>
