<div class="h-full">
  <a
    [link]="getShopUrl(entity)"
    [class.cursor-default]="!entity?.available"
  >
    @if (image) {
      <app-shop-card-logo-atom
        [entity]="entity"
        [isMarketplaceAvailable]="isMarketplaceAvailable"
        [shopImage]="shopImage ?? ''"
        [uploadedImage]="image"
        [entityName]="entityName"
        [entityCategory]="entityCategory"
        [displayEntityImage]="displayEntityImage"
        [displayEntityCategory]="displayEntityCategory"
      >
      </app-shop-card-logo-atom>
    }
  </a>
</div>
