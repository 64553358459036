import { Component } from '@angular/core';
import { PaymentMethodHandles } from '@config/payment-methods.config';

@Component({
  selector: 'app-payment-panel-stripe-bancontact',
  templateUrl: './payment-panel-stripe-bancontact.component.html',
})
export class PaymentPanelStripeBancontactComponent {
  paymentMethodHandles = PaymentMethodHandles;
}
