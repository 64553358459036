<div
  class="mx-auto pb-5"
  [style.backgroundColor]="backgroundColor | contentBuilderColor"
>
  @let vm = vm$ | async;
  <ng-container *ngIf="vm && !vm?.isShopLoading && vm.shop; else loading">
    <ng-container *ngIf="!isOrderingAllowed">
      <div class="my-5 px-5 py-5">
        <div class="rounded rounded-md bg-highlight-50 p-4 font-accent">
          <p>
            {{ vm.shop.name }}
            {{ 'shop-page.not-available' | translate }}
          </p>
          <p
            *ngIf="availableAtMessage"
            class="mt-4"
          >
            {{ 'shop-page.back-in-duration' | translate: { duration: availableAtMessage } }}
          </p>
        </div>
        <div
          *ngIf="shouldBeAvailableNow"
          class="mt-2 flex w-full flex-row justify-center"
        >
          <app-button-atom
            type="light"
            size="S"
            [label]="'shop-page.reload-page-button' | translate"
            (clickEventEmitter)="reloadPage()"
          ></app-button-atom>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="isOrderingAllowed && categoriesWithProducts">
      <div
        *ngIf="categoriesWithProducts.length"
        appScrollSpy
        (sectionChange)="changeTab($event)"
        [ids]="idsCategories"
      >
        <div
          class="sticky z-10"
          #categoryTabsContainer
          [style.top]="categoryListTop + 'px'"
          [ngClass]="isBurgerNavigationOpen ? 'fixed z-120' : 'sticky'"
        >
          <app-tab-list-atom
            [items]="tabCategories"
            [activeItem]="tab"
            [displayBurgerNavigation]="displayBurgerMenuCategories"
            [isNotched]="isNotched"
            [categoriesBackgroundColor]="categoriesBackgroundColor"
            [categoriesActiveColor]="categoriesActiveColor"
            [categoriesInactiveColor]="categoriesInactiveColor"
            [burgerBackgroundColor]="burgerBackgroundColor"
            [burgerIconColor]="burgerIconColor"
            (tabChange)="onTabClick($event)"
            (isBurgerNavigationOpen)="toggleBurgerMenuNavigation($event)"
          >
          </app-tab-list-atom>

          <div
            *ngIf="filtereableLabels.length > 0"
            class="shadow-xs mx-auto w-full overflow-x-auto shadow scrollbar-hide"
            [style.backgroundColor]="vm.filterColors.banner | contentBuilderColor"
          >
            <ul class="flex flex-row gap-4 px-5 py-3 lg:py-4 lg:pl-12">
              <li
                *ngFor="let filtereableLabel of filtereableLabels"
                class="cursor-pointer"
                (click)="selectLabel(filtereableLabel)"
              >
                <app-tag-atom
                  [color]="
                    selectedLabel === filtereableLabel
                      ? vm.filterColors.activeColors
                      : vm.filterColors.inactiveColors
                  "
                  [text]="filtereableLabel.name"
                ></app-tag-atom>
              </li>
            </ul>
          </div>
        </div>
        <div
          *ngFor="let category of categoriesWithProducts"
          id="{{ category.id }}"
        >
          <app-category-factory
            [componentClass]="getCategoryComponentClass(category)"
            [category]="category"
            [products]="category.productsInformation ?? []"
            [selectedLabel]="selectedLabel"
            [isOrderingAllowed]="isOrderingAllowed"
            [categoryNameColor]="categoryNameColor"
            [categoryDescriptionColor]="categoryDescriptionColor"
            [highlightCategoryNameColor]="highlightCategoryNameColor"
            [highlightCategoryBackgroundColor]="highlightCategoryBackgroundColor"
            [productNameColor]="productNameColor"
            [productDescriptionColor]="productDescriptionColor"
            [productPriceColor]="productPriceColor"
            [productImagePlaceholderColor]="productImagePlaceholderColor"
            [productBorderColor]="productBorderColor"
            [productBackgroundColor]="productBackgroundColor"
            [tagColors]="vm.tagColors"
            (navigateTo)="isOrderingAllowed ? navigateTo($event) : null"
          ></app-category-factory>
        </div>
      </div>
    </ng-container>

    <div
      *ngIf="displayBackShopListButtonMobile || displayBackShopListButtonDesktop"
      [ngClass]="
        displayBackShopListButtonMobile && displayBackShopListButtonDesktop
          ? 'block'
          : displayBackShopListButtonMobile && !displayBackShopListButtonDesktop
            ? 'block lg:hidden'
            : !displayBackShopListButtonMobile && displayBackShopListButtonDesktop
              ? 'hidden lg:block'
              : 'hidden'
      "
    >
      <div
        *ngIf="isScrollingDown && shopListUrl"
        class="fixed bottom-16 right-5 z-50 w-auto"
        [class.bottom-32]="(cartProducts$ | async)?.length"
      >
        <div class="flex flex-row-reverse">
          <app-button-atom
            *ngIf="backShopListButtonText"
            [label]="backShopListButtonText"
            [thickSize]="'S'"
            [icon]="'icon-bullet-list'"
            (click)="backShopListButton(shopListUrl)"
            [type]="'light'"
            [overrideBgColor]="shopListButtonColor"
            [overrideTextColor]="shopListButtonTextColor"
          >
            >
          </app-button-atom>
          <app-rounded-icon-button
            *ngIf="!backShopListButtonText"
            icon="icon-bullet-list"
            [color]="'primary'"
            (onClick)="backShopListButton(shopListUrl)"
          ></app-rounded-icon-button>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="warningMessages?.length">
    <div class="my-5 px-5 py-5">
      <p
        *ngFor="let message of warningMessages"
        class="rounded rounded-sm bg-highlight-50 p-4 font-accent"
      >
        {{ message }}
      </p>
    </div>
  </ng-container>

  <ng-template #loading>
    <div class="pt-8">
      <ng-container *ngFor="let i of [].constructor(20)">
        <div class="flex h-32 cursor-pointer justify-between bg-white px-5 py-3">
          <div class="w-full">
            <p class="mb-2 h-6 w-32 animate-pulse rounded bg-neutral-200"></p>
            <p class="mb-2 h-4 w-44 animate-pulse rounded bg-neutral-50"></p>
          </div>
          <div class="height-fit-content relative">
            <div class="h-28 w-28 animate-pulse rounded bg-neutral-50"></div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-template>
</div>
