import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { Injectable, OnDestroy } from '@angular/core';

import {
  SELECT_BASKET_CART,
  SELECT_BASKET_CART_ITEMS,
  SELECT_BASKET_CONFIRMED_BY,
  SELECT_BASKET_ID,
  SELECT_BASKET_ON_SITE_LOCATION_ID,
  SELECT_BASKET_SUB_CARTS,
  SELECT_BASKET_USER,
} from '@store/basket/basket.selection';
import { BASKET_STORE_BASKET_INITIAL_STATE } from '@store/basket/basket.state';
import { takeUntil } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BasketStoreSelector implements OnDestroy {
  private readonly _destroyerRef = new Subject<boolean>();

  basket = BASKET_STORE_BASKET_INITIAL_STATE;

  select = this.store.select(SELECT_BASKET_CART);

  selectId = this.store.select(SELECT_BASKET_ID);

  selectUser = this.store.select(SELECT_BASKET_USER);

  selectSubCarts = this.store.select(SELECT_BASKET_SUB_CARTS);

  selectCartItems = this.store.select(SELECT_BASKET_CART_ITEMS);

  selectConfirmedBy = this.store.select(SELECT_BASKET_CONFIRMED_BY);

  selectOnSiteLocationId = this.store.select(SELECT_BASKET_ON_SITE_LOCATION_ID);

  constructor(private store: Store) {
    this.select.pipe(takeUntil(this._destroyerRef)).subscribe(_basket => {
      this.basket = _basket;
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe();
  }

  private _unsubscribe(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
  }
}
