import { take, tap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ShopsService } from '@core/services/shops/shops.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';

import { ShopKeys } from '@config/keys/shop.keys';
import { EntityKeys } from '@config/keys/entity.keys';

import { Paths } from '@config/paths.config';
import { DoodShopModel } from '@core/models/shop.model';
import { SlugUtils } from '@shared/utils/slug/slug.utils';

@Component({
  selector: 'app-qr-code-redirect',
  templateUrl: './qr-code-redirect.component.html',
})
export class QrCodeRedirectComponent implements OnInit {
  constructor(
    private readonly router: Router,
    private readonly shopsService: ShopsService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly routerHelper: RouterHelperService,
  ) {}

  ngOnInit(): void {
    const shopSlug = this.activatedRoute.snapshot.params.shopSlug;

    this.shopsService
      .loadShopBySlug$(shopSlug)
      .pipe(
        take(1),
        tap(shop => this.shopLoaded(shop)),
      )
      .subscribe();
  }

  private shopLoaded(shop?: DoodShopModel): void {
    if (!shop) {
      alert('Page introuvable');
      this.router.navigate([`/`]);

      return;
    }

    this.router.navigate([
      this.routerHelper.translateRoute(
        `${Paths.Shops}/${SlugUtils.formatSlug(shop[ShopKeys.Type].name)}/${shop[EntityKeys.Slug]}`,
      ),
    ]);
  }
}
