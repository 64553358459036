import { Component } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

import { UserKeys } from '@config/keys/user.keys';

import { ModalsService } from '@core/services/modals/modals.service';
import { OrdersService } from '@core/services/orders/orders.service';

import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { OrderStoreSelector } from '@common/selectors/order.selector';
import { CartStoreDispatcher } from '@common/dispatchers/cart.dispatcher';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { CountryISO } from '@shared/components/input-country-phone/config/country-phone.config';

@Component({
  selector: 'app-cart-user-informations-modal',
  templateUrl: './cart-user-informations-modal.component.html',
  styleUrls: ['./cart-user-informations-modal.component.scss'],
})
export class CartUserInformationsModalComponent extends DestroyerBase {
  static handle = 'cart-user-informations-modal';

  index$: Observable<number> = this.modalSelector
    .selectModal(CartUserInformationsModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  contactPhone$: Observable<string | undefined> = this.orderSelector.selectValidation.pipe(
    map(order => order?.contact_phone_number ?? undefined),
  );

  marketplaceCountry$ = this.marketplaceSelector.selectMarketplaceCountry;

  userPhone?: string;

  userKeys = UserKeys;

  private destroyed$ = new Subject<boolean>();

  countryIso = CountryISO;

  constructor(
    private modalSelector: ModalStoreSelector,
    private orderSelector: OrderStoreSelector,
    private cartDispatcher: CartStoreDispatcher,
    private readonly ordersService: OrdersService,
    private readonly modalsService: ModalsService,
    readonly settingsSelector: SettingsStoreSelector,
    private marketplaceSelector: MarketplaceStoreSelector,
  ) {
    super();
    this.contactPhone$
      .pipe(
        takeUntil(this.destroyed$),
        take(1),
        map(phoneNumber => {
          this.userPhone = phoneNumber;
        }),
      )
      .subscribe();
  }

  closeModal(): void {
    this.modalsService.close(CartUserInformationsModalComponent.handle);
  }

  onUpdatePhone($event: string): void {
    this.userPhone = $event.replace(/\s/g, '');
  }

  updateInformation(): void {
    if (this.userPhone?.length) {
      this.cartDispatcher.updateActive({
        contact_phone: this.userPhone,
      });
      this.ordersService.removeDefaultPhone();
      this.ordersService.checkCartIsValid$().pipe(take(1)).subscribe();
    }
    this.closeModal();
  }
}
