import { computed, effect, inject, Injectable, untracked } from '@angular/core';
import { AutoFillCartItemRulesCategory } from '../cart/auto-fill-cart-item-rules/auto-fill-cart-item-rules-category';
import { CategoryStepperUtil } from './util/category-stepper-util';
import {
  CartDraftDeclinableProductStore,
  DraftProductsByCategoryId,
} from '../cart/cart-draft-declinable-product.store';
import { DeclinableStepperUtil } from './util/declinable-stepper-util';
import { SimpleProductCartUtil } from './util/simple-product-stepper-util';
import { SimpleProductWithAdditionsGroupStep, Step, Stepper } from './stepper.type';
import { RootStepperStore } from './root-stepper.store';
import { SimpleProductStepper } from './simple-product-stepper.store';
import { Subject } from 'rxjs';
import { StepInfo } from '@core/models/stepper.model';
import { toSignal } from '@angular/core/rxjs-interop';
import _ from 'lodash';

@Injectable()
export class DeclinableProductStepperStore implements SimpleProductStepper {
  private readonly rootStepperStore = inject(RootStepperStore);
  private readonly cartDraftDeclinableProductStore = inject(CartDraftDeclinableProductStore);

  constructor() {
    effect(
      () => {
        const stepper = this.stepper();
        this.rootStepperStore.setChildStepper(stepper);
      },
      {
        allowSignalWrites: true,
      },
    );
    effect(
      () => {
        const steps = this.steppers();
        if (!steps) {
          this.rootStepperStore.setSteppers([]);
          return;
        }
        this.rootStepperStore.setSteppers(Object.values(steps).filter(step => step) as Step[]);
      },
      {
        allowSignalWrites: true,
      },
    );
  }

  private readonly declinableProductId = computed(() => {
    const cartDraftDeclinableProductStore = this.cartDraftDeclinableProductStore.selectors();
    return cartDraftDeclinableProductStore?.declinableProduct?.id;
  });

  public readonly selectableCategoryIds = computed(() => {
    const declinableProductId = this.declinableProductId();
    // reset to undefined each time the declinableProduct change
    if (!declinableProductId) {
      return undefined;
    }
    const declinableProduct = untracked(
      // avoid to reset selectableCategoryIds each time the declinableProduct change (ex: if the cart id updated)
      () => this.cartDraftDeclinableProductStore.selectors()?.declinableProduct,
    );
    const productsByCategoryId = untracked(
      // avoid to reset selectableCategoryIds each time the declinableProduct change (ex: if the cart id updated)
      () => this.cartDraftDeclinableProductStore.selectors()?.draftProductsByCategoryId,
    );
    if (!declinableProduct || !productsByCategoryId) {
      return undefined;
    }

    const result = declinableProduct.categories
      .filter(category => {
        if (!category.store_id) {
          return false;
        }
        const categoryProducts = productsByCategoryId[category.store_id];
        const isExclusiveCategoryProduct = AutoFillCartItemRulesCategory.isExclusiveCategoryProduct(
          {
            category,
          },
        );
        if (!isExclusiveCategoryProduct) {
          return true;
        }
        const isExclusiveCategoryProductAdditionsGroup =
          isExclusiveCategoryProduct && categoryProducts[0].additions.products.length;
        return isExclusiveCategoryProductAdditionsGroup;
      })
      .map(category => category.store_id);
    return result;
  });

  public readonly selectedStep$ = new Subject<StepInfo>();

  private readonly selectedStep = toSignal(this.selectedStep$);

  public readonly selectedStepsId = computed<StepInfo | undefined>(() => {
    const selectedStep = this.selectedStep();

    const declinableProductId = this.declinableProductId();
    // reset to undefined each time the declinableProduct change
    if (!declinableProductId) {
      return undefined;
    }
    // ! go automatically to the first noSkippable step
    const categoryId = selectedStep?.categoryId ?? this.selectableCategoryIds()?.[0];

    const productsByCategoryId =
      untracked(
        () => this.cartDraftDeclinableProductStore.selectors()?.draftProductsByCategoryId,
      ) || {};

    // check if it is a category with only one product and additions group
    const simpleProductId = this.resolveSimpleProductId({
      selectedStep,
      categoryId,
      productsByCategoryId,
    });

    let additionsGroupId = selectedStep?.additionsGroupId;
    if (simpleProductId && !additionsGroupId) {
      const additionGroups = untracked(
        () =>
          this.cartDraftDeclinableProductStore.selectors()?.draftAdditionGroupsBySimpleProductId,
      )?.[simpleProductId];
      additionsGroupId = additionGroups?.[0].id;
    }

    return {
      categoryId,
      simpleProductId,
      additionsGroupId,
    };
  });

  // todo add a way to select a specific step, otherwise create a nestedSignal
  public setSelectedProductId(productId: string) {
    this.selectedStep$.next({
      categoryId: this.selectedStepsId()?.categoryId,
      simpleProductId: productId,
    });
  }

  public setSelectedAdditionsGroupId(id: string): void {
    this.selectedStep$.next({
      categoryId: this.selectedStepsId()?.categoryId,
      simpleProductId: this.selectedStepsId()?.simpleProductId,
      additionsGroupId: id,
    });
  }

  // todo create steppers computed, and calculate the next step... directly and do not pass nextStep function
  public readonly steppers = computed(() => {
    const declinableProductId = this.declinableProductId();

    if (!declinableProductId) {
      return undefined;
    }

    const declinableStepper = this.calculateDeclinableStepper();
    if (!declinableStepper) {
      return undefined;
    }
    const categoryStepper = this.calculateCategoryStepper();
    const simpleProductStepper = this.calculateSimpleProductStepper();

    return {
      declinableStepper,
      categoryStepper,
      simpleProductStepper,
    };
  });

  public readonly stepper = computed<Stepper | undefined>(() => {
    const steppers = this.steppers();
    if (!steppers) {
      return undefined;
    }
    const { declinableStepper, categoryStepper, simpleProductStepper } = steppers;

    return {
      step: declinableStepper,
      childStepper: categoryStepper
        ? {
            step: categoryStepper,
            childStepper: simpleProductStepper
              ? { step: simpleProductStepper, childStepper: undefined }
              : undefined,
          }
        : undefined,
    } satisfies Stepper;
  });

  public readonly simpleProductStepper = computed<SimpleProductWithAdditionsGroupStep | undefined>(
    () => {
      const steppers = this.steppers();
      if (!steppers) {
        return undefined;
      }
      const { simpleProductStepper } = steppers;
      return simpleProductStepper;
    },
  );

  private calculateDeclinableStepper() {
    const selectors = this.cartDraftDeclinableProductStore.selectors();
    if (!selectors) {
      return undefined;
    }
    const declinableProduct = selectors.declinableProduct;
    const selectedCategoryId = this.selectedStepsId()?.categoryId;

    return DeclinableStepperUtil.calculateDeclinableStepStates({
      declinableProduct,
      totalSelectedQuantity: selectors.cartDraft.quantity ?? 0,
      isValid: selectors.validity.isDeclinableProductValid,
      selectedCategoryId: selectedCategoryId,
      selectableCategoryIds: this.selectableCategoryIds(),
      setSelectedStep: (stepsId: StepInfo) => {
        this.selectedStep$.next(stepsId);
      },
    });
  }

  private calculateCategoryStepper() {
    const selectors = this.cartDraftDeclinableProductStore.selectors();
    if (!selectors) {
      return undefined;
    }
    const declinableProduct = selectors.declinableProduct;
    const selectedCategoryId = this.selectedStepsId()?.categoryId;

    if (!selectedCategoryId) {
      return undefined;
    }
    const categoryData = CategoryStepperUtil.getCategoryData({
      selectedCategoryId,
      declinableProduct,
      compiledCategoriesValidity: selectors.validity.compiledCategoriesValidity,
      draftProductsByCategoryId: selectors.draftProductsByCategoryId || {},
      totalSelectedQuantityByCategoryId: selectors.totalSelectedQuantityByCategoryId || {},
    });

    if (!categoryData) {
      return undefined;
    }

    return CategoryStepperUtil.calculateStepStates({
      selectedCategoryId,
      category: categoryData.category,
      totalSelectedQuantity: categoryData.totalSelectedQuantity,
      isCategoryValid: categoryData.isCategoryValid,
      selectedSimpleProductId: this.selectedStepsId()?.simpleProductId,
      allProductsOfTheCategory: categoryData.allProductsOfTheCategory,
      setSelectedStep: (stepsId: StepInfo) => {
        this.selectedStep$.next(stepsId);
      },
    });
  }

  private calculateSimpleProductStepper() {
    const selectors = this.cartDraftDeclinableProductStore.selectors();
    const selectedSimpleProductId = this.selectedStepsId()?.simpleProductId;
    const selectedCategoryId = this.selectedStepsId()?.categoryId;

    if (!selectedSimpleProductId || !selectedCategoryId || !selectors) {
      return undefined;
    }

    const data = SimpleProductCartUtil.getSimpleProductCartStepperData({
      selectedSimpleProductId,
      simpleProduct: selectors.getDraftSimpleProductById(selectedSimpleProductId),
      cartValidity: selectors.getSimpleProductValidities({
        categoryId: selectedCategoryId,
        productId: selectedSimpleProductId,
      }),
      additionGroups: selectors.draftAdditionGroupsBySimpleProductId[selectedSimpleProductId],
      selectedAdditionIds:
        selectors.getCartItem({
          categoryId: selectedCategoryId,
          productId: selectedSimpleProductId,
        })?.additions ?? [],
    });

    if (!data) {
      return undefined;
    }

    const selectedAdditionsGroupId = this.selectedStepsId()?.additionsGroupId;

    return SimpleProductCartUtil.calculateSimpleProductStates({
      simpleProductAdditionsByGroups: data.additionsByGroups,
      selectedAdditionsGroupId,
      isAdditionGroupValid: SimpleProductCartUtil.isSelectedGroupValid({
        selectedAdditionsGroupId,
        cartValidity: data.cartValidity,
      }),
      totalGroupAdditionsQuantity: SimpleProductCartUtil.getAdditionGroupTotalSelected({
        selectedAdditionIds: data.selectedAdditionIds,
        simpleProductAdditionsByGroups: data.additionsByGroups,
        selectedAdditionsGroupId,
      }),
      product: data.product,
      setSelectedStep: (stepsId: StepInfo) => {
        this.selectedStep$.next(stepsId);
      },
      currentStep: this.selectedStepsId(),
    });
  }

  private resolveSimpleProductId({
    selectedStep,
    categoryId,
    productsByCategoryId,
  }: {
    selectedStep: StepInfo | undefined;
    categoryId: string | undefined;
    productsByCategoryId: DraftProductsByCategoryId;
  }) {
    let simpleProductId = selectedStep?.simpleProductId;
    if (categoryId && !simpleProductId) {
      const categoryProducts = productsByCategoryId[categoryId];
      const isExclusiveCategoryProduct = categoryProducts?.length === 1;
      const isExclusiveCategoryProductAdditionsGroup =
        isExclusiveCategoryProduct && categoryProducts[0].additions.products.length;
      simpleProductId = isExclusiveCategoryProductAdditionsGroup
        ? categoryProducts[0]?.id
        : undefined;
    }
    return simpleProductId;
  }
}
