import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-kiosk-light-product-details-page',
  templateUrl: './kiosk-light-product-details-page.component.html',
})
export class KioskLightProductDetailsPageComponent implements OnInit {

  @Input() body?: any;
  @Input() backgroundColor?: IContentBuilderFieldColor;
  @Input() textColor?: IContentBuilderFieldColor;
  @Input() buttonBackgroundColor?: IContentBuilderFieldColor;
  @Input() buttonTextColor?: IContentBuilderFieldColor;

  productId: string | null = null;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.updateProductId();
  }

  private updateProductId(): void {
    const productId = this.getProductIdFromRoute(this.route);

    if (productId) {
      this.productId = productId;
    } else {
      this.productId = null;
    }
  }

  private getProductIdFromRoute(route: ActivatedRoute): string | null {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route.snapshot.paramMap.get('productId');
  }

  back(): void {
    const shopCity = this.route.snapshot.paramMap.get('shopCity');
    const shopType = this.route.snapshot.paramMap.get('shopType');
    const shopSlug = this.route.snapshot.paramMap.get('shopSlug');

    this.router.navigate([`/fr/${shopCity}/${shopType}/${shopSlug}`]);
  }
}
