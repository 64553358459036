import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { BASKET_STORE_KEY, BasketCartState, BasketState } from './basket.state';
import { createHydratedStoreActions } from '@store/_abstract/hydrated';

export const BASKET_STORE_API_ACTIONS = createActionGroup({
  source: 'Dood/API/Basket',
  events: {},
});

export const BASKET_STORE_LOCAL_ACTIONS = createActionGroup({
  source: 'Dood/LOCAL/Basket',
  events: {
    // Basket
    'Set Basket': props<{ basket: BasketCartState }>(),
    'Update Basket': props<{ changes: Partial<BasketCartState> }>(),
    'Reset Basket': emptyProps(),

    // Misc
    'Reset': emptyProps(),
  },
});

export const BASKET_STORE_COMPONENT_ACTIONS = createActionGroup({
  source: 'Dood/COMPONENT/Basket',
  events: {
    // State
    'Update State': props<{ changes: Partial<BasketState> }>(),
    'Reset State': emptyProps(),
  },
});

export const BASKET_STORE_HYDRATION_ACTIONS = createHydratedStoreActions(BASKET_STORE_KEY);
