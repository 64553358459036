import { combineLatest, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';

import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';

import { ModalsService } from 'src/app/core/services/modals/modals.service';
import { ModalScrollBlockBase } from '@core/base/modalScrollBlock/modal-scroll-block.base';
import { MarketplaceService } from '@core/services/marketplace/marketplace.service';
import { TranslateService } from '@ngx-translate/core';
import { ComoService } from '@core/services/como/como.service';
import { AlertModalComponent } from '@shared/modals/alert-modal/alert-modal.component';
import { MarketplaceStoreRefiner } from '@common/refiners/marketplace.refiner';

@Component({
  selector: 'app-como-create-or-link-account-modal',
  templateUrl: './como-create-or-link-account-modal.component.html',
})
export class ComoCreateOrLinkAccountModalComponent extends ModalScrollBlockBase implements OnInit {
  static handle = 'como-create-or-link-account-modal';

  isDesktop$ = this.settingsSelector.selectDeviceIsDesktopScreen;

  index$: Observable<number> = this.modalSelector
    .selectModal(ComoCreateOrLinkAccountModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  step = 'attach-with-email';
  comoPlugin: any;
  phoneOrEmail = '';

  constructor(
    private modalSelector: ModalStoreSelector,
    private readonly modalsService: ModalsService,
    private settingsSelector: SettingsStoreSelector,
    private readonly marketplaceService: MarketplaceService,
    private readonly translateService: TranslateService,
    private readonly comoService: ComoService,
    private readonly marketplaceStoreRefiner: MarketplaceStoreRefiner,
  ) {
    super();
  }

  ngOnInit(): void {
    this.comoPlugin = this.marketplaceService.getPluginByType('como');
    if (!this.comoPlugin || this.comoPlugin.plugin_enabled !== true) {
      this.modalsService.open(AlertModalComponent.handle);
      this.modalsService.setData(AlertModalComponent.handle, {
        message: this.translateService.instant('como.loyalty-plugin-not-configured'),
      });
      return;
    }

    combineLatest([
      this.marketplaceStoreRefiner.selectIsComoPluginEnabled,
      this.comoService.isUserComoAccountEnabledOnMarketplaceLevel$(),
    ])
      .pipe(
        take(1),
        tap(([isMarketplaceComoEnabled, isUserComoEnabled]) => {
          if (!isMarketplaceComoEnabled || isUserComoEnabled) {
            this.modalsService.close(ComoCreateOrLinkAccountModalComponent.handle);
          }
        }),
      )
      .subscribe();
  }

  goToStep(step: string) {
    if (step === 'confirmation') {
      // reload page to reload user and blocks displayed
      document.location.reload();
      this.close();
    }

    this.step = step;
  }

  setPhoneOrEmail(phoneOrEmail: string) {
    this.phoneOrEmail = phoneOrEmail;
  }

  close(): void {
    this.modalsService.close(ComoCreateOrLinkAccountModalComponent.handle);
  }
}
