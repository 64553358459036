import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
  signal,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CartStoreDispatcher } from '@common/dispatchers/cart.dispatcher';
import { BasketStoreRefiner } from '@common/refiners/basket.refiner';
import { CartStoreRefiner } from '@common/refiners/cart.refiner';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';
import { BasketStoreSelector } from '@common/selectors/basket.selector';
import { CartStoreSelector } from '@common/selectors/cart.selector';
import { OrderStoreSelector } from '@common/selectors/order.selector';
import {
  CartItemKeys,
  ReconstructedCartItemKeys,
  ReconstructedCartKeys,
} from '@config/keys/cart.keys';
import { ProductKeys } from '@config/keys/product.keys';
import { ShopKeys } from '@config/keys/shop.keys';
import { Paths } from '@config/paths.config';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { DoodReconstructedCart, DoodReconstructedCartItem } from '@core/models/cart.model';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { CartService } from '@core/services/cart/cart.service';
import { ErrorService } from '@core/services/error-service/error.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmModalComponent } from '@shared/modals/confirm-modal/confirm-modal.component';
import { ItemEditModalComponent } from '@shared/modals/item-edit-modal/item-edit-modal.component';
import { filter, map, Subject, take, takeUntil, tap } from 'rxjs';
import { ResetStateService } from '@core/services/reset-state.service';
import { KioskHorizontalScrollComponent } from '../kiosk-horizontal-scroll/kiosk-hotizontal-scroll.component';

@Component({
  selector: 'app-kiosk-footer-cart-block',
  templateUrl: './kiosk-footer-cart-block.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KioskFooterCartBlockComponent extends DestroyerBase implements OnInit, OnDestroy {
  @Input() primaryButtonBackgroundColor?: IContentBuilderFieldColor;
  @Input() primaryButtonTextColor?: IContentBuilderFieldColor;
  @Input() secondaryButtonBackgroundColor?: IContentBuilderFieldColor;
  @Input() secondaryButtonTextColor?: IContentBuilderFieldColor;
  @Input() productBackgroundColor?: IContentBuilderFieldColor;
  @Input() productTextColor?: IContentBuilderFieldColor;

  @ViewChild('horizontalScroll') horizontalScroll: KioskHorizontalScrollComponent | undefined;

  @ViewChild('productList', { static: false }) productList!: ElementRef;

  basket$ = this.basketSelector.select;
  basketId$ = this.basketSelector.select.pipe(map(basket => basket[CartItemKeys.Id]));
  userId$ = this.authSelector.selectUserId;
  reconstructedCart$ = this.cartService.getCartLineItems$();
  orderValidationInProgress$ = this.orderSelector.selectIsValidating;
  orderValidate$ = this.orderSelector.selectValidation;
  reconstructedShareOrderBasket$ = this.basketRefiner.selectCartItemsGroupedByUser;
  cart$ = this.cartSelector.selectActive;
  order$ = this.orderSelector.selectValidation;
  cartProducts$ = this.cartRefiner.selectActiveCartProducts;

  shopKeys = ShopKeys;
  productKeys = ProductKeys;
  cartItemKeys = CartItemKeys;
  reconstructedCartKeys = ReconstructedCartKeys;
  reconstructedCartItemKeys = ReconstructedCartItemKeys;

  isNavigationToCartInProgress = false;

  errorMessages: string[] = [];

  isCartOverflow = false;

  public hideButtons = signal(false);

  private destroyed$ = new Subject<boolean>();

  constructor(
    private orderSelector: OrderStoreSelector,
    private cartSelector: CartStoreSelector,
    private authSelector: AuthStoreSelector,
    private cartRefiner: CartStoreRefiner,
    private basketRefiner: BasketStoreRefiner,
    private basketSelector: BasketStoreSelector,
    private cartService: CartService,
    private router: Router,
    private readonly routerHelper: RouterHelperService,
    private readonly modalsService: ModalsService,
    private readonly translateService: TranslateService,
    private readonly cartDispatcher: CartStoreDispatcher,
    private readonly errorService: ErrorService,
    private readonly resetStateService: ResetStateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.cartService.loadShopsOfCart$().subscribe();

    this.errorService
      .getErrorMessage$()
      .pipe(
        takeUntil(this.destroyed$),
        tap(messages => (this.errorMessages = messages)),
      )
      .subscribe();

    this.checkRoute(this.router.url);

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.checkRoute(this.router.url);
    });

    this.cartProducts$
      .pipe(
        takeUntil(this.destroyed$),
        tap(products => {
          this.scrollToRight();
        }),
      )
      .subscribe();
  }

  private scrollToRight(): void {
    if (this.horizontalScroll) {
      this.horizontalScroll.scrollToRight();
    }
  }

  navigateToCart(): void {
    this.isNavigationToCartInProgress = true;
    this.router.navigate([this.routerHelper.translateRoute(Paths.CartResume)]);
  }

  ngOnDestroy(): void {
    this.modalsService.close(ItemEditModalComponent.handle);
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private checkRoute(currentRoute: string): void {
    this.hideButtons?.set(currentRoute.includes('product-add'));
  }

  onUpdateItem(itemId: number | string): void {
    ItemEditModalComponent.navigate = false;
    ItemEditModalComponent.backgroundColor = this.productBackgroundColor;
    ItemEditModalComponent.textColor = this.productTextColor;
    ItemEditModalComponent.buttonBackgroundColor = this.primaryButtonBackgroundColor;
    ItemEditModalComponent.buttonTextColor = this.primaryButtonTextColor;
    this.modalsService.open(ItemEditModalComponent.handle);
    this.cartService.loadShopsOfCart$().pipe(takeUntil(this.destroyed$)).subscribe();
    this.cartService
      .getCartLineItems$()
      .pipe(
        takeUntil(this.destroyed$),
        take(1),
        map(reconstructedCart => this.lookForItemInCart(itemId, reconstructedCart)),
        map(cartItem => {
          if (cartItem) {
            this.cartDispatcher.updateStore({ edit_item: cartItem });
          }
        }),
      )
      .subscribe();
  }

  private lookForItemInCart(
    itemId: string | number,
    reconstructedCart: DoodReconstructedCart[],
  ): null | DoodReconstructedCartItem {
    for (const cartOfShop of reconstructedCart) {
      const cartItem = cartOfShop[ReconstructedCartKeys.Items].find(
        elem => elem[ReconstructedCartItemKeys.Item][CartItemKeys.Id] === itemId,
      );
      if (cartItem) {
        return cartItem;
      }
    }

    return null;
  }

  clearCart(): void {
    ConfirmModalComponent.backgroundColor = this.productBackgroundColor;
    ConfirmModalComponent.textColor = this.productTextColor;
    ConfirmModalComponent.buttonBackgroundColor = this.primaryButtonBackgroundColor;
    ConfirmModalComponent.buttonTextColor = this.primaryButtonTextColor;
    this.modalsService.open(ConfirmModalComponent.handle);
    this.modalsService.setData(ConfirmModalComponent.handle, {
      title: this.translateService.instant('basket.clear-basket-confirm-modal.title'),
      message: this.translateService.instant('basket.clear-basket-confirm-modal.message'),
      confirmButtonText: this.translateService.instant('basket.clear-basket-confirm-modal.confirm'),
      isConfirm: false,
    });

    this.modalsService
      .getData(ConfirmModalComponent.handle)
      .pipe(
        takeUntil(this._destroyerRef),
        map(modal => modal?.data?.isConfirm),
        filter(isConfirm => Boolean(isConfirm)),
        tap(() => {
          this.resetStateService.refreshUI();
        }),
      )
      .subscribe();
  }
}
