export enum ZerosixAssetType {
  VOUCHER = 'voucher',
  REWARD = 'reward',
}

export interface IZerosixAsset {
  id?: string;
  type?: ZerosixAssetType;
  url: string;
  name: string;
  value?: number;
  unit: string;
  code: string;
  expiration_date?: string;
  external_id?: string;
  external_ids: string[];
  minimum_amount?: number;
  is_usable?: boolean;
  level_name?: string;
  points_required?: number;
}

export interface IZerosixCard {
  latent_points_balance?: number;
  terms_accepted: boolean;
  points_balance?: number;
  current_level?: string;
  card_url: string;
  member_summary?: string;
  available_vouchers: IZerosixAsset[];
  rewards: IZerosixAsset[];
}
