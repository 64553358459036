import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';

import { ComoService } from '@core/services/como/como.service';
import { BasketStoreSelector } from '@common/selectors/basket.selector';

import { ComoAsset, ComoUser } from '@core/models/como.model';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-como-home-mosaic-block',
  templateUrl: './como-home-mosaic-block.component.html',
})
export class ComoHomeMosaicBlockComponent implements OnInit, OnDestroy {
  @Input() loyaltyPointsTitle = 'Vos points fidélité';
  @Input() creditPointsTitle = 'Votre crédit';
  @Input() pointsLabel = 'points';
  @Input() displayPoints = true;
  @Input() pointsCardLink?: string;
  @Input() pointsBackgroundColor?: IContentBuilderFieldColor;
  @Input() pointsTextColor?: IContentBuilderFieldColor;
  @Input() displayCredits = true;
  @Input() creditsCardLink?: string;
  @Input() creditsBackgroundColor?: IContentBuilderFieldColor;
  @Input() creditsTextColor?: IContentBuilderFieldColor;
  @Input() displayAssets = true;
  @Input() assetsHeading = 'como.loyalty-rewards';
  @Input() assetsSubheading?: string;

  @HostBinding('style.display')
  get display() {
    return this.comoUser ? 'block' : 'none';
  }

  public comoUser?: ComoUser | null;
  public basketId$ = this.basketSelector.selectId;

  private readonly _destroyerRef = new Subject<boolean>();

  constructor(
    private readonly comoService: ComoService,
    private basketSelector: BasketStoreSelector,
  ) {}

  ngOnInit(): void {
    this.comoService.loadComoUser();
    this.comoService
      .getComoUser$()
      .pipe(
        takeUntil(this._destroyerRef),
        tap(comoUser => (this.comoUser = comoUser)),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
  }

  onAssetClick($event: ComoAsset): void {}
}
