import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {Capacitor} from '@capacitor/core';

@Component({
  selector: 'app-input-text-atom',
  templateUrl: './input-text-atom.component.html',
})
export class InputTextAtomComponent implements OnInit, OnChanges {
  @ViewChild('addressSearchInput', {static: true})
  addressSearchInput!: ElementRef;

  @Input() value = '';
  @Input() label = '';
  @Input() buttonLabel!: string;
  @Input() iconRight!: string;
  @Input() iconLeft!: string;
  @Input() cross!: boolean;
  @Input() size: 'normal' | 'full' = 'normal';
  @Input() autofocus = false;
  @Input() currency = '';
  @Input() isNumberOnly = false;

  @Output() valueChange = new EventEmitter();
  @Output() secondaryValueChange = new EventEmitter();
  @Output() iconClick = new EventEmitter();
  @Output() buttonClick = new EventEmitter();
  @Output() enterPress = new EventEmitter();

  displayedValue!: string;

  onAction = false;

  isNative = false;

  constructor() {
  }

  ngOnInit(): void {
    this.displayedValue = this.isNumberOnly ? this.value.toString() : this.value;
    this.isNative = Capacitor.isNativePlatform();
  }

  clearValue(): void {
    this.displayedValue = '';
    this.value = '';
    this.onAction = false;
    this.valueChange.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName) && propName === 'value') {
        this.displayedValue = changes.value.currentValue;
        this.onAction = false;
      }
    }
  }

  triggerAction(): void {
    this.onAction = true;
  }

  onInputChange(value: string): void {
    this.secondaryValueChange.emit(value);
  }

  numberOnly(event: KeyboardEvent): void {
    const inputValue = (event.target as HTMLInputElement).value;
    const pattern = /[0-9.]/; // Allow digits and a single dot
    const specialKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'];

    if (specialKeys.includes(event.key)) {
      return;
    }

    // Do not allow more than one dot
    if (event.key === '.' && inputValue.includes('.')) {
      event.preventDefault();
      return;
    }

    // Do not allow non-matching characters
    if (!pattern.test(event.key)) {
      event.preventDefault();
    }
  }
}
