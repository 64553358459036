import { Component, Input } from '@angular/core';

import {
  IContentBuilderFieldColor,
  IContentBuilderFieldImage,
} from '@core/models/content-builder-fields.model';
import { NavigationHistoryService } from '@core/services/navigation-history/navigation-history.service';

@Component({
  selector: 'app-header-big-logo-block',
  templateUrl: './header-big-logo-block.component.html',
})
export class HeaderBigLogoBlockComponent {
  @Input() isStatic = false;
  @Input() logo?: IContentBuilderFieldImage;
  @Input() backgroundColor?: IContentBuilderFieldColor;

  constructor(private readonly navigationHistoryService: NavigationHistoryService) {}

  back(): void {
    this.navigationHistoryService.goBack();
  }
}
