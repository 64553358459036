<div
  class="inline-flex items-center align-middle w-auto px-3 py-1.5 rounded font-bold text-sm"
  [class.bg-neutral-700]="type === 'dark'"
  [class.bg-success-500]="type === 'success'"
  [class.bg-secondary-700]="type === 'error'"
  [ngClass]="{
    'bg-neutral-200 text-neutral-800': type === 'neutral',
    'text-white': type !== 'neutral',
  }"
>
  <span
    *ngIf="icon"
    class="icon {{ icon }} mr-2 leading-5"
    [class.text-white]="type === 'dark'"
  ></span>
  <span [class.text-white]="type === 'dark'">{{ text | translate }}</span>
</div>
