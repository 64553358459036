<div class="container mx-auto max-w-640 pb-5">
  <ul
    class="flex mt-6 justify-center"
    [style.color]="textColor | contentBuilderColor"
    [class.text-primary-800]="!textColor?.value"
  >
    <li
      class="mx-4 text-2xl"
      *ngIf="facebookUrl"
    >
      <a
        [href]="facebookUrl"
        target="_blank"
      >
        <i
          class="icon icon-facebook text-3xl w-7.5 h-7.5"
          [style.color]="textColor | contentBuilderColor"
          [class.text-primary-800]="!textColor?.value"
        ></i>
      </a>
    </li>
    <li
      class="mx-4 text-2xl"
      *ngIf="twitterUrl"
    >
      <a
        [href]="twitterUrl"
        target="_blank"
      >
        <i
          class="icon icon-x text-3xl w-7.5 h-7.5"
          [style.color]="textColor | contentBuilderColor"
          [class.text-primary-800]="!textColor?.value"
        ></i>
      </a>
    </li>
    <li
      class="mx-4 text-2xl"
      *ngIf="instagramUrl"
    >
      <a
        [href]="instagramUrl"
        target="_blank"
      >
        <i
          class="icon icon-instagram text-3xl w-7.5 h-7.5"
          [style.color]="textColor | contentBuilderColor"
          [class.text-primary-800]="!textColor?.value"
        ></i>
      </a>
    </li>
    <li
      class="mx-4 text-2xl"
      *ngIf="linkedinUrl"
    >
      <a
        [href]="linkedinUrl"
        target="_blank"
      >
        <i
          class="icon icon-linkedin text-3xl w-7.5 h-7.5"
          [style.color]="textColor | contentBuilderColor"
          [class.text-primary-800]="!textColor?.value"
        ></i>
      </a>
    </li>
    <li
      class="mx-4 text-2xl"
      *ngIf="tiktokUrl"
    >
      <a
        [href]="tiktokUrl"
        target="_blank"
      >
        <i
          class="icon icon-tiktok text-3xl w-7.5 h-7.5"
          [style.color]="textColor | contentBuilderColor"
          [class.text-primary-800]="!textColor?.value"
        ></i>
      </a>
    </li>
  </ul>
</div>
