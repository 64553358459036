<app-header-atom
  [logoImageUrl]="logo?.url"
  [displayLogo]="displayLogo"
  [backgroundColor]="backgroundColor"
  [labelColor]="labelColor"
  [iconColor]="iconColor"
  [iconBackgroundColor]="iconBackgroundColor"
  [buttonTextColor]="buttonTextColor"
  [buttonBackgroundColor]="buttonBackgroundColor"
  [displayHamburger]="displayHamburger"
  [hamburgerColor]="hamburgerColor"
  [displayBackButton]="displayBackButton"
  [backLinkUrl]="backLinkUrl"
  [leftBlocks]="leftBlocks"
  [rightBlocks]="rightBlocks"
  [navigationLinks]="navigationLinks"
  [positionAbsoluteOnMobile]="positionAbsoluteOnMobile"
  [positionAbsoluteOnDesktop]="positionAbsoluteOnDesktop"
  [languageFlagUrl]="languageFlagUrl"
  (hamburgerClick)="openSidebarNavigation()"
  (languageFlagClick)="openSidebarNavigation()"
></app-header-atom>
