import { Component, EventEmitter, Input, Output } from '@angular/core';

import { DoodProductModel } from '@core/models/product.model';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';
import { ContentBuilderFieldColors } from '@shared/interfaces/content-builder.interface';

@Component({
  selector: 'app-product-push-list-atom',
  templateUrl: './product-push-list-atom.component.html',
})
export class ProductPushListAtomComponent {
  @Input() displayContext = '';
  @Input() products: DoodProductModel[] = [];
  @Input() isOrderingAllowed = true;
  @Input() productNameColor: IContentBuilderFieldColor = {
    value: 'neutral-900',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() productDescriptionColor: IContentBuilderFieldColor = {
    value: 'neutral-900',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() productPriceColor: IContentBuilderFieldColor = {
    value: 'neutral-900',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() productImagePlaceholderColor: IContentBuilderFieldColor = {
    value: 'neutral-50',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() productBorderColor: IContentBuilderFieldColor = {
    value: 'neutral-100',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() productBackgroundColor: IContentBuilderFieldColor = {
    value: 'transparent',
    type: ColorFieldTypesValues.Hex,
  };
  @Input({ required: true }) tagColors!: ContentBuilderFieldColors;

  @Output() navigateTo = new EventEmitter<DoodProductModel>();

  onNavigateTo(product: DoodProductModel): void {
    this.navigateTo.emit(product);
  }
}
