import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { ComoService } from '@core/services/como/como.service';
import { ModalsService } from '@core/services/modals/modals.service';

import { ComoAsset, ComoUser } from '@core/models/como.model';
import { BasketStoreSelector } from '@common/selectors/basket.selector';
import { ComoUseCreditBalanceModalComponent } from '@shared/modals/como/como-use-credit-balance-modal/como-use-credit-balance-modal.component';

@Component({
  selector: 'app-como-credit-balance-card-block',
  templateUrl: './como-credit-balance-card-block.component.html',
})
export class ComoCreditBalanceCardBlockComponent implements OnInit, OnDestroy {
  // TODO: Remove french values and replace them by i18n values
  @Input() pointsLabel = 'points';
  @Input() creditPointsTitle = 'Votre crédit';
  @Input() loyaltyPointsTitle = 'Vos points fidélité';

  public comoUser?: ComoUser | null;

  basketId$ = this.basketSelector.selectId;

  private readonly destroyed$ = new Subject<boolean>();

  constructor(
    private readonly comoService: ComoService,
    private basketSelector: BasketStoreSelector,
    private readonly modalsService: ModalsService,
  ) {}

  ngOnInit(): void {
    this.comoService.loadComoUser();
    this.comoService
      .getComoUser$()
      .pipe(
        takeUntil(this.destroyed$),
        tap(comoUser => (this.comoUser = comoUser)),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.modalsService.close(ComoUseCreditBalanceModalComponent.handle);
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onAssetClick($event: ComoAsset): void {}

  useCreditBalance(): void {
    this.modalsService.open(ComoUseCreditBalanceModalComponent.handle);
  }
}
