import { take } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';

import { DoodCartFunnelValueTarget } from '@store/cart-funnel/cart-funnel.model';
import { CartFunnelStoreSelector } from '@common/selectors/cart-funnel.selector';
import { CartFunnelStoreDispatcher } from '@common/dispatchers/cart-funnel.dispatcher';

@Component({
  selector: 'app-cart-funnel-on-behalf-user-phone-item-block',
  templateUrl: './cart-funnel-on-behalf-user-phone-item-block.component.html',
})
export class CartFunnelOnBehalfUserPhoneItemBlockComponent implements OnInit {
  @Input() title!: string;
  @Input() placeholder!: string;
  @Input() required = false;
  @Input() blockId!: string;
  @Input() formId!: string;

  value = '';

  constructor(
    private cartFunnelSelector: CartFunnelStoreSelector,
    private cartFunnelDispatcher: CartFunnelStoreDispatcher,
  ) {}

  ngOnInit(): void {
    this.cartFunnelDispatcher.addValue({
      id: this.blockId,
      title: this.title,
      displayTitle: true,
      formId: this.formId,
      required: this.required,
      target: DoodCartFunnelValueTarget.OnBehalfUserPhone,
    });

    this.cartFunnelSelector
      .selectValue(this.blockId)
      .pipe(take(1))
      .subscribe(form => {
        this.value = form?.value?.toString() ?? '';
      });
  }

  onValueChange(_value: string | boolean): void {
    this.cartFunnelDispatcher.updateValue({
      id: this.blockId,
      changes: {
        value: _value,
      },
    });
  }
}
