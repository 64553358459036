<a
  [link]="linkUrl"
  [ngClass]="{
    'shadow-S rounded-lg bg-white py-4 px-5': !displayEntityImage,
    'py-4': !displayEntityImage && entityNameSize === 'lg',
    'py-5': !displayEntityImage && entityNameSize === '2xl',
    'py-6': !displayEntityImage && entityNameSize === '3xl',
  }"
  class="flex flex-col block w-full card-link"
>
  <div
    *ngIf="displayEntityImage"
    class="relative mb-1.5 h-32 grow overflow-hidden rounded-lg bg-neutral-100 kiosk:h-full"
    [class.lg:h-44]="increaseHeight"
    [ngClass]="{
      'rounded-lg rounded-br-2xxl h-40': templateLayout === themeLayoutKeys.HeroLayout,
    }"
  >
    <img
      *ngIf="imgUrl"
      [src]="imgUrl"
      loading="lazy"
      class="object-cover w-full h-full push-shop-card-v2-atom__image"
    />
    <div
      class="absolute top-0 bottom-0 left-0 right-0 text-2xs opacity-30 xs:text-xs xm:text-sm"
      [class.bg-black]="closedLabel"
      [class.opacity-60]="closedLabel"
    ></div>
    <div
      *ngIf="closedLabel"
      class="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center p-2 font-bold text-center text-white"
      [ngClass]="{
        'pb-10': category || shortDescription,
        'text-sm xs:text-base': closedLabel.length <= 20,
        'text-2xs xs:text-xs md:text-sm': closedLabel.length > 20,
        'rounded-lg rounded-br-2xxl': templateLayout === themeLayoutKeys.HeroLayout,
      }"
    >
      {{ closedLabel }}
    </div>
    <div
      class="absolute bottom-0 px-2 py-1 m-2 text-xs font-bold rounded bg-neutral-200"
      *ngIf="
        displayEntityCategory &&
        category &&
        (!shortDescription || shortDescription.trim().length === 0)
      "
    >
      {{ category }}
    </div>
    <div
      class="absolute bottom-0 px-2 py-1 m-2 text-xs font-bold rounded bg-neutral-200"
      *ngIf="displayEntityCategory && shortDescription && shortDescription.trim().length > 0"
    >
      {{ shortDescription }}
    </div>
  </div>
  <div>
    <p
      class="text-override whitespace-pre-line py-0.5 font-accent text-lg font-bold leading-6 text-neutral-800"
      [ngClass]="{
        'text-xs pb-0.5': entityNameSize === 'xs',
        'text-sm pb-0.5': entityNameSize === 's',
        'text-md pb-0.5': entityNameSize === 'm',
        'text-lg pb-0.5': entityNameSize === 'lg',
        'text-2xl pb-2': entityNameSize === '2xl',
        'text-3xl pb-4': entityNameSize === '3xl',
      }"
    >
      {{ title }}
    </p>
    <p class="text-sm font-base text-neutral-600">
      <span
        class="dot"
        *ngIf="displayEntityDistance && distance"
        >{{ 'shop-list-header.at' | translate }} {{ formattedDistance }}</span
      >
      <span
        *ngIf="displayEntityPricing && budget"
        class="dot"
      >
        <app-budget-shop-atom
          [value]="budget"
          [currency]="currency"
        ></app-budget-shop-atom>
      </span>
      <span
        class="dot"
        *ngIf="availableAt"
        >{{ availableAt }}</span
      >
      <span
        class="dot"
        *ngIf="stars"
      >
        <i class="icon icon-todo mr-0.5 text-xs text-highlight-500"></i>
        <span>{{ stars }}</span>
      </span>
    </p>
    <p
      *ngIf="displayEntityAddress && address"
      class="text-sm font-base text-neutral-600"
    >
      {{ address }}
    </p>
  </div>
</a>
