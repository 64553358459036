<app-modal-filler-atom [index]="(index$ | async) ?? 0"></app-modal-filler-atom>
<div
  class="fixed left-0 right-0 mx-auto overflow-y-auto shadow"
  [ngClass]="
    (isMobile$ | async)
      ? 'bottom-0 max-h-85-screen max-w-screen-sm'
      : 'flex justify-center items-center w-full h-full top-0 left-0'
  "
  [style.z-index]="((index$ | async) ?? 0) + 50"
>
  <app-modal-filler-atom
    class="hidden md:block"
    [index]="(index$ | async) ?? 0"
  ></app-modal-filler-atom>
  <div
    class="bg-white p-5 md:p-7"
    [ngClass]="(isMobile$ | async) ? 'rounded-t-3xl' : 'rounded-3xl min-w-448'"
    [style.z-index]="((index$ | async) ?? 0) + 50"
  >
    <div class="mb-7">
      <div class="mb-4">
        <div
          *ngIf="distributionModeItems?.length === 1"
          class="mb-4"
        >
          <app-button-atom
            [size]="'L'"
            [disabled]="true"
            [label]="distributionModeItems[0].label | translate"
          >
          </app-button-atom>
        </div>
        <div
          *ngIf="
            (distributionModeItems?.length ?? 0) > 1 && (distributionModeItems?.length ?? 0) < 3
          "
          class="mx-auto mb-4 max-w-sm"
        >
          <app-parameters-distribution-mode-block
            [selectedDistributionMode]="selectedDistributionMode"
            [isCartParameters]="true"
            [items]="distributionModeItems"
            (selectItem)="distributionModeSelectionChanged($event)"
          ></app-parameters-distribution-mode-block>
        </div>
        <div
          *ngIf="(distributionModeItems?.length ?? 0) >= 3"
          class="mx-auto mb-4 max-w-sm"
        >
          <app-custom-select-atom
            [items]="distributionModeItems"
            [selectedItem]="selectedDistributionMode"
            (selectItem)="distributionModeSelectionChanged($event)"
          ></app-custom-select-atom>
        </div>
      </div>
      <div *ngIf="selectedOrderTypeCapabilities.isDeliveryAddressMandatory">
        <app-parameters-location-block
          [selectedValue]="selectedLocation ?? ''"
          [selectedCommentValue]="deliveryComment"
          (placeChanged)="locationChanged($event)"
        ></app-parameters-location-block>
      </div>
    </div>

    <!-- Delivery Instructions -->
    @if (
      (selectedDistributionMode === distributionMode.Delivery ||
        selectedDistributionMode === distributionMode.Shipping) &&
      !((userIsLoggedIn$ | async) && selectedLocation && (instructions$ | async))
    ) {
      <div class="mx-auto mb-7 max-w-sm">
        <app-parameters-delivery-instructions-block
          [instructions]="forceType(instructions$ | async)"
          (instructionsChanged)="deliveryCommentChanged(forceType($event))"
        ></app-parameters-delivery-instructions-block>
      </div>
    }

    <div
      *ngIf="message"
      class="my-4 rounded rounded-s bg-neutral-50 p-4 font-base text-sm text-neutral-700"
    >
      {{ message }}
    </div>

    <div
      class="mb-7"
      *ngIf="selectedOrderTypeCapabilities.preorderingAllowed"
    >
      <app-parameters-wanted-at-block
        [dateSlots]="wantedAtDateSlots"
        [timeSlots]="wantedAtTimeSlots"
        [wantedAtType]="selectedWantedAtType"
        [wantedAtDate]="selectedWantedAtDate"
        [wantedAtTime]="selectedWantedAtTime"
        [displayAsapForLaterPicker]="displayAsapForLaterPicker"
        (typeChanged)="onWantedAtTypeChanged($event)"
        (dateChanged)="onWantedAtDateChanged($event)"
        (timeChanged)="onWantedAtTimeChanged($event)"
      ></app-parameters-wanted-at-block>
    </div>
    <app-button-atom
      [color]="'primary'"
      [label]="'parameters.validate' | translate"
      [type]="'primary'"
      [size]="'L'"
      (click)="save()"
    ></app-button-atom>
  </div>
</div>
