import { Component, Input } from '@angular/core';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-product-category-atom',
  templateUrl: './product-category-atom.component.html',
})
export class ProductCategoryAtomComponent {
  @Input() heading!: string;
  @Input() description!: string;
  @Input() id!: string;
  @Input() categoryNameColor: IContentBuilderFieldColor = {
    value: 'neutral-900',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() categoryDescriptionColor: IContentBuilderFieldColor = {
    value: 'neutral-600',
    type: ColorFieldTypesValues.Palette,
  };
}
