import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';

import {
  SELECT_PAYMENT_AMOUNT,
  SELECT_PAYMENT_BANCONTACT,
  SELECT_PAYMENT_BUTTON,
  SELECT_PAYMENT_ERROR,
  SELECT_PAYMENT_METHODS,
  SELECT_PAYMENT_STATE,
  SELECT_PAYMENT_STATUS,
  SELECT_PAYMENT_TRANSACTION,
} from '@store/payment/payment.selection';
import { PAYMENT_STORE_BANCONTACT_INITIAL_STATE } from '@store/payment';

@Injectable({ providedIn: 'root' })
export class PaymentStoreSelector implements OnDestroy {
  private readonly _destroyerRef = new Subject<boolean>();

  bancontact = PAYMENT_STORE_BANCONTACT_INITIAL_STATE;

  select = this.store.select(SELECT_PAYMENT_STATE);
  selectError = this.store.select(SELECT_PAYMENT_ERROR);
  selectAmount = this.store.select(SELECT_PAYMENT_AMOUNT);
  selectButton = this.store.select(SELECT_PAYMENT_BUTTON);
  selectStatus = this.store.select(SELECT_PAYMENT_STATUS);
  selectMethods = this.store.select(SELECT_PAYMENT_METHODS);
  selectBancontact = this.store.select(SELECT_PAYMENT_BANCONTACT);
  selectTransaction = this.store.select(SELECT_PAYMENT_TRANSACTION);

  constructor(private store: Store) {
    this.selectBancontact.pipe(takeUntil(this._destroyerRef)).subscribe(_bancontact => {
      this.bancontact = _bancontact;
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe();
  }

  private _unsubscribe(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
  }
}
