<div class="flex justify-between items-baseline mb-8 gap-3">
  <h1 class="py-2 font-accent font-bold text-3xs leading-8xs text-neutral-800">
    {{ heading }}
  </h1>
  <app-rounded-icon-button
    *ngIf="displayCloseIcon"
    [icon]="'icon-cross'"
    [color]="'primary'"
    (click)="close()"
  ></app-rounded-icon-button>
</div>
