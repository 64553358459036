import { Component, Input } from '@angular/core';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-text-block',
  templateUrl: './text-block.component.html',
})
export class TextBlockComponent {
  @Input() content = '';
  @Input() width = 'small';
  @Input() withoutPadding = false;
  @Input() withoutContainer = false;
  @Input() topMargin = 'none';
  @Input() bottomMargin = 'medium';
  @Input() textColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };
}
