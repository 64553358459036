<app-marketplace-header-atom
  title="Tout Food Society"
  (backEvent)="onBackButtonClick()"
  subtitle="Les 10 restaurants en 1 carte"
  imgUrl="assets/images/food-society/marketplace-header-background.jpg"
></app-marketplace-header-atom>

<div *ngIf="isMarketplaceAvailable$ | async; else notAvailable">
  <div
    *ngIf="categoryBlocks; else loading"
    appScrollSpy
    (sectionChange)="changeTab($event)"
    [ids]="idsCategories"
  >
    <div
      class="sticky top-0 z-10"
      #categoryTabsContainer
    >
      <app-tab-list-atom
        [items]="tabCategories"
        [activeItem]="tab"
        (tabChange)="onTabClick($event)"
      ></app-tab-list-atom>
    </div>
    <ng-container *ngFor="let categoryBlock of categoryBlocks">
      <app-component-factory
        [componentClass]="categoryBlock.componentClass"
        [data]="categoryBlock"
      ></app-component-factory>
    </ng-container>
  </div>

  <ng-template #loading>
    <div class="pt-8">
      <ng-container *ngFor="let i of [].constructor(200)">
        <div class="bg-white flex justify-between py-3 px-5 cursor-pointer max-w-screen-sm h-32">
          <div class="w-full">
            <p class="animate-pulse h-6 bg-neutral-200 rounded w-32 mb-2"></p>
            <p class="animate-pulse h-4 bg-neutral-50 rounded w-44 mb-2"></p>
          </div>
          <div class="relative height-fit-content">
            <div class="animate-pulse rounded bg-neutral-50 h-28 w-28"></div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-template>
</div>

<ng-template #notAvailable>
  <div class="max-w-screen-sm mx-auto px-5 py-5 my-5">
    <p class="font-accent p-4 rounded rounded-sm bg-highlight-50">
      {{ 'shop-page.order-not-available' | translate }}
    </p>
  </div>
</ng-template>
