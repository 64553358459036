import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-payment-panel-adyen-paypal',
  templateUrl: './payment-panel-adyen-paypal.component.html',
})
export class PaymentPanelAdyenPaypalComponent {
  @Input() clientKey?: string;
  @Input() paymentMethodPaypalAvailable = false;
}
