import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Component, Input } from '@angular/core';
import { CartFunnelStoreSelector } from '@common/selectors/cart-funnel.selector';

@Component({
  selector: 'app-cart-funnel-form-block',
  templateUrl: './cart-funnel-form-block.component.html',
})
export class CartFunnelFormBlockComponent {
  @Input() title!: string;
  @Input() blockId!: string;
  @Input() items: unknown[] = [];

  isActive$: Observable<boolean> = this.cartFunnelSelector.selectActiveStepId.pipe(
    map(id => id === this.blockId),
  );

  constructor(private cartFunnelSelector: CartFunnelStoreSelector) {}
}
