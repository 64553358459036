<app-modal-filler-atom [index]="(index$ | async) ?? 0"></app-modal-filler-atom>
<div
  class="fixed right-0 left-0 shadow overflow-y-auto mdMax:bottom-0 mdMax:max-h-85-screen mdMax:max-w-screen-sm mdMax:mx-auto md:flex md:justify-center md:items-center md:w-full md:h-full md:top-0 md:left-0"
  [style.z-index]="((index$ | async) ?? 0) + 50"
>
  <app-modal-filler-atom
    [withBackdrop]="false"
    [index]="(index$ | async) ?? 0"
    *ngIf="settingsSelector.selectDeviceIsDesktopScreen | async"
  ></app-modal-filler-atom>
  <div
    class="bg-white p-5 md:p-7 rounded-t-3xl md:rounded-3xl md:max-w-lg"
    [style.z-index]="((index$ | async) ?? 0) + 50"
  >
    <div *ngIf="!user?.[userKeys.Id] || !user?.[userKeys.Email]">
      <app-authentication-block
        [userJourneyType]="userJourneyType"
        [legalCheckboxLabel]="legalCheckboxLabel"
        [optinCheckboxLabel]="optinCheckboxLabel"
        [firstStepHeading]="firstStepHeading"
        [firstStepLoginButton]="firstStepLoginButton"
        [firstStepSignupButton]="firstStepSignupButton"
        [loginStepHeading]="loginStepHeading"
        [signupStepHeading]="signupStepHeading"
        [signupStepSubheading]="signupStepSubheading"
        [signupStepDisplayFirstname]="signupStepDisplayFirstname"
        [signupStepFirstnameLabel]="signupStepFirstnameLabel"
        [signupStepFirstnameHelp]="signupStepFirstnameHelp"
        [signupStepDisplayLastname]="signupStepDisplayLastname"
        [signupStepLastnameLabel]="signupStepLastnameLabel"
        [signupStepLastnameHelp]="signupStepLastnameHelp"
        [signupStepEmailLabel]="signupStepEmailLabel"
        [signupStepEmailHelp]="signupStepEmailHelp"
        [signupStepDisplayPhone]="signupStepDisplayPhone"
        [signupStepPhoneLabel]="signupStepPhoneLabel"
        [signupStepPhoneHelp]="signupStepPhoneHelp"
        [signupStepPasswordLabel]="signupStepPasswordLabel"
        [signupStepPasswordHelp]="signupStepPasswordHelp"
        [signupFooter]="signupFooter"
        [authenticationProviderGoogle]="authenticationProviderGoogle"
        [authenticationProviderFacebook]="authenticationProviderFacebook"
        [authenticationProviderApple]="authenticationProviderApple"
      ></app-authentication-block>
    </div>
    <div *ngIf="user?.[userKeys.Id] && user?.[userKeys.Email]">
      <p class="mt-5 mb-7 text-neutral-600 font-base">
        {{ 'authentication.already-logged-in.already-logged-in-as-user' | translate: user }}
      </p>
      <div class="pt-1 flex justify-between items-center">
        <div class="mr-2 xm:pr-1 w-full xm:w-1/2">
          <app-button-atom
            size="M"
            [label]="'authentication.already-logged-in.cancel-button' | translate"
            color="primary"
            (click)="close()"
            type="light"
            [full]="true"
          ></app-button-atom>
        </div>
        <div class="xm:pl-1 w-full xm:w-1/2">
          <app-button-atom
            size="M"
            [label]="'authentication.already-logged-in.logout-button' | translate"
            (click)="logout()"
            type="primary"
            [full]="true"
          ></app-button-atom>
        </div>
      </div>
    </div>
  </div>
</div>
