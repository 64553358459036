<div *ngFor="let voucher of voucher$ | async">
  <div
    *ngIf="voucher?.valid"
    class="mx-auto mb-3 max-w-md px-5 lg:max-w-full lg:px-0"
  >
    <app-discount-voucher-atom
      [voucher]="forceAccessName(voucher) ?? voucher.code"
      [isValid]="!!voucher.valid"
    ></app-discount-voucher-atom>
  </div>
</div>
