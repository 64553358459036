import { ObjectUtils } from '@app/utilities/object.util';
import { DoodOrderModel, DoodValidateOrderResponse } from '@store/order/order.model';

export class OrderUtils {
  static isOrderValidationResponse(_order: unknown): _order is DoodValidateOrderResponse {
    return ObjectUtils.isObject(_order) && 'available_payment_methods' in _order;
  }
  static isOrderModel(_order: unknown): _order is DoodOrderModel {
    return ObjectUtils.isObject(_order) && 'id' in _order && 'final_price' in _order;
  }
}
