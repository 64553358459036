<div class="col-span-2">
  <a
    class="flex w-full cursor-pointer flex-col items-start justify-center gap-0.5 overflow-hidden p-4 pr-5"
    [class.rounded-sm]="rounded === 'small'"
    [class.rounded-md]="rounded === 'medium'"
    [class.rounded-lg]="!rounded || rounded === 'large'"
    [class.rounded-2xl]="rounded === '2xl'"
    [class.rounded-3xl]="rounded === '3xl'"
    [class.rounded-4xl]="rounded === '4xl'"
    [class.rounded-full]="rounded === 'full'"
    [class.shadow-S]="!shadow || shadow === 'small'"
    [class.shadow-md]="shadow === 'medium'"
    [class.shadow-lg]="shadow === 'large'"
    [style.aspect-ratio]="aspectRatio"
    [style.backgroundColor]="backgroundColor | contentBuilderColor: '#E6BE2A'"
  >
    <h2
      class="font-accent text-sm font-semibold md:text-base"
      [style.color]="textColor | contentBuilderColor: 'neutral-800'"
    >
      {{ heading | translate }}
    </h2>
    <p
      class="font-base text-sm font-light md:text-base"
      [style.color]="textColor | contentBuilderColor: 'neutral-800'"
    >
      {{ text | translate }}
    </p>
  </a>
</div>
