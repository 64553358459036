<div
  class="absolute top-0 w-32 h-32 md:w-48 md:h-48 z-30 rounded-b-lg p-3.5 shadow shadow-lg"
  [style.backgroundColor]="backgroundColor | contentBuilderColor: '#FFFFFF'"
  (click)="back()"
>
  <img
    class="w-full h-full object-contain"
    *ngIf="logo"
    [src]="logo.url"
    alt="logo"
  />
</div>
<div
  *ngIf="isStatic"
  class="w-32 md:w-48 mr-12"
></div>
