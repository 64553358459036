import { ReducerTypes, ActionCreator, on } from '@ngrx/store';

import { DoodShopModel } from '@core/models/shop.model';
import { createActiveStorePiece } from '@store/_abstract/active';

import {
  ShopState,
  SHOP_STORE_ACTIVE_ADAPTER,
  SHOP_STORE_INITIAL_SLOTS_STATE,
  SHOP_STORE_INITIAL_STATE,
} from './shop.state';
import { SHOP_STORE_ACTIVE_ACTIONS, SHOP_STORE_LOCAL_ACTIONS } from './shop.action';

export const SHOP_STORE_API_PIECE: ReducerTypes<ShopState, readonly ActionCreator[]>[] = [];

export const SHOP_STORE_LOCAL_PIECE: ReducerTypes<ShopState, readonly ActionCreator[]>[] = [
  // Slots
  on(SHOP_STORE_LOCAL_ACTIONS.addSlots, (state, { slots }): ShopState => {
    const _slots = Array.from(new Set([...state.slots, ...slots]));
    return { ...state, slots: _slots };
  }),
  on(SHOP_STORE_LOCAL_ACTIONS.removeSlots, (state, { slots }): ShopState => {
    const _slots = Array.from(state.slots).filter(_slot => slots.includes(_slot));
    return { ...state, slots: _slots };
  }),
  on(SHOP_STORE_LOCAL_ACTIONS.setSlots, (state, { slots }): ShopState => {
    return { ...state, slots };
  }),
  on(SHOP_STORE_LOCAL_ACTIONS.resetSlots, (state): ShopState => {
    return { ...state, slots: SHOP_STORE_INITIAL_SLOTS_STATE };
  }),

  // Status
  on(SHOP_STORE_LOCAL_ACTIONS.updateIsLoaded, (state, { isLoaded }): ShopState => {
    return { ...state, isLoaded };
  }),
  on(SHOP_STORE_LOCAL_ACTIONS.updateIsLoading, (state, { isLoading }): ShopState => {
    return { ...state, isLoading };
  }),

  // Misc
  on(SHOP_STORE_LOCAL_ACTIONS.reset, (): ShopState => {
    return SHOP_STORE_INITIAL_STATE;
  }),
];

export const SHOP_STORE_ACTIVE_PIECE = createActiveStorePiece<ShopState, DoodShopModel, string>({
  actions: SHOP_STORE_ACTIVE_ACTIONS,
  adapter: SHOP_STORE_ACTIVE_ADAPTER,
});
