import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable, throwError } from 'rxjs';
import { catchError, map, take, takeUntil, tap } from 'rxjs/operators';

import { CartKeys } from '@config/keys/cart.keys';
import { ComoUser } from '@core/models/como.model';
import { DoodOrderModel } from '@store/order/order.model';

import { CartService } from '@core/services/cart/cart.service';
import { ComoService } from '@core/services/como/como.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { OrdersService } from '@core/services/orders/orders.service';

import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { AlertModalComponent } from '@shared/modals/alert-modal/alert-modal.component';
import { OrderStoreSelector } from '@common/selectors/order.selector';

@Component({
  selector: 'app-como-use-credit-balance-modal',
  templateUrl: './como-use-credit-balance-modal.component.html',
})
export class ComoUseCreditBalanceModalComponent extends DestroyerBase implements OnInit {
  static handle = 'como-use-credit-balance-modal';

  index$: Observable<number> = this.modalSelector
    .selectModal(ComoUseCreditBalanceModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  creditBalance = 0;
  amountToUse = 0;
  maxAmountToUse = 0;
  verificationCode = '';
  verificationCodeRequired = false;
  isDataLoaded = false;
  isValidating = false;

  private readonly orderValidate$ = this.orderSelector.selectValidation;
  // private readonly orderValidate$ = this.ordersQuery.orderValidate$;

  constructor(
    private orderSelector: OrderStoreSelector,
    private modalSelector: ModalStoreSelector,
    private readonly comoService: ComoService,
    private readonly cartService: CartService,
    private readonly modalsService: ModalsService,
    private readonly ordersService: OrdersService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.comoService.loadComoUser();

    combineLatest([
      this.comoService.getComoUser$(),
      this.comoService.sendPaymentVerificationCode$(),
      this.orderValidate$,
    ])
      .pipe(
        takeUntil(this._destroyerRef),
        take(1),
        tap(([comoUser, verificationCodeSent, order]) =>
          this.dataLoaded(verificationCodeSent, comoUser, order as DoodOrderModel),
        ),
      )
      .subscribe();
  }

  close(): void {
    this.modalsService.close(ComoUseCreditBalanceModalComponent.handle);
  }

  apply(): void {
    this.isValidating = true;
    this.cartService.setUsePoints({
      [CartKeys.UsePointsAmount]: Math.round(this.amountToUse * 100),
      [CartKeys.UsePointsVerificationCode]: this.verificationCodeRequired
        ? this.verificationCode
        : undefined,
    });
    this.ordersService
      .checkCartIsValid$()
      .pipe(
        tap(() => this.close()),
        catchError(error => {
          this.modalsService.open(AlertModalComponent.handle);
          this.modalsService.setData(AlertModalComponent.handle, {
            message: error.error.message,
          });
          return throwError(() => error);
        }),
      )
      .subscribe();
  }

  private dataLoaded(
    verificationCodeSent: boolean,
    comoUser?: ComoUser | null,
    order?: DoodOrderModel,
  ): void {
    this.creditBalance = comoUser?.credit_balance?.monetary || 0;
    const amountUsed = order?.use_points?.amount || 0;
    const orderTotal = (order?.final_price || 0) + amountUsed;
    this.maxAmountToUse = Math.min(this.creditBalance, orderTotal);
    this.amountToUse = this.maxAmountToUse / 100;
    this.verificationCodeRequired = verificationCodeSent;
    this.isDataLoaded = true;
  }
}
