import { Store } from '@ngrx/store';
import { map, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';

import { DoodFilterModel } from '@core/models/filters.model';
import { DoodEntityCategory } from '@core/models/entity.model';

import {
  SELECT_FILTERS_ALL,
  SELECT_FILTERS_CATEGORIES,
  SELECT_FILTERS_ENTITY,
} from '@store/filter/filter.selection';

@Injectable({ providedIn: 'root' })
export class FilterStoreSelector implements OnDestroy {
  private readonly _destroyerRef = new Subject<boolean>();

  filters: DoodFilterModel[] = [];
  categories: DoodEntityCategory[] = [];

  selectCategories = this.store.select(SELECT_FILTERS_CATEGORIES);

  selectFilters = this.store.select(SELECT_FILTERS_ALL);
  selectFilter = (id: string): Observable<DoodFilterModel | undefined> =>
    this.store.select(SELECT_FILTERS_ENTITY(id));

  selectActiveFilters = this.selectFilters.pipe(
    map(_filters => _filters.filter(filter => filter.values?.length)),
  );
  selectActiveFiltersLength = this.selectFilters.pipe(map(_filters => _filters.length));

  constructor(private store: Store) {
    this.selectFilters.pipe(takeUntil(this._destroyerRef)).subscribe(_filters => {
      this.filters = _filters;
    });
    this.selectCategories.pipe(takeUntil(this._destroyerRef)).subscribe(_categories => {
      this.categories = _categories;
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe();
  }

  private _unsubscribe(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
  }
}
