import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';

import { DOOD_URL } from '@config/ws.config';
import { Paths } from '@config/paths.config';

import { SlugUtils } from '@shared/utils/slug/slug.utils';
import { DateUtils } from 'src/app/shared/utils/date/date.utils';

import { VenueKeys } from '@config/keys/venue.keys';
import { FilterKeys } from '@config/keys/filter.keys';
import { EntityKeys } from '@config/keys/entity.keys';

import { ApiParametersKeys } from '@config/keys/parameters.keys';
import { FilterStoreSelector } from '@common/selectors/filter.selector';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { EntityStoreDispatcher } from '@common/dispatchers/entity.dispatcher';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

import { SettingsParametersState } from '@store/settings/settings.state';
import { EntitiesApiService } from '@core/services/api/entities/entities-api.service';

import { DoodFilterModel } from '@core/models/filters.model';
import { DoodEntityCategory, DoodEntityModel, DoodEntityType } from '@core/models/entity.model';

@Injectable({
  providedIn: 'root',
})
export class EntitiesService {
  private allEntitiesByMarketplace = new Map<string, Subject<DoodEntityModel[]>>();
  private debouncedLoadAllByMarketplace = new Map<string, Subject<DoodEntityModel[]>>();

  constructor(
    private filterSelector: FilterStoreSelector,
    private entitiesApiService: EntitiesApiService,
    private settingsSelector: SettingsStoreSelector,
    private entityDispatcher: EntityStoreDispatcher,
    private marketplaceSelector: MarketplaceStoreSelector,
  ) {}

  public loadAll$(marketplaceId: string): Subject<DoodEntityModel[]> {
    // Share the same observable
    let requestSubject$ = this.debouncedLoadAllByMarketplace.get(marketplaceId);
    let subject$ = this.allEntitiesByMarketplace.get(marketplaceId);

    if (!requestSubject$ || !subject$) {
      requestSubject$ = new Subject<DoodEntityModel[]>();
      subject$ = new Subject<DoodEntityModel[]>();

      combineLatest([
        requestSubject$.pipe(
          debounceTime(50),
          switchMap(() => this.entitiesApiService.get$(marketplaceId)),
          tap(entities => this.entityDispatcher.setAll(entities)),
        ),
        of(subject$),
      ])
        .pipe(tap(([entities, bindedSubject$]) => bindedSubject$.next(entities)))
        .subscribe();
      this.debouncedLoadAllByMarketplace.set(marketplaceId, requestSubject$);
      this.allEntitiesByMarketplace.set(marketplaceId, subject$);
    }

    requestSubject$.next([]);

    return subject$;
  }

  public loadProximityEntities$(): Observable<DoodEntityModel[]> {
    return combineLatest([
      this.marketplaceSelector.selectMarketplace,
      this.settingsSelector.selectParameters,
      this.filterSelector.selectFilters,
      this.filterSelector.selectCategories,
    ]).pipe(
      switchMap(([marketplace, parameters, filters, categories]) => {
        const params = this.retrieveEntitiesParameters(parameters, filters, categories);

        return this.entitiesApiService
          .get$(marketplace.id, params)
          .pipe(tap(entities => this.entityDispatcher.setAll(entities)));
      }),
      catchError(error => {
        console.error(error);
        return of([]);
      }),
    );
  }

  public loadEntitiesCategories$(marketplaceId: string): Observable<DoodEntityCategory[]> {
    return this.entitiesApiService.getEntitiesCategories$(marketplaceId).pipe(
      map(items =>
        items.map(item => {
          return {
            ...item,
            icon_url: item.icon_url ? `${DOOD_URL}${item.icon_url}` : '',
          };
        }),
      ),
    );
  }

  private retrieveEntitiesParameters(
    parameters: SettingsParametersState,
    filters: DoodFilterModel[],
    categories?: DoodEntityCategory[],
  ): HttpParams {
    let params = new HttpParams();
    if (filters.length) {
      filters.map(filter => {
        if (filter.id === FilterKeys.Distance) {
          if (filter.values?.length) {
            params = params.append(ApiParametersKeys.MaxDistance, filter.values[0].value);
          }
        } else if (filter.id === FilterKeys.PriceRange && filter.values?.length) {
          const pricing = filter.values?.map(pricing => pricing.value);
          pricing.forEach(
            (pricing: string) =>
              (params = params.append(`${ApiParametersKeys.Pricing}[]`, pricing.toUpperCase())),
          );
        }
      });
    }

    if (parameters.distribution_mode) {
      params = params.append('order_type', parameters.distribution_mode);
    }
    if (parameters.location) {
      params = params.append(ApiParametersKeys.Latitude, parameters.location?.lat as number);
      params = params.append(ApiParametersKeys.Longitude, parameters.location?.lng as number);
    }
    // tslint:disable-next-line:max-line-length
    const wantedAt = parameters.wanted_at;
    if (wantedAt) {
      params = params.append(
        ApiParametersKeys.WantedAt,
        DateUtils.dayjsInMarketplaceTimezone(wantedAt).toISOString() as string,
      );
    }

    if (categories?.length) {
      const categoryIds = categories.map(category => category.id);
      categoryIds.forEach(
        (id: string) => (params = params.append(`${ApiParametersKeys.Types}[]`, id)),
      );
    }

    if (
      !params.has(ApiParametersKeys.MaxDistance) &&
      params.has(ApiParametersKeys.Latitude) &&
      params.has(ApiParametersKeys.Longitude)
    ) {
      params =
        parameters.distance && parameters.distance >= 0 && !parameters.location?.[VenueKeys.Address]
          ? params.append(ApiParametersKeys.MaxDistance, parameters.distance)
          : params.append(ApiParametersKeys.MaxDistance, 15000);
    }

    return params;
  }

  getUrl(entity: DoodEntityModel): string {
    if (
      entity &&
      entity[EntityKeys.EntityType] &&
      entity[EntityKeys.EntityType] === DoodEntityType.Shop
    ) {
      return `${Paths.Shops}/${SlugUtils.formatSlug(entity[EntityKeys.Type]?.[EntityKeys.Name])}/${entity[EntityKeys.Slug]}`;
    }

    if (
      entity &&
      entity[EntityKeys.EntityType] &&
      entity[EntityKeys.EntityType] === DoodEntityType.Marketplace
    ) {
      const mainDomain = entity[EntityKeys.Domains]?.[0];
      if (!mainDomain) {
        console.error('No main domain defined', entity);
        return '';
      }
      return 'https://' + mainDomain;
    }

    console.error('Unknown entity type', entity);
    return '';
  }

  getFullAddress(entity: DoodEntityModel): string {
    let fullAddress = '';
    let address = entity[EntityKeys.Venue]?.address;

    if (entity[EntityKeys.Address]) {
      address = entity[EntityKeys.Address];
    }

    if (address?.street_line1) {
      fullAddress += address?.street_line1 + ', ';
    }

    if (address?.postal_code) {
      fullAddress += address?.postal_code + ' ';
    }

    if (address?.city) {
      fullAddress += address?.city;
    }

    return fullAddress;
  }
}
