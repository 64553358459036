import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-legals-footer-link-block',
  templateUrl: './legals-footer-link-item-block.component.html',
  styleUrls: ['./legals-footer-link-item-block.component.scss'],
})
export class LegalsFooterLinkItemBlockComponent {
  @Input() name!: string;
  @Input() link!: string;
  @Input() openInNewTab?: boolean;

  private isJavaScriptLink(): boolean {
    return this.link.startsWith('javascript:');
  }

  get validLink(): string {
    return this.isJavaScriptLink() ? '' : this.link;
  }

  protected onHandleJavascriptLink(event: Event) {
    if (this.isJavaScriptLink()) {
      event.preventDefault();
      eval(this.link);
    }
  }
}
