import { Directive, HostListener, EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[appKeyboardListener]',
  standalone: true,
})
export class KeyboardListenerDirective {
  @Output() textBuffered = new EventEmitter<string>();

  private keyBuffer: string = '';
  private keySubject = new Subject<string>();

  constructor() {
    this.keySubject.pipe(debounceTime(1000)).subscribe(() => {
      if (this.keyBuffer) {
        this.textBuffered.emit(this.keyBuffer);
        this.keyBuffer = '';
      }
    });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key.length === 1) {
      this.keyBuffer += event.key;
    } else if (event.key === 'Backspace' && this.keyBuffer.length > 0) {
      this.keyBuffer = this.keyBuffer.slice(0, -1);
    }

    this.keySubject.next(this.keyBuffer);
  }
}
