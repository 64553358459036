import { interval } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { switchMap, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { DoodOrderModel } from '@store/order/order.model';
import { OrderStoreSelector } from '@common/selectors/order.selector';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';

import { CartService } from '@core/services/cart/cart.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { NotificationService } from '@core/services/notification/notification.service';
import { EventTrackerService } from '@core/services/event-tracker/event-tracker.service';
import { NavigationHistoryService } from '@core/services/navigation-history/navigation-history.service';
import { KioskService } from '@core/services/kiosk/kiosk.service';

@Component({
  selector: 'app-order-status-page',
  templateUrl: './order-status-page.component.html',
})
export class OrderStatusPageComponent extends DestroyerBase implements OnInit, OnDestroy {
  orderAlreadyTracked = false;

  @Input() body!: unknown[];

  constructor(
    private orderSelector: OrderStoreSelector,
    private readonly cartService: CartService,
    private readonly ordersService: OrdersService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly notificationService: NotificationService,
    private readonly eventTrackerService: EventTrackerService,
    private readonly navigationHistoryService: NavigationHistoryService,
    private readonly kioskService: KioskService,
  ) {
    super();
    const orderId = this.activatedRoute.snapshot.params.orderId;

    const urlAuthTokenSearchParams = new URLSearchParams(window.location.search);
    let shareCode: string | undefined;
    if (urlAuthTokenSearchParams.has('share_code')) {
      shareCode = urlAuthTokenSearchParams.get('share_code') ?? undefined;
    }

    this.orderSelector
      .selectOrder(orderId)
      .pipe(
        takeUntil(this._destroyerRef),
        tap(order => this.orderConfirmedEvent(order)),
      )
      .subscribe();

    // Fallback if websocket is not available
    interval(30000)
      .pipe(
        takeUntil(this._destroyerRef),
        switchMap(() => this.ordersService.loadOrderById$(orderId, shareCode)),
        switchMap(() => this.orderSelector.selectOrder(orderId)),
        takeWhile(order => !order || !['CANCELLED', 'COLLECTED'].includes(order.status)),
      )
      .subscribe();

    this.loadOrder(orderId, shareCode);
  }

  orderConfirmedEvent(order: DoodOrderModel | undefined): void {
    if (
      !this.orderAlreadyTracked &&
      order?.status &&
      ['WAITING', 'PREPARATION', 'READY', 'COLLECTED'].includes(order.status)
    ) {
      this.kioskService.printOrder$(order).subscribe();
      this.eventTrackerService.dispatch(EventTrackerService.EventOrderPaid, order);
      this.orderAlreadyTracked = true;
    }
  }

  ngOnInit(): void {
    // Do not display order notification on this page
    this.notificationService.setHandleNotifications(false);
    this.navigationHistoryService.pushState('home', '/');
    this.navigationHistoryService.pushStateFromActivatedRouteSnapshot(
      'order-status',
      this.activatedRoute.snapshot,
    );

    this.cartService.clearCart();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    // Restore notification display when leaving this page
    this.notificationService.setHandleNotifications(true);
    this.ordersService.clearOrders();
  }

  loadOrder(orderId: string, shareCode?: string): void {
    this.ordersService.deActiveOrderToStore();
    this.ordersService.setLoadingToStore();
    this.ordersService
      .loadOrderById$(orderId, shareCode)
      .pipe(
        takeUntil(this._destroyerRef),
        tap(order => this.ordersService.setActiveOrderToStore(order)),
      )
      .subscribe();
  }
}
