import { createFeature, createSelector } from '@ngrx/store';

import { BASKET_STORE_KEY } from './basket.state';
import { BASKET_STORE_REDUCER } from './basket.reducer';

const BASKET_STORE_FEATURE = createFeature({
  name: BASKET_STORE_KEY,
  reducer: BASKET_STORE_REDUCER,
});

export const { selectBasket: SELECT_BASKET_CART, selectBasketState: SELECT_BASKET_STATE } =
  BASKET_STORE_FEATURE;

export const SELECT_BASKET_ID = createSelector(SELECT_BASKET_CART, cart => cart.id);
export const SELECT_BASKET_USER = createSelector(SELECT_BASKET_CART, cart => cart.user);
export const SELECT_BASKET_SUB_CARTS = createSelector(SELECT_BASKET_CART, cart => cart.sub_carts);
export const SELECT_BASKET_CART_ITEMS = createSelector(SELECT_BASKET_CART, cart => cart.cart_items);
export const SELECT_BASKET_CONFIRMED_BY = createSelector(
  SELECT_BASKET_CART,
  cart => cart.confirmed_by,
);
export const SELECT_BASKET_ON_SITE_LOCATION_ID = createSelector(
  SELECT_BASKET_CART,
  cart => cart.on_site_location_id,
);
