<app-modal-filler-atom
  (click)="close()"
  [index]="(index$ | async) ?? 0"
></app-modal-filler-atom>
<div
  class="fixed right-0 left-0 shadow overflow-y-auto mdMax:bottom-0 mdMax:max-h-85-screen mdMax:max-w-screen-sm mdMax:mx-auto md:flex md:justify-center md:items-center md:w-full md:h-full md:top-0 md:left-0"
  [style.z-index]="((index$ | async) ?? 0) + 50"
>
  <div
    class="bg-white p-5 md:p-7 rounded-t-3xl md:rounded-3xl md:max-w-lg md:w-2/5"
    [style.z-index]="((index$ | async) ?? 0) + 50"
  >
    <h1
      class="text-neutral-800 font-brand font-bold text-28px pb-2"
      translate="lydia.phone-number-modal.heading"
    ></h1>
    <p class="text-neutral-800 font-base text-base pb-2">
      {{ 'lydia.phone-number-modal.description' | translate }}
    </p>
    <div class="flex-1 mr-2.5 pb-6">
      <p class="mb-2 text-neutral-600 font-base">
        {{ 'lydia.phone-number-modal.phone-number-input-label' | translate }}
      </p>
      <div class="text-neutral-800 leading-5 pb-6">
        <form [formGroup]="form">
          <input
            type="tel"
            #phoneNumber
            formControlName="phoneNumber"
            class="w-full border border-neutral-800 rounded py-4.5 px-5"
          />
        </form>
      </div>
    </div>

    <div class="flex flex-col gap-10">
      <app-button-atom
        size="L"
        [label]="'lydia.phone-number-modal.continue-button' | translate"
        type="primary"
        [full]="true"
        (click)="save()"
      ></app-button-atom>
    </div>
  </div>
</div>
