import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';

import { DoodDeliveryAddressModel } from '@core/models/delivery-address.model';

import {
  SELECT_DELIVERY_ACTIVE,
  SELECT_DELIVERY_ACTIVE_ID,
  SELECT_DELIVERY_ADDRESSES_ALL,
  SELECT_DELIVERY_ADDRESS_ENTITY,
  SELECT_DELIVERY_IS_LOADED,
  SELECT_DELIVERY_IS_LOADING,
} from '@store/delivery/delivery.selection';

@Injectable({ providedIn: 'root' })
export class DeliveryStoreSelector implements OnDestroy {
  private readonly _destroyerRef = new Subject<boolean>();

  addresses: DoodDeliveryAddressModel[] = [];
  active: DoodDeliveryAddressModel | null = null;

  selectAddresses = this.store.select(SELECT_DELIVERY_ADDRESSES_ALL);
  selectAddress = (id: string): Observable<DoodDeliveryAddressModel | undefined> =>
    this.store.select(SELECT_DELIVERY_ADDRESS_ENTITY(id));

  selectIsLoaded = this.store.select(SELECT_DELIVERY_IS_LOADED);
  selectIsLoading = this.store.select(SELECT_DELIVERY_IS_LOADING);

  selectActive = this.store.select(SELECT_DELIVERY_ACTIVE);
  selectActiveId = this.store.select(SELECT_DELIVERY_ACTIVE_ID);

  constructor(private store: Store) {
    this.selectAddresses.pipe(takeUntil(this._destroyerRef)).subscribe(_addresses => {
      this.addresses = _addresses;
    });
    this.selectActive.pipe(takeUntil(this._destroyerRef)).subscribe(_active => {
      this.active = _active;
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe();
  }

  private _unsubscribe(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
  }
}
