import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { DoodBlockModel } from '@core/models/block.model';
import { DoodModalConfig } from '@core/models/marketplace.model';

import { DoodApiError } from '@shared/interfaces/error.interface';

export interface ContentBlockState extends EntityState<DoodBlockModel> {
  isLoaded: boolean;
  isLoading: boolean;
  error: DoodApiError | null;
}

export interface ContentModalState extends EntityState<DoodModalConfig> {
  isLoaded: boolean;
  isLoading: boolean;
  error: DoodApiError | null;
}

export interface ContentHeaderState {
  title: string | null;
  // Cart
  isCartButtonVisible: boolean;
  isCartParametersVisible: boolean;
  // Search
  isShopSearchAddressVisible: boolean;
  isShopSearchParametersVisible: boolean;
}

export interface ContentProductModalState {
  shopId: string | null;
  productId: string | null;
  shopIsNotActive: boolean;
  categoryId: string | undefined;
}

export interface ContentState {
  modals: ContentModalState;
  blocks: ContentBlockState;
  header: ContentHeaderState;
  product: ContentProductModalState;
}

export const CONTENT_STORE_KEY = 'Content';

export const CONTENT_STORE_BLOCK_ADAPTER = createEntityAdapter<DoodBlockModel>();

export const CONTENT_STORE_MODAL_ADAPTER = createEntityAdapter<DoodModalConfig>({
  selectId: _modal => _modal.handle,
});

export const CONTENT_STORE_BLOCK_INITIAL_STATE = CONTENT_STORE_BLOCK_ADAPTER.getInitialState({
  error: null,
  isLoaded: false,
  isLoading: false,
});

export const CONTENT_STORE_MODAL_INITIAL_STATE = CONTENT_STORE_MODAL_ADAPTER.getInitialState({
  error: null,
  isLoaded: false,
  isLoading: false,
});

export const CONTENT_STORE_HEADER_INITIAL_STATE: ContentHeaderState = {
  title: null,
  isCartButtonVisible: false,
  isCartParametersVisible: false,
  isShopSearchAddressVisible: false,
  isShopSearchParametersVisible: false,
};

export const CONTENT_STORE_PRODUCT_INITIAL_STATE: ContentProductModalState = {
  shopId: null,
  productId: null,
  shopIsNotActive: false,
  categoryId: undefined,
};

export const CONTENT_STORE_INITIAL_STATE: ContentState = {
  modals: CONTENT_STORE_MODAL_INITIAL_STATE,
  blocks: CONTENT_STORE_BLOCK_INITIAL_STATE,
  header: CONTENT_STORE_HEADER_INITIAL_STATE,
  product: CONTENT_STORE_PRODUCT_INITIAL_STATE,
};
