export interface DoodCartFunnelStep {
  id: string;
  index: number;
}

export interface DoodCartFunnelValue<Value = string | number | boolean> {
  id: string;
  title: string;
  formId: string;
  required: boolean;

  type?: string;
  value?: Value;
  placeholder?: string;
  target?: DoodCartFunnelValueTarget;

  displayTitle?: boolean;
  displayTitleInComment?: boolean;

  customTitle?: string;
  displayCustomTitleInComment?: boolean;
}

export enum DoodCartFunnelValueTarget {
  OrderComment = 'ORDER_COMMENT',
  OnBehalfUserFullName = 'ON_BEHALF_USER_FULL_NAME',
  OnBehalfUserEmail = 'ON_BEHALF_USER_EMAIL',
  OnBehalfUserPhone = 'ON_BEHALF_USER_PHONE',
  OnBehalfUserOptin = 'ON_BEHALF_USER_OPTIN',
}
