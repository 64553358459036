import {Component, Input} from '@angular/core';
import {IContentBuilderFieldImage} from "@core/models/content-builder-fields.model";

@Component({
  selector: 'app-simple-header-image-item-block',
  templateUrl: './simple-header-image-item-block.component.html',
  styles: []
})
export class SimpleHeaderImageItemBlockComponent {

  @Input() image?: IContentBuilderFieldImage;
  @Input() linkUrl?: string;
  @Input() linkTarget = '_self';

}

