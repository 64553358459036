<div
  (mousedown)="this.openModalIfDelivery($event)"
  (click)="this.openModalIfDelivery($event)"
>
  <app-input-text-atom
    [label]="'parameters.location-placeholder' | translate"
    [iconRight]="'icon-position'"
    [value]="(filterAddress$ | async) ?? ''"
    [cross]="true"
    (iconClick)="geolocalizeUser()"
    (valueChange)="onValueChange()"
  ></app-input-text-atom>
</div>
<div
  *ngIf="geolocalizeError?.length"
  class="pt-2 text-error-700 font-base text-sm"
>
  {{ geolocalizeError }}
</div>
