import { DoodShopModel } from '@core/models/shop.model';
import { ICartValidateItem, ICartValidateItemProduct } from '@core/models/order.model';

// Only on Order Validate
export interface DoodTipModel {
  amount: number;
}

export interface DoodMetafieldModel {
  id: string;
  value: string;
  handle: string;
}

export enum DoodOrderStatus {
  ready = 'READY',
  unpaid = 'UNPAID',
  payment = 'PAYMENT',
  waiting = 'WAITING',
  cancelled = 'CANCELLED',
  collected = 'COLLECTED',
  preparation = 'PREPARATION',
  processingPayment = 'PROCESSING_PAYMENT',
}

export interface DoodAddressPointModel {
  coordinates: number[];
}

export interface DoodPickupLocationModel {
  city: string;
  post_code: string;
  street_line1: string;
  street_line2: string;
  point: DoodAddressPointModel | null;
}

export interface DoodOrderAvailablePaymentMethodModel {
  valid: boolean;
  violations: string[];
  payment_method: DoodPaymentMethodTypeModel;
}

export interface DoodPaymentMethodTypeModel {
  handle: string;
  position: number;
}

export interface DoodOrderTipModel {
  amount: number;
}

export interface DoodOrderViolationModel {
  message: string;
  propertyPath: string;
}

// NEW
export enum DoodAppScreen {
  mobile = 'mobile',
  tablet = 'tablet',
  desktop = 'desktop',
}

export enum DoodAppDevice {
  mobile = 'mobile',
  tablet = 'tablet',
  desktop = 'desktop',
}

export enum DoodOrderPaymentInvoiceGenerationStatus {
  pending = 'PENDING',
  generated = 'GENERATED',
}

export enum DoodAppPlatform {
  iOs = 'ios',
  web = 'web',
  android = 'android',
}

export const DoodOrderType = {
  delivery: 'delivery',
  clickAndCollect: 'click-and-collect',
  shipping: 'shipping',
  onSite: 'on-site',
  eatIn: 'eat-in',
} as const;

export type DoodOrderType = (typeof DoodOrderType)[keyof typeof DoodOrderType];

export interface DoodOrderUserModel {
  full_name: string | null;
  email: string | null;
  phone: string | null;
  optin: boolean | null;
}

export interface DoodUsePointModel {
  amount: number;
  verification_code: string | null;
}

export interface DoodTipModel {
  amount: number;
}

export interface DoodOrderLineItemModel {
  line_id: number;
  type: string;
  level: number;
  title: string;
  quantity: number;
  unit_price: number;
  price?: number;

  subtype: string;
  currency: string;
  tax_rate: number;
  price_included: boolean;
  tax_price: number | null;
  price_gross: number | null;

  image: string | null;
  shop_id: string | null;
  product_id: string | null;
  description: string | null;
  delivery_price_amount: number;
}

// TODO: Check with Maxime and Xavier
export interface DoodAddressPointModel {
  coordinates: number[];
}

export interface DoodAddressModel {
  addressee?: string;
  street_line_1: string;
  post_code: string | null;
  city: string;
  country?: string;
  point?: DoodAddressModel;
}

export interface DoodTemporaryCouponModel {
  valid?: boolean;
  key: string | null;
  code: string | null;
  error: string | null;
}

export interface DoodCouponModel extends DoodTemporaryCouponModel {
  balance: number | null;
  amount_off: number | null;
  deal_code: string | null;
  item_code: string | null;
  name: string | null;
  percent_off: number | null;
  type: string | null;
  valid: boolean;
}

export interface IOrderItem {
  id: string;
  shop_id: string;
  additions?: IOrderItemAddition;
  products?: ICartValidateItemProduct[];
  quantity?: number;
  name?: string;
  is_service_charge?: boolean;
  final_price?: number;
  type?: string;
  categories?: IOrderItemCategory[];
  addition_group?: string;
}

export interface IOrderItemAddition {
  products: string[];
  items: IOrderItem[];
}

export interface IOrderItemCategory {
  products: IOrderItem[];
  store_id: string;
}

// EQ: IOrderValidate
export interface DoodTemporaryOrderModel<Shop = string | null> {
  message: string | null;
  onsite_location_id: string | null; // ID du numero de table dans le cadre d'une commande en service à table (si Service à table; sinon null)(donc si type === OrderTypeValues.OnSite)
  user: string | null;
  type: DoodOrderType;
  products: IOrderItem[];
  marketplace: string;
  multi_shop: boolean | null; // Si true alors forcément child
  // TODO: Later rename to shop_id
  shop: Shop; // ID du shop, si multishop alors null
  app_platform: DoodAppPlatform; // Type de platform
  customer_device: DoodAppDevice;
  coupons: DoodTemporaryCouponModel[];
  use_points?: DoodUsePointModel; // On API side, this should be an array or undefined
  wanted_at: Date | null;

  final_price: number; // ! Not sure about this one

  cart_id: string;
  cart_created_at: Date;
  delivery_comment?: string | null;
  contact_phone_number: string | null;
  estimated_delivery_duration: number | null; // Only when DoodOrderType.delivery
  delivery_address: Partial<DoodAddressModel> | null;
  instructions: string | null;

  kiosk: string | null; // Si commande via borne
  payment_phone_number: string | null;
  tip: DoodTipModel | null;
  on_behalf_of: DoodOrderUserModel | null; // Si anonyme, on peut te poser la quesiton sur ton nom prénom email phone etc...
}

// Define partial Order for validate and create
// Valider la commande (quand don voit le panier, on ajoute un produits etc...), Permet d'avoir une prévisualisation de la commande (avant sa création), vérifie la dispo des produits etc...
export interface DoodValidateOrderQuery extends Omit<Partial<DoodTemporaryOrderModel>, 'products'> {
  register_user_to_como: boolean | null; // Permet de créer un compte fidélité en meme temps que la commande
  products?: ICartValidateItem[];
}

export interface DoodValidateOrderResponse extends DoodValidateOrderQuery {
  available_payment_methods: DoodOrderAvailablePaymentMethodModel[];
}

// Page panier
export interface DoodCreateOrderQuery extends DoodValidateOrderQuery {
  payment_service: string | null; // Service de paiement
  cart?: string;
}

export interface DoodOrderModel extends DoodTemporaryOrderModel<DoodShopModel | null> {
  id: string;
  ready_at: Date | null; // ?
  created_at: Date;
  cancellation_reason: string | null;
  register_user_to_como: boolean;
  payment_service: string;
  multi_shop: boolean;
  coupons_amount_off: number;

  // Delivery
  delivery_address: DoodAddressModel | null;
  delivery_distance: number | null;
  delivery_participation_amount: number | null;
  delivery_price_amount: number | null;
  delivery_price_currency: string | null;
  delivery_provider: string | null;
  delivery_tax: number | null;

  discounted_tax: number;
  discounted_price_exc_tax: number;
  discounted_price_inc_tax: number;

  estimated_time_of_arrival: Date | null;
  estimated_delivery_duration: number | null;

  final_price: number;
  total?: number;
  currency: string;
  line_items: DoodOrderLineItemModel[];
  preparation_time: number | null;

  regular_price_exc_tax: number;
  regular_price_inc_tax: number;
  regular_tax: number;

  child_orders?: DoodOrderModel[];

  status: DoodOrderStatus;
  number: string;
  shop: DoodShopModel | null;
  parent_order_id: string | null; // ?
  pickup_location: DoodPickupLocationModel | null;

  metafields: DoodMetafieldModel[] | null;
  paid_amount: number | null;
  payments?: DoodOrderPaymentModel[];
}

export interface DoodOrderPaymentModel {
  amount: number;
  id: string;
  invoice_file_path?: string;
  invoice_generation_status: DoodOrderPaymentInvoiceGenerationStatus;
  invoice_number: string;
}

export interface DoodOrderModelTrash {
  // Trash
  white_label: boolean;
}

// EQ: IResponseOrderValidate

// DoodTemporaryOrderModel -> DoodValidateOrderModel -> DoodCreateOrderModel -> DoodCreateOrderResponseModel(DoodOrderModel)
