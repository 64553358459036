import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { DoodMarketplaceModel } from '@core/models/marketplace.model';
import { createHydratedStoreActions } from '@store/_abstract/hydrated';

import { MARKETPLACE_STORE_KEY, MarketplaceState } from './marketplace.state';

export const MARKETPLACE_STORE_API_ACTIONS = createActionGroup({
  source: 'Dood/API/Marketplace',
  events: {},
});

export const MARKETPLACE_STORE_LOCAL_ACTIONS = createActionGroup({
  source: 'Dood/LOCAL/Marketplace',
  events: {
    // Is Loaded
    'Update Is Loaded': props<{ isLoaded: boolean }>(),
    'Reset Is Loaded': emptyProps(),

    // Is Loading
    'Update Is Loading': props<{ isLoading: boolean }>(),
    'Reset Is Loading': emptyProps(),

    // Marketplace
    'Set Marketplace': props<{ marketplace: DoodMarketplaceModel }>(),
    'Update Marketplace': props<{ changes: Partial<DoodMarketplaceModel> }>(),
    'Reset Marketplace': emptyProps(),

    // Misc
    'reset': emptyProps(),
  },
});

export const MARKETPLACE_STORE_COMPONENT_ACTIONS = createActionGroup({
  source: 'Dood/COMPONENT/Marketplace',
  events: {
    'Update State': props<{ changes: Partial<MarketplaceState> }>(),
    'Reset State': emptyProps(),
  },
});

export const MARKETPLACE_STORE_HYDRATION_ACTIONS =
  createHydratedStoreActions(MARKETPLACE_STORE_KEY);
