import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import dayjs from 'dayjs';
import { TranslateService } from '@ngx-translate/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, from, fromEvent, of, skipWhile, Subject, timer } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
  throttleTime,
} from 'rxjs/operators';

import { MarketplaceKeys, ShopKeys } from '@config/keys/shop.keys';
import { ProductKeys } from '@config/keys/product.keys';
import { CategoryKeys } from '@config/keys/category.keys';

import { DateUtils } from '@shared/utils/date/date.utils';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';
import { DoodCategoryWithProduct } from '@shared/interfaces/category.interface';

import { CartStoreRefiner } from '@common/refiners/cart.refiner';
import { ShopStoreRefiner } from '@common/refiners/shop.refiner';
import { ShopStoreSelector } from '@common/selectors/shop.selector';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

import { ISimpleItem } from '@core/models/simple-item.model';
import { DoodShopModel, IShopCatalog, IShopCatalogTimeRule } from '@core/models/shop.model';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

import { OrdersService } from '@core/services/orders/orders.service';
import { NativeService } from '@core/services/native/native.service';
import { ShopAvailableAtService } from '@core/services/shops/shop-available-at.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { AnalyticsService } from '@core/services/app/analytics.service';

@Component({
  selector: 'app-kiosk-shop-category-list-block',
  templateUrl: './kiosk-shop-category-list-block.component.html',
})
export class KioskShopCategoryListBlockComponent implements OnInit, OnDestroy {
  @HostBinding('class') classes = 'flex h-full w-full flex-col w-[244px] min-w-[244px]';
  @Input() displayBackShopListButtonMobile = false;
  @Input() displayBackShopListButtonDesktop = false;
  @Input() backShopListButtonText!: string;
  @Input() shopListUrl!: string;
  @Input() shopListButtonColor?: IContentBuilderFieldColor;
  @Input() shopListButtonTextColor?: IContentBuilderFieldColor;
  @Input() categoryListTop = 0;
  @Input() displayBurgerMenuCategories = true;
  @Input() backgroundColor: IContentBuilderFieldColor = {
    value: 'transparent',
    type: ColorFieldTypesValues.Hex,
  };
  @Input() itemBackgroundColor: IContentBuilderFieldColor = {
    value: 'transparent',
    type: ColorFieldTypesValues.Hex,
  };
  @Input() itemHoverBackgroundColor: IContentBuilderFieldColor = {
    value: 'transparent',
    type: ColorFieldTypesValues.Hex,
  };
  @Input() accentColor: IContentBuilderFieldColor = {
    value: 'transparent',
    type: ColorFieldTypesValues.Hex,
  };

  @ViewChild('categoryTabsContainer') categoryTabsContainer?: ElementRef;
  @ViewChildren('itemElement') itemElements!: QueryList<ElementRef>;

  shop$ = this.shopSelector.selectActive;
  isShopLoading$ = this.shopSelector.selectIsLoading;
  categoriesWithProducts$ = this.shopRefiner.selectActiveProductsCategories;

  isOrderingAllowed = true;
  productKeys = ProductKeys;

  cartProducts$ = this.cartRefiner.selectActiveCartProducts;

  tab?: ISimpleItem;
  tabCategories!: ISimpleItem[];
  idsCategories!: string[];
  categoryKeys = CategoryKeys;
  isNotched = false;
  private destroyed$ = new Subject<boolean>();
  availableAtMessage: string | null = null;
  shouldBeAvailableNow = false;

  private previousScrollPosition = 0;

  isScrollingDown = false;

  warningMessages: string[] = [];

  isBurgerNavigationOpen = false;

  constructor(
    private readonly router: Router,
    private shopRefiner: ShopStoreRefiner,
    private cartRefiner: CartStoreRefiner,
    private shopSelector: ShopStoreSelector,
    private readonly ordersService: OrdersService,
    private readonly activatedRoute: ActivatedRoute,
    protected settingsSelector: SettingsStoreSelector,
    private readonly routerHelper: RouterHelperService,
    private marketplaceSelector: MarketplaceStoreSelector,
    private readonly translateService: TranslateService,
    private readonly shopAvailableAtService: ShopAvailableAtService,
    private readonly analyticsService: AnalyticsService,
  ) {}

  ngOnInit(): void {
    const marketplace = this.marketplaceSelector.marketplace;

    this.cartRefiner.selectActiveCartProducts
      .pipe(
        take(1),
        switchMap(products =>
          products.length ? this.ordersService.checkCartIsValid$() : of(false),
        ),
      )
      .subscribe();

    this.shop$
      .pipe(
        takeUntil(this.destroyed$),
        skipWhile(shop => !shop),
        debounceTime(250),
        map(shop => {
          this.isOrderingAllowed = marketplace.available && !!shop?.[ShopKeys.Available];
          if (!marketplace.available) {
            this.analyticsService.trackEvent('warning', {
              warning_reason: 'marketplace_not_available',
              shop_id: shop?.[ShopKeys.Id],
              shop_name: shop?.[ShopKeys.Name],
              shop_available: shop?.[ShopKeys.Available],
              shop_available_at: shop?.[ShopKeys.AvailableAt],
              marketplace_id: marketplace[MarketplaceKeys.Id],
              marketplace_name: marketplace[MarketplaceKeys.Name],
              marketplace_available: marketplace[MarketplaceKeys.Available],
            });
          }
          if (!shop?.[ShopKeys.Available]) {
            this.analyticsService.trackEvent('warning', {
              warning_reason: 'shop_not_available',
              shop_id: shop?.[ShopKeys.Id],
              shop_name: shop?.[ShopKeys.Name],
              shop_available: shop?.[ShopKeys.Available],
              shop_available_at: shop?.[ShopKeys.AvailableAt],
              marketplace_id: marketplace[MarketplaceKeys.Id],
              marketplace_name: marketplace[MarketplaceKeys.Name],
              marketplace_available: marketplace[MarketplaceKeys.Available],
            });
          }
        }),
      )
      .subscribe();

    timer(1, 1000)
      .pipe(
        takeUntil(this.destroyed$),
        switchMap(() => this.shop$),
        tap(shop => {
          if (!shop) {
            return;
          }
          if (!shop.available_at) {
            return;
          }

          const now = DateUtils.dayjsInMarketplaceTimezone();
          const dateTimeAvailableAt = DateUtils.dayjsInMarketplaceTimezone(shop.available_at);
          this.shouldBeAvailableNow =
            dateTimeAvailableAt.isBefore(now) || dateTimeAvailableAt.isSame(now);
        }),
      )
      .subscribe();

    this.categoriesWithProducts$
      .pipe(
        filter(categories => !!categories.length),
        takeUntil(this.destroyed$),
        tap(categories => {
          this.populateCategoriesForTabs(categories);
        }),
      )
      .subscribe();

    from(NativeService.isNotched())
      .pipe(
        takeUntil(this.destroyed$),
        switchMap(isNotched => {
          this.isNotched = isNotched;
          if (this.isNotched) {
            return from(StatusBar.setStyle({ style: Style.Dark }));
          }
          return of(null);
        }),
      )
      .subscribe();

    timer(1, 1000)
      .pipe(
        takeUntil(this.destroyed$),
        tap(() => this.updateAvailableAtMessage()),
      )
      .subscribe();

    this.getWarningMessage();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  navigateTo(categoryId: string): void {
    this.shopSelector.selectActive
      .pipe(
        take(1),
        map(shop => {
          if (!shop) {
            return;
          }
          this.router.navigate([`categories/${categoryId}`], {
            relativeTo: this.activatedRoute,
          });
        }),
      )
      .subscribe();
  }

  changeTab(id: string): void {
    this.tab = this.tabCategories?.find(tab => tab.value === id);
  }

  onCategoryClick(categoryId: string): void {
    this.navigateTo(categoryId);
  }

  backShopListButton(url: string): void {
    this.router.navigate([this.routerHelper.translateRoute(`/${url}`)]);
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    const scroll$ = fromEvent(window, 'scroll');
    scroll$.pipe(throttleTime(10)).subscribe(() => {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

      if (currentScrollPosition - this.previousScrollPosition >= 10) {
        this.isScrollingDown = true;
        return;
      }

      this.isScrollingDown = false;

      this.previousScrollPosition = currentScrollPosition;
    });
  }

  toggleBurgerMenuNavigation(event: boolean): void {
    this.isBurgerNavigationOpen = event;
  }

  private populateCategoriesForTabs(categories: DoodCategoryWithProduct[]): void {
    this.tabCategories = categories
      .filter(c => c.productsInformation?.length)
      .map(category => {
        return {
          label: category.name,
          value: category.id,
          icon: category.icon,
        };
      });
    this.idsCategories = categories.map(category => {
      return category.id;
    });
  }

  private updateAvailableAtMessage(): void {
    this.shopSelector.selectActive
      .pipe(
        take(1),
        tap(shop => {
          if (!shop) {
            return;
          }
          this.availableAtMessage = this.shopAvailableAtService.getAvailableAtDisplayString(shop);
        }),
      )
      .subscribe();
  }

  reloadPage(): void {
    document.location.reload();
  }

  private getWarningMessage(): void {
    combineLatest([
      this.isShopLoading$,
      this.shopRefiner.selectActiveShopEnabledDistributionMode,
      this.shopSelector.selectActive,
      this.shopSelector.selectActive.pipe(map(shop => shop?.catalogs)),
      this.shopRefiner.selectActiveShopEnabledCatalogs,
      this.shopRefiner.selectActiveShopEnabledStores,
      this.shopRefiner.selectActiveProductsCategories,
    ])
      .pipe(takeUntil(this.destroyed$), debounceTime(50))
      .subscribe(
        ([isShopLoading, distributionModes, shop, catalogs, enabledCatalogs, stores, products]) => {
          this.warningMessages = [];

          if (isShopLoading) {
            return;
          }

          if (!distributionModes?.length) {
            this.addWarningMessage(
              'shop-page.warning-message.distribution-mode-not-available',
              shop,
            );
            return;
          }

          if (!catalogs?.length) {
            this.addWarningMessage('shop-page.warning-message.catalog-not-available', shop);
            return;
          }

          const enabledCatalogsTodayTimeRules = this.getEnabledCatalogsTodayTimeRules(
            catalogs as IShopCatalog[],
          );

          if (catalogs?.length && !enabledCatalogsTodayTimeRules?.length) {
            this.addWarningMessage('shop-page.warning-message.catalog-not-available-today', shop);
          }

          const closestFutureCatalogTimeRule = this.findClosestFutureDate(
            enabledCatalogsTodayTimeRules,
          );

          if (closestFutureCatalogTimeRule && !enabledCatalogsTodayTimeRules?.length) {
            this.addWarningMessage('shop-page.warning-message.catalog-not-available-before', shop, {
              time: dayjs()
                .startOf('day')
                .add(closestFutureCatalogTimeRule, 'second')
                .format('HH:mm'),
            });
            return;
          }

          if (enabledCatalogs?.length && !stores?.length) {
            this.addWarningMessage('shop-page.warning-message.stores-not-available', shop);
          }

          if (!products?.length) {
            this.addWarningMessage('shop-page.warning-message.products-not-available', shop);
          }
        },
      );
  }

  private addWarningMessage(
    translationKey: string,
    shop: DoodShopModel | null,
    params?: {
      [key: string]: string;
    },
  ): void {
    this.warningMessages.push(
      `${this.translateService.instant(translationKey)} ${params?.time ? params.time : ''}`,
    );

    this.analyticsService.trackEvent('warning', {
      warning_reason: 'no_product_displayed_on_shop_page',
      no_product_available_reason: translationKey.split('.').pop(),
      shop_id: shop?.[ShopKeys.Id],
      shop_name: shop?.[ShopKeys.Name],
      shop_available: shop?.[ShopKeys.Available],
      shop_available_at: shop?.[ShopKeys.AvailableAt],
    });
  }

  private getEnabledCatalogsTodayTimeRules(enabledCatalogs: IShopCatalog[] | undefined): number[] {
    return (enabledCatalogs || [])
      .reduce((acc, catalog) => {
        if (catalog?.enabled_time_rules) {
          acc.push(...catalog.enabled_time_rules);
        }
        return acc;
      }, [] as IShopCatalogTimeRule[])
      .filter(timeRule => timeRule.days_of_week.includes(dayjs().day() + 1))
      .map(timeRule => timeRule.start_time);
  }

  private findClosestFutureDate(dates: number[]): number | null {
    const now = dayjs();

    const dayjsDates = dates.map(seconds => dayjs().startOf('day').add(seconds, 'second'));
    const futureDates = dayjsDates.filter(date => date.isAfter(now));
    const closestFutureDate = futureDates.reduce((closestDate, currentDate) => {
      return currentDate.isBefore(closestDate) ? currentDate : closestDate;
    }, futureDates[0]);
    const closestFutureDateInSeconds = closestFutureDate?.diff(dayjs().startOf('day'), 'second');
    return closestFutureDateInSeconds || null;
  }
}
