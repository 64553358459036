import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-food-society-hero-block',
  templateUrl: './food-society-hero-block.component.html',
})
export class FoodSocietyHeroBlockComponent {
  @Input() baseline?: string;
  @Input() socialTiktokURL?: string;
  @Input() socialFacebookURL?: string;
  @Input() socialLinkedinURL?: string;
  @Input() socialInstagramURL?: string;
}
