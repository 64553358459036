import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { DoodEntityModel } from '@core/models/entity.model';

import { ObjectUtils } from '@app/utilities/object.util';
import { DistanceUtils } from '@shared/utils/distance/distance.utils';

@Component({
  selector: 'app-push-shop-atom',
  templateUrl: './push-shop-atom.component.html',
})
export class PushShopAtomComponent implements OnInit {
  @Input()
  @Input()
  name!: string;
  @Input() category!: string;
  @Input() stars!: string;
  @Input() distance!: number;
  @Input() readyAt!: string;
  @Input() shop!: DoodEntityModel;
  @Input() isFavorite!: boolean;
  @Input() promotion!: {
    label: string;
    percent: number;
  };
  @Input() budget!: string;
  @Output() buttonClick = new EventEmitter();
  @Output() favoriteChange = new EventEmitter<boolean>();

  formattedDistance!: string;
  shopImage?: string;

  ngOnInit(): void {
    this.formattedDistance = DistanceUtils.formatDistance(this.distance);
    if (this.shop.images && ObjectUtils.isObject(this.shop.images)) {
      if (this.shop.images.cover && ObjectUtils.isObject(this.shop.images.cover)) {
        this.shopImage = String(this.shop.images.cover?.url ?? undefined);
      }
    }

    // Legacy image
    if (!this.shopImage && this.shop?.cover) {
      this.shopImage = 'https://api.dood.com' + this.shop?.cover;
    }
  }

  onFavoriteClick(event: Event): void {
    event.stopPropagation();
    this.favoriteChange.emit(!this.isFavorite);
  }
}
