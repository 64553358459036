import { Injectable } from '@angular/core';

import { AuthFirebaseService } from '@core/services/api/auth-firebase/auth-firebase.service';

import { CartStoreDispatcher } from '@common/dispatchers/cart.dispatcher';
import { ShopStoreDispatcher } from '@common/dispatchers/shop.dispatcher';
import { OrderStoreDispatcher } from '@common/dispatchers/order.dispatcher';
import { BasketStoreDispatcher } from '@common/dispatchers/basket.dispatcher';
import { FilterStoreDispatcher } from '@common/dispatchers/filter.dispatcher';
import { AnchorStoreDispatcher } from '@common/dispatchers/anchor.dispatcher';
import { EntityStoreDispatcher } from '@common/dispatchers/entity.dispatcher';
import { ProductStoreDispatcher } from '@common/dispatchers/product.dispatcher';
import { PaymentStoreDispatcher } from '@common/dispatchers/payment.dispatcher';
import { DeliveryStoreDispatcher } from '@common/dispatchers/delivery.dispatcher';
import { AuthStoreDispatcher } from '@common/dispatchers/authentication.dispatcher';
import { DeclinableStoreDispatcher } from '@common/dispatchers/declinable.dispatcher';
import { CartFunnelStoreDispatcher } from '@common/dispatchers/cart-funnel.dispatcher';
import { MarketplaceStoreDispatcher } from '@common/dispatchers/marketplace.dispatcher';
import { TransactionStoreDispatcher } from '@common/dispatchers/transaction.dispatcher';
import { OnSiteLocationStoreDispatcher } from '@common/dispatchers/on-site-location.dispatcher';

@Injectable({
  providedIn: 'root',
})
export class ResetStateService {
  constructor(
    private authDispatcher: AuthStoreDispatcher,
    private cartDispatcher: CartStoreDispatcher,
    private shopDispatcher: ShopStoreDispatcher,
    private orderDispatcher: OrderStoreDispatcher,
    private entityDispatcher: EntityStoreDispatcher,
    private filterDispatcher: FilterStoreDispatcher,
    private basketDispatcher: BasketStoreDispatcher,
    private anchorDispatcher: AnchorStoreDispatcher,
    private paymentDispatcher: PaymentStoreDispatcher,
    private productDispatcher: ProductStoreDispatcher,
    private deliveryDispatcher: DeliveryStoreDispatcher,
    private cartFunnelDispatcher: CartFunnelStoreDispatcher,
    private declinableDispatcher: DeclinableStoreDispatcher,
    private marketplaceDispatcher: MarketplaceStoreDispatcher,
    private transactionDispatcher: TransactionStoreDispatcher,
    protected readonly authFirebaseService: AuthFirebaseService,
    private onSiteLocationDispatcher: OnSiteLocationStoreDispatcher,
  ) {}

  reset(): void {
    this.anchorDispatcher.reset();
    this.authDispatcher.reset();
    this.cartFunnelDispatcher.reset();
    this.basketDispatcher.reset();
    this.declinableDispatcher.reset();
    this.deliveryDispatcher.reset();
    this.entityDispatcher.reset();
    this.filterDispatcher.reset();
    this.onSiteLocationDispatcher.reset();
    this.orderDispatcher.reset();
    this.paymentDispatcher.reset();
    this.productDispatcher.reset();
    this.shopDispatcher.reset();
    this.transactionDispatcher.reset();
    this.cartDispatcher.reset();
    this.marketplaceDispatcher.reset();
  }

  // TODO: Change this to observable
  async refreshUI(): Promise<void> {
    this.reset();
    await this.authFirebaseService.disconnectUser();
    this.goToHome();
  }

  goToHome(): void {
    window.location.href = '/';
  }
}
