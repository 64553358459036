<div class="flex h-full flex-row justify-start gap-2.5">
  <div
    class="text-primary flex h-full w-56 flex-col items-center justify-start gap-2.5"
    [style.color]="accentColor | contentBuilderColor"
  >
    <div
      class="flex w-full cursor-pointer justify-center rounded-t-lg py-4.5 shadow-sm"
      [style.backgroundColor]="backgroundColor | contentBuilderColor"
      (pointerdown)="startScroll('up')"
      (pointerup)="stopScroll()"
      (pointerleave)="stopScroll()"
      #scrollUpIcon
    >
      <i class="icon icon-chevron-down rotate-180"></i>
    </div>

    <div
      #scrollContainer
      class="text-primary flex w-full flex-1 flex-col gap-2.5 overflow-y-scroll scrollbar-hide"
      [@staggeredListAnimation]="{
        value: displayItems.length,
        params: { animateEnterElementFromTopToBottom: true },
      }"
    >
      <div
        *ngFor="let item of displayItems; let i = index"
        class="relative flex h-44 min-h-44 w-full flex-col items-center gap-2.5 text-center"
        (click)="categoryClick(item, i)"
      >
        <div
          class="flex h-full w-full flex-col justify-between rounded-lg pb-2.5 pt-4.5"
          [style.backgroundColor]="
            selectedIndex === i
              ? (itemSelectedBackgroundColor | contentBuilderColor)
              : (itemBackgroundColor | contentBuilderColor)
          "
        >
          <div
            class="bg-gray h-28 w-full bg-contain bg-center bg-no-repeat"
            [ngStyle]="{
              'background-image': item.icon ? 'url(' + categoryImageUrl(item.icon) + ')' : '',
            }"
          ></div>
          <p
            class="px-6 text-base font-bold"
            [style.color]="accentColor | contentBuilderColor"
          >
            {{ item.label }}
          </p>
        </div>
      </div>
    </div>

    <div
      class="flex w-full cursor-pointer justify-center rounded-b-lg py-4.5"
      [style.backgroundColor]="backgroundColor | contentBuilderColor"
      (pointerdown)="startScroll('down')"
      (pointerup)="stopScroll()"
      (pointerleave)="stopScroll()"
      #scrollDownIcon
    >
      <i class="icon icon-chevron-down"></i>
    </div>
  </div>
  <div class="flex h-full flex-col items-center justify-center gap-2.5">
    <ng-container *ngFor="let item of displayItems; let i = index">
      <div
        class="h-2.5 w-2.5 rounded-full transition-all"
        [ngStyle]="{
          'background-color':
            i !== activeIndex
              ? (backgroundColor | contentBuilderColor)
              : (accentColor | contentBuilderColor),
        }"
      ></div>
    </ng-container>
  </div>
</div>
