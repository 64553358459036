<ng-container class="relative">
  <div class="min-h-screen px-5 pb-20 md:px-8 xl:px-12">
    @if (heading) {
      <h2
        class="pb-12 pt-24 text-5xl font-bold"
        [style.color]="headingColor | contentBuilderColor"
      >
        {{ heading }}
      </h2>
    }
    <div
      class="grid gap-4 sm:gap-x-4 md:gap-x-5 lg:gap-5 xl:gap-6"
      [ngClass]="
        templateLayout === templateLayoutKeys.DefaultLayout
          ? 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'
          : shops && shops.length <= 9
            ? 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-3'
            : 'grid-cols-2 md:grid-cols-3 xl:grid-cols-4'
      "
    >
      <ng-container *ngIf="shops; else loading">
        <div *ngFor="let shop of shops; let index = index">
          <app-push-shop-card-v2-atom
            [title]="shop[entityKeys.Name]"
            [entity]="shop"
            [imgUrl]="shopImageCoverUrl(shop)"
            [distance]="shop[entityKeys.Distance]"
            [category]="shop[entityKeys.Type]?.name ?? ''"
            [budget]="shop[entityKeys.Pricing]"
            [currency]="getShopCurrency(shop)"
            [shortDescription]="shop[entityKeys.ShortDescription]"
            [linkUrl]="entityUrl(shop)"
            [address]="entityAddress(shop)"
            [displayEntityImage]="displayEntityImages"
            [displayEntityDistance]="displayEntityDistances"
            [displayEntityPricing]="displayEntityPricings"
            [displayEntityAddress]="displayEntityAddresses"
            [displayEntityCategory]="displayEntityCategories"
            [entityNameSize]="entityNamesSize"
            [increaseHeight]="shops.length <= 9"
            [wantedAt]="wantedAt"
            [templateLayout]="templateLayout"
          ></app-push-shop-card-v2-atom>
        </div>
      </ng-container>
      <ng-template #loading>
        <div
          class="max-w-lg"
          *ngFor="let i of [].constructor(30)"
        >
          <div
            class="relative mb-2 h-28 animate-pulse overflow-hidden rounded-lg bg-neutral-100"
          ></div>
          <div class="w-6/12">
            <p class="mb-1.5 animate-pulse bg-neutral-100 py-1.5"></p>
            <p class="animate-pulse bg-neutral-50 py-1"></p>
          </div>
        </div>
      </ng-template>
      <ng-container *ngIf="!shops?.length">
        <p class="rounded rounded-sm bg-highlight-50 p-4 font-accent">
          {{ 'shop-list-page.no-results-available' | translate }}
        </p>
      </ng-container>
    </div>
  </div>
  <div
    class="sticky bottom-15.5 left-full w-auto pr-5 lg:hidden"
    [ngClass]="isIos ? '' : 'inline-block'"
  >
    <app-button-atom
      *ngIf="displaySeeShopMapButton"
      [type]="'primary'"
      [icon]="'icon-distance'"
      [overrideBgColor]="shopsMapButtonColor"
      [overrideTextColor]="shopsMapButtonTextColor"
      [size]="'S'"
      [color]="'primary'"
      [label]="'shop-list-header.show-map' | translate"
      (clickEventEmitter)="navigateToMapPage()"
      [ngClass]="isIos ? 'absolute right-5 bottom-3' : ''"
    ></app-button-atom>
  </div>
  <div class="sticky bottom-0 left-0 z-30 bg-white shadow shadow-top lg:hidden">
    <app-shop-search-parameters-overview></app-shop-search-parameters-overview>
  </div>
</ng-container>
