import { DoodUserModel } from '@store/authentication/authentication.model';

export interface DoodTransactionModel {
  id: string;
  order: string;
  paid_at: Date;
  amount: number;
  currency: string;
  payment_method: any;
  user: string | DoodUserModel;
  status: DoodTransactionStatus;
}

export enum DoodTransactionStatus {
  payment = 'PAYMENT',
  confirmed = 'CONFIRMED',
}

export interface DoodGroupedTransaction {
  user: string | DoodUserModel;
  transactions: DoodTransactionModel[];
}
