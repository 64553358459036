import { find } from 'lodash';
import { Store } from '@ngrx/store';
import { Component, Input, OnInit } from '@angular/core';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { CartService } from '@core/services/cart/cart.service';
import { PongoService } from '@core/services/pongo/pongo.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { ModalsService } from '@core/services/modals/modals.service';

import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { MarketplaceStoreRefiner } from '@common/refiners/marketplace.refiner';

import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { SELECT_AUTH_USER_META_FIELDS } from '@store/authentication';
import { IPongoAsset, IPongoCustomer, PongoAssetType } from '@core/models/pongo.model';
import { PongoOfferedProductPickerModalComponent } from '@shared/modals/pongo/pongo-offered-product-picker-modal/pongo-offered-product-picker-modal.component';
import { MarketplaceKeys } from '@config/keys/shop.keys';
import { MarketplaceCartScopeValues } from '@config/values/marketplace.values';
import { ShopStoreSelector } from '@common/selectors/shop.selector';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

@Component({
  selector: 'app-pongo-asset-list-block',
  templateUrl: './pongo-asset-list-block.component.html',
})
export class PongoAssetListBlockComponent extends DestroyerBase implements OnInit {
  @Input() heading = 'pongo.loyalty-rewards';
  @Input() subheading?: string;
  @Input() width: 'centered' | 'full' = 'centered';
  @Input() itemsToShowCardCarousel = 4;

  isDesktop = false;
  customer?: IPongoCustomer;
  assets: IPongoAsset[] = [];
  selectIsPongoPluginEnabled = this.marketplaceRefiner.selectIsPongoPluginEnabled;

  constructor(
    private store: Store,
    private readonly cartService: CartService,
    private readonly pongoService: PongoService,
    private readonly ordersService: OrdersService,
    private readonly modalsService: ModalsService,
    private readonly settingsSelector: SettingsStoreSelector,
    private readonly marketplaceRefiner: MarketplaceStoreRefiner,
    private readonly shopSelector: ShopStoreSelector,
    private readonly marketplaceSelector: MarketplaceStoreSelector,
  ) {
    super();

    this.settingsSelector.selectDeviceIsDesktopScreen
      .pipe(takeUntil(this._destroyerRef))
      .subscribe(_isDesktop => (this.isDesktop = _isDesktop));
  }

  ngOnInit(): void {
    this.updateCustomer();
  }

  private updateCustomer(): void {
    const marketplace = this.marketplaceSelector.marketplace;

    this.store
      .select(SELECT_AUTH_USER_META_FIELDS)
      .pipe(
        takeUntil(this._destroyerRef),
        switchMap(() => this.shopSelector.selectActive),
        filter(shop => {
          return (
            marketplace[MarketplaceKeys.CartScope] === MarketplaceCartScopeValues.Marketplace ||
            !!shop
          );
        }),
        switchMap(shop => {
          const isCartScopeShop =
            marketplace[MarketplaceKeys.CartScope] === MarketplaceCartScopeValues.Shop;
          return this.pongoService.getCustomer$(isCartScopeShop ? shop : null);
        }),
        tap(customer => {
          if (!customer) return;
          this.customer = customer;
          this.assets = customer.rewards.filter(reward => {
            let hasExternalIds =
              reward.type !== PongoAssetType.FREE_PRODUCT || reward.external_ids.length > 0;
            return hasExternalIds;
          });
        }),
      )
      .subscribe();
  }

  useAsset(asset: IPongoAsset): void {
    const coupon = { code: asset.hash };
    this.cartService.addCoupon(coupon);

    if (asset.external_ids.length > 0) {
      PongoOfferedProductPickerModalComponent.asset = asset;
      PongoOfferedProductPickerModalComponent.onProductSelected = (): void => {
        this.updateCart();
        PongoOfferedProductPickerModalComponent.onProductSelected = (): void => {};
      };

      this.modalsService.open(PongoOfferedProductPickerModalComponent.handle);
    } else {
      this.updateCart();
    }
  }

  removeAsset(asset: IPongoAsset): void {
    const coupon = { code: asset.hash };
    this.cartService.removeCoupon(coupon);
    this.updateCart();
  }

  buyAsset(asset: IPongoAsset): void {
    asset.buyInProcess = true;
    this.pongoService
      .buyReward$(asset.id)
      .pipe(
        tap(() => (asset.buyInProcess = false)),
        tap(() => this.updateCustomer()),
        tap(asset => this.useAsset(asset)),
      )
      .subscribe();
  }

  isAssetInCart(asset: IPongoAsset): boolean {
    const coupons = this.cartService.getCouponsInCart();

    return !!find(coupons, coupon => coupon.code && coupon.code === asset.hash);
  }

  private updateCart(): void {
    this.ordersService
      .checkCartIsValid$()
      .pipe(
        take(1),
        tap(() => {
          window.scroll({
            top: 0,
            behavior: 'smooth',
          });
        }),
      )
      .subscribe();
  }
}
