import { Component } from '@angular/core';
import { PaymentMethodHandles } from '@config/payment-methods.config';

@Component({
  selector: 'app-payment-panel-on-site-with-stripe',
  templateUrl: './payment-panel-on-site-with-stripe.component.html',
})
export class PaymentPanelOnSiteWithStripeComponent {
  paymentMethodHandles = PaymentMethodHandles;
}
