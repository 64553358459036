export enum ProductKeys {
  Id = 'id',
  Type = 'type',
  Name = 'name',
  Description = 'description',
  Price = 'price',
  Vat = 'vat',
  Discount = 'discount',
  PreparationTime = 'preparation_time',
  Available = 'available',
  PayableWithMealVoucher = 'payable_with_meal_voucher',
  Hidden = 'hidden',
  Allergens = 'allergens',
  Icon = 'icon',
  InventoryAvailable = 'inventory_available',
  InventoryTracked = 'inventory_tracked',
  Additions = 'additions',
  MinCount = 'min_count',
  MaxCount = 'max_count',
  AdditionsGroup = 'addition_group',
  Categories = 'categories',
  PriceOverrides = 'price_overrides',
  FinalPrice = 'final_price',
  IsChecked = 'is_checked',
  Labels = 'labels',
  ShopId = 'shop_id',
  ShopName = 'shop_name',
  ShopSlug = 'shop_slug',
  ShopAvailable = 'shop_available',
  Quantity = 'quantity',
  ParentProduct = 'parent_product',
  ContextualPrices = 'contextual_prices',
  Metafields = 'metafields',
  Store = 'store',
  Images = 'images',
  PriceToDisplay = 'price_to_display',
  ExternalId = 'external_id',
  Plu = 'plu',
}
