import { Dayjs } from 'dayjs';
import { DateUtils } from '../date/date.utils';

export interface ITimeSlot {
  id: number;
  start: Dayjs;
  end: Dayjs;
}

export class TimeSlotsUtils {
  static generateTimeSlots = ({
    day,
    start,
    startWithMinute,
    end,
    interval,
    endWithMinute,
    minDate,
    maxDate,
  }: {
    day: Date;
    start: number;
    startWithMinute?: number;
    end: number;
    interval: number;
    endWithMinute?: number;
    minDate: Dayjs;
    maxDate: Dayjs;
  }): ITimeSlot[] => {
    if (startWithMinute) {
      startWithMinute = TimeSlotsUtils.getNextTimeRange(startWithMinute);
    }
    const setTime = (x: Date, h = 0, m = 0, s = 0, ms = 0): Date =>
      new Date(
        DateUtils.dayjsInMarketplaceTimezone(x)
          .startOf('day')
          .set('hour', h)
          .set('minute', m)
          .format(),
      );

    // tmp fix
    if (
      DateUtils.dayjsInMarketplaceTimezone(day).daysInMonth() === 31 &&
      DateUtils.dayjsInMarketplaceTimezone(day).month() === 2
    ) {
      start -= 1;
      end -= 1;
    }

    let from = setTime(day, start, startWithMinute);
    const to = setTime(day, end, endWithMinute);

    const step = (date: Date): Dayjs =>
      DateUtils.dayjsInMarketplaceTimezone(date).add(interval, 'minute');
    const blocks: ITimeSlot[] = [];
    let cursor = from;
    let index = 1;

    while (DateUtils.dayjsInMarketplaceTimezone(cursor).isBefore(to)) {
      let startOfSlot = DateUtils.dayjsInMarketplaceTimezone(cursor);
      let endOfSlot = startOfSlot.add(interval, 'minute');
      if (endOfSlot.isAfter(maxDate)) {
        break;
      }

      if (startOfSlot.isAfter(minDate)) {
        blocks.push({
          id: index,
          start: startOfSlot,
          end: endOfSlot,
        });
      }
      cursor = step(cursor).toDate();
      index = index + 1;
    }
    return blocks;
  };

  static getNextTimeRange(value: number): number {
    const range = 15;
    let result;
    for (let time = 0; time < 60; time = time + range) {
      if (value === time) {
        result = time;
      } else if (value > time) {
        result = time + range;
      }
    }
    return result as number;
  }

  static generateGivenTimeSlots(slots: number[]): ITimeSlot[] {
    const generateSlots = [];
    let index = 1;
    if (slots.length === 0) {
      return [];
    }

    slots = slots.sort();
    const firstSlot = DateUtils.dayjsInMarketplaceTimezone(slots[0] * 1000);
    for (const slot of slots) {
      const startSlot = DateUtils.dayjsInMarketplaceTimezone(slot * 1000);
      const endSlot = DateUtils.dayjsInMarketplaceTimezone(slot * 1000).add(15, 'minute');

      if (!firstSlot.isSame(startSlot, 'day')) {
        break;
      }

      generateSlots.push({
        id: index,
        start: startSlot,
        end: endSlot,
      });
      index += 1;
    }

    return generateSlots;
  }
}
