<div
  *ngIf="attribute === groupedOrderModalsAttributes.ShareLink"
  class="p-5 bg-white rounded-t-3xl md:rounded-3xl"
>
  <div class="flex justify-between items-center">
    <h2 class="font-accent font-bold text-3xs">
      {{ 'group-order-modal.title' | translate }}
    </h2>
  </div>
  <p class="mt-5 mb-5 text-neutral-600 font-base">
    {{ 'group-order-modal.share-order' | translate }}
  </p>
  <div class="pt-1 flex flex-wrap gap-y-4 justify-between items-center">
    <div class="w-full">
      <app-button-atom
        size="M"
        [label]="'group-order-modal.share-button' | translate"
        (click)="shareLink.emit()"
        type="primary"
        [full]="true"
        [iconUrl]="isProcessingRequest ? 'assets/images/spinner.svg' : ''"
      ></app-button-atom>
    </div>
    <div class="w-full">
      <app-button-atom
        size="M"
        [label]="'group-order-modal.back-button' | translate"
        color="primary"
        (click)="orderAlone.emit()"
        type="light"
        [full]="true"
      ></app-button-atom>
    </div>
  </div>
</div>

<div
  *ngIf="attribute === groupedOrderModalsAttributes.JoinOrder"
  class="p-5 bg-white rounded-t-3xl md:rounded-3xl"
>
  <div class="flex justify-between items-center">
    <h2 class="font-accent font-bold text-3xs">
      {{ 'group-order-modal.title' | translate }}
    </h2>
  </div>
  <p class="mt-5 mb-5 text-neutral-600 font-base">
    {{ 'group-order-modal.join-orer' | translate }}
  </p>
  <div class="pt-1 flex gap-x-4 justify-between items-center">
    <div class="w-6/12">
      <app-button-atom
        size="M"
        [label]="'group-order-modal.cancel-button' | translate"
        color="primary"
        (click)="declineInvitation.emit()"
        type="light"
        [full]="true"
      ></app-button-atom>
    </div>
    <div class="w-6/12">
      <app-button-atom
        size="M"
        [label]="'group-order-modal.continue-button' | translate"
        (click)="joinOrder.emit()"
        type="primary"
        [full]="true"
      ></app-button-atom>
    </div>
  </div>
</div>

<div
  *ngIf="attribute === groupedOrderModalsAttributes.CancelOrder"
  class="p-5 bg-white rounded-t-3xl md:rounded-3xl"
>
  <div class="flex justify-between items-center">
    <h2 class="font-accent font-bold text-3xs">
      {{ 'group-order-modal.title' | translate }}
    </h2>
  </div>
  <p class="mt-5 mb-5 text-neutral-600 font-base">
    {{ 'group-order-modal.cancel-order' | translate }}
  </p>
  <div class="pt-1 flex gap-x-4 justify-between items-center">
    <div class="w-6/12">
      <app-button-atom
        size="M"
        [label]="'group-order-modal.cancel-button' | translate"
        color="primary"
        (click)="closeModal.emit()"
        type="light"
        [full]="true"
      ></app-button-atom>
    </div>
    <div class="w-6/12">
      <app-button-atom
        size="M"
        [label]="'group-order-modal.confirm-button' | translate"
        (click)="cancelOrder.emit()"
        type="primary"
        [full]="true"
      ></app-button-atom>
    </div>
  </div>
</div>

<div
  *ngIf="attribute === groupedOrderModalsAttributes.ClearCart"
  class="p-5 bg-white rounded-t-3xl md:rounded-3xl"
>
  <div class="flex justify-between items-center">
    <h2 class="font-accent font-bold text-3xs">
      {{ 'group-order-modal.title' | translate }}
    </h2>
  </div>
  <p
    class="mt-5 mb-5 text-neutral-600 font-base"
    *ngIf="!isOrderOwner"
  >
    {{ 'group-order-modal.leave-order-1' | translate }}
    {{ basket.user.firstname }},
    {{ 'group-order-modal.leave-order-2' | translate }}
  </p>
  <p
    class="mt-5 mb-5 text-neutral-600 font-base"
    *ngIf="isOrderOwner"
  >
    {{ 'group-order-modal.cancel-order' | translate }}
  </p>
  <div class="pt-1 flex gap-x-4 justify-between items-center">
    <div class="w-6/12">
      <app-button-atom
        size="M"
        [label]="'group-order-modal.cancel-button' | translate"
        color="primary"
        (click)="closeModal.emit()"
        type="light"
        [full]="true"
      ></app-button-atom>
    </div>
    <div class="w-6/12">
      <app-button-atom
        size="M"
        [label]="'group-order-modal.confirm-button' | translate"
        (click)="clearCart.emit()"
        type="primary"
        [full]="true"
        [iconUrl]="isProcessingRequest ? 'assets/images/spinner.svg' : ''"
      ></app-button-atom>
    </div>
  </div>
</div>

<div
  *ngIf="attribute === groupedOrderModalsAttributes.LeavingOrder"
  class="p-5 bg-white rounded-t-3xl md:rounded-3xl"
>
  <div class="flex justify-between items-center">
    <h2 class="font-accent font-bold text-3xs">
      {{ 'group-order-modal.title' | translate }}
    </h2>
  </div>
  <p class="mt-5 mb-5 text-neutral-600 font-base">
    {{ 'group-order-modal.leave-order-1' | translate }}
    {{ basket.user.firstname }},
    {{ 'group-order-modal.leave-order-2' | translate }}
  </p>
  <div class="pt-1 flex gap-x-4 justify-between items-center">
    <div class="w-6/12">
      <app-button-atom
        size="M"
        [label]="'group-order-modal.cancel-button' | translate"
        color="primary"
        (click)="closeModal.emit()"
        type="light"
        [full]="true"
      ></app-button-atom>
    </div>
    <div class="w-6/12">
      <app-button-atom
        size="M"
        [label]="'group-order-modal.confirm-button' | translate"
        (click)="leaveOrder.emit()"
        type="primary"
        [full]="true"
      ></app-button-atom>
    </div>
  </div>
</div>

<div
  *ngIf="attribute === groupedOrderModalsAttributes.RemoveUserOrder"
  class="p-5 bg-white rounded-t-3xl md:rounded-3xl"
>
  <div class="flex justify-between items-center">
    <h2 class="font-accent font-bold text-3xs">
      {{ 'group-order-modal.title' | translate }}
    </h2>
  </div>
  <p class="mt-5 mb-5 text-neutral-600 font-base">
    {{ 'group-order-modal.remove-user' | translate }}
  </p>
  <div class="pt-1 flex gap-x-4 justify-between items-center">
    <div class="w-6/12">
      <app-button-atom
        size="M"
        [label]="'group-order-modal.cancel-button' | translate"
        color="primary"
        (click)="closeModal.emit()"
        type="light"
        [full]="true"
      ></app-button-atom>
    </div>
    <div class="w-6/12">
      <app-button-atom
        size="M"
        [label]="'group-order-modal.confirm-button' | translate"
        (click)="removeUser.emit()"
        type="primary"
        [full]="true"
      ></app-button-atom>
    </div>
  </div>
</div>

<div
  *ngIf="attribute === groupedOrderModalsAttributes.GeneratedLink"
  class="max-w-screen-sm p-5 bg-white rounded-t-3xl md:rounded-3xl"
>
  <div class="flex justify-between items-center">
    <h2 class="font-accent font-bold text-3xs">
      {{ 'group-order-modal.title' | translate }}
    </h2>
  </div>
  <p class="mt-5 mb-5 text-neutral-600 font-base">
    {{ 'group-order-modal.generated-link' | translate }}
  </p>
  <div
    *ngIf="link"
    class="mt-5 mb-5 flex gap-x-4 justify-between items-center"
  >
    <p
      [innerText]="link"
      class="break-all text-neutral-600 font-base"
    ></p>
  </div>
  <div class="w-full">
    <app-button-atom
      size="M"
      [label]="'group-order-modal.continue-button' | translate"
      color="primary"
      (click)="closeModal.emit()"
      type="primary"
      [full]="true"
    ></app-button-atom>
  </div>
</div>

<div
  *ngIf="attribute === groupedOrderModalsAttributes.NotValidOrder"
  class="max-w-screen-sm p-5 bg-white rounded-t-3xl md:rounded-3xl"
>
  <div class="flex justify-between items-center">
    <h2 class="font-accent font-bold text-3xs">
      {{ 'group-order-modal.title' | translate }}
    </h2>
  </div>
  <p class="mt-5 mb-5 text-neutral-600 font-base">
    {{ 'group-order-modal.not-valid' | translate }}
  </p>
  <div class="w-full">
    <app-button-atom
      size="M"
      [label]="'group-order-modal.confirm-button' | translate"
      (click)="clearCart.emit()"
      type="primary"
      [full]="true"
      [iconUrl]="isProcessingRequest ? 'assets/images/spinner.svg' : ''"
    ></app-button-atom>
  </div>
</div>

<div
  *ngIf="attribute === groupedOrderModalsAttributes.ChooseOrderType"
  class="max-w-screen-sm p-5 pb-10 bg-white rounded-t-3xl md:rounded-3xl"
>
  <div class="flex justify-between items-center">
    <h2 class="font-accent font-bold text-3xs">
      {{ 'group-order-modal.order-type.title' | translate }}
    </h2>
  </div>
  <div class="pt-5 w-full">
    <app-button-atom
      size="M"
      [label]="'group-order-modal.order-type.order-alone' | translate"
      color="primary"
      (click)="orderAlone.emit()"
      type="primary"
      [full]="true"
    ></app-button-atom>
  </div>
  <div class="flex items-center py-3">
    <div class="h-px w-full border-b border-gray-200"></div>
    <div class="text-gray-400 px-2">Ou</div>
    <div class="h-px w-full border-b border-gray-200"></div>
  </div>
  <p class="mb-5 text-neutral-800 font-base">
    {{ 'group-order-modal.order-type.share-order' | translate }}
  </p>
  <div class="w-full">
    <app-button-atom
      size="M"
      [label]="'group-order-modal.order-type.group-order' | translate"
      color="primary"
      (click)="startShareOrder.emit()"
      type="primary"
      [full]="true"
    ></app-button-atom>
  </div>
</div>
