import { DoodEntityModel } from '@core/models/entity.model';
import { IAddress } from '@core/models/address.model';
import { IMetafield } from '@core/models/metafield.model';

export enum DoodAuthenticationStep {
  login = 'login',
  signup = 'signup',
}

export interface DoodUserModel {
  id: string;
  lastname?: string;
  firstname?: string;
  email?: string;
  phone?: string;
  avatar?: string;
  addresses?: IAddress;
  roles?: string[];
  legal_terms_accepted_at?: Date;
  entities?: DoodEntityModel[];
  is_anonymous: boolean;
  metafields?: IMetafield[];
  preferred_locales?: string[];
  optin?: boolean;
  custom_token?: string;
}
