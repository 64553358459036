import { combineLatest, timer } from 'rxjs';
import { Component, Input } from '@angular/core';
import { debounce, map, switchMap, take } from 'rxjs/operators';

import { MarketplaceKeys } from '@config/keys/shop.keys';

import { DoodOrderModel } from '@store/order/order.model';
import { OrdersService } from '@core/services/orders/orders.service';
import { MarketplaceCartScopeValues } from '@config/values/marketplace.values';

import { ShopStoreSelector } from '@common/selectors/shop.selector';
import { CartStoreSelector } from '@common/selectors/cart.selector';
import { BasketStoreRefiner } from '@common/refiners/basket.refiner';
import { OrderStoreSelector } from '@common/selectors/order.selector';
import { CartStoreDispatcher } from '@common/dispatchers/cart.dispatcher';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

@Component({
  selector: 'app-cart-tip-picker-block',
  templateUrl: './cart-tip-picker-block.component.html',
})
export class CartTipPickerBlockComponent {
  @Input() choice1Amount = 1;
  @Input() choice1Unit = 'currency';
  @Input() choice2Amount = 2;
  @Input() choice2Unit = 'currency';
  @Input() choice3Amount = 5;
  @Input() choice3Unit = 'percent';

  totalAmount$ = this.orderSelector.selectValidation.pipe(
    map(order => (order as DoodOrderModel)?.discounted_price_inc_tax),
  );

  tipsAllowed$ = this.cartSelector.selectActive
    .pipe(
      switchMap(cart =>
        combineLatest([
          this.shopSelector.selectShop(cart?.shop ?? ''),
          this.marketplaceSelector.selectMarketplace.pipe(map(marketplace => marketplace)),
          this.basketRefiner.selectHasBasket,
        ]),
      ),
    )
    .pipe(
      map(([shop, marketplace, isBasketExist]) => {
        if (marketplace[MarketplaceKeys.CartScope] === MarketplaceCartScopeValues.Marketplace) {
          return marketplace[MarketplaceKeys.TipsAllowed];
        }
        return !!shop?.tips_allowed && !isBasketExist;
      }),
    );

  constructor(
    private cartSelector: CartStoreSelector,
    private shopSelector: ShopStoreSelector,
    private basketRefiner: BasketStoreRefiner,
    private cartDispatcher: CartStoreDispatcher,
    private readonly ordersService: OrdersService,
    private readonly orderSelector: OrderStoreSelector,
    private marketplaceSelector: MarketplaceStoreSelector,
  ) {}

  onSelectedTip(tip: number): void {
    this.cartDispatcher.updateActive({ tip });
    this.ordersService
      .checkCartIsValid$()
      .pipe(
        take(1),
        debounce(() => timer(1000)),
      )
      .subscribe();
  }
}
