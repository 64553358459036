import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';
import { Component, ElementRef, Input } from '@angular/core';
import { combineLatest, Observable, of, throwError, from } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { catchError, filter, map, skipWhile, switchMap, take, tap } from 'rxjs/operators';

import { UserKeys } from '@config/keys/user.keys';

import { FormsState } from '@core/models/forms.model';
import { DoodUserModel } from '@store/authentication/authentication.model';

import { PatternUtil } from '@shared/utils/pattern/pattern.utils';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';

import { ComoService } from '@core/services/como/como.service';
import { UserService } from '@core/services/user/user.service';
import { PongoService } from '@core/services/pongo/pongo.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { ZerosixService } from '@core/services/zerosix/zerosix.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { AuthFirebaseService } from '@core/services/api/auth-firebase/auth-firebase.service';

import { emailValidator } from '@shared/validators/email/email.validator';
import { CountryISO } from '@shared/components/input-country-phone/config/country-phone.config';
import { AuthenticationUserJourneyType } from '@shared/blocks/authentication-block/authentication-user-journey-type';
import { AuthenticationModalComponent } from '@shared/modals/authentication-modal/authentication-modal.component';
import {
  AuthenticationForm,
  AuthenticationFormState,
} from '@common/states/authentication-form.state';
import { MarketplaceStoreRefiner } from '@common/refiners/marketplace.refiner';
import { ZerosixCreateOrLinkAccountModalComponent } from '@shared/modals/zerosix/zerosix-create-or-link-account-modal/zerosix-create-or-link-account-modal.component';
import { PongoCreateOrLinkAccountModalComponent } from '@shared/modals/pongo/pongo-create-or-link-account-modal/pongo-create-or-link-account-modal.component';
import { ComoCreateOrLinkAccountModalComponent } from '@shared/modals/como/como-create-or-link-account-modal/como-create-or-link-account-modal.component';
import { UserCredential } from 'firebase/auth';

@Component({
  selector: 'app-authentication-signup-step',
  templateUrl: './authentication-signup-step.component.html',
})
export class AuthenticationSignupStepComponent extends DestroyerBase {
  @Input() legalCheckboxLabel?: string;
  @Input() optinCheckboxLabel?: string;
  @Input() userJourneyType = AuthenticationUserJourneyType.ASK_EMAIL_FIRST;
  @Input() signupStepHeading!: string;
  @Input() signupStepSubheading!: string;
  @Input() signupStepDisplayFirstname = true;
  @Input() signupStepFirstnameLabel!: string;
  @Input() signupStepFirstnameHelp!: string;
  @Input() signupStepDisplayLastname = true;
  @Input() signupStepLastnameLabel!: string;
  @Input() signupStepLastnameHelp!: string;
  @Input() signupStepEmailLabel!: string;
  @Input() signupStepEmailHelp!: string;
  @Input() signupStepDisplayPhone = true;
  @Input() signupStepPhoneLabel!: string;
  @Input() signupStepPhoneHelp!: string;
  @Input() signupStepPasswordLabel!: string;
  @Input() signupStepPasswordHelp!: string;
  @Input() signupFooter: unknown[] = [];

  firebaseToken$ = this.authFirebaseService.firebaseToken$;
  authenticationUserJourneyType = AuthenticationUserJourneyType;
  passwordErrorMessage?: string;
  emailErrorMessage?: string;
  anyErrorMessage?: string;
  signupWithEmailAndPassword = false;
  phoneErrorMessage?: string;
  form?: UntypedFormGroup;
  isSignUpInProgress = false;

  marketplaceName$ = this.marketplaceSelector.selectMarketplaceName;
  authenticationEmailValue$ = this.authenticationForm.selectValue<string>('email');
  authenticationProviderValue$ = this.authenticationForm.selectValue<string>('provider');

  marketplaceCountry$ = this.marketplaceSelector.selectMarketplaceCountry;

  countryIso = CountryISO;

  constructor(
    private el: ElementRef,
    private readonly router: Router,
    private readonly fb: UntypedFormBuilder,
    private authSelector: AuthStoreSelector,
    private readonly comoService: ComoService,
    private readonly userService: UserService,
    private readonly pongoService: PongoService,
    private readonly modalsService: ModalsService,
    private readonly zerosixService: ZerosixService,
    private readonly routerHelper: RouterHelperService,
    private authenticationForm: AuthenticationFormState,
    private readonly translateService: TranslateService,
    private marketplaceRefiner: MarketplaceStoreRefiner,
    private marketplaceSelector: MarketplaceStoreSelector,
    private readonly authFirebaseService: AuthFirebaseService,
  ) {
    super();
    this.authenticationProviderValue$
      .pipe(
        skipWhile(value => !value),
        take(1),
      )
      .subscribe(value => {
        this.signupWithEmailAndPassword = !(
          value && ['google.com', 'facebook.com', 'apple.com'].includes(value)
        );

        // TODO: Check this form
        if (value === 'apple.com' && Capacitor.isNativePlatform()) {
          /* FIX APPLE NATIVE CONNECT WITHOUT FIRSTNAME & NAME */
          this.form = this.fb.group({
            provider: [''],
            firebaseUid: [''],
            [UserKeys.Email]: ['', Validators.required],
            [UserKeys.Phone]: ['', Validators.pattern(PatternUtil.phone)],
            ...(this.signupWithEmailAndPassword && {
              [UserKeys.Password]: ['', Validators.required],
            }),
            legals: [false, Validators.requiredTrue],
            newsletter: [false, Validators.required],
          });
        } else {
          this.form = this.fb.group({
            provider: [''],
            firebaseUid: [''],
            [UserKeys.Email]: [
              '',
              {
                validators: [emailValidator(), Validators.required],
                updateOn: 'blur',
              },
            ],
            [UserKeys.FirstName]: ['', Validators.required],
            [UserKeys.Phone]: [
              '',
              {
                validators: [Validators.pattern(PatternUtil.phone)],
                updateOn: 'blur',
              },
            ],
            [UserKeys.LastName]: [''],
            ...(this.signupWithEmailAndPassword && {
              [UserKeys.Password]: ['', Validators.required],
            }),
            legals: [false, Validators.requiredTrue],
            newsletter: [false, Validators.required],
          });
        }
        this.form.setValue({
          ...this.form.value,
          email: this.authenticationForm.value?.['email'] ?? '',
        });
        this.authenticationForm.upsert(this.form);
      });
  }

  onSubmit(): void {
    if (!this.form?.valid) {
      return;
    }

    this.isSignUpInProgress = true;
    combineLatest([this.authFirebaseService.firebaseToken$, this.authenticationForm.select()])
      .pipe(
        take(1),
        switchMap(([firebaseToken, form]) => {
          if (firebaseToken) {
            // Firebase account exists but not DOOD account
            return this.createUser$(form);
          }
          // Firebase and DOOD account doesn't exist yet
          return this.passwordSignUp$(form).pipe(
            switchMap(() =>
              this.authFirebaseService.isAnonymous
                ? this.upgradeAnonymousUser$(form)
                : this.createUser$(form),
            ),
          );
        }),
        tap(() => this.redirectIfNeeded()),
        tap(() => this.modalsService.close(AuthenticationModalComponent.handle)),
        tap(() => this.displayLoyaltyAccountPrompt()),
      )
      .subscribe();
  }

  closeModal(): void {
    this.modalsService.close(AuthenticationModalComponent.handle);
  }

  onUpdatePhone($event: string): void {
    this.form?.patchValue({ [UserKeys.Phone]: $event.replace(/\s/g, '') });
  }

  private passwordSignUp$(form: any): Observable<FormsState['authentication']> {
    if (
      ['google.com', 'facebook.com', 'apple.com'].includes(form.provider) ||
      this.authFirebaseService.isAnonymous
    ) {
      return of(form);
    }

    return from(this.authFirebaseService.signUp(form.email.trim(), form.password)).pipe(
      catchError((error: any) => {
        this.isSignUpInProgress = false;

        this.passwordErrorMessage = this.authFirebaseService.getPasswordErrorMessage(error);

        if (this.passwordErrorMessage) {
          this.scrollToFirstInvalidControl('password');
        }

        if (!this.passwordErrorMessage) {
          this.emailErrorMessage = this.authFirebaseService.getEmailErrorMessage(error);
          if (this.emailErrorMessage) {
            this.scrollToFirstInvalidControl('email');
          }
        }

        if (!this.passwordErrorMessage && !this.emailErrorMessage) {
          this.anyErrorMessage = error.message;
        }

        return throwError(() => error);
      }),
      tap((userCredential: UserCredential) =>
        this.form?.controls.firebaseUid.setValue(userCredential.user?.uid),
      ),
      switchMap((userCredential: UserCredential) =>
        this.authFirebaseService.saveFirebaseTokenInSessionStore$(userCredential),
      ),
      map(() => form),
    );
  }

  private createUser$(form: AuthenticationForm): Observable<any> {
    const navigatorLocale = navigator ? [...navigator.languages] : ['fr-FR'];
    if (!form) return of(null);

    let newUser = {
      [UserKeys.Optin]: Boolean(form.newsletter),
      [UserKeys.Phone]: form.phone.toString(),
      [UserKeys.LastName]: form.lastname.toString(),
      [UserKeys.Email]: form.email.toString().trim(),
      [UserKeys.FirstName]: form.firstname.toString(),
      [UserKeys.PreferredLocales]: navigatorLocale,
      [UserKeys.FirebaseUID]: this.authFirebaseService.getCurrentUser()?.uid,
    };

    if (form.provider === 'apple.com' && Capacitor.isNativePlatform()) {
      newUser = {
        ...newUser,
        [UserKeys.FirstName]: form.email.toString(),
      };
    }

    return this.userService.createUser$(newUser).pipe(
      catchError((error: any) => {
        this.isSignUpInProgress = false;
        this.anyErrorMessage = this.translateService.instant('authentication.unknown-error');
        return throwError(() => error);
      }),
    );
  }

  private upgradeAnonymousUser$(form: AuthenticationForm): Observable<DoodUserModel> {
    if (!form) {
      throw new Error('[AuthenticationSignupStepComponent] authentication form is empty !');
    }

    const newUser = {
      [UserKeys.Email]: form.email.toString(),
      [UserKeys.Phone]: form.phone.toString(),
      [UserKeys.Optin]: Boolean(form.newsletter),
      [UserKeys.LastName]: form.lastname.toString(),
      [UserKeys.FirstName]: form.firstname.toString(),
    };

    return this.authFirebaseService
      .upgradeAnonymousWithEmailAndPassword(form.email.toString(), form.password.toString())
      .pipe(
        filter(credentials => !!credentials),
        switchMap(() => this.authSelector.selectUser),
        take(1),
        switchMap(user => this.userService.upgradeAnonymousUser$(newUser, user?.id ?? '')),
      );
  }

  private redirectIfNeeded(): void {
    const requestedUrl = this.authSelector.guard?.requestedUrl;
    if (requestedUrl) {
      this.router.navigate([this.routerHelper.translateRoute(requestedUrl)]);
    }
  }

  private displayLoyaltyAccountPrompt(): void {
    combineLatest([
      this.marketplaceRefiner.selectIsComoPluginEnabled,
      this.comoService.isUserComoAccountEnabledOnMarketplaceLevel$(),
    ])
      .pipe(
        take(1),
        tap(([isMarketplaceComoEnabled, isUserComoEnabled]) => {
          if (
            isMarketplaceComoEnabled &&
            !isUserComoEnabled &&
            !this.authFirebaseService.isAnonymous
          ) {
            this.modalsService.open(ComoCreateOrLinkAccountModalComponent.handle);
          }
        }),
      )
      .subscribe();

    combineLatest([
      this.marketplaceRefiner.selectIsZeroSixPluginEnabled,
      this.zerosixService.isUserZeroSixCardEnabledOnMarketplaceLevel$(),
    ])
      .pipe(
        take(1),
        tap(([isMarketplaceComoEnabled, isUserZerosixEnabled]) => {
          if (
            isMarketplaceComoEnabled &&
            !isUserZerosixEnabled &&
            !this.authFirebaseService.isAnonymous
          ) {
            this.modalsService.open(ZerosixCreateOrLinkAccountModalComponent.handle);
          }
        }),
      )
      .subscribe();

    combineLatest([
      this.marketplaceRefiner.selectIsPongoPluginEnabled,
      this.pongoService.isUserPongoCustomerEnabledOnMarketplaceLevel$(),
    ])
      .pipe(
        take(1),
        tap(([isMarketplacePongoComoEnabled, isUserZerosixEnabled]) => {
          if (
            isMarketplacePongoComoEnabled &&
            !isUserZerosixEnabled &&
            !this.authFirebaseService.isAnonymous
          ) {
            this.modalsService.open(PongoCreateOrLinkAccountModalComponent.handle);
          }
        }),
      )
      .subscribe();
  }

  private scrollToFirstInvalidControl(type: string): void {
    if (type === 'email') {
      const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector('#email');
      firstInvalidControl?.focus();
    }

    if (type === 'password') {
      const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector('#password');
      firstInvalidControl?.focus();
    }
  }
}
