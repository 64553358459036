import { Subject } from 'rxjs';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { distinctUntilKeyChanged, take, takeUntil, tap } from 'rxjs/operators';

import { DoodShopModel } from '@core/models/shop.model';
import { DistributionModeValues } from '@config/values/order.values';

import { ShopsService } from '@core/services/shops/shops.service';
import { ShopStoreDispatcher } from '@common/dispatchers/shop.dispatcher';
import { NavigationHistoryService } from '@core/services/navigation-history/navigation-history.service';

@Component({
  selector: 'app-shop-digital-menu-page',
  templateUrl: './shop-digital-menu-page.component.html',
})
export class ShopDigitalMenuPageComponent implements OnInit, OnDestroy {
  @Input() body!: unknown[];
  @Input() metaDescription?: string;

  private readonly destroyed$ = new Subject<boolean>();
  // private shop?: IShop;

  constructor(
    private readonly meta: Meta,
    private shopDispatcher: ShopStoreDispatcher,
    private readonly shopsService: ShopsService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly navigationHistoryService: NavigationHistoryService,
  ) {}

  ngOnInit(): void {
    this.navigationHistoryService.pushStateFromActivatedRouteSnapshot(
      'shop-digital-menu',
      this.activatedRoute.snapshot,
    );

    this.activatedRoute.params
      .pipe(
        takeUntil(this.destroyed$),
        distinctUntilKeyChanged('shopSlug'),
        tap(params => this.loadShop(params.shopSlug)),
      )
      .subscribe();

    if (this.metaDescription) {
      this.meta.updateTag({
        name: 'description',
        content: this.metaDescription,
      });
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  loadShop(shopSlug: string): void {
    this.shopDispatcher.updateIsLoading(true);
    this.shopsService
      .loadShopBySlug$(shopSlug, DistributionModeValues.EatIn)
      .pipe(
        takeUntil(this.destroyed$),
        take(1),
        tap(shop => this.shopLoaded(shop)),
      )
      .subscribe();
  }

  private shopLoaded(shop: DoodShopModel): void {
    // this.shop = shop;
    this.shopDispatcher.insertActive(shop);
    this.shopDispatcher.updateIsLoading(false);
  }
}
