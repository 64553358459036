import { createReducer } from '@ngrx/store';

import { CONTENT_STORE_INITIAL_STATE } from './content.state';
import { CONTENT_STORE_API_PIECE, CONTENT_STORE_LOCAL_PIECE } from './content.piece';

export const CONTENT_STORE_REDUCER = createReducer(
  CONTENT_STORE_INITIAL_STATE,
  ...CONTENT_STORE_API_PIECE,
  ...CONTENT_STORE_LOCAL_PIECE,
);
