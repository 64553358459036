import { combineLatest, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { OrderUtils } from '@app/utilities/order.util';
import { DoodValidateOrderQuery } from '@store/order/order.model';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { PaymentMethodHandles } from '@config/payment-methods.config';

import { OrderStoreSelector } from '@common/selectors/order.selector';
import { BasketStoreRefiner } from '@common/refiners/basket.refiner';
import { BasketStoreSelector } from '@common/selectors/basket.selector';
import { PaymentStoreDispatcher } from '@common/dispatchers/payment.dispatcher';

import { OrdersService } from '@core/services/orders/orders.service';
import { PaymentService } from '@core/services/payment/payment.service';
import { TransactionsService } from '@core/services/transactions/transactions.service';
import { NavigationHistoryService } from '@core/services/navigation-history/navigation-history.service';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-payment-page',
  templateUrl: './payment-page.component.html',
})
export class PaymentPageComponent extends DestroyerBase implements OnInit, OnDestroy {
  @Input() body!: unknown[];
  @Input() backgroundColor: IContentBuilderFieldColor = {
    value: '#FFFFFF',
    type: ColorFieldTypesValues.Hex,
  };

  orderValidate$ = this.orderSelector.selectValidation;

  constructor(
    private readonly router: Router,
    private orderSelector: OrderStoreSelector,
    private basketRefiner: BasketStoreRefiner,
    private basketSelector: BasketStoreSelector,
    private readonly ordersService: OrdersService,
    private readonly paymentService: PaymentService,
    private readonly activatedRoute: ActivatedRoute,
    private paymentDispatcher: PaymentStoreDispatcher,
    private readonly transactionsService: TransactionsService,
    private readonly navigationHistoryService: NavigationHistoryService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.navigationHistoryService.pushStateFromActivatedRouteSnapshot(
      'payment',
      this.activatedRoute.snapshot,
    );

    this.paymentService.setLoadingToStore();

    if (this.router.url.includes('payment_method=EDENRED')) {
      this.ordersService.checkCartIsValid$().pipe(take(1)).subscribe();
    }

    combineLatest([
      this.orderValidate$,
      this.orderSelector.selectErrors,
      this.basketRefiner.selectHasBasket,
    ])
      .pipe(
        takeUntil(this._destroyerRef),
        switchMap(([validate, error, isBasketExist]) => {
          if (!validate && !error) {
            return this.ordersService.checkCartIsValid$();
          }

          if (validate && this.isTransactionPaymentMethodAvailable(validate) && isBasketExist) {
            const basketOrderId = this.basketSelector.basket.order;
            if (basketOrderId) {
              return this.orderSelector.selectOrder(basketOrderId);
            }
            return this.orderSelector.selectActive;
          }

          return of(validate);
        }),
      )
      .subscribe(order => {
        if (order) {
          if (OrderUtils.isOrderValidationResponse(order)) {
            const availablePaymentMethods = (order.available_payment_methods ?? [])
              ?.filter(i => i.valid)
              .map(i => i.payment_method)
              .sort((i, j) => i.position - j.position);

            this.paymentDispatcher.updateMethods(availablePaymentMethods);
          }
        }
        this.paymentService.unSetLoadingToStore();
      });
  }

  ngOnDestroy(): void {
    this.transactionsService.clearActiveTransaction();
    super.ngOnDestroy();
  }

  private isTransactionPaymentMethodAvailable(
    validatedOrder: DoodValidateOrderQuery | null,
  ): boolean {
    if (OrderUtils.isOrderValidationResponse(validatedOrder)) {
      if (!validatedOrder || !validatedOrder.available_payment_methods) return false;
      return !!validatedOrder.available_payment_methods.find(
        method => method.payment_method.handle === PaymentMethodHandles.Transactions,
      );
    }
    return false;
  }
}
