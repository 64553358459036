<div
  (click)="cardClick.emit()"
  class="flex justify-between bg-white text-neutral-600 font-base text-base cursor-pointer shadow-S rounded-lg"
>
  <div class="p-4.5">
    <p class="font-accent text-neutral-800 lining-nums text-lg font-bold">
      {{ shopName }}
    </p>
    <div class="pb-1">
      <p class="inline-block">{{ nbItems }} {{ 'orders.items' | translate }} &#x2022;&nbsp;</p>
      <p class="inline-block">
        <app-price-atom
          [bold]="false"
          [amount]="checkAmount"
          [format]="true"
        ></app-price-atom>
        &#x2022; &nbsp;
      </p>
      <p class="inline-block">{{ checkDate }}</p>
    </div>
    <div class="flex">
      <app-tag-status-atom
        *ngIf="!checkOutsandingAmount"
        class="mr-3"
        [type]="'success'"
        [icon]="'icon-c-check'"
        [text]="'pay-at-table.user-checks.paid' | translate"
      ></app-tag-status-atom>
      <app-tag-status-atom
        *ngIf="checkOutsandingAmount"
        class="mr-3"
        [type]="'neutral'"
        [icon]="'icon-hourglass'"
        [text]="'pay-at-table.user-checks.in-progress' | translate"
      ></app-tag-status-atom>

      <app-tag-status-atom
        *ngIf="title"
        [type]="'neutral'"
        [text]="title"
      ></app-tag-status-atom>
    </div>
  </div>
  <div class="flex items-center">
    <div class="h-full flex items-center px-3.5">
      <i class="icon icon-arrow-down-s text-neutral-800"></i>
    </div>
  </div>
</div>
