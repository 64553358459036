<app-pat-payment-panel-adyen-generic
  type="card"
  [isPayButtonVisible]="true"
  [payButtonText]="'payment.pay-with-credit-card' | translate"
  [clientKey]="clientKey"
  [googlePayMerchantName]="googlePayMerchantName"
  [googlePayMerchantId]="googlePayMerchantId"
  [googlePayGatewayMerchantId]="googlePayGatewayMerchantId"
  [paymentMethodCbAvailable]="paymentMethodCbAvailable"
  [paymentMethodPaypalAvailable]="paymentMethodPaypalAvailable"
  [paymentMethodGooglePayAvailable]="paymentMethodGooglePayAvailable"
  [paymentMethodApplePayAvailable]="paymentMethodApplePayAvailable"
  paymentMethodHandle="ADYEN_CB"
>
  <app-pat-payment-panel-adyen-cb-skeleton loadingState></app-pat-payment-panel-adyen-cb-skeleton>
</app-pat-payment-panel-adyen-generic>
