<div class="flex flex-row">
  <a
    (click)="buttonClick.emit($event)"
    class="block cursor-pointer overflow-hidden truncate rounded px-2 py-1 font-base leading-5"
    [style.backgroundColor]="
      isContentBuilderFieldColors(color) ? (color.background | contentBuilderColor) : ''
    "
    [style.color]="isContentBuilderFieldColors(color) ? (color.font | contentBuilderColor) : ''"
    [ngClass]="{
      'text-xs leading-3.5': size === 'S',
      'text-sm': size === 'M',
      'bg-neutral-100 text-neutral-800': color === 'neutral',
      'bg-neutral-200 text-neutral-800': color === 'neutral-200',
      'bg-neutral-300 text-neutral-800': color === 'neutral-300',
      'bg-success-100 text-success-800': color === 'success',
      'bg-primary-100 text-primary-800': color === 'primary',
      'bg-primary-600 text-neutral-50': color === 'primary-invert',
      'bg-secondary-100 text-secondary-800': color === 'secondary',
      'bg-info-100 text-info-800': color === 'info',
      'bg-highlight-100 text-highlight-800': color === 'highlight',
      'bg-error-100 text-error-800': color === 'error',
    }"
    [class.line-through]="lineThrough"
    [class.font-bold]="bold"
    [link]="forceType(url)"
    >{{ text }}</a
  >
</div>
