import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-shop-description-atom',
  templateUrl: './shop-description-atom.component.html',
})
export class ShopDescriptionAtomComponent {
  @Input() name!: string;
  @Input() isFavorite!: boolean;
  @Input() category!: string;
  @Input() shortDescription?: string;
  @Input() stars!: string;
  @Input() description!: string;

  @Output() favoriteChange = new EventEmitter<boolean>();

  onFavoriteClick(): void {
    this.favoriteChange.emit(!this.isFavorite);
  }
}
