import { combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';

import { PaymentService } from '@core/services/payment/payment.service';
import { PaymentStoreDispatcher } from '@common/dispatchers/payment.dispatcher';

@Component({
  template: ``,
  selector: 'app-pat-payment-panel-lyfpay',
})
export class PatPaymentPanelLyfpayComponent implements OnInit {
  constructor(
    private readonly paymentService: PaymentService,
    private paymentDispatcher: PaymentStoreDispatcher,
  ) {}

  ngOnInit(): void {
    this.paymentDispatcher.updateUI({
      button: {
        isEnabled: false,
      },
      status: {
        isLoading: false,
      },
    });

    combineLatest([this.paymentService.pay$, this.paymentService.paymentLyfpayIntent$])
      .pipe(
        take(1),
        map(([pay, paymentIntent]) => {
          if (paymentIntent?.payment_page_url) {
            window.location.href = paymentIntent.payment_page_url;
          } else {
            throw new Error('Payment intent is undefined');
          }
        }),
      )
      .subscribe();
  }
}
