import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map, take, tap } from 'rxjs/operators';
import { PaymentIntent, StripeError } from '@stripe/stripe-js';

import { Paths } from '@config/paths.config';
import { StripeErrors } from '@config/errors.config';

import { CartService } from '@core/services/cart/cart.service';
import { BasketService } from '@core/services/basket/basket.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { TransactionsService } from '@core/services/transactions/transactions.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';

import { IOrder } from '@core/models/order.model';
import { IStripePaymentIntent } from '@core/models/payment.model';
import { DoodOrderModel, DoodOrderStatus } from '@store/order/order.model';
import { TransactionStoreSelector } from '@common/selectors/transaction.selector';
import { PaymentStoreDispatcher } from '@common/dispatchers/payment.dispatcher';

@Injectable({
  providedIn: 'root',
})
export class StripeCustomService {
  paymentIntent$: BehaviorSubject<IStripePaymentIntent | null> =
    new BehaviorSubject<IStripePaymentIntent | null>(null);

  redirectUrl$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(
    private readonly router: Router,
    private readonly cartService: CartService,
    private readonly basketService: BasketService,
    private readonly ordersService: OrdersService,
    private paymentDispatcher: PaymentStoreDispatcher,
    private readonly routerHelper: RouterHelperService,
    private readonly translateService: TranslateService,
    private transactionSelector: TransactionStoreSelector,
    private readonly transactionsService: TransactionsService,
  ) {}

  handlePaymentResult(
    result: { paymentIntent?: PaymentIntent; error?: StripeError },
    order: IOrder,
  ): void {
    if (result.error || !result.paymentIntent) {
      this.handlePaymentError(order, result.error);
      return;
    }

    return this.handlePaymentSuccess(order);
  }

  handlePaymentError(order: IOrder, error?: StripeError): void {
    this.translateService
      .get(['payment.unknown-error'])
      .pipe(
        take(1),
        map(_ => {
          const errorMessage = error?.message ?? 'payment.unknown-error';
          const isStripeConnectionError = error?.type === StripeErrors.ApiConnectionError;

          this.paymentDispatcher.updateUI({
            error: errorMessage,
            button: {
              isEnabled: isStripeConnectionError,
            },
            status: {
              isLoading: false,
              isInProgress: false,
              isRetryInProgress: isStripeConnectionError,
            },
          });

          if (error?.code === StripeErrors.PaymentIntentUnexpectedState) {
            this.synchronizeOrderStatus(order.id);
          }
        }),
      )
      .subscribe();
  }

  handlePaymentSuccess(order: IOrder): void {
    this.paymentDispatcher.updateUI({
      error: null,
      button: {
        isEnabled: true,
      },
      status: {
        isLoading: false,
        isRetryInProgress: false,
      },
    });

    if (this.transactionSelector.active?.order === order.id) {
      this.transactionsService.clearActiveTransaction();
      this.router.navigate([this.routerHelper.translateRoute(`${Paths.GroupPayment}`)]);
      return;
    }

    this.basketService.clearBasket();
    this.cartService.clearCart();
    this.router.navigate([
      this.routerHelper.translateRoute(`${Paths.Orders}/${order.id}/${Paths.Status}`),
    ]);
  }

  synchronizeOrderStatus(orderId: string): void {
    this.ordersService
      .loadOrderById$(orderId)
      .pipe(
        take(1),
        tap((order: DoodOrderModel) => {
          if ([DoodOrderStatus.waiting, DoodOrderStatus.preparation].includes(order.status)) {
            this.ordersService.deActiveOrderToStore();
            this.ordersService.removeOrderFromStore(order.id);
            this.handlePaymentSuccess(order);
          }
        }),
        catchError(error => {
          this.paymentDispatcher.updateUI({
            error: error?.message || 'payment.unknown-error',
            status: {
              isLoading: false,
              isInProgress: false,
              isRetryInProgress: true,
            },
          });
          return error;
        }),
      )
      .subscribe();
  }
}
