import { timer } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { DoodShopModel } from '@core/models/shop.model';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';

import { ShopsService } from '@core/services/shops/shops.service';
import { NativeService } from '@core/services/native/native.service';
import { CartStoreSelector } from '@common/selectors/cart.selector';

@Component({
  selector: 'app-shop-hero-image-atom',
  templateUrl: './shop-hero-image-atom.component.html',
})
export class ShopHeroImageAtomComponent extends DestroyerBase implements OnInit {
  @Input() shop!: DoodShopModel;
  @Input() imageSrc!: string;
  @Input() isPreorderingAllowed?: boolean;
  @Input() isBigHeaderImage?: boolean;
  @Output() clickBack = new EventEmitter<Event>();
  @Output() openParameters = new EventEmitter();

  isNotched = false;
  closedLabel?: string;

  constructor(
    private readonly shopsService: ShopsService,
    private readonly cartStoreSelector: CartStoreSelector,
  ) {
    super();
  }

  openCartParameters(): void {
    this.openParameters.emit();
  }

  async ngOnInit(): Promise<void> {
    this.isNotched = await NativeService.isNotched();

    if (this.isNotched) {
      await StatusBar.setStyle({ style: Style.Dark });
    }
    this.updateClosedLabel();
  }

  updateClosedLabel(): void {
    timer(1, 1000)
      .pipe(
        takeUntil(this._destroyerRef),
        tap(message => {
          const orderType = this.cartStoreSelector.active?.type;
          this.closedLabel = this.shopsService.getClosedMessage(this.shop, null, orderType);
        }),
      )
      .subscribe();
  }
}
