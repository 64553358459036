<div
  class="max-w-full py-3 md:py-6 lg:py-9"
  [style.backgroundColor]="backgroundColor | contentBuilderColor: 'transparent'"
>
  <ng-container *ngIf="displayType === 'grid'">
    <div
      *ngIf="title || description"
      class="w-full px-5 pt-2 md:mb-4 lg:mb-6 lg:px-12"
    >
      <h2
        class="font-brand text-2xl text-neutral-900 lg:text-4xl"
        *ngIf="title"
      >
        {{ title }}
      </h2>
      <p
        *ngIf="description"
        class="mt-3 font-base text-sm text-neutral-600 md:text-base"
      >
        {{ description }}
      </p>
    </div>
    <app-product-push-list-atom
      *ngIf="tagColors$ | async as tagColors"
      displayContext="suggestion"
      [products]="suggestions() ?? []"
      (navigateTo)="onNavigateTo($event)"
      [tagColors]="tagColors"
    ></app-product-push-list-atom>
  </ng-container>

  <div
    class="flex flex-col gap-2.5"
    *ngIf="displayType === 'kiosk_grid'"
  >
    <div
      *ngIf="title || description"
      class="w-full pb-5"
    >
      <h2
        class="px-1 text-3xl font-semibold"
        [style.color]="textColor | contentBuilderColor"
        *ngIf="title"
      >
        {{ title }}
      </h2>
    </div>
    <div
      class="grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-3 kiosk:grid-cols-3"
      [@staggeredListAnimation]="{
        value: suggestions()?.length,
        params: { animateEnterElementFromTopToBottom: true },
      }"
    >
      <ng-container *ngFor="let product of suggestions()">
        <app-kiosk-product-card-atom
          *ngIf="product"
          [isProductAvailable]="isProductAvailable(product)"
          (addProduct)="addProductDispatcher(product)"
          (showProduct)="openProductModal(product)"
          [product]="product"
          [quantity]="product.quantity"
          [textColor]="textColor"
          [cardColor]="cardColor"
          [buttonBackgroundColor]="buttonBackgroundColor"
          [buttonTextColor]="buttonTextColor"
        ></app-kiosk-product-card-atom>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="displayType === 'horizontal_highlight'">
    <app-product-card-list-atom
      displayContext="suggestion"
      [heading]="title"
      [description]="description"
      [products]="suggestions() ?? []"
      (productClick)="onNavigateTo($event)"
    ></app-product-card-list-atom>
  </ng-container>
</div>
