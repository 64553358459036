<div class="bg-neutral-100 px-11">
  <app-top-hero-atom
    [brandText]="baseline ?? ''"
    [links]="[
      { url: socialInstagramURL ?? '', icon: 'instagram' },
      { url: socialFacebookURL ?? '', icon: 'facebook' },
      { url: socialTiktokURL ?? '', icon: 'tiktok' },
      { url: socialLinkedinURL ?? '', icon: 'linkedin' },
    ]"
  ></app-top-hero-atom>
</div>
