<ng-container *ngIf="isActive$ | async">
  <div *ngIf="title">
    <h1 class="top-0 py-4 py-44 text-center font-accent text-4.5xl font-bold text-neutral-800">
      {{ title }}
    </h1>
  </div>
  <div
    class="mx-auto h-full px-5"
    [class.md:pt-32]="!title"
  >
    <app-block-list
      [blockIdList]="items"
      [injectData]="{ formId: blockId }"
      class="flex flex-col gap-28 pb-52"
    ></app-block-list>
  </div>
</ng-container>
