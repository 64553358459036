import { Component, Input } from '@angular/core';

import { ITag } from '@shared/models/tag';

@Component({
  selector: 'app-hero',
  templateUrl: './hero-block.component.html',
})
export class HeroBlockComponent {
  @Input() cta: any;
  @Input() heading!: string;
  @Input() tags!: Array<ITag>;
}
