import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { ModalsService } from '@core/services/modals/modals.service';
import { ShopSearchParametersService } from '@core/services/shop-search-parameters/shop-search-parameters.service';

import { ShopSearchParametersModalComponent } from '@shared/modals/shop-search-parameters-modal/shop-search-parameters-modal.component';

@Component({
  template: ``,
  selector: 'app-shop-search-parameters-modal-launcher-block',
})
export class ShopSearchParametersModalLauncherBlockComponent implements OnInit, OnDestroy {
  @Input() forceLaunch = false;

  constructor(
    private readonly modalsService: ModalsService,
    private readonly shopSearchParametersService: ShopSearchParametersService,
  ) {}

  ngOnInit(): void {
    if (this.forceLaunch) {
      this.modalsService.open(ShopSearchParametersModalComponent.handle);
      return;
    }
    const parameters = this.shopSearchParametersService.getParameters();
    if (!parameters.is_distribution_mode_defined) {
      this.modalsService.open(ShopSearchParametersModalComponent.handle);
    }
  }

  ngOnDestroy(): void {
    this.modalsService.close(ShopSearchParametersModalComponent.handle);
  }
}
