import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { DoodEntityModel } from '@core/models/entity.model';
import { DoodApiError } from '@shared/interfaces/error.interface';

export const ENTITY_STORE_API_ACTIONS = createActionGroup({
  source: 'Dood/API/Entity',
  events: {
    'Fetch Entities': emptyProps(),
    'Fetch Entities Success': props<{ entities: DoodEntityModel[] }>(),
    'Fetch Entities Failure': props<{ error: DoodApiError }>(),
    'Fetch Entities Complete': emptyProps(),
  },
});

export const ENTITY_STORE_LOCAL_ACTIONS = createActionGroup({
  source: 'Dood/LOCAL/Entity',
  events: {
    // Entities
    'Add Entities': props<{ entities: DoodEntityModel[] }>(),
    'Set Entities': props<{ entities: DoodEntityModel[] }>(),
    'Remove Entities': props<{ ids: string[] }>(),
    'Upsert Entities': props<{ entities: DoodEntityModel[] }>(),

    // Entity
    'Add Entity': props<{ entity: DoodEntityModel }>(),
    'Remove Entity': props<{ id: string }>(),
    'Upsert Entity': props<{ entity: DoodEntityModel }>(),

    // Misc
    'reset': emptyProps(),
  },
});
