import {Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild,} from '@angular/core';
import {get} from 'lodash';
import dayjs from 'dayjs';
import {ViewportScroller} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {StatusBar, Style} from '@capacitor/status-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {combineLatest, from, fromEvent, of, skipWhile, Subject, timer} from 'rxjs';
import {debounceTime, map, startWith, switchMap, take, takeUntil, tap, throttleTime,} from 'rxjs/operators';

import {MarketplaceKeys, ShopKeys} from '@config/keys/shop.keys';
import {ProductKeys} from '@config/keys/product.keys';
import {CategoryKeys} from '@config/keys/category.keys';

import {DateUtils} from '@shared/utils/date/date.utils';
import {ColorFieldTypesValues} from '@config/values/color-field-types.values';
import {DoodCategoryWithProduct} from '@shared/interfaces/category.interface';

import {CartStoreRefiner} from '@common/refiners/cart.refiner';
import {ShopStoreRefiner} from '@common/refiners/shop.refiner';
import {ShopStoreSelector} from '@common/selectors/shop.selector';
import {SettingsStoreSelector} from '@common/selectors/settings.selector';
import {MarketplaceStoreSelector} from '@common/selectors/marketplace.selector';

import {ISimpleItem, ISubItem} from '@core/models/simple-item.model';
import {DoodShopModel, IShopCatalog, IShopCatalogTimeRule} from '@core/models/shop.model';
import {IContentBuilderFieldColor} from '@core/models/content-builder-fields.model';
import {CategoryDisplayType, DoodProductModel, ICategory, IProductLabel,} from '@core/models/product.model';

import {OrdersService} from '@core/services/orders/orders.service';
import {NativeService} from '@core/services/native/native.service';
import {ProductService} from '@core/services/product/product.service';
import {ShopAvailableAtService} from '@core/services/shops/shop-available-at.service';
import {RouterHelperService} from '@core/services/router-helper/router-helper.service';

import {
  ProductCategoryCardsWithNameAtomComponent
} from '@shared/atoms/product-category-cards-with-name-atom/product-category-cards-with-name-atom.component';
import {
  ProductCategoryCardsWithoutNameAtomComponent
} from '@shared/atoms/product-category-cards-without-name-atom/product-category-cards-without-name-atom.component';
import {
  ProductCategoryHorizontalHighlightAtomComponent
} from '@shared/atoms/product-category-horizontal-highlight-atom/product-category-horizontal-highlight-atom.component';
import {AnalyticsService} from "@core/services/app/analytics.service";

@Component({
  selector: 'app-shop-product-list-block',
  templateUrl: './shop-product-list-block.component.html',
})
export class ShopProductListBlockComponent implements OnInit, OnDestroy {
  @Input() displayBackShopListButtonMobile = false;
  @Input() displayBackShopListButtonDesktop = false;
  @Input() backShopListButtonText!: string;
  @Input() shopListUrl!: string;
  @Input() shopListButtonColor?: IContentBuilderFieldColor;
  @Input() shopListButtonTextColor?: IContentBuilderFieldColor;
  @Input() categoryListTop = 0;
  @Input() displayBurgerMenuCategories = true;
  @Input() backgroundColor: IContentBuilderFieldColor = {
    value: 'transparent',
    type: ColorFieldTypesValues.Hex,
  };
  @Input() categoriesBackgroundColor: IContentBuilderFieldColor = {
    value: '#FFFFFF',
    type: ColorFieldTypesValues.Hex,
  };
  @Input() burgerBackgroundColor: IContentBuilderFieldColor = {
    value: 'primary-100',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() burgerIconColor: IContentBuilderFieldColor = {
    value: 'primary-600',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() categoriesActiveColor: IContentBuilderFieldColor = {
    value: 'primary-600',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() categoriesInactiveColor: IContentBuilderFieldColor = {
    value: 'neutral-600',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() categoryNameColor: IContentBuilderFieldColor = {
    value: 'neutral-900',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() categoryDescriptionColor: IContentBuilderFieldColor = {
    value: 'neutral-600',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() highlightCategoryNameColor: IContentBuilderFieldColor = {
    type: ColorFieldTypesValues.Default,
    value: '',
  };
  @Input() highlightCategoryBackgroundColor: IContentBuilderFieldColor = {
    value: 'neutral-600',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() productNameColor: IContentBuilderFieldColor = {
    value: 'neutral-900',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() productDescriptionColor: IContentBuilderFieldColor = {
    value: 'neutral-900',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() productPriceColor: IContentBuilderFieldColor = {
    value: 'neutral-900',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() productImagePlaceholderColor: IContentBuilderFieldColor = {
    value: 'neutral-50',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() productBorderColor: IContentBuilderFieldColor = {
    value: 'neutral-100',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() productBackgroundColor: IContentBuilderFieldColor = {
    value: 'transparent',
    type: ColorFieldTypesValues.Hex,
  };

  private readonly filterColors$ =
    this.marketplaceSelector.selectMarketPlaceProductLabelsFilterColors;
  private readonly tagColors$ = this.marketplaceSelector.selectMarketPlaceProductLabelsTagColors;

  @ViewChild('categoryTabsContainer') categoryTabsContainer?: ElementRef;

  shop$ = this.shopSelector.selectActive;
  isShopLoading$ = this.shopSelector.selectIsLoading;
  categoriesWithProducts?: DoodCategoryWithProduct[];

  isOrderingAllowed = true;
  productKeys = ProductKeys;

  cartProducts$ = this.cartRefiner.selectActiveCartProducts;

  tab?: ISimpleItem;
  tabCategories!: ISimpleItem[];
  idsCategories!: string[];
  categoryKeys = CategoryKeys;
  isNotched = false;
  private destroyed$ = new Subject<boolean>();
  availableAtMessage: string | null = null;
  shouldBeAvailableNow = false;

  private previousScrollPosition = 0;

  isScrollingDown = false;

  warningMessages: string[] = [];

  isBurgerNavigationOpen = false;
  filtereableLabels: IProductLabel[] = [];
  selectedLabel?: IProductLabel;

  protected readonly vm$ = combineLatest({
    shop: this.shop$,
    isShopLoading: this.isShopLoading$,
    cartProducts: this.cartProducts$,
    tagColors: this.tagColors$,
    filterColors: this.filterColors$,
  }).pipe(
    startWith({
      isShopLoading: true,
      shop: null,
    }),
  );

  constructor(
    private readonly router: Router,
    private shopRefiner: ShopStoreRefiner,
    private cartRefiner: CartStoreRefiner,
    private shopSelector: ShopStoreSelector,
    private readonly ordersService: OrdersService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly productService: ProductService,
    protected settingsSelector: SettingsStoreSelector,
    private readonly routerHelper: RouterHelperService,
    private readonly marketplaceSelector: MarketplaceStoreSelector,
    private readonly viewportScroller: ViewportScroller,
    private readonly translateService: TranslateService,
    private readonly shopAvailableAtService: ShopAvailableAtService,
    private readonly analyticsService: AnalyticsService,
  ) {
  }

  ngOnInit(): void {
    const marketplace = this.marketplaceSelector.marketplace;

    this.cartRefiner.selectActiveCartProducts
      .pipe(
        take(1),
        switchMap(products =>
          products.length ? this.ordersService.checkCartIsValid$() : of(false),
        ),
      )
      .subscribe();

    this.shop$
      .pipe(
        takeUntil(this.destroyed$),
        skipWhile(shop => !shop),
        debounceTime(250),
        map(
          shop => {
            this.isOrderingAllowed = marketplace.available && !!shop?.[ShopKeys.Available];
            if (!marketplace.available) {
              this.analyticsService.trackEvent('warning', {
                warning_reason: 'marketplace_not_available',
                shop_id: shop?.[ShopKeys.Id],
                shop_name: shop?.[ShopKeys.Name],
                shop_available: shop?.[ShopKeys.Available],
                shop_available_at: shop?.[ShopKeys.AvailableAt],
                marketplace_id: marketplace[MarketplaceKeys.Id],
                marketplace_name: marketplace[MarketplaceKeys.Name],
                marketplace_available: marketplace[MarketplaceKeys.Available],
              });
            }
            if (!shop?.[ShopKeys.Available]) {
              this.analyticsService.trackEvent('warning', {
                warning_reason: 'shop_not_available',
                shop_id: shop?.[ShopKeys.Id],
                shop_name: shop?.[ShopKeys.Name],
                shop_available: shop?.[ShopKeys.Available],
                shop_available_at: shop?.[ShopKeys.AvailableAt],
                marketplace_id: marketplace[MarketplaceKeys.Id],
                marketplace_name: marketplace[MarketplaceKeys.Name],
                marketplace_available: marketplace[MarketplaceKeys.Available],
              });
            }
          },
        ),
      )
      .subscribe();

    timer(1, 1000)
      .pipe(
        takeUntil(this.destroyed$),
        switchMap(() => this.shop$),
        tap(shop => {
          if (!shop) {
            return;
          }
          if (!shop.available_at) {
            return;
          }

          const now = DateUtils.dayjsInMarketplaceTimezone();
          const dateTimeAvailableAt = DateUtils.dayjsInMarketplaceTimezone(shop.available_at);
          this.shouldBeAvailableNow =
            dateTimeAvailableAt.isBefore(now) || dateTimeAvailableAt.isSame(now);
        }),
      )
      .subscribe();

    this.shopRefiner.selectActiveProductsCategories
      .pipe(takeUntil(this.destroyed$))
      .subscribe(categoriesWithProducts =>
        this.updateCategoriesWithProducts(categoriesWithProducts),
      );

    from(NativeService.isNotched())
      .pipe(
        takeUntil(this.destroyed$),
        switchMap(isNotched => {
          this.isNotched = isNotched;
          if (this.isNotched) {
            return from(StatusBar.setStyle({style: Style.Dark}));
          }
          return of(null);
        }),
      )
      .subscribe();

    timer(1, 1000)
      .pipe(
        takeUntil(this.destroyed$),
        tap(() => this.updateAvailableAtMessage()),
      )
      .subscribe();

    this.getWarningMessage();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  navigateTo(product: DoodProductModel): void {
    this.productService.setProductToStore(product);
    this.shopSelector.selectActive
      .pipe(
        take(1),
        map(shop => {
          if (!shop) {
            return;
          }
          this.router.navigate([`products/${shop.id}/${product[ProductKeys.Id]}`], {
            relativeTo: this.activatedRoute,
            fragment: product.id,
          });
        }),
      )
      .subscribe();
  }

  getCategoryComponentClass(category: ICategory): typeof ProductCategoryCardsWithNameAtomComponent {
    const displayTypeMap = {
      [CategoryDisplayType.CardsWithoutName]: ProductCategoryCardsWithoutNameAtomComponent,
      [CategoryDisplayType.HorizontalHighlight]: ProductCategoryHorizontalHighlightAtomComponent,
    };
    const defaultComponentClass = ProductCategoryCardsWithNameAtomComponent;

    return get(displayTypeMap, category[CategoryKeys.DisplayType], defaultComponentClass);
  }

  changeTab(id: string): void {
    this.tab = this.tabCategories?.find(tab => tab.value === id);
  }

  onTabClick(category: { item: ISimpleItem; subItem?: ISubItem }): void {
    if (this.categoryTabsContainer && this.categoryTabsContainer.nativeElement) {
      const topOffset = this.categoryTabsContainer.nativeElement.offsetHeight || 30;
      this.viewportScroller.setOffset([0, topOffset]);
    }
    category.subItem
      ? this.viewportScroller.scrollToAnchor(category.subItem.value)
      : this.viewportScroller.scrollToAnchor(category.item.value);
  }

  backShopListButton(url: string): void {
    this.router.navigate([this.routerHelper.translateRoute(`/${url}`)]);
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    const scroll$ = fromEvent(window, 'scroll');
    scroll$.pipe(throttleTime(10)).subscribe(() => {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

      if (currentScrollPosition - this.previousScrollPosition >= 10) {
        this.isScrollingDown = true;
        return;
      }

      this.isScrollingDown = false;

      this.previousScrollPosition = currentScrollPosition;
    });
  }

  toggleBurgerMenuNavigation(event: boolean): void {
    this.isBurgerNavigationOpen = event;
  }

  private populateCategoriesForTabs(categories: DoodCategoryWithProduct[]): void {
    this.tabCategories = categories
      .filter(c => c.productsInformation?.length)
      .map(category => {
        return {
          label: category.name,
          value: category.id,
        };
      });
    this.idsCategories = categories.map(category => {
      return category.id;
    });
  }

  private updateAvailableAtMessage(): void {
    this.shopSelector.selectActive
      .pipe(
        take(1),
        tap(shop => {
          if (!shop) {
            return;
          }
          this.availableAtMessage = this.shopAvailableAtService.getAvailableAtDisplayString(shop);
        }),
      )
      .subscribe();
  }

  reloadPage(): void {
    document.location.reload();
  }

  private getWarningMessage(): void {
    combineLatest([
      this.isShopLoading$,
      this.shopRefiner.selectActiveShopEnabledDistributionMode,
      this.shopSelector.selectActive,
      this.shopSelector.selectActive.pipe(map(shop => shop?.catalogs)),
      this.shopRefiner.selectActiveShopEnabledCatalogs,
      this.shopRefiner.selectActiveShopEnabledStores,
      this.shopRefiner.selectActiveProductsCategories,
    ])
      .pipe(takeUntil(this.destroyed$), debounceTime(50))
      .subscribe(
        ([isShopLoading, distributionModes, shop, catalogs, enabledCatalogs, stores, products]) => {
          this.warningMessages = [];

          if (isShopLoading) {
            return;
          }

          if (!distributionModes?.length) {
            this.addWarningMessage('shop-page.warning-message.distribution-mode-not-available', shop);
            return;
          }

          if (!catalogs?.length) {
            this.addWarningMessage('shop-page.warning-message.catalog-not-available', shop);
            return;
          }

          const enabledCatalogsTodayTimeRules = this.getEnabledCatalogsTodayTimeRules(
            catalogs as IShopCatalog[],
          );

          if (catalogs?.length && !enabledCatalogsTodayTimeRules?.length) {
            this.addWarningMessage('shop-page.warning-message.catalog-not-available-today', shop);
          }

          const closestFutureCatalogTimeRule = this.findClosestFutureDate(
            enabledCatalogsTodayTimeRules,
          );

          if (closestFutureCatalogTimeRule && !enabledCatalogsTodayTimeRules?.length) {
            this.addWarningMessage('shop-page.warning-message.catalog-not-available-before', shop, {
              time: dayjs()
                .startOf('day')
                .add(closestFutureCatalogTimeRule, 'second')
                .format('HH:mm'),
            });
            return;
          }

          if (enabledCatalogs?.length && !stores?.length) {
            this.addWarningMessage('shop-page.warning-message.stores-not-available', shop);
          }

          if (!products?.length) {
            this.addWarningMessage('shop-page.warning-message.products-not-available', shop);
          }
        },
      );
  }

  private addWarningMessage(translationKey: string, shop: DoodShopModel | null, params?: {
    [key: string]: string
  }): void {
    this.warningMessages.push(
      `${this.translateService.instant(translationKey)} ${params?.time ? params.time : ''}`,
    );

    this.analyticsService.trackEvent('warning', {
      warning_reason: 'no_product_displayed_on_shop_page',
      no_product_available_reason: translationKey.split('.').pop(),
      shop_id: shop?.[ShopKeys.Id],
      shop_name: shop?.[ShopKeys.Name],
      shop_available: shop?.[ShopKeys.Available],
      shop_available_at: shop?.[ShopKeys.AvailableAt]
    });
  }

  private getEnabledCatalogsTodayTimeRules(enabledCatalogs: IShopCatalog[] | undefined): number[] {
    return (enabledCatalogs || [])
      .reduce((acc, catalog) => {
        if (catalog?.enabled_time_rules) {
          acc.push(...catalog.enabled_time_rules);
        }
        return acc;
      }, [] as IShopCatalogTimeRule[])
      .filter(timeRule => timeRule.days_of_week.includes(dayjs().day() + 1))
      .map(timeRule => timeRule.start_time);
  }

  private findClosestFutureDate(dates: number[]): number | null {
    const now = dayjs();

    const dayjsDates = dates.map(seconds => dayjs().startOf('day').add(seconds, 'second'));
    const futureDates = dayjsDates.filter(date => date.isAfter(now));
    const closestFutureDate = futureDates.reduce((closestDate, currentDate) => {
      return currentDate.isBefore(closestDate) ? currentDate : closestDate;
    }, futureDates[0]);
    const closestFutureDateInSeconds = closestFutureDate?.diff(dayjs().startOf('day'), 'second');
    return closestFutureDateInSeconds || null;
  }

  private updateCategoriesWithProducts(categoriesWithProducts: DoodCategoryWithProduct[]) {
    this.categoriesWithProducts = categoriesWithProducts;

    this.populateCategoriesForTabs(categoriesWithProducts);
    this.populateFiltereableLabels(categoriesWithProducts);
  }

  private populateFiltereableLabels(categoriesWithProducts: DoodCategoryWithProduct[]) {
    const filtereableLabelsMap: { [key: string]: IProductLabel } = {};

    categoriesWithProducts.forEach(category => {
      category.productsInformation?.forEach(product => {
        product[ProductKeys.Labels]?.forEach(label => {
          if (label.filtereable && !filtereableLabelsMap[label.id]
            // temp fix: do not display label from DOOD marketplace or label without name
            && !((label.entity_owner?.entity_type === 'marketplace' && label.entity_owner?.entity_id === '60ddd671c771a46420551ba4') || !label.name)
          ) {
            filtereableLabelsMap[label.id] = label;
          }
        });
      });
    });

    this.filtereableLabels = Object.values(filtereableLabelsMap).sort(
      (a, b) => a.position - b.position,
    );
  }

  selectLabel(filtereableLabel: IProductLabel) {
    if (this.selectedLabel === filtereableLabel) {
      this.selectedLabel = undefined;
    } else {
      this.selectedLabel = filtereableLabel;
    }
  }
}
