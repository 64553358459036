import { Store } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Injectable } from '@angular/core';

import { DoodFilterModel } from '@core/models/filters.model';
import { DoodEntityCategory } from '@core/models/entity.model';
import { FILTER_STORE_LOCAL_ACTIONS } from '@store/filter/filter.action';

@Injectable({ providedIn: 'root' })
export class FilterStoreDispatcher {
  constructor(private store: Store) {}

  // Many
  upsertFilters(filters: DoodFilterModel[]): void {
    this.store.dispatch(FILTER_STORE_LOCAL_ACTIONS.upsertFilters({ filters }));
  }

  // One
  upsertFilter(filter: DoodFilterModel): void {
    this.store.dispatch(FILTER_STORE_LOCAL_ACTIONS.upsertFilter({ filter }));
  }

  updateFilter(filter: Update<DoodFilterModel>): void {
    this.store.dispatch(FILTER_STORE_LOCAL_ACTIONS.updateFilter({ filter }));
  }

  // Category
  addCategory(category: DoodEntityCategory): void {
    this.store.dispatch(FILTER_STORE_LOCAL_ACTIONS.addCategory({ category }));
  }

  removeCategory(id: string): void {
    this.store.dispatch(FILTER_STORE_LOCAL_ACTIONS.removeCategory({ id }));
  }

  reset(): void {
    this.store.dispatch(FILTER_STORE_LOCAL_ACTIONS.reset());
  }
}
