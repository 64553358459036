import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  OnInit,
  OnChanges,
  AfterViewInit,
} from '@angular/core';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ISimpleItem, ISubItem } from '@core/models/simple-item.model';
import { DOOD_URL } from '@config/ws.config';
import { staggeredListAnimation } from '@shared/animations/list.animation';

@Component({
  selector: 'app-kiosk-vertical-category-list-atom',
  templateUrl: './kiosk-vertical-category-list-atom.component.html',
  styleUrls: ['./kiosk-vertical-category-list-atom.component.scss'],
  animations: [staggeredListAnimation],
})
export class KioskVerticalCategoryListAtomComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() items!: ISimpleItem[];
  @Input() activeItem?: ISimpleItem;
  @Input() activeSubItem?: ISubItem;
  @Input() displayItems!: ISimpleItem[];
  @Input() backgroundColor?: IContentBuilderFieldColor;
  @Input() itemBackgroundColor?: IContentBuilderFieldColor;
  @Input() itemSelectedBackgroundColor?: IContentBuilderFieldColor;
  @Input() accentColor?: IContentBuilderFieldColor;

  @Output() categoryChange = new EventEmitter<string>();
  @Output() searchEvent = new EventEmitter<Event>();
  @Output() isBurgerNavigationOpen = new EventEmitter<boolean>();

  @ViewChild('scrollContainer') scrollContainer: ElementRef | undefined;
  @ViewChild('scrollUpIcon') scrollUpIcon: ElementRef | undefined;
  @ViewChild('scrollDownIcon') scrollDownIcon: ElementRef | undefined;

  scrollAmount = 100;
  scrollInterval: any;
  activeIndex = 0;
  selectedIndex: number | null = null;

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    this.displayItems = this.items;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeItem) {
      this.detectActiveItem();
    }
  }

  ngAfterViewInit(): void {
    if (this.scrollContainer) {
      this.renderer.listen(this.scrollContainer.nativeElement, 'scroll', (event: Event) => {
        this.onScroll();
      });
      this.updateScrollIconsOpacity();
    }
  }

  startScroll(direction: 'up' | 'down'): void {
    this.scrollInterval = setInterval(() => {
      this.scrollTo(direction);
    }, 10);
  }

  stopScroll(): void {
    clearInterval(this.scrollInterval);
  }

  scrollTo(direction: 'up' | 'down'): void {
    const container = this.scrollContainer?.nativeElement;
    if (container) {
      container.scrollBy({
        top: direction === 'up' ? -this.scrollAmount : this.scrollAmount,
        behavior: 'smooth',
      });
      this.detectActiveItem();
      this.updateScrollIconsOpacity();
    }
  }

  categoryClick(item: ISimpleItem, index: number): void {
    this.activeItem = item;
    this.selectedIndex = index;
    this.categoryChange.emit(item.value);
    this.centerActiveItem(index);
  }

  setActiveItem(index: number): void {
    if (this.activeIndex !== index) {
      this.activeIndex = index;
    }
  }

  onScroll(): void {
    this.detectActiveItem();
    this.updateScrollIconsOpacity();
  }

  detectActiveItem(): void {
    const container = this.scrollContainer?.nativeElement;
    if (container) {
      const scrollTop = container.scrollTop;
      const containerHeight = container.scrollHeight - container.clientHeight;

      const scrollPercentage = containerHeight ? scrollTop / containerHeight : 0;

      const totalItems = this.displayItems.length;
      const calculatedIndex = Math.round(scrollPercentage * (totalItems - 1));

      this.setActiveItem(calculatedIndex);
    }
  }

  centerActiveItem(index: number): void {
    const container = this.scrollContainer?.nativeElement;
    if (container) {
      const itemElement = container.children[index] as HTMLElement;
      if (itemElement) {
        const itemOffsetTop = itemElement.offsetTop;
        const containerHeight = container.clientHeight;
        const itemHeight = itemElement.clientHeight;

        container.scrollTo({
          top: itemOffsetTop - containerHeight / 2 + itemHeight / 2,
          behavior: 'smooth',
        });
      }
    }
  }

  updateScrollIconsOpacity(): void {
    const container = this.scrollContainer?.nativeElement;
    if (container && this.scrollUpIcon && this.scrollDownIcon) {
      const scrollTop = container.scrollTop;
      const maxScrollTop = container.scrollHeight - container.clientHeight;

      const upOpacity = scrollTop === 0 ? 0.5 : 1;
      const downOpacity = scrollTop >= maxScrollTop ? 0.5 : 1;

      this.renderer.setStyle(this.scrollUpIcon.nativeElement, 'opacity', upOpacity);
      this.renderer.setStyle(this.scrollDownIcon.nativeElement, 'opacity', downOpacity);
    }
  }

  toggleSearch(): void {
    this.searchEvent.emit();
  }

  categoryImageUrl(icon: string) {
    return `${DOOD_URL}/api/storages/${icon}/preview/${icon}.png`;
  }
}
