import { Component, OnInit } from '@angular/core';
import { DoodOpenCheck } from '@core/models/check.model';
import { ActivatedRoute } from '@angular/router';
import { OnSiteLocationsService } from '@core/services/on-site-locations/on-site-locations.service';
import { switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-check-details-block',
  templateUrl: './check-details-block.component.html',
})
export class CheckDetailsBlockComponent implements OnInit {
  check!: DoodOpenCheck;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly onSiteLocationsService: OnSiteLocationsService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.url
      .pipe(
        switchMap(url => this.onSiteLocationsService.getUserCheck$(url[1].path)),
        tap(check => (this.check = check)),
      )
      .subscribe();
  }
}
