<div class="p-5 md:p-7 bg-white rounded-t-3xl md:rounded-3xl">
  <div class="flex justify-between items-center pb-6">
    <h2 class="font-accent font-bold text-3xs lg:text-32px">
      {{ 'voucher-modal.title' | translate }}
    </h2>
  </div>
  <div class="text-neutral-800 leading-5 pb-6">
    <input
      [(ngModel)]="voucher"
      [placeholder]="'voucher-modal.placeholder' | translate"
      class="w-full border border-neutral-800 rounded py-4.5 px-5 lg:w-448px"
      autofocus
    />
  </div>
  <p
    *ngIf="error"
    class="font-base text-secondary-700 pb-4.5"
  >
    {{ 'voucher-modal.voucher-not-available' | translate }}
  </p>
  <div class="pt-1 flex">
    <div class="mr-2 xm:pr-1 w-full xm:w-1/2">
      <app-button-atom
        size="M"
        [label]="'voucher-modal.cancel' | translate"
        color="primary"
        (click)="closeVoucher.emit()"
        type="light"
        [full]="true"
      ></app-button-atom>
    </div>
    <div class="xm:pt-0 xm:pl-1 w-full xm:w-1/2">
      <app-button-atom
        [iconUrl]="isValidating ? 'assets/images/spinner.svg' : ''"
        [disabled]="isValidating"
        size="M"
        [label]="'voucher-modal.add' | translate"
        (click)="applyVoucher.emit(voucher)"
        type="primary"
        [full]="true"
      ></app-button-atom>
    </div>
  </div>
</div>
