import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ISubItem } from '@core/models/simple-item.model';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DoodFilterModel } from '@core/models/filters.model';

@Component({
  selector: 'app-toggle-filter-atom',
  templateUrl: './toggle-filter-atom.component.html',
  styleUrls: ['./toggle-filter-atom.component.scss'],
  animations: [
    trigger('isAccordionActive', [
      state(
        'hidden',
        style({
          height: '0',
          overflow: 'hidden',
        }),
      ),
      state(
        'visible',
        style({
          height: '*',
        }),
      ),
      transition('visible <=> hidden', [
        style({ overflow: 'hidden' }),
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'),
      ]),
      transition('void => *', animate(0)),
    ]),
  ],
})
export class ToggleFilterAtomComponent implements OnInit, OnChanges {
  @Input() name!: string;
  @Input() id!: string;
  @Input() type!: 'checkbox' | 'radio' | 'custom-radio';
  @Input() multiSelect!: boolean;
  @Input() items!: ISubItem[];
  @Input() selectedItems: ISubItem[] = [];

  @Input() initItemValues!: DoodFilterModel[];

  form!: UntypedFormGroup;
  isAccordionActive = true;

  @Output() selectedItemsChange = new EventEmitter<DoodFilterModel>();

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.initItemValues?.map(item => {
      if (item.id === this.id && item.type === 'radio') {
        this.form.patchValue({ [item.type]: item.values?.[0]?.value });
      } else if (item.id === this.id && item.type === 'checkbox') {
        item.values?.map(value => {
          this.form.patchValue({ [value.value]: true });
        });
      } else if (item.id === this.id && item.type === 'custom-radio') {
        this.selectedItems = item.values ? (item.values as ISubItem[]) : [];
      }
    });
  }

  buildForm(items: ISubItem[]): void {
    switch (this.type) {
      case 'checkbox': {
        const checkSelectedValues = items
          .map(item => item.value)
          .reduce((acc: any, value) => {
            return {
              ...acc,
              [value]: this.selectedItems.some(item => item.value === value),
            };
          }, {});
        this.form = this.fb.group(checkSelectedValues);
        break;
      }
      case 'radio': {
        this.form = this.fb.group({
          radio: this.selectedItems.length ? this.selectedItems[0].value : null,
        });
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items) {
      const { currentValue } = changes.items;
      this.buildForm(currentValue);
    }
    if (changes.type) {
      this.buildForm(this.items);
    }
  }

  updateCheckBox(name: string): void {
    const formValue = this.form.value;
    this.selectedItems = this.items.filter(item => {
      return formValue[item.value];
    });
    this.selectedItemsChange.emit({
      id: name,
      type: 'checkbox',
      values: this.selectedItems,
    });
  }

  selectRadio(name: string, item: ISubItem): void {
    this.selectedItems = [item];
    this.selectedItemsChange.emit({
      id: name,
      type: 'radio',
      values: this.selectedItems,
    });
  }

  selectCustomRadio(name: string, item: ISubItem): void {
    if (this.multiSelect) {
      this.selectedItems = [...this.selectedItems];
      const selectedItemIndex = this.selectedItems.findIndex(
        selectedItem => selectedItem.value === item.value,
      );
      selectedItemIndex >= 0
        ? this.selectedItems.splice(selectedItemIndex, 1)
        : this.selectedItems.push(item);
    } else {
      this.selectedItems = [item];
    }
    this.selectedItemsChange.emit({
      id: name,
      type: 'custom-radio',
      values: this.selectedItems,
    });
  }

  toggleAccordion(): void {
    this.isAccordionActive = !this.isAccordionActive;
  }

  isCustomRadioValueSelected(item: ISubItem): boolean {
    if (this.multiSelect)
      return !!this.selectedItems.find(selectedItem => selectedItem.value === item.value);
    return item.value === this.selectedItems[0]?.value;
  }

  forceType(data: any): any {
    return data as any;
  }
}
