import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import {
  PAYMENT_STORE_COMPONENT_ACTIONS,
  PAYMENT_STORE_LOCAL_ACTIONS,
  PaymentBancontactState,
  PaymentButtonState,
  PaymentStatusState,
  PaymentTransactionState,
} from '@store/payment';
import { ObjectUtils } from '@app/utilities/object.util';
import { DoodPaymentMethodTypeModel } from '@store/order/order.model';
import { PaymentUpdateDataOptions, PaymentUpdateUiOptions } from '@store/payment/payment.model';

@Injectable({ providedIn: 'root' })
export class PaymentStoreDispatcher {
  constructor(private store: Store) {}

  resetState(): void {
    this.store.dispatch(PAYMENT_STORE_LOCAL_ACTIONS.reset());
  }

  updateUI(options: PaymentUpdateUiOptions | null): void {
    if (ObjectUtils.isNull(options)) {
      this.resetUI();
      return;
    }

    const { error, button, status } = options;
    this.store.dispatch(
      PAYMENT_STORE_COMPONENT_ACTIONS.updateUI({
        changes: { error, button, status },
      }),
    );
  }

  resetUI(): void {
    this.store.dispatch(PAYMENT_STORE_COMPONENT_ACTIONS.resetUI());
  }

  updateData(options: PaymentUpdateDataOptions | null): void {
    if (ObjectUtils.isNull(options)) {
      this.store.dispatch(PAYMENT_STORE_COMPONENT_ACTIONS.resetData());
      return;
    }

    const { amount, methods, transaction } = options;
    this.store.dispatch(
      PAYMENT_STORE_COMPONENT_ACTIONS.updateData({
        changes: { amount, methods, transaction },
      }),
    );
  }

  updateAmount(amount: number | null): void {
    this.store.dispatch(PAYMENT_STORE_LOCAL_ACTIONS.updateAmount({ amount }));
  }

  updateMethods(methods: DoodPaymentMethodTypeModel[]): void {
    this.store.dispatch(PAYMENT_STORE_LOCAL_ACTIONS.updateMethods({ methods }));
  }

  updateTransaction(transaction: Partial<PaymentTransactionState> | null): void {
    if (ObjectUtils.isNull(transaction)) {
      this.store.dispatch(PAYMENT_STORE_LOCAL_ACTIONS.resetTransaction());
    } else {
      this.store.dispatch(PAYMENT_STORE_LOCAL_ACTIONS.updateTransaction({ transaction }));
    }
  }

  updateError(error: string | null): void {
    this.store.dispatch(PAYMENT_STORE_LOCAL_ACTIONS.updateError({ error }));
  }

  updateStatus(status: Partial<PaymentStatusState> | null): void {
    if (ObjectUtils.isNull(status)) {
      this.store.dispatch(PAYMENT_STORE_LOCAL_ACTIONS.resetStatus());
    } else {
      this.store.dispatch(PAYMENT_STORE_LOCAL_ACTIONS.updateStatus({ status }));
    }
  }

  updateButton(button: Partial<PaymentButtonState> | null): void {
    if (ObjectUtils.isNull(button)) {
      this.store.dispatch(PAYMENT_STORE_LOCAL_ACTIONS.resetButton());
    } else {
      this.store.dispatch(PAYMENT_STORE_LOCAL_ACTIONS.updateButton({ button }));
    }
  }

  updateBancontact(bancontact: Partial<PaymentBancontactState> | null): void {
    if (ObjectUtils.isNull(bancontact)) {
      this.store.dispatch(PAYMENT_STORE_LOCAL_ACTIONS.resetBancontact());
    } else {
      this.store.dispatch(PAYMENT_STORE_LOCAL_ACTIONS.updateBancontact({ bancontact }));
    }
  }

  reset(): void {
    this.store.dispatch(PAYMENT_STORE_LOCAL_ACTIONS.reset());
  }
}
