<app-payment-panel-adyen-generic
  type="mealVoucher_FR_natixis"
  [isPayButtonVisible]="true"
  [payButtonText]="'payment.pay-with-natixis' | translate"
  [clientKey]="clientKey"
  [paymentMethodMealVoucherNatixisAvailable]="paymentMethodMealVoucherNatixisAvailable"
  paymentMethodHandle="ADYEN_MEAL_VOUCHER_NATIXIS"
>
  <app-payment-panel-adyen-cb-skeleton loadingState></app-payment-panel-adyen-cb-skeleton>
</app-payment-panel-adyen-generic>
