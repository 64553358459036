import { Component } from '@angular/core';
import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'app-validate-shared-order-header-block',
  templateUrl: './validate-shared-order-header-block.component.html',
})
export class ValidateSharedOrderHeaderBlockComponent {
  constructor(private readonly location: LocationStrategy) {}

  back(): void {
    this.location.back();
  }
}
