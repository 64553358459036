import { Component, Input } from '@angular/core';
import { OrderStoreSelector } from '@common/selectors/order.selector';
import { ICoupon } from '@core/models/cart.model';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { CartService } from '@core/services/cart/cart.service';
import { NotificationService } from '@core/services/notification/notification.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { TranslateService } from '@ngx-translate/core';
import { map, take, tap } from 'rxjs';

@Component({
  selector: 'app-kiosk-cart-promo-code-block',
  templateUrl: './kiosk-cart-promo-code-block.component.html',
})
export class KioskCartPromoCodeBlockComponent {
  @Input() primaryButtonBackgroundColor?: IContentBuilderFieldColor;
  @Input() primaryButtonTextColor?: IContentBuilderFieldColor;
  @Input() secondaryButtonBackgroundColor?: IContentBuilderFieldColor;
  @Input() secondaryButtonTextColor?: IContentBuilderFieldColor;
  @Input() textColor?: IContentBuilderFieldColor;
  @Input() backgroundColor?: IContentBuilderFieldColor;

  protected order$ = this.orderSelector.selectValidation;
  protected coupons$ = this.order$.pipe(map(order => order?.coupons as ICoupon[]));

  coupons = [];

  isValidating = false;
  errorVoucher: string | undefined = undefined;
  voucher: string | undefined = undefined;

  constructor(
    private readonly orderSelector: OrderStoreSelector,
    private readonly cartService: CartService,
    private readonly ordersService: OrdersService,
    private readonly notificationService: NotificationService,
    private readonly translateService: TranslateService,
  ) {}

  applyVoucher(voucher: string | undefined): void {
    if (voucher) {
      voucher = voucher.trim();
      this.isValidating = true;
      this.cartService.addCoupon({ code: voucher });
      this.ordersService
        .checkCartIsValid$()
        .pipe(
          take(1),
          tap(order => {
            const coupon = order?.coupons?.find(el => el.code === voucher);
            if (coupon?.error) {
              this.errorVoucher = coupon.error;
              this.isValidating = false;
              this.cartService.removeCoupon({ code: voucher });
              this.ordersService.checkCartIsValid$().subscribe();
              return;
            }
            this.errorVoucher = '';
            this.notificationService.displayMessage(
              this.translateService.instant('basket.notification-valid-voucher'),
            );
            this.voucher = undefined;
          }),
        )
        .subscribe();
    }
  }

  removeCoupon(coupon: any): void {
    this.cartService.removeCoupon(coupon);
    this.ordersService.checkCartIsValid$().subscribe();
  }
}
