<div class="p-5 rounded-xl max-w-screen-sm mx-auto">
  <div class="flex">
    <div [class.pr-4]="imgUrl">
      <p class="pb-2 font-accent font-bold text-1xsl leading-tight">
        {{ title }}
      </p>
      <p class="pb-5 font-accent text-base text-neutral-600 leading-tight">
        {{ text }}
      </p>
    </div>
    <div *ngIf="imgUrl">
      <img
        class="min-w-16.5"
        src="{{ imgUrl }}"
        alt=""
      />
    </div>
  </div>
  <div
    class="flex justify-center"
    *ngIf="!isSafari"
  >
    <app-button-atom
      icon="icon-cross"
      [type]="'outlined'"
      [size]="'S'"
      color="neutral"
      label="Non"
      (clickEventEmitter)="noButtonClick.emit($event)"
    ></app-button-atom>
    <app-button-atom
      class="ml-6"
      icon="icon-check-single"
      [type]="'outlined'"
      [size]="'S'"
      color="neutral"
      label="Oui"
      (clickEventEmitter)="yesButtonClick.emit($event)"
    ></app-button-atom>
  </div>
  <div
    class="font-accent text-sm text-neutral-600 leading-tight"
    *ngIf="isSafari"
  >
    Appuyez <i class="icon-share-button"></i>, puis sur "Ajouter à l'écran d'accueil"
    <div
      class="flex justify-center"
      *ngIf="isSafari"
    >
      <i class="icon icon-arrow-down-s icon-rotate-90 icon-32"></i>
    </div>
  </div>
</div>
