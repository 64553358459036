<div
  class="flex px-3 py-2"
  [class.pb-5]="isNotched"
  (click)="clickItem.emit()"
>
  <div class="flex justify-between w-full">
    <div
      *ngFor="let item of items"
      class="flex items-center mr-3 cursor-pointer"
    >
      <div
        class="mr-2"
        *ngIf="item.icon && item.label"
      >
        <app-rounded-icon-button
          [icon]="item.icon"
          [overrideBackgroundColor]="iconBackgroundColor"
          [overrideColor]="iconColor"
          type="light"
        ></app-rounded-icon-button>
      </div>
      <span
        [innerHTML]="item.label | translate"
        class="text-sm kiosk:text-base"
        [style.color]="textColor | contentBuilderColor"
      ></span>
    </div>
    <div class="flex items-center">
      <button
        class="icon icon-arrow-down-s"
        [style.color]="textColor | contentBuilderColor"
      ></button>
    </div>
  </div>
</div>
