import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-rounded-icon-button',
  templateUrl: './rounded-icon-button.component.html',
  styleUrls: ['./rounded-icon-button.component.scss'],
})
export class RoundedIconButtonComponent {
  @Input() icon!: string;
  @Input() color: 'neutral' | 'primary' | 'success' | 'info' | 'highlight' | 'error' = 'primary';
  @Input() type: 'light' | 'normal' | 'extra-light' | 'white' | 'without-background' | 'outlined' =
    'light';
  @Input() size: 'xs' | 'sm' | 'md' | 'isNotched' = 'sm';
  @Input() fontSize!: string;
  @Input() outlined = false;
  @Input() disabled = false;
  @Input() bold = false;
  @Input() overrideColor?: IContentBuilderFieldColor;
  @Input() overrideBackgroundColor?: IContentBuilderFieldColor;

  @Output() onClick = new EventEmitter<Event>();

  getButtonClasses(): any {
    return {
      'opacity-50': this.disabled,
      ['text-' + this.color + '-600']:
        !this.overrideColor && (this.type === 'light' || this.type === 'extra-light'),
      'text-neutral-800':
        !this.overrideColor && (this.type === 'white' || this.type === 'without-background'),
      'text-white': !this.overrideColor && this.type === 'normal',
      ['bg-' + this.color + '-100']: !this.overrideBackgroundColor && this.type === 'light',
      ['bg-' + this.color + '-50']: !this.overrideBackgroundColor && this.type === 'extra-light',
      'bg-white': !this.overrideBackgroundColor && this.type === 'white',
      'bg-transparent': !this.overrideBackgroundColor && this.type === 'without-background',
      ['bg-' + this.color + '-600']: !this.overrideBackgroundColor && this.type === 'normal',
      ['bg-transparent border-4 text-2xl border-' +
      this.overrideColor +
      'text-' +
      this.overrideColor]: this.type === 'outlined',
      ['font-bold']: this.bold,
    };
  }
}
