import { computed, effect, inject, Injectable, Signal, signal, untracked } from '@angular/core';
import { IAdditionGroup } from '@core/models/shop.model';
import { DoodProductModel } from '@core/models/product.model';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { distinctUntilChanged, map } from 'rxjs';
import { ValidityWithAdditionsByGroupsState } from '../cart/util/cart-simple-product-util';
import { SimpleProductCartUtil } from './util/simple-product-stepper-util';
import { CartDraftSimpleProductStore } from '../cart/cart-draft-simple-product.store';
import { SimpleProductWithAdditionsGroupStep, Stepper } from './stepper.type';
import { RootStepperStore } from './root-stepper.store';
import { StepInfo } from '@core/models/stepper.model';

export type SimpleProductConfigStepperData = {
  additionsByGroups: IAdditionGroup[];
  product: DoodProductModel;
  selectedAdditionIds: string[];
  cartValidity: ValidityWithAdditionsByGroupsState;
};

export type SimpleProductConfigStepper = Signal<SimpleProductConfigStepperData | undefined>;
export interface SimpleProductStepper {
  simpleProductStepper: Signal<SimpleProductWithAdditionsGroupStep | undefined>;
  setSelectedAdditionsGroupId: (id: string) => void;
}

@Injectable()
export class SimpleProductStepperStore implements SimpleProductStepper {
  private readonly cartDraftSimpleProductStore = inject(CartDraftSimpleProductStore);
  private readonly rootStepperStore = inject(RootStepperStore);

  constructor() {
    effect(
      () => {
        const stepper = this.stepper();

        this.rootStepperStore.setChildStepper(stepper);
      },
      {
        allowSignalWrites: true,
      },
    );
  }

  private readonly productId = toSignal(
    toObservable(this.cartDraftSimpleProductStore.selectors).pipe(
      map(selectors => selectors?.draftSimpleProduct?.id),
      distinctUntilChanged(),
    ),
  );

  private readonly selectedAdditionsGroupId = computed(() => {
    const productId = this.productId();
    // reset to undefined each time the product change
    if (!productId) {
      return undefined;
    }
    // ! go automatically to the first step
    return signal<string | undefined>(
      untracked(() => this.cartDraftSimpleProductStore.selectors())?.draftAdditionGroups[0]?.id,
    );
  });

  public setSelectedAdditionsGroupId(id: string) {
    this.selectedAdditionsGroupId()?.set(id);
  }

  public readonly simpleProductStepper = computed<SimpleProductWithAdditionsGroupStep | undefined>(
    () => {
      const productId = this.productId();
      // reset to undefined each time the product change
      if (!productId) {
        return undefined;
      }

      const selectors = this.cartDraftSimpleProductStore.selectors();
      if (!selectors) {
        return undefined;
      }
      const selectedStepId = this.selectedAdditionsGroupId()?.();

      return SimpleProductCartUtil.calculateSimpleProductStates({
        product: selectors.draftSimpleProduct,
        simpleProductAdditionsByGroups: selectors.draftAdditionGroups,
        selectedAdditionsGroupId: selectedStepId,
        isAdditionGroupValid: SimpleProductCartUtil.isSelectedGroupValid({
          selectedAdditionsGroupId: selectedStepId,
          cartValidity: selectors.validity,
        }),
        totalGroupAdditionsQuantity: SimpleProductCartUtil.getAdditionGroupTotalSelected({
          selectedAdditionIds: selectors.cartDraft.additions,
          simpleProductAdditionsByGroups: selectors.draftAdditionGroups,
          selectedAdditionsGroupId: selectedStepId,
        }),
        setSelectedStep: (stepsId: StepInfo) => {
          const additionsGroupId = stepsId.additionsGroupId;
          if (!additionsGroupId) {
            return;
          }
          this.setSelectedAdditionsGroupId(additionsGroupId);
        },
        currentStep: {
          additionsGroupId: selectedStepId,
        },
      });
    },
  );

  public readonly stepper = computed<Stepper | undefined>(() => {
    const stepper = this.simpleProductStepper();

    if (!stepper) {
      return undefined;
    }

    return {
      step: stepper,
      childStepper: undefined,
    } satisfies Stepper;
  });

  // only used to remember to inject the store in the component
  public init() {
    return;
  }
}
