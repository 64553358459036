import { Comparer, createEntityAdapter, EntityAdapter, IdSelector } from '@ngrx/entity';

import { ActiveAdapter, createActiveAdapter } from '@store/_abstract/active/active.adapter';

import { FunnelState } from './funnel.state';

interface FunnelStepAdapter<Step, Id> extends ActiveAdapter<Step, Id> {}

interface FunnelValueAdapter<Value> extends EntityAdapter<Value> {}

interface FunnelValueAdapterOptions<Value> {
  selectId?: IdSelector<Value>;
  sortComparer?: false | Comparer<Value>;
}

interface FunnelStepAdapterOptions<Step, Id> {
  selectId?: IdSelector<Step>;
  sortComparer?: false | Comparer<Step>;
}

export interface FunnelAdapterOptions<Step, Value, Id> {
  step?: FunnelStepAdapterOptions<Step, Id> | undefined;
  value?: FunnelValueAdapterOptions<Value> | undefined;
}

export interface FunnelAdapter<Step, Value, Id> {
  steps: FunnelStepAdapter<Step, Id>;
  values: FunnelValueAdapter<Value>;
  getInitialState: <State extends FunnelState<Step, Value, Id>>(state?: Partial<State>) => State;
}

const createFunnelStepAdapter = <Step, Id>(
  options?: FunnelStepAdapterOptions<Step, Id>,
): FunnelStepAdapter<Step, Id> => createActiveAdapter<Step, Id>(options);

const createFunnelValueAdapter = <Value>(
  options?: FunnelValueAdapterOptions<Value>,
): FunnelValueAdapter<Value> => createEntityAdapter<Value>(options);

export const createFunnelAdapter = <Step, Value, Id>(
  options?: FunnelAdapterOptions<Step, Value, Id>,
): FunnelAdapter<Step, Value, Id> => {
  const steps = createFunnelStepAdapter<Step, Id>(options?.step);
  const values = createFunnelValueAdapter<Value>(options?.value);
  return {
    steps,
    values,
    getInitialState: <State extends FunnelState<Step, Value, Id>>(
      state?: Partial<State>,
    ): State => {
      return {
        ...(state ?? {}),
        steps: steps.getInitialState(),
        values: values.getInitialState(),
      } as State;
    },
  };
};
