import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { DoodProductModel } from '@core/models/product.model';
import { ProductKeys } from '@config/keys/product.keys';
import { ProductService } from '@core/services/product/product.service';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';
import {ProductTrackerService} from "@core/services/app/product-tracker.service";

@Component({
  selector: 'app-product-card-atom',
  templateUrl: './product-card-atom.component.html',
})
export class ProductCardAtomComponent implements OnInit {
  @Input() displayContext = '';
  @Input() product!: DoodProductModel;
  @Input() isOrderingAllowed = true;
  @Input() productNameColor: IContentBuilderFieldColor = {
    value: 'neutral-900',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() productPriceColor: IContentBuilderFieldColor = {
    value: 'neutral-900',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() productImagePlaceholderColor: IContentBuilderFieldColor = {
    value: 'neutral-50',
    type: ColorFieldTypesValues.Palette,
  };

  @Output() productClick = new EventEmitter<DoodProductModel>();

  constructor(
    private readonly productService: ProductService,
    private readonly productTrackerService: ProductTrackerService,

    ) {}

  ngOnInit(): void {
    this.productTrackerService.trackProductDisplayed(this.product, this.displayContext);
  }


  isAvailable(product: DoodProductModel): boolean {
    return this.productService.isAvailable(product);
  }

  get ProductKeys(): typeof ProductKeys {
    return ProductKeys;
  }

  onProductClick(product: DoodProductModel): void {
    if (!this.isAvailable(product)) {
      return;
    }
    this.productClick.emit(product);
  }

  forceType(data: any): any {
    return data as any;
  }
}
