<app-modal-filler-atom
  (click)="close()"
  [index]="(index$ | async) ?? 0"
></app-modal-filler-atom>
<div
  class="fixed left-0 right-0 overflow-y-auto shadow md:left-0 md:top-0 md:flex md:h-full md:w-full md:items-center md:justify-center mdMax:bottom-0 mdMax:mx-auto mdMax:max-h-85-screen mdMax:max-w-screen-sm"
  [style.z-index]="((index$ | async) ?? 0) + 50"
>
  <app-modal-filler-atom
    class="hidden md:block"
    [withBackdrop]="false"
    (click)="close()"
    [index]="(index$ | async) ?? 0"
  ></app-modal-filler-atom>
  <div
    class="rounded-t-3xl bg-white p-5 md:w-2/5 md:min-w-448 md:max-w-lg md:rounded-3xl md:p-7"
    [style.z-index]="((index$ | async) ?? 0) + 50"
  >
    <h1
      class="pb-5 font-brand text-28px font-bold text-neutral-800"
      translate="group-payments.add-payment-modal.title"
    ></h1>
    <div class="pb-5">
      <ng-container *ngIf="userHasPaid$ | async">
        <div class="flex cursor-pointer flex-col text-neutral-600">
          <div class="flex items-center justify-between py-4.5">
            <div class="flex w-full items-center justify-center justify-between">
              <div>
                <span class="pr-1.5 text-xs">
                  <input
                    type="radio"
                    class="shadow-custom form-radio m-1 border-2 border-neutral-400 border-primary-600 bg-primary-600 bg-none p-2.5"
                    [checked]="true"
                  />
                </span>
                <span class="text-sm text-neutral-800">
                  {{ 'group-payments.add-payment-modal.pay-remaining-amount' | translate }}
                </span>
              </div>
              <div class="text-sm text-neutral-800">
                <app-price-atom
                  *ngIf="this.remainingAmount"
                  [size]="'SM'"
                  [amount]="this.remainingAmount"
                  [bold]="false"
                  [format]="true"
                ></app-price-atom>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!(userHasPaid$ | async)">
        <div *ngFor="let paymentMethod of paymentOptions">
          <div
            *ngIf="paymentMethod.mode !== paymentModalAmountModes.Remaining"
            class="flex cursor-pointer flex-col text-neutral-600"
          >
            <div
              class="flex items-center justify-between py-4.5"
              (click)="changePaymentOption(paymentMethod)"
            >
              <div class="flex items-center">
                <div>
                  <span class="pr-1.5 text-xs">
                    <input
                      type="radio"
                      [ngClass]="{
                        'p-2.5 bg-primary-600 border-primary-600 m-1 shadow-custom':
                          paymentMethod === selectedPaymentMethod,
                      }"
                      class="form-radio m-2 border-2 border-neutral-400 bg-none p-1.5"
                      [checked]="paymentMethod === selectedPaymentMethod"
                    />
                  </span>
                  <span class="text-sm text-neutral-800">
                    {{ 'group-payments.add-payment-modal.' + paymentMethod.label | translate }}
                  </span>
                  (<app-price-atom
                    *ngIf="paymentMethod.mode === paymentModalAmountModes.Own"
                    [size]="'SM'"
                    [amount]="this.ownAmount"
                    [bold]="false"
                    [format]="true"
                  ></app-price-atom>
                  <app-price-atom
                    *ngIf="paymentMethod.mode === paymentModalAmountModes.Full"
                    [size]="'SM'"
                    [amount]="this.remainingAmount"
                    [bold]="false"
                    [format]="true"
                  ></app-price-atom
                  >)
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div *ngIf="errorMessage">
      <p
        class="py-4 text-center text-sm text-error-700"
        [translate]="errorMessage"
      ></p>
    </div>

    <app-button-atom
      size="L"
      [disabled]="disablePayment"
      [label]="'group-payments.add-payment-modal.continue-to-payment' | translate"
      type="primary"
      [full]="true"
      (click)="onSubmit()"
    >
    </app-button-atom>
  </div>
</div>
