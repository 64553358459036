<app-modal-filler-atom
  (click)="close()"
  [index]="(index$ | async) ?? 0"
></app-modal-filler-atom>
<div
  class="fixed mx-auto right-0 left-0 shadow overflow-y-auto bottom-0 max-h-85-screen max-w-screen-sm lg:flex lg:justify-center lg:items-center lg:w-full lg:h-full lg:top-0 lg:left-0'"
  [style.z-index]="((index$ | async) ?? 0) + 50"
>
  <div class="p-5 md:p-7 pt-10 bg-white rounded-t-3xl lg:rounded-3xl">
    <div class="flex justify-between items-center mb-6">
      <div class="font-accent font-bold text-md">
        {{ 'como.confirm-use-points' | translate: { assetName: getAssetName() } }}
      </div>
    </div>

    <div class="pt-1 flex flex-nowrap">
      <div class="pr-1 w-1/2">
        <app-button-atom
          size="M"
          [label]="'como.cancel' | translate"
          color="primary"
          (click)="close()"
          type="light"
          [full]="true"
        ></app-button-atom>
      </div>
      <div class="pt-0 pl-1 w-1/2">
        <app-button-atom
          size="M"
          [label]="'como.validate' | translate"
          (click)="confirm()"
          type="primary"
          [full]="true"
          [iconUrl]="isProcessing ? 'assets/images/spinner.svg' : ''"
          [disabled]="isProcessing"
        ></app-button-atom>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <ng-container *ngFor="let i of [].constructor(2)">
    <div class="animate-pulse h-4 bg-neutral-100 rounded w-32 mb-3"></div>
    <div class="animate-pulse h-10 bg-neutral-50 rounded w-full mb-6"></div>
  </ng-container>
</ng-template>
