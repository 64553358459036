<div
  class="fixed mx-auto h-full w-full md:bottom-0 md:left-0 md:flex md:flex-col md:items-center md:justify-center mdMax:bottom-0 mdMax:left-0 mdMax:right-0 mdMax:top-0"
  [style.z-index]="110"
>
  <app-modal-filler-atom
    (click)="close()"
    [index]="0"
  ></app-modal-filler-atom>
  <div
    class="flex h-screen w-full flex-col overflow-y-auto bg-white pb-36 scrollbar-hide md:relative md:my-20 md:h-auto md:max-h-[90vh] md:max-w-[90vw] md:rounded-3xl md:pb-0"
    [style.z-index]="55"
    [style.backgroundColor]="backgroundColor | contentBuilderColor"
    [style.color]="textColor | contentBuilderColor"
  >
    <div class="my-10 text-center text-5xl font-bold">
      {{ heading | translate }}
    </div>
    <div class="mx-5 grid grid-cols-3 gap-5 overflow-y-auto p-4">
      <ng-container *ngIf="!suggestions || suggestions.length === 0">
        <ng-container *ngFor="let i of [].constructor(6)">
          <div class="w-full flex-grow md:max-w-screen-sm">
            <div class="h-60 w-full animate-pulse bg-neutral-100"></div>
            <div class="w-full px-5 py-5">
              <p class="mb-3 h-8 w-8/12 animate-pulse rounded bg-neutral-200"></p>
              <p class="mb-3 h-5 w-11/12 animate-pulse rounded bg-neutral-50"></p>
              <p class="mb-3 h-5 w-6/12 animate-pulse rounded bg-neutral-50"></p>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <div
        *ngFor="let product of suggestions"
        [ngClass]="{
          'outline outline-[8px]': product.quantity,
        }"
        [style.outlineColor]="buttonBackgroundColor"
      >
        <app-kiosk-product-card-atom
          *ngIf="product"
          [quantity]="product.quantity"
          [product]="product"
          [textColor]="textColor"
          [cardColor]="cardColor"
          [buttonBackgroundColor]="buttonBackgroundColor"
          [buttonTextColor]="buttonTextColor"
          [maxProduct]="product.max_count ?? 99"
          [selectionnable]="true"
          [displayedQuantity]="product.quantity ?? 0"
          (changeProductQuantity)="changeQuantity(product, $event)"
          (showProduct)="openProductModal(product)"
          (click)="onProductCardClick(product)"
        ></app-kiosk-product-card-atom>
      </div>
    </div>
    <div class="m-10 grid w-[90%] grid-cols-1">
      <app-button-atom
        size="XXL"
        thickSize="XXL"
        [label]="buttonLabel | translate"
        [overrideBgColor]="buttonBackgroundColor"
        [overrideTextColor]="buttonTextColor"
        type="kiosk-primary"
        (click)="valid()"
        [full]="true"
      ></app-button-atom>
    </div>
  </div>
</div>
