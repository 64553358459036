import { takeUntil, tap } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { ComoService } from '@core/services/como/como.service';
import { CartService } from '@core/services/cart/cart.service';
import { ModalsService } from '@core/services/modals/modals.service';

import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { ContentStoreDispatcher } from '@common/dispatchers/content.dispatcher';
import { ProductAddModalComponent } from '@shared/modals/product-add-modal/product-add-modal.component';

import { DoodProductModel } from '@core/models/product.model';
import { ComoProductsSelectionStep } from '@shared/modals/como/como-products-selection-modal/como-products-selection-step-model';

@Component({
  selector: 'app-como-products-selection-step',
  templateUrl: './como-products-selection-step.component.html',
})
export class ComoProductsSelectionStepComponent extends DestroyerBase implements OnChanges {
  @Input() step!: ComoProductsSelectionStep;
  @Output() goToNextStep = new EventEmitter<void>();

  isProductsLoading = true;
  products: DoodProductModel[] = [];

  constructor(
    private readonly comoService: ComoService,
    private readonly cartService: CartService,
    private readonly modalsService: ModalsService,
    private contentDispatcher: ContentStoreDispatcher,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['step']) {
      this.products = [];
      this.isProductsLoading = true;
      const shopId = this.cartService.getShopId();
      this.comoService
        .allowedProducts$(this.step.ids, shopId)
        .pipe(
          takeUntil(this._destroyerRef),
          tap(products => this.displayProducts(products)),
        )
        .subscribe();
    }
  }

  private displayProducts(products: DoodProductModel[]): void {
    this.products = products;
    this.isProductsLoading = false;
  }

  onNavigateTo(product: DoodProductModel): void {
    this.contentDispatcher.updateProduct({
      shopIsNotActive: true,
      productId: product.id,
      shopId: product.shop_id,
    });
    ProductAddModalComponent.allowAddToCart = true;
    ProductAddModalComponent.onAdd = (): void => {
      this.goToNextStep.emit();
      ProductAddModalComponent.onAdd = (): void => {};
    };
    this.modalsService.open(ProductAddModalComponent.handle);
  }
}
