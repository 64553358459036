import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Component, OnDestroy } from '@angular/core';

import { ShopsService } from '@core/services/shops/shops.service';
import { ModalsService } from '@core/services/modals/modals.service';

import { CartStoreSelector } from '@common/selectors/cart.selector';
import { ShopStoreSelector } from '@common/selectors/shop.selector';
import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';

@Component({
  selector: 'app-shop-capabilities-modal',
  templateUrl: './shop-capabilities-modal.component.html',
})
export class ShopCapabilitiesModalComponent implements OnDestroy {
  private readonly unsubscribe$ = new Subject<boolean>();

  static handle = 'shop-capabilities-modal';

  // TODO: This is present in multiple components, create specific helper for it
  index$ = this.modalSelector
    .selectModal(ShopCapabilitiesModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  distributionMode$ = this.cartSelector.selectActive.pipe(map(cart => cart?.type));

  shopOpensAt!: string;

  message!: string;

  constructor(
    private cartSelector: CartStoreSelector,
    private shopSelector: ShopStoreSelector,
    private modalSelector: ModalStoreSelector,
    private readonly shopsService: ShopsService,
    private readonly modalsService: ModalsService,
    readonly settingsSelector: SettingsStoreSelector,
  ) {
    this.shopSelector.selectActive
      .pipe(
        takeUntil(this.unsubscribe$),
        map(shop => {
          this.shopOpensAt = this.shopsService.getClosedMessage(shop ?? undefined);
        }),
      )
      .subscribe();
  }

  closeAdvertising(): void {
    this.modalsService.close(ShopCapabilitiesModalComponent.handle);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
