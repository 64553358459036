import { Dictionary } from '@ngrx/entity';

import { DoodCartModel } from '@core/models/cart.model';
import { DateUtils } from '@shared/utils/date/date.utils';
import { createHydratedReducer } from '@store/_abstract/hydrated';

import {
  CART_STORE_API_PIECE,
  CART_STORE_LOCAL_PIECE,
  CART_STORE_HYDRATION_PIECE,
  CART_STORE_COMPONENT_PIECE,
  CART_STORE_ACTIVE_PIECE,
} from './cart.piece';
import { CART_STORE_INITIAL_STATE, CART_STORE_KEY, CartState } from './cart.state';

export const CART_STORE_REDUCER = createHydratedReducer<CartState>({
  key: CART_STORE_KEY,
  initialState: CART_STORE_INITIAL_STATE,
  beforeLoad: (_state: CartState) => {
    const _entities = { ..._state.entities } as Record<string, DoodCartModel | undefined>;
    _state.ids.forEach(_id => {
      const _entity = _entities[_id];
      _entities[_id] = _entity
        ? {
            ..._entity,
            wanted_at: _entity.wanted_at
              ? new Date(DateUtils.dayjsInMarketplaceTimezone(_entity.wanted_at).format())
              : null,
          }
        : undefined;
    });
    return {
      ..._state,
      entities: _entities as unknown as Dictionary<DoodCartModel>,
    };
  },
  ons: [
    ...CART_STORE_HYDRATION_PIECE,
    ...CART_STORE_ACTIVE_PIECE,
    ...CART_STORE_API_PIECE,
    ...CART_STORE_LOCAL_PIECE,
    ...CART_STORE_COMPONENT_PIECE,
  ],
});
