import { Action, StoreModule, combineReducers } from '@ngrx/store';

import { CartEffects } from '@common/effects/cart.effect';
import { OrderEffects } from '@common/effects/order.effect';
import { ContentEffects } from '@common/effects/content.effect';
import { DeliveryEffects } from '@common/effects/delivery.effect';
import { AuthenticationEffects } from '@common/effects/authentication.effect';

import {
  CART_FUNNEL_STORE_KEY,
  CART_FUNNEL_STORE_REDUCER,
  CartFunnelState,
} from '@store/cart-funnel';
import {
  TRANSACTION_STORE_KEY,
  TRANSACTION_STORE_REDUCER,
  TransactionState,
} from '@store/transaction';
import {
  MARKETPLACE_STORE_KEY,
  MARKETPLACE_STORE_REDUCER,
  MarketplaceState,
} from '@store/marketplace';
import { CART_STORE_KEY, CART_STORE_REDUCER, CartState } from '@store/cart';
import { SHOP_STORE_KEY, SHOP_STORE_REDUCER, ShopState } from '@store/shop';
import { ORDER_STORE_KEY, ORDER_STORE_REDUCER, OrderState } from '@store/order';
import { MODAL_STORE_KEY, MODAL_STORE_REDUCER, ModalState } from '@store/modal';
import { BASKET_STORE_KEY, BASKET_STORE_REDUCER, BasketState } from '@store/basket';
import { ANCHOR_STORE_KEY, ANCHOR_STORE_REDUCER, AnchorState } from '@store/anchor';
import { FILTER_STORE_KEY, FILTER_STORE_REDUCER, FilterState } from '@store/filter';
import { PAYMENT_STORE_KEY, PAYMENT_STORE_REDUCER, PaymentState } from '@store/payment';
import { DoodEntityState, ENTITY_STORE_KEY, ENTITY_STORE_REDUCER } from '@store/entity';
import { CONTENT_STORE_KEY, CONTENT_STORE_REDUCER, ContentState } from '@store/content';
import { PRODUCT_STORE_KEY, PRODUCT_STORE_REDUCER, ProductState } from '@store/product';
import { SETTINGS_STORE_KEY, SETTINGS_STORE_REDUCER, SettingsState } from '@store/settings';
import { DELIVERY_STORE_KEY, DELIVERY_STORE_REDUCER, DeliveryState } from '@store/delivery';
import { AUTH_STORE_KEY, AUTH_STORE_REDUCER, AuthenticationState } from '@store/authentication';
import { OSL_STORE_KEY, OSL_STORE_REDUCER, OnSiteLocationState } from '@store/on-site-location';
import { PAT_STORE_KEY, PAT_STORE_REDUCER, PaymentAtTableState } from '@store/payment-at-table';
import { DECLINABLE_STORE_KEY, DECLINABLE_STORE_REDUCER, DeclinableState } from '@store/declinable';

export const APP_EFFECTS = [
  AuthenticationEffects,
  ContentEffects,
  CartEffects,
  OrderEffects,
  DeliveryEffects,
];

export const APP_STORE = {
  [AUTH_STORE_KEY]: AUTH_STORE_REDUCER,
  [CONTENT_STORE_KEY]: CONTENT_STORE_REDUCER,
  [MARKETPLACE_STORE_KEY]: MARKETPLACE_STORE_REDUCER,
  [SETTINGS_STORE_KEY]: SETTINGS_STORE_REDUCER,
  [CART_STORE_KEY]: CART_STORE_REDUCER,
  [BASKET_STORE_KEY]: BASKET_STORE_REDUCER,
  [OSL_STORE_KEY]: OSL_STORE_REDUCER,
  [SHOP_STORE_KEY]: SHOP_STORE_REDUCER,
  [TRANSACTION_STORE_KEY]: TRANSACTION_STORE_REDUCER,
  [DECLINABLE_STORE_KEY]: DECLINABLE_STORE_REDUCER,
  [PRODUCT_STORE_KEY]: PRODUCT_STORE_REDUCER,
  [MODAL_STORE_KEY]: MODAL_STORE_REDUCER,
  [ORDER_STORE_KEY]: ORDER_STORE_REDUCER,
  [FILTER_STORE_KEY]: FILTER_STORE_REDUCER,
  [ENTITY_STORE_KEY]: ENTITY_STORE_REDUCER,
  [ANCHOR_STORE_KEY]: ANCHOR_STORE_REDUCER,
  [DELIVERY_STORE_KEY]: DELIVERY_STORE_REDUCER,
  [CART_FUNNEL_STORE_KEY]: CART_FUNNEL_STORE_REDUCER,
  [PAT_STORE_KEY]: PAT_STORE_REDUCER,
  [PAYMENT_STORE_KEY]: PAYMENT_STORE_REDUCER,
};

export const APP_STORE_FEATURES = [
  StoreModule.forFeature(AUTH_STORE_KEY, AUTH_STORE_REDUCER),
  StoreModule.forFeature(CONTENT_STORE_KEY, CONTENT_STORE_REDUCER),
  StoreModule.forFeature(SETTINGS_STORE_KEY, SETTINGS_STORE_REDUCER),
  StoreModule.forFeature(MARKETPLACE_STORE_KEY, MARKETPLACE_STORE_REDUCER),
  StoreModule.forFeature(CART_STORE_KEY, CART_STORE_REDUCER),
  StoreModule.forFeature(BASKET_STORE_KEY, BASKET_STORE_REDUCER),
  StoreModule.forFeature(OSL_STORE_KEY, OSL_STORE_REDUCER),
  StoreModule.forFeature(SHOP_STORE_KEY, SHOP_STORE_REDUCER),
  StoreModule.forFeature(TRANSACTION_STORE_KEY, TRANSACTION_STORE_REDUCER),
  StoreModule.forFeature(DECLINABLE_STORE_KEY, DECLINABLE_STORE_REDUCER),
  StoreModule.forFeature(PRODUCT_STORE_KEY, PRODUCT_STORE_REDUCER),
  StoreModule.forFeature(MODAL_STORE_KEY, MODAL_STORE_REDUCER),
  StoreModule.forFeature(ORDER_STORE_KEY, ORDER_STORE_REDUCER),
  StoreModule.forFeature(FILTER_STORE_KEY, FILTER_STORE_REDUCER),
  StoreModule.forFeature(ENTITY_STORE_KEY, ENTITY_STORE_REDUCER),
  StoreModule.forFeature(ANCHOR_STORE_KEY, ANCHOR_STORE_REDUCER),
  StoreModule.forFeature(DELIVERY_STORE_KEY, DELIVERY_STORE_REDUCER),
  StoreModule.forFeature(CART_FUNNEL_STORE_KEY, CART_FUNNEL_STORE_REDUCER),
  StoreModule.forFeature(PAT_STORE_KEY, PAT_STORE_REDUCER),
  StoreModule.forFeature(PAYMENT_STORE_KEY, PAYMENT_STORE_REDUCER),
];

interface AppState {
  [AUTH_STORE_KEY]: AuthenticationState;
  [CONTENT_STORE_KEY]: ContentState;
  [SETTINGS_STORE_KEY]: SettingsState;
  [MARKETPLACE_STORE_KEY]: MarketplaceState;
  [CART_STORE_KEY]: CartState;
  [BASKET_STORE_KEY]: BasketState;
  [OSL_STORE_KEY]: OnSiteLocationState;
  [SHOP_STORE_KEY]: ShopState;
  [TRANSACTION_STORE_KEY]: TransactionState;
  [DECLINABLE_STORE_KEY]: DeclinableState;
  [PRODUCT_STORE_KEY]: ProductState;
  [MODAL_STORE_KEY]: ModalState;
  [ORDER_STORE_KEY]: OrderState;
  [FILTER_STORE_KEY]: FilterState;
  [ENTITY_STORE_KEY]: DoodEntityState;
  [ANCHOR_STORE_KEY]: AnchorState;
  [DELIVERY_STORE_KEY]: DeliveryState;
  [CART_FUNNEL_STORE_KEY]: CartFunnelState;
  [PAT_STORE_KEY]: PaymentAtTableState;
  [PAYMENT_STORE_KEY]: PaymentState;
}

export const APP_REDUCER = (state: AppState, action: Action): AppState => {
  return combineReducers({
    [AUTH_STORE_KEY]: AUTH_STORE_REDUCER,
    [CONTENT_STORE_KEY]: CONTENT_STORE_REDUCER,
    [SETTINGS_STORE_KEY]: SETTINGS_STORE_REDUCER,
    [MARKETPLACE_STORE_KEY]: MARKETPLACE_STORE_REDUCER,
    [CART_STORE_KEY]: CART_STORE_REDUCER,
    [BASKET_STORE_KEY]: BASKET_STORE_REDUCER,
    [OSL_STORE_KEY]: OSL_STORE_REDUCER,
    [SHOP_STORE_KEY]: SHOP_STORE_REDUCER,
    [TRANSACTION_STORE_KEY]: TRANSACTION_STORE_REDUCER,
    [DECLINABLE_STORE_KEY]: DECLINABLE_STORE_REDUCER,
    [PRODUCT_STORE_KEY]: PRODUCT_STORE_REDUCER,
    [MODAL_STORE_KEY]: MODAL_STORE_REDUCER,
    [ORDER_STORE_KEY]: ORDER_STORE_REDUCER,
    [FILTER_STORE_KEY]: FILTER_STORE_REDUCER,
    [ENTITY_STORE_KEY]: ENTITY_STORE_REDUCER,
    [ANCHOR_STORE_KEY]: ANCHOR_STORE_REDUCER,
    [DELIVERY_STORE_KEY]: DELIVERY_STORE_REDUCER,
    [CART_FUNNEL_STORE_KEY]: CART_FUNNEL_STORE_REDUCER,
    [PAT_STORE_KEY]: PAT_STORE_REDUCER,
    [PAYMENT_STORE_KEY]: PAYMENT_STORE_REDUCER,
  })(state, action);
};
