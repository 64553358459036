import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {ComoService} from '@core/services/como/como.service';
import {UserService} from '@core/services/user/user.service';
import {tap} from 'rxjs/operators';
import {MarketplaceStoreSelector} from '@common/selectors/marketplace.selector';
import {Observable} from 'rxjs';
import {AuthStoreSelector} from '@common/selectors/authentication.selector';

@Component({
  selector: 'app-como-create-or-link-account-modal-registration-step',
  templateUrl: './como-create-or-link-account-modal-registration-step.component.html',
  styleUrls: ['./como-create-or-link-account-modal-registration-step.component.scss'],
})
export class ComoCreateOrLinkAccountModalRegistrationStepComponent implements AfterViewInit {
  @Input() comoPlugin: any;
  @Input() phoneOrEmail = '';

  @Output() goToStep = new EventEmitter<string>();
  @Output() close = new EventEmitter<void>();

  form: UntypedFormGroup = this.fb.group({
    legals: [false, Validators.requiredTrue],
    optin: [false],
    dateOfBirth: [
      '',
      [
        Validators.pattern(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/),
        this.dateOfBirthValidator()
      ]
    ],
  });
  errorMessage?: string;
  isValidating = false;
  optinAcceptedOnUserCreation = false;
  inputDateOfBirthRule = 'DISABLED';
  marketplaceName$: Observable<string> = this.marketplaceSelector.selectMarketplaceName;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly comoService: ComoService,
    private readonly userService: UserService,
    private readonly marketplaceSelector: MarketplaceStoreSelector,
    private readonly authSelector: AuthStoreSelector,
  ) {
  }

  ngAfterViewInit(): void {
    this.inputDateOfBirthRule = this.comoPlugin['input_date_of_birth'];
    this.optinAcceptedOnUserCreation = this.authSelector.user?.optin === true;
  }

  dateOfBirthValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      const errors: ValidationErrors = {};

      if (this.inputDateOfBirthRule === 'REQUIRED' && (!value || value.trim() === '')) {
        errors['required'] = true;
      }

      return Object.keys(errors).length > 0 ? errors : null;
    };
  }


  onSubmit() {
    if (this.form.invalid || this.isValidating) {
      return;
    }
    this.isValidating = true;
    this.errorMessage = undefined;

    this.comoService
      .createComoUser$(
        this.phoneOrEmail,
        this.form.value['legals'],
        this.form.value['dateOfBirth'],
        this.optinAcceptedOnUserCreation || this.form.value['optin'],
      )
      .pipe(
        tap(user => this.userService.setUser(user)),
        tap(() => this.goToStep.next('confirmation')),
      )
      .subscribe({
        error: error => this.handleErrorResponse(error.error),
        complete: () => (this.isValidating = false),
      });
  }

  private handleErrorResponse(error: any): void {
    this.isValidating = false;
    switch (error.code) {
      case 103:
        this.errorMessage = 'como.create-account.errors.account-already-exists-phone';
        break;
      case 0:
        // Generic error
        this.errorMessage = 'como.errors.generic-error';
        break;
      case 101:
        // Como is already attached to user
        this.errorMessage = 'como.create-account.errors.user-has-loyalty-attached';
        break;
      case 102:
        // Phone number is missing
        break;
      case 1:
        // Not logged in
        break;
      case 3:
        // Como not enabled
        this.errorMessage = 'como.errors.loyalty-not-enabled';
        break;
      default:
        this.errorMessage = 'como.errors.generic-error';
    }
  }

  onContinueWithoutLoyalty(): void {
    this.close.next();
  }
}
