import { HydrationState, createHydratedAdapter } from '@store/_abstract/hydrated';

import { DoodApiError } from '@shared/interfaces/error.interface';
import { DoodAuthenticationStep, DoodUserModel } from '@store/authentication/authentication.model';

export interface AuthenticationState extends HydrationState {
  isUserLoaded: boolean;
  isUserLoading: boolean;
  user: DoodUserModel | null;
  error: DoodApiError | null;
  guard: AuthenticationGuardState;
  status: AuthenticationStatusState;
  permissions: AuthenticationPermissionsState;
}

export interface AuthenticationStatusState {
  redirect: boolean;
  token: string | null;
  step: DoodAuthenticationStep | null;
}

export interface AuthenticationGuardState {
  requestedUrl: string;
}

export interface AuthenticationPermissionsState {
  geolocation: boolean;
}

export const AUTH_STORE_KEY = 'Authentication';

export const AUTH_STORE_HYDRATION_ADAPTER = createHydratedAdapter();

export const AUTH_STORE_INITIAL_STATUS_STATE: AuthenticationStatusState = {
  token: null,
  step: null,
  redirect: false,
};

export const AUTH_STORE_INITIAL_GUARD_STATE: AuthenticationGuardState = {
  requestedUrl: '',
};

export const AUTH_STORE_INITIAL_PERMISSIONS_STATE: AuthenticationPermissionsState = {
  geolocation: false,
};

export const AUTH_STORE_INITIAL_STATE: AuthenticationState =
  AUTH_STORE_HYDRATION_ADAPTER.getInitialState({
    user: null,
    error: null,
    isUserLoaded: false,
    isUserLoading: false,
    guard: AUTH_STORE_INITIAL_GUARD_STATE,
    status: AUTH_STORE_INITIAL_STATUS_STATE,
    permissions: AUTH_STORE_INITIAL_PERMISSIONS_STATE,
  });
