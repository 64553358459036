import { mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  AUTH_STORE_API_ACTIONS,
  AUTH_STORE_LOCAL_ACTIONS,
} from '@store/authentication/authentication.action';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationEffects {
  constructor(private _actions: Actions) {}

  fetchUser = createEffect(() => {
    return this._actions.pipe(
      ofType(AUTH_STORE_API_ACTIONS.fetchUser),
      mergeMap(() => {
        return [AUTH_STORE_LOCAL_ACTIONS.updateIsUserLoading({ isUserLoading: true })];
      }),
    );
  });

  fetchUserSuccess = createEffect(() => {
    return this._actions.pipe(
      ofType(AUTH_STORE_API_ACTIONS.fetchUserSuccess),
      mergeMap(({ user }) => {
        return [
          AUTH_STORE_LOCAL_ACTIONS.updateUser({ user }),
          AUTH_STORE_LOCAL_ACTIONS.updateIsUserLoaded({ isUserLoaded: true }),
          AUTH_STORE_API_ACTIONS.fetchUserComplete(),
        ];
      }),
    );
  });

  fetchUserFailure = createEffect(() => {
    return this._actions.pipe(
      ofType(AUTH_STORE_API_ACTIONS.fetchUserFailure),
      mergeMap(({ error }) => {
        return [
          AUTH_STORE_LOCAL_ACTIONS.updateError({ error }),
          AUTH_STORE_LOCAL_ACTIONS.updateIsUserLoaded({ isUserLoaded: false }),
          AUTH_STORE_API_ACTIONS.fetchUserComplete(),
        ];
      }),
    );
  });

  fetchUserComplete = createEffect(() => {
    return this._actions.pipe(
      ofType(AUTH_STORE_API_ACTIONS.fetchUserComplete),
      mergeMap(() => {
        return [AUTH_STORE_LOCAL_ACTIONS.updateIsUserLoading({ isUserLoading: false })];
      }),
    );
  });
}
