<div *ngIf="orders$ | async as orders">
  <ng-container *ngIf="orders.length; else loading">
    <div class="md:flex md:flex-col md:items-center">
      <div class="px-5 pb-3 md:w-8/12">
        <div class="flex flex-col">
          <div
            class="flex flex-col items-start gap-4 lg:flex-row"
            *ngFor="let order of orders"
          >
            <div class="w-full rounded-lg border border-neutral-300">
              <app-order-status-atom-v2
                [order]="order"
                [shopName]="order.shop.name"
                [orderNumber]="order.number"
                [orderStatus]="order.status"
                [displayLocalizeButton]="displayLocalizeButton"
                [displayDetailsButton]="displayDetailsButton"
                [locationName]="(onSiteLocationName$ | async) ?? ''"
                [textColor]="textColor"
                [secondaryTextColor]="secondaryTextColor"
              ></app-order-status-atom-v2>
            </div>
            <div
              *ngIf="displayShopContact && (order?.shop?.phone || order?.shop?.email)"
              class="w-full rounded-lg border border-neutral-300 lg:ml-3 lg:w-64 lg:flex-shrink-0"
            >
              <!-- TODO: Add proper [emailAddress] value from API response -->
              <app-actions-info-shop-atom
                [isFavourite]="false"
                [shopName]="order?.shop?.name"
                [phoneNumber]="order?.shop?.phone"
                [instagramURL]="order?.shop?.link_instagram"
                [facebookURL]="order?.shop?.link_facebook"
                [emailAddress]="''"
                [twitterURL]="order?.shop?.link_twitter"
                [textColor]="textColor"
              ></app-actions-info-shop-atom>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #loading>
  <div class="bg-neutral-100 px-5 pt-5">
    <ng-container *ngFor="let i of [].constructor(2)">
      <div class="flex flex-col items-center pb-5">
        <div
          class="flex h-32 w-full cursor-pointer justify-between rounded rounded-lg bg-white px-5 py-3 md:w-8/12"
        >
          <div class="w-full">
            <p class="mb-2 h-6 w-32 animate-pulse rounded bg-neutral-200"></p>
            <p class="mb-2 h-4 w-44 animate-pulse rounded bg-neutral-50"></p>
            <p class="mb-2 h-4 w-56 animate-pulse rounded bg-neutral-50"></p>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
