import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Type } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DoodBlockModel } from '@core/models/block.model';
import { CamelCaseUtils } from '@app/utilities/camel-case.util';
import { DoodRenderingBlock } from '@shared/interfaces/block.interface';
import { ContentStoreSelector } from '@common/selectors/content.selector';
import { ALLOWED_BLOCKS } from '@config/blocks.config';

@Component({
  selector: 'app-block-factory',
  templateUrl: './block-factory.component.html',
  styleUrls: ['./block-factory.component.scss'],
})
export class BlockFactoryComponent implements OnInit, OnDestroy {
  private _destroyerRef = new Subject<boolean>();

  data: object = {};
  componentClass?: Type<unknown> | string;

  @Input() injectData: object = {};
  @Input() blockId?: string | Partial<DoodRenderingBlock>;

  @Output() isVisibleChanged = new EventEmitter<{ index: string; isVisible: boolean }>();

  constructor(private blockSelector: ContentStoreSelector) {}

  // TODO: Need to avoid modifying blockId input value
  ngOnInit(): void {
    if (!this.blockId) return;

    if (typeof this.blockId !== 'string') {
      if (this.blockId.componentClass) {
        this.data = this.blockId.data ?? {};
        this.componentClass = this.blockId.componentClass;
      }

      if (this.blockId.block) {
        this.blockId = this.blockId.block;
      }
    }

    this.blockSelector
      .selectBlock(this.blockId.toString())
      .pipe(takeUntil(this._destroyerRef))
      .subscribe((_block?: DoodBlockModel) => {
        if (_block) {
          this._renderComponent(_block);
        }
      });
  }

  ngOnDestroy(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
  }

  private _renderComponent(_descriptor: DoodBlockModel): void {
    if (!_descriptor) {
      console.error('[BlockFactory] Error: Block ' + this.blockId + ' is not found');
      return;
    }

    let _type = _descriptor.type;

    if (!_type.endsWith('Component')) {
      _type = _type + 'Component';
    }

    if (!ALLOWED_BLOCKS.hasOwnProperty(_type)) {
      console.debug('[BlockFactory] blockDescriptor: ', _descriptor);
      console.error('[BlockFactory] Error: Component ' + _type + ' is not registered');
      return;
    }

    this.componentClass = Object(ALLOWED_BLOCKS)[_type];
    this.data = {
      ...CamelCaseUtils.convertAttributeNamesToCamelCase(_descriptor.data),
      blockId: _descriptor.id,
    };

    this.data = { ...this.data, ...this.injectData };
  }
  forceType(data: any): any {
    return data as any;
  }
}
