import { effect, inject, Injectable, untracked } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { map, scan, share } from 'rxjs';
import { SimpleProductStepperStore } from '../simple-product-stepper.store';
import { IAdditionGroup } from '@core/models/shop.model';

@Injectable()
export class SimpleProductAutoNextStepService {
  private readonly simpleProductStepperStore = inject(SimpleProductStepperStore);

  constructor() {
    effect(
      () => {
        // go next step, if the step is valid and the user can not make more choices
        const visitedStep = this.visitedStep();
        if (!visitedStep) {
          return false;
        }
        const additionGroupStepBeforeBack = untracked(() => this.additionGroupStepBeforeBack());
        if (!additionGroupStepBeforeBack) {
          return false;
        }
        const blockAutoNextStep = visitedStep.position < additionGroupStepBeforeBack.position;
        if (blockAutoNextStep) {
          return;
        }

        const currentStepper = untracked(() => this.simpleProductStepperStore.stepper()?.step);
        if (!currentStepper) {
          return;
        }
        if (!currentStepper.isValid || currentStepper.canMakeMoreChoices) {
          return;
        }

        if (currentStepper.hasNextStep) {
          const nextStep = currentStepper.nextStep;
          if (nextStep) {
            currentStepper.setSelectedStep(nextStep);
          }
          return;
        }
      },
      {
        allowSignalWrites: true,
      },
    );
  }

  public initAutoNextStep() {}

  private readonly visitedStep$ = toObservable(
    this.simpleProductStepperStore.simpleProductStepper,
  ).pipe(
    map(stepper => {
      const selectedAdditionsGroupId = stepper?.selectedStepId;
      const additionGroups = stepper?.additionsGroups;
      if (!selectedAdditionsGroupId || !additionGroups) {
        return undefined;
      }
      return this.calculateAdditionGroupIndex({ additionGroups, selectedAdditionsGroupId });
    }),
    share(),
  );
  private readonly visitedStep = toSignal(this.visitedStep$);

  private readonly additionGroupStepBeforeBack = toSignal(
    this.visitedStep$.pipe(
      scan((acc, value) => {
        if (!value) {
          return acc;
        }
        if (!acc) {
          return value;
        }
        if (value.position > acc.position) {
          return value;
        }
        return acc;
      }),
    ),
  );

  private calculateAdditionGroupIndex({
    additionGroups,
    selectedAdditionsGroupId,
  }: {
    additionGroups: IAdditionGroup[];
    selectedAdditionsGroupId: string;
  }) {
    const index = additionGroups.findIndex(
      additionGroup => additionGroup.id === selectedAdditionsGroupId,
    );
    return {
      position: index,
    };
  }
}
