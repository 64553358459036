<app-modal-filler-atom
  (click)="close()"
  [index]="(index$ | async) ?? 0"
></app-modal-filler-atom>
<div
  class="fixed max-h-85-screen max-w-screen-sm mx-auto right-0 left-0 bottom-0 shadow overflow-y-auto"
  [style.z-index]="((index$ | async) ?? 0) + 50"
>
  <div class="p-5 md:p-7 bg-white rounded-t-3xl">
    <div class="flex justify-between items-center mb-6">
      <h2 class="font-accent font-bold text-3xs">Votre crédit</h2>
      <app-rounded-icon-button
        icon="icon-cross"
        (onClick)="close()"
      ></app-rounded-icon-button>
    </div>

    <ng-container *ngIf="isDataLoaded; else loading">
      <label
        for="amountToUse"
        class="block text-base leading-5 font-base font-normal text-neutral-600"
      >
        Montant à utiliser pour cette commande
      </label>
      <label
        for="amountToUse"
        class="block mb-2 text-xs font-base font-normal text-neutral-500"
      >
        Maximum :
        <app-price-atom
          [size]="'SM'"
          [amount]="maxAmountToUse"
          [bold]="false"
          [format]="true"
        ></app-price-atom>
      </label>
      <div class="text-neutral-800 leading-5 pb-6">
        <input
          [(ngModel)]="amountToUse"
          id="amountToUse"
          type="number"
          min="0.00"
          step="0.01"
          class="w-full border border-neutral-800 rounded py-4.5 px-5"
        />
        <div
          class="font-base text-error-600 pt-2"
          *ngIf="amountToUse > maxAmountToUse / 100"
        >
          Le montant ne doit pas dépasser
          <app-price-atom
            [size]="'SM'"
            [amount]="maxAmountToUse"
            [bold]="false"
            [format]="true"
          ></app-price-atom>
        </div>
      </div>

      <ng-container *ngIf="verificationCodeRequired">
        <label
          for="amountToUse"
          class="block text-base leading-5 font-base font-normal text-neutral-600 mb-2"
        >
          Code de vérification
        </label>
        <div class="text-neutral-800 leading-5 pb-6">
          <input
            [(ngModel)]="verificationCode"
            id="verificationCode"
            class="w-full border border-neutral-800 rounded py-4.5 px-5"
          />
        </div>
      </ng-container>
    </ng-container>

    <div class="pt-1 flex flex-nowrap">
      <div class="pr-1 w-1/2">
        <app-button-atom
          size="M"
          label="Annuler"
          color="primary"
          (click)="close()"
          type="light"
          [full]="true"
        ></app-button-atom>
      </div>
      <div class="pt-0 pl-1 w-1/2">
        <app-button-atom
          size="M"
          label="Valider"
          (click)="apply()"
          type="primary"
          [full]="true"
          [iconUrl]="isValidating ? 'assets/images/spinner.svg' : ''"
          [disabled]="!isDataLoaded || isValidating || amountToUse > maxAmountToUse / 100"
        ></app-button-atom>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <ng-container *ngFor="let i of [].constructor(2)">
    <div class="animate-pulse h-4 bg-neutral-100 rounded w-32 mb-3"></div>
    <div class="animate-pulse h-10 bg-neutral-50 rounded w-full mb-6"></div>
  </ng-container>
</ng-template>
