import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { DoodApiError } from '@shared/interfaces/error.interface';
import { createActiveStoreActions } from '@store/_abstract/active';
import { DoodTransactionModel } from '@core/models/transaction.model';
import { createHydratedStoreActions } from '@store/_abstract/hydrated';

import { TRANSACTION_STORE_KEY } from './transaction.state';

export const TRANSACTION_STORE_API_ACTIONS = createActionGroup({
  source: 'Dood/API/Transaction',
  events: {
    'Fetch Transactions': emptyProps(),
    'Fetch Transactions Success': props<{ transactions: DoodTransactionModel[] }>(),
    'Fetch Transactions Failure': props<{ error: DoodApiError }>(),
    'Fetch Transactions Complete': emptyProps(),
  },
});

export const TRANSACTION_STORE_LOCAL_ACTIONS = createActionGroup({
  source: 'Dood/LOCAL/Transaction',
  events: {
    // Amount
    'Update Amount': props<{ amount: number | null }>(),
    'Reset Amount': emptyProps(),

    // Payment Mode
    'Update Payment Mode': props<{ mode: string | null }>(),
    'Reset Payment Mode': emptyProps(),

    // Errors
    'Update Error': props<{ error: string | null }>(),
    'Reset Error': emptyProps(),

    // Status
    'Update Is Loaded': props<{ isLoaded: boolean }>(),
    'Update Is Loading': props<{ isLoading: boolean }>(),

    // Misc
    'reset': emptyProps(),
  },
});

export const TRANSACTION_STORE_ACTIVE_ACTIONS =
  createActiveStoreActions<DoodTransactionModel>(TRANSACTION_STORE_KEY);

export const TRANSACTION_STORE_HYDRATION_ACTIONS =
  createHydratedStoreActions(TRANSACTION_STORE_KEY);
