import { MarketplaceKeys } from '@config/keys/shop.keys';
import { IAddress } from './address.model';
import { IDistributionMode } from './shop.model';
import { ColorsGroup } from './marketplace-colors.model';

export interface IFirebaseTenant {
  firebase_tenant_id?: string;
  id: string;
  name: string;
}

export interface IUIConfigFont {
  family: string;
  url: string;
  local?: boolean;
}

export interface IUIConfigFontList {
  brand: IUIConfigFont;
  accent: IUIConfigFont;
  base: IUIConfigFont;
}

export interface IUIConfig {
  colors: { key: string; value: string };
  fonts?: IUIConfigFontList;
  custom_css: string[];
}

export interface IMarketplaceUserInterface {
  colors: { key: string; value: string };
  fonts?: IUIConfigFontList;
  custom_css: string[];
  translations?: any;
}

export interface IPageConfig {
  id?: string;
  path: string;
  block?: any;
  guards?: Array<string>;
  data?: any;
  children?: Array<IPageConfig>;
  parent?: string;
  redirect_to?: string;
}

export interface IBlockConfig {
  id: string;
  type: string;
  data?: any;
}

export interface DoodModalConfig {
  handle: string;
  block: string;
}

export interface ILayout {
  powered_by: any;
}

// TODO: Move to a marketplace enum file
export enum DoodMarketplaceCartScope {
  marketplace = 'MARKETPLACE',
}

export interface DoodOrganizationModel {
  id?: string;
}

export interface DoodMarketplaceModel {
  [MarketplaceKeys.Id]: string;
  [MarketplaceKeys.Name]: string;
  [MarketplaceKeys.Available]: boolean;
  [MarketplaceKeys.CartScope]: string;
  [MarketplaceKeys.DistributionModes]: IDistributionMode[];
  [MarketplaceKeys.Address]?: IAddress;
  [MarketplaceKeys.Plugins]?: Array<any>;
  [MarketplaceKeys.Currency]: string;
  user_interface?: IMarketplaceUserInterface;
  white_label?: { ui: { colors: { key: string; value: string } } };
  // ui?: IUIConfig;
  layout?: ILayout;
  pages?: Array<IPageConfig>;
  blocks?: Array<IBlockConfig>;
  modals?: Array<DoodModalConfig>;
  [MarketplaceKeys.Locales]: DoodMarketplaceLocale[];
  [MarketplaceKeys.DefaultLocale]?: DoodMarketplaceLocale;
  [MarketplaceKeys.Timezone]?: string;
  [MarketplaceKeys.Country]: string;
  [MarketplaceKeys.Domains]: string[];
  [MarketplaceKeys.AuthMode]?: string;
  [MarketplaceKeys.TipsAllowed]: boolean;
  [MarketplaceKeys.Capabilities]: { type: string }[];
  [MarketplaceKeys.FirebaseTenant]?: IFirebaseTenant;
  [MarketplaceKeys.Colors]?: ColorsGroup;
  [MarketplaceKeys.Organization]?: DoodOrganizationModel;
  [MarketplaceKeys.DeliveryProvider]?: string;
}

export interface IPushShop {
  logo?: string;
  name: string;
  shop: string;
  type: string;
  slug?: string;
  eat_in: boolean;
}

export interface DoodMarketplaceLocale {
  id: string;
  locale: string;
  name: string;
}
