import { Router } from '@angular/router';
import { Component } from '@angular/core';

import { Paths } from '@config/paths.config';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';

@Component({
  selector: 'app-navigate-to-map-button-modal',
  templateUrl: './navigate-to-map-button-modal.component.html',
})
export class NavigateToMapButtonModalComponent {
  static handle = 'navigate-to-map-button-modal';

  constructor(
    private readonly router: Router,
    private readonly routerHelper: RouterHelperService,
  ) {}

  navigateToMapPage(): void {
    this.router.navigate([this.routerHelper.translateRoute(`${Paths.ShopsMap}`)]);
  }
}
