export enum LineItemKeys {
  LineId = 'line_id',
  Type = 'type',
  Subtype = 'subtype',
  Level = 'level',
  Title = 'title',
  Quantity = 'quantity',
  UnitPrice = 'unit_price',
  PriceGross = 'price_gross',
  Price = 'price',
  PriceIncluded = 'price_included',
  Currency = 'currency',
  TaxRate = 'tax_rate',
  TaxPrice = 'tax_price',
  Description = 'description',
  ProductId = 'product_id',
  DeliveryPriceAmount = 'delivery_price_amount',
}
