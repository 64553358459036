import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { Paths } from '@config/paths.config';
import { DoodOrderModel } from '@store/order/order.model';
import { PaymentMethodHandles } from '@config/payment-methods.config';

import { CartService } from '@core/services/cart/cart.service';
import { BasketService } from '@core/services/basket/basket.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { PaymentService } from '@core/services/payment/payment.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { PaymentStoreDispatcher } from '@common/dispatchers/payment.dispatcher';

@Component({
  template: ``,
  selector: 'app-payment-panel-no-payment',
})
export class PaymentPanelNoPaymentComponent implements OnInit, OnDestroy {
  protected readonly unsubscribe$ = new Subject<boolean>();

  constructor(
    private readonly router: Router,
    private readonly cartService: CartService,
    private readonly basketService: BasketService,
    private readonly ordersService: OrdersService,
    private readonly paymentService: PaymentService,
    private paymentDispatcher: PaymentStoreDispatcher,
    private readonly routerHelper: RouterHelperService,
  ) {}

  ngOnInit(): void {
    this.paymentDispatcher.updateUI({
      button: {
        isEnabled: true,
        isVisible: true,
        text: 'payment.validate-on-site-zero',
      },
      status: {
        isInProgress: false,
      },
    });

    this.paymentService.pay$
      .pipe(takeUntil(this.unsubscribe$), take(1))
      .subscribe(() => this.handlePayment());
  }

  ngOnDestroy(): void {
    this.paymentDispatcher.resetUI();
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();

    window.onbeforeunload = (): void => {};
  }

  private handlePayment(): void {
    this.paymentDispatcher.updateUI({
      button: {
        isEnabled: false,
      },
      status: {
        isInProgress: true,
      },
    });

    this.createOnsiteOrder().pipe(take(1), takeUntil(this.unsubscribe$)).subscribe();
  }

  private createOnsiteOrder(): Observable<DoodOrderModel | null> {
    return this.ordersService.createOrder$(PaymentMethodHandles.NoPayment).pipe(
      take(1),
      tap(order => {
        if (order) {
          this.router.navigate([
            this.routerHelper.translateRoute(`${Paths.Orders}/${order.id}/${Paths.Status}`),
          ]);
        }
        this.cartService.clearCart();
        this.basketService.clearBasket();
      }),
    );
  }
}
