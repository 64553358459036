import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-informations-column-block',
  templateUrl: './informations-column-block.component.html',
})
export class InformationsColumnBlockComponent {
  @Input() icon!: string;
  @Input() title!: string;
  @Input() columns: unknown[] = [];
}
