<app-payment-panel-adyen-generic
  type="applepay"
  [clientKey]="clientKey"
  [googlePayMerchantName]="googlePayMerchantName"
  [googlePayMerchantId]="googlePayMerchantId"
  [googlePayGatewayMerchantId]="googlePayGatewayMerchantId"
  [paymentMethodCbAvailable]="paymentMethodCbAvailable"
  [paymentMethodPaypalAvailable]="paymentMethodPaypalAvailable"
  [paymentMethodGooglePayAvailable]="paymentMethodGooglePayAvailable"
  [paymentMethodApplePayAvailable]="paymentMethodApplePayAvailable"
  paymentMethodHandle="ADYEN_APPLE_PAY"
  [isPayButtonVisible]="false"
>
  <app-payment-panel-button-skeleton loadingState></app-payment-panel-button-skeleton>
</app-payment-panel-adyen-generic>
