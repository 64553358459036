import { Injectable } from '@angular/core';

import { ShopKeys } from '@config/keys/shop.keys';
import { DoodShopModel } from '@core/models/shop.model';
import { PluginTypes } from '@config/values/plugin-types.values';

@Injectable({
  providedIn: 'root',
})
export class ResaNinjaService {
  constructor() {}

  getPlugin(shop: DoodShopModel | null): any {
    return shop?.[ShopKeys.Plugins]?.find(
      plugin => plugin.type === PluginTypes.ResaNinja && plugin.plugin_enabled,
    );
  }

  getBookUrl(shop: DoodShopModel | null): string | null {
    const plugin = this.getPlugin(shop);
    if (!plugin) {
      return null;
    }

    if (!plugin.resa_ninja_shop_id) {
      return null;
    }

    return 'https://dood.resa.ninja/booking/web/' + plugin.resa_ninja_shop_id;
  }
}
