import dayjs from 'dayjs';
import { Capacitor } from '@capacitor/core';
import { inject, Inject, Injectable, Injector } from '@angular/core';
import { combineLatest, from, Observable, of, throwError, timer, zip } from 'rxjs';
import { catchError, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';

// import {AngularFireAuth} from '@angular/fire/compat/auth';
import { SignInResult } from '@capacitor-firebase/authentication';

import { UserService } from '@core/services/user/user.service';
import { NativeService } from '@core/services/native/native.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { ResetStateService } from '@core/services/reset-state.service';
import { AnalyticsService } from '@core/services/app/analytics.service';

import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';

import { ErrorsCode } from '@config/errors.config';
import { ERRORS } from '@config/labels/errors.labels';
import { DoodUserModel } from '@store/authentication/authentication.model';
import { AuthStoreDispatcher } from '@common/dispatchers/authentication.dispatcher';
import { WaitingModalComponent } from '@shared/modals/waiting-modal/waiting-modal.component';

import {
  browserPopupRedirectResolver,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  FacebookAuthProvider,
  fetchSignInMethodsForEmail,
  getAdditionalUserInfo,
  getRedirectResult,
  GoogleAuthProvider,
  linkWithCredential,
  linkWithPopup,
  OAuthProvider,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithCredential,
  signInWithCustomToken,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  updatePassword,
  User,
  UserCredential,
} from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { Auth, authState, idToken, signInWithEmailAndPassword } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthFirebaseService {
  private auth: Auth = inject(Auth);
  idToken$ = idToken(this.auth);

  private readonly isAuthSynced$ = combineLatest({
    // Sometimes after a long period of time, the user seems to be desynchronized from the firebase authentication and the authentication store.
    // This function checks if the user is connected to firebase and the authentication store. If not, it tries to reconnect the user.
    firebaseIdToken: this.idToken$,
    user: this.authSelector.selectUser,
    storeStatus: this.authSelector.selectStatus,
  }).pipe(
    take(1),
    switchMap(({ firebaseIdToken, user, storeStatus }) => {
      const userNotConnected = !firebaseIdToken && !user;
      if (userNotConnected) {
        return of(true);
      }

      const isTokenSynced = firebaseIdToken === storeStatus.token;
      const isConnectionSynced = !!firebaseIdToken && !!user && isTokenSynced;

      if (isConnectionSynced) {
        return of(true);
      }

      if (!isTokenSynced) {
        this.authDispatcher.updateStatus({ token: firebaseIdToken });
        return of(true);
      }
      const isConnectedOnFirebase = !!firebaseIdToken;
      const isConnectedOnAuthenticationStore = !!user;
      if (!isConnectedOnFirebase && isConnectedOnAuthenticationStore) {
        return from(this.disconnectUser()).pipe(map(() => true));
      }
      if (isConnectedOnFirebase && !isConnectedOnAuthenticationStore) {
        return this.restoreUser$(firebaseIdToken).pipe(map(() => true));
      }

      return of(true);
    }),
    catchError(() => {
      return this.disconnectUser();
    }),
    catchError(() => {
      return of(true);
    }),
  );

  authState$ = this.isAuthSynced$.pipe(
    switchMap(() => authState(this.auth)),
    shareReplay(1),
  );

  private _isAnonymous = false;

  passwordReset = false;
  firebaseToken$ = this.authSelector.selectStatusToken;

  get isAnonymous(): boolean {
    return this._isAnonymous;
  }

  constructor(
    private authSelector: AuthStoreSelector,
    private readonly userService: UserService,
    private authDispatcher: AuthStoreDispatcher,
    private readonly modalsService: ModalsService,
    private readonly nativeService: NativeService,
    private settingsSelector: SettingsStoreSelector,
    private readonly analyticsService: AnalyticsService,
    @Inject(Injector) private readonly injector: Injector,
  ) {
    let previousFirebaseAuthenticationStatus = false;

    zip(this.isAuthenticate$(), this.authSelector.selectUserId)
      .pipe(
        switchMap(([firebaseAuthenticationStatus, userId]) => {
          const isFirebaseSessionTerminated =
            previousFirebaseAuthenticationStatus && !firebaseAuthenticationStatus;
          const isInvalidState = !firebaseAuthenticationStatus && !!userId;

          if (userId) {
            return this.userService.loadUser$().pipe(
              catchError(err => {
                console.log('[Auth][Error] User not found on DOOD. Logout...');
                this.disconnectUser().then(() => {
                  document.location.reload();
                });

                return throwError(() => err);
              }),
              tap(user => {
                console.log('[Auth] Logged in user', { user });
              }),
            );
          }

          if (isFirebaseSessionTerminated || isInvalidState) {
            this.modalsService.open(WaitingModalComponent.handle);
            const resetStateService = this.injector.get(ResetStateService);
            resetStateService.refreshUI();
          }
          previousFirebaseAuthenticationStatus = !!firebaseAuthenticationStatus;
          return of(previousFirebaseAuthenticationStatus);
        }),
      )
      .subscribe();

    // TODO: Later add takeUntilDestroyed
    this.authSelector.selectUserIsAnonymous.subscribe(isAnonymous => {
      this._isAnonymous = !!isAnonymous;
    });
  }

  checkIfUserExists(email: string): Promise<boolean> {
    email = email.toLowerCase();
    return fetchSignInMethodsForEmail(this.auth, email).then((res: string | string[]) => {
      return res.indexOf('password') > -1 || res.indexOf('google.com') > -1;
    });
  }

  fetchSignInMethodsForEmail(email: string): Promise<string[]> {
    email = email.toLowerCase();
    return fetchSignInMethodsForEmail(this.auth, email);
  }

  signInWithPassword(email: string, password: string): Observable<DoodUserModel | undefined> {
    email = email.toLowerCase();
    return from(signInWithEmailAndPassword(this.auth, email, password)).pipe(
      tap(credentials => this.saveFirebaseTokenInSessionStore$(credentials).subscribe()),
      switchMap(userCredential => this.saveFirebaseTokenInSessionStore$(userCredential)),
      switchMap(() => this.loadUser$()),
    );
  }

  signInWithFacebook(): Observable<UserCredential> {
    return this.signInWithPopup(new FacebookAuthProvider()).pipe(
      catchError(error => {
        if (error.code === 'auth/popup-blocked') {
          return this.signInWithRedirect(new FacebookAuthProvider());
        } else {
          return throwError(() => error);
        }
      }),
    );
  }

  signInWithGoogle(): Observable<UserCredential> {
    if (this.nativeService.isInAppBrowser()) {
      return this.signInWithRedirect(new GoogleAuthProvider());
    }

    return this.signInWithPopup(new GoogleAuthProvider()).pipe(
      catchError(error => {
        if (error.code === 'auth/popup-blocked') {
          return this.signInWithRedirect(new GoogleAuthProvider());
        } else {
          return throwError(() => error);
        }
      }),
    );
  }

  signInWithApple(): Observable<UserCredential> {
    const provider = this.createAppleAuthProvider();
    if (Capacitor.isNativePlatform()) {
      return this.signInWithCredentials(provider);
    }
    return this.signInWithPopup(provider).pipe(
      catchError(error => {
        if (error.code === 'auth/popup-blocked') {
          return this.signInWithRedirect(provider);
        } else {
          return throwError(() => error);
        }
      }),
    );
  }

  createAppleAuthProvider(): OAuthProvider {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    return provider;
  }

  signInWithCustomToken(token: string): Observable<DoodUserModel | undefined> {
    return from(signInWithCustomToken(this.auth, token)).pipe(
      switchMap((userCredential: UserCredential) =>
        this.saveFirebaseTokenInSessionStore$(userCredential),
      ),
      switchMap(() => this.loadUser$()),
    );
  }

  private restoreUser$(firebaseToken: string) {
    this.authDispatcher.updateStatus({ token: firebaseToken });
    return this.loadUser$();
  }

  private signInWithPopup(provider: any): Observable<UserCredential> {
    return from(signInWithPopup(this.auth, provider, browserPopupRedirectResolver)).pipe(
      catchError(error => {
        return throwError(() => error);
      }),
    );
  }

  private signInWithRedirect(provider: any): Observable<UserCredential> {
    this.authDispatcher.updateStatus({ redirect: true });
    return from(
      signInWithRedirect(this.auth, provider).then(() => {
        return getRedirectResult(this.auth);
      }),
    ).pipe(
      switchMap(userCredential =>
        userCredential ? of(userCredential) : throwError(() => new Error('UserCredential is null')),
      ),
    );
  }

  private signInWithCredentials(provider: any): Observable<UserCredential> {
    return from(signInWithCredential(this.auth, provider));
  }

  signInAnonymously(): Observable<UserCredential> {
    return from(signInAnonymously(this.auth)).pipe(tap(() => this.startAnonymousUserLogoutTimer()));
  }

  startAnonymousUserLogoutTimer(): void {
    this.authState$
      .pipe(
        take(1),
        switchMap(firebaseUser =>
          firebaseUser?.isAnonymous ? of(firebaseUser.metadata.creationTime) : of(undefined),
        ),
        switchMap(creationTime => {
          if (creationTime) {
            return timer(dayjs(creationTime).add(6, 'hour').diff(new Date())).pipe(
              take(1),
              switchMap(() => from(this.disconnectUser())),
            );
          }

          return of();
        }),
      )
      .subscribe();
  }

  upgradeAnonymousWithEmailAndPassword(email: string, password: string): Observable<any> {
    const auth = this.auth;
    const credentials = EmailAuthProvider.credential(email, password);
    if (credentials && auth.currentUser) {
      return from(linkWithCredential(auth.currentUser, credentials)).pipe(
        switchMap(userCredential => this.saveFirebaseTokenInSessionStore$(userCredential as any)),
        catchError(error => throwError(() => error)),
      );
    }
    return of();
  }

  upgradeAnonymousWithGoogle(): Observable<any> {
    const auth = this.auth;
    if (auth.currentUser) {
      return from(linkWithPopup(auth.currentUser, new GoogleAuthProvider())).pipe(
        catchError(error => throwError(() => error)),
      );
    }
    return of();
  }

  upgradeAnonymousWithApple(): Observable<any> {
    const auth = this.auth;
    if (auth.currentUser) {
      const provider = this.createAppleAuthProvider();
      return from(linkWithPopup(auth.currentUser, provider)).pipe(
        catchError(error => throwError(() => error)),
      );
    }
    return of();
  }

  upgradeAnonymousWithFacebook(): Observable<any> {
    const auth = this.auth;
    if (auth.currentUser) {
      return from(linkWithPopup(auth.currentUser, new FacebookAuthProvider())).pipe(
        catchError(error => throwError(() => error)),
      );
    }
    return of();
  }

  signUp(email: string, password: string): Promise<UserCredential> {
    email = email.toLowerCase();
    return createUserWithEmailAndPassword(this.auth, email, password).then(async res => {
      const firebaseToken = await res.user?.getIdToken();
      if (firebaseToken) {
        this.authDispatcher.updateStatus({ token: firebaseToken });
      }
      return res;
    });
  }

  getFirebaseToken$(userCredential: UserCredential): Observable<string | undefined> {
    const additionalUserInfo = getAdditionalUserInfo(userCredential);
    if (!!additionalUserInfo?.isNewUser) {
      return of(undefined);
    }

    return this.idToken$.pipe(
      switchMap((firebaseToken: any) => {
        if (firebaseToken) {
          return of(firebaseToken);
        }
        return of(undefined);
      }),
    );
  }

  saveFirebaseTokenInSessionStore$(userCredential: UserCredential): Observable<boolean> {
    const user = userCredential.user;
    if (!user) {
      return of(false);
    }

    return this.idToken$.pipe(
      tap((firebaseToken: string | null) =>
        this.authDispatcher.updateStatus({ token: firebaseToken }),
      ),
      map(() => true),
    );
  }

  saveNativeFirebaseTokenInSessionStore$(userCredential: SignInResult): Observable<boolean> {
    const user = userCredential.user;
    if (!user) {
      return of(false);
    }

    return this.idToken$.pipe(
      tap(token => this.authDispatcher.updateStatus({ token })),
      map(() => true),
    );
  }

  loadUser$(): Observable<DoodUserModel | undefined> {
    return this.userService.loadUser$().pipe(
      catchError(() => {
        return of(undefined);
      }),
    );
  }

  resetPassword(email: string): Promise<boolean> {
    email = email.toLowerCase();
    /* Set language code for email template */
    const language = this.settingsSelector.app.locale;
    if (language) {
      this.auth.languageCode = language;
    }

    return sendPasswordResetEmail(this.auth, email)
      .then(async () => {
        return true;
      })
      .catch((err: FirebaseError) => {
        return false;
      });
  }

  isAuthenticate$(): Observable<User | null> {
    return this.idToken$.pipe(
      take(1),
      tap(token => {
        if (token) {
          this.authDispatcher.updateStatus({ token });
        } else {
          if (this.authSelector.status.token) {
            this.authDispatcher.updateStatus({ token: null });
            const isUserAnonymous = !!this.userService.user?.is_anonymous;
            if (isUserAnonymous) {
              signOut(this.auth).then(() => {
                this.authDispatcher.resetAndClear();
              });
            }
          }
        }
      }),
      switchMap(() => this.authState$),
    );
  }

  disconnectUser(): Promise<void> {
    return signOut(this.auth).then(() => {
      this.authDispatcher.resetAndClear();
    });
  }

  public changePassword(oldPassword: string, newPassword: string): void {
    this.analyticsService.trackEvent('change_password_intent');
    const user = this.auth.currentUser;
    if (user?.email) {
      const credentials = EmailAuthProvider.credential(user.email, oldPassword);

      this.reauthenticateWithCredential(credentials, newPassword);
    }
  }

  public reauthenticateWithCredential(credentials: any, newPassword: string): void {
    const user = this.auth.currentUser;
    if (user) {
      reauthenticateWithCredential(user, credentials)
        .then(() => {
          updatePassword(user, newPassword).then(() => {
            this.analyticsService.trackEvent('change_password');
          });
        })
        .catch(error => {
          this.analyticsService.trackEvent('change_password_failure');
          console.error(error);
        });
    }
  }

  public getEmailErrorMessage(error: FirebaseError): string | undefined {
    switch (error.code) {
      case ErrorsCode.EmailAlreadyExist:
        return ERRORS.EMAIL_ALREADY_EXIST;
      case ErrorsCode.InvalidEmail:
        return ERRORS.LOGIN_INVALID;
      case ErrorsCode.UserNotFound:
        return ERRORS.USER_NOT_FOUND;
      case ErrorsCode.WeakPassword:
        return ERRORS.WEAK_PASSWORD;
      case ErrorsCode.WrongPassword:
        return undefined;
      case ErrorsCode.DisabledUser:
        return ERRORS.DISABLED_USER;
      default:
      // console.error(error?.message);
    }
    return undefined;
  }

  public getPasswordErrorMessage(error: FirebaseError): string | undefined {
    switch (error.code) {
      case ErrorsCode.WeakPassword:
        return ERRORS.WEAK_PASSWORD;
      case ErrorsCode.WrongPassword:
        return ERRORS.PASSSWORD_INVALID;
      default:
      // console.error(error?.message);
    }
    return undefined;
  }

  initAuthTokenRefresh(): void {
    this.authState$.subscribe(user => {
      if (user) {
        const isCreated24hoursbefore = dayjs(user.metadata.creationTime)
          .add(24, 'hours')
          .isBefore(dayjs());
        if (user.isAnonymous && isCreated24hoursbefore) {
          console.log('[USER] anonymous user is 24 hours old. Disconnecting...');
          this.disconnectUser().then(() => {
            document.location.reload();
          });
        }

        this.idToken$.pipe(take(1)).subscribe(token => {
          this.startTokenRefreshTimer();
          this.authDispatcher.updateStatus({ token: token });
        });
      } else {
        this.authDispatcher.resetAndClear();
      }
    });
  }

  private startTokenRefreshTimer(): void {
    // Refresh token each 10 minutes
    timer(600000)
      .pipe(
        take(1),
        tap(() => console.log('[Firebase] startTokenRefreshTimer tick')),
        switchMap(v => this.authState$),
        switchMap(v => (v ? v.getIdTokenResult(true) : of(undefined))),
      )
      .subscribe(result => {
        if (result) {
          this.authDispatcher.updateStatus({ token: result.token });
          // TODO: Check WTF is this...
          this.startTokenRefreshTimer();
        } else {
          // TODO: Check if we disconnect user here
          this.authDispatcher.resetAndClear();
        }
      });
  }

  getCurrentUser(): User | null {
    return this.auth.currentUser;
  }
}
