import { of } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { CartStoreDispatcher } from '@common/dispatchers/cart.dispatcher';
import { MarketplaceStoreRefiner } from '@common/refiners/marketplace.refiner';

import { ComoUser } from '@core/models/como.model';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

import { ComoService } from '@core/services/como/como.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { AuthFirebaseService } from '@core/services/api/auth-firebase/auth-firebase.service';

import { AuthenticationModalComponent } from '@shared/modals/authentication-modal/authentication-modal.component';
import { ComoLinkAccountModalComponent } from '@shared/modals/como/como-link-account-modal/como-link-account-modal.component';

@Component({
  selector: 'app-mosaic-como-link-account-card-block',
  templateUrl: './mosaic-como-link-account-card-block.component.html',
  styleUrls: ['./mosaic-como-link-account-card-block.component.scss'],
})
export class MosaicComoLinkAccountCardBlockComponent extends DestroyerBase implements OnInit {
  @Input() heading?: string;
  @Input() text?: string;
  @Input() backgroundColor?: IContentBuilderFieldColor;
  @Input() textColor?: string;

  isLoading = true;
  comoUser: ComoUser | null = null;

  isComoEnabled$ = this.marketplaceRefiner.selectIsComoPluginEnabled;

  constructor(
    private readonly comoService: ComoService,
    private cartDispatcher: CartStoreDispatcher,
    private readonly modalsService: ModalsService,
    readonly authFirebaseService: AuthFirebaseService,
    private marketplaceRefiner: MarketplaceStoreRefiner,
  ) {
    super();
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.isComoEnabled$
      .pipe(
        takeUntil(this._destroyerRef),
        switchMap(isComoEnabled => {
          if (!isComoEnabled) {
            this.isLoading = false;
            return of(false);
          }
          this.comoService.loadComoUser();
          return this.comoService.getComoUser$().pipe(
            takeUntil(this._destroyerRef),
            tap(comoUser => (this.comoUser = comoUser ?? null)),
            tap(() => (this.isLoading = false)),
          );
        }),
      )
      .subscribe();
  }

  onClick(): void {
    this.authFirebaseService
      .isAuthenticate$()
      .pipe(take(1))
      .subscribe(isAuthenticated => {
        if (!isAuthenticated || this.authFirebaseService.isAnonymous) {
          this.cartDispatcher.resetActive();
          this.modalsService.open(AuthenticationModalComponent.handle);
        } else {
          this.modalsService.open(ComoLinkAccountModalComponent.handle);
        }
      });
  }

  protected satisfyTs(text: string | undefined): IContentBuilderFieldColor {
    // I do not know what is the real value of text, so I just cast it to satisfy the type checker
    return text as unknown as IContentBuilderFieldColor;
  }
}
