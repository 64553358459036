import {Dayjs} from 'dayjs';
import dayjs from 'dayjs';
import {Injectable} from '@angular/core';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import {TranslateService} from '@ngx-translate/core';

import {CartKeys} from '@config/keys/cart.keys';

import {DoodCartModel} from '@core/models/cart.model';
import {ISimpleItem} from '@core/models/simple-item.model';

import {DateUtils} from '@shared/utils/date/date.utils';
import {DoodOrderModel} from '@store/order/order.model';
import {OrderTypeService} from '@core/services/order-type/order-type.service';
import {ParametersWantedAtTypeLabels} from '@config/labels/parameters.labels';
import {IOrderTypeCapabilities} from '@config/order-types-capabilities.config';
import {OrderTypeValues} from "@config/values/order.values";

@Injectable({
  providedIn: 'root',
})
export class CartParametersService {
  constructor(
    private readonly orderTypeService: OrderTypeService,
    private readonly translateService: TranslateService,
  ) {
    dayjs.extend(isTomorrow);
  }

  retrieveCartParametersValue(
    cart: DoodCartModel,
    order?: DoodOrderModel | null,
    slots?: number[],
  ): ISimpleItem[] {
    const wantedAt = cart.wanted_at;
    const distributionMode = cart.type;

    const orderTypeCapabilities = this.orderTypeService.getCapabilities(distributionMode);
    const distributionIcon = orderTypeCapabilities.icon;
    const distributionLabel = orderTypeCapabilities.label;
    const items: ISimpleItem[] = [];
    items.push({
      icon: distributionIcon,
      value: distributionMode,
      label: distributionLabel,
    });

    if (orderTypeCapabilities.displaySlot) {
      const wantedAtIcon = 'icon-clock';
      const wantedAtLabel =
        this.getWantedAtLabel(orderTypeCapabilities, wantedAt, distributionMode, order, slots) ?? '';

      items.push({
        icon: wantedAtIcon,
        label: wantedAtLabel,
        value: wantedAtLabel,
      });
    }

    if (orderTypeCapabilities.isOnSiteLocationMandatory) {
      if (cart[CartKeys.OnSiteLocationName]) {
        items.push({
          icon: 'icon-pin',
          label: cart.on_site_location_name ?? '',
          value: cart.on_site_location_name ?? '',
        });
      } else {
        items.push({
          icon: 'icon-cross-2',
          value: 'Emplacement inconnu',
          label: this.translateService.instant('parameters.unknown-location') ?? '',
        });
      }
    }

    return items;
  }

  private getWantedAtLabel(
    orderTypeCapabilities: IOrderTypeCapabilities,
    wantedAt: Date | null,
    distributionMode: string,
    order?: DoodOrderModel | null,
    slots?: number[],
  ): string | undefined {
    const readyAtDate = order?.ready_at;
    if (readyAtDate) {
      const defautLabel = !wantedAt
        ? this.translateService.instant(ParametersWantedAtTypeLabels.ASAP)
        : this.translateService.instant(ParametersWantedAtTypeLabels.LATER);
      return this.getFormattedDate(readyAtDate, distributionMode, order, defautLabel);
    }

    if (wantedAt) {
      const defaultLabel = dayjs(wantedAt).isToday()
        ? this.translateService.instant('parameters.wantedAt.today')
        : this.translateService.instant(ParametersWantedAtTypeLabels.LATER);

      return this.getFormattedDate(wantedAt, distributionMode, order, defaultLabel);
    }

    if (orderTypeCapabilities.preorderingAllowed && slots) {
      return this.getFormattedSlotLabel(slots, distributionMode);
    }

    if (!orderTypeCapabilities.preorderingAllowed) {
      return;
    }

    return ParametersWantedAtTypeLabels.ASAP;
  }

  private getFormattedDate(
    date: Date,
    distributionMode: string,
    order?: DoodOrderModel | null,
    defaultLabel = this.translateService.instant(ParametersWantedAtTypeLabels.LATER),
  ): string {
    let dateDayjs = DateUtils.dayjsInMarketplaceTimezone(date);
    const estimatedDeliveryDuration = order?.estimated_delivery_duration;
    if (estimatedDeliveryDuration) {
      dateDayjs = dateDayjs.add(estimatedDeliveryDuration, 'seconds');
    }
    const formattedDate = new Date(dateDayjs.format());

    const dateText = this.getDateText(dateDayjs, defaultLabel);

    return this.getFormattedLabel(dateText, formattedDate, distributionMode);
  }

  private getDateText(dateDayjs: Dayjs, defaultLabel: string): string {
    if (dateDayjs.isToday()) {
      return defaultLabel;
    } else if (dateDayjs.isTomorrow()) {
      return this.translateService.instant('parameters.wantedAt.tomorrow');
    } else {
      const dateText = dateDayjs.format('dddd DD/MM');
      return dateText.charAt(0).toUpperCase() + dateText.slice(1);
    }
  }

  private getFormattedLabel(dateText: string, date: Date, distributionMode: string): string {
    // Add 15 min to time displayed if delivery
    const deltaDisplayed = distributionMode === OrderTypeValues.Delivery ? 900 * 1000 : 0;
    const dateDisplayed = new Date(date.getTime() + deltaDisplayed);

    return `<span class="block text-sm font-base">${dateText}</span> <span class="block font-base text-sm">${this.getReadyAtLabel(dateDisplayed)}</span>`;
  }

  private getFormattedSlotLabel(slots: number[], distributionMode: string): string {
    if (!slots.length) {
      return `<span class="block text-sm font-base">${this.translateService.instant(ParametersWantedAtTypeLabels.ASAP)}</span>`;
    }
    const slotDayjs = dayjs.unix(slots[0]);
    const slotText = this.getDateText(
      slotDayjs,
      this.translateService.instant(ParametersWantedAtTypeLabels.ASAP),
    );
    const asapSlot = dayjs.unix(slots[0]).toDate();

    return this.getFormattedLabel(slotText, asapSlot, distributionMode);
  }

  private getReadyAtLabel(readyAt: Date): string | undefined {
    const minutes = DateUtils.dayjsInMarketplaceTimezone(readyAt).minute();
    const localizedHourFormat = this.translateService.instant('parameters.wantedAt.slots-format');
    if (minutes >= 0 && minutes < 15) {
      return `${DateUtils.dayjsInMarketplaceTimezone(readyAt).minute(0).format(localizedHourFormat)} - ${DateUtils.dayjsInMarketplaceTimezone(readyAt).minute(15).format(localizedHourFormat)}`;
    } else if (minutes >= 15 && minutes < 30) {
      return `${DateUtils.dayjsInMarketplaceTimezone(readyAt).minute(15).format(localizedHourFormat)} - ${DateUtils.dayjsInMarketplaceTimezone(readyAt).minute(30).format(localizedHourFormat)}`;
    } else if (minutes >= 30 && minutes < 45) {
      return `${DateUtils.dayjsInMarketplaceTimezone(readyAt).minute(30).format(localizedHourFormat)} - ${DateUtils.dayjsInMarketplaceTimezone(readyAt).minute(45).format(localizedHourFormat)}`;
    } else if (minutes >= 45 && minutes < 60) {
      return `${DateUtils.dayjsInMarketplaceTimezone(readyAt).minute(45).format(localizedHourFormat)} - ${DateUtils.dayjsInMarketplaceTimezone(
        readyAt,
      )
        .hour(DateUtils.dayjsInMarketplaceTimezone(readyAt).hour() + 1)
        .minute(0)
        .format(localizedHourFormat)}`;
    }
  }
}
