import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { ISimpleItem } from '@core/models/simple-item.model';
import { ILocationParameter } from '@core/models/parameters.model';
import { DoodLocationParameters } from '@store/settings/settings.model';

import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { SettingsParametersState } from '@store/settings/settings.state';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { OrderTypeService } from '@core/services/order-type/order-type.service';
import { ParametersWantedAtTypeLabels } from '@config/labels/parameters.labels';

import { DistributionModeValues } from '@config/values/order.values';
import { ParametersDistributionModeValues } from '@config/values/parameters.values';
import { SettingsStoreDispatcher } from '@common/dispatchers/settings.dispatcher';

@Injectable({
  providedIn: 'root',
})
export class ShopSearchParametersService extends DestroyerBase {
  constructor(
    private settingsSelector: SettingsStoreSelector,
    private settingsDispatcher: SettingsStoreDispatcher,
    private readonly orderTypeService: OrderTypeService,
  ) {
    super();
  }

  set(parameters: Partial<SettingsParametersState>): void {
    parameters.last_update = new Date();
    this.settingsDispatcher.updateParameters(parameters);
  }

  setLocation(location?: ILocationParameter): void {
    this.set({
      location,
      is_default_location: false,
    });
  }

  setLocationFromQueryParams(locationParameters: DoodLocationParameters): void {
    this.set({
      location: {
        address: `${locationParameters.deliveryStreetAddress}, ${locationParameters.deliveryPostalCode} ${locationParameters.deliveryCity}, ${locationParameters.deliveryCountry}`,
        lat: Number(locationParameters.deliveryLat),
        lng: Number(locationParameters.deliveryLng),
      },
      location_params: {
        deliveryStreetAddress: `${locationParameters.deliveryStreetAddress}`,
        deliveryPostalCode: `${locationParameters.deliveryPostalCode}`,
        deliveryCity: `${locationParameters.deliveryCity}`,
        deliveryCountry: `${locationParameters.deliveryCountry}`,
        deliveryLat: Number(locationParameters.deliveryLat),
        deliveryLng: Number(locationParameters.deliveryLng),
        deliveryInstructions: `${locationParameters.deliveryInstructions}`,
      },
    });
  }

  setDistributionModeType(distributionMode: string, isDistributionModeDefinedByUser = true): void {
    if (distributionMode === ParametersDistributionModeValues.Delivery) {
      this.set({
        wanted_at: null,
      });
    }
    this.set({
      distribution_mode: distributionMode,
      is_distribution_mode_defined: isDistributionModeDefinedByUser,
    });
  }

  getParameters$(): Observable<SettingsParametersState> {
    return this.settingsSelector.selectParameters;
  }

  getParameters(): SettingsParametersState {
    return this.settingsSelector.parameters;
  }

  getDistributionMode(): string {
    const parameters = this.getParameters();
    return parameters.distribution_mode;
  }

  retrieveParametersValue(parameters: SettingsParametersState): ISimpleItem[] {
    const distributionMode = parameters.distribution_mode;
    const orderTypeCapabilities = this.orderTypeService.getCapabilities(distributionMode);
    const distributionLabel = orderTypeCapabilities.label;
    const distributionIcon = orderTypeCapabilities.icon;
    const items = [];

    items.push({
      label: distributionLabel as string,
      icon: distributionIcon,
      value: distributionMode,
    });

    if (orderTypeCapabilities.preorderingAllowed) {
      const wantedAtTypeLabel = parameters.wanted_at
        ? ParametersWantedAtTypeLabels.LATER
        : ParametersWantedAtTypeLabels.ASAP;
      items.push({
        label: wantedAtTypeLabel as string,
        icon: 'icon-clock',
        value: wantedAtTypeLabel as string,
      });
    }

    return items;
  }

  setDefinedDistributionModeType(is_distribution_mode_defined: boolean): void {
    this.set({ is_distribution_mode_defined });
  }

  setDefault(defaultValues: any): void {
    if (
      defaultValues.latitude &&
      defaultValues.longitude &&
      (!this.settingsSelector.parameters?.location?.lat ||
        !this.settingsSelector.parameters?.location?.lng)
    ) {
      this.set({
        location: {
          lat: defaultValues.latitude,
          lng: defaultValues.longitude,
        },
        is_default_location: true,
      });
    }
    if (defaultValues.maxDistance && !this.settingsSelector.parameters?.distance) {
      this.set({ distance: defaultValues.maxDistance });
    }
  }

  isDeliveryDistributionMode(type: string): boolean {
    switch (type) {
      case DistributionModeValues.Stuart:
      case DistributionModeValues.UberDirect:
      case DistributionModeValues.Shipday:
      case DistributionModeValues.ZitiCity:
      case DistributionModeValues.OwnDelivery:
        return true;
      default:
        return false;
    }
  }

  setForceDistributionMode(force_distribution_mode?: string): void {
    this.set({ force_distribution_mode });
  }
}
