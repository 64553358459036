<div
  class="fixed mx-auto h-full w-full md:bottom-0 md:left-0 md:flex md:flex-col md:items-center md:justify-center mdMax:bottom-0 mdMax:left-0 mdMax:right-0 mdMax:top-0"
  [style.z-index]="index + 110"
>
  <app-modal-filler-atom
    *ngIf="!isMobile"
    [withBackdrop]="true"
    (click)="closeModal.emit($event)"
  ></app-modal-filler-atom>
  <div
    class="flex h-full flex-col overflow-y-auto bg-white pb-20 scrollbar-hide md:relative md:my-20 md:max-h-screen md:w-full md:max-w-screen-sm md:rounded-3xl md:pb-0"
    style="max-height: 1021px !important"
  >
    <div
      id="container"
      class="flex h-full flex-col overflow-y-auto bg-white scrollbar-hide md:relative md:max-h-screen md:w-full md:max-w-screen-sm md:pb-0"
      [style.color]="descriptionColor | contentBuilderColor"
      [style.z-index]="index + 50"
    >
      <app-back-header-atom
        [text]="title"
        [icon]="'arrow-left'"
        [isProductHeader]="true"
        [textColor]="headingColor"
        (click)="closeModal.emit($event)"
      ></app-back-header-atom>
      @if (!hideAdditionGroupDetails) {
        <div class="px-5 pb-3 pt-6">
          <p
            *ngIf="minCount && count && minCount < count"
            class="font-accent text-md font-bold leading-6"
          >
            {{ 'product-add-modal.choose-from' | translate }}
            {{ minCount }} {{ 'product-add-modal.to' | translate }}
            {{ count }}
          </p>
          <p
            *ngIf="(!minCount && count) || (minCount === count && count)"
            class="font-accent text-md font-bold leading-6"
          >
            {{ 'product-add-modal.choose' | translate }} {{ count }}
            <span *ngIf="minCount === 0">({{ 'product-add-modal.optional' | translate }})</span>
          </p>
          <p
            *ngIf="!count && isAdditionPanel"
            class="font-accent text-md font-bold leading-6"
          >
            Vous pouvez ajouter des suppléments
          </p>
        </div>
      }

      <div class="px-5">
        <ng-container *ngFor="let product of products">
          <div
            *ngIf="!product[productKeys.Hidden]"
            class="flex items-center justify-between py-3"
            [class.opacity-25]="!product[productKeys.Available]"
          >
            <div class="flex items-center">
              <input
                type="checkbox"
                [id]="product[productKeys.Id]"
                [name]="product[productKeys.Name]"
                class="form-checkbox mr-3.5 h-5 w-5 border text-primary-600"
                (change)="toggleCheckbox($event)"
                [disabled]="!product[productKeys.Available]"
                [checked]="isChecked(product[productKeys.Id])"
              />
              <div>
                <div>
                  <label
                    [for]="product[productKeys.Id]"
                    class="mr-4 font-base font-normal"
                    >{{ product[productKeys.Name] }}</label
                  >
                  <div
                    *ngIf="product?.images?.thumbnail?.url"
                    class="flex items-center space-x-4"
                  >
                    <div *ngIf="isChecked(product[productKeys.Id]) && (!count || count > 1)">
                      <app-counter-atom
                        [size]="'S'"
                        [min]="
                          forceType(
                            product[productKeys.MinCount] ? product[productKeys.MinCount] : 1
                          )
                        "
                        [max]="forceType(product[productKeys.MaxCount])"
                        [disableIncrease]="count ? (selectedProducts?.length ?? 0) >= count : false"
                        (countChange)="changeNbProduct($event, product[productKeys.Id])"
                        [count]="calculateNbProduct(product[productKeys.Id])"
                      ></app-counter-atom>
                    </div>
                    @let uiPrice =
                      product.type === 'SIMPLE'
                        ? product[productKeys.Price]
                        : product[productKeys.Discount] || product[productKeys.Price];
                    @let needToBeFormatted =
                      product.type === 'SIMPLE' ||
                      (!product[productKeys.Discount] && !isAdditionPanel);

                    <div
                      *ngIf="uiPrice && product[productKeys.Available]"
                      class="min-w-16.5 pr-1.5"
                      [style.color]="priceColor | contentBuilderColor"
                    >
                      <span>
                        <span *ngIf="uiPrice">+</span>
                        <app-price-atom
                          [amount]="uiPrice"
                          [bold]="false"
                          [size]="'SM'"
                          [format]="needToBeFormatted"
                        ></app-price-atom>
                      </span>
                    </div>
                    <span *ngIf="!product[productKeys.Available]"> Indisponible </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngIf="product.images?.thumbnail?.url"
              class="height-fit-content relative bg-neutral-50"
            >
              <img
                *ngIf="product.images?.thumbnail?.url"
                class="push-image h-full rounded rounded-lg object-cover"
                [src]="product.images?.thumbnail?.url"
                alt="image"
              />
            </div>
            <div
              *ngIf="!product?.images?.thumbnail?.url"
              class="flex items-center space-x-4"
            >
              <div *ngIf="isChecked(product[productKeys.Id]) && (!count || count > 1)">
                <app-counter-atom
                  [size]="'S'"
                  [min]="
                    forceType(product[productKeys.MinCount] ? product[productKeys.MinCount] : 1)
                  "
                  [max]="forceType(product[productKeys.MaxCount])"
                  [disableIncrease]="count ? (selectedProducts?.length ?? 0) >= count : false"
                  (countChange)="changeNbProduct($event, product[productKeys.Id])"
                  [count]="calculateNbProduct(product[productKeys.Id])"
                ></app-counter-atom>
              </div>
              <div
                *ngIf="
                  product[productKeys.Price] &&
                  product[productKeys.Price] !== 0 &&
                  product[productKeys.Available]
                "
                class="min-w-16.5 px-1.5 text-right"
              >
                <span
                  *ngIf="!isAdditionPanel"
                  [style.color]="priceColor | contentBuilderColor"
                >
                  <span *ngIf="product[productKeys.Price] > 0">+</span>
                  <app-price-atom
                    [amount]="product[productKeys.Price]"
                    [bold]="false"
                    [size]="'SM'"
                    [format]="true"
                  ></app-price-atom>
                </span>
                <span
                  *ngIf="isAdditionPanel"
                  [style.color]="priceColor | contentBuilderColor"
                >
                  <span *ngIf="product[productKeys.Price] > 0">+</span>
                  <app-price-atom
                    [amount]="product[productKeys.Price]"
                    [bold]="false"
                    [size]="'SM'"
                  ></app-price-atom>
                </span>
              </div>
              <span *ngIf="!product[productKeys.Available]"> Indisponible </span>
            </div>
          </div>
          <div
            *ngIf="
              product.additions_group_informations &&
              selectedProducts.includes(product[productKeys.Id])
            "
            class="pb-10"
          >
            <div
              *ngFor="let group of product.additions_group_informations"
              class="border-b border-neutral-300"
              (click)="openAdditions(product, group.id)"
            >
              <div
                *ngIf="group.items.length"
                class="flex items-center justify-between bg-neutral-100 px-5 py-4"
              >
                <div>
                  <p class="mb-0.5 font-accent text-base font-bold">
                    <span *ngIf="group.min_count > 0 && group.isValid"
                      ><i class="icon icon-check-circle mr-1.5 mt-1 text-success-600"></i
                    ></span>
                    <span *ngIf="group.min_count > 0 && !group.isValid"
                      ><i class="icon icon-warning-circle mr-1.5 mt-1"></i
                    ></span>
                    {{ group.name }}
                  </p>
                  @if (!hideAdditionGroupDetails) {
                    <p
                      *ngIf="
                        group.min_count > 0 && group.max_count === group.min_count && !group.isValid
                      "
                      class="font-base text-sm {{
                        !group.isValid ? 'text-secondary-700' : ''
                      }} leading-5"
                      [style.color]="
                        group.isValid ? (inactiveStateColor | contentBuilderColor) : ''
                      "
                    >
                      {{ 'product-add-modal.choose' | translate }}
                      {{ group.min_count }}
                    </p>
                    <p
                      *ngIf="
                        (group.min_count === 1 || group.min_count > 1) &&
                        group.min_count !== group.max_count &&
                        (group.max_count ?? 0) > 1 &&
                        !group.isValid
                      "
                      class="font-base text-sm {{
                        !group.isValid ? 'text-secondary-700' : ''
                      }} leading-5"
                      [style.color]="
                        group.isValid ? (inactiveStateColor | contentBuilderColor) : ''
                      "
                    >
                      {{ 'product-add-modal.choose' | translate }}
                      {{ group.min_count }}
                      {{ 'product-add-modal.to' | translate }}
                      {{ group.max_count }}
                    </p>
                    <p
                      *ngIf="group.min_count === 0 && (group.max_count ?? 0) > 0 && !group.isValid"
                      class="font-base text-sm leading-5"
                      [style.color]="inactiveStateColor | contentBuilderColor"
                    >
                      {{ 'product-add-modal.choose' | translate }}
                      {{ group.max_count }} max
                    </p>
                  }
                  <div *ngIf="group.selectedItems?.length">
                    <ng-container *ngFor="let item of group.selectedItems; let last1 = last">
                      <span class="font-base text-sm font-bold"
                        >{{ item.name }}
                        <span *ngIf="(item?.quantity ?? 0) > 1">x{{ item.quantity }}</span></span
                      >
                      <span *ngIf="!last1">, </span>
                    </ng-container>
                  </div>
                </div>
                <div>
                  <i class="icon icon-arrow-down-s"></i>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <footer
      class="fixed bottom-0 mx-auto w-full bg-white px-5 py-4 shadow-top md:sticky md:max-w-screen-sm md:px-6"
    >
      <app-button-atom
        *ngIf="isAdditionPanel"
        [disabled]="
          (selectedProducts?.length ?? 0) > count && (selectedProducts?.length ?? 0) < minCount
        "
        [type]="'primary'"
        [size]="'L'"
        [label]="'product-add-modal.validate' | translate"
        (click)="closeModal.emit()"
      ></app-button-atom>
      <app-button-atom
        *ngIf="!isAdditionPanel"
        [disabled]="checkIfGroupIsNotValid()"
        [type]="'primary'"
        [size]="'L'"
        [label]="'product-add-modal.validate' | translate"
        (click)="onValidate()"
      ></app-button-atom>
    </footer>
  </div>
</div>
