import { Component, HostBinding, Input } from '@angular/core';

import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-hero-card-carousel-block',
  templateUrl: './hero-card-carousel-block.component.html',
})
export class HeroCardCarouselBlockComponent {
  @Input() backgroundImage?: string;
  @Input() linkUrl?: string;
  @Input() linkTarget = '_self';
  @Input() heading?: string;
  @Input() content?: string;
  @Input() textColor?: IContentBuilderFieldColor = {
    value: '#00000',
    type: ColorFieldTypesValues.Hex,
  };
  @Input() backgroundColor: IContentBuilderFieldColor = {
    value: '#FFFFFF',
    type: ColorFieldTypesValues.Hex,
  };

  @HostBinding('class') classes = 'h-64 w-52 max-w-52 min-w-52 p-1';
}
