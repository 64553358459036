import { takeUntil, tap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';

import { ComoUser } from '@core/models/como.model';
import { ComoService } from '@core/services/como/como.service';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';

@Component({
  selector: 'app-como-punch-cards-block',
  templateUrl: './como-punch-cards-block.component.html',
  styleUrls: ['./como-punch-cards-block.component.scss'],
})
export class ComoPunchCardsBlockComponent extends DestroyerBase implements OnInit {
  public comoUser?: ComoUser | null;

  constructor(private readonly comoService: ComoService) {
    super();
  }

  ngOnInit(): void {
    this.comoService.loadComoUser();
    this.comoService
      .getComoUser$()
      .pipe(
        takeUntil(this._destroyerRef),
        tap(comoUser => (this.comoUser = comoUser)),
      )
      .subscribe();
  }
}
