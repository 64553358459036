<div
  class="mb-7 w-full"
  *ngIf="!isLoading && rewards.length > 0"
>
  <ul class="flex overflow-x-auto px-5 pt-2 pb-2 scrollbar-hide">
    <li
      class="pr-3 flex-shrink-0 flex-grow-0 w-6/12 max-w-56"
      *ngFor="let asset of rewards"
    >
      <ng-container *ngTemplateOutlet="assetCard; context: { asset }"></ng-container>
    </li>
  </ul>
</div>

<div
  *ngIf="isLoading"
  class="mb-7 flex overflow-x-auto px-5 pt-2 pb-2 scrollbar-hide"
>
  <div
    *ngFor="let i of [].constructor(30)"
    class="mr-3 flex-shrink-0 flex-grow-0 w-5/12 max-w-56 ng-star-inserted rounded-lg shadow-S overflow-hidden"
  >
    <div class="animate-pulse h-28 overflow-hidden relative bg-neutral-100"></div>
    <div class="h-32 p-4">
      <p class="animate-pulse mb-3 py-3 bg-neutral-100"></p>
      <p class="animate-pulse py-2 bg-neutral-100"></p>
    </div>
  </div>
</div>

<ng-template
  #assetCard
  let-asset="asset"
>
  <div class="h-full flex justify-between flex-col rounded-lg bg-white shadow-S overflow-hidden">
    <div
      *ngIf="asset.picture_url"
      class="flex-shrink-0 bg-neutral-50"
      style="aspect-ratio: 1"
    >
      <img
        [src]="asset.picture_url"
        class="object-contain"
        style="aspect-ratio: 1"
      />
    </div>
    <div class="px-4 py-4 h-full text-sm flex flex-col justify-between">
      <div>
        <p class="text-base lg:text-md font-bold font-base leading-5 pt-1 mb-2">
          {{ asset.name }}
        </p>
        <p>{{ 'pongo.accessible-in-points' | translate: asset }}</p>
      </div>
    </div>
  </div>
</ng-template>
