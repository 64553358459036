import { Router } from '@angular/router';
import { Component } from '@angular/core';

import { RouterHelperService } from '@core/services/router-helper/router-helper.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
})
export class PageNotFoundComponent {
  constructor(
    private router: Router,
    private readonly routerHelper: RouterHelperService,
  ) {}

  navigateToHome(): void {
    this.router.navigate([this.routerHelper.translateRoute('/')]);
  }
}
