import { Store } from '@ngrx/store';
import { EntityMapOne, Update } from '@ngrx/entity';
import { Injectable } from '@angular/core';

import { MODAL_STORE_LOCAL_ACTIONS } from '@store/modal';
import { DoodModalModel } from '@core/models/modal.model';

@Injectable({ providedIn: 'root' })
export class ModalStoreDispatcher {
  constructor(private store: Store) {}

  // One
  addModal(modal: DoodModalModel): void {
    this.store.dispatch(MODAL_STORE_LOCAL_ACTIONS.addModal({ modal }));
  }

  updateModal(modal: Update<DoodModalModel>): void {
    this.store.dispatch(MODAL_STORE_LOCAL_ACTIONS.updateModal({ modal }));
  }

  mapModal(map: EntityMapOne<DoodModalModel>): void {
    this.store.dispatch(MODAL_STORE_LOCAL_ACTIONS.mapModal({ modal: map }));
  }

  removeModal(id: string): void {
    this.store.dispatch(MODAL_STORE_LOCAL_ACTIONS.removeModal({ id }));
  }

  // Misc
  reset(): void {
    this.store.dispatch(MODAL_STORE_LOCAL_ACTIONS.reset());
  }
}
