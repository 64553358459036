import { DoodShopModel } from '@core/models/shop.model';
import { ActiveState, createActiveAdapter } from '@store/_abstract/active';

export interface ShopState extends ActiveState<DoodShopModel, string> {
  slots: number[];
  isLoaded: boolean;
  isLoading: boolean;
  active: string | null;
}

export const SHOP_STORE_KEY = 'Shops';

export const SHOP_STORE_ACTIVE_ADAPTER = createActiveAdapter<DoodShopModel, string>();

export const SHOP_STORE_INITIAL_SLOTS_STATE: number[] = [];

export const SHOP_STORE_INITIAL_STATE: ShopState = SHOP_STORE_ACTIVE_ADAPTER.getInitialState({
  isLoaded: false,
  isLoading: false,
  slots: SHOP_STORE_INITIAL_SLOTS_STATE,
});
