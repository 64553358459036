<ng-container *ngIf="['text', 'email', 'tel'].includes(type)">
  <label
    *ngIf="label"
    [class.font-bold]="boldLabel"
    >{{ label }}</label
  >
  <input
    [type]="type"
    [placeholder]="placeholder ? placeholder : ''"
    [value]="value ? value : ''"
    (keyup)="onChangeTextValue($event)"
    (keyup.enter)="unfocus($event.target)"
    autofocus
  />
</ng-container>
<ng-container *ngIf="type === 'radio'">
  <input
    [id]="label"
    [type]="type"
    [checked]="active"
    (change)="onChangeValueRadio(value)"
  />
  <label
    *ngIf="label"
    class="ml-2"
    [class.font-bold]="boldLabel"
    [for]="label"
    >{{ label }}</label
  >
</ng-container>
<ng-container *ngIf="type === 'checkbox'">
  <input
    [id]="label"
    [type]="type"
    [checked]="value"
    (change)="onChangeValueCheckbox($event)"
    class="form-checkbox m-3 h-6 w-6 border text-primary-600"
  />
  <label
    *ngIf="label"
    class="ml-2"
    [class.font-bold]="boldLabel"
    [for]="label"
    >{{ label }}</label
  >
</ng-container>
