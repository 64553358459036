<header
  [class.absolute]="positionAbsoluteOnMobile"
  [class.pt-8]="isNotched"
  [ngClass]="positionAbsoluteOnDesktop ? 'md:absolute' : 'md:relative'"
  class="z-30 mx-auto w-full"
>
  <div
    class="flex w-full items-center justify-between overflow-x-auto px-5 py-4 scrollbar-hide lg:px-12"
    [style.backgroundColor]="backgroundColor | contentBuilderColor"
  >
    <div class="flex flex-shrink items-center justify-start scrollbar-hide md:overflow-x-auto">
      <a
        [link]="'/'"
        *ngIf="displayLogo"
      >
        <img
          class="h-12 max-w-none"
          src="{{ logoImageUrl }}"
          alt=""
        />
      </a>
      <app-rounded-icon-button
        *ngIf="displayBackButton"
        [icon]="'icon-arrow-left'"
        [type]="'light'"
        [size]="isNotched ? 'isNotched' : 'md'"
        [color]="'neutral'"
        [overrideBackgroundColor]="iconBackgroundColor"
        [overrideColor]="iconColor"
        (click)="back()"
      ></app-rounded-icon-button>
      <div class="flew-row ml-2 mr-4 flex flex-shrink items-center">
        <app-block-list [blockIdList]="leftBlocks"></app-block-list>
        <app-block-list
          [blockIdList]="navigationLinks"
          *ngIf="navigationLinks && !isMobileScreenType"
        ></app-block-list>
      </div>
    </div>

    <h1
      *ngIf="title$ | async as title"
      class="block text-center font-accent text-md font-bold lg:hidden"
    >
      {{ title | translate }}
    </h1>

    <div class="-mr-4 flex flex-shrink-0 flex-row items-center justify-end">
      <!-- Shop search address -->
      <div
        class="mr-4 hidden w-96 rounded-full mix-blend-multiply lg:block"
        *ngIf="isShopSearchAddressVisible$ | async"
      >
        <app-shop-search-location-input></app-shop-search-location-input>
      </div>

      <!-- Shop search parameters -->
      <div
        class="mr-4 hidden rounded-full lg:block"
        *ngIf="isShopSearchParametersVisible$ | async"
      >
        <app-shop-search-parameters-overview
          [iconBackgroundColor]="iconBackgroundColor"
          [iconColor]="iconColor"
          [textColor]="labelColor"
        ></app-shop-search-parameters-overview>
      </div>

      <!-- Cart parameters -->
      <div
        class="mr-4 hidden rounded-full lg:block"
        *ngIf="isCartParametersVisible$ | async"
      >
        <app-cart-parameters-overview
          [iconBackgroundColor]="iconBackgroundColor"
          [iconColor]="iconColor"
          [textColor]="labelColor"
        ></app-cart-parameters-overview>
      </div>

      <!-- Cart button -->
      <div
        class="mr-4 hidden rounded-full lg:block"
        *ngIf="isCartButtonVisible$ | async"
      >
        <app-button-atom
          [size]="'L'"
          [type]="'primary'"
          [disabled]="isNavigationToCartInProgress"
          [icon]="!isNavigationToCartInProgress ? 'icon-shopping-bag' : ''"
          [iconUrl]="isNavigationToCartInProgress ? 'assets/images/spinner.svg' : ''"
          [label]="('shop-page.basket' | translate) + ' (' + (cartProducts$ | async)?.length + ')'"
          [overrideBgColor]="buttonBackgroundColor"
          [overrideTextColor]="buttonTextColor"
          (click)="navigateToCart()"
        ></app-button-atom>
      </div>

      <div
        class="ml-5 mr-4 flex"
        *ngIf="displayHamburger"
      >
        <div class="flew-row flex flex-shrink items-center">
          <app-block-list [blockIdList]="rightBlocks"></app-block-list>
        </div>
        <app-header-language-flag-atom
          *ngIf="languageFlagUrl"
          [languageFlagUrl]="languageFlagUrl"
          (click)="onLanguageFlagClick()"
        ></app-header-language-flag-atom>
        <div class="flex items-center">
          <app-hamburger-menu-atom
            [color]="hamburgerColor"
            [class.md:hidden]="navigationLinks?.length"
            [light]="positionAbsoluteOnMobile && isMobileScreenType"
            (click)="navigationLinks?.length ? toggleStaticNav() : onClick()"
          ></app-hamburger-menu-atom>
        </div>
      </div>
    </div>
  </div>
</header>
<nav
  class="z-100 w-screen bg-neutral-800"
  [ngClass]="isOpenStaticNav ? 'fixed top-0 bottom-0 left-0 right-0' : 'hidden'"
>
  <div class="flex h-screen flex-col justify-center text-center">
    <header class="absolute top-0 w-full p-5">
      <div class="flex justify-between">
        <span></span>
        <span
          ><i
            class="icon icon-cross text-white"
            (click)="toggleStaticNav()"
          ></i
        ></span>
      </div>
    </header>
    <div class="self-center">
      <app-block-list
        *ngIf="navigationLinks"
        class="overflow-x-scroll bg-white"
        [blockIdList]="navigationLinks"
        (click)="toggleStaticNav()"
      ></app-block-list>
      <app-block-list [blockIdList]="rightBlocks"></app-block-list>
    </div>
  </div>
</nav>
