import { of } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import {
  PAYMENT_STORE_BUTTON_INITIAL_STATE,
  PAYMENT_STORE_STATUS_INITIAL_STATE,
} from '@store/payment/payment.state';

import { DestroyerBase } from '@core/base/destroyer/destroyer.base';

import { Paths } from '@config/paths.config';
import { IPaymentMethod, PaymentMethodHandles } from '@config/payment-methods.config';

import { PaymentStoreRefiner } from '@common/refiners/payment.refiner';
import { PaymentStoreSelector } from '@common/selectors/payment.selector';
import { PaymentStoreDispatcher } from '@common/dispatchers/payment.dispatcher';
import { PaymentAtTableStoreSelector } from '@common/selectors/payment-at-table.selector';

import { PaymentService } from '@core/services/payment/payment.service';
import { ErrorService } from '@core/services/error-service/error.service';
import { NavigationHistoryService } from '@core/services/navigation-history/navigation-history.service';

@Component({
  selector: 'app-pat-payment-method-picker-block',
  templateUrl: './pat-payment-method-picker-block.component.html',
  styleUrls: ['./pat-payment-method-picker-block.component.scss'],
})
export class PatPaymentMethodPickerBlockComponent extends DestroyerBase implements OnInit {
  amount$ = this.paymentSelector.selectAmount;
  availablePaymentMethods$ = this.paymentRefiner.selectPatPaymentMethodsWithDescriptors;
  availablePaymentMethods?: IPaymentMethod[];
  selectedPaymentMethod?: IPaymentMethod;
  errorMessages: string[] = [];
  error: string | null = null;
  amount: number | null = null;
  status = PAYMENT_STORE_STATUS_INITIAL_STATE;
  button = PAYMENT_STORE_BUTTON_INITIAL_STATE;

  get hasErrorMessages(): boolean {
    return this.errorMessages?.length > 0;
  }

  @Output() selectedPaymentMethodEmitter = new EventEmitter<IPaymentMethod>();

  constructor(
    private router: Router,
    private readonly errorService: ErrorService,
    private paymentRefiner: PaymentStoreRefiner,
    private paymentSelector: PaymentStoreSelector,
    private readonly activatedRoute: ActivatedRoute,
    private readonly paymentService: PaymentService,
    private paymentDispatcher: PaymentStoreDispatcher,
    private paymentAtTableSelector: PaymentAtTableStoreSelector,
    private readonly navigationHistoryService: NavigationHistoryService,
  ) {
    super();
    this.paymentSelector.select
      .pipe(takeUntil(this._destroyerRef))
      .subscribe(({ amount, button, error, status }) => {
        this.amount = amount;
        this.button = button;
        this.error = error;
        this.status = status;
      });
  }

  ngOnInit(): void {
    this.navigationHistoryService.pushStateFromActivatedRouteSnapshot(
      `${Paths.Payment}`,
      this.activatedRoute.snapshot,
    );

    this.setDefaultUI();

    const parsedUrl = this.router.parseUrl(this.router.url);

    // TODO: Inception :'D
    this.availablePaymentMethods$
      .pipe(
        tap(availablePaymentMethods => {
          availablePaymentMethods = availablePaymentMethods || [];
          availablePaymentMethods.forEach(a => {
            a.data = {
              ...a.data,
              isUnique: availablePaymentMethods.length === 1,
            };
          });
          this.availablePaymentMethods = availablePaymentMethods;

          if (availablePaymentMethods.length === 1) {
            this.selectedPaymentMethod = availablePaymentMethods[0];
            this.selectedPaymentMethodEmitter.emit(this.selectedPaymentMethod);
          }

          if (parsedUrl.queryParams?.payment_method === PaymentMethodHandles.Edenred) {
            this.selectedPaymentMethod =
              availablePaymentMethods.find(pm => pm.handle === PaymentMethodHandles.Edenred) ||
              undefined;
            this.selectedPaymentMethodEmitter.emit(this.selectedPaymentMethod);
          }

          if (parsedUrl.queryParams?.transaction) {
            // TODO: Check leak
            this.paymentAtTableSelector.selectCheck
              .pipe(takeUntil(this._destroyerRef))
              .subscribe(check => {
                check = check;
                const transaction = check.transactions.find(
                  t => t.id === parsedUrl.queryParams?.transaction,
                );
                if (transaction) {
                  this.amount$ = of(transaction.amount);
                }
              });
          }
        }),
      )
      .subscribe();

    this.errorService
      .getErrorMessage$()
      .pipe(takeUntil(this._destroyerRef))
      .subscribe(messages => {
        this.errorMessages = messages;
      });
  }

  selectPaymentMethod(paymentMethod: IPaymentMethod): void {
    this.paymentSelector.selectStatus.pipe(take(1)).subscribe(({ isRetryInProgress }) => {
      if (isRetryInProgress) {
        return;
      }
      if (this.selectedPaymentMethod === paymentMethod) {
        this.selectedPaymentMethod = undefined;
        this.setDefaultUI();
        return;
      }
      this.selectedPaymentMethod = paymentMethod;
    });

    this.selectedPaymentMethodEmitter.emit(this.selectedPaymentMethod);
  }

  triggerPayment(): void {
    this.paymentService.triggerPayment();
  }

  private setDefaultUI(): void {
    this.paymentDispatcher.resetUI();
  }

  forceType(data: any): any {
    return data as any;
  }
}
