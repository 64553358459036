// todo make it global

import { inject, Injectable } from '@angular/core';
import { ProductStoreRefiner } from '@common/refiners/product.refiner';
import { DoodProductModel } from '@core/models/product.model';
import { IAdditionGroup } from '@core/models/shop.model';
import { ProductsService } from '@core/services/products/products.service';
import { forkJoin, map, Observable, switchMap, take } from 'rxjs';

export type ProductsByCategoryId = {
  [categoryId: string]: DoodProductModel[];
};

export type AdditionGroupsBySimpleProductId = {
  [simpleProductId: string]: IAdditionGroup[] | undefined;
};

@Injectable({ providedIn: 'root' })
export class CartUtilService {
  private readonly productsService = inject(ProductsService);
  private readonly productStoreRefiner = inject(ProductStoreRefiner);

  public getProductsByCategoryId$(
    declinableProduct: DoodProductModel,
  ): Observable<ProductsByCategoryId> {
    return this.productsService.selectAll$().pipe(
      map(allProducts => {
        const productsByCategory = declinableProduct.categories.reduce((acc, category) => {
          const productsCategory = allProducts
            .filter(product => category.products.includes(product.id))
            ?.map(product => ({ ...product, quantity: 0 })); // ensure to reset all quantity to 0, otherwise if the product is already in the cart, it will share the same reference
          return { ...acc, [category.store_id ?? '']: productsCategory };
        }, {} as ProductsByCategoryId);
        return productsByCategory;
      }),
    );
  }

  /**
   * It does not handle the case when a simple product is in multiple categories (yet)
   */
  public getAdditionGroupsBySimpleProductId$(
    productsByCategoryId: ProductsByCategoryId,
  ): Observable<AdditionGroupsBySimpleProductId> {
    const productAdditionsGroupByProductId$ = Object.values(productsByCategoryId)
      .map(simpleProducts => {
        return simpleProducts.map(simpleProduct => {
          const additionProductsIds = simpleProduct.additions?.products;
          if (!additionProductsIds) {
            return undefined;
          }
          return this.getProductAdditionsByGroup$(additionProductsIds).pipe(
            map(additionsGroups => {
              return CartUtilService.mapAdditionGroupsWithPosition({
                additionsGroups,
                simpleProductAdditions: simpleProduct.additions.products,
              }).sort((a, b) => a.position - b.position);
            }),
            map(data => {
              return {
                [simpleProduct.id]: data,
              };
            }),
          );
        });
      })
      .flat()
      .filter(item => item !== undefined) as Observable<AdditionGroupsBySimpleProductId>[];
    return forkJoin(productAdditionsGroupByProductId$).pipe(
      map(data => {
        return data.reduce((acc, item) => {
          return { ...acc, ...item };
        }, {} as AdditionGroupsBySimpleProductId);
      }),
    );
  }

  static mapAdditionGroupsWithPosition({
    additionsGroups,
    simpleProductAdditions,
  }: {
    additionsGroups: IAdditionGroup[];
    simpleProductAdditions: string[];
  }): {
    position: number;
    selected?: string[];
    isValid?: boolean;
    selectedItems?: DoodProductModel[];
    id: string;
    name: string;
    min_count: number;
    max_count?: number;
    items: DoodProductModel[];
    count?: number;
  }[] {
    return additionsGroups.map(additionGroup => {
      const positionOfFirstAdditionInParentProduct = simpleProductAdditions.indexOf(
        additionGroup.items[0].id,
      );
      return {
        ...additionGroup,
        position: positionOfFirstAdditionInParentProduct,
      };
    });
  }

  public getProductAdditionsByGroup$(additions: string[]): Observable<IAdditionGroup[]> {
    return this.productsService.selectAll$().pipe(
      map(products => products.filter(product => additions.includes(product.id))),
      map(products =>
        products.map(product => ({
          // ensure that the quantity is set to 0 and it avoid to share the same reference, because the product quantity is mutated
          ...product,
          quantity: 0,
        })),
      ),
      switchMap(additionsProducts => {
        return this.productStoreRefiner.getAdditionsByGroup$(additionsProducts);
      }),
      map(groups => {
        return CartUtilService.mapAdditionGroupsWithPosition({
          additionsGroups: groups,
          simpleProductAdditions: additions,
        }).sort((a, b) => a.position - b.position);
      }),
      take(1),
    );
  }
}
