<div class="relative flex max-w-32 flex-col items-center justify-center gap-1.5 text-center">
  <div
    class="relative h-32 w-32 rounded-lg"
    [style.backgroundColor]="productBackgroundColor | contentBuilderColor"
  >
    <img
      *ngIf="thumbnail"
      class="push-image h-full w-full rounded-lg bg-center object-cover"
      [src]="thumbnail"
      alt="image"
    />
    <span
      class="absolute -top-2 right-3 flex h-6 w-6 items-center justify-center rounded-full text-center text-sm font-bold"
      [style.backgroundColor]="dotColor | contentBuilderColor"
      [style.color]="dotTextColor | contentBuilderColor"
      >x{{ quantity }}</span
    >
  </div>
  <p
    class="whitespace-nowrap break-words text-left text-base font-semibold"
    [style.color]="productTextColor | contentBuilderColor"
  >
    {{ truncatedName }}
  </p>
</div>
