import {combineLatest, Subject, timer} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {Component, Input, OnInit} from '@angular/core';

import {PaymentService} from '@core/services/payment/payment.service';
import {PaymentStoreDispatcher} from '@common/dispatchers/payment.dispatcher';
import {DoodOrderModel} from '@store/order/order.model';

@Component({
  selector: 'app-pat-payment-panel-yavin',
  template: `
    <div *ngIf="isUnique" class="absolute inset-0 h-screen z-100 bg-white flex items-center justify-center">
      <div class="flex flex-col items-center justify-center gap-8 p-12">
        <i class="icon icon-privacy text-4xl animate-pulse"></i>
        <h1 class="text-xl font-bold text-center">Redirection vers la page de paiement...</h1>
      </div>
    </div>`,
})
export class PatPaymentPanelYavinComponent implements OnInit {
  @Input() isUnique = false;
  protected readonly unsubscribe$ = new Subject<boolean>();
  order: DoodOrderModel | null = null;

  constructor(
    private readonly paymentService: PaymentService,
    private paymentDispatcher: PaymentStoreDispatcher,
  ) {
  }

  ngOnInit(): void {
    timer(1).subscribe(() => {
      this.paymentDispatcher.updateUI({
        button: {
          text: 'yavin.pay-button',
          isEnabled: true,
        },
        status: {
          isLoading: false,
        },
      });
    });

    if (this.isUnique) {
      timer(1).subscribe(() => {
        this.paymentDispatcher.updateButton({
          text: 'Redirection vers la page de paiement...',
        });
      });
    }

    combineLatest([this.paymentService.pay$, this.paymentService.paymentYavinIntent$])
      .pipe(
        takeUntil(this.unsubscribe$),
        map(([, paymentIntent]) => {
          if (paymentIntent?.payment_page_url) {
            window.location.href = paymentIntent.payment_page_url;
          } else {
            throw new Error('Payment intent is undefined');
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.paymentDispatcher.resetUI();
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
