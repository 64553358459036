<div class="flex flex-1 flex-col">
  @if (header) {
    <div
      id="header"
      class="flex-shrink-0"
      [ngStyle]="getBackgroundStyles()"
      [style.backgroundColor]="backgroundColor | contentBuilderColor"
      [style.color]="textColor | contentBuilderColor"
    >
      <app-block-list [blockIdList]="header"></app-block-list>
    </div>
  }

  <div
    class="flex flex-1 flex-col overflow-y-auto"
    [style.backgroundColor]="backgroundColor | contentBuilderColor"
    [style.color]="textColor | contentBuilderColor"
  >
    <div class="flex h-full flex-col items-center justify-center p-10 pb-64 text-center">
      <!-- Heading -->
      <h1
        class="mb-16 text-center text-6xl font-bold"
        [style.color]="accentColor | contentBuilderColor"
      >
        {{ title | translate }}
      </h1>

      <!-- Cards -->
      <div class="mb-32 grid grid-cols-2 gap-12">
        <div
          *ngFor="let distributionModeItem of availableDistributionModeItems"
          class=""
        >
          <app-kiosk-active-button-atom
            [activeBackground]="activeCardBackgroundColor"
            [activeTextColor]="activeCardTextColor"
            [inactiveBackground]="inactiveCardBackgroundColor"
            [inactiveTextColor]="inactiveCardTextColor"
            [label]="distributionModeItem.label | translate"
            [isActive]="distributionModeItem.value === selectedDistributionMode"
            (clickEmitter)="selectDistributionMode(distributionModeItem)"
          >
            <i
              class="icon {{ distributionModeItem.icon }} mb-4"
              *ngIf="distributionModeItem.icon"
            ></i>
          </app-kiosk-active-button-atom>
        </div>
      </div>

      <!-- Date and time picker -->
      <div *ngIf="preorderingAllowed()">
        <h1
          class="mb-16 mt-32 text-center text-6xl font-bold"
          [style.color]="accentColor | contentBuilderColor"
        >
          {{ 'parameters.for-when' | translate }}
        </h1>
        <div class="mb-32 grid grid-cols-2 gap-12">
          <app-kiosk-active-button-atom
            [label]="'parameters.asap' | translate"
            [activeBackground]="activeCardBackgroundColor"
            [activeTextColor]="activeCardTextColor"
            [inactiveBackground]="inactiveCardBackgroundColor"
            [inactiveTextColor]="inactiveCardTextColor"
            [isActive]="selectedWantedAtType === 'asap'"
            (clickEmitter)="onWantedAtTypeChanged('asap')"
          >
          </app-kiosk-active-button-atom>
          <app-kiosk-active-button-atom
            [label]="'parameters.for-later' | translate"
            [activeBackground]="activeCardBackgroundColor"
            [activeTextColor]="activeCardTextColor"
            [inactiveBackground]="inactiveCardBackgroundColor"
            [inactiveTextColor]="inactiveCardTextColor"
            [isActive]="selectedWantedAtType === 'later'"
            (clickEmitter)="onWantedAtTypeChanged('later')"
          >
          </app-kiosk-active-button-atom>
        </div>
        <div
          *ngIf="selectedWantedAtType === 'later'"
          class="mb-5"
        >
          <select
            class="text-4xl"
            [(ngModel)]="selectedDateSlot"
            (change)="changeDate()"
          >
            <option
              *ngFor="let item of availableDatesSlots"
              [ngValue]="item.value"
            >
              {{ item.label }}
            </option>
          </select>
          <select
            class="text-4xl"
            [(ngModel)]="selectedTimeSlot"
          >
            <option
              *ngFor="let item of availableTimesSlots"
              [ngValue]="item.slot.start"
            >
              {{ item.label }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="w-full self-end">
    <app-kiosk-footer-double-button-atom
      [backgroundColor]="backgroundColor"
      [textColor]="textColor"
      firstButtonLabel="Annuler"
      [secondButtonBackgroundColor]="secondaryButtonBackgroundColor"
      [secondButtonTextColor]="secondaryButtonTextColor"
      [secondButtonDisabled]="!canValidate()"
      secondButtonLabel="Valider"
      (onFirstButtonClick)="navigateto('/')"
      (onSecondButtonClick)="navigateto(linkUrl, true)"
    >
    </app-kiosk-footer-double-button-atom>
  </div>
</div>
