<div
  class="flex cursor-pointer items-center justify-between py-4"
  (click)="openCompound.emit($event)"
  [style.color]="descriptionColor | contentBuilderColor"
>
  <div>
    <div
      *ngIf="name"
      class="flex items-center font-accent text-md font-bold leading-6"
      [style.color]="headingColor | contentBuilderColor"
    >
      <span *ngIf="isValid && products?.length"
        ><i class="icon icon-check-circle mr-1.5 mt-1 text-success-600"></i
      ></span>
      <span *ngIf="isValid === false"
        ><i class="icon icon-warning-circle mr-1.5 mt-1 text-neutral-800"></i
      ></span>
      <span>{{ name }}</span>
    </div>
    <div
      *ngIf="description"
      class="font-base text-sm font-normal"
    >
      {{ description }}
    </div>
    @if (!hideAdditionGroupDetails) {
      <div
        *ngIf="(!minCount && count) || (minCount === count && count !== null && count > 0)"
        class="flex items-center justify-between leading-6"
      >
        <span
          [class.text-secondary-700]="isValid === false"
          class="font-base text-base font-normal"
          [style.color]="inactiveStateColor | contentBuilderColor"
          >{{ 'product-add-modal.choose' | translate }} {{ count }}
          <span *ngIf="minCount === 0">({{ 'product-add-modal.optional' | translate }})</span></span
        >
      </div>
      <div
        *ngIf="minCount && minCount !== count"
        class="flex items-center justify-between leading-6"
      >
        <span
          [class.text-secondary-700]="isValid === false"
          class="font-base text-base font-normal"
          [style.color]="inactiveStateColor | contentBuilderColor"
          >{{ 'product-add-modal.choose-from' | translate }} {{ minCount }}
          {{ 'product-add-modal.to' | translate }} {{ count }}</span
        >
      </div>
    }
    <div
      *ngIf="selectedItems$ | async as items"
      class="font-base text-base font-bold font-normal"
    >
      <span *ngFor="let item of items; let last = last">
        {{ item.name }}<span *ngIf="item.quantity > 1"> &times; {{ item.quantity }}</span
        ><span *ngIf="!last && (items?.length ?? 0) > 1">, </span>
        <span *ngIf="item.additions?.length"> - </span>
        <span *ngFor="let addition of item.additions; let lastAddition = last"
          >{{ addition.name }}
          <span *ngIf="addition.quantity > 1">&times; {{ addition.quantity }}</span
          ><span *ngIf="!lastAddition && (item.additions?.length ?? 0) > 1">, </span>
        </span>
      </span>
    </div>
  </div>
  <div class="ml-2">
    <i class="icon icon-arrow-down-s"></i>
  </div>
</div>
