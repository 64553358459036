import { Component, Input, OnInit } from '@angular/core';

import { CartFunnelStoreDispatcher } from '@common/dispatchers/cart-funnel.dispatcher';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-kiosk-cart-funnel-input-text-block',
  templateUrl: './kiosk-cart-funnel-input-text-block.component.html',
})
export class KioskCartFunnelInputTextBlockComponent implements OnInit {
  @Input() title!: string;
  @Input() label!: string;
  @Input() placeholder!: string;
  @Input() required = false;
  @Input() blockId!: string;
  @Input() formId!: string;
  @Input() color?: IContentBuilderFieldColor;
  @Input() textColor?: IContentBuilderFieldColor;

  value: string | boolean = '';

  constructor(private cartFunnelDispatcher: CartFunnelStoreDispatcher) {}

  ngOnInit(): void {
    this.cartFunnelDispatcher.addValue({
      id: this.blockId,
      title: this.title,
      displayTitle: true,
      formId: this.formId,
      required: this.required,
    });
  }

  onValueChange(_value: string | boolean): void {
    this.cartFunnelDispatcher.updateValue({
      id: this.blockId,
      changes: {
        value: _value,
      },
    });
    this.cartFunnelDispatcher.funnelUsername(_value as string);
  }
  forceType(data: any): any {
    return data as any;
  }
}
