<span
  *ngIf="value === budgetValues.Cheap"
  class="font-roboto text-sm text-neutral-600"
>
  <span> {{ currency === 'EUR' ? '€' : '$' }}</span>
</span>
<span
  *ngIf="value === budgetValues.Moderate"
  class="font-roboto text-sm text-neutral-600"
>
  <span> {{ currency === 'EUR' ? '€€' : '$$' }}</span>
</span>
<span
  *ngIf="value === budgetValues.Expensive"
  class="font-roboto text-sm text-neutral-600"
>
  <span> {{ currency === 'EUR' ? '€€€' : '$$$' }}</span>
</span>
