import { ReducerTypes, ActionCreator, on } from '@ngrx/store';

import { ArrayUtils } from '@app/utilities/array.util';

import { ActiveState } from './active.state';
import { ActiveStoreActionGroup } from './active.action';
import { ActiveAdapter, createActiveAdapter } from './active.adapter';

export interface ActivePieceOptions<Entity, Id> {
  adapter?: ActiveAdapter<Entity, Id>;
  actions: ActiveStoreActionGroup<Entity, Id>;
}

export const createActiveStorePiece = <
  State extends ActiveState<Entity, Id>,
  Entity,
  Id extends string | number = string,
>({
  actions,
  adapter,
}: ActivePieceOptions<Entity, Id>): ReducerTypes<State, readonly ActionCreator[]>[] => {
  const _adapter = adapter ?? createActiveAdapter<Entity, Id>();
  return [
    // One
    on(actions.addOne, (state, { entity }) => _adapter.addOne(entity, state)),
    on(actions.mapOne, (state, { entity }) => _adapter.mapOne(entity, state)),
    on(actions.removeOne, (state, { id }) => {
      return {
        ...(typeof id === 'string' ? _adapter.removeOne(id, state) : _adapter.removeOne(id, state)),
        active: state.active === id ? null : state.active,
      };
    }),
    on(actions.updateOne, (state, { update }) => _adapter.updateOne(update, state)),
    on(actions.upsertOne, (state, { entity }) => _adapter.upsertOne(entity, state)),

    // Many
    on(actions.addMany, (state, { entities }) => _adapter.addMany(entities, state)),
    on(actions.mapMany, (state, { entities }) => _adapter.map(entities, state)),
    on(actions.removeMany, (state, { ids }) => {
      return {
        ...(ArrayUtils.isArrayOfNumbers(ids)
          ? _adapter.removeMany(ids, state)
          : _adapter.removeMany(
              ids.map(_id => _id.toString()),
              state,
            )),
        active: state.active && ids.includes(state.active) ? null : state.active,
      };
    }),
    on(actions.updateMany, (state, { updates }) => _adapter.updateMany(updates, state)),
    on(actions.upsertMany, (state, { entities }) => _adapter.upsertMany(entities, state)),

    // All
    on(actions.setAll, (state, { entities }) => _adapter.setAll(entities, state)),
    on(actions.removeAll, state => _adapter.removeAll(state)),

    // Active
    on(actions.setActive, (state, { active }) => _adapter.setActive(active, state)),
    on(actions.resetActive, state => _adapter.resetActive(state)),
    on(actions.updateActive, (state, { changes }) => _adapter.updateActive(changes, state)),
    on(actions.insertActive, (state, { entity }) => _adapter.insertActive(entity, state)),
    on(actions.upsertActive, (state, { changes }) => _adapter.upsertActive(changes, state)),

    // Misc
    on(actions.reset, _ => {
      return _adapter.getInitialState();
    }),
  ];
};
