export enum TransactionKeys {
  Id = 'id',
  Order = 'order',
  User = 'user',
  PaymentMethod = 'payment_method',
  Status = 'status',
  PaidAt = 'paid_at',
  Amount = 'amount',
  Currency = 'currency',
}
