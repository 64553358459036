<div
  class="sticky top-0 z-30 flex h-16 w-full flex-row items-center overflow-hidden bg-white pl-5 shadow shadow-bottom"
>
  <app-small-button-atom
    [icon]="'arrow-left'"
    [text]="'Retour'"
    (click)="goBack()"
  ></app-small-button-atom>
  <app-horizontal-filter-list-atom
    class="w-full"
    [items]="(entities$ | async) ?? []"
    (clickItem)="navigateToShop($event)"
  ></app-horizontal-filter-list-atom>
</div>
