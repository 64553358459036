import {combineLatest} from 'rxjs';
import {Component, OnInit} from '@angular/core';
import {map, take, takeUntil, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';

import {Paths} from '@config/paths.config';

import {PriceUtils} from '@shared/utils/price/price.utils';
import {CurrencyUtils} from '@shared/utils/currency/currency.utils';

import {DestroyerBase} from '@core/base/destroyer/destroyer.base';
import {MarketplaceStoreSelector} from '@common/selectors/marketplace.selector';
import {PaymentAtTableStoreRefiner} from '@common/refiners/payment-at-table.refiner';
import {PaymentAtTableStoreSelector} from '@common/selectors/payment-at-table.selector';
import {PaymentAtTableStoreDispatcher} from '@common/dispatchers/payment-at-table.dispatcher';

import {IPatChooseType, IPatRadioButtons, PatRadioButtonKey} from '@core/models/pat.model';
import {IShopPayAtTablePlugin} from '@core/models/shop.model';

import {DoodPaymentAtTableAmountType} from '@store/payment-at-table/payment-at-table.enum';

import {ModalsService} from '@core/services/modals/modals.service';
import {ShopsApiService} from '@core/services/api/shops/shops-api.service';
import {RouterHelperService} from '@core/services/router-helper/router-helper.service';
import {NavigationHistoryService} from '@core/services/navigation-history/navigation-history.service';

import {PatFreeAmountModalComponent} from '@shared/modals/pat-free-amount-modal/pat-free-amount-modal.component';
import {PatDivideAmountModalComponent} from '@shared/modals/pat-divide-amount-modal/pat-divide-amount-modal.component';
import {
  PatSelectProductsModalComponent
} from '@shared/modals/pat-select-products-modal/pat-select-products-modal.component';
import {CHECK_TIPS_CONFIG} from '@config/values/pat.values';

@Component({
  selector: 'app-pat-choose-amount-page',
  templateUrl: './pat-choose-amount-page.component.html',
})
export class PatChooseAmountPageComponent extends DestroyerBase implements OnInit {
  outstandingAmount$ = this.paymentAtTableSelector.selectCheckOutstandingAmount;

  totalAmount$ = this.paymentAtTableSelector.selectChooseAmount;

  totalAmountWithTip$ = combineLatest([
    this.paymentAtTableSelector.selectChooseAmount,
    this.paymentAtTableSelector.selectChooseTip,
  ]).pipe(map(([amount, tip]) => PriceUtils.formatAndRoundUpPriceWithComa(amount + (tip ?? 0))));

  tip$ = this.paymentAtTableSelector.selectChooseTip;

  currencySymbol!: string;

  radioButtons: IPatRadioButtons = {
    total: true,
    products: false,
    divide: false,
    free: false,
  };

  amountTypeValues = DoodPaymentAtTableAmountType;

  private readonly modalMethodsMap = {
    [DoodPaymentAtTableAmountType.Products]: PatSelectProductsModalComponent.handle,
    [DoodPaymentAtTableAmountType.Divide]: PatDivideAmountModalComponent.handle,
    [DoodPaymentAtTableAmountType.Free]: PatFreeAmountModalComponent.handle,
  };

  selectedChooseType!: IPatChooseType;

  isLoading = false;
  errorMessage: string | null = null;

  isTransactionError = false;

  CHECK_TIPS_CONFIG = CHECK_TIPS_CONFIG;

  isModalTipOtherAmountOpen = false;

  isTipsEnabled = false;

  constructor(
    private readonly router: Router,
    private readonly modalsService: ModalsService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly shopApiService: ShopsApiService,
    private readonly routerHelper: RouterHelperService,
    private marketplaceSelector: MarketplaceStoreSelector,
    private paymentAtTableRefiner: PaymentAtTableStoreRefiner,
    private paymentAtTableSelector: PaymentAtTableStoreSelector,
    private paymentAtTableDispatcher: PaymentAtTableStoreDispatcher,
    private readonly navigationHistoryService: NavigationHistoryService,
  ) {
    super();
    this.paymentAtTableRefiner.selectErrorMessage
      .pipe(takeUntil(this._destroyerRef))
      .subscribe(_message => {
        this.errorMessage = _message;
      });
  }

  ngOnInit(): void {
    this.navigationHistoryService.pushStateFromActivatedRouteSnapshot(
      `${Paths.PayAtTable}/${Paths.PayAtTableChooseAmount}`,
      this.activatedRoute.snapshot,
    );
    combineLatest([
      this.paymentAtTableSelector.selectChoose,
      this.paymentAtTableSelector.selectCheckOutstandingAmount,
    ])
      .pipe(
        takeUntil(this._destroyerRef),
        tap(([choose, outstandingAmount]) => {

          if (choose.amount <= 0 && outstandingAmount > 0) {
            this.paymentAtTableDispatcher.updateChoose({
              type: DoodPaymentAtTableAmountType.Total,
              amount: outstandingAmount,
            });
          }

          if (
            choose.type === DoodPaymentAtTableAmountType.Total &&
            outstandingAmount > 0 &&
            outstandingAmount !== choose.amount
          ) {
            this.paymentAtTableDispatcher.updateAmount(outstandingAmount);
          }

          if (choose.type) {
            this.resetRadioButtons();
            this.selectedChooseType = {
              type: choose.type,
              totalAmount: choose.amount,
              tip: choose.tip ?? undefined,
              selectedProducts: choose.products,
              divideParts: choose.parts ?? undefined,
            };
            this.radioButtons[choose.type] = true;
          }
        }),
      )
      .subscribe();

    this.paymentAtTableSelector.selectCheck
      .pipe(
        takeUntil(this._destroyerRef),
        tap((check) => {
          if (!check?.shop?.id || !check?.marketplace?.id) {
            return;
          }

          this.shopApiService
            .getShopById$(check?.shop?.id, check?.marketplace?.id)
            .subscribe(shop => {
              // Get an existing pay at table plugin.
              const patPlugin = shop.plugins.find(plugin => {
                return plugin.plugin_enabled && plugin.type === 'PAY_AT_TABLE';
              });

              if (patPlugin === undefined) {
                return;
              }

              this.isTipsEnabled = (patPlugin as IShopPayAtTablePlugin).tips_enabled ?? false;
            });
        }),
      )
      .subscribe();

    this.currencySymbol = CurrencyUtils.getCurrencySymbol(
      this.marketplaceSelector.marketplace.currency,
    );
  }

  goToPayment(): void {
    this.setLoading(true);

    this.router.navigate([
      this.routerHelper.translateRoute(`/${Paths.PayAtTable}/${Paths.Payment}`),
    ]);
  }

  private setLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  getAmountType(blockIdValue: string | boolean): void {
    if (typeof blockIdValue === 'boolean') {
      return;
    }
    const blockId = blockIdValue as PatRadioButtonKey;
    if (blockId !== DoodPaymentAtTableAmountType.Total) {
      const modalMethod = this.modalMethodsMap[blockId];
      if (modalMethod) {
        this.modalsService.open(modalMethod);
      }
    } else {
      this.paymentAtTableSelector.selectCheck.pipe(take(1)).subscribe(check => {
        this.paymentAtTableDispatcher.updateChoose({
          amount: check.outstanding_amount,
          type: blockId as DoodPaymentAtTableAmountType,
        });
      });
    }

    this.resetRadioButtons();
    this.radioButtons[blockId] = true;
  }

  private resetRadioButtons(): void {
    for (const key in this.radioButtons) {
      if (this.radioButtons.hasOwnProperty(key)) {
        this.radioButtons[key as PatRadioButtonKey] = false;
      }
    }
  }

  openModal(type: PatRadioButtonKey): void {
    if (type !== DoodPaymentAtTableAmountType.Total) {
      const modalMethod = this.modalMethodsMap[type];
      if (modalMethod) {
        this.modalsService.open(modalMethod);
      }
    }
  }

  onSelectedTip(tip: number): void {
    this.paymentAtTableDispatcher.updateTip(tip);
  }

  onOtherTipAmount(): void {
    this.isModalTipOtherAmountOpen = true;
  }

  onApplyTip(tip: number): void {
    this.paymentAtTableDispatcher.updateTip(tip * 100);
    this.isModalTipOtherAmountOpen = false;
  }

  closeTipModal(): void {
    this.isModalTipOtherAmountOpen = false;
  }
}
