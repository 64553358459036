<app-modal-filler-atom
  (click)="close()"
  [index]="5"
></app-modal-filler-atom>
<div
  class="max-h-85-screen' fixed left-0 right-0 top-60 mx-auto max-w-screen-md content-center overflow-y-auto"
  [style.z-index]="750"
>
  <div
    class="relative rounded-lg bg-white"
    [style.z-index]="((index$ | async) ?? 0) + 50"
  >
    <div class="absolute right-4 top-4 z-10">
      <app-rounded-icon-button
        icon="icon-cross"
        [overrideColor]="forceType(backgroundColor)"
        size="md"
        type="outlined"
        [bold]="true"
        (onClick)="close()"
      ></app-rounded-icon-button>
    </div>
    <div class="relative flex h-96 items-center justify-center overflow-hidden rounded-t-lg">
      <div class="absolute inset-0 bg-white">
        <div
          class="h-52"
          [style.backgroundColor]="forceType(accentColor) | contentBuilderColor"
        ></div>
      </div>
      @if (image) {
        <img
          [src]="image"
          alt=" "
          class="relative z-10 aspect-square h-auto w-80 object-cover shadow-lg"
        />
      }
    </div>
    <div
      class="mx-auto flex max-w-80 flex-col items-center justify-center gap-5 pb-24 pt-10 text-left"
    >
      @if (name) {
        <p class="text-center text-xl font-bold">{{ name }}</p>
      }
      @if (price) {
        <app-price-atom
          size="XL"
          [amount]="forceType(price)"
          [bold]="true"
        ></app-price-atom>
      }
      @if (description) {
        <p>
          {{ description }}
        </p>
      }
      @if (allergens?.length) {
        <div class="flex w-full flex-col gap-2">
          <p class="font-bold">{{ 'product-add-modal.allergens' | translate }}</p>
          <p>
            @for (allergen of allergens; track allergen.id; let last = $last) {
              {{ allergen.name }}{{ !last ? ', ' : '' }}
            }
          </p>
        </div>
      }
      @if (price) {
        <div class="flex flex-col items-center justify-center gap-4 py-4">
          @if (type === 'SIMPLE' && product?.additions?.products?.length === 0) {
            <app-counter-atom
              [type]="'kiosk'"
              [size]="'L'"
              [min]="1"
              [backgroundColor]="forceType(backgroundColor) | contentBuilderColor"
              [textColor]="forceType(accentColor) | contentBuilderColor"
              [(count)]="quantity"
            ></app-counter-atom>
            <app-button-atom
              [label]="'product-add-modal.add-to-cart' | translate"
              [type]="'kiosk-outlined'"
              [size]="'XXL'"
              [thickSize]="'S'"
              [overrideBgColor]="forceType(backgroundColor)"
              [overrideTextColor]="forceType(accentColor)"
              (click)="addToCart()"
            >
            </app-button-atom>
          } @else {
            <app-button-atom
              [label]="'product-add-modal.choose-declinable' | translate"
              [type]="'kiosk-outlined'"
              [size]="'XXL'"
              [thickSize]="'S'"
              [overrideBgColor]="forceType(backgroundColor)"
              [overrideTextColor]="forceType(accentColor)"
              (click)="onNavigateToProduct()"
            >
            </app-button-atom>
          }
        </div>
      }
    </div>
  </div>
</div>
