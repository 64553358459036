import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContentBuilderFieldColors } from '@shared/interfaces/content-builder.interface';

@Component({
  selector: 'app-tag-atom',
  templateUrl: './tag-atom.component.html',
})
export class TagAtomComponent {
  @Input() color:
    | 'neutral'
    | 'success'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'highlight'
    | 'error'
    | 'neutral-200'
    | 'neutral-300'
    | 'primary-invert'
    | ContentBuilderFieldColors = 'neutral';
  @Input() size: 'S' | 'M' = 'M';
  @Input() text!: string;
  @Input() bold = true;
  @Input() lineThrough!: boolean;
  @Input() url: any[] | string | null | undefined;
  @Output() buttonClick = new EventEmitter<Event>();

  protected isContentBuilderFieldColors(color: this['color']): color is ContentBuilderFieldColors {
    return typeof color === 'object' && 'font' in color && 'background' in color;
  }

  forceType(data: any): any {
    return data as any;
  }
}
