import { NgModule } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { RouterModule, Routes } from '@angular/router';

import {
  LocalizeParser,
  LocalizeRouterModule,
  LocalizeRouterSettings,
} from '@gilsdav/ngx-translate-router';

import { AppLocalizeHttpLoaderFactory } from '@app/app.i18n';

import { PageNotFoundComponent } from '@shared/components/page-not-found/page-not-found.component';
import { QrCodeRedirectComponent } from '@core/components/qr-code-redirect/qr-code-redirect.component';
import { LegacyShopRedirectComponent } from '@core/components/legacy-shop-redirect/legacy-shop-redirect.component';
import { LegacyOrderStatusRedirectComponent } from '@core/components/legacy-order-status-redirect/legacy-order-status-redirect.component';
import { LegacyShopDigitalMenuRedirectComponent } from '@core/components/legacy-shop-digital-menu-redirect/legacy-shop-digital-menu-redirect.component';

export const routes: Routes = [
  { path: 'thank-you/:id', component: LegacyOrderStatusRedirectComponent },
  { path: 'shops/:shopSlug', component: LegacyShopRedirectComponent },
  { path: 'shop/:shopSlug', component: LegacyShopRedirectComponent },
  {
    path: 'shop/:shopSlug/on-site-ordering',
    component: QrCodeRedirectComponent,
  },
  {
    path: 'shop/:shopSlug/menu',
    component: LegacyShopDigitalMenuRedirectComponent,
  },
  { path: '404', component: PageNotFoundComponent },
  { path: '**', pathMatch: 'full', redirectTo: '/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollOffset: [0, 0],
      anchorScrolling: 'enabled',
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'enabled',
    }),
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: AppLocalizeHttpLoaderFactory,
        deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient],
      },
    }),
  ],
  exports: [RouterModule, LocalizeRouterModule],
})
export class AppRoutingModule {}
