import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Component, Input } from '@angular/core';

import { ModalsService } from '@core/services/modals/modals.service';

import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-kiosk-alert-modal',
  template: `
    <app-modal-filler-atom
      (click)="close()"
      [index]="5"
    ></app-modal-filler-atom>
    <div
      class="md:left-0' fixed bottom-0 left-0 right-0 mx-auto max-h-85-screen max-w-screen-sm overflow-y-auto md:top-0 md:flex md:h-full md:w-full md:items-center md:justify-center"
      [style.z-index]="750"
    >
      <div
        class="flex min-w-448 flex-col gap-6 rounded-3xl bg-white p-5 px-16 py-8 text-center"
        [style.z-index]="((index$ | async) ?? 0) + 50"
      >
        @if (icon) {
          <i
            class="py-4 text-9xl icon {{ icon }}"
            [style.color]="accentColor | contentBuilderColor"
          ></i>
        }
        <div
          class="px-18 py-6 text-center font-accent text-5xl font-semibold"
          [style.color]="accentColor | contentBuilderColor"
        >
          {{ message }}
        </div>
        <app-button-atom
          size="L"
          label="Fermer"
          color="primary"
          (click)="close()"
          type="kiosk-primary"
          [overrideBgColor]="accentColor"
          [full]="true"
        ></app-button-atom>
      </div>
    </div>
  `,
})
export class KioskAlertModalComponent {
  static handle = 'kiosk-alert-modal';

  @Input() accentColor?: IContentBuilderFieldColor;
  @Input() message?: string;
  @Input() icon?: string;

  isMobile$ = this.settingsSelector.selectDeviceIsMobileScreen;

  index$: Observable<number> = this.modalSelector
    .selectModal(KioskAlertModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  isNotched = false;

  constructor(
    private modalSelector: ModalStoreSelector,
    private readonly modalsService: ModalsService,
    private settingsSelector: SettingsStoreSelector,
  ) {}

  close(): void {
    this.modalsService.close(KioskAlertModalComponent.handle);
  }
}
