import { createFeature, createSelector } from '@ngrx/store';

import { PAT_STORE_KEY } from './payment-at-table.state';
import { PAT_STORE_REDUCER } from './payment-at-table.reducer';

const PAT_STORE_FEATURE = createFeature({
  name: PAT_STORE_KEY,
  reducer: PAT_STORE_REDUCER,
});

export const {
  selectCheck: SELECT_PAT_CHECK_STATE,
  selectChoose: SELECT_PAT_CHOOSE_STATE,
  selectPaymentAtTableState: SELECT_PAT_STATE,
} = PAT_STORE_FEATURE;

// Choose
export const SELECT_PAT_CHOOSE_TIP = createSelector(SELECT_PAT_CHOOSE_STATE, c => c.tip);
export const SELECT_PAT_CHOOSE_TYPE = createSelector(SELECT_PAT_CHOOSE_STATE, c => c.type);
export const SELECT_PAT_CHOOSE_ERROR = createSelector(SELECT_PAT_CHOOSE_STATE, c => c.error);
export const SELECT_PAT_CHOOSE_PARTS = createSelector(SELECT_PAT_CHOOSE_STATE, c => c.parts);
export const SELECT_PAT_CHOOSE_AMOUNT = createSelector(SELECT_PAT_CHOOSE_STATE, c => c.amount);
export const SELECT_PAT_CHOOSE_PRODUCTS = createSelector(SELECT_PAT_CHOOSE_STATE, c => c.products);

// Check
export const SELECT_PAT_CHECK_SHOP = createSelector(SELECT_PAT_CHECK_STATE, c => c.shop);
export const SELECT_PAT_CHECK_ITEMS = createSelector(SELECT_PAT_CHECK_STATE, c => c.items);
export const SELECT_PAT_CHECK_ERROR = createSelector(SELECT_PAT_CHECK_STATE, c => c.error);
export const SELECT_PAT_CHECK_FINAL_PRICE = createSelector(
  SELECT_PAT_CHECK_STATE,
  c => c.final_price,
);
export const SELECT_PAT_CHECK_MARKETPLACE = createSelector(
  SELECT_PAT_CHECK_STATE,
  c => c.marketplace,
);
export const SELECT_PAT_CHECK_TRANSITIONS = createSelector(
  SELECT_PAT_CHECK_STATE,
  c => c.transactions,
);
export const SELECT_PAT_CHECK_ON_SITE_LOCATION = createSelector(
  SELECT_PAT_CHECK_STATE,
  c => c.onsite_location,
);
export const SELECT_PAT_CHECK_OUTSTANDING_AMOUNT = createSelector(
  SELECT_PAT_CHECK_STATE,
  c => c.outstanding_amount,
);
export const SELECT_PAT_CHECK_ACTIVE_TRANSACTION = createSelector(
  SELECT_PAT_CHECK_STATE,
  c => c.active_transaction,
);
