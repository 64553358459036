import { timer } from 'rxjs';
import { Component, Input } from '@angular/core';

import { ModalsService } from '@core/services/modals/modals.service';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';
import { SidebarNavigationModalComponent } from '@shared/modals/sidebar-navigation-modal/sidebar-navigation-modal.component';

@Component({
  selector: 'app-sidebar-navigation-modal-item-block',
  templateUrl: './sidebar-navigation-modal-item-block.component.html',
})
export class SidebarNavigationModalItemBlockComponent {
  @Input() title?: string;
  @Input() path?: string;
  @Input() linkTarget = '_self';
  @Input() icon?: string;
  @Input() userMustBeLoggedIn = false;

  userIsLoggedIn$ = this.authSelector.selectUserIsLoggedIn;

  constructor(
    private authSelector: AuthStoreSelector,
    private readonly modalsService: ModalsService,
  ) {}

  click(): void {
    // Tiny delay to prevent page change "flash"
    timer(1).subscribe(() => {
      this.modalsService.close(SidebarNavigationModalComponent.handle);
    });
  }
}
