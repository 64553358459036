import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-html-block',
  templateUrl: './html-block.component.html',
  template: ``,
})
export class HtmlBlockComponent {
  @Input() html?: string;
}
