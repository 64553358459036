import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-cart-footer-button-atom',
  templateUrl: './cart-footer-button-atom.component.html',
  styleUrls: ['./cart-footer-button-atom.component.scss'],
})
export class CartFooterButtonAtomComponent {
  @Input() icon!: string;
  @Input() link?: string;
  @Input() label!: string;
  @Input() iconUrl!: string;
  @Input() disabled = false;
  @Input() buttonBackgroundColor: IContentBuilderFieldColor = {
    value: 'primary-600',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() buttonTextColor: IContentBuilderFieldColor = {
    value: '#FFFFFF',
    type: ColorFieldTypesValues.Hex,
  };

  @Output() clickEventEmitter = new EventEmitter<any>();
}
