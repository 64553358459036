import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-small-button-atom',
  templateUrl: './small-button-atom.component.html',
})
export class SmallButtonAtomComponent {
  @Input() icon!: string;
  @Input() text!: string;
}
