import { createHydratedReducer } from '@store/_abstract/hydrated';

import {
  MARKETPLACE_STORE_API_PIECE,
  MARKETPLACE_STORE_COMPONENT_PIECE,
  MARKETPLACE_STORE_LOCAL_PIECE,
} from './marketplace.piece';
import { MARKETPLACE_STORE_INITIAL_STATE, MARKETPLACE_STORE_KEY } from './marketplace.state';

export const MARKETPLACE_STORE_REDUCER = createHydratedReducer({
  key: MARKETPLACE_STORE_KEY,
  initialState: MARKETPLACE_STORE_INITIAL_STATE,
  ons: [
    ...MARKETPLACE_STORE_API_PIECE,
    ...MARKETPLACE_STORE_LOCAL_PIECE,
    ...MARKETPLACE_STORE_COMPONENT_PIECE,
  ],
});
