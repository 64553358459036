<div *ngIf="orderValidate$ | async as orderValidate">
  <div class="mb-3 px-5 lg:px-0 max-w-md lg:max-w-full mx-auto">
    <app-discount-voucher-atom
      [headingColor]="headingColor"
      (click)="openInputVoucher()"
      (removeVoucher)="onRemoveVoucher($event)"
    ></app-discount-voucher-atom>
  </div>
</div>
<div *ngIf="cartCoupon$ | async as coupon">
  <div *ngIf="errorMessage$ | async">
    <div class="mb-3 px-5 lg:px-0 max-w-md lg:max-w-full mx-auto">
      <app-discount-voucher-atom
        [headingColor]="headingColor"
        (click)="openInputVoucher()"
        (removeVoucher)="onRemoveVoucher($event)"
      ></app-discount-voucher-atom>
    </div>
  </div>
</div>
