import { Injectable } from '@angular/core';
import { IKioskHardwareEpsonLocalPrinter } from '@core/models/kiosk.model';
import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class EpsonLocalPrinterService {
  printerIpAddress = '';
  printerDeviceId = '';
  lineWidth = 48;

  constructor(private readonly http: HttpClient) {}

  setPrinterConfiguration(printerConfiguration: IKioskHardwareEpsonLocalPrinter): void {
    this.printerIpAddress = printerConfiguration.printer_ip_address;
    this.printerDeviceId = printerConfiguration.printer_device_id;
    this.lineWidth = printerConfiguration.line_width;
  }

  subscribeToPrintLines(printLines$: Subject<string[]>): void {
    console.log(
      `[EpsonLocalPrinter]: Subscribe to print with Epson ePOS with deviceId:${this.printerDeviceId} on ip: ${this.printerIpAddress}`,
    );

    printLines$.pipe(switchMap(lines => this.print(lines))).subscribe(
      response => {
        console.log('[EpsonLocalPrinter]: success', response);
      },
      error => {
        console.error('[EpsonLocalPrinter]: error', error);
      },
    );
  }

  print(lines: string[]) {
    const xml = this.convertLinesToXML(lines);
    const url = `http://${this.printerIpAddress}/cgi-bin/epos/service.cgi?devid=${this.printerDeviceId}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/xml',
    });

    console.log('[EpsonLocalPrinter] Print', { lines }, xml);
    return this.http.post(url, xml, { headers, responseType: 'text' });
  }

  private convertLinesToXML(lines: string[]) {
    // XML de départ
    let xml = `<?xml version="1.0" encoding="UTF-8"?>
  <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
    <s:Body>
      <epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
  `;

    lines.forEach(line => {
      const escapedLine = this.escapeXml(line);
      xml += `<text x="0" font="font_a" em="false" reverse="false" dh="false" dw="false" align="left">${escapedLine}</text>
  <feed line="1"/>`;
    });

    xml += `
        <cut type="feed"/>
      </epos-print>
    </s:Body>
  </s:Envelope>`;
    return xml;
  }

  // Fonction pour échapper les caractères spéciaux dans le XML
  private escapeXml(unsafe: string): string {
    return unsafe.replace(/[<>&"']/g, function (char) {
      switch (char) {
        case '<':
          return '&lt;';
        case '>':
          return '&gt;';
        case '&':
          return '&amp;';
        case '"':
          return '&quot;';
        case "'":
          return '&apos;';
        default:
          return char;
      }
    });
  }
}
