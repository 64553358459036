import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OrderStatusLabels } from '@config/labels/order.labels';

@Component({
  selector: 'app-notification-message-atom',
  templateUrl: './notification-message-atom.component.html',
})
export class NotificationMessageAtomComponent {
  @Input() message!: string;

  @Output() closeMessage = new EventEmitter<any>();
  @Output() clickMessage = new EventEmitter<any>();

  get orderStatusLabels(): typeof OrderStatusLabels {
    return OrderStatusLabels;
  }
}
