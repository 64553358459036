import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ContentStoreDispatcher } from '@common/dispatchers/content.dispatcher';
import { CartItemKeys } from '@config/keys/cart.keys';
import { ProductKeys } from '@config/keys/product.keys';

import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { ICartItem } from '@core/models/cart.model';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { DoodProductModel } from '@core/models/product.model';
import { CartService } from '@core/services/cart/cart.service';
import { ComoService } from '@core/services/como/como.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { ComoProductsSelectionStep } from '@shared/modals/como/como-products-selection-modal/como-products-selection-step-model';
import { ProductAddModalComponent } from '@shared/modals/product-add-modal/product-add-modal.component';
import { takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-kiosk-como-step-atom',
  templateUrl: './kiosk-como-step-atom.component.html',
})
export class KioskComoStepAtomComponent extends DestroyerBase {
  @Input() asset: any;
  @Input() backgroundColor?: IContentBuilderFieldColor;
  @Input() textColor?: IContentBuilderFieldColor;
  @Input() buttonBackgroundColor?: IContentBuilderFieldColor;
  @Input() buttonTextColor?: IContentBuilderFieldColor;
  @Input() step!: ComoProductsSelectionStep;

  @Output() changeBufferProduct = new EventEmitter<{
    product: ICartItem;
    deleteProduct: boolean;
  }>();

  isProductsLoading = true;
  products: DoodProductModel[] = [];
  selectedProducts: { [key: string]: number } = {};
  errorMessage = '';

  constructor(
    private readonly comoService: ComoService,
    private readonly cartService: CartService,
    private readonly contentDispatcher: ContentStoreDispatcher,
    private readonly modalsService: ModalsService,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['step']) {
      this.products = [];
      this.isProductsLoading = true;
      const shopId = this.cartService.getShopId();
      this.comoService
        .allowedProducts$(this.step.ids, shopId)
        .pipe(
          takeUntil(this._destroyerRef),
          tap(products => this.displayProducts(products)),
        )
        .subscribe();
    }
  }

  private displayProducts(products: DoodProductModel[]): void {
    this.products = products;
    this.isProductsLoading = false;
  }

  changeQuantity(product: DoodProductModel, quantity: number): void {
    product.quantity = quantity;
    if (product.quantity && product.quantity > 0) {
      if (product.additions?.products?.length > 0) {
        if (product.quantity > (this.selectedProducts[product.id] ?? 0)) {
          this.contentDispatcher.updateProduct({
            shopIsNotActive: true,
            productId: product.id,
            shopId: product.shop_id,
          });
          ProductAddModalComponent.allowAddToCart = true;
          ProductAddModalComponent.onAdd = (): void => {
            ProductAddModalComponent.onAdd = (): void => {};
          };
          ProductAddModalComponent.onClose = (): void => {
            ProductAddModalComponent.onClose = (): void => {};
          };

          this.modalsService.open(ProductAddModalComponent.handle, undefined, {
            displayPrice: false,
            maxProduct: this.getMaxProduct(product),
            backgroundColor: this.backgroundColor,
            textColor: this.textColor,
            buttonBackgroundColor: this.buttonBackgroundColor,
            buttonTextColor: this.buttonTextColor,
            replaceAddToCart: (productWithOptions: ICartItem) => {
              this.changeBufferProduct.emit({ product: productWithOptions, deleteProduct: false });
              this.selectedProducts[productWithOptions.item_id] =
                (this.selectedProducts[productWithOptions.item_id] ?? 0) +
                productWithOptions.quantity;
              this.checkValidity();
            },
          });
        } else {
          this.changeBufferProduct.emit({
            product: this.createCartItemFromProduct(product),
            deleteProduct: true,
          });
          this.selectedProducts[product.id] = this.selectedProducts[product.id] - 1;
        }
      } else {
        if (product.quantity > (this.selectedProducts[product.id] ?? 0)) {
          this.changeBufferProduct.emit({
            product: this.createCartItemFromProduct(product),
            deleteProduct: false,
          });
        } else {
          this.changeBufferProduct.emit({
            product: this.createCartItemFromProduct(product),
            deleteProduct: true,
          });
        }
        this.selectedProducts[product.id] = product.quantity ?? 0;
        this.checkValidity();
      }
    } else {
      this.removeProduct(product);
      this.changeBufferProduct.emit({
        product: this.createCartItemFromProduct(product),
        deleteProduct: true,
      });
    }
  }

  createCartItemFromProduct(product: DoodProductModel) {
    const cartItem: Partial<ICartItem> = {
      [CartItemKeys.ItemId]: product?.id,
      [CartItemKeys.ShopId]: product?.shop_id,
      [CartItemKeys.Additions]: [],
      [CartItemKeys.Quantity]: product.quantity,
    };

    return cartItem as ICartItem;
  }

  removeProduct(product: DoodProductModel): void {
    if (this.selectedProducts[product.id] && this.selectedProducts[product.id] > 0) {
      this.selectedProducts[product.id] = (this.selectedProducts[product.id] ?? 0) - 1;
    }
  }

  getQuantityIfSelected(product: DoodProductModel) {
    return this.selectedProducts[product[ProductKeys.Id]];
  }

  checkValidity() {
    let nbProductSelected = Object.values(this.selectedProducts).reduce(
      (acc, value) => acc + value,
      0,
    );

    if (
      this.step.conditions &&
      (nbProductSelected > this.step.conditions.quantity.maximum ||
        nbProductSelected < this.step.conditions.quantity.minimum)
    ) {
      this.errorMessage = 'Nombre de produits sélectionné incorrect.';
      return false;
    }
    this.errorMessage = '';
    return true;
  }

  isMonoSelection() {
    if (this.step.conditions?.quantity.maximum === 1) {
      return true;
    }
    return false;
  }

  getMaxProduct({ id }: DoodProductModel) {
    let max = this.step.conditions?.quantity.maximum;

    let nbProductSelected = Object.values(this.selectedProducts).reduce(
      (acc, value) => acc + value,
      0,
    );

    nbProductSelected = nbProductSelected - (this.selectedProducts[id] ?? 0);

    if (!max || !nbProductSelected) {
      return max;
    }
    return max - nbProductSelected;
  }

  forceType(data: any): any {
    return data as any;
  }
}
