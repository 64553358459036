import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationHistoryService } from '@core/services/navigation-history/navigation-history.service';
import {
  IContentBuilderFieldColor,
  IContentBuilderFieldImage,
} from '@core/models/content-builder-fields.model';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-generic-page',
  templateUrl: './generic-page.component.html',
  styleUrls: ['./generic-page.component.scss'],
})
export class GenericPageComponent implements OnInit {
  @Input() body: any;
  @Input() footer: any;

  @Input() backgroundColor?: IContentBuilderFieldColor;
  @Input() backgroundImage?: IContentBuilderFieldImage;
  @Input() textColor?: IContentBuilderFieldColor;

  @Input() kioskDisplay = false;

  @Input() metaDescription?: string;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly navigationHistoryService: NavigationHistoryService,
    private el: ElementRef,
    private readonly meta: Meta,
  ) {}

  ngOnInit(): void {
    this.navigationHistoryService.pushStateFromActivatedRouteSnapshot(
      '/' + this.activatedRoute.snapshot.url.join('/'),
      this.activatedRoute.snapshot,
    );

    if (this.textColor?.value) {
      this.setTextOverrideColor();
    }
    if (this.metaDescription) {
      this.meta.updateTag({
        name: 'description',
        content: this.metaDescription,
      });
    }
  }

  setTextOverrideColor(): void {
    const textColor = this.textColor?.value;
    this.el.nativeElement.style.setProperty('--my-class-bg-color', textColor);
  }
}
