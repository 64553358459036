<div
  *ngIf="comoUser"
  class="m-5 flex flex-col rounded-lg border-4 border-solid p-5"
  [style.backgroundColor]="backgroundColor | contentBuilderColor"
  [style.color]="textColor | contentBuilderColor"
  [style.borderColor]="secondaryButtonBackgroundColor | contentBuilderColor"
>
  <div class="mb-5">
    <div
      class="flex flex-col gap-2"
      *ngIf="heading || subheading"
    >
      <div class="flex flex-row justify-between">
        <div>
          <div
            class="font-accent text-4xl font-bold"
            *ngIf="heading"
          >
            {{ heading | translate }}
          </div>
          <div
            class="px-5 font-base text-xl"
            *ngIf="subheading"
          >
            {{ subheading }}
          </div>
        </div>
        <div class="flex flex-row">
          <app-kiosk-active-button-atom
            class="mr-5 flex"
            [activeBackground]="backgroundColor"
            [activeTextColor]="textColor"
            [inactiveBackground]="textColor"
            secondaryTextColor="#FFFFFF"
            label=""
            (clickEmitter)="scrollLeft()"
          >
            <i class="icon icon-arrow-left text-md"></i>
          </app-kiosk-active-button-atom>
          <app-kiosk-active-button-atom
            class="mr-5 flex"
            [activeBackground]="backgroundColor"
            [activeTextColor]="textColor"
            [inactiveBackground]="textColor"
            secondaryTextColor="#FFFFFF"
            label=""
            (clickEmitter)="scrollRight()"
          >
            <i class="icon icon-arrow-right text-md"></i>
          </app-kiosk-active-button-atom>
        </div>
      </div>
    </div>
  </div>
  <div
    class="flex flex-row gap-3 overflow-x-auto scroll-smooth scrollbar-hide"
    #loyaltyCarousel
  >
    <ng-container *ngIf="displayRedeemableGifts">
      <div
        class="mr-3 w-40 max-w-40 flex-shrink-0 flex-grow-0"
        *ngFor="let asset of redeemableAssets"
      >
        <app-kiosk-como-item-atom
          [asset]="asset"
          [displayAddButton]="true"
          [isAssetInCart]="(isAssetInCart$(asset) | async) ?? false"
          (assetAdd)="onAssetAdd(asset)"
          (assetRemove)="onAssetRemove(asset)"
        ></app-kiosk-como-item-atom>
      </div>
    </ng-container>
    <ng-container *ngIf="displayRedeemablePointShop">
      <div
        class="mr-3 w-40 max-w-40 flex-shrink-0 flex-grow-0"
        *ngFor="let asset of redeemablePointShops"
      >
        <app-kiosk-como-item-atom
          [asset]="asset"
          [isAssetInCart]="(isAssetInCart$(asset) | async) ?? false"
          (assetAdd)="onAssetAdd(asset)"
          (assetRemove)="onAssetRemove(asset)"
        ></app-kiosk-como-item-atom>
      </div>
    </ng-container>
    <ng-container *ngIf="displayNotRedeemableGifts">
      <div
        class="mr-3 w-40 max-w-40 flex-shrink-0 flex-grow-0"
        *ngFor="let asset of noRedeemableAssets"
      >
        <app-kiosk-como-item-atom
          [asset]="asset"
          [isAssetInCart]="(isAssetInCart$(asset) | async) ?? false"
          (assetAdd)="onAssetAdd(asset)"
          (assetRemove)="onAssetRemove(asset)"
        ></app-kiosk-como-item-atom>
      </div>
    </ng-container>
    <ng-container *ngIf="displayNotRedeemablePointShop">
      <div
        class="mr-3 w-40 max-w-40 flex-shrink-0 flex-grow-0"
        *ngFor="let asset of noRedeemablePointShops"
      >
        <app-kiosk-como-item-atom
          [asset]="asset"
          [isAssetInCart]="(isAssetInCart$(asset) | async) ?? false"
          (assetAdd)="onAssetAdd(asset)"
          (assetRemove)="onAssetRemove(asset)"
        ></app-kiosk-como-item-atom>
      </div>
    </ng-container>
    <ng-container *ngIf="displayNotUsablePointShop">
      <div
        class="mr-3 w-40 max-w-40 flex-shrink-0 flex-grow-0"
        *ngFor="let asset of pointShops"
      >
        <app-kiosk-como-item-atom
          [asset]="asset"
          (assetAdd)="onAssetAdd(asset)"
          (assetRemove)="onAssetRemove(asset)"
        ></app-kiosk-como-item-atom>
      </div>
    </ng-container>
  </div>
</div>
<!--<div-->
<!--  *ngIf="!comoUser"-->
<!--  class="m-5 rounded-lg border-4 border-solid"-->
<!--  [style.backgroundColor]="backgroundColor | contentBuilderColor"-->
<!--  [style.color]="textColor | contentBuilderColor"-->
<!--  [style.borderColor]="secondaryButtonBackgroundColor | contentBuilderColor"-->
<!--&gt;-->
<!--  <div class="mx-5 mt-5 text-4xl">-->
<!--    {{ connexionHeading }}-->
<!--  </div>-->
<!--  <app-kiosk-loyalty-connection-block-->
<!--    [textColor]="textColor"-->
<!--    [backgroundColor]="backgroundColor"-->
<!--    [cardsColor]="primaryButtonBackgroundColor"-->
<!--    [standalone]="true"-->
<!--    [firstCardLink]="linkConnexion"-->
<!--    [firstCardLabel]="connexionCardLabel"-->
<!--  ></app-kiosk-loyalty-connection-block>-->
<!--</div>-->
