<app-account-hero-atom
  class="w-full"
  [name]="fullName"
  [email]="personalInfoForm.getRawValue()[UserKeys.Email]"
>
</app-account-hero-atom>
<div class="px-5 py-6 md:flex md:flex-col md:items-center">
  <div class="md:w-656px md:flex-auto">
    <h2 class="mb-6 font-accent text-2xl font-bold md:mb-8 md:text-3xs">
      {{ 'account.my-account' | translate }}
    </h2>

    <form [formGroup]="personalInfoForm">
      <h3 class="text-lg font-bold text-neutral-800">
        {{ 'account.personal-informations' | translate }}
      </h3>
      <div class="flex flex-col py-1">
        <label
          for="email"
          class="py-2 text-neutral-600"
          >Email</label
        >
        <input
          id="email"
          class="rounded border border-neutral-300 bg-neutral-50 px-4.5 py-5 text-neutral-400"
          type="email"
          name="email"
          autocomplete="email"
          [formControlName]="UserKeys.Email"
        />
      </div>
      <div class="flex flex-col py-1">
        <label
          for="firstname"
          class="py-2 text-neutral-600"
          >{{ 'account.firstname' | translate }}</label
        >
        <input
          id="firstname"
          class="rounded border border-neutral-300 bg-neutral-50 px-4.5 py-5 text-neutral-800"
          type="text"
          autocomplete="given-name"
          [formControlName]="UserKeys.FirstName"
        />
      </div>
      <div class="flex flex-col py-1">
        <label
          for="lastname"
          class="py-2 text-neutral-600"
          >{{ 'account.name' | translate }}</label
        >
        <input
          id="lastname"
          autocomplete="family-name"
          class="rounded border border-neutral-300 bg-neutral-50 px-4.5 py-5 text-neutral-800"
          type="text"
          [formControlName]="UserKeys.LastName"
        />
      </div>
      <div class="flex flex-col py-1">
        <label
          for="phone"
          class="py-2 text-neutral-600"
          >{{ 'account.phone' | translate }}</label
        >
        <app-input-country-phone
          [selectedIso]="
            (marketplaceCountry$ | async)
              ? ((marketplaceCountry$ | async) ?? '')
              : countryIso.France
          "
          [phone]="personalInfoForm.getRawValue()[UserKeys.Phone]"
          (updatePhone)="onUpdatePhone($event)"
        ></app-input-country-phone>
      </div>
      <div class="flex flex-col py-1">
        <label class="py-2 text-neutral-600">{{ 'account.preferred-locale' | translate }}</label>

        <select
          class="rounded border border-neutral-300 bg-neutral-50 px-4.5 py-5 text-neutral-800"
          [formControlName]="UserKeys.PreferredLocales"
          autocomplete="language"
        >
          <option
            *ngFor="let language of preferredLanguages"
            [ngValue]="language.countryCode"
          >
            {{ language.name }}
          </option>
        </select>
      </div>
      <div class="mb-12 flex justify-end pr-5 pt-9">
        <app-button-atom
          (click)="submitPersonalInfo()"
          [label]="'account.validate' | translate"
          size="M"
          type="primary"
          [disabled]="isPersonalInfoUpdateInProgress"
        ></app-button-atom>
      </div>
    </form>

    <form [formGroup]="resetPasswordForm">
      <h3 class="text-lg font-bold text-neutral-800">
        {{ 'account.update-password' | translate }}
      </h3>
      <div class="flex flex-col py-1">
        <label
          for="password"
          class="py-2 text-neutral-600"
          >{{ 'account.actual-password' | translate }}</label
        >
        <input
          id="password"
          class="rounded border border-neutral-300 bg-neutral-50 px-4.5 py-5 text-neutral-800"
          type="password"
          autocomplete="current-password"
          formControlName="password"
        />
      </div>
      <div class="flex flex-col py-1">
        <label
          for="newPassword"
          class="py-2 text-neutral-600"
          >{{ 'account.new-password' | translate }}</label
        >
        <input
          id="newPassword"
          autocomplete="new-password"
          class="rounded border border-neutral-300 bg-neutral-50 px-4.5 py-5 text-neutral-800"
          type="password"
          formControlName="newPassword"
        />
      </div>
      <div class="flex flex-col py-1">
        <label
          for="confirmPassword"
          class="py-2 text-neutral-600"
          >{{ 'account.confirm-password' | translate }}</label
        >
        <input
          id="confirmPassword"
          class="rounded border border-neutral-300 bg-neutral-50 px-4.5 py-5 text-neutral-800"
          type="password"
          autocomplete="new-password"
          formControlName="confirmPassword"
        />
      </div>
      <div class="mb-12 flex justify-end pr-5 pt-9">
        <app-button-atom
          (click)="resetPassword()"
          label="{{ 'account.validate' | translate }}"
          size="M"
          type="primary"
        ></app-button-atom>
      </div>
    </form>
    <div class="mb-12 flex justify-end pr-5 pt-9">
      <a
        *ngIf="!isDeleteUserRequested"
        class="cursor-pointer font-bold text-red-500"
        (click)="deleteAccount()"
        >{{ 'account.delete-account' | translate }}</a
      >
      <p
        *ngIf="isDeleteUserRequested"
        class="cursor-pointer font-bold text-green-500"
      >
        {{ 'account.delete-account-requested' | translate }}
      </p>
    </div>
  </div>

  <div class="bg-neutral-100 p-5 font-accent text-neutral-800">
    {{ 'account.legal-data.part-1' | translate }} <br /><br />
    {{ 'account.legal-data.part-2' | translate }}
    <a
      class="underline"
      target="_blank"
      href="https://www.dood.com/corporate/politique-de-confidentialite/"
      >{{ 'account.legal-data.link' | translate }}</a
    >.
  </div>
</div>
