import { Observable, Subject } from 'rxjs';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { catchError, map, skipWhile, take, takeUntil, tap } from 'rxjs/operators';

import { UserService } from '@core/services/user/user.service';
import { PongoService } from '@core/services/pongo/pongo.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { AuthFirebaseService } from '@core/services/api/auth-firebase/auth-firebase.service';

import { UserKeys } from '@config/keys/user.keys';
import { PatternUtil } from '@shared/utils/pattern/pattern.utils';
import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { DoodUserModel } from '@store/authentication/authentication.model';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';
import { ModalScrollBlockBase } from '@core/base/modalScrollBlock/modal-scroll-block.base';
import { AuthenticationModalComponent } from '@shared/modals/authentication-modal/authentication-modal.component';

@Component({
  selector: 'app-pongo-create-or-link-account-modal',
  templateUrl: './pongo-create-or-link-account-modal.component.html',
})
export class PongoCreateOrLinkAccountModalComponent
  extends ModalScrollBlockBase
  implements OnInit, OnDestroy
{
  private _destroyerRef = new Subject<boolean>();

  static handle = 'pongo-create-or-link-account-modal';

  @Input() modalHeading = 'pongo.join-modal-heading';

  isDesktop$ = this.settingsSelector.selectDeviceIsDesktopScreen;

  index$: Observable<number> = this.modalSelector
    .selectModal(PongoCreateOrLinkAccountModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  isLoading = true;
  accountExists = false;
  isValidating = false;
  phoneNumberDefinedByUser = true;
  phoneErrorMessage?: string;
  errorMessage?: string;
  form: UntypedFormGroup;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private authSelector: AuthStoreSelector,
    private readonly userService: UserService,
    private modalSelector: ModalStoreSelector,
    private readonly pongoService: PongoService,
    private readonly modalsService: ModalsService,
    private settingsSelector: SettingsStoreSelector,
    private readonly authFirebaseService: AuthFirebaseService,
  ) {
    super();
    this.form = this.fb.group({});

    this.authSelector.selectUser
      .pipe(
        takeUntil(this._destroyerRef),
        skipWhile(user => !user),
        tap(user => user && this.updateForm(user)),
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.authFirebaseService
      .isAuthenticate$()
      .pipe(
        take(1),
        map(v => !!v),
        tap(connected => {
          if (!connected) {
            this.modalsService.close(PongoCreateOrLinkAccountModalComponent.handle);
            this.modalsService.open(AuthenticationModalComponent.handle);
            return;
          } else {
            this.checkIfAccountAlreadyExists();
          }
        }),
      )
      .subscribe();
  }

  updateForm(user: DoodUserModel): void {
    const phoneNumber = user[UserKeys.Phone];

    let phoneValidators = [Validators.pattern(PatternUtil.phone)];

    if (phoneNumber) {
      phoneValidators.push(Validators.required);
    }

    this.form = this.fb.group({
      [UserKeys.Phone]: ['', phoneValidators],
      optin: ['', []],
    });

    this.form.setValue({
      [UserKeys.Phone]: phoneNumber ?? '',
      optin: false,
    });

    if (this.form.invalid || !phoneNumber) {
      this.phoneNumberDefinedByUser = false;
    }
  }

  ngOnDestroy(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
    super.ngOnDestroy();
  }

  close(): void {
    this.modalsService.close(PongoCreateOrLinkAccountModalComponent.handle);
  }

  private checkIfAccountAlreadyExists(): void {
    this.pongoService
      .isUserPongoCustomerEnabledOnMarketplaceLevel$()
      .pipe(
        takeUntil(this._destroyerRef),
        tap(accountExists => {
          if (!accountExists) {
            this.isLoading = false;
            this.accountExists = false;
          } else {
            this.checkIfAccountStillExistsOnPongo();
          }
        }),
      )
      .subscribe();
  }

  private checkIfAccountStillExistsOnPongo(): void {
    this.pongoService
      .getCustomer$()
      .pipe(
        takeUntil(this._destroyerRef),
        tap(customer => {
          this.isLoading = false;
          this.accountExists = true;
        }),
        catchError(error => {
          this.isLoading = false;
          this.accountExists = false;
          return error;
        }),
      )
      .subscribe();
  }

  attachCustomer(): void {
    if (this.form.invalid || this.isValidating) {
      return;
    }
    this.isValidating = true;
    this.phoneErrorMessage = undefined;
    this.errorMessage = undefined;

    this.pongoService
      .attachCustomer$(this.form.value.phone)
      .pipe(
        tap(user => this.userService.setUser(user)),
        tap(() => this.close()),
      )
      .subscribe({
        error: error => this.handleAttachErrorResponse(error.error),
        complete: () => (this.isValidating = false),
      });
  }

  private handleAttachErrorResponse(error: any): void {
    this.isValidating = false;
    switch (error.code) {
      case 202:
        this.createCustomer();
        break;
      default:
        this.errorMessage = 'pongo.errors.generic-error';
    }
  }

  createCustomer(): void {
    if (this.form.invalid || this.isValidating) {
      return;
    }
    this.isValidating = true;
    this.errorMessage = undefined;
    this.phoneErrorMessage = undefined;

    let phoneNumber = this.form.value[UserKeys.Phone];
    let hasOptin = this.form.value['optin'];
    this.pongoService
      .createCustomer$(phoneNumber, hasOptin)
      .pipe(
        tap(user => this.userService.setUser(user)),
        tap(() => this.close()),
      )
      .subscribe({
        error: error => this.handleErrorResponse(error.error),
        complete: () => (this.isValidating = false),
      });
  }

  private handleErrorResponse(error: any): void {
    this.isValidating = false;
    switch (error.code) {
      case 103:
        this.phoneErrorMessage = 'pongo.create-account.errors.account-already-exists';
        break;
      case 100:
        this.phoneErrorMessage = 'signup.invalid-phone';
        break;
      default:
        this.errorMessage = 'pongo.errors.generic-error';
    }
  }
}
