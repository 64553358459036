<div
  class="flex justify-between h-full p-5 sm:px-10 sm:pt-10 sm:pb-12 rounded-lg"
  [style.background-color]="backgroundColor | contentBuilderColor: 'primary-600'"
>
  <div
    [class.pr-4]="imageUrl"
    class="flex flex-col"
  >
    <p
      *ngIf="heading"
      class="pb-6 font-accent font-bold leading-tight sm:text-4.5xl text-1xsl text-white"
    >
      {{ heading }}
    </p>
    <p
      *ngIf="text"
      class="pb-5 text-base text-white text-lg leading-tight hidden sm:block flex-1"
    >
      <app-text-atom [content]="text"></app-text-atom>
    </p>
    <app-button-atom
      *ngIf="buttonLabel"
      [type]="'outlined'"
      [size]="'S'"
      [label]="buttonLabel"
      (clickEventEmitter)="buttonClick.emit($event)"
    ></app-button-atom>
  </div>
  <div
    *ngIf="imageUrl"
    class="self-end w-16 sm:w-32 h-16 sm:h-32 flex-shrink-0"
  >
    <img
      [src]="imageUrl"
      class="w-full h-full"
      alt=""
    />
  </div>
</div>
