import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DoodShopModel } from '@core/models/shop.model';
import { ShopKeys } from '@config/keys/shop.keys';
import { ProductKeys } from '@config/keys/product.keys';
import { ShopsService } from '@core/services/shops/shops.service';

@Component({
  selector: 'app-horizontal-push-shops-list-atom',
  templateUrl: './horizontal-push-shops-list-atom.component.html',
  styleUrls: ['./horizontal-push-shops-list-atom.component.scss'],
})
export class HorizontalPushShopsListAtomComponent {
  @Input() title!: string;
  @Input() shops!: DoodShopModel[];
  @Input() showAll!: boolean;

  hidePrevBtn!: boolean;
  hideNextBtn!: boolean;

  get ShopKeys(): typeof ShopKeys {
    return ShopKeys;
  }

  get ProductKeys(): typeof ProductKeys {
    return ProductKeys;
  }

  page = 1;

  @Output() favoriteChange = new EventEmitter<boolean>();
  @Output() clickCard = new EventEmitter();

  constructor(public readonly shopsService: ShopsService) {}

  nextPage(): void {
    this.page++;
    this.checkBtns();
    this.scroll();
  }

  prevPage(): void {
    this.page--;
    this.checkBtns();
    this.scroll(true);
  }

  scroll(back = false): void {
    const slidesLength = Math.ceil(this.shops.length / 4);
    let itemIndex;
    if (this.page === slidesLength) {
      itemIndex = this.shops.length;
    } else {
      itemIndex = this.page * 4;
    }
    itemIndex = back ? itemIndex - 3 : itemIndex;
    const el = document.getElementById('slide_' + itemIndex);
    el?.scrollIntoView({ behavior: 'smooth' });
  }

  checkBtns(): void {
    this.hideNextBtn = this.shops.length <= this.page * 4;
    this.hidePrevBtn = this.page === 1;
  }

  forceType(data: any): any {
    return data as any;
  }
}
