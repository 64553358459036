import { Component } from '@angular/core';
import { PaymentMethodHandles } from '@config/payment-methods.config';

@Component({
  selector: 'app-payment-panel-stripe-twint',
  template: `
    <app-payment-panel-stripe-generic
      [type]="paymentMethodHandles.StripeTwint"
      [isPayButtonVisible]="true"
      payButtonText="payment.pay-with-twint"
    ></app-payment-panel-stripe-generic>
  `,
})
export class PaymentPanelStripeTwintComponent {
  paymentMethodHandles = PaymentMethodHandles;
}
