import { Component, Input } from '@angular/core';

import { UserKeys } from '@config/keys/user.keys';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-user-information-cart-atom',
  templateUrl: './user-information-cart-atom.component.html',
})
export class UserInformationCartAtomComponent {
  @Input() value!: string;
  @Input() instructions?: string;
  @Input() text!: string;
  @Input() icon!: string;
  @Input() error = false;
  @Input() errorText!: string;
  @Input() headingColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() textColor: IContentBuilderFieldColor = {
    value: 'neutral-600',
    type: ColorFieldTypesValues.Palette,
  };

  userKeys = UserKeys;
}
