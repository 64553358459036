<div
  [style.backgroundColor]="$any(backgroundColor) | contentBuilderColor"
  [ngClass]="{ 'pb-12 mt-12': templateLayout === templateLayoutKeys.HeroLayout }"
>
  <div
    class="px-5 md:mx-auto kiosk:mt-5"
    [ngClass]="{
      'pb-2': bottomMargin === 'small',
      'pb-10': bottomMargin === 'medium',
      'pb-16': bottomMargin === 'large',
      'md:container': templateLayout === templateLayoutKeys.DefaultLayout,
      'max-w-screen-4xl md:px-6 md:py-6 lg:px-12': templateLayout === templateLayoutKeys.HeroLayout,
    }"
    [style.backgroundColor]="$any(backgroundColor) | contentBuilderColor"
  >
    <div
      class="flex flex-col"
      [ngClass]="{
        'pt-6 pb-4': templateLayout === templateLayoutKeys.DefaultLayout,
        'pt-24 pb-12': templateLayout === templateLayoutKeys.HeroLayout,
      }"
      *ngIf="title"
    >
      <h2
        class="font-brand text-base text-neutral-900"
        [ngClass]="{
          'text-xl lg:text-2xl': titleSize === 'XS',
          'text-2xl lg:text-3xl': titleSize === 'S',
          'text-3xl lg:text-4xl': titleSize === 'M',
          'text-4xl lg:text-5xl': titleSize === 'L',
          'font-bold': templateLayout === templateLayoutKeys.HeroLayout,
        }"
      >
        {{ title }}
      </h2>
    </div>

    <div
      class="grid gap-4 sm:grid-cols-2 sm:gap-x-4 md:gap-x-5 lg:grid-cols-3 lg:gap-5 xl:grid-cols-4 xl:gap-6 kiosk:grid-cols-2"
      [ngClass]="{
        'grid-cols-1': layout === 'col-1',
        'grid-cols-2': layout === 'col-2',
        'kiosk:grid-cols-1': kioskLayout === 'col-1',
        'kiosk:grid-cols-2': kioskLayout === 'col-2',
        'kiosk:grid-cols-3': kioskLayout === 'col-3',
        'kiosk:grid-cols-4': kioskLayout === 'col-4',
        'kiosk:grid-cols-5': kioskLayout === 'col-5',
        'kiosk:grid-cols-6': kioskLayout === 'col-6',
      }"
    >
      <ng-container *ngIf="!isLoading; else loading">
        <app-block-list
          [blockIdList]="shops"
          [injectData]="{
            displayOpenedShop: true,
            templateLayout: templateLayout,
            headingColor: headingColor,
            displayClosedShop: false,
            kioskCardAspectRatio,
          }"
        ></app-block-list>
        <app-block-list
          [blockIdList]="shops"
          [injectData]="{
            displayOpenedShop: false,
            templateLayout: templateLayout,
            headingColor: headingColor,
            displayClosedShop: true,
            kioskCardAspectRatio,
          }"
        ></app-block-list>
      </ng-container>

      <ng-template #loading>
        <div
          class="max-w-lg"
          *ngFor="let i of [].constructor(30)"
        >
          <div
            class="relative mb-2 h-28 animate-pulse overflow-hidden rounded-lg bg-neutral-100"
          ></div>
          <div class="w-6/12">
            <p class="mb-1.5 animate-pulse bg-neutral-100 py-1.5"></p>
            <p class="animate-pulse bg-neutral-50 py-1"></p>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
