import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DoodProductModel, IProductLabel } from '@core/models/product.model';
import { ProductKeys } from '@config/keys/product.keys';
import { ProductService } from '@core/services/product/product.service';

@Component({
  selector: 'app-product-push-atom',
  templateUrl: './product-push-atom.component.html',
  styleUrls: ['./product-push-atom.component.scss'],
})
export class ProductPushAtomComponent {
  @Input() product!: DoodProductModel;
  @Output() buttonClick = new EventEmitter<DoodProductModel>();

  constructor(private readonly productService: ProductService) {}

  public get ProductKeys(): typeof ProductKeys {
    return ProductKeys;
  }

  goToProduct(product: DoodProductModel): void {
    if (this.isAvailable(product)) {
      this.buttonClick.emit(product);
    }
  }

  visibleLabels(labels: IProductLabel[] | undefined): IProductLabel[] {
    if (!labels) {
      return [];
    }

    return labels.filter(label => label.visible);
  }

  isAvailable(product: DoodProductModel): boolean {
    return this.productService.isAvailable(product);
  }

  forceType(data: any): any {
    return data as any;
  }
}
