import { ReducerTypes, ActionCreator, on } from '@ngrx/store';

import { DoodProductModel } from '@core/models/product.model';
import { createActiveStorePiece } from '@store/_abstract/active';

import {
  ProductState,
  PRODUCT_STORE_INITIAL_STATE,
  PRODUCT_STORE_ACTIVE_ADAPTER,
} from './product.state';
import { PRODUCT_STORE_ACTIVE_ACTIONS, PRODUCT_STORE_LOCAL_ACTIONS } from './product.action';

export const PRODUCT_STORE_API_PIECE: ReducerTypes<ProductState, readonly ActionCreator[]>[] = [];

export const PRODUCT_STORE_LOCAL_PIECE: ReducerTypes<ProductState, readonly ActionCreator[]>[] = [
  // Status
  on(PRODUCT_STORE_LOCAL_ACTIONS.updateIsLoaded, (state, { isLoaded }): ProductState => {
    return { ...state, isLoaded };
  }),
  on(PRODUCT_STORE_LOCAL_ACTIONS.updateIsLoading, (state, { isLoading }): ProductState => {
    return { ...state, isLoading };
  }),
  // Set Active
  on(PRODUCT_STORE_ACTIVE_ACTIONS.setActiveCategory, (state, { activeCategory }): ProductState => {
    return { ...state, activeCategory };
  }),
  // Error
  on(PRODUCT_STORE_LOCAL_ACTIONS.updateError, (state, { error }): ProductState => {
    return { ...state, error };
  }),
  on(PRODUCT_STORE_LOCAL_ACTIONS.resetError, (state): ProductState => {
    return { ...state, error: null };
  }),
  // Misc
  on(PRODUCT_STORE_LOCAL_ACTIONS.reset, (): ProductState => {
    return PRODUCT_STORE_INITIAL_STATE;
  }),
];

export const PRODUCT_STORE_ACTIVE_PIECE = createActiveStorePiece<ProductState, DoodProductModel>({
  adapter: PRODUCT_STORE_ACTIVE_ADAPTER,
  actions: PRODUCT_STORE_ACTIVE_ACTIONS,
});
