import { createActionGroup, emptyProps, props } from '@ngrx/store';

import {
  PaymentAtTableCheckState,
  PaymentAtTableChooseState,
  PaymentAtTableState,
} from './payment-at-table.state';
import { DoodPaymentAtTableAmountType } from './payment-at-table.enum';

export const PAT_STORE_API_ACTIONS = createActionGroup({
  source: 'Dood/API/PaymentAtTable',
  events: {},
});

export const PAT_STORE_LOCAL_ACTIONS = createActionGroup({
  source: 'Dood/LOCAL/PaymentAtTable',
  events: {
    // Check
    'Update Check': props<{ check: Partial<PaymentAtTableCheckState> }>(),
    'Reset Check': emptyProps(),
    // --

    // Choose
    // -- Amount
    'Update Amount': props<{ amount: number }>(),
    'Reset Amount': emptyProps(),
    // -- Tip
    'Update Tip': props<{ tip: number | null }>(),
    'Reset Tip': emptyProps(),
    // -- Products
    'Set Products': props<{ products: string[] }>(),
    'Add Products': props<{ products: string[] }>(),
    'Remove Products': props<{ products: string[] }>(),
    'Reset Products': emptyProps(),
    // -- Parts
    'Update Parts': props<{ parts: number | null }>(),
    'Reset Parts': emptyProps(),
    // -- Type
    'Update Type': props<{ value: DoodPaymentAtTableAmountType }>(),
    'Reset Type': emptyProps(),
    // -- Error
    'Update Error': props<{ error: string | null }>(),
    'Reset Error': emptyProps(),

    // Misc
    'reset': emptyProps(),
  },
});

export const PAT_STORE_COMPONENT_ACTIONS = createActionGroup({
  source: 'Dood/COMPONENT/PaymentAtTable',
  events: {
    // State
    'Update State': props<{ changes: Partial<PaymentAtTableState> }>(),
    // Choose
    'Update Choose': props<{ changes: Partial<PaymentAtTableChooseState> }>(),
    'Reset Choose': emptyProps(),
    // Check
    'Update Check': props<{ changes: Partial<PaymentAtTableCheckState> }>(),
    'Reset Check': emptyProps(),
  },
});
