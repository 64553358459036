import { createReducer } from '@ngrx/store';

import { DELIVERY_STORE_INITIAL_STATE } from './delivery.state';
import {
  DELIVERY_STORE_ACTIVE_PIECE,
  DELIVERY_STORE_API_PIECE,
  DELIVERY_STORE_LOCAL_PIECE,
} from './delivery.piece';

export const DELIVERY_STORE_REDUCER = createReducer(
  DELIVERY_STORE_INITIAL_STATE,
  ...DELIVERY_STORE_ACTIVE_PIECE,
  ...DELIVERY_STORE_API_PIECE,
  ...DELIVERY_STORE_LOCAL_PIECE,
);
