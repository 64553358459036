<div class="px-5 py-5 lg:px-12 kiosk:py-10">
  <p
    *ngIf="category"
    class="font-accent font-bold kiosk:text-3xl"
  >
    {{ category }}
  </p>
  <p
    class="font-brand font-extrabold py-1 kiosk:py-3"
    [ngClass]="{
      'text-2xl': titleSize === 'XS',
      'text-3xl': titleSize === 'S',
      'text-4xl kiosk:text-6xl ': titleSize === 'M',
      'text-5xl': titleSize === 'L',
    }"
  >
    {{ heading }}
  </p>
  <p
    *ngIf="description"
    class="font-accent kiosk:text-xl"
  >
    {{ description }}
  </p>
</div>
