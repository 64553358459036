import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { DoodApiError } from '@shared/interfaces/error.interface';
import { createActiveStoreActions } from '@store/_abstract/active';
import { createFunnelStoreActions } from '@store/_abstract/funnel/funnel.action';

import { CART_FUNNEL_STORE_KEY } from './cart-funnel.state';
import { DoodCartFunnelStep, DoodCartFunnelValue } from './cart-funnel.model';
import { createHydratedStoreActions } from '@store/_abstract/hydrated';

const CART_FUNNEL_STORE_ACTIVE_ACTIONS = createActiveStoreActions<string>(CART_FUNNEL_STORE_KEY);

const CART_FUNNEL_STORE_FUNNEL_ACTIONS = createFunnelStoreActions<
  DoodCartFunnelStep,
  DoodCartFunnelValue,
  string
>(CART_FUNNEL_STORE_KEY);

export const CART_FUNNEL_STORE_GENERATED_ACTIONS = {
  ...CART_FUNNEL_STORE_ACTIVE_ACTIONS,
  ...CART_FUNNEL_STORE_FUNNEL_ACTIONS,
};

export const CART_FUNNEL_STORE_API_ACTIONS = createActionGroup({
  source: 'Dood/API/CartFunnel',
  events: {
    // Steps
    'Fetch Steps': emptyProps(),
    'Fetch Steps Success': props<{ steps: DoodCartFunnelStep[] }>(),
    'Fetch Steps Failure': props<{ error: DoodApiError }>(),
    'Fetch Steps Complete': emptyProps(),

    // Values
    'Fetch Values': emptyProps(),
    'Fetch Values Success': props<{ values: DoodCartFunnelValue[] }>(),
    'Fetch Values Failure': props<{ error: DoodApiError }>(),
    'Fetch Values Complete': emptyProps(),
  },
});

export const CART_FUNNEL_STORE_LOCAL_ACTIONS = createActionGroup({
  source: 'Dood/LOCAL/CartFunnel',
  events: {
    // Step
    'Add Step': props<{ step: DoodCartFunnelStep; setAsActive?: boolean }>(),

    // Comment
    'Update Order Comment': props<{ comment: string | null }>(),
    'Reset Order Comment': emptyProps(),

    // Misc
    'reset': emptyProps(),

    'Set Funnel Username': props<{ funnelUsername: string }>(),
  },
});

export const CART_FUNNEL_STORE_HYDRATION_ACTIONS =
  createHydratedStoreActions(CART_FUNNEL_STORE_KEY);
