<ng-container *ngIf="shop$ | async as shop">
  <ng-container *ngIf="shop.available">
    <div *ngIf="filters$ | async as filters">
      <app-horizontal-filter-list-atom
        [items]="filters"
        (clickItem)="onClickFilter($event)"
      ></app-horizontal-filter-list-atom>
    </div>
  </ng-container>
</ng-container>
