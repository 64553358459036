import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { combineLatest, Observable, of, Subject, throwError } from 'rxjs';

import { Paths } from '@config/paths.config';
import { CartService } from '@core/services/cart/cart.service';
import { PushService } from '@core/services/push/push.service';
import { UserService } from '@core/services/user/user.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { BasketService } from '@core/services/basket/basket.service';
import { OrdersService } from '@core/services/orders/orders.service';

import { DoodShopModel } from '@core/models/shop.model';
import { DoodCartApiResponse } from '@core/models/cart-api.model';

import { CartStoreSelector } from '@common/selectors/cart.selector';
import { ShopStoreSelector } from '@common/selectors/shop.selector';
import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { BasketStoreSelector } from '@common/selectors/basket.selector';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';
import { BasketStoreDispatcher } from '@common/dispatchers/basket.dispatcher';

import { SlugUtils } from '@shared/utils/slug/slug.utils';
import { GroupedOrderModalsAttributes } from '@config/modals/grouped-order-modals.config';

import { AlertModalComponent } from '@shared/modals/alert-modal/alert-modal.component';
import { AuthenticationModalComponent } from '@shared/modals/authentication-modal/authentication-modal.component';
import { CartParametersModalComponent } from '@shared/modals/cart-parameters-modal/cart-parameters-modal.component';

@Component({
  selector: 'app-share-grouped-order-modal',
  templateUrl: './share-grouped-order-modal.component.html',
})
export class ShareGroupedOrderModalComponent implements OnInit {
  static handle = 'share-grouped-order-modal';

  index$ = this.modalSelector
    .selectModal(ShareGroupedOrderModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  attribute$ = this.modalSelector
    .selectModal(ShareGroupedOrderModalComponent.handle)
    .pipe(map(el => el?.attribute));

  basket$ = this.basketSelector.select;

  userId$ = this.authSelector.selectUserId;

  isAuthenticate$ = this.authSelector.selectUserIsLoggedIn;

  generatedLink!: string;

  isRequestInProgress = false;

  pushSubscription!: Subject<any>;

  constructor(
    private cartSelector: CartStoreSelector,
    private authSelector: AuthStoreSelector,
    private shopSelector: ShopStoreSelector,
    private modalSelector: ModalStoreSelector,
    private readonly userService: UserService,
    private readonly cartService: CartService,
    private readonly pushService: PushService,
    private basketSelector: BasketStoreSelector,
    private readonly basketService: BasketService,
    private readonly ordersService: OrdersService,
    private readonly modalsService: ModalsService,
    private basketDispatcher: BasketStoreDispatcher,
    readonly settingsSelector: SettingsStoreSelector,
    private readonly translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.attribute$
      .pipe(
        take(1),
        tap(attributes => {
          const basket = this.basketSelector.basket;
          if (attributes === GroupedOrderModalsAttributes.GeneratedLink && basket) {
            this.displayLinkSharing(basket);
          }
        }),
      )
      .subscribe();
  }

  closeModal(): void {
    this.modalsService.close(ShareGroupedOrderModalComponent.handle);
  }

  shareLink(): void {
    this.basket$
      .pipe(
        take(1),
        tap(basket => {
          if (basket.id && basket.shop === this.shopSelector.active?.id) {
            this.displayLinkSharing(basket);
            return;
          }

          this.createSharedBasket();
        }),
      )
      .subscribe();
  }

  private createSharedBasket(): void {
    if (this.isRequestInProgress) {
      return;
    }

    this.isRequestInProgress = true;
    this.basketService
      .createBasket$(true)
      .pipe(
        take(1),
        catchError(error => {
          this.modalsService.open(AlertModalComponent.handle);
          this.modalsService.setData(AlertModalComponent.handle, {
            message: this.translateService.instant('group-order-modal.unknown-error'),
          });
          this.isRequestInProgress = false;
          return throwError(() => error);
        }),
        switchMap(basket => {
          const id: string = basket.shop.id ? basket.shop.id : basket.shop;
          return combineLatest([of(basket), this.shopSelector.selectShop(id)]);
        }),
      )
      .subscribe(
        ([basket, shop]) => this.displayLinkSharing(basket, shop),
        () => {},
        () => (this.isRequestInProgress = false),
      );
  }

  private displayLinkSharing(basket: DoodCartApiResponse, shop?: DoodShopModel | undefined): void {
    let link = '';
    const navigator = window.navigator;

    if (shop) {
      link = this.constructShareLink(basket.id, shop, basket.share_code);
    }

    this.generatedLink = link;

    if (navigator.share) {
      navigator
        .share({
          title: 'Lien de la commande groupée',
          url: link,
        })
        .then()
        .catch();
    } else {
      this.modalsService.close(ShareGroupedOrderModalComponent.handle);
      this.modalsService.open(
        ShareGroupedOrderModalComponent.handle,
        GroupedOrderModalsAttributes.GeneratedLink,
      );
    }
  }

  private constructShareLink(cartId: string, shop: DoodShopModel, shareCode: string): string {
    const language = this.settingsSelector.app.locale;
    const domain = location.protocol + '//' + location.host;
    return `${domain}/${language}/${Paths.Shops}/${SlugUtils.formatSlug(shop.type.name)}/${shop.slug}?join_cart_id=${cartId}&share_code=${shareCode}`;
  }

  joinOrder(): void {
    this.isAuthenticate$
      .pipe(
        take(1),
        tap(val => {
          this.basketDispatcher.updateBasket({ joined: true });
          this.modalsService.close(ShareGroupedOrderModalComponent.handle);
          if (!val) {
            this.modalsService.open(AuthenticationModalComponent.handle);
          }
        }),
      )
      .subscribe();
  }

  declineInvitation(): void {
    this.modalsService.close(ShareGroupedOrderModalComponent.handle);
    this.modalsService.open(CartParametersModalComponent.handle);
  }

  leavingOrder(): void {
    this.basketSelector.select.pipe(take(1)).subscribe(({ id }) => {
      this.pushService.removeSubscription(`dood/carts/${id}`, this.pushSubscription);
    });

    this.userId$
      .pipe(
        take(1),
        switchMap(id => {
          if (id) {
            return this.basketService.removeBasketUser$(id);
          }
          return of(null);
        }),
        tap(() => {
          this.basketService.clearBasket();
          this.cartService.clearCart();
        }),
        switchMap(() => this.ordersService.checkCartIsValid$()),
      )
      .subscribe();
    this.modalsService.close(ShareGroupedOrderModalComponent.handle);
  }

  cancelOrder(): void {
    this.basketService
      .updateBasket$(false)
      .pipe(
        take(1),
        switchMap(() => this.ordersService.checkCartIsValid$()),
      )
      .subscribe(() => {
        this.basketService.clearBasket();
      });
    this.modalsService.close(ShareGroupedOrderModalComponent.handle);
  }

  clearCart(): void {
    this.isRequestInProgress = true;
    let cleanupAction: Observable<any>;

    const user = this.userService.user;
    const basket = this.basketSelector.basket;

    if (basket.user.id && basket.user.id !== user?.id) {
      cleanupAction = this.userId$.pipe(
        take(1),
        switchMap(id => {
          if (id) {
            this.basketService.removeBasketUser$(id);
          }
          return of(null);
        }),
        switchMap(() => this.ordersService.checkCartIsValid$()),
      );
    } else {
      cleanupAction = this.basketService.updateBasket$(false);
    }

    // TODO: WTF is this
    cleanupAction
      .pipe(
        tap(() => {
          this.cartService.clearCart();
          this.basketService.clearBasket();
          this.ordersService.clearOrders();
          this.modalsService.close(ShareGroupedOrderModalComponent.handle);

          window.location.href = '/';
        }),
        catchError(error => {
          this.cartService.clearCart();
          this.basketService.clearBasket();
          this.ordersService.clearOrders();
          this.modalsService.close(ShareGroupedOrderModalComponent.handle);

          window.location.href = '/';

          return throwError(() => error);
        }),
        take(1),
      )
      .subscribe({ complete: () => (this.isRequestInProgress = false) });
  }

  removeUser(): void {
    this.basketSelector.select
      .pipe(
        take(1),
        switchMap(basket => {
          return this.basketService.removeBasketUser$(basket.removeUserId as string).pipe(take(1));
        }),
      )
      .subscribe();
    this.modalsService.close(ShareGroupedOrderModalComponent.handle);
  }

  startShareOrder(): void {
    const activeCart = this.cartSelector.active;
    const isAuthenticate = !!this.userService.user?.id;
    if (activeCart?.handle && !isAuthenticate) {
      this.basketDispatcher.updateBasket({ created: true });
      this.modalsService.close(ShareGroupedOrderModalComponent.handle);
      this.modalsService.open(AuthenticationModalComponent.handle);
    }
    if (activeCart?.handle && isAuthenticate) {
      this.modalsService.close(ShareGroupedOrderModalComponent.handle);
      this.modalsService.open(
        ShareGroupedOrderModalComponent.handle,
        GroupedOrderModalsAttributes.ShareLink,
      );
    }
  }

  orderAlone(): void {
    this.basketDispatcher.updateBasket({ rejected: true });
    this.modalsService.close(ShareGroupedOrderModalComponent.handle);
  }
}
