import { of, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { UserKeys } from '@config/keys/user.keys';

import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { emailValidator } from '@shared/validators/email/email.validator';
import { DoodUserModel } from '@store/authentication/authentication.model';
import { ConfirmModalComponent } from '@shared/modals/confirm-modal/confirm-modal.component';

import { UserService } from '@core/services/user/user.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { HubspotService } from '@core/services/hubspot/hubspot.service';
import { AuthFirebaseService } from '@core/services/api/auth-firebase/auth-firebase.service';

import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

import { PREFERRED_LANGUAGES } from '@config/preferred-languages.config';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';
import { CountryISO } from '@shared/components/input-country-phone/config/country-phone.config';

@Component({
  selector: 'app-account-page-block',
  templateUrl: './account-page-block.component.html',
})
export class AccountPageBlockComponent extends DestroyerBase implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject<boolean>();

  UserKeys = UserKeys;
  personalInfoForm!: UntypedFormGroup;
  resetPasswordForm!: UntypedFormGroup;
  user: DoodUserModel | null = null;

  isPersonalInfoUpdateInProgress = false;

  fullName!: string;

  marketplaceCountry$ = this.marketplaceSelector.selectMarketplaceCountry;

  countryIso = CountryISO;

  isDeleteUserRequested = false;

  preferredLanguages = PREFERRED_LANGUAGES;

  selectedPreferredLanguage!: string[];

  get userId(): string {
    return this.user?.id ?? '';
  }

  constructor(
    private readonly fb: UntypedFormBuilder,
    private authSelector: AuthStoreSelector,
    private readonly userService: UserService,
    private readonly authFB: AuthFirebaseService,
    private marketplaceSelector: MarketplaceStoreSelector,
    private readonly hubspotService: HubspotService,
    private readonly translateService: TranslateService,
    private readonly modalsService: ModalsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initPersonalInfoForm();
    this.initResetPasswordForm();
    this.fillForms();
    this.fullName =
      this.personalInfoForm.value[UserKeys.FirstName] +
      ' ' +
      this.personalInfoForm.value[UserKeys.LastName];
  }

  fillForms(): void {
    this.authSelector.selectUser.pipe(takeUntil(this.ngUnsubscribe)).subscribe(user => {
      this.user = user;
      this.personalInfoForm.patchValue({
        [UserKeys.Email]: user?.email ?? '',
        [UserKeys.LastName]: user?.lastname ?? '',
        [UserKeys.FirstName]: user?.firstname ?? '',
        [UserKeys.Phone]: user?.phone ? user.phone : '',
      });

      if (user?.[UserKeys.PreferredLocales]) {
        this.selectedPreferredLanguage = this.getPreferredLocales(
          user?.[UserKeys.PreferredLocales]?.length
            ? (user?.[UserKeys.PreferredLocales] as string[])
            : ['fr-FR'],
          PREFERRED_LANGUAGES,
        );
        this.personalInfoForm.patchValue({
          [UserKeys.PreferredLocales]: this.selectedPreferredLanguage[1],
        });
      }
    });
  }

  initPersonalInfoForm(): void {
    this.personalInfoForm = this.fb.group({
      [UserKeys.FirstName]: ['', Validators.required],
      [UserKeys.LastName]: ['', Validators.required],
      [UserKeys.Email]: [
        {
          disabled: true,
          value: '',
        },
        [emailValidator(), Validators.required],
      ],
      [UserKeys.Password]: ['', Validators.required],
      [UserKeys.Phone]: ['', Validators.required],
      [UserKeys.PreferredLocales]: ['', Validators.required],
    });
  }

  initResetPasswordForm(): void {
    this.resetPasswordForm = this.fb.group({
      password: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });
  }

  submitPersonalInfo(): void {
    this.isPersonalInfoUpdateInProgress = true;
    const { value } = this.personalInfoForm;
    const preferredLocales = this.getPreferredLocale(
      value[UserKeys.PreferredLocales],
      PREFERRED_LANGUAGES,
    );
    this.userService
      .updateUser$(
        {
          [UserKeys.Email]: value[UserKeys.Email],
          [UserKeys.FirstName]: value[UserKeys.FirstName],
          [UserKeys.LastName]: value[UserKeys.LastName],
          [UserKeys.Phone]: value[UserKeys.Phone],
          [UserKeys.PreferredLocales]: preferredLocales,
        },
        this.userId,
      )
      .subscribe(() => {
        this.isPersonalInfoUpdateInProgress = false;
        this.fullName =
          this.personalInfoForm.value[UserKeys.FirstName] +
          ' ' +
          this.personalInfoForm.value[UserKeys.LastName];
      });
  }

  resetPassword(): void {
    const { value } = this.resetPasswordForm;
    this.authFB.changePassword(value.password, value.newPassword);
    this.resetPasswordForm.reset();
  }

  deleteAccount(): void {
    this.modalsService.open(ConfirmModalComponent.handle);
    this.modalsService.setData(ConfirmModalComponent.handle, {
      title: '',
      message: this.translateService.instant('account.are-you-sure'),
      confirmButtonText: this.translateService.instant('basket.clear-basket-confirm-modal.confirm'),
      isConfirm: false,
    });

    this.modalsService
      .getData(ConfirmModalComponent.handle)
      .pipe(
        takeUntil(this._destroyerRef),
        tap(modal => {
          if (!modal?.data?.isConfirm) {
            return;
          }

          this.authSelector.selectUser
            .pipe(
              take(1),
              switchMap(user => {
                if (user) {
                  const { email, firstname, lastname } = user;
                  const data = {
                    email,
                    firstname,
                    lastname,
                  };
                  return this.hubspotService.requestDeleteUserAccount(data);
                }
                return of(null);
              }),
            )
            .subscribe(() => {
              // TODO: Check when null received
              this.isDeleteUserRequested = true;
              this.modalsService.close(ConfirmModalComponent.handle);
            });
        }),
      )
      .subscribe();

    return;
  }

  onUpdatePhone($event: string): void {
    this.personalInfoForm?.patchValue({
      [UserKeys.Phone]: $event.replace(/\s/g, ''),
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  private getPreferredLocales(
    codes: string[],
    languages: { ietfCode: string; countryCode: string }[],
  ): string[] {
    const result: string[] = [];
    for (const lang of languages) {
      if (codes.includes(lang.ietfCode)) {
        result.push(lang.ietfCode, lang.countryCode.toUpperCase());
      }
    }
    return result;
  }

  private getPreferredLocale(
    code: string,
    languages: { ietfCode: string; countryCode: string }[],
  ): string[] {
    const result: string[] = [];
    for (const lang of languages) {
      if (code.toUpperCase() === lang.countryCode) {
        result.push(lang.ietfCode, lang.countryCode);
        break; // arrête la boucle après avoir trouvé la correspondance
      }
    }
    return result;
  }
}
