<app-modal-filler-atom
  (click)="close()"
  [index]="5"
></app-modal-filler-atom>
<div
  class="fixed mx-auto right-0 left-0 overflow-y-auto bottom-0 max-h-85-screen max-w-screen-sm md:flex md:justify-center md:items-center md:w-full md:h-full md:top-0 md:left-0'"
  [style.z-index]="750"
>
  <div
    class="bg-white p-5"
    [ngClass]="(isMobile$ | async) ? 'rounded-t-3xl' : 'rounded-3xl min-w-448'"
    [class.pb-8]="isNotched"
    [style.z-index]="((index$ | async) ?? 0) + 50"
  >
    <div class="flex justify-between items-center">
      <h1 class="text-neutral-800 font-brand font-bold text-28px pb-2">
        <span *ngIf="(data$ | async)?.title as title">
          {{ title }}
        </span>
      </h1>
      <app-rounded-icon-button
        icon="icon-cross"
        (onClick)="close()"
      ></app-rounded-icon-button>
    </div>
    <div
      *ngIf="(data$ | async)?.message as message"
      class="text-base font-accent py-6"
    >
      {{ message }}
    </div>
  </div>
</div>
