import { ReducerTypes, ActionCreator, on } from '@ngrx/store';

import { createHydratedStorePiece } from '@store/_abstract/hydrated/hydrated.piece';

import {
  AuthenticationState,
  AUTH_STORE_INITIAL_PERMISSIONS_STATE,
  AUTH_STORE_INITIAL_STATE,
  AUTH_STORE_INITIAL_STATUS_STATE,
  AUTH_STORE_KEY,
  AUTH_STORE_INITIAL_GUARD_STATE,
} from './authentication.state';
import { AUTH_STORE_HYDRATION_ACTIONS, AUTH_STORE_LOCAL_ACTIONS } from './authentication.action';

export const AUTH_STORE_API_PIECE: ReducerTypes<AuthenticationState, readonly ActionCreator[]>[] =
  [];

export const AUTH_STORE_LOCAL_PIECE: ReducerTypes<AuthenticationState, readonly ActionCreator[]>[] =
  [
    // Profile
    on(AUTH_STORE_LOCAL_ACTIONS.updateUser, (state, { user }): AuthenticationState => {
      return { ...state, user };
    }),
    on(AUTH_STORE_LOCAL_ACTIONS.resetUser, (state): AuthenticationState => {
      return { ...state, user: null };
    }),
    on(
      AUTH_STORE_LOCAL_ACTIONS.updateIsUserLoaded,
      (state, { isUserLoaded }): AuthenticationState => {
        return { ...state, isUserLoaded };
      },
    ),
    on(
      AUTH_STORE_LOCAL_ACTIONS.updateIsUserLoading,
      (state, { isUserLoading }): AuthenticationState => {
        return { ...state, isUserLoading };
      },
    ),

    // Status
    on(AUTH_STORE_LOCAL_ACTIONS.updateStatus, (state, { status }): AuthenticationState => {
      return { ...state, status: { ...state.status, ...status } };
    }),
    on(AUTH_STORE_LOCAL_ACTIONS.resetStatus, (state): AuthenticationState => {
      return { ...state, status: AUTH_STORE_INITIAL_STATUS_STATE };
    }),

    // Guard
    on(AUTH_STORE_LOCAL_ACTIONS.updateGuard, (state, { guard }): AuthenticationState => {
      return { ...state, guard: { ...state.guard, ...guard } };
    }),
    on(AUTH_STORE_LOCAL_ACTIONS.resetGuard, (state): AuthenticationState => {
      return { ...state, guard: AUTH_STORE_INITIAL_GUARD_STATE };
    }),

    // Permission
    on(
      AUTH_STORE_LOCAL_ACTIONS.updatePermissions,
      (state, { permissions }): AuthenticationState => {
        return { ...state, permissions: { ...state.permissions, ...permissions } };
      },
    ),
    on(AUTH_STORE_LOCAL_ACTIONS.resetPermissions, (state): AuthenticationState => {
      return { ...state, permissions: AUTH_STORE_INITIAL_PERMISSIONS_STATE };
    }),

    // Error
    on(AUTH_STORE_LOCAL_ACTIONS.updateError, (state, { error }): AuthenticationState => {
      return { ...state, error };
    }),
    on(AUTH_STORE_LOCAL_ACTIONS.resetError, (state): AuthenticationState => {
      return { ...state, error: null };
    }),

    // Misc
    on(AUTH_STORE_LOCAL_ACTIONS.reset, (): AuthenticationState => {
      return AUTH_STORE_INITIAL_STATE;
    }),
  ];

export const AUTH_STORE_HYDRATION_PIECE = createHydratedStorePiece<AuthenticationState>(
  AUTH_STORE_KEY,
  AUTH_STORE_HYDRATION_ACTIONS,
);
