<app-modal-filler-atom
  [index]="index + 10"
  (onClick)="closeModal($event)"
>
  <div class="flex items-end justify-center md:items-center h-full w-full">
    <div
      class="flex flex-col gap-8 p-5 bg-white rounded-t-3xl w-full md:p-7 md:rounded-3xl md:w-auto pointer-events-auto"
    >
      <!-- Header -->
      <div class="flex flex-col gap-4">
        <div class="flex justify-between items-start gap-4">
          <!-- Title -->
          <div class="text-3xl font-bold">
            {{ title ?? ('user-feedback.modal.min-value-label' | translate) }}
          </div>

          <!-- Close modal  button -->
          <app-rounded-icon-button
            size="sm"
            type="white"
            icon="icon-cross"
            (click)="closeModal($event)"
          ></app-rounded-icon-button>
        </div>

        <!-- Description -->
        <div class="text-lg">{{ description }}</div>
      </div>

      <!-- Content -->
      <app-block-list
        class="flex flex-col gap-4"
        [blockIdList]="items"
      ></app-block-list>
    </div>
  </div>
</app-modal-filler-atom>
