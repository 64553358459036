import { createFeature } from '@ngrx/store';

import { MARKETPLACE_STORE_KEY } from './marketplace.state';
import { MARKETPLACE_STORE_REDUCER } from './marketplace.reducer';

const MARKETPLACE_STORE_FEATURE = createFeature({
  name: MARKETPLACE_STORE_KEY,
  reducer: MARKETPLACE_STORE_REDUCER,
});

export const {
  selectMarketplace: SELECT_MARKETPLACE_ITEM,
  selectIsLoaded: SELECT_MARKETPLACE_IS_LOADED,
  selectIsLoading: SELECT_MARKETPLACE_IS_LOADING,
  selectMarketplaceState: SELECT_MARKETPLACE_STATE,
} = MARKETPLACE_STORE_FEATURE;
