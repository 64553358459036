import { ReducerTypes, ActionCreator, on } from '@ngrx/store';

import { createActiveStorePiece } from '@store/_abstract/active';
import { DoodDeliveryAddressModel } from '@core/models/delivery-address.model';

import {
  DeliveryState,
  DELIVERY_STORE_ACTIVE_ADAPTER,
  DELIVERY_STORE_INITIAL_STATE,
} from './delivery.state';
import { DELIVERY_STORE_ACTIVE_ACTIONS, DELIVERY_STORE_LOCAL_ACTIONS } from './delivery.action';

export const DELIVERY_STORE_API_PIECE: ReducerTypes<DeliveryState, readonly ActionCreator[]>[] = [];

export const DELIVERY_STORE_LOCAL_PIECE: ReducerTypes<DeliveryState, readonly ActionCreator[]>[] = [
  // Status
  on(DELIVERY_STORE_LOCAL_ACTIONS.updateIsLoaded, (state, { isLoaded }): DeliveryState => {
    return { ...state, isLoaded };
  }),
  on(DELIVERY_STORE_LOCAL_ACTIONS.updateIsLoading, (state, { isLoading }): DeliveryState => {
    return { ...state, isLoading };
  }),

  // Misc
  on(DELIVERY_STORE_LOCAL_ACTIONS.reset, (): DeliveryState => {
    return DELIVERY_STORE_INITIAL_STATE;
  }),
];

export const DELIVERY_STORE_ACTIVE_PIECE = createActiveStorePiece<
  DeliveryState,
  DoodDeliveryAddressModel
>({
  adapter: DELIVERY_STORE_ACTIVE_ADAPTER,
  actions: DELIVERY_STORE_ACTIVE_ACTIONS,
});
