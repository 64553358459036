import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICategory, DoodProductModel } from '@core/models/product.model';
import { CategoryKeys } from '@config/keys/category.keys';
import { ContentBuilderFieldColors } from '@shared/interfaces/content-builder.interface';

@Component({
  selector: 'app-product-category-cards-without-name-atom',
  templateUrl: './product-category-cards-without-name-atom.component.html',
})
export class ProductCategoryCardsWithoutNameAtomComponent {
  @Input() displayContext = '';
  @Input() category!: ICategory;
  @Input() products!: DoodProductModel[];
  @Input({ required: true }) tagColors!: ContentBuilderFieldColors;
  @Output() navigateTo = new EventEmitter<DoodProductModel>();

  categoryKeys = CategoryKeys;

  onNavigateTo(product: DoodProductModel): void {
    this.navigateTo.emit(product);
  }
}
