<app-modal-filler-atom
  (click)="closeModal()"
  [index]="(index$ | async) ?? 0"
></app-modal-filler-atom>
<div
  class="fixed right-0 left-0 shadow overflow-y-auto"
  [ngClass]="
    isMobile
      ? 'bottom-0 max-h-85-screen max-w-screen-sm'
      : 'flex justify-center items-center w-full h-full top-0 left-0'
  "
  [style.z-index]="((index$ | async) ?? 0) + 50"
>
  <div
    class="bg-white p-5"
    [ngClass]="isMobile ? 'rounded-t-3xl' : 'rounded-3xl min-w-448'"
    [style.z-index]="((index$ | async) ?? 0) + 50"
  >
    <div class="flex justify-between items-center pb-7">
      <h2 class="font-accent font-bold text-3xs leading-8">
        {{ 'pay-at-table.free-amount-modal.title' | translate }}
      </h2>
    </div>
    <p class="pb-2 font-base text-neutral-600">
      {{ 'pay-at-table.free-amount-modal.choose-amount' | translate }}
    </p>
    <div class="pb-3">
      <app-input-text-atom
        [size]="'full'"
        [currency]="'EUR'"
        [isNumberOnly]="true"
        (secondaryValueChange)="changeFreeAmount($event)"
      ></app-input-text-atom>
    </div>
    <p
      *ngIf="errorMessage"
      class="my-1 font-base text-xs text-secondary-700 leading-4"
    >
      {{ errorMessage }}
    </p>
    <div class="pt-2 flex justify-between items-center">
      <div class="mr-2 xm:pr-1 w-full xm:w-1/2">
        <app-button-atom
          size="M"
          [label]="'pay-at-table.free-amount-modal.cancel' | translate"
          color="primary"
          (click)="closeModal()"
          type="light"
          [full]="true"
        ></app-button-atom>
      </div>
      <div class="xm:pl-1 w-full xm:w-1/2">
        <app-button-atom
          [disabled]="!!errorMessage"
          size="M"
          [label]="'pay-at-table.free-amount-modal.validate' | translate"
          (click)="validate()"
          type="primary"
          [full]="true"
        ></app-button-atom>
      </div>
    </div>
  </div>
</div>
