import { combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';

import { ModalsService } from '@core/services/modals/modals.service';

import { CartCommentLabels } from '@config/labels/comment.labels';
import { CartCommentTypeValues } from '@config/values/cart.values';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';

import { CartStoreSelector } from '@common/selectors/cart.selector';
import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { CartStoreDispatcher } from '@common/dispatchers/cart.dispatcher';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';

@Component({
  selector: 'app-cart-comment-modal',
  templateUrl: './cart-comment-modal.component.html',
})
export class CartCommentModalComponent extends DestroyerBase implements OnInit {
  static handle = 'cart-comment-modal';

  index$ = this.modalSelector
    .selectModal(CartCommentModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  title!: string;

  message?: string | null;

  constructor(
    private cartSelector: CartStoreSelector,
    private modalSelector: ModalStoreSelector,
    private cartDispatcher: CartStoreDispatcher,
    private readonly modalsService: ModalsService,
    readonly settingsSelector: SettingsStoreSelector,
  ) {
    super();
  }

  ngOnInit(): void {
    combineLatest([
      this.cartSelector.selectComment.pipe(map(c => c?.type)),
      this.cartSelector.selectActive,
    ])
      .pipe(
        take(1),
        map(([type, cart]) => {
          if (type === CartCommentTypeValues.Merchant) {
            this.title = CartCommentLabels.Merchant;
            this.message = cart?.message;
          }
        }),
      )
      .subscribe();
  }

  applyComment(comment: string): void {
    const commentType = this.cartSelector.state.comment?.type;
    if (comment && commentType === CartCommentTypeValues.Merchant) {
      this.cartDispatcher.updateActive({ message: comment });
    }
    this.closeComment();
  }

  closeComment(): void {
    this.modalsService.close(CartCommentModalComponent.handle);
  }
}
