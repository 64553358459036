import { DoodMarketplaceModel } from '@core/models/marketplace.model';
import { HydrationState, createHydratedAdapter } from '@store/_abstract/hydrated';

export interface MarketplaceState extends HydrationState {
  isLoaded: boolean;
  isLoading: boolean;
  marketplace: DoodMarketplaceModel;
}

export const MARKETPLACE_STORE_KEY = 'Marketplace';

export const MARKETPLACE_STORE_HYDRATION_ADAPTER = createHydratedAdapter();

export const MARKETPLACE_STORE_MARKETPLACE_INITIAL_STATE: DoodMarketplaceModel = {
  id: '',
  name: '',
  plugins: [],
  locales: [],
  domains: [],
  country: 'fr',
  currency: '',
  available: false,
  capabilities: [],
  layout: undefined,
  address: undefined,
  cart_scope: 'SHOP',
  tips_allowed: false,
  distribution_modes: [],
  default_locale: undefined,
  firebase_tenant: undefined,
};

export const MARKETPLACE_STORE_INITIAL_STATE: MarketplaceState =
  MARKETPLACE_STORE_HYDRATION_ADAPTER.getInitialState({
    isLoaded: false,
    isLoading: false,
    marketplace: MARKETPLACE_STORE_MARKETPLACE_INITIAL_STATE,
  });
