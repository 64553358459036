<div
  *ngIf="!isNative"
  class="text-center lg:text-left"
>
  <h1 class="mb-4 font-accent font-light text-2xl text-white">
    {{ heading }}
  </h1>
  <div class="flex justify-center pb-8">
    <div
      *ngIf="appStoreLink"
      class="mr-2"
    >
      <a href="{{ appStoreLink }}"
        ><img
          src="assets/images/stores/app-store.png"
          alt="Apple Store"
      /></a>
    </div>
    <div *ngIf="playStoreLink">
      <a href="{{ playStoreLink }}"
        ><img
          src="assets/images/stores/google-play.png"
          alt="Google Play"
      /></a>
    </div>
  </div>
</div>
