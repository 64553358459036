import { computed, inject, Injectable, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ICartDeclinableProductItem, ICartItem } from '@core/models/cart.model';
import { DoodProductModel, IOverridesPrices } from '@core/models/product.model';
import {
  BehaviorSubject,
  combineLatest,
  map,
  Observable,
  ReplaySubject,
  share,
  switchMap,
} from 'rxjs';
import { CartSimpleProductUtil, DraftAdditionsGroup } from './util/cart-simple-product-util';
import { CartCategoryUtil } from './util/cart-category-util';
import { CartDeclinableProductUtil } from './util/cart-declinable-product-util';
import {
  AdditionGroupsBySimpleProductId,
  CartUtilService,
  ProductsByCategoryId,
} from './util/cart-util.service';

type HistoryByCategoryId = { [categoryId: string]: string[] };

export type DraftAdditionGroupsBySimpleProductId = {
  [simpleProductId: string]: DraftAdditionsGroup[] | undefined;
};
export type ProductWithUIData = DoodProductModel & { uiPrice: number | undefined };
export type DraftProductsByCategoryId = { [categoryId: string]: ProductWithUIData[] };
export type DeclinableProductCart = Omit<ICartDeclinableProductItem, 'id'> & {
  id: string | number | undefined;
};
export type SimpleProductCartValidity =
  | {
      productId: string;
      isValid: boolean;
      additionsByGroupsValidity: Record<
        string,
        {
          isValid: boolean;
          isInvalid: boolean;
        }
      >;
    }
  | undefined;

@Injectable()
export class CartDraftDeclinableProductStore {
  private readonly cartUtilService = inject(CartUtilService);

  private readonly declinableProduct$ = new ReplaySubject<DoodProductModel>(1);
  private readonly declinableProduct = toSignal(this.declinableProduct$, {
    initialValue: undefined,
  });

  private readonly productsByCategoryId$ = this.declinableProduct$.pipe(
    switchMap(declinableProduct =>
      this.cartUtilService.getProductsByCategoryId$(declinableProduct),
    ),
    share(),
  );
  private readonly productsByCategoryId = toSignal(this.productsByCategoryId$, {
    initialValue: undefined,
  });
  private readonly additionGroupsBySimpleProductId$ = this.productsByCategoryId$.pipe(
    switchMap(productsByCategoryId =>
      this.cartUtilService.getAdditionGroupsBySimpleProductId$(productsByCategoryId),
    ),
  );
  private readonly additionGroupsBySimpleProductId = toSignal(
    this.additionGroupsBySimpleProductId$,
    {
      initialValue: undefined,
    },
  );

  private readonly shopId$ = new ReplaySubject<string>(1);
  private readonly existingCart$ = new BehaviorSubject<ICartItem | undefined>(undefined);

  public setExistingCart(cart: ICartItem) {
    this.existingCart$.next(cart);
  }

  public init({
    shopId,
    declinableProduct,
  }: {
    shopId: string;
    declinableProduct: DoodProductModel;
  }) {
    this.shopId$.next(shopId);
    this.declinableProduct$.next(declinableProduct);
  }

  private readonly declinableProductCategories = toSignal(
    this.declinableProduct$.pipe(map(declinableProduct => declinableProduct.categories)),
    {
      initialValue: undefined,
    },
  );

  // cart dérivé qui inclu le cart de toutes les catégories, ainsi que le cart de tous les produits simples qui le compose
  private readonly initialCartDraftState$: Observable<DeclinableProductCart> = combineLatest({
    productsByCategory: this.productsByCategoryId$,
    declinableProduct: this.declinableProduct$,
    shopId: this.shopId$,
    existingCart: this.existingCart$,
  }).pipe(
    map(({ productsByCategory, declinableProduct, shopId, existingCart }) => {
      return {
        item_id: declinableProduct.id,
        quantity: existingCart?.quantity ?? 0,
        products: CartCategoryUtil.createCartDraftCategory({
          productsByCategory,
          shopId,
          existingCart,
          categories: declinableProduct.categories,
        }),
        shop_id: shopId,
        id: existingCart?.id,
      } satisfies DeclinableProductCart;
    }),
  );

  private readonly initialHistoryByCategoryId$: Observable<HistoryByCategoryId> =
    this.productsByCategoryId$.pipe(
      map(productsByCategory => {
        return Object.keys(productsByCategory).reduce(
          (acc, categoryId) => {
            return {
              [categoryId]: [] as string[],
            };
          },
          {} as { [categoryId: string]: string[] },
        );
      }),
    );

  private readonly state = toSignal(
    combineLatest({
      initialCartDraftState: this.initialCartDraftState$,
      initialHistoryByCategoryId: this.initialHistoryByCategoryId$,
    }).pipe(
      map(({ initialCartDraftState, initialHistoryByCategoryId }) => {
        return signal({
          // todo make it readonly
          cartDraft: initialCartDraftState,
          historyByCategoryId: initialHistoryByCategoryId,
        });
      }),
    ),
    {
      initialValue: undefined,
    },
  );

  public readonly selectors = computed(() => {
    const state = this.state()?.();
    if (!state) {
      return undefined;
    }

    const declinableProduct = this.declinableProduct();
    if (!declinableProduct) {
      return undefined;
    }

    const declinableProductCategories = this.declinableProductCategories();
    if (!declinableProductCategories) {
      return undefined;
    }

    const additionGroupsBySimpleProductId = this.additionGroupsBySimpleProductId();
    if (!additionGroupsBySimpleProductId) {
      return undefined;
    }

    const productsByCategoryId = this.productsByCategoryId();
    if (!productsByCategoryId) {
      return undefined;
    }
    const cartDraft = state.cartDraft;

    const { isDeclinableProductValid, compiledCategoriesValidity } =
      CartDeclinableProductUtil.checkCategoriesValidity(
        declinableProductCategories,
        additionGroupsBySimpleProductId,
        cartDraft,
      );

    // ! it does not handle the case where a product is in multiple categories (otherwise create DraftAdditionGroupsBySimpleProductIdByCategoryId)
    const draftAdditionGroupsBySimpleProductId: DraftAdditionGroupsBySimpleProductId =
      this.mapAdditionGroupsBySimpleProductId(additionGroupsBySimpleProductId, cartDraft);
    const finalCart = {
      ...cartDraft,
      products: cartDraft.products?.filter(product => product.quantity > 0),
    };

    const productsByCategoryId$ = this.productsByCategoryId();
    if (!productsByCategoryId$) {
      return undefined;
    }
    const draftProductsByCategoryId = this.calculateDraftProductsByCategory({
      productsByCategoryId$,
      cartDraft,
      priceOverrides: declinableProduct.price_overrides ?? [],
    });

    return {
      cartDraft,
      finalCart,
      draftProductsByCategoryId,
      draftAdditionGroupsBySimpleProductId,
      getDraftSimpleProductById: (productId: string) => {
        // todo move & pas optimal
        return Object.values(draftProductsByCategoryId)
          .find(products => products.find(product => product.id === productId))
          ?.filter(product => product.id === productId)[0];
      },
      getCartItem: ({ productId, categoryId }: { productId: string; categoryId: string }) => {
        return cartDraft.products?.find(
          cartItem => cartItem.id === productId && cartItem.store_id === categoryId,
        );
      },
      getSimpleProductValidities: ({
        productId,
        categoryId,
      }: {
        productId: string;
        categoryId: string;
      }): SimpleProductCartValidity => {
        const compiledCategoryValidity = compiledCategoriesValidity.filter(
          categoryValidity => categoryValidity?.categoryId === categoryId,
        );
        const simpleProductValidity =
          compiledCategoryValidity[0]?.compiledSimpleProductValidities.find(
            simpleProductValidity => simpleProductValidity?.productId === productId,
          );
        return simpleProductValidity;
      },
      getCategoryById: ({ categoryId }: { categoryId: string }) => {
        return declinableProduct.categories.find(category => category.store_id === categoryId);
      },
      validity: {
        isDeclinableProductValid,
        compiledCategoriesValidity,
      },
      declinableProduct,
      totalSelectedQuantityByCategoryId:
        CartDeclinableProductUtil.calculateTotalSelectedQuantityByCategoryId(cartDraft.products),
      shopId: cartDraft.shop_id,
    }; // find a way to make it all readonly
  });

  private mapAdditionGroupsBySimpleProductId(
    additionGroupsBySimpleProductId: AdditionGroupsBySimpleProductId,
    cartDraft: DeclinableProductCart,
  ): DraftAdditionGroupsBySimpleProductId {
    return Object.entries(additionGroupsBySimpleProductId).reduce(
      (acc, [simpleProductId, additionGroups]) => {
        return {
          ...acc,
          [simpleProductId]: additionGroups?.map(additionGroup => {
            const selectedAdditionIds =
              cartDraft.products?.find(
                categoryCartDraft => categoryCartDraft.id === simpleProductId,
              )?.additions ?? [];
            return CartSimpleProductUtil.calculateGroupAdditions({
              additionGroup,
              selectedAdditionIds,
            });
          }),
        };
      },
      {} as DraftAdditionGroupsBySimpleProductId,
    );
  }

  // It is used to calculate the quantity of each product according to the cartDraft
  private calculateDraftProductsByCategory({
    productsByCategoryId$,
    cartDraft,
    priceOverrides,
  }: {
    productsByCategoryId$: ProductsByCategoryId;
    cartDraft: DeclinableProductCart;
    priceOverrides: IOverridesPrices[];
  }) {
    return Object.entries(productsByCategoryId$).reduce((acc, [categoryId, products]) => {
      return {
        ...acc,
        [categoryId]: products.map(product => {
          const cartItemAssociated = cartDraft.products?.find(
            cartItem => cartItem.id === product.id && cartItem.store_id === categoryId,
          );
          return {
            ...product,
            quantity: cartItemAssociated?.quantity ?? 0,
            uiPrice: priceOverrides.find(
              priceOverride =>
                priceOverride.product === product.id && priceOverride.store === categoryId, // check if it is ok, beause catgeoryId is a a store_id
            )?.additional_price,
          } satisfies ProductWithUIData;
        }),
      };
    }, {} as DraftProductsByCategoryId);
  }

  public updateQuantity(quantity: number) {
    const state = this.state();
    state?.update(stateData => ({
      ...stateData,
      cartDraft: {
        ...stateData.cartDraft,
        quantity,
      },
    }));
  }

  public updateSimpleProductQuantity({
    categoryId,
    productId,
    quantity,
  }: {
    categoryId: string;
    productId: string;
    quantity: number;
  }) {
    const state = this.state();
    if (!state) {
      return;
    }

    const selectors = this.selectors();
    if (!selectors) {
      return;
    }

    const category = selectors.declinableProduct.categories.find(
      category => category.store_id === categoryId,
    );
    if (!category) {
      return;
    }

    const categoryHistoryWithoutCurrentProductId = (
      state().historyByCategoryId?.[categoryId] ?? []
    ).filter(historyProductId => historyProductId !== productId);
    const updateHistoryByCategoryId = {
      ...state().historyByCategoryId,
      [categoryId]: [...categoryHistoryWithoutCurrentProductId, productId],
    };

    const currentCategoryCartDraftProduct = selectors.cartDraft.products?.find(
      cartItem => cartItem.id === productId && cartItem.store_id === categoryId,
    );

    if (!currentCategoryCartDraftProduct) {
      return;
    }
    const updatedCurrentCategoryCartDraftProduct = {
      ...currentCategoryCartDraftProduct,
      quantity,
    };

    const currentCategoryCartDraftOtherProducts =
      selectors.cartDraft.products?.filter(
        cartItem => cartItem.store_id === categoryId && cartItem.id !== productId,
      ) ?? [];

    const updatedCategoryCartDraftProducts = [
      ...currentCategoryCartDraftOtherProducts,
      updatedCurrentCategoryCartDraftProduct,
    ];

    const updatedCategoryCartDraftProductsWithoutExcess = CartCategoryUtil.removeExcessQuantity({
      cartProductItems: updatedCategoryCartDraftProducts,
      categoryMaxQuantity: category.count,
      editHistory: updateHistoryByCategoryId[categoryId],
    });

    const otherCategoriesCartDraftProducts =
      selectors.cartDraft.products?.filter(cartItem => cartItem.store_id !== categoryId) ?? [];

    state.update(state => {
      return {
        ...state,
        cartDraft: {
          ...state.cartDraft,
          products: [
            ...otherCategoriesCartDraftProducts,
            ...updatedCategoryCartDraftProductsWithoutExcess,
          ],
        },
        historyByCategoryId: updateHistoryByCategoryId,
      };
    });
  }

  public updateAdditionQuantity({
    productId,
    additionId,
    categoryId,
    quantity,
  }: {
    productId: string;
    additionId: string;
    categoryId: string;
    quantity: number;
  }) {
    this.state()?.update(state => {
      const simpleProductCartDraft = state.cartDraft.products?.find(
        cartProductItem =>
          cartProductItem.id === productId && cartProductItem.store_id === categoryId,
      );

      if (!simpleProductCartDraft) {
        return state;
      }
      const selectedAdditionIdsValue =
        simpleProductCartDraft.additions?.filter(id => id !== additionId) ?? [];
      const additionGroups =
        this.selectors()?.draftAdditionGroupsBySimpleProductId[productId] ?? [];

      const { selectedAdditionIdsWithoutExcessQuantity, separate_additions_by_group } =
        CartSimpleProductUtil.updateAdditionSelections({
          selectedAdditionIdsValue,
          quantity,
          additionId,
          additionGroups,
        });
      return {
        ...state,
        cartDraft: {
          ...state.cartDraft,
          products: state.cartDraft.products?.map(product => {
            if (product.id === productId && product.store_id === categoryId) {
              return {
                ...product,
                additions: selectedAdditionIdsWithoutExcessQuantity,
                separate_additions_by_group,
              };
            }
            return product;
          }),
        },
      };
    });
  }
}
