<app-modal-filler-atom
  (click)="close()"
  [index]="(index$ | async) ?? 0"
></app-modal-filler-atom>
<div
  class="fixed left-0 right-0 mx-auto overflow-y-auto shadow"
  [ngClass]="
    (isMobile$ | async)
      ? 'bottom-0 max-h-85-screen max-w-screen-sm'
      : 'flex justify-center items-center w-full h-full top-0 left-0'
  "
  [style.z-index]="((index$ | async) ?? 0) + 50"
>
  <app-modal-filler-atom
    class="hidden md:block"
    [withBackdrop]="false"
    (click)="close()"
    [index]="(index$ | async) ?? 0"
  ></app-modal-filler-atom>
  <div
    class="max-h-85-screen overflow-y-auto bg-white p-5"
    [ngClass]="(isMobile$ | async) ? 'rounded-t-3xl' : 'rounded-3xl min-w-448'"
    [class.pb-8]="isNotched"
    [style.z-index]="((index$ | async) ?? 0) + 50"
  >
    <header class="flex w-full justify-between">
      <h1
        class="pb-5 font-brand text-28px font-bold text-neutral-800"
        translate="delivery-addresses-modal.title"
      ></h1>
      <a
        class="block cursor-pointer p-2"
        (click)="close()"
        ><i class="icon icon-cross"></i
      ></a>
    </header>
    <div
      *ngIf="!isForm"
      class="mb-4 px-1.5"
    >
      <a
        class="block flex cursor-pointer items-center text-sm font-bold text-primary-600"
        (click)="addNewAddress()"
        ><i class="icon icon-plus mr-3"></i>
        {{ 'delivery-addresses-modal.add-addresse' | translate }}</a
      >
      <ng-container *ngIf="!(loadingDeliveryAddresses$ | async)">
        <div
          *ngIf="savedDeliveryAddresses$ | async as addresses"
          class="op pt-6"
        >
          <ul>
            <li
              *ngFor="let address of addresses"
              class="mb-4 flex justify-between"
              [class.opacity-20]="address.id === addressBeingDeleted"
            >
              <div class="flex items-center">
                <div class="flex items-center p-2 pl-0 pr-4">
                  <i class="icon icon-pin text-29px text-neutral-800"></i>
                </div>
                <div class="text-sm">
                  <p
                    *ngIf="address?.name"
                    class="font-bold"
                  >
                    {{ address.name }}
                  </p>
                  <p *ngIf="address?.street">
                    {{ address.street }}
                  </p>
                  <p>
                    <span *ngIf="address.postal_code">{{ address.postal_code }} </span
                    ><span *ngIf="address.city">{{ address.city }}</span>
                  </p>
                  @if (address?.instructions) {
                    <p class="italic text-neutral-600">{{ address.instructions }}</p>
                  }
                  <a
                    class="block cursor-pointer font-bold text-primary-800"
                    (click)="deleteExistingAddress(address.id)"
                    >{{ 'delivery-addresses-modal.delete' | translate }}</a
                  >
                </div>
              </div>
              <app-button-atom
                size="S"
                [disabled]="address.id === addressBeingDeleted"
                [label]="'delivery-addresses-modal.button' | translate"
                [color]="'primary'"
                [type]="'light'"
                [thickSize]="'XS'"
                (click)="chooseExistingAddress(address)"
              ></app-button-atom>
            </li>
          </ul>
        </div>
      </ng-container>
      <div *ngIf="loadingDeliveryAddresses$ | async">
        <ng-container *ngFor="let i of [].constructor(3)">
          <div class="mt-2 flex">
            <div class="p-2">
              <i class="icon icon-pin"></i>
            </div>
            <div class="w-full">
              <p class="mb-1.5 w-6/12 animate-pulse bg-neutral-100 py-1.5"></p>
              <p class="mb-1.5 w-4/12 animate-pulse bg-neutral-100 py-1.5"></p>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div *ngIf="isForm">
      <div class="mb-5">
        <app-input-text-atom
          [label]="'parameters.location-placeholder' | translate"
          [iconRight]="'icon-position'"
          [value]="displayedValue ?? ''"
          [cross]="true"
          (iconClick)="geolocalizeUser()"
          (valueChange)="valueChanged($event)"
        ></app-input-text-atom>
      </div>
      <div class="mb-5">
        <app-button-atom
          type="light"
          size="S"
          thickSize="S"
          [full]="true"
          icon="icon-position"
          [label]="'parameters.geolocalize-button' | translate"
          (clickEventEmitter)="geolocalizeUser()"
        ></app-button-atom>
      </div>
      <div
        *ngIf="deliveryAddressError"
        class="my-5 rounded bg-error-50 px-4 py-3 font-base text-sm text-error-700"
      >
        {{ deliveryAddressError }}
      </div>
      <div>
        <label
          for="name"
          class="-mt-1 mb-1 block font-base text-sm font-normal leading-5 text-neutral-500"
          >{{ 'delivery-addresses-modal.name-input' | translate }}</label
        >
        <input
          id="name"
          type="text"
          [ngModel]="addressName"
          (ngModelChange)="onInputNameChange($event)"
          class="mb-6 flex w-full border border-solid border-neutral-300 bg-neutral-50 px-5 py-3 font-base text-base font-normal leading-5"
        />
      </div>
      <div>
        <app-parameters-delivery-instructions-block
          [instructions]="forceType(instructions)"
          (instructionsChanged)="instructions = forceType($event)"
        ></app-parameters-delivery-instructions-block>
      </div>
      <div class="flex-1 pt-6">
        <app-button-atom
          *ngIf="!saveAddressLoading"
          [type]="'primary'"
          [size]="'L'"
          [label]="'delivery-addresses-modal.button' | translate"
          [disabled]="!displayedValue"
          (click)="selectAddress()"
        >
        </app-button-atom>
        <app-button-atom
          *ngIf="saveAddressLoading"
          [type]="'primary'"
          [size]="'L'"
          [disabled]="!displayedValue"
          [label]="'delivery-addresses-modal.button' | translate"
          [iconUrl]="saveAddressLoading ? 'assets/images/spinner.svg' : ''"
          (click)="selectAddress()"
        >
        </app-button-atom>
      </div>
    </div>
  </div>
</div>
