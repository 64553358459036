<a
  *ngIf="userMustBeLoggedIn && (userIsLoggedIn$ | async)"
  class="block relative w-full py-3 pl-14.5 pr-4 text-md cursor-pointer"
  (click)="click()"
  [link]="path"
  [target]="linkTarget"
>
  <i
    class="absolute left-5 icon"
    [class]="'icon-' + icon"
  ></i>
  <div class="font-accent text-base text-neutral-800 font-bold">
    {{ title }}
  </div>
</a>
<a
  *ngIf="!userMustBeLoggedIn"
  class="block relative w-full py-3 pl-14.5 pr-4 text-md cursor-pointer"
  (click)="click()"
  [link]="path"
  [target]="linkTarget"
>
  <i
    class="absolute left-5 icon"
    [class]="'icon-' + icon"
  ></i>
  <div class="font-accent text-base text-neutral-800 font-bold">
    {{ title }}
  </div>
</a>
