@if (order) {
  <ng-container *ngIf="['WAITING', 'PREPARATION', 'READY', 'COLLECTED'].includes(order.status)">
    <div class="md:flex md:flex-col md:items-center kiosk:mt-32">
      <div class="px-5 pb-3 md:w-8/12">
        <div class="flex items-center pb-3 pl-5 pr-11 pt-1 md:justify-center">
          <p
            class="text-accent mr-6 text-2xl font-bold leading-8 md:mr-7 md:text-5xl kiosk:text-6xl"
            [style.color]="textColor | contentBuilderColor"
          >
            {{ text }}
          </p>
          <span class="kiosk:hidden"
            ><i class="icon icon-n-check text-4xl text-success-600 md:text-5.8xl"></i
          ></span>
        </div>
      </div>
    </div>
  </ng-container>
}
