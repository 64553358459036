import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MarketplaceService } from '@core/services/marketplace/marketplace.service';
import { switchMap, take, tap } from 'rxjs/operators';
import { AuthFirebaseService } from '@core/services/api/auth-firebase/auth-firebase.service';
import { UserService } from '@core/services/user/user.service';
import { DoodUserModel } from '@store/authentication/authentication.model';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { of, from } from 'rxjs';
import { UserCredential, updatePassword } from 'firebase/auth';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';

@Component({
  selector: 'app-authentication-step',
  templateUrl: './authentication-step.component.html',
  styleUrls: ['./authentication-step.component.scss'],
})
export class AuthenticationStepComponent implements OnInit {
  @Output() onClose = new EventEmitter<void>();
  @Output() onComplete = new EventEmitter<void>();
  @Output() onKioskConfirm = new EventEmitter<void>();

  isLoading = true;
  passwordForm: UntypedFormGroup = this.fb.group({
    password: ['', Validators.required],
  });
  signInWithPopupInProgress = false;
  passwordErrorMessage?: string;
  isPasswordChangeInProgress = false;

  kioskParameters = this.settingsSelector.kiosk;

  constructor(
    private readonly marketplaceService: MarketplaceService,
    private readonly authFirebaseService: AuthFirebaseService,
    private readonly userService: UserService,
    private readonly authStoreSelector: AuthStoreSelector,
    private readonly settingsSelector: SettingsStoreSelector,
    private readonly fb: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    const comoPlugin = this.marketplaceService.getPluginByType('como');
    if (comoPlugin['allow_anonymous_user']) {
      if (!this.kioskParameters.isKioskLayout) {
        this.onComplete.next();
      } else {
        this.onKioskConfirm.next();
      }
    }

    if (!this.authStoreSelector.user?.is_anonymous) {
      if (!this.kioskParameters.isKioskLayout) {
        this.onComplete.next();
      } else {
        this.onKioskConfirm.next();
      }
    }

    this.isLoading = false;
  }

  googleUpgrade(): void {
    this.signInWithPopupInProgress = true;
    this.authFirebaseService
      .upgradeAnonymousWithGoogle()
      .pipe(
        switchMap((userCredential: UserCredential) =>
          this.authFirebaseService.saveFirebaseTokenInSessionStore$(userCredential),
        ),
        switchMap(() => this.authStoreSelector.selectUser),
        take(1),
        switchMap(user => {
          const newUser = {};
          return this.userService.upgradeAnonymousUser$(newUser, user?.id ?? '');
        }),
        switchMap(() => this.authFirebaseService.loadUser$()),
        switchMap(user => this.updateUserIsNotAnonymous(user)),
        tap(user => this.handleLoginResult(user)),
      )
      .subscribe({
        error: error => {
          this.signInWithPopupInProgress = false;
          this.passwordErrorMessage = this.authFirebaseService.getPasswordErrorMessage(error);
        },
        complete: () => (this.signInWithPopupInProgress = false),
      });
  }

  private handleLoginResult(user?: DoodUserModel) {
    if (!this.kioskParameters.isKioskLayout) {
      this.onComplete.next();
    } else {
      this.onKioskConfirm.next();
    }
  }

  private updateUserIsNotAnonymous(user?: DoodUserModel) {
    if (user) {
      return this.userService.updateUser$(
        {
          is_anonymous: false,
        },
        user.id,
      );
    } else {
      return of(user);
    }
  }

  changePassword() {
    this.isPasswordChangeInProgress = true;
    const newPassword = this.passwordForm.get('password')?.value;
    const user = this.authFirebaseService.getCurrentUser();
    if (!user) {
      alert('ERROR: no user found');
      return;
    }
    from(updatePassword(user, newPassword))
      .pipe(
        switchMap(() => this.authFirebaseService.loadUser$()),
        switchMap(user => this.updateUserIsNotAnonymous(user)),
        tap(() => {
          this.isPasswordChangeInProgress = false;
          if (!this.kioskParameters.isKioskLayout) {
            this.onComplete.next();
          } else {
            this.onKioskConfirm.next();
          }
        }),
      )
      .subscribe();
  }
}
