import { combineLatest } from 'rxjs';
import { Router } from '@angular/router';
import { Component, Input } from '@angular/core';
import { take, tap } from 'rxjs/operators';

import { Paths } from '@config/paths.config';
import { CartFunnelStoreSelector } from '@common/selectors/cart-funnel.selector';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { CartFunnelStoreDispatcher } from '@common/dispatchers/cart-funnel.dispatcher';
import {
  IContentBuilderFieldColor,
  IContentBuilderFieldImage,
} from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-kiosk-cart-funnel-header-block',
  templateUrl: './kiosk-cart-funnel-header-block.component.html',
})
export class KioskCartFunnelHeaderBlockComponent {
  @Input() displayLogo = false;
  @Input() logo?: { url: IContentBuilderFieldImage };
  @Input() backgroundColor?: IContentBuilderFieldColor;
  @Input() backgroundImage?: IContentBuilderFieldImage;

  constructor(
    private readonly router: Router,
    private readonly routerHelper: RouterHelperService,
    private cartFunnelSelector: CartFunnelStoreSelector,
    private cartFunnelDispatcher: CartFunnelStoreDispatcher,
  ) {}

  back(): void {
    combineLatest([this.cartFunnelSelector.selectSteps, this.cartFunnelSelector.selectActiveStep])
      .pipe(
        take(1),
        tap(([steps, active]) => {
          if (active?.index === 0) {
            this.router.navigate([this.routerHelper.translateRoute(Paths.Cart)]);
          }

          if (active?.index && active.index > 0) {
            const previous = steps.filter(f => f.index === active.index - 1)?.[0]?.id;
            this.cartFunnelDispatcher.setActive(previous);
          }
        }),
      )
      .subscribe();
  }
}
