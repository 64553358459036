<div
  class="py-5 border-neutral-400 border rounded rounded-s cursor-pointer"
  [style.color]="textColor | contentBuilderColor"
>
  <div
    class="flex items-center px-4.5"
    [style.color]="headingColor | contentBuilderColor"
  >
    <i class="icon {{ icon }} mr-3"></i>
    <span class="font-base font-bold lg:text-md">{{ text }}</span>
  </div>
  <div
    *ngIf="comment"
    class="flex items-center px-4.5 my-3"
  >
    <div>
      <span class="font-base">{{ comment }}</span>
    </div>
  </div>
  <div
    *ngIf="comment"
    class="flex px-4.5"
  >
    <a
      (click)="$event.stopPropagation(); updadeComment.emit()"
      class="mr-2 font-base font-bold text-sm text-primary-600 cursor-pointer"
      >{{ 'basket.comment-edit' | translate }}</a
    >
    <a
      (click)="$event.stopPropagation(); removeComment.emit()"
      class="mr-2 font-base font-bold text-sm text-primary-600 cursor-pointer"
      >{{ 'basket.comment-remove' | translate }}</a
    >
  </div>
</div>
