<div class="md:flex md:flex-col md:items-center">
  <div class="px-5 pb-3 md:w-8/12">
    <app-email-receipt-atom
      [icon]="'icon-receipt_long'"
      [title]="'pay-at-table.payment-confirmation.receipt.receipt-by-email' | translate"
      [text]="'pay-at-table.payment-confirmation.receipt.description' | translate"
      (openModal)="openModalReceipt()"
    ></app-email-receipt-atom>
  </div>
</div>
