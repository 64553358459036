import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class HubspotApiService {
  constructor(private readonly httpClient: HttpClient) {}

  requestHubspot$(data: any, formId: string): Observable<any> {
    return this.httpClient.post<any>(
      `https://api.hsforms.com/submissions/v3/integration/submit/${formId}`,
      data,
    );
  }
}
