import { find } from 'lodash';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  AfterViewInit,
  ElementRef,
} from '@angular/core';

import { ComoAsset } from '@core/models/como.model';

import { CartService } from '@core/services/cart/cart.service';
import { ComoService } from '@core/services/como/como.service';

import { DestroyerBase } from '@core/base/destroyer/destroyer.base';

@Component({
  selector: 'app-como-asset-list-atom',
  templateUrl: './como-asset-list-atom.component.html',
})
export class ComoAssetListAtomComponent extends DestroyerBase implements OnChanges, AfterViewInit {
  @Input() assets!: ComoAsset[];
  @Input() heading = 'como.loyalty-rewards';
  @Input() subheading?: string;
  @Input() displayPoints = true;
  @Input() displayCredits = true;
  @Input() displayAddButton = true;
  @Input() pointsBalance?: number;
  @Input() creditBalance?: number;
  @Input() displayRedeemableGifts = true;
  @Input() displayNotRedeemableGifts = true;
  @Input() displayRedeemablePointShop = true;
  @Input() displayNotRedeemablePointShop = true;
  @Input() displayNotUsablePointShop = true;
  @Output() onAssetAdd = new EventEmitter<ComoAsset>();
  @Output() onAssetRemove = new EventEmitter<ComoAsset>();

  @ViewChild('scrollContainer', { static: false }) scrollContainerRef!: ElementRef<HTMLDivElement>;

  redeemableAssets: ComoAsset[] = [];
  noRedeemableAssets: ComoAsset[] = [];
  redeemablePointShops: ComoAsset[] = [];
  noRedeemablePointShops: ComoAsset[] = [];
  pointShops: ComoAsset[] = [];

  constructor(
    private readonly cartService: CartService,
    private readonly comoService: ComoService,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.redeemableAssets = [];
    this.noRedeemableAssets = [];
    this.redeemablePointShops = [];
    this.noRedeemablePointShops = [];
    this.pointShops = [];

    for (const asset of this.assets) {
      const isPointShop = asset.key?.startsWith('ps_');
      if (asset.is_usable) {
        if (asset.status?.toLowerCase() !== 'active') {
          continue;
        }
        if (asset.redeemable === true) {
          if (isPointShop) {
            this.redeemablePointShops.push(asset);
          } else {
            this.redeemableAssets.push(asset);
          }
        } else {
          if (isPointShop) {
            this.noRedeemablePointShops.push(asset);
          } else {
            this.noRedeemableAssets.push(asset);
          }
        }
      } else {
        this.pointShops.push(asset);
      }
    }
  }

  ngAfterViewInit(): void {
    this.enableHorizontalScrollOnDesktop();
  }

  private enableHorizontalScrollOnDesktop() {
    const el = this.scrollContainerRef.nativeElement;

    el.addEventListener(
      'wheel',
      (e: WheelEvent) => {
        if (e.deltaY !== 0) {
          e.preventDefault(); // prevent vertical scroll
          el.scrollLeft += e.deltaY; // scroll horizontally instead
        }
      },
      { passive: false },
    );
  }

  isAssetInCart$(asset: ComoAsset): Observable<boolean> {
    return this.comoService.getComoUser$().pipe(
      takeUntil(this._destroyerRef),
      map(() => {
        const coupons = this.cartService.getCouponsInCart();

        return !!find(coupons, coupon => coupon.key && coupon.key === asset.key);
      }),
    );
  }
}
