import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-account-not-found-step',
  templateUrl: './account-not-found-step.component.html',
})
export class AccountNotFoundStepComponent {
  @Input() phone?: string;

  @Output() goToPhoneStep = new EventEmitter<void>();
  @Output() onClose = new EventEmitter<Event>();

  onRegister(): void {
    console.log('quick registration');
  }

  onGoToPhoneStep(): void {
    this.goToPhoneStep.next();
  }
}
