import { Component, Input } from '@angular/core';

import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { ModalsService } from '@core/services/modals/modals.service';
import { RegistrationStep } from '@shared/modals/como/como-registration-modal/registration-step.enum';
import { Router } from '@angular/router';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { SettingsParametersState } from '@store/settings';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-como-registration-modal',
  templateUrl: './como-registration-modal.component.html',
})
export class ComoRegistrationModalComponent extends DestroyerBase {
  static handle = 'como-registration-modal';

  phone?: string;
  @Input() goToURLOnClose?: string;
  @Input() goToKioskRegister?: boolean = false;
  registrationStep = RegistrationStep;
  @Input() activeStep = this.registrationStep.STEP_PHONE;

  @Input() accentColor?: IContentBuilderFieldColor;

  constructor(
    private readonly modalsService: ModalsService,
    private readonly router: Router,
    private readonly routerHelper: RouterHelperService,
    private readonly settingsSelector: SettingsStoreSelector,
  ) {
    super();
  }

  ngOnInit() {
    this.getParameters();
    if (this.goToKioskRegister) {
      this.onGoToRegisterStep();
    }
  }

  onClose(): void {
    this.modalsService.close(ComoRegistrationModalComponent.handle);
  }

  onComplete(): void {
    this.onClose();
    if (this.goToURLOnClose) {
      this.router.navigate([
        this.goToURLOnClose
          ? this.routerHelper.translateRoute(this.goToURLOnClose)
          : this.goToURLOnClose,
      ]);
    }
  }

  onGoToOtpStep(): void {
    this.activeStep = this.registrationStep.STEP_OTP;
  }

  setPhone(phone: string): void {
    this.phone = phone;
    this.activeStep = this.registrationStep.STEP_ACCOUNT_NOT_FOUND;
  }

  onGoToAccountNotFoundStep() {
    this.activeStep = this.registrationStep.STEP_ACCOUNT_NOT_FOUND;
  }

  onGoToPhoneStep() {
    this.activeStep = this.registrationStep.STEP_PHONE;
  }

  onGoToAuthenticationStep() {
    this.activeStep = this.registrationStep.STEP_AUTHENTICATION;
  }

  onGoToConfirmationStep() {
    this.activeStep = this.registrationStep.STEP_KIOSK_CONFIRMATION;
  }

  onGoToRegisterStep() {
    this.activeStep = this.registrationStep.STEP_KIOSK_REGISTER;
  }

  onGoToKioskRegisterConfirmationStep() {
    this.activeStep = this.registrationStep.STEP_KIOSK_REGISTER_CONFIRMATION;
  }

  onGoToKioskAccountNotFoundStep() {
    this.activeStep = this.registrationStep.STEP_KIOSK_ACCOUNT_NOT_FOUND;
  }

  getParameters(): SettingsParametersState {
    return this.settingsSelector.parameters;
  }
}
