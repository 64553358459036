import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NoConnectionModalService {
  protected display$ = new BehaviorSubject(false);
  protected retryInProgress$ = new BehaviorSubject(false);

  getDisplay$(): BehaviorSubject<boolean> {
    return this.display$;
  }

  setDisplay(display: boolean): void {
    this.display$.next(display);
  }

  getRetryInProgress$(): BehaviorSubject<boolean> {
    return this.retryInProgress$;
  }

  setRetryInProgress(retryInProgress: boolean): void {
    this.retryInProgress$.next(retryInProgress);
  }
}
