import {map} from 'rxjs/operators';
import {Component, Input, OnDestroy} from '@angular/core';

import {LocationUtils} from '@shared/utils/location/location.utils';

import {ModalsService} from '@core/services/modals/modals.service';

import {CartCommentTypeValues} from '@config/values/cart.values';
import {OrderTypeValues} from 'src/app/config/values/order.values';

import {CartStoreSelector} from '@common/selectors/cart.selector';
import {OrderStoreSelector} from '@common/selectors/order.selector';
import {CartStoreDispatcher} from '@common/dispatchers/cart.dispatcher';

import {CartCommentModalComponent} from '@shared/modals/cart-comment-modal/cart-comment-modal.component';
import {CartParametersModalComponent} from '@shared/modals/cart-parameters-modal/cart-parameters-modal.component';
import {
  CartUserInformationsModalComponent
} from '@shared/modals/cart-user-informations-modal/cart-user-informations-modal.component';
import {IContentBuilderFieldColor} from '@core/models/content-builder-fields.model';
import {ColorFieldTypesValues} from '@config/values/color-field-types.values';

@Component({
  selector: 'app-cart-additional-information-block',
  templateUrl: './cart-additional-information-block.component.html',
})
export class CartAdditionalInformationBlockComponent implements OnDestroy {
  @Input() headingColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() textColor: IContentBuilderFieldColor = {
    value: 'neutral-600',
    type: ColorFieldTypesValues.Palette,
  };

  message$ = this.cartSelector.selectActive.pipe(map(cart => cart?.message));
  selectedDistribution$ = this.cartSelector.selectActive.pipe(map(cart => cart?.type));
  contactPhone$ = this.cartSelector.selectActive.pipe(map(cart => cart?.contact_phone));
  missingPhone$ = this.orderSelector.selectErrors.pipe(map(errors => errors.missing_number));
  instructions$ = this.cartSelector.selectActive.pipe(map(cart => cart?.delivery_address?.instructions));
  deliveryAddress$ = this.cartSelector.selectActive.pipe(
    map(cart => {
      const deliveryAddress = cart?.delivery_address;
      if (deliveryAddress) {
        return LocationUtils.constructDisplayedDeliveryAddressWithValidateResponse(deliveryAddress);
      }
      return undefined;
    }),
  );

  orderTypeValues = OrderTypeValues;

  constructor(
    private cartSelector: CartStoreSelector,
    private orderSelector: OrderStoreSelector,
    private cartDispatcher: CartStoreDispatcher,
    private readonly modalsService: ModalsService,
  ) {
  }

  openInputComment(type: string): void {
    this.cartDispatcher.updateStore({
      comment: {type},
    });
    this.modalsService.open(CartCommentModalComponent.handle);
  }

  onRemoveComment(type: string): void {
    if (type === CartCommentTypeValues.Merchant) {
      this.cartDispatcher.updateActive({message: ''});
    }
  }

  openInputUserInformation(): void {
    this.modalsService.open(CartUserInformationsModalComponent.handle);
  }

  openCartParameters(): void {
    this.modalsService.open(CartParametersModalComponent.handle);
  }

  ngOnDestroy(): void {
    this.modalsService.close(CartCommentModalComponent.handle);
    this.modalsService.close(CartUserInformationsModalComponent.handle);
  }
}
