export interface IKiosk {
  id: string;
  available: boolean;
  name: string;
  kiosk_hardwares: IKioskHardware[];
}

export interface IKioskHardware {
  type: string;
}

export interface IKioskHardwareStripeTerminal extends IKioskHardware {
  reader_id: string;
  stripe_location_id: string;
  simulated_terminal: string;
}

export interface IKioskHardwareYavinTerminal extends IKioskHardware {
  terminal_ip_address: string;
}

export interface IKioskHardwareAuresEtkPrinter extends IKioskHardware {
  kiosk_local_ip_address: string;
}

export interface IKioskHardwareEpsonLocalPrinter extends IKioskHardware {
  printer_ip_address: string;
  printer_device_id: string;
  line_width: number;
}

export enum KioskHardwareType {
  STRIPE_TERMINAL = 'stripe_terminal',
  YAVIN_TERMINAL = 'yavin_terminal',
  AURES_ETK_PRINTER = 'aures_etk_printer',
  EPSON_LOCAL_PRINTER = 'epson_local_printer',
}
