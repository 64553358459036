<div
  class="md:flex md:flex-col md:items-center"
  *ngIf="displayCard"
>
  <div class="px-5 pb-3 md:w-8/12">
    <div
      class="flex flex-col p-5 md:p-6 mb-3 md:mb-8 rounded-m"
      [style.backgroundColor]="backgroundColor | contentBuilderColor: 'neutral-100'"
    >
      <div class="flex flex-row justify-between items-center">
        <div
          class="text-md md:text-29px font-accent font-bold"
          [style.color]="textColor | contentBuilderColor: 'neutral-800'"
        >
          <p>{{ text }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
