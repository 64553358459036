import { CommonModule } from '@angular/common';
import {
  Component,
  effect,
  ElementRef,
  inject,
  input,
  untracked,
  viewChildren,
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { ICartDraftSimpleProductItem } from '@core/models/cart.model';
import { DoodProductModel } from '@core/models/product.model';
import { StepInfo } from '@core/models/stepper.model';
import { ButtonAtomComponent } from '@shared/atoms/button-atom/button-atom.component';
import { KioskHorizontalScrollComponent } from '@shared/blocks/kiosk/kiosk-horizontal-scroll/kiosk-hotizontal-scroll.component';
import { DeclinableProductCart } from '@shared/local-store/cart/cart-draft-declinable-product.store';
import { SimpleProductCart } from '@shared/local-store/cart/cart-draft-simple-product.store';
import { CompositionStepperUtil } from '@shared/local-store/stepper/composition-stepper.service';
import { RootStepperStore } from '@shared/local-store/stepper/root-stepper.store';
import { map, combineLatest, switchMap, EMPTY, shareReplay, pairwise } from 'rxjs';
import { opacityListAnimation } from '@shared/animations/list.animation';
import { KioskProductOverviewCartAtomComponent } from '../kiosk-product-overview-cart-atom/kiosk-product-overview-cart-atom.component';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ContentBuilderColorPipe } from '@core/pipes/content-builder-color.pipe';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-kiosk-draft-cart-composition-organism',
  standalone: true,
  imports: [
    CommonModule,
    KioskHorizontalScrollComponent,
    ButtonAtomComponent,
    KioskProductOverviewCartAtomComponent,
    ContentBuilderColorPipe,
    TranslateModule,
  ],
  animations: [opacityListAnimation],
  template: `
    @let stepperProducts = stepperProductComposition();
    @if (stepperProducts && stepperProducts.length) {
      <div
        class="rounded p-2"
        [style.background-color]="config().compositionBackgroundColor | contentBuilderColor"
      >
        <!-- TODO can be a customer translation -->
        <p class="font-accent text-2.5xl font-semibold">
          {{ 'kiosk.cart-item-real-time-composition' | translate }}
        </p>
        <app-kiosk-horizontal-scroll
          [leftButtonTemplate]="leftButton"
          [rightButtonTemplate]="rightButton"
          [contentTemplate]="content"
          #horizontalScroll
        >
          <ng-template #leftButton>
            @let leftButtonData = horizontalScroll.leftButtonData;
            <app-button-atom
              size="S"
              color="neutral"
              [icon]="'icon-arrow-down-s'"
              [iconRotation]="'180'"
              type="kiosk-light"
              thickSize="S"
              [full]="false"
              [aspectSquare]="true"
              [disabled]="leftButtonData().disabled"
            ></app-button-atom>
          </ng-template>
          <ng-template #rightButton>
            @let rightButtonData = horizontalScroll.rightButtonData;
            <app-button-atom
              size="S"
              color="neutral"
              [icon]="'icon-arrow-down-s'"
              [iconRotation]="'270'"
              type="kiosk-light"
              thickSize="S"
              [full]="false"
              [disabled]="rightButtonData().disabled"
              [aspectSquare]="true"
            ></app-button-atom>
          </ng-template>
          <ng-template #content>
            <!-- TODO pass the stepperProducts to the scroller and get it by the contexte (it will enable to the scroller to be updated)  -->
            <div>
              <div class="max-width-full flex flex-row">
                <ul
                  class="flex flex-1 flex-row gap-8 pt-2.5"
                  [@opacityListAnimation]="stepperProducts.length"
                >
                  @for (
                    stepperProduct of stepperProducts;
                    track stepperProduct.product?.id;
                    let index = $index
                  ) {
                    @let product = stepperProduct.product;
                    <li #compositionProductItem>
                      <app-kiosk-product-overview-cart-atom
                        [id]="product?.id ?? ''"
                        [name]="product?.name ?? 'INVALID'"
                        [quantity]="stepperProduct.quantity"
                        [thumbnail]="product?.images?.thumbnail?.url"
                        [dotColor]="config().primaryButtonBackgroundColor"
                        [dotTextColor]="config().primaryButtonTextColor"
                        [productBackgroundColor]="config().productBackgroundColor"
                        [productTextColor]="config().productTextColor"
                        (click)="
                          goToStep(
                            {
                              categoryId: stepperProduct.categoryId,
                              simpleProductId: stepperProduct.simpleProductId,
                              additionsGroupId: stepperProduct.additionsGroupId,
                            },
                            index
                          )
                        "
                      ></app-kiosk-product-overview-cart-atom>
                    </li>
                  }
                </ul>
              </div>
            </div>
          </ng-template>
        </app-kiosk-horizontal-scroll>
      </div>
    }
  `,
})
export class KioskDraftCartOrganismComponent {
  private readonly rootStepperStore = inject(RootStepperStore);
  private readonly compositionStepperUtil = inject(CompositionStepperUtil);
  private readonly compositionProductItemElements =
    viewChildren<ElementRef>('compositionProductItem');

  public readonly cartData = input.required<
    | {
        cart: DeclinableProductCart | ICartDraftSimpleProductItem;
        isValid: boolean;
      }
    | undefined
  >();

  public readonly product = input.required<Readonly<DoodProductModel> | undefined>();
  public readonly config = input.required<{
    primaryButtonBackgroundColor?: IContentBuilderFieldColor;
    primaryButtonTextColor?: IContentBuilderFieldColor;
    productBackgroundColor?: IContentBuilderFieldColor;
    productTextColor?: IContentBuilderFieldColor;
    compositionBackgroundColor?: IContentBuilderFieldColor;
  }>();

  constructor() {
    this.scrollToSelectedProduct();
  }

  private readonly cart$ = toObservable(this.cartData).pipe(map(cartData => cartData?.cart));
  private readonly product$ = toObservable(this.product);

  private scrollToSelectedProduct() {
    effect(() => {
      const compositionProductItemElements = this.compositionProductItemElements();

      const scrollToAddProductInComposition = untracked(() =>
        this.scrollToAddProductInComposition$(),
      );
      if (!scrollToAddProductInComposition) {
        return;
      }

      const stepperComposition = untracked(() => this.stepperProductComposition());

      if (!stepperComposition) {
        return;
      }

      // find the index of the product to scroll to
      const index = stepperComposition.findIndex(
        product => product.id === scrollToAddProductInComposition,
      );

      if (index === -1) {
        return;
      }

      return compositionProductItemElements[index]?.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    });
  }

  protected readonly stepperProductComposition$ = combineLatest({
    cart: this.cart$,
    product: this.product$,
  }).pipe(
    switchMap(({ cart, product }) => {
      if (!cart || !product) {
        return EMPTY;
      }
      if (product.type === 'DECLINABLE') {
        return this.compositionStepperUtil.declinableProductStepperComposition$({
          cart: cart as DeclinableProductCart,
          categories: product?.categories ?? [],
        });
      }

      return this.compositionStepperUtil.simpleProductStepperComposition$({
        cart: cart as SimpleProductCart,
        productAdditionIds: product.additions?.products ?? [],
      });
    }),
    shareReplay(1),
  );

  protected readonly itemIdAddedToTheComposition$ = this.stepperProductComposition$.pipe(
    pairwise(),
    map(([previous, current]) => {
      if (!previous || !current) {
        return;
      }
      const result = current
        .filter(currentItem => {
          return !previous.some(previousItem => previousItem.id === currentItem.id);
        })
        .map(item => item.id);
      return result;
    }),

    map(items => items?.[items.length - 1]),
  );

  protected readonly scrollToAddProductInComposition$ = toSignal(this.itemIdAddedToTheComposition$);

  protected readonly stepperProductComposition = toSignal(this.stepperProductComposition$);

  protected goToStep(data: StepInfo, index: number): void {
    this.rootStepperStore.setSelectedStepId(data);
    this.compositionProductItemElements()[index]?.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  }
}
