<div
  [style.backgroundColor]="backgroundColor | contentBuilderColor"
  [style.borderColor]="textColor | contentBuilderColor"
  class="mt-16"
>
  <div
    class="mx-5 flex flex-grow rounded-lg border border-4 border-solid px-6 py-4"
    [style.backgroundColor]="backgroundColor | contentBuilderColor"
    [style.borderColor]="textColor | contentBuilderColor"
  >
    <input
      appVirtualKeyboard
      type="text"
      [(ngModel)]="voucher"
      [isOverFooter]="true"
      class="flex flex-grow border border-none text-2xl outline-none focus:outline-none focus:ring-0"
      placeholder="Saisir un code promo"
      [style.backgroundColor]="backgroundColor | contentBuilderColor"
    />
    <app-button-atom
      size="M"
      thickSize="M"
      label="Ajouter"
      [overrideBgColor]="primaryButtonBackgroundColor"
      [overrideTextColor]="primaryButtonTextColor"
      type="kiosk-primary"
      [full]="true"
      (click)="applyVoucher(voucher)"
    ></app-button-atom>
  </div>
  <div
    *ngIf="errorVoucher"
    class="pl-5 pt-5"
  >
    {{ errorVoucher }}
  </div>
  <div
    *ngFor="let coupon of (order$ | async)?.coupons"
    class="m-5 flex justify-between border-b border-neutral-300 px-4 py-6"
    [style.backgroundColor]="primaryButtonTextColor | contentBuilderColor"
    [style.color]="primaryButtonBackgroundColor | contentBuilderColor"
    [class.opacity-50]="$any(coupon).removeInProgress"
  >
    <div class="leading-snug lg:text-base">
      <div class="pb-5 text-xl font-bold">
        <ng-container *ngIf="$any(coupon).name">
          {{ $any(coupon).name }}
        </ng-container>
        <ng-container *ngIf="!$any(coupon).name">
          {{ 'basket.discount-voucher' | translate }} «{{ coupon.code }}»
        </ng-container>
      </div>
      <div
        *ngIf="$any(coupon).type === 'ERROR'"
        class="text-xs text-error-800"
      >
        <ng-container *ngIf="coupon.error">
          {{ 'voucher-modal.voucher-not-available' | translate }}
        </ng-container>
        <ng-container *ngIf="!coupon.error">
          {{ 'basket.invalid-voucher' | translate }}
        </ng-container>
      </div>
      <div>
        <app-button-atom
          size="M"
          thickSize="S"
          label="Supprimer"
          [overrideBgColor]="secondaryButtonBackgroundColor"
          [overrideTextColor]="secondaryButtonTextColor"
          type="kiosk-primary"
          [full]="true"
          (click)="removeCoupon(coupon)"
        ></app-button-atom>
      </div>
    </div>
    <div class="flex flex-row items-center justify-end">
      <div
        class="icon icon-coupon-3 pl-4 text-neutral-400"
        *ngIf="$any(coupon).type !== 'ERROR'"
      ></div>
      <div
        class="icon icon-warning-circle pl-4 text-error-800"
        *ngIf="$any(coupon).type === 'ERROR'"
      ></div>
      <div
        class="w-20 text-right text-sm leading-5"
        *ngIf="$any(coupon).type !== 'ERROR'"
      >
        –
        <app-price-atom
          [bold]="false"
          [amount]="$any(coupon).amount_off ?? 0"
          [format]="true"
        ></app-price-atom>
      </div>
    </div>
  </div>
</div>
