import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DOOD_API } from '@config/ws.config';

@Injectable({
  providedIn: 'root',
})
export class SuggestionsSetsApiService {
  constructor(private readonly httpClient: HttpClient) {}

  // TODO: Specify a type for suggestions
  public getSuggestionsSet$(suggestionsSetId: string, context: any): Observable<any> {
    return this.httpClient.post<any>(
      `${DOOD_API}/suggestions-sets/${suggestionsSetId}/suggest`,
      context,
    );
  }
}
