import {
  APP_INITIALIZER,
  DEFAULT_CURRENCY_CODE,
  Injector,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { RouterModule } from '@angular/router';
import localeFr from '@angular/common/locales/fr';
import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserModule } from '@angular/platform-browser';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ServiceWorkerModule } from '@angular/service-worker';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';

import { NgxStripeModule } from 'ngx-stripe';

import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

import { environment } from '@env/environment';

import { AppComponent } from '@app/app.component';
import { AppHttpLoaderFactory } from '@app/app.i18n';
import { APP_EFFECTS, APP_STORE } from '@app/app.store';

import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';
import { AppRoutingModule } from '@app/app-routing.module';

import { ANALYTICS_WINDOW, AnalyticWindow } from '@shared/interfaces/window.interface';

import { MarketplaceService } from '@core/services/marketplace/marketplace.service';
import { NotificationService } from '@core/services/notification/notification.service';

import { loadMarketplaceLocale } from '@app/load-marketplace-locale.initializer';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';
import { DoodApiInterceptor } from '@core/interceptors/dood-api/dood-api.interceptor';
import { ensureMarketplaceIsLoaded } from '@app/ensure-marketplace-is-loaded.initializer';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { ReceiveReceiptModalComponent } from '@shared/modals/receive-receipt-modal/receive-receipt-modal.component';

registerLocaleData(localeFr);

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent, ReceiveReceiptModalComponent],
  imports: [
    // Ngrx
    StoreModule.forRoot(APP_STORE),
    EffectsModule.forRoot(APP_EFFECTS),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      serialize: true,
      name: 'Dood -- NgRX',
      logOnly: !environment.production,
      connectInZone: true,
    }),
    // Basics
    FormsModule,
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    // Dood
    CoreModule,
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.withServiceWorkers,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    // Google Maps
    GoogleMapsModule,
    // Stripe
    NgxStripeModule.forRoot(environment.api.stripe.key),
    // Internationalization
    TranslateModule.forRoot({
      loader: {
        deps: [HttpClient],
        provide: TranslateLoader,
        useFactory: AppHttpLoaderFactory,
      },
    }),
  ],
  providers: [
    // Firebase
    provideFirebaseApp(() => initializeApp(environment.api.firebase)),
    provideAuth(() => getAuth()),
    // Ionic
    InAppBrowser,
    // Services
    NotificationService,
    // Interceptors
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: DoodApiInterceptor,
    },
    // Initializers
    {
      multi: true,
      provide: APP_INITIALIZER,
      deps: [MarketplaceService, Injector],
      useFactory: ensureMarketplaceIsLoaded,
    },
    // Misc
    {
      provide: LOCALE_ID,
      deps: [MarketplaceStoreSelector],
      useFactory: loadMarketplaceLocale,
    },
    {
      provide: ANALYTICS_WINDOW,
      useFactory: (): AnalyticWindow => {
        return window as unknown as AnalyticWindow;
      },
    },
    // {
    //   provide: TENANT_ID,
    //   deps: [MarketplaceService],
    //   useFactory: (marketplaceService: MarketplaceService): string | null | undefined => {
    //     return marketplaceService.getFirebaseTenantId();
    //   },
    // },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    { provide: APP_BASE_HREF, useValue: environment.baseUrl },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
