<div
  class="max-w-screen-sm bg-white py-3 flex items-center justify-between"
  [ngClass]="{
    'opacity-30 line-through pointer-events-none': removeInProgress,
  }"
>
  <div
    class="flex"
    (click)="updateItem.emit(itemId)"
  >
    <div
      *ngIf="imgUrl"
      class="w-8"
    >
      <img
        [src]="imgUrl"
        alt="img"
        *ngIf="imgUrl"
        loading="lazy"
      />
    </div>
    <div class="flex items-center">
      <span
        class="pr-2 text-base"
        [class.pl-2]="imgUrl"
      >
        {{ itemName }}
      </span>
      <app-icon-button
        *ngIf="itemId"
        icon="icon-pencil text-base"
        color="neutral"
      ></app-icon-button>
    </div>
  </div>
  <div class="flex items-center text-sm">
    <span class="px-2">{{ quantity }}&times;</span>
    <app-price-atom
      class="px-3"
      [amount]="amount"
    ></app-price-atom>
    <app-icon-button
      (onClick)="onRemove(itemId)"
      icon="icon-cross text-2xs font-bold"
      color="error"
    ></app-icon-button>
  </div>
</div>
