<div
  class="flex max-w-sm items-center bg-primary-600 p-2 text-white rounded-4xl"
  (click)="buttonClick.emit()"
>
  <button class="font-bold bg-primary-800 rounded-full w-10 h-10">
    {{ quantity }}
  </button>
  <p class="flex-1 text-center font-bold">{{ label }}</p>
  <div
    class="mr-3"
    *ngIf="amount"
  >
    <app-price-atom
      [format]="true"
      [amount]="amount"
    ></app-price-atom>
  </div>
</div>
