import { Component, Input, OnInit } from '@angular/core';
import { IContentBuilderFieldImage } from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-image-and-text-block',
  templateUrl: './image-and-text-block.component.html',
})
export class ImageAndTextBlockComponent implements OnInit {
  IMAGE_POSITION_RIGHT = 'right';

  @Input() fullWidth = false;
  @Input() image?: IContentBuilderFieldImage;
  @Input() content?: string;
  @Input() imagePosition?: string;
  @Input() imageVerticalPosition = 'top';
  @Input() textVerticalPosition = 'top';
  @Input() imageSize = 4;
  @Input() roundedImage = false;
  @Input() anchor?: string;
  imageWidthClass?: string;
  textWidthClass?: string;

  constructor() {}

  ngOnInit(): void {
    this.imageWidthClass = 'lg:w-' + this.imageSize + '/12';
    this.textWidthClass = 'lg:w-' + (12 - this.imageSize) + '/12';
  }
}
