import { DateUtils } from 'src/app/shared/utils/date/date.utils';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DateHelperService {
  constructor(private readonly translateService: TranslateService) {
    dayjs.extend(isToday);
  }

  getDaysUntilString(date: dayjs.ConfigType): Observable<string> {
    const dayjsDate = dayjs(date).startOf('day');
    if (dayjsDate.isToday()) {
      return this.translateService.get('days-until-date.today');
    }

    const now = DateUtils.dayjsInMarketplaceTimezone().startOf('day');
    const differenceInDays = Math.abs(dayjsDate.diff(now, 'day'));
    if (dayjsDate.isBefore(now, 'day')) {
      if (differenceInDays === 1) {
        return this.translateService.get('days-until-date.yesterday');
      }
      return this.translateService.get('days-until-date.number-of-days-ago', {
        days: differenceInDays,
      });
    }

    if (differenceInDays === 1) {
      return this.translateService.get('days-until-date.tomorrow');
    }
    return this.translateService.get('days-until-date.in-number-of-days', {
      days: differenceInDays,
    });
  }
}
