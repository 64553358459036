import { Observable, Subject } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { DoodFilterModel } from '@core/models/filters.model';
import { DoodEntityCategory } from '@core/models/entity.model';

import { ModalsService } from '@core/services/modals/modals.service';
import { EntitiesService } from '@core/services/entities/entities.service';

import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { FilterStoreSelector } from '@common/selectors/filter.selector';
import { FilterStoreDispatcher } from '@common/dispatchers/filter.dispatcher';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

@Component({
  selector: 'app-filters-modal',
  templateUrl: './filters-modal.component.html',
})
export class FiltersModalComponent implements OnInit, OnDestroy {
  static handle = 'filters-modal';

  @Input()
  filters!: DoodFilterModel[];

  index$: Observable<number> = this.modalSelector
    .selectModal(FiltersModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  selectedFilters$ = this.filterSelector.selectFilters;

  marketplaceId$: Observable<string> = this.marketplaceSelector.selectMarketplaceId;

  categoryItems!: DoodEntityCategory[];

  protected readonly unsubscribe$ = new Subject<boolean>();

  constructor(
    private modalSelector: ModalStoreSelector,
    private filterSelector: FilterStoreSelector,
    private readonly modalsService: ModalsService,
    private filterDispatcher: FilterStoreDispatcher,
    private readonly entitiesService: EntitiesService,
    private marketplaceSelector: MarketplaceStoreSelector,
  ) {
    this.marketplaceId$
      .pipe(
        take(1),
        switchMap(id => this.entitiesService.loadEntitiesCategories$(id)),
      )
      .subscribe(result => (this.categoryItems = result));
  }

  ngOnInit(): void {
    const _filters = this.filters?.map(filter => {
      return {
        id: filter.id,
        type: filter.type,
      };
    });
    this.filterDispatcher.upsertFilters(_filters);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  closeFilters(): void {
    this.modalsService.close(FiltersModalComponent.handle);
  }

  updateFilters(value: DoodFilterModel): void {
    this.filterDispatcher.upsertFilter(value);
  }

  applyFilters(): void {
    this.modalsService.close(FiltersModalComponent.handle);
  }

  removeFilters(): void {
    this.filterDispatcher.reset();
    this.modalsService.close(FiltersModalComponent.handle);
  }

  selectCategory(category: DoodEntityCategory): void {
    this.filterDispatcher.addCategory(category);
    this.modalsService.close(FiltersModalComponent.handle);
  }
}
