import { Component, Input, OnDestroy } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ModalScrollBlockBase } from '@core/base/modalScrollBlock/modal-scroll-block.base';
import {
  ICartDraftDeclinableProductItem,
  ICartDraftSimpleProductItem,
  ICartItem,
} from '@core/models/cart.model';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { DoodProductModel, IOverridesPrices } from '@core/models/product.model';
import { IAdditionGroup } from '@core/models/shop.model';
import { ModalsService } from '@core/services/modals/modals.service';
import { map, Subject } from 'rxjs';

@Component({
  selector: 'app-kiosk-select-product-modal',
  template: `
    <app-modal-filler-atom
      class="hidden md:block"
      (click)="onCloseModal()"
      [index]="index"
    ></app-modal-filler-atom>
    <div
      class="fixed mx-auto h-full w-full md:bottom-0 md:left-0 md:flex md:flex-col md:items-center md:justify-center mdMax:bottom-0 mdMax:left-0 mdMax:right-0 mdMax:top-0"
      [style.z-index]="index + 100"
    >
      <app-modal-filler-atom
        class="hidden md:block"
        [withBackdrop]="false"
        (click)="onCloseModal()"
        [index]="index"
      ></app-modal-filler-atom>
      <div
        class="relative h-screen w-full overflow-y-auto bg-white p-12 pb-36 scrollbar-hide md:relative md:my-20 md:h-auto md:max-h-[90vh] md:max-w-[90vw] md:rounded-3xl md:pb-0"
        [style.z-index]="index + 50"
        [style.backgroundColor]="backgroundColor | contentBuilderColor"
        [style.color]="textColor | contentBuilderColor"
      >
        <app-kiosk-product-details-organism
          [textColor]="textColor"
          [buttonBackgroundColor]="buttonBackgroundColor"
          [buttonTextColor]="buttonTextColor"
          [secondaryColor]="secondaryColor"
          [backgroundColor]="backgroundColor"
          [primaryButtonBackgroundColor]="primaryButtonBackgroundColor"
          [primaryButtonTextColor]="primaryButtonTextColor"
          [secondaryColor]="secondaryColor"
          [activeShopId]="activeShopId"
          [product]="product"
          [simpleProductAdditionsByGroups]="simpleProductAdditionsByGroups"
          [displayPriceInfo]="displayPriceInfo"
          [simpleProductPriceOverrides]="simpleProductPriceOverrides"
          [initialCart]="initialCart"
          (addToCart)="addToCart()"
        />
        <div
          class="sticky bottom-0 w-full bg-white py-4"
          [style.backgroundColor]="backgroundColor | contentBuilderColor"
        >
          <div class="ml-auto flex flex-row items-center justify-between gap-12">
            <app-button-atom
              size="XXL"
              label="Retour"
              thickSize="L"
              type="kiosk-primary"
              (click)="onCloseModal()"
            ></app-button-atom>
            <app-button-atom
              size="XXL"
              label="Ajouter"
              thickSize="L"
              [overrideBgColor]="primaryButtonBackgroundColor"
              [overrideTextColor]="primaryButtonTextColor"
              type="kiosk-primary"
              [disabled]="!canTheProductBeAdded()"
              (click)="addToCart()"
            ></app-button-atom>
          </div>
        </div>
      </div>
    </div>
  `,
}) // todo rename
export class KioskSelectSimpleProductModalComponent
  extends ModalScrollBlockBase
  implements OnDestroy
{
  static handle = 'kiosk-select-product-modal';

  @Input() cardColor!: IContentBuilderFieldColor;
  @Input() textColor!: IContentBuilderFieldColor;
  @Input() buttonBackgroundColor!: IContentBuilderFieldColor;
  @Input() buttonTextColor!: IContentBuilderFieldColor;
  @Input() backgroundColor!: IContentBuilderFieldColor;
  @Input() primaryButtonBackgroundColor!: IContentBuilderFieldColor;
  @Input() primaryButtonTextColor!: IContentBuilderFieldColor;
  @Input() secondaryColor!: IContentBuilderFieldColor;

  @Input() product!: Readonly<DoodProductModel>;
  @Input() activeShopId!: string;
  @Input() simpleProductAdditionsByGroups!: IAdditionGroup[]; // Only used by simple products
  @Input() maxProductQuantity: number | undefined;
  @Input() displayPriceInfo!: boolean;
  @Input() simpleProductPriceOverrides: IOverridesPrices | undefined;
  @Input() initialCart: ICartItem | undefined;
  @Input() validityChange!: (isValid: { isValid: boolean }) => void;
  @Input() cartChange!: (
    cart: ICartDraftSimpleProductItem | ICartDraftDeclinableProductItem,
  ) => void;
  @Input() addToCart!: () => void;
  @Input() modalClose!: () => void;

  protected index = 0;

  constructor(private readonly modalsService: ModalsService) {
    super();
  }
  protected readonly _validityChange$ = new Subject<{ isValid: boolean }>(); // the output validityChange is override by the modal service

  protected readonly canTheProductBeAdded = toSignal(
    this._validityChange$.pipe(map(v => v.isValid)),
    {
      initialValue: false,
    },
  );

  protected onCloseModal() {
    this.modalsService.close(KioskSelectSimpleProductModalComponent.handle);
  }

  ngOnDestroy(): void {
    this.modalClose();
  }
}
