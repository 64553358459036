import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ComoService } from '@core/services/como/como.service';
import { Subject } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ComoAsset, ComoUser } from '@core/models/como.model';
import { CartService } from '@core/services/cart/cart.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { BasketService } from '../../../../core/services/basket/basket.service';

@Component({
  selector: 'app-como-asset-list-block',
  templateUrl: './como-asset-list-block.component.html',
})
export class ComoAssetListBlockComponent implements OnInit, OnDestroy {
  @Input() heading = 'como.loyalty-rewards';
  @Input() subheading?: string;
  @Input() displayCredits = true;
  @Input() displayPoints = true;
  @Input() displayRedeemableGifts = true;
  @Input() displayNotRedeemableGifts = true;
  @Input() displayRedeemablePointShop = true;
  @Input() displayNotRedeemablePointShop = true;
  @Input() displayNotUsablePointShop = true;

  public comoUser?: ComoUser | null;

  private readonly destroyed$ = new Subject<boolean>();

  constructor(
    private readonly comoService: ComoService,
    private readonly cartService: CartService,
    private readonly basketService: BasketService,
    private readonly ordersService: OrdersService,
  ) {}

  ngOnInit(): void {
    this.comoService.loadComoUser();
    this.comoService
      .getComoUser$()
      .pipe(
        takeUntil(this.destroyed$),
        tap(comoUser => (this.comoUser = comoUser)),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onAssetAdd(asset: ComoAsset): void {
    this.comoService.confirmUsePointToAddAsset(asset);
  }

  onAssetRemove($event: ComoAsset): void {
    const coupon = { key: $event.key };
    this.cartService.removeCoupon(coupon);
    this.basketService
      .removeBasketCoupon$(coupon)
      .pipe(
        switchMap(() => this.ordersService.checkCartIsValid$()),
        tap(() => this.updateCart()),
      )
      .subscribe();
  }

  private updateCart(): void {
    this.ordersService.checkCartIsValid$().pipe(take(1)).subscribe();
  }
}
