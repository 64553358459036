import { Update } from '@ngrx/entity';
import { ActionGroup } from '@store/_abstract/common/action.creator';
import { ActionCreatorProps, createActionGroup, emptyProps, props } from '@ngrx/store';

import { ActiveStoreActionsKeys } from '@store/_abstract/active/active.action';

export enum FunnelStoreActionsKeys {
  // Step
  addStep = 'Add Step',
  addSteps = 'Add Steps',
  removeStep = 'Remove Step',
  removeSteps = 'Remove Steps',
  updateStep = 'Update Step',
  updateSteps = 'Update Steps',
  resetSteps = 'Reset Steps',
  // Value
  addValue = 'Add Value',
  addValues = 'Add Values',
  removeValue = 'Remove Value',
  removeValues = 'Remove Values',
  updateValue = 'Update Value',
  updateValues = 'Update Values',
  resetValues = 'Reset Values',
  // Misc
  reset = 'Reset',
}

export type FunnelStoreActions<Step, Value, Id> = {
  // Step
  [FunnelStoreActionsKeys.addStep]: ActionCreatorProps<{ step: Step }>;
  [FunnelStoreActionsKeys.addSteps]: ActionCreatorProps<{ steps: Step[] }>;
  [FunnelStoreActionsKeys.removeStep]: ActionCreatorProps<{ id: string }>;
  [FunnelStoreActionsKeys.removeSteps]: ActionCreatorProps<{ ids: string[] }>;
  [FunnelStoreActionsKeys.updateStep]: ActionCreatorProps<{ step: Update<Step> }>;
  [FunnelStoreActionsKeys.updateSteps]: ActionCreatorProps<{ steps: Update<Step>[] }>;
  [FunnelStoreActionsKeys.resetSteps]: ActionCreatorProps<void>;

  // Value
  [FunnelStoreActionsKeys.addValue]: ActionCreatorProps<{ value: Value }>;
  [FunnelStoreActionsKeys.addValues]: ActionCreatorProps<{ values: Value[] }>;
  [FunnelStoreActionsKeys.removeValue]: ActionCreatorProps<{ id: string }>;
  [FunnelStoreActionsKeys.removeValues]: ActionCreatorProps<{ ids: string[] }>;
  [FunnelStoreActionsKeys.updateValue]: ActionCreatorProps<{ value: Update<Value> }>;
  [FunnelStoreActionsKeys.updateValues]: ActionCreatorProps<{ values: Update<Value>[] }>;
  [FunnelStoreActionsKeys.resetValues]: ActionCreatorProps<void>;

  // Active
  [ActiveStoreActionsKeys.resetActive]: ActionCreatorProps<void>;
  [ActiveStoreActionsKeys.setActive]: ActionCreatorProps<{ active: Id | null }>;
  [ActiveStoreActionsKeys.updateActive]: ActionCreatorProps<{ step: Partial<Step> }>;
  [ActiveStoreActionsKeys.insertActive]: ActionCreatorProps<{ step: Step }>;
  [ActiveStoreActionsKeys.upsertActive]: ActionCreatorProps<{ changes: Update<Step> }>;

  // Misc
  [FunnelStoreActionsKeys.reset]: ActionCreatorProps<void>;
};

export type FunnelStoreActionGroup<S, V, Id> = ActionGroup<string, FunnelStoreActions<S, V, Id>>;

export const FUNNEL_STORE_KEY = 'Dood/FUNNEL';

const createFunnelStoreSource = <Source extends string>(name: string): Source => {
  return `${FUNNEL_STORE_KEY}/${name}` as Source;
};

export const createFunnelStoreActions = <Step, Value, Id = string>(
  name: string,
): FunnelStoreActionGroup<Step, Value, Id> => {
  return createActionGroup({
    source: createFunnelStoreSource(name),
    events: {
      // Step
      [FunnelStoreActionsKeys.addStep]: props<{ step: Step }>(),
      [FunnelStoreActionsKeys.addSteps]: props<{ steps: Step[] }>(),
      [FunnelStoreActionsKeys.removeStep]: props<{ id: string }>(),
      [FunnelStoreActionsKeys.removeSteps]: props<{ ids: string[] }>(),
      [FunnelStoreActionsKeys.updateStep]: props<{ step: Update<Step> }>(),
      [FunnelStoreActionsKeys.updateSteps]: props<{ steps: Update<Step>[] }>(),
      [FunnelStoreActionsKeys.resetSteps]: emptyProps(),

      // Value
      [FunnelStoreActionsKeys.addValue]: props<{ value: Value }>(),
      [FunnelStoreActionsKeys.addValues]: props<{ values: Value[] }>(),
      [FunnelStoreActionsKeys.removeValue]: props<{ id: string }>(),
      [FunnelStoreActionsKeys.removeValues]: props<{ ids: string[] }>(),
      [FunnelStoreActionsKeys.updateValue]: props<{ value: Update<Value> }>(),
      [FunnelStoreActionsKeys.updateValues]: props<{ values: Update<Value>[] }>(),
      [FunnelStoreActionsKeys.resetValues]: emptyProps(),

      // Active
      [ActiveStoreActionsKeys.setActive]: props<{ active: Id | null }>(),
      [ActiveStoreActionsKeys.resetActive]: emptyProps(),
      [ActiveStoreActionsKeys.updateActive]: props<{ step: Partial<Step> }>(),
      [ActiveStoreActionsKeys.insertActive]: props<{ step: Step }>(),
      [ActiveStoreActionsKeys.upsertActive]: props<{ changes: Update<Step> }>(),

      // Misc
      [FunnelStoreActionsKeys.reset]: emptyProps(),
    },
  });
};
