<!-- <app-back-header-atom
  icon="arrow-left"
  [text]="'cart-funnel.title' | translate"
  (navigationClick)="back()"
></app-back-header-atom> -->
<app-kiosk-header-atom
  [logoImageUrl]="logo?.url"
  [displayLogo]="displayLogo"
  [backgroundColor]="backgroundColor"
  [backgroundImage]="backgroundImage"
></app-kiosk-header-atom>
