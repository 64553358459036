import { Component, Input, OnInit } from '@angular/core';

import { ColorFieldTypesValues } from '@config/values/color-field-types.values';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-header-button-block',
  templateUrl: './header-button-block.component.html',
})
export class HeaderButtonBlockComponent implements OnInit {
  @Input() label?: string;
  @Input() linkUrl?: string;
  @Input() labelMobile?: string;
  @Input() displayOnMobile = false;
  @Input() displayOnDesktop = true;
  @Input() backgroundColor: IContentBuilderFieldColor = {
    value: 'neutral-100',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() textColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() fontSize = 'sm';
  @Input() fontWeight = 'normal';

  constructor() {}

  ngOnInit(): void {
    if (this.displayOnMobile && !this.labelMobile) {
      this.labelMobile = this.label;
    }
  }
}
