<div
  class="min-h-screen cursor-pointer bg-cover bg-center"
  [ngStyle]="getBackgroundStyles()"
  (click)="redirect()"
>
  <div
    class="text-primary fixed bottom-0 left-0 right-0 flex min-h-400px flex-col items-center justify-center gap-10 px-10 pb-14.5 pt-20 text-center"
    [style.backgroundColor]="backgroundColor | contentBuilderColor"
    [style.color]="textColor | contentBuilderColor"
  >
    <button
      type="button"
      class="mb-10 text-5xl font-bold leading-10"
    >
      {{ labelCallToAction || 'kiosk.splash-screen-page.default-landing-message' | translate }}
    </button>
    <app-kiosk-select-language-block></app-kiosk-select-language-block>
    <button
      type="button"
      class="hidden text-2xl underline"
      *ngIf="displayAccessibilityButton"
      (click)="onInnerClick($event)"
    >
      {{
        labelAccessibilityButton || 'kiosk.splash-screen-page.default-accessibility-message'
          | translate
      }}
    </button>
  </div>
</div>
