import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-fat-button-atom',
  templateUrl: './fat-button-atom.component.html',
})
export class FatButtonAtomComponent {
  @Input() backgroundColor!: string;
  @Input() backgroundImage!: string;
  @Input() headingSize: 'S' | 'M' | 'L' = 'M';
  @Input() topText!: string;
  @Input() bottomText!: string;

  @Output() buttonClick = new EventEmitter<Event>();
}
