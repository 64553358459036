import { DeviceUtils } from '@app/utilities/device.util';
import { DoodMarketplaceLocale } from '@core/models/marketplace.model';
import { DoodAppDevice, DoodAppPlatform, DoodAppScreen } from '@store/order/order.model';
import { DoodLocationModel, DoodLocationParameters, DoodWantedAtType } from './settings.model';
import { HydrationState, createHydratedAdapter } from '@store/_abstract/hydrated';

export interface SettingsParametersState {
  last_update: Date;
  wanted_at: Date | null;
  distance: number | null;
  distribution_mode: string;
  is_default_location: boolean;
  location: DoodLocationModel | null;
  is_distribution_mode_defined: boolean;
  force_distribution_mode: string | null;
  wanted_at_type: DoodWantedAtType | null;
  location_params: DoodLocationParameters | null;
  marketplace_locale: DoodMarketplaceLocale | null;
}

export interface SettingsDeviceState {
  screen: DoodAppScreen;
  device: DoodAppDevice;
  platform: DoodAppPlatform;
}

export interface SettingsAppState {
  locale: string;
}

export interface SettingsKioskState {
  id: string | null;
  isKioskLayout: boolean;
}

export interface SettingsState extends HydrationState {
  app: SettingsAppState;
  kiosk: SettingsKioskState;
  device: SettingsDeviceState;
  parameters: SettingsParametersState;
}

export const SETTINGS_STORE_KEY = 'Settings';

export const SETTINGS_HYDRATION_STORE_ADAPTER = createHydratedAdapter();

export const SETTINGS_STORE_INITIAL_DEVICE_STATE: SettingsDeviceState = {
  device: DeviceUtils.getDeviceType(),
  platform: DeviceUtils.getPlatform(),
  screen: DeviceUtils.getDeviceScreenType(),
};

export const SETTINGS_STORE_INITIAL_APP_STATE: SettingsAppState = {
  locale: 'fr-FR',
};

export const SETTINGS_STORE_INITIAL_KIOSK_STATE: SettingsKioskState = {
  id: null,
  isKioskLayout: false,
};

export const SETTINGS_STORE_INITIAL_PARAMETERS_STATE: SettingsParametersState = {
  distance: null,
  location: null,
  wanted_at: null,
  wanted_at_type: null,
  location_params: null,
  distribution_mode: '',
  last_update: new Date(),
  marketplace_locale: null,
  is_default_location: false,
  force_distribution_mode: null,
  is_distribution_mode_defined: false,
};

export const SETTINGS_STORE_INITIAL_STATE: SettingsState =
  SETTINGS_HYDRATION_STORE_ADAPTER.getInitialState({
    app: SETTINGS_STORE_INITIAL_APP_STATE,
    kiosk: SETTINGS_STORE_INITIAL_KIOSK_STATE,
    device: SETTINGS_STORE_INITIAL_DEVICE_STATE,
    parameters: SETTINGS_STORE_INITIAL_PARAMETERS_STATE,
  });
