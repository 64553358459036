<div
  class="fixed h-full top-0 bottom-0 left-0 right-0 bg-neutral-50 z-50 flex items-center justify-center overflow-hidden">
  <div class="relative h-20 w-full flex items-center justify-center">
    <p class="absolute text-xl font-medium transition-all duration-250 ease-in-out text-center px-10"
       [ngClass]="{
         'opacity-0 -translate-y-5': isFadingOut,
         'opacity-0 translate-y-5': isFadingIn,
         'opacity-100 translate-y-0': !isFadingOut && !isFadingIn
       }">
      {{ messages[currentIndex] | translate }}
    </p>
  </div>
</div>
