import { Component, OnInit } from '@angular/core';
import { ModalsService } from '@core/services/modals/modals.service';
import { ModalScrollBlockBase } from '@core/base/modalScrollBlock/modal-scroll-block.base';
import { ComoCreateOrLinkAccountModalComponent } from '../como-create-or-link-account-modal/como-create-or-link-account-modal.component';

@Component({
  selector: 'app-como-link-account-modal',
  templateUrl: './como-link-account-modal.component.html',
})
export class ComoLinkAccountModalComponent extends ModalScrollBlockBase implements OnInit {
  static handle = 'como-link-account-modal';

  constructor(private readonly modalsService: ModalsService) {
    super();
  }

  ngOnInit(): void {
    this.modalsService.open(ComoCreateOrLinkAccountModalComponent.handle);
    this.modalsService.close(ComoLinkAccountModalComponent.handle);
  }
}
