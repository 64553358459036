import { getHydratedStoreData, setHydratedStoreData } from './hydrated.storage';

export const HYDRATED_STORE_CACHE_KEY = '$cache';
export const HYDRATED_STORE_CACHE_DEFAULT_TTL = 3 * 3600 * 1000;

export const isHydratedStoreCacheExpired = (key: string): boolean => {
  const _now = Date.now();
  const _end = getHydratedStoreCacheTtl(key) ?? 0;
  return _end ? _end <= _now : true;
};

export const checkHydratedStoreCache = (
  key: string,
  ttl: number = HYDRATED_STORE_CACHE_DEFAULT_TTL,
): void => {
  if (isHydratedStoreCacheExpired(key)) {
    cleanHydratedStoreCacheTtl(key);
  } else {
    setHydratedStoreCacheTtl(key, ttl);
  }
};

export const getHydratedStoreCacheTtl = (key: string): number | undefined => {
  const _cache = getHydratedStoreData<Record<string, number>>(HYDRATED_STORE_CACHE_KEY) ?? {};
  return _cache[key];
};

export const setHydratedStoreCacheTtl = (
  key: string,
  ttl: number = HYDRATED_STORE_CACHE_DEFAULT_TTL,
): void => {
  const _cache = getHydratedStoreData<Record<string, number>>(HYDRATED_STORE_CACHE_KEY) ?? {};
  _cache[key] = Date.now() + ttl;
  setHydratedStoreData(HYDRATED_STORE_CACHE_KEY, _cache);
};

export const cleanHydratedStoreCacheTtl = (key: string): void => {
  const _cache = getHydratedStoreData<Record<string, number>>(HYDRATED_STORE_CACHE_KEY);
  setHydratedStoreData(HYDRATED_STORE_CACHE_KEY, { ..._cache, [key]: null });
};
