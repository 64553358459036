import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {DoodProductModel, IProductLabel} from '@core/models/product.model';
import {ProductService} from '@core/services/product/product.service';
import {ProductKeys} from '@config/keys/product.keys';
import {IContentBuilderFieldColor} from '@core/models/content-builder-fields.model';
import {ColorFieldTypesValues} from '@config/values/color-field-types.values';
import {ContentBuilderFieldColors} from '@shared/interfaces/content-builder.interface';
import {combineLatest} from 'rxjs';
import {MarketplaceStoreSelector} from '@common/selectors/marketplace.selector';
import {ProductTrackerService} from "@core/services/app/product-tracker.service";

@Component({
  selector: 'app-product-push-v2-atom',
  templateUrl: './product-push-v2-atom.component.html',
  styleUrls: ['./product-push-v2-atom.component.scss'],
})
export class ProductPushV2AtomComponent implements OnInit, OnChanges {
  @Input() displayContext = '';
  @Input() product!: DoodProductModel;
  @Input() isOrderingAllowed = true;
  @Input() productNameColor: IContentBuilderFieldColor = {
    value: 'neutral-900',
    type: ColorFieldTypesValues.Hex,
  };
  @Input() productDescriptionColor: IContentBuilderFieldColor = {
    value: 'neutral-900',
    type: ColorFieldTypesValues.Hex,
  };
  @Input() productPriceColor: IContentBuilderFieldColor = {
    value: 'neutral-900',
    type: ColorFieldTypesValues.Hex,
  };
  @Input() productImagePlaceholderColor: IContentBuilderFieldColor = {
    value: 'neutral-50',
    type: ColorFieldTypesValues.Hex,
  };
  @Input({required: true}) tagColors!: ContentBuilderFieldColors;
  @Output() buttonClick = new EventEmitter<DoodProductModel>();

  get productLabels(): IProductLabel[] {
    return this.product[ProductKeys.Labels] ?? [];
  }

  isAvailable!: boolean;

  private readonly filterColors$ =
    this.marketplaceSelector.selectMarketPlaceProductLabelsFilterColors;

  protected readonly vm$ = combineLatest({
    filterColors: this.filterColors$,
  });

  constructor(
    private readonly productService: ProductService,
    private readonly marketplaceSelector: MarketplaceStoreSelector,
    private readonly productTrackerService: ProductTrackerService,
  ) {
  }

  ngOnInit(): void {
    this.productTrackerService.trackProductDisplayed(this.product, this.displayContext);
  }

  public get ProductKeys(): typeof ProductKeys {
    return ProductKeys;
  }

  goToProduct(product: DoodProductModel): void {
    if (this.isAvailable) {
      this.buttonClick.emit(product);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.product) {
      this.checkIsAvailable();
    }
  }

  checkIsAvailable(): void {
    this.isAvailable = this.productService.isAvailable(this.product);
  }

  displayDiscount(product: DoodProductModel): boolean {
    const discount = product[ProductKeys.Discount];
    return (discount && discount > 0) === true;
  }

  forceType(data: any): any {
    return data as any;
  }
}
