<a
  [link]="linkUrl"
  class="cursor-pointer"
  *ngIf="!isOverlay && !isSplashScreenHidden"
>
  <img
    [src]="image?.url"
    class="w-full object-contain h-screen"
  />
</a>
<a
  [link]="linkUrl"
  (click)="validateSplashScreen()"
  *ngIf="isOverlay && !isSplashScreenHidden"
  class="z-100 fixed h-full w-full cursor-pointer top-0 left-0"
  [style.background]="backgroundColor | contentBuilderColor: 'neutral-100'"
>
  <img
    [src]="image?.url"
    class="w-full object-contain h-screen"
  />
</a>
