<div class="flex flex-col gap-14">
  <label
    class="text-center text-2.5xl font-bold"
    [style.color]="textColor | contentBuilderColor"
  >
    {{ title }}
    <span *ngIf="required">&#42;</span>
  </label>
  <app-kiosk-input-multi-atom
    [type]="'text'"
    [placeholder]="placeholder"
    [value]="forceType(value)"
    [textColor]="textColor"
    [color]="color"
    [isOverFooter]="true"
    (valueEmitter)="onValueChange($event)"
  ></app-kiosk-input-multi-atom>
</div>
