<div class="container relative w-full">
  <div class="relative">
    <div class="relative">
      <div
        class="flag-wrapper absolute left-0 cursor-pointer"
        [ngClass]="
          alternativeStyle ? 'border-neutral-800 bg-white' : 'bg-neutral-50 border-neutral-300'
        "
        (click)="toggleCountriesList()"
        [class.active]="inputActive"
      >
        <div class="selected-flags">
          <div
            class="flag"
            [ngClass]="[selectedIso ? 'iti__' + selectedIso : '']"
          ></div>
          <div class="flag-arrow"></div>
        </div>
      </div>
      <input
        id="phone"
        type="text"
        (input)="onChangePhone()"
        [(ngModel)]="phone"
        (focusin)="detectActive()"
        (focusout)="detectActive()"
        class="flex w-full border border-solid px-5 py-8xs pl-14 font-base text-base font-normal leading-5"
        [ngClass]="
          alternativeStyle ? 'border-neutral-800 bg-white' : 'border-neutral-300 bg-neutral-50'
        "
        autocomplete="tel"
        pattern="\+[0-9\s\-]+"
        placeholder="+3361234568"
      />
    </div>
  </div>
  <div
    *ngIf="listOpen"
    class="countries-list absolute z-10 w-full border border-neutral-300 bg-white"
    [ngClass]="listOnTop ? 'bottom-full' : ''"
  >
    <div
      *ngFor="let country of countries"
      class="cursor-pointer px-0.5 py-2 hover:bg-neutral-200"
      (click)="selectCountry(country?.iso2)"
    >
      <span> {{ country?.name }}</span>
    </div>
  </div>
</div>
