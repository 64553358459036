<div class="">
  <div
    class="flex flex-col pt-2 lg:pt-4 pb-2 px-5 lg:px-12"
    *ngIf="heading || cta || description"
  >
    <div class="flex justify-between items-center">
      <h2
        class="font-brand text-2xl lg:text-4xl"
        [style.color]="categoryNameColor | contentBuilderColor"
        *ngIf="heading"
      >
        {{ heading }}
      </h2>
      <a
        (click)="ctaClick.emit()"
        *ngIf="cta"
        class="text-primary-600 font-sm font-bold cursor-pointer"
      >
        {{ cta }}
        <i class="icon icon-arrow-right icon-16"></i>
      </a>
    </div>
    <p
      *ngIf="description"
      class="mt-3 text-sm md:text-base font-base"
      [style.color]="categoryDescriptionColor | contentBuilderColor"
    >
      {{ description }}
    </p>
  </div>
  <ul
    *ngIf="!isDesktop"
    class="flex overflow-x-auto scrollbar-hide mt-4 pb-8 px-5 lg:px-12"
  >
    <li
      class="mr-3 md:mr-4 lg:mr-6 xl:mr-8 min-w-10 w-10 lg:min-w-52 lg:w-52 flex-shrink-0 flex-grow-0"
      *ngFor="let product of products"
    >
      <app-product-card-atom
        [displayContext]="displayContext"
        [product]="product"
        [isOrderingAllowed]="isOrderingAllowed"
        [productNameColor]="productNameColor"
        [productPriceColor]="productPriceColor"
        [productImagePlaceholderColor]="productImagePlaceholderColor"
        (productClick)="productClick.emit(product)"
      ></app-product-card-atom>
    </li>
  </ul>
  <div
    *ngIf="isDesktop"
    class="w-full mt-4 pb-8 px-5 lg:px-12"
  >
    <app-card-carousel
      [cards]="products"
      [nbDisplayCards]="6"
      [carouselItem]="productTemplate"
    ></app-card-carousel>

    <ng-template
      #productTemplate
      let-product
    >
      <div class="flex-1 items-stretch">
        <app-product-card-atom
          [displayContext]="displayContext"
          [product]="product"
          [isOrderingAllowed]="isOrderingAllowed"
          [productNameColor]="productNameColor"
          [productPriceColor]="productPriceColor"
          [productImagePlaceholderColor]="productImagePlaceholderColor"
          (productClick)="productClick.emit(product)"
        ></app-product-card-atom>
      </div>
    </ng-template>
  </div>
</div>
