import { Router } from '@angular/router';
import { Directive, HostBinding, HostListener, Input, OnChanges } from '@angular/core';

import { ModalsService } from '@core/services/modals/modals.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';

@Directive({
  selector: 'a[link], app-button-atom[link]',
})
export class LinkDirective implements OnChanges {
  @HostBinding('attr.href') hrefAttr: string | undefined = '';
  @Input() link?: string;

  constructor(
    private readonly router: Router,
    private readonly routerHelper: RouterHelperService,
    private readonly modalsService: ModalsService,
  ) {}

  ngOnChanges(): void {
    this.hrefAttr = this.link ? (this.routerHelper.translateRoute(this.link) as string) : this.link;
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    if (!this.link) {
      return;
    } else if (this.modalsService.isModalLink(this.link)) {
      event.preventDefault();
      this.modalsService.openFromLink(this.link);
    } else if (this.routerHelper.isAnchor(this.link)) {
      event.preventDefault();
      this.router.navigate([], { fragment: this.link?.substring(1) });
    } else if (!event.ctrlKey && !this.routerHelper.isLinkExternal(this.link)) {
      event.preventDefault();
      this.router.navigate([this.link ? this.routerHelper.translateRoute(this.link) : this.link]);
    }
  }
}
