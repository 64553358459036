import { Component, Input } from '@angular/core';
import { CartFunnelStoreDispatcher } from '@common/dispatchers/cart-funnel.dispatcher';
import { CartFunnelStoreSelector } from '@common/selectors/cart-funnel.selector';
import { OnBehalfType } from '@config/keys/on-behalf-type.keys';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { DoodCartFunnelValueTarget } from '@store/cart-funnel';
import { take } from 'rxjs';

@Component({
  selector: 'app-kiosk-cart-funnel-on-behalf-field-block',
  templateUrl: './kiosk-cart-funnel-on-behalf-field-block.component.html',
})
export class KioskCartFunnelOnBehalfFieldBlockComponent {
  @Input() onBehalfInputType!: OnBehalfType;
  @Input() title!: string;
  @Input() placeholder?: string;
  @Input() required: boolean = false;
  @Input() blockId!: string;
  @Input() formId!: string;
  @Input() textColor?: IContentBuilderFieldColor;
  @Input() color?: IContentBuilderFieldColor;

  value: string | boolean = '';

  private readonly cartFunnelTargetValueMapping = {
    [OnBehalfType.Phone]: DoodCartFunnelValueTarget.OnBehalfUserPhone,
    [OnBehalfType.Fullname]: DoodCartFunnelValueTarget.OnBehalfUserFullName,
    [OnBehalfType.Email]: DoodCartFunnelValueTarget.OnBehalfUserEmail,
    [OnBehalfType.Optin]: DoodCartFunnelValueTarget.OnBehalfUserOptin,
  };

  constructor(
    private cartFunnelSelector: CartFunnelStoreSelector,
    private cartFunnelDispatcher: CartFunnelStoreDispatcher,
  ) {}

  ngOnInit(): void {
    this.cartFunnelDispatcher.addValue({
      id: this.blockId,
      title: this.title,
      displayTitle: true,
      formId: this.formId,
      required: this.required,
      target: this.getTargetForOnBehalfType(),
    });

    this.cartFunnelSelector
      .selectValue(this.blockId)
      .pipe(take(1))
      .subscribe(form => {
        this.value =
          this.onBehalfInputType === OnBehalfType.Optin
            ? Boolean(form?.value)
            : (form?.value?.toString() ?? '');
      });
  }

  private getTargetForOnBehalfType(): DoodCartFunnelValueTarget {
    return this.cartFunnelTargetValueMapping[this.onBehalfInputType];
  }

  onValueChange(_value: string | boolean): void {
    this.cartFunnelDispatcher.updateValue({
      id: this.blockId,
      changes: {
        value: _value,
      },
    });
  }

  forceType(data: any): any {
    return data as any;
  }
}
