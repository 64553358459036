<app-back-header-atom
  *ngIf="displayHeader"
  [icon]="displayHeaderBack ? 'arrow-left' : undefined"
  [text]="(headerText ?? 'pay-at-table.header') | translate"
  (navigationClick)="goBack()"
></app-back-header-atom>
<router-outlet></router-outlet>
<app-pat-base-modal *ngIf="error">
  <p class="font-base text-error-600">
    {{ error }}
  </p>
</app-pat-base-modal>
