import { createReducer } from '@ngrx/store';

import {
  PRODUCT_STORE_ACTIVE_PIECE,
  PRODUCT_STORE_API_PIECE,
  PRODUCT_STORE_LOCAL_PIECE,
} from './product.piece';
import { PRODUCT_STORE_INITIAL_STATE } from './product.state';

export const PRODUCT_STORE_REDUCER = createReducer(
  PRODUCT_STORE_INITIAL_STATE,
  ...PRODUCT_STORE_ACTIVE_PIECE,
  ...PRODUCT_STORE_API_PIECE,
  ...PRODUCT_STORE_LOCAL_PIECE,
);
