import { createFeature, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';

import { DoodEntityModel } from '@core/models/entity.model';

import { ENTITY_STORE_REDUCER } from './entity.reducer';
import { ENTITY_STORE_KEY, ENTITY_STORE_ADAPTER, DoodEntityState } from './entity.state';

const ENTITY_STORE_FEATURE = createFeature({
  name: ENTITY_STORE_KEY,
  reducer: ENTITY_STORE_REDUCER,
});

const { selectEntitiesState: SELECT_ENTITIES_STATE } = ENTITY_STORE_FEATURE;

export const { selectIds: SELECT_ENTITIES_IDS, selectEntities: SELECT_ENTITIES_ENTITIES } =
  ENTITY_STORE_FEATURE;

export const { selectAll: SELECT_ENTITIES_ALL } =
  ENTITY_STORE_ADAPTER.getSelectors(SELECT_ENTITIES_STATE);

export const SELECT_ENTITIES_ENTITY = (
  id: string,
): MemoizedSelector<
  Record<string, any>,
  DoodEntityModel | undefined,
  DefaultProjectorFn<DoodEntityModel | undefined>
> =>
  createSelector(SELECT_ENTITIES_STATE, (state: DoodEntityState) => {
    return state.entities[id];
  });
