import { Component, OnInit } from '@angular/core';
import { combineLatest, of, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { catchError, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { DestroyerBase } from '@core/base/destroyer/destroyer.base';

import { IStripePaymentIntent } from '@core/models/payment.model';
import { IEdenredGetUserResponse } from '@core/models/edenred.model';

import { EdenredService } from '@core/services/edenred/edenred.service';
import { PaymentService } from '@core/services/payment/payment.service';
import { PaymentStoreSelector } from '@common/selectors/payment.selector';
import { PaymentStoreDispatcher } from '@common/dispatchers/payment.dispatcher';

@Component({
  selector: 'app-pat-payment-panel-edenred',
  templateUrl: './pat-payment-panel-edenred.component.html',
})
export class PatPaymentPanelEdenredComponent extends DestroyerBase implements OnInit {
  isLoading = true;
  balance?: number;
  isUserLoggedIn = false;
  ssoEdenredUrl!: string;
  isTransactionMandatory = false;
  processingError: string | null = null;
  paymentIntent: IStripePaymentIntent | null = null;

  constructor(
    private paymentSelector: PaymentStoreSelector,
    private readonly edenredService: EdenredService,
    private readonly paymentService: PaymentService,
    private paymentDispatcher: PaymentStoreDispatcher,
    private readonly translateService: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.processingError = null;

    combineLatest([
      this.edenredService.getUser$(),
      this.paymentService.paymentIntent$,
      this.paymentService.isTransactionMandatory$,
    ])
      .pipe(
        takeUntil(this._destroyerRef),
        take(1),
        tap(([, paymentIntent, isTransactionMandatory]) => {
          this.paymentIntent = paymentIntent;
          this.isTransactionMandatory = isTransactionMandatory;
        }),
        tap(([user]) => this.handleGetUserResponse(user)),
        tap(() => (this.isLoading = false)),
        catchError(err => {
          return throwError(() => err);
        }),
      )
      .subscribe();
  }

  private handleGetUserResponse(edenredUser: IEdenredGetUserResponse): void {
    if (edenredUser?.balance) {
      this.checkBalance(edenredUser.balance.available_amount);
      this.isUserLoggedIn = true;
      return;
    }

    let redirectUri = document.location.href + '?payment_method=EDENRED';

    if (!this.isTransactionMandatory) {
      this.edenredService
        .edenredWebLogin$(redirectUri)
        .pipe(
          take(1),
          tap(response => {
            if (response.urledenred) {
              this.ssoEdenredUrl = response.urledenred;
            }
          }),
        )
        .subscribe();
    } else {
      // TODO: Check leak ?
      this.paymentSelector.selectTransaction
        .pipe(
          takeUntil(this._destroyerRef),
          switchMap(({ id }) => {
            if (!id) {
              return of(null);
            }

            redirectUri = `${document.location.href}?payment_method=EDENRED&transaction=${id}`;
            redirectUri = encodeURIComponent(redirectUri);

            return this.edenredService.edenredWebLogin$(redirectUri);
          }),
        )
        .subscribe(response => {
          if (response?.urledenred) {
            this.ssoEdenredUrl = response.urledenred;
          }
        });
    }
  }

  private checkBalance(balance: number): void {
    let orderFinalPrice = 0;
    if (this.paymentIntent) {
      orderFinalPrice = 0 || 0;
    }
    this.balance = balance;

    if (balance < orderFinalPrice) {
      this.translateService
        .get(['payment.edenred-picker.insufficient-balance'])
        .pipe(take(1))
        .subscribe(translation => {
          this.processingError = translation['payment.edenred-picker.insufficient-balance'];
        });
      this.paymentDispatcher.updateUI({
        button: {
          isEnabled: false,
        },
        status: {
          isInProgress: false,
        },
      });
    } else {
      this.processingError = null;
      this.paymentDispatcher.updateUI({
        button: {
          isEnabled: true,
        },
      });
    }
    this.isLoading = false;
  }

  redirectToSsoEdenred(): void {
    window.location.href = this.ssoEdenredUrl;
  }
}
