import { Component, Input } from '@angular/core';
import { CartFunnelStoreDispatcher } from '@common/dispatchers/cart-funnel.dispatcher';
import { ContentStoreSelector } from '@common/selectors/content.selector';

@Component({
  selector: 'app-cart-funnel-select-block',
  template: `
    <div class="pt-4">
      <label class="block mt-5 mb-3 font-bold font-accent md:mt-6 text-md">
        {{ title }}
        <span *ngIf="required">&#42;</span>
      </label>
      <label class="block max-w-screen-sm">
        <select
          class="form-select pl-4 py-2.5 block w-full mt-1 bg-neutral-50 font-base text-base font-normal border-neutral-300"
          [ngClass]="
            radiusType === 'SM'
              ? 'rounded-sm'
              : radiusType === 'MD'
                ? 'rounded-md'
                : radiusType === 'LG'
                  ? 'rounded-lg'
                  : 'rounded-full'
          "
          [(ngModel)]="selectedItem"
          (change)="onValueChange(selectedItem)"
        >
          @if (placeholder) {
            <option
              [value]="'0'"
              disabled
              selected
            >
              {{ placeholder }}
            </option>
          }
          <option
            *ngFor="let option of optionsList"
            [value]="option.value"
          >
            {{ option.label }}
          </option>
        </select>
      </label>
    </div>
  `,
})
export class CartFunnelSelectBlockComponent {
  @Input() title: string = '';
  @Input() options?: any;
  @Input() required: boolean = true;
  @Input() blockId!: string;
  @Input() formId!: string;
  @Input() displayTitleInComment: boolean = true;
  @Input() placeholder?: string;
  @Input() radiusType: 'SM' | 'MD' | 'LG' | 'FULL' = 'FULL';

  selectedItem: string = '0';
  optionsList: { value: string; label: string }[] = [];

  constructor(
    private cartFunnelDispatcher: CartFunnelStoreDispatcher,
    private readonly contentSelector: ContentStoreSelector,
  ) {}

  ngOnInit(): void {
    this.cartFunnelDispatcher.addValue({
      id: this.blockId,
      title: this.title,
      displayTitle: this.displayTitleInComment,
      formId: this.formId,
      required: this.required,
    });

    this.optionsList = [];

    for (let option of this.options) {
      this.contentSelector.selectBlock(option.block).subscribe(blockData => {
        if (blockData && blockData.data) {
          this.optionsList.push({
            value: blockData.data.value as string,
            label: blockData.data.label as string,
          });
        }
      });
    }
  }

  onValueChange(selectedItem?: string): void {
    this.cartFunnelDispatcher.updateValue({
      id: this.blockId,
      changes: {
        value: selectedItem,
      },
    });
  }
}
