export const labelOptions = {
  fontFamily: 'Raleway',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '14px',
  lineHeight: '18px',
};

export const neutralMarkerConfig = {
  url: './../../../../assets/images/map/markerNeutral.svg',
  scaledSize: {
    width: 32,
    height: 32,
  },
};

export const selectedMarkerMarkerConfig = {
  labelOrigin: { x: 24, y: 58 },
  url: './../../../../assets/images/map/markerSelected.svg',
  scaledSize: {
    width: 48,
    height: 48,
  },
};

export const currentLocationMarkerConfig = {
  url: './../../../../assets/images/map/currentLocation.svg',
  scaledSize: {
    width: 20,
    height: 20,
  },
};

export const clusterMarkerConfig = [
  {
    url: './../../../../assets/images/map/markerCluster.svg',
    height: 48,
    width: 48,
    textColor: 'white',
    fontFamily: 'Raleway',
    fontStyle: 'normal',
    fontWeight: 'bold',
    textSize: 14,
    textLineHeight: 18,
    anchorText: [13, 0],
  },
];
