<div class="mx-auto bg-{{ background }}-800 max-w-screen-sm lg:max-w-full">
  <div
    *ngFor="let block of items; let i = index"
    class="transition-opacity duration-400 ease-linear delay-100 opacity-0"
    [ngClass]="index === i ? 'opacity-100' : ''"
  >
    <app-block-factory
      *ngIf="index === i"
      [blockId]="block.block"
    >
    </app-block-factory>
  </div>
</div>
