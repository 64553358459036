<div class="bg-white flex justify-between py-3 px-5 cursor-pointer max-w-screen-sm h-32">
  <div class="w-full">
    <p class="animate-pulse h-6 bg-neutral-200 rounded w-32 mb-2"></p>
    <p class="animate-pulse h-4 bg-neutral-50 rounded w-44 mb-2"></p>
    <p class="animate-pulse h-4 bg-neutral-50 rounded w-44 mb-2"></p>
  </div>
</div>
<div class="bg-white flex justify-between py-3 px-5 mb-8xs cursor-pointer max-w-screen-sm h-16">
  <div></div>
  <p class="animate-pulse h-6 bg-neutral-200 rounded w-32 mb-2"></p>
</div>
