import { Component, Input } from '@angular/core';

import { ColorFieldTypesValues } from '@config/values/color-field-types.values';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-carousel-block',
  templateUrl: './carousel-block.component.html',
})
export class CarouselBlockComponent {
  @Input() slides?: unknown[];
  @Input() positionSticky = false;
  @Input() positionStickyTop = 0;
  @Input() backgroundColor: IContentBuilderFieldColor = {
    value: 'transparent',
    type: ColorFieldTypesValues.Hex,
  };
}
