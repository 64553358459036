<div
  class="flex h-full justify-between px-5 py-3 leading-5 md:px-3 xl:px-5 xl:py-5"
  [class.opacity-40]="!isAvailable || !isOrderingAllowed"
  [class.cursor-pointer]="isAvailable && isOrderingAllowed"
  (click)="goToProduct(product)"
>
  <div class="w-full overflow-hidden pr-5">
    <p
      *ngIf="product[ProductKeys.ShopName]"
      class="pb-1.5 font-accent text-xs font-semibold text-neutral-500 xl:text-md"
    >
      <i class="icon icon-chef-hat"></i>
      {{ product[ProductKeys.ShopName] }}
    </p>
    <p class="font-bold">
      <span
        class="mr-2 inline-block rounded-xm bg-primary-600 px-2 text-sm text-white"
        *ngIf="product[ProductKeys.Quantity]"
      >
        {{ product[ProductKeys.Quantity] }}×
      </span>
    </p>
    <p
      class="xl:texit-md break-words font-accent text-base font-bold"
      [style.color]="productNameColor | contentBuilderColor"
    >
      {{ product[ProductKeys.Name] }}
    </p>
    <p
      *ngIf="product[ProductKeys.Description]"
      class="line-clamp-2 pt-0.5 font-base text-sm xl:pt-1.5"
      [style.color]="productDescriptionColor | contentBuilderColor"
    >
      {{ product[ProductKeys.Description] }}
    </p>

    @let visibleLabels = productLabels | appVisibleLabels;
    @let vm = vm$ | async;
    <ul
      class="mt-2 flex flex-wrap gap-1 xl:mt-3"
      *ngIf="visibleLabels?.length || !isAvailable"
    >
      <li *ngIf="!isAvailable">
        <app-tag-atom
          [bold]="false"
          color="neutral"
          [text]="'product-add-modal.product-unavailable' | translate"
        ></app-tag-atom>
      </li>
      <li *ngFor="let label of visibleLabels">
        <app-tag-atom
          [bold]="false"
          [text]="label.name"
          [lineThrough]="label.line_through"
        ></app-tag-atom>
      </li>
    </ul>
    <div
      *ngIf="!product[ProductKeys.PriceToDisplay]"
      class="mt-2 flex flex-row gap-2 text-base xl:mt-3 xl:text-md"
      [style.color]="productPriceColor | contentBuilderColor"
    >
      <ng-container *ngIf="displayDiscount(product); else priceOnly">
        <app-price-atom
          class="mr-1"
          [amount]="product[ProductKeys.Discount] ?? 0"
          [bold]="false"
        ></app-price-atom>
        <app-tag-atom
          class="mr-1"
          color="success"
          text="{{
            product[ProductKeys.Price] | discountPercent: product[ProductKeys.Discount] ?? 0
          }}"
          *ngIf="product[ProductKeys.Price]"
        ></app-tag-atom>
        <app-price-atom
          class="mr-1"
          [amount]="product[ProductKeys.Price]"
          [discount]="true"
          [bold]="false"
          *ngIf="product[ProductKeys.Price]"
        ></app-price-atom>
      </ng-container>
      <ng-template #priceOnly>
        <app-price-atom
          class="mr-1"
          [amount]="product[ProductKeys.Price]"
          [bold]="false"
        ></app-price-atom>
      </ng-template>
    </div>
    <div
      *ngIf="product[ProductKeys.PriceToDisplay]"
      class="mt-2 text-base xl:mt-3 xl:text-md"
    >
      <span class="mr-1"> {{ product[ProductKeys.PriceToDisplay] }}</span>
    </div>
  </div>
  <div
    class="push-image h-full overflow-hidden rounded rounded-lg object-cover"
    [style.backgroundColor]="productImagePlaceholderColor | contentBuilderColor"
  >
    <img
      *ngIf="product.images?.thumbnail?.url"
      class="push-image h-full rounded object-cover"
      [src]="product.images?.thumbnail?.url"
      alt="image"
    />
  </div>
</div>
