import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CartOverviewModalComponent } from '../../modals/cart-overview-modal/cart-overview-modal.component';
import { ModalsService } from '@core/services/modals/modals.service';
import { ActivatedRoute } from '@angular/router';
import { NavigationHistoryService } from '@core/services/navigation-history/navigation-history.service';

@Component({
  selector: 'app-marketplace-products-page',
  templateUrl: './marketplace-products-page.component.html',
})
export class MarketplaceProductsPageComponent implements OnInit, OnDestroy {
  @Input() body: any;

  constructor(
    private readonly modalsService: ModalsService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly navigationHistoryService: NavigationHistoryService,
  ) {}

  ngOnInit(): void {
    this.navigationHistoryService.pushStateFromActivatedRouteSnapshot(
      'marketplace-product-list',
      this.activatedRoute.snapshot,
    );
    this.modalsService.open(CartOverviewModalComponent.handle);
  }

  ngOnDestroy(): void {
    this.modalsService.close(CartOverviewModalComponent.handle);
  }
}
