import { createActionGroup, emptyProps, props } from '@ngrx/store';

import {
  AuthenticationGuardState,
  AuthenticationPermissionsState,
  AuthenticationStatusState,
  AUTH_STORE_KEY,
} from './authentication.state';
import { DoodApiError } from '@shared/interfaces/error.interface';
import { createHydratedStoreActions } from '@store/_abstract/hydrated';
import { DoodUserModel } from '@store/authentication/authentication.model';

export const AUTH_STORE_API_ACTIONS = createActionGroup({
  source: 'Dood/API/Authentication',
  events: {
    'Fetch User': emptyProps(),
    'Fetch User Success': props<{ user: DoodUserModel }>(),
    'Fetch User Failure': props<{ error: DoodApiError }>(),
    'Fetch User Complete': emptyProps(),
  },
});

export const AUTH_STORE_LOCAL_ACTIONS = createActionGroup({
  source: 'Dood/LOCAL/Authentication',
  events: {
    // User
    'Update User': props<{ user: DoodUserModel }>(),
    'Reset User': emptyProps(),
    'Update Is User Loaded': props<{ isUserLoaded: boolean }>(),
    'Update Is User Loading': props<{ isUserLoading: boolean }>(),

    // Status
    'Update Status': props<{ status: Partial<AuthenticationStatusState> }>(),
    'Reset Status': emptyProps(),

    // Guards
    'Update Guard': props<{ guard: Partial<AuthenticationGuardState> }>(),
    'Reset Guard': emptyProps(),

    // Permission
    'Update Permissions': props<{ permissions: Partial<AuthenticationPermissionsState> }>(),
    'Reset Permissions': emptyProps(),

    // Errors
    'Update Error': props<{ error: DoodApiError }>(),
    'Reset Error': emptyProps(),

    // Misc
    'reset': emptyProps(),
  },
});

export const AUTH_STORE_HYDRATION_ACTIONS = createHydratedStoreActions(AUTH_STORE_KEY);
