import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-sticky-block',
  templateUrl: './sticky-block.component.html',
  styles: []
})
export class StickyBlockComponent {

  @Input() body: any;
  @Input() position = 'top';
  @Input() gap = '0';

  getStyle() {
    return this.position === 'top' ? {top: this.gap + 'px'} : this.position === 'bottom' ? {bottom: this.gap + 'px'} : undefined;
  }

}
