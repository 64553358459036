import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button-block',
  templateUrl: './button-block.component.html',
})
export class ButtonBlockComponent {
  @Input() url!: string;
  @Input() label!: string;
  @Input() thickSize: 'S' | 'M' | 'L' = 'M';
  @Input() type: 'primary' | 'light' | 'outlined' = 'primary';
  @Input() color: 'neutral' | 'success' | 'primary' | 'info' | 'highligh' = 'primary';
}
