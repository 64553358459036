import { EntityMapOne, Update } from '@ngrx/entity';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { DoodApiError } from '@shared/interfaces/error.interface';
import { createHydratedStoreActions } from '@store/_abstract/hydrated';
import { DoodOnSiteLocationModel } from '@core/models/on-site-location.model';

import { OSL_STORE_KEY, OnSiteLocationSettingsState } from './on-site-location.state';

export const OSL_STORE_API_ACTIONS = createActionGroup({
  source: 'Dood/API/OnSiteLocation',
  events: {},
});

export const OSL_STORE_LOCAL_ACTIONS = createActionGroup({
  source: 'Dood/LOCAL/OnSiteLocation',
  events: {
    // Locations
    // -- Multiple
    'Set Locations': props<{ locations: DoodOnSiteLocationModel[] }>(),
    'Add Locations': props<{ locations: DoodOnSiteLocationModel[] }>(),
    'Remove Locations': props<{ ids: string[] }>(),
    'Upsert Locations': props<{ locations: DoodOnSiteLocationModel[] }>(),
    'Reset Locations': emptyProps(),

    // -- Single
    'Add Location': props<{ location: DoodOnSiteLocationModel }>(),
    'Remove Location': props<{ id: string }>(),
    'Upsert Location': props<{ location: DoodOnSiteLocationModel }>(),
    'Update Location': props<{ location: Update<DoodOnSiteLocationModel> }>(),
    'Map Location': props<{ location: EntityMapOne<DoodOnSiteLocationModel> }>(),

    // Active
    'Update Active': props<{ active: string | null }>(),
    'Reset Active': emptyProps(),

    // Settings
    'Update Settings': props<{ settings: Partial<OnSiteLocationSettingsState> }>(),
    'Reset Settings': emptyProps(),

    // Error
    'Reset Error': emptyProps(),
    'Update Error': props<{ error: DoodApiError | null }>(),

    // Status
    'Update Is Loaded': props<{ isLoaded: boolean }>(),
    'Update Is Loading': props<{ isLoading: boolean }>(),

    // Misc
    'reset': emptyProps(),
  },
});

export const OSL_STORE_HYDRATION_ACTIONS = createHydratedStoreActions(OSL_STORE_KEY);
