import {Component, Input} from '@angular/core';

import {MarketplaceKeys} from '@config/keys/shop.keys';
import {LanguageFlagsUrls} from '@config/language-flags.config';
import {ModalsService} from '@core/services/modals/modals.service';
import {SettingsStoreSelector} from '@common/selectors/settings.selector';
import {MarketplaceStoreSelector} from '@common/selectors/marketplace.selector';
import {IContentBuilderFieldColor} from '@core/models/content-builder-fields.model';
import {SidebarNavigationModalComponent} from '@shared/modals/sidebar-navigation-modal/sidebar-navigation-modal.component';
import {ColorFieldTypesValues} from '@config/values/color-field-types.values';

@Component({
  selector: 'app-header-block',
  templateUrl: './header-block.component.html',
})
export class HeaderBlockComponent {
  @Input() displayLogo = false;
  @Input() backLinkUrl!: string;
  @Input() logo?: {url: string};
  @Input() displayHamburger = true;
  @Input() displayBackButton = true;
  @Input() leftBlocks: unknown[] = [];
  @Input() rightBlocks: unknown[] = [];
  @Input() navigationLinks: unknown[] = [];
  @Input() positionAbsoluteOnMobile = false;
  @Input() positionAbsoluteOnDesktop = false;
  @Input() hamburgerColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() backgroundColor: IContentBuilderFieldColor = {
    value: 'transparent',
    type: ColorFieldTypesValues.Hex,
  };
  @Input() labelColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() iconColor: IContentBuilderFieldColor = {
    value: 'primary-600',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() iconBackgroundColor: IContentBuilderFieldColor = {
    value: 'primary-100',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() buttonTextColor: IContentBuilderFieldColor = {
    value: '#FFFFFF',
    type: ColorFieldTypesValues.Hex,
  };
  @Input() buttonBackgroundColor: IContentBuilderFieldColor = {
    value: 'primary-600',
    type: ColorFieldTypesValues.Palette,
  };

  languageFlagUrl?: string;

  constructor(
    private readonly modalsService: ModalsService,
    private settingsSelector: SettingsStoreSelector,
    private marketplaceSelector: MarketplaceStoreSelector,
  ) {
    if (this.marketplaceSelector.marketplace[MarketplaceKeys.Locales]?.length > 1) {
      this.languageFlagUrl = LanguageFlagsUrls.get(this.settingsSelector.app.locale);
    }
  }

  openSidebarNavigation(): void {
    this.modalsService.open(SidebarNavigationModalComponent.handle);
  }
}
