<div
  class="mx-auto max-w-md px-5 pt-2 lg:max-w-full lg:px-0 lg:pt-0"
  [style.color]="textColor | contentBuilderColor"
>
  <ng-container *ngIf="basket$ | async as basket">
    <div
      *ngIf="basket.id"
      class="mb-4 flex items-center px-3 py-3"
    >
      <span class="icon icon-group-order text-2xl"></span>
      <span
        *ngIf="basket.user.firstname"
        class="pl-4 font-accent font-bold"
      >
        {{ 'basket.group-command-of' | translate }}
        {{ basket.user.firstname }}</span
      >
      <span
        *ngIf="!basket.user.firstname"
        class="pl-4 font-accent font-bold"
      >
        {{ 'basket.group-command' | translate }}</span
      >
    </div>
  </ng-container>
  <div
    *ngIf="isGroupedOrderAllowed"
    class="pb-6"
  >
    <app-button-atom
      [size]="'XS'"
      thickSize="S"
      [label]="
        ((basketId$ | async)
          ? 'shop-page.share-my-grouped-order'
          : 'shop-page.create-my-grouped-order'
        ) | translate
      "
      type="light"
      class="dot dot-2"
      [icon]="'icon-group-order'"
      (click)="openGroupOrderModal()"
    ></app-button-atom>
  </div>
  <h1
    class="font-accent text-2xl font-bold lg:text-3xl"
    [style.color]="headingColor | contentBuilderColor"
  >
    {{ 'basket.overview' | translate }}
  </h1>
  <ng-container *ngIf="!(orderValidationInProgress$ | async); else mainLoading">
    <ng-container
      *ngIf="(basketId$ | async) && (reconstructedShareOrderBasket$ | async) as reconstructedBasket"
    >
      <ng-container *ngIf="reconstructedBasket.length; else emptyBasket">
        <div *ngFor="let elem of reconstructedBasket">
          <p
            class="mt-4 px-1 py-2 text-center font-base text-sm font-bold text-white"
            [style.color]="headingColor | contentBuilderColor"
          >
            <span *ngIf="elem.user.firstname">{{ elem.user.firstname }}</span>
            <span *ngIf="!elem.user.firstname">{{ 'basket.anonymous' | translate }}</span>
            <span *ngIf="(userId$ | async) === elem.user.id">
              ({{ 'basket.you' | translate }})</span
            >
          </p>
          <ul>
            <li
              *ngFor="let item of elem.items; let index = index"
              class="border-neutral-300 {{ item.product?.id }}"
              [ngClass]="{ 'border-t': index > 0 }"
            >
              <div
                class="align-center flex justify-center gap-3.5"
                *ngIf="basket$ | async as basket"
              >
                <app-overview-cart-item-atom
                  class="flex-auto"
                  [id]="item.itemId"
                  [isProductExists]="!!item.product?.[productKeys.Id]"
                  [name]="item.product?.[productKeys.Name] ?? 'INVALID'"
                  [groupAdditionsItems]="item.product?.additions?.items ?? []"
                  [groupCompoundItems]="item.product?.categories ?? []"
                  [quantity]="item.quantity"
                  [price]="item.price"
                  [formatPrice]="true"
                  [isOwner]="(userId$ | async) === elem.user.id"
                  [displayRemoveIcon]="
                    (userId$ | async) === elem.user.id || basket.user.id === (userId$ | async)
                  "
                  [isBasketOrder]="true"
                  (updateItem)="onUpdateItem(item.itemId, item.id)"
                  (removeItem)="onRemoveBasketItem(item.id)"
                ></app-overview-cart-item-atom>
              </div>
            </li>
          </ul>
          <div
            *ngFor="let coupon of elem.subCart?.coupons"
            class="flex justify-between border-b border-neutral-300 py-3"
            [class.opacity-50]="coupon.removeInProgress"
          >
            <div class="text-sm font-normal leading-snug lg:text-base">
              <div>
                <ng-container *ngIf="coupon.name">
                  {{ coupon.name }}
                </ng-container>
                <ng-container *ngIf="!coupon.name">
                  {{ 'basket.discount-voucher' | translate }}
                  «{{ coupon.code }}»
                </ng-container>
              </div>
              <div
                *ngIf="coupon.type === 'ERROR'"
                class="text-xs text-error-800"
              >
                <ng-container *ngIf="coupon.error">
                  {{ coupon.error }}
                </ng-container>
                <ng-container *ngIf="!coupon.error">
                  {{ 'basket.invalid-voucher' | translate }}
                </ng-container>
              </div>
              <div *ngIf="(userId$ | async) === elem.user.id">
                <a
                  (click)="onRemoveCoupon(coupon)"
                  class="cursor-pointer text-primary-600"
                  >{{ 'basket.delete' | translate }}</a
                >
              </div>
            </div>
            <div class="flex flex-row items-center justify-end">
              <div
                class="icon icon-coupon-3 pl-4 text-neutral-400"
                *ngIf="coupon.type !== 'ERROR'"
              ></div>
              <div
                class="icon icon-warning-circle pl-4 text-error-800"
                *ngIf="coupon.type === 'ERROR'"
              ></div>
              <div
                class="w-20 text-right text-sm leading-5"
                *ngIf="coupon.type !== 'ERROR'"
              >
                –
                <app-price-atom
                  [bold]="false"
                  [amount]="coupon.amount_off ?? 0"
                  [format]="true"
                ></app-price-atom>
              </div>
            </div>
          </div>
          <div
            *ngIf="elem.subCart?.total || elem.subCart?.total === 0"
            class="flex justify-between py-3 text-base"
          >
            <span class="font-accent font-bold">{{ 'basket.subtotal' | translate }} :</span>
            <app-price-atom
              [amount]="elem.subCart?.total ?? 0"
              [bold]="true"
              [format]="true"
            ></app-price-atom>
          </div>
          <div
            *ngIf="basket$ | async as basket"
            class="pb-3"
          >
            <a
              *ngIf="basket.user.id === (userId$ | async) && basket.user.id !== elem.user.id"
              class="flex cursor-pointer items-center text-secondary-600"
              (click)="removeUserFromCart(elem.user.id)"
            >
              <span class="icon icon-cross mr-2"></span>
              <span>{{ 'group-order-modal.delete-guest-button' | translate }}</span>
            </a>
          </div>
        </div>
      </ng-container>
      <ng-template #emptyBasket>
        <div class="mx-auto my-5 max-w-screen-sm px-5 py-5">
          <p class="rounded rounded-sm bg-highlight-50 p-4 text-center font-accent">
            {{ 'basket.empty-basket' | translate }}
          </p>
        </div>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="!(reconstructedShareOrderBasket$ | async)?.length">
      <ng-container *ngIf="reconstructedCart$ | async as cartByShop; else loading">
        <div *ngIf="cartByShop.length; else emptyCart">
          <div *ngFor="let cart of cartByShop">
            <h2
              class="mt-8xs font-accent text-base font-bold"
              [style.color]="secondaryTextColor | contentBuilderColor"
            >
              {{ cart[reconstructedCartKeys.Shop][shopKeys.Name] }}
            </h2>
            <ul>
              <li
                *ngFor="let item of cart[reconstructedCartKeys.Items]; let index = index"
                class="border-neutral-300 {{
                  item[reconstructedCartItemKeys.Item][cartItemKeys.Id]
                }}"
                [ngClass]="{ 'border-t': index > 0 }"
              >
                <app-overview-cart-item-atom
                  [id]="item[reconstructedCartItemKeys.Item][cartItemKeys.Id]"
                  [isProductExists]="!!item[reconstructedCartItemKeys.Product]?.[productKeys.Id]"
                  [name]="item[reconstructedCartItemKeys.Product]?.[productKeys.Name] ?? 'INVALID'"
                  [childItems]="item[reconstructedCartItemKeys.ChildCompoundItems] ?? []"
                  [quantity]="item[reconstructedCartItemKeys.Item][cartItemKeys.Quantity]"
                  [price]="item[reconstructedCartItemKeys.UnitPrice] ?? 0"
                  [textColor]="textColor"
                  [productNameColor]="productNameColor"
                  [productDetailsColor]="productDetailsColor"
                  (updateItem)="onUpdateItem(forceType($event))"
                  (removeItem)="onRemoveBasketItem($event)"
                ></app-overview-cart-item-atom>
              </li>
            </ul>
          </div>
        </div>
        <ng-template #emptyCart>
          <div class="mx-auto my-5 max-w-screen-sm px-5 py-5">
            <p class="rounded rounded-sm bg-highlight-50 p-4 text-center font-accent">
              {{ 'basket.empty-basket' | translate }}
            </p>
          </div>
        </ng-template>
      </ng-container>

      <ng-template #loading>
        <div class="pt-8">
          <ng-container *ngFor="let i of [].constructor(2)">
            <div class="flex max-w-screen-sm flex-col justify-between pb-3">
              <div class="flex w-full flex-row justify-between">
                <div class="mb-3 h-4 w-32 animate-pulse rounded bg-neutral-100"></div>
              </div>
              <ng-container *ngFor="let j of [].constructor(3)">
                <div class="flex max-w-screen-sm justify-between pb-2">
                  <div class="flex w-full flex-row justify-between">
                    <div class="mb-2 h-4 w-7/12 animate-pulse rounded bg-neutral-50"></div>
                    <div class="mb-2 h-4 w-14 animate-pulse rounded bg-neutral-50"></div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>

  <ng-template #mainLoading>
    <div
      *ngFor="let i of [].constructor(5)"
      class="flex w-full items-center justify-between border-b border-neutral-300 py-3"
    >
      <div class="flex w-9/12">
        <p class="ml-2 h-4 w-7/12 animate-pulse bg-neutral-200"></p>
      </div>
      <div class="h-4 w-10 animate-pulse bg-neutral-200"></div>
    </div>
  </ng-template>
</div>
