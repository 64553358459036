import { Component, Input } from '@angular/core';

import { ModalsService } from '@core/services/modals/modals.service';

interface GenericModalData {
  modalHandle: string;
}

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
})
export class GenericModalComponent {
  @Input() content!: unknown[];
  @Input() handle!: string;

  constructor(private readonly modalsService: ModalsService) {}

  close(): void {
    this.modalsService.close(this.handle);
  }

  getData(): GenericModalData {
    return {
      modalHandle: this.handle,
    };
  }
}
