import { Component, Input, ViewEncapsulation } from '@angular/core';

enum ExternalLinkButtonType {
  text = 'text',
  light = 'light',
  normal = 'normal',
  outlined = 'outlined',
}

enum ExternalLinkButtonLevel {
  primary = 'primary',
  secondary = 'secondary',
  neutral = 'neutral',
  info = 'info',
  success = 'success',
  error = 'error',
  highlight = 'highlight',
}

enum ExternalLinkButtonSize {
  small = 'sm',
  medium = 'md',
  large = 'lg',
  extraLarge = 'xl',
}

type ExternalLinkButtonTarget = '_blank' | '_self' | '_parent' | '_top' | string;

@Component({
  selector: 'app-external-link-button-block',
  templateUrl: './external-link-button-block.component.html',
  styleUrls: ['./external-link-button-block.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExternalLinkButtonBlockComponent {
  @Input() url?: string;
  @Input() disabled = false;
  @Input() content: unknown[] = [];
  @Input() target: ExternalLinkButtonTarget = '_blank';
  @Input() size: ExternalLinkButtonSize = ExternalLinkButtonSize.medium;
  @Input() type: ExternalLinkButtonType = ExternalLinkButtonType.normal;
  @Input() level: ExternalLinkButtonLevel = ExternalLinkButtonLevel.primary;
}
