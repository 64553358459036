import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { from, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, take, takeUntil, tap } from 'rxjs/operators';

import { ORDER_ALREADY_CREATED_WITH_CART, USER_IS_ALREADY_CONFIRMED } from '@config/errors.config';
import { Paths } from '@config/paths.config';

import { CartKeys } from '@config/keys/cart.keys';
import { UserKeys } from '@config/keys/user.keys';
import { OrderKeys } from '@config/keys/order.keys';
import { DistributionModeKeys, ShopKeys } from '@config/keys/shop.keys';

import { CartStoreRefiner } from '@common/refiners/cart.refiner';
import { CartStoreSelector } from '@common/selectors/cart.selector';
import { ShopStoreSelector } from '@common/selectors/shop.selector';
import { OrderStoreSelector } from '@common/selectors/order.selector';
import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { BasketStoreSelector } from '@common/selectors/basket.selector';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';

import { UserService } from '@core/services/user/user.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { BasketService } from '@core/services/basket/basket.service';
import { NativeService } from '@core/services/native/native.service';
import { ErrorService } from '@core/services/error-service/error.service';
import { OrderTypeService } from '@core/services/order-type/order-type.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';

import {
  DoodCreateOrderQuery,
  DoodOrderType,
  DoodValidateOrderQuery,
} from '@store/order/order.model';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { ShopStoreDispatcher } from '@common/dispatchers/shop.dispatcher';
import { DistributionModeValues, OnSiteLocationPickModeValues } from '@config/values/order.values';
import { CartParametersModalComponent } from '@shared/modals/cart-parameters-modal/cart-parameters-modal.component';

@Component({
  selector: 'app-cart-footer-modal',
  templateUrl: './cart-footer-modal.component.html',
})
export class CartFooterModalComponent extends DestroyerBase implements OnInit, OnDestroy {
  static handle = 'cart-footer-modal';

  index$: Observable<number> = this.modalSelector
    .selectModal(CartFooterModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  orderKeys = OrderKeys;

  missingPhone$ = this.orderSelector.selectErrors.pipe(map(errors => errors.missing_number));

  orderValidate$ = this.orderSelector.selectValidation;

  orderHasProduct$ = this.orderValidate$.pipe(map(validate => !!validate?.products?.length));

  basketId$ = this.basketSelector.selectId;

  basket$ = this.basketSelector.select;

  userId$ = this.authSelector.selectUserId;

  cartHasProduct$ = this.cartRefiner.selectActiveCartProducts.pipe(map(p => !!p.length));

  checkCartIsValidCallInProgress$ = this.ordersService.checkCartIsValidCallInProgress$;

  errorMessages: string[] = [];

  isGoToPaymentInProgress = false;

  onSiteLocationIsMissing = false;

  isNotched = false;

  missingLocationIdError!: string;

  @Input() goToPaymentButtonText = 'basket.payment';
  private destroyed$ = new Subject<boolean>();

  constructor(
    private readonly router: Router,
    private cartRefiner: CartStoreRefiner,
    private cartSelector: CartStoreSelector,
    private shopSelector: ShopStoreSelector,
    private authSelector: AuthStoreSelector,
    private modalSelector: ModalStoreSelector,
    private orderSelector: OrderStoreSelector,
    private readonly userService: UserService,
    private shopDispatcher: ShopStoreDispatcher,
    private basketSelector: BasketStoreSelector,
    private readonly errorService: ErrorService,
    private readonly ordersService: OrdersService,
    private readonly modalsService: ModalsService,
    private readonly basketService: BasketService,
    private readonly routerHelper: RouterHelperService,
    private readonly orderTypeService: OrderTypeService,
    private readonly translateService: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void {
    from(NativeService.isNotched()).subscribe(_isNotched => {
      this.isNotched = _isNotched;
    });

    this.errorService
      .getErrorMessage$()
      .pipe(
        takeUntil(this.destroyed$),
        tap(messages => (this.errorMessages = messages)),
      )
      .subscribe();

    /* If error violations with validate api */
    // combineLatest([this.orderSelector.selectViolations, this.cartSelector.selectActive])
    //   .pipe(
    //     takeUntil(this.destroyed$),
    //     switchMap(([violations, cart]) => {
    //       const isTimeSlotError = violations?.some(err => {
    //         const hasTimeSlotError =
    //           err.message.includes(TIME_SLOT_ERROR) ||
    //           err.message.includes(TIME_SLOT_PREORDER_MIN_MAX_ERROR) ||
    //           err.message.includes(ORDER_CANNOT_BE_PICKED_IN_THE_PAST);
    //
    //         if (hasTimeSlotError) {
    //           if (!cart?.[CartKeys.WantedAt]) {
    //             return true;
    //           } else if (cart?.[CartKeys.WantedAt]) {
    //             this.modalsService.open(CartParametersModalComponent.handle);
    //           }
    //         }
    //
    //         return false;
    //       });
    //
    //       return isTimeSlotError ? this.ordersService.loadShopSlots$() : of(null);
    //     }),
    //   )
    //   .subscribe();

    this.missingPhone$
      .pipe(
        takeUntil(this.destroyed$),
        tap(isMissing => (this.isGoToPaymentInProgress = !!isMissing)),
      )
      .subscribe();

    const cart = this.cartSelector.active;

    if (cart?.shop && !this.shopSelector.active && !cart?.[CartKeys.MultiShop]) {
      this.shopSelector
        .selectShop(cart.shop)
        .pipe(
          takeUntil(this._destroyerRef),
          tap(shop => {
            if (shop) {
              this.shopDispatcher.setActive(shop.id);
              if (cart?.type === DistributionModeValues.OnSite) {
                this.checkIfOnSiteLocationIsMissing();
              }
            }
          }),
        )
        .subscribe();
    }
    if (cart?.type === DistributionModeValues.OnSite) {
      this.checkIfOnSiteLocationIsMissing();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  goToPayment(): void {
    this.isGoToPaymentInProgress = true;
    this.ordersService
      .checkCartIsValid$()
      .pipe(
        take(1),
        map(order => {
          if (
            order &&
            order.type &&
            order.contact_phone_number === null &&
            ([DoodOrderType.delivery, DoodOrderType.shipping] as DoodOrderType[]).includes(
              order.type,
            )
          ) {
            this.ordersService.setDefaultPhone();
          }
        }),
      )
      .subscribe(() => {
        this.router.navigate([this.routerHelper.translateRoute(`/${Paths.Cart}/${Paths.Funnel}`)]);
      });
  }

  confirmOrder(): void {
    this.isGoToPaymentInProgress = true;
    this.basketService
      .confirmBasket$()
      .pipe(
        take(1),
        catchError(err => {
          this.isGoToPaymentInProgress = false;
          if (
            err?.error?.detail === USER_IS_ALREADY_CONFIRMED ||
            err?.error?.detail === ORDER_ALREADY_CREATED_WITH_CART
          ) {
            return of([]);
          }
          return throwError(() => err);
        }),
      )
      .subscribe(res => {
        this.isGoToPaymentInProgress = false;
        this.router.navigate([
          this.routerHelper.translateRoute(`/${Paths.Validate}/${Paths.SharedOrder}`),
        ]);
      });
  }

  private checkIfOnSiteLocationIsMissing(): boolean {
    const user = this.userService.user;
    const shop = this.shopSelector.active;
    const cart = this.cartSelector.active;
    const basket = this.basketSelector.basket;
    const distributionMode = cart?.[CartKeys.Type];
    const orderTypeCapabilities = this.orderTypeService.getCapabilities(distributionMode);
    const onsiteLocationPickMode = shop?.[ShopKeys.DistributionModes].filter(v => {
      return v[DistributionModeKeys.Type] === DistributionModeValues.OnSite;
    })?.[0][DistributionModeKeys.OnsiteLocationPickMode];

    if (cart && orderTypeCapabilities.isOnSiteLocationMandatory) {
      this.onSiteLocationIsMissing = !!!cart[CartKeys.OnSiteLocationName];

      if (
        this.onSiteLocationIsMissing &&
        onsiteLocationPickMode === OnSiteLocationPickModeValues.Url &&
        cart?.[CartKeys.Type] === DistributionModeValues.OnSite
      ) {
        this.missingLocationIdError = this.translateService.instant('basket.please-scan-qr-code');
        return false;
      }

      if (shop && !!!cart[CartKeys.OnSiteLocationName] && !basket?.id) {
        this.modalsService.open(CartParametersModalComponent.handle);
      }

      if (
        shop &&
        !!!cart[CartKeys.OnSiteLocationName] &&
        basket?.user?.[UserKeys.Id] === user?.[UserKeys.Id]
      ) {
        this.modalsService.open(CartParametersModalComponent.handle);
      }
      return !!cart[CartKeys.OnSiteLocationName];
    }

    return false;
  }

  protected orderLength(data: DoodValidateOrderQuery | DoodCreateOrderQuery) {
    return this.orderKeys.AvailablePaymentMethods in data
      ? (data[this.orderKeys.AvailablePaymentMethods] as any[]).length
      : 0;
  }
}
