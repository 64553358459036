import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { map, shareReplay, takeUntil } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';

import {
  MARKETPLACE_STORE_INITIAL_STATE,
  MARKETPLACE_STORE_MARKETPLACE_INITIAL_STATE,
  SELECT_MARKETPLACE_IS_LOADED,
  SELECT_MARKETPLACE_IS_LOADING,
  SELECT_MARKETPLACE_ITEM,
  SELECT_MARKETPLACE_STATE,
} from '@store/marketplace';
import {
  ColorsGroupKey,
  ProductLabelsFilterColors,
  ProductLabelsTagColors,
  SubGroupKeys,
} from '@core/models/marketplace-colors.model';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ContentBuilderFieldColors } from '@shared/interfaces/content-builder.interface';

@Injectable({ providedIn: 'root' })
export class MarketplaceStoreSelector implements OnDestroy {
  private readonly _destroyerRef = new Subject<boolean>();

  state = MARKETPLACE_STORE_INITIAL_STATE;
  marketplace = MARKETPLACE_STORE_MARKETPLACE_INITIAL_STATE;

  // State
  select = this.store.select(SELECT_MARKETPLACE_STATE);

  // Marketplace
  selectMarketplace = this.store.select(SELECT_MARKETPLACE_ITEM);
  selectMarketplaceId = this.selectMarketplace.pipe(map(m => m.id));
  selectMarketplaceName = this.selectMarketplace.pipe(map(m => m.name));
  selectMarketplaceLayout = this.selectMarketplace.pipe(map(m => m.layout));
  selectMarketplaceCountry = this.selectMarketplace.pipe(map(m => m.country));
  selectMarketplacePlugins = this.selectMarketplace.pipe(map(m => m.plugins));
  selectMarketplaceLocales = this.selectMarketplace.pipe(map(m => m.locales));
  selectMarketplaceCurrency = this.selectMarketplace.pipe(map(m => m.currency));
  selectMarketplaceAvailable = this.selectMarketplace.pipe(map(m => m.available));
  selectMarketplaceDistributionModes = this.selectMarketplace.pipe(map(m => m.distribution_modes));
  selectMarketplaceColors = this.selectMarketplace.pipe(map(m => m.colors));
  selectMarketplaceCurrentDistributionMode = this.selectMarketplace.pipe(
    map(m => m.delivery_provider),
  );
  selectMarketplaceColorsSubGroup = <GroupKey extends ColorsGroupKey>(
    colorsGroupKey: GroupKey,
    subGroup: SubGroupKeys<GroupKey>,
  ) => this.selectMarketplace.pipe(map(m => m.colors?.[colorsGroupKey]?.[subGroup]));
  selectMarketPlaceProductLabelsTagColors = this.selectMarketplaceColorsSubGroup(
    'products-labels',
    'tag',
  ).pipe(
    map(tagColors => {
      if (!tagColors) {
        return ProductLabelsTagColors.tag;
      }
      return tagColors;
    }),
    map(colors => {
      return colors.reduce(
        (acc, colorItem) => {
          acc[colorItem.label] = colorItem.color;
          return acc;
        },
        {} as Record<string, IContentBuilderFieldColor>,
      );
    }),
    map(tagColors => {
      const tagColorsMapper: ContentBuilderFieldColors = {
        font: tagColors['colors.product-labels.text-color-of-tags'],
        background: tagColors['colors.product-labels.background-color-of-tags'],
      };
      return tagColorsMapper;
    }),
    shareReplay(1),
  );
  selectMarketPlaceProductLabelsFilterColors = this.selectMarketplaceColorsSubGroup(
    'products-labels',
    'filter',
  ).pipe(
    map(filterColors => {
      if (!filterColors) {
        return ProductLabelsFilterColors.filter;
      }
      return filterColors;
    }),
    map(colors => {
      return colors.reduce(
        (acc, colorItem) => {
          acc[colorItem.label] = colorItem.color;
          return acc;
        },
        {} as Record<string, IContentBuilderFieldColor>,
      );
    }),
    map(filterColors => {
      const filterColorsMapper: ContentBuilderFieldColors = {
        font: filterColors['colors.product-labels.text-color-of-filters'],
        background: filterColors['colors.product-labels.background-color-of-filters'],
      };
      const filterColorsMapperActive: ContentBuilderFieldColors = {
        font: filterColors['colors.product-labels.text-color-of-active-filters'],
        background: filterColors['colors.product-labels.background-color-of-active-filters'],
      };

      const banner = filterColors['colors.product-labels.background-color-of-filter-banner'];
      return { inactiveColors: filterColorsMapper, activeColors: filterColorsMapperActive, banner };
    }),
    shareReplay(1),
  );

  // Status
  selectIsLoaded = this.store.select(SELECT_MARKETPLACE_IS_LOADED);
  selectIsLoading = this.store.select(SELECT_MARKETPLACE_IS_LOADING);

  constructor(private store: Store) {
    this.select.pipe(takeUntil(this._destroyerRef)).subscribe(_state => {
      this.state = _state;
    });
    this.selectMarketplace.pipe(takeUntil(this._destroyerRef)).subscribe(_marketplace => {
      this.marketplace = _marketplace;
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe();
  }

  private _unsubscribe(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
  }
}
