export enum ErrorsCode {
  UserNotFound = 'auth/user-not-found',
  WrongPassword = 'auth/wrong-password',
  InvalidEmail = 'auth/invalid-email',
  EmailAlreadyExist = 'auth/email-already-in-use',
  WeakPassword = 'auth/weak-password',
  ServerFail = 'internal_server_error',
  Disconnected = 'disconnected',
  DisabledUser = 'auth/user-disabled',
}

export const GEOLOCATION_ERROR = 'parameters.geolocation-activate';
export const USER_IS_ALREADY_CONFIRMED = 'User is already confirmed';
export const ORDER_ALREADY_CREATED_WITH_CART = 'Order has already been created from this cart.';
export const ORDER_ALREADY_PAID = 'Order is already paid';

// TRANSACTIONS
export const ORDER_INVALID_STATUS = 'order: Invalid order status';
export const TRANSACTION_EXCEEDS_TOTAL = 'The transaction amount exceeds the order total';

// COUPONS
export const COUPON_NOT_VALID =
  "Coupon invalide - Vérifiez que l'article est déjà dans votre panier";

export const UNABLE_TO_CREATE_PAYMENT = 'Unable to create payment.';

export enum StripeErrors {
  PaymentIntentUnexpectedState = 'payment_intent_unexpected_state',
  ApiConnectionError = 'api_connection_error',
}
