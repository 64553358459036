import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { ComoAsset } from '@core/models/como.model';
@Component({
  selector: 'app-kiosk-como-item-atom',
  templateUrl: './kiosk-como-item-atom.component.html',
  styleUrls: ['./kiosk-como-item-atom.component.scss'],
})
export class KioskComoItemAtomComponent {

  @Input() background!: string;
  @Input() textColor!: string;
  @Input() buttonBackground!: string;
  @Input() buttonTextColor!: string;
  @Input() displayPoints?: boolean;

  @Output() click = new EventEmitter<any>();

  constructor() {}

  @Input() asset!: ComoAsset;
  @Input() isAssetInCart = false;
  @Input() displayAddButton = true;
  @Input() tagLabelGift = 'como.asset-list-gift-label';
  @Input() tagLabelPointShop = 'como.asset-list-point-shop-label';

  @Output() assetAdd = new EventEmitter<ComoAsset>();
  @Output() assetRemove = new EventEmitter<ComoAsset>();

  onAssetAdd(asset: ComoAsset): void {
    if (!asset.is_usable) {
      return;
    }
    this.assetAdd.emit(asset);
  }

  onAssetRemove(asset: ComoAsset): void {
    this.assetRemove.emit(asset);
  }

  assetTagText(asset: ComoAsset): string {
    if (asset.key?.startsWith('ps_')) {
      return this.tagLabelPointShop;
    }

    return this.tagLabelGift;
  }
}
