<div
  class="pr-3 lg:pr-4 flex-shrink-0 flex-grow-0"
  [ngClass]="{
    'w-3/12 max-w-28': width === '3/12',
    'w-4/12 max-w-36': width === '4/12',
    'w-6/12  max-w-56': width === '6/12',
    'w-full  max-w-112': width === '12/12',
  }"
>
  <a
    class="w-full rounded-lg bg-center p-2 flex flex-col justify-end bg-cover"
    [link]="linkUrl"
    [style.aspectRatio]="aspectRatio"
    [style.backgroundColor]="backgroundColor | contentBuilderColor"
    [style.backgroundImage]="backgroundImage ? 'url(' + backgroundImage?.url + ')' : ''"
    [style.height]="height ? height + 'px' : 'auto'"
  >
    <app-tag-atom
      *ngIf="tag"
      [text]="tag"
      size="S"
    ></app-tag-atom>
  </a>
</div>
