import { switchMap, take } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';

import { EntityStoreSelector } from '@common/selectors/entity.selector';

import { EntitiesService } from '@core/services/entities/entities.service';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

@Component({
  selector: 'app-shop-small-overview-list-block',
  templateUrl: './shop-small-overview-list-block.component.html',
})
export class ShopSmallOverviewListBlockComponent implements OnInit {
  entities$ = this.entitySelector.selectEntities;
  marketplaceId$ = this.marketplaceSelector.selectMarketplaceId;

  constructor(
    private entitiesService: EntitiesService,
    private entitySelector: EntityStoreSelector,
    private marketplaceSelector: MarketplaceStoreSelector,
  ) {}

  ngOnInit(): void {
    this.marketplaceId$
      .pipe(
        take(1),
        switchMap(marketplaceId => this.entitiesService.loadAll$(marketplaceId)),
      )
      .subscribe();
  }
}
