<div class="flex h-full flex-1 flex-col overflow-x-hidden">
  @if (header) {
    <div
      id="header"
      class="flex-shrink-0"
      [ngStyle]="getBackgroundStyles()"
      [style.backgroundColor]="backgroundColor | contentBuilderColor"
      [style.color]="textColor | contentBuilderColor"
    >
      <app-block-list [blockIdList]="header"></app-block-list>
    </div>
  }
  <div class="flex h-full flex-1 overflow-hidden">
    <div
      class="flex h-full w-full flex-1 flex-col pt-4"
      [style.backgroundColor]="backgroundColor | contentBuilderColor"
      [style.color]="textColor | contentBuilderColor"
    >
      <div
        class="mb-5 pl-5 text-4.5xl font-bold"
        *ngIf="title"
      >
        {{ title }}
      </div>
      <div class="overflow-y-auto">
        <app-block-list [blockIdList]="body"></app-block-list>
      </div>
    </div>
  </div>
  <div
    [style.backgroundColor]="backgroundColor | contentBuilderColor"
    [style.color]="textColor | contentBuilderColor"
  >
    <app-block-list [blockIdList]="footer"></app-block-list>
  </div>
</div>
