import {
  AUTH_STORE_API_PIECE,
  AUTH_STORE_HYDRATION_PIECE,
  AUTH_STORE_LOCAL_PIECE,
} from './authentication.piece';
import { AUTH_STORE_INITIAL_STATE, AUTH_STORE_KEY } from './authentication.state';

import { createHydratedReducer } from '@store/_abstract/hydrated';

export const AUTH_STORE_REDUCER = createHydratedReducer({
  key: AUTH_STORE_KEY,
  initialState: AUTH_STORE_INITIAL_STATE,
  ons: [...AUTH_STORE_API_PIECE, ...AUTH_STORE_LOCAL_PIECE, ...AUTH_STORE_HYDRATION_PIECE],
});
