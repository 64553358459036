import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {
  constructor() {}

  extractIV(base64Key: string): Uint8Array {
    const ivString = base64Key.slice(0, 16); // IV is the first 16 characters
    return new TextEncoder().encode(ivString);
  }

  decodeBase64(base64String: string): Uint8Array {
    return Uint8Array.from(atob(base64String), c => c.charCodeAt(0));
  }

  importCryptoKey(key: Uint8Array): Promise<CryptoKey> {
    return crypto.subtle.importKey('raw', key, { name: 'AES-CBC' }, false, ['decrypt']);
  }

  decryptMessage(iv: Uint8Array, cryptoKey: CryptoKey, encrypted: Uint8Array): Promise<string> {
    return crypto.subtle
      .decrypt({ name: 'AES-CBC', iv }, cryptoKey, encrypted)
      .then(decrypted => new TextDecoder().decode(decrypted));
  }

  decryptAES(base64Key: string, base64Encrypted: string): Promise<string> {
    const iv = this.extractIV(base64Key);
    const key = this.decodeBase64(base64Key);
    const encrypted = this.decodeBase64(base64Encrypted);

    return this.importCryptoKey(key)
      .then(cryptoKey => this.decryptMessage(iv, cryptoKey, encrypted))
      .catch(error => {
        console.error('Erreur lors du décryptage :', error);
        throw error;
      });
  }
}
