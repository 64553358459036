export enum OrderStatusValues {
  Payment = 'PAYMENT',
  LookingForDelivery = 'LOOKING_FOR_DELIVERY',
  Waiting = 'WAITING',
  Preparation = 'PREPARATION',
  Ready = 'READY',
  Collected = 'COLLECTED',
  Cancelled = 'CANCELLED',
}

export enum DistributionModeValues {
  Stuart = 'stuart',
  UberDirect = 'uber-direct',
  Shipday = 'shipday',
  OwnShipping = 'own-shipping',
  ClickAndCollect = 'click-and-collect',
  OwnDelivery = 'own-delivery',
  OnSite = 'on-site',
  EatIn = 'eat-in',
  ZitiCity = 'ziti-city',
}

export enum OnSiteLocationPickModeValues {
  UrlAndDropdown = 'URL_AND_DROPDOWN',
  UrlOrDropdown = 'URL_OR_DROPDOWN',
  Url = 'URL',
}

export const DeliveryDistributionModes = [
  DistributionModeValues.Stuart.valueOf(),
  DistributionModeValues.UberDirect.valueOf(),
  DistributionModeValues.Shipday.valueOf(),
  DistributionModeValues.OwnDelivery.valueOf(),
  DistributionModeValues.ZitiCity.valueOf(),
];

export enum OrderTypeValues {
  Delivery = 'delivery',
  ClickAndCollect = 'click-and-collect',
  Shipping = 'shipping',
  OnSite = 'on-site',
  EatIn = 'eat-in',
}

export enum AuthModeValues {
  Full = 'full-authentication',
  Anonymous = 'anonymous-authentication',
  OnlyAnonymous = 'only-anonymous-authentication',
}
