import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { NavigationHistoryState } from '@core/models/navigation-history.model';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationHistoryService {
  private states: NavigationHistoryState[] = [];
  private allHistory: NavigationHistoryState[] = [];

  constructor(
    private readonly router: Router,
    private readonly routerHelper: RouterHelperService,
  ) {}

  goBack(): boolean {
    if (this.states.length <= 1) {
      this.router.navigate([this.routerHelper.translateRoute('/')]);
      return false;
    }

    const state = this.states.pop();
    if (!state) {
      return false;
    }
    state.dismiss$.next(null);

    const nextState = this.states[this.states.length - 1];
    if (nextState.route) {
      this.router.navigateByUrl(this.routerHelper.translateRoute(nextState.route) as string);
    }

    return true;
  }

  pushState(identifier?: string, route?: string): NavigationHistoryState {
    if (!identifier) {
      identifier = 'random-' + Math.random();
    }

    const state: NavigationHistoryState = {
      identifier,
      route,
      dismiss$: new Subject(),
    };

    const newStates: NavigationHistoryState[] = [];
    let statePushed = false;

    this.allHistory.push(state);
    // Keep existing states until same identifier
    for (const stateInStack of this.states) {
      if (state.identifier === stateInStack.identifier) {
        statePushed = true;
        newStates.push(state);
        break;
      } else {
        newStates.push(stateInStack);
      }
    }

    // If no state with this identifier, push state at the end of the stack
    if (!statePushed) {
      newStates.push(state);
    }

    this.states = newStates;
    return state;
  }

  pushStateFromActivatedRouteSnapshot(
    identifier: string,
    activatedRouteSnapshot: ActivatedRouteSnapshot,
  ): NavigationHistoryState | null {
    if (activatedRouteSnapshot.children.length > 0) {
      return null;
    }

    const url = this.routerHelper.getActivatedUrl(activatedRouteSnapshot);
    return this.pushState(identifier, url);
  }

  removeState(state: NavigationHistoryState): void {
    this.states = this.states.filter(s => s !== state);
  }

  haveHistory(): boolean {
    return this.states.length > 2;
  }

  getHistory(): NavigationHistoryState[] {
    return this.allHistory;
  }
}
