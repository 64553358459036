import { Observable } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { DeclinableStoreRefiner } from '@common/refiners/declinable.refiner';
import { ISelectedCompoundItem, ISelectedMappedCompoundItem } from '@core/models/product.model';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-compound-product-item-atom',
  templateUrl: './compound-product-item-atom.component.html',
})
export class CompoundProductItemAtomComponent implements OnInit {
  @Input() name!: string;
  @Input() count!: number;
  @Input() isValid = false;
  @Input() storeId!: string;
  @Input() minCount!: number;
  @Input() description?: string;
  @Input() products!: ISelectedCompoundItem[];
  @Input() headingColor: IContentBuilderFieldColor = {
    value: 'neutral-900',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() descriptionColor: IContentBuilderFieldColor = {
    value: 'neutral-700',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() inactiveStateColor: IContentBuilderFieldColor = {
    value: 'neutral-500',
    type: ColorFieldTypesValues.Palette,
  };

  @Input() hideAdditionGroupDetails = false;

  @Output() openCompound = new EventEmitter<Event>();

  isInvalid = false;

  selectedItems$!: Observable<ISelectedMappedCompoundItem[] | undefined>;

  constructor(private declinableRefiner: DeclinableStoreRefiner) {}

  ngOnInit(): void {
    this.selectedItems$ = this.declinableRefiner.selectSelectedDeclinables(this.storeId);
    // this.selectedItems$ = this.compoundItemQuery.getSelectedItems$(this.storeId);
  }
}
