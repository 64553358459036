<div
  class="flex py-6 px-5 rounded-xl bg-cover bg-center"
  [ngStyle]="{ backgroundImage: 'url(' + backgroundUrl + ')' }"
>
  <div [class.pr-4]="imgUrl">
    <p class="pb-2 font-accent font-bold text-1xsl text-white leading-tight">
      {{ title }}
    </p>
    <p class="pb-5 font-accent font-bold text-base text-white leading-tight">
      {{ text }}
    </p>
    <app-button-atom
      [type]="'outlined'"
      [size]="'L'"
      [label]="textButton"
      (clickEventEmitter)="buttonClick.emit($event)"
    ></app-button-atom>
  </div>
  <div *ngIf="imgUrl">
    <img
      class="min-w-16.5"
      src="{{ imgUrl }}"
      alt=""
    />
  </div>
</div>
