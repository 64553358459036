import { throwError } from 'rxjs';
import { Component, Input } from '@angular/core';
import { catchError, take, tap } from 'rxjs/operators';

import { OnSiteLocationStoreSelector } from '@common/selectors/on-site-location.selector';
import { PaymentAtTableStoreDispatcher } from '@common/dispatchers/payment-at-table.dispatcher';
import { OnSiteLocationsService } from '@core/services/on-site-locations/on-site-locations.service';
import { PAT_ERROR_NO_CHECKS_OPEN } from '@config/values/pat.values';
import { CheckKeys } from '@config/keys/check.keys';
import { PaymentStoreDispatcher } from '@common/dispatchers/payment.dispatcher';

@Component({
  selector: 'app-pat-checks-error',
  templateUrl: './pat-checks-error.component.html',
})
export class PatChecksErrorComponent {
  @Input() error!: string;

  @Input() displayRetryButton = false;

  isLoading = false;

  constructor(
    private onSiteLocationSelector: OnSiteLocationStoreSelector,
    private readonly onSiteLocationService: OnSiteLocationsService,
    private paymentAtTableDispatcher: PaymentAtTableStoreDispatcher,
    private paymentDispatcher: PaymentStoreDispatcher,
  ) {}

  retry(): void {
    this.isLoading = true;
    const { id, lastLocationId } = this.onSiteLocationSelector.settings;
    const onSiteLocationId = id ?? lastLocationId;
    if (onSiteLocationId) {
      this.onSiteLocationService
        .loadOnSiteLocationOpenCheck$(onSiteLocationId)
        .pipe(
          take(1),
          catchError(err => {
            this.isLoading = false;
            return throwError(() => err);
          }),
          tap(() => (this.isLoading = false)),
        )
        .subscribe(check => {
          if (check) {
            this.paymentAtTableDispatcher.updateCheck({
              ...check,
              error: null,
            });
            // @todo Refactor this to effect?
            if (check?.[CheckKeys.AvailablePaymentMethods]?.length) {
              this.paymentDispatcher.updateMethods(
                check.available_payment_methods
                  .filter(method => method.valid)
                  .map(method => method.payment_method),
              );
            }
          } else {
            this.paymentAtTableDispatcher.updateCheck({
              error: {
                detail: PAT_ERROR_NO_CHECKS_OPEN,
              },
            });
          }
        });
    }
  }
}
