import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, from } from 'rxjs';

import { environment } from '@env/environment';
import { InjectableScript, ScriptInjector } from '@common/injectors/script.injector';

// TODO: On version 16+ check if this injector is still needed
const GOOGLE_MAPS_API_SCRIPT: InjectableScript = {
  id: 'google-maps',
  src: `https://maps.googleapis.com/maps/api/js?key=${environment.api.maps.key}&libraries=places`,
};

const GOOGLE_MAPS_CLUSTER_API_SCRIPT: InjectableScript = {
  id: 'google-maps-cluster',
  src: 'https://unpkg.com/@googlemaps/markerclustererplus/dist/index.min.js',
};

@Injectable({ providedIn: 'root' })
export class GoogleMapsApiHelper {
  isLoaded = false;

  readonly onLoaded = new BehaviorSubject<boolean>(this.isLoaded);

  constructor(private scriptInjector: ScriptInjector) {
    this.initialize();
  }

  initialize(): void {
    if (this.isLoaded) return;
    combineLatest([from(this._loadMapApi()), from(this._loadMapClusterApi())])
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.isLoaded = true;
        },
        error: () => {
          this.isLoaded = false;
        },
        complete: () => {
          this.onLoaded.next(this.isLoaded);
        },
      });
  }

  private _loadMapApi(): Promise<void> {
    return this.scriptInjector.injectScript(GOOGLE_MAPS_API_SCRIPT);
  }

  private _loadMapClusterApi(): Promise<void> {
    return this.scriptInjector.injectScript(GOOGLE_MAPS_CLUSTER_API_SCRIPT);
  }
}
