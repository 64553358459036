import { createReducer } from '@ngrx/store';

import {
  CART_FUNNEL_STORE_API_PIECE,
  CART_FUNNEL_STORE_FUNNEL_PIECE,
  CART_FUNNEL_STORE_LOCAL_PIECE,
} from './cart-funnel.piece';
import { CART_FUNNEL_STORE_INITIAL_STATE } from './cart-funnel.state';

export const CART_FUNNEL_STORE_REDUCER = createReducer(
  CART_FUNNEL_STORE_INITIAL_STATE,
  ...CART_FUNNEL_STORE_FUNNEL_PIECE,
  ...CART_FUNNEL_STORE_API_PIECE,
  ...CART_FUNNEL_STORE_LOCAL_PIECE,
);
