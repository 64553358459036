import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-text-atom',
  templateUrl: './text-atom.component.html',
  styleUrls: ['./text-atom.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TextAtomComponent {
  @Input() content!: unknown;

  forceType(data: any): any {
    return data as any;
  }
}
