export const DEFAULT_COLORS = {
  neutral: {
    50: '#F9FAFB',
    100: '#F2F4F7',
    200: '#ECEFF4',
    300: '#E5E9F0',
    400: '#C4CAD4',
    500: '#8F96A3',
    600: '#5A6272',
    700: '#3D485C',
    800: '#202837',
    900: '#171D27',
  },
  primary: {
    50: '#F6F6FE',
    100: '#E9E8FC',
    200: '#CAC9F8',
    300: '#A7A5F3',
    400: '#8985EF',
    500: '#6F6AEB',
    600: '#504BE7',
    700: '#3A34DA',
    800: '#322EB8',
    900: '#2D2993',
  },
  secondary: {
    50: '#FFEEEB',
    100: '#FFD5CC',
    200: '#FFBBAD',
    300: '#FF9E8A',
    400: '#FF846B',
    500: '#FF6242',
    600: '#FF3C14',
    700: '#EB2800',
    800: '#BD2000',
    900: '#8F1800',
  },
  error: {
    50: '#FFEEEB',
    100: '#FFD5CC',
    200: '#FFBBAD',
    300: '#FF9E8A',
    400: '#FF846B',
    500: '#FF6242',
    600: '#FF3C14',
    700: '#EB2800',
    800: '#BD2000',
    900: '#8F1800',
  },
  success: {
    50: '#ECF8F4',
    100: '#CEEEE3',
    200: '#ACE2D0',
    300: '#86D5BA',
    400: '#5FC8A4',
    500: '#3BBF91',
    600: '#26B584',
    700: '#199F71',
    800: '#0E815A',
    900: '#056141',
  },
  info: {
    50: '#BCEEFB',
    100: '#BCEEFB',
    200: '#95E5F9',
    300: '#6ADAF6',
    400: '#43D0F4',
    500: '#17C5F1',
    600: '#0AB1DB',
    700: '#068FB1',
    800: '#04708B',
    900: '#035468',
  },
  highlight: {
    50: '#FFF7DC',
    100: '#FEF0BE',
    200: '#FEE79A',
    300: '#FDDE72',
    400: '#FDD54F',
    500: '#FCCC27',
    600: '#F1BC03',
    700: '#DDAC03',
    800: '#BA9103',
    900: '#977502',
  },
};
