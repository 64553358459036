import { PlaceResult } from '@core/models/google-maps.model';
import { ILocationParameter } from '@core/models/parameters.model';
import { IValidateDeliveryAddress } from '@core/models/order.model';
import { DoodLocationParameters } from '@store/settings/settings.model';
import {
  DoodDeliveryAddressModel,
  DoodDeliveryAddressCoordinates,
} from '@core/models/delivery-address.model';

import { DeliveryAddressKeys } from '@config/keys/delivery-address.keys';

export class LocationUtils {
  static mapPlaceResultToLocation(place: PlaceResult): ILocationParameter {
    return {
      address: place.formatted_address as string,
      lat: place.geometry?.location.lat(),
      lng: place.geometry?.location.lng(),
    };
  }

  static mapDeliveryAddressToLocation(
    address: DoodDeliveryAddressModel | undefined,
  ): ILocationParameter | undefined {
    if (!address) {
      return undefined;
    }

    return {
      address:
        (address?.street ? `${address.street},` : '') +
        (address?.postal_code ? ` ${address.postal_code},` : '') +
        (address?.city ? ` ${address.city}` : ''),
      lat: address?.point.coordinates[1],
      lng: address?.point.coordinates[0],
      address_components: address?.address_components,
      instructions: address?.instructions,
    };
  }

  static mapLocationParameterToDeliveryAddress(
    location: DoodLocationParameters,
  ): IValidateDeliveryAddress {
    return {
      street_line_1: location.deliveryStreetAddress,
      post_code: location.deliveryPostalCode,
      city: location.deliveryCity,
      country: location.deliveryCountry,
      point: {
        coordinates: [location.deliveryLng, location.deliveryLat],
      },
      instructions: location.deliveryInstructions,
    };
  }

  static mapIValidatedDeliveryAddressToIDeliveryAddress(
    old: IValidateDeliveryAddress,
  ): Partial<DoodDeliveryAddressModel> {
    let deliveryAddress: Partial<DoodDeliveryAddressModel> = {
      country: old.country ? old.country : '',
      city: old.city ? old.city : '',
      postal_code: old.post_code ? old.post_code : '',
      street: old.street_line_1 ? old.street_line_1 : '',
      point: old.point as DoodDeliveryAddressCoordinates,
      formatted_address: '',
      id: '',
      user: '',
      is_default: false,
      name: '',
      instructions: old.instructions ?? null,
    };
    deliveryAddress.formatted_address =
      LocationUtils.constructDisplayedDeliveryAddress(deliveryAddress);
    return deliveryAddress;
  }

  static mapINewDeliveryAddressToOdDeliveryAddress(
    address: DoodDeliveryAddressModel,
  ): IValidateDeliveryAddress {
    return {
      street_line_1: address.street ? address.street : '',
      city: address.city ? address.city : '',
      country: address.country ? address.country : '',
      post_code: address.postal_code ? address.postal_code : '',
      [DeliveryAddressKeys.Point]: address.point as DoodDeliveryAddressCoordinates,
      instructions: address.instructions ?? null,
    };
  }

  static constructDisplayedDeliveryAddress(
    address: Partial<DoodDeliveryAddressModel> | undefined,
  ): string {
    if (!address) {
      return '';
    }

    return (
      (address.street ? `${address.street},` : '') +
      (address.postal_code ? ` ${address.postal_code},` : '') +
      (address.city ? ` ${address.city}` : '')
    );
  }

  static constructDisplayedDeliveryAddressWithValidateResponse(
    address: IValidateDeliveryAddress,
  ): string {
    return (
      (address.addressee
        ? `${address.addressee},`
        : address.street_line_1
          ? `${address.street_line_1}`
          : '') +
      (address.post_code ? ` ${address.post_code},` : '') +
      (address.city ? ` ${address.city}` : '')
    );
  }
}
