<div
  class="aspect-ratio-3 w-full h-full bg-no-repeat bg-cover pt-20 md:pt-24 lg:pt-28 pb-4 md:pb-6 lg:pb-8 bg-primary-300"
  [style.backgroundImage]="backgroundImage ? 'url(' + backgroundImage?.url + ')' : ''"
>
  <div class="px-5 lg:px-12">
    <h1
      class="text-white font-brand font-extrabold text-4xl md:text-5xl xl:text-6xl pb-3 md:pb-5 xl:pb-7"
    >
      {{ title }}
    </h1>
    <p class="text-white font-base text-base md:text-lg lg:text-xl">
      {{ description }}
    </p>
  </div>
</div>
