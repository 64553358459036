<div
  (click)="cardClick.emit()"
  class="flex cursor-pointer justify-between rounded-lg bg-white font-base text-base text-neutral-600 shadow-S"
>
  <div class="p-4.5">
    <p class="font-accent text-lg font-bold lining-nums text-neutral-800">
      {{ shopName }}
    </p>
    <div class="flex">
      <p>{{ nbProducts }} {{ 'orders.items' | translate }} &#x2022;&nbsp;</p>
      <p>
        <app-price-atom
          [bold]="false"
          [amount]="orderAmount"
          [format]="true"
        ></app-price-atom>
        &#x2022; &nbsp;
      </p>
      <p>{{ order_date }}</p>
    </div>
    <div class="flex">
      <app-tag-status-atom
        class="mr-3"
        [type]="orderStatusLabels[orderStatus].type"
        [icon]="orderStatusLabels[orderStatus].icon"
        [text]="orderStatusLabels[orderStatus].label | translate"
      ></app-tag-status-atom>

      <app-tag-status-atom
        [type]="'neutral'"
        [text]="title"
      ></app-tag-status-atom>
    </div>
  </div>
  <div class="flex items-center">
    <div class="flex h-full items-center px-3.5">
      <i class="icon icon-arrow-down-s text-neutral-800"></i>
    </div>
  </div>
</div>
