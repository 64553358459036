import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-kiosk-register-confirmation-step',
  template: `
    <div class="flex flex-col items-center justify-center gap-12">
      <h1
        class="w-full text-center text-4xl font-bold"
        [style.color]="accentColor | contentBuilderColor"
      >
        {{ 'como.create-or-link-account.kiosk-register-confirmation-step.title' | translate }}
      </h1>
      <i
        class="icon icon-n-check text-9xl"
        [style.color]="accentColor | contentBuilderColor"
      ></i>
      <div class="w-full">
        <app-button-atom
          size="L"
          [label]="
            'como.create-or-link-account.kiosk-register-confirmation-step.continue' | translate
          "
          type="kiosk-primary"
          [overrideBgColor]="accentColor"
          (click)="onCompleteStep()"
          [full]="true"
        ></app-button-atom>
      </div>
    </div>
  `,
})
export class KioskRegisterConfirmationStepComponent {
  @Output() onComplete = new EventEmitter<void>();

  @Input() accentColor?: IContentBuilderFieldColor;

  onCompleteStep(): void {
    this.onComplete.next();
  }
}
