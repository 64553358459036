<a
  class="px-4 kiosk:py-4 rounded-full kiosk:text-base whitespace-nowrap mr-2"
  [style.backgroundColor]="backgroundColor | contentBuilderColor"
  [style.color]="textColor | contentBuilderColor"
  [ngClass]="{
    hidden: !displayOnMobile,
    'lg:inline-block': displayOnDesktop,
    'lg:hidden': !displayOnDesktop,
    'py-2': fontSize === 'sm' || fontSize === 'base',
    'py-3': fontSize === 'lg',
    'py-4': fontSize === 'xl' || fontSize === '2xl',
    'text-sm': fontSize === 'sm',
    'text-base': fontSize === 'base',
    'text-lg': fontSize === 'lg',
    'text-xl': fontSize === 'xl',
    'text-2xl': fontSize === '2xl',
    'font-bold': fontWeight === 'bold',
  }"
  [link]="linkUrl"
>
  <span class="hidden lg:inline-block">{{ label }}</span>
  <span class="lg:hidden">{{ labelMobile }}</span>
</a>
