import { createReducer } from '@ngrx/store';

import { FILTER_STORE_INITIAL_STATE } from './filter.state';
import { FILTER_STORE_API_PIECE, FILTER_STORE_LOCAL_PIECE } from './filter.piece';

export const FILTER_STORE_REDUCER = createReducer(
  FILTER_STORE_INITIAL_STATE,
  ...FILTER_STORE_API_PIECE,
  ...FILTER_STORE_LOCAL_PIECE,
);
