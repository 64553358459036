import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-kiosk-stepper-navigation-atom',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="flex w-full items-center justify-between py-4">
      <app-button-atom
        size="M"
        [label]="'declinable-product-stepper.previous' | translate"
        color="primary"
        [icon]="'icon-arrow-down-s'"
        [iconRotation]="'180'"
        [type]="'kiosk-primary'"
        [overrideBgColor]="color()"
        [overrideTextColor]="accentColor()"
        (click)="previousStep.emit()"
        thickSize="M"
        [full]="false"
      ></app-button-atom>
      <div class="flex flex-col gap-2 text-center font-bold">
        <p class="text-4xl">
          {{ categoryName() }}
        </p>
        <p class="text-2xl">{{ buildEntitySubtitle() }}</p>
      </div>
      <app-button-atom
        size="M"
        [label]="nextButtonText() | translate"
        color="primary"
        [icon]="'icon-arrow-down-s'"
        [type]="'kiosk-primary'"
        [overrideBgColor]="color()"
        [overrideTextColor]="accentColor()"
        [disabled]="!isNextButtonEnable()"
        (click)="nextStep.emit()"
        [iconOnRight]="true"
        thickSize="M"
        [full]="false"
      ></app-button-atom>
    </div>
  `,
})
export class KioskStepperNavigationAtomComponent {
  public readonly categoryName = input<string>();

  public readonly count = input<number>();

  public readonly minStepCount = input<number>();
  public readonly maxStepCount = input<number>();

  public readonly isPreviousButtonEnable = input<boolean>();
  public readonly isNextButtonEnable = input<boolean>();

  public readonly hasNextStep = input<boolean>();

  public readonly color = input<IContentBuilderFieldColor>();
  public readonly accentColor = input<IContentBuilderFieldColor>();

  public readonly previousStep = output<void>();
  public readonly nextStep = output<void>();

  protected readonly nextButtonText = computed(() => {
    if (!this.hasNextStep()) {
      return 'declinable-product-stepper.add';
    }
    if (this.count() === 0 && this.isNextButtonEnable()) {
      return 'declinable-product-stepper.no-thank-you';
    }
    return 'declinable-product-stepper.next';
  });

  protected buildEntitySubtitle(): string {
    if (!this.minStepCount() && this.maxStepCount())
      return `Choisissez-en jusqu'à ${this.maxStepCount()}`;
    if (this.minStepCount() && !this.maxStepCount())
      return `Choisissez-en au moins ${this.minStepCount()}`;
    if (this.minStepCount() && this.maxStepCount() && this.minStepCount() === this.maxStepCount())
      return `Choisissez-en ${this.maxStepCount()}`;
    if (this.minStepCount() && this.maxStepCount())
      return `Choisissez-en de ${this.minStepCount()} à ${this.maxStepCount()}`;
    return '';
  }
}
