import { Component, OnInit } from '@angular/core';
import { MarketplaceKeys } from '@config/keys/shop.keys';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';
import { CurrencyValues } from '@config/values/currency.values';

@Component({
  selector: 'app-currency-atom',
  templateUrl: './currency-atom.component.html',
})
export class CurrencyAtomComponent implements OnInit {
  currency!: string;

  constructor(private marketplaceSelector: MarketplaceStoreSelector) {}

  ngOnInit(): void {
    const marketplaceCurrency = this.marketplaceSelector.marketplace?.[MarketplaceKeys.Currency];
    switch (marketplaceCurrency) {
      case CurrencyValues.EUR:
        this.currency = '€';
        break;
      default:
        this.currency = marketplaceCurrency;
    }
  }
}
