<div
  class="flex w-full flex-row items-center justify-between gap-5 p-5 font-bold"
  [style.backgroundColor]="primaryButtonBackgroundColor | contentBuilderColor"
  [style.color]="primaryButtonTextColor | contentBuilderColor"
>
  <div class="text-4xl">Total de la commande</div>
  <div>
    @let finalPrice = (order$ | async)?.final_price;
    @let cartProducts = cartProducts$ | async;
    <app-price-atom
      [format]="true"
      [amount]="
        ((order$ | async)?.final_price && (cartProducts$ | async)?.length
          ? (order$ | async)?.final_price
          : 0) ?? 0
      "
      size="3XL"
    ></app-price-atom>
  </div>
</div>
<div class="grid grid-cols-2 gap-10 p-10">
  <app-button-atom
    size="XXL"
    thickSize="XXL"
    label="Retour"
    [overrideBgColor]="secondaryButtonBackgroundColor"
    [overrideTextColor]="secondaryButtonTextColor"
    type="kiosk-light"
    [full]="true"
    (click)="back()"
  ></app-button-atom>

  <app-button-atom
    size="XXL"
    label="Payer"
    thickSize="XXL"
    [overrideBgColor]="primaryButtonBackgroundColor"
    [overrideTextColor]="primaryButtonTextColor"
    type="kiosk-primary"
    [full]="true"
    (click)="navigateToFunnel()"
  ></app-button-atom>
</div>
