import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { take, tap } from 'rxjs/operators';

import { UserKeys } from '@config/keys/user.keys';
import { DoodUserModel } from '@store/authentication/authentication.model';
import { UserApiService } from '@core/services/api/user/user-api.service';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';
import { AuthStoreDispatcher } from '@common/dispatchers/authentication.dispatcher';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user: DoodUserModel | null = null;

  get user(): DoodUserModel | null {
    return this._user;
  }

  constructor(
    private authSelector: AuthStoreSelector,
    private authDispatcher: AuthStoreDispatcher,
    private readonly userApiService: UserApiService,
  ) {
    this.authSelector.selectUser.subscribe(user => {
      this._user = user;
    });
  }

  setUser(user: DoodUserModel): void {
    this.authDispatcher.fetchUserSuccess(user);
  }

  loadUser$(): Observable<DoodUserModel> {
    return this.userApiService.getUserApi$().pipe(tap(user => this.setUser(user)));
  }

  loadUserPromise(): Promise<DoodUserModel | undefined> {
    return this.userApiService.getUserPromise();
  }

  createAnonymousUser$(): Observable<DoodUserModel> {
    return this.userApiService.createAnonymousUserApi$().pipe(
      take(1),
      tap(createdUser => this.setUser(createdUser)),
    );
  }

  createUser$(user: Partial<DoodUserModel>): Observable<DoodUserModel> {
    user[UserKeys.Email] = user[UserKeys.Email]?.toLowerCase();
    return this.userApiService.createUserApi$(user).pipe(
      take(1),
      tap(createdUser => this.setUser(createdUser)),
    );
  }

  upgradeAnonymousUser$(user: Partial<DoodUserModel>, userId: string): Observable<DoodUserModel> {
    user[UserKeys.Email] = user[UserKeys.Email]?.toLowerCase();
    return this.userApiService.upgradeAnonymousUserApi$(user, userId).pipe(
      take(1),
      tap(updatedUser => this.setUser(updatedUser)),
    );
  }

  updateUser$(user: Partial<DoodUserModel>, id: string): Observable<DoodUserModel> {
    const _user = { ...user, email: user.email?.toLowerCase() };
    return this.userApiService
      .updatePersonalInfo$(_user, id)
      .pipe(tap(updatedUser => this.setUser(updatedUser)));
  }
}
