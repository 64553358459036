import { ActivatedRoute } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { skipWhile, takeUntil, tap } from 'rxjs/operators';

import { DestroyerBase } from '@core/base/destroyer/destroyer.base';

import { OrderStoreSelector } from '@common/selectors/order.selector';
import { DoodOrderModel } from '@store/order/order.model';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-order-status-message-card-block',
  templateUrl: './order-status-message-card-block.component.html',
})
export class OrderStatusMessageCardBlockComponent extends DestroyerBase implements OnInit {
  @Input() text = '';
  @Input() displayOnlyIfTypes = false;
  @Input() enabledForTypes: string[] = [];
  @Input() displayOnlyIfPaymentMethods = false;
  @Input() displayIfOrderAmountEqualZero = true;
  @Input() textColor?: IContentBuilderFieldColor;
  @Input() enabledForPaymentMethods: string[] = [];
  @Input() backgroundColor?: IContentBuilderFieldColor;

  displayCard = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private orderSelector: OrderStoreSelector,
  ) {
    super();
  }

  ngOnInit(): void {
    const orderId = this.activatedRoute.snapshot.params.orderId;
    this.orderSelector
      .selectOrder(orderId)
      .pipe(
        takeUntil(this._destroyerRef),
        skipWhile(order => !order),
        tap(order => this.checkDisplayConditions(order)),
      )
      .subscribe();
  }

  private checkDisplayConditions(order?: DoodOrderModel): void {
    this.displayCard = false;
    if (!order) {
      return;
    }

    if (
      this.displayOnlyIfPaymentMethods &&
      !this.enabledForPaymentMethods.includes(order.payment_service)
    ) {
      return;
    }
    if (this.displayOnlyIfTypes && !this.enabledForTypes.includes(order.type)) {
      return;
    }

    if (!this.displayIfOrderAmountEqualZero && order.final_price === 0) {
      return;
    }

    this.displayCard = true;
  }
}
