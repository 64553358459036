import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';

import {
  PAT_STORE_INITIAL_CHECK_STATE,
  PAT_STORE_INITIAL_CHOOSE_STATE,
  PAT_STORE_INITIAL_STATE,
} from '@store/payment-at-table/payment-at-table.state';
import {
  SELECT_PAT_CHOOSE_AMOUNT,
  SELECT_PAT_CHECK_STATE,
  SELECT_PAT_CHOOSE_STATE,
  SELECT_PAT_CHOOSE_ERROR,
  SELECT_PAT_CHOOSE_PARTS,
  SELECT_PAT_CHOOSE_PRODUCTS,
  SELECT_PAT_STATE,
  SELECT_PAT_CHOOSE_TIP,
  SELECT_PAT_CHOOSE_TYPE,
  SELECT_PAT_CHECK_ACTIVE_TRANSACTION,
  SELECT_PAT_CHECK_FINAL_PRICE,
  SELECT_PAT_CHECK_MARKETPLACE,
  SELECT_PAT_CHECK_ON_SITE_LOCATION,
  SELECT_PAT_CHECK_OUTSTANDING_AMOUNT,
  SELECT_PAT_CHECK_SHOP,
  SELECT_PAT_CHECK_TRANSITIONS,
  SELECT_PAT_CHECK_ERROR,
  SELECT_PAT_CHECK_ITEMS,
} from '@store/payment-at-table/payment-at-table.selection';

@Injectable({ providedIn: 'root' })
export class PaymentAtTableStoreSelector implements OnDestroy {
  private readonly _destroyerRef = new Subject<boolean>();

  state = PAT_STORE_INITIAL_STATE;
  check = PAT_STORE_INITIAL_CHECK_STATE;
  choose = PAT_STORE_INITIAL_CHOOSE_STATE;
  checkOutstandingAmount: number = 0;

  // State
  select = this.store.select(SELECT_PAT_STATE);

  // Check
  selectCheck = this.store.select(SELECT_PAT_CHECK_STATE);
  selectCheckShop = this.store.select(SELECT_PAT_CHECK_SHOP);
  selectCheckItems = this.store.select(SELECT_PAT_CHECK_ITEMS);
  selectCheckError = this.store.select(SELECT_PAT_CHECK_ERROR);
  selectCheckFinalPrice = this.store.select(SELECT_PAT_CHECK_FINAL_PRICE);
  selectCheckMarketplace = this.store.select(SELECT_PAT_CHECK_MARKETPLACE);
  selectCheckTransactions = this.store.select(SELECT_PAT_CHECK_TRANSITIONS);
  selectCheckOnSiteLocation = this.store.select(SELECT_PAT_CHECK_ON_SITE_LOCATION);
  selectCheckOutstandingAmount = this.store.select(SELECT_PAT_CHECK_OUTSTANDING_AMOUNT);
  selectCheckActiveTransaction = this.store.select(SELECT_PAT_CHECK_ACTIVE_TRANSACTION);

  // Choose
  selectChoose = this.store.select(SELECT_PAT_CHOOSE_STATE);
  selectChooseTip = this.store.select(SELECT_PAT_CHOOSE_TIP);
  selectChooseType = this.store.select(SELECT_PAT_CHOOSE_TYPE);
  selectChooseError = this.store.select(SELECT_PAT_CHOOSE_ERROR);
  selectChooseParts = this.store.select(SELECT_PAT_CHOOSE_PARTS);
  selectChooseAmount = this.store.select(SELECT_PAT_CHOOSE_AMOUNT);
  selectChooseProducts = this.store.select(SELECT_PAT_CHOOSE_PRODUCTS);

  constructor(private store: Store) {
    this.select.pipe(takeUntil(this._destroyerRef)).subscribe(_state => {
      this.state = _state;
    });
    this.selectCheck.pipe(takeUntil(this._destroyerRef)).subscribe(_check => {
      this.check = _check;
    });
    this.selectChoose.pipe(takeUntil(this._destroyerRef)).subscribe(_choose => {
      this.choose = _choose;
    });
    this.selectCheckOutstandingAmount
      .pipe(takeUntil(this._destroyerRef))
      .subscribe(_checkOutstandingAmount => {
        this.checkOutstandingAmount = _checkOutstandingAmount;
      });
  }

  ngOnDestroy(): void {
    this._unsubscribe();
  }

  private _unsubscribe(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
  }
}
