import { Subject } from 'rxjs';
import { Injectable, NgZone } from '@angular/core';

import { environment } from '@env/environment';
import { DoodMercureMessage } from '@shared/interfaces/topic.interface';

@Injectable({
  providedIn: 'root',
})
export class PushService {
  eventSourceError$ = new Subject<unknown>();

  private eventSource?: EventSource;
  private subscriptions = new Map<string, Subject<unknown>[]>();

  constructor(private readonly zone: NgZone) {}

  addSubscription(topic: string): Subject<unknown> {
    const _subject = new Subject<unknown>();
    const _subscriptions = this.getTopicSubjects(topic);

    _subscriptions.push(_subject);
    this.subscriptions.set(topic, _subscriptions);
    this.refreshEventSource();

    return _subject;
  }

  removeSubscription(topic: string, subject: Subject<unknown>): void {
    let subscriptionsOfTopic = this.subscriptions.get(topic);
    if (!subscriptionsOfTopic) {
      return;
    }
    subscriptionsOfTopic = subscriptionsOfTopic.filter(s => s !== subject);
    if (subscriptionsOfTopic.length > 0) {
      this.subscriptions.set(topic, subscriptionsOfTopic);
    } else {
      this.subscriptions.delete(topic);
      this.refreshEventSource();
    }
  }

  getTopicSubjects(topic: string): Subject<unknown>[] {
    return this.subscriptions.get(topic) ?? [];
  }

  private refreshEventSource(): void {
    const url = new URL(environment.api.mercure.url);
    const topics = Array.from(this.subscriptions.keys());

    if (this.eventSource) {
      this.eventSource.close();
    }

    if (topics.length === 0) {
      return;
    }

    topics.forEach(topic => {
      url.searchParams.append('topic', topic);
    });

    url.searchParams.append('ngsw-bypass', 'true');

    this.eventSource = new EventSource(url.toString(), {
      withCredentials: true,
    });

    this.eventSource.addEventListener('message', (event: MessageEvent<string>) => {
      this.zone.run(() => {
        this.messageReceived(JSON.parse(event.data));
      });
    });

    this.eventSource.addEventListener('error', event => {
      console.error(event, 'eventSource');
      this.eventSourceError$.next(event);
    });
  }

  private messageReceived(message: DoodMercureMessage): void {
    console.log('[Push] Message received', message);
    if (!Array.isArray(message.topics)) {
      return;
    }

    message.topics.forEach((topic: string) => {
      if (!this.subscriptions.has(topic)) return;
      const subjects = this.getTopicSubjects(topic);
      subjects.forEach(subject => {
        subject.next(message);
      });
    });
  }
}
