export const DEFAULT_FONTS = {
  brand: {
    family: 'Raleway, sans-serif',
    url: 'https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap',
  },
  accent: {
    family: 'Raleway, sans-serif',
    url: 'https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap',
  },
  base: {
    family: 'Roboto, sans-serif',
    url: 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap',
  },
};
