import { Component, Input } from '@angular/core';

import { IContentBuilderFieldImage } from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-marketplace-hero-slide-image-block',
  templateUrl: './marketplace-hero-slide-image-block.component.html',
})
export class MarketplaceHeroSlideImageBlockComponent {
  @Input() image?: IContentBuilderFieldImage;
}
