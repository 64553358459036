import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { map, skipWhile, take, tap } from 'rxjs/operators';

import { ShopKeys } from '@config/keys/shop.keys';
import { OrderKeys } from '@config/keys/order.keys';

import { SELECT_AUTH_USER } from '@store/authentication';
import { DoodOrderModel } from '@store/order/order.model';
import { OrderStatusLabels } from '@config/labels/order.labels';

import { PushService } from '@core/services/push/push.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { NotificationService } from '@core/services/notification/notification.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';

@Component({
  template: ``,
  selector: 'app-order-update-notification-subscriber',
})
export class OrderUpdateNotificationSubscriberComponent implements OnInit {
  pushSubscription: any;

  constructor(
    private store: Store,
    private readonly router: Router,
    private readonly asd: NotificationService,
    private readonly pushService: PushService,
    private readonly ordersService: OrdersService,
    private readonly routerHelper: RouterHelperService,
  ) {}

  ngOnInit(): void {
    this.subscribeToOrderUpdates();
  }

  private subscribeToOrderUpdates(): void {
    this.store
      .select(SELECT_AUTH_USER)
      .pipe(
        skipWhile(user => !user),
        take(1),
        tap(user => {
          if (user) {
            this.pushSubscription = this.pushService.addSubscription(
              `dood/users/${user?.id}/orders`,
            );
            this.pushSubscription
              .pipe(
                tap((res: any) => {
                  if (res.action !== 'update') {
                    return;
                  }

                  const status = res?.data?.status;
                  this.loadOrderForNotification(res.id, user.firstname ?? '', status);
                }),
              )
              .subscribe();
          }
        }),
      )
      .subscribe();
  }

  loadOrderForNotification(
    orderId: string,
    firstName: string,
    status: keyof typeof OrderStatusLabels,
  ): void {
    this.ordersService
      .loadOrderById$(orderId)
      .pipe(
        take(1),
        map(order => (order?.[OrderKeys.MultiShop] ? order[OrderKeys.ChildOrders] : [order])),
        tap(orders => {
          orders?.forEach((order: DoodOrderModel) => {
            if (['PREPARATION', 'READY'].includes(status)) {
              this.asd
                .displayOrderStatusUpdate(
                  firstName,
                  order?.[OrderKeys.Shop]?.[ShopKeys.Name],
                  status,
                  0,
                )
                .pipe(
                  tap(() => {
                    this.router.navigate([
                      this.routerHelper.translateRoute(`/orders/${orderId}/details`),
                    ]);
                  }),
                )
                .subscribe();
            }
          });
        }),
      )
      .subscribe();
  }
}
