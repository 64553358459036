import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ContentBuilderColorPipe } from '@core/pipes/content-builder-color.pipe';

@Component({
  selector: 'app-button-atom',
  templateUrl: './button-atom.component.html',
  styleUrls: ['./button-atom.component.scss'],
  standalone: true,
  imports: [CommonModule, ContentBuilderColorPipe],
})
export class ButtonAtomComponent {
  @Input() type:
    | 'primary'
    | 'light'
    | 'outlined'
    | 'link'
    | 'kiosk-primary'
    | 'kiosk-light'
    | 'kiosk-outlined' = 'primary';
  @Input() label!: string;
  @Input() icon!: string;
  @Input() iconUrl!: string | undefined;
  @Input() size: 'XXL' | 'L' | 'M' | 'S' | 'XS' = 'S';
  @Input() thickSize: 'XXL' | 'XS' | 'S' | 'M' | 'L' = 'M';
  @Input() iconOnRight = false;
  @Input() disabled = false;
  @Input() lookDisabled = false;
  @Input() full = false;
  @Input() link?: string;
  @Input() iconRotation: '0' | '90' | '180' | '270' = '0';
  @Input() aspectSquare = false;
  // color works for light type only
  @Input() color: 'neutral' | 'success' | 'primary' | 'info' | 'highligh' = 'primary';
  @Input() overrideBgColor?: IContentBuilderFieldColor;
  @Input() overrideTextColor?: IContentBuilderFieldColor;

  @Output() clickEventEmitter = new EventEmitter<any>();
}
