import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DistanceUtils } from '../../utils/distance/distance.utils';

@Component({
  selector: 'app-shop-address-atom',
  templateUrl: './shop-address-atom.component.html',
})
export class ShopAddressAtomComponent implements OnInit {
  @Input() address!: string;
  @Input() postcode!: string;
  @Input() city!: string;
  @Input() distance?: number;
  @Input() localization!: boolean;
  @Input() itinerary!: boolean;

  @Output() getLocalization = new EventEmitter<any>();
  @Output() getItinerary = new EventEmitter<any>();

  formattedDistance?: string;

  constructor() {}

  ngOnInit(): void {
    if (this.distance) {
      this.formattedDistance = DistanceUtils.formatDistance(this.distance);
    }
  }
}
