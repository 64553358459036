import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DOOD_API } from '@config/ws.config';
import { IStripeTerminalConnectionTokenResponse } from '@core/models/stripe.model';
import { DoodApiStripePaymentIntentResponse } from '@shared/interfaces/stripe.interface';

@Injectable({
  providedIn: 'root',
})
export class StripeTerminalApiService {
  constructor(private client: HttpClient) {}

  public getConnectionToken$(): Observable<IStripeTerminalConnectionTokenResponse> {
    return this.client.post<IStripeTerminalConnectionTokenResponse>(
      `${DOOD_API}/stripe-terminal/connection-token`,
      {},
    );
  }

  // TODO: Check this with Maxime & Xavier
  public capturePayment$(orderId: string): Observable<DoodApiStripePaymentIntentResponse> {
    return this.client.post<DoodApiStripePaymentIntentResponse>(
      `${DOOD_API}/orders/${orderId}/stripe-terminal-payment-capture`,
      {},
    );
  }
}
