<div class="mx-auto flex h-full flex-col">
  <ng-container *ngIf="(isShopLoading$ | async) === false && (shop$ | async) as shop; else loading">
    <ng-container *ngIf="categoriesWithProducts$ | async as products">
      <div
        *ngIf="products.length"
        appScrollSpy
        (sectionChange)="changeTab($event)"
        [ids]="idsCategories"
        class="flex h-full flex-col"
      >
        <app-kiosk-vertical-category-list-atom
          [items]="tabCategories"
          [activeItem]="tab"
          (categoryChange)="onCategoryClick($event)"
          [backgroundColor]="backgroundColor"
          [itemBackgroundColor]="itemBackgroundColor"
          [accentColor]="accentColor"
          class="flex h-full flex-col"
        >
        </app-kiosk-vertical-category-list-atom>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="warningMessages?.length">
    <div class="my-5 px-5 py-5">
      <p
        *ngFor="let message of warningMessages"
        class="rounded-sm bg-highlight-50 p-4 font-accent"
      >
        {{ message }}
      </p>
    </div>
  </ng-container>
  <ng-template #loading>
    <div class="flex h-full flex-col">
      <div class="flex h-full flex-row justify-start">
        <div class="text-primary flex h-full w-56 flex-col items-center justify-start gap-2.5">
          <div
            class="flex w-full animate-pulse cursor-pointer justify-center rounded-t-lg bg-neutral-200 py-4.5 shadow-sm"
          >
            <i class="icon icon-chevron-down rotate-180 text-neutral-50"></i>
          </div>
          <div class="flex w-full flex-1 flex-col gap-2.5 overflow-hidden scrollbar-hide">
            <div
              class="flex min-h-44 w-full animate-pulse flex-col items-center gap-2.5 rounded-lg bg-neutral-200 py-2.5 text-center"
              *ngFor="let i of [].constructor(7)"
            ></div>
          </div>
          <div
            class="flex w-full animate-pulse cursor-pointer justify-center rounded-b-lg bg-neutral-200 py-4.5 shadow-sm"
          >
            <i class="icon icon-chevron-down text-neutral-50"></i>
          </div>
        </div>
        <div class="flex h-full flex-col items-center justify-center gap-2.5 px-2">
          <ng-container *ngFor="let i of [].constructor(7)">
            <div class="h-2.5 w-2.5 animate-pulse rounded-full bg-neutral-200 transition-all"></div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-template>
</div>
