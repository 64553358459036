<div class="pt-4">
  <label class="block mt-5 mb-3 font-bold font-accent md:mt-6 text-md"
    >{{ title }}
    <span *ngIf="required">&#42;</span>
  </label>
  <app-input-multi-atom
    [type]="'email'"
    [placeholder]="placeholder"
    [value]="value"
    (valueEmitter)="onValueChange($event)"
  ></app-input-multi-atom>
</div>
