import { IDeclinableCategory } from '@core/models/product.model';
import { CartCategoryUtil } from './cart-category-util';
import { CartSimpleProductUtil } from './cart-simple-product-util';
import { AdditionGroupsBySimpleProductId } from './cart-util.service';
import { DeclinableProductCart } from '../cart-draft-declinable-product.store';
import { ICartDraftDeclinedProduct } from '@core/models/cart.model';

export type CategoryProductValidity = {
  isCategoryValid: boolean;
  categoryId: string;
  compiledSimpleProductValidities: {
    productId: string;
    isValid: boolean;
    additionsByGroupsValidity: Record<
      string,
      {
        isValid: boolean;
        isInvalid: boolean;
      }
    >;
  }[];
};

export type DeclinableProductValidity = {
  isDeclinableProductValid: boolean;
  compiledCategoriesValidity: CategoryProductValidity[];
};

export type TotalQuantityByCategoryId =
  | {
      [categoryId: string]: number;
    }
  | undefined;

export class CartDeclinableProductUtil {
  static checkAllCategoriesValidity({
    compiledCategoriesValidity,
  }: {
    compiledCategoriesValidity: { isCategoryValid: boolean; categoryId: string }[];
  }) {
    return compiledCategoriesValidity.every(({ isCategoryValid }) => isCategoryValid);
  }

  static checkCategoriesValidity(
    declinableProductCategories: IDeclinableCategory[],
    additionGroupsBySimpleProductId: AdditionGroupsBySimpleProductId,
    cartDraft: DeclinableProductCart,
  ): DeclinableProductValidity {
    const compiledCategoriesValidity = declinableProductCategories
      ?.map(category => {
        if (!category.store_id) {
          return undefined;
        }
        const compiledSimpleProductValidities = category.products.map(simpleProductId => {
          const simpleProductAdditionsGroups = additionGroupsBySimpleProductId[simpleProductId];
          if (!simpleProductAdditionsGroups) {
            return undefined;
          }

          const selectedAdditionIds =
            cartDraft.products?.find(categoryCartDraft => categoryCartDraft.id === simpleProductId)
              ?.additions ?? [];
          const compiledSimpleProductValidity = CartSimpleProductUtil.checkAdditionGroupValidity({
            simpleProductAdditionsGroups,
            selectedAdditionIds,
          });
          return { ...compiledSimpleProductValidity, productId: simpleProductId };
        });
        const categoryCartDraft =
          cartDraft.products?.filter(item => item.store_id === category?.store_id) ?? [];
        const isCategoryValid = CartCategoryUtil.checkCartValidity({
          cart: categoryCartDraft,
          compiledValidity: compiledSimpleProductValidities,
          category,
          totalSelectedQuantity: CartCategoryUtil.calculateTotalQuantity({
            cart: categoryCartDraft,
          }),
        });
        return {
          isCategoryValid,
          categoryId: category.store_id,
          compiledSimpleProductValidities,
        };
      })
      .filter(item => item !== undefined) as {
      // todo improve typing
      isCategoryValid: boolean;
      categoryId: string;
      compiledSimpleProductValidities: {
        productId: string;
        isValid: boolean;
        additionsByGroupsValidity: Record<
          string,
          {
            isValid: boolean;
            isInvalid: boolean;
          }
        >;
      }[];
    }[];
    const isDeclinableProductValid = CartDeclinableProductUtil.checkAllCategoriesValidity({
      compiledCategoriesValidity,
    });
    return { isDeclinableProductValid, compiledCategoriesValidity };
  }

  static calculateTotalSelectedQuantityByCategoryId(
    cartDraftCategoryItems: ICartDraftDeclinedProduct[] | undefined,
  ): TotalQuantityByCategoryId {
    return cartDraftCategoryItems?.reduce(
      (acc, cartCategoryItem) => {
        if (!cartCategoryItem.store_id) {
          return acc;
        }
        return {
          ...acc,
          [cartCategoryItem.store_id]:
            (acc[cartCategoryItem.store_id] ?? 0) + cartCategoryItem.quantity,
        };
      },
      {} as { [categoryId: string]: number },
    );
  }
}
