<app-modal-filler-atom
  (click)="close()"
  [index]="(index$ | async) ?? 0"
></app-modal-filler-atom>
<div
  class="fixed top-0 bottom-0 right-0 w-72 lg:w-80 bg-white"
  [style.z-index]="((index$ | async) ?? 0) + 60"
>
  <div class="flex flex-col h-screen justify-between overflow-y-auto pb-16">
    <div
      class="flex justify-between items-center p-6"
      [class.pt-10]="isNotched"
    >
      <div>
        <app-select-language-block></app-select-language-block>
      </div>
      <a
        class="cursor-pointer"
        (click)="close()"
      ><i class="icon icon-cross"></i
      ></a>
    </div>
    <div
      *ngIf="!(userId$ | async) || (!(isAnonymous$ | async) && !(firebaseToken$ | async))"
      class="p-6"
    >
      <app-button-atom
        [type]="'primary'"
        [full]="true"
        [label]="'sidebar.login-signup' | translate"
        (click)="openAuthenticationModal()"
      ></app-button-atom>
    </div>
    <div class="flex flex-col mb-auto">
      <ul class="w-full">
        <li
          *ngFor="let item of items"
          class="relative w-full py-3 pl-14.5 pr-4 text-md"
        >
          <a
            [link]="item.path"
            class="flex items-center"
            (click)="close()"
          >
            <i
              class="absolute left-5 icon"
              [class]="'icon-' + item.icon"
            ></i>
            <div class="font-accent text-base text-neutral-800 font-bold">
              {{ item.title | translate }}
            </div>
          </a>
        </li>
      </ul>

      <div
        class="w-full"
        *ngIf="customItems"
      >
        <app-block-list [blockIdList]="customItems"></app-block-list>
      </div>
      <ul
        class="w-full"
        *ngIf="forceDistributionMode$ | async"
      >
        <li class="relative w-full py-3 pl-14.5 pr-4 text-md">
          <div
            class="flex items-center"
            (click)="resetForceDistributionMode()"
          >
            <i class="absolute left-5"></i>
            <div class="font-accent text-base text-neutral-800 font-bold">
              {{ 'sidebar.see-all-distribution-modes' | translate }}
            </div>
          </div>
        </li>
      </ul>
      <ul
        *ngIf="(isAnonymous$ | async) && (firebaseToken$ | async)"
        class="w-full border-t border-neutral-300 pt-4 mt-4"
      >
        <li class="relative w-full py-3 pl-14.5 pr-4 text-md flex items-center">
          <i class="absolute left-5 icon icon-person-circle"></i>
          <div class="font-accent text-base text-neutral-800">
            {{ 'sidebar.navigation.logged-as-anonymous' | translate }}
          </div>
        </li>
      </ul>
      <ul class="w-full border-t border-neutral-300 py-4 mt-4">
        <ng-container
          *ngIf="
            ((userId$ | async) && (firebaseToken$ | async)) ||
            ((isAnonymous$ | async) && (firebaseToken$ | async))
          "
        >
          <li
            class="relative w-full py-3 pl-14.5 pr-4 text-md cursor-pointer flex items-center"
            (click)="logout()"
          >
            <i class="absolute left-5 icon icon-logout"></i>
            <div class="font-accent text-base text-neutral-800 font-bold">
              {{ 'sidebar.navigation.disconnect' | translate }}
            </div>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>
<footer
  class="fixed bottom-0 right-0 p-2 w-72 lg:w-80 bg-white"
  [style.z-index]="((index$ | async) ?? 0) + 60"
>

  <div class="flex flex-col text-sm text-neutral-600 flex p-6 gap-2">
    <a
      [href]="'legal-links.terms-of-sale.link' | translate"
      target="_blank"
      rel="noopener noreferrer"
      class="hover:underline"
    >{{ 'legal-links.terms-of-sale.label' | translate }}</a>

    <a
      [href]="'legal-links.terms-of-use.link' | translate"
      target="_blank"
      rel="noopener noreferrer"
      class="hover:underline"
    >{{ 'legal-links.terms-of-use.label' | translate }}</a>

    <a
      [href]="'legal-links.privacy-policy.link' | translate"
      target="_blank"
      rel="noopener noreferrer"
      class="hover:underline"
    >{{ 'legal-links.privacy-policy.label' | translate }}</a>

    <a
      [href]="'legal-links.legal-notice.link' | translate"
      target="_blank"
      rel="noopener noreferrer"
      class="hover:underline"
    >{{ 'legal-links.legal-notice.label' | translate }}</a>
  </div>



  <div class="justify-between flex flex-row">
    <div
      class="w-10 h-10 bg-neutral-50"
      (click)="resetTap()"
    ></div>
    <div
      class="flex items-center w-fit-content"
      *ngIf="displayPoweredByDood"
    >
      <span class="mr-2 font-base text-xs text-neutral-800 font-normal">Powered by </span>
      <a [link]="doodCorpoUrl"
      ><img
        class="w-20"
        src="assets/images/dood-logo/logo-dood-dark.png"
        alt="Dood"
      />
      </a>
    </div>
    <div
      class="flex items-center w-fit-content"
      *ngIf="!displayPoweredByDood && footerMessage"
    >
      <a
        [link]="footerMessageUrl"
        class="mr-2 font-base text-xs text-neutral-800 font-normal"
      >
        {{ footerMessage }}
      </a>
    </div>
  </div>
  <div class="text-xs text-right text-neutral-500">Version {{ version }}</div>
</footer>
