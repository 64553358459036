import { ReducerTypes, ActionCreator, on } from '@ngrx/store';

import { DeviceUtils } from '@app/utilities/device.util';
import { createHydratedStorePiece } from '@store/_abstract/hydrated';

import {
  SettingsState,
  SETTINGS_STORE_INITIAL_APP_STATE,
  SETTINGS_STORE_INITIAL_DEVICE_STATE,
  SETTINGS_STORE_INITIAL_KIOSK_STATE,
  SETTINGS_STORE_INITIAL_PARAMETERS_STATE,
  SETTINGS_STORE_INITIAL_STATE,
  SETTINGS_STORE_KEY,
} from './settings.state';
import { SETTINGS_STORE_HYDRATION_ACTIONS, SETTINGS_STORE_LOCAL_ACTIONS } from './settings.action';

export const SETTINGS_STORE_API_PIECE: ReducerTypes<SettingsState, readonly ActionCreator[]>[] = [];

export const SETTINGS_STORE_LOCAL_PIECE: ReducerTypes<SettingsState, readonly ActionCreator[]>[] = [
  // Parameters
  on(SETTINGS_STORE_LOCAL_ACTIONS.updateParameters, (state, { parameters }): SettingsState => {
    return { ...state, parameters: { ...state.parameters, ...parameters } };
  }),
  on(SETTINGS_STORE_LOCAL_ACTIONS.resetParameters, (state): SettingsState => {
    return { ...state, parameters: SETTINGS_STORE_INITIAL_PARAMETERS_STATE };
  }),

  // App
  on(SETTINGS_STORE_LOCAL_ACTIONS.updateApp, (state, { app }) => {
    return {
      ...state,
      app: { ...state.app, ...app },
    };
  }),
  on(SETTINGS_STORE_LOCAL_ACTIONS.updateAppLocale, (state, { locale }) => ({
    ...state,
    app: { ...state.app, locale },
  })),
  on(SETTINGS_STORE_LOCAL_ACTIONS.resetApp, state => ({
    ...state,
    app: SETTINGS_STORE_INITIAL_APP_STATE,
  })),

  // Kiosk
  on(SETTINGS_STORE_LOCAL_ACTIONS.updateKiosk, (state, { kiosk }) => {
    return {
      ...state,
      kiosk: { ...state.kiosk, ...kiosk },
    };
  }),
  on(SETTINGS_STORE_LOCAL_ACTIONS.updateKioskId, (state, { id }) => ({
    ...state,
    kiosk: { ...state.kiosk, id },
  })),
  on(SETTINGS_STORE_LOCAL_ACTIONS.updateIsKioskLayout, (state, { isKioskLayout }) => ({
    ...state,
    kiosk: { ...state.kiosk, isKioskLayout },
  })),
  on(SETTINGS_STORE_LOCAL_ACTIONS.resetKiosk, state => ({
    ...state,
    kiosk: SETTINGS_STORE_INITIAL_KIOSK_STATE,
  })),

  // Device
  on(SETTINGS_STORE_LOCAL_ACTIONS.calculateDevice, (state): SettingsState => {
    return {
      ...state,
      device: {
        device: DeviceUtils.getDeviceType(),
        platform: DeviceUtils.getPlatform(),
        screen: DeviceUtils.getDeviceScreenType(),
      },
    };
  }),
  on(SETTINGS_STORE_LOCAL_ACTIONS.updateDevice, (state, { device: app }): SettingsState => {
    return { ...state, device: { ...state.device, ...app } };
  }),
  on(SETTINGS_STORE_LOCAL_ACTIONS.resetDevice, (state): SettingsState => {
    return { ...state, device: SETTINGS_STORE_INITIAL_DEVICE_STATE };
  }),

  // Misc
  on(SETTINGS_STORE_LOCAL_ACTIONS.reset, (): SettingsState => {
    return SETTINGS_STORE_INITIAL_STATE;
  }),
];

export const SETTINGS_STORE_HYDRATION_PIECE = createHydratedStorePiece<SettingsState>(
  SETTINGS_STORE_KEY,
  SETTINGS_STORE_HYDRATION_ACTIONS,
);
