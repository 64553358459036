<ng-container *ngIf="!errorMessage">
  <div class="min-h-screen px-5 pb-64 pt-6">
    <ng-container *ngIf="checkId$ | async">
      <ng-container *ngIf="forceType(outstandingAmount) <= 0">
        <div class="flex justify-center pb-3 md:w-8/12">
          <p
            class="text-accent mr-6 text-2xl font-bold leading-8 text-neutral-800 md:mr-7 md:text-5xl"
          >
            {{ 'pay-at-table.overview.bill-payed' | translate }}
          </p>
          <span><i class="icon icon-n-check text-4xl text-success-600 md:text-5.8xl"></i></span>
        </div>
        <div class="pb-3 md:w-8/12">
          <div class="mb-3 flex flex-col rounded-m bg-neutral-100 p-5 text-center md:mb-8 md:p-6">
            <p class="font-accent text-md font-bold text-neutral-800 md:text-29px">
              {{ 'pay-at-table.overview.thanks-for-visit' | translate }}
            </p>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <h1
      *ngIf="forceType(outstandingAmount) > 0"
      class="mb-1 font-accent text-2xl font-bold text-neutral-800 lg:text-3xl"
    >
      {{ 'pay-at-table.overview.title' | translate }}
    </h1>
    <ng-container *ngIf="shop$ | async as shop">
      <p
        *ngIf="shop?.name; else loadingTemplate"
        class="mb-6 font-base text-sm text-neutral-600"
      >
        <span> {{ shop.name }}</span>
        <span *ngIf="onsiteLocation$ | async as onsite"> — {{ onsite.name }}</span>
      </p>
      <ng-template #loadingTemplate>
        <p class="mb-3 h-6 w-9/12 animate-pulse rounded bg-neutral-200"></p>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="items$ | async as items">
      <ng-container *ngIf="items?.length; else loadingTemplateItems">
        <div
          *ngFor="let item of items"
          class="flex items-center border-b border-neutral-300 py-3"
        >
          <div class="mr-2">
            <app-quantity-atom [quantity]="1"></app-quantity-atom>
          </div>
          <div class="flex w-full items-center justify-between">
            <p>
              <span class="ml-2 inline-block font-base text-base font-bold leading-6 lg:text-md">{{
                item.name
              }}</span>
            </p>
            <app-price-atom
              [amount]="item.price"
              [format]="true"
              [bold]="false"
            ></app-price-atom>
          </div>
        </div>
      </ng-container>
      <ng-template #loadingTemplateItems>
        <div
          *ngFor="let i of [].constructor(5)"
          class="flex w-full items-center justify-between border-b border-neutral-300 py-3"
        >
          <div class="flex w-9/12">
            <p class="h-6 w-6 animate-pulse rounded-2xl bg-neutral-400"></p>
            <p class="ml-2 h-6 w-7/12 animate-pulse bg-neutral-200"></p>
          </div>
          <div class="h-6 w-10 animate-pulse bg-neutral-200"></div>
        </div>
      </ng-template>
    </ng-container>
    <ng-container>
      <div
        *ngIf="checkId$ | async; else loadingTemplateAmount"
        class="flex justify-between pb-5 pt-3"
      >
        <span class="font-accent text-md font-bold leading-6">{{
          'pay-at-table.overview.total' | translate
        }}</span>
        <span class="font-accent text-base font-bold leading-6"
          ><app-price-atom [amount]="forceType(finalPrice$ | async)"></app-price-atom
        ></span>
      </div>

      <ng-template #loadingTemplateAmount>
        <div class="mt-3 flex justify-between">
          <p class="mb-3 h-5 w-6/12 animate-pulse rounded bg-neutral-400"></p>
          <p class="mb-3 h-5 w-2/12 animate-pulse rounded bg-neutral-400"></p>
        </div>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="transactions$ | async as transactions">
      <div
        *ngFor="let transaction of transactions"
        class="border-b border-neutral-300 py-2 font-accent leading-6"
      >
        <div class="flex justify-between">
          <p class="font-bold">
            <container-element [ngSwitch]="transaction.source">
              <span *ngSwitchCase="checkTransactionSources.DOOD">
                {{ 'pay-at-table.overview.payment-online-label' | translate }}
              </span>
              <span *ngSwitchCase="checkTransactionSources.CashRegister">
                {{ 'pay-at-table.overview.payment-cash-register-label' | translate }}
              </span>
              <span *ngSwitchDefault>
                {{ 'pay-at-table.overview.payment-label' | translate }}
              </span>
            </container-element>
          </p>
          <p
            class="pl-2 font-base"
            [ngClass]="{
              'opacity-50': transaction.status !== checkTransactionStatuses.Captured,
              'line-through': transaction.status === checkTransactionStatuses.Cancelled,
            }"
          >
            <app-price-atom
              [format]="true"
              [amount]="transaction.amount * -1"
              [bold]="false"
              [withParenthesis]="transaction.status !== checkTransactionStatuses.Captured"
            ></app-price-atom>
          </p>
        </div>
        <div
          *ngIf="transaction?.tip"
          class="flex justify-between text-neutral-600"
        >
          <p class="flex">
            <i class="icon icon-satisfied mr-2 flex items-center"></i
            >{{ 'pay-at-table.overview.tip' | translate }}
          </p>
          <p class="pl-2">
            <app-price-atom
              [format]="true"
              [amount]="transaction?.tip?.amount"
              [bold]="false"
            ></app-price-atom>
          </p>
        </div>
        <ng-container [ngSwitch]="transaction.status">
          <ng-container *ngSwitchCase="checkTransactionStatuses.Authorized">
            <p class="font-base text-sm text-neutral-600">
              {{ 'pay-at-table.overview.payment-status-authorized-label' | translate }}
            </p>
          </ng-container>
          <ng-container *ngSwitchCase="checkTransactionStatuses.Payment">
            <p class="font-base text-sm text-neutral-600">
              {{ 'pay-at-table.overview.payment-status-authorized-label' | translate }}
            </p>
          </ng-container>
          <ng-container *ngSwitchCase="checkTransactionStatuses.Cancelled">
            <p class="font-base text-sm text-neutral-600">
              {{ 'pay-at-table.overview.payment-status-cancelled-label' | translate }}
            </p>
          </ng-container>
          <ng-container *ngSwitchCase="checkTransactionStatuses.CapturedButInconsistentWithRemote">
            <p class="font-base text-sm text-error-800">
              {{ 'pay-at-table.overview.payment-status-inconsistent-label' | translate }}
            </p>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>

    <ng-container>
      <div
        *ngIf="checkId$ | async; else loadingRemainAmount"
        class="flex justify-between py-3"
      >
        <span class="font-accent text-md font-bold leading-6">{{
          'pay-at-table.overview.left-to-pay' | translate
        }}</span>
        <span class="font-accent text-base font-bold leading-6"
          ><app-price-atom [amount]="forceType(outstandingAmount)"></app-price-atom
        ></span>
      </div>

      <ng-template #loadingRemainAmount>
        <div class="flex justify-between">
          <p class="mb-3 h-5 w-6/12 animate-pulse rounded bg-neutral-400"></p>
          <p class="mb-3 h-5 w-2/12 animate-pulse rounded bg-neutral-400"></p>
        </div>
      </ng-template>
    </ng-container>

    <app-pat-base-modal *ngIf="forceType(outstandingAmount) > 0">
      <ng-container *ngIf="!error">
        <p class="mb-3 text-center font-base text-neutral-800">
          Reste à payer :
          <app-price-atom
            [amount]="forceType(outstandingAmount)"
            [bold]="false"
          ></app-price-atom>
        </p>
        <app-button-atom
          class="mx-auto"
          [disabled]="!outstandingAmount || !!!(checkId$ | async)"
          [icon]="'icon-black-lock'"
          [label]="'pay-at-table.pay' | translate"
          [full]="true"
          (click)="goToChooseAmount()"
        ></app-button-atom>
      </ng-container>
    </app-pat-base-modal>
  </div>
</ng-container>

<ng-container *ngIf="errorMessage">
  <div class="flex min-h-screen items-center px-5 pb-64 pt-6">
    <app-pat-checks-error
      [error]="errorMessage"
      [displayRetryButton]="true"
    ></app-pat-checks-error>
  </div>
</ng-container>
