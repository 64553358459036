<div
  class="container mx-auto max-w-640"
  [ngClass]="{
    'max-w-full': displayKiosk,
    'pt-5': paddingTop === 'small',
    'pt-10': paddingTop === 'large',
    'pb-5': paddingBottom === 'small',
    'pb-10': paddingBottom === 'large',
  }"
  *ngIf="image"
>
  <img
    [style]="heightStyles$ | async"
    [src]="image.url"
    class="w-full"
  />
</div>
