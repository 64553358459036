<div
  class="flex flex-col rounded-m bg-white p-5 md:p-6 divide-y gap-4"
  [style.color]="textColor | contentBuilderColor"
>
  <div
    *ngIf="phoneNumber"
    class="flex flex-row items-center flex-wrap gap-2 justify-between"
  >
    <div class="flex flex-row items-center gap-2">
      <i class="icon icon-phone text-lg"></i>
      <span class="text-md">{{ phoneNumber }}</span>
    </div>
    <a
      class="font-base font-bold text-sm lg:text-base text-primary-600 cursor-pointer"
      [href]="'tel:' + phoneNumber"
      >{{ 'order-actions.call' | translate }}</a
    >
  </div>
  <div
    *ngIf="emailAddress"
    class="flex items-center flex-wrap gap-2 justify-content-between"
  >
    <i class="icon icon-phone text-lg"></i>
    <span class="text-md">{{ emailAddress }}</span>
    <a
      class="font-base font-bold text-sm lg:text-base text-primary-600 cursor-pointer"
      [href]="'mailto:' + emailAddress"
      >{{ 'order-actions.write' | translate }}</a
    >
  </div>
  <div
    class="flex flex-col items-center justify-center pt-8 gap-4"
    *ngIf="facebookURL || instagramURL || twitterURL"
  >
    <span class="font-base font-bold text-2xl"
      >{{ 'order-actions.follow' | translate }} {{ shopName }}</span
    >
    <div class="flex gap-3">
      <app-rounded-icon-button
        *ngIf="facebookURL"
        [size]="'md'"
        icon="icon-facebook"
        (click)="navigateTo(facebookURL)"
      ></app-rounded-icon-button>
      <app-rounded-icon-button
        *ngIf="instagramURL"
        [size]="'md'"
        icon="icon-instagram"
        (click)="navigateTo(instagramURL)"
      ></app-rounded-icon-button>
      <app-rounded-icon-button
        *ngIf="twitterURL"
        [size]="'md'"
        icon="icon-x"
        (click)="navigateTo(twitterURL)"
      ></app-rounded-icon-button>
    </div>
  </div>
</div>
