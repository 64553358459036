<app-modal-filler-atom
  (click)="closeFilters()"
  [index]="(index$ | async) ?? 0"
></app-modal-filler-atom>
<div
  class="fixed bottom-0 left-0 right-0 top-0 min-h-screen overflow-y-auto overflow-x-hidden bg-white lg:bottom-0 lg:left-auto lg:right-0 lg:top-0 lg:w-4/12"
  [style.z-index]="((index$ | async) ?? 0) + 50"
>
  <header class="flex items-center justify-between px-5 py-3 shadow-md">
    <!-- <app-input-text-atom class="mr-5 w-full" [label]="'Restaurants, plats...'" [iconLeft]="'icon-zoom'" [value]=""></app-input-text-atom> -->
    <div></div>
    <span
      class="icon icon-cross cursor-pointer"
      (click)="closeFilters()"
    ></span>
  </header>
  <div class="min-h-full px-5">
    <div class="py-3">
      <app-horizontal-categories-atom
        [items]="categoryItems"
        [mobileView]="true"
        (clickCard)="selectCategory($event)"
      ></app-horizontal-categories-atom>
    </div>
    <div *ngFor="let filter of filters">
      <app-toggle-filter-atom
        [name]="filter.label ?? '' | translate"
        [type]="filter.type"
        [multiSelect]="false"
        [items]="filter.values ?? []"
        [id]="filter.id"
        [initItemValues]="(selectedFilters$ | async) ?? []"
        (selectedItemsChange)="updateFilters($event)"
      ></app-toggle-filter-atom>
    </div>
  </div>
  <footer class="sticky bottom-0 left-0 bg-white shadow shadow-top">
    <div class="flex w-full flex-nowrap p-3">
      <app-button-atom
        class="mr-3 w-full"
        [type]="'light'"
        [color]="'primary'"
        [label]="'filters.delete-all' | translate"
        [size]="'L'"
        [full]="true"
        (click)="removeFilters()"
      ></app-button-atom>
      <app-button-atom
        class="w-full"
        [type]="'primary'"
        [color]="'primary'"
        [label]="'filters.apply' | translate"
        [size]="'L'"
        [full]="true"
        (click)="applyFilters()"
      ></app-button-atom>
    </div>
  </footer>
</div>
