import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CheckKeys, CheckTransactionKeys } from '@config/keys/check.keys';
import { DoodOpenCheck, DoodOpenCheckTransaction } from '@core/models/check.model';

@Component({
  selector: 'app-check-overview-atom',
  templateUrl: './check-overview-atom.component.html',
})
export class CheckOverviewAtomComponent implements OnInit, OnChanges {
  @Input() check!: DoodOpenCheck;

  capturedTransactons!: DoodOpenCheckTransaction[];

  checkKeys = CheckKeys;

  constructor() {}

  ngOnInit(): void {
    if (this.checkKeys) {
      this.capturedTransactons = this.check?.[CheckKeys.Transactions].filter(
        transaction => transaction?.[CheckTransactionKeys.Status] === 'CAPTURED',
      );
    }
  }

  ngOnChanges(): void {
    if (this.checkKeys) {
      this.capturedTransactons = this.check?.[CheckKeys.Transactions].filter(
        transaction => transaction?.[CheckTransactionKeys.Status] === 'CAPTURED',
      );
    }
  }
}
