import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ContentChild,
  inject,
  input,
  TemplateRef,
} from '@angular/core';
import {
  KioskSimpleProductStepperComponent,
  SimpleProductWithAdditionsGroupStepperData,
} from './kiosk-simple-product-stepper.component';
import { DoodProductModel } from '@core/models/product.model';
import { CartDraftDeclinableProductStore } from '@shared/local-store/cart/cart-draft-declinable-product.store';
import { DeclinableProductStepperStore } from '@shared/local-store/stepper/declinable-product-stepper.store';

export type StepperCategoryTplData =
  | {
      setSelectedProductId: (productId: string) => void;
    }
  | undefined;

export function forceStepperCategoryTplData(data: any) {
  return data as StepperCategoryTplData;
}

export function forceSimpleProductWithAdditionsGroupStepperData(data: any) {
  return data as SimpleProductWithAdditionsGroupStepperData;
}

@Component({
  selector: 'app-kiosk-category-stepper',
  standalone: true,
  imports: [CommonModule, KioskSimpleProductStepperComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container *ngIf="!categoryStepper()?.selectedSimpleProductId">
      <ng-container
        *ngTemplateOutlet="
          categoryTpl;
          context: {
            $implicit: categoryTplData(),
          }
        "
      ></ng-container>
    </ng-container>
    <ng-container *ngIf="categoryStepper()?.selectedSimpleProductId">
      @let product = simpleProduct();
      @if (productAdditionsByGroup() && product) {
        <app-kiosk-simple-product-stepper
          [simpleProduct]="product"
          [additionsByGroups]="productAdditionsByGroup()"
          (updateAdditionQuantity)="updateAdditionQuantity($event)"
        >
          <ng-template
            #stepperAdditionsGroupTpl
            let-displayedAdditionsGroupData
          >
            @let simpleProductWithAdditionsGroupStepperData =
              forceSimpleProductWithAdditionsGroupStepperData(displayedAdditionsGroupData);
            <ng-container
              *ngTemplateOutlet="
                additionsGroupTpl;
                context: {
                  $implicit: simpleProductWithAdditionsGroupStepperData,
                }
              "
            ></ng-container>
          </ng-template>
        </app-kiosk-simple-product-stepper>
      }
    </ng-container>
  `,
})
export class KioskCategoryStepperComponent {
  private readonly declinableProductStepperStore = inject(DeclinableProductStepperStore);
  private readonly cartDraftDeclinableProductStore = inject(CartDraftDeclinableProductStore);

  public readonly categoryId = input.required<string>();

  protected readonly categoryStepper = computed(() => {
    return this.declinableProductStepperStore.steppers()?.categoryStepper;
  });

  protected updateAdditionQuantity(data: { additionId: string; quantity: number }) {
    const productId = this.categoryStepper()?.selectedSimpleProductId;
    if (!productId) {
      return;
    }
    this.cartDraftDeclinableProductStore.updateAdditionQuantity({
      ...data,
      categoryId: this.categoryId(),
      productId,
    });
  }

  @ContentChild('categoryTpl', { read: TemplateRef })
  categoryTpl!: TemplateRef<unknown>;

  @ContentChild('additionsGroupTpl', { read: TemplateRef })
  additionsGroupTpl!: TemplateRef<unknown>;

  protected readonly categoryTplData = computed<StepperCategoryTplData>(() => {
    return {
      setSelectedProductId: selectedProductId => {
        // todoR check si on peut simplifier
        this.declinableProductStepperStore.setSelectedProductId(selectedProductId);
      },
    };
  });

  protected forceSimpleProductWithAdditionsGroupStepperData(data: any) {
    return data as SimpleProductWithAdditionsGroupStepperData;
  }

  protected productPriceOverrides = (product: DoodProductModel, productId: string) => {
    const result = product.price_overrides?.find(
      priceOverride => priceOverride.product === productId,
    );
    return result;
  };

  protected readonly simpleProduct = computed(() => {
    return this.cartDraftDeclinableProductStore
      .selectors()
      ?.draftProductsByCategoryId[
        this.categoryId()
      ].find(product => product.id === this.categoryStepper()?.selectedSimpleProductId);
  });

  protected readonly productAdditionsByGroup = computed(() => {
    const simpleProductId = this.categoryStepper()?.selectedSimpleProductId;
    if (!simpleProductId) {
      return undefined;
    }
    return this.cartDraftDeclinableProductStore.selectors()?.draftAdditionGroupsBySimpleProductId[
      simpleProductId
    ];
  });
}
