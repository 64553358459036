import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { CartService } from '@core/services/cart/cart.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { BasketService } from '@core/services/basket/basket.service';
import { PaymentStoreDispatcher } from '@common/dispatchers/payment.dispatcher';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { throwError, timer } from 'rxjs';
import { DoodOrderModel } from '@store/order/order.model';
import { OrderKeys } from '@config/keys/order.keys';
import { catchError, delay, map, takeUntil, tap } from 'rxjs/operators';
import { PaymentMethodHandles } from '@config/payment-methods.config';
import { DoodApiError } from '@shared/interfaces/error.interface';
import { Paths } from '@config/paths.config';
import { YavinTerminalService } from '@core/services/yavin-terminal/yavin-terminal.service';
import { KioskService } from '@core/services/kiosk/kiosk.service';
import { YavinTransactionResponse } from '@core/services/api/yavin-terminal/yavin-terminal-api.service';

@Component({
  selector: 'app-payment-panel-yavin-terminal',
  templateUrl: './payment-panel-yavin-terminal.component.html',
  styles: [],
})
export class PaymentPanelYavinTerminalComponent extends DestroyerBase implements OnInit, OnDestroy {
  isPaymentInProgress = false;
  isOrderCancelled = false;
  displayPaymentLoadingScreen = false;
  statusMessage?: string;
  errorMessage?: string;
  isPaid = false;
  isOnError = false;
  isLoading = true;
  loadKioskResetTimer = false;

  constructor(
    private readonly router: Router,
    private readonly cartService: CartService,
    private readonly ordersService: OrdersService,
    private readonly basketService: BasketService,
    private readonly paymentDispatcher: PaymentStoreDispatcher,
    private readonly yavinTerminalService: YavinTerminalService,
    private readonly routerHelper: RouterHelperService,
    private readonly kioskService: KioskService,
  ) {
    super();
  }

  ngOnInit(): void {
    timer(1).subscribe(() => {
      this.paymentDispatcher.updateUI({
        error: null,
        button: {
          isEnabled: false,
          isVisible: false,
        },
        status: {
          isLoading: false,
        },
      });
    });

    this.createOrder();
  }

  async ngOnDestroy(): Promise<void> {
    if (!this.isPaid) {
      this.statusMessage = 'ANNULATION';
      await this.yavinTerminalService.abortTransaction$().pipe(delay(3000)).toPromise();
    }
    super.ngOnDestroy();
  }

  createOrder(): void {
    this.isOnError = false;
    this.isLoading = true;
    this.errorMessage = '';
    this.statusMessage = '';
    this.loadKioskResetTimer = false;

    this.ordersService
      .createOrder$(PaymentMethodHandles.YavinTerminal)
      .pipe(
        takeUntil(this._destroyerRef),
        tap(() => {
          this.isLoading = false;
        }),
        map(order => (order ? this.startTransaction(order) : null)),
      )
      .subscribe({
        error: (response: { error: DoodApiError }) => {
          this.handleOrderError(response.error);
        },
      });
  }

  private startTransaction(order: DoodOrderModel): void {
    const finalPrice = order[OrderKeys.FinalPrice];

    this.errorMessage = '';
    this.statusMessage = '';

    if (typeof finalPrice !== 'number') {
      this.errorMessage = 'Erreur : montant incorrect';
      return;
    }
    console.log('[Yavin ETK] Start transaction', {
      amount: finalPrice,
      orderNumber: order[OrderKeys.Number],
      orderId: order[OrderKeys.Id],
    });

    this.yavinTerminalService
      .startTransaction$(finalPrice, order[OrderKeys.Number], order[OrderKeys.Id])
      .pipe(
        tap(transactionResponse => {
          console.log('[Yavin ETK] Transaction response', transactionResponse);
          this.isLoading = false;

          if (transactionResponse.status === 'ko') {
            this.handlePaymentError(transactionResponse.message ?? 'Erreur');
          }

          if (transactionResponse.status === 'ok') {
            this.errorMessage = undefined;
            this.handlePaymentSuccess(order, transactionResponse);
          }

          if (transactionResponse.message) {
            this.statusMessage = transactionResponse.message;
          }
        }),
        catchError(err => {
          this.handlePaymentError('Impossible de communiquer avec le terminal de paiement.');
          return throwError(() => err);
        }),
      )
      .subscribe();
  }

  private handlePaymentError(errorMessage: string): void {
    console.log('[YAVIN_TERMINAL] Launch reset timer');
    errorMessage = 'Transaction annulée';
    this.loadKioskResetTimer = true;

    this.isLoading = false;
    this.errorMessage = errorMessage;
    this.statusMessage = 'ERREUR';
    this.isOnError = true;
  }

  private handlePaymentSuccess(
    order: DoodOrderModel,
    transactionResponse: YavinTransactionResponse,
  ): void {
    const clientCardTicketLines = transactionResponse.clientCardTicket.split('\n');

    this.yavinTerminalService
      .changeOrderStatusToPayment$(order.id, transactionResponse.transactionId ?? 'UNKNOWN')
      .pipe(
        tap(() => (this.isPaid = true)),
        tap(order => this.kioskService.printLines(clientCardTicketLines)),
        tap(order => this.handleChangeOrderStatusToPaymentSuccess(order)),
      )
      .subscribe();
  }

  private handleChangeOrderStatusToPaymentSuccess(order: DoodOrderModel): void {
    this.basketService.clearBasket();
    this.cartService.clearCart();
    this.router.navigate([
      this.routerHelper.translateRoute(`${Paths.Orders}/${order.id}/${Paths.Status}`),
    ]);
    this.isPaymentInProgress = false;
  }

  private handleOrderError(error: Partial<DoodApiError>): void {
    this.isLoading = false;
    this.errorMessage = error.detail ?? 'Une erreur est survenue.';
  }

  backToCart(): void {
    this.router.navigate([this.routerHelper.translateRoute(`${Paths.Cart}`)]);
  }
}
