<div
  class="sticky top-0 z-30 flex relative justify-center h-16 kiosk:h-22 items-center cursor-pointer py-6 kiosk:py-10 px-5 shadow shadow-bottom text-center"
  [class.pt-14]="isNotched"
  [style.color]="textColor | contentBuilderColor"
  [style.backgroundColor]="backgroundColor | contentBuilderColor"
>
  <i
    *ngIf="icon"
    class="absolute left-5 top icon icon-{{ icon }} mr-2"
    (click)="navigationClick.emit()"
  ></i>
  <span
    *ngIf="text"
    class="font-accent font-bold text-md"
    [class.lg-hidden]="!isProductHeader"
    >{{ text }}</span
  >
  <div
    *ngIf="menu"
    class="absolute right-5"
    (click)="openNavigation.emit()"
  >
    <app-hamburger-menu-atom></app-hamburger-menu-atom>
  </div>
</div>
