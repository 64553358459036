<div
  *ngIf="tipsAllowed$ | async"
  class="mx-auto mb-3 max-w-md px-5 lg:max-w-full lg:px-0"
>
  <app-cart-tip-atom
    [choice1Amount]="choice1Amount"
    [choice1Unit]="choice1Unit"
    [choice2Amount]="choice2Amount"
    [choice2Unit]="choice2Unit"
    [choice3Amount]="choice3Amount"
    [choice3Unit]="choice3Unit"
    [totalAmount]="((totalAmount$ | async) ?? 0) / 100"
    (tipEmitter)="onSelectedTip($event)"
  ></app-cart-tip-atom>
</div>
