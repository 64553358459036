import { Component, Input } from '@angular/core';
import { IContentBuilderFieldImage } from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-home-hero-showcase-block',
  templateUrl: './home-hero-showcase-block.component.html',
})
export class HomeHeroShowcaseBlockComponent {
  @Input() title?: string;
  @Input() linkUrl?: string;
  @Input() linkLabel?: string;
  @Input() buttonSizeY: 'S' | 'M' | 'L' = 'M';
  @Input() backgroundImage?: IContentBuilderFieldImage;

  scrollTo(): void {
    const pageHeight = window.innerHeight;
    window.scrollBy(0, pageHeight);
  }
}
