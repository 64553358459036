<div
  class="w-full max-w-screen-sm lg:max-w-full text-sm"
  [style.color]="textColor | contentBuilderColor"
>
  <div class="lg:flex w-full">
    <div
      class="hidden lg:block relative w-5/12 bg-neutral-100"
      *ngIf="displayImage"
    >
      <img
        *ngIf="imageSrc"
        class="absolute z-10 object-cover h-40 w-full h-full"
        [src]="imageSrc"
        alt=""
      />
      <div
        class="absolute top-0 bottom-0 left-0 right-0 bg-gradient-to-b from-neutral-800 to-transparent opacity-30 z-10"
        [class.bg-black]="closedLabel"
        [class.opacity-60]="closedLabel"
      ></div>
      <div
        *ngIf="closedLabel"
        class="absolute top-0 bottom-0 left-0 right-0 text-2xl text-white font-bold flex flex-col justify-center items-center z-10 px-8 text-center"
      >
        <p class="block">{{ closedLabel }}</p>
        <p
          *ngIf="isPreorderingAllowed"
          class="block px-5 py-2 mt-2 text-base border border-white rounded-3xl cursor-pointer"
          (click)="openCartParameters()"
        >
          {{ 'shop-page.planning-for-later' | translate }}
        </p>
      </div>
    </div>
    <div class="lg:w-7/12 lg:py-20 lg:px-14">
      <div class="flex items-center py-0.5 lg:py-0">
        <h2
          class="flex-1 font-bold lg:mb-2 leading-none"
          [style.color]="shopNameColor | contentBuilderColor"
          [ngClass]="{
            'text-2xl lg:text-3xl': titleSize === 'XS',
            'text-3xl lg:text-4xl': titleSize === 'S',
            'text-3.5xl lg:text-5.8xl': titleSize === 'M',
            'text-5xl lg:text-6xl': titleSize === 'L',
            'font-brand': titleFont === 'brand',
            'font-accent': titleFont === 'accent',
            'font-base': titleFont === 'base',
          }"
        >
          {{ name }}
        </h2>
      </div>
      <p>
        <span
          class="dot"
          *ngIf="displayCategory && category && !shortDescription"
          >{{ category }}</span
        >
        <span
          class="dot"
          *ngIf="displayCategory && shortDescription"
          >{{ shortDescription }}</span
        >
        <app-star-atom
          [star]="stars"
          *ngIf="stars"
        ></app-star-atom>
      </p>
      <p *ngIf="displayAddress">
        <span
          class="dot"
          *ngIf="address || city"
          >{{ address }} - {{ postcode }} {{ city }}</span
        >
        <span
          class="dot"
          *ngIf="formattedDistance"
          >À {{ formattedDistance }}</span
        >
      </p>
      <div class="flex flex-wrap items-center pt-3 lg:py-0 lg:pt-6">
        <app-button-atom
          class="pr-2 pb-2"
          *ngIf="displayGroupedOrderButton"
          [size]="displayGroupedOrderButton && displayMoreInfoButton ? 'XS' : 'S'"
          [label]="
            (basketId ? 'shop-page.share-my-grouped-order' : 'shop-page.create-my-grouped-order')
              | translate
          "
          type="light"
          [icon]="'icon-group-order'"
          (clickEventEmitter)="groupOrderClick.emit()"
        ></app-button-atom>
        <app-button-atom
          class="pr-2 pb-2"
          *ngIf="displayMoreInfoButton"
          [size]="displayGroupedOrderButton && displayMoreInfoButton ? 'XS' : 'S'"
          [label]="'shop-page.more-informations' | translate"
          type="light"
          (clickEventEmitter)="showMoreClick.emit()"
        ></app-button-atom>
        <app-button-atom
          class="pr-2 pb-2"
          *ngIf="displayBookButton"
          size="S"
          [label]="
            (bookButtonLabelOverride ? bookButtonLabelOverride : 'shop-page.book') | translate
          "
          type="light"
          (clickEventEmitter)="bookButtonClick.emit()"
        ></app-button-atom>
        <!-- <button
                    [class.icon-heart]="!isFavorite" [class.icon-todo]="isFavorite"
                    (click)="onFavoriteClick()"
                    class="hidden md:block icon w-8 h-8 rounded-full font-bold text-primary-600 bg-primary-100"
                ></button> -->
        <ng-container *ngIf="links">
          <a
            *ngFor="let link of links"
            (click)="clickLink.emit(link)"
            class="dot dot-2 text-primary-600 text-sm leading-5 cursor-pointer"
          >
            {{ link.label }}
          </a>
        </ng-container>
      </div>
    </div>
  </div>
</div>
