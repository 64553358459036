import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { ModalsService } from '@core/services/modals/modals.service';
import { ResetStateService } from '@core/services/reset-state.service';

import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';

import { UserKeys } from '@config/keys/user.keys';
import { DoodUserModel } from '@store/authentication/authentication.model';
import { ModalScrollBlockBase } from '@core/base/modalScrollBlock/modal-scroll-block.base';
import { WaitingModalComponent } from '@shared/modals/waiting-modal/waiting-modal.component';
import { AuthenticationUserJourneyType } from '@shared/blocks/authentication-block/authentication-user-journey-type';
import { ModalStoreSelector } from '@common/selectors/modal.selector';

@Component({
  selector: 'app-authentication-modal',
  templateUrl: './authentication-modal.component.html',
})
export class AuthenticationModalComponent
  extends ModalScrollBlockBase
  implements OnInit, OnDestroy
{
  private _destroyerRef = new Subject<boolean>();

  static handle = 'authentication-modal';

  @Input() userJourneyType = AuthenticationUserJourneyType.ASK_EMAIL_FIRST;
  @Input() legalCheckboxLabel?: string;
  @Input() optinCheckboxLabel?: string;

  @Input() firstStepHeading!: string;
  @Input() firstStepLoginButton!: string;
  @Input() firstStepSignupButton!: string;
  @Input() loginStepHeading!: string;
  @Input() signupStepHeading!: string;
  @Input() signupStepSubheading!: string;
  @Input() signupStepDisplayFirstname = true;
  @Input() signupStepFirstnameLabel!: string;
  @Input() signupStepFirstnameHelp!: string;
  @Input() signupStepDisplayLastname = true;
  @Input() signupStepLastnameLabel!: string;
  @Input() signupStepLastnameHelp!: string;
  @Input() signupStepEmailLabel!: string;
  @Input() signupStepEmailHelp!: string;
  @Input() signupStepDisplayPhone = true;
  @Input() signupStepPhoneLabel!: string;
  @Input() signupStepPhoneHelp!: string;
  @Input() signupStepPasswordLabel!: string;
  @Input() signupStepPasswordHelp!: string;
  @Input() signupFooter?: any;
  @Input() authenticationProviderGoogle = true;
  @Input() authenticationProviderFacebook = true;
  @Input() authenticationProviderApple = false;

  userKeys = UserKeys;
  user: DoodUserModel | null = null;

  user$ = this.authSelector.selectUser;
  index$: Observable<number> = this.modalSelector
    .selectModal(AuthenticationModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  constructor(
    readonly store: Store,
    readonly authSelector: AuthStoreSelector,
    readonly modalSelector: ModalStoreSelector,
    private readonly modalsService: ModalsService,
    readonly settingsSelector: SettingsStoreSelector,
    private readonly translateService: TranslateService,
    private readonly resetStateService: ResetStateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.user$
      .pipe(
        takeUntil(this._destroyerRef),
        tap(user => (this.user = user)),
      )
      .subscribe();

    if (!this.firstStepHeading) {
      this.firstStepHeading = this.translateService.instant('authentication.title');
    }
    if (!this.firstStepLoginButton) {
      this.firstStepLoginButton = this.translateService.instant('authentication.login-action');
    }
    if (!this.firstStepSignupButton) {
      this.firstStepSignupButton = this.translateService.instant('authentication.signup-action');
    }
    if (!this.loginStepHeading) {
      this.loginStepHeading = this.translateService.instant('authentication.email-login-title');
    }
    if (!this.signupStepHeading) {
      this.signupStepHeading = this.translateService.instant('signup.title');
    }
    if (!this.signupStepFirstnameLabel) {
      this.signupStepFirstnameLabel = this.translateService.instant('signup.firstname');
    }
    if (!this.signupStepLastnameLabel) {
      this.signupStepLastnameLabel = this.translateService.instant('signup.lastname');
    }
    if (!this.signupStepEmailLabel) {
      this.signupStepEmailLabel = this.translateService.instant('signup.email');
    }
    if (!this.signupStepPhoneLabel) {
      this.signupStepPhoneLabel = this.translateService.instant('signup.phone');
    }
    if (!this.signupStepPasswordLabel) {
      this.signupStepPasswordLabel = this.translateService.instant('signup.password');
    }
    if (!this.signupStepPasswordHelp) {
      this.signupStepPasswordHelp = this.translateService.instant('signup.password-information');
    }
  }

  ngOnDestroy(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
    super.ngOnDestroy();
  }

  close(): void {
    this.modalsService.close(AuthenticationModalComponent.handle);
  }

  async logout(): Promise<void> {
    this.modalsService.open(WaitingModalComponent.handle);
    this.resetStateService.refreshUI();
  }
}
