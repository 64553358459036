import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AnalyticsService} from "@core/services/app/analytics.service";

@Component({
  selector: 'app-email-receipt-atom',
  templateUrl: './email-receipt-atom.component.html',
})
export class EmailReceiptAtomComponent {
  @Input() title!: string;
  @Input() text!: string;
  @Input() icon!: string;
  @Input() email!: string;
  @Input() firstName!: string;

  @Output() openModal = new EventEmitter<string>();

  constructor(
    private readonly analyticsService: AnalyticsService,
  ) {
    this.analyticsService.trackEvent('receive_receipt_display');
  }
}
