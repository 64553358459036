import { Pipe, PipeTransform } from '@angular/core';
import { IProductLabel } from '@core/models/product.model';

@Pipe({
  name: 'appVisibleLabels',
})
export class VisibleLabelsPipe implements PipeTransform {
  transform(labels: IProductLabel[] | undefined): IProductLabel[] {
    if (!labels) {
      return [];
    }

    const visibleLabels = labels.filter(label => label.visible);

    // Filtrage et tri des labels
    const sortedMarketPlaceLabels = visibleLabels
      .filter(label => label.entity_owner?.entity_type === 'marketplace')
      .sort((a, b) => a.position - b.position);
    const sortedShopLabels = visibleLabels
      .filter(label => label.entity_owner?.entity_type === 'shop')
      .sort((a, b) => a.position - b.position);

    // Merge product labels with same name
    const uniqueLabelsMap = new Map<string, IProductLabel>();
    [...sortedMarketPlaceLabels, ...sortedShopLabels].forEach(label => {
      if (!uniqueLabelsMap.has(label.name)) {
        uniqueLabelsMap.set(label.name, label);
      }
    });

    const productLabels = Array.from(uniqueLabelsMap.values());

    return productLabels;
  }

}
