import {
  PAT_STORE_API_PIECE,
  PAT_STORE_COMPONENT_PIECE,
  PAT_STORE_LOCAL_PIECE,
} from './payment-at-table.piece';
import {
  PAT_STORE_INITIAL_STATE,
  PAT_STORE_KEY,
  PaymentAtTableState,
} from './payment-at-table.state';
import { createHydratedReducer } from '@store/_abstract/hydrated';

// export const PAT_STORE_REDUCER = createReducer(
//   PAT_STORE_INITIAL_STATE,
//   ...PAT_STORE_API_PIECE,
//   ...PAT_STORE_LOCAL_PIECE,
//   ...PAT_STORE_COMPONENT_PIECE,
// );

export const PAT_STORE_REDUCER = createHydratedReducer({
  key: PAT_STORE_KEY,
  initialState: PAT_STORE_INITIAL_STATE,
  beforeLoad: (_state: PaymentAtTableState) => {
    const _result: PaymentAtTableState = {
      ..._state,
    };
    return _result;
  },
  ons: [...PAT_STORE_API_PIECE, ...PAT_STORE_LOCAL_PIECE, ...PAT_STORE_COMPONENT_PIECE],
});
