import { Injectable } from '@angular/core';
import { DoodUserModel } from '@store/authentication/authentication.model';
import { DOOD_API } from '@config/ws.config';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  constructor(private readonly httpClient: HttpClient) {}

  getUserApi$(): Observable<DoodUserModel> {
    return this.httpClient.get<DoodUserModel>(`${DOOD_API}/users/me`);
  }

  async getUserPromise(): Promise<DoodUserModel | undefined> {
    return new Promise<undefined | DoodUserModel>(resolve => {
      this.httpClient
        .get<DoodUserModel>(`${DOOD_API}/users/me`)
        .toPromise()
        .then(user => {
          resolve(user);
        })
        .catch(() => {
          resolve(undefined);
        });
    });
  }

  createUserApi$(user: Partial<DoodUserModel>): Observable<DoodUserModel> {
    return this.httpClient.post<DoodUserModel>(`${DOOD_API}/users`, user);
  }

  createAnonymousUserApi$(): Observable<DoodUserModel> {
    return this.httpClient.post<DoodUserModel>(`${DOOD_API}/users/anonymous`, {});
  }

  upgradeAnonymousUserApi$(user: Partial<DoodUserModel>, id: string): Observable<DoodUserModel> {
    return this.httpClient.patch<DoodUserModel>(`${DOOD_API}/users/${id}/upgrade-anonymous`, user);
  }

  updatePersonalInfo$(user: Partial<DoodUserModel>, id: string): Observable<DoodUserModel> {
    return this.httpClient.patch<DoodUserModel>(`${DOOD_API}/users/` + id, user);
  }
}
