import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import { DoodAnchorModel } from '@store/anchor/anchor.model';
import { ANCHOR_STORE_LOCAL_ACTIONS } from '@store/anchor/anchor.action';

@Injectable({ providedIn: 'root' })
export class AnchorStoreDispatcher {
  constructor(private store: Store) {}

  // One
  addAnchor(anchor: DoodAnchorModel): void {
    this.store.dispatch(ANCHOR_STORE_LOCAL_ACTIONS.addAnchor({ anchor }));
  }

  removeAnchor(id: string): void {
    this.store.dispatch(ANCHOR_STORE_LOCAL_ACTIONS.removeAnchor({ id }));
  }

  // Misc
  reset(): void {
    this.store.dispatch(ANCHOR_STORE_LOCAL_ACTIONS.reset());
  }
}
