import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, throwError } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { catchError, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { OrderStoreSelector } from '@common/selectors/order.selector';
import { BasketStoreSelector } from '@common/selectors/basket.selector';
import { PaymentStoreDispatcher } from '@common/dispatchers/payment.dispatcher';
import { TransactionStoreSelector } from '@common/selectors/transaction.selector';

import { DoodOrderModel } from '@store/order/order.model';
import { DoodApiError } from '@shared/interfaces/error.interface';
import { DoodTransactionModel, DoodTransactionStatus } from '@core/models/transaction.model';

import { Paths } from '@config/paths.config';
import { PaymentMethodHandles } from 'src/app/config/payment-methods.config';
import { ORDER_INVALID_STATUS, TRANSACTION_EXCEEDS_TOTAL } from 'src/app/config/errors.config';

import { CartService } from '@core/services/cart/cart.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { BasketService } from '@core/services/basket/basket.service';
import { PaymentService } from '@core/services/payment/payment.service';
import { TransactionsService } from '@core/services/transactions/transactions.service';
import { RouterHelperService } from 'src/app/core/services/router-helper/router-helper.service';

@Component({
  template: ``,
  selector: 'app-payment-panel-on-site-without-deposit',
})
export class PaymentPanelOnSiteWithoutDepositComponent implements OnInit, OnDestroy {
  protected readonly unsubscribe$ = new Subject<boolean>();

  private transaction?: DoodTransactionModel;

  public customerGroupLabel?: string;

  constructor(
    private readonly router: Router,
    private orderSelector: OrderStoreSelector,
    private readonly cartService: CartService,
    private basketSelector: BasketStoreSelector,
    private readonly ordersService: OrdersService,
    private readonly basketService: BasketService,
    private readonly paymentService: PaymentService,
    private paymentDispatcher: PaymentStoreDispatcher,
    private readonly routerHelper: RouterHelperService,
    private readonly translateService: TranslateService,
    private transactionSelector: TransactionStoreSelector,
    private readonly transactionsService: TransactionsService,
  ) {}

  ngOnInit(): void {
    this.paymentDispatcher.updateUI({
      button: {
        isEnabled: true,
        isVisible: true,
        text: this.customerGroupLabel
          ? 'payment.validate-on-site-zero'
          : 'payment.validate-on-site',
      },
      status: {
        isInProgress: false,
      },
    });

    this.paymentService.pay$
      .pipe(takeUntil(this.unsubscribe$), take(1))
      .subscribe(() => this.handlePayment());
  }

  ngOnDestroy(): void {
    if (this.transaction && this.transaction.status === DoodTransactionStatus.payment) {
      this.cancelAbortedTransaction(this.transaction.id);
    }

    this.paymentDispatcher.resetUI();
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();

    window.onbeforeunload = (): void => {};
  }

  private handlePayment(): void {
    this.paymentDispatcher.updateUI({
      button: {
        isEnabled: false,
      },
      status: {
        isInProgress: true,
      },
    });

    const basket = this.basketSelector.basket;

    let order$: Observable<DoodOrderModel | null | undefined>;

    if (basket && basket?.order) {
      order$ = this.orderSelector.selectOrder(basket?.order as string);
    } else {
      order$ = this.orderSelector.selectActive;
    }

    order$
      .pipe(
        switchMap(order =>
          order?.payment_service === PaymentMethodHandles.Transactions
            ? this.createOnsiteTransaction()
            : this.createOnsiteOrder(),
        ),
        take(1),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  private createOnsiteTransaction(): Observable<DoodTransactionModel> {
    return this.transactionSelector.selectAmount.pipe(
      switchMap(amount =>
        amount && amount >= 1
          ? this.transactionsService.createTransaction$({
              amount,
              payment_method: PaymentMethodHandles.OnsiteWithoutDeposit,
            })
          : throwError(() => ({
              error: {
                detail: 'group-payments.errors.amount-is-undefined-or-0',
              },
            })),
      ),
      tap(transaction => {
        this.transaction = transaction;
        this.transactionsService.clearActiveTransaction();
        const basket = this.basketSelector.basket;

        if (transaction.order === basket.order) {
          this.router.navigate([this.routerHelper.translateRoute(`${Paths.GroupPayment}`)]);
        }
      }),
      take(1),
      takeUntil(this.unsubscribe$),
      catchError(error => {
        this.handleTransactionError(error.error);
        return throwError(() => error);
      }),
    );
  }

  private createOnsiteOrder(): Observable<DoodOrderModel | null> {
    return this.ordersService.createOrder$(PaymentMethodHandles.OnsiteWithoutDeposit).pipe(
      take(1),
      tap(order => {
        if (order) {
          this.router.navigate([
            this.routerHelper.translateRoute(`${Paths.Orders}/${order.id}/${Paths.Status}`),
          ]);
        } else {
          console.error("Error: createOnsiteOrder doesn't return Order");
        }
        this.cartService.clearCart();
        this.basketService.clearBasket();
      }),
    );
  }

  private handleTransactionError(error: DoodApiError): void {
    let errorMessage;
    switch (error?.detail) {
      case ORDER_INVALID_STATUS:
        this.translateService
          .get('group-payments.errors.order-invalid-status')
          .pipe(
            take(1),
            tap(translation => alert(translation)),
            tap(() => {
              this.ordersService.clearOrders();
              this.cartService.clearCart();
              this.transactionsService.clearTransactions();
              this.basketService.clearBasket();
              this.router.navigate([this.routerHelper.translateRoute('/')]);
            }),
          )
          .subscribe();
        return;
      case TRANSACTION_EXCEEDS_TOTAL:
        errorMessage = 'group-payments.errors.amount-exceeds-total';
        break;
      default:
        errorMessage = error?.detail || 'payment.unknown-error';
    }

    this.paymentDispatcher.updateUI({
      error: errorMessage,
      button: {
        isEnabled: false,
      },
      status: {
        isLoading: false,
        isInProgress: false,
      },
    });
  }

  private cancelAbortedTransaction(id?: string): void {
    if (!id) {
      return;
    }

    this.transactionsService
      .cancelTransaction$(id)
      .pipe(
        tap(() => this.transactionsService.clearActiveTransaction()),
        take(1),
      )
      .subscribe();
  }
}
