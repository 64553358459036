import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { PaymentAtTableStoreSelector } from '@app/common/selectors/payment-at-table.selector';
import {
  PAT_ERROR_MESSAGES,
  PAT_ERROR_NO_CHECKS_OPEN,
  PAT_ERROR_NOT_FOUND,
} from '@config/values/pat.values';

@Injectable({ providedIn: 'root' })
export class PaymentAtTableStoreRefiner {
  // allTransactionsItems$
  selectTransactionsItems = this.selector.selectCheckTransactions.pipe(
    map(transactions => {
      return transactions.reduce((result, transaction) => {
        if (!transaction.items) {
          return result;
        }
        return [...result, ...transaction.items];
      }, new Array<string>());
    }),
  );

  /// errorMessage$
  selectErrorMessage = this.selector.selectCheckError.pipe(
    map(error => {
      if (!error?.detail) {
        return null;
      }
      if (error?.detail && error?.detail === PAT_ERROR_NO_CHECKS_OPEN) {
        return PAT_ERROR_MESSAGES.NO_CHECKS_OPEN;
      } else if (error?.detail && error?.detail === PAT_ERROR_NOT_FOUND) {
        return PAT_ERROR_MESSAGES.NOT_FOUND;
      } else {
        return PAT_ERROR_MESSAGES.TRY_AGAIN_LATER;
      }
    }),
  );

  constructor(private selector: PaymentAtTableStoreSelector) {}
}
