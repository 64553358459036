<ng-container *ngIf="orderValidate$ | async as orderValidate">
  <div
    *ngIf="cartHasProduct$ | async"
    class="mx-auto max-w-md"
    [style.color]="textColor | contentBuilderColor"
  >
    <ng-container *ngIf="(getServiceCharges(orderValidate)?.length ?? 0) > 0">
      <div class="px-5 lg:px-0">
        <div *ngFor="let item of getServiceCharges(orderValidate)">
          <app-overview-cart-additional-atom
            [text]="item.name ?? ''"
            [price]="item.final_price ?? 0"
            [textColor]="textColor"
            [secondaryTextColor]="secondaryTextColor"
          ></app-overview-cart-additional-atom>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="forceType(orderValidate)[orderKeys.DeliveryPriceAmount]">
      <div class="px-5 lg:px-0">
        <app-overview-cart-additional-atom
          *ngIf="forceType(orderValidate)[orderKeys.DeliveryPriceAmount]"
          [text]="'basket.delivery-costs' | translate"
          [icon]="'icon-bike'"
          [price]="forceType(orderValidate)[orderKeys.DeliveryPriceAmount]"
          [textColor]="textColor"
          [secondaryTextColor]="secondaryTextColor"
        ></app-overview-cart-additional-atom>
      </div>
    </ng-container>
  </div>
</ng-container>

<div class="mx-auto max-w-md">
  <ng-container *ngIf="errorMessages">
    <div
      class="ng-star-inserted my-6 mb-3 hidden rounded bg-error-50 px-4 py-3 font-base text-sm text-error-700 lg:block"
      *ngFor="let message of errorMessages"
    >
      {{ message }}
    </div>
  </ng-container>

  <ng-container *ngIf="missingLocationIdError">
    <div
      class="ng-star-inserted my-6 mb-3 hidden rounded bg-error-50 px-4 py-3 font-base text-sm text-error-700 lg:block"
    >
      {{ missingLocationIdError }}
    </div>
  </ng-container>
</div>

<ng-container *ngIf="orderValidate$ | async as orderValidate; else loading">
  <div
    *ngIf="cartHasProduct$ | async"
    class="mx-auto mb-3 max-w-md"
  >
    <app-cart-total-amount-atom
      [totalAmount]="orderValidate[orderKeys.FinalPrice] ?? 0"
      [usePointsAmount]="orderValidate[orderKeys.UsePoints]?.amount ?? 0"
      [discount]="forceType(orderValidate)[orderKeys.CouponsAmountOff]"
      [coupons]="orderValidate[orderKeys.Coupons]"
      [tips]="orderValidate[orderKeys.Tip]?.amount ?? 0"
      [isBasketExist]="!!(basketId$ | async)"
      [headingColor]="headingColor"
      [textColor]="textColor"
      [secondaryTextColor]="secondaryTextColor"
      (removeCoupon)="removeCoupon($event)"
      (removeUsePoints)="removeUsePoints()"
    ></app-cart-total-amount-atom>
    <div class="hidden lg:block">
      <app-button-atom
        [size]="'L'"
        [type]="'primary'"
        (click)="goToPayment()"
        [disabled]="
          isGoToPaymentInProgress ||
          !!errorMessages.length ||
          !(orderHasProduct$ | async) ||
          (missingPhone$ | async) === true ||
          onSiteLocationIsMissing === true ||
          !!missingLocationIdError
        "
        [label]="
          orderValidate[orderKeys.FinalPrice] === 0
            ? ('basket.payment_zero' | translate)
            : ('basket.payment' | translate)
        "
        [iconUrl]="isGoToPaymentInProgress ? 'assets/images/spinner.svg' : ''"
        *ngIf="
          !!forceType(orderValidate)[orderKeys.AvailablePaymentMethods].length &&
          !(basketId$ | async)
        "
      ></app-button-atom>
      <!-- Buttons for shared order -->
      <app-button-atom
        *ngIf="
          !!forceType(orderValidate)[orderKeys.AvailablePaymentMethods].length &&
          (basketId$ | async) &&
          (basket$ | async)?.user?.id === (userId$ | async)
        "
        [label]="'basket.payment' | translate"
        [type]="'primary'"
        [size]="'L'"
        (click)="confirmOrder()"
        [disabled]="
          isGoToPaymentInProgress ||
          !!errorMessages.length ||
          !(orderHasProduct$ | async) ||
          (missingPhone$ | async) === true ||
          onSiteLocationIsMissing === true ||
          !!missingLocationIdError
        "
        [iconUrl]="isGoToPaymentInProgress ? 'assets/images/spinner.svg' : ''"
      ></app-button-atom>
      <app-button-atom
        *ngIf="
          !!forceType(orderValidate)[orderKeys.AvailablePaymentMethods]?.length &&
          (basketId$ | async) &&
          (basket$ | async)?.user?.id !== (userId$ | async)
        "
        [label]="'basket.validate-order' | translate"
        [type]="'primary'"
        [size]="'L'"
        (click)="confirmOrder()"
        [disabled]="
          isGoToPaymentInProgress ||
          !!errorMessages.length ||
          !(orderHasProduct$ | async) ||
          (missingPhone$ | async) === true ||
          !!missingLocationIdError
        "
        [iconUrl]="isGoToPaymentInProgress ? 'assets/images/spinner.svg' : ''"
      ></app-button-atom>
    </div>

    <!-- Withdrawing share order from guest -->
    <div
      class="mt-4 px-5 lg:px-0"
      *ngIf="(basketId$ | async) && (basket$ | async)?.user?.id !== (userId$ | async)"
    >
      <app-button-atom
        [icon]="'icon-cross'"
        [thickSize]="'S'"
        [full]="true"
        [color]="'neutral'"
        [type]="'light'"
        [label]="'group-order-modal.withdrawing-order-button' | translate"
        (click)="exitGroupOrder()"
      ></app-button-atom>
    </div>
    <!-- Cancel share order from master -->
    <div
      class="mt-4 px-5 lg:px-0"
      *ngIf="(basketId$ | async) && (basket$ | async)?.user?.id === (userId$ | async)"
    >
      <app-button-atom
        [icon]="'icon-cross'"
        [thickSize]="'S'"
        [full]="true"
        [color]="'neutral'"
        [type]="'light'"
        [label]="'group-order-modal.cancel-order-button' | translate"
        (click)="cancelGroupOrder()"
      ></app-button-atom>
    </div>
  </div>
</ng-container>

<div
  *ngIf="cartHasProduct$ | async"
  class="mx-auto mb-3 max-w-md"
>
  <!-- clear cart button -->
  <div class="flex flex-row justify-center pt-6">
    <app-button-atom
      [label]="'basket.clear-basket' | translate"
      size="XS"
      type="outlined"
      thickSize="XS"
      color="neutral"
      [icon]="'icon-garbage'"
      (click)="clearCart()"
    ></app-button-atom>
  </div>
</div>

<ng-template #loading>
  <div class="hidden lg:block">
    <app-button-atom
      type="primary"
      size="L"
      [label]="'basket.payment' | translate"
      [disabled]="true"
    ></app-button-atom>
  </div>
</ng-template>
