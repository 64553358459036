import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-kiosk-pager-atom',
  templateUrl: './kiosk-pager-atom.component.html',
})
export class KioskPagerAtomComponent {
  @Input() color?: IContentBuilderFieldColor;
  @Input() value!: string;
  @Input() pagerSize!: number;
  @Input() minPagerSize?: number;

  @Output() valueEmitter = new EventEmitter<string | boolean>();

  pagerValue = '';

  constructor() {}

  onNumberClick(value: number | string): void {
    const valueStr = value.toString();
    if (this.pagerValue.length < (this.minPagerSize ?? this.pagerSize)) {
      this.pagerValue += valueStr;
      this.onInputChange();
    }
  }

  onDeleteClick(): void {
    this.pagerValue = '';
    this.emitValue();
  }

  onInputChange(): void {
    if (this.pagerValue.length === (this.minPagerSize ?? this.pagerSize)) {
      this.emitValue();
    }
  }

  emitValue(): void {
    this.valueEmitter.emit(this.pagerValue);
  }
}
