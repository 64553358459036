import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';

import {
  SELECT_CART_FUNNEL_ACTIVE_STEP,
  SELECT_CART_FUNNEL_ACTIVE_STEP_ID,
  SELECT_CART_FUNNEL_IS_LAST_STEP_ACTIVE,
  SELECT_CART_FUNNEL_LAST_STEP,
  SELECT_CART_FUNNEL_NEXT_STEP,
  SELECT_CART_FUNNEL_NEXT_STEP_ID,
  SELECT_CART_FUNNEL_ORDER_COMMENT,
  SELECT_CART_FUNNEL_STEP,
  SELECT_CART_FUNNEL_STEPS_ALL,
  SELECT_CART_FUNNEL_USER_NAME,
  SELECT_CART_FUNNEL_VALUE,
  SELECT_CART_FUNNEL_VALUES_ALL,
} from '@store/cart-funnel/cart-funnel.selection';
import { DoodCartFunnelStep, DoodCartFunnelValue } from '@store/cart-funnel/cart-funnel.model';

@Injectable({ providedIn: 'root' })
export class CartFunnelStoreSelector implements OnDestroy {
  private readonly _destroyerRef = new Subject<boolean>();

  steps: DoodCartFunnelStep[] = [];
  values: DoodCartFunnelValue[] = [];
  activeStep: DoodCartFunnelStep | null = null;

  selectValues = this.store.select(SELECT_CART_FUNNEL_VALUES_ALL);
  selectValue = (id: string): Observable<DoodCartFunnelValue | undefined> =>
    this.store.select(SELECT_CART_FUNNEL_VALUE(id));

  selectSteps = this.store.select(SELECT_CART_FUNNEL_STEPS_ALL);
  selectStep = (id: string): Observable<DoodCartFunnelStep | undefined> =>
    this.store.select(SELECT_CART_FUNNEL_STEP(id));

  selectNextStep = this.store.select(SELECT_CART_FUNNEL_NEXT_STEP);
  selectNextStepId = this.store.select(SELECT_CART_FUNNEL_NEXT_STEP_ID);

  selectActiveStep = this.store.select(SELECT_CART_FUNNEL_ACTIVE_STEP);
  selectActiveStepId = this.store.select(SELECT_CART_FUNNEL_ACTIVE_STEP_ID);

  selectLastStep = this.store.select(SELECT_CART_FUNNEL_LAST_STEP);
  selectIsLastStepActive = this.store.select(SELECT_CART_FUNNEL_IS_LAST_STEP_ACTIVE);

  selectOrderComment = this.store.select(SELECT_CART_FUNNEL_ORDER_COMMENT);

  selectfunnelUsername = this.store.select(SELECT_CART_FUNNEL_USER_NAME);

  constructor(private store: Store) {
    this.selectValues.pipe(takeUntil(this._destroyerRef)).subscribe(_values => {
      this.values = _values;
    });

    this.selectSteps.pipe(takeUntil(this._destroyerRef)).subscribe(_steps => {
      this.steps = _steps;
    });

    this.selectActiveStep.pipe(takeUntil(this._destroyerRef)).subscribe(_active => {
      this.activeStep = _active;
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe();
  }

  private _unsubscribe(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
  }
}
