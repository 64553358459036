export enum PaymentModalAmountModes {
  Full = 'full',
  Own = 'own',
  Remaining = 'remaining',
}

export const paymentOptions = [
  {
    label: 'pay-entire-amount',
    mode: PaymentModalAmountModes.Full,
  },
  {
    label: 'pay-for-own-products',
    mode: PaymentModalAmountModes.Own,
  },
  {
    label: 'pay-remaining-amount',
    mode: PaymentModalAmountModes.Remaining,
  },
];
