<ul class="inline-flex bg-neutral-100 rounded-4xl p-2">
  <li
    *ngFor="let item of items"
    class="flex items-center mr-6 cursor-pointer"
    (click)="clickItem.emit(item)"
  >
    <div
      class="mr-2"
      *ngIf="item.icon"
    >
      <app-rounded-icon-button
        [icon]="item.icon"
        [outlined]="true"
      ></app-rounded-icon-button>
    </div>
    <span [innerHTML]="item.label"></span>
  </li>
  <li
    class="flex items-center"
    (click)="clickAction.emit()"
  >
    <button class="icon icon-arrow-down-s w-10 h-10"></button>
  </li>
</ul>
