import { Injectable } from '@angular/core';

export interface InjectableScript {
  id?: string;
  src: string;
}

@Injectable({ providedIn: 'root' })
export class ScriptInjector {
  private _loadedScripts: Record<string, boolean> = {};
  private _scriptsPromises = new Map<string, Promise<void>>();

  isScriptLoaded(src: string): boolean {
    return Boolean(this._loadedScripts[src]);
  }

  injectScript(script: InjectableScript): Promise<void> {
    const { id, src } = script;

    if (this.isScriptLoaded(src)) {
      return Promise.resolve();
    }

    const _existingPromise = this._scriptsPromises.get(src);
    if (_existingPromise) {
      return _existingPromise;
    }

    const _promise = new Promise<void>((resolve, reject) => {
      const _script = document.createElement('script');

      _script.src = src;
      _script.async = true;
      _script.id = id ?? '';
      _script.onload = (): void => {
        this._loadedScripts[src] = true;
        resolve();
      };
      _script.onerror = (error: unknown): void => {
        this._loadedScripts[src] = false;
        this._scriptsPromises.delete(src);
        reject(error);
      };

      document.body.appendChild(_script);
    });

    this._scriptsPromises.set(src, _promise);
    return _promise;
  }
}
