import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pat-base-modal',
  templateUrl: './pat-base-modal.component.html',
})
export class PatBaseModalComponent {
  @Input() blur = false;
  @Output() closeModal = new EventEmitter<void>();
}
