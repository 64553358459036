<div class="flex h-full w-full flex-col">
  <div class="flex-1 overflow-y-auto">
    <div
      class="flex w-full flex-1 flex-col p-5"
      [style.backgroundColor]="backgroundColor | contentBuilderColor"
      [style.color]="textColor | contentBuilderColor"
    >
      <div
        *ngIf="(asset$ | async) && (asset$ | async)?.items_selection"
        class="gap-5"
      >
        <ng-container *ngFor="let step of steps; let i = index">
          <app-kiosk-como-step-atom
            [step]="step"
            [backgroundColor]="backgroundColor"
            [textColor]="textColor"
            [buttonBackgroundColor]="primaryButtonBackgroundColor"
            [buttonTextColor]="primaryButtonTextColor"
            (changeBufferProduct)="changeBufferProduct(i, $event)"
          ></app-kiosk-como-step-atom>
        </ng-container>
      </div>
      <div
        *ngIf="!(asset$ | async) || !(asset$ | async)?.items_selection"
        class="font-4xl"
      >
        {{ 'zerosix.offered-product-modal.no-product-available' | translate }}
      </div>
    </div>
  </div>

  <div class="w-full self-end">
    <app-kiosk-footer-double-button-atom
      [backgroundColor]="backgroundColor"
      [textColor]="textColor"
      [firstButtonBackgroundColor]="secondaryButtonBackgroundColor"
      [firstButtonTextColor]="secondaryButtonTextColor"
      firstButtonLabel="Annuler"
      [secondButtonBackgroundColor]="primaryButtonBackgroundColor"
      [secondButtonTextColor]="primaryButtonTextColor"
      [secondButtonDisabled]="!allStepValid()"
      secondButtonLabel="Valider"
      (onFirstButtonClick)="onCancel()"
      (onSecondButtonClick)="onValidate()"
    >
    </app-kiosk-footer-double-button-atom>
  </div>
</div>
