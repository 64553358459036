import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ComoService } from '@core/services/como/como.service';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { AuthFirebaseService } from '@core/services/api/auth-firebase/auth-firebase.service';
import { UserService } from '@core/services/user/user.service';
import { UserCredential } from 'firebase/auth';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-phone-number-step',
  templateUrl: './phone-number-step.component.html',
})
export class PhoneNumberStepComponent extends DestroyerBase implements OnInit {
  @Input() phone = '';

  @Output() onClose = new EventEmitter<Event>();
  @Output() setPhone = new EventEmitter<string>();
  @Output() goToNextStep = new EventEmitter<void>();
  @Output() goToAuthenticationStep = new EventEmitter<void>();
  @Output() goToAccountNotFoundStep = new EventEmitter<void>();
  @Output() goToKioskAccountNotFoundStep = new EventEmitter<void>();

  @Input() accentColor?: IContentBuilderFieldColor;

  form: UntypedFormGroup;
  isValidating = false;
  errorMessage?: string;

  kioskParameters = this.settingsSelector.kiosk;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly comoService: ComoService,
    private readonly authFirebaseService: AuthFirebaseService,
    private readonly userService: UserService,
    private readonly settingsSelector: SettingsStoreSelector,
  ) {
    super();
    this.form = this.formBuilder.group({
      ['phone']: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.form.setValue({ phone: this.phone ?? '' });
  }

  onRegister(): void {
    const phone = this.form.controls['phone']?.value;

    if (this.form.invalid || this.isValidating || !phone) {
      return;
    }
    this.isValidating = true;
    this.errorMessage = undefined;
    this.comoService
      .linkComoUser$({ phoneOrEmail: phone })
      .pipe(
        takeUntil(this._destroyerRef),
        tap(response => {
          if (response.otp_sent) {
            this.setPhone.next(phone);
            this.goToNextStep.next();
          } else {
            this.goToAuthenticationStep.next();
          }
        }),
      )
      .subscribe({
        error: error => this.handleErrorResponse(error.error, phone),
        complete: () => (this.isValidating = false),
      });
  }

  anonymousLogin(): void {
    this.authFirebaseService
      .signInAnonymously()
      .pipe(
        switchMap((userCredential: UserCredential) =>
          this.authFirebaseService.saveFirebaseTokenInSessionStore$(userCredential),
        ),
        switchMap(() => this.userService.createAnonymousUser$()),
        switchMap(() => this.authFirebaseService.loadUser$()),
      )
      .subscribe();
  }

  onKeypadNumberClick(number: number): void {
    const currentValue = this.form.controls.phone.value;
    this.form.controls.phone.setValue(currentValue + number.toString());
  }

  onKeypadDeleteClick(): void {
    const currentValue = this.form.controls.phone.value;
    this.form.controls.phone.setValue(currentValue.slice(0, -1)); // Supprime le dernier caractère
  }

  private handleErrorResponse(error: any, phone: string): void {
    if (error.code === 202) {
      this.setPhone.next(phone);
      if (!this.kioskParameters.isKioskLayout) {
        this.goToAccountNotFoundStep.next();
      } else {
        this.goToKioskAccountNotFoundStep.next();
      }
    }
    this.errorMessage = this.comoService.getErrorMessageFromCode(error.code);
  }
}
