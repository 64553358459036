import { timer } from 'rxjs';
import { Component, Input } from '@angular/core';

import { ModalsService } from '@core/services/modals/modals.service';

@Component({
  selector: 'app-modal-button-block',
  templateUrl: './modal-button-block.component.html',
})
export class ModalButtonBlockComponent {
  @Input() label!: string;
  @Input() linkUrl?: string;
  @Input() modalHandle!: string;
  @Input() size: 'XXL' | 'L' | 'M' | 'S' | 'XS' = 'S';
  @Input() action: 'CLOSE_MODAL' | 'GO_TO_URL' = 'CLOSE_MODAL';
  @Input() style: 'primary' | 'light' | 'outlined' | 'link' = 'primary';

  constructor(private readonly modalsService: ModalsService) {}

  close(): void {
    if (this.modalHandle) {
      this.modalsService.close(this.modalHandle);
    }
  }

  click($event: Event): void {
    timer(0).subscribe(() => {
      this.close();
    });
  }
}
