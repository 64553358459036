import { InjectionToken, Signal } from '@angular/core';
import { CategoryStep } from '../stepper.type';
import { DoodProductModel, IDeclinableCategory } from '@core/models/product.model';
import { AutoFillCartItemRulesCategory } from '../../cart/auto-fill-cart-item-rules/auto-fill-cart-item-rules-category';
import { DraftProductsByCategoryId } from '@shared/local-store/cart/cart-draft-declinable-product.store';
import {
  CategoryProductValidity,
  TotalQuantityByCategoryId,
} from '@shared/local-store/cart/util/cart-declinable-product-util';
import { StepInfo } from '@core/models/stepper.model';

export const CategoryConfigStepperToken = new InjectionToken<CategoryStepperConfig>(
  'CategoryConfigStepperToken is used to provide the config for the category stepper',
);

export type CategoryStepperConfigData = {
  category: IDeclinableCategory;
  totalSelectedQuantity: number;
  isCategoryValid: boolean;
  allProductsOfTheCategory: DoodProductModel[];
};

export type CategoryStepperConfig = Signal<CategoryStepperConfigData | undefined>;

export class CategoryStepperUtil {
  static calculateStepStates({
    selectedCategoryId,
    category,
    totalSelectedQuantity,
    isCategoryValid,
    selectedSimpleProductId,
    allProductsOfTheCategory,
    setSelectedStep,
  }: {
    selectedCategoryId: string;
    category: IDeclinableCategory;
    totalSelectedQuantity: number;
    isCategoryValid: boolean;
    selectedSimpleProductId: string | undefined;
    allProductsOfTheCategory: DoodProductModel[];
    setSelectedStep: (stepsId: StepInfo) => void;
  }): CategoryStep | undefined {
    const maxCategoryQuantity = category.count || Infinity;

    const canMakeMoreChoices = totalSelectedQuantity < maxCategoryQuantity;

    const isAutoSelectedProductWithAdditions =
      AutoFillCartItemRulesCategory.isExclusiveProductWithAdditions({
        category,
        categoryProducts: allProductsOfTheCategory,
      });
    const isSkippable = isAutoSelectedProductWithAdditions;
    const isCategoryWithOnlyPreSelectedProductsWithSomeWithAdditionsGroups =
      this.isSkippableCategoryWithOnlyPreSelectedProductsWithSomeWithAdditionsGroups({
        canMakeMoreChoices,
        isCategoryValid,
        allProductsOfTheCategory,
        isSkippable,
      });
    const isSelectedStepWithAdditionsGroup = !!allProductsOfTheCategory.find(
      product => product.id === selectedSimpleProductId,
    )?.additions.products.length;

    const hasNextStep = !isCategoryValid && canMakeMoreChoices;
    const hasPreviousStep = !!selectedSimpleProductId && !isSkippable;
    return {
      type: 'category',
      category,
      mustHaveChildStepper:
        isCategoryWithOnlyPreSelectedProductsWithSomeWithAdditionsGroups ||
        isSelectedStepWithAdditionsGroup,
      selectedSimpleProductId,
      selectedStepId: selectedSimpleProductId,
      isValid: isCategoryValid,
      canMakeMoreChoices,
      minStepCount: category.min_count ?? 0,
      maxStepCount: category.count ?? Infinity,
      count: totalSelectedQuantity,
      hasNextStep,
      hasPreviousStep,
      previousStepId: undefined,
      nextStepId: undefined,
      skippable: isSkippable,
      nextStep: hasNextStep
        ? {
            categoryId: selectedCategoryId,
            simpleProductId: undefined,
          }
        : undefined,
      previousStep: hasPreviousStep
        ? {
            categoryId: selectedCategoryId,
            simpleProductId: undefined,
          }
        : undefined,
      setSelectedStep,
    } satisfies CategoryStep;
  }

  /**
   * This method does not check if the additions group are preselected by default
   */
  static isSkippableCategoryWithOnlyPreSelectedProductsWithSomeWithAdditionsGroups({
    isCategoryValid,
    canMakeMoreChoices,
    allProductsOfTheCategory,
    isSkippable,
  }: {
    isCategoryValid: boolean;
    canMakeMoreChoices: boolean;
    allProductsOfTheCategory: DoodProductModel[];
    isSkippable: boolean;
  }) {
    const isCategoryStepCompleted = isCategoryValid && !canMakeMoreChoices;
    const hasCategoryProductWithAdditionsGroup = allProductsOfTheCategory.some(
      product => product.additions.products.length > 0,
    );

    return isCategoryStepCompleted && hasCategoryProductWithAdditionsGroup && isSkippable;
  }

  static getCategoryData({
    selectedCategoryId,
    declinableProduct,
    compiledCategoriesValidity,
    draftProductsByCategoryId,
    totalSelectedQuantityByCategoryId,
  }: {
    selectedCategoryId: string | undefined;
    declinableProduct: DoodProductModel;
    compiledCategoriesValidity: CategoryProductValidity[];
    draftProductsByCategoryId: DraftProductsByCategoryId;
    totalSelectedQuantityByCategoryId: TotalQuantityByCategoryId;
  }) {
    const currentCategoryId = selectedCategoryId;
    if (!currentCategoryId) {
      return undefined;
    }

    const selectedCategory = declinableProduct.categories.find(
      category => category.store_id === currentCategoryId,
    );
    if (!selectedCategory) {
      return undefined;
    }

    const isCategoryValid =
      compiledCategoriesValidity.find(
        categoryValidity => categoryValidity?.categoryId === currentCategoryId,
      )?.isCategoryValid ?? false;

    const allProductsOfTheCategory = draftProductsByCategoryId?.[currentCategoryId];
    if (!allProductsOfTheCategory) {
      return undefined;
    }
    return {
      category: selectedCategory,
      totalSelectedQuantity: totalSelectedQuantityByCategoryId?.[currentCategoryId] ?? 0,
      isCategoryValid,
      allProductsOfTheCategory,
    } satisfies CategoryStepperConfigData;
  }
}
