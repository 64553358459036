import { createFeature, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';

import { MODAL_STORE_REDUCER } from './modal.reducer';
import { DoodModalModel } from '@core/models/modal.model';
import { MODAL_STORE_KEY, MODAL_STORE_ADAPTER } from './modal.state';

const MODAL_STORE_FEATURE = createFeature({
  name: MODAL_STORE_KEY,
  reducer: MODAL_STORE_REDUCER,
});

export const { selectModalsState: SELECT_MODAL_STATE } = MODAL_STORE_FEATURE;

export const {
  selectIds: SELECT_MODALS_IDS,
  selectAll: SELECT_MODALS_ALL,
  selectTotal: SELECT_MODALS_TOTAL,
  selectEntities: SELECT_MODALS_ENTITIES,
} = MODAL_STORE_ADAPTER.getSelectors(SELECT_MODAL_STATE);

export const SELECT_MODALS_ENTITY = (
  id: string,
): MemoizedSelector<
  Record<string, any>,
  DoodModalModel | undefined,
  DefaultProjectorFn<DoodModalModel | undefined>
> =>
  createSelector(SELECT_MODALS_ENTITIES, entities => {
    return entities[id];
  });
