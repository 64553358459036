import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'discountPercent',
})
export class DiscountPercentPipe implements PipeTransform {
  transform(originalPrice: number, discountedPrice: number): string {
    return '-' + ((100 * (originalPrice - discountedPrice)) / originalPrice).toFixed() + '%';
  }
}
