import { Component } from '@angular/core';
import { PaymentMethodHandles } from '@config/payment-methods.config';

@Component({
  selector: 'app-payment-panel-stripe-googlepay',
  templateUrl: './payment-panel-stripe-googlepay.component.html',
})
export class PaymentPanelStripeGooglepayComponent {
  paymentMethodHandles = PaymentMethodHandles;
}
