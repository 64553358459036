import { Injectable } from '@angular/core';
import { DOOD_API } from '@config/ws.config';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IEdenredGetUserResponse, IEdenredWebLoginResponse } from '@core/models/edenred.model';

@Injectable({
  providedIn: 'root',
})
export class EdenredApiService {
  constructor(private readonly httpClient: HttpClient) {}

  getUser$(): Observable<IEdenredGetUserResponse> {
    return this.httpClient.get<any>(`${DOOD_API}/edenred/check`);
  }

  edenredWebLogin$(redirectUri: string): Observable<IEdenredWebLoginResponse> {
    return this.httpClient.get<any>(`${DOOD_API}/edenred/weblogin?redirect_uri=${redirectUri}`);
  }
}
