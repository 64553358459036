export enum CartItemKeys {
  Id = 'id',
  ItemId = 'item_id',
  ShopId = 'shop_id',
  Products = 'products',
  Additions = 'additions',
  Quantity = 'quantity',
  StoreId = 'store_id',
  SeparateAdditionsByGroup = 'separate_additions_by_group',
  Name = 'name',
  IsServiceCharge = 'is_service_charge',
  FinalPrice = 'final_price',
}

export enum CartKeys {
  Id = 'id',
  Handle = 'handle',
  CartId = 'cart_id',
  CartCreatedAt = 'cart_created_at',
  CartItems = 'cart_items',
  Currency = 'currency',
  ConfirmedBy = 'confirmed_by',
  DeliveryProvider = 'delivery_provider',
  OnSiteLocationId = 'on_site_location_id',
  OnSiteLocationName = 'on_site_location_name',
  Order = 'order',
  PaymentService = 'payment_service',
  Message = 'message',
  User = 'user',
  Type = 'type',
  AuthMode = 'auth_mode',
  WhiteLabel = 'white_label',
  Marketplace = 'marketplace',
  MultiShop = 'multi_shop',
  CustomerDevice = 'customer_device',
  AppPlatform = 'app_platform',
  WantedAt = 'wanted_at',
  Products = 'products',
  Coupons = 'coupons',
  Shop = 'shop',
  ShopSlug = 'shop_slug',
  SubCarts = 'sub_carts',
  RegisterUserToComo = 'register_user_to_como',
  UsePoints = 'use_points',
  UsePointsAmount = 'amount',
  UsePointsVerificationCode = 'verification_code',
  ContactPhone = 'contact_phone',
  DeliveryAddress = 'delivery_address',
  Shared = 'shared',
  ShareCode = 'share_code',
  Total = 'total',
  Tip = 'tip',
  PaymentPhoneNumber = 'PaymentPhoneNumber',
  OnBehalfOf = 'on_behalf_of',
  IsUserSelectedDistributionMode = 'is_user_selected_distribution_mode',
  Instructions = 'instructions',
}

export enum ReconstructedCartKeys {
  Shop = 'shop',
  Items = 'items',
}

export enum ReconstructedCartItemKeys {
  Item = 'item',
  Product = 'product',
  ChildCompoundItems = 'child_compound_items',
  UnitPrice = 'unit_price',
  DiscountPrice = 'discount_price',
}
