import { createEntityAdapter } from '@ngrx/entity';

import { createHydratedAdapter, HydrationState } from '@store/_abstract/hydrated';

import {
  DoodCreateOrderQuery,
  DoodOrderModel,
  DoodOrderViolationModel,
  DoodValidateOrderQuery,
} from './order.model';
import { ActiveState, createActiveAdapter } from '@store/_abstract/active';

export interface OrderErrorState extends Record<string, unknown> {
  error: unknown | null;
  missing_number: boolean;
  violations: DoodOrderViolationModel[];
}

export interface OrderState extends ActiveState<DoodOrderModel, string>, HydrationState {
  isLoaded: boolean;
  isLoading: boolean;
  isValidating: boolean;
  errors: OrderErrorState;
  validation: DoodValidateOrderQuery | DoodCreateOrderQuery | null;
}

export const ORDER_STORE_KEY = 'Orders';

export const ORDER_STORE_ACTIVE_ADAPTER = createActiveAdapter<DoodOrderModel, string>();

export const ORDER_STORE_HYDRATION_ADAPTER = createHydratedAdapter();

export const ORDER_STORE_ADAPTER = createEntityAdapter<DoodOrderModel>();

export const ORDER_ERROR_INITIAL_STATE: OrderErrorState = {
  error: null,
  violations: [],
  missing_number: false,
};

export const ORDER_STORE_INITIAL_STATE: OrderState = ORDER_STORE_HYDRATION_ADAPTER.getInitialState(
  ORDER_STORE_ACTIVE_ADAPTER.getInitialState({
    isLoaded: false,
    isLoading: false,
    validation: null,
    isValidating: false,
    errors: ORDER_ERROR_INITIAL_STATE,
  }),
);
