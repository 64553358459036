import { ReducerTypes, ActionCreator, on } from '@ngrx/store';

import {
  PAT_STORE_INITIAL_AMOUNT_STATE,
  PAT_STORE_INITIAL_CHECK_STATE,
  PAT_STORE_INITIAL_CHOOSE_STATE,
  PAT_STORE_INITIAL_PRODUCTS_STATE,
  PAT_STORE_INITIAL_STATE,
  PAT_STORE_INITIAL_TYPE_STATE,
  PaymentAtTableState,
} from './payment-at-table.state';
import { PAT_STORE_COMPONENT_ACTIONS, PAT_STORE_LOCAL_ACTIONS } from './payment-at-table.action';

export const PAT_STORE_API_PIECE: ReducerTypes<PaymentAtTableState, readonly ActionCreator[]>[] =
  [];

export const PAT_STORE_CHOOSE_PIECE: ReducerTypes<PaymentAtTableState, readonly ActionCreator[]>[] =
  [
    // Amount
    on(PAT_STORE_LOCAL_ACTIONS.updateAmount, (state, { amount }): PaymentAtTableState => {
      return { ...state, choose: { ...state.choose, amount } };
    }),
    on(PAT_STORE_LOCAL_ACTIONS.resetAmount, (state): PaymentAtTableState => {
      return { ...state, choose: { ...state.choose, amount: PAT_STORE_INITIAL_AMOUNT_STATE } };
    }),

    // Tip
    on(PAT_STORE_LOCAL_ACTIONS.updateTip, (state, { tip }): PaymentAtTableState => {
      return { ...state, choose: { ...state.choose, tip } };
    }),
    on(PAT_STORE_LOCAL_ACTIONS.resetTip, (state): PaymentAtTableState => {
      return { ...state, choose: { ...state.choose, tip: null } };
    }),

    // Products
    on(PAT_STORE_LOCAL_ACTIONS.addProducts, (state, { products }): PaymentAtTableState => {
      const _result = new Set([...state.choose.products, ...products]);
      return { ...state, choose: { ...state.choose, products: Array.from(_result) } };
    }),
    on(PAT_STORE_LOCAL_ACTIONS.setProducts, (state, { products }): PaymentAtTableState => {
      return { ...state, choose: { ...state.choose, products } };
    }),
    on(PAT_STORE_LOCAL_ACTIONS.resetProducts, (state): PaymentAtTableState => {
      return { ...state, choose: { ...state.choose, products: PAT_STORE_INITIAL_PRODUCTS_STATE } };
    }),

    // Parts
    on(PAT_STORE_LOCAL_ACTIONS.updateParts, (state, { parts }): PaymentAtTableState => {
      return { ...state, choose: { ...state.choose, parts } };
    }),
    on(PAT_STORE_LOCAL_ACTIONS.resetParts, (state): PaymentAtTableState => {
      return { ...state, choose: { ...state.choose, parts: null } };
    }),

    // Type
    on(PAT_STORE_LOCAL_ACTIONS.updateType, (state, { value: type }): PaymentAtTableState => {
      return { ...state, choose: { ...state.choose, type } };
    }),
    on(PAT_STORE_LOCAL_ACTIONS.resetType, (state): PaymentAtTableState => {
      return { ...state, choose: { ...state.choose, type: PAT_STORE_INITIAL_TYPE_STATE } };
    }),

    // Error
    on(PAT_STORE_LOCAL_ACTIONS.updateError, (state, { error }): PaymentAtTableState => {
      return { ...state, choose: { ...state.choose, error } };
    }),
    on(PAT_STORE_LOCAL_ACTIONS.resetError, (state): PaymentAtTableState => {
      return { ...state, choose: { ...state.choose, error: null } };
    }),
  ];

export const PAT_STORE_CHECK_PIECE: ReducerTypes<PaymentAtTableState, readonly ActionCreator[]>[] =
  [];

export const PAT_STORE_LOCAL_PIECE: ReducerTypes<PaymentAtTableState, readonly ActionCreator[]>[] =
  [
    // Choose
    ...PAT_STORE_CHOOSE_PIECE,

    // Check
    ...PAT_STORE_CHECK_PIECE,

    // Misc
    on(PAT_STORE_LOCAL_ACTIONS.reset, (): PaymentAtTableState => {
      return PAT_STORE_INITIAL_STATE;
    }),
  ];

export const PAT_STORE_COMPONENT_PIECE: ReducerTypes<
  PaymentAtTableState,
  readonly ActionCreator[]
>[] = [
  on(PAT_STORE_COMPONENT_ACTIONS.updateState, (state, { changes }): PaymentAtTableState => {
    return { ...state, ...changes };
  }),
  // Choose
  on(PAT_STORE_COMPONENT_ACTIONS.updateChoose, (state, { changes }): PaymentAtTableState => {
    return { ...state, choose: { ...state.choose, ...changes } };
  }),
  on(PAT_STORE_COMPONENT_ACTIONS.resetChoose, (state): PaymentAtTableState => {
    return { ...state, choose: PAT_STORE_INITIAL_CHOOSE_STATE };
  }),
  // Check
  on(PAT_STORE_COMPONENT_ACTIONS.updateCheck, (state, { changes }): PaymentAtTableState => {
    return { ...state, check: { ...state.check, ...changes } };
  }),
  on(PAT_STORE_COMPONENT_ACTIONS.resetCheck, (state): PaymentAtTableState => {
    return { ...state, check: PAT_STORE_INITIAL_CHECK_STATE };
  }),
];
