import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { CurrencyPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime, map, take, takeUntil, tap } from 'rxjs/operators';

import { UserKeys } from '@config/keys/user.keys';

import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

import { UserService } from '@core/services/user/user.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { TransactionsService } from '@core/services/transactions/transactions.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';

import { PatternUtil } from '@shared/utils/pattern/pattern.utils';
import { ModalScrollBlockBase } from '@core/base/modalScrollBlock/modal-scroll-block.base';

import { OrderStoreSelector } from '@common/selectors/order.selector';
import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { BasketStoreSelector } from '@common/selectors/basket.selector';
import { TransactionStoreRefiner } from '@common/refiners/transaction.refiner';

import { Paths } from '@config/paths.config';
import { PaymentModalAmountModes, paymentOptions } from '@config/modals/add-payment-modal.config';

@Component({
  selector: 'app-add-payment-modal',
  templateUrl: './add-payment-modal.component.html',
  styleUrls: ['./add-payment-modal.component.scss'],
})
export class AddPaymentModalComponent extends ModalScrollBlockBase implements OnInit, OnDestroy {
  private _destroyerRef = new Subject<boolean>();

  static handle = 'add-payment-modal';

  private currency$ = this.marketplaceSelector.selectMarketplaceCurrency;

  index$ = this.modalSelector
    .selectModal(AddPaymentModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  currencyInputDebounceSubject$ = new Subject<any>();
  userHasPaid$ = this.transactionRefiner.selectAreTransactionsPaidByUser;

  paymentOptions = paymentOptions;
  selectedPaymentMethod = paymentOptions[1];
  paymentModalAmountModes = PaymentModalAmountModes;

  customAmount = 0;
  ownAmount!: number;
  remainingAmount = 0;
  totalAmount: number;

  basketUserItems!: string[];
  basketAllItems!: string[];

  errorMessage?: string;

  form!: UntypedFormGroup;

  disablePayment = false;

  constructor(
    private readonly router: Router,
    private readonly fb: UntypedFormBuilder,
    private modalSelector: ModalStoreSelector,
    private orderSelector: OrderStoreSelector,
    private readonly userService: UserService,
    private basketSelector: BasketStoreSelector,
    private readonly currencyPipe: CurrencyPipe,
    private readonly modalsService: ModalsService,
    private readonly routerHelper: RouterHelperService,
    private marketplaceSelector: MarketplaceStoreSelector,
    private transactionRefiner: TransactionStoreRefiner,
    private readonly transactionsService: TransactionsService,
  ) {
    super();
    this.createForm();
    this.totalAmount = this.orderSelector.active?.final_price || 0;
  }

  ngOnInit(): void {
    this.listenToCurrencyInputDebounce();
    this.setOwnAmount();
    this.setRemainingAmount();

    this.transactionRefiner.selectAreTransactionsPaidByUser.pipe(take(1)).subscribe(arePaid => {
      if (arePaid) {
        this.selectedPaymentMethod = paymentOptions[2];
      }
    });
  }

  ngOnDestroy(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
    super.ngOnDestroy();
  }

  onSubmit(): void {
    this.errorMessage = undefined;
    const amount = this.getTransactionAmount();
    if (amount > this.remainingAmount) {
      this.errorMessage = 'group-payments.errors.amount-exceeds-total';
      return;
    }
    this.transactionsService.setTransactionAmount(amount);
    this.transactionsService.setTransactionInstantAmountMode(this.selectedPaymentMethod.mode);
    this.close();
    this.router.navigate([this.routerHelper.translateRoute(`/${Paths.Payment}`)]);
  }

  changePaymentOption(option: any): void {
    this.selectedPaymentMethod = option;
  }

  close(): void {
    this.modalsService.close(AddPaymentModalComponent.handle);
  }

  private createForm(): void {
    this.currency$
      .pipe(
        take(1),
        tap(() => {
          this.form = this.fb.group({
            amount: ['', [Validators.required]],
          });
        }),
      )
      .subscribe();
  }

  updateCurrencyField(value: any): void {
    const onlyNumbers = value.target.value.replace(/[,]/g, '.').replace(/[^\d,.]/g, '');
    const currencyAmount = Number(onlyNumbers.match(PatternUtil.decimalNumber)?.[0]) || 1;
    this.currency$
      .pipe(
        take(1),
        tap(currency => {
          this.form.value.amount = this.currencyPipe.transform(currencyAmount, currency, 'symbol');
          value.target.value = this.form.value.amount;
        }),
      )
      .subscribe();

    this.customAmount = currencyAmount * 100;
  }

  private setOwnAmount(): void {
    this.basketSelector.selectSubCarts
      .pipe(
        take(1),
        map(subCarts =>
          subCarts.find(cart => cart.user[UserKeys.Id] === this.userService.user?.id),
        ),
      )
      .subscribe(subCart => (this.ownAmount = subCart?.total || 0));
  }

  private setRemainingAmount(): void {
    this.transactionRefiner.selectTotalPaidAmount
      .pipe(
        takeUntil(this._destroyerRef),
        tap(amountPaid => (this.remainingAmount = this.totalAmount - amountPaid)),
      )
      .subscribe();
  }

  private getTransactionAmount(): number {
    switch (this.selectedPaymentMethod.mode) {
      case PaymentModalAmountModes.Own:
        return this.ownAmount <= this.remainingAmount ? this.ownAmount : this.remainingAmount;
      case PaymentModalAmountModes.Full:
      case PaymentModalAmountModes.Remaining:
        return this.remainingAmount;
    }
  }

  private listenToCurrencyInputDebounce(): void {
    this.currencyInputDebounceSubject$
      .pipe(
        takeUntil(this._destroyerRef),
        debounceTime(1000),
        tap(event => this.updateCurrencyField(event)),
      )
      .subscribe(() => (this.disablePayment = false));
  }
}
