import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import {
  MARKETPLACE_STORE_COMPONENT_ACTIONS,
  MARKETPLACE_STORE_LOCAL_ACTIONS,
  MarketplaceState,
} from '@store/marketplace';
import { DoodMarketplaceModel } from '@core/models/marketplace.model';

@Injectable({ providedIn: 'root' })
export class MarketplaceStoreDispatcher {
  constructor(private store: Store) {}

  updateState(changes: Partial<MarketplaceState>): void {
    this.store.dispatch(MARKETPLACE_STORE_COMPONENT_ACTIONS.updateState({ changes }));
  }

  updateMarketplace(changes: Partial<DoodMarketplaceModel>): void {
    this.store.dispatch(MARKETPLACE_STORE_LOCAL_ACTIONS.updateMarketplace({ changes }));
  }

  reset(): void {
    this.store.dispatch(MARKETPLACE_STORE_LOCAL_ACTIONS.reset());
  }
}
