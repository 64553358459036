import { HYDRATED_STORE_INITIAL_STATE, HydrationState } from './hydrated.state';

export interface HydratedAdapter {
  resetHydratable<State extends HydrationState>(state: State): State;
  updateHydratable<State extends HydrationState>(hydratable: boolean, state: State): State;
  getInitialState<State extends object>(state?: State): HydrationState & State;
}

export const createHydratedAdapter = (): HydratedAdapter => {
  return {
    getInitialState: <State extends HydrationState>(state?: Partial<State>): State => {
      return {
        ...HYDRATED_STORE_INITIAL_STATE,
        ...state,
      } as State;
    },

    // Hydratable
    updateHydratable: <State extends HydrationState>(hydratable: boolean, state: State): State => {
      return { ...state, _hydratable: hydratable };
    },
    resetHydratable: <State extends HydrationState>(state: State): State => {
      return { ...state, ...HYDRATED_STORE_INITIAL_STATE };
    },
  };
};
