<div class="mb-3 px-5 lg:px-0 max-w-md lg:max-w-full mx-auto">
  <div
    *ngIf="orderPickupLocation$ | async as location"
    class="py-5 px-4.5 border-neutral-400 border text-neutral-800 rounded rounded-s"
  >
    <div class="flex items-center pb-5">
      <i class="icon icon-pin mr-3"></i>
      <span class="font-base font-bold lg:text-md">{{
        'basket.additonnal-informations.address-pickup-location' | translate
      }}</span>
    </div>
    <div class="font-base text-neutral-600 lg:text-md">
      <span *ngIf="location.street_line1">{{ location.street_line1 }}</span>
      <span *ngIf="location.post_code">, {{ location.post_code }}</span>
      <span *ngIf="location.city">, {{ location.city }}</span>
    </div>
  </div>
</div>
