import { ParametersDistributionModeLabels } from '../labels/parameters.labels';

export const ParametersWantedAtTypeValues = {
  Asap: 'asap',
  Later: 'later',
};
export type ParametersWantedAtTypeValues =
  (typeof ParametersWantedAtTypeValues)[keyof typeof ParametersWantedAtTypeValues];

export enum ParametersDistributionModeValues {
  ClickAndCollect = 'click-and-collect',
  Delivery = 'delivery',
  Shipping = 'shipping',
  OnSite = 'on-site',
  EatIn = 'eat-in',
}

export const BASE_CUSTOM_SWITCH_VALUES = [
  {
    value: ParametersDistributionModeValues.ClickAndCollect,
    label: ParametersDistributionModeLabels.ClickAndCollect,
    icon: 'icon-shopping-bag',
  },
  {
    value: ParametersDistributionModeValues.Delivery,
    label: ParametersDistributionModeLabels.Delivery,
    icon: 'icon-bike',
  },
];

export const ALL_PARAMETERS_DISTRIBUTION_MODE_VALUES = [
  {
    value: ParametersDistributionModeValues.ClickAndCollect,
    label: ParametersDistributionModeLabels.ClickAndCollect,
  },
  {
    value: ParametersDistributionModeValues.Delivery,
    label: ParametersDistributionModeLabels.Delivery,
  },
  {
    value: ParametersDistributionModeValues.OnSite,
    label: ParametersDistributionModeLabels.OnSite,
  },
  {
    value: ParametersDistributionModeValues.EatIn,
    label: ParametersDistributionModeLabels.EatIn,
  },
  {
    value: ParametersDistributionModeValues.Shipping,
    label: ParametersDistributionModeLabels.Shipping,
  },
];
