import { DoodProductModel } from '@core/models/product.model';
import { ActiveState, createActiveAdapter } from '@store/_abstract/active';
import { DoodApiError } from '@shared/interfaces/error.interface';

export interface ProductState extends ActiveState<DoodProductModel, string> {
  isLoaded: boolean;
  isLoading: boolean;
  activeCategory: string | undefined;
  error: DoodApiError | null;
}

export const PRODUCT_STORE_KEY = 'Products';

export const PRODUCT_STORE_ACTIVE_ADAPTER = createActiveAdapter<DoodProductModel, string>({
  selectId: ({ _id, id }) => _id ?? id,
});

export const PRODUCT_STORE_INITIAL_STATE: ProductState =
  PRODUCT_STORE_ACTIVE_ADAPTER.getInitialState({
    error: null,
    activeCategory: undefined,
    isLoaded: false,
    isLoading: false,
  });
