import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-big-footer-column-item-block',
  templateUrl: './big-footer-column-item-block.component.html',
})
export class BigFooterColumnItemBlockComponent {
  @Input() name!: string;
  @Input() link!: string;
  @Input() openInNewTab?: boolean;
}
