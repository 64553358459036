import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { map, takeUntil } from 'rxjs/operators';

import { CartTipTypeValues } from '@config/values/cart.values';
import { OrderStoreSelector } from '@common/selectors/order.selector';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { ModalsService } from '@core/services/modals/modals.service';
import { CartTipPickerModalComponent } from '@shared/modals/cart-tip-picker-modal/cart-tip-picker-modal.component';

@Component({
  selector: 'app-cart-tip-atom',
  templateUrl: './cart-tip-atom.component.html',
})
export class CartTipAtomComponent extends DestroyerBase implements OnInit, OnChanges {
  @Input() choice1Amount!: number;
  @Input() choice1Unit!: string;
  @Input() choice2Amount!: number;
  @Input() choice2Unit!: string;
  @Input() choice3Amount!: number;
  @Input() choice3Unit!: string;
  @Input() totalAmount!: number;
  @Input() isOtherAmountModal = true;
  @Input() selectedTip = 0;
  @Output() tipEmitter = new EventEmitter<number>();
  @Output() otherAmountEmitter = new EventEmitter<boolean>();

  isChoice1Selected = false;
  isChoice2Selected = false;
  isChoice3Selected = false;
  isOtherAmountSelected = false;

  otherAmount = 0;

  tipTypeValues = CartTipTypeValues;

  constructor(
    private orderSelector: OrderStoreSelector,
    private readonly modalsService: ModalsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.orderSelector.selectValidation
      .pipe(
        takeUntil(this._destroyerRef),
        map(validation => validation?.tip?.amount),
      )
      .subscribe(tip => {
        if (tip) {
          this.selectedTip = tip / 100;
          this.checkOtherAmountIsSelected();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedTip) {
      this.checkOtherAmountIsSelected();
    }
  }

  onSelectTip(tip: any): void {
    this.selectedTip = tip;
    this.isOtherAmountSelected = false;
    this.otherAmount = 0;
    this.tipEmitter.emit(tip * 100);
  }

  selectOtherTipAmount(): void {
    if (!this.isOtherAmountModal) {
      this.otherAmountEmitter.emit(true);
      return;
    }
    this.modalsService.open(CartTipPickerModalComponent.handle);
  }

  calculateAmount(value: number, totalAmount: number): number {
    return Math.round(((totalAmount * value) / 100) * 1e2) / 1e2;
  }

  checkOtherAmountIsSelected(): void {
    this.isChoice1Selected =
      this.choice1Unit === CartTipTypeValues.Currency
        ? this.selectedTip === this.choice1Amount
        : this.selectedTip === this.calculateAmount(this.choice1Amount, this.totalAmount);
    this.isChoice2Selected =
      this.choice2Unit === CartTipTypeValues.Currency
        ? this.selectedTip === this.choice2Amount
        : this.selectedTip === this.calculateAmount(this.choice2Amount, this.totalAmount);
    this.isChoice3Selected =
      this.choice3Unit === CartTipTypeValues.Currency
        ? this.selectedTip === this.choice3Amount
        : this.selectedTip === this.calculateAmount(this.choice3Amount, this.totalAmount);
    this.isOtherAmountSelected =
      !!this.selectedTip &&
      !this.isChoice1Selected &&
      !this.isChoice2Selected &&
      !this.isChoice3Selected;
    this.otherAmount =
      !!this.selectedTip &&
      !this.isChoice1Selected &&
      !this.isChoice2Selected &&
      !this.isChoice3Selected
        ? this.selectedTip
        : 0;
  }
}
