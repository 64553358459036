<app-modal-filler-atom
  (click)="close()"
  [index]="(index$ | async) ?? 0"
></app-modal-filler-atom>
<div
  class="fixed inset-x-0 overflow-y-auto shadow md:left-0 md:top-0 md:flex md:h-full md:w-full md:items-center md:justify-center mdMax:bottom-0 mdMax:mx-auto mdMax:max-h-85-screen mdMax:max-w-screen-sm"
  [style.z-index]="((index$ | async) ?? 0) + 50"
>
  <app-modal-filler-atom
    *ngIf="isDesktop$ | async"
    [withBackdrop]="false"
    (click)="close()"
    [index]="(index$ | async) ?? 0"
  ></app-modal-filler-atom>
  <div
    class="flex flex-col rounded-t-3xl bg-white py-5 md:max-w-lg md:rounded-3xl md:py-7"
    [style.z-index]="((index$ | async) ?? 0) + 50"
  >
    <div class="flex items-center justify-between px-5 pb-5 md:px-7">
      <h1
        class="font-brand text-28px font-bold text-neutral-800"
        [translate]="modalHeading"
      ></h1>
    </div>

    <ng-container *ngIf="!this.isLoading && !this.accountExists">
      <div class="md:px-2">
        <app-pongo-marketplace-rewards-list-block> </app-pongo-marketplace-rewards-list-block>
      </div>

      <form [formGroup]="form">
        <div
          *ngIf="!phoneNumberDefinedByUser"
          class="px-5 pb-6 md:px-7"
        >
          <label
            for="phone"
            class="mb-1 block font-base text-sm font-normal leading-5 text-neutral-600"
            translate="signup.phone"
          ></label>
          <label
            for="phone"
            class="mb-2 block font-base text-xs font-normal text-neutral-500"
          >
            {{ 'pongo.help-phone-format' | translate }}
          </label>
          <input
            id="phone"
            type="tel"
            formControlName="phone"
            class="flex w-full border border-solid border-neutral-300 bg-neutral-50 px-5 py-8xs font-base text-base font-normal leading-5"
            [class.border-error-700]="
              phoneErrorMessage ||
              (form.controls?.phone?.invalid &&
                (form.controls?.phone?.dirty || form.controls?.phone?.touched))
            "
          />
          <ng-container
            *ngIf="
              form.controls?.phone?.invalid &&
              (form.controls?.phone?.dirty || form.controls?.phone?.touched)
            "
          >
            <span
              class="font-base text-xs font-normal text-error-700"
              *ngIf="form.controls?.phone?.errors"
              translate="signup.invalid-phone"
            ></span>
          </ng-container>
          <ng-container *ngIf="phoneErrorMessage">
            <span
              class="font-base text-xs font-normal text-error-700"
              [translate]="phoneErrorMessage"
            ></span>
          </ng-container>
        </div>
        <div class="mb-7 flex px-5 pb-2 md:px-7">
          <input
            type="checkbox"
            [id]="'pongoOptin'"
            [name]=""
            class="form-checkbox mr-3.5 h-5 w-5 border text-primary-600"
            formControlName="optin"
          />
          <label
            [for]="'pongoOptin'"
            class="mr-4 font-base text-sm font-normal"
            >{{ 'pongo.optin-checkbox-label' | translate }}</label
          >
        </div>
      </form>

      <div class="grid grid-cols-2 gap-4 px-5 md:px-7">
        <app-button-atom
          [type]="'light'"
          [label]="'pongo.continue-without-loyalty-button' | translate"
          [size]="'L'"
          (click)="close()"
          [full]="true"
        ></app-button-atom>
        <app-button-atom
          [type]="'primary'"
          [label]="'pongo.continue-with-loyalty-button' | translate"
          [size]="'L'"
          (click)="attachCustomer()"
          [full]="true"
          [iconUrl]="isValidating ? 'assets/images/spinner.svg' : ''"
          [disabled]="isValidating"
        ></app-button-atom>
      </div>
    </ng-container>

    <ng-container *ngIf="!this.isLoading && this.accountExists">
      <div class="px-5 pb-7">
        <p class="pb-3 font-base text-base text-neutral-800">
          {{ 'pongo.already-member-information' | translate }}
        </p>
      </div>
      <div class="px-5 pb-3">
        <app-button-atom
          [type]="'light'"
          [label]="'pongo.already-member-close-button' | translate"
          [size]="'L'"
          (click)="close()"
        ></app-button-atom>
      </div>
    </ng-container>

    <ng-container *ngIf="this.isLoading">
      <div class="flex h-48 w-full flex-row items-center justify-center">
        <img
          src="assets/images/spinner.svg"
          class="pr-4"
        />
      </div>
    </ng-container>
  </div>
</div>
