<div class="">
  <div class="min-h-screen pb-40 kiosk:pb-0">
    <router-outlet></router-outlet>
  </div>

  <app-block-list [blockIdList]="footer"></app-block-list>

  <app-default-dood-legal-footer *ngIf="displayDefaultDoodFooter"></app-default-dood-legal-footer>
</div>

<app-block-list [blockIdList]="globalFeatures"></app-block-list>

<app-order-update-notification-subscriber
  *ngIf="displayOrderStatusUpdateNotification"
></app-order-update-notification-subscriber>
