import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import { DoodEntityModel } from '@core/models/entity.model';
import { ENTITY_STORE_LOCAL_ACTIONS } from '@store/entity/entity.action';

@Injectable({ providedIn: 'root' })
export class EntityStoreDispatcher {
  constructor(private store: Store) {}

  // All
  setAll(entities: DoodEntityModel[]): void {
    this.store.dispatch(ENTITY_STORE_LOCAL_ACTIONS.setEntities({ entities }));
  }

  reset(): void {
    this.store.dispatch(ENTITY_STORE_LOCAL_ACTIONS.reset());
  }
}
