import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationHistoryService } from '@core/services/navigation-history/navigation-history.service';

@Component({
  selector: 'app-account-page',
  templateUrl: './account-page.component.html',
})
export class AccountPageComponent implements OnInit {
  @Input() body: any;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly navigationHistoryService: NavigationHistoryService,
  ) {}

  ngOnInit(): void {
    this.navigationHistoryService.pushStateFromActivatedRouteSnapshot(
      'user-account',
      this.activatedRoute.snapshot,
    );
  }
}
