import { Component, EventEmitter, Input, Output } from '@angular/core';

import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import {
  IContentBuilderFieldColor,
} from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-kiosk-footer-double-button-atom',
  templateUrl: './kiosk-footer-double-button-atom.component.html',
})
export class KioskFooterDoubleButtonAtomComponent extends DestroyerBase {

  @Input() backgroundColor?: IContentBuilderFieldColor;
  @Input() textColor?: IContentBuilderFieldColor;
  @Input() firstButtonBackgroundColor?: IContentBuilderFieldColor;
  @Input() firstButtonTextColor?: IContentBuilderFieldColor;
  @Input() firstButtonLabel?: string;
  @Input() firstButtonDisabled?: boolean = false;
  @Input() secondButtonBackgroundColor?: IContentBuilderFieldColor;
  @Input() secondButtonTextColor?: IContentBuilderFieldColor;
  @Input() secondButtonLabel?: string;
  @Input() secondButtonDisabled?: boolean = false;
  @Input() displaySecondButton?: boolean = true;

  @Output() onFirstButtonClick = new EventEmitter()
  @Output() onSecondButtonClick = new EventEmitter();

}
