import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ProductStoreSelector } from '@common/selectors/product.selector';

import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import {
  IContentBuilderFieldColor,
  IContentBuilderFieldImage,
} from '@core/models/content-builder-fields.model';
import { filter, map } from 'rxjs';

@Component({
  selector: 'app-kiosk-header-atom',
  templateUrl: './kiosk-header-atom.component.html',
})
export class KioskHeaderAtomComponent extends DestroyerBase {
  @Output() languageFlagClick = new EventEmitter<Event>();
  @Input() logoImageUrl?: IContentBuilderFieldImage;
  @Input() backgroundColor?: IContentBuilderFieldColor;
  @Input() backgroundImage?: IContentBuilderFieldImage;
  @Input() headerLandingText?: string;
  @Input() displayHomeButton: boolean = false;
  @Input() buttonHomeText?: string;
  @Input() buttonBackgroundColor?: IContentBuilderFieldColor;
  @Input() buttonTextColor?: IContentBuilderFieldColor;
  @Input() displayLogo = false;
  @Input() languageFlagUrl?: string;
  @Input() size: 'L' | 'M' | 'S' = 'S';

  hideHomeBtn: boolean = true;

  private readonly activeCategory$ = this.productSelector.selectActiveCategory;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly productSelector: ProductStoreSelector,
    private readonly router: Router,
  ) {
    super();
  }

  ngOnInit() {
    this.checkRoute(this.router.url);

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.checkRoute(this.router.url);
    });
  }

  private checkRoute(currentRoute: string): void {
    this.hideHomeBtn = !currentRoute.includes('product-add');
  }

  onLanguageFlagClick(): void {
    this.languageFlagClick.emit();
  }

  getBackgroundStyles() {
    return {
      'background-color': this.backgroundColor ? this.backgroundColor : null,
      'background-image': this.backgroundImage ? `url(${this.backgroundImage.url})` : null,
      'background-size': this.backgroundImage ? 'cover' : null,
    };
  }

  redirectoToHome(): void {
    const shopCity = this.activatedRoute.snapshot.paramMap.get('shopCity');
    const shopType = this.activatedRoute.snapshot.paramMap.get('shopType');
    const shopSlug = this.activatedRoute.snapshot.paramMap.get('shopSlug');

    this.activeCategory$
      .pipe(
        map(id => {
          this.router.navigate([
            `/fr/${shopCity}/${shopType}/${shopSlug}${id ? `/categories/${id}` : ''}`,
          ]);
        }),
      )
      .subscribe();
  }
}
