<div class="mb-3">
  <a [link]="url">
    <app-button-atom
      class="mx-auto"
      [label]="label"
      [color]="color"
      [type]="type"
      [full]="true"
      [thickSize]="thickSize"
    ></app-button-atom>
  </a>
</div>
