import {
  ICartDeclinableAdditionsItem,
  ICartItem,
  ICartSimpleProductOfCategoryItem,
} from '@core/models/cart.model';
import { DoodProductModel, IDeclinableCategory } from '@core/models/product.model';
import { AutoFillCartItemRulesCategory } from '../auto-fill-cart-item-rules/auto-fill-cart-item-rules-category';
import { ProductsByCategoryId } from './cart-util.service';

export class CartCategoryUtil {
  static checkCartValidity({
    cart,
    compiledValidity,
    category,
    totalSelectedQuantity,
  }: {
    cart: ICartDeclinableAdditionsItem[];
    compiledValidity: (
      | {
          productId: string;
          isValid: boolean;
          additionsByGroupsValidity: Record<
            string,
            {
              isValid: boolean;
              isInvalid: boolean;
            }
          >;
        }
      | undefined
    )[];
    category: IDeclinableCategory; // todo refacto by productByCategory
    totalSelectedQuantity: number;
  }) {
    // check if every selected cart item is valid
    const areSelectedCartItemValid = cart
      .filter(cartItem => cartItem.quantity > 0)
      .every(
        cartItem =>
          compiledValidity.find(validityItem => cartItem.id === validityItem?.productId)?.isValid,
      );

    const maxCategoryQuantity = category.count || Infinity;
    const isQuantityValid =
      category.min_count <= totalSelectedQuantity && totalSelectedQuantity <= maxCategoryQuantity;
    const isValid = areSelectedCartItemValid && isQuantityValid;
    return isValid;
  }

  static calculateTotalQuantity({ cart }: { cart: ICartDeclinableAdditionsItem[] }): number {
    return cart.reduce((acc, item) => acc + item.quantity, 0);
  }

  static removeExcessQuantity({
    cartProductItems,
    categoryMaxQuantity,
    editHistory,
  }: {
    cartProductItems: ICartDeclinableAdditionsItem[];
    categoryMaxQuantity: number;
    editHistory: string[];
  }) {
    const totalQuantity = cartProductItems.reduce(
      (acc, cartProductItem) => acc + (cartProductItem?.quantity ?? 0),
      0,
    );
    let remainingQuantityToRemove = totalQuantity - categoryMaxQuantity;
    if (remainingQuantityToRemove <= 0) {
      return cartProductItems;
    }

    for (const productId of editHistory) {
      const cartProductItem = cartProductItems.find(
        cartProductItem => cartProductItem.id === productId,
      );
      if (!cartProductItem) {
        continue;
      }
      if (remainingQuantityToRemove <= 0) {
        return cartProductItems;
      }
      const productQuantity = cartProductItem?.quantity ?? 0;
      if (productQuantity <= 0) {
        continue;
      }

      const quantityToRemove = Math.min(productQuantity, remainingQuantityToRemove);
      remainingQuantityToRemove -= quantityToRemove;
      cartProductItems = cartProductItems.map(cartProductItem => {
        if (cartProductItem.id === productId) {
          const isProductWith0Quantity = productQuantity - quantityToRemove === 0;
          return {
            ...cartProductItem,
            quantity: productQuantity - quantityToRemove,
            ...(isProductWith0Quantity && {
              // reset selected additions for removed product
              additions: [],
              separate_additions_by_group: [],
            }),
          };
        }
        return cartProductItem;
      });
    }
    return cartProductItems;
  }

  static ensureQuantityValidity({
    quantity,
    categoryProducts,
    category,
  }: {
    quantity: number | undefined;
    categoryProducts: DoodProductModel[];
    category: IDeclinableCategory;
  }) {
    if (typeof quantity === undefined) {
      return { quantity: 0 };
    }
    if (
      AutoFillCartItemRulesCategory.isExclusiveProductWithAdditions({
        categoryProducts,
        category,
      })
    ) {
      return this.calculateExclusiveProductWithAdditionsQuantity({
        quantity,
      });
    }
    if (
      AutoFillCartItemRulesCategory.isExclusiveCategoryProduct({
        category,
      })
    ) {
      return { quantity: category.count, previousQuantity: category.count };
    }
    return { quantity: quantity, previousQuantity: quantity };
  }

  static calculateExclusiveProductWithAdditionsQuantity({
    quantity,
  }: {
    quantity: number | undefined;
  }) {
    if (typeof quantity === 'undefined') {
      return { quantity: 1 };
    }
    if (quantity === 0) {
      // avoid to unselect the exclusive product
      return { quantity };
    }
    return { quantity: quantity };
  }

  static createCartDraftCategory({
    productsByCategory,
    shopId,
    existingCart,
    categories,
  }: {
    productsByCategory: ProductsByCategoryId;
    shopId: string;
    existingCart: ICartItem | undefined;
    categories: IDeclinableCategory[];
  }): ICartSimpleProductOfCategoryItem[] {
    return Object.entries(productsByCategory)
      .map(([categoryId, products]) => {
        return products.map(product => {
          const existingProductCartItem = existingCart?.products?.find(
            existingProduct => existingProduct.id === product.id,
          );
          const { quantity } = this.ensureQuantityValidity({
            quantity: existingProductCartItem?.quantity,
            categoryProducts: productsByCategory[categoryId],
            category:
              categories.find(category => category.store_id === categoryId) ??
              ({} as IDeclinableCategory),
          });
          return {
            id: product.id,
            shop_id: shopId,
            store_id: categoryId,
            additions: existingProductCartItem?.additions ?? [],
            quantity: quantity ?? 0,
            separate_additions_by_group: existingProductCartItem?.separate_additions_by_group ?? [],
          } satisfies ICartSimpleProductOfCategoryItem;
        });
      })
      .flat();
  }
}
