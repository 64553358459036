// TODO: Check if changes produced bugs
import { every } from 'lodash';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, of, Subject, throwError, timer } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import {
  PaymentRequestItem,
  PaymentRequestOptions,
  PaymentRequestPaymentMethodEvent,
  StripeCardCvcElement,
  StripeCardElementOptions,
  StripeCardExpiryElement,
  StripeCardNumberElement,
  StripeElementChangeEvent,
  StripeElementLocale,
  StripeElements,
  StripeElementsOptions,
  StripeError,
} from '@stripe/stripe-js';
import { StripeService } from 'ngx-stripe';
import { UserKeys } from '@config/keys/user.keys';
import { CartKeys } from '@config/keys/cart.keys';
import { TransactionKeys } from '@config/keys/transaction.keys';
import { DoodOrderModel, IOrderItem } from '@store/order/order.model';
import { DoodApiError } from '@shared/interfaces/error.interface';
import { PAYMENT_STORE_BANCONTACT_INITIAL_STATE } from '@store/payment';
import { DoodUserModel } from '@store/authentication/authentication.model';
import { OrderStoreDispatcher } from '@common/dispatchers/order.dispatcher';
import { PaymentStoreDispatcher } from '@common/dispatchers/payment.dispatcher';
import { OrderStoreSelector } from '@common/selectors/order.selector';
import { PaymentStoreRefiner } from '@common/refiners/payment.refiner';
import { BasketStoreSelector } from '@common/selectors/basket.selector';
import { PaymentStoreSelector } from '@common/selectors/payment.selector';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';
import { TransactionStoreSelector } from '@common/selectors/transaction.selector';
import { CartService } from '@core/services/cart/cart.service';
import { UserService } from '@core/services/user/user.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { BasketService } from '@core/services/basket/basket.service';
import { PaymentService } from '@core/services/payment/payment.service';
import { TransactionsService } from '@core/services/transactions/transactions.service';
import { StripeCustomService } from '@core/services/stripe-custom/stripe-custom.service';
import { RouterHelperService } from 'src/app/core/services/router-helper/router-helper.service';

import { IStripePaymentIntent } from '@core/models/payment.model';
import { IOrder } from '@core/models/order.model';
import { DoodTransactionModel, DoodTransactionStatus } from '@core/models/transaction.model';

import { PaymentMethodHandles } from 'src/app/config/payment-methods.config';
import { ORDER_INVALID_STATUS, TRANSACTION_EXCEEDS_TOTAL } from 'src/app/config/errors.config';

import { BancontactPaymentFormModalComponent } from '@shared/modals/bancontact-payment-form-modal/bancontact-payment-form-modal.component';

interface CardElementItem {
  cardNumber: boolean;
  cardExpiry: boolean;
  cardCvc: boolean;
}

@Component({
  selector: 'app-payment-panel-stripe-generic',
  templateUrl: './payment-panel-stripe-generic.component.html',
  styleUrls: ['./payment-panel-stripe-generic.component.scss'],
})
export class PaymentPanelStripeGenericComponent implements OnInit, OnDestroy {
  @Input() type!: PaymentMethodHandles;
  @Input() payButtonText!: string;
  @Input() isPayButtonVisible = true;

  isLoading = true;
  elements?: StripeElements;
  cardNumber?: StripeCardNumberElement;
  cardExpiry?: StripeCardExpiryElement;
  cardCvc?: StripeCardCvcElement;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        fontSize: '18px',
        color: '#31325F',
        backgroundColor: '#F9FAFB',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  };

  elementsOptions: StripeElementsOptions = {
    locale: this.settingsSelector.app.locale as StripeElementLocale,
  };

  user: DoodUserModel | null = null;
  order: DoodOrderModel | null = null;
  paymentRequestOptions!: PaymentRequestOptions;
  bancontact = PAYMENT_STORE_BANCONTACT_INITIAL_STATE;

  bancontactAdditionalData$ = this.paymentSelector.selectBancontact;

  userKeys = UserKeys;

  private readonly unsubscribe$ = new Subject<boolean>();
  private paymentIntent?: IStripePaymentIntent;
  private cardElementsValid: CardElementItem = {
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  };

  constructor(
    private readonly router: Router,
    private authSelector: AuthStoreSelector,
    private orderSelector: OrderStoreSelector,
    private readonly cartService: CartService,
    private readonly userService: UserService,
    private basketSelector: BasketStoreSelector,
    private paymentRefiner: PaymentStoreRefiner,
    @Inject(DOCUMENT) private document: Document,
    private orderDispatcher: OrderStoreDispatcher,
    private readonly stripeService: StripeService,
    private readonly ordersService: OrdersService,
    private readonly basketService: BasketService,
    private readonly modalsService: ModalsService,
    private paymentSelector: PaymentStoreSelector,
    private settingsSelector: SettingsStoreSelector,
    private readonly paymentService: PaymentService,
    private paymentDispatcher: PaymentStoreDispatcher,
    private readonly routerHelper: RouterHelperService,
    private readonly translateService: TranslateService,
    private marketplaceSelector: MarketplaceStoreSelector,
    private transactionSelector: TransactionStoreSelector,
    private readonly transactionsService: TransactionsService,
    private readonly stripeCustomService: StripeCustomService,
  ) {
    this.paymentSelector.selectBancontact
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(_bancontact => {
        this.bancontact = _bancontact;
      });
    this.authSelector.selectUser.pipe(takeUntil(this.unsubscribe$)).subscribe(_user => {
      this.user = _user;
    });
  }

  ngOnInit(): void {
    timer(1).subscribe(() => {
      this.paymentDispatcher.updateUI({
        error: null,
        button: {
          isEnabled: false,
          text: this.payButtonText,
          isVisible: Boolean(this.isPayButtonVisible),
        },
        status: {
          isLoading: true,
        },
      });
    });

    const basket = this.basketSelector.basket;

    let order$: Observable<DoodOrderModel | undefined | null>;

    if (basket && basket?.[CartKeys.Order]) {
      order$ = this.orderSelector.selectOrder(basket?.[CartKeys.Order] as string);
    } else {
      order$ = this.orderSelector.selectActive;
    }

    order$
      .pipe(
        take(1),
        takeUntil(this.unsubscribe$),
        switchMap(order =>
          order?.payment_service === PaymentMethodHandles.Transactions
            ? this.createPaymentIntentFromGroupTransaction(order)
            : this.createPaymentIntentFromNewOrder(),
        ),
      )
      .subscribe();

    window.onbeforeunload = (): void => this.synchronizeOrderStatusOnPaymentFlowExit();
  }

  ngOnDestroy(): void {
    this.cancelAbortedTransaction$(this.transactionSelector.active?.id).subscribe();
    this.synchronizeOrderStatusOnPaymentFlowExit();

    this.paymentDispatcher.resetUI();

    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();

    if (this.cardNumber) {
      this.cardNumber.unmount();
      this.cardNumber.destroy();
    }
  }

  private createPaymentIntentFromNewOrder(): Observable<
    [DoodOrderModel, IStripePaymentIntent] | null
  > {
    return this.ordersService.createOrder$(this.mapOrderTypeToDoodApiValue()).pipe(
      tap(order => (order ? this.mapOrderToPaymentRequestOptions(order) : of(null))),
      tap(order => {
        this.order = order;
        if (order) {
          this.ordersService.setActiveOrderToStore(order);
        }
      }),
      switchMap(order => (order ? this.createPaymentIntent(order) : of(null))),
    );
  }

  private createPaymentIntentFromGroupTransaction(
    order: DoodOrderModel,
  ): Observable<[DoodTransactionModel, IStripePaymentIntent] | undefined> {
    this.order = order;
    return this.cancelAbortedTransaction$(this.transactionSelector.active?.id).pipe(
      switchMap(() => this.transactionSelector.selectAmount),
      switchMap(amount =>
        amount && amount >= 1
          ? this.transactionsService.createTransaction$({
              amount,
              [TransactionKeys.PaymentMethod]: this.mapOrderTypeToDoodApiValue(),
            })
          : throwError(() => ({
              error: {
                detail: 'group-payments.errors.amount-is-undefined-or-0',
              },
            })),
      ),
      tap(transaction => this.transactionsService.setActiveTransaction(transaction)),
      catchError(error => {
        this.handleTransactionError(error.error);
        return throwError(() => error);
      }),
      tap(transaction => this.mapTransactionToPaymentRequestOptions(transaction)),
      switchMap(transaction => this.createTransactionPaymentIntent(transaction)),
      take(1),
      takeUntil(this.unsubscribe$),
    );
  }

  private mapOrderToPaymentRequestOptions(order: DoodOrderModel): void {
    if (
      this.type === PaymentMethodHandles.StripeApplePay ||
      this.type === PaymentMethodHandles.StripeGooglePay
      // TODOr check if twint needs to be added here
    ) {
      this.paymentRequestOptions = {
        country: this.marketplaceSelector.marketplace.country?.toUpperCase() || 'FR',
        currency: order.currency?.toLocaleLowerCase() as string,
        total: {
          label: 'TOTAL',
          amount: order.final_price,
        },
        displayItems: order.products.map((product: IOrderItem) => {
          return {
            amount: product.final_price,
            label: product.name,
          } as PaymentRequestItem;
        }),
        requestPayerName: false,
        requestPayerEmail: false,
        disableWallets: ['browserCard'],
      };
    }
  }

  private mapTransactionToPaymentRequestOptions(
    transaction: DoodTransactionModel | undefined,
  ): void {
    if (transaction && (this.type === 'STRIPE_APPLE_PAY' || this.type === 'STRIPE_GOOGLE_PAY')) {
      this.paymentRequestOptions = {
        country: this.marketplaceSelector.marketplace.country?.toUpperCase() || 'FR',
        currency: transaction[TransactionKeys.Currency]?.toLocaleLowerCase()!,
        total: {
          label: 'TOTAL',
          amount: transaction[TransactionKeys.Amount],
        },
        requestPayerName: false,
        requestPayerEmail: false,
        disableWallets: ['browserCard'],
      };
    }
  }

  private createPaymentIntent(
    order: DoodOrderModel,
  ): Observable<[DoodOrderModel, IStripePaymentIntent]> {
    return combineLatest([
      of(order),
      this.paymentService.createOrderPaymentIntent$<IStripePaymentIntent>(order.id),
    ]).pipe(
      take(1),
      tap(([, paymentIntent]) => this.initializeStripeElement(paymentIntent)),
      catchError(error => {
        // TODO: Remove line bellow
        // this.ordersStore.update({ errors: err, validate: undefined });
        // TODO: Replace this with an action aggregator
        this.orderDispatcher.updateErrors({ error });
        this.orderDispatcher.resetValidation();
        return throwError(() => error);
      }),
    );
  }

  private createTransactionPaymentIntent(
    transaction: DoodTransactionModel | undefined,
  ): Observable<[DoodTransactionModel, IStripePaymentIntent] | undefined> {
    if (!transaction?.[TransactionKeys.Id]) {
      this.orderDispatcher.resetValidation();
      this.orderDispatcher.updateErrors({
        'payment.unknown-error': {
          details: 'payment.unknown-error',
        },
      });
      return of(undefined);
    }

    return combineLatest([
      of(transaction),
      this.paymentService.createTransactionPaymentIntent$<IStripePaymentIntent>(
        transaction[TransactionKeys.Id],
      ),
    ]).pipe(
      take(1),
      tap(([_, paymentIntent]) => this.initializeStripeElement(paymentIntent)),
      catchError(error => {
        this.orderDispatcher.updateErrors({ error });
        this.orderDispatcher.resetValidation();
        return throwError(() => error);
      }),
    );
  }

  private initializeStripeElement(paymentIntent: IStripePaymentIntent): void {
    this.paymentIntent = paymentIntent;

    /* TYPE IS STRIPE BANCONTACT */
    if (this.type === PaymentMethodHandles.StripeBancontact) {
      const user = this.userService.user;
      if (user) {
        this.paymentService.setAdditionalData({
          ...(user?.firstname &&
            user?.lastname && {
              complete_name: `${user.lastname} ${user.firstname}`,
            }),
          ...(user?.email && {
            email: user?.email,
          }),
        });
      }

      this.paymentRefiner.selectDoesHaveBancontactInformation
        .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
        .subscribe(hasInformation => {
          if (hasInformation) {
            this.paymentDispatcher.updateUI({
              error: null,
              button: {
                isEnabled: true,
                text: 'payment.button',
                isVisible: Boolean(this.isPayButtonVisible),
              },
              status: {
                isLoading: false,
              },
            });
          }
        });

      this.listenPayButton();

      return;
    }

    if (this.type === PaymentMethodHandles.StripeTwint) {
      this.paymentDispatcher.updateUI({
        error: null,
        button: {
          isEnabled: true,
        },
        status: {
          isLoading: false,
          isInProgress: false,
        },
      });
      this.listenPayButton();
    }

    // TODO: takeUntil + take ???
    if (
      this.type !== 'STRIPE_APPLE_PAY' &&
      this.type !== 'STRIPE_GOOGLE_PAY' &&
      this.type !== 'STRIPE_TWINT'
    ) {
      this.stripeService
        .elements(this.elementsOptions)
        .pipe(
          takeUntil(this.unsubscribe$),
          take(1),
          tap(elements => this.mountStripeElement(elements)),
          tap(() => this.onPayFormLoaded()),
        )
        .subscribe();
    }
  }

  mountStripeElement(elements: StripeElements): void {
    this.elements = elements;
    if (!this.cardNumber || !this.cardExpiry || !this.cardCvc) {
      this.cardNumber = this.elements.create('cardNumber', this.cardOptions);
      this.cardNumber.mount('#card-number-element');
      this.cardExpiry = this.elements.create('cardExpiry', this.cardOptions);
      this.cardExpiry.mount('#card-expiry-element');
      this.cardCvc = this.elements.create('cardCvc', this.cardOptions);
      this.cardCvc.mount('#card-cvc-element');
    }

    this.cardNumber.on('change', event => this.onPayFormChanged('cardNumber', event));
    this.cardExpiry.on('change', event => this.onPayFormChanged('cardExpiry', event));
    this.cardCvc.on('change', event => this.onPayFormChanged('cardCvc', event));
  }

  onPayFormLoaded(): void {
    this.listenPayButton();

    this.paymentDispatcher.updateUI({
      error: null,
      button: {
        isEnabled: false,
      },
      status: {
        isLoading: false,
        isInProgress: false,
      },
    });

    this.isLoading = false;
  }

  private listenPayButton(): void {
    this.paymentService.pay$
      .pipe(
        takeUntil(this.unsubscribe$),
        take(1),
        tap(() => {
          if (this.type === PaymentMethodHandles.StripeBancontact) {
            this.handleBanContactPayment();
            return;
          }
          if (this.type === PaymentMethodHandles.StripeTwint) {
            this.handleTwintPayment();
            return;
          }
          this.handlePayment();
        }),
      )
      .subscribe();
  }

  onPaymentMethod(_event: PaymentRequestPaymentMethodEvent): void {
    if (this.paymentIntent && this.paymentIntent.payment_intent_client_secret) {
      this.stripeService
        .confirmCardPayment(
          this.paymentIntent.payment_intent_client_secret,
          { payment_method: _event.paymentMethod.id },
          { handleActions: false },
        )
        .pipe(
          switchMap(confirmResult => {
            if (confirmResult.error) {
              _event.complete('fail');
              return throwError(() => confirmResult.error);
            }

            this.paymentDispatcher.updateUI({
              error: null,
              button: {
                isEnabled: false,
              },
              status: {
                isInProgress: true,
              },
            });

            _event.complete('success');

            // TODO: 'requires_source_action' seems not being a correct value
            if (
              ['requires_action', 'requires_source_action'].includes(
                confirmResult.paymentIntent?.status ?? '',
              )
            ) {
              if (this.paymentIntent && this.paymentIntent.payment_intent_client_secret) {
                return this.stripeService.confirmCardPayment(
                  this.paymentIntent.payment_intent_client_secret,
                );
              }
            }

            return of(confirmResult);
          }),
          takeUntil(this.unsubscribe$),
          take(1),
        )
        .subscribe(
          () => (this.order ? this.stripeCustomService.handlePaymentSuccess(this.order) : of(null)),
          error => this.handlePaymentError(this.order, error),
          () => {
            this.paymentDispatcher.updateUI({
              error: null,
              button: {
                isEnabled: false,
              }
            });
          },
        );
    }
  }

  onNotAvailable(): void {
    this.paymentDispatcher.updateError('payment.payment-mode-unavailable');
  }

  handlePayment(): void {
    if (!this.paymentIntent || !this.cardNumber) {
      return;
    }

    this.paymentDispatcher.updateUI({
      error: null,
      button: {
        isEnabled: false,
      },
      status: {
        isInProgress: true,
      },
    });
    if (this.paymentIntent.payment_intent_client_secret) {
      this.stripeService
        .confirmCardPayment(this.paymentIntent.payment_intent_client_secret, {
          payment_method: {
            card: this.cardNumber,
          },
        })
        .pipe(
          tap(result =>
            this.order
              ? this.stripeCustomService.handlePaymentResult(result, this.order)
              : of(null),
          ),
          tap(result => {
            if (result.error || !result.paymentIntent) {
              this.listenPayButton();
            }
          }),
        )
        .subscribe();
    }
  }

  handleBanContactPayment(): void {
    this.paymentDispatcher.updateUI({
      error: null,
      button: {
        isEnabled: false,
      },
      status: {
        isInProgress: true,
      },
    });

    this.paymentSelector.selectBancontact
      .pipe(
        switchMap(({ email, complete_name }) => {
          if (email && complete_name) {
            if (this.paymentIntent?.payment_intent_client_secret) {
              const return_url = `${this.document.baseURI}payment-confirm?order_id=${this.order?.id}`;
              return this.stripeService.confirmBancontactPayment(
                this.paymentIntent.payment_intent_client_secret,
                {
                  return_url,
                  payment_method: {
                    billing_details: {
                      email,
                      name: complete_name,
                    },
                  },
                },
              );
            }
          }
          return of(null);
        }),
      )
      .subscribe();
  }

  handleTwintPayment(): void {
    this.paymentDispatcher.updateUI({
      error: null,
      button: {
        isEnabled: false,
      },
      status: {
        isInProgress: false,
      },
    });

    const payment_intent_client_secret = this.paymentIntent?.payment_intent_client_secret;

    if (payment_intent_client_secret) {
      const url = `${this.document.baseURI}payment-confirm?order_id=${this.order?.id}`;
      this.stripeService.stripe.stripe // ngx-stripe does not expose confirmTwintPayment method for now
        .pipe(
          switchMap(stripe => {
            return of(
              stripe.confirmTwintPayment(payment_intent_client_secret, {
                return_url: url,
                payment_method: {}, // ! this field is mandatory, even if it is empty
              }),
            );
          }),
        )
        .subscribe();
    }
  }

  openBancontactForm(): void {
    this.modalsService.open(BancontactPaymentFormModalComponent.handle);
  }

  private onPayFormChanged(
    inputName: keyof CardElementItem,
    event: StripeElementChangeEvent,
  ): void {
    this.cardElementsValid[inputName] = event.complete;

    this.paymentDispatcher.updateButton({
      isEnabled: every(this.cardElementsValid, item => item),
    });
  }

  private handlePaymentError(order: IOrder | null, error?: StripeError): void {
    if (order) {
      this.stripeCustomService.handlePaymentError(order, error);
    }
    this.listenPayButton();
  }

  private handleTransactionError(error: DoodApiError): void {
    let errorMessage;
    switch (error.detail) {
      case ORDER_INVALID_STATUS:
        this.translateService
          .get('group-payments.errors.order-invalid-status')
          .pipe(
            take(1),
            tap(translation => alert(translation)),
            tap(() => {
              this.ordersService.clearOrders();
              this.cartService.clearCart();
              this.transactionsService.clearTransactions();
              this.basketService.clearBasket();
              this.router.navigate(['/']);
              this.router.navigate([this.routerHelper.translateRoute('/')]);
            }),
          )
          .subscribe();
        return;
      case TRANSACTION_EXCEEDS_TOTAL:
        errorMessage = 'group-payments.errors.amount-exceeds-total';
        break;
      default:
        errorMessage = error.detail || 'payment.unknown-error';
    }
    this.paymentDispatcher.updateUI({
      error: errorMessage,
      button: {
        isEnabled: false,
      },
      status: {
        isLoading: false,
        isInProgress: false,
      },
    });

    this.listenPayButton();
  }

  private cancelAbortedTransaction$(id?: string): Observable<unknown> {
    if (!id) {
      return of(null);
    }

    const active = this.transactionSelector.active;

    if (
      active &&
      active.order === this.order?.id &&
      active.status === DoodTransactionStatus.payment
    ) {
      return this.transactionsService.cancelTransaction$(id).pipe(
        tap(() => this.transactionsService.clearActiveTransaction()),
        take(1),
      );
    }

    return of(null);
  }

  private synchronizeOrderStatusOnPaymentFlowExit(): void {
    this.paymentSelector.selectStatus
      .pipe(
        take(1),
        takeUntil(this.unsubscribe$),
        tap(({ isRetryInProgress }) => {
          if (this.order && isRetryInProgress) {
            this.ordersService.setActiveOrderToStore(this.order);
            this.stripeCustomService.synchronizeOrderStatus(this.order.id);
          }
        }),
      )
      .subscribe();
    window.onbeforeunload = null;
  }

  private mapOrderTypeToDoodApiValue(): string {
    if (this.type === PaymentMethodHandles.Onsite) {
      return this.type;
    }
    if (this.type === PaymentMethodHandles.StripeBancontact) {
      return PaymentMethodHandles.StripeBancontact;
    }
    if (this.type === PaymentMethodHandles.StripeTwint) {
      return PaymentMethodHandles.StripeTwint;
    }
    return PaymentMethodHandles.Stripe;
  }
}
