import { Subject } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { map, skipWhile, takeUntil, tap } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { UserService } from '@core/services/user/user.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { ZerosixService } from '@core/services/zerosix/zerosix.service';

import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';

import { UserKeys } from '@config/keys/user.keys';
import { PatternUtil } from '@shared/utils/pattern/pattern.utils';
import { ModalScrollBlockBase } from '@core/base/modalScrollBlock/modal-scroll-block.base';
import { ZerosixCreateAccountModalComponent } from '@shared/modals/zerosix/zerosix-create-account-modal/zerosix-create-account-modal.component';

@Component({
  selector: 'app-zerosix-link-account-modal',
  templateUrl: './zerosix-link-account-modal.component.html',
})
export class ZerosixLinkAccountModalComponent
  extends ModalScrollBlockBase
  implements OnInit, OnDestroy
{
  private _destroyerRef = new Subject<boolean>();

  static handle = 'zerosix-link-account-modal';

  isDesktop$ = this.settingsSelector.selectDeviceIsDesktopScreen;

  index$ = this.modalSelector
    .selectModal(ZerosixLinkAccountModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  form: UntypedFormGroup;

  errorMessage?: string;
  phoneErrorMessage?: string;
  authCodeErrorMessage?: string;

  isValidating = false;

  step = 'link-form';

  // private phoneNumber!: string;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private authSelector: AuthStoreSelector,
    private modalSelector: ModalStoreSelector,
    private readonly userService: UserService,
    private readonly modalsService: ModalsService,
    private settingsSelector: SettingsStoreSelector,
    private readonly zerosixService: ZerosixService,
  ) {
    super();
    this.form = this.fb.group({
      [UserKeys.Phone]: ['', [Validators.pattern(PatternUtil.phone), Validators.required]],
    });
  }

  ngOnInit(): void {
    this.authSelector.selectUser
      .pipe(
        takeUntil(this._destroyerRef),
        skipWhile(user => !user),
        tap(user => this.setPhoneNumber(user?.phone)),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
    super.ngOnDestroy();
  }

  close(): void {
    this.modalsService.close(ZerosixLinkAccountModalComponent.handle);
  }

  onSubmit(): void {
    if (this.form.invalid || this.isValidating) {
      return;
    }
    this.isValidating = true;
    this.phoneErrorMessage = undefined;
    this.authCodeErrorMessage = undefined;
    this.errorMessage = undefined;

    if (this.form.controls[UserKeys.Phone]) {
      this.zerosixService
        .sendOtp$(this.form.value[UserKeys.Phone])
        .pipe(tap(() => this.goToVerificationCodeStep()))
        .subscribe({
          error: error => this.handleErrorResponse(error.error),
          complete: () => (this.isValidating = false),
        });
      return;
    }

    this.zerosixService
      .attachUserWithOtp$(this.form.value.authCode)
      .pipe(
        tap(user => this.userService.setUser(user)),
        tap(() => this.goToConfirmationStep()),
      )
      .subscribe({
        error: error => this.handleErrorResponse(error.error),
        complete: () => (this.isValidating = false),
      });
  }

  openCreateLoyaltyAccountModal(): void {
    this.close();
    this.modalsService.open(ZerosixCreateAccountModalComponent.handle);
  }

  private handleErrorResponse(error: any): void {
    this.isValidating = false;
    switch (error.code) {
      case 202:
        this.phoneErrorMessage = 'zerosix.link-account.errors.account-not-found';
        break;
      case 10:
        this.authCodeErrorMessage = 'zerosix.link-account.errors.invalid-auth-code';
        break;
      default:
        this.errorMessage = 'zerosix.errors.generic-error';
    }
  }

  private goToVerificationCodeStep(): void {
    this.step = 'verification-code';
    // this.phoneNumber = this.form.value[UserKeys.Phone];
    this.form = this.fb.group({
      authCode: ['', [Validators.required]],
    });
  }

  private goToConfirmationStep(): void {
    this.step = 'confirmation';
  }

  private setPhoneNumber(phoneNumber?: string): void {
    this.form.setValue({
      [UserKeys.Phone]: phoneNumber,
    });
  }
}
