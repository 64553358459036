import {Subject} from 'rxjs';
import {Router} from '@angular/router';
import {switchMap, takeUntil, tap} from 'rxjs/operators';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';

import {ModalsService} from '@core/services/modals/modals.service';
import {NativeService} from '@core/services/native/native.service';
import {EntitiesService} from '@core/services/entities/entities.service';
import {OrderTypeService} from '@core/services/order-type/order-type.service';
import {RouterHelperService} from 'src/app/core/services/router-helper/router-helper.service';

import {Paths} from '@config/paths.config';
import {EntityKeys} from '@config/keys/entity.keys';

import {DoodEntityModel} from '@core/models/entity.model';
import {DoodWantedAtType} from '@store/settings/settings.model';
import {IContentBuilderFieldColor} from '@core/models/content-builder-fields.model';

import {SettingsStoreSelector} from '@common/selectors/settings.selector';
import {ParametersDistributionModeValues} from '@config/values/parameters.values';
import { ShopSearchParametersModalComponent } from '@shared/modals/shop-search-parameters-modal/shop-search-parameters-modal.component';
import { TemplateLayoutKeys } from '@config/keys/template-layout.keys';
import {AnalyticsService} from "@core/services/app/analytics.service";

@Component({
  selector: 'app-shop-around-list-body-block',
  templateUrl: './shop-around-list-body-block.component.html',
})
export class ShopAroundListBodyBlockComponent implements OnInit, OnDestroy {
  @Input() heading?: string;
  @Input() headingColor?: IContentBuilderFieldColor;
  @Input() displayEntityImages = true;
  @Input() displayEntityDistances = true;
  @Input() displayEntityPricings = true;
  @Input() displayEntityAddresses = false;
  @Input() displayEntityCategories = true;
  @Input() entityNamesSize = 'lg';
  @Input() displaySeeShopMapButton = true;
  @Input() shopsMapButtonColor?: IContentBuilderFieldColor;
  @Input() shopsMapButtonTextColor?: IContentBuilderFieldColor;
  @Input() templateLayout = TemplateLayoutKeys.DefaultLayout;

  shops!: DoodEntityModel[];
  entityKeys = EntityKeys;
  templateLayoutKeys = TemplateLayoutKeys;
  isIos = false;

  private destroyed$ = new Subject<boolean>();
  wantedAt: Date | null = null;

  constructor(
    private readonly router: Router,
    private readonly modalsService: ModalsService,
    private settingsSelector: SettingsStoreSelector,
    private readonly entitiesService: EntitiesService,
    private readonly routerHelper: RouterHelperService,
    private readonly orderTypeService: OrderTypeService,
    private readonly analyticsService: AnalyticsService,
  ) {
  }

  ngOnInit(): void {
    this.settingsSelector.selectParameters
      .pipe(
        tap(parameters => {
          const {location, distribution_mode, wanted_at, wanted_at_type} = parameters;
          const orderTypeCapabilities = this.orderTypeService.getCapabilities(distribution_mode);
          const isLocationMandatory =
            !location && distribution_mode === ParametersDistributionModeValues.Delivery;

          if (
            isLocationMandatory ||
            (isLocationMandatory && !wanted_at_type) ||
            (orderTypeCapabilities.preorderingAllowed &&
              wanted_at_type === DoodWantedAtType.later &&
              !wanted_at)
          ) {
            this.openParameters();
          }

          this.wantedAt = wanted_at;
        }),
        switchMap(() => {
          const parameters = this.settingsSelector.parameters;
          this.analyticsService.trackEvent('search_shops', {
            search_shops_location: parameters.location,
            search_shops_wanted_at: parameters.wanted_at,
            search_shops_distribution_mode: parameters.distribution_mode,
          });
          return this.entitiesService.loadProximityEntities$();
        }),
        takeUntil(this.destroyed$),
        tap(entities => {
          this.shops = entities;
          const parameters = this.settingsSelector.parameters;

          if (entities.length === 0) {
            this.analyticsService.trackEvent('search_shops_not_found', {
              search_shops_location: parameters.location,
              search_shops_wanted_at: parameters.wanted_at,
              search_shops_distribution_mode: parameters.distribution_mode,
            });
          } else {
            this.analyticsService.trackEvent('search_shops_success', {
              search_shops_result_count: entities.length,
              search_shops_location: parameters.location,
              search_shops_wanted_at: parameters.wanted_at,
              search_shops_distribution_mode: parameters.distribution_mode,
            });
          }
        }),
      )
      .subscribe();

    this.isIos = NativeService.platform === 'ios';
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  entityUrl(entity: DoodEntityModel): string {
    return this.entitiesService.getUrl(entity);
  }

  entityAddress(entity: DoodEntityModel): string {
    return this.entitiesService.getFullAddress(entity);
  }

  openParameters(): void {
    this.modalsService.open(ShopSearchParametersModalComponent.handle);
  }

  navigateToMapPage(): void {
    this.router.navigate([this.routerHelper.translateRoute(`${Paths.ShopsMap}`)]);
  }

  shopImageCoverUrl(shop: DoodEntityModel): string {
    const _images = shop[EntityKeys.Images] ?? {};

    if (_images.overview?.url) {
      return _images.overview.url;
    }

    if (_images.cover?.url) {
      return _images.cover.url;
    }

    if (shop.cover) {
      return 'https://api.dood.com' + shop.cover;
    }

    return '';
  }

  protected getShopCurrency(shop: DoodEntityModel): string {
    // satisfy TS for strictTemplate (not the best solution)
    return (shop as unknown as { [EntityKeys.Currency]: string })[this.entityKeys.Currency] ?? '';
  }
}
