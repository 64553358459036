<a
  *ngIf="openInNewTab"
  class="py-2.5 block font-accent text-white text-sm"
  target="_blank"
  href="{{ link }}"
  >{{ name }}</a
>
<a
  *ngIf="!openInNewTab"
  class="py-2.5 block font-accent text-white text-sm"
  href="{{ link }}"
  >{{ name }}</a
>
