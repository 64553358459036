export enum UserKeys {
  Id = 'id',
  FirstName = 'firstname',
  LastName = 'lastname',
  CompleteName = 'complete_name',
  Email = 'email',
  Password = 'password',
  Addresses = 'addresses',
  Phone = 'phone',
  Roles = 'roles',
  Avatar = 'avatar',
  LegalTermsAcceptedAt = 'legal_terms_accepted_at',
  Entities = 'entities',
  IsAnonymous = 'is_anonymous',
  FirebaseUID = 'firebase_uid',
  Optin = 'optin',
  Metafields = 'metafields',
  PreferredLocales = 'preferred_locales',
  OptIn = 'optin',
}
