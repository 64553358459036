<div class="bg-neutral-800 border-t border-neutral-600 pb-40 lg:pb-14">
  <div
    class="py-5 lg:px-12 text-white text-sm text-center lg:text-left flex flex-col lg:justify-center"
  >
    <div
      *ngIf="hasLinks()"
      class="w-full overflow-x-auto pr-4 scrollbar-hide flex items-center px-5"
    >
      <app-block-list
        class="overflow-x-scroll bg-white"
        [blockIdList]="links"
      ></app-block-list>
    </div>
    <div *ngIf="displayPoweredByDood">
      <a
        class="flex items-center justify-center w-full py-3"
        href="https://www.dood.com/corporate/"
        target="_blank"
      >
        <img
          class="h-10"
          src="assets/images/dood-logo/logo-dood-white.svg"
          alt="DOOD logo"
        />
      </a>
    </div>
  </div>
</div>
