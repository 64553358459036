import { Subject } from 'rxjs';
import { skipWhile, take, takeUntil, tap } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { DoodShopModel } from '@core/models/shop.model';
import { ISimpleItem } from '@core/models/simple-item.model';

import { DistributionModeKeys, ShopKeys } from '@config/keys/shop.keys';

import { BasketStoreRefiner } from '@common/refiners/basket.refiner';
import { OnSiteLocationStoreRefiner } from '@common/refiners/on-site-location.refiner';

import { AuthStoreSelector } from '@common/selectors/authentication.selector';
import { OnSiteLocationStoreSelector } from '@common/selectors/on-site-location.selector';

import { DoodOnSiteLocationSourceValues } from '@store/on-site-location/on-site-location.enum';
import { DistributionModeValues, OnSiteLocationPickModeValues } from '@config/values/order.values';

@Component({
  selector: 'app-on-site-location-select',
  templateUrl: './on-site-location-select.component.html',
})
export class OnSiteLocationSelectComponent implements OnInit, OnDestroy {
  @Input() shop!: DoodShopModel;
  @Input() selectedOnSiteLocationId?: string;

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onSiteLocationChanged = new EventEmitter<string>();

  onSiteLocations?: ISimpleItem[];

  userId$ = this.authSelector.selectUserMetafields;

  basketUserId$ = this.basketRefiner.selectUserId;

  displayOnSiteLocationSelect = true;

  private destroyed$ = new Subject<boolean>();

  constructor(
    private authSelector: AuthStoreSelector,
    private basketRefiner: BasketStoreRefiner,
    private onSiteLocationRefiner: OnSiteLocationStoreRefiner,
    private onSiteLocationSelector: OnSiteLocationStoreSelector,
  ) {}

  ngOnInit(): void {
    const { id: onSiteLocationId, source } = this.onSiteLocationSelector.settings;
    const onsiteLocationPickMode = this.shop[ShopKeys.DistributionModes].filter(
      distributionMode => {
        return distributionMode[DistributionModeKeys.Type] === DistributionModeValues.OnSite;
      },
    )?.[0]?.[DistributionModeKeys.OnsiteLocationPickMode];

    const isSourceFromUrl = source === DoodOnSiteLocationSourceValues.fromQrCode;

    if (
      onsiteLocationPickMode === OnSiteLocationPickModeValues.UrlOrDropdown &&
      isSourceFromUrl &&
      onSiteLocationId
    ) {
      this.displayOnSiteLocationSelect = false;
      return;
    }

    if (onsiteLocationPickMode === OnSiteLocationPickModeValues.Url) {
      this.displayOnSiteLocationSelect = false;
      return;
    }

    this.onSiteLocationRefiner
      .selectByShopId(this.shop[ShopKeys.Id])
      .pipe(
        takeUntil(this.destroyed$),
        skipWhile(onSiteLocations => !onSiteLocations?.length),
        take(1),
        tap(onSiteLocations => {
          this.onSiteLocations = onSiteLocations
            .filter(e => e.enabled)
            .map(onSiteLocation => {
              return {
                value: onSiteLocation.id,
                label: onSiteLocation.name,
              };
            })
            .sort((a: { label: string }, b: { label: string }) => {
              return a.label < b.label ? -1 : 1;
            });

          const { lastLocationId: lastOnSiteLocationId } = this.onSiteLocationSelector.settings;

          if (!this.selectedOnSiteLocationId && lastOnSiteLocationId) {
            const findLastOnSiteLocation = this.onSiteLocations.find(
              onSiteLocation => onSiteLocation.value === lastOnSiteLocationId,
            );

            this.selectedOnSiteLocationId = findLastOnSiteLocation?.value;

            this.onOnSiteLocationChanged(this.selectedOnSiteLocationId);
            return;
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onOnSiteLocationChanged($event: any): void {
    this.onSiteLocationChanged.emit($event);
  }
}
