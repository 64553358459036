<app-back-header-atom
  [icon]="'arrow-left'"
  [text]="'basket.my-order' | translate"
  [textColor]="textColor"
  [backgroundColor]="backgroundColor"
  (navigationClick)="back()"
></app-back-header-atom>
<div
  class="max-w-md mx-auto py-4 lg:pt-12 lg:pb-10"
  [style.color]="textColor | contentBuilderColor"
  [ngClass]="{
    'hidden lg:block ': (hideCartParameters$ | async),
  }"
>
  <h1 class="hidden lg:block mb-5 font-accent font-bold text-5xl text-center">
    {{ 'basket.my-order' | translate }}
  </h1>

  <div
    *ngIf="!(hideCartParameters$ | async)"
    class="max-w-md flex justify-center"
  >
    <div class="lg:bg-neutral-100 w-full mx-2">
      <app-cart-parameters-overview
        [iconBackgroundColor]="iconBackgroundColor"
        [iconColor]="iconColor"
        [textColor]="labelColor"
      ></app-cart-parameters-overview>
    </div>
  </div>
</div>
