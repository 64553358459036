import { Component, Input, OnInit } from '@angular/core';

import { ModalsService } from '@core/services/modals/modals.service';
import { IContentBuilderFieldImage } from '@core/models/content-builder-fields.model';
import { ModalScrollBlockBase } from '@core/base/modalScrollBlock/modal-scroll-block.base';
import { timer } from 'rxjs';

@Component({
  selector: 'app-splash-screen-image-modal',
  templateUrl: './splash-screen-image-modal.component.html',
  styleUrls: ['./splash-screen-image-modal.component.scss'],
})
export class SplashScreenImageModalComponent extends ModalScrollBlockBase implements OnInit {
  static handle = 'splash-screen-image-modal';

  @Input() url?: string;
  @Input() maxWidth: string = '100dvw';
  @Input() maxHeight: string = '100dvh';
  @Input() content?: IContentBuilderFieldImage;
  @Input() closeDelay?: string;

  constructor(private modalService: ModalsService) {
    super();
  }

  ngOnInit(): void {
    const delay = this.closeDelay && parseInt(this.closeDelay, 10) * 1000;
    if (delay) {
      timer(delay).subscribe(() => this.modalService.close(SplashScreenImageModalComponent.handle));
    }
  }

  close(event: Event): void {
    event.stopPropagation();
    this.modalService.close(SplashScreenImageModalComponent.handle);
  }
}
