import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-header-language-flag-atom',
  templateUrl: './header-language-flag-atom.component.html',
  styleUrls: ['./header-language-flag-atom.component.scss'],
})
export class HeaderLanguageFlagAtomComponent {
  @Input() languageFlagUrl!: string;
}
