import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { DoodDeclinableModel } from '@core/models/product.model';
import { DoodApiError } from '@shared/interfaces/error.interface';
import { createActiveStoreActions } from '@store/_abstract/active';

import { DECLINABLE_STORE_KEY } from './declinable.state';

export const DECLINABLE_STORE_API_ACTIONS = createActionGroup({
  source: 'Dood/API/Declinable',
  events: {
    'Fetch Declinables': emptyProps(),
    'Fetch Declinables Success': props<{ declinables: DoodDeclinableModel[] }>(),
    'Fetch Declinables Failure': props<{ error: DoodApiError }>(),
    'Fetch Declinables Complete': emptyProps(),
  },
});

export const DECLINABLE_STORE_LOCAL_ACTIONS = createActionGroup({
  source: 'Dood/LOCAL/Declinable',
  events: {
    // Errors
    'Update Error': props<{ error: DoodApiError | null }>(),
    'Reset Error': emptyProps(),

    // Status
    'Update Is Loaded': props<{ isLoaded: boolean }>(),
    'Update Is Loading': props<{ isLoading: boolean }>(),

    // Misc
    'reset': emptyProps(),
  },
});

export const DECLINABLE_STORE_ACTIVE_ACTIONS =
  createActiveStoreActions<DoodDeclinableModel>(DECLINABLE_STORE_KEY);
