import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';

import { ComoService } from '@core/services/como/como.service';
import { ModalsService } from '@core/services/modals/modals.service';

import { DestroyerBase } from '@core/base/destroyer/destroyer.base';

import { ComoAsset } from '@core/models/como.model';
import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { ComoProductsSelectionStep } from '@shared/modals/como/como-products-selection-modal/como-products-selection-step-model';

@Component({
  selector: 'app-como-products-selection-modal',
  templateUrl: './como-products-selection-modal.component.html',
})
export class ComoProductsSelectionModalComponent extends DestroyerBase implements OnInit {
  static handle = 'como-products-selection-modal';

  index$: Observable<number> = this.modalSelector
    .selectModal(ComoProductsSelectionModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 5)));

  @Input() asset!: ComoAsset;

  isRewardAddInProgress = false;

  currentStepId = 0;
  steps?: ComoProductsSelectionStep[];
  currentStep?: ComoProductsSelectionStep;

  constructor(
    private modalSelector: ModalStoreSelector,
    private readonly comoService: ComoService,
    private readonly modalsService: ModalsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.prepareSteps();
    if (this.steps?.length === 0) {
      this.addRewardAndClose();
      return;
    }
    this.currentStep = this.steps?.[this.currentStepId];
  }

  close(): void {
    this.modalsService.close(ComoProductsSelectionModalComponent.handle);
  }

  goToNextStep(): void {
    const steps = this.steps;

    if (!steps) {
      this.addRewardAndClose();
      return;
    }

    if (this.currentStepId === steps.length - 1) {
      this.addRewardAndClose();
    }

    this.currentStepId++;
    this.currentStep = this.steps?.[this.currentStepId];
  }

  private prepareSteps(): void {
    this.steps = [];
    let discounts = this.asset.items_selection?.discounts ?? [];
    for (const discount of discounts) {
      for (const basketRequirement of discount.basketRequirements) {
        this.steps.push({
          title: basketRequirement.text,
          ids: basketRequirement.ids,
        });
      }
    }
  }

  private addRewardAndClose(): void {
    this.isRewardAddInProgress = true;
    this.comoService
      .addRewardToCart$(this.asset)
      .pipe(
        tap(() => {
          this.close();
          window.scroll({
            top: 0,
            behavior: 'smooth',
          });
        }),
      )
      .subscribe();
  }
}
