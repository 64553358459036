import { ISimpleItem } from '@core/models/simple-item.model';
import { DistributionModeValues, OrderTypeValues } from '@config/values/order.values';
import { ParametersDistributionModeLabels } from '@config/labels/parameters.labels';
import { IDistributionMode } from '@core/models/shop.model';

export class ParametersUtils {
  static mapDistributionModes(distributions?: IDistributionMode[]): ISimpleItem[] {
    if (!distributions) {
      return [];
    }

    let distributionModes: ISimpleItem[] = [];
    distributions?.map(distributionMode => {
      const type = distributionMode.type;
      const customName = distributionMode.custom_name;

      switch (type) {
        case DistributionModeValues.ClickAndCollect:
          distributionModes = [
            ...distributionModes,
            {
              label: customName ?? ParametersDistributionModeLabels.ClickAndCollect,
              value: OrderTypeValues.ClickAndCollect,
              message: distributionMode.message,
              icon: 'icon-shopping-bag',
            },
          ];
          break;
        case DistributionModeValues.ZitiCity:
        case DistributionModeValues.Stuart:
        case DistributionModeValues.UberDirect:
        case DistributionModeValues.Shipday:
        case DistributionModeValues.OwnDelivery:
          distributionModes = [
            ...distributionModes,
            {
              label: customName ?? ParametersDistributionModeLabels.Delivery,
              value: OrderTypeValues.Delivery,
              message: distributionMode.message,
              icon: 'icon-bike',
            },
          ];
          break;
        case DistributionModeValues.OwnShipping:
          distributionModes = [
            ...distributionModes,
            {
              label: customName ?? ParametersDistributionModeLabels.Shipping,
              value: OrderTypeValues.Shipping,
              message: distributionMode.message,
              icon: 'icon-bag-delivery',
            },
          ];
          break;
        case DistributionModeValues.OnSite:
          distributionModes = [
            ...distributionModes,
            {
              label: customName ?? ParametersDistributionModeLabels.OnSite,
              value: OrderTypeValues.OnSite,
              message: distributionMode.message,
              icon: 'icon-seat',
            },
          ];
          break;
        case DistributionModeValues.EatIn:
          distributionModes = [
            ...distributionModes,
            {
              label: customName ?? ParametersDistributionModeLabels.EatIn,
              value: OrderTypeValues.EatIn,
              message: distributionMode.message,
              icon: 'icon-seat',
            },
          ];
          break;
      }
    });
    return distributionModes.filter((v, i, a) => a.findIndex(t => t.value === v.value) === i);
  }
}
