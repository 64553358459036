<!--TODO: add navigation-->
<div
  class="bg-neutral-100 px-5 pb-4"
  *ngFor="let shopItem of shopItems"
>
  <a (click)="navigateToShop(shopItem.slug)">
    @if (isMarketplaceAvailable$ | async; as isMarketplaceAvailable) {
      <app-shop-list-atom
        [shopItem]="forceType(shopItem)"
        [isMarketPlaceAvailable]="isMarketplaceAvailable"
      ></app-shop-list-atom>
    }
  </a>
</div>
