import { ReducerTypes, ActionCreator, on } from '@ngrx/store';

import { FILTER_STORE_LOCAL_ACTIONS } from './filter.action';
import { FilterState, FILTER_STORE_ADAPTER, FILTER_STORE_INITIAL_STATE } from './filter.state';

export const FILTER_STORE_API_PIECE: ReducerTypes<FilterState, readonly ActionCreator[]>[] = [];

export const FILTER_STORE_LOCAL_PIECE: ReducerTypes<FilterState, readonly ActionCreator[]>[] = [
  // Multiple
  on(FILTER_STORE_LOCAL_ACTIONS.addFilters, (state, { filters }): FilterState => {
    return FILTER_STORE_ADAPTER.addMany(filters, state);
  }),
  on(FILTER_STORE_LOCAL_ACTIONS.setFilters, (state, { filters }): FilterState => {
    return FILTER_STORE_ADAPTER.setAll(filters, state);
  }),
  on(FILTER_STORE_LOCAL_ACTIONS.removeFilters, (state, { ids }): FilterState => {
    return FILTER_STORE_ADAPTER.removeMany(ids, state);
  }),
  on(FILTER_STORE_LOCAL_ACTIONS.upsertFilters, (state, { filters }): FilterState => {
    return FILTER_STORE_ADAPTER.upsertMany(filters, state);
  }),
  on(FILTER_STORE_LOCAL_ACTIONS.resetFilters, (state): FilterState => {
    return FILTER_STORE_ADAPTER.removeAll(state);
  }),

  // Single
  on(FILTER_STORE_LOCAL_ACTIONS.addFilter, (state, { filter }): FilterState => {
    return FILTER_STORE_ADAPTER.addOne(filter, state);
  }),
  on(FILTER_STORE_LOCAL_ACTIONS.updateFilter, (state, { filter }): FilterState => {
    return FILTER_STORE_ADAPTER.updateOne(filter, state);
  }),
  on(FILTER_STORE_LOCAL_ACTIONS.removeFilter, (state, { id }): FilterState => {
    return FILTER_STORE_ADAPTER.removeOne(id, state);
  }),
  on(FILTER_STORE_LOCAL_ACTIONS.upsertFilter, (state, { filter }): FilterState => {
    return FILTER_STORE_ADAPTER.upsertOne(filter, state);
  }),

  // Category
  on(FILTER_STORE_LOCAL_ACTIONS.addCategory, (state, { category }): FilterState => {
    let _categories = [...state.categories];
    const isFoundIndex = state.categories.findIndex(_category => _category.id === category.id);
    if (isFoundIndex >= 0) {
      _categories[isFoundIndex] = category;
    } else {
      _categories = [..._categories, category];
    }
    return { ...state, categories: _categories };
  }),
  on(FILTER_STORE_LOCAL_ACTIONS.removeCategory, (state, { id }): FilterState => {
    const isFound = state.categories.find(_category => _category.id === id);
    if (!isFound) return state;
    const _categories = [...state.categories];
    return { ...state, categories: _categories.filter(_c => _c.id === id) };
  }),
  on(FILTER_STORE_LOCAL_ACTIONS.updateCategories, (state, { categories }): FilterState => {
    return { ...state, categories };
  }),
  on(FILTER_STORE_LOCAL_ACTIONS.resetCategories, (state): FilterState => {
    return { ...state, categories: [] };
  }),

  // Misc
  on(FILTER_STORE_LOCAL_ACTIONS.reset, (): FilterState => FILTER_STORE_INITIAL_STATE),
];
