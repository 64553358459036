<app-modal-filler-atom
  (click)="close()"
  [index]="(index$ | async) ?? 0"
></app-modal-filler-atom>
<div
  class="fixed left-0 right-0 overflow-y-auto shadow md:left-0 md:top-0 md:flex md:h-full md:w-full md:items-center md:justify-center mdMax:bottom-0 mdMax:mx-auto mdMax:max-h-85-screen mdMax:max-w-screen-sm"
  [style.z-index]="((index$ | async) ?? 0) + 50"
>
  <div
    class="rounded-t-3xl bg-white p-5 md:w-2/5 md:max-w-lg md:rounded-3xl md:p-7"
    [style.z-index]="((index$ | async) ?? 0) + 50"
  >
    <h1
      class="font-brand text-28px font-bold text-neutral-800"
      translate="zerosix.offered-product-modal.heading"
    ></h1>
    <app-card-carousel
      *ngIf="products.length"
      [cards]="products"
      [nbDisplayCards]="2"
      [carouselItem]="productTemplate"
    ></app-card-carousel>

    <ng-template
      #productTemplate
      let-product
    >
      <div
        class="mr-3 w-10 min-w-10 flex-shrink-0 flex-grow-0 md:mr-4 lg:mr-6 lg:w-52 lg:min-w-52 xl:mr-8"
      >
        <app-product-card-atom
          displayContext="loyalty"
          [product]="product"
          [isOrderingAllowed]="true"
          (productClick)="onNavigateTo(product)"
        ></app-product-card-atom>
      </div>
    </ng-template>

    <div
      *ngIf="products.length === 0"
      class="py-4"
    >
      {{ 'zerosix.offered-product-modal.no-product-available' | translate }}
    </div>

    <div class="mt-4">
      <app-button-atom
        [label]="'zerosix.cancel-add-voucher' | translate"
        type="light"
        [full]="true"
        (click)="cancel()"
      ></app-button-atom>
    </div>
  </div>
</div>
