import { Update } from '@ngrx/entity';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { DoodFilterModel } from '@core/models/filters.model';
import { DoodEntityCategory } from '@core/models/entity.model';
import { DoodApiError } from '@shared/interfaces/error.interface';

export const FILTER_STORE_API_ACTIONS = createActionGroup({
  source: 'Dood/API/Filter',
  events: {
    'Fetch Filters': props<{ userId: string; marketplaceId: string }>(),
    'Fetch Filters Success': props<{ filters: DoodFilterModel[] }>(),
    'Fetch Filters Failure': props<{ error: DoodApiError }>(),
    'Fetch Filters Complete': emptyProps(),
  },
});

export const FILTER_STORE_LOCAL_ACTIONS = createActionGroup({
  source: 'Dood/LOCAL/Filter',
  events: {
    // Filters
    // -- Multiple
    'Set Filters': props<{ filters: DoodFilterModel[] }>(),
    'Add Filters': props<{ filters: DoodFilterModel[] }>(),
    'Remove Filters': props<{ ids: string[] }>(),
    'Upsert Filters': props<{ filters: DoodFilterModel[] }>(),
    'Reset Filters': emptyProps(),

    // -- Single
    'Add Filter': props<{ filter: DoodFilterModel }>(),
    'Remove Filter': props<{ id: string }>(),
    'Upsert Filter': props<{ filter: DoodFilterModel }>(),
    'Update Filter': props<{ filter: Update<DoodFilterModel> }>(),

    // Categories
    'Add Category': props<{ category: DoodEntityCategory }>(),
    'Remove Category': props<{ id: string }>(),
    'Update Categories': props<{ categories: DoodEntityCategory[] }>(),
    'Reset Categories': emptyProps(),

    // Misc
    'reset': emptyProps(),
  },
});
