import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { DoodShopModel } from '@core/models/shop.model';
import { DoodApiError } from '@shared/interfaces/error.interface';
import { createActiveStoreActions } from '@store/_abstract/active';
import { SHOP_STORE_KEY } from './shop.state';

export const SHOP_STORE_API_ACTIONS = createActionGroup({
  source: 'Dood/API/Shop',
  events: {
    'Fetch Shops': emptyProps(),
    'Fetch Shops Success': props<{ shops: DoodShopModel[] }>(),
    'Fetch Shops Failure': props<{ error: DoodApiError }>(),
    'Fetch Shops Complete': emptyProps(),
  },
});

export const SHOP_STORE_LOCAL_ACTIONS = createActionGroup({
  source: 'Dood/LOCAL/Shop',
  events: {
    // Shops
    // -- Multiple
    'Set Shops': props<{ shops: DoodShopModel[] }>(),
    'Add Shops': props<{ shops: DoodShopModel[] }>(),
    'Remove Shops': props<{ ids: string[] }>(),
    'Upsert Shops': props<{ shops: DoodShopModel[] }>(),
    'Remove All Shops': emptyProps(),

    // -- Single
    'Add Shop': props<{ shop: DoodShopModel }>(),
    'Remove Shop': props<{ id: string }>(),
    'Upsert Shop': props<{ shop: DoodShopModel }>(),

    // Active
    'Update Active': props<{ active: string | null }>(),
    'Reset Active': emptyProps(),

    // Slots
    'Add Slots': props<{ slots: number[] }>(),
    'Remove Slots': props<{ slots: number[] }>(),
    'Set Slots': props<{ slots: number[] }>(),
    'Reset Slots': props<{ slots: number[] }>(),

    // Error
    'Reset Error': emptyProps(),
    'Update Error': props<{ error: unknown }>(),

    // Status
    'Update Is Loaded': props<{ isLoaded: boolean }>(),
    'Update Is Loading': props<{ isLoading: boolean }>(),

    // Misc
    'reset': emptyProps(),
  },
});

export const SHOP_STORE_ACTIVE_ACTIONS = createActiveStoreActions<DoodShopModel, string>(
  SHOP_STORE_KEY,
);
