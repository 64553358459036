import { Injectable } from '@angular/core';
import { DoodEntityModel, DoodEntityCategory } from '@core/models/entity.model';
import { HttpClient } from '@angular/common/http';
import { DOOD_API } from '@config/ws.config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EntitiesApiService {
  constructor(private client: HttpClient) {}

  get$(marketplaceId: string, params: any = {}): Observable<DoodEntityModel[]> {
    return this.client.get<DoodEntityModel[]>(
      `${DOOD_API}/marketplaces/${marketplaceId}/list-entities`,
      {
        params,
      },
    );
  }

  getEntitiesCategories$(marketplaceId: string): Observable<DoodEntityCategory[]> {
    return this.client.get<DoodEntityCategory[]>(
      `${DOOD_API}/types/active?marketplace=${marketplaceId}`,
    );
  }
}
