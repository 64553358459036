<div
  class="bg-white flex cursor-pointer max-w-screen-sm leading-5 h-full rounded shadow lg:shadow-none"
  (click)="buttonClick.emit()"
>
  <div class="relative height-fit-content">
    <img
      *ngIf="shopImage"
      [src]="shopImage"
      class="w-24 h-24 object-cover rounded-l rounded-r-none lg:rounded-lg lg:rounded-l-lg"
      alt="image"
    />
    <div
      *ngIf="!shopImage"
      class="w-24 h-24 bg-neutral-100 rounded-l rounded-r-none lg:rounded-lg lg:rounded-l-lg"
    ></div>
    <div class="absolute bottom-0 left-0 text-white m-3">
      <!--            TODO: change icon-->
      <!-- <i class="icon" [class.icon-heart]="!isFavorite" [class.icon-todo]="isFavorite"
                (click)="onFavoriteClick($event)"></i> -->
    </div>
  </div>
  <div class="px-3 py-1.5 text-sm text-neutral-600">
    <p class="font-accent font-bold text-md text-neutral-800 leading-6">
      {{ name }}
      <span class="icon icon-pin text-primary-600 text-sm"></span>
    </p>
    <p>
      <span class="dot">{{ category }}</span>
      <span
        *ngIf="budget"
        class="dot"
      >
        <app-budget-shop-atom [value]="budget"></app-budget-shop-atom>
      </span>
      <app-star-atom
        *ngIf="stars"
        [star]="stars"
      ></app-star-atom>
    </p>
    <p>
      <span
        class="dot"
        *ngIf="formattedDistance"
        >{{ formattedDistance }}</span
      >
      <span
        class="dot"
        *ngIf="readyAt"
        >{{ readyAt }}</span
      >
    </p>
    <p
      *ngIf="promotion"
      class="flex items-center text-success-700"
    >
      <i class="mr-1 icon icon-todo"></i>
      -{{ promotion.percent }} % {{ promotion.label }}
    </p>
  </div>
</div>
