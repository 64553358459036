import { RouterHelperService } from './../../../core/services/router-helper/router-helper.service';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mosaic-long-push-atom',
  templateUrl: './mosaic-long-push-atom.component.html',
  styleUrls: ['./mosaic-long-push-atom.component.scss'],
})
export class MosaicLongPushAtomComponent {
  @Input() imageUrl?: string;
  @Input() title?: string;
  @Input() linkUrl!: string;
  @Input() aspectRatio?: string;
  @Input() rounded?: string;
  @Input() shadow?: string;

  constructor(
    private readonly router: Router,
    private readonly routerHelper: RouterHelperService,
  ) {}

  onClick(): void {
    if (this.linkUrl.startsWith('http')) {
      window.open(this.linkUrl, '_blank');
    } else {
      this.router.navigate([this.routerHelper.translateRoute(this.linkUrl)]);
    }
  }
}
