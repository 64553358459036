<div class="mx-auto max-w-md lg:mx-auto lg:max-w-4xl lg:px-0">
  <div
    class="mx-auto max-w-md px-5 pb-4 lg:max-w-full lg:px-0 lg:pb-6"
    *ngIf="heading || leading"
  >
    <h2
      *ngIf="heading"
      class="font-accent text-2xl font-bold text-neutral-800 lg:text-3xl"
    >
      {{ heading }}
    </h2>
    <p
      *ngIf="leading"
      class="mt-1 font-base text-sm text-neutral-600 md:text-base"
    >
      {{ leading }}
    </p>
  </div>
  <ul
    *ngIf="isMobile"
    class="flex overflow-x-auto px-5 pb-8 scrollbar-hide lg:px-0 lg:pb-12"
  >
    <li
      class="ml-1 mr-3 w-10 min-w-10 flex-shrink-0 flex-grow-0 lg:w-52 lg:min-w-52"
      *ngFor="let suggestion of suggestions"
    >
      <app-product-card-atom
        *ngIf="suggestion.type === 'product'"
        displayContext="suggestion"
        [product]="forceType(suggestion).data"
        (productClick)="productClick(forceType(suggestion).data)"
      ></app-product-card-atom>
    </li>
  </ul>
  <div
    class="px-5"
    *ngIf="!isMobile"
  >
    <app-card-carousel
      [cards]="suggestions"
      [nbDisplayCards]="2"
      [carouselItem]="productTemplate"
    ></app-card-carousel>
    <ng-template
      #productTemplate
      let-suggestion
    >
      <div class="flex-1 items-stretch">
        <app-product-card-atom
          *ngIf="suggestion.type === 'product'"
          displayContext="suggestion"
          [product]="suggestion.data"
          (productClick)="productClick(suggestion.data)"
        ></app-product-card-atom>
      </div>
    </ng-template>
  </div>
</div>
