import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  Input,
  Renderer2,
  TemplateRef,
  ViewChild,
  signal,
  DoCheck,
  AfterViewInit,
} from '@angular/core';

@Component({
  selector: 'app-kiosk-horizontal-scroll',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  template: ` <div class="flex flex-row items-center gap-2">
    <ng-container *ngIf="showScrollBtn()">
      <div
        class="contents"
        (pointerdown)="scrollTo('left')"
      >
        <ng-container
          *ngTemplateOutlet="
            leftButtonTemplate;
            context: {
              $implicit: leftButtonData(),
            }
          "
        ></ng-container>
      </div>
    </ng-container>
    <div
      class="w-full overflow-x-auto scroll-smooth scrollbar-hide"
      #scrollContainer
    >
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    <ng-container *ngIf="showScrollBtn()">
      <div
        class="contents"
        (pointerdown)="scrollTo('right')"
      >
        <ng-container
          *ngTemplateOutlet="
            rightButtonTemplate;
            context: {
              $implicit: rightButtonData(),
            }
          "
        ></ng-container>
      </div>
    </ng-container>
  </div>`,
})
export class KioskHorizontalScrollComponent implements DoCheck, AfterViewInit {
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly renderer2 = inject(Renderer2);

  @ViewChild('scrollContainer') scrollContainer: ElementRef | undefined;

  @Input() leftButtonTemplate!: TemplateRef<any>;
  @Input() rightButtonTemplate!: TemplateRef<any>;
  @Input() contentTemplate!: TemplateRef<any>;

  private scrollAmount = signal(100);
  private isScrolling = signal(false);

  showScrollBtn = signal(false);
  disableLeftBtn = signal(true);
  disableRightBtn = signal(false);

  ngDoCheck(): void {
    this.updateScrollIconsOpacity();
  }

  ngAfterViewInit(): void {
    if (this.scrollContainer) {
      this.updateScrollIconsOpacity();

      this.renderer2.listen(this.scrollContainer.nativeElement, 'scroll', (event: Event) => {
        if (!this.isScrolling()) {
          this.isScrolling.set(true);
          requestAnimationFrame(() => {
            this.updateScrollIconsOpacity();
            this.isScrolling.set(false);
          });
        }
      });
    }
  }

  scrollTo(direction: 'left' | 'right'): void {
    const container = this.scrollContainer?.nativeElement;
    if (container) {
      container.scrollBy({
        left: direction === 'left' ? -this.scrollAmount() : this.scrollAmount(),
        behavior: 'smooth',
      });
      this.updateScrollIconsOpacity();
    }
  }

  scrollToRight(): void {
    this.cdr.detectChanges();
    const container = this.scrollContainer?.nativeElement;
    if (container) {
      container.scrollTo({
        left: container.scrollWidth - container.clientWidth,
        behavior: 'smooth',
      });
    }
  }

  updateScrollIconsOpacity(): void {
    const container = this.scrollContainer?.nativeElement;
    if (container) {
      const scrollLeft = container.scrollLeft;
      const maxScrollLeft = container.scrollWidth - container.clientWidth;

      const disableLeft = scrollLeft <= 0;
      const disableRight = scrollLeft >= maxScrollLeft;
      const showScroll = scrollLeft > 0 || scrollLeft < maxScrollLeft;

      if (
        this.disableLeftBtn() !== disableLeft ||
        this.disableRightBtn() !== disableRight ||
        this.showScrollBtn() !== showScroll
      ) {
        this.disableLeftBtn.set(disableLeft);
        this.disableRightBtn.set(disableRight);
        this.showScrollBtn.set(showScroll);

        this.cdr.detectChanges();
      }
    }
  }

  public leftButtonData = () => {
    return {
      onClick: () => this.scrollTo('left'),
      disabled: this.disableLeftBtn(),
    };
  };
  public rightButtonData = () => {
    return {
      onClick: () => this.scrollTo('right'),
      disabled: this.disableRightBtn(),
    };
  };
}
