import {Store} from '@ngrx/store';
import {ActivatedRoute, Router} from '@angular/router';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {from, interval, Observable, of, throwError} from 'rxjs';
import {catchError, filter, switchMap, take, takeUntil, tap} from 'rxjs/operators';

import {Paths} from '@config/paths.config';
import {ObjectUtils} from '@app/utilities/object.util';
import {CheckKeys} from '@config/keys/check.keys';
import {DestroyerBase} from '@core/base/destroyer/destroyer.base';

import {DoodUserModel} from '@store/authentication/authentication.model';
import {SELECT_AUTH_USER} from '@store/authentication/authentication.selection';

import {PaymentAtTableStoreSelector} from '@common/selectors/payment-at-table.selector';
import {OnSiteLocationStoreSelector} from '@common/selectors/on-site-location.selector';

import {PaymentStoreDispatcher} from '@common/dispatchers/payment.dispatcher';
import {PaymentAtTableStoreDispatcher} from '@common/dispatchers/payment-at-table.dispatcher';

import {UserService} from '@core/services/user/user.service';
import {PushService} from '@core/services/push/push.service';
import {EncryptionService} from '@core/services/encryption/encryption.service';
import {RouterHelperService} from '@core/services/router-helper/router-helper.service';
import {AuthFirebaseService} from '@core/services/api/auth-firebase/auth-firebase.service';
import {OnSiteLocationsService} from '@core/services/on-site-locations/on-site-locations.service';
import {NavigationHistoryService} from '@core/services/navigation-history/navigation-history.service';
import {PAT_ERROR_NO_CHECKS_OPEN, PAT_ERROR_NOT_FOUND} from '@config/values/pat.values';
import {UserCredential} from 'firebase/auth';
import {AnalyticsService} from "@core/services/app/analytics.service";

@Component({
  selector: 'app-pat-page',
  templateUrl: './pat-page.component.html',
})
export class PatPageComponent extends DestroyerBase implements OnInit, OnDestroy {
  @Input() displayHeader = true;
  @Input() displayHeaderBack = true;
  @Input() headerText?: string;

  error!: string;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly pushService: PushService,
    private paymentDispatcher: PaymentStoreDispatcher,
    private readonly routerHelper: RouterHelperService,
    private readonly encryptionService: EncryptionService,
    private readonly authFirebaseService: AuthFirebaseService,
    private paymentAtTableSelector: PaymentAtTableStoreSelector,
    private onSiteLocationSelector: OnSiteLocationStoreSelector,
    private paymentAtTableDispatcher: PaymentAtTableStoreDispatcher,
    private readonly onSiteLocationService: OnSiteLocationsService,
    private readonly navigationHistoryService: NavigationHistoryService,
    private readonly analyticsService: AnalyticsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.store
      .select(SELECT_AUTH_USER)
      .pipe(
        take(1),
        switchMap(user => user?.id ? of(user) : this.signInUserAsAnonymous$().pipe(take(1))),
        tap(user => {
          if (user) {
            this.getOnSiteLocationOpenCheck();
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    const check = this.paymentAtTableSelector.check;
    if (check?.id) {
      this.onSiteLocationService.removeOnSiteLocationCheck(
        check.id,
        this.onSiteLocationService.checksSubscriptions$,
      );
    }

    this.paymentAtTableDispatcher.resetCheck();
  }

  goBack(): void {
    this.navigationHistoryService.goBack();
  }

  signInUserAsAnonymous$(): Observable<DoodUserModel | undefined> {
    return this.authFirebaseService.signInAnonymously().pipe(
      switchMap((userCredential: UserCredential) =>
        this.authFirebaseService.saveFirebaseTokenInSessionStore$(userCredential),
      ),
      switchMap(() => this.userService.createAnonymousUser$()),
      switchMap(() => this.authFirebaseService.loadUser$()),
    );
  }

  getOnSiteLocationOpenCheck(): void {
    const {id, lastLocationId} = this.onSiteLocationSelector.settings;
    const onSiteLocationId = id ?? lastLocationId;

    this.analyticsService.trackEvent('get_check_on_location', {
      on_site_location_id: onSiteLocationId
    });

    let check = this.paymentAtTableSelector.check;
    const checkIdUrlParam = this.route.snapshot.queryParams.checkId;

    let storeCheckId = check?.id ? check?.id : checkIdUrlParam ? checkIdUrlParam : undefined;

    if (!onSiteLocationId && !storeCheckId) {
      this.paymentAtTableDispatcher.updateCheck({
        error: {
          detail: PAT_ERROR_NOT_FOUND,
        },
      });
      return;
    }

    if (storeCheckId && onSiteLocationId && storeCheckId !== onSiteLocationId) {
      this.onSiteLocationService.removeOnSiteLocationCheck(
        check.id,
        this.onSiteLocationService.checksSubscriptions$,
      );
      this.paymentAtTableDispatcher.reset();
      storeCheckId = undefined;
    }

    if (storeCheckId) {
      this.onSiteLocationService.getOnSiteLocationCheck$(storeCheckId).pipe(take(1)).subscribe();

      this.onSiteLocationService
        .subscribeToOnSiteLocationCheck$(storeCheckId)
        .pipe(
          tap(result => {
            const _data = String(ObjectUtils.isObject(result) ? result.data : '');
            this.decryptAES(check?.[CheckKeys.MercurePrivateKey], _data)
              .pipe(take(1))
              .subscribe(v => {
                if (v) {
                  this.paymentAtTableDispatcher.resetCheck();
                  this.paymentAtTableDispatcher.updateCheck({...JSON.parse(v), error: null});
                }
              });
          }),
        )
        .subscribe();
      return;
    }

    if (onSiteLocationId) {
      this.onSiteLocationService
        .loadOnSiteLocationOpenCheck$(onSiteLocationId)
        .pipe(
          take(1),
          catchError(err => {
            this.analyticsService.trackEvent('get_check_on_location_error', {
              error_message: err?.error?.detail
            });
            this.paymentAtTableDispatcher.updateCheck({
              error: {
                detail: err?.error?.detail,
              },
            });
            return throwError(() => err);
          }),
          tap(result => {
            if (!result) {
              this.analyticsService.trackEvent('get_check_on_location_no_check', {
                on_site_location_id: onSiteLocationId
              });
              this.paymentAtTableDispatcher.updateCheck({
                error: {
                  detail: PAT_ERROR_NO_CHECKS_OPEN,
                },
              });
            } else {
              this.paymentAtTableDispatcher.updateCheck({...result, error: null});
              let check = result;
              this.analyticsService.trackEvent('get_check_on_location_success', {
                on_site_location_id: check.onsite_location?.id,
                on_site_location_name: check.onsite_location?.name,
                check_id: check.id,
                check_opened_at: check.opened_at,
                check_shop: check.shop,
                check_final_price: check.final_price,
                check_currency: check.currency,
              });
              const paygreenStatus = this.route.snapshot.queryParams.paygreen_status;
              const transaction = this.route.snapshot.queryParams.transactionId;
              if (
                (paygreenStatus === 'authorized' || paygreenStatus === 'successed') &&
                transaction
              ) {
                this.router.navigate([
                  this.routerHelper.translateRoute(
                    `/${Paths.PayAtTable}/${Paths.PaymentConfirmation}/${transaction}`,
                  ),
                ]);
              }
              if (check?.[CheckKeys.AvailablePaymentMethods]?.length) {
                this.paymentDispatcher.updateMethods(
                  result.available_payment_methods
                    .filter(method => method.valid)
                    .map(method => method.payment_method),
                );
              }
              this.onSiteLocationService
                .subscribeToOnSiteLocationCheck$(result.id)
                .pipe(
                  tap(result => {
                    const _data = String(ObjectUtils.isObject(result) ? result.data : '');
                    this.decryptAES(check?.[CheckKeys.MercurePrivateKey], _data)
                      .pipe(take(1))
                      .subscribe(result => {
                        if (result) {
                          this.paymentAtTableDispatcher.resetCheck();
                          this.paymentAtTableDispatcher.updateCheck(JSON.parse(result));
                        }
                      });
                  }),
                  switchMap(result =>
                    this.pushService.eventSourceError$.pipe(
                      takeUntil(this._destroyerRef),
                      filter(error => !!error), // check if there is an error
                      switchMap(() =>
                        interval(10000).pipe(
                          takeUntil(this._destroyerRef), // start polling every 10 seconds
                          switchMap(() => {
                            const _checkId = String(ObjectUtils.isObject(result) ? result.id : '');
                            return this.onSiteLocationService.getOnSiteLocationCheck$(_checkId);
                          }),
                          takeUntil(this._destroyerRef),
                        ),
                      ),
                    ),
                  ),
                )
                .subscribe();
            }
          }),
        )
        .subscribe();
    }
  }

  decryptAES(base64Key: string, base64Encrypted: string): Observable<string> {
    return from(this.encryptionService.decryptAES(base64Key, base64Encrypted));
  }
}
