import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-kiosk-account-not-found-step',
  template: `
    <div class="flex flex-col items-center justify-center gap-12">
      <h1
        class="w-full text-center text-4xl font-bold"
        [style.color]="accentColor | contentBuilderColor"
      >
        {{
          'como.create-or-link-account.kiosk-account-not-found-step.title'
            | translate: { phoneNumber: phone }
        }}
      </h1>
      <div class="flex w-full flex-col gap-12">
        <app-button-atom
          size="L"
          [label]="
            'como.create-or-link-account.kiosk-account-not-found-step.update-phone-number'
              | translate
          "
          type="kiosk-primary"
          [overrideBgColor]="accentColor"
          (click)="onGoToPhoneStep()"
          [full]="true"
        ></app-button-atom>

        <app-button-atom
          size="L"
          [label]="'como.create-or-link-account.kiosk-account-not-found-step.register' | translate"
          type="kiosk-primary"
          [overrideBgColor]="accentColor"
          (click)="onGoToRegisterStep()"
          [full]="true"
        ></app-button-atom>

        <app-button-atom
          size="L"
          [label]="
            'como.create-or-link-account.kiosk-account-not-found-step.order-without-account'
              | translate
          "
          (click)="completeStep()"
          [overrideBgColor]="accentColor"
          type="kiosk-light"
          [full]="true"
        ></app-button-atom>
      </div>
    </div>
  `,
})
export class KioskAccountNotFoundStep {
  @Output() onComplete = new EventEmitter<void>();
  @Output() goToPhoneStep = new EventEmitter<void>();
  @Output() goToRegisterStep = new EventEmitter<void>();

  @Input() phone = '';
  @Input() accentColor?: IContentBuilderFieldColor;

  user$ = this.authSelector.selectUser;

  constructor(private readonly authSelector: AuthStoreSelector) {}

  completeStep(): void {
    this.onComplete.next();
  }

  onGoToPhoneStep(): void {
    this.goToPhoneStep.next();
  }

  onGoToRegisterStep(): void {
    this.goToRegisterStep.next();
  }
}
