import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { IPaymentMethodDescriptor } from '@core/models/payment.model';

import {
  PaymentBancontactState,
  PaymentButtonState,
  PaymentState,
  PaymentStatusState,
  PaymentTransactionState,
} from './payment.state';
import { PaymentUpdateDataOptions, PaymentUpdateUiOptions } from './payment.model';

export const PAYMENT_STORE_API_ACTIONS = createActionGroup({
  source: 'Dood/API/Payment',
  events: {},
});

export const PAYMENT_STORE_LOCAL_ACTIONS = createActionGroup({
  source: 'Dood/LOCAL/Payment',
  events: {
    // Status
    'Update Status': props<{ status: Partial<PaymentStatusState> }>(),
    'Reset Status': emptyProps(),

    // Button
    'Update Button': props<{ button: Partial<PaymentButtonState> }>(),
    'Reset Button': emptyProps(),

    // Bancontact
    'Update Bancontact': props<{ bancontact: Partial<PaymentBancontactState> }>(),
    'Reset Bancontact': emptyProps(),

    // Transaction
    'Update Transaction': props<{ transaction: Partial<PaymentTransactionState> }>(),
    'Reset Transaction': emptyProps(),

    // Methods
    'Update Methods': props<{ methods: IPaymentMethodDescriptor[] }>(),
    'Reset Methods': emptyProps(),

    // Amount
    'Update Amount': props<{ amount: number | null }>(),
    'Reset Amount': emptyProps(),

    // Error
    'Update Error': props<{ error: string | null }>(),
    'Reset Error': emptyProps(),

    // Misc
    'reset': emptyProps(),
  },
});

export const PAYMENT_STORE_COMPONENT_ACTIONS = createActionGroup({
  source: 'Dood/COMPONENT/Payment',
  events: {
    // State
    'Update State': props<{ changes: Partial<PaymentState> }>(),
    // UI
    'Update UI': props<{ changes: PaymentUpdateUiOptions }>(),
    'Reset UI': emptyProps(),
    // Data
    'Update Data': props<{ changes: PaymentUpdateDataOptions }>(),
    'Reset Data': emptyProps(),
  },
});
