import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-big-footer-body-social-networks-block',
  templateUrl: './big-footer-body-social-networks-block.component.html',
})
export class BigFooterBodySocialNetworksBlockComponent {
  @Input() heading!: string;
  @Input() facebookLink!: string;
  @Input() twitterLink!: string;
  @Input() instagramLink!: string;
  @Input() linkedinLink!: string;

  navigateTo(url: string): void {
    window.open(url, '_blank');
  }
}
