export enum RegistrationStep {
  STEP_PHONE = 'STEP_PHONE',
  STEP_OTP = 'STEP_OTP',
  STEP_AUTHENTICATION = 'STEP_AUTHENTICATION',
  STEP_ACCOUNT_NOT_FOUND = 'STEP_ACCOUNT_NOT_FOUND',
  STEP_KIOSK_CONFIRMATION = 'STEP_KIOSK_CONFIRMATION',
  STEP_KIOSK_REGISTER = 'STEP_KIOSK_REGISTER',
  STEP_KIOSK_REGISTER_CONFIRMATION = 'STEP_KIOSK_REGISTER_CONFIRMATION',
  STEP_KIOSK_ACCOUNT_NOT_FOUND = 'STEP_KIOSK_ACCOUNT_NOT_FOUND',
}
