import { DoodProductModel } from '@core/models/product.model';
import { IAdditionGroup } from '@core/models/shop.model';
import {
  DraftAdditionsGroup,
  ValidityWithAdditionsByGroupsState,
} from '@shared/local-store/cart/util/cart-simple-product-util';
import { SimpleProductWithAdditionsGroupStep } from '../stepper.type';
import {
  ProductWithUIData,
  SimpleProductCartValidity,
} from '@shared/local-store/cart/cart-draft-declinable-product.store';
import { SimpleProductConfigStepperData } from '../simple-product-stepper.store';
import { StepInfo } from '@core/models/stepper.model';

export class SimpleProductCartUtil {
  static calculateSimpleProductStates({
    simpleProductAdditionsByGroups,
    selectedAdditionsGroupId,
    isAdditionGroupValid,
    totalGroupAdditionsQuantity,
    product,
    setSelectedStep,
    currentStep,
  }: {
    simpleProductAdditionsByGroups: IAdditionGroup[];
    selectedAdditionsGroupId: string | undefined;
    isAdditionGroupValid: boolean;
    totalGroupAdditionsQuantity: number;
    product: DoodProductModel | undefined;
    setSelectedStep: (stepsId: StepInfo) => void;
    currentStep: StepInfo | undefined;
  }): SimpleProductWithAdditionsGroupStep | undefined {
    const additionGroupIndex = simpleProductAdditionsByGroups.findIndex(
      additionGroup => additionGroup.id === selectedAdditionsGroupId,
    );
    const additionGroup = simpleProductAdditionsByGroups[additionGroupIndex];
    if (!additionGroup) {
      return undefined;
    }

    const hasMaxQuantity = additionGroup.max_count !== undefined;
    const isMaxCountReached = totalGroupAdditionsQuantity >= (additionGroup.max_count ?? Infinity);
    const canMakeMoreChoices = hasMaxQuantity ? !isMaxCountReached : false;

    const currentStepIndex = simpleProductAdditionsByGroups.indexOf(additionGroup);
    const hasNextStep = currentStepIndex < simpleProductAdditionsByGroups.length - 1;
    const hasPreviousStep = currentStepIndex > 0;
    const previousStepId = simpleProductAdditionsByGroups[currentStepIndex - 1]?.id;
    const nextStepId = simpleProductAdditionsByGroups[currentStepIndex + 1]?.id;

    return {
      type: 'simpleProductWithAdditionsGroup',
      additionGroup,
      product,
      selectedAdditionsGroupId,
      selectedStepId: selectedAdditionsGroupId,
      isValid: isAdditionGroupValid,
      canMakeMoreChoices,
      minStepCount: additionGroup.min_count ?? 0,
      maxStepCount: additionGroup.max_count ?? Infinity,
      count: totalGroupAdditionsQuantity,
      hasNextStep,
      hasPreviousStep,
      previousStepId,
      nextStepId,
      skippable: false, // this is not handled yet, the additions in a group are not preselected yet
      additionsGroups: simpleProductAdditionsByGroups,
      nextStep: hasNextStep
        ? {
            ...currentStep,
            additionsGroupId: nextStepId,
          }
        : undefined,
      previousStep: hasPreviousStep
        ? {
            ...currentStep,
            additionsGroupId: previousStepId,
          }
        : undefined,
      setSelectedStep,
    } satisfies SimpleProductWithAdditionsGroupStep;
  }

  static getAdditionGroupTotalSelected({
    simpleProductAdditionsByGroups,
    selectedAdditionsGroupId,
    selectedAdditionIds,
  }: {
    simpleProductAdditionsByGroups: IAdditionGroup[];
    selectedAdditionsGroupId: string | undefined;
    selectedAdditionIds: string[];
  }) {
    if (!selectedAdditionsGroupId) {
      return 0;
    }
    const selectedAdditionsGroup = simpleProductAdditionsByGroups.find(
      group => group.id === selectedAdditionsGroupId,
    );
    return selectedAdditionIds.filter(selectedAdditionId => {
      return selectedAdditionsGroup?.items.some(item => item.id === selectedAdditionId);
    }).length;
  }

  static isSelectedGroupValid({
    selectedAdditionsGroupId,
    cartValidity,
  }: {
    selectedAdditionsGroupId: string | undefined;
    cartValidity: ValidityWithAdditionsByGroupsState;
  }) {
    if (!selectedAdditionsGroupId) {
      return false;
    }

    return cartValidity.additionsByGroupsValidity[selectedAdditionsGroupId].isValid;
  }

  static getSimpleProductCartStepperData({
    selectedSimpleProductId,
    simpleProduct,
    cartValidity,
    additionGroups,
    selectedAdditionIds,
  }: {
    selectedSimpleProductId: string | undefined;
    simpleProduct: ProductWithUIData | undefined;
    cartValidity: SimpleProductCartValidity | undefined;
    additionGroups: DraftAdditionsGroup[] | undefined;
    selectedAdditionIds: string[] | undefined;
  }): SimpleProductConfigStepperData | undefined {
    const _selectedSimpleProductId = selectedSimpleProductId;
    if (!_selectedSimpleProductId) {
      return undefined;
    }
    if (!simpleProduct) {
      return undefined;
    }
    if (!cartValidity) {
      return undefined;
    }
    if (!selectedAdditionIds) {
      return undefined;
    }
    return {
      additionsByGroups: additionGroups ?? [],
      product: simpleProduct,
      selectedAdditionIds,
      cartValidity,
    } satisfies SimpleProductConfigStepperData;
  }
}
