import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { map, take, takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { CartService } from '@core/services/cart/cart.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { CartStoreDispatcher } from '@common/dispatchers/cart.dispatcher';

import {
  CartItemKeys,
  ReconstructedCartItemKeys,
  ReconstructedCartKeys,
} from '@config/keys/cart.keys';
import { DoodReconstructedCart, DoodReconstructedCartItem } from '@core/models/cart.model';

import { ItemEditModalComponent } from '@shared/modals/item-edit-modal/item-edit-modal.component';

@Component({
  selector: 'app-item-edit-page',
  template: ``,
})
export class ItemEditPageComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<boolean>();

  constructor(
    private readonly cartService: CartService,
    private cartDispatcher: CartStoreDispatcher,
    private readonly modalsService: ModalsService,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.params.pipe(takeUntil(this.destroyed$)).subscribe(params => {
      this.loadCartItem(parseInt(params.itemId, 10));
    });
  }

  ngOnInit(): void {
    this.modalsService.open(ItemEditModalComponent.handle);
  }

  ngOnDestroy(): void {
    this.modalsService.close(ItemEditModalComponent.handle);
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private loadCartItem(itemId: number): void {
    this.cartService.loadShopsOfCart$().pipe(takeUntil(this.destroyed$)).subscribe();
    this.cartService
      .getCartLineItems$()
      .pipe(
        takeUntil(this.destroyed$),
        take(1),
        map(reconstructedCart => this.lookForItemInCart(itemId, reconstructedCart)),
        map(cartItem => {
          if (cartItem) {
            this.cartDispatcher.updateStore({ edit_item: cartItem });
          }
        }),
      )
      .subscribe();
  }

  private lookForItemInCart(
    itemId: number,
    reconstructedCart: DoodReconstructedCart[],
  ): null | DoodReconstructedCartItem {
    for (const cartOfShop of reconstructedCart) {
      const cartItem = cartOfShop[ReconstructedCartKeys.Items].find(
        elem => elem[ReconstructedCartItemKeys.Item][CartItemKeys.Id] === itemId,
      );
      if (cartItem) {
        return cartItem;
      }
    }

    return null;
  }
}
