import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DoodProductModel } from '@core/models/product.model';
import {
  ICartApiResponseCategoryProduct,
  ICartApiResponseDeclinableCategory,
} from '@core/models/cart-api.model';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-overview-cart-item-atom',
  templateUrl: './overview-cart-item-atom.component.html',
})
export class OverviewCartItemAtomComponent {
  @Input() id!: string | number;
  @Input() name!: string;
  @Input() quantity!: number;
  @Input() childItems!: DoodProductModel[];
  @Input() groupAdditionsItems!: ICartApiResponseCategoryProduct[];
  @Input() groupCompoundItems!: ICartApiResponseDeclinableCategory[];
  @Input() price!: number;
  @Input() formatPrice = false;
  @Input() isOwner = true;
  @Input() displayRemoveIcon = false;
  @Input() isBasketOrder = false;
  @Input() isProductExists = true;
  @Input() textColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() productNameColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() productDetailsColor: IContentBuilderFieldColor = {
    value: 'neutral-600',
    type: ColorFieldTypesValues.Palette,
  };

  @Output() updateItem = new EventEmitter<string | number>();
  @Output() removeItem = new EventEmitter<string | number>();
}
