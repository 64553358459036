<app-modal-filler-atom
  (click)="closeModal()"
  [index]="(index$ | async) ?? 0"
></app-modal-filler-atom>
<div
  class="fixed right-0 left-0 shadow overflow-y-auto"
  [ngClass]="
    isMobile
      ? 'bottom-0 max-h-85-screen max-w-screen-sm'
      : 'flex justify-center items-center w-full h-full top-0 left-0'
  "
  [style.z-index]="((index$ | async) ?? 0) + 50"
>
  <div
    class="bg-white p-5"
    [ngClass]="isMobile ? 'rounded-t-3xl' : 'rounded-3xl min-w-448'"
    [style.z-index]="((index$ | async) ?? 0) + 50"
  >
    <div class="flex justify-between items-center pb-7">
      <h2 class="font-accent font-bold text-3xs leading-8">
        {{ 'pay-at-table.divide-amount-modal.title' | translate }}
      </h2>
    </div>
    <div class="flex justify-between py-3">
      <span class="font-accent font-bold text-md leading-6">{{
        'pay-at-table.divide-amount-modal.total' | translate
      }}</span>
      <span class="font-accent font-bold text-base leading-6"
        ><app-price-atom
          [amount]="finalPrice"
          [format]="true"
        ></app-price-atom
      ></span>
    </div>
    <div class="flex py-3">
      <span class="font-accent font-bold text-md leading-6">{{
        'pay-at-table.divide-amount-modal.nb-of-parts' | translate
      }}</span>
    </div>
    <div class="flex justify-center py-3">
      <app-counter-atom
        [size]="'L'"
        [count]="count"
        [type]="'light'"
        [min]="1"
        (countChange)="onChangeCount($event)"
      ></app-counter-atom>
    </div>
    <div class="flex justify-between py-3">
      <span class="font-accent font-bold text-md leading-6">{{
        'pay-at-table.divide-amount-modal.part-to-pay' | translate
      }}</span>
      <span class="font-accent font-bold text-base leading-6"
        ><app-price-atom
          [amount]="totalAmount"
          [format]="true"
        ></app-price-atom
      ></span>
    </div>
    <div class="pt-2 flex justify-between items-center">
      <div class="mr-2 xm:pr-1 w-full xm:w-1/2">
        <app-button-atom
          size="M"
          [label]="'pay-at-table.divide-amount-modal.cancel' | translate"
          color="primary"
          (click)="closeModal()"
          type="light"
          [full]="true"
        ></app-button-atom>
      </div>
      <div class="xm:pl-1 w-full xm:w-1/2">
        <app-button-atom
          size="M"
          [label]="'pay-at-table.divide-amount-modal.validate' | translate"
          (click)="validate()"
          type="primary"
          [full]="true"
        ></app-button-atom>
      </div>
    </div>
  </div>
</div>
