import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Capacitor } from '@capacitor/core';
import { Injectable, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Geolocation, Position } from '@capacitor/geolocation';

import { GEOLOCATION_ERROR } from '@config/errors.config';
import { PlaceResult } from '@core/models/google-maps.model';
import { GooglePlacesState } from '@common/states/google-places.state';

import { GoogleMapsApiHelper } from '@common/helpers/google-maps-api.helper';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';
import { AuthStoreDispatcher } from '@common/dispatchers/authentication.dispatcher';

@Injectable({
  providedIn: 'root',
})
export class GoogleApiService {
  constructor(
    private ngZone: NgZone,
    private googleMapsHelper: GoogleMapsApiHelper,
    private authDispatcher: AuthStoreDispatcher,
    private googlePlacesState: GooglePlacesState,
    private readonly translateService: TranslateService,
    private marketplaceSelector: MarketplaceStoreSelector,
  ) {}

  getAutoCompleteAddress(input: HTMLInputElement): Subject<PlaceResult> {
    const placeChanged$ = new Subject<PlaceResult>();
    this.googleMapsHelper.initialize();
    this.googleMapsHelper.onLoaded.pipe(filter(x => x)).subscribe(isLoaded => {
      if (isLoaded) {
        const autocomplete = new google.maps.places.Autocomplete(input, {
          componentRestrictions: {
            country: this.marketplaceSelector.marketplace.country || 'fr',
          },
          fields: ['geometry', 'formatted_address', 'address_components'],
        });
        autocomplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
            const place = autocomplete.getPlace() as PlaceResult;

            if (place.geometry === undefined || place.geometry === null) {
              return;
            }
            placeChanged$.next(place);
            this.googlePlacesState.update(place);
            return place;
          });
        });
      }
    });
    return placeChanged$;
  }

  async getNativePosition(): Promise<Position> {
    return new Promise((resolve, reject) => {
      Geolocation.getCurrentPosition().then(
        resp => {
          resolve(resp);
        },
        () => {
          reject();
        },
      );
    });
  }

  geolocateUser$(): Subject<PlaceResult> {
    let location;
    const onUserLocated$ = new Subject<PlaceResult>();

    if (Capacitor.isNativePlatform()) {
      this.getNativePosition().then(r => {
        location = new google.maps.LatLng(r.coords.latitude, r.coords.longitude);
        const request = { location: location };

        const geocoder = new google.maps.Geocoder();

        geocoder.geocode(request, (results, status) => {
          this.ngZone.run(() => {
            const result = results?.[0];
            if (status !== google.maps.GeocoderStatus.OK || !result) {
              onUserLocated$.error('Unable to geolocate user');
            }
            if (result) {
              onUserLocated$.next(result);
            }
          });
        });
      });
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          const geocoder = new google.maps.Geocoder();
          location = new google.maps.LatLng(latitude, longitude);
          const request = { location: location };
          geocoder.geocode(request, (results, status) => {
            this.ngZone.run(() => {
              const result = results?.[0];
              if (status !== google.maps.GeocoderStatus.OK || !result) {
                onUserLocated$.error('Unable to geolocate user');
              }
              if (result) {
                onUserLocated$.next(result);
              }
            });
          });

          this.authDispatcher.updatePermissions({
            geolocation: true,
          });
        },
        error => {
          switch (error.code) {
            case 1:
              this.authDispatcher.updatePermissions({
                geolocation: false,
              });
              break;
          }
          onUserLocated$.error(this.translateService.instant(GEOLOCATION_ERROR));
        },
      );
    } else {
      onUserLocated$.error('Geolocation is not supported by this browser!');
    }

    return onUserLocated$;
  }
}
