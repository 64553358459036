import { Component, OnDestroy } from '@angular/core';

import { NoConnectionModalService } from '@core/services/no-connection-modal/no-connection-modal.service';

@Component({
  selector: 'app-no-connection-modal',
  templateUrl: './no-connection-modal.component.html',
})
export class NoConnectionModalComponent implements OnDestroy {
  static handle = 'no-connection-modal';
  retryInProgress$ = this.noConnectionModalService.getRetryInProgress$();

  constructor(private readonly noConnectionModalService: NoConnectionModalService) {}

  ngOnDestroy(): void {
    this.noConnectionModalService.setRetryInProgress(false);
  }

  reload(): void {
    window.location.reload();
  }
}
