import { Injectable } from '@angular/core';
import { finalize, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { fromEvent, merge, throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

import { DOOD_API } from '@config/ws.config';
import { DoodOrderModel } from '@store/order/order.model';

@Injectable({
  providedIn: 'root',
})
export class AuresEtkApiService {
  static ERROR_STATUS = 0;
  static STATUS_TICKET_SOLDE = 1002;

  private etkApi = 'http://localhost:5001';

  constructor(private readonly httpClient: HttpClient) {}

  abortTransaction$(): Observable<any> {
    return this.httpClient.get<any>(`${this.etkApi}/api/v1/AbortCBTransaction`);
  }

  createPaymentIntent$(amount: number, orderNumber?: string): Observable<any> {
    orderNumber = orderNumber ?? 'UNKNOWN';
    const normalizedAmount = Math.round(amount) / 100;
    const terminalEventSource = new EventSource(
      `${this.etkApi}/api/v1/ReglementCommande?unitaire=${normalizedAmount}&numCommande=${orderNumber}`,
    );

    return merge(
      fromEvent(terminalEventSource, 'error'),
      fromEvent(terminalEventSource, 'usermessage'),
      fromEvent(terminalEventSource, 'result'),
    ).pipe(
      map((event: any) => {
        if (event.type === 'error') {
          terminalEventSource.close();
          throw throwError(() => 'Impossible de communiquer avec le terminal de paiement.');
        }
        return JSON.parse(event?.data);
      }),
      finalize(() => {
        console.log('[ETK] Close event source');
        terminalEventSource.close();
      }),
    );
  }

  changeOrderStatusToPayment$(orderId: string, paymentRef: string): Observable<DoodOrderModel> {
    return this.httpClient.post<DoodOrderModel>(`${DOOD_API}/aures-etk-terminal/payment`, {
      order_id: orderId,
      terminal_payment_ref: paymentRef,
    });
  }

  printDocument$(document: any): Observable<any> {
    return this.httpClient.post<any>(`${this.etkApi}/api/v1/PrintDocument`, document);
  }
}
