<div
  class="bg-highlight-50 rounded-m p-3.5 flex text-neutral-800"
  (click)="clickMessage.emit()"
>
  <p
    class="flex-1 mx-4 text-lg text-neutral-800"
    [innerHTML]="message | translate"
  ></p>
  <span>
    <i
      class="icon icon-cross cursor-pointer text-2xs"
      (click)="$event.preventDefault(); closeMessage.emit()"
    ></i>
  </span>
</div>
