import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { DoodAnchorModel } from './anchor.model';
import { DoodApiError } from '@shared/interfaces/error.interface';

export interface AnchorState extends EntityState<DoodAnchorModel> {
  isLoaded: boolean;
  isLoading: boolean;
  error: DoodApiError | null;
}

export const ANCHOR_STORE_KEY = 'Anchors';

export const ANCHOR_STORE_ADAPTER = createEntityAdapter<DoodAnchorModel>({
  selectId: _item => _item.handle,
});

export const ANCHOR_STORE_INITIAL_STATE: AnchorState = ANCHOR_STORE_ADAPTER.getInitialState({
  error: null,
  isLoaded: false,
  isLoading: false,
});
