import { Component, Input, OnDestroy } from '@angular/core';

import { AuthStoreSelector } from '@common/selectors/authentication.selector';
import { DoodAuthenticationStep } from '@store/authentication/authentication.model';
import { AuthStoreDispatcher } from '@common/dispatchers/authentication.dispatcher';
import { AuthenticationScreenService } from '@core/services/authentication-screen/authentication-screen.service';
import { AuthenticationUserJourneyType } from '@shared/blocks/authentication-block/authentication-user-journey-type';

@Component({
  selector: 'app-authentication-block',
  templateUrl: './authentication-block.component.html',
})
export class AuthenticationBlockComponent implements OnDestroy {
  @Input() userJourneyType = AuthenticationUserJourneyType.ASK_EMAIL_FIRST;
  @Input() legalCheckboxLabel?: string;
  @Input() optinCheckboxLabel?: string;

  @Input() firstStepHeading!: string;
  @Input() firstStepLoginButton!: string;
  @Input() firstStepSignupButton!: string;
  @Input() loginStepHeading!: string;
  @Input() signupStepHeading!: string;
  @Input() signupStepSubheading!: string;
  @Input() signupStepDisplayFirstname = true;
  @Input() signupStepFirstnameLabel!: string;
  @Input() signupStepFirstnameHelp!: string;
  @Input() signupStepDisplayLastname = true;
  @Input() signupStepLastnameLabel!: string;
  @Input() signupStepLastnameHelp!: string;
  @Input() signupStepEmailLabel!: string;
  @Input() signupStepEmailHelp!: string;
  @Input() signupStepDisplayPhone = true;
  @Input() signupStepPhoneLabel!: string;
  @Input() signupStepPhoneHelp!: string;
  @Input() signupStepPasswordLabel!: string;
  @Input() signupStepPasswordHelp!: string;
  @Input() signupFooter?: any;
  @Input() authenticationProviderGoogle = true;
  @Input() authenticationProviderFacebook = true;
  @Input() authenticationProviderApple = false;

  steps = DoodAuthenticationStep;

  authenticationStep$ = this.authSelector.selectStatusStep;

  constructor(
    private authSelector: AuthStoreSelector,
    private authDispatcher: AuthStoreDispatcher,
    private readonly authenticationScreenService: AuthenticationScreenService,
  ) {
    this.authenticationScreenService.goToStep(DoodAuthenticationStep.login);
  }

  ngOnDestroy(): void {
    this.authDispatcher.updateStatus({ step: null });
  }
}
