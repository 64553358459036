import { DoodOpenCheck } from '@core/models/check.model';

import { DoodPaymentAtTableAmountType } from './payment-at-table.enum';
import { createHydratedAdapter, HydrationState } from '@store/_abstract/hydrated';

export interface PaymentAtTableCheckError {
  detail: string;
}

export interface PaymentAtTableCheckState extends DoodOpenCheck {
  error: PaymentAtTableCheckError | null;
}

export interface PaymentAtTableChooseState {
  amount: number;
  tip: number | null;
  products: string[];
  error: string | null;
  parts: number | null;
  type: DoodPaymentAtTableAmountType;
}

// TODO: Check if products should be stored here
export interface PaymentAtTableState extends HydrationState {
  check: PaymentAtTableCheckState;
  choose: PaymentAtTableChooseState;
}

export const PAT_STORE_KEY = 'PaymentAtTable';

export const PAT_STORE_INITIAL_AMOUNT_STATE = 0;

export const PAT_STORE_INITIAL_PRODUCTS_STATE = new Array<string>();

export const PAT_STORE_INITIAL_TYPE_STATE = DoodPaymentAtTableAmountType.Total;

export const PAT_STORE_INITIAL_CHOOSE_STATE: PaymentAtTableChooseState = {
  tip: null,
  error: null,
  parts: null,
  type: PAT_STORE_INITIAL_TYPE_STATE,
  amount: PAT_STORE_INITIAL_AMOUNT_STATE,
  products: PAT_STORE_INITIAL_PRODUCTS_STATE,
};

export const PAT_STORE_INITIAL_CHECK_STATE: PaymentAtTableCheckState = {
  id: '',
  items: [],
  error: null,
  currency: '',
  closed_at: '',
  opened_at: '',
  final_price: 0,
  transactions: [],
  outstanding_amount: 0,
  mercure_private_key: '',
  available_payment_methods: [],
  marketplace: {
    id: '',
    name: '',
    current_user_roles: [],
  },
  shop: {
    id: '',
    name: '',
    current_user_roles: [],
  },
  onsite_location: {
    id: '',
    name: '',
  },
};

export const PAT_STORE_HYDRATION_ADAPTER = createHydratedAdapter();

export const PAT_STORE_INITIAL_STATE: PaymentAtTableState =
  PAT_STORE_HYDRATION_ADAPTER.getInitialState({
    check: PAT_STORE_INITIAL_CHECK_STATE,
    choose: PAT_STORE_INITIAL_CHOOSE_STATE,
  });
