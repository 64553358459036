import {
  catchError,
  delay,
  filter,
  map,
  retry,
  retryWhen,
  switchMap,
  take,
  takeUntil,
  takeWhile,
  tap,
} from 'rxjs/operators';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { DoodApiError } from '@shared/interfaces/error.interface';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';

import { CheckKeys } from '@config/keys/check.keys';

import { Paths } from '@config/paths.config';
import { UNABLE_TO_CREATE_PAYMENT } from '@config/errors.config';
import { IPaymentMethod, PaymentMethodHandles } from '@config/payment-methods.config';

import {
  IAdyenPaymentIntent,
  ILyfPayPaymentIntent,
  IPaygreenPaymentIntent,
  IStripePaymentIntent,
  IYavinPaymentIntent,
} from '@core/models/payment.model';
import { DoodOpenCheck, DoodOpenCheckTransaction } from '@core/models/check.model';

import { PaymentStoreDispatcher } from '@common/dispatchers/payment.dispatcher';
import { OnSiteLocationStoreSelector } from '@common/selectors/on-site-location.selector';
import { PaymentAtTableStoreSelector } from '@common/selectors/payment-at-table.selector';
import { PaymentAtTableStoreDispatcher } from '@common/dispatchers/payment-at-table.dispatcher';

import { PaymentService } from '@core/services/payment/payment.service';
import { EdenredService } from '@core/services/edenred/edenred.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { OnSiteLocationsService } from '@core/services/on-site-locations/on-site-locations.service';
import { A_LOCK_FAILED_DOCUMENT } from '@config/values/pat.values';

@Component({
  selector: 'app-pat-payment-page',
  templateUrl: './pat-payment-page.component.html',
})
export class PatPaymentPageComponent extends DestroyerBase implements OnInit {
  isTransactionError = false;
  paymentMethod!: IPaymentMethod;
  transaction$!: Observable<DoodOpenCheckTransaction | null>;

  constructor(
    private readonly router: Router,
    private readonly edenredService: EdenredService,
    private readonly paymentService: PaymentService,
    private paymentDispatcher: PaymentStoreDispatcher,
    private readonly routerHelper: RouterHelperService,
    private readonly translateService: TranslateService,
    private onSiteLocationSelector: OnSiteLocationStoreSelector,
    private paymentAtTableSelector: PaymentAtTableStoreSelector,
    private paymentAtTableDispatcher: PaymentAtTableStoreDispatcher,
    private readonly onSiteLocationsService: OnSiteLocationsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initializeOpenCheck();
    this.setupPaymentHandling();
    this.setPaymentAmount();
  }

  private initializeOpenCheck(): void {
    const check = this.paymentAtTableSelector.check;
    const { id: onSiteLocationId } = this.onSiteLocationSelector.settings;
    if (!check?.[CheckKeys.Id] && onSiteLocationId) {
      this.onSiteLocationsService
        .loadOnSiteLocationOpenCheck$(onSiteLocationId)
        .pipe(take(1))
        .subscribe(check => {
          if (check) {
            this.paymentAtTableDispatcher.updateCheck({ ...check, error: null });
          }
        });
    }
  }

  private setupPaymentHandling(): void {
    this.paymentService.pay$
      .pipe(
        takeUntil(this._destroyerRef),
        take(1),
        tap(() => this.handlePayment()),
      )
      .subscribe();
  }

  onSelectedPaymentMethod(paymentMethod: IPaymentMethod): void {
    if (!paymentMethod?.handle) {
      return;
    }

    this.paymentService.paymentIntent$.next(null);
    this.paymentService.paymentPaygreenIntent$.next(null);
    this.paymentService.paymentYavinIntent$.next(null);
    this.paymentService.paymentLyfpayIntent$.next(null);
    this.paymentService.paymentAdyenIntent$.next(null);

    this.paymentMethod = paymentMethod;
    this.isEdenredPayment(paymentMethod)
      ? this.handleEdenredPayment(paymentMethod)
      : this.handleOtherPayments(paymentMethod);
  }

  private getCheckTransaction$(
    paymentMethod: IPaymentMethod,
    amount?: number,
  ): Observable<DoodOpenCheckTransaction | null> {
    let retries = 0;
    const MAX_RETRIES = 10;
    const RETRY_DELAY = 1000; // Delay in milliseconds

    return this.paymentAtTableSelector.selectCheck.pipe(
      take(1),
      switchMap(check => {
        if (!check?.id) {
          return of([null, null]);
        }

        return combineLatest([of(check), this.paymentAtTableSelector.selectChoose]);
      }),
      take(1),
      switchMap(([check, paymentAtTable]) => {
        if (!check || !paymentAtTable) return of(null);
        const data = {
          payment_method: paymentMethod.handle,
          amount: amount ?? paymentAtTable?.amount + (paymentAtTable?.tip || 0),
          type: paymentMethod.handle,
          ...(paymentAtTable?.products?.length &&
            paymentAtTable?.type === 'products' && {
              items: [...paymentAtTable?.products],
            }),
          ...(paymentAtTable?.tip && { tip: paymentAtTable?.tip }),
        };
        return this.onSiteLocationsService.createCheckTransaction$(check.id, data);
      }),
      retry({
        delay: errors =>
          errors.pipe(
            delay(RETRY_DELAY),
            tap((error: DoodApiError) => {
              if (error?.message !== A_LOCK_FAILED_DOCUMENT || retries++ >= MAX_RETRIES) {
                this.isTransactionError = true;
                throw error;
              }
            }),
          ),
      }),
      catchError(error => {
        return throwError(() => error);
      }),
      tap(transaction => {
        if (transaction) {
          this.transaction$ = of(transaction);
          this.getOnsiteLocationCheck(of(transaction));
          this.paymentDispatcher.updateData({
            amount: transaction.amount,
            transaction: {
              id: transaction.id,
            },
          });
        }
      }),
    );
  }

  private getPaymentIntentFromTransaction$(
    transaction$: Observable<DoodOpenCheckTransaction | null>,
  ): Observable<IStripePaymentIntent | IPaygreenPaymentIntent | IYavinPaymentIntent | null> {
    return transaction$.pipe(
      tap(_ => {
        this.paymentDispatcher.updateUI({
          error: null,
          button: {
            isEnabled: false,
            isVisible: true,
            text: 'payment.button',
          },
          status: {
            isLoading: false,
            isInProgress: false,
            isRetryInProgress: false,
          },
        });
      }),
      switchMap(transaction => {
        if (!transaction) {
          return of(null);
        }
        return this.onSiteLocationsService.getOnSiteLocationCheckTransactionsPayment$(
          transaction.id,
        );
      }),
      catchError(error => {
        this.paymentDispatcher.updateUI({
          error: this.translateService.instant('payment.payment-error'),
          button: {
            isEnabled: false,
          },
          status: {
            isLoading: false,
            isInProgress: false,
          },
        });
        return throwError(() => error);
      }),
    );
  }

  private executePaymentTransaction(paymentIntent$: Observable<IStripePaymentIntent | null>): void {
    paymentIntent$
      .pipe(
        tap(paymentIntent => {
          if (paymentIntent) {
            this.paymentDispatcher.updateAmount(paymentIntent.payment_intent_amount);
            this.paymentService.paymentIntent$.next(paymentIntent);
          }
        }),
        retry({
          delay: errors =>
            errors.pipe(
              takeWhile((error: any) => error?.error?.detail !== UNABLE_TO_CREATE_PAYMENT),
              delay(1000),
              take(10),
            ),
        }),
        catchError(err => {
          return throwError(() => err);
        }),
      )
      .subscribe();
  }

  private getOnsiteLocationCheck(transaction$: Observable<DoodOpenCheckTransaction | null>): void {
    transaction$
      .pipe(
        take(1),
        switchMap(transaction => {
          const check = this.paymentAtTableSelector.check;

          if (!transaction && !check?.[CheckKeys.Id]) {
            return of(null);
          }

          this.setRedirectAfterPaymentUrl({ id: transaction?.[CheckKeys.Id] as string });
          return this.onSiteLocationsService.getOnSiteLocationCheck$(check?.[CheckKeys.Id]);
        }),
        takeUntil(this._destroyerRef),
      )
      .subscribe();
  }

  private setRedirectAfterPaymentUrl({ id }: { id: string }): void {
    this.paymentService.redirectUrl$.next(`${Paths.PayAtTable}/${Paths.PaymentConfirmation}/${id}`);
  }

  private handlePayment(): void {
    if (!this.paymentMethod) {
      return;
    }
    if (this.isEdenredPayment(this.paymentMethod)) {
      this.paymentDispatcher.updateUI({
        error: null,
        button: {
          isEnabled: false,
        },
        status: {
          isLoading: true,
          isInProgress: true,
        },
      });

      this.transaction$
        .pipe(
          take(1),
          switchMap(transaction => {
            if (!transaction) {
              return of(null);
            }
            return this.onSiteLocationsService.getOnSiteLocationCheckTransactionsPayment$(
              transaction.id,
            );
          }),
          catchError(error => {
            this.paymentDispatcher.updateUI({
              error: this.translateService.instant('payment.payment-error'),
              button: {
                isEnabled: false,
              },
              status: {
                isLoading: false,
                isInProgress: false,
              },
            });
            return throwError(() => error);
          }),
          switchMap(_ => {
            return this.paymentService.redirectUrl$;
          }),
          takeUntil(this._destroyerRef),
          tap(redirectUrl => {
            this.router.navigate([this.routerHelper.translateRoute(`${redirectUrl}`)]);
          }),
        )
        .subscribe();
    }
  }

  private isEdenredPayment(paymentMethod: IPaymentMethod): boolean {
    return paymentMethod.handle === PaymentMethodHandles.Edenred;
  }

  private isPaygreenPayment(paymentMethod: IPaymentMethod): boolean {
    return (
      paymentMethod.handle === PaymentMethodHandles.PaygreenTrd ||
      paymentMethod.handle === PaymentMethodHandles.PaygreenRestoflash ||
      paymentMethod.handle === PaymentMethodHandles.PaygreenLunchr
    );
  }

  private isYavinPayment(paymentMethod: IPaymentMethod): boolean {
    return paymentMethod.handle === PaymentMethodHandles.Yavin;
  }

  private isLyfPayPayment(paymentMethod: IPaymentMethod): boolean {
    return paymentMethod.handle === PaymentMethodHandles.LyfPay;
  }

  private isAdyenPayment(paymentMethod: IPaymentMethod): boolean {
    return (
      paymentMethod.handle === PaymentMethodHandles.AdyenCB ||
      paymentMethod.handle === PaymentMethodHandles.AdyenApplePay ||
      paymentMethod.handle === PaymentMethodHandles.AdyenGooglePay
    );
  }

  private handleOtherPayments(paymentMethod: IPaymentMethod): void {
    const checkTransaction$ = this.getCheckTransaction$(paymentMethod);
    const paymentIntent$ = this.getPaymentIntentFromTransaction$(checkTransaction$);

    if (this.isPaygreenPayment(paymentMethod)) {
      this.executePaygreenPaymentTransaction(
        paymentIntent$ as Observable<IPaygreenPaymentIntent | null>,
      );
      return;
    }

    if (this.isYavinPayment(paymentMethod)) {
      this.executeYavinPaymentTransaction(paymentIntent$ as Observable<IYavinPaymentIntent | null>);
      return;
    }

    if (this.isAdyenPayment(paymentMethod)) {
      this.executeAdyenPaymentTransaction(paymentIntent$ as Observable<IAdyenPaymentIntent | null>);
      return;
    }

    if (this.isLyfPayPayment(paymentMethod)) {
      this.executeLyfPayPaymentTransaction(
        paymentIntent$ as Observable<ILyfPayPaymentIntent | null>,
      );
      return;
    }

    this.executePaymentTransaction(paymentIntent$ as Observable<IStripePaymentIntent | null>);
  }

  private handleEdenredPayment(paymentMethod: IPaymentMethod): void {
    this.paymentService.isTransactionMandatory$.next(true);
    if (this.hasTransactionInUrl()) {
      this.processEdenredTransaction();
    } else {
      this.getCheckTransaction$(paymentMethod).pipe(take(1)).subscribe();
    }
  }

  private hasTransactionInUrl(): boolean {
    const parsedUrl = this.router.parseUrl(this.router.url);
    return !!parsedUrl.queryParams?.transaction;
  }

  private processEdenredTransaction(): void {
    this.paymentAtTableSelector.selectCheck
      .pipe(
        filter(this.hasValidCheck),
        take(1),
        switchMap(check => this.retrieveTransactionAmount$(check, this.paymentMethod)),
        filter((transaction): transaction is DoodOpenCheckTransaction => transaction !== null),
        switchMap(transaction => this.verifyUserLoginAndPassTransaction$(transaction)),
        takeWhile(() => !this.isEdenredPaymentMethod()),
        switchMap(transaction => this.getPaymentIntentFromTransaction$(of(transaction))),
        catchError((error: DoodApiError) => this.handlePaymentError(error)),
        tap(paymentIntent =>
          this.executePaymentTransaction(of(paymentIntent as IStripePaymentIntent | null)),
        ),
      )
      .subscribe();
  }

  private hasValidCheck(check: DoodOpenCheck): boolean {
    return !!check?.[CheckKeys.Id];
  }

  private retrieveTransactionAmount$(
    check: DoodOpenCheck,
    paymentMethod: IPaymentMethod,
  ): Observable<DoodOpenCheckTransaction | null> {
    if (!check?.[CheckKeys.Id]) {
      return of(null);
    }

    const parsedUrl = this.router.parseUrl(this.router.url);
    const transactionAmount = check?.[CheckKeys.Transactions]?.find(
      t => t.id === parsedUrl.queryParams?.transaction,
    )?.amount;

    return this.getCheckTransaction$(paymentMethod, transactionAmount).pipe(take(1));
  }

  private verifyUserLoginAndPassTransaction$(
    transaction: DoodOpenCheckTransaction,
  ): Observable<DoodOpenCheckTransaction> {
    return this.edenredService.getUser$().pipe(
      takeWhile(userResponse => !!userResponse.logged_in),
      map(() => transaction),
    );
  }

  private isEdenredPaymentMethod(): boolean {
    return this.paymentMethod.handle === PaymentMethodHandles.Edenred;
  }

  private handlePaymentError(error: DoodApiError): Observable<never> {
    this.paymentDispatcher.updateUI({
      error: this.translateService.instant('payment.payment-error'),
      button: {
        isEnabled: false,
      },
      status: {
        isLoading: false,
        isInProgress: false,
      },
    });
    return throwError(() => error);
  }

  private executePaygreenPaymentTransaction(
    paymentIntent$: Observable<IPaygreenPaymentIntent | null>,
  ): void {
    paymentIntent$
      .pipe(
        tap(paymentIntent => {
          this.paymentService.paymentPaygreenIntent$.next(paymentIntent);
          if (paymentIntent?.payment_page_url) {
            this.paymentDispatcher.updateUI({
              error: null,
              button: {
                isEnabled: true,
                isVisible: true,
                text: 'payment.button',
              },
              status: {
                isLoading: false,
                isInProgress: false,
                isRetryInProgress: false,
              },
            });
          }
        }),
        retryWhen(errors =>
          errors.pipe(
            takeWhile(error => error?.error?.detail !== UNABLE_TO_CREATE_PAYMENT),
            delay(1000),
            take(10),
          ),
        ),
        catchError(err => {
          return throwError(() => err);
        }),
      )
      .subscribe();
  }

  private executeYavinPaymentTransaction(
    paymentIntent$: Observable<IYavinPaymentIntent | null>,
  ): void {
    paymentIntent$
      .pipe(
        tap(paymentIntent => {
          this.paymentService.paymentYavinIntent$.next(paymentIntent);
          this.paymentService.triggerPayment();
          if (paymentIntent?.payment_page_url) {
            this.paymentDispatcher.updateUI({
              error: null,
              button: {
                isEnabled: true,
                isVisible: true,
                text: 'payment.button',
              },
              status: {
                isLoading: false,
                isInProgress: false,
                isRetryInProgress: false,
              },
            });
          }
        }),
        retryWhen(errors =>
          errors.pipe(
            takeWhile(error => error?.error?.detail !== UNABLE_TO_CREATE_PAYMENT),
            delay(1000),
            take(10),
          ),
        ),
        catchError(err => {
          return throwError(() => err);
        }),
      )
      .subscribe();
  }

  private executeLyfPayPaymentTransaction(
    paymentIntent$: Observable<ILyfPayPaymentIntent | null>,
  ): void {
    paymentIntent$
      .pipe(
        tap(paymentIntent => {
          this.paymentService.paymentLyfpayIntent$.next(paymentIntent);
          if (paymentIntent?.payment_page_url) {
            this.paymentDispatcher.updateUI({
              error: null,
              button: {
                isEnabled: true,
                isVisible: true,
                text: 'payment.button',
              },
              status: {
                isLoading: false,
                isInProgress: false,
                isRetryInProgress: false,
              },
            });
          }
        }),
        retryWhen(errors =>
          errors.pipe(
            takeWhile(error => error?.error?.detail !== UNABLE_TO_CREATE_PAYMENT),
            delay(1000),
            take(10),
          ),
        ),
        catchError(err => {
          return throwError(() => err);
        }),
      )
      .subscribe();
  }

  private executeAdyenPaymentTransaction(
    paymentIntent$: Observable<IAdyenPaymentIntent | null>,
  ): void {
    paymentIntent$
      .pipe(
        takeUntil(this._destroyerRef),
        tap(paymentIntent => {
          this.paymentService.paymentAdyenIntent$.next(paymentIntent);
        }),
        retryWhen(errors =>
          errors.pipe(
            takeWhile(error => error?.error?.detail !== UNABLE_TO_CREATE_PAYMENT),
            delay(1000),
            take(10),
          ),
        ),
        catchError(err => {
          return throwError(() => err);
        }),
      )
      .subscribe();
  }

  private setPaymentAmount(): void {
    const { amount, tip } = this.paymentAtTableSelector.choose;
    this.paymentDispatcher.updateAmount(amount + (tip ?? 0));
  }
}
