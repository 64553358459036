import { combineLatest } from 'rxjs';
import { Injectable } from '@angular/core';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { ShopUtils } from '@app/utilities/shop.util';
import { ShopStoreSelector } from '@app/common/selectors/shop.selector';

import { IShopCatalog } from '@core/models/shop.model';
import { DoodProductModel, ICategory } from '@core/models/product.model';

@Injectable({ providedIn: 'root' })
export class ShopStoreRefiner {
  // Active
  selectActiveShopProducts = this.selector.selectActive.pipe(
    map(_active => _active?.products?.filter(p => !p.hidden && p.id && p.name) ?? []),
  );

  selectActiveShopAdditionGroups = this.selector.selectActive.pipe(
    map(_active => _active?.addition_groups ?? []),
  );

  selectActiveShopAvailableStores = this.selector.selectActive.pipe(
    map(_active => _active?.store.filter(_shop => _shop.available) ?? []),
  );

  selectAllActiveShopStores = this.selector.selectActive.pipe(map(_active => _active?.store ?? []));

  selectActiveShopEnabledCatalogs = this.selector.selectActive.pipe(
    map(_active => {
      const _catalogs = _active?.catalogs ?? [];
      const _enabledCatalogs = _active?.enabled_catalogs ?? [];
      const _foundCatalogs: IShopCatalog[] = [];

      _enabledCatalogs.forEach(id => {
        const _found = _catalogs.find(catalog => catalog.id === id);
        if (_found) _foundCatalogs.push(_found);
      });

      return _foundCatalogs.sort((a, b) => (a.position as number) - (b.position as number)) ?? null;
    }),
    distinctUntilChanged(),
  );

  selectActiveShopEnabledStores = combineLatest([
    this.selectActiveShopEnabledCatalogs,
    this.selectActiveShopAvailableStores,
  ]).pipe(
    map(([catalogs, stores]) => {
      const enabledStores: ICategory[] = [];
      catalogs.forEach(catalog => {
        catalog.stores.map((id: string) => {
          if (stores.find(store => store.id === id)) {
            const _found = stores.find(store => store.id === id);
            if (_found) enabledStores.push(_found as ICategory);
          }
        });
      });
      return enabledStores;
    }),
    distinctUntilChanged(),
  );

  selectActiveShopStores = combineLatest([this.selectActiveShopAvailableStores]).pipe(
    map(([stores]) => {
      return stores;
    }),
  );

  selectActiveProductsCategories = combineLatest([
    this.selectActiveShopEnabledStores,
    this.selectActiveShopProducts,
    this.selector.selectActive,
  ]).pipe(
    debounceTime(20),
    map(([stores, products, active]) => {
      if (active) {
        return ShopUtils.joinItems(stores, products, active.id);
      }
      return [];
    }),
    map(categories => categories.filter(category => category.products?.length)),
  );

  selectActiveShopEnabledDistributionMode = this.selector.selectActive.pipe(
    map(shop => {
      return shop?.distribution_modes?.filter(mode => mode.enabled) ?? [];
    }),
  );

  selectProductsByCategoryId(categoryId: string | undefined) {
    return combineLatest([
      this.selectActiveShopEnabledStores,
      this.selectActiveShopProducts,
      this.selector.selectActive,
    ]).pipe(
      map(([stores, products, active]) => {
        if (active) {
          const categories = ShopUtils.joinItems(stores, products, active.id);
          const category = categories.find(cat => cat.id === categoryId);
          return category?.products;
        }
        return null;
      }),
    );
  }
  selectCategoryById(categoryId: string) {
    return combineLatest([
      this.selectActiveShopEnabledStores,
      this.selectActiveShopProducts,
      this.selector.selectActive,
    ]).pipe(
      map(([stores, products, active]) => {
        if (active) {
          const categories = ShopUtils.joinItems(stores, products, active.id);
          const category = categories.find(cat => cat.id === categoryId);
          return category || null;
        }
        return null;
      }),
    );
  }

  selectCategories = combineLatest([
    this.selectActiveShopStores,
    this.selectActiveShopProducts,
    this.selector.selectActive,
  ]).pipe(
    map(([stores, products, active]) => {
      if (active) {
        return ShopUtils.joinItems(stores, products, active.id);
      }
      return null;
    }),
  );

  // Products
  selectShopsProducts = this.selector.selectShops.pipe(
    map(shops => {
      return shops.reduce((_products, _shop) => {
        return [..._products, ..._shop.products];
      }, [] as DoodProductModel[]);
    }),
  );

  constructor(private selector: ShopStoreSelector) {}
}
