<div class="text-center">
  <app-top-hero-atom
    [logoImg]="image?.url ?? ''"
    [brandText]="text"
  ></app-top-hero-atom>
  <app-block-list
    *ngIf="socialLinks"
    class="overflow-x-scroll bg-white"
    [blockIdList]="socialLinks"
  ></app-block-list>
</div>
