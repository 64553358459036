<div class="min-h-screen px-5 pt-2">
  <ng-container *ngIf="!errorMessage">
    <h1 class="font-accent text-2xl font-bold text-neutral-800 lg:text-3xl">
      {{ 'pay-at-table.choose-amount.title' | translate }}
    </h1>
    <div class="py-5">
      <div class="py-3.5">
        <app-input-multi-atom
          [type]="'radio'"
          [value]="amountTypeValues.Total"
          [blockId]="amountTypeValues.Total"
          [label]="'pay-at-table.choose-amount.pay-total-amount' | translate"
          [boldLabel]="true"
          [active]="radioButtons.total"
          (valueEmitter)="getAmountType($event)"
        ></app-input-multi-atom>
        <p
          *ngIf="radioButtons.total"
          class="pl-10 text-sm text-neutral-600"
        >
          {{ 'pay-at-table.choose-amount.amount' | translate }} :
          <app-price-atom
            [amount]="(outstandingAmount$ | async) || 0"
            [format]="true"
            [bold]="false"
          ></app-price-atom>
        </p>
      </div>
      <div class="py-3.5">
        <app-input-multi-atom
          [type]="'radio'"
          [value]="amountTypeValues.Products"
          [blockId]="amountTypeValues.Products"
          [label]="'pay-at-table.choose-amount.choose-products-to-pay' | translate"
          [boldLabel]="true"
          [active]="radioButtons.products"
          (valueEmitter)="getAmountType($event)"
        ></app-input-multi-atom>
        <p
          *ngIf="radioButtons.products"
          class="pl-10 text-sm text-neutral-600"
        >
          {{ 'pay-at-table.choose-amount.amount' | translate }} :
          <app-price-atom
            [amount]="selectedChooseType.totalAmount"
            [format]="true"
            [bold]="false"
          ></app-price-atom>
        </p>
        <p
          *ngIf="radioButtons.products"
          class="cursor-pointer pl-10 text-sm font-bold text-primary-600"
          (click)="openModal(amountTypeValues.Products)"
        >
          {{ 'pay-at-table.choose-amount.modify' | translate }}
        </p>
      </div>
      <div class="py-3.5">
        <app-input-multi-atom
          [type]="'radio'"
          [value]="amountTypeValues.Divide"
          [blockId]="amountTypeValues.Divide"
          [label]="'pay-at-table.choose-amount.divide-bill' | translate"
          [boldLabel]="true"
          [active]="radioButtons.divide"
          (valueEmitter)="getAmountType($event)"
        ></app-input-multi-atom>
        <p
          *ngIf="radioButtons.divide"
          class="pl-10 text-sm text-neutral-600"
        >
          {{ 'pay-at-table.choose-amount.amount' | translate }} :
          <app-price-atom
            [amount]="selectedChooseType.totalAmount"
            [format]="true"
            [bold]="false"
          ></app-price-atom>
        </p>
        <p
          *ngIf="radioButtons.divide"
          class="cursor-pointer pl-10 text-sm font-bold text-primary-600"
          (click)="openModal(amountTypeValues.Divide)"
        >
          {{ 'pay-at-table.choose-amount.modify' | translate }}
        </p>
      </div>
      <div class="py-3.5">
        <app-input-multi-atom
          [type]="'radio'"
          [value]="amountTypeValues.Free"
          [blockId]="amountTypeValues.Free"
          [label]="'pay-at-table.choose-amount.pay-free-amount' | translate"
          [boldLabel]="true"
          [active]="radioButtons.free"
          (valueEmitter)="getAmountType($event)"
        ></app-input-multi-atom>
        <p
          *ngIf="radioButtons.free"
          class="pl-10 text-sm text-neutral-600"
        >
          {{ 'pay-at-table.choose-amount.amount' | translate }} :
          <app-price-atom
            [amount]="selectedChooseType.totalAmount"
            [format]="true"
            [bold]="false"
          ></app-price-atom>
        </p>
        <p
          *ngIf="radioButtons.free"
          class="cursor-pointer pl-10 text-sm font-bold text-primary-600"
          (click)="openModal(amountTypeValues.Free)"
        >
          {{ 'pay-at-table.choose-amount.modify' | translate }}
        </p>
      </div>

      <div *ngIf="isTipsEnabled">
        <app-cart-tip-atom
          [choice1Amount]="CHECK_TIPS_CONFIG['amount-1']"
          [choice1Unit]="CHECK_TIPS_CONFIG['type-1']"
          [choice2Amount]="CHECK_TIPS_CONFIG['amount-2']"
          [choice2Unit]="CHECK_TIPS_CONFIG['type-2']"
          [choice3Amount]="CHECK_TIPS_CONFIG['amount-3']"
          [choice3Unit]="CHECK_TIPS_CONFIG['type-3']"
          [totalAmount]="((totalAmount$ | async) ?? 0) / 100"
          [selectedTip]="((tip$ | async) ?? 0) / 100 || 0"
          [isOtherAmountModal]="false"
          (tipEmitter)="onSelectedTip($event)"
          (otherAmountEmitter)="onOtherTipAmount()"
        ></app-cart-tip-atom>
      </div>
    </div>
    <app-pat-base-modal *ngIf="!isTransactionError">
      <p class="mb-3 text-center font-base text-neutral-800">
        {{ 'pay-at-table.choose-amount.rest-to-pay' | translate }} :
        <app-price-atom
          [amount]="(outstandingAmount$ | async) || 0"
          [bold]="false"
          [format]="true"
        ></app-price-atom>
      </p>
      <app-button-atom
        class="mx-auto"
        [disabled]="isLoading"
        [icon]="isLoading ? '' : 'icon-black-lock'"
        [iconUrl]="isLoading ? 'assets/images/spinner.svg' : ''"
        [label]="
          ('pay-at-table.pay' | translate) +
          ' ' +
          ((totalAmountWithTip$ | async) || 0) +
          ' ' +
          currencySymbol
        "
        [full]="true"
        (click)="goToPayment()"
      ></app-button-atom>
    </app-pat-base-modal>
    <app-pat-base-modal *ngIf="isTransactionError">
      <p class="mb-3 text-center font-base text-neutral-800">
        {{ 'pay-at-table.choose-amount.error-occured' | translate }}
      </p>
      <app-button-atom
        class="mx-auto"
        [disabled]="isLoading"
        [icon]="isLoading ? '' : 'icon-black-lock'"
        [iconUrl]="isLoading ? 'assets/images/spinner.svg' : ''"
        [label]="
          ('pay-at-table.pay' | translate) +
          ' ' +
          ((totalAmountWithTip$ | async) || 0) +
          ' ' +
          currencySymbol
        "
        [full]="true"
        (click)="goToPayment()"
      ></app-button-atom>
    </app-pat-base-modal>
    <app-pat-base-modal *ngIf="isModalTipOtherAmountOpen && isTipsEnabled">
      <app-input-tip-picker-atom
        (applyTip)="onApplyTip($event)"
        (closeTip)="closeTipModal()"
      ></app-input-tip-picker-atom>
    </app-pat-base-modal>
  </ng-container>
  <app-pat-checks-error
    *ngIf="errorMessage"
    [error]="errorMessage"
    [displayRetryButton]="true"
  ></app-pat-checks-error>
</div>
