<h2 class="pb-6 font-accent text-29px font-bold leading-7 text-white">
  {{ title }}
</h2>
<div class="flex justify-center md:block">
  <app-button-atom
    [link]="urlButton"
    class="mx-auto"
    [type]="'primary'"
    [color]="'primary'"
    [label]="labelButton ?? ''"
    [thickSize]="buttonSizeY"
  ></app-button-atom>
</div>
