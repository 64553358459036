import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class DestroyerBase implements OnDestroy {
  protected readonly _destroyerRef = new Subject<boolean>();

  ngOnDestroy(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
  }
}
