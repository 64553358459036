import { DoodAppDevice, DoodAppPlatform, DoodAppScreen } from '@store/order/order.model';

const MOBILE_MAX_WIDTH = 768;
const TABLET_MAX_WIDTH = 992;

export class DeviceUtils {
  static getPlatform(): DoodAppPlatform {
    const ua = navigator.userAgent;
    if (ua.indexOf('Android') !== -1) {
      return DoodAppPlatform.android;
    }
    if (ua.indexOf('like Mac') !== -1) {
      return DoodAppPlatform.iOs;
    }
    return DoodAppPlatform.web;
  }

  static getDeviceType(): DoodAppDevice {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return DoodAppDevice.tablet;
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua,
      )
    ) {
      return DoodAppDevice.mobile;
    }
    return DoodAppDevice.desktop;
  }

  static getDeviceScreenType(): DoodAppScreen {
    const width = window.innerWidth;
    if (width <= MOBILE_MAX_WIDTH) {
      return DoodAppScreen.mobile;
    }

    if (width <= TABLET_MAX_WIDTH && width > MOBILE_MAX_WIDTH) {
      return DoodAppScreen.tablet;
    }
    return DoodAppScreen.desktop;
  }

  static isSafari(): boolean {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  static isMobileScreen(): boolean {
    return this.getDeviceScreenType() === DoodAppScreen.mobile;
  }
}
