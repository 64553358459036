<div class="flex max-w-screen-sm items-center justify-between border-b border-neutral-300 py-3">
  <div class="mr-2 flex-1">
    <p
      *ngIf="!mustTranslate"
      class="text-sm leading-4.5 lg:text-base"
      [style.color]="textColor | contentBuilderColor"
    >
      {{ text }}
    </p>
    <p *ngIf="mustTranslate">{{ text | translate }}</p>
  </div>
  <div class="flex flex-row items-center justify-center">
    <div
      class="icon {{ icon }} pl-4"
      [style.color]="secondaryTextColor | contentBuilderColor"
    ></div>
    <div class="w-20 text-right text-sm leading-5">
      <app-price-atom
        [bold]="false"
        [amount]="price"
        [format]="true"
      ></app-price-atom>
    </div>
  </div>
</div>
