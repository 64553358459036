import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { DoodEntityCategory } from '@core/models/entity.model';

@Component({
  selector: 'app-horizontal-categories-atom',
  templateUrl: './horizontal-categories-atom.component.html',
})
export class HorizontalCategoriesAtomComponent implements AfterViewInit {
  @Input() title!: string;
  @Input() items!: DoodEntityCategory[];
  @Input() mobileView!: boolean;

  @ViewChild('categoriesWrapper')
  categoriesWrapper!: ElementRef;

  hidePrevBtn!: boolean;
  hideNextBtn!: boolean;

  itemsScrolled!: number;
  slideWidth!: number;
  itemWidth = 80;
  itemsPerPage!: number;
  offset = 300;

  @Output() favoriteChange = new EventEmitter<boolean>();
  @Output() clickCard = new EventEmitter<DoodEntityCategory>();

  nextPage(): void {
    this.itemsScrolled++;
    this.scroll();
  }

  prevPage(): void {
    this.itemsScrolled--;
    this.scroll(true);
  }

  scroll(back = false): void {
    const el = document.getElementById(
      'slide_' + (back ? this.itemsScrolled - this.itemsPerPage + 1 : this.itemsScrolled),
    );
    el?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const width = this.categoriesWrapper.nativeElement.offsetWidth;
      if (width < 1024 || this.mobileView) {
        this.hideNextBtn = true;
        this.hidePrevBtn = true;
        return;
      }
      // hide buttons if items fit on screen
      if (width > this.itemWidth * this.items?.length + this.offset) {
        this.hideNextBtn = true;
        this.hidePrevBtn = true;
        return;
      }
      this.hideNextBtn = false;
      this.hidePrevBtn = false;

      this.itemsPerPage = Math.floor((width - this.offset) / this.itemWidth);

      this.slideWidth = this.itemsPerPage * this.itemWidth;
      this.itemsScrolled = this.itemsPerPage;
    });
  }
}
