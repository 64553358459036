import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-push-shop-card-atom',
  templateUrl: './push-shop-card-atom.component.html',
})
export class PushShopCardAtomComponent {
  @Input() logoUrl!: string;
  @Input() name!: string;
  @Input() slug!: string;
}
