import { filter } from 'lodash';
import { Injectable } from '@angular/core';

import { DoodProductModel } from '@core/models/product.model';

@Injectable({
  providedIn: 'root',
})
export class ProductSelectorService {
  constructor() {}

  selectProducts(
    products: DoodProductModel[],
    items: Record<string, unknown>[],
  ): DoodProductModel[] {
    const selectedProducts: DoodProductModel[] = [];
    for (const item of items) {
      switch (item.type) {
        case 'product_id':
          selectedProducts.push(
            ...(filter(products, {
              id: item.value,
            }) as DoodProductModel[]),
          );
          break;
        case 'label_name':
          selectedProducts.push(
            ...(filter(products, {
              labels: [{ name: item.value }],
            }) as DoodProductModel[]),
          );
          break;
        case 'label_handle':
          selectedProducts.push(
            ...(filter(products, {
              labels: [{ handle: item.value }],
            }) as DoodProductModel[]),
          );
          break;
        case 'label_id':
          selectedProducts.push(
            ...(filter(products, {
              labels: [{ id: item.value }],
            }) as DoodProductModel[]),
          );
          break;
      }
    }

    return [...new Set(selectedProducts)];
  }
}
