import { Router } from '@angular/router';
import { Component, Input } from '@angular/core';

import { CheckKeys } from '@config/keys/check.keys';
import { DoodOpenCheck } from '@core/models/check.model';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';

@Component({
  selector: 'app-check-card',
  templateUrl: './check-card.component.html',
})
export class CheckCardComponent {
  @Input() check!: DoodOpenCheck;

  checkKeys = CheckKeys;

  constructor(
    private readonly router: Router,
    private readonly routerHelper: RouterHelperService,
  ) {}

  goToCheckDetails(id: string): void {
    this.router.navigate([this.routerHelper.translateRoute(`/checks/${id}/details`)]);
  }
}
