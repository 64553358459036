import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DOOD_API } from '@config/ws.config';
import { DoodTransactionModel } from '@core/models/transaction.model';

@Injectable({
  providedIn: 'root',
})
export class TransactionsApiService {
  constructor(private readonly httpClient: HttpClient) {}

  getTransaction$(id: string): Observable<DoodTransactionModel> {
    return this.httpClient.get<DoodTransactionModel>(`${DOOD_API}/transactions/${id}`);
  }

  createTransaction$(transaction: Partial<DoodTransactionModel>): Observable<DoodTransactionModel> {
    return this.httpClient.post<DoodTransactionModel>(`${DOOD_API}/transactions`, transaction);
  }

  // TODO: Type received data from Dood API
  cancelTransaction$(id: string): Observable<unknown> {
    return this.httpClient.post<unknown>(`${DOOD_API}/transactions/${id}/cancel`, {});
  }
}
