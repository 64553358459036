import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ComoService } from '@core/services/como/como.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-como-create-or-link-account-modal-otp-step',
  templateUrl: './como-create-or-link-account-modal-otp-step.component.html',
  styleUrls: ['./como-create-or-link-account-modal-otp-step.component.scss'],
})
export class ComoCreateOrLinkAccountModalOtpStepComponent implements OnInit {
  @Input() phoneOrEmail = '';

  @Output() goToStep = new EventEmitter<string>();

  form = this.fb.group({
    authCode: ['', [Validators.required]],
  });
  authCodeErrorMessage?: string;
  errorMessage?: string;
  isValidating = false;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly comoService: ComoService,
  ) {}

  ngOnInit(): void {}

  onSubmit() {
    this.isValidating = true;
    this.authCodeErrorMessage = undefined;
    this.errorMessage = undefined;
    this.comoService
      .linkComoUser$({ phoneOrEmail: this.phoneOrEmail, code: this.form.value.authCode })
      .pipe(tap(() => this.goToStep.next('confirmation')))
      .subscribe({
        error: error => this.handleErrorResponse(error.error),
        complete: () => (this.isValidating = false),
      });
  }

  private handleErrorResponse(error: any): void {
    this.isValidating = false;
    switch (error.code) {
      case 10:
        // Invalid code
        this.authCodeErrorMessage = 'como.link-account.errors.invalid-auth-code';
        break;
      case 0:
        this.errorMessage = 'como.errors.generic-error';
        break;
      case 101:
        // Como is already attached to user
        this.errorMessage = 'como.create-account.errors.user-has-loyalty-attached';
        break;
      case 201:
        // Unable to send verification code
        this.errorMessage = 'como.link-account.errors.unable-to-send-code';
        break;
      case 3:
        // Como not enabled
        this.errorMessage = 'como.errors.loyalty-not-enabled';
        break;
      default:
        this.errorMessage = 'como.errors.generic-error';
    }
  }
}
