<div
  *ngIf="!(signInWithRedirectLoading$ | async); else loading"
  class="relative mx-auto max-w-screen-sm"
  [formGroup]="form"
>
  <ng-container *ngIf="authenticationSubStep === 'HOME'">
    <div *ngIf="authMode !== authModeValues.OnlyAnonymous">
      <div class="mb-8 flex items-center justify-between gap-3">
        <h1 class="py-2 font-accent text-3xs font-bold leading-8xs text-neutral-800">
          {{ firstStepHeading }}
        </h1>
        <app-rounded-icon-button
          [icon]="'icon-cross'"
          [color]="'primary'"
          (click)="closeModal()"
        ></app-rounded-icon-button>
      </div>

      <div class="pb-6">
        <div class="flex flex-col gap-5">
          <div *ngIf="authenticationProviderGoogle">
            <app-button-atom
              [type]="'outlined'"
              [size]="'L'"
              [label]="'authentication.google.button' | translate"
              [color]="'neutral'"
              [iconUrl]="'assets/images/auth-logos/google.png'"
              (click)="isAnonymous ? googleUpgrade() : googleLogin()"
            ></app-button-atom>
          </div>
          <div *ngIf="authenticationProviderApple">
            <app-button-atom
              [type]="'outlined'"
              [size]="'L'"
              [label]="'authentication.apple.button' | translate"
              [color]="'neutral'"
              [iconUrl]="'assets/images/auth-logos/apple.png'"
              (click)="isAnonymous ? appleUpgrade() : appleLogin()"
            ></app-button-atom>
          </div>
          <!-- <div *ngIf="!isNative && authenticationProviderFacebook">
                        <app-button-atom [type]="'outlined'" [size]="'L'"
                                         [label]="'authentication.facebook.button' | translate"
                                         [color]="'neutral'" [iconUrl]="'assets/images/auth-logos/facebook.png'"
                                         (click)="isAnonymous ? facebookUpgrade() : facebookLogin()"></app-button-atom>
                    </div> -->
          <div *ngIf="authenticationProviderComo">
            <app-button-atom
              [type]="'outlined'"
              [size]="'L'"
              [label]="'authentication.loyalty.button' | translate"
              [color]="'neutral'"
              (click)="comoLogin()"
            ></app-button-atom>
          </div>
          <div *ngIf="authMode === authModeValues.Anonymous && !isAnonymous">
            <app-button-atom
              [type]="'outlined'"
              [size]="'L'"
              [label]="'authentication.anonymous.button' | translate"
              [color]="'neutral'"
              (click)="anonymousLogin()"
            >
            </app-button-atom>
          </div>
        </div>
      </div>

      <ng-container *ngIf="userJourneyType === authenticationUserJourneyType.ASK_EMAIL_FIRST">
        <div class="flex items-center pb-6">
          <div class="h-px w-full border-b-2 border-neutral-400"></div>
          <span class="inline-block px-3 font-base text-base font-bold text-neutral-500">{{
            'divider.or' | translate
          }}</span>
          <div class="h-px w-full border-b-2 border-neutral-400"></div>
        </div>
        <div class="relative">
          <input
            type="email"
            placeholder="{{ 'authentication.login' | translate }}"
            formControlName="email"
            (keyup.enter)="emailLogin()"
            appAutoFocus
            autocomplete="email"
            name="email"
            class="flex w-full border border-solid border-neutral-300 bg-neutral-50 px-5 py-8xs font-base text-base font-normal leading-5"
            [class.border-error-700]="emailErrorMessage || form.controls.email?.errors?.format"
          />
          <span
            *ngIf="form.controls.email?.errors?.format && form.controls.email?.touched"
            class="block py-2 font-base text-xs font-normal text-error-700"
            >{{ 'errors.email-not-valid' | translate }}</span
          >
        </div>
        <div class="py-3">
          <app-button-atom
            [disabled]="
              form.controls.email.status !== 'VALID' ||
              !(authenticationEmailValue$ | async) ||
              isUserRegisteredCheckInProgress ||
              isUserEmailLoginInProgress
            "
            [iconUrl]="
              isUserRegisteredCheckInProgress || isUserEmailLoginInProgress
                ? 'assets/images/spinner.svg'
                : undefined
            "
            [type]="'primary'"
            [label]="'authentication.continue' | translate"
            [size]="'L'"
            (click)="emailLogin()"
          ></app-button-atom>
        </div>
      </ng-container>

      <ng-container *ngIf="userJourneyType === authenticationUserJourneyType.ASK_LOGIN_OR_SIGNUP">
        <div>
          <app-button-atom
            [type]="'outlined'"
            [size]="'L'"
            [label]="firstStepLoginButton"
            [color]="'neutral'"
            (click)="goToEmailLoginStep()"
          >
          </app-button-atom>
          <div class="flex items-center py-6">
            <div class="h-px w-full border-b-2 border-neutral-400"></div>
            <span class="inline-block px-3 font-base text-base font-bold text-neutral-500">{{
              'divider.or' | translate
            }}</span>
            <div class="h-px w-full border-b-2 border-neutral-400"></div>
          </div>
          <app-button-atom
            [type]="'outlined'"
            [size]="'L'"
            [label]="firstStepSignupButton"
            [color]="'neutral'"
            (click)="goToEmailSignupStep()"
          >
          </app-button-atom>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <!--    EMAIL LOGIN  -->

  <ng-container *ngIf="authenticationSubStep === 'EMAIL_LOGIN'">
    <div class="mb-8 flex items-center justify-between gap-3">
      <h1 class="py-2 font-accent text-3xs font-bold leading-8xs text-neutral-800">
        {{ loginStepHeading }}
      </h1>
      <app-rounded-icon-button
        [icon]="'icon-cross'"
        [color]="'primary'"
        (click)="closeModal()"
      ></app-rounded-icon-button>
    </div>
    <div class="relative">
      <input
        type="email"
        placeholder="{{ 'authentication.login' | translate }}"
        formControlName="email"
        (keyup.enter)="emailLogin()"
        appAutoFocus
        autocomplete="email"
        name="email"
        class="flex w-full border border-solid border-neutral-300 bg-neutral-50 px-5 py-8xs font-base text-base font-normal leading-5"
        [class.border-error-700]="emailErrorMessage"
      />
      <span
        *ngIf="!emailResetSend && !form?.controls?.email?.errors?.format"
        class="block py-2 font-base text-xs font-normal text-error-700"
      >{{ emailErrorMessage ?? '' | translate }}</span
      >
      <span
        *ngIf="form?.controls?.email?.errors?.format && form?.controls?.email?.touched"
        class="block py-2 font-base text-xs font-normal text-error-700"
        >{{ 'signup.invalid-email' | translate }}</span
      >
    </div>

    <input
      #inputFocused
      type="password"
      autocomplete="current-password"
      placeholder="{{ 'authentication.password' | translate }}"
      formControlName="password"
      (keyup.enter)="emailLogin()"
      class="mt-6 flex w-full border border-solid border-neutral-300 bg-neutral-50 px-5 py-8xs font-base text-base font-normal leading-5"
      [class.border-error-700]="passwordErrorMessage"
      appAutoFocus
    />
    <span class="mb-2 block font-base text-xs font-normal text-error-700">{{
        passwordErrorMessage ?? '' | translate
      }}</span>

    <p
      class="cursor-pointer pb-2 text-center font-base text-sm font-normal leading-5"
      (click)="resetPassword()"
    >
      <ng-container *ngIf="!emailResetSend">
        {{ 'authentication.forgot-password' | translate }}
      </ng-container>
      <ng-container *ngIf="emailResetSend">
        {{ 'authentication.email-send-forgot-password' | translate }}
      </ng-container>
    </p>

    <div class="py-3">
      <app-button-atom
        [disabled]="
          form.invalid ||
          !(authenticationEmailValue$ | async) ||
          isUserRegisteredCheckInProgress ||
          isUserEmailLoginInProgress
        "
        [iconUrl]="
          isUserRegisteredCheckInProgress || isUserEmailLoginInProgress
            ? 'assets/images/spinner.svg'
            : undefined
        "
        [type]="'primary'"
        [label]="'authentication.continue' | translate"
        [size]="'L'"
        (click)="emailLogin()"
      ></app-button-atom>
    </div>
    <p
      class="my-2 font-base text-xs font-normal text-error-700"
      *ngIf="anyErrorMessage"
    >
      {{ anyErrorMessage }}
    </p>
  </ng-container>

  <ng-container *ngIf="!isSignInMethodPasswordAllowed && isSignInMethodGoogleAllowed">
    <p
      class="ng-star-inserted rounded-sm bg-info-50 p-5 font-base text-sm text-info-900"
      translate="authentication.google.emailAlreadyExist"
    ></p>
  </ng-container>
  <ng-container *ngIf="!isSignInMethodPasswordAllowed && isSignInMethodGoogleAllowed">
    <p
      class="ng-star-inserted rounded-sm bg-info-50 p-5 font-base text-sm text-info-900"
      translate="authentication.apple.emailAlreadyExist"
    ></p>
  </ng-container>
  <ng-container *ngIf="!isSignInMethodPasswordAllowed && isSignInMethodFacebookAllowed">
    <p
      class="ng-star-inserted rounded-sm bg-info-50 p-5 font-base text-sm text-info-900"
      translate="authentication.facebook.emailAlreadyExist"
    ></p>
  </ng-container>

  <div
    class="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center bg-white bg-opacity-90"
    *ngIf="signInWithPopupInProgress && authMode !== authModeValues.OnlyAnonymous"
  >
    <div class="flex flex-row items-center font-base">
      <img
        src="../../../../../assets/images/spinner.svg"
        class="pr-4"
      />
      Connexion&hellip;
    </div>
  </div>

  <div
    class="flex h-30 items-center justify-center bg-white bg-opacity-90 px-10"
    *ngIf="authMode === authModeValues.OnlyAnonymous"
  >
    <div class="flex flex-row items-center font-base">
      <img
        src="../../../../../assets/images/spinner.svg"
        class="pr-4"
      />
      Veuillez patienter&hellip;
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="w-full">
    <img
      src="assets/images/spinner.svg"
      alt=""
      class="m-auto w-1/6 p-2 pb-16"
    />
  </div>
</ng-template>
