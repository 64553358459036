<div
  id="{{ anchor }}"
  class="mx-auto"
  [class.mb-10]="!roundedImage"
  [ngClass]="fullWidth ? 'w-full' : 'container max-w-640 pb-5'"
>
  <div
    class="flex flex-col lg:flex-row"
    [class.lg:flex-row-reverse]="this.imagePosition === this.IMAGE_POSITION_RIGHT"
  >
    <div
      *ngIf="image && !roundedImage"
      class="mb-10 flex lg:mb-0"
      [class]="imageWidthClass ?? ''"
      [ngClass]="{
        'items-start': !imageVerticalPosition || imageVerticalPosition === 'top',
        'items-center': imageVerticalPosition === 'center',
        'items-end': imageVerticalPosition === 'bottom',
      }"
    >
      <img
        [src]="image.url"
        class="w-full"
      />
    </div>
    <div
      *ngIf="image && roundedImage"
      class="w-full pb-16 pt-20 lg:w-6/12 xl:pb-20 xl:pt-28"
    >
      <img
        [src]="image.url"
        class="mx-auto h-72 w-72 rounded-full object-cover sm:h-96 sm:w-96 xl:h-540px xl:w-540px"
      />
    </div>
    <div
      class="-mt-1 flex px-5"
      [class]="textWidthClass ?? ''"
      [class.sm:pl-0]="this.imagePosition === this.IMAGE_POSITION_RIGHT"
      [class.sm:pr-0]="this.imagePosition !== this.IMAGE_POSITION_RIGHT"
      [class.md:px-10]="fullWidth"
      [class.md:pr-20]="roundedImage"
      [class.lg:pr-30]="roundedImage"
      [ngClass]="{
        'items-start': !imageVerticalPosition || imageVerticalPosition === 'top',
        'items-center': imageVerticalPosition === 'center',
        'items-end': imageVerticalPosition === 'bottom',
        'w-4/5 mx-auto pt-0 xl:pt-28 pb-16 lg:pb-20 text-center': fullWidth,
      }"
    >
      <app-text-atom [content]="content"></app-text-atom>
    </div>
  </div>
</div>
