import { ObjectUtils } from '@app/utilities/object.util';

import {
  cleanHydratedStoreCacheTtl,
  getHydratedStoreCacheTtl,
  isHydratedStoreCacheExpired,
  setHydratedStoreCacheTtl,
} from './hydrated.cache';
import { HydrationReducerOpts } from './hydrated.reducer';
import { setHydratedStoreData } from './hydrated.storage';

export interface HydrationState {
  _hydratable: boolean;
}

export const HYDRATED_STORE_INITIAL_STATE: HydrationState = {
  _hydratable: true,
};

export const checkHydratedStoreState = <S>(
  options: HydrationReducerOpts<S>,
  state?: Partial<S>,
): S => {
  const _state = state ?? options.initialState;
  const _cache = getHydratedStoreCacheTtl(options.key);
  if (!ObjectUtils.isNil(_cache)) {
    if (isHydratedStoreCacheExpired(options.key)) {
      return updateHydratedStoreState(options, undefined);
    }
  } else {
    return updateHydratedStoreState(options);
  }
  return _state as S;
};

export const updateHydratedStoreState = <S>(
  options: HydrationReducerOpts<S>,
  state?: Partial<S>,
): S => {
  const _state = state ?? options.initialState;
  const _keys = Object.keys(_state);
  const _filteredData = !options.properties
    ? _keys
    : _keys.filter(_prop => options.properties?.includes(_prop));
  const _data = _filteredData.reduce((_result, _prop) => {
    const _value = Object(_state)[_prop];
    if (!ObjectUtils.isNil(_value)) {
      return { ..._result, [_prop]: _value };
    }
    return _result;
  }, {} as Partial<S>);
  setHydratedStoreCacheTtl(options.key, options.ttl);
  setHydratedStoreData(options.key, _data);
  return _data as S;
};

export const cleanHydratedStoreState = (key: string): void => {
  cleanHydratedStoreCacheTtl(key);
  setHydratedStoreData(key, null);
};
