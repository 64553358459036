import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProductKeys } from '@config/keys/product.keys';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { DoodProductModel } from '@core/models/product.model';

@Component({
  selector: 'app-kiosk-product-card-atom',
  templateUrl: './kiosk-product-card-atom.component.html',
  styleUrls: ['./kiosk-product-card-atom.component.scss'],
  host: {
    '(click)': 'onCardClicked($event)',
  },
})
export class KioskProductCardAtomComponent {
  @Input() product!: DoodProductModel;
  @Input() quantity!: number | undefined;
  @Input() isProductAvailable: boolean = true;

  @Input() isOrderingAllowed = true;
  @Input() textColor?: IContentBuilderFieldColor;
  @Input() cardColor?: IContentBuilderFieldColor;
  @Input() buttonBackgroundColor?: IContentBuilderFieldColor;
  @Input() buttonTextColor?: IContentBuilderFieldColor;
  @Input() unavailableButtonBackground?: IContentBuilderFieldColor;
  @Input() unavailableButtonText?: IContentBuilderFieldColor;
  @Input() secondaryColor?: IContentBuilderFieldColor;
  @Input() displayPrice?: boolean = true;
  @Input() displayedQuantity: number = 0;
  @Input() selectionnable?: boolean = false;
  @Input() maxProduct: number = 1;
  @Input() monoSelection: boolean = false;
  @Input() displayedAddition?: number;

  @Output() buttonClick = new EventEmitter<DoodProductModel>();
  @Output() addProduct = new EventEmitter<void>();
  @Output() showProduct = new EventEmitter<void>();
  @Output() deleteProductProduct = new EventEmitter<DoodProductModel>();
  @Output() changeProductQuantity = new EventEmitter<number>();

  public get ProductKeys(): typeof ProductKeys {
    return ProductKeys;
  }

  goToProduct(product: DoodProductModel): void {
    if (!this.isProductAvailable) {
      return;
    }
    this.buttonClick.emit(product);
  }

  displayDiscount(product: DoodProductModel): boolean {
    const discount = product[ProductKeys.Discount];
    return (discount && discount > 0) === true;
  }

  addToCart(): void {
    this.addProduct.emit();
  }

  showProductModal(): void {
    this.showProduct.emit();
  }

  changeNbProduct(quantity: number) {
    this.changeProductQuantity.emit(quantity);
  }

  onCardClicked(event: Event): void {
    const target = event.target as HTMLElement;

    if (!this.selectionnable || target.closest('app-counter-atom')) {
      return;
    }

    switch (this.quantity) {
      case 0:
      case undefined:
      case null:
        this.changeNbProduct(1);
        break;
      case 1:
        this.changeNbProduct(0);
        break;
      default:
        break;
    }
  }

  forceType(data: any): any {
    return data as any;
  }
}
