import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-kiosk-input-radio-atom',
  templateUrl: './kiosk-input-radio-atom.component.html',
})
export class KioskInputRadiotAtomComponent {
  @Input() label!: string;
  @Input() blockId!: string;
  @Input() value!: string;
  @Input() active = false;
  @Input() textColor?: IContentBuilderFieldColor;
  @Input() buttonColor?: IContentBuilderFieldColor;
  @Input() activeTextColor?: IContentBuilderFieldColor;
  @Input() activeButtonColor?: IContentBuilderFieldColor;

  @Output() valueEmitter = new EventEmitter<string | boolean>();

  onChangeValueRadio(event: string): void {
    this.valueEmitter.emit(event);
  }
}
