import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { DoodApiError } from '@shared/interfaces/error.interface';
import { createActiveStoreActions } from '@store/_abstract/active';
import { DoodDeliveryAddressModel } from '@core/models/delivery-address.model';

import { DELIVERY_STORE_KEY } from './delivery.state';

export const DELIVERY_STORE_API_ACTIONS = createActionGroup({
  source: 'Dood/API/Delivery',
  events: {
    'Fetch Addresses': emptyProps(),
    'Fetch Addresses Success': props<{ addresses: DoodDeliveryAddressModel[] }>(),
    'Fetch Addresses Failure': props<{ error: DoodApiError }>(),
    'Fetch Addresses Complete': emptyProps(),
  },
});

export const DELIVERY_STORE_LOCAL_ACTIONS = createActionGroup({
  source: 'Dood/LOCAL/Delivery',
  events: {
    // Errors
    'Update Error': props<{ error: DoodApiError | null }>(),
    'Reset Error': emptyProps(),

    // Status
    'Update Is Loaded': props<{ isLoaded: boolean }>(),
    'Update Is Loading': props<{ isLoading: boolean }>(),

    // Misc
    'reset': emptyProps(),
  },
});

export const DELIVERY_STORE_ACTIVE_ACTIONS =
  createActiveStoreActions<DoodDeliveryAddressModel>(DELIVERY_STORE_KEY);
