<app-modal-filler-atom
  (click)="close()"
  [index]="(index$ | async) ?? 0"
></app-modal-filler-atom>
<div
  class="fixed right-0 left-0 shadow overflow-y-auto mdMax:bottom-0 mdMax:max-h-85-screen mdMax:max-w-screen-sm mdMax:mx-auto md:flex md:justify-center md:items-center md:w-full md:h-full md:top-0 md:left-0"
  [style.z-index]="((index$ | async) ?? 0) + 50"
>
  <div
    class="bg-white p-5 md:p-7 rounded-t-3xl md:rounded-3xl md:max-w-lg md:w-2/5"
    [style.z-index]="((index$ | async) ?? 0) + 50"
  >
    <h1
      class="text-neutral-800 font-brand font-bold text-28px"
      translate="pongo.offered-product-modal.heading"
    ></h1>
    <app-card-carousel
      *ngIf="products.length"
      [cards]="products"
      [nbDisplayCards]="2"
      [carouselItem]="productTemplate"
    ></app-card-carousel>

    <ng-template
      #productTemplate
      let-product
    >
      <div
        class="mr-3 md:mr-4 lg:mr-6 xl:mr-8 min-w-10 w-10 lg:min-w-52 lg:w-52 flex-shrink-0 flex-grow-0"
      >
        <app-product-card-atom
          displayContext="loyalty"
          [product]="product"
          [isOrderingAllowed]="true"
          (productClick)="onNavigateTo(product)"
        ></app-product-card-atom>
      </div>
    </ng-template>

    <div
      *ngIf="!isProductsLoading && products.length === 0"
      class="py-4"
    >
      {{ 'pongo.offered-product-modal.no-product-available' | translate }}
    </div>

    <div
      *ngIf="isProductsLoading"
      class="py-4 w-full text-center"
    >
      <img
        src="assets/images/spinner.svg"
        alt=""
        class="pr-2"
      />
    </div>

    <div class="mt-4">
      <app-button-atom
        [label]="'pongo.cancel-add-voucher' | translate"
        type="light"
        [full]="true"
        (click)="cancel()"
      ></app-button-atom>
    </div>
  </div>
</div>
