import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import 'dayjs/locale/fr';
import 'dayjs/locale/es';
import 'dayjs/locale/nl';
import { locale } from 'dayjs';
import { combineLatest, fromEvent, of } from 'rxjs';
import { Stripe } from '@capacitor-community/stripe';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';

import { Paths } from '@config/paths.config';
import { environment } from '@env/environment';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';

import { CartStoreRefiner } from '@common/refiners/cart.refiner';

import { CartStoreSelector } from '@common/selectors/cart.selector';
import { ShopStoreSelector } from '@common/selectors/shop.selector';
import { OrderStoreSelector } from '@common/selectors/order.selector';
import { PaymentStoreSelector } from './common/selectors/payment.selector';
import { BasketStoreSelector } from '@common/selectors/basket.selector';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';
import { OnSiteLocationStoreSelector } from '@common/selectors/on-site-location.selector';

import { CartStoreDispatcher } from '@common/dispatchers/cart.dispatcher';
import { BasketStoreDispatcher } from '@common/dispatchers/basket.dispatcher';
import { PaymentStoreDispatcher } from '@common/dispatchers/payment.dispatcher';
import { SettingsStoreDispatcher } from '@common/dispatchers/settings.dispatcher';
import { OnSiteLocationStoreDispatcher } from '@common/dispatchers/on-site-location.dispatcher';

import { DoodOrderStatus } from '@store/order/order.model';
import { DoodLocationParameters } from '@store/settings/settings.model';
import { DoodOnSiteLocationModel } from '@core/models/on-site-location.model';

import { CartKeys } from '@config/keys/cart.keys';
import { UrlParamsKeys } from '@config/keys/url-params.keys';
import { OnSiteLocationKeys } from '@config/keys/on-site-location.keys';
import { DistributionModeKeys, MarketplaceKeys, ShopKeys } from '@config/keys/shop.keys';

import {
  DistributionModeValues,
  OnSiteLocationPickModeValues,
  OrderTypeValues,
} from '@config/values/order.values';
import { MarketplaceCartScopeValues } from '@config/values/marketplace.values';

import { BasketStoreRefiner } from './common/refiners/basket.refiner';
import { DoodOnSiteLocationSourceValues } from '@store/on-site-location/on-site-location.enum';

import { PwaService } from '@core/services/pwa/pwa.service';
import { ComoService } from '@core/services/como/como.service';
import { CartService } from '@core/services/cart/cart.service';
import { KioskService } from '@core/services/kiosk/kiosk.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { UpdateService } from '@core/services/update/update.service';
import { BasketService } from '@core/services/basket/basket.service';
import { PaymentService } from '@core/services/payment/payment.service';
import { AnalyticsService } from '@core/services/app/analytics.service';
import { CanonicalService } from '@core/services/canonical/canonical.service';
import { MarketplaceService } from '@core/services/marketplace/marketplace.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { AuthFirebaseService } from '@core/services/api/auth-firebase/auth-firebase.service';
import { DoodAppLoaderService } from '@core/services/dood-app-loader/dood-app-loader.service';
import { NavigationHistoryService } from '@core/services/navigation-history/navigation-history.service';
import { NoConnectionModalService } from '@core/services/no-connection-modal/no-connection-modal.service';
import { ShopSearchParametersService } from '@core/services/shop-search-parameters/shop-search-parameters.service';

import { NoConnectionModalComponent } from '@shared/modals/no-connection-modal/no-connection-modal.component';
import { AuthenticationModalComponent } from '@shared/modals/authentication-modal/authentication-modal.component';
import { CartParametersModalComponent } from '@shared/modals/cart-parameters-modal/cart-parameters-modal.component';
import { BrevoService } from '@core/services/plugin/brevo/brevo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent extends DestroyerBase implements OnInit, AfterViewInit, OnDestroy {
  title = 'dood-front';
  layout$ = this.marketplaceService.getLayout$();
  displayNoConnectionMessage$ = this.noConnectionModalService.getDisplay$();

  constructor(
    private readonly router: Router,
    private cartRefiner: CartStoreRefiner,
    private readonly pwaService: PwaService,
    private cartSelector: CartStoreSelector,
    private shopSelector: ShopStoreSelector,
    private authSelector: AuthStoreSelector,
    private orderSelector: OrderStoreSelector,
    private basketRefiner: BasketStoreRefiner,
    private basketSelector: BasketStoreSelector,
    private cartDispatcher: CartStoreDispatcher,
    private paymentSelector: PaymentStoreSelector,
    private basketDispatcher: BasketStoreDispatcher,
    private settingsSelector: SettingsStoreSelector,
    private settingsDispatcher: SettingsStoreDispatcher,
    private marketplaceSelector: MarketplaceStoreSelector,
    private onSiteLocationSelector: OnSiteLocationStoreSelector,
    private onSiteLocationDispatcher: OnSiteLocationStoreDispatcher,
    private readonly cartService: CartService,
    private readonly comoService: ComoService,
    private readonly kioskService: KioskService,
    private readonly updateService: UpdateService,
    private readonly ordersService: OrdersService,
    private readonly modalsService: ModalsService,
    private readonly basketService: BasketService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly paymentService: PaymentService,
    private paymentDispatcher: PaymentStoreDispatcher,
    private readonly routerHelper: RouterHelperService,
    private readonly canonicalService: CanonicalService,
    private readonly analyticsService: AnalyticsService,
    private readonly marketplaceService: MarketplaceService,
    private readonly authFirebaseService: AuthFirebaseService,
    private readonly localizeRouterService: LocalizeRouterService,
    private readonly navigationHistoryService: NavigationHistoryService,
    private readonly noConnectionModalService: NoConnectionModalService,
    private readonly shopSearchParametersService: ShopSearchParametersService,
    private readonly doodAppLoaderService: DoodAppLoaderService,
    private readonly brevoTracking: BrevoService,
  ) {
    super();
    locale('fr');
    this.initLanguage();
    this.initDayjsLocale();
    this.translateInitialRoute();
    this.navigationHistoryService.pushState('home', '/');
    this.canonicalService.createCanonicalLink();
    this.authFirebaseService.initAuthTokenRefresh();
    this.brevoTracking.initializeTracker();
  }

  ngAfterViewInit(): void {
    this.doodAppLoaderService.hide();
  }

  ngOnInit(): void {
    this.analyticsService.trackEvent('webbase_initialized');
    this.updateService.checkForUpdate();
    this.pwaService.initialize();
    this.updateDeviceScreenType();
    this.synchronizeOrderStatusAfterLostConnection();
    this.cartDispatcher.checkLastCartUpdate();
    this.storeOnSiteLocationIdFromQueryParams();
    this.storeForceDistributionModeFromQueryParams();
    this.storeSetDistributionModeFromQueryParams();
    this.storeDeliverySearchParametersFromQueryParams();
    this.paymentService.checkDigitalWalletAvailable();
    this.initializeStripeCapacitor();
    this.kioskService.initializeKiosk();
    this.authFirebaseService.startAnonymousUserLogoutTimer();
    this.handleSignInWithCustomToken();
    this.comoService.handleSingleSignOn();

    const isSignInWithRedirect = this.authSelector.status.redirect;

    if (isSignInWithRedirect) {
      this.modalsService.open(AuthenticationModalComponent.handle);
    }

    this.displayNoConnectionMessage$
      .pipe(takeUntil(this._destroyerRef), distinctUntilChanged())
      .subscribe(display => {
        if (display) {
          this.modalsService.open(NoConnectionModalComponent.handle);
        } else {
          this.modalsService.close(NoConnectionModalComponent.handle);
        }
      });
  }

  handleSignInWithCustomToken(): void {
    const urlAuthTokenSearchParams = new URLSearchParams(window.location.search);
    if (!urlAuthTokenSearchParams.has('authToken')) {
      return;
    }

    const authToken = urlAuthTokenSearchParams.get('authToken');

    if (!authToken) {
      return;
    }

    console.log('[App] Connecting to with authToken "' + authToken + '"');

    this.authFirebaseService
      .signInWithCustomToken(authToken)
      .pipe(
        take(1),
        tap(result => console.log('[App] Result', result)),
        // tap(() => document.location.reload())
        // @fixme
      )
      .subscribe();
  }

  private storeForceDistributionModeFromQueryParams(): void {
    const urlSearchParams = new URLSearchParams(window.location.search);
    if (urlSearchParams.has('forceDistributionMode')) {
      const forceDistributionMode = urlSearchParams.get('forceDistributionMode') as string;
      this.forceDistributionMode(forceDistributionMode);
    }
  }

  private storeSetDistributionModeFromQueryParams(): void {
    const urlSearchParams = new URLSearchParams(window.location.search);
    if (urlSearchParams.has('setDistributionMode')) {
      const setDistributionMode = urlSearchParams.get('setDistributionMode') as string;
      this.setDistributionMode(setDistributionMode);
    }
  }

  private storeDeliverySearchParametersFromQueryParams(): void {
    const paramsToCheck = [
      'deliveryStreetAddress',
      'deliveryPostalCode',
      'deliveryCity',
      'deliveryLat',
      'deliveryLng',
    ];
    const urlSearchParams = new URLSearchParams(window.location.search);

    const allParamsPresent = paramsToCheck.every(param => urlSearchParams.has(param));
    if (allParamsPresent) {
      const allParams: DoodLocationParameters = {
        deliveryStreetAddress: urlSearchParams.get('deliveryStreetAddress') as string,
        deliveryPostalCode: urlSearchParams.get('deliveryPostalCode') as string,
        deliveryCity: urlSearchParams.get('deliveryCity') as string,
        deliveryLat: Number(urlSearchParams.get('deliveryLat')),
        deliveryLng: Number(urlSearchParams.get('deliveryLng')),
        deliveryCountry: (urlSearchParams.get('deliveryCountry') as string) ?? 'FR',
        deliveryInstructions: urlSearchParams.get('deliveryInstructions') as string,
      };

      this.shopSearchParametersService.setLocationFromQueryParams(allParams);

      const cart = this.cartSelector.active;
      if (cart) {
        let newCart = {
          delivery_address: {
            point: {
              coordinates: [
                Number(urlSearchParams.get('deliveryLng')),
                Number(urlSearchParams.get('deliveryLat')),
              ],
            },
            city: urlSearchParams.get('deliveryCity') ?? '',
            country: urlSearchParams.get('deliveryCountry') ?? '',
            post_code: urlSearchParams.get('deliveryPostalCode'),
            street_line_1: urlSearchParams.get('deliveryStreetAddress') ?? '',
            instructions: urlSearchParams.get('deliveryInstructions') ?? null,
          },
        };
        this.cartDispatcher.updateActive(newCart);
      }
    }
  }

  private storeOnSiteLocationIdFromQueryParams(): void {
    const urlSearchParams = new URLSearchParams(window.location.search);
    if (urlSearchParams.has('onSiteLocationId')) {
      const onSiteLocationId = urlSearchParams.get('onSiteLocationId') as string;
      const orderType = OrderTypeValues.OnSite;

      this.onSiteLocationDispatcher.updateSettings({
        orderType,
        id: onSiteLocationId,
        lastLocationId: onSiteLocationId,
        source: DoodOnSiteLocationSourceValues.fromQrCode,
      });

      this.shopSearchParametersService.setDistributionModeType(orderType, true);
      this.forceDistributionMode(orderType);
      this.shopSearchParametersService.setDefinedDistributionModeType(true);
    }

    combineLatest([
      this.onSiteLocationSelector.selectSettings.pipe(
        distinctUntilChanged((p, c) => p?.id === c?.id),
      ),
      this.cartRefiner.selectHasCart,
      this.basketRefiner.selectHasBasket,
      this.shopSelector.selectActive,
      this.cartSelector.selectActive.pipe(
        distinctUntilChanged((p, c) => {
          return p?.type === c?.type && p?.on_site_location_id === c?.on_site_location_id;
        }),
      ),
      this.onSiteLocationSelector.selectLocations,
    ])
      .pipe(
        tap(([onSiteLocation, cartExist, basketExist, activeShop, activeCart, onSiteLocations]) => {
          if (!cartExist && !onSiteLocation.id) {
            return;
          }

          const onsiteLocationPickMode = activeShop?.[ShopKeys.DistributionModes].filter(
            distributionMode => {
              return distributionMode[DistributionModeKeys.Type] === DistributionModeValues.OnSite;
            },
          )?.[0]?.[DistributionModeKeys.OnsiteLocationPickMode];

          const { id: onSiteLocationId, source } = this.onSiteLocationSelector.settings;
          const isSourceFromUrl = source === DoodOnSiteLocationSourceValues.fromQrCode;

          const basketOnSiteLocationId = this.basketSelector.basket.on_site_location_id;

          const onSiteLocationName = onSiteLocations?.filter(
            (location: DoodOnSiteLocationModel) =>
              location[OnSiteLocationKeys.Id] === (basketOnSiteLocationId ?? onSiteLocation.id),
          )?.[0]?.[OnSiteLocationKeys.Name];

          const isJointCartQueryParam =
            !!this.activatedRoute.snapshot.queryParams?.[UrlParamsKeys.JoinCartId] &&
            !!this.activatedRoute.snapshot.queryParams?.[UrlParamsKeys.ShareCode];

          if (
            basketOnSiteLocationId &&
            onSiteLocations.find(
              (location: DoodOnSiteLocationModel) =>
                location[OnSiteLocationKeys.Id] === basketOnSiteLocationId,
            )
          ) {
            this.onSiteLocationDispatcher.updateSettings({
              id: basketOnSiteLocationId,
              source: DoodOnSiteLocationSourceValues.fromUser,
            });
          }

          if (
            !onsiteLocationPickMode ||
            onsiteLocationPickMode === OnSiteLocationPickModeValues.Url ||
            (onsiteLocationPickMode === OnSiteLocationPickModeValues.UrlOrDropdown &&
              isSourceFromUrl &&
              onSiteLocationId)
          ) {
            if (activeCart && onSiteLocation.id) {
              this.cartDispatcher.updateActive({
                [CartKeys.OnSiteLocationName]: onSiteLocationName,
                [CartKeys.OnSiteLocationId]: basketOnSiteLocationId ?? onSiteLocation.id,
              });
            }
            return;
          }

          if (
            onsiteLocationPickMode === OnSiteLocationPickModeValues.UrlOrDropdown &&
            isSourceFromUrl &&
            onSiteLocationId
          ) {
            return;
          }

          const marketplace = this.marketplaceSelector.marketplace;

          const isCartScopShop =
            marketplace?.[MarketplaceKeys.CartScope] === MarketplaceCartScopeValues.Shop;
          const isOnSiteCart = activeCart?.[CartKeys.Type] === DistributionModeValues.OnSite;
          const isLocationIdMismatch =
            activeCart?.[CartKeys.OnSiteLocationId] !== onSiteLocation.id;
          const isLocationIdMissing = !onSiteLocation.id;

          if (
            onSiteLocation.source === DoodOnSiteLocationSourceValues.fromUser &&
            isCartScopShop &&
            activeShop &&
            !basketExist &&
            !isJointCartQueryParam
          ) {
            if ((isOnSiteCart && isLocationIdMismatch) || (isOnSiteCart && isLocationIdMissing)) {
              this.modalsService.open(CartParametersModalComponent.handle);
            }
          }

          if (onSiteLocation.id) {
            this.cartDispatcher.updateActive({
              [CartKeys.OnSiteLocationName]: onSiteLocationName,
              [CartKeys.OnSiteLocationId]: basketOnSiteLocationId ?? onSiteLocation.id,
            });

            if (basketExist) {
              this.basketDispatcher.updateBasket({
                on_site_location_id: basketOnSiteLocationId ?? onSiteLocation.id,
              });
              this.basketService.updateBasket$().pipe(take(1)).subscribe();
            }
          }
        }),
      )
      .subscribe();
  }

  private updateDeviceScreenType(): void {
    fromEvent(window, 'resize')
      .pipe(
        takeUntil(this._destroyerRef),
        debounceTime(1000),
        map(() => window.innerWidth), // Don't use mapTo!
        distinctUntilChanged(),
        startWith(window.innerWidth),
      )
      .subscribe(() => {
        this.settingsDispatcher.calculateDevice();
      });
  }

  private initLanguage(): void {
    const marketplaceLanguages = this.marketplaceSelector.marketplace.locales;
    const localizeRouterLanguage =
      marketplaceLanguages.length === 1
        ? marketplaceLanguages[0].locale
        : this.localizeRouterService.parser.getLocationLang() ||
          this.localizeRouterService.parser.defaultLang;
    this.localizeRouterService.changeLanguage(localizeRouterLanguage, {
      replaceUrl: true,
    });

    if (localizeRouterLanguage !== this.settingsSelector.app.locale) {
      // TODO: Remove line bellow
      // this.languageStore.update({ locale: localizeRouterLanguage });
      this.settingsDispatcher.updateAppLocale(localizeRouterLanguage);
    }
  }

  private initDayjsLocale(): void {
    locale(this.settingsSelector.app.locale);
  }

  private translateInitialRoute(): void {
    const queryParamsString = location.search.substring(1);
    const queryParams = this.convertQueryParamsStringToObject(queryParamsString);
    this.router.navigate([this.routerHelper.translateRoute(`${window.location.pathname}`)], {
      queryParams,
    });
  }

  private initializeStripeCapacitor(): void {
    Stripe.initialize({
      publishableKey: environment.api.stripe.key,
    });
  }

  private convertQueryParamsStringToObject(queryParamsString: string): object {
    if (queryParamsString) {
      return JSON.parse(
        '{"' + queryParamsString.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        (key, value) => (key === '' ? value : decodeURIComponent(value)),
      );
    }
    return {};
  }

  private synchronizeOrderStatusAfterLostConnection(): void {
    this.paymentSelector.selectStatus
      .pipe(
        switchMap(status => {
          if (!status.isRetryInProgress) return of(null);
          return this.orderSelector.selectActiveId;
        }),
        switchMap(id => (id ? this.ordersService.loadOrderById$(id.toString()) : of(null))),
        tap(order => {
          if (
            order?.status &&
            [DoodOrderStatus.waiting, DoodOrderStatus.preparation].includes(order?.status)
          ) {
            this.ordersService.deActiveOrderToStore();
            this.ordersService.removeOrderFromStore(order.id);
            this.cartService.clearCart();
            this.paymentDispatcher.resetUI();
            this.router.navigate([
              this.routerHelper.translateRoute(`${Paths.Orders}/${order.id}/${Paths.Status}`),
            ]);
          }
        }),
        catchError(error => {
          this.paymentDispatcher.updateStatus({ isRetryInProgress: true });
          return error;
        }),
        take(1),
        takeUntil(this._destroyerRef),
      )
      .subscribe();
  }

  private forceDistributionMode(distributionMode: string): void {
    this.shopSearchParametersService.setForceDistributionMode(distributionMode);
    this.setDistributionMode(distributionMode);
  }

  // TODO: This method is 98% similar to  the previous one, clean the code...
  private setDistributionMode(distributionMode: string): void {
    this.shopSearchParametersService.setDistributionModeType(distributionMode, true);
  }
}
