<div
  *ngIf="comoUser"
  class="bg-neutral-100 mb-3 w-full"
>
  <app-como-asset-list-atom
    [assets]="comoUser.assets"
    [heading]="heading | translate"
    [subheading]="subheading"
    [displayCredits]="displayCredits"
    [displayPoints]="displayPoints"
    [displayRedeemableGifts]="displayRedeemableGifts"
    [displayNotRedeemableGifts]="displayNotRedeemableGifts"
    [displayRedeemablePointShop]="displayRedeemablePointShop"
    [displayNotRedeemablePointShop]="displayNotRedeemablePointShop"
    [displayNotUsablePointShop]="displayNotUsablePointShop"
    [creditBalance]="comoUser.credit_balance.monetary"
    [pointsBalance]="comoUser.points_balance.non_monetary"
    (onAssetAdd)="onAssetAdd($event)"
    (onAssetRemove)="onAssetRemove($event)"
  ></app-como-asset-list-atom>
</div>
