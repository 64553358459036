import { Component, Input } from '@angular/core';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-social-link-block',
  templateUrl: './social-link-block.component.html',
})
export class SocialLinkBlockComponent {
  @Input() icon?: string;
  @Input() linkUrl?: string;
  @Input() textColor?: IContentBuilderFieldColor;
}
