<div
  class="mx-auto block min-h-screen max-w-screen-4xl md:px-6 md:py-6 lg:px-12"
  [ngClass]="!displayCarousel && !heroContextualHeading ? 'h-screen md:py-0' : 'lg:h-screen'"
>
  <div class="relative flex h-full w-full flex-col">
    <div class="relative flex min-h-[75vh] flex-1 items-center md:rounded-2xxl md:shadow-2xl">
      <div
        class="absolute inset-0"
        [ngClass]="
          !displayCarousel && !heroContextualHeading
            ? '[&>*]:md:rounded-2xxl'
            : '[&>*]:md:rounded-t-2xxl'
        "
      >
        <img
          [src]="heroImage?.url"
          class="h-full w-full object-cover"
        />
        <div class="absolute inset-0 bg-black/30"></div>
      </div>

      <div
        class="relative flex h-full max-h-max w-full flex-col items-center justify-center gap-12 px-4 text-white"
      >
        <div class="flex flex-col items-center justify-center gap-2 p-6">
          @if (heroLogo) {
            <img
              [src]="heroLogo?.url"
              class="h-full max-h-[25vh] w-auto"
            />
            @if (heroBaseline) {
              <p
                class="py-6 text-center text-lg font-bold"
                [style.color]="heroBaselineColor | contentBuilderColor"
              >
                {{ heroBaseline }}
              </p>
            }
          }
          @let settingParameters = settingsParameters$ | async;
          <div
            class="flex flex-row gap-2"
            role="tablist"
          >
            @if (availableDistributionModes$ | async; as availableDistributionModes) {
              @for (distributionMode of availableDistributionModes; track $index) {
                <button
                  role="tab"
                  tabindex="0"
                  [attr.aria-selected]="
                    distributionMode.value === settingParameters?.distribution_mode
                      ? 'true'
                      : 'false'
                  "
                  [attr.aria-controls]="'tab-content-' + $index"
                  [id]="'tab-' + $index"
                  class="flex h-24 w-24 cursor-pointer flex-col items-center justify-center rounded-m bg-white p-1 text-center shadow-xl"
                  (click)="openParametersModal(distributionMode.value)"
                  [style.color]="heroDistributionButtonColor | contentBuilderColor"
                  [style.backgroundColor]="
                    heroDistributionButtonBackgroundColor
                      | contentBuilderColor: { type: 'hex', value: '#FFFFFF' }
                  "
                >
                  <i class="icon {{ distributionMode.icon }} text-lg md:text-2xl"></i>
                  <p class="bold text-10 leading-tight md:text-sm">
                    {{ distributionMode.label | translate }}
                  </p>
                </button>
              }
            }
          </div>
        </div>
      </div>
    </div>
    @if (displayCarousel || heroContextualHeading) {
      <div class="bg-white p-8 md:rounded-b-2xxl lg:h-64 lg:max-h-64">
        <div
          class="grid grid-cols-1 items-start md:gap-6 lg:gap-12"
          [ngClass]="heroContextualHeading ? 'md:grid-cols-4' : 'grid-cols-1'"
        >
          @if (heroContextualHeading) {
            <div
              [style.color]="heroContextualHeadingColor | contentBuilderColor"
              class="md:col-span-2"
            >
              <h2 class="mb-4 text-3xl font-bold xl:text-5xl">
                {{ heroContextualHeading }}
              </h2>
              <p class="mb-6 text-sm md:line-clamp-3">
                {{ heroContextualContent }}
              </p>
            </div>
          }
          @if (cards) {
            <div
              class="lg-and-min-height-820-hover-upper-container"
              [ngClass]="
                heroContextualHeading
                  ? 'w-[calc(100vw_-_24px)] md:w-[calc(50vw_-_12px)]  lg:w-[calc(50vw-8px)] left-2/4'
                  : 'w-[calc(100vw_-_24px)]'
              "
            >
              <app-hero-carousel-block
                [cards]="cards"
                [accentColor]="accentColor"
              ></app-hero-carousel-block>
            </div>
          }
        </div>
      </div>
    }
  </div>
</div>
