import { Store } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Injectable } from '@angular/core';

import {
  CART_FUNNEL_STORE_GENERATED_ACTIONS,
  CART_FUNNEL_STORE_LOCAL_ACTIONS,
} from '@store/cart-funnel/cart-funnel.action';
import { DoodCartFunnelStep, DoodCartFunnelValue } from '@store/cart-funnel/cart-funnel.model';

@Injectable({ providedIn: 'root' })
export class CartFunnelStoreDispatcher {
  constructor(private store: Store) {}

  // Step
  addStep(step: DoodCartFunnelStep, setAsActive = false): void {
    this.store.dispatch(CART_FUNNEL_STORE_LOCAL_ACTIONS.addStep({ step, setAsActive }));
  }

  // Value
  addValue(value: DoodCartFunnelValue): void {
    this.store.dispatch(CART_FUNNEL_STORE_GENERATED_ACTIONS.addValue({ value }));
  }

  updateValue(value: Update<DoodCartFunnelValue>): void {
    this.store.dispatch(CART_FUNNEL_STORE_GENERATED_ACTIONS.updateValue({ value }));
  }

  // Active Step
  setActive(active: string | null): void {
    this.store.dispatch(CART_FUNNEL_STORE_GENERATED_ACTIONS.setActive({ active }));
  }

  // Comment
  updateOrderComment(comment: string | null): void {
    this.store.dispatch(CART_FUNNEL_STORE_LOCAL_ACTIONS.updateOrderComment({ comment }));
  }

  funnelUsername(funnelUsername: string): void {
    this.store.dispatch(CART_FUNNEL_STORE_LOCAL_ACTIONS.setFunnelUsername({ funnelUsername }));
  }

  reset(): void {
    this.store.dispatch(CART_FUNNEL_STORE_LOCAL_ACTIONS.reset());
  }
}
