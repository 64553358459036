<div
  [ngClass]="{ 'lg:flex': !mobileView }"
  class="w-full bg-white justify-center items-center"
  #categoriesWrapper
>
  <p class="text-md lg:1xsl pt-5 lg:pt-2 lg:mr-2.5 font-accent font-bold text-center lg:text-left">
    {{ title }}
  </p>
  <p
    class="hidden lg:block lg:ml-9.5"
    *ngIf="!hidePrevBtn"
  >
    <app-rounded-icon-button
      icon="icon-arrow-left"
      color="primary"
      [outlined]="true"
      [disabled]="itemsScrolled === itemsPerPage"
      (onClick)="prevPage()"
    ></app-rounded-icon-button>
  </p>
  <ul
    *ngIf="items; else noItem"
    [style.width.px]="slideWidth"
    [ngClass]="{ 'lg:overflow-x-hidden lg:my-7': !mobileView }"
    class="flex overflow-x-auto flex-shrink-0 my-3 mx-4 scrollbar-hide"
  >
    <li
      [ngClass]="{ 'lg:w-20': !mobileView }"
      class="w-16.5 text-center flex-shrink-0 px-1 mt-1 cursor-pointer"
      [id]="'slide_' + (index + 1)"
      *ngFor="let item of items; let index = index"
      (click)="clickCard.emit(item)"
    >
      <img
        *ngIf="item.icon_url"
        src="{{ item.icon_url }}"
        alt="{{ item.name }}"
        class="max-w-48px mx-auto"
      />
      <p
        [ngClass]="{ 'lg:text-sm': !mobileView }"
        class="mt-3 text-xs font-accent font-bold break-all"
      >
        {{ item.name }}
      </p>
    </li>
  </ul>
  <ng-template #noItem>
    <ul
      [style.width.px]="slideWidth"
      class="lg:overflow-x-hidden flex overflow-x-auto flex-shrink-0 my-3 mx-4 scrollbar-hide"
    >
      <li
        class="w-16 mr-2 lg:w-20 text-center flex-shrink-0 px-1 mt-1"
        *ngFor="let j of [].constructor(4)"
      >
        <div class="w-full h-12 mb-2 animate-pulse bg-neutral-50"></div>
        <div class="h-1.5 w-full animate-pulse bg-neutral-100"></div>
      </li>
    </ul>
  </ng-template>

  <p
    class="hidden lg:block"
    *ngIf="!hideNextBtn"
  >
    <app-rounded-icon-button
      icon="icon-arrow-right"
      color="primary"
      [disabled]="itemsScrolled === items?.length"
      [outlined]="true"
      (onClick)="nextPage()"
    ></app-rounded-icon-button>
  </p>
</div>
