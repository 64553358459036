export const DEFAULT_BLOCKS = [
  {
    id: 'main-layout',
    type: 'MainLayoutComponent',
    data: {
      display_default_dood_footer: true,
    },
  },
  {
    id: 'simple-layout',
    type: 'MainLayoutComponent',
    data: {
      display_default_dood_footer: false,
      footer: [],
    },
  },

  // Back header
  {
    id: 'header-block',
    type: 'HeaderBlockComponent',
    data: {},
  },

  // Shop list
  {
    id: 'shop-around-search-block',
    type: 'ShopAroundListHeaderBlockComponent',
    data: {},
  },
  {
    id: 'shop-around-list-block',
    type: 'ShopAroundListBodyBlockComponent',
    data: {},
  },
  {
    id: 'shop-list-page',
    type: 'ShopSearchPageComponent',
    data: {
      body: ['header-block', 'shop-around-search-block', 'shop-around-list-block'],
    },
  },

  // Map page
  {
    id: 'shops-map-block',
    type: 'ShopsMapBlockComponent',
    data: {},
  },
  {
    id: 'map-page',
    type: 'ShopsMapPageComponent',
    data: {
      body: ['header-block', 'shops-map-block'],
    },
  },

  // Shop pages
  {
    id: 'shop-header-block',
    type: 'HeaderBlockComponent',
    data: {
      positionAbsoluteOnMobile: true,
    },
  },
  {
    id: 'app-header-digital-menu-block',
    type: 'HeaderDigitalMenuBlockComponent',
    data: {},
  },
  {
    id: 'shop-page-hero-block',
    type: 'ShopHeroImageBlockComponent',
    data: {},
  },
  {
    id: 'shop-page-product-list-block',
    type: 'ShopProductListBlockComponent',
    data: {},
  },
  {
    id: 'shop-page',
    type: 'ShopPageComponent',
    data: {
      body: ['shop-header-block', 'shop-page-hero-block', 'shop-page-product-list-block'],
    },
  },
  {
    id: 'product-add-page',
    type: 'ProductAddPageComponent',
    data: {},
  },
  {
    id: 'shop-digital-menu-page-product-list-block',
    type: 'ShopProductListBlockComponent',
    data: {},
  },
  {
    id: 'shop-digital-menu-page',
    type: 'ShopDigitalMenuPageComponent',
    data: {
      body: [
        'app-header-digital-menu-block',
        'shop-page-hero-block',
        'shop-digital-menu-page-product-list-block',
      ],
    },
  },
  {
    id: 'digital-menu-product-page',
    type: 'ProductAddPageComponent',
    data: {
      allow_add_to_cart: false,
    },
  },

  // Cart pages
  {
    id: 'cart-header-block',
    type: 'CartHeaderBlockComponent',
    data: {},
  },
  {
    id: 'cart-item-list-block',
    type: 'CartItemListBlockComponent',
    data: {},
  },
  {
    id: 'cart-amount-block',
    type: 'CartMerchantAmountBlockComponent',
    data: {},
  },
  {
    id: 'cart-on-site-location-block',
    type: 'CartOnSiteLocationBlockComponent',
    data: {},
  },
  {
    id: 'cart-voucher-block',
    type: 'CartVoucherBlockComponent',
    data: {},
  },
  {
    id: 'cart-tip-picker-block',
    type: 'CartTipPickerBlockComponent',
    data: {},
  },
  {
    id: 'cart-additional-information-block',
    type: 'CartAdditionalInformationBlockComponent',
    data: {},
  },
  {
    id: 'como-join-loyalty-block',
    type: 'ComoJoinLoyaltyBlockComponent',
    data: {},
  },
  {
    id: 'como-cart-gifts-block',
    type: 'ComoAssetListBlockComponent',
    data: {},
  },
  {
    id: 'como-cart-credit-balance-card-block',
    type: 'ComoCreditBalanceCardBlockComponent',
    data: {},
  },
  {
    id: 'zerosix-cart-gifts-block',
    type: 'ZerosixAssetListBlockComponent',
    data: {},
  },
  {
    id: 'pongo-cart-gifts-block',
    type: 'PongoAssetListBlockComponent',
    data: {},
  },
  {
    id: 'cart-page',
    type: 'CartPageComponent',
    data: {
      top: ['cart-header-block'],
      left: ['cart-item-list-block', 'cart-amount-block'],
      right: [
        'cart-on-site-location-block',
        'cart-voucher-block',
        'cart-tip-picker-block',
        'cart-additional-information-block',
        'como-join-loyalty-block',
        'como-cart-gifts-block',
        'como-cart-credit-balance-card-block',
        'zerosix-cart-gifts-block',
        'pongo-cart-gifts-block',
      ],
    },
  },
  {
    id: 'cart-item-edit-page',
    type: 'ItemEditPageComponent',
    data: {},
  },

  // Cart funnel page

  {
    id: 'cart-funnel-page',
    type: 'CartFunnelPageComponent',
    data: {},
  },

  {
    id: 'kiosk-cart-funnel-page',
    type: 'KioskCartFunnelPageComponent',
    data: {},
  },

  // Validate Shared Order Page
  {
    id: 'validate-shared-order-page',
    type: 'ValidateSharedOrderPageComponent',
    data: {
      body: ['validate-shared-order-header-block', 'validate-shared-order-body-block'],
    },
  },
  {
    id: 'validate-shared-order-header-block',
    type: 'ValidateSharedOrderHeaderBlock',
    data: {},
  },
  {
    id: 'validate-shared-order-body-block',
    type: 'ValidateSharedOrderBodyBlock',
    data: {},
  },

  // Payment page
  {
    id: 'payment-header-block',
    type: 'PaymentPageHeaderBlockComponent',
    data: {},
  },
  {
    id: 'payment-method-picker-block',
    type: 'PaymentMethodPickerBlockComponent',
    data: {},
  },
  {
    id: 'payment-page',
    type: 'PaymentPageComponent',
    data: {
      body: ['payment-header-block', 'payment-method-picker-block'],
    },
  },

  // Payment page
  {
    id: 'payment-confirm-page',
    type: 'PaymentConfirmPageComponent',
    data: {
      body: ['payment-header-block'],
    },
  },
  {
    id: 'group-payment-header-block',
    type: 'GroupPaymentPageHeaderBlockComponent',
    data: {},
  },
  {
    id: 'group-payment-list-block',
    type: 'GroupPaymentListBlockComponent',
    data: {},
  },
  {
    id: 'group-payment-page',
    type: 'GroupPaymentPageComponent',
    data: {
      body: ['group-payment-header-block', 'group-payment-list-block'],
    },
  },

  // Order confirmation page
  {
    id: 'order-status-block',
    type: 'OrderStatusBlockComponent',
    data: {},
  },
  {
    id: 'order-status-success-header-block',
    type: 'OrderStatusSuccessHeaderBlockComponent',
    data: {},
  },
  {
    id: 'order-status-follow-order-card-block',
    type: 'OrderStatusFollowOrderCardBlockComponent',
    data: {},
  },
  {
    id: 'order-status-page',
    type: 'OrderStatusPageComponent',
    data: {
      body: [
        'header-block',
        'order-status-success-header-block',
        'order-status-follow-order-card-block',
        'order-status-block',
      ],
    },
  },
  {
    id: 'user-feedback-block',
    type: 'UserFeedbackBlockComponent',
    data: {},
  },

  // Order details page
  {
    id: 'order-details-block',
    type: 'OrderDetailsBlockComponent',
    data: {},
  },
  {
    id: 'order-details-page',
    type: 'OrderDetailsPageComponent',
    data: {
      body: ['header-block', 'order-details-block'],
    },
  },

  // Check details page
  {
    id: 'check-details-block',
    type: 'CheckDetailsBlockComponent',
    data: {},
  },
  {
    id: 'check-details-page',
    type: 'CheckDetailsPageComponent',
    data: {
      body: ['header-block', 'check-details-block'],
    },
  },

  // Pay at table pages
  {
    id: 'pay-at-table-page',
    type: 'PatPageComponent',
    data: {},
  },
  {
    id: 'pay-at-table-overview-page',
    type: 'GenericPageComponent',
    data: {
      body: ['pay-at-table-overview-page-overview-block'],
    },
  },
  {
    id: 'pay-at-table-overview-page-overview-block',
    type: 'PatOverviewBlockComponent',
    data: {},
  },
  {
    id: 'pay-at-table-choose-amount-page',
    type: 'PatChooseAmountPageComponent',
    data: {},
  },
  {
    id: 'pay-at-table-payment-page',
    type: 'PatPaymentPageComponent',
    data: {},
  },
  {
    id: 'pay-at-table-payment-confirmation-page',
    type: 'PatPaymentConfirmationPageComponent',
    data: {},
  },

  // Account page
  {
    id: 'account-block',
    type: 'AccountPageBlockComponent',
    data: {},
  },
  {
    id: 'account-page',
    type: 'AccountPageComponent',
    data: {
      page_handle: 'account',
      body: ['header-block', 'account-block'],
    },
  },

  // Order page
  {
    id: 'orders-block',
    type: 'OrdersPageBlockComponent',
    data: {},
  },
  {
    id: 'orders-page',
    type: 'OrdersPageComponent',
    data: {
      page_handle: 'order',
      body: ['header-block', 'orders-block'],
    },
  },

  // Checks page
  {
    id: 'checks-block',
    type: 'ChecksPageBlockComponent',
    data: {},
  },
  {
    id: 'checks-page',
    type: 'ChecksPageComponent',
    data: {
      page_handle: 'check',
      body: ['header-block', 'checks-block'],
    },
  },

  // FromQrPage
  {
    id: 'from-qr-page',
    type: 'RedirectPageComponent',
    data: {
      redirectTo: '/',
    },
  },

  // Splash screen image
  {
    id: 'splash-screen-image-modal-block',
    type: 'SplashScreenImageModalBlock',
    data: {},
  },

  // Debug network connection page
  {
    id: 'debug-network-connection-block',
    type: 'DebugNetworkConnectionBlockComponent',
    data: {},
  },
  {
    id: 'debug-network-connection-page',
    type: 'GenericPage',
    data: {
      body: ['header-block', 'debug-network-connection-block'],
    },
  },
];
