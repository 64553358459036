<div
  *ngIf="heading"
  class="w-full pt-8 md:pt-12 lg:pt-14 mb-4 lg:mb-6 px-5 lg:px-12"
  id="{{ id }}"
>
  <h2
    class="font-brand text-2xl lg:text-4xl"
    [style.color]="categoryNameColor | contentBuilderColor"
  >
    {{ heading }}
  </h2>
  <p
    *ngIf="description"
    class="mt-1 lg:mt-3 text-sm md:text-base font-base"
    [style.color]="categoryDescriptionColor | contentBuilderColor"
  >
    {{ description }}
  </p>
</div>
