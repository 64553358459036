<div
  id="{{ anchor }}"
  class="mb-5 flex justify-between px-5 md:mb-10 md:px-12"
>
  <h2 class="font-accent text-xl font-bold text-neutral-800 md:text-4.5xl">
    {{ title }}
  </h2>
</div>
<div class="flex flex-shrink-0 overflow-x-auto px-5 pb-12 scrollbar-hide md:px-12">
  <app-block-factory
    *ngFor="let image of images"
    [blockId]="image"
  >
  </app-block-factory>
</div>
