<button
  *ngIf="!outlined"
  [disabled]="disabled"
  (click)="onClick.emit($event)"
  class="icon {{ icon }} {{ size }} {{ fontSize }} rounded-full"
  [ngClass]="getButtonClasses()"
  [style.backgroundColor]="overrideBackgroundColor | contentBuilderColor"
  [style.color]="overrideColor | contentBuilderColor"
  [style.borderColor]="type === 'outlined' ? (overrideColor | contentBuilderColor) : ''"
></button>
<button
  *ngIf="outlined"
  [disabled]="disabled"
  (click)="onClick.emit($event)"
  class="icon {{ icon }} {{ size }} {{ fontSize }} rounded-full"
  [ngClass]="getButtonClasses()"
  [style.backgroundColor]="overrideBackgroundColor | contentBuilderColor"
  [style.color]="overrideColor | contentBuilderColor"
></button>
