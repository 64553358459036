import { inject, Injectable } from '@angular/core';
import { DoodProductModel, IDeclinableCategory } from '@core/models/product.model';
import { DeclinableProductCart } from '@shared/local-store/cart/cart-draft-declinable-product.store';
import { ProductsService } from '@core/services/products/products.service';
import { map, Observable, take } from 'rxjs';
import { StepInfo } from '@core/models/stepper.model';
import { SimpleProductCart } from '../cart/cart-draft-simple-product.store';

type ProductStepInfo = StepInfo & {
  id: string;
  quantity: number;
};

type ProductStepDetails = ProductStepInfo & {
  product: DoodProductModel | undefined;
};

@Injectable({
  providedIn: 'root',
})
export class CompositionStepperUtil {
  private readonly productsService = inject(ProductsService);

  public declinableProductStepperComposition$({
    categories,
    cart,
  }: {
    categories: IDeclinableCategory[];
    cart: DeclinableProductCart;
  }): Observable<ProductStepDetails[] | undefined> {
    return this.productsService.selectAll$().pipe(
      take(1),
      map(allProducts => {
        return (
          this.declinableSelectableProductWithQuantityIds({ cart, categories })
            ?.map(
              selectedProductData =>
                ({
                  product: allProducts.find(allProduct => allProduct.id === selectedProductData.id),
                  ...selectedProductData,
                }) satisfies ProductStepDetails,
            )
            .map(data => ({
              data,
              categoryPosition: categories.findIndex(category => {
                return category.store_id === data.categoryId;
              }),
              simpleProductId: data.simpleProductId,
              additionGroupPosition: allProducts
                .find(product => product.id === data.simpleProductId)
                ?.additions?.products.findIndex(id => id === data.id),
            }))
            .sort((a, b) => {
              if (a.categoryPosition === b.categoryPosition) {
                return (a.additionGroupPosition ?? 0) - (b.additionGroupPosition ?? 0);
              }

              return a.categoryPosition - b.categoryPosition;
            })
            .map(({ data }) => data) ?? []
        );
      }),
    );
  }

  private declinableSelectableProductWithQuantityIds({
    cart,
    categories,
  }: {
    cart: DeclinableProductCart;
    categories: IDeclinableCategory[];
  }): ProductStepInfo[] | undefined {
    const productsIdToDisplay = cart.products
      ?.map(item => {
        const isExclusiveCategoryProduct =
          categories.find(category => {
            return category.products.find(categoryProduct => categoryProduct === item.id);
          })?.products.length === 1;
        const additionIdWithQuantity =
          item.separate_additions_by_group
            ?.map(group => {
              const countByAdditionId =
                group.selected.reduce<Record<string, number>>((acc, id) => {
                  acc[id] = (acc[id] || 0) + 1;
                  return acc;
                }, {}) ?? {};
              return Object.entries(countByAdditionId).map(([id, quantity]) => {
                const stepInfo: StepInfo = {
                  categoryId: item.store_id,
                  simpleProductId: item.id,
                  additionsGroupId: group.id,
                };
                return {
                  id,
                  quantity,
                  ...stepInfo,
                };
              });
            })
            .flat() ?? [];
        const stepInfo: StepInfo = {
          categoryId: item.store_id,
          // simpleProductId: item.id,
        };
        return [
          !isExclusiveCategoryProduct
            ? [
                {
                  id: item.id,
                  quantity: item.quantity,
                  ...stepInfo,
                },
              ]
            : [],
          ...additionIdWithQuantity,
        ];
      })
      .flat();
    return productsIdToDisplay?.flat();
  }

  public simpleProductStepperComposition$({
    cart,
    productAdditionIds,
  }: {
    cart: SimpleProductCart;
    productAdditionIds: string[];
  }): Observable<ProductStepDetails[] | undefined> {
    return this.productsService.selectAll$().pipe(
      take(1),
      map(allProducts => {
        const result =
          this.simpleProductSelectableStepsWithProductWithQuantity({ cart })
            ?.map(selectedProductData => ({
              product: allProducts.find(allProduct => allProduct.id === selectedProductData.id),
              ...selectedProductData,
            }))
            .map(data => ({
              data,
              additionGroupPosition: productAdditionIds.findIndex(id => id === data.id),
            }))
            .sort((a, b) => (a.additionGroupPosition ?? 0) - (b.additionGroupPosition ?? 0))
            .map(({ data }) => data) ?? [];
        return result;
      }),
    );
  }

  private simpleProductSelectableStepsWithProductWithQuantity({
    cart,
  }: {
    cart: SimpleProductCart;
  }): ProductStepInfo[] | undefined {
    const additionIdWithQuantity =
      cart.separate_additions_by_group
        ?.map(group => {
          const countByAdditionId =
            group.selected.reduce<Record<string, number>>((acc, id) => {
              acc[id] = (acc[id] || 0) + 1;
              return acc;
            }, {}) ?? {};
          return Object.entries(countByAdditionId).map(([id, quantity]) => {
            const stepInfo: StepInfo = {
              simpleProductId: cart.item_id,
              additionsGroupId: group.id,
            };
            return {
              id,
              quantity,
              ...stepInfo,
            };
          });
        })
        .flat() ?? [];
    return additionIdWithQuantity;
  }
}
