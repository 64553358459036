import { CommonModule } from '@angular/common';
import { Component, Input, Output } from '@angular/core';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ContentBuilderColorPipe } from '@core/pipes/content-builder-color.pipe';

@Component({
  selector: 'app-kiosk-product-overview-cart-atom',
  templateUrl: './kiosk-product-overview-cart-atom.component.html',
  styleUrls: ['./kiosk-product-overview-cart-atom.component.scss'],
  standalone: true,
  imports: [CommonModule, ContentBuilderColorPipe],
})
export class KioskProductOverviewCartAtomComponent {
  @Input() id!: string | number;
  @Input() name!: string;
  @Input() quantity!: number;
  @Input() dotColor?: IContentBuilderFieldColor;
  @Input() dotTextColor?: IContentBuilderFieldColor;
  @Input() productBackgroundColor?: IContentBuilderFieldColor;
  @Input() productTextColor?: IContentBuilderFieldColor;
  @Input() thumbnail?: string;
  @Output() cartTotalPrice?: string;

  get truncatedName(): string {
    return this.name.length > 13 ? `${this.name.slice(0, 13)}...` : this.name;
  }
}
