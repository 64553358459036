import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { DoodOrderModel } from '@store/order/order.model';
import { IKioskHardwareAuresEtkPrinter } from '@core/models/kiosk.model';
import { AuresEtkApiService } from '@core/services/api/aures-etk/aures-etk-api.service';

@Injectable({
  providedIn: 'root',
})
export class AuresEtkService {
  kioskLocalIpAddress = '';

  setPrinterConfiguration(printerConfiguration: IKioskHardwareAuresEtkPrinter): void {
    this.kioskLocalIpAddress = printerConfiguration.kiosk_local_ip_address;
  }

  transformTicketLinesToPrintDocument(ticketLines: string[], order: DoodOrderModel): any {
    const ticket: any[] = [];

    for (const line of ticketLines) {
      ticket.push({
        Type: 'text',
        value: line,
        style: 'NORMAL',
        align: 'LEFT',
        size: 'NORMAL',
        lineBreakAfter: true,
      });
    }

    ticket.push({
      Type: 'text',
      value: ' ',
      style: 'NORMAL',
      align: 'LEFT',
      size: 'NORMAL',
      lineBreakAfter: true,
    });
    ticket.push({
      Type: 'text',
      value: '#' + order.number,
      style: 'BOLD',
      align: 'CENTER',
      size: 'WX2HX2',
      lineBreakAfter: true,
    });

    return {
      origineName: this.kioskLocalIpAddress ?? 'UNKNOWN',
      ticket,
    };
  }

  constructor(private readonly auresEtkApi: AuresEtkApiService) {}

  abortTransaction$(): Observable<any> {
    return this.auresEtkApi.abortTransaction$();
  }

  createPaymentIntent$(amount: number, orderNumber?: string): Observable<any> {
    return this.auresEtkApi.createPaymentIntent$(amount, orderNumber);
  }

  changeOrderStatusToPayment$(orderId: string, paymentRef: string): Observable<DoodOrderModel> {
    return this.auresEtkApi.changeOrderStatusToPayment$(orderId, paymentRef);
  }

  printDocument$(document: any): Observable<any> {
    return this.auresEtkApi.printDocument$(document);
  }
}
