import { Router } from '@angular/router';
import { AfterContentInit, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';

import { DoodFilterModel } from '@core/models/filters.model';
import { DoodEntityCategory } from '@core/models/entity.model';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

import { Paths } from '@config/paths.config';
import { FilterStoreSelector } from '@common/selectors/filter.selector';
import { FilterStoreDispatcher } from '@common/dispatchers/filter.dispatcher';
import { FiltersModalComponent } from '@shared/modals/filters-modal/filters-modal.component';

import { ModalsService } from '@core/services/modals/modals.service';
import { HeaderService } from '@core/services/header/header.service';
import { FiltersService } from '@core/services/filters/filters.service';
import { RouterHelperService } from 'src/app/core/services/router-helper/router-helper.service';

@Component({
  selector: 'app-shop-around-list-header-block',
  templateUrl: './shop-around-list-header-block.component.html',
})
export class ShopAroundListHeaderBlockComponent implements AfterContentInit, OnDestroy {
  @Input() title = 'shop-list-header.title';
  @Input() displayTitle = true;
  @Input() displayAddressSearchInput = true;
  @Input() displayFiltersButton = true;
  @Input() displayMapButton = true;
  @Input() shopsMapButtonColor?: IContentBuilderFieldColor;
  @Input() shopsMapButtonTextColor?: IContentBuilderFieldColor;

  nbFilters$ = this.filterSelector.selectActiveFiltersLength;
  selectedFilters$ = this.filterSelector.selectActiveFilters;
  selectedCategories$ = this.filterSelector.selectCategories;

  constructor(
    private readonly router: Router,
    private filterSelector: FilterStoreSelector,
    private readonly modalsService: ModalsService,
    private readonly headerService: HeaderService,
    private filterDispatcher: FilterStoreDispatcher,
    private readonly filtersService: FiltersService,
    private readonly routerHelper: RouterHelperService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngAfterContentInit(): void {
    this.headerService.setTitle(this.displayTitle ? this.title : undefined);
    this.headerService.toggleShopSearchAddressVisibility(this.displayAddressSearchInput);
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.headerService.toggleShopSearchAddressVisibility(false);
    this.headerService.setTitle(undefined);
  }

  openFilters(): void {
    this.modalsService.open(FiltersModalComponent.handle);
  }

  removeFilter(
    filter: DoodFilterModel,
    value?: {
      id?: string | undefined;
      value: string;
    },
  ): void {
    this.filtersService.removeFilter(filter, value?.id);
  }

  removeCategoryFilter(categoryValue: {
    id?: string | undefined;
    value: DoodEntityCategory;
  }): void {
    if (!categoryValue.id) {
      return;
    }
    this.filterDispatcher.removeCategory(categoryValue.id);
  }

  navigateToMapPage(): void {
    this.router.navigate([this.routerHelper.translateRoute(`${Paths.ShopsMap}`)]);
  }
}
