import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-pat-payment-panel-adyen-googlepay',
  templateUrl: './pat-payment-panel-adyen-googlepay.component.html',
})
export class PatPaymentPanelAdyenGooglepayComponent {
  @Input() clientKey?: string;
  @Input() googlePayMerchantName?: string;
  @Input() googlePayMerchantId?: string;
  @Input() googlePayGatewayMerchantId?: string;
  @Input() paymentMethodCbAvailable?: string;
  @Input() paymentMethodPaypalAvailable = false;
  @Input() paymentMethodGooglePayAvailable = false;
  @Input() paymentMethodApplePayAvailable = false;
}
