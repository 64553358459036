import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, interval, merge, of, Subject, throwError } from 'rxjs';
import { catchError, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { OrderKeys } from '@config/keys/order.keys';

import { BasketStoreRefiner } from '@common/refiners/basket.refiner';

import { Paths } from '@config/paths.config';
import { PaymentMethodHandles } from 'src/app/config/payment-methods.config';
import { BasketStoreSelector } from '@common/selectors/basket.selector';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';

import { CartService } from '@core/services/cart/cart.service';
import { PushService } from '@core/services/push/push.service';
import { BasketService } from '@core/services/basket/basket.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { TransactionsService } from '@core/services/transactions/transactions.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { OrderStatusValues } from '@config/values/order.values';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { DoodValidateOrderResponse } from '@store/order/order.model';
import { CartParametersModalComponent } from '@shared/modals/cart-parameters-modal/cart-parameters-modal.component';
import { OrderStoreRefiner } from '@common/refiners/order.refiner';
import { ErrorService } from '@core/services/error-service/error.service';

@Component({
  selector: 'app-validate-shared-order-body-block',
  templateUrl: './validate-shared-order-body-block.component.html',
})
export class ValidateSharedOrderBodyBlockComponent
  extends DestroyerBase
  implements OnInit, OnDestroy
{
  readonly orderStatusValues = OrderStatusValues;
  readonly userId$ = this.authSelector.selectUserId;
  readonly total$ = this.basketRefiner.selectTotalOfConfirmedUsers;
  readonly basketItemsGroupByUser$ = this.basketRefiner.selectCartItemsGroupedByUser;
  readonly reconstructedBasketSubCarts$ = this.basketRefiner.selectReconstructedBasketSubCarts;

  orderStatus!: string;
  pushSubscription!: Subject<unknown>;

  constructor(
    private readonly router: Router,
    private authSelector: AuthStoreSelector,
    private orderRefiner: OrderStoreRefiner,
    private readonly cartService: CartService,
    private readonly pushService: PushService,
    private basketRefiner: BasketStoreRefiner,
    private basketSelector: BasketStoreSelector,
    private readonly orderService: OrdersService,
    private readonly modalsService: ModalsService,
    private readonly ordersService: OrdersService,
    private readonly basketService: BasketService,
    private readonly routerHelper: RouterHelperService,
    private readonly translateService: TranslateService,
    private readonly transactionsService: TransactionsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToSharedOrderUpdates();

    // TODO: Check code bellow
    combineLatest([
      this.userId$,
      this.basketSelector.select,
      this.orderService.checkCartIsValid$(),
      this.basketRefiner.selectAreAllUsersConfirmed,
    ])
      .pipe(
        takeUntil(this._destroyerRef),
        switchMap(([userId, basket, validatedOrder, isConfirmedCart]) => {
          if (isConfirmedCart && this.isTransactionPaymentMethodAvailable(validatedOrder)) {
            if (basket.user.id === userId && !basket.order) {
              return this.orderService.createOrder$(PaymentMethodHandles.Transactions).pipe(
                tap(() => {
                  this.router.navigate([
                    this.routerHelper.translateRoute(`/${Paths.GroupPayment}`),
                  ]);
                }),
                tap(order => (order ? this.orderService.setActiveOrderToStore(order) : of(null))),
              );
            }

            this.router.navigate([this.routerHelper.translateRoute(`/${Paths.GroupPayment}`)]);
            return of();
          }

          if (basket.user.id === userId && isConfirmedCart) {
            this.router.navigate([this.routerHelper.translateRoute(`/${Paths.Payment}`)]);
            return of();
          }

          return of();
        }),
        takeUntil(this._destroyerRef),
      )
      .subscribe();

    /* Open cart parameters modals if given time slot error */
    combineLatest([
      this.basketRefiner.selectIfBasketUserIsCurrentUser,
      this.orderRefiner.selectContainsAnyError(ErrorService.SLOT_ERRORS),
    ])
      .pipe(takeUntil(this._destroyerRef))
      .subscribe(([isMainBasketUser, orderContainsSlotError]) => {
        if (isMainBasketUser && orderContainsSlotError) {
          this.modalsService.open(CartParametersModalComponent.handle);
        }
      });
  }

  goHome(): void {
    this.router.navigate([this.routerHelper.translateRoute('/')]);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.basketSelector.select.pipe(take(1)).subscribe(basket => {
      this.pushService.removeSubscription(`dood/carts/${basket.id}`, this.pushSubscription);
    });
  }

  private isTransactionPaymentMethodAvailable(
    validatedOrder: DoodValidateOrderResponse | null,
  ): boolean {
    // tslint:disable-next-line:max-line-length
    return !!validatedOrder?.available_payment_methods.find(
      method => method.payment_method.handle === PaymentMethodHandles.Transactions,
    );
  }

  private subscribeToSharedOrderUpdates(): void {
    merge(
      interval(15000),
      this.basketSelector.select.pipe(
        take(1),
        switchMap(basket => this.basketService.getBasket$(basket.id, basket.share_code)),
        switchMap(basket => {
          if (basket.id) {
            this.pushSubscription = this.pushService.addSubscription(`dood/carts/${basket.id}`);
            return this.pushSubscription;
          }
          return of(null);
        }),
      ),
    )
      .pipe(
        takeUntil(this._destroyerRef),
        switchMap(() =>
          this.basketService.getBasket$(
            this.basketSelector.basket.id,
            this.basketSelector.basket.share_code,
          ),
        ),
        catchError(error => {
          if (error.status === 403) {
            this.translateService
              .get('group-order-modal.order-cancelled')
              .pipe(take(1))
              .subscribe(translation => {
                alert(translation);
                this.ordersService.clearOrders();
                this.basketService.clearBasket();
                this.cartService.clearCart();
                this.router.navigate([this.routerHelper.translateRoute('/')]);
              });
          }
          return throwError(() => error);
        }),
        switchMap(basket => {
          if (basket?.order) {
            return this.orderService.getOrderById$(basket.order);
          }
          return of(null);
        }),
      )
      .subscribe(res => {
        this.orderStatus = res?.[OrderKeys.Status] as string;

        if (this.orderStatus === OrderStatusValues.Waiting && res && res.id) {
          this.basketService.clearBasket();
          this.cartService.clearCart();
          this.orderService.clearOrders();
          this.transactionsService.clearTransactions();
          this.router.navigate([
            this.routerHelper.translateRoute(`${Paths.Orders}/${res.id}/${Paths.Status}`),
          ]);
        }
      });
  }
}
