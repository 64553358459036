import { EMPTY, Observable, of, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import {
  DoodDeliveryAddressCoordinates,
  DoodDeliveryAddressModel,
} from '@core/models/delivery-address.model';
import { PlaceResult } from '@core/models/google-maps.model';

import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { DeliveryStoreSelector } from '@common/selectors/delivery.selector';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { DeliveryStoreDispatcher } from '@common/dispatchers/delivery.dispatcher';

import { OrdersService } from '@core/services/orders/orders.service';
import { NativeService } from '@core/services/native/native.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { GoogleApiService } from '@core/services/api/google/google-api.service';
import { DeliveryAddressService } from '@core/services/delivery-address/delivery-address.service';
import { DeliveryAddressKeys } from '@config/keys/delivery-address.keys';
import { ModalScrollBlockBase } from '@core/base/modalScrollBlock/modal-scroll-block.base';
import { InputTextAtomComponent } from '@shared/atoms/input-text-atom/input-text-atom.component';

@Component({
  selector: 'app-delivery-addresses-modal',
  templateUrl: './delivery-addresses-modal.component.html',
})
export class DeliveryAddressesModalComponent
  extends ModalScrollBlockBase
  implements OnInit, OnDestroy
{
  private _destroyerRef = new Subject<boolean>();

  static handle = 'delivery-addresses-modal';

  @ViewChild(InputTextAtomComponent) input!: InputTextAtomComponent;

  index$: Observable<number> = this.modalSelector
    .selectModal(DeliveryAddressesModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  isMobile$ = this.settingsSelector.selectDeviceIsMobileScreen;

  savedDeliveryAddresses$ = this.deliverySelector.selectAddresses;

  loadingDeliveryAddresses$ = this.deliverySelector.selectIsLoading;

  isNotched = false;

  isForm = false;

  displayedValue?: string;

  geolocalizeError?: string;

  place!: PlaceResult;

  addressName?: string;

  @Input() instructions: string | null = null;

  deliveryAddressError?: string;

  saveAddressLoading = false;

  addressBeingDeleted?: string;

  constructor(
    private modalSelector: ModalStoreSelector,
    private changeDetector: ChangeDetectorRef,
    private readonly modalsService: ModalsService,
    private readonly ordersService: OrdersService,
    private deliverySelector: DeliveryStoreSelector,
    private settingsSelector: SettingsStoreSelector,
    private readonly googleApiService: GoogleApiService,
    private readonly translateService: TranslateService,
    private deliveryDispatcher: DeliveryStoreDispatcher,
    private readonly deliveryAddressService: DeliveryAddressService,
  ) {
    super();
  }

  ngOnDestroy(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
    super.ngOnDestroy();
  }

  async ngOnInit(): Promise<void> {
    this.isNotched = await NativeService.isNotched();
  }

  close(): void {
    this.modalsService.close(DeliveryAddressesModalComponent.handle);
  }

  addNewAddress(): void {
    this.isForm = true;
    this.changeDetector?.detectChanges();
    const input = this.input?.addressSearchInput.nativeElement;
    this.googleApiService
      .getAutoCompleteAddress(input)
      .pipe(
        takeUntil(this._destroyerRef),
        tap(place => this.onPlaceChanged(place)),
      )
      .subscribe();
  }

  selectAddress(): void {
    this.saveAddressLoading = true;
    const deliveryAddress = this.ordersService.placeResultToDeliveryAddress(this.place);
    if (!deliveryAddress) {
      return;
    }

    const newDeliveryAddress: Partial<DoodDeliveryAddressModel> = {
      [DeliveryAddressKeys.IsDefault]: true,
      [DeliveryAddressKeys.Street]: deliveryAddress.street_line_1,
      [DeliveryAddressKeys.City]: deliveryAddress?.city,
      [DeliveryAddressKeys.Country]: deliveryAddress?.country as string,
      [DeliveryAddressKeys.PostalCode]: deliveryAddress?.post_code
        ? deliveryAddress?.post_code
        : null,
      [DeliveryAddressKeys.Point]: deliveryAddress?.point as DoodDeliveryAddressCoordinates,
      ...(this.addressName && {
        [DeliveryAddressKeys.Name]: this.addressName,
      }),
      [DeliveryAddressKeys.Instructions]: this.instructions,
    };
    this.deliveryAddressService
      .addDeliveryAddress$(newDeliveryAddress)
      .pipe(
        catchError(err => this.handleAddDeliveryAddressError(err)),
        tap((createdAddress: DoodDeliveryAddressModel) => {
          this.deliveryDispatcher.updateActive(createdAddress.id);
        }),
        switchMap(() => this.deliveryAddressService.getDeliveryAddresses$()),
        tap(() => {
          this.saveAddressLoading = false;
          this.modalsService.close(DeliveryAddressesModalComponent.handle);
        }),
      )
      .subscribe();
  }

  handleAddDeliveryAddressError(err: any): Observable<never> {
    this.saveAddressLoading = false;
    this.deliveryAddressError = this.translateService.instant(
      'parameters.missing-delivery-address',
    );

    return EMPTY;
  }

  chooseExistingAddress(address: DoodDeliveryAddressModel): void {
    this.deliveryDispatcher.updateActive(address.id);
    this.deliveryAddressService.updateDeliveryAddress$(address).pipe(take(1)).subscribe();
    this.modalsService.close(DeliveryAddressesModalComponent.handle);
  }

  onInputNameChange(event: string): void {
    this.addressName = event;
  }

  geolocalizeUser(): void {
    this.googleApiService
      .geolocateUser$()
      .pipe(
        take(1),
        tap(place => this.onPlaceChanged(place)),
        catchError(error => {
          this.geolocalizeError = error;
          this.input.clearValue();
          return of(error);
        }),
      )
      .subscribe();
  }

  valueChanged($event: string): void {
    this.displayedValue = $event;
  }

  deleteExistingAddress(address: string): void {
    this.addressBeingDeleted = address;
    this.deliveryAddressService
      .deleteDeliveryAddress$(address)
      .pipe(
        switchMap(() => this.deliveryAddressService.getDeliveryAddresses$()),
        take(1),
        catchError(err => {
          this.addressBeingDeleted = undefined;
          return of(null);
        }),
      )
      .subscribe(() => (this.addressBeingDeleted = undefined));
  }

  private onPlaceChanged(place: PlaceResult): void {
    this.place = place;
    this.displayedValue = place.formatted_address;
    this.geolocalizeError = undefined;
  }

  forceType(data: any): any {
    return data as any;
  }
}
