<app-tag-status-atom
  *ngIf="orderStatus === orderStatusValues.Payment"
  [type]="'neutral'"
  [icon]="'icon-clock'"
  [text]="orderStatusLabels.PAYMENT.label | translate"
></app-tag-status-atom>
<app-tag-status-atom
  *ngIf="orderStatus === orderStatusValues.LookingForDelivery"
  [type]="'neutral'"
  [icon]="'icon-clock'"
  [text]="orderStatusLabels.LOOKING_FOR_DELIVERY.label | translate"
></app-tag-status-atom>
<app-tag-status-atom
  *ngIf="orderStatus === orderStatusValues.Waiting"
  [type]="'neutral'"
  [icon]="'icon-hourglass'"
  [text]="orderStatusLabels.WAITING.label | translate"
></app-tag-status-atom>
<app-tag-status-atom
  *ngIf="orderStatus === orderStatusValues.Ready"
  [type]="'success'"
  [icon]="'icon-n-check'"
  [text]="orderStatusLabels.READY.label | translate"
></app-tag-status-atom>
<app-tag-status-atom
  *ngIf="orderStatus === orderStatusValues.Collected"
  [type]="'success'"
  [icon]="'icon-n-check'"
  [text]="orderStatusLabels.COLLECTED.label | translate"
></app-tag-status-atom>
<app-tag-status-atom
  *ngIf="orderStatus === orderStatusValues.Preparation"
  [type]="'dark'"
  [icon]="'icon-chef-hat'"
  [text]="orderStatusLabels.PREPARATION.label | translate"
></app-tag-status-atom>
<app-tag-status-atom
  *ngIf="orderStatus === orderStatusValues.Cancelled"
  [type]="'error'"
  [icon]="'icon-c-remove'"
  [text]="orderStatusLabels.CANCELLED.label | translate"
></app-tag-status-atom>
