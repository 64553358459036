import { takeUntil } from 'rxjs/operators';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { DoodProductModel } from '@core/models/product.model';
import { ProductKeys } from '@config/keys/product.keys';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-product-card-list-atom',
  templateUrl: './product-card-list-atom.component.html',
})
export class ProductCardListAtomComponent extends DestroyerBase {
  @Input() displayContext = '';
  @Input() cta!: string;
  @Input() heading?: string;
  @Input() description?: string;
  @Input() products!: DoodProductModel[];
  @Input() isOrderingAllowed = true;
  @Input() categoryNameColor: IContentBuilderFieldColor = {
    value: 'neutral-900',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() categoryDescriptionColor: IContentBuilderFieldColor = {
    value: 'neutral-600',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() productNameColor: IContentBuilderFieldColor = {
    value: 'neutral-900',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() productDescriptionColor: IContentBuilderFieldColor = {
    value: 'neutral-900',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() productPriceColor: IContentBuilderFieldColor = {
    value: 'neutral-900',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() productImagePlaceholderColor: IContentBuilderFieldColor = {
    value: 'neutral-50',
    type: ColorFieldTypesValues.Palette,
  };

  @Output() productClick = new EventEmitter<DoodProductModel>();
  @Output() ctaClick = new EventEmitter();

  isDesktop = false;

  constructor(protected settingsSelector: SettingsStoreSelector) {
    super();

    this.settingsSelector.selectDeviceIsDesktopScreen
      .pipe(takeUntil(this._destroyerRef))
      .subscribe(_isDesktop => (this.isDesktop = _isDesktop));
  }

  get ProductKeys(): typeof ProductKeys {
    return ProductKeys;
  }
}
