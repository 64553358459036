import { Observable, of } from 'rxjs';
import { Component, Input } from '@angular/core';

import {
  IContentBuilderFieldColor,
  IContentBuilderFieldImage,
} from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-highlight-card-block',
  templateUrl: './highlight-card-block.component.html',
})
export class HighlightCardBlockComponent {
  @Input() displayOpenedShop = true;
  @Input() displayClosedShop = true;
  @Input() linkUrl!: string;
  @Input() title!: string;
  @Input() titleSize: 'S' | 'M' | 'L' = 'M';
  @Input() description!: string;
  @Input() descriptionSize: 'S' | 'M' | 'L' = 'S';
  @Input() image!: IContentBuilderFieldImage;
  @Input() textColor!: IContentBuilderFieldColor;
  @Input() backgroundColor!: IContentBuilderFieldColor;
  @Input() displayCtaIcon = true;
  @Input() kioskCardAspectRatio = '16/9';

  componentFactoryIsBlockVisible$(): Observable<boolean> {
    return of(this.displayOpenedShop);
  }
}
