import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-legals-footer-block',
  templateUrl: './legals-footer-block.component.html',
})
export class LegalsFooterBlockComponent {
  @Input() links: unknown[] = [];
  @Input() displayPoweredByDood = true;

  hasLinks(): boolean {
    return Array.isArray(this.links) && this.links.length > 0;
  }
}
