import { Component } from '@angular/core';
import { AnchorStoreSelector } from '@common/selectors/anchor.selector';

@Component({
  selector: 'app-anchor-buttons-list-block',
  templateUrl: './anchor-buttons-list-block.component.html',
})
export class AnchorButtonsListBlockComponent {
  anchors$ = this.anchorSelector.selectAnchors;
  constructor(private anchorSelector: AnchorStoreSelector) {}
}
