import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-checkbox-atom',
  templateUrl: './checkbox-atom.component.html',
  styles: ``,
})
export class CheckboxAtomComponent {
  @Input() isChecked: boolean = false;
  @Input() label?: string;
  @Input() type!: string;
  @Input() color?: IContentBuilderFieldColor;

  @Input() size: 'S' | 'M' | 'L' = 'S';

  @Output() isCheckedChange = new EventEmitter<boolean>();

  toggleCheck() {
    this.isChecked = !this.isChecked;
    this.isCheckedChange.emit(this.isChecked);
  }
}
