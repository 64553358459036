<div
  *ngIf="isLoading"
  class="bg-white flex justify-center items-center h-32"
>
  <i class="icon icon-privacy text-4xl animate-pulse"></i>
</div>

<div
  *ngIf="!isLoading && isUserLoggedIn"
  class="p-3 pb-7 pt-0 flex flex-col text-neutral-600"
>
  <div>{{ 'payment.edenred-picker.connected-account' | translate }}</div>
  <div *ngIf="balance">
    {{ 'payment.edenred-picker.amount-of-day' | translate }}
    {{ balance / 100 | currency: 'EUR' : 'symbol' : undefined : 'fr-FR' }}
  </div>
  <div
    *ngIf="processingError"
    class="text-secondary-600"
  >
    {{ processingError }}
  </div>
</div>

<div *ngIf="!isUserLoggedIn && ssoEdenredUrl">
  <div class="p-3 pb-7 pt-0 flex flex-col justify-center items-center text-neutral-600">
    <app-button-atom
      [label]="'Connecter mon compte Edenred'"
      [thickSize]="'S'"
      [color]="'primary'"
      (click)="redirectToSsoEdenred()"
    >
    </app-button-atom>
  </div>
</div>
