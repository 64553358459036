<app-modal-filler-atom [index]="80"></app-modal-filler-atom>
<div
  class="fixed right-0 left-0 shadow overflow-y-auto mdMax:bottom-0 mdMax:max-h-85-screen mdMax:max-w-screen-sm mdMax:mx-auto md:flex md:justify-center md:items-center md:w-full md:h-full md:top-0 md:left-0"
  [style.z-index]="200"
>
  <div
    class="bg-white p-5 md:p-7 rounded-t-3xl md:rounded-3xl md:max-w-lg md:w-2/5"
    [style.z-index]="200"
  >
    <h1 class="text-neutral-800 font-brand font-bold text-28px pb-2">Kiosk</h1>
    <div class="flex-1 mr-2.5 pb-6">
      <p class="mb-2 text-neutral-600 font-base">Numéro de kiosk</p>
      <div class="text-neutral-800 leading-5 pb-6">
        <div
          class="flex flex-row items-center text-md"
          *ngIf="isLoading"
        >
          <img
            src="assets/images/spinner.svg"
            alt=""
            class="pr-2"
          />
          Chargement en cours
        </div>

        <div
          class="flex flex-row items-center text-md"
          *ngIf="!isLoading && kiosks.length === 0"
        >
          Aucun kiosk disponible sur cette marketplace.
        </div>

        <select
          *ngIf="!isLoading && kiosks.length > 0"
          [(ngModel)]="selectedKioskId"
          (change)="onChangeKiosk()"
          class="form-select cursor-pointer pl-4 py-2.5 block w-full bg-neutral-100 rounded-full font-base text-base font-normal"
        >
          <option
            *ngFor="let kiosk of kiosks"
            [ngValue]="kiosk.id"
          >
            {{ kiosk.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="flex flex-col gap-10">
      <app-button-atom
        size="L"
        [label]="'Continuer'"
        type="primary"
        [full]="true"
        (click)="save()"
      ></app-button-atom>
    </div>
  </div>
</div>
