import { tap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';

import { IPongoAsset } from '@core/models/pongo.model';
import { PongoService } from '@core/services/pongo/pongo.service';

@Component({
  selector: 'app-pongo-marketplace-rewards-list-block',
  templateUrl: './pongo-marketplace-rewards-list-block.component.html',
})
export class PongoMarketplaceRewardsListBlockComponent implements OnInit {
  isLoading = true;
  rewards: IPongoAsset[] = [];

  constructor(private readonly pongoService: PongoService) {}

  ngOnInit(): void {
    this.loadMarketplaceRewards();
  }

  private loadMarketplaceRewards(): void {
    this.pongoService
      .getMarketplaceRewards$()
      .pipe(
        tap(rewards => (this.rewards = rewards)),
        tap(() => (this.isLoading = false)),
      )
      .subscribe();
  }
}
