<div class="px-4 py-3 pr-28 overflow-x-auto whitespace-nowrap scrollbar-hide">
  <span
    *ngIf="text"
    class="text-sm"
    >{{ text }}</span
  >
  <ng-container *ngFor="let item of items">
    <button
      *ngIf="item.label"
      class="bg-neutral-100 rounded-3xl text-sm font-accent font-semibold py-1.5 px-3 mx-2"
      (click)="clickItem.emit(item)"
    >
      {{ item.label }}
    </button>
  </ng-container>
</div>
