<div class="pt-4">
  <p class="font-accent font-bold mt-5 mb-3 md:mt-6 text-md">
    {{ title }}
    <span *ngIf="required">&#42;</span>
  </p>

  <app-block-list
    [blockIdList]="options"
    [injectData]="{ parentId: blockId }"
  ></app-block-list>
</div>
