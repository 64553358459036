import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appPhoneMask]',
})
export class PhoneMaskDirective {
  private phoneConfig = [
    '0',
    /[0-9]/,
    ' ',
    /[0-9]/,
    /[0-9]/,
    ' ',
    /[0-9]/,
    /[0-9]/,
    ' ',
    /[0-9]/,
    /[0-9]/,
    ' ',
    /[0-9]/,
    /[0-9]/,
  ];

  constructor(
    public ngControl: NgControl,
    private elRef: ElementRef,
  ) {}

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event: string): void {
    this.onInputChange(event);
  }

  @HostListener('focusout', ['$event.target.value'])
  onBlur(value: string): void {
    this.validateInput(value);
  }

  validateInput(value: string): void {
    if (!/^[0-9 ]+$/g.test(value)) {
      this.ngControl.control?.setErrors({ phone: true });
    }
  }

  onInputChange(inputValue: string): void {
    const { selectionStart } = this.elRef.nativeElement;
    if (inputValue.length > 1) {
      inputValue = inputValue.substring(1);
      inputValue = inputValue.substring(0, selectionStart - 1);
    }
    const pureStr = inputValue.replace(/[^0-9]/g, '');
    let numberIndex = 0;
    let symbolsBefore = 1;
    inputValue = this.phoneConfig.reduce((str: string, reg: any) => {
      if (reg instanceof RegExp) {
        const chart = pureStr[numberIndex] ? pureStr[numberIndex] : '';
        numberIndex++;
        return str + (reg.test(chart) ? chart : '_');
      } else {
        if (numberIndex < pureStr.length) {
          symbolsBefore++;
        }
        return str + reg;
      }
    }, '');
    this.ngControl.valueAccessor?.writeValue(inputValue);
    const cursorPosition = symbolsBefore + pureStr.length - 1;
    this.elRef.nativeElement.setSelectionRange(cursorPosition, cursorPosition);
  }
}
