import { UntypedFormGroup } from '@angular/forms';
import { debounceTime, map } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';

export type AuthenticationForm = Record<string, string | number | boolean> | null;

@Injectable({ providedIn: 'root' })
export class AuthenticationFormState implements OnDestroy {
  private _formSubscription: Subscription | null = null;

  private _valid = false;
  private _form: UntypedFormGroup | null = null;
  private _value: AuthenticationForm = null;

  readonly onStatusChange = new BehaviorSubject<boolean>(this._valid);
  readonly onValueChange = new BehaviorSubject<AuthenticationForm>(this._value);

  get form(): UntypedFormGroup | null {
    return this._form;
  }

  get valid(): boolean {
    return this._valid;
  }

  get value(): AuthenticationForm {
    return this._value;
  }

  ngOnDestroy(): void {
    this.clean();
  }

  upsert(_form: UntypedFormGroup): void {
    this.clean();
    this._updateForm(_form);
    this._formSubscription = combineLatest([_form.valueChanges, _form.statusChanges])
      .pipe(debounceTime(200))
      .subscribe(() => {
        this._updateState();
        this.onValueChange.next(this._value);
      });
  }

  select(): Observable<AuthenticationForm> {
    return this.onValueChange;
  }

  selectValue<T = string | number>(key: string): Observable<T | undefined> {
    return this.onValueChange.pipe(
      map(data => {
        return data ? (data[key] as unknown as T) : undefined;
      }),
    );
  }

  clean(): void {
    this._formSubscription?.unsubscribe();
    this._formSubscription = null;
    this._form = null;
    this._setValid(false);
    this._setValue(null);
  }

  private _updateForm(_form: UntypedFormGroup | null): void {
    this._form = _form;
    this._updateState();
  }

  private _updateState(): void {
    this._setValue(this._form?.value ?? {});
    this._setValid(this._form?.valid ?? false);
  }

  private _setValue(_value: AuthenticationForm): void {
    const _changed = this._value !== _value;
    this._value = { ...this._value, ..._value };
    if (_changed) {
      this.onValueChange.next(this._value);
    }
  }

  private _setValid(_valid: boolean): void {
    const _changed = this._valid !== _valid;
    this._valid = _valid;
    if (_changed) {
      this.onStatusChange.next(this._valid);
    }
  }
}
