import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import {
  TRANSACTION_STORE_ACTIVE_ACTIONS,
  TRANSACTION_STORE_LOCAL_ACTIONS,
} from '@store/transaction';
import { DoodTransactionModel } from '@core/models/transaction.model';

@Injectable({ providedIn: 'root' })
export class TransactionStoreDispatcher {
  constructor(private store: Store) {}

  // Many
  upsertMany(entities: DoodTransactionModel[]): void {
    this.store.dispatch(TRANSACTION_STORE_ACTIVE_ACTIONS.upsertMany({ entities }));
  }

  // One
  addOne(entity: DoodTransactionModel): void {
    this.store.dispatch(TRANSACTION_STORE_ACTIVE_ACTIONS.addOne({ entity }));
  }

  upsertOne(entity: DoodTransactionModel): void {
    this.store.dispatch(TRANSACTION_STORE_ACTIVE_ACTIONS.upsertOne({ entity }));
  }

  // Active
  setActive(active: string | null): void {
    this.store.dispatch(TRANSACTION_STORE_ACTIVE_ACTIONS.setActive({ active }));
  }

  insertActive(entity: DoodTransactionModel): void {
    this.store.dispatch(TRANSACTION_STORE_ACTIVE_ACTIONS.insertActive({ entity }));
  }

  resetActive(): void {
    this.store.dispatch(TRANSACTION_STORE_ACTIVE_ACTIONS.resetActive());
  }

  // Payment Mode
  updatePaymentMode(mode: string | null): void {
    this.store.dispatch(TRANSACTION_STORE_LOCAL_ACTIONS.updatePaymentMode({ mode }));
  }

  // Amount
  updateAmount(amount: number | null): void {
    this.store.dispatch(TRANSACTION_STORE_LOCAL_ACTIONS.updateAmount({ amount }));
  }

  // Error
  updateError(error: string | null): void {
    this.store.dispatch(TRANSACTION_STORE_LOCAL_ACTIONS.updateError({ error }));
  }

  reset(): void {
    this.store.dispatch(TRANSACTION_STORE_LOCAL_ACTIONS.reset());
  }
}
