import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, switchMap, take } from 'rxjs/operators';

import { DateUtils } from '@shared/utils/date/date.utils';

import {
  CART_STORE_ACTIVE_ACTIONS,
  CART_STORE_COMPONENT_ACTIONS,
  CART_STORE_HYDRATION_ACTIONS,
  CART_STORE_LOCAL_ACTIONS,
} from '@store/cart/cart.action';
import {
  CART_FUNNEL_STORE_HYDRATION_ACTIONS,
  CART_FUNNEL_STORE_LOCAL_ACTIONS,
} from '@store/cart-funnel/cart-funnel.action';

import { SELECT_CART_STATE } from '@store/cart/cart.selection';

@Injectable({
  providedIn: 'root',
})
export class CartEffects {
  private timerId: NodeJS.Timeout | null = null;

  constructor(
    private store: Store,
    private _actions: Actions,
  ) {}

  // Last Update
  updateLastUpdate = createEffect(() => {
    return this._actions.pipe(
      ofType(
        // One
        CART_STORE_ACTIVE_ACTIONS.addOne,
        CART_STORE_ACTIVE_ACTIONS.mapOne,
        CART_STORE_ACTIVE_ACTIONS.removeOne,
        CART_STORE_ACTIVE_ACTIONS.updateOne,
        CART_STORE_ACTIVE_ACTIONS.upsertOne,
        // Many
        CART_STORE_ACTIVE_ACTIONS.addMany,
        CART_STORE_ACTIVE_ACTIONS.mapMany,
        CART_STORE_ACTIVE_ACTIONS.removeMany,
        CART_STORE_ACTIVE_ACTIONS.updateMany,
        CART_STORE_ACTIVE_ACTIONS.upsertMany,
        // Active
        CART_STORE_ACTIVE_ACTIONS.setActive,
        CART_STORE_ACTIVE_ACTIONS.resetActive,
        CART_STORE_ACTIVE_ACTIONS.updateActive,
        CART_STORE_ACTIVE_ACTIONS.upsertActive,
        CART_STORE_ACTIVE_ACTIONS.insertActive,
        // Last Update
        CART_STORE_COMPONENT_ACTIONS.checkLastCartUpdate,
      ),
      switchMap(() => this.store.select(SELECT_CART_STATE).pipe(take(1))),
      mergeMap(state => {
        const now = new Date();
        const hour = 3 * 3600 * 1000;
        const actions: Action[] = [];

        const last_update = new Date(
          DateUtils.dayjsInMarketplaceTimezone(state.last_update).format(),
        );
        if (!this.timerId && last_update && !DateUtils.isLessThanFromNow(last_update, hour)) {
          actions.push(CART_STORE_LOCAL_ACTIONS.reset());
        }
        this._setTimer(hour);
        return [...actions, CART_STORE_LOCAL_ACTIONS.updateLastUpdate({ last_update: now })];
      }),
    );
  });

  // Timeout
  updateStoresOnTimeout = createEffect(() => {
    return this._actions.pipe(
      ofType(CART_STORE_COMPONENT_ACTIONS.updateOnTimeout),
      map(() => CART_STORE_LOCAL_ACTIONS.reset()),
    );
  });

  // Reset
  resetAndCleanCartStores = createEffect(() => {
    return this._actions.pipe(
      ofType(CART_STORE_COMPONENT_ACTIONS.resetAndClearCartStores),
      mergeMap(() => [
        CART_FUNNEL_STORE_LOCAL_ACTIONS.reset(),
        CART_FUNNEL_STORE_HYDRATION_ACTIONS.clear(),
        CART_STORE_COMPONENT_ACTIONS.resetAndClear(),
        CART_STORE_HYDRATION_ACTIONS.disabledHydrationAndClear(),
      ]),
    );
  });

  resetAndClean = createEffect(() => {
    return this._actions.pipe(
      ofType(CART_STORE_COMPONENT_ACTIONS.resetAndClear),
      mergeMap(() => [CART_STORE_LOCAL_ACTIONS.reset(), CART_STORE_HYDRATION_ACTIONS.clear()]),
    );
  });

  private _setTimer(interval: number): void {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
    this.timerId = setInterval(() => {
      this.store.dispatch(CART_STORE_COMPONENT_ACTIONS.updateOnTimeout());
    }, interval);
  }
}
