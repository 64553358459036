import { Component, Input, OnInit } from '@angular/core';
import { Observable, of, timer, from } from 'rxjs';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { takeUntil, tap } from 'rxjs/operators';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { Router } from '@angular/router';
import { ResetStateService } from '@core/services/reset-state.service';

@Component({
  selector: 'app-timer-redirect-block',
  template: ``,
})
export class TimerRedirectBlockComponent extends DestroyerBase implements OnInit {
  @Input() redirectTo?: string;
  @Input() duration?: number;
  @Input() resetUI = false;

  constructor(
    private readonly routerHelper: RouterHelperService,
    private readonly router: Router,
    private readonly resetStateService: ResetStateService,
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.duration || !this.redirectTo) {
      return;
    }

    timer(this.duration * 1000)
      .pipe(
        takeUntil(this._destroyerRef),
        tap(() => {
          this.resetUIIfNeeded().subscribe(() => {
            if (!this.redirectTo) {
              return;
            }
            if (this.routerHelper.isLinkExternal(this.redirectTo)) {
              window.location.href = this.redirectTo;
            } else {
              this.router.navigate([this.routerHelper.translateRoute(this.redirectTo)]);
            }
          });
        }),
      )
      .subscribe();
  }

  private resetUIIfNeeded(): Observable<any> {
    if (this.resetUI) {
      return from(this.resetStateService.refreshUI());
    }

    return of(null);
  }
}
