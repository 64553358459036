import { keyBy } from 'lodash';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ICountry } from '@shared/components/input-country-phone/models/country.models';
import { getAllCountries } from '@shared/components/input-country-phone/config/countries.config';

@Component({
  selector: 'app-input-country-phone',
  templateUrl: './input-country-phone.component.html',
  styleUrls: ['./input-country-phone.component.scss'],
})
export class InputCountryPhoneComponent implements OnInit {
  @Input() selectedIso?: string;
  @Input() phone?: string;
  @Input() alternativeStyle = false;
  @Input() listOnTop = false;
  @Output() updatePhone = new EventEmitter<string>();

  countries: ICountry[] = getAllCountries();

  placeholder?: string;

  prefix?: string;

  listOpen = false;

  inputActive = false;

  constructor() {}

  ngOnInit(): void {
    if (this.phone) {
      const matchedCountry = this.getCountryFromPhoneNumber(this.phone);
      if (matchedCountry) {
        this.selectedIso = matchedCountry.iso2;
      }
    }
    if (!this.phone && this.selectedIso) {
      const countriesIndexed = keyBy(this.countries, 'iso2');
      const dialCode = countriesIndexed[this.selectedIso]?.dialCode;
      if (dialCode) {
        this.phone = `+${dialCode}`;
      }
    }
  }

  toggleCountriesList(): void {
    this.listOpen = !this.listOpen;
  }

  selectCountry(iso: string | undefined): void {
    if (!iso) {
      return;
    }
    const countriesIndexed = keyBy(this.countries, 'iso2');
    this.selectedIso = iso;
    const dialCode = countriesIndexed[iso]?.dialCode;
    if (dialCode) {
      this.phone = `+${dialCode}`;
    }
    this.listOpen = false;
  }

  onChangePhone(): void {
    const matchedCountry = this.getCountryFromPhoneNumber(this.phone || '');
    if (matchedCountry) {
      this.selectedIso = matchedCountry.iso2;
    }
    this.updatePhone.emit(this.phone);
  }

  detectActive(): void {
    this.inputActive = !this.inputActive;
    if (!this.inputActive) {
      this.listOpen = false;
    }
  }

  private getCountryFromPhoneNumber(phoneNumber: string): ICountry | null {
    // Nettoyage du numéro (suppression des espaces et des tirets éventuels)
    phoneNumber = phoneNumber.replace(/[^0-9+]/g, '');

    // On retire le "+" pour ne garder que les chiffres
    let numberWithoutPlus = phoneNumber.replace(/^\+/, '');

    const allCountriesWithComparableDialCode = this.countries
      .map(country => {
        if (country.areaCodes) {
          return country.areaCodes.map(areaCode => {
            return {
              comparableDialCode: country.dialCode + areaCode,
              ...country,
            };
          });
        }
        return {
          comparableDialCode: country.dialCode,
          ...country,
        };
      })
      .flat();
    const fullMatchDialCodeCountry = this.recursiveFilteringCountriesByDialCodeAreaFullMatch(
      allCountriesWithComparableDialCode,
      numberWithoutPlus,
    );
    if (fullMatchDialCodeCountry) {
      return fullMatchDialCodeCountry;
    }
    return this.recursiveFilteringCountriesByDialCodePriority(this.countries, numberWithoutPlus);
  }

  private recursiveFilteringCountriesByDialCodeAreaFullMatch(
    allCountriesWithComparableDialCode: {
      name: string;
      iso2: string;
      dialCode: string;
      priority: number;
      areaCodes: string[] | null;
      comparableDialCode: string;
    }[],
    phoneNumber: string,
    index: number = 0,
  ):
    | (ICountry & {
        comparableDialCode: string;
      })
    | null {
    if (index >= phoneNumber.length) {
      return null;
    }
    if (phoneNumber.length < 4) {
      return null;
    }
    const dialCode = phoneNumber.substring(0, index + 1);
    const filteredCountries = allCountriesWithComparableDialCode.filter(country => {
      return country.comparableDialCode.startsWith(dialCode);
    });
    if (filteredCountries.length === 1) {
      const matchedCountry = filteredCountries[0];
      const isFullDialCodeMatch = dialCode === matchedCountry.comparableDialCode;
      if (!isFullDialCodeMatch) {
        return null;
      }
      return filteredCountries[0];
    }
    if (filteredCountries.length === 0) {
      return null;
    }
    return this.recursiveFilteringCountriesByDialCodeAreaFullMatch(
      allCountriesWithComparableDialCode,
      phoneNumber,
      index + 1,
    );
  }

  private recursiveFilteringCountriesByDialCodePriority(
    allCountriesWithComparableDialCode: ICountry[],
    phoneNumber: string,
    index: number = 0,
  ): ICountry | null {
    if (index >= phoneNumber.length) {
      return null;
    }
    if (phoneNumber.length < 4) {
      return null;
    }
    const dialCode = phoneNumber.substring(0, index + 1);
    const filteredCountries = allCountriesWithComparableDialCode.filter(country => {
      return country.dialCode.startsWith(dialCode);
    });
    if (filteredCountries.length === 1) {
      return filteredCountries[0];
    }
    if (filteredCountries.length === 0) {
      return null;
    }
    const result = this.recursiveFilteringCountriesByDialCodePriority(
      filteredCountries,
      phoneNumber,
      index + 1,
    );
    if (result) {
      return result;
    }
    const matchedCountryWithHighestPriority = filteredCountries.reduce((acc, country) => {
      return acc.priority < country.priority ? acc : country;
    });
    return matchedCountryWithHighestPriority;
  }
}
