import { Injectable, OnDestroy } from '@angular/core';

@Injectable()
export class ModalScrollBlockBase implements OnDestroy {
  constructor() {
    const bodyElement = document.body;
    bodyElement.classList.add('block-vertical-scroll');
  }

  ngOnDestroy(): void {
    const bodyElement = document.body;
    bodyElement.classList.remove('block-vertical-scroll');
  }
}
