import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { PaymentUtils } from '@app/utilities/payment.util';
import { PaymentStoreSelector } from '@app/common/selectors/payment.selector';
import { PAYMENT_METHODS } from '@config/payment-methods.config';
import { PAT_PAYMENT_METHODS } from '@config/pat-payment-methods.config';

@Injectable({ providedIn: 'root' })
export class PaymentStoreRefiner {
  selectPaymentMethodsWithDescriptors = this.selector.selectMethods.pipe(
    map(d => PaymentUtils.mapMethodsWithDescriptors(d, PAYMENT_METHODS)),
  );

  selectPatPaymentMethodsWithDescriptors = this.selector.selectMethods.pipe(
    map(d => PaymentUtils.mapMethodsWithDescriptors(d, PAT_PAYMENT_METHODS)),
  );

  selectDoesHaveBancontactInformation = this.selector.selectBancontact.pipe(
    map(b => b.complete_name && b.email),
  );

  constructor(private selector: PaymentStoreSelector) {}
}
