import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-input-comment-atom',
  templateUrl: './input-comment-atom.component.html',
})
export class InputCommentAtomComponent {
  @Input() text!: string;
  @Input() comment!: string;

  @Output() closeComment = new EventEmitter();
  @Output() applyComment = new EventEmitter<string>();
}
