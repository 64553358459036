const RADIANS: number = 180 / 3.14159265;
const METRES_IN_MILE = 1609.34;
export class DistanceUtils {
  static formatDistance(distance: number): string {
    if (distance < 1000) {
      return `${Math.ceil(distance)} m`;
    } else {
      return ` ${DistanceUtils.round(Math.round((distance * 10) / 10) / 1000, 1)} km`;
    }
  }

  static calculateDistanceBetweenCoordinates(
    x1: number,
    y1: number,
    x2: number,
    y2: number,
  ): number {
    if (x1 === x2 && y1 === y2) {
      return 0;
    } else {
      // Calculating Distance between Points
      const lt1 = x1 / RADIANS;
      const lg1 = y1 / RADIANS;
      const lt2 = x2 / RADIANS;
      const lg2 = y2 / RADIANS;

      // radius of earth in miles (3,958.8) * metres in a mile * position on surface of sphere...
      return (
        3958.8 *
        METRES_IN_MILE *
        Math.acos(
          Math.sin(lt1) * Math.sin(lt2) + Math.cos(lt1) * Math.cos(lt2) * Math.cos(lg2 - lg1),
        )
      );
    }
  }

  static round(value: number, precision: number): number {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }
}
