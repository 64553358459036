<div class="bg-neutral-100 px-5 pb-4">
  <a [link]="link?.url">
    <app-fat-button-atom
      [topText]="heading ?? ''"
      [backgroundColor]="'bg-primary-600'"
      [headingSize]="'M'"
      [bottomText]="text ?? ''"
      [backgroundImage]="'assets/images/food-society/fat-button-texture.png'"
    ></app-fat-button-atom>
  </a>
</div>
