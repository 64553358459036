import { map, takeUntil } from 'rxjs/operators';
import { Component, Input } from '@angular/core';

import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { ModalsService } from '@core/services/modals/modals.service';
import { ModalStoreSelector } from '@common/selectors/modal.selector';

@Component({
  selector: 'app-share-user-feedback-modal',
  templateUrl: './share-user-feedback-modal.component.html',
  styleUrls: ['./share-user-feedback-modal.component.scss'],
})
export class ShareUserFeedbackModalComponent extends DestroyerBase {
  static handle = 'share-user-feedback';

  index = 0;

  @Input() title = '';
  @Input() description = '';
  @Input() items: unknown[] = [];

  constructor(
    private modalService: ModalsService,
    private modalSelector: ModalStoreSelector,
  ) {
    super();
    this.modalSelector
      .selectModal(ShareUserFeedbackModalComponent.handle)
      .pipe(
        takeUntil(this._destroyerRef),
        map(el => (el?.index ? el.index : 4)),
      )
      .subscribe(_index => {
        this.index = _index;
      });
  }

  closeModal(_event: Event): void {
    this.modalService.close(ShareUserFeedbackModalComponent.handle);
  }
}
