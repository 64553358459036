import { ReducerTypes, ActionCreator, on } from '@ngrx/store';

import {
  OrderState,
  ORDER_ERROR_INITIAL_STATE,
  ORDER_STORE_INITIAL_STATE,
  ORDER_STORE_KEY,
  ORDER_STORE_ACTIVE_ADAPTER,
} from './order.state';
import {
  ORDER_STORE_ACTIVE_ACTIONS,
  ORDER_STORE_HYDRATION_ACTIONS,
  ORDER_STORE_LOCAL_ACTIONS,
} from './order.action';
import { DoodOrderModel } from './order.model';

import { createActiveStorePiece } from '@store/_abstract/active';
import { createHydratedStorePiece as createHydratedStorePiece } from '@store/_abstract/hydrated';

export const ORDER_STORE_API_PIECE: ReducerTypes<OrderState, readonly ActionCreator[]>[] = [];

export const ORDER_STORE_LOCAL_PIECE: ReducerTypes<OrderState, readonly ActionCreator[]>[] = [
  // Status
  on(ORDER_STORE_LOCAL_ACTIONS.updateIsLoaded, (state, { isLoaded }): OrderState => {
    return { ...state, isLoaded };
  }),
  on(ORDER_STORE_LOCAL_ACTIONS.updateIsLoading, (state, { isLoading }): OrderState => {
    return { ...state, isLoading };
  }),
  on(ORDER_STORE_LOCAL_ACTIONS.updateIsValidating, (state, { isValidating }): OrderState => {
    return { ...state, isValidating };
  }),

  // Validation
  on(ORDER_STORE_LOCAL_ACTIONS.updateValidation, (state, { validation }): OrderState => {
    return { ...state, validation };
  }),
  on(ORDER_STORE_LOCAL_ACTIONS.resetValidation, (state): OrderState => {
    return { ...state, validation: null };
  }),

  // Error
  on(ORDER_STORE_LOCAL_ACTIONS.updateErrors, (state, { errors }): OrderState => {
    return { ...state, errors: { ...state.errors, ...errors } };
  }),
  on(ORDER_STORE_LOCAL_ACTIONS.resetErrors, (state): OrderState => {
    return { ...state, errors: ORDER_ERROR_INITIAL_STATE };
  }),

  // Misc
  on(ORDER_STORE_LOCAL_ACTIONS.reset, (): OrderState => ORDER_STORE_INITIAL_STATE),
];

export const ORDER_STORE_ACTIVE_PIECE = createActiveStorePiece<OrderState, DoodOrderModel>({
  adapter: ORDER_STORE_ACTIVE_ADAPTER,
  actions: ORDER_STORE_ACTIVE_ACTIONS,
});

export const ORDER_STORE_HYDRATION_PIECE = createHydratedStorePiece<OrderState>(
  ORDER_STORE_KEY,
  ORDER_STORE_HYDRATION_ACTIONS,
);
