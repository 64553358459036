<div class="p-5 bg-white rounded-t-3xl md:rounded-3xl">
  <div class="flex justify-between items-center">
    <h2 class="font-accent font-bold text-3xs">
      {{ 'comment-modal.title' | translate }}
    </h2>
  </div>
  <p class="mt-5 mb-2 text-neutral-600 font-base">{{ text | translate }}</p>
  <div class="text-neutral-800 leading-5 pb-6">
    <textarea
      [(ngModel)]="comment"
      class="w-full border border-neutral-800 rounded py-4.5 px-5 lg:w-448px"
      autofocus
    ></textarea>
  </div>
  <div class="pt-1 flex justify-between items-center">
    <div class="mr-2 xm:pr-1 w-full xm:w-1/2">
      <app-button-atom
        size="M"
        [label]="'comment-modal.cancel' | translate"
        color="primary"
        (click)="closeComment.emit()"
        type="light"
        [full]="true"
      ></app-button-atom>
    </div>
    <div class="xm:pl-1 w-full xm:w-1/2">
      <app-button-atom
        size="M"
        [label]="'comment-modal.validate' | translate"
        (click)="applyComment.emit(comment)"
        type="primary"
        [full]="true"
      ></app-button-atom>
    </div>
  </div>
</div>
