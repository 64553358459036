import { map } from 'rxjs/operators';
import { Component } from '@angular/core';

import { DoodOrderModel } from '@store/order/order.model';
import { OrderStoreSelector } from '@common/selectors/order.selector';

@Component({
  selector: 'app-cart-pickup-location-block',
  templateUrl: './cart-pickup-location-block.component.html',
})
export class CartPickupLocationBlockComponent {
  orderPickupLocation$ = this.orderSelector.selectValidation.pipe(
    map(order => (order as DoodOrderModel)?.pickup_location),
  );

  constructor(private orderSelector: OrderStoreSelector) {}
}
