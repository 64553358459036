import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';
import { StatusBar, StatusBarInfo } from '@capacitor/status-bar';

@Injectable({
  providedIn: 'root',
})
export class NativeService {
  static native: boolean;
  static platform: 'ios' | 'android' | 'web';
  static notched: boolean;
  static statusBarInfo: StatusBarInfo;

  constructor() {}

  static async isNative(): Promise<boolean> {
    if (this.native === undefined) {
      const platform = (await Device.getInfo()).platform;
      this.native = platform.length > 0 && platform !== 'web';
      NativeService.platform = platform;
    }
    return this.native;
  }

  static async isNativeAndroid(): Promise<boolean> {
    return (await Device.getInfo()).platform === 'android';
  }

  static async isNativeIos(): Promise<boolean> {
    return (await Device.getInfo()).platform === 'ios';
  }

  static async getStatusBar(): Promise<StatusBarInfo> {
    if (NativeService.statusBarInfo === undefined) {
      NativeService.statusBarInfo = await StatusBar.getInfo();
    }
    return NativeService.statusBarInfo;
  }

  static async isNotched(): Promise<boolean> {
    if (await NativeService.isNative()) {
      const device = await Device.getInfo();
      const statusBarInfo = await NativeService.getStatusBar();
      const reqExNotNotchIphones = new RegExp(/(5|6|7|8|se)/g);
      NativeService.notched = !!(
        (statusBarInfo.hasOwnProperty('overlays') && statusBarInfo.overlays === true) ||
        (device.model &&
          device.model.toLowerCase().indexOf('iphone') >= 0 &&
          !reqExNotNotchIphones.test(device.model.toLowerCase()))
      );

      return NativeService.notched;
    } else {
      return false;
    }
  }

  isInAppBrowser(): boolean {
    const ua = navigator.userAgent || navigator.vendor;
    const isInstagramInAppBrowser = ua.indexOf('Instagram') > -1 || /instagram/i.test(ua);
    const isFacebookInAppBrowser = (): boolean =>
      /FB_IAB/.test(navigator.userAgent) ||
      /FBAN/.test(navigator.userAgent) ||
      /FBAV/.test(navigator.userAgent);

    return isInstagramInAppBrowser || isFacebookInAppBrowser();
  }
}
