import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-comment-cart-atom',
  templateUrl: './comment-cart-atom.component.html',
})
export class CommentCartAtomComponent {
  @Input() text!: string;
  @Input() icon!: string;
  @Input() comment!: string;
  @Input() headingColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() textColor: IContentBuilderFieldColor = {
    value: 'neutral-600',
    type: ColorFieldTypesValues.Palette,
  };

  @Output() removeComment = new EventEmitter<string>();
  @Output() updadeComment = new EventEmitter<string>();
}
