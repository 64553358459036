<input
  appVirtualKeyboard
  [isOverFooter]="isOverFooter"
  *ngIf="['text', 'email', 'tel'].includes(type)"
  [type]="type"
  [placeholder]="placeholder ?? ''"
  [(ngModel)]="value"
  #inputText
  [style.color]="textColor | contentBuilderColor"
  (ngModelChange)="onChangeTextValue($event)"
  [style.borderColor]="color | contentBuilderColor"
  class="w-full py-6 text-3xl bg-transparent border-4 rounded-lg px-11 placeholder:opacity-30"
/>
<app-checkbox-atom
  *ngIf="type === 'checkbox'"
  [type]="type"
  [color]="color"
  size="L"
  [(isChecked)]="isChecked"
  (isCheckedChange)="onChangeValueCheckbox($event)"
></app-checkbox-atom>
