import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NativeService } from '@core/services/native/native.service';

@Component({
  selector: 'app-counter-atom',
  templateUrl: './counter-atom.component.html',
})
export class CounterAtomComponent implements OnInit {
  @Input() count!: number;
  @Input() min: null | number = 0;
  @Input() max: null | number = null;
  @Input() size: 'XL' | 'M' | 'L' | 'S' = 'M';
  @Input() type: 'primary' | 'light' | 'kiosk' = 'primary';
  @Input() disableIncrease = false;
  @Input() acceptNoItem = false;

  @Input() backgroundColor?: string;
  @Input() textColor?: string;

  @Output() countChange = new EventEmitter<number>();

  isNative = NativeService.native;

  ngOnInit(): void {
    if (!this.count && !this.acceptNoItem) {
      this.count = 1;
    }
  }

  increaseCount(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    if (this.type === 'kiosk') {
      this.countChange.emit(this.count + 1);
    } else {
      this.count += 1;
      this.countChange.emit(this.count);
    }
  }

  decreaseCount(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    if (this.type === 'kiosk') {
      this.countChange.emit(this.count - 1);
    } else {
      this.count -= 1;
      this.countChange.emit(this.count);
    }
  }

  increaseDisabled(): boolean {
    return this.max !== null && this.count >= this.max;
  }

  decreaseDisabled(): boolean {
    return this.min !== null && this.count <= this.min;
  }
}
