<div
  class="flex cursor-pointer items-center justify-center rounded border-2 transition-all duration-200"
  [ngClass]="
    size === 'S' ? 'h-6 w-6 border-2' : size === 'L' ? 'h-12 w-12 border-4' : 'h-10 w-10 border-4'
  "
  [style.borderColor]="color | contentBuilderColor"
  [style.backgroundColor]="isChecked ? (color | contentBuilderColor) : 'transparent'"
  (click)="toggleCheck()"
>
  <svg
    *ngIf="isChecked"
    class="text-white"
    [ngClass]="size === 'S' ? 'h-4 w-4' : size === 'L' ? 'h-8 w-8' : 'h-6 w-6'"
    fill="currentColor"
    viewBox="0 0 20 20"
  >
    <path
      fill-rule="evenodd"
      d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 10-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z"
      clip-rule="evenodd"
    ></path>
  </svg>
</div>
