import { combineLatest, of } from 'rxjs';
import { ChangeDetectorRef, Component } from '@angular/core';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';

import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { CheckKeys } from '@config/keys/check.keys';
import { ModalsService } from '@core/services/modals/modals.service';
import { DoodOpenCheckItemExtended } from '@core/models/check.model';

import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { PaymentAtTableStoreSelector } from '@common/selectors/payment-at-table.selector';

import { PaymentAtTableStoreRefiner } from '@common/refiners/payment-at-table.refiner';
import { DoodPaymentAtTableAmountType } from '@store/payment-at-table/payment-at-table.enum';
import { PaymentAtTableStoreDispatcher } from '@common/dispatchers/payment-at-table.dispatcher';

@Component({
  selector: 'app-pat-select-products-modal',
  templateUrl: './pat-select-products-modal.component.html',
})
export class PatSelectProductsModalComponent extends DestroyerBase {
  static handle = 'pat-select-products-modal';

  totalAmount = 0;
  isMobile = false;
  allChecked = false;
  disableValidate = false;
  items: DoodOpenCheckItemExtended[] = [];

  index$ = this.modalSelector
    .selectModal(PatSelectProductsModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  constructor(
    private cd: ChangeDetectorRef,
    private modalSelector: ModalStoreSelector,
    private readonly modalsService: ModalsService,
    protected settingsSelector: SettingsStoreSelector,
    private paymentAtTableRefiner: PaymentAtTableStoreRefiner,
    private paymentAtTableSelector: PaymentAtTableStoreSelector,
    private paymentAtTableDispatcher: PaymentAtTableStoreDispatcher,
  ) {
    super();

    this.settingsSelector.selectDeviceIsMobileScreen
      .pipe(takeUntil(this._destroyerRef))
      .subscribe(_isMobile => (this.isMobile = _isMobile));

    this.paymentAtTableSelector.select.pipe(take(1)).subscribe(({ choose, check }) => {
      this.items = check.items.map(item => {
        return {
          ...item,
          isChecked: !!choose.products?.includes(item.id),
          isEnabled: !!!item.selected_by?.user,
        };
      });

      this.totalAmount = this.calculateTotalPrice();
      this.paymentAtTableDispatcher.updateChoose({
        type: DoodPaymentAtTableAmountType.Products,
        amount:
          this.totalAmount > check.outstanding_amount ? check.outstanding_amount : this.totalAmount,
      });
    });

    this.paymentAtTableSelector.select
      .pipe(
        takeUntil(this._destroyerRef),
        switchMap(data => {
          return combineLatest([of(data), this.paymentAtTableRefiner.selectTransactionsItems]);
        }),
      )
      .subscribe(([{ check, choose }, items]) => {
        this.items = check[CheckKeys.Items]?.map(item => {
          return {
            ...item,
            isChecked: !!choose.products?.includes(item.id),
            isEnabled: !!!item.selected_by?.user,
          };
        });
      });
  }

  closeModal(): void {
    this.modalsService.close(PatSelectProductsModalComponent.handle);
  }

  validate(): void {
    this.modalsService.close(PatSelectProductsModalComponent.handle);
  }

  toggleSelect(): void {
    this.allChecked = !this.allChecked;
    for (const item of this.items) {
      if (item.isEnabled) {
        item.isChecked = this.allChecked;
      }
    }

    this.updateAmountAndValidate();
    this.cd.detectChanges();
  }

  onCheckboxChange(checked: boolean, item: DoodOpenCheckItemExtended): void {
    item.isChecked = checked;

    this.updateAmountAndValidate();
  }

  private updateAmountAndValidate(): void {
    this.paymentAtTableSelector.selectCheck.pipe(take(1)).subscribe(({ outstanding_amount }) => {
      this.totalAmount = this.calculateTotalPrice();

      if (this.totalAmount <= outstanding_amount) {
        this.disableValidate = false;
        this.paymentAtTableDispatcher.updateChoose({
          type: DoodPaymentAtTableAmountType.Products,
          products: this.items.filter(v => v.isChecked).map(v => v.id),
          amount: this.totalAmount > outstanding_amount ? outstanding_amount : this.totalAmount,
        });
      } else {
        this.disableValidate = true;
      }
    });
  }

  private calculateTotalPrice(): number {
    let totalPrice = 0;

    for (const item of this.items) {
      if (item.isChecked) {
        totalPrice += item.price;
      }
    }

    return totalPrice;
  }
}
