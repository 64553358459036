import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ComoService } from '../../../../../core/services/como/como.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-como-create-or-link-account-modal-attach-with-email-step',
  templateUrl: './como-create-or-link-account-modal-attach-with-email-step.component.html',
  styleUrls: ['./como-create-or-link-account-modal-attach-with-email-step.component.scss'],
})
export class ComoCreateOrLinkAccountModalAttachWithEmailStepComponent implements OnInit {
  @Output() goToStep = new EventEmitter<string>();
  isLoading = true;

  constructor(private readonly comoService: ComoService) {}

  ngOnInit(): void {
    this.comoService
      .attachUserWithEmail$()
      .pipe(tap(result => this.handleResult(result)))
      .subscribe();
  }

  private handleResult(result: boolean) {
    this.isLoading = false;
    if (result) {
      this.goToStep.next('confirmation');
    } else {
      this.goToStep.next('identification');
    }
  }
}
