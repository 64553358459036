<div class="max-w-screen-sm mx-auto">
  <div class="mb-4 flex flex-col">
    <div class="pb-4">
      <app-tag-list-atom [tags]="tags"></app-tag-list-atom>
    </div>
    <h2 class="text-2xl font-bold font-brand">{{ heading }}</h2>
    <a
      [link]="cta.url"
      *ngIf="cta"
      class="font-base font-bold bg-primary-600 text-white text-base p-3 mt-4 rounded-full text-center"
      >{{ cta.text }}</a
    >
  </div>
</div>
