<app-modal-filler-atom [index]="(index$ | async) ?? 0"></app-modal-filler-atom>
<div
  class="fixed left-0 right-0 overflow-y-auto shadow md:left-0 md:top-0 md:flex md:h-full md:w-full md:items-center md:justify-center mdMax:bottom-0 mdMax:mx-auto mdMax:max-h-85-screen mdMax:max-w-screen-sm"
  [style.z-index]="((index$ | async) ?? 0) + 50"
>
  <app-modal-filler-atom
    [index]="(index$ | async) ?? 0"
    [withBackdrop]="false"
    (click)="close()"
  ></app-modal-filler-atom>
  <div
    class="rounded-t-3xl bg-white p-5 md:w-2/5 md:max-w-lg md:rounded-3xl md:p-7"
    [style.z-index]="((index$ | async) ?? 0) + 50"
  ></div>
</div>
