<div
  class="py-4 lg:py-8"
  [style.backgroundColor]="highlightCategoryBackgroundColor | contentBuilderColor"
>
  <app-product-card-list-atom
    displayContext="shop_page"
    [heading]="category?.[categoryKeys.Name]"
    [products]="products"
    [isOrderingAllowed]="isOrderingAllowed"
    [categoryNameColor]="forceType(colorOrFallback(highlightCategoryNameColor, categoryNameColor))"
    [productNameColor]="productNameColor"
    [productDescriptionColor]="productDescriptionColor"
    [productPriceColor]="productPriceColor"
    [productImagePlaceholderColor]="productImagePlaceholderColor"
    (productClick)="onNavigateTo($event)"
  ></app-product-card-list-atom>
</div>
