import {Component, ElementRef, HostBinding, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NavigationHistoryService} from '@core/services/navigation-history/navigation-history.service';
import {IContentBuilderFieldColor, IContentBuilderFieldImage,} from '@core/models/content-builder-fields.model';
import {Meta} from '@angular/platform-browser';
import {CartService} from '@core/services/cart/cart.service';

@Component({
  selector: 'app-kiosk-cart-page',
  templateUrl: './kiosk-cart-page.component.html',
  styleUrls: ['./kiosk-cart-page.component.scss'],
})
export class KioskCartPageComponent implements OnInit {
  @Input() body: any;
  @Input() footer: any;

  @Input() title?: string;
  @Input() primaryButtonBackgroundColor?: IContentBuilderFieldColor;
  @Input() primaryButtonTextColor?: IContentBuilderFieldColor;
  @Input() secondaryButtonBackgroundColor?: IContentBuilderFieldColor;
  @Input() secondaryButtonTextColor?: IContentBuilderFieldColor;
  @Input() backgroundColor?: IContentBuilderFieldColor;
  @Input() backgroundImage?: IContentBuilderFieldImage;
  @Input() textColor?: IContentBuilderFieldColor;
  @Input() header?: unknown[];

  @Input() metaDescription?: string;

  @HostBinding('class') classes = 'h-screen flex';

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly navigationHistoryService: NavigationHistoryService,
    private readonly el: ElementRef,
    private readonly meta: Meta,
    private readonly cartService: CartService,
    // private readonly modalSelector: ModalStoreSelector,
    // private readonly modalsService: ModalsService,
  ) {
    this.cartService.loadShopsOfCart$().subscribe();
  }

  ngOnInit(): void {
    this.navigationHistoryService.pushStateFromActivatedRouteSnapshot(
      '/' + this.activatedRoute.snapshot.url.join('/'),
      this.activatedRoute.snapshot,
    );

    if (this.textColor?.value) {
      this.setTextOverrideColor();
    }
    if (this.metaDescription) {
      this.meta.updateTag({
        name: 'description',
        content: this.metaDescription,
      });
    }

    // this.modalSelector.selectModal(KioskSuggestionsModalComponent.handle);
    // this.modalsService.open(KioskSuggestionsModalComponent.handle);
  }

  setTextOverrideColor(): void {
    const textColor = this.textColor?.value;
    this.el.nativeElement.style.setProperty('--my-class-bg-color', textColor);
  }

  getBackgroundStyles() {
    return {
        'background-color': this.backgroundColor ? this.backgroundColor : null,
        'background-image': this.backgroundImage ? `url(${this.backgroundImage.url})` : null,
        'background-size': this.backgroundImage ? 'cover' : null
    };
  }
}
