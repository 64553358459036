<div class="py-2">
  <app-kiosk-input-radio-atom
    [label]="label"
    [active]="isActive"
    [value]="value"
    (valueEmitter)="onValueChange($event)"
    [textColor]="textColor"
    [buttonColor]="buttonColor"
    [activeTextColor]="activeTextColor"
    [activeButtonColor]="activeButtonColor"
  ></app-kiosk-input-radio-atom>
</div>
