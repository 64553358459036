import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CartTipTypeValues } from '@config/values/cart.values';

@Component({
  selector: 'app-button-square-atom',
  templateUrl: './button-square-atom.component.html',
})
export class ButtonSquareAtomComponent implements OnInit, OnChanges {
  @Input()
  text!: string;
  @Input()
  value!: number;
  @Input()
  type!: string;
  @Input()
  full = false;
  @Input()
  isActive = false;
  @Input()
  totalAmount!: number;
  @Input()
  otherAmmount!: number;

  amount!: number;

  @Output() buttonClick = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {
    this.getAmount(this.type, this.value, this.totalAmount);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getAmount(this.type, this.value, changes?.totalAmount?.currentValue);
  }

  getAmount(type: string, value: number, totalAmount: number): void {
    if (type === CartTipTypeValues.Percent && value && totalAmount) {
      this.amount = this.calculateAmount(this.value, totalAmount);
    }
  }

  calculateAmount(value: number, totalAmount: number): number {
    return Math.round(((totalAmount * value) / 100) * 1e2) / 1e2;
  }
}
