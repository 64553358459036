import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import {
  PRODUCT_STORE_ACTIVE_ACTIONS,
  PRODUCT_STORE_LOCAL_ACTIONS,
} from '@store/product/product.action';
import { DoodProductModel } from '@core/models/product.model';

@Injectable({ providedIn: 'root' })
export class ProductStoreDispatcher {
  constructor(private store: Store) {}

  // Many
  upsertMany(products: DoodProductModel[]): void {
    this.store.dispatch(PRODUCT_STORE_ACTIVE_ACTIONS.upsertMany({ entities: products }));
  }

  // Active
  resetActive(): void {
    this.store.dispatch(PRODUCT_STORE_ACTIVE_ACTIONS.resetActive());
  }

  insertActive(product: DoodProductModel): void {
    this.store.dispatch(PRODUCT_STORE_ACTIVE_ACTIONS.insertActive({ entity: product }));
  }

  setActiveCategory(category: string | undefined): void {
    this.store.dispatch(
      PRODUCT_STORE_ACTIVE_ACTIONS.setActiveCategory({ activeCategory: category }),
    );
  }

  reset(): void {
    this.store.dispatch(PRODUCT_STORE_LOCAL_ACTIONS.reset());
  }
}
