import { Component, Input } from '@angular/core';
import { ShopBudgetValues } from '@config/values/shop.values';

@Component({
  selector: 'app-budget-shop-atom',
  templateUrl: './budget-shop-atom.component.html',
})
export class BudgetShopAtomComponent {
  @Input() value!: string;
  @Input() currency = 'EUR';

  budgetValues = ShopBudgetValues;
}
