import { ReducerTypes, ActionCreator, on } from '@ngrx/store';

import { ANCHOR_STORE_LOCAL_ACTIONS } from './anchor.action';
import { AnchorState, ANCHOR_STORE_ADAPTER, ANCHOR_STORE_INITIAL_STATE } from './anchor.state';

export const ANCHOR_STORE_API_PIECE: ReducerTypes<AnchorState, readonly ActionCreator[]>[] = [];

export const ANCHOR_STORE_LOCAL_PIECE: ReducerTypes<AnchorState, readonly ActionCreator[]>[] = [
  // Multiple
  on(ANCHOR_STORE_LOCAL_ACTIONS.addAnchors, (state, { anchors }): AnchorState => {
    return ANCHOR_STORE_ADAPTER.addMany(anchors, state);
  }),
  on(ANCHOR_STORE_LOCAL_ACTIONS.removeAnchors, (state, { ids }): AnchorState => {
    return ANCHOR_STORE_ADAPTER.removeMany(ids, state);
  }),
  on(ANCHOR_STORE_LOCAL_ACTIONS.upsertAnchors, (state, { anchors }): AnchorState => {
    return ANCHOR_STORE_ADAPTER.upsertMany(anchors, state);
  }),
  // Single
  on(ANCHOR_STORE_LOCAL_ACTIONS.addAnchor, (state, { anchor }): AnchorState => {
    return ANCHOR_STORE_ADAPTER.addOne(anchor, state);
  }),
  on(ANCHOR_STORE_LOCAL_ACTIONS.removeAnchor, (state, { id }): AnchorState => {
    return ANCHOR_STORE_ADAPTER.removeOne(id, state);
  }),
  on(ANCHOR_STORE_LOCAL_ACTIONS.upsertAnchor, (state, { anchor }): AnchorState => {
    return ANCHOR_STORE_ADAPTER.upsertOne(anchor, state);
  }),
  // Status
  on(ANCHOR_STORE_LOCAL_ACTIONS.updateIsLoaded, (state, { isLoaded }): AnchorState => {
    return { ...state, isLoaded };
  }),
  on(ANCHOR_STORE_LOCAL_ACTIONS.updateIsLoading, (state, { isLoading }): AnchorState => {
    return { ...state, isLoading };
  }),
  // Error
  on(ANCHOR_STORE_LOCAL_ACTIONS.updateError, (state, { error }): AnchorState => {
    return { ...state, error };
  }),
  on(ANCHOR_STORE_LOCAL_ACTIONS.resetError, (state): AnchorState => {
    return { ...state, error: null };
  }),
  // Misc
  on(ANCHOR_STORE_LOCAL_ACTIONS.reset, (): AnchorState => {
    return ANCHOR_STORE_INITIAL_STATE;
  }),
];
