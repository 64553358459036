import { Component, Input } from '@angular/core';
import { ModalsService } from '@core/services/modals/modals.service';
import { KioskSuggestionsModalComponent } from '../kiosk-suggestions-modal/kiosk-suggestions-modal.component';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-kiosk-suggestions-modal-block',
  templateUrl: './kiosk-suggestions-modal-block.component.html',
})
export class KioskSuggestionsModalBlockComponent {
  @Input() heading = 'kiosk-suggestions-modal.heading';
  @Input() buttonBackgroundColor?: IContentBuilderFieldColor;
  @Input() buttonTextColor?: IContentBuilderFieldColor;
  @Input() secondaryColor?: IContentBuilderFieldColor;
  @Input() buttonLabel = 'kiosk-suggestions-modal.continue-button';
  @Input() textColor?: IContentBuilderFieldColor;
  @Input() backgroundColor?: IContentBuilderFieldColor;
  @Input() cardColor?: IContentBuilderFieldColor;
  @Input() suggestionsSet?: string;

  constructor(private readonly modalsService: ModalsService) {}

  ngOnInit() {
    this.modalsService.open(KioskSuggestionsModalComponent.handle);
    KioskSuggestionsModalComponent.heading = this.heading;
    KioskSuggestionsModalComponent.buttonBackgroundColor = this.buttonBackgroundColor;
    KioskSuggestionsModalComponent.buttonTextColor = this.buttonTextColor;
    KioskSuggestionsModalComponent.textColor = this.textColor;
    KioskSuggestionsModalComponent.secondaryColor = this.secondaryColor;
    KioskSuggestionsModalComponent.backgroundColor = this.backgroundColor;
    KioskSuggestionsModalComponent.cardColor = this.cardColor;
    KioskSuggestionsModalComponent.buttonLabel = this.buttonLabel;
    KioskSuggestionsModalComponent.suggestionsSet = this.suggestionsSet;
  }
}
