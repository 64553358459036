import { Component, EventEmitter, Input, Output } from '@angular/core';

import { EntityKeys } from '@config/keys/entity.keys';

import { DoodEntityModel } from '@core/models/entity.model';
import { IContentBuilderFieldImage } from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-shop-card-logo-atom',
  templateUrl: './shop-card-logo-atom.component.html',
})
export class ShopCardLogoAtomComponent {
  @Input() entity?: DoodEntityModel;
  @Input() isMarketplaceAvailable = true;
  @Input() shopImage!: string;
  @Input() uploadedImage!: IContentBuilderFieldImage;
  @Input() entityName!: string;
  @Input() entityCategory!: string;
  @Input() displayEntityImage = true;
  @Input() displayEntityCategory = true;

  @Output() navigationClick = new EventEmitter<DoodEntityModel>();

  entityKeys = EntityKeys;
}
