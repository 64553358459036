import { Component, Input } from '@angular/core';

import { MarketplaceKeys } from '@config/keys/shop.keys';
import { LanguageFlagsUrls } from '@config/language-flags.config';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';
import {
  IContentBuilderFieldColor,
  IContentBuilderFieldImage,
} from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-kiosk-header-block',
  templateUrl: './kiosk-header-block.component.html',
})
export class KioskHeaderBlockComponent {
  @Input() color?: string;
  @Input() displayLogo = false;
  @Input() logo?: { url: IContentBuilderFieldImage };
  @Input() backgroundColor?: IContentBuilderFieldColor;
  @Input() backgroundImage?: IContentBuilderFieldImage;
  @Input() headerLandingText?: string;
  @Input() displayHomeButton: boolean = false;
  @Input() buttonHomeText?: string;
  @Input() buttonBackgroundColor?: IContentBuilderFieldColor;
  @Input() buttonTextColor?: IContentBuilderFieldColor;
  @Input() size: 'L' | 'M' | 'S' = 'S';

  languageFlagUrl?: string;

  constructor(
    private settingsSelector: SettingsStoreSelector,
    private marketplaceSelector: MarketplaceStoreSelector,
  ) {
    if (this.marketplaceSelector.marketplace[MarketplaceKeys.Locales]?.length > 1) {
      this.languageFlagUrl = LanguageFlagsUrls.get(this.settingsSelector.app.locale);
    }
  }
}
