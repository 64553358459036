import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';

import { CartKeys } from '@config/keys/cart.keys';
import { ShopKeys } from '@config/keys/shop.keys';
import { EntityKeys } from '@config/keys/entity.keys';

import { ModalsService } from '@core/services/modals/modals.service';
import { OrderTypeService } from '@core/services/order-type/order-type.service';

import { DoodShopModel } from '@core/models/shop.model';
import { DoodEntityModel, DoodEntitySchedule, DoodEntityStatus } from '@core/models/entity.model';

import { CartStoreSelector } from '@common/selectors/cart.selector';
import { ShopCapabilitiesModalComponent } from '@shared/modals/shop-capabilities-modal/shop-capabilities-modal.component';

@Injectable({
  providedIn: 'root',
})
export class EntityStatusService {
  constructor(
    private cartSelector: CartStoreSelector,
    private readonly modalsService: ModalsService,
    private readonly orderTypeService: OrderTypeService,
  ) {}

  isOpen(schedules?: DoodEntitySchedule[], atDate?: Date): boolean {
    if (!schedules) {
      return false;
    }

    if (!atDate) {
      atDate = new Date();
    }
    const currentDay = atDate.getDay();
    const currentHour = atDate.getHours();
    const hourMinute = atDate.getMinutes();
    const nowNumber = currentDay * (60 * 24) + currentHour * 60 + hourMinute;
    const todaysSchedule = schedules.filter(schedule => {
      const day = Math.floor(schedule.start / (60 * 24));

      return day === currentDay;
    });

    const timeframe = todaysSchedule.filter(
      schedule => nowNumber >= schedule.start && schedule.end >= nowNumber,
    );
    return timeframe.length > 0;
  }

  calculateStatus(entity?: DoodEntityModel, orderType?: string, wantedAt?: Date): DoodEntityStatus {
    if (!entity || !orderType) {
      return {
        isVisible: false,
        isOrderingAvailable: false,
        orderingAvailableAt: undefined,
        isPreorderingAvailable: false,
      };
    }

    const orderTypeCapabilities = this.orderTypeService.getCapabilities(orderType);

    if (entity[EntityKeys.EntityType] === 'marketplace') {
      return {
        isVisible: entity[EntityKeys.Available],
        isOrderingAvailable: entity[EntityKeys.Available],
        orderingAvailableAt: undefined,
        isPreorderingAvailable: entity[EntityKeys.Available],
      };
    }

    if (entity[EntityKeys.EntityType] === 'shop') {
      const isOpen = this.isOpen(entity[EntityKeys.Schedules]);
      const isShopAvailable = entity[EntityKeys.Available];

      return {
        isVisible: isShopAvailable,
        isOrderingAvailable: isShopAvailable && (isOpen || !orderTypeCapabilities.useOpenHours),
        orderingAvailableAt: undefined,
        isPreorderingAvailable:
          isShopAvailable &&
          !isOpen &&
          orderTypeCapabilities.useOpenHours &&
          orderTypeCapabilities.preorderingAllowed,
      };
    }

    return {
      isVisible: false,
      isOrderingAvailable: false,
      orderingAvailableAt: undefined,
      isPreorderingAvailable: false,
    };
  }

  getPreOrderWarningModal(shop: DoodShopModel): void {
    this.cartSelector.selectActive
      .pipe(
        take(1),
        map(cart => {
          if (cart && shop) {
            const orderTypeCapabilities = this.orderTypeService.getCapabilities(
              cart[CartKeys.Type],
              shop,
            );
            if (
              !shop[ShopKeys.Schedules] ||
              (orderTypeCapabilities.useOpenHours &&
                !orderTypeCapabilities.preorderingAllowed &&
                !this.isOpen(shop?.[ShopKeys.Schedules]))
            ) {
              this.modalsService.open(ShopCapabilitiesModalComponent.handle);
            }
          }
        }),
      )
      .subscribe();
  }
}
