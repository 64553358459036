import { keyBy } from 'lodash';
import { Router } from '@angular/router';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import {
  ICartDeclinableSeparateAdditions,
  ICartItem,
  DoodReconstructedCartItem,
} from '@core/models/cart.model';
import {
  DoodDeclinableModel,
  DoodProductModel,
  ISelectedCompoundItem,
} from '@core/models/product.model';
import { IAdditionGroup } from '@core/models/shop.model';

import { CartStoreSelector } from '@common/selectors/cart.selector';
import { ShopStoreSelector } from '@common/selectors/shop.selector';
import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { BasketStoreSelector } from '@common/selectors/basket.selector';
import { DeclinableStoreSelector } from '@common/selectors/declinable.selector';

import { CartStoreDispatcher } from '@common/dispatchers/cart.dispatcher';
import { DeclinableStoreDispatcher } from '@common/dispatchers/declinable.dispatcher';

import { ProductKeys } from '@config/keys/product.keys';
import { AdditionGroupKeys, ShopKeys } from '@config/keys/shop.keys';
import { CartItemKeys, CartKeys, ReconstructedCartItemKeys } from '@config/keys/cart.keys';

import { Paths } from '@config/paths.config';
import { ProductTypeValues } from '@config/values/product.values';
import { ModalScrollBlockBase } from '@core/base/modalScrollBlock/modal-scroll-block.base';

import { CartService } from '@core/services/cart/cart.service';
import { BasketService } from '@core/services/basket/basket.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { ProductService } from '@core/services/product/product.service';
import { RouterHelperService } from 'src/app/core/services/router-helper/router-helper.service';

import { ProductUtils } from '@app/utilities/product.util';
import { CompoundItemModalComponent } from '@shared/modals/compound-item-modal/compound-item-modal.component';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

@Component({
  selector: 'app-item-edit-modal',
  templateUrl: './item-edit-modal.component.html',
})
export class ItemEditModalComponent extends ModalScrollBlockBase implements OnInit, OnDestroy {
  @Input() headingColor: IContentBuilderFieldColor = {
    value: 'neutral-900',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() descriptionColor: IContentBuilderFieldColor = {
    value: 'neutral-700',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() inactiveStateColor: IContentBuilderFieldColor = {
    value: 'neutral-500',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() priceColor: IContentBuilderFieldColor = {
    value: 'neutral-700',
    type: ColorFieldTypesValues.Palette,
  };

  @Input() onCartItemRemoved = () => {};
  @Input() onCartItemUpdated = (cartItem: ICartItem) => {};

  @Input() hideAdditionGroupDetails = false;

  protected readonly tagColors$ =
    this.marketplaceStoreSelector.selectMarketPlaceProductLabelsTagColors;
  private _destroyerRef = new Subject<boolean>();

  static handle = 'item-edit-modal';

  static navigate = true;

  static backgroundColor?: IContentBuilderFieldColor = undefined;
  static textColor?: IContentBuilderFieldColor = undefined;
  static buttonBackgroundColor?: IContentBuilderFieldColor = undefined;
  static buttonTextColor?: IContentBuilderFieldColor = undefined;

  get backgroundColor(): IContentBuilderFieldColor | undefined {
    return ItemEditModalComponent.backgroundColor;
  }

  get textColor(): IContentBuilderFieldColor | undefined {
    return ItemEditModalComponent.textColor;
  }

  get buttonBackgroundColor(): IContentBuilderFieldColor | undefined {
    return ItemEditModalComponent.buttonBackgroundColor;
  }

  get buttonTextColor(): IContentBuilderFieldColor | undefined {
    return ItemEditModalComponent.buttonTextColor;
  }

  index$: Observable<number> = this.modalSelector
    .selectModal(ItemEditModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  declinables$ = this.declinableSelector.selectDeclinables;

  product!: DoodProductModel;

  additions!: DoodProductModel[];

  additionsByGroup!: IAdditionGroup[];

  selectedAdditions: string[] = [];

  selectedAdditionsByGroup: (IAdditionGroup & {
    selectedAdditions: string[];
  })[] = [];

  quantity!: number;

  isButtonValid = true;

  cartEditItem$ = this.cartSelector.selectEditItem;

  constructor(
    private readonly router: Router,
    private cartSelector: CartStoreSelector,
    private shopSelector: ShopStoreSelector,
    private modalSelector: ModalStoreSelector,
    private readonly cartService: CartService,
    private basketSelector: BasketStoreSelector,
    private cartDispatcher: CartStoreDispatcher,
    private readonly modalsService: ModalsService,
    private readonly ordersService: OrdersService,
    private readonly basketService: BasketService,
    private readonly productService: ProductService,
    private readonly routerHelper: RouterHelperService,
    private declinableSelector: DeclinableStoreSelector,
    private declinableDispatcher: DeclinableStoreDispatcher,
    private readonly marketplaceStoreSelector: MarketplaceStoreSelector,
  ) {
    super();
  }

  ngOnInit(): void {
    this.cartSelector.selectEditItem
      .pipe(
        take(1),
        map(item => {
          if (item) {
            this.product = item?.[ReconstructedCartItemKeys.Product];
            this.quantity = item?.[ReconstructedCartItemKeys.Item][CartItemKeys.Quantity];
            if (item[ReconstructedCartItemKeys.Item][CartItemKeys.Additions]) {
              this.loadData(item);
            }
          }
          return item;
        }),
        tap(
          item =>
            item &&
            item[ReconstructedCartItemKeys.Product] &&
            item[ReconstructedCartItemKeys.Product][ProductKeys.Type] ===
              ProductTypeValues.Declinable &&
            this.productService.setCategories(
              item[ReconstructedCartItemKeys.Item][CartItemKeys.ShopId],
              item[ReconstructedCartItemKeys.Product].categories,
              item[ReconstructedCartItemKeys.Product]?.[ProductKeys.PriceOverrides],
            ),
        ),
        switchMap(item => {
          return combineLatest([of(item), this.declinableSelector.selectDeclinables]);
        }),
        take(2),
        map(([item, compounds]) => {
          if (item) {
            this.retrieveProductsOfCompoundItem(compounds, item);
            this.retrieveAdditionsOfCompoundItem(compounds, item);
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
    super.ngOnDestroy();
    this.declinableDispatcher.removeAll();
  }

  loadData(cartItem: DoodReconstructedCartItem): void {
    const item = cartItem[ReconstructedCartItemKeys.Item];
    this.shopSelector.selectShops
      .pipe(
        takeUntil(this._destroyerRef),
        map(shops => shops.find(shop => shop[ShopKeys.Id] === item[CartItemKeys.ShopId])),
        map(shop => {
          if (shop) {
            const availableAdditions = shop[ShopKeys.Products].filter(
              product =>
                !product[ProductKeys.Hidden] &&
                product[ProductKeys.Available] &&
                product[ProductKeys.Type] === ProductTypeValues.Addition,
            );
            const availableAdditionsKeyById = keyBy(availableAdditions, 'id');
            const productAdditions = cartItem[ReconstructedCartItemKeys.Product]?.[
              ProductKeys.Additions
            ].products.map(addition => availableAdditionsKeyById[addition]);
            if (productAdditions) {
              this.selectedAdditions = [];
              const newAdd: DoodProductModel[] = [];
              productAdditions.map(add => {
                if (item[CartItemKeys.Additions].some(id => id === add?.[ProductKeys.Id])) {
                  let quantity = 0;
                  item[CartItemKeys.Additions].forEach(
                    v => v === add?.[ProductKeys.Id] && quantity++,
                  );
                  newAdd.push({
                    ...add,
                    [ProductKeys.IsChecked]: true,
                    [ProductKeys.Quantity]: quantity,
                  });

                  for (let i = 0; i < quantity; i++) {
                    this.selectedAdditions.push(add[ProductKeys.Id]);
                  }
                } else {
                  newAdd.push({
                    ...add,
                    [ProductKeys.IsChecked]: false,
                  });
                }
              });

              const additionsByGroup = this.productService.getAdditionsByGroup(
                shop[ShopKeys.AdditionGroups],
                newAdd,
              );

              // retriveSelectedAdditionsGroup(item, additionsByGroup);

              return {
                additions: newAdd,
                additionsGroup: additionsByGroup,
                cartItem: item,
              };
            }
          }
        }),
      )
      .subscribe(v => {
        if (v) {
          this.additions = v.additions.filter(
            addition => !addition[ProductKeys.AdditionsGroup] && addition[ProductKeys.Id],
          );
          this.additionsByGroup = v.additionsGroup.filter(additionGroup => additionGroup.id);

          if (this.additionsByGroup?.length) {
            this.selectedAdditionsByGroup = [];
            this.additionsByGroup.forEach(el => {
              if (el.id) {
                this.selectedAdditionsByGroup.push({
                  ...el,
                  selectedAdditions: [],
                });
              }
            });

            this.selectedAdditionsByGroup.forEach(el => {
              el.items.some(elem =>
                v.cartItem[CartItemKeys.Additions].some(id => {
                  if (
                    id === elem[ProductKeys.Id] &&
                    !el.selectedAdditions.includes(elem[ProductKeys.Id])
                  ) {
                    el.selectedAdditions.push(id);
                  }
                }),
              );
            });
            this.checkValidationProduct();
          }
        }
      });
  }

  updateCart(): void {
    combineLatest([this.cartSelector.selectEditItem, this.declinableSelector.selectDeclinables])
      .pipe(
        take(1),
        map(([cartEdit, compoundItems]) => {
          let cartItem: ICartItem;
          // TODO: Use enum here
          const isDeclinable = cartEdit?.product.type === 'DECLINABLE';
          if (cartEdit && isDeclinable) {
            const declinable: Partial<ICartItem> = {
              [CartItemKeys.Id]: cartEdit[ReconstructedCartItemKeys.Item][CartItemKeys.Id],
              [CartItemKeys.ItemId]: cartEdit[ReconstructedCartItemKeys.Item][CartItemKeys.ItemId],
              [CartItemKeys.ShopId]: cartEdit[ReconstructedCartItemKeys.Item][CartItemKeys.ShopId],
              [CartItemKeys.Products]: [],
              [CartItemKeys.Quantity]: this.quantity,
            };
            compoundItems.map(item => {
              if (!item.isValid) {
                this.productService.setInvalidCompoundProduct(item.store_id);
              } else {
                item.selected?.forEach((e: ISelectedCompoundItem) => {
                  const additions: string[] = [];
                  const separateAdditionsByGroups: ICartDeclinableSeparateAdditions[] = [];
                  e.additions_by_group?.forEach(group => {
                    group.selected?.map(el => additions.push(el));
                    separateAdditionsByGroups.push({
                      id: group[AdditionGroupKeys.Id],
                      selected: group.selected as string[],
                    });
                  });
                  declinable[CartItemKeys.Products]?.push({
                    [CartItemKeys.Id]: e.id,
                    [CartItemKeys.Additions]: additions,
                    [CartItemKeys.Quantity]: e.quantity,
                    [CartItemKeys.ShopId]: cartEdit.item[CartItemKeys.ShopId],
                    [CartItemKeys.StoreId]: item.store_id,
                    [CartItemKeys.SeparateAdditionsByGroup]: separateAdditionsByGroups,
                  });
                });
              }
            });
            this.cartService.updateCartItem(
              declinable as ICartItem,
              ItemEditModalComponent.navigate,
            );
            this.cartDispatcher.updateStore({ edit_item: null });
            this.onCartItemUpdated(declinable as ICartItem);
            // this.cartService.mapCompoundItemForCart(compoundItems, declinable);
            this.close();
          } else if (cartEdit) {
            cartItem = {
              [CartItemKeys.Id]: cartEdit.item[CartItemKeys.Id],
              [CartItemKeys.ItemId]: cartEdit.item[CartItemKeys.ItemId],
              [CartItemKeys.ShopId]: cartEdit.item[CartItemKeys.ShopId],
              [CartItemKeys.Additions]: this.selectedAdditions,
              [CartItemKeys.Quantity]: this.quantity,
            };
            this.cartService.updateCartItem(cartItem, ItemEditModalComponent.navigate);
            this.cartDispatcher.updateStore({ edit_item: null });
            this.onCartItemUpdated(cartItem as ICartItem);
            this.close();
          }
        }),
      )
      .subscribe();
  }

  close(): void {
    this.modalsService.close(ItemEditModalComponent.handle);
    this.cartDispatcher.updateStore({ edit_item: null });
    if (ItemEditModalComponent.navigate) {
      this.router.navigate([this.routerHelper.translateRoute(Paths.Cart)]);
    }
  }

  openCompound(item: string): void {
    // this.compoundItemStore.setActive(item);
    this.declinableDispatcher.setActive(item);
    this.modalsService.open(CompoundItemModalComponent.handle);
  }

  onChangeNbProduct(quantity: number): number {
    return (this.quantity = quantity);
  }

  onSelectAddition(addition: DoodProductModel): string[] {
    if (addition[ProductKeys.AdditionsGroup]) {
      this.onSelectAdditionsByGroup(addition);
    }
    this.selectedAdditions = ProductUtils.toggleItem(addition, this.selectedAdditions);
    return this.selectedAdditions;
  }

  onChangeNbAddition(event: { quantity: number; id: string }): void {
    this.onChangeNbAdditionGroup(event);
    let count = 0;
    this.selectedAdditions.forEach(v => v === event.id && count++);
    for (let it = count; it < event.quantity; it++) {
      this.selectedAdditions.push(event.id);
    }
    for (let it = count; it > event.quantity; it--) {
      const index = this.selectedAdditions.indexOf(event.id);
      if (index !== -1) {
        this.selectedAdditions.splice(index, 1);
      }
    }
  }

  onChangeNbAdditionGroup(event: { quantity: number; id: string }): void {
    let count = 0;
    this.selectedAdditionsByGroup.forEach(group => {
      group.selectedAdditions.forEach(v => v === event.id && count++);
      group.items.forEach(addition => {
        if (addition[ProductKeys.Id] === event.id) {
          for (let it = count; it < event.quantity; it++) {
            group.selectedAdditions.push(event.id);
          }

          for (let it = count; it > event.quantity; it--) {
            const index = group.selectedAdditions.indexOf(event.id);
            if (index !== -1) {
              group.selectedAdditions.splice(index, 1);
            }
          }
        }
      });
    });
    this.checkValidationProduct();
  }

  onRemoveProduct(): void {
    combineLatest([this.cartSelector.selectEditItem, this.basketSelector.select])
      .pipe(
        take(1),
        switchMap(([cartEdit, basket]) => {
          if (basket[CartKeys.Id]) {
            return this.basketService.removeBasketItem$().pipe(
              tap(() => this.close()),
              switchMap(() => this.ordersService.checkCartIsValid$()),
            );
          } else {
            return this.cartService.removeItem$(cartEdit?.item.id as number);
          }
        }),
        tap(() => this.onCartItemRemoved()),
        takeUntil(this._destroyerRef),
      )
      .subscribe(() => {
        this.close();
      });
  }

  private onSelectAdditionsByGroup(addition: DoodProductModel): void {
    if (addition[ProductKeys.IsChecked]) {
      this.selectedAdditionsByGroup
        ?.find(el => el.id === addition[ProductKeys.AdditionsGroup])
        ?.selectedAdditions?.push(addition[ProductKeys.Id]);
    } else {
      const index = this.selectedAdditionsByGroup
        .find(el => el.id === addition[ProductKeys.AdditionsGroup])
        ?.selectedAdditions.indexOf(addition[ProductKeys.Id]) as number;
      this.selectedAdditionsByGroup
        .find(el => el.id === addition[ProductKeys.AdditionsGroup])
        ?.selectedAdditions.splice(index, 1);
    }
    this.checkValidationProduct();
  }

  private checkValidationProduct(): void {
    this.isButtonValid = true;
    if (this.selectedAdditionsByGroup.length) {
      this.selectedAdditionsByGroup.map(group => {
        if (
          group.selectedAdditions.length < group.min_count ||
          (group.max_count && group.selectedAdditions.length > group.max_count)
        ) {
          this.isButtonValid = false;
        }
      });
    }
  }

  private retrieveProductsOfCompoundItem(
    compounds: DoodDeclinableModel[],
    item: DoodReconstructedCartItem,
  ): void {
    compounds.forEach(el => {
      const productsOfCompound: string[] = [];
      item.item.products?.forEach(product => {
        if (el.store_id === product.store_id) {
          productsOfCompound.push(product.id);
        }
      });
      if (productsOfCompound) {
        this.productService.updateSelectedCompoundProduct(productsOfCompound, el.store_id);
      }
    });
  }

  private retrieveAdditionsOfCompoundItem(
    compounds: DoodDeclinableModel[],
    item: DoodReconstructedCartItem,
  ): void {
    this.shopSelector
      .selectShop(item.item.shop_id)
      .pipe(
        take(1),
        map(shop =>
          shop?.[ShopKeys.Products].filter(
            product => product[ProductKeys.Type] === ProductTypeValues.Addition,
          ),
        ),
        map(additions => {
          compounds.forEach(el => {
            item.item.products?.forEach(product => {
              if (
                el.store_id === product.store_id &&
                product[CartItemKeys.Additions]?.length &&
                additions
              ) {
                product[CartItemKeys.SeparateAdditionsByGroup]?.forEach(group => {
                  this.productService.updateSelectedAdditionsCompoundProduct(
                    group.selected,
                    el.store_id,
                    product[CartItemKeys.Id],
                    additions,
                    group.id,
                  );
                });
              }
            });
          });
        }),
      )
      .subscribe();
  }
}
