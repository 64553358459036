<app-modal-filler-atom
  (click)="closeModal()"
  [index]="((index$ | async) ?? 0) + 10"
></app-modal-filler-atom>
<div
  class="fixed left-0 right-0 overflow-y-auto shadow"
  [ngClass]="
    (settingsSelector.selectDeviceIsMobileScreen | async)
      ? 'bottom-0 max-h-85-screen max-w-screen-sm'
      : 'flex justify-center items-center w-full h-full top-0 left-0'
  "
  [style.z-index]="((index$ | async) ?? 0) + 60"
>
  @if (basket$ | async; as basket) {
    <app-share-grouped-order-atom
      [attribute]="(attribute$ | async) ?? ''"
      [link]="generatedLink"
      [basket]="basket"
      [isProcessingRequest]="isRequestInProgress"
      [isOrderOwner]="(basket$ | async)?.user?.id === (userId$ | async)"
      (shareLink)="shareLink()"
      (closeModal)="closeModal()"
      (joinOrder)="joinOrder()"
      (declineInvitation)="declineInvitation()"
      (leaveOrder)="leavingOrder()"
      (removeUser)="removeUser()"
      (cancelOrder)="cancelOrder()"
      (clearCart)="clearCart()"
      (startShareOrder)="startShareOrder()"
      (orderAlone)="orderAlone()"
    ></app-share-grouped-order-atom>
  }
</div>
