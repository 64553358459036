export enum CurrencyValues {
  EUR = '€',
  AUD = 'AUD',
  CAD = 'CAD',
  CHF = 'CHF',
  CNY = 'CNY',
  GBP = 'GBP',
  HKD = 'HKD',
  INR = 'INR',
  JPY = 'JPY',
  MAD = 'MAD',
  MUR = 'MUR',
  MXN = 'MXN',
  NZD = 'NZD',
  RUB = 'RUB',
  SEK = 'SEK',
  SGD = 'SGD',
  THB = 'THB',
  TWD = 'TWD',
  USD = 'USD',
}
