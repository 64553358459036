import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { SlugUtils } from '@shared/utils/slug/slug.utils';
import { AnchorStoreDispatcher } from '@common/dispatchers/anchor.dispatcher';

@Component({
  selector: 'app-anchor-block',
  templateUrl: './anchor-block.component.html',
})
export class AnchorBlockComponent implements OnInit, OnDestroy {
  handle?: string;

  @Input() name?: string;

  constructor(private anchorDispatcher: AnchorStoreDispatcher) {}

  ngOnInit(): void {
    if (!this.name) {
      return;
    }

    this.handle = SlugUtils.formatSlug(this.name);

    this.anchorDispatcher.addAnchor({
      name: this.name,
      handle: this.handle,
    });
  }

  ngOnDestroy(): void {
    if (this.handle) {
      this.anchorDispatcher.removeAnchor(this.handle);
    }
  }
}
