import { Component, OnDestroy, OnInit } from '@angular/core';

import { ModalsService } from '@core/services/modals/modals.service';
import { CartOverviewModalComponent } from '@shared/modals/cart-overview-modal/cart-overview-modal.component';

@Component({
  template: ``,
  selector: 'app-cart-button-block',
})
export class CartButtonBlockComponent implements OnInit, OnDestroy {
  constructor(private readonly modalsService: ModalsService) {}

  ngOnInit(): void {
    this.modalsService.open(CartOverviewModalComponent.handle);
  }

  ngOnDestroy(): void {
    this.modalsService.close(CartOverviewModalComponent.handle);
  }
}
