export const PAT_ERROR_MESSAGES = {
  NO_CHECKS_OPEN: `pay-at-table.error.no-checks-open`,
  TRY_AGAIN_LATER: `pay-at-table.error.try-again-later`,
  NOT_FOUND: `pay-at-table.error.location-not-found`,
};

export const PAT_ERROR_NO_CHECKS_OPEN = 'No checks are open on this location';
export const PAT_ERROR_NOT_FOUND = 'Not Found';
export const A_LOCK_FAILED_DOCUMENT = 'A lock failed on a document.';

export const CHECK_TIPS_CONFIG = {
  'amount-1': 1,
  'type-1': 'currency',
  'amount-2': 2,
  'type-2': 'currency',
  'amount-3': 3,
  'type-3': 'currency',
};
