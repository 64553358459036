import { ParametersDistributionModeLabels } from './labels/parameters.labels';

export interface IOrderTypeCapabilities {
  label: string;
  icon: string;
  useOpenHours: boolean;
  displaySlot: boolean;
  preorderingAllowed: boolean;
  isDeliveryAddressMandatory: boolean;
  isMobilePhoneMandatory: boolean;
  isOnSiteLocationMandatory: boolean;
}

export const ORDER_TYPES_CAPABILITIES: {
  [key: string]: IOrderTypeCapabilities;
} = {
  'click-and-collect': {
    label: ParametersDistributionModeLabels.ClickAndCollect,
    icon: 'icon-shopping-bag-2',
    useOpenHours: true,
    displaySlot: true,
    preorderingAllowed: false,
    isDeliveryAddressMandatory: false,
    isMobilePhoneMandatory: false,
    isOnSiteLocationMandatory: false,
  },
  delivery: {
    label: ParametersDistributionModeLabels.Delivery,
    icon: 'icon-bike',
    useOpenHours: true,
    displaySlot: true,
    preorderingAllowed: false,
    isDeliveryAddressMandatory: true,
    isMobilePhoneMandatory: true,
    isOnSiteLocationMandatory: false,
  },
  shipping: {
    label: ParametersDistributionModeLabels.Shipping,
    icon: 'icon-bag-delivery',
    useOpenHours: false,
    displaySlot: true,
    preorderingAllowed: false,
    isDeliveryAddressMandatory: true,
    isOnSiteLocationMandatory: false,
    isMobilePhoneMandatory: true,
  },
  'on-site': {
    label: ParametersDistributionModeLabels.OnSite,
    icon: 'icon-seat',
    useOpenHours: true,
    displaySlot: false,
    preorderingAllowed: false,
    isDeliveryAddressMandatory: false,
    isOnSiteLocationMandatory: true,
    isMobilePhoneMandatory: false,
  },
  'eat-in': {
    label: ParametersDistributionModeLabels.EatIn,
    icon: 'icon-seat',
    useOpenHours: true,
    displaySlot: false,
    preorderingAllowed: false,
    isDeliveryAddressMandatory: false,
    isOnSiteLocationMandatory: false,
    isMobilePhoneMandatory: false,
  },
};
