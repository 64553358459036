import { Type } from '@angular/core';

import { PaymentPanelCmiComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-cmi/payment-panel-cmi.component';
import { PaymentPanelLydiaComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-lydia/payment-panel-lydia.component';
import { PaymentPanelYavinComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-yavin/payment-panel-yavin.component';
import { PaymentPanelLyfpayComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-lyfpay/payment-panel-lyfpay.component';
import { PaymentPanelStripeComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-stripe/payment-panel-stripe.component';
import { PaymentPanelEdenredComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-edenred/payment-panel-edenred.component';
import { PaymentPanelAdyenCbComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-adyen-cb/payment-panel-adyen-cb.component';
import { PaymentPanelNoPaymentComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-no-payment/payment-panel-no-payment.component';
import { PaymentPanelPaygreenTrdComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-paygreen-trd/payment-panel-paygreen-trd.component';
import { PaymentPanelAdyenPaypalComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-adyen-paypal/payment-panel-adyen-paypal.component';

import { PaymentPanelAdyenApplepayComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-adyen-applepay/payment-panel-adyen-applepay.component';
import { PaymentPanelAdyenTerminalComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-adyen-terminal/payment-panel-adyen-terminal.component';
import { PaymentPanelStripeTerminalComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-stripe-terminal/payment-panel-stripe-terminal.component';
import { PaymentPanelAdyenGooglepayComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-adyen-googlepay/payment-panel-adyen-googlepay.component';
import { PaymentPanelStripeApplepayComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-stripe-applepay/payment-panel-stripe-applepay.component';
import { PaymentPanelPaygreenLunchrComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-paygreen-lunchr/payment-panel-paygreen-lunchr.component';
import { PaymentPanelStripeGooglepayComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-stripe-googlepay/payment-panel-stripe-googlepay.component';
import { PaymentPanelStripeBancontactComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-stripe-bancontact/payment-panel-stripe-bancontact.component';
import { PaymentPanelAuresEtkTerminalComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-aures-etk-terminal/payment-panel-aures-etk-terminal.component';
import { PaymentPanelOnSiteWithStripeComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-on-site-with-stripe/payment-panel-on-site-with-stripe.component';
import { PaymentPanelPaygreenRestoflashComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-paygreen-restoflash/payment-panel-paygreen-restoflash.component';
import { PaymentPanelOnSiteWithoutDepositComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-on-site-without-deposit/payment-panel-on-site-without-deposit.component';
import { PaymentPanelAdyenMealVoucherSodexoComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-adyen-meal-voucher-sodexo/payment-panel-adyen-meal-voucher-sodexo.component';
import { PaymentPanelAdyenMealVoucherNatixisComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-adyen-meal-voucher-natixis/payment-panel-adyen-meal-voucher-natixis.component';
import { PaymentPanelAdyenMealVoucherGroupeUpComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-adyen-meal-voucher-groupe-up/payment-panel-adyen-meal-voucher-groupe-up.component';
import { PaymentPanelYavinTerminalComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-yavin-terminal/payment-panel-yavin-terminal.component';
import { PaymentPanelStripeTwintComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-stripe-twint/payment-panel-stripe-twint.component';
import {
  PaymentPanelVivaWalletComponent
} from "@shared/blocks/payment-method-picker-block/panels/payment-panel-viva-wallet/payment-panel-viva-wallet.component";

export interface IPaymentMethod {
  handle: PaymentMethodHandles;
  label: string;
  logos?: Array<string>;
  panelComponent?: Type<{}>;
  data?: object;
}

export enum PaymentMethodHandles {
  AdyenCB = 'ADYEN_CB',
  AdyenPaypal = 'ADYEN_PAYPAL',
  AdyenMealVoucherNatixis = 'ADYEN_MEAL_VOUCHER_NATIXIS',
  AdyenMealVoucherSodexo = 'ADYEN_MEAL_VOUCHER_SODEXO',
  AdyenMealVoucherGroupeUp = 'ADYEN_MEAL_VOUCHER_GROUPE_UP',
  AdyenGooglePay = 'ADYEN_GOOGLE_PAY',
  AdyenApplePay = 'ADYEN_APPLE_PAY',
  AdyenTerminal = 'ADYEN_TERMINAL',
  Stripe = 'STRIPE',
  StripeTerminal = 'STRIPE_TERMINAL',
  StripeGooglePay = 'STRIPE_GOOGLE_PAY',
  StripeTwint = 'STRIPE_TWINT',
  StripeApplePay = 'STRIPE_APPLE_PAY',
  StripeBancontact = 'STRIPE_BANCONTACT',
  Edenred = 'EDENRED',
  Onsite = 'ONSITE',
  OnsiteWithoutDeposit = 'ON_SITE_WITHOUT_DEPOSIT',
  NoPayment = 'NO_PAYMENT',
  PaygreenLunchr = 'PAYGREEN_LUNCHR',
  PaygreenRestoflash = 'PAYGREEN_RESTOFLASH',
  PaygreenTrd = 'PAYGREEN_TRD',
  Cmi = 'CMI',
  Lydia = 'LYDIA',
  LyfPay = 'LYFPAY',
  Yavin = 'YAVIN',
  VivaWallet = 'VIVA_WALLET',
  AuresEtkTerminal = 'AURES_ETK_TERMINAL',
  YavinTerminal = 'YAVIN_TERMINAL',
  Transactions = 'TRANSACTIONS',
}

export const PAYMENT_METHODS: Array<IPaymentMethod> = [
  {
    handle: PaymentMethodHandles.AdyenCB,
    label: 'Carte bancaire / Swile',
    panelComponent: PaymentPanelAdyenCbComponent,
    logos: [
      './assets/images/payment-logos/logo-visa.png',
      './assets/images/payment-logos/logo-mastercard.png',
      './assets/images/payment-logos/logo-swile.png',
    ],
  },
  {
    handle: PaymentMethodHandles.AdyenPaypal,
    label: 'Paypal',
    panelComponent: PaymentPanelAdyenPaypalComponent,
    logos: ['./assets/images/payment-logos/logo-paypal.png'],
  },
  {
    handle: PaymentMethodHandles.AdyenMealVoucherNatixis,
    label: 'Bimpli (Apetiz)',
    panelComponent: PaymentPanelAdyenMealVoucherNatixisComponent,
    logos: ['./assets/images/payment-logos/logo-bimpli.png'],
  },
  {
    handle: PaymentMethodHandles.AdyenMealVoucherSodexo,
    label: 'Pass Restaurant (Pluxee)',
    panelComponent: PaymentPanelAdyenMealVoucherSodexoComponent,
    logos: ['./assets/images/payment-logos/logo-pass-restaurant.png'],
  },
  {
    handle: PaymentMethodHandles.AdyenMealVoucherGroupeUp,
    label: 'Up Déjeuner',
    panelComponent: PaymentPanelAdyenMealVoucherGroupeUpComponent,
    logos: ['./assets/images/payment-logos/logo-up.png'],
  },
  {
    handle: PaymentMethodHandles.AdyenGooglePay,
    label: 'Google Pay',
    panelComponent: PaymentPanelAdyenGooglepayComponent,
    logos: ['./assets/images/payment-logos/logo-googlepay.png'],
  },
  {
    handle: PaymentMethodHandles.AdyenApplePay,
    label: 'Apple Pay',
    panelComponent: PaymentPanelAdyenApplepayComponent,
    logos: ['./assets/images/payment-logos/logo-applepay.png'],
  },
  {
    handle: PaymentMethodHandles.Stripe,
    label: 'payment.credit-card',
    panelComponent: PaymentPanelStripeComponent,
    logos: [
      './assets/images/payment-logos/logo-visa.png',
      './assets/images/payment-logos/logo-mastercard.png',
      './assets/images/payment-logos/logo-amex.png',
    ],
  },
  {
    handle: PaymentMethodHandles.StripeTerminal,
    label: 'payment.credit-card',
    panelComponent: PaymentPanelStripeTerminalComponent,
    logos: [
      './assets/images/payment-logos/logo-visa.png',
      './assets/images/payment-logos/logo-mastercard.png',
      './assets/images/payment-logos/logo-googlepay.png',
      './assets/images/payment-logos/logo-applepay.png',
    ],
  },
  {
    handle: PaymentMethodHandles.StripeGooglePay,
    label: 'Google Pay',
    panelComponent: PaymentPanelStripeGooglepayComponent,
    logos: ['./assets/images/payment-logos/logo-googlepay.png'],
  },
  {
    handle: PaymentMethodHandles.StripeTwint,
    label: 'Twint',
    panelComponent: PaymentPanelStripeTwintComponent,
    logos: ['./assets/images/payment-logos/logo-twint.svg'],
  },
  {
    handle: PaymentMethodHandles.StripeApplePay,
    label: 'Apple Pay',
    panelComponent: PaymentPanelStripeApplepayComponent,
    logos: ['./assets/images/payment-logos/logo-applepay.png'],
  },
  {
    handle: PaymentMethodHandles.StripeBancontact,
    label: 'Bancontact',
    panelComponent: PaymentPanelStripeBancontactComponent,
    logos: ['./assets/images/payment-logos/logo-bancontact.png'],
  },
  {
    handle: PaymentMethodHandles.Edenred,
    label: 'payment.edenred',
    panelComponent: PaymentPanelEdenredComponent,
    logos: ['./assets/images/payment-logos/logo-edenred.png'],
  },
  {
    handle: PaymentMethodHandles.Onsite,
    label: 'payment.on-site',
    panelComponent: PaymentPanelOnSiteWithStripeComponent,
    logos: ['./assets/images/payment-logos/logo-onsite.png'],
  },
  {
    handle: PaymentMethodHandles.OnsiteWithoutDeposit,
    label: 'payment.on-site',
    panelComponent: PaymentPanelOnSiteWithoutDepositComponent,
    logos: ['./assets/images/payment-logos/logo-onsite.png'],
  },
  {
    handle: PaymentMethodHandles.NoPayment,
    label: 'payment.on-site-zero',
    panelComponent: PaymentPanelNoPaymentComponent,
    logos: [],
  },
  {
    handle: PaymentMethodHandles.PaygreenLunchr,
    label: 'Swile',
    panelComponent: PaymentPanelPaygreenLunchrComponent,
    logos: ['./assets/images/payment-logos/logo-swile.png'],
  },
  {
    handle: PaymentMethodHandles.PaygreenRestoflash,
    label: 'Resto Flash®',
    panelComponent: PaymentPanelPaygreenRestoflashComponent,
    logos: ['./assets/images/payment-logos/logo-restoflash.png'],
  },
  {
    handle: PaymentMethodHandles.PaygreenTrd,
    label: 'Titres Restaurant',
    panelComponent: PaymentPanelPaygreenTrdComponent,
    logos: [
      './assets/images/payment-logos/logo-apetiz.png',
      './assets/images/payment-logos/logo-pluxee.png',
      './assets/images/payment-logos/logo-pass-restaurant.png',
      './assets/images/payment-logos/logo-up.png',
    ],
  },
  {
    handle: PaymentMethodHandles.Cmi,
    label: 'CMI',
    panelComponent: PaymentPanelCmiComponent,
    logos: [
      './assets/images/payment-logos/logo-cmi-2.png',
      './assets/images/payment-logos/logo-master-card-secure-code.png',
      './assets/images/payment-logos/logo-verified-by-visa.png',
    ],
  },
  {
    handle: PaymentMethodHandles.Lydia,
    label: 'Lydia',
    panelComponent: PaymentPanelLydiaComponent,
    logos: ['./assets/images/payment-logos/logo-lydia.png'],
  },
  {
    handle: PaymentMethodHandles.LyfPay,
    label: 'payment.lyfpay',
    panelComponent: PaymentPanelLyfpayComponent,
    logos: ['./assets/images/payment-logos/logo-lyfpay.png'],
  },
  {
    handle: PaymentMethodHandles.Yavin,
    label: 'payment.yavin',
    panelComponent: PaymentPanelYavinComponent,
    logos: [
      './assets/images/payment-logos/logo-visa.png',
      './assets/images/payment-logos/logo-mastercard.png',
    ],
  },
  {
    handle: PaymentMethodHandles.YavinTerminal,
    label: 'Carte bancaire',
    panelComponent: PaymentPanelYavinTerminalComponent,
    logos: [
      './assets/images/payment-logos/logo-visa.png',
      './assets/images/payment-logos/logo-mastercard.png',
    ],
  },
  {
    handle: PaymentMethodHandles.VivaWallet,
    label: 'payment.viva-wallet',
    panelComponent: PaymentPanelVivaWalletComponent,
    logos: [
      './assets/images/payment-logos/logo-visa.png',
      './assets/images/payment-logos/logo-mastercard.png',
      './assets/images/payment-logos/logo-amex.png',
    ],
  },
  {
    handle: PaymentMethodHandles.AdyenTerminal,
    label: 'Adyen Terminal',
    panelComponent: PaymentPanelAdyenTerminalComponent,
    logos: [
      './assets/images/payment-logos/logo-visa.png',
      './assets/images/payment-logos/logo-mastercard.png',
    ],
  },
  {
    handle: PaymentMethodHandles.AuresEtkTerminal,
    label: 'payment.credit-card',
    panelComponent: PaymentPanelAuresEtkTerminalComponent,
    logos: [
      './assets/images/payment-logos/logo-visa.png',
      './assets/images/payment-logos/logo-mastercard.png',
    ],
  },
];

export interface KioskPaymentMethodCard {
  icon?: string;
  label: string;
  paymentMethod: IPaymentMethod;
  hasNextScreen: boolean;
}
