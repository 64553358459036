<div
  class="max-w-md mx-auto mb-3 px-5 lg:max-w-full lg:px-0"
  *ngIf="comoUser === null"
>
  <div class="py-5 bg-highlight-500 text-neutral-800 rounded rounded-s">
    <div class="flex items-center px-4.5">
      <span class="font-base font-bold lg:text-md">Programme de fidelité</span>
    </div>
    <div class="px-4.5 flex items-start">
      <input
        id="registerUserToComo"
        type="checkbox"
        [formControl]="registerUserToComo"
        class="m-3 ml-0 form-checkbox h-6 w-6 border text-primary-600"
      />
      <label
        for="registerUserToComo"
        class="mt-3 font-base text-sm leading-5 text-neutral-800"
      >
        Je m'inscris au programme de fidélité
      </label>
    </div>
  </div>
</div>
