<div class="mb-5 flex flex-col gap-5 bg-neutral-100 py-6">
  <div
    class="flex flex-col gap-2 px-5"
    *ngIf="heading || subheading"
  >
    <h2
      class="font-accent text-2xl font-bold text-neutral-800 lg:text-3xl"
      *ngIf="heading"
    >
      {{ heading | translate }}
    </h2>
    <div
      class="font-base text-sm text-neutral-600 lg:text-base"
      *ngIf="subheading"
    >
      {{ subheading }}
    </div>
  </div>

  <div
    class="flex flex-row items-center px-5 font-base text-sm text-neutral-600 lg:text-base"
    *ngIf="
      (displayPoints && pointsBalance && pointsBalance > 0) ||
      (displayCredits && creditBalance && creditBalance > 0)
    "
  >
    <i class="icon icon-coins mt-0.5 pr-1.5"></i>
    <span
      *ngIf="displayPoints && pointsBalance && pointsBalance > 0"
      [translate]="'como.amount-of-points'"
      [translateParams]="{ points: pointsBalance / 100 }"
    ></span>
    <span
      *ngIf="
        displayPoints &&
        pointsBalance &&
        pointsBalance > 0 &&
        displayCredits &&
        creditBalance &&
        creditBalance > 0
      "
      class="px-1"
      >—</span
    >
    <span *ngIf="displayCredits && creditBalance && creditBalance > 0">
      {{ 'como.credits-label' | translate }}
      <app-price-atom
        [amount]="creditBalance"
        [format]="true"
        [bold]="false"
        size="SM"
      ></app-price-atom>
    </span>
  </div>

  <div
    class="px-5 font-base text-sm text-neutral-600 lg:text-base"
    *ngIf="!assets || assets.length === 0"
  >
    {{ 'como.no-assets' | translate }}
  </div>
  <div
    #scrollContainer
    class="w-full overflow-x-auto scrollbar-hide"
  >
    <ul
      class="flex pl-5 pr-2"
      *ngIf="assets.length > 0"
    >
      <ng-container *ngIf="displayRedeemableGifts">
        <li
          class="mr-3 w-40 min-w-40 flex-shrink-0 flex-grow-0"
          *ngFor="let asset of redeemableAssets"
        >
          <app-como-asset-item-atom
            [asset]="asset"
            [displayAddButton]="displayAddButton"
            [isAssetInCart]="!!(isAssetInCart$(asset) | async)"
            (assetAdd)="onAssetAdd.emit(asset)"
            (assetRemove)="onAssetRemove.emit(asset)"
          ></app-como-asset-item-atom>
        </li>
      </ng-container>
      <ng-container *ngIf="displayNotRedeemableGifts">
        <li
          class="mr-3 w-40 min-w-40 flex-shrink-0 flex-grow-0"
          *ngFor="let asset of noRedeemableAssets"
        >
          <app-como-asset-item-atom
            [asset]="asset"
            [displayAddButton]="displayAddButton"
            [isAssetInCart]="!!(isAssetInCart$(asset) | async)"
            (assetAdd)="onAssetAdd.emit(asset)"
            (assetRemove)="onAssetRemove.emit(asset)"
          ></app-como-asset-item-atom>
        </li>
      </ng-container>
      <ng-container *ngIf="displayRedeemablePointShop">
        <li
          class="mr-3 w-40 min-w-40 flex-shrink-0 flex-grow-0"
          *ngFor="let asset of redeemablePointShops"
        >
          <app-como-asset-item-atom
            [asset]="asset"
            [displayAddButton]="displayAddButton"
            [isAssetInCart]="!!(isAssetInCart$(asset) | async)"
            (assetAdd)="onAssetAdd.emit(asset)"
            (assetRemove)="onAssetRemove.emit(asset)"
          ></app-como-asset-item-atom>
        </li>
      </ng-container>
      <ng-container *ngIf="displayNotRedeemablePointShop">
        <li
          class="mr-3 w-40 min-w-40 flex-shrink-0 flex-grow-0"
          *ngFor="let asset of noRedeemablePointShops"
        >
          <app-como-asset-item-atom
            [asset]="asset"
            [displayAddButton]="displayAddButton"
            [isAssetInCart]="!!(isAssetInCart$(asset) | async)"
            (assetAdd)="onAssetAdd.emit(asset)"
            (assetRemove)="onAssetRemove.emit(asset)"
          ></app-como-asset-item-atom>
        </li>
      </ng-container>
      <ng-container *ngIf="displayNotUsablePointShop">
        <li
          class="mr-3 w-40 min-w-40 flex-shrink-0 flex-grow-0"
          *ngFor="let asset of pointShops"
        >
          <app-como-asset-item-atom
            [asset]="asset"
            [displayAddButton]="displayAddButton"
            (assetAdd)="onAssetAdd.emit(asset)"
            (assetRemove)="onAssetRemove.emit(asset)"
          ></app-como-asset-item-atom>
        </li>
      </ng-container>
      <li class="w-4 flex-shrink-0"></li>
    </ul>
  </div>
</div>
