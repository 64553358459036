<app-modal-filler-atom
  *ngIf="blur"
  (click)="closeModal.emit()"
  [index]="-10"
></app-modal-filler-atom>
<div
  class="fixed right-0 left-0 bottom-0 p-5 shadow-top overflow-y-auto bg-white rounded-t-2.5-l"
  [class.z-50]="blur"
>
  <ng-content></ng-content>
</div>
