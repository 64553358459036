import { IPaymentMethodDescriptor } from '@core/models/payment.model';

export interface PaymentButtonState {
  text: string;
  isVisible: boolean;
  isEnabled: boolean;
}

export interface PaymentTransactionState {
  id: string | null;
  url: string | null;
}

export interface PaymentStatusState {
  isLoading: boolean;
  isInProgress: boolean;
  isRetryInProgress: boolean;
}

export interface PaymentBancontactState {
  email: string | null;
  complete_name: string | null;
}

export interface PaymentState {
  error: string | null;
  amount: number | null;
  status: PaymentStatusState;
  button: PaymentButtonState;
  bancontact: PaymentBancontactState;
  transaction: PaymentTransactionState;
  methods: IPaymentMethodDescriptor[];
}

export const PAYMENT_STORE_KEY = 'Payment';

export const PAYMENT_STORE_BUTTON_INITIAL_STATE: PaymentButtonState = {
  isVisible: true,
  isEnabled: false,
  text: 'payment.button',
};

export const PAYMENT_STORE_TRANSACTION_INITIAL_STATE: PaymentTransactionState = {
  id: null,
  url: null,
};

export const PAYMENT_STORE_STATUS_INITIAL_STATE: PaymentStatusState = {
  isLoading: false,
  isInProgress: false,
  isRetryInProgress: false,
};

export const PAYMENT_STORE_BANCONTACT_INITIAL_STATE: PaymentBancontactState = {
  email: null,
  complete_name: null,
};

export const PAYMENT_STORE_METHODS_INITIAL_STATE: IPaymentMethodDescriptor[] = [];

export const PAYMENT_STORE_INITIAL_STATE: PaymentState = {
  error: null,
  amount: null,
  button: PAYMENT_STORE_BUTTON_INITIAL_STATE,
  status: PAYMENT_STORE_STATUS_INITIAL_STATE,
  methods: PAYMENT_STORE_METHODS_INITIAL_STATE,
  bancontact: PAYMENT_STORE_BANCONTACT_INITIAL_STATE,
  transaction: PAYMENT_STORE_TRANSACTION_INITIAL_STATE,
};
