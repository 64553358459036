<div class="p-5 bg-white">
  <div class="flex justify-between items-center">
    <h2 class="font-accent font-bold text-3xs">Ajouter une carte bancaire</h2>
    <app-rounded-icon-button
      icon="icon-cross"
      (onClick)="closeModal.emit()"
    ></app-rounded-icon-button>
  </div>
  <form [formGroup]="paymentForm">
    <p class="mt-5 mb-2 text-neutral-600 font-base">Numéro de carte</p>
    <div class="text-neutral-800 leading-5 pb-6">
      <input
        formControlName="cardNumber"
        type="tel"
        #ccNumber
        class="w-full border border-neutral-800 rounded py-4.5 px-5"
        (keyup)="creditCardNumberSpacing()"
      />
    </div>
    <div class="flex">
      <div class="flex-1 mr-2.5">
        <p class="mt-5 mb-2 text-neutral-600 font-base">Date d'expiration</p>
        <div class="text-neutral-800 leading-5 pb-6">
          <input
            placeholder="MM/AA"
            pattern="^[ 0-9]*$"
            formControlName="date"
            class="w-full border border-neutral-800 rounded py-4.5 px-5"
          />
        </div>
      </div>
      <div class="flex-1">
        <p class="mt-5 mb-2 text-neutral-600 font-base">Code de sécurité</p>
        <div class="text-neutral-800 leading-5 pb-6">
          <input
            placeholder="CVC"
            formControlName="code"
            class="w-full border border-neutral-800 rounded py-4.5 px-5"
          />
        </div>
      </div>
    </div>
    ²
  </form>
  <p
    *ngIf="error"
    class="font-base text-secondary-700 pt-4.5 pb-4.5"
  >
    ERROR MESSAGE
  </p>
  <div class="pt-1 flex flex-wrap xm:flex-nowrap">
    <div class="xm:pr-1 w-full xm:w-1/2">
      <app-button-atom
        size="M"
        label="Annuler"
        color="primary"
        (click)="closeModal.emit()"
        icon="icon-cross"
        type="light"
        [full]="true"
      ></app-button-atom>
    </div>
    <div class="pt-2 xm:pt-0 xm:pl-1 w-full xm:w-1/2">
      <app-button-atom
        [disabled]="paymentForm.invalid"
        size="M"
        label="Ajouter"
        (click)="saveCard.emit(card)"
        icon="icon-plus"
        type="primary"
        [full]="true"
      ></app-button-atom>
    </div>
  </div>
</div>
