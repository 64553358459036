import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EntitiesService } from '@core/services/entities/entities.service';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MarketplaceService } from '@core/services/marketplace/marketplace.service';
import { TemplateLayoutKeys } from '@config/keys/template-layout.keys';
import { ContentBuilderFieldColors } from '@shared/interfaces/content-builder.interface';

@Component({
  selector: 'app-shop-cards-block',
  templateUrl: './shop-cards-block.component.html',
  styleUrls: ['./shop-cards-block.component.scss'],
})
export class ShopCardsBlockComponent implements OnInit, OnDestroy {
  @Input() shops: any;
  @Input() title?: string;
  @Input() titleSize: 'XS' | 'S' | 'M' | 'L' = 'M';
  @Input() layout = 'col-1';
  @Input() bottomMargin = 'medium';
  @Input() kioskLayout = 'col-3';
  @Input() kioskCardAspectRatio = '16/9';
  @Input() templateLayout = TemplateLayoutKeys.DefaultLayout;
  @Input() backgroundColor?: ContentBuilderFieldColors;
  @Input() headingColor?: ContentBuilderFieldColors;

  private destroyed$ = new Subject<boolean>();
  isLoading = true;

  templateLayoutKeys = TemplateLayoutKeys

  constructor(
    private readonly entitiesService: EntitiesService,
    private readonly marketplaceService: MarketplaceService,
  ) {}

  ngOnInit(): void {
    this.entitiesService
      .loadAll$(this.marketplaceService.getId())
      .pipe(
        takeUntil(this.destroyed$),
        tap(() => (this.isLoading = false)),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
