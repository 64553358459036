import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import {
  ORDER_STORE_ACTIVE_ACTIONS,
  ORDER_STORE_API_ACTIONS,
  ORDER_STORE_HYDRATION_ACTIONS,
  ORDER_STORE_LOCAL_ACTIONS,
} from '@store/order/order.action';
import { DoodOrderModel, DoodValidateOrderQuery } from '@store/order/order.model';
import { OrderErrorState } from '@store/order';

@Injectable({ providedIn: 'root' })
export class OrderStoreDispatcher {
  constructor(private store: Store) {}

  // Api
  fetchOrders(userId: string, marketplaceId: string): void {
    this.store.dispatch(ORDER_STORE_API_ACTIONS.fetchOrders({ userId, marketplaceId }));
  }

  // One
  upsertOne(entity: DoodOrderModel): void {
    this.store.dispatch(ORDER_STORE_ACTIVE_ACTIONS.upsertOne({ entity }));
  }

  removeOne(id: string): void {
    this.store.dispatch(ORDER_STORE_ACTIVE_ACTIONS.removeOne({ id }));
  }

  // Many
  upsertMany(entities: DoodOrderModel[]): void {
    this.store.dispatch(
      ORDER_STORE_ACTIVE_ACTIONS.upsertMany({
        entities,
      }),
    );
  }

  // All
  removeAll(): void {
    this.store.dispatch(ORDER_STORE_ACTIVE_ACTIONS.removeAll());
  }

  // Active
  setActive(active: string): void {
    this.store.dispatch(ORDER_STORE_ACTIVE_ACTIONS.setActive({ active }));
  }

  upsertActive(id: string, order: DoodOrderModel): void {
    this.upsertOne(order);
    this.setActive(id);
  }

  resetActive(): void {
    this.store.dispatch(ORDER_STORE_ACTIVE_ACTIONS.resetActive());
  }

  // Status
  updateIsValidating(isValidating: boolean): void {
    this.store.dispatch(ORDER_STORE_LOCAL_ACTIONS.updateIsValidating({ isValidating }));
  }

  // Validation
  updateValidation(validation: DoodValidateOrderQuery | null): void {
    this.store.dispatch(
      ORDER_STORE_LOCAL_ACTIONS.updateValidation({
        validation,
      }),
    );
  }

  resetValidation(): void {
    this.store.dispatch(ORDER_STORE_LOCAL_ACTIONS.resetValidation());
  }

  // Status
  updateIsLoading(isLoading: boolean): void {
    this.store.dispatch(ORDER_STORE_LOCAL_ACTIONS.updateIsLoading({ isLoading }));
  }

  // Errors
  updateErrors(errors: Partial<OrderErrorState>): void {
    this.store.dispatch(ORDER_STORE_LOCAL_ACTIONS.updateErrors({ errors }));
  }

  resetErrors(): void {
    this.store.dispatch(ORDER_STORE_LOCAL_ACTIONS.resetErrors());
  }

  // State
  reset(): void {
    this.store.dispatch(ORDER_STORE_LOCAL_ACTIONS.reset());
  }

  // Hydration
  clear(): void {
    this.store.dispatch(ORDER_STORE_HYDRATION_ACTIONS.clear());
  }
}
