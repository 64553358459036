import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Type,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { CamelCaseUtils } from '@app/utilities/camel-case.util';

import { ComponentHostDirective } from '@core/directives/component-host.directive';

@Component({
  selector: 'app-component-factory',
  templateUrl: './component-factory.component.html',
  styleUrls: ['./component-factory.component.scss'],
})
export class ComponentFactoryComponent implements AfterContentInit, OnDestroy {
  @Input() data?: object;
  @Input() componentClass?: Type<unknown>;

  @Output() isVisibleChanged = new EventEmitter();

  @ViewChild(ComponentHostDirective, { static: true })
  componentHost!: ComponentHostDirective;

  private readonly destroyed$ = new Subject<boolean>();

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngAfterContentInit(): void {
    this.componentHost.viewContainerRef.clear();

    if (!this.componentClass) {
      return;
    }

    const componentRef = this.componentHost.viewContainerRef.createComponent<any>(
      this.componentClass,
    );

    if (this.data) {
      Object.assign(
        componentRef.instance,
        CamelCaseUtils.convertAttributeNamesToCamelCase(this.data),
      );
    }

    if ('componentFactoryIsBlockVisible$' in Object(componentRef.instance)) {
      Object(componentRef.instance)
        .componentFactoryIsBlockVisible$()
        .pipe(
          takeUntil(this.destroyed$),
          tap((isVisible: boolean) => this.isVisibleChanged.emit(isVisible)),
        )
        .subscribe();
    }
  }
}
