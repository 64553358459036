import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GoogleMapsModule } from '@angular/google-maps';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, CurrencyPipe, TitleCasePipe } from '@angular/common';

import { CoreModule } from '@core/core.module';
import { APP_STORE_FEATURES } from '@app/app.store';

import { ShopPageComponent } from '@shared/pages/shop-page/shop-page.component';
import { HeroBlockComponent } from '@shared/blocks/hero-block/hero-block.component';
import { GenericPageComponent } from '@shared/pages/generic-page/generic-page.component';
import { HeaderBlockComponent } from '@shared/blocks/header-block/header-block.component';
import { ShopHeroSimpleBlockComponent } from '@shared/blocks/shop-hero-simple-block/shop-hero-simple-block.component';

import { TagAtomComponent } from '@shared/atoms/tag-atom/tag-atom.component';
import { TagListAtomComponent } from '@shared/atoms/tag-list-atom/tag-list-atom.component';
import { HeadingContentBlockComponent } from '@shared/blocks/heading-content-block/heading-content-block.component';
import { ShopProductListBlockComponent } from '@shared/blocks/shop-product-list-block/shop-product-list-block.component';
import { ShopCategoryTabsBlockComponent } from '@shared/blocks/shop-category-tabs-block/shop-category-tabs-block.component';
import { ShopSmallOverviewListBlockComponent } from '@shared/blocks/shop-small-overview-list-block/shop-small-overview-list-block.component';

import { CartPageComponent } from '@shared/pages/cart-page/cart-page.component';
import { CartHeaderBlockComponent } from '@shared/blocks/cart-header-block/cart-header-block.component';
import { CartItemListBlockComponent } from '@shared/blocks/cart-item-list-block/cart-item-list-block.component';
import { ShopsCustomMapPageComponent } from '@shared/pages/shops-custom-map-page/shops-custom-map-page.component';
import { ShopsCustomMapBlockComponent } from '@shared/blocks/shops-custom-map-block/shops-custom-map-block.component';
import { DevBlockContainerAtomComponent } from '@shared/atoms/dev-block-container-atom/dev-block-container-atom.component';

import { ModalFillerAtomComponent } from '@shared/atoms/modal-filler-atom/modal-filler-atom.component';
import { OrderStatusPageComponent } from '@shared/pages/order-status-page/order-status-page.component';
import { OrderStatusBlockComponent } from '@shared/blocks/order-status-block/order-status-block.component';
import { CartOverviewModalComponent } from '@shared/modals/cart-overview-modal/cart-overview-modal.component';
import { AuthenticationModalComponent } from '@shared/modals/authentication-modal/authentication-modal.component';
import { SidebarNavigationModalComponent } from '@shared/modals/sidebar-navigation-modal/sidebar-navigation-modal.component';
import { SocialNetworksFollowUsBlockComponent } from '@shared/blocks/social-networks-follow-us-block/social-networks-follow-us-block.component';
import { AccountPageComponent } from '@shared/pages/account-page/account-page.component';
import { OrdersPageComponent } from '@shared/pages/orders-page/orders-page.component';
import { FavoritesPageComponent } from '@shared/pages/favorites-page/favorites-page.component';
import { AccountPageBlockComponent } from '@shared/blocks/account-page-block/account-page-block.component';
import { OrdersPageBlockComponent } from '@shared/blocks/orders-page-block/orders-page-block.component';
import { FavoritesPageBlockComponent } from '@shared/blocks/favorites-page-block/favorites-page-block.component';
import { CartFooterModalComponent } from '@shared/modals/cart-footer-modal/cart-footer-modal.component';
import { PaymentPageComponent } from '@shared/pages/payment-page/payment-page.component';
import { PaymentPageHeaderBlockComponent } from '@shared/blocks/payment-page-header-block/payment-page-header-block.component';
import { NextButtonAtomComponent } from '@shared/atoms/next-button-atom/next-button-atom.component';
import { TabListAtomComponent } from '@shared/atoms/tab-list-atom/tab-list-atom.component';
import { AddButtonAtomComponent } from '@shared/atoms/add-button-atom/add-button-atom.component';
import { FatButtonAtomComponent } from '@shared/atoms/fat-button-atom/fat-button-atom.component';
import { HorizontalFilterListAtomComponent } from '@shared/atoms/horizontal-filter-list-atom/horizontal-filter-list-atom.component';
import { AutoFocusDirective } from '@shared/directives/autofocus/auto-focus.directive';
import { ProductPushAtomComponent } from '@shared/atoms/product-push-atom/product-push-atom.component';
import { ButtonAtomComponent } from '@shared/atoms/button-atom/button-atom.component';
import { PriceAtomComponent } from '@shared/atoms/price-atom/price-atom.component';
import { RoundedIconButtonComponent } from '@shared/atoms/rounded-icon-button/rounded-icon-button.component';
import { CounterAtomComponent } from '@shared/atoms/counter-atom/counter-atom.component';
import { HeaderAtomComponent } from '@shared/atoms/header-atom/header-atom.component';
import { HamburgerMenuAtomComponent } from '@shared/atoms/hamburger-menu-atom/hamburger-menu-atom.component';
import { ProductAddPageComponent } from '@shared/pages/product-add-page/product-add-page.component';
import { ProductAddModalComponent } from '@shared/modals/product-add-modal/product-add-modal.component';
import { PushShopCardAtomComponent } from '@shared/atoms/push-shop-card-atom/push-shop-card-atom.component';
import { ProductCategoryAtomComponent } from '@shared/atoms/product-category-atom/product-category-atom.component';
import { ShopSmallOverviewGridBlockComponent } from '@shared/blocks/shop-small-overview-grid-block/shop-small-overview-grid-block.component';
import { FoodSocietyHeroBlockComponent } from '@shared/blocks/food-society/food-society-hero-block/food-society-hero-block.component';
import { MosaicBlockComponent } from '@shared/blocks/mosaic-block/mosaic-block.component';
import { FoodSocietyFatButtonBlockComponent } from '@shared/blocks/food-society/food-society-fat-button-block/food-society-fat-button-block.component';
import { FoodSocietyShopListBlockComponent } from '@shared/blocks/food-society/food-society-shop-list-block/food-society-shop-list-block.component';
import { SimpleSmallHeroAtomComponent } from '@shared/atoms/simple-small-hero-atom/simple-small-hero-atom.component';
import { ShopListAtomComponent } from '@shared/atoms/shop-list-atom/shop-list-atom.component';
import { ProductPanelAtomComponent } from '@shared/atoms/product-panel-atom/product-panel-atom.component';
import { CartItemAtomComponent } from '@shared/atoms/cart-item-atom/cart-item-atom.component';
import { IconButtonComponent } from '@shared/atoms/icon-button/icon-button.component';
import { MosaicLongPushAtomComponent } from '@shared/atoms/mosaic-long-push-atom/mosaic-long-push-atom.component';
import { MosaicPushAtomComponent } from '@shared/atoms/mosaic-push-atom/mosaic-push-atom.component';
import { InputVoucherAtomComponent } from '@shared/atoms/input-voucher-atom/input-voucher-atom.component';
import { PaymentMethodPickerBlockComponent } from '@shared/blocks/payment-method-picker-block/payment-method-picker-block.component';
import { PaymentPanelStripeComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-stripe/payment-panel-stripe.component';
import { DiscountVoucherAtomComponent } from '@shared/atoms/discount-voucher-atom/discount-voucher-atom.component';
import { CartTotalAmountAtomComponent } from '@shared/atoms/cart-total-amount-atom/cart-total-amount-atom.component';
import { CartMerchantAmountBlockComponent } from '@shared/blocks/cart-merchant-amount-block/cart-merchant-amount-block.component';
import { ItemEditModalComponent } from '@shared/modals/item-edit-modal/item-edit-modal.component';
import { ItemEditPageComponent } from '@shared/pages/item-edit-page/item-edit-page.component';
import { ResumeOrderAtomComponent } from '@shared/atoms/resume-order-atom/resume-order-atom.component';
import { PaymentPanelEdenredComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-edenred/payment-panel-edenred.component';
import { PaymentPanelOnSiteWithStripeComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-on-site-with-stripe/payment-panel-on-site-with-stripe.component';
import { PaymentPanelOnSiteWithoutDepositComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-on-site-without-deposit/payment-panel-on-site-without-deposit.component';
import { PaymentPanelPaygreenTrdComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-paygreen-trd/payment-panel-paygreen-trd.component';
import { PaymentPanelPaygreenRestoflashComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-paygreen-restoflash/payment-panel-paygreen-restoflash.component';
import { PaymentPanelPaygreenLunchrComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-paygreen-lunchr/payment-panel-paygreen-lunchr.component';
import { PaymentPanelAdyenCbComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-adyen-cb/payment-panel-adyen-cb.component';
import { PaymentPanelAdyenPaypalComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-adyen-paypal/payment-panel-adyen-paypal.component';
import { PaymentPanelAdyenApplepayComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-adyen-applepay/payment-panel-adyen-applepay.component';
import { PaymentPanelAdyenGooglepayComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-adyen-googlepay/payment-panel-adyen-googlepay.component';
import { PaymentPanelAdyenCbSkeletonComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-adyen-cb/payment-panel-adyen-cb-skeleton/payment-panel-adyen-cb-skeleton.component';
import { PaymentPanelAdyenGenericComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-adyen-generic/payment-panel-adyen-generic.component';
import { TopHeroAtomComponent } from '@shared/atoms/top-hero-atom/top-hero-atom.component';
import { OrderStatusAtomComponent } from '@shared/atoms/order-status-atom/order-status-atom.component';
import { TagStatusAtomComponent } from '@shared/atoms/tag-status-atom/tag-status-atom.component';
import { SmallButtonAtomComponent } from '@shared/atoms/small-button-atom/small-button-atom.component';
import { BackHeaderAtomComponent } from '@shared/atoms/back-header-atom/back-header-atom.component';
import { CartVoucherModalComponent } from '@shared/modals/cart-voucher-modal/cart-voucher-modal.component';
import { DetailOrderAtomComponent } from '@shared/atoms/detail-order-atom/detail-order-atom.component';
import { CardButtonAtomComponent } from '@shared/atoms/card-button-atom/card-button-atom.component';
import { MarketplaceProductsBlockComponent } from '@shared/blocks/marketplace-products-block/marketplace-products-block.component';
import { OrderDetailsPageComponent } from '@shared/pages/order-details-page/order-details-page.component';
import { OrderDetailsBlockComponent } from '@shared/blocks/order-details-block/order-details-block.component';
import { OrderDetailsPageHeaderComponent } from '@shared/blocks/order-details-page-header/order-details-page-header.component';
import { PaymentPanelButtonSkeletonComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-button-skeleton/payment-panel-button-skeleton.component';
import { PaymentPanelPaygreenGenericComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-paygreen-generic/payment-panel-paygreen-generic.component';
import { ShopHeaderNavigationBlockComponent } from '@shared/blocks/shop-header-navigation-block/shop-header-navigation-block.component';
import { PushTextButtonAtomComponent } from '@shared/atoms/push-text-button-atom/push-text-button-atom.component';
import { OrderStatusPushItemBlockComponent } from '@shared/blocks/order-status-push-item-block/order-status-push-item-block.component';
import { SocialItemAtomComponent } from '@shared/atoms/social-item-atom/social-item-atom.component';
import { EventItemAtomComponent } from '@shared/atoms/event-item-atom/event-item-atom.component';
import { ProductPushListAtomComponent } from '@shared/atoms/product-push-list-atom/product-push-list-atom.component';
import { ProductCategoryCardsWithNameAtomComponent } from '@shared/atoms/product-category-cards-with-name-atom/product-category-cards-with-name-atom.component';
import { ProductCategoryCardsWithoutNameAtomComponent } from '@shared/atoms/product-category-cards-without-name-atom/product-category-cards-without-name-atom.component';
import { ProductCategoryHorizontalHighlightAtomComponent } from '@shared/atoms/product-category-horizontal-highlight-atom/product-category-horizontal-highlight-atom.component';
import { CategoryFactoryComponent } from '@shared/blocks/shop-product-list-block/category-factory/category-factory.component';
import { MarketplaceCategoryHorizontalComponent } from '@shared/blocks/marketplace-products-block/marketplace-category-horizontal/marketplace-category-horizontal.component';
import { MarketplaceCategoryVerticalTwoLevelsComponent } from '@shared/blocks/marketplace-products-block/marketplace-category-vertical-two-levels/marketplace-category-vertical-two-levels.component';
import { NotificationOrderAtomComponent } from '@shared/atoms/notification-order-atom/notification-order-atom.component';
import { NotificationBlockComponent } from '@shared/blocks/notification-block/notification-block.component';
import { ProductCardAtomComponent } from '@shared/atoms/product-card-atom/product-card-atom.component';
import { ProductCardListAtomComponent } from '@shared/atoms/product-card-list-atom/product-card-list-atom.component';
import { MarketplaceHeaderAtomComponent } from '@shared/atoms/marketplace-header-atom/marketplace-header-atom.component';
import { MarketplaceProductsPageComponent } from '@shared/pages/marketplace-products-page/marketplace-products-page.component';
import { PushModalButtonAtomComponent } from '@shared/atoms/push-modal-button-atom/push-modal-button-atom.component';
import { AddHomeScreenModalComponent } from '@shared/modals/add-home-screen-modal/add-home-screen-modal.component';
import { TextAtomComponent } from '@shared/atoms/text-atom/text-atom.component';
import { TextBlockComponent } from '@shared/blocks/text-block/text-block.component';
import { CartSuggestionsBlockComponent } from '@shared/blocks/cart-suggestions-block/cart-suggestions-block.component';
import { HorizontalAnchorListAtomComponent } from '@shared/atoms/horizontal-anchor-list-atom/horizontal-anchor-list-atom.component';
import { MarketplaceCategoryTabsBlocksComponent } from '@shared/blocks/marketplace-category-tabs-blocks/marketplace-category-tabs-blocks.component';
import { AuthenticationBlockComponent } from '@shared/blocks/authentication-block/authentication-block.component';
import { OrderCardSkeletonComponent } from '@shared/blocks/orders-page-block/order-card-skeleton/order-card-skeleton.component';
import { OrderCardComponent } from '@shared/blocks/orders-page-block/order-card/order-card.component';
import { ScrollSpyDirective } from '@shared/directives/scroll-spy.directive';
import { AddCreditCardAtomComponent } from '@shared/atoms/add-credit-card-atom/add-credit-card-atom.component';
import { MarketplaceProductsPreloaderBlockComponent } from '@shared/blocks/marketplace-products-preloader-block/marketplace-products-preloader-block.component';
import { PushShopCardV2AtomComponent } from '@shared/atoms/push-shop-card-v2-atom/push-shop-card-v2-atom.component';
import { HorizontalPushShopsListAtomComponent } from '@shared/atoms/horizontal-push-shops-list-atom/horizontal-push-shops-list-atom.component';
import { InputTextAtomComponent } from '@shared/atoms/input-text-atom/input-text-atom.component';
import { CustomSwitchAtomComponent } from '@shared/atoms/custom-switch-atom/custom-switch-atom.component';
import { PushTextButtonV2AtomComponent } from '@shared/atoms/push-text-button-v2-atom/push-text-button-v2-atom.component';
import { PushPictureAtomComponent } from '@shared/atoms/push-picture-atom/push-picture-atom.component';
import { FadedCarouselAtomComponent } from '@shared/atoms/faded-carousel-atom/faded-carousel-atom.component';
import { CartInformationButtonAtomComponent } from '@shared/atoms/cart-information-button-atom/cart-information-button-atom.component';
import { SearchParametersAtomComponent } from '@shared/atoms/search-parameters-atom/search-parameters-atom.component';
import { ProductPushV2AtomComponent } from '@shared/atoms/product-push-v2-atom/product-push-v2-atom.component';
import { PushShopAtomComponent } from '@shared/atoms/push-shop-atom/push-shop-atom.component';
import { StarAtomComponent } from '@shared/atoms/star-atom/star-atom.component';
import { ShopAddressAtomComponent } from '@shared/atoms/shop-address-atom/shop-address-atom.component';
import { HorizontalCategoriesAtomComponent } from '@shared/atoms/horizontal-categories-atom/horizontal-categories-atom.component';
import { OverviewCartItemAtomComponent } from '@shared/atoms/overview-cart-item-atom/overview-cart-item-atom.component';
import { QuantityAtomComponent } from '@shared/atoms/quantity-atom/quantity-atom.component';
import { MarketplaceHeroBlockComponent } from '@shared/blocks/marketplace-hero-block/marketplace-hero-block.component';
import { ShopTypePickerBlockComponent } from '@shared/blocks/shop-type-picker-block/shop-type-picker-block.component';
import { ShopSearchPageComponent } from '@shared/pages/shop-search-page/shop-search-page.component';
import { ShopAroundListBlockComponent } from '@shared/blocks/shop-around-list-block/shop-around-list-block.component';
import { ShopAroundListHeaderBlockComponent } from '@shared/blocks/shop-around-list-header-block/shop-around-list-header-block.component';
import { ShopAroundListBodyBlockComponent } from '@shared/blocks/shop-around-list-body-block/shop-around-list-body-block.component';
import { ShopMeansOfPaymentAtomComponent } from '@shared/atoms/shop-means-of-payment-atom/shop-means-of-payment-atom.component';
import { ShopDescriptionAtomComponent } from '@shared/atoms/shop-description-atom/shop-description-atom.component';
import { ShopInformationsAtomComponent } from '@shared/atoms/shop-informations-atom/shop-informations-atom.component';
import { ToggleFilterAtomComponent } from '@shared/atoms/toggle-filter-atom/toggle-filter-atom.component';
import { OverviewCartAdditionalAtomComponent } from '@shared/atoms/overview-cart-additional-atom/overview-cart-additional-atom.component';
import { OverviewCartAtomComponent } from '@shared/atoms/overview-cart-atom/overview-cart-atom.component';
import { ShopSearchParametersModalComponent } from '@shared/modals/shop-search-parameters-modal/shop-search-parameters-modal.component';
import { CustomSelectAtomComponent } from '@shared/atoms/custom-select-atom/custom-select-atom.component';
import { OpenParametersAtomComponent } from '@shared/atoms/open-parameters-atom/open-parameters-atom.component';
import { FiltersModalComponent } from '@shared/modals/filters-modal/filters-modal.component';
import { ChipAtomComponent } from '@shared/atoms/chip-atom/chip-atom.component';
import { ShopsMapPageComponent } from '@shared/pages/shops-map-page/shops-map-page.component';
import { ShopsMapBlockComponent } from '@shared/blocks/shops-map-block/shops-map-block.component';
import { ParametersWantedAtBlockComponent } from '@shared/blocks/parameters/parameters-wanted-at-block/parameters-wanted-at-block.component';
import { ShopHeroImageAtomComponent } from '@shared/atoms/shop-hero-image-atom/shop-hero-image-atom.component';
import { ShopHeroImageBlockComponent } from '@shared/blocks/shop-hero-image-block/shop-hero-image-block.component';
import { PaymentPanelStripeGenericComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-stripe-generic/payment-panel-stripe-generic.component';
import { ParametersLocationBlockComponent } from '@shared/blocks/parameters/parameters-location-block/parameters-location-block.component';
import { ParametersDistributionModeBlockComponent } from '@shared/blocks/parameters/parameters-distribution-mode-block/parameters-distribution-mode-block.component';
import { CartParametersModalComponent } from '@shared/modals/cart-parameters-modal/cart-parameters-modal.component';
import { ComoHomeMosaicBlockComponent } from '@shared/blocks/como/como-home-mosaic-block/como-home-mosaic-block.component';
import { ComoAssetListAtomComponent } from '@shared/atoms/como/como-asset-list-atom/como-asset-list-atom.component';
import { ComoAssetItemAtomComponent } from '@shared/atoms/como/como-asset-item-atom/como-asset-item-atom.component';
import { CommentCartAtomComponent } from '@shared/atoms/comment-cart-atom/comment-cart-atom.component';
import { CartAdditionalInformationBlockComponent } from '@shared/blocks/cart-additional-information-block/cart-additional-information-block.component';
import { CartCommentModalComponent } from '@shared/modals/cart-comment-modal/cart-comment-modal.component';
import { InputCommentAtomComponent } from '@shared/atoms/input-comment-atom/input-comment-atom.component';
import { ComoAssetListBlockComponent } from '@shared/blocks/como/como-asset-list-block/como-asset-list-block.component';
import { CartVoucherBlockComponent } from '@shared/blocks/cart-voucher-block/cart-voucher-block.component';
import { ComoCreditBalanceCardBlockComponent } from '@shared/blocks/como/como-credit-balance-card-block/como-credit-balance-card-block.component';
import { ComoUseCreditBalanceModalComponent } from '@shared/modals/como/como-use-credit-balance-modal/como-use-credit-balance-modal.component';
import { UserInformationCartAtomComponent } from '@shared/atoms/user-information-cart-atom/user-information-cart-atom.component';
import { CartUserInformationsModalComponent } from '@shared/modals/cart-user-informations-modal/cart-user-informations-modal.component';
import { OrderStatusAtomV2Component } from '@shared/atoms/order-status-atom-v2/order-status-atom-v2.component';
import { MosaicCardBlockComponent } from '@shared/blocks/mosaic-card-block/mosaic-card-block.component';
import { ComoJoinLoyaltyBlockComponent } from '@shared/blocks/como/como-join-loyalty-block/como-join-loyalty-block.component';
import { CompoundProductItemAtomComponent } from '@shared/atoms/compound-product-item-atom/compound-product-item-atom.component';
import { CompoundItemModalComponent } from '@shared/modals/compound-item-modal/compound-item-modal.component';
import { CompoundItemPanelAtomComponent } from '@shared/atoms/compound-item-panel-atom/compound-item-panel-atom.component';
import { ShopCardsBlockComponent } from '@shared/blocks/shop-cards-block/shop-cards-block.component';
import { ShopCardBlockComponent } from '@shared/blocks/shop-card-block/shop-card-block.component';
import { NotificationMessageAtomComponent } from '@shared/atoms/notification-message-atom/notification-message-atom.component';
import { ShopSearchParametersModalLauncherBlockComponent } from '@shared/blocks/modal-launchers/shop-search-parameters-modal-launcher-block/shop-search-parameters-modal-launcher-block.component';
import { CartParametersModalLauncherBlockComponent } from '@shared/blocks/modal-launchers/cart-parameters-modal-launcher-block/cart-parameters-modal-launcher-block.component';
import { CartParametersOverviewComponent } from '@shared/components/cart-parameters-overview/cart-parameters-overview.component';
import { ShopSchedulesAtomComponent } from '@shared/atoms/shop-schedules-atom/shop-schedules-atom.component';
import { ShopInformationsModalComponent } from '@shared/modals/shop-informations-modal/shop-informations-modal.component';
import { CartOnSiteLocationBlockComponent } from '@shared/blocks/cart-on-site-location-block/cart-on-site-location-block.component';
import { HeaderButtonBlockComponent } from '@shared/blocks/header-button-block/header-button-block.component';
import { FirstLetterUppercasePipe } from '@shared/pipes/first-letter-uppercase/first-letter-uppercase.pipe';
import { ActionsInfoShopAtomComponent } from '@shared/atoms/actions-info-shop-atom/actions-info-shop-atom.component';
import { ToggleSwitchAtomComponent } from '@shared/atoms/toggle-switch-atom/toggle-switch-atom.component';
import { BigFooterBlockComponent } from '@shared/blocks/footer/big-footer/big-footer-block/big-footer-block.component';
import { BigFooterBodyDownloadAppsBlockComponent } from '@shared/blocks/footer/big-footer/big-footer-body-download-apps-block/big-footer-body-download-apps-block.component';
import { BigFooterBodySocialNetworksBlockComponent } from '@shared/blocks/footer/big-footer/big-footer-body-social-networks-block/big-footer-body-social-networks-block.component';
import { BigFooterColumnBlockComponent } from '@shared/blocks/footer/big-footer/big-footer-column-block/big-footer-column-block.component';
import { BigFooterColumnItemBlockComponent } from '@shared/blocks/footer/big-footer/big-footer-column-item-block/big-footer-column-item-block.component';
import { LegalsFooterBlockComponent } from '@shared/blocks/footer/legals-footer/legals-footer-block/legals-footer-block.component';
import { LegalsFooterLinkItemBlockComponent } from '@shared/blocks/footer/legals-footer/legals-footer-link-block/legals-footer-link-item-block.component';
import { AccountHeroAtomComponent } from '@shared/atoms/account-hero-atom/account-hero-atom.component';
import { DetailOrderVoucherBlockComponent } from '@shared/blocks/detail-order-voucher-block/detail-order-voucher-block.component';
import { ImageFullWidthBlockComponent } from '@shared/blocks/image-full-width-block/image-full-width-block.component';
import { ImageTwoColsBlockComponent } from '@shared/blocks/image-two-cols-block/image-two-cols-block.component';
import { SocialLinksBlockComponent } from '@shared/blocks/social-links-block/social-links-block.component';
import { YoutubePlayerBlockComponent } from '@shared/blocks/youtube-player-block/youtube-player-block.component';
import { VimeoPlayerBlockComponent } from '@shared/blocks/vimeo-player-block/vimeo-player-block.component';
import { ImageAndTextBlockComponent } from '@shared/blocks/image-and-text-block/image-and-text-block.component';
import { HeaderBigLogoBlockComponent } from '@shared/blocks/header-big-logo-block/header-big-logo-block.component';
import { PushRowBlockComponent } from '@shared/blocks/push-row-block/push-row-block.component';
import { BigPushBlockComponent } from '@shared/blocks/big-push-block/big-push-block.component';
// tslint:disable-next-line:max-line-length
import { ShopSearchParametersOverviewComponent } from '@shared/components/shop-search-parameters-overview/shop-search-parameters-overview.component';
import { ShopSearchLocationInputComponent } from '@shared/components/shop-search-location-input/shop-search-location-input.component';
import { BudgetShopAtomComponent } from '@shared/atoms/budget-shop-atom/budget-shop-atom.component';
import { OnSiteLocationSelectComponent } from '@shared/components/on-site-location-select/on-site-location-select.component';
import { MainLayoutComponent } from '@shared/layouts/main-layout/main-layout.component';
import { DefaultDoodLegalFooterComponent } from '@shared/components/default-dood-legal-footer/default-dood-legal-footer.component';
import { RedirectPageComponent } from '@shared/pages/redirect-page/redirect-page.component';
import { SidebarNavigationModalItemBlockComponent } from '@shared/blocks/sidebar-navigation-modal-item-block/sidebar-navigation-modal-item-block.component';
import { NavigateToMapButtonModalComponent } from '@shared/modals/navigate-to-map-button-modal/navigate-to-map-button-modal.component';
import { AnchorButtonsListBlockComponent } from '@shared/blocks/anchor-buttons-list-block/anchor-buttons-list-block.component';
import { ShopCapabilitiesModalComponent } from '@shared/modals/shop-capabilities-modal/shop-capabilities-modal.component';
import { PageNotFoundComponent } from '@shared/components/page-not-found/page-not-found.component';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderLinkBlockComponent } from '@shared/blocks/header-link-block/header-link-block.component';
import { HomeHeroShowcaseBlockComponent } from '@shared/blocks/home-hero-showcase-block/home-hero-showcase-block.component';
import { SlideShowImageBlockComponent } from '@shared/blocks/slide-show-image-block/slide-show-image-block.component';
import { ImageOfSlideShowBlockComponent } from '@shared/blocks/image-of-slide-show-block/image-of-slide-show-block.component';
import { ImageAndCallToActionsBlockComponent } from '@shared/blocks/image-and-call-to-actions-block/image-and-call-to-actions-block.component';
import { ButtonBlockComponent } from '@shared/blocks/button-block/button-block.component';
import { InformationsContainerBlockComponent } from '@shared/blocks/informations-container-block/informations-container-block.component';
import { InformationsColumnBlockComponent } from '@shared/blocks/informations-column-block/informations-column-block.component';
import { InformationsRowBlockComponent } from '@shared/blocks/informations-row-block/informations-row-block.component';
import { MapLocationBlockComponent } from '@shared/blocks/map-location-block/map-location-block.component';
// tslint:disable-next-line:max-line-length
import { BigFooterBodyLogoBlockComponent } from '@shared/blocks/footer/big-footer/big-footer-body-logo-block/big-footer-body-logo-block.component';
import { BigFooterBodyCallToActionBlockComponent } from '@shared/blocks/footer/big-footer/big-footer-body-call-to-action-block/big-footer-body-call-to-action-block.component';
import { HeaderAnchorListBlockComponent } from '@shared/blocks/header-anchor-list-block/header-anchor-list-block.component';
import { PaymentPanelStripeApplepayComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-stripe-applepay/payment-panel-stripe-applepay.component';
import { PaymentPanelStripeGooglepayComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-stripe-googlepay/payment-panel-stripe-googlepay.component';
import { NgxStripeModule } from 'ngx-stripe';
import { HeaderSocialItemBlockComponent } from '@shared/blocks/header-social-item-block/header-social-item-block.component';
import { MarketplaceHeroSlideImageBlockComponent } from '@shared/blocks/marketplace-hero-slide-image-block/marketplace-hero-slide-image-block.component';
import { MarketplaceHeroSlideTextBlockComponent } from '@shared/blocks/marketplace-hero-slide-text-block/marketplace-hero-slide-text-block.component';
import { SuggestionCardsBlockComponent } from '@shared/blocks/suggestion-cards-block/suggestion-cards-block.component';
import { CartSuggestionCardsBlockComponent } from '@shared/blocks/cart-suggestion-cards-block/cart-suggestion-cards-block.component';
import { CartButtonBlockComponent } from '@shared/blocks/cart-button-block/cart-button-block.component';
import { MosaicComoLinkAccountCardBlockComponent } from '@shared/blocks/mosaic-como-link-account-card-block/mosaic-como-link-account-card-block.component';
import { MosaicComoCreateAccountCardBlockComponent } from '@shared/blocks/mosaic-como-create-account-card-block/mosaic-como-create-account-card-block.component';
import { MosaicComoLongPushAtomComponent } from '@shared/atoms/mosaic-como-long-push-atom/mosaic-como-long-push-atom.component';
import { ComoLinkAccountModalComponent } from '@shared/modals/como/como-link-account-modal/como-link-account-modal.component';
import { ComoCreateAccountModalComponent } from '@shared/modals/como/como-create-account-modal/como-create-account-modal.component';
import { ComoCreateOrLinkAccountModalComponent } from '@shared/modals/como/como-create-or-link-account-modal/como-create-or-link-account-modal.component';
import { PaymentPanelCmiGenericComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-cmi-generic/payment-panel-cmi-generic.component';
import { PaymentPanelCmiComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-cmi/payment-panel-cmi.component';
import { OrderStatusSuccessHeaderBlockComponent } from '@shared/blocks/order-status-success-header-block/order-status-success-header-block.component';
import { OrderStatusFollowOrderCardBlockComponent } from '@shared/blocks/order-status-follow-order-card-block/order-status-follow-order-card-block.component';
import { OrderStatusMessageCardBlockComponent } from '@shared/blocks/order-status-message-card-block/order-status-message-card-block.component';
import { ShopDigitalMenuPageComponent } from '@shared/pages/shop-digital-menu-page/shop-digital-menu-page.component';
import { InformationsTextBlockComponent } from '@shared/blocks/informations-text-block/informations-text-block.component';
import { FsHeroBlockComponent } from '@shared/blocks/food-society/fs-hero-block/fs-hero-block.component';
import { SocialLinkBlockComponent } from '@shared/blocks/social-link-block/social-link-block.component';
import { PageHeroBlockComponent } from '@shared/blocks/page-hero-block/page-hero-block.component';
import { HighlightCardBlockComponent } from '@shared/blocks/highlight-card-block/highlight-card-block.component';
import { ShopCardWithLogoBlockComponent } from '@shared/blocks/shop-card-with-logo-block/shop-card-with-logo-block.component';
import { ShopCardLogoAtomComponent } from '@shared/atoms/shop-card-logo-atom/shop-card-logo-atom.component';
import { ShopSmallHeroBlockComponent } from '@shared/blocks/shop-small-hero-block/shop-small-hero-block.component';
import { HeaderLanguageFlagAtomComponent } from '@shared/atoms/header-language-flag-atom/header-language-flag-atom.component';
import { PaymentPanelStripeTerminalComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-stripe-terminal/payment-panel-stripe-terminal.component';
import { StripeTerminalReaderPickerModalComponent } from '@shared/modals/stripe-terminal-reader-picker-modal/stripe-terminal-reader-picker-modal.component';
import { KioskOrderStatusBlockComponent } from '@shared/blocks/kiosk-order-status-block/kiosk-order-status-block.component';
import { TimerRedirectBlockComponent } from '@shared/blocks/timer-redirect-block/timer-redirect-block.component';
import { SpacerBlockComponent } from '@shared/blocks/spacer-block/spacer-block.component';
import { KioskCartResetTimerBlockComponent } from '@shared/blocks/kiosk-cart-reset-timer-block/kiosk-cart-reset-timer-block.component';
import { KioskCartResetTimerModalComponent } from '@shared/modals/kiosk-cart-reset-timer-modal/kiosk-cart-reset-timer-modal.component';
import { WaitingModalComponent } from '@shared/modals/waiting-modal/waiting-modal.component';
import { ShareGroupedOrderModalComponent } from '@shared/modals/share-grouped-order-modal/share-grouped-order-modal.component';
import { ShareGroupedOrderAtomComponent } from '@shared/atoms/share-grouped-order-atom/share-grouped-order-atom.component';
import { ValidateSharedOrderPageComponent } from '@shared/pages/validate-shared-order-page/validate-shared-order-page.component';
import { ValidateSharedOrderHeaderBlockComponent } from '@shared/blocks/validate-shared-order-header-block/validate-shared-order-header-block.component';
// tslint:disable-next-line:max-line-length
import { ValidateSharedOrderBodyBlockComponent } from '@shared/blocks/validate-shared-order-body-block/validate-shared-order-body-block.component';
import { GroupPaymentPageHeaderBlockComponent } from '@shared/blocks/group-payment-page-header-block/group-payment-page-header-block.component';
import { GroupPaymentListBlockComponent } from '@shared/blocks/group-payment-list-block/group-payment-list-block.component';
import { GroupPaymentPageComponent } from '@shared/pages/group-payment-page/group-payment-page.component';
import { AddPaymentModalComponent } from '@shared/modals/add-payment-modal/add-payment-modal.component';
import { OrderStatusTagAtomComponent } from '@shared/atoms/order-status-tag-atom/order-status-tag-atom.component';
import { ComoSsoLoadingModalComponent } from '@shared/modals/como/como-sso-loading-modal/como-sso-loading-modal.component';
import { ComoConfirmUsePointModalComponent } from '@shared/modals/como/como-confirm-use-point-modal/como-confirm-use-point-modal.component';
import { CartTipPickerBlockComponent } from '@shared/blocks/cart-tip-picker-block/cart-tip-picker-block.component';
import { CartTipAtomComponent } from '@shared/atoms/cart-tip-atom/cart-tip-atom.component';
import { ButtonSquareAtomComponent } from '@shared/atoms/button-square-atom/button-square-atom.component';
import { CartTipPickerModalComponent } from '@shared/modals/cart-tip-picker-modal/cart-tip-picker-modal.component';
import { InputTipPickerAtomComponent } from '@shared/atoms/input-tip-picker-atom/input-tip-picker-atom.component';
import { InputCustomNumberComponent } from '@shared/atoms/input-custom-number/input-custom-number.component';
import { CurrencyAtomComponent } from '@shared/atoms/currency-atom/currency-atom.component';
import { OrderUpdateNotificationSubscriberComponent } from '@shared/components/order-update-notification-subscriber/order-update-notification-subscriber.component';
import { CarouselBlockComponent } from '@shared/blocks/carousel-block/carousel-block.component';
import { CarouselSlideCardBlockComponent } from '@shared/blocks/carousel-slide-card-block/carousel-slide-card-block.component';
import { ZerosixCreateOrLinkAccountModalComponent } from '@shared/modals/zerosix/zerosix-create-or-link-account-modal/zerosix-create-or-link-account-modal.component';
import { ZerosixCreateAccountModalComponent } from '@shared/modals/zerosix/zerosix-create-account-modal/zerosix-create-account-modal.component';
import { ZerosixLinkAccountModalComponent } from '@shared/modals/zerosix/zerosix-link-account-modal/zerosix-link-account-modal.component';
import { NestedPageBlockComponent } from '@shared/blocks/nested-page-block/nested-page-block.component';
import { ZerosixAssetListBlockComponent } from '@shared/blocks/zerosix/zerosix-asset-list-block/zerosix-asset-list-block.component';
import { ZerosixOfferedProductPickerModalComponent } from '@shared/modals/zerosix/zerosix-offered-product-picker-modal/zerosix-offered-product-picker-modal.component';
import { HtmlBlockComponent } from '@shared/blocks/html-block/html-block.component';
import { ScriptBlockComponent } from '@shared/blocks/script-block/script-block.component';
import { InputCountryPhoneComponent } from '@shared/components/input-country-phone/input-country-phone.component';
import { AuthenticationHomeStepComponent } from '@shared/blocks/authentication-block/authentication-home-step/authentication-home-step.component';
import { AuthenticationSignupStepComponent } from '@shared/blocks/authentication-block/authentication-signup-step/authentication-signup-step.component';
import { ModalHeaderBlockComponent } from '@shared/blocks/modal-header-block/modal-header-block.component';
import { ModalButtonBlockComponent } from '@shared/blocks/modal-button-block/modal-button-block.component';
import { GenericModalComponent } from '@shared/modals/generic-modal/generic-modal.component';
import { DeliveryAddressesModalComponent } from '@shared/modals/delivery-addresses-modal/delivery-addresses-modal.component';
import { PaymentPanelLydiaComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-lydia/payment-panel-lydia.component';
// tslint:disable-next-line:max-line-length
import { LydiaPaymentPhoneNumberModalComponent } from '@shared/modals/lydia-payment-phone-number-modal/lydia-payment-phone-number-modal.component';
import { ComoPunchCardsBlockComponent } from '@shared/blocks/como/como-punch-cards-block/como-punch-cards-block.component';
import { DebugNetworkConnectionBlockComponent } from '@shared/blocks/debug/debug-network-connection-block/debug-network-connection-block.component';
import { PaymentPanelAuresEtkTerminalComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-aures-etk-terminal/payment-panel-aures-etk-terminal.component';
import { GroupedOrderModalLauncherBlockComponent } from '@shared/blocks/modal-launchers/grouped-order-modal-launcher-block/grouped-order-modal-launcher-block.component';
import { CartFunnelPageComponent } from '@shared/pages/cart-funnel-page/cart-funnel-page.component';
import { CartFunnelFormBlockComponent } from '@shared/blocks/cart-funnel/cart-funnel-form-block/cart-funnel-form-block.component';
import { CartFunnelHeaderBlockComponent } from '@shared/blocks/cart-funnel/cart-funnel-header-block/cart-funnel-header-block.component';
// tslint:disable-next-line:max-line-length
import { InputMultiAtomComponent } from '@shared/atoms/input-multi-atom/input-multi-atom.component';
// tslint:disable-next-line:max-line-length
import { CartFunnelRadioItemBlockComponent } from '@shared/blocks/cart-funnel/cart-funnel-radio-item-block/cart-funnel-radio-item-block.component';
import { CartFunnelTextItemBlockComponent } from '@shared/blocks/cart-funnel/cart-funnel-text-item-block/cart-funnel-text-item-block.component';
import { CartFunnelRadioOptionBlockComponent } from '@shared/blocks/cart-funnel/cart-funnel-radio-option-block/cart-funnel-radio-option-block.component';
import { SelectLanguageBlockComponent } from '@shared/blocks/select-language-block/select-language-block.component';
import { HeaderDigitalMenuBlockComponent } from '@shared/blocks/header-digital-menu-block/header-digital-menu-block.component';
import { NoConnectionModalComponent } from '@shared/modals/no-connection-modal/no-connection-modal.component';
import { PaymentPanelAdyenTerminalComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-adyen-terminal/payment-panel-adyen-terminal.component';
import { KioskPickerModalComponent } from '@shared/modals/kiosk-picker-modal/kiosk-picker-modal.component';
import { KioskPickerModalLauncherBlockComponent } from '@shared/blocks/modal-launchers/kiosk-picker-modal-launcher-block/kiosk-picker-modal-launcher-block.component';
import { CartFunnelOnBehalfUserFullNameItemBlockComponent } from '@shared/blocks/cart-funnel/cart-funnel-on-behalf-user-full-name-item-block/cart-funnel-on-behalf-user-full-name-item-block.component';
import { CartFunnelOnBehalfUserEmailItemBlockComponent } from '@shared/blocks/cart-funnel/cart-funnel-on-behalf-user-email-item-block/cart-funnel-on-behalf-user-email-item-block.component';
import { CartFunnelOnBehalfUserPhoneItemBlockComponent } from '@shared/blocks/cart-funnel/cart-funnel-on-behalf-user-phone-item-block/cart-funnel-on-behalf-user-phone-item-block.component';
import { PaymentPanelStripeBancontactComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-stripe-bancontact/payment-panel-stripe-bancontact.component';
import { BancontactPaymentFormModalComponent } from '@shared/modals/bancontact-payment-form-modal/bancontact-payment-form-modal.component';
import { PaymentConfirmPageComponent } from '@shared/pages/payment-confirm-page/payment-confirm-page.component';
import { CardCarouselComponent } from '@shared/components/card-carousel/card-carousel.component';
import { PaymentPanelNoPaymentComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-no-payment/payment-panel-no-payment.component';
import { CartFunnelOnBehalfUserOptinItemBlockComponent } from '@shared/blocks/cart-funnel/cart-funnel-on-behalf-user-optin-item-block/cart-funnel-on-behalf-user-optin-item-block.component';
import { CartFunnelCheckboxItemBlockComponent } from '@shared/blocks/cart-funnel/cart-funnel-checkbox-item-block/cart-funnel-checkbox-item-block.component';
import { ConfirmModalComponent } from '@shared/modals/confirm-modal/confirm-modal.component';
import { SplashScreenBlockComponent } from '@shared/blocks/splash-screen-block/splash-screen-block.component';
import { AlertModalComponent } from '@shared/modals/alert-modal/alert-modal.component';
import { ResetCartFunnelBlockComponent } from '@shared/blocks/reset-cart-funnel-block/reset-cart-funnel-block.component';
import { CookieConsentLinkItemBlockComponent } from '@shared/blocks/footer/legals-footer/cookie-consent-link-item-block/cookie-consent-link-item-block.component';
import { CartFooterButtonAtomComponent } from '@shared/atoms/cart-footer-button-atom/cart-footer-button-atom.component';
import { PongoCreateOrLinkAccountModalComponent } from '@shared/modals/pongo/pongo-create-or-link-account-modal/pongo-create-or-link-account-modal.component';
import { PadSplashScreenBlockComponent } from '@shared/blocks/pad-splash-screen-block/pad-splash-screen-block.component';
import { PongoAssetListBlockComponent } from '@shared/blocks/pongo/pongo-asset-list-block/pongo-asset-list-block.component';
import { EmailReceiptAtomComponent } from '@shared/atoms/email-receipt-atom/email-receipt-atom.component';
import { ClassicButtonBlockComponent } from '@shared/blocks/classic-button-block/classic-button-block.component';
import { ImageLogoBlockComponent } from '@shared/blocks/image-logo-block/image-logo-block.component';
import { ImageVariableWidthBlockComponent } from '@shared/blocks/image-variable-width-block/image-variable-width-block.component';
import { AnonymousAuthenticationPreloaderBlockComponent } from '@shared/blocks/anonymous-authentication-preloader-block/anonymous-authentication-preloader-block.component';
import { PaymentPanelLyfpayComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-lyfpay/payment-panel-lyfpay.component';
import { PongoOfferedProductPickerModalComponent } from '@shared/modals/pongo/pongo-offered-product-picker-modal/pongo-offered-product-picker-modal.component';
import { PaymentPanelAdyenMealVoucherNatixisComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-adyen-meal-voucher-natixis/payment-panel-adyen-meal-voucher-natixis.component';
import { PaymentPanelAdyenMealVoucherSodexoComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-adyen-meal-voucher-sodexo/payment-panel-adyen-meal-voucher-sodexo.component';
import { PaymentPanelAdyenMealVoucherGroupeUpComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-adyen-meal-voucher-groupe-up/payment-panel-adyen-meal-voucher-groupe-up.component';
import { ChecksPageComponent } from '@shared/pages/checks-page/checks-page.component';
import { ChecksPageBlockComponent } from '@shared/blocks/checks-page-block/checks-page-block.component';
import { CheckCardComponent } from '@shared/blocks/checks-page-block/check-card/check-card.component';
import { ResumeCheckAtomComponent } from '@shared/atoms/resume-check-atom/resume-check-atom.component';
import { CheckDetailsPageComponent } from '@shared/pages/check-details-page/check-details-page.component';
import { CheckOverviewAtomComponent } from '@shared/atoms/check-overview-atom/check-overview-atom.component';
import { CheckDetailsBlockComponent } from '@shared/blocks/check-details-block/check-details-block.component';
import { PaymentPanelYavinComponent } from '@shared/blocks/payment-method-picker-block/panels/payment-panel-yavin/payment-panel-yavin.component';
import { PongoMarketplaceRewardsListBlockComponent } from '@shared/blocks/pongo/pongo-marketplace-rewards-list-block/pongo-marketplace-rewards-list-block.component';
import { CartPickupLocationBlockComponent } from '@shared/blocks/cart-pickup-location-block/cart-pickup-location-block.component';
import { ComoRegistrationModalComponent } from '@shared/modals/como/como-registration-modal/como-registration-modal.component';
import { PhoneNumberStepComponent } from '@shared/modals/como/como-registration-modal/phone-number-step/phone-number-step.component';
import { OtpStepComponent } from '@shared/modals/como/como-registration-modal/otp-step/otp-step.component';
import { AccountNotFoundStepComponent } from '@shared/modals/como/como-registration-modal/account-not-found-step/account-not-found-step.component';
import { NumericKeypadAtomComponent } from '@shared/atoms/numeric-keypad-atom/numeric-keypad-atom.component';
import { ComoProductsSelectionModalComponent } from '@shared/modals/como/como-products-selection-modal/como-products-selection-modal.component';
import { ComoProductsSelectionStepComponent } from '@shared/modals/como/como-products-selection-modal/como-products-selection-step/como-products-selection-step.component';
import { KioskLanguageFlagAtomComponent } from './atoms/kiosk/kiosk-language-flag-atom/kiosk-language-flag-atom.component';
import { KioskSelectLanguageBlockComponent } from './blocks/kiosk/kiosk-select-language-block/kiosk-select-language-block.component';
import { PatPaymentMethodPickerBlockComponent } from './blocks/pat-payment-method-picker-block/pat-payment-method-picker-block.component';
import { PatBaseModalComponent } from '@shared/components/pat-base-modal/pat-base-modal.component';
import { PatChecksErrorComponent } from '@shared/components/pat-checks-error/pat-checks-error.component';
import { PatPageComponent } from '@shared/pages/pat-page/pat-page.component';
import { PatPaymentConfirmationPageComponent } from '@shared/pages/pat-payment-confirmation-page/pat-payment-confirmation-page.component';
import { PatPaymentPageComponent } from '@shared/pages/pat-payment-page/pat-payment-page.component';
import { PatChooseAmountPageComponent } from '@shared/pages/pat-choose-amount-page/pat-choose-amount-page.component';
import { PatOverviewBlockComponent } from '@shared/blocks/pat-overview-block/pat-overview-block.component';
import { VisibleLabelsPipe } from '@shared/pipes/visible-product-labels/visible-product-labels.pipe';

import { UserFeedbackBlockComponent } from '@shared/blocks/user-feedback-block/user-feedback-block.component';
import { ShareUserFeedbackModalComponent } from '@shared/modals/share-user-feedback-modal/share-user-feedback-modal.component';
import { ExternalLinkButtonBlockComponent } from '@shared/blocks/external-link-button-block/external-link-button-block.component';
import { SplashScreenImageModalComponent } from '@shared/modals/splash-screen-image-modal/splash-screen-image-modal.component';
import { SplashScreenImageModalBlockComponent } from '@shared/blocks/splash-screen-image-modal-block/splash-screen-image-modal-block.component';
import { UserFeedbackLinkBlockComponent } from '@shared/blocks/user-feedback-link-block/user-feedback-link-block.component';
import { OrderReceiveReceiptBlockComponent } from './blocks/order-receive-receipt-block/order-receive-receipt-block.component';
import { PaymentPanelYavinTerminalComponent } from './blocks/payment-method-picker-block/panels/payment-panel-yavin-terminal/payment-panel-yavin-terminal.component';
import { KioskHeaderAtomComponent } from './atoms/kiosk/kiosk-header-atom/kiosk-header-atom.component';
import { KioskHeaderBlockComponent } from './blocks/kiosk/kiosk-header-block/kiosk-header-block.component';
import { KioskSplashScreenPageComponent } from './pages/kiosk/kiosk-splash-screen-page/kiosk-splash-screen-page.component';
import { KioskVerticalCategoryListAtomComponent } from './atoms/kiosk/kiosk-vertical-category-list-atom/kiosk-vertical-category-list-atom.component';
import { ComoCreateOrLinkAccountModalAttachWithEmailStepComponent } from './modals/como/como-create-or-link-account-modal/como-create-or-link-account-modal-attach-with-email-step/como-create-or-link-account-modal-attach-with-email-step.component';
import { ComoCreateOrLinkAccountModalIdentificationStepComponent } from './modals/como/como-create-or-link-account-modal/como-create-or-link-account-modal-identification-step/como-create-or-link-account-modal-identification-step.component';
import { ComoCreateOrLinkAccountModalOtpStepComponent } from './modals/como/como-create-or-link-account-modal/como-create-or-link-account-modal-otp-step/como-create-or-link-account-modal-otp-step.component';
import { ComoCreateOrLinkAccountModalRegistrationStepComponent } from './modals/como/como-create-or-link-account-modal/como-create-or-link-account-modal-registration-step/como-create-or-link-account-modal-registration-step.component';
import { AuthenticationStepComponent } from './modals/como/como-registration-modal/authentication-step/authentication-step.component';
import { KioskShopCategoryListBlockComponent } from './blocks/kiosk/kiosk-shop-category-list-block/kiosk-shop-category-list-block.component';
import { KioskShopPageComponent } from './pages/kiosk/kiosk-shop-page/kiosk-shop-page.component';
import { KioskCategoryPageComponent } from './pages/kiosk/kiosk-category-page/kiosk-category-page.component';
import { KioskProductCardAtomComponent } from './atoms/kiosk/kiosk-product-card-atom/kiosk-product-card-atom.component';
import { KioskFooterCartBlockComponent } from './blocks/kiosk/kiosk-footer-cart-block/kiosk-footer-cart-block.component';
import { PatPaymentPanelEdenredComponent } from '@shared/blocks/pat-payment-method-picker-block/pat-panel/pat-payment-panel-edenred/pat-payment-panel-edenred.component';
import { PatDivideAmountModalComponent } from '@shared/modals/pat-divide-amount-modal/pat-divide-amount-modal.component';
import { PatFreeAmountModalComponent } from '@shared/modals/pat-free-amount-modal/pat-free-amount-modal.component';
import { PatSelectProductsModalComponent } from '@shared/modals/pat-select-products-modal/pat-select-products-modal.component';
import { PatPaymentPanelStripeCbComponent } from '@shared/blocks/pat-payment-method-picker-block/pat-panel/pat-payment-panel-stripe-cb/pat-payment-panel-stripe-cb.component';
import { PatPaymentPanelStripeGooglepayComponent } from '@shared/blocks/pat-payment-method-picker-block/pat-panel/pat-payment-panel-stripe-googlepay/pat-payment-panel-stripe-googlepay.component';
import { PatPaymentPanelStripeApplepayComponent } from '@shared/blocks/pat-payment-method-picker-block/pat-panel/pat-payment-panel-stripe-applepay/pat-payment-panel-stripe-applepay.component';
import { PatPaymentPanelStripeGenericComponent } from '@shared/blocks/pat-payment-method-picker-block/pat-panel/pat-payment-panel-stripe-generic/pat-payment-panel-stripe-generic.component';
import { PatPaymentPanelPaygreenGenericComponent } from '@shared/blocks/pat-payment-method-picker-block/pat-panel/pat-payment-panel-paygreen-generic/pat-payment-panel-paygreen-generic.component';
import { PatPaymentPanelPaygreenLunchrComponent } from '@shared/blocks/pat-payment-method-picker-block/pat-panel/pat-payment-panel-paygreen-lunchr/pat-payment-panel-paygreen-lunchr.component';
import { PatPaymentPanelPaygreenRestoflashComponent } from '@shared/blocks/pat-payment-method-picker-block/pat-panel/pat-payment-panel-paygreen-restoflash/pat-payment-panel-paygreen-restoflash.component';
import { PatPaymentPanelYavinComponent } from '@shared/blocks/pat-payment-method-picker-block/pat-panel/pat-payment-panel-yavin/pat-payment-panel-yavin.component';
import { PatPaymentPanelLyfpayComponent } from '@shared/blocks/pat-payment-method-picker-block/pat-panel/pat-payment-panel-lyfpay/pat-payment-panel-lyfpay.component';
import { PatPaymentPanelAdyenApplepayComponent } from '@shared/blocks/pat-payment-method-picker-block/pat-panel/pat-payment-panel-adyen-applepay/pat-payment-panel-adyen-applepay.component';
import { PatPaymentPanelAdyenCbSkeletonComponent } from '@shared/blocks/pat-payment-method-picker-block/pat-panel/pat-payment-panel-adyen-cb/pat-payment-panel-adyen-cb-skeleton/pat-payment-panel-adyen-cb-skeleton.component';
import { PatPaymentPanelAdyenCbComponent } from '@shared/blocks/pat-payment-method-picker-block/pat-panel/pat-payment-panel-adyen-cb/pat-payment-panel-adyen-cb.component';
import { PatPaymentPanelAdyenGenericComponent } from '@shared/blocks/pat-payment-method-picker-block/pat-panel/pat-payment-panel-adyen-generic/pat-payment-panel-adyen-generic.component';
import { PatPaymentPanelAdyenGooglepayComponent } from '@shared/blocks/pat-payment-method-picker-block/pat-panel/pat-payment-panel-adyen-googlepay/pat-payment-panel-adyen-googlepay.component';
import { CallComponentMethodPipe } from './pipes/call-component-method/call-component-method.pipe';
import { KioskLightProductDetailsPageComponent } from './pages/kiosk/kiosk-light-product-details-page/kiosk-light-product-details-page.component';
import { KioskProductAddPageComponent } from './pages/kiosk/kiosk-product-add-page/kiosk-product-add-page.component';
import { KioskDistributionModePickerPageComponent } from './pages/kiosk/kiosk-distribution-mode-picker-page/kiosk-distribution-mode-picker-page.component';
import { KioskCartPageComponent } from './pages/kiosk/kiosk-cart-page/kiosk-cart-page.component';
import { KioskVirtualKeyboardComponent } from './pages/kiosk/kiosk-virtual-keyboard/kiosk-virtual-keyboard.component';
import { KioskActiveButtonAtomComponent } from './atoms/kiosk/kiosk-active-button-atom/kiosk-active-button-atom.component';
import { KioskCartPromoCodeBlockComponent } from './blocks/kiosk/kiosk-cart-promo-code-block/kiosk-cart-promo-code-block.component';
import { KioskCartProductsListBlockComponent } from './blocks/kiosk/kiosk-cart-products-list-block/kiosk-cart-products-list-block.component';
import { KioskCartLoyaltyBlockComponent } from './blocks/kiosk/kiosk-cart-loyalty-block/kiosk-cart-loyalty-block.component';
import { KioskComoItemAtomComponent } from './atoms/kiosk/kiosk-como-item-atom/kiosk-como-item-atom.component';
import { KioskCartFooterBlockComponent } from './blocks/kiosk/kiosk-cart-footer-block/kiosk-cart-footer-block.component';
import { KioskProductsSameCategoryBlockComponent } from './blocks/kiosk/kiosk-products-same-category-block/kiosk-products-same-category-block.component';
import { KioskProductDetailsBlockComponent } from './blocks/kiosk/kiosk-product-details-block/kiosk-product-details-block.component';
import { KioskPromoSelectProductPageComponent } from './pages/kiosk/kiosk-promo-select-product-page/kiosk-promo-select-product-page.component';
import { KioskPromoSelectProductBlockComponent } from './blocks/kiosk/kiosk-promo-select-product-block/kiosk-promo-select-product-blok.component';
import { KioskFooterDoubleButtonAtomComponent } from './atoms/kiosk/kiosk-footer-double-button-atom/kiosk-footer-double-button-atom.component';
import { KioskComoStepAtomComponent } from './atoms/kiosk/kiosk-como-step-atom/kiosk-como-step-atom.component';
import { KioskLoyaltyConnectionPageComponent } from './pages/kiosk/kiosk-loyalty-connection-page/kiosk-loyalty-connection-page.component';
import { KioskLoyaltyConnectionBlockComponent } from './blocks/kiosk/kiosk-loyalty-connection-block/kiosk-loyalty-connection-block.component';
import { KioskDoubleButtonFooterBlockComponent } from './blocks/kiosk/kiosk-double-button-footer-block/kiosk-double-button-footer-block.component';
import { KioskCartFunnelHeaderBlockComponent } from './blocks/kiosk/cart-funnel/kiosk-cart-funnel-header-block/kiosk-cart-funnel-header-block.component';
import { KioskCartFunnelPageComponent } from './pages/kiosk/kiosk-cart-funnel-page/kiosk-cart-funnel-page.component';
import { KioskCartFunnelRadioItemBlockComponent } from './blocks/kiosk/cart-funnel/kiosk-cart-funnel-radio-item-block/kiosk-cart-funnel-radio-item-block.component';
import { KioskCartFunnelRadioOptionBlockComponent } from './blocks/kiosk/cart-funnel/kiosk-cart-funnel-radio-option-block/kiosk-cart-funnel-radio-option-block.component';
import { KioskCartFunnelFormBlockComponent } from './blocks/kiosk/cart-funnel/kiosk-cart-funnel-form-block/kiosk-cart-funnel-form-block.component';
import { KioskCartFunnelInputTextBlockComponent } from './blocks/kiosk/cart-funnel/kiosk-cart-funnel-input-text-block/kiosk-cart-funnel-input-text-block.component';
import { KioskInputRadiotAtomComponent } from './atoms/kiosk/kiosk-input-radio-atom/kiosk-input-radio-atom.component';
import { KioskCartFunnelPagerBlockComponent } from './blocks/kiosk/cart-funnel/kiosk-cart-funnel-pager-block/kiosk-cart-funnel-pager-blockcomponent';
import { KioskPagerAtomComponent } from './atoms/kiosk/kiosk-pager-atom/kiosk-pager-atom.component';
import { KioskProductOverviewCartAtomComponent } from './atoms/kiosk/kiosk-product-overview-cart-atom/kiosk-product-overview-cart-atom.component';
import { KioskPaymentMethodPickerBlockComponent } from './blocks/kiosk-payment-method-picker-block/kiosk-payment-method-picker-block.component';
import { KioskSuggestionsModalComponent } from './blocks/kiosk/kiosk-suggestions-modal/kiosk-suggestions-modal.component';
import { KioskSuggestionsModalBlockComponent } from './blocks/kiosk/kiosk-suggestions-modal-block/kiosk-suggestions-modal-block.component';
import { KioskSelectionGroupWrapperComponent } from './blocks/kiosk/kiosk-selection-group-wrapper-atom/kiosk-selection-group-wrapper-atom.component';
import { SimpleProductCardOrganismComponent } from './atoms/kiosk/kiosk-simple-product-card-organism/kiosk-simple-product-card-organism.component';
import { KioskSelectProductCategoryOrganismComponent } from './atoms/kiosk/kiosk-select-product-category-organism/kiosk-select-product-category-organism.component';
import { KioskSelectDeclinableProductsComponent } from './atoms/kiosk/kiosk-select-declinable-products/kiosk-select-declinable-products.component';
import { KioskSelectSimpleProductModalComponent } from './modals/kiosk-select-simple-product-modal/kiosk-select-simple-product-modal.component';
import { KioskProductDetailsOrganismComponent } from './atoms/kiosk/kiosk-product-details-organism/kiosk-product-details-organism.component';
import { KioskCartFunnelOnBehalfFieldBlockComponent } from './blocks/kiosk/cart-funnel/kiosk-cart-funnel-on-behalf-field-block/kiosk-cart-funnel-on-behalf-field-block.component';
import { KioskInputMultiAtomComponent } from './atoms/kiosk/kiosk-input-multi-atom/kiosk-input-multi-atom.component';
import { CheckboxAtomComponent } from './atoms/checkbox-atom/checkbox-atom.component';
import { PatAvailabilityModalComponent } from './modals/pat-availability-modal/pat-availability-modal.component';
import { StickyBlockComponent } from './blocks/sticky-block/sticky-block.component';
import { SimpleHeaderBlockComponent } from './blocks/simple-header-block/simple-header-block.component';
import { SimpleHeaderImageItemBlockComponent } from './blocks/simple-header-image-item-block/simple-header-image-item-block.component';
import { SimpleHeaderTextItemBlockComponent } from './blocks/simple-header-text-item-block/simple-header-text-item-block.component';
import { PaymentPanelStripeTwintComponent } from './blocks/payment-method-picker-block/panels/payment-panel-stripe-twint/payment-panel-stripe-twint.component';
import { PatPaymentPanelStripeTwintComponent } from './blocks/pat-payment-method-picker-block/pat-panel/pat-payment-panel-stripe-twint/pat-payment-panel-stripe-twint.component';
import { ParametersDeliveryInstructionsBlockComponent } from './blocks/parameters/parameters-delivery-instructions-block/parameters-delivery-instructions-block.component';
import { KioskConfirmationStepComponent } from './modals/como/como-registration-modal/kiosk-confirmation-step/kiosk-confirmation-step.component';
import { KioskAccountNotFoundStep } from './modals/como/como-registration-modal/kiosk-account-not-found-step/kiosk-account-not-found-step.component';
import { KioskRegisterConfirmationStepComponent } from './modals/como/como-registration-modal/kiosk-register-confirmation-step/kiosk-register-confirmation-step.component';
import { KioskRegisterStepComponent } from './modals/como/como-registration-modal/kiosk-register-step/kiosk-register-step.component';
import { KioskSelectAdditionsProductOrganismComponent } from './atoms/kiosk/kiosk-select-addition-product-organism/kiosk-select-addition-product-organism.component';
import { KioskProductOverviewModalComponent } from './modals/kiosk-product-overview-modal/kiosk-product-overview-modal.component';
import { KioskStepperNavigationAtomComponent } from './atoms/kiosk/kiosk-stepper-navigation-atom/kiosk-stepper-navigation-atom.component';
import { KioskCategoryStepperComponent } from './atoms/kiosk/kiosk-product-stepper/kiosk-category-stepper.component';
import { KioskSimpleProductStepperComponent } from './atoms/kiosk/kiosk-product-stepper/kiosk-simple-product-stepper.component';
import { KioskSimpleProductDetailsOrganism } from './atoms/kiosk/kiosk-simple-product-details-organism/kiosk-simple-product-details-organism.component';
import { PaymentLoadingScreenAtomComponent } from './atoms/payment-loading-screen-atom/payment-loading-screen-atom.component';
import { HeroCardCarouselBlockComponent } from './blocks/hero-card-carousel-block/hero-card-carousel-block.component';
import { HeroCarouselBlockComponent } from './blocks/hero-carousel-block/hero-carousel-block.component';
import { ShopHeroTemplateBlockComponent } from './blocks/shop-hero-template-block/shop-hero-template-block.component';
import { KioskAlertModalComponent } from './modals/kiosk-alert-modal/kiosk-alert-modal.component';
import { CartFunnelSelectBlockComponent } from './blocks/cart-funnel/cart-funnel-select-block/cart-funnel-select-block.component';
import { KioskHorizontalScrollComponent } from './blocks/kiosk/kiosk-horizontal-scroll/kiosk-hotizontal-scroll.component';
import { KioskDraftCartOrganismComponent } from './atoms/kiosk/kiosk-draft-cart-composition-organism/kiosk-draft-cart-composition-organism.component';
import {
  PaymentPanelVivaWalletComponent
} from "@shared/blocks/payment-method-picker-block/panels/payment-panel-viva-wallet/payment-panel-viva-wallet.component";

@NgModule({
  imports: [
    CoreModule,
    FormsModule,
    RouterModule,
    CommonModule,
    APP_STORE_FEATURES,
    ReactiveFormsModule,
    TranslateModule,
    NgxStripeModule,
    // Google Maps
    GoogleMapsModule,
    CallComponentMethodPipe,
    KioskCategoryStepperComponent,
    KioskSimpleProductStepperComponent,
    KioskHorizontalScrollComponent,
    KioskDraftCartOrganismComponent,
    KioskProductOverviewCartAtomComponent,
    ButtonAtomComponent,
  ],
  providers: [TitleCasePipe, CurrencyPipe],
  exports: [
    NotificationBlockComponent,
    PageNotFoundComponent,
    CookieConsentLinkItemBlockComponent,
    PriceAtomComponent,
    ButtonAtomComponent,
    HeaderAtomComponent,
    BackHeaderAtomComponent,
    InputMultiAtomComponent,
    ModalFillerAtomComponent,
    OverviewCartItemAtomComponent,
    QuantityAtomComponent,
    CounterAtomComponent,
    InputTextAtomComponent,
    EmailReceiptAtomComponent,
    CartTipAtomComponent,
    InputTipPickerAtomComponent,
  ],
  declarations: [
    KioskSimpleProductDetailsOrganism,
    KioskProductDetailsOrganismComponent,
    KioskSelectDeclinableProductsComponent,
    KioskSelectAdditionsProductOrganismComponent,
    KioskSelectProductCategoryOrganismComponent,
    SimpleProductCardOrganismComponent,
    KioskSelectionGroupWrapperComponent,
    VisibleLabelsPipe,
    HeroBlockComponent,
    GenericPageComponent,
    TagAtomComponent,
    TagListAtomComponent,
    ShopHeroSimpleBlockComponent,
    ShopPageComponent,
    ShopProductListBlockComponent,
    HeaderBlockComponent,
    HeadingContentBlockComponent,
    ShopSmallOverviewListBlockComponent,
    ShopSmallOverviewGridBlockComponent,
    DevBlockContainerAtomComponent,
    ShopCategoryTabsBlockComponent,
    ShopsCustomMapBlockComponent,
    ShopsCustomMapPageComponent,
    CartHeaderBlockComponent,
    CartItemListBlockComponent,
    CartPageComponent,
    OrderStatusPageComponent,
    OrderStatusBlockComponent,
    SocialNetworksFollowUsBlockComponent,
    CartOverviewModalComponent,
    SidebarNavigationModalComponent,
    AuthenticationModalComponent,
    ModalFillerAtomComponent,
    PatDivideAmountModalComponent,
    PatFreeAmountModalComponent,
    PatSelectProductsModalComponent,
    AccountPageComponent,
    OrdersPageComponent,
    FavoritesPageComponent,
    AccountPageBlockComponent,
    OrdersPageBlockComponent,
    FavoritesPageBlockComponent,
    CartFooterModalComponent,
    PaymentPageComponent,
    PaymentPageHeaderBlockComponent,
    NextButtonAtomComponent,
    AutoFocusDirective,
    PriceAtomComponent,
    AddButtonAtomComponent,
    RoundedIconButtonComponent,
    FatButtonAtomComponent,
    ProductPushAtomComponent,
    CounterAtomComponent,
    HeaderAtomComponent,
    HamburgerMenuAtomComponent,
    ProductAddPageComponent,
    ProductAddModalComponent,
    PushShopCardAtomComponent,
    ProductCategoryAtomComponent,
    HorizontalFilterListAtomComponent,
    ProductPanelAtomComponent,
    ShopListAtomComponent,
    TabListAtomComponent,
    FoodSocietyHeroBlockComponent,
    MosaicBlockComponent,
    MosaicCardBlockComponent,
    FoodSocietyFatButtonBlockComponent,
    FoodSocietyShopListBlockComponent,
    SimpleSmallHeroAtomComponent,
    CartItemAtomComponent,
    IconButtonComponent,
    MosaicLongPushAtomComponent,
    MosaicPushAtomComponent,
    InputVoucherAtomComponent,
    DiscountVoucherAtomComponent,
    PaymentMethodPickerBlockComponent,
    PaymentPanelStripeComponent,
    CartTotalAmountAtomComponent,
    CartMerchantAmountBlockComponent,
    ShopCardsBlockComponent,
    ShopCardBlockComponent,
    ItemEditModalComponent,
    ItemEditPageComponent,
    ResumeOrderAtomComponent,
    PaymentPanelEdenredComponent,
    PaymentPanelOnSiteWithStripeComponent,
    PaymentPanelOnSiteWithoutDepositComponent,
    PaymentPanelPaygreenTrdComponent,
    PaymentPanelPaygreenRestoflashComponent,
    PaymentPanelPaygreenLunchrComponent,
    PaymentPanelAdyenCbComponent,
    PaymentPanelAdyenPaypalComponent,
    PaymentPanelAdyenApplepayComponent,
    PaymentPanelAdyenGooglepayComponent,
    PaymentPanelAdyenCbSkeletonComponent,
    PaymentPanelAdyenGenericComponent,
    PaymentPanelAdyenTerminalComponent,
    PatPaymentPanelStripeCbComponent,
    PatPaymentPanelStripeTwintComponent,
    PatPaymentPanelStripeGooglepayComponent,
    PatPaymentPanelStripeApplepayComponent,
    PatPaymentPanelStripeGenericComponent,
    PatPaymentPanelEdenredComponent,
    PatPaymentPanelPaygreenGenericComponent,
    PatPaymentPanelPaygreenLunchrComponent,
    PatPaymentPanelPaygreenRestoflashComponent,
    PatPaymentPanelYavinComponent,
    PatPaymentPanelLyfpayComponent,
    PatPaymentPanelAdyenApplepayComponent,
    PatPaymentPanelAdyenCbSkeletonComponent,
    PatPaymentPanelAdyenCbComponent,
    PatPaymentPanelAdyenGenericComponent,
    PatPaymentPanelAdyenGooglepayComponent,
    TopHeroAtomComponent,
    OrderStatusAtomComponent,
    TagStatusAtomComponent,
    SmallButtonAtomComponent,
    BackHeaderAtomComponent,
    CartVoucherModalComponent,
    DetailOrderAtomComponent,
    CardButtonAtomComponent,
    MarketplaceProductsBlockComponent,
    PaymentPanelButtonSkeletonComponent,
    OrderDetailsPageComponent,
    OrderDetailsBlockComponent,
    OrderDetailsPageHeaderComponent,
    PaymentPanelPaygreenGenericComponent,
    ShopHeaderNavigationBlockComponent,
    PushTextButtonAtomComponent,
    OrderStatusPushItemBlockComponent,
    SocialItemAtomComponent,
    EventItemAtomComponent,
    ProductPushListAtomComponent,
    ProductCategoryCardsWithNameAtomComponent,
    ProductCategoryCardsWithoutNameAtomComponent,
    ProductCategoryHorizontalHighlightAtomComponent,
    CategoryFactoryComponent,
    MarketplaceCategoryHorizontalComponent,
    MarketplaceCategoryVerticalTwoLevelsComponent,
    ProductCardAtomComponent,
    ProductCardListAtomComponent,
    NotificationOrderAtomComponent,
    NumericKeypadAtomComponent,
    NotificationBlockComponent,
    MarketplaceHeaderAtomComponent,
    MarketplaceProductsPageComponent,
    CartSuggestionsBlockComponent,
    PushModalButtonAtomComponent,
    AddHomeScreenModalComponent,
    TextAtomComponent,
    TextBlockComponent,
    HorizontalAnchorListAtomComponent,
    MarketplaceCategoryTabsBlocksComponent,
    AuthenticationBlockComponent,
    AuthenticationHomeStepComponent,
    AuthenticationSignupStepComponent,
    OrderCardSkeletonComponent,
    OrderCardComponent,
    ScrollSpyDirective,
    AddCreditCardAtomComponent,
    MarketplaceProductsPreloaderBlockComponent,
    PushShopCardV2AtomComponent,
    HorizontalPushShopsListAtomComponent,
    InputTextAtomComponent,
    CustomSwitchAtomComponent,
    PushTextButtonV2AtomComponent,
    PushPictureAtomComponent,
    FadedCarouselAtomComponent,
    CartInformationButtonAtomComponent,
    SearchParametersAtomComponent,
    ProductPushV2AtomComponent,
    StarAtomComponent,
    ShopAddressAtomComponent,
    PushShopAtomComponent,
    HorizontalCategoriesAtomComponent,
    OverviewCartItemAtomComponent,
    QuantityAtomComponent,
    MarketplaceHeroBlockComponent,
    ShopTypePickerBlockComponent,
    ShopSearchPageComponent,
    ShopAroundListBlockComponent,
    ShopAroundListHeaderBlockComponent,
    ShopAroundListBodyBlockComponent,
    ShopMeansOfPaymentAtomComponent,
    ShopDescriptionAtomComponent,
    ShopInformationsAtomComponent,
    ToggleFilterAtomComponent,
    OverviewCartAdditionalAtomComponent,
    OverviewCartAtomComponent,
    ShopSearchParametersModalComponent,
    ParametersWantedAtBlockComponent,
    CustomSelectAtomComponent,
    OpenParametersAtomComponent,
    FiltersModalComponent,
    ChipAtomComponent,
    ShopsMapPageComponent,
    ShopsMapBlockComponent,
    ShopHeroImageAtomComponent,
    ShopHeroImageBlockComponent,
    CompoundProductItemAtomComponent,
    CompoundItemModalComponent,
    CompoundItemPanelAtomComponent,
    ParametersLocationBlockComponent,
    ParametersDistributionModeBlockComponent,
    CartParametersModalComponent,
    PaymentPanelStripeGenericComponent,
    ComoHomeMosaicBlockComponent,
    ComoAssetListAtomComponent,
    ComoAssetItemAtomComponent,
    CommentCartAtomComponent,
    CartAdditionalInformationBlockComponent,
    CartCommentModalComponent,
    InputCommentAtomComponent,
    ComoAssetListBlockComponent,
    CartVoucherBlockComponent,
    ComoCreditBalanceCardBlockComponent,
    ComoUseCreditBalanceModalComponent,
    UserInformationCartAtomComponent,
    CartUserInformationsModalComponent,
    OrderStatusAtomV2Component,
    ComoJoinLoyaltyBlockComponent,
    NotificationMessageAtomComponent,
    ShopSearchParametersModalLauncherBlockComponent,
    CartParametersModalLauncherBlockComponent,
    CartParametersOverviewComponent,
    ShopInformationsModalComponent,
    ShopSchedulesAtomComponent,
    CartOnSiteLocationBlockComponent,
    HeaderButtonBlockComponent,
    FirstLetterUppercasePipe,
    AccountHeroAtomComponent,
    ActionsInfoShopAtomComponent,
    ToggleSwitchAtomComponent,
    BigFooterBlockComponent,
    BigFooterBodyDownloadAppsBlockComponent,
    BigFooterBodySocialNetworksBlockComponent,
    BigFooterColumnBlockComponent,
    BigFooterColumnItemBlockComponent,
    LegalsFooterBlockComponent,
    LegalsFooterLinkItemBlockComponent,
    DetailOrderVoucherBlockComponent,
    ImageFullWidthBlockComponent,
    ImageTwoColsBlockComponent,
    SocialLinksBlockComponent,
    YoutubePlayerBlockComponent,
    VimeoPlayerBlockComponent,
    ImageAndTextBlockComponent,
    HeaderBigLogoBlockComponent,
    PushRowBlockComponent,
    BigPushBlockComponent,
    ShopSearchParametersOverviewComponent,
    ShopSearchLocationInputComponent,
    BudgetShopAtomComponent,
    OnSiteLocationSelectComponent,
    MainLayoutComponent,
    DefaultDoodLegalFooterComponent,
    RedirectPageComponent,
    SidebarNavigationModalItemBlockComponent,
    NavigateToMapButtonModalComponent,
    AnchorButtonsListBlockComponent,
    ShopCapabilitiesModalComponent,
    PageNotFoundComponent,
    HeaderLinkBlockComponent,
    HomeHeroShowcaseBlockComponent,
    SlideShowImageBlockComponent,
    ImageOfSlideShowBlockComponent,
    ImageAndCallToActionsBlockComponent,
    ButtonBlockComponent,
    InformationsContainerBlockComponent,
    InformationsColumnBlockComponent,
    InformationsRowBlockComponent,
    MapLocationBlockComponent,
    BigFooterBodyLogoBlockComponent,
    BigFooterBodyCallToActionBlockComponent,
    HeaderAnchorListBlockComponent,
    PaymentPanelStripeApplepayComponent,
    PaymentPanelStripeGooglepayComponent,
    PaymentPanelStripeTwintComponent,
    HeaderSocialItemBlockComponent,
    MarketplaceHeroSlideImageBlockComponent,
    MarketplaceHeroSlideTextBlockComponent,
    SuggestionCardsBlockComponent,
    CartSuggestionCardsBlockComponent,
    CartButtonBlockComponent,
    MosaicComoLinkAccountCardBlockComponent,
    MosaicComoCreateAccountCardBlockComponent,
    MosaicComoLongPushAtomComponent,
    ComoLinkAccountModalComponent,
    ComoCreateAccountModalComponent,
    ComoCreateOrLinkAccountModalComponent,
    PaymentPanelCmiGenericComponent,
    PaymentPanelCmiComponent,
    OrderStatusSuccessHeaderBlockComponent,
    OrderStatusFollowOrderCardBlockComponent,
    OrderStatusMessageCardBlockComponent,
    ShopDigitalMenuPageComponent,
    InformationsTextBlockComponent,
    FsHeroBlockComponent,
    SocialLinkBlockComponent,
    HighlightCardBlockComponent,
    ShopCardWithLogoBlockComponent,
    ShopCardLogoAtomComponent,
    PageHeroBlockComponent,
    ShopSmallHeroBlockComponent,
    HeaderLanguageFlagAtomComponent,
    KioskLanguageFlagAtomComponent,
    PaymentPanelStripeTerminalComponent,
    StripeTerminalReaderPickerModalComponent,
    KioskOrderStatusBlockComponent,
    TimerRedirectBlockComponent,
    SpacerBlockComponent,
    KioskCartResetTimerBlockComponent,
    KioskCartResetTimerModalComponent,
    WaitingModalComponent,
    ShareGroupedOrderModalComponent,
    ShareGroupedOrderAtomComponent,
    ValidateSharedOrderPageComponent,
    ValidateSharedOrderHeaderBlockComponent,
    ValidateSharedOrderBodyBlockComponent,
    GroupPaymentPageHeaderBlockComponent,
    GroupPaymentListBlockComponent,
    GroupPaymentPageComponent,
    AddPaymentModalComponent,
    OrderStatusTagAtomComponent,
    ComoSsoLoadingModalComponent,
    ComoConfirmUsePointModalComponent,
    CartTipPickerBlockComponent,
    CartTipAtomComponent,
    ButtonSquareAtomComponent,
    CartTipPickerModalComponent,
    InputTipPickerAtomComponent,
    InputCustomNumberComponent,
    CurrencyAtomComponent,
    OrderUpdateNotificationSubscriberComponent,
    CarouselBlockComponent,
    CarouselSlideCardBlockComponent,
    ZerosixCreateOrLinkAccountModalComponent,
    ZerosixCreateAccountModalComponent,
    ZerosixLinkAccountModalComponent,
    NestedPageBlockComponent,
    ZerosixAssetListBlockComponent,
    ZerosixOfferedProductPickerModalComponent,
    PongoAssetListBlockComponent,
    HtmlBlockComponent,
    ScriptBlockComponent,
    InputCountryPhoneComponent,
    ModalHeaderBlockComponent,
    ModalButtonBlockComponent,
    GenericModalComponent,
    DeliveryAddressesModalComponent,
    PaymentPanelLydiaComponent,
    PaymentPanelAuresEtkTerminalComponent,
    LydiaPaymentPhoneNumberModalComponent,
    ComoPunchCardsBlockComponent,
    DebugNetworkConnectionBlockComponent,
    GroupedOrderModalLauncherBlockComponent,
    CartFunnelPageComponent,
    CartFunnelFormBlockComponent,
    CartFunnelHeaderBlockComponent,
    CartFunnelRadioItemBlockComponent,
    CartFunnelTextItemBlockComponent,
    CartFunnelRadioOptionBlockComponent,
    CartFunnelSelectBlockComponent,
    InputMultiAtomComponent,
    SelectLanguageBlockComponent,
    KioskSelectLanguageBlockComponent,
    HeaderDigitalMenuBlockComponent,
    NoConnectionModalComponent,
    KioskPickerModalLauncherBlockComponent,
    KioskPickerModalComponent,
    KioskProductOverviewModalComponent,
    KioskSelectSimpleProductModalComponent,
    CartFunnelOnBehalfUserFullNameItemBlockComponent,
    CartFunnelOnBehalfUserEmailItemBlockComponent,
    CartFunnelOnBehalfUserPhoneItemBlockComponent,
    PaymentPanelStripeBancontactComponent,
    BancontactPaymentFormModalComponent,
    PaymentConfirmPageComponent,
    CardCarouselComponent,
    PaymentPanelNoPaymentComponent,
    CartFunnelOnBehalfUserOptinItemBlockComponent,
    CartFunnelCheckboxItemBlockComponent,
    ConfirmModalComponent,
    SplashScreenBlockComponent,
    AlertModalComponent,
    KioskAlertModalComponent,
    ResetCartFunnelBlockComponent,
    CookieConsentLinkItemBlockComponent,
    CartFooterButtonAtomComponent,
    PongoCreateOrLinkAccountModalComponent,
    PadSplashScreenBlockComponent,
    AnonymousAuthenticationPreloaderBlockComponent,
    PaymentPanelLyfpayComponent,
    PaymentPanelYavinComponent,
    PaymentPanelVivaWalletComponent,
    ImageVariableWidthBlockComponent,
    PongoOfferedProductPickerModalComponent,
    EmailReceiptAtomComponent,
    ClassicButtonBlockComponent,
    ImageLogoBlockComponent,
    PaymentPanelAdyenMealVoucherNatixisComponent,
    PaymentPanelAdyenMealVoucherSodexoComponent,
    PaymentPanelAdyenMealVoucherGroupeUpComponent,
    ChecksPageComponent,
    ChecksPageBlockComponent,
    CheckCardComponent,
    ResumeCheckAtomComponent,
    CheckDetailsPageComponent,
    CheckOverviewAtomComponent,
    CheckDetailsBlockComponent,
    PongoMarketplaceRewardsListBlockComponent,
    CartPickupLocationBlockComponent,
    ComoRegistrationModalComponent,
    PhoneNumberStepComponent,
    OtpStepComponent,
    AccountNotFoundStepComponent,
    KioskConfirmationStepComponent,
    KioskRegisterConfirmationStepComponent,
    KioskRegisterStepComponent,
    KioskAccountNotFoundStep,
    ComoProductsSelectionModalComponent,
    ComoProductsSelectionStepComponent,
    AuthenticationStepComponent,
    ComoCreateOrLinkAccountModalIdentificationStepComponent,
    ComoCreateOrLinkAccountModalOtpStepComponent,
    ComoCreateOrLinkAccountModalRegistrationStepComponent,
    ComoCreateOrLinkAccountModalAttachWithEmailStepComponent,
    PatOverviewBlockComponent,
    PatChooseAmountPageComponent,
    PatPaymentPageComponent,
    PatPaymentConfirmationPageComponent,
    PatPageComponent,
    PatChecksErrorComponent,
    PatBaseModalComponent,
    PatPaymentMethodPickerBlockComponent,
    UserFeedbackBlockComponent,
    ShareUserFeedbackModalComponent,
    ExternalLinkButtonBlockComponent,
    SplashScreenImageModalComponent,
    SplashScreenImageModalBlockComponent,
    UserFeedbackLinkBlockComponent,
    OrderReceiveReceiptBlockComponent,
    PaymentPanelYavinTerminalComponent,
    CheckboxAtomComponent,
    PatAvailabilityModalComponent,
    ParametersDeliveryInstructionsBlockComponent,
    UserFeedbackLinkBlockComponent,
    HeroCarouselBlockComponent,
    HeroCardCarouselBlockComponent,
    ShopHeroTemplateBlockComponent,
    KioskSplashScreenPageComponent,
    KioskDistributionModePickerPageComponent,
    KioskLoyaltyConnectionPageComponent,
    KioskCartPageComponent,
    KioskShopPageComponent,
    KioskLightProductDetailsPageComponent,
    KioskProductAddPageComponent,
    KioskPromoSelectProductPageComponent,
    KioskCategoryPageComponent,
    KioskActiveButtonAtomComponent,
    KioskHeaderAtomComponent,
    KioskFooterDoubleButtonAtomComponent,
    KioskComoStepAtomComponent,
    KioskHeaderBlockComponent,
    KioskFooterCartBlockComponent,
    KioskCartPromoCodeBlockComponent,
    KioskDoubleButtonFooterBlockComponent,
    KioskLoyaltyConnectionBlockComponent,
    KioskCartProductsListBlockComponent,
    KioskCartLoyaltyBlockComponent,
    KioskProductsSameCategoryBlockComponent,
    KioskPromoSelectProductBlockComponent,
    KioskProductDetailsBlockComponent,
    KioskCartFooterBlockComponent,
    KioskComoItemAtomComponent,
    KioskShopCategoryListBlockComponent,
    KioskSuggestionsModalComponent,
    KioskSuggestionsModalBlockComponent,
    KioskVerticalCategoryListAtomComponent,
    KioskStepperNavigationAtomComponent,
    KioskProductCardAtomComponent,
    KioskVirtualKeyboardComponent,
    KioskCartFunnelPageComponent,
    KioskCartFunnelFormBlockComponent,
    KioskCartFunnelHeaderBlockComponent,
    KioskCartFunnelInputTextBlockComponent,
    KioskCartFunnelRadioItemBlockComponent,
    KioskCartFunnelRadioOptionBlockComponent,
    KioskCartFunnelPagerBlockComponent,
    KioskInputMultiAtomComponent,
    KioskPagerAtomComponent,
    KioskInputRadiotAtomComponent,
    KioskPaymentMethodPickerBlockComponent,
    KioskCartFunnelOnBehalfFieldBlockComponent,
    StickyBlockComponent,
    SimpleHeaderBlockComponent,
    SimpleHeaderImageItemBlockComponent,
    SimpleHeaderTextItemBlockComponent,
    PaymentLoadingScreenAtomComponent,
  ],
})
export class SharedModule {}
