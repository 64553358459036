import { distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { ShopKeys } from '@config/keys/shop.keys';

import { OrderTypeValues } from '@config/values/order.values';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';

import { CartStoreDispatcher } from '@common/dispatchers/cart.dispatcher';

import { CartStoreSelector } from '@common/selectors/cart.selector';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

import { DoodCartModel } from '@core/models/cart.model';
import { DoodShopModel } from '@core/models/shop.model';
import { ISimpleItem } from '@core/models/simple-item.model';
import {
  IContentBuilderFieldColor,
  IContentBuilderFieldImage,
} from '@core/models/content-builder-fields.model';

import { CartService } from '@core/services/cart/cart.service';
import { ShopsService } from '@core/services/shops/shops.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { EventTrackerService } from '@core/services/event-tracker/event-tracker.service';
import { OnSiteLocationsService } from '@core/services/on-site-locations/on-site-locations.service';
import { NavigationHistoryService } from '@core/services/navigation-history/navigation-history.service';

import { AlertModalComponent } from '@shared/modals/alert-modal/alert-modal.component';
import { CartParametersModalComponent } from '@shared/modals/cart-parameters-modal/cart-parameters-modal.component';
import { ShopStoreDispatcher } from '@common/dispatchers/shop.dispatcher';
import { AnalyticsService } from '@core/services/app/analytics.service';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-kiosk-shop-page',
  templateUrl: './kiosk-shop-page.component.html',
})
export class KioskShopPageComponent extends DestroyerBase implements OnInit, OnDestroy {
  @Input() body!: unknown[];
  @Input() metaDescription?: string;
  @Input() backgroundColor: IContentBuilderFieldColor = {
    value: 'neutral-50',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() backgroundImage?: IContentBuilderFieldImage;
  @Input() textColor?: IContentBuilderFieldColor;
  @Input() header?: unknown[];
  @Input() sidebar?: unknown[];
  @Input() footer?: unknown[];

  cart?: DoodCartModel;

  hideLeftBody: boolean = false;

  private readonly destroyed$ = new Subject<boolean>();

  constructor(
    private cartSelector: CartStoreSelector,
    private readonly cartService: CartService,
    private cartDispatcher: CartStoreDispatcher,
    private shopDispatcher: ShopStoreDispatcher,
    private readonly shopsService: ShopsService,
    private readonly modalsService: ModalsService,
    private readonly ordersService: OrdersService,
    private settingsSelector: SettingsStoreSelector,
    private readonly activatedRoute: ActivatedRoute,
    private readonly translateService: TranslateService,
    private marketplaceSelector: MarketplaceStoreSelector,
    private readonly eventTrackerService: EventTrackerService,
    private readonly onSiteLocationsService: OnSiteLocationsService,
    private readonly navigationHistoryService: NavigationHistoryService,
    private readonly analyticsService: AnalyticsService,
    private readonly router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.navigationHistoryService.pushStateFromActivatedRouteSnapshot(
      'shop',
      this.activatedRoute.snapshot,
    );

    this.activatedRoute.params
      .pipe(
        takeUntil(this.destroyed$),
        // Get the shopSlug from URL to handle overridden routes in the content builder
        map(
          () => this.activatedRoute.snapshot.url[this.activatedRoute.snapshot.url.length - 1].path,
        ),
        distinctUntilChanged(),
      )
      .subscribe(shopSlug => {
        this.loadShop(shopSlug);
      });

    this.checkRoute(this.router.url);

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.checkRoute(this.router.url);
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private checkRoute(currentRoute: string): void {
    this.hideLeftBody = currentRoute.includes('product-add');
  }

  loadShop(shopSlug: string): void {
    this.shopDispatcher.updateIsLoading(true);
    this.shopsService
      .loadShopBySlug$(shopSlug)
      .pipe(
        takeUntil(this.destroyed$),
        take(1),
        tap(shop => this.shopLoaded(shop)),
      )
      .subscribe();
  }

  private shopLoaded(shop: DoodShopModel): void {
    this.analyticsService.trackViewShop(shop);
    this.eventTrackerService.dispatch(EventTrackerService.EventShopViewed, shop);
    this.shopDispatcher.insertActive(shop);
    this.cartService.updateShopIdForActiveCart(shop.id);
    this.shopDispatcher.updateIsLoading(false);

    this.cartService
      .getCartEntityDistributionModes$(this.marketplaceSelector.marketplace, shop[ShopKeys.Id])
      .pipe(take(1))
      .subscribe(allowedDistributionMode => {
        this.createOrSetActiveCart(shop, allowedDistributionMode);
      });

    this.cartSelector.selectActive
      .pipe(
        takeUntil(this.destroyed$),
        filter(cart => cart?.type === OrderTypeValues.OnSite),
        take(1),
        switchMap(() => this.onSiteLocationsService.loadOnSiteLocations$(shop[ShopKeys.Id])),
      )
      .subscribe();

    this.getShopSlots();
  }

  private createOrSetActiveCart(shop: DoodShopModel, allowedDistributionMode: ISimpleItem[]): void {
    const marketplace = this.marketplaceSelector.marketplace;
    const cartEntityHandle = this.cartService.calculateCartHandle(marketplace, shop);
    const parameters = this.settingsSelector.parameters;
    const allowedDistributionModeHandles = allowedDistributionMode.map(item => item.value);

    if (!allowedDistributionModeHandles.length) {
      this.modalsService.open(AlertModalComponent.handle);
      this.modalsService.setData(AlertModalComponent.handle, {
        message: this.translateService.instant('shop-page.shop-not-available'),
      });
    }

    if (!cartEntityHandle) {
      console.error("Unable to create cart, cart handle can't be calculated");
      return;
    }

    // TODO!: Check if this is correct
    this.cartSelector
      .selectCart(cartEntityHandle)
      .pipe(take(1))
      .subscribe(cart => {
        this.cart = cart;

        if (!this.cart) {
          let orderType = parameters.distribution_mode;

          if (!allowedDistributionModeHandles.includes(orderType)) {
            orderType = allowedDistributionModeHandles[0];
          }

          this.cartService.createCartForActiveShop(orderType);
          this.cart = this.cartSelector.carts.find(cart => cart.handle === cartEntityHandle);
        }

        this.cartDispatcher.setActive(cartEntityHandle);

        const lastNavigationPage =
          this.navigationHistoryService.getHistory()[
            this.navigationHistoryService.getHistory().length - 2
          ];
        if (
          parameters.is_distribution_mode_defined &&
          this.cart?.type !== parameters.distribution_mode &&
          (lastNavigationPage?.identifier === 'shop-search' ||
            lastNavigationPage?.identifier === '/shops') &&
          allowedDistributionModeHandles.includes(parameters.distribution_mode)
        ) {
          this.modalsService.open(CartParametersModalComponent.handle);
          this.modalsService.setData(CartParametersModalComponent.handle, {
            text: this.translateService.instant('parameters.warning-existing-cart'),
          });
        }
      });
  }
  private getShopSlots(): void {
    this.ordersService.loadShopSlots$().pipe(take(1)).subscribe();
  }

  getBackgroundStyles() {
    return {
      'background-color': this.backgroundColor ? this.backgroundColor : null,
      'background-image': this.backgroundImage ? `url(${this.backgroundImage.url})` : null,
      'background-size': this.backgroundImage ? 'cover' : null,
    };
  }

  forceType(data: any): any {
    return data as any;
  }
}
