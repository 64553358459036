import { DefaultProjectorFn, MemoizedSelector, createFeature, createSelector } from '@ngrx/store';

import { DoodOnSiteLocationModel } from '@core/models/on-site-location.model';

import {
  OSL_ENTITY_STORE_ADAPTER,
  OSL_STORE_KEY,
  OnSiteLocationState,
} from './on-site-location.state';
import { OSL_STORE_REDUCER } from './on-site-location.reducer';

const OSL_STORE_FEATURE = createFeature({
  name: OSL_STORE_KEY,
  reducer: OSL_STORE_REDUCER,
});

export const {
  selectError: SELECT_OSL_ERROR,
  selectActive: SELECT_OSL_ACTIVE_ID,
  selectSettings: SELECT_OSL_SETTINGS,
  selectIsLoaded: SELECT_OSL_IS_LOADED,
  selectIsLoading: SELECT_OSL_IS_LOADING,
  selectOnSiteLocationState: SELECT_OSL_STATE,
} = OSL_STORE_FEATURE;

export const { selectAll: SELECT_OSL_ALL, selectEntities: SELECT_OSL_ENTITIES } =
  OSL_ENTITY_STORE_ADAPTER.getSelectors(SELECT_OSL_STATE);

export const SELECT_OSL_ACTIVE = createSelector(
  SELECT_OSL_ENTITIES,
  SELECT_OSL_ACTIVE_ID,
  (entities, id) => (id ? (entities[id] ?? null) : null),
);

export const SELECT_OSL_ENTITY = (
  id: string,
): MemoizedSelector<
  Record<string, any>,
  DoodOnSiteLocationModel | undefined,
  DefaultProjectorFn<DoodOnSiteLocationModel | undefined>
> =>
  createSelector(SELECT_OSL_STATE, (state: OnSiteLocationState) => {
    return state.entities[id];
  });
