import { Component, Input } from '@angular/core';

import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

import { ShopSearchParametersService } from '@core/services/shop-search-parameters/shop-search-parameters.service';

@Component({
  selector: 'app-mosaic-card-block',
  templateUrl: './mosaic-card-block.component.html',
  styleUrls: ['./mosaic-card-block.component.scss'],
})
export class MosaicCardBlockComponent {
  @Input() width?: string;
  @Input() backgroundImage?: any;
  @Input() linkUrl?: string;
  @Input() linkTarget = '_self';
  @Input() distributionMode?: string;
  @Input() heading?: string;
  @Input() aspectRatio?: string;
  @Input() shadow?: string;
  @Input() rounded?: string;
  @Input() font?: string;
  @Input() fontSize?: string;
  @Input() backgroundStyleContain?: boolean;
  @Input() textColor?: IContentBuilderFieldColor = {
    value: '#00000',
    type: ColorFieldTypesValues.Hex,
  };
  @Input() backgroundColor: IContentBuilderFieldColor = {
    value: '#FFFFFF',
    type: ColorFieldTypesValues.Hex,
  };

  constructor(private readonly shopSearchParametersService: ShopSearchParametersService) {}

  onClick(): void {
    if (this.distributionMode) {
      this.shopSearchParametersService.setDistributionModeType(this.distributionMode, true);
    }
  }
}
