import { IPaymentMethod, PaymentMethodHandles } from '@config/payment-methods.config';
import { PatPaymentPanelStripeCbComponent } from '@shared/blocks/pat-payment-method-picker-block/pat-panel/pat-payment-panel-stripe-cb/pat-payment-panel-stripe-cb.component';
import { PatPaymentPanelLyfpayComponent } from '@shared/blocks/pat-payment-method-picker-block/pat-panel/pat-payment-panel-lyfpay/pat-payment-panel-lyfpay.component';
import { PatPaymentPanelPaygreenLunchrComponent } from '@shared/blocks/pat-payment-method-picker-block/pat-panel/pat-payment-panel-paygreen-lunchr/pat-payment-panel-paygreen-lunchr.component';
import { PatPaymentPanelPaygreenRestoflashComponent } from '@shared/blocks/pat-payment-method-picker-block/pat-panel/pat-payment-panel-paygreen-restoflash/pat-payment-panel-paygreen-restoflash.component';
import { PatPaymentPanelYavinComponent } from '@shared/blocks/pat-payment-method-picker-block/pat-panel/pat-payment-panel-yavin/pat-payment-panel-yavin.component';
import { PatPaymentPanelPaygreenGenericComponent } from '@shared/blocks/pat-payment-method-picker-block/pat-panel/pat-payment-panel-paygreen-generic/pat-payment-panel-paygreen-generic.component';
import { PatPaymentPanelEdenredComponent } from '@shared/blocks/pat-payment-method-picker-block/pat-panel/pat-payment-panel-edenred/pat-payment-panel-edenred.component';
import { PatPaymentPanelStripeApplepayComponent } from '@shared/blocks/pat-payment-method-picker-block/pat-panel/pat-payment-panel-stripe-applepay/pat-payment-panel-stripe-applepay.component';
import { PatPaymentPanelStripeGooglepayComponent } from '@shared/blocks/pat-payment-method-picker-block/pat-panel/pat-payment-panel-stripe-googlepay/pat-payment-panel-stripe-googlepay.component';
import { PatPaymentPanelAdyenCbComponent } from '@shared/blocks/pat-payment-method-picker-block/pat-panel/pat-payment-panel-adyen-cb/pat-payment-panel-adyen-cb.component';
import { PatPaymentPanelAdyenGooglepayComponent } from '@shared/blocks/pat-payment-method-picker-block/pat-panel/pat-payment-panel-adyen-googlepay/pat-payment-panel-adyen-googlepay.component';
import { PatPaymentPanelAdyenApplepayComponent } from '@shared/blocks/pat-payment-method-picker-block/pat-panel/pat-payment-panel-adyen-applepay/pat-payment-panel-adyen-applepay.component';
import { PatPaymentPanelStripeTwintComponent } from '@shared/blocks/pat-payment-method-picker-block/pat-panel/pat-payment-panel-stripe-twint/pat-payment-panel-stripe-twint.component';

export const PAT_PAYMENT_METHODS: Array<IPaymentMethod> = [
  {
    handle: PaymentMethodHandles.Stripe,
    label: 'payment.credit-card',
    panelComponent: PatPaymentPanelStripeCbComponent,
    logos: [
      './assets/images/payment-logos/logo-visa.png',
      './assets/images/payment-logos/logo-mastercard.png',
      './assets/images/payment-logos/logo-amex.png',
    ],
  },
  {
    handle: PaymentMethodHandles.StripeGooglePay,
    label: 'Google Pay',
    panelComponent: PatPaymentPanelStripeGooglepayComponent,
    logos: ['./assets/images/payment-logos/logo-googlepay.png'],
  },
  {
    handle: PaymentMethodHandles.StripeTwint,
    label: 'Twint',
    panelComponent: PatPaymentPanelStripeTwintComponent,
    logos: ['./assets/images/payment-logos/logo-twint.svg'],
  },
  {
    handle: PaymentMethodHandles.StripeApplePay,
    label: 'Apple Pay',
    panelComponent: PatPaymentPanelStripeApplepayComponent,
    logos: ['./assets/images/payment-logos/logo-applepay.png'],
  },
  {
    handle: PaymentMethodHandles.Edenred,
    label: 'payment.edenred',
    panelComponent: PatPaymentPanelEdenredComponent,
    logos: ['./assets/images/payment-logos/logo-edenred.png'],
  },
  {
    handle: PaymentMethodHandles.PaygreenTrd,
    label: 'Titres Restaurant',
    panelComponent: PatPaymentPanelPaygreenGenericComponent,
    logos: [
      './assets/images/payment-logos/logo-apetiz.png',
      './assets/images/payment-logos/logo-pluxee.png',
      './assets/images/payment-logos/logo-pass-restaurant.png',
      './assets/images/payment-logos/logo-up.png',
    ],
  },
  {
    handle: PaymentMethodHandles.PaygreenLunchr,
    label: 'Swile',
    panelComponent: PatPaymentPanelPaygreenLunchrComponent,
    logos: ['./assets/images/payment-logos/logo-swile.png'],
  },
  {
    handle: PaymentMethodHandles.PaygreenRestoflash,
    label: 'Resto Flash®',
    panelComponent: PatPaymentPanelPaygreenRestoflashComponent,
    logos: ['./assets/images/payment-logos/logo-restoflash.png'],
  },
  {
    handle: PaymentMethodHandles.Yavin,
    label: 'payment.yavin',
    panelComponent: PatPaymentPanelYavinComponent,
    logos: [
      './assets/images/payment-logos/logo-visa.png',
      './assets/images/payment-logos/logo-mastercard.png',
    ],
  },
  {
    handle: PaymentMethodHandles.LyfPay,
    label: 'payment.lyfpay',
    panelComponent: PatPaymentPanelLyfpayComponent,
    logos: ['./assets/images/payment-logos/logo-lyfpay.png'],
  },
  {
    handle: PaymentMethodHandles.AdyenCB,
    label: 'Carte bancaire',
    panelComponent: PatPaymentPanelAdyenCbComponent,
    logos: [
      './assets/images/payment-logos/logo-visa.png',
      './assets/images/payment-logos/logo-mastercard.png',
      './assets/images/payment-logos/logo-swile.png',
    ],
  },
  {
    handle: PaymentMethodHandles.AdyenGooglePay,
    label: 'Google Pay',
    panelComponent: PatPaymentPanelAdyenGooglepayComponent,
    logos: ['./assets/images/payment-logos/logo-googlepay.png'],
  },
  {
    handle: PaymentMethodHandles.AdyenApplePay,
    label: 'Apple Pay',
    panelComponent: PatPaymentPanelAdyenApplepayComponent,
    logos: ['./assets/images/payment-logos/logo-applepay.png'],
  },
];
