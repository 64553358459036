import { of, timer } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { environment } from '@env/environment';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';

import { OrdersService } from '@core/services/orders/orders.service';
import { PaymentService } from '@core/services/payment/payment.service';
import { PaymentStoreDispatcher } from '@common/dispatchers/payment.dispatcher';

@Component({
  selector: 'app-payment-panel-cmi-generic',
  templateUrl: './payment-panel-cmi-generic.component.html',
})
export class PaymentPanelCmiGenericComponent extends DestroyerBase implements OnInit {
  @ViewChild('cmiPaymentForm') cmiPaymentForm!: ElementRef;

  @Input() payButtonText!: string;
  @Input() isPayButtonVisible: boolean = true;

  paymentElements: Record<string, unknown> | null = null;
  actionUrl: string = environment.api.cmi.url;

  constructor(
    private readonly ordersService: OrdersService,
    private readonly paymentService: PaymentService,
    private paymentDispatcher: PaymentStoreDispatcher,
  ) {
    super();
  }

  ngOnInit(): void {
    timer(1).subscribe(() => {
      this.paymentDispatcher.updateUI({
        error: null,
        button: {
          isEnabled: false,
          text: this.payButtonText,
          isVisible: Boolean(this.isPayButtonVisible),
        },
        status: {
          isLoading: true,
        },
      });
    });

    this.ordersService
      .createOrder$('CMI')
      .pipe(
        switchMap(order =>
          order
            ? this.paymentService.createOrderPaymentIntent$<Record<string, unknown>>(order.id)
            : of(null),
        ),
        tap(paymentData => {
          this.paymentElements = paymentData;
          this.paymentDispatcher.updateUI({
            button: {
              isEnabled: true,
            },
            status: {
              isLoading: false,
            },
          });
          this.initPayButtonListener();
        }),
      )
      .subscribe({
        error: error => {
          this.handlePaymentError(error);
        },
      });
  }

  private initPayButtonListener(): void {
    this.paymentService.pay$
      .pipe(
        takeUntil(this._destroyerRef),
        take(1),
        tap(() => this.handlePayment()),
      )
      .subscribe();
  }

  private handlePayment(): void {
    this.paymentDispatcher.updateStatus({ isInProgress: true });
    this.cmiPaymentForm.nativeElement.submit();
  }

  private handlePaymentError(_: unknown): void {
    this.paymentDispatcher.updateUI({
      error: 'payment.unknown-error',
      button: {
        isEnabled: false,
        isVisible: Boolean(this.isPayButtonVisible),
      },
      status: {
        isLoading: false,
        isInProgress: false,
      },
    });
    this.initPayButtonListener();
  }
}
