import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ISimpleItem } from '@core/models/simple-item.model';
import { BASE_CUSTOM_SWITCH_VALUES } from '@config/values/parameters.values';

@Component({
  selector: 'app-parameters-distribution-mode-block',
  templateUrl: './parameters-distribution-mode-block.component.html',
})
export class ParametersDistributionModeBlockComponent {
  @Input() items: ISimpleItem[] = BASE_CUSTOM_SWITCH_VALUES;
  @Input() isCartParameters = false;
  @Input() selectedDistributionMode!: string;
  @Output() selectItem = new EventEmitter();

  getSwitchValue(item: ISimpleItem): void {
    this.selectItem.emit(item.value);
  }
}
