<div class="p-3 pb-7 flex flex-col adyen-checkout">
  <ng-content
    select="[loadingState]"
    *ngIf="isLoading"
  ></ng-content>
  <div
    #adyenContainer
    class="flex flex-col justify-center"
  ></div>
</div>
