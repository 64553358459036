import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DoodProductModel } from '@core/models/product.model';
import { ProductKeys } from '@config/keys/product.keys';
import { ISubItem } from '@core/models/simple-item.model';

@Component({
  selector: 'app-overview-cart-atom',
  templateUrl: './overview-cart-atom.component.html',
})
export class OverviewCartAtomComponent {
  @Input() shopName!: string;
  @Input() products!: DoodProductModel[];
  @Input() additionalItems!: ISubItem[];
  @Input() totalAmount!: number;

  comment!: string;

  @Output() submitCart = new EventEmitter<string>();
  @Output() closeCart = new EventEmitter();

  get ProductKeys(): typeof ProductKeys {
    return ProductKeys;
  }

  forceType(data: any): any {
    return data as any;
  }
}
