import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Component } from '@angular/core';

import { ModalStoreSelector } from '@common/selectors/modal.selector';

@Component({
  selector: 'app-waiting-modal',
  templateUrl: './waiting-modal.component.html',
})
export class WaitingModalComponent {
  static handle = 'waiting-modal';

  index$: Observable<number> = this.modalSelector
    .selectModal(WaitingModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  constructor(private modalSelector: ModalStoreSelector) {}
}
