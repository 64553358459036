import { ICartDeclinableSeparateAdditions } from '@core/models/cart.model';
import { DoodProductModel } from '@core/models/product.model';
import { IAdditionGroup } from '@core/models/shop.model';

export type DraftAdditionsGroup = Omit<IAdditionGroup, 'items'> & {
  items: DraftAdditionProductModel[];
};

export type DraftAdditionProductModel = Readonly<
  DoodProductModel & {
    remainingAuthorizedQuantity: number;
    uiPrice: number | undefined;
  }
>;

export type ValidityWithAdditionsByGroupsState = {
  isValid: boolean;
  additionsByGroupsValidity: Record<
    string,
    {
      isValid: boolean;
      isInvalid: boolean;
    }
  >;
};

export class CartSimpleProductUtil {
  /**
   * it does not check the addition quantity validity on (addition that are not included in a group)
   * it does not check the self addition quantity min/max
   * But that can be added
   */
  static checkAdditionGroupValidity({
    simpleProductAdditionsGroups,
    selectedAdditionIds,
  }: {
    simpleProductAdditionsGroups: IAdditionGroup[];
    selectedAdditionIds: string[];
  }) {
    const additionsByGroupsValidity = this.evaluateAdditionGroupsValidity({
      simpleProductAdditionsGroups,
      selectedAdditionIds,
    });

    const isValid = Object.values(additionsByGroupsValidity).every(
      isGroupValid => isGroupValid.isValid,
    );
    return { isValid, additionsByGroupsValidity };
  }

  static evaluateAdditionGroupsValidity({
    simpleProductAdditionsGroups,
    selectedAdditionIds,
  }: {
    simpleProductAdditionsGroups: IAdditionGroup[];
    selectedAdditionIds: string[];
  }) {
    return simpleProductAdditionsGroups.reduce(
      (acc, additionGroup) => {
        const totalGroupAdditionsQuantity = selectedAdditionIds.filter(selectedAdditionId =>
          additionGroup.items.some(additionItem => additionItem.id === selectedAdditionId),
        );
        const isMinGroupQuantityValid =
          totalGroupAdditionsQuantity.length >= (additionGroup.min_count ?? 0);
        const isMaxGroupQuantityValid =
          totalGroupAdditionsQuantity.length <= (additionGroup.max_count ?? Infinity);
        const isGroupValid = isMinGroupQuantityValid && isMaxGroupQuantityValid;
        acc[additionGroup.id] = { isValid: isGroupValid, isInvalid: !isGroupValid };
        return acc;
      },
      {} as Record<string, { isValid: boolean; isInvalid: boolean }>,
    );
  }

  static calculateGroupAdditions({
    additionGroup,
    selectedAdditionIds,
  }: {
    additionGroup: IAdditionGroup;
    selectedAdditionIds: string[];
  }) {
    const totalGroupAdditionsQuantity = selectedAdditionIds.filter(addition =>
      additionGroup.items.find(additionItem => additionItem.id === addition),
    ).length;
    const remainingAuthorizedGroupQuantity =
      (additionGroup.max_count ?? 0) - totalGroupAdditionsQuantity;

    return {
      ...additionGroup,
      items: additionGroup.items.map(additionItem => {
        const additionQuantity = selectedAdditionIds.filter(
          addition => addition === additionItem?.id,
        ).length;

        const additionMax = additionItem.max_count || Infinity;

        const remainingAuthorizedQuantity = Math.min(remainingAuthorizedGroupQuantity, additionMax);
        const maxProductQuantity = Math.min(
          remainingAuthorizedQuantity + additionQuantity,
          additionMax,
        );

        const product: DraftAdditionProductModel = {
          ...additionItem,
          quantity: additionQuantity,
          remainingAuthorizedQuantity: maxProductQuantity,
          uiPrice: additionItem.discount ?? additionItem.price,
        } as const; // it make the object readonly

        return product;
      }),
    };
  }

  static getSelectedAdditionsByGroup({
    additionGroups,
    selectedAdditionIds,
  }: {
    additionGroups: IAdditionGroup[];
    selectedAdditionIds: string[];
  }): ICartDeclinableSeparateAdditions[] {
    return selectedAdditionIds.reduce((acc, selectedAdditionId) => {
      const group = additionGroups.find(group =>
        group.items.some(item => item.id === selectedAdditionId),
      );
      if (!group) {
        return acc;
      }
      const groupIndex = acc.findIndex(accGroup => accGroup.id === group.id);
      if (groupIndex === -1) {
        return [
          ...acc,
          {
            id: group.id,
            selected: [selectedAdditionId],
          },
        ];
      }
      acc[groupIndex].selected.push(selectedAdditionId);
      return acc;
    }, [] as ICartDeclinableSeparateAdditions[]);
  }

  static removeExcessAdditionByGroup({
    additionGroups,
    selectedAdditionIds,
  }: {
    additionGroups: IAdditionGroup[];
    selectedAdditionIds: string[];
  }) {
    const selectedAdditionIdsWithoutExcessQuantity = [...selectedAdditionIds];
    additionGroups.forEach(group => {
      const additionsFromTheGroup = selectedAdditionIdsWithoutExcessQuantity.filter(addition =>
        group.items.find(additionItem => additionItem.id === addition),
      );
      const totalGroupAdditionsQuantity = additionsFromTheGroup.length;
      const excessAdditionQuantity = totalGroupAdditionsQuantity - (group.max_count ?? 0);
      if (excessAdditionQuantity >= 1) {
        additionsFromTheGroup.length = excessAdditionQuantity;
        const oldestAdditionsToRemove = additionsFromTheGroup;
        oldestAdditionsToRemove.forEach(additionId => {
          const firstAdditionIndex = selectedAdditionIdsWithoutExcessQuantity.findIndex(
            id => id === additionId,
          );
          selectedAdditionIdsWithoutExcessQuantity.splice(firstAdditionIndex, 1);
        });
      }
    });
    return selectedAdditionIdsWithoutExcessQuantity;
  }

  static updateAdditionSelections({
    selectedAdditionIdsValue,
    quantity,
    additionId,
    additionGroups,
  }: {
    selectedAdditionIdsValue: string[];
    quantity: number;
    additionId: string;
    additionGroups: IAdditionGroup[];
  }) {
    // ! puts all the same additions ids at the end of the array, it will be use as an history, it can be improve !
    const updatedSelectedAdditionIdsValue = [
      ...selectedAdditionIdsValue,
      ...Array(quantity).fill(additionId),
    ];

    const selectedAdditionIdsWithoutExcessQuantity =
      CartSimpleProductUtil.removeExcessAdditionByGroup({
        additionGroups,
        selectedAdditionIds: updatedSelectedAdditionIdsValue,
      });

    const separate_additions_by_group = CartSimpleProductUtil.getSelectedAdditionsByGroup({
      additionGroups,
      selectedAdditionIds: selectedAdditionIdsWithoutExcessQuantity,
    });
    return { selectedAdditionIdsWithoutExcessQuantity, separate_additions_by_group };
  }
}
