import { Injectable } from '@angular/core';
import { DoodShopModel } from '../../models/shop.model';
import { DateUtils } from '@shared/utils/date/date.utils';
import { ShopKeys } from '@config/keys/shop.keys';

@Injectable({
  providedIn: 'root',
})
export class ShopAvailableAtService {
  constructor() {}

  getAvailableAtDisplayString(shop: DoodShopModel): string | null {
    const availableAt = shop[ShopKeys.AvailableAt];

    if (!availableAt) {
      return null;
    }

    const dateTimeAvailableAt = DateUtils.dayjsInMarketplaceTimezone(availableAt);
    const dateTimeNow = DateUtils.dayjsInMarketplaceTimezone();

    let seconds = dateTimeAvailableAt.diff(dateTimeNow, 'second');

    if (seconds < 0) {
      return null;
    }

    const days = Math.floor(seconds / (24 * 60 * 60));
    seconds = seconds - days * 24 * 60 * 60;

    const hours = Math.floor(seconds / (60 * 60));
    seconds = seconds - hours * 60 * 60;

    const minutes = Math.floor(seconds / 60);
    seconds = seconds - minutes * 60;

    let message = '';

    if (days > 0) {
      message += days + ' j ';
    }

    if (hours > 0) {
      message += hours + ' h ';
    }

    if (minutes > 0) {
      message += minutes + ' m ';
    }

    if (seconds > 0) {
      message += seconds + ' s';
    }

    return message;
  }
}
