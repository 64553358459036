import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Component } from '@angular/core';

import { SELECT_ORDER_ACTIVE } from '@store/order';

@Component({
  selector: 'app-detail-order-voucher-block',
  templateUrl: './detail-order-voucher-block.component.html',
})
export class DetailOrderVoucherBlockComponent {
  voucher$ = this.store.select(SELECT_ORDER_ACTIVE).pipe(map(order => order?.coupons));

  constructor(private readonly store: Store) {}

  protected forceAccessName(voucher: any) {
    return voucher?.name;
  }
}
