<app-modal-filler-atom
  (click)="close()"
  [index]="(index$ | async) ?? 0"
></app-modal-filler-atom>
<div
  class="fixed right-0 left-0 shadow overflow-y-auto mdMax:bottom-0 mdMax:max-h-85-screen mdMax:max-w-screen-sm mdMax:mx-auto md:flex md:justify-center md:items-center md:w-full md:h-full md:top-0 md:left-0"
  [style.z-index]="((index$ | async) ?? 0) + 50"
>
  <div
    class="bg-white p-5 md:p-7 rounded-t-3xl md:rounded-3xl md:max-w-lg md:w-2/5"
    [style.z-index]="((index$ | async) ?? 0) + 50"
  >
    <ng-container *ngIf="!isRewardAddInProgress">
      <ng-container *ngIf="currentStep">
        <app-como-products-selection-step
          [step]="currentStep"
          (goToNextStep)="goToNextStep()"
        ></app-como-products-selection-step>
      </ng-container>

      <div class="mt-4">
        <app-button-atom
          [label]="'Annuler'"
          type="light"
          [full]="true"
          (click)="close()"
        ></app-button-atom>
      </div>
    </ng-container>
    <ng-container *ngIf="isRewardAddInProgress">
      <div class="py-4 w-full text-center">
        <img
          src="assets/images/spinner.svg"
          alt=""
          class="pr-2"
        />
      </div>
    </ng-container>
  </div>
</div>
