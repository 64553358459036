import { Injectable } from '@angular/core';

import { DoodFilterModel } from '@core/models/filters.model';
import { FilterStoreDispatcher } from '@common/dispatchers/filter.dispatcher';

@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  constructor(private filterDispatcher: FilterStoreDispatcher) {}

  removeFilter(filter: DoodFilterModel, value?: string): void {
    this.filterDispatcher.updateFilter({
      id: filter.id,
      changes: {
        ...filter,
        values: filter.values?.filter(_value => _value.value !== value),
      },
    });
  }
}
