<div
  class="pt-5"
  *ngIf="isLoading || products.length > 0"
>
  <h1 class="px-5 font-accent font-extrabold text-lg text-neutral-800 pb-4">Suggestions</h1>
  <ng-container *ngIf="!isLoading">
    <ul class="flex overflow-x-auto pb-5 pl-5">
      <li
        class="mr-3 min-w-10 w-10 flex-shrink-0 flex-grow-0"
        *ngFor="let product of products"
      >
        <app-product-card-atom
          displayContext="suggestion"
          [product]="product"
          (productClick)="onProductClick(product)"
        ></app-product-card-atom>
      </li>
    </ul>
  </ng-container>
  <ng-container *ngIf="isLoading">
    <ul class="flex overflow-x-auto pb-5 pl-5">
      <ng-container *ngFor="let i of [].constructor(20)">
        <li
          class="px-3 py-1 h-36 w-36 rounded-lg flex-shrink-0 animate-pulse bg-neutral-50 mr-3"
        ></li>
      </ng-container>
    </ul>
  </ng-container>
</div>
