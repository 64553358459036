<div class="flex w-full">
  <div
    class="bg-neutral-100 rounded-l-3xl"
    *ngIf="!displayedValue && iconLeft"
  >
    <i
      class="icon {{ iconLeft }} m-2 mr-0 p-2 cursor-pointer text-neutral-600"
      (click)="iconClick.emit()"
    ></i>
  </div>
  <input
    class="w-full max-w-sm text-neutral-800 py-3.5 bg-neutral-100 leading-5 overflow-ellipsis outline-none
        {{ iconLeft ? 'rounded-r-3xl' : 'pl-4 rounded-l-3xl rounded-r-not' }}"
    [class.pr-4]="!!currency"
    [class.text-right]="!!currency"
    type="text"
    [placeholder]="label"
    [value]="displayedValue ? displayedValue : ''"
    #addressSearchInput
    appAutoFocus
    (input)="onInputChange(addressSearchInput.value)"
    (keydown)="isNumberOnly ? numberOnly($event) : null"
  />
  <div
    class="bg-neutral-100"
    [class.rounded-r-3xl]="!buttonLabel"
    *ngIf="cross && displayedValue"
    [class.neg-2]="isNative"
  >
    <i
      class="icon icon-cross text-neutral-500 m-2 p-2 text-xs cursor-pointer"
      (click)="clearValue()"
    ></i>
  </div>
  <div
    class="bg-neutral-100 rounded-r-3xl flex items-center ml-neg-1"
    *ngIf="!value && iconRight"
  >
    <i
      *ngIf="!onAction"
      class="icon {{ iconRight }} m-2 p-2 cursor-pointer text-neutral-500"
      (click)="iconClick.emit(); triggerAction()"
    ></i>
    <img
      *ngIf="onAction"
      src="assets/images/spinner.svg"
      alt=""
      class="p-2 mr-2"
    />
  </div>
  <div
    *ngIf="currency"
    class="bg-neutral-100 rounded-r-3xl flex items-center ml-neg-1"
  >
    <span class="text-neutral-500 m-2 p-2 cursor-pointer">{{ currency }}</span>
  </div>
  <span
    class="bg-primary-600 p-3.5 pl-5.5 font-bold font-base text-base text-neutral-50 rounded-r-3xl cursor-pointer"
    (click)="buttonClick.emit()"
    *ngIf="buttonLabel && displayedValue"
  >
    {{ buttonLabel }}
  </span>
</div>
