<div class="pt-8 p-5 lg:px-8 text-center">
  <h1
    class="font-accent font-neutral-800 px-2.5 font-extrabold uppercase text-center text-2.5xl leading-7"
  >
    {{ text }}
  </h1>
  <ul class="text-primary-600 flex mt-6 justify-center">
    <li
      *ngFor="let link of links"
      class="mx-4 text-2xl"
    >
      <a
        [href]="link.url"
        target="_blank"
      >
        <i class="icon icon-{{ link.icon }} text-3xl w-7.5 h-7.5"></i>
      </a>
    </li>
  </ul>
</div>
