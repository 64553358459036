import { Component } from '@angular/core';
import { PaymentMethodHandles } from '@config/payment-methods.config';

@Component({
  selector: 'app-payment-panel-stripe-applepay',
  templateUrl: './payment-panel-stripe-applepay.component.html',
})
export class PaymentPanelStripeApplepayComponent {
  paymentMethodHandles = PaymentMethodHandles;
}
