import { tap } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';

import { ComoService } from '@core/services/como/como.service';

@Component({
  selector: 'app-otp-step',
  templateUrl: './otp-step.component.html',
})
export class OtpStepComponent extends DestroyerBase {
  @Input() phone?: string;

  @Output() onClose = new EventEmitter<Event>();
  @Output() goToPreviousStep = new EventEmitter<void>();
  @Output() goToNextStep = new EventEmitter<void>();

  form: UntypedFormGroup;
  isValidating = false;
  errorMessage?: string;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly comoService: ComoService,
  ) {
    super();
    this.form = this.formBuilder.group({
      ['authCode']: ['', [Validators.required]],
    });
  }

  onSubmit(): void {
    const authCode = this.form.controls['authCode']?.value;

    if (this.form.invalid || this.isValidating || !authCode || !this.phone) {
      return;
    }
    this.isValidating = true;

    this.comoService
      .linkComoUser$({ phoneOrEmail: this.phone, code: authCode })
      .pipe(tap(response => this.onOtpValidated()))
      .subscribe({
        error: error => this.handleErrorResponse(error.error),
        complete: () => (this.isValidating = false),
      });
  }

  onGoToPreviousStep(): void {
    this.goToPreviousStep.next();
  }

  private onOtpValidated() {
    this.goToNextStep.next();
  }

  onKeypadNumberClick(number: number): void {
    const currentValue = this.form.controls.authCode.value;
    this.form.controls.authCode.setValue(currentValue + number.toString());
  }

  onKeypadDeleteClick(): void {
    const currentValue = this.form.controls.authCode.value;
    this.form.controls.authCode.setValue(currentValue.slice(0, -1)); // Supprime le dernier caractère
  }

  private handleErrorResponse(error: any): void {
    this.isValidating = false;
    this.errorMessage = this.comoService.getErrorMessageFromCode(error.code);
  }
}
