import { LocalStorageUtil } from '@shared/utils/local-storage/local-storage.util';

export type HydratedStoreMap = Record<string, unknown>;

export const HYDRATED_STORE_KEY = 'stores';

export const getHydratedStoreData = <D extends Record<string, unknown>>(
  key: string,
): D | undefined => {
  const _map: HydratedStoreMap = JSON.parse(LocalStorageUtil.get(HYDRATED_STORE_KEY) ?? '{}');
  return _map[key] as D;
};

export const setHydratedStoreData = <D>(key: string, data: D): void => {
  const _map: HydratedStoreMap = JSON.parse(LocalStorageUtil.get(HYDRATED_STORE_KEY) ?? '{}');
  _map[key] = data;
  const _data = Object.keys(_map).reduce((_result, _prop) => {
    const _value = _map[_prop];
    if (_value) {
      return { ..._result, [_prop]: _value };
    }
    return _result;
  }, {} as HydratedStoreMap);
  localStorage.setItem(HYDRATED_STORE_KEY, JSON.stringify(_data));
};
