import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { CartFunnelStoreSelector } from '@common/selectors/cart-funnel.selector';
import { CartFunnelStoreDispatcher } from '@common/dispatchers/cart-funnel.dispatcher';

@Component({
  selector: 'app-cart-funnel-radio-option-block',
  templateUrl: './cart-funnel-radio-option-block.component.html',
})
export class CartFunnelRadioOptionBlockComponent implements OnInit, OnDestroy {
  private _destroyerRef = new Subject<boolean>();

  @Input() label!: string;
  @Input() value!: string;
  @Input() parentId!: string;

  isActive = false;

  constructor(
    private cartFunnelSelector: CartFunnelStoreSelector,
    private cartFunnelDispatcher: CartFunnelStoreDispatcher,
  ) {}

  ngOnInit(): void {
    this.cartFunnelSelector
      .selectValue(this.parentId)
      .pipe(
        takeUntil(this._destroyerRef),
        map(input => input?.value === this.value),
      )
      .subscribe(_isActive => {
        this.isActive = _isActive;
      });
  }

  ngOnDestroy(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
  }

  onValueChange(_value: boolean | string): void {
    this.cartFunnelDispatcher.updateValue({
      id: this.parentId,
      changes: {
        value: _value,
      },
    });
  }
}
