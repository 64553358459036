import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  ViewChild,
} from '@angular/core';
import { sortBy } from 'lodash';
import { Router } from '@angular/router';
import { of, Subject, Subscription } from 'rxjs';
import { catchError, filter, map, take, takeUntil, tap } from 'rxjs/operators';

import { Paths } from '@config/paths.config';
import {
  clusterMarkerConfig,
  currentLocationMarkerConfig,
  labelOptions,
  neutralMarkerConfig,
  selectedMarkerMarkerConfig,
} from '@shared/blocks/shops-map-block/config/shops-map-block.config';

import { DoodCoordinationPoint, IMarker } from '@core/models/marker.model';
import { DoodVenuePoint } from '@core/models/venue.model';
import { DoodEntityModel } from '@core/models/entity.model';

import { EntityKeys } from '@config/keys/entity.keys';
import { VenueKeys, VenuePointKeys } from '@config/keys/venue.keys';

import { SlugUtils } from '@shared/utils/slug/slug.utils';
import { ParametersUtils } from '@app/utilities/parameters.util';
import { DistanceUtils } from '@shared/utils/distance/distance.utils';

import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { googleMapsSilverTheme } from '@styles/googleMapsSilverTheme';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { GoogleMapsApiHelper } from '@common/helpers/google-maps-api.helper';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

import { ModalsService } from '@core/services/modals/modals.service';
import { EntitiesService } from '@core/services/entities/entities.service';
import { GoogleApiService } from '@core/services/api/google/google-api.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { ShopSearchParametersService } from '@core/services/shop-search-parameters/shop-search-parameters.service';

import { FiltersModalComponent } from '@shared/modals/filters-modal/filters-modal.component';
import { InputTextAtomComponent } from '@shared/atoms/input-text-atom/input-text-atom.component';
import { ShopSearchParametersModalComponent } from '@shared/modals/shop-search-parameters-modal/shop-search-parameters-modal.component';
import { ClusterIconStyle } from '@angular/google-maps';

@Component({
  selector: 'app-shops-map-block',
  templateUrl: './shops-map-block.component.html',
  styleUrls: ['./shops-map-block.component.scss'],
})
export class ShopsMapBlockComponent
  extends DestroyerBase
  implements AfterViewInit, AfterViewChecked
{
  @ViewChild(InputTextAtomComponent) private input!: InputTextAtomComponent;
  @ViewChild('shopSideScrollingList', { read: ElementRef })
  private shopSideScrollingList!: ElementRef<HTMLDivElement>;

  @Input() textInputLabel = 'shops-map.input-label';
  @Input() filterButtonLabel = 'shop-list-header.filter';
  @Input() bulletListButtonLabel = 'shops-map.list-button';

  filterIcon = 'icon-filter';
  textInputIcon = 'icon-position';
  bulletListIcon = 'icon-bullet-list';

  styles = clusterMarkerConfig;
  neutralMarkerConfig = neutralMarkerConfig as string | google.maps.Icon | google.maps.Symbol; // satisfy the type, but it is not a best practice
  selectedMarkerMarkerConfig = selectedMarkerMarkerConfig;
  currentLocationMarkerConfig = currentLocationMarkerConfig;

  filterAddress$ = new Subject<string>();

  shops: DoodEntityModel[] = [];

  markers: IMarker[] = [];

  entityKeys = EntityKeys;

  geolocateError?: string;

  isShopSideScrollingListVisible = false;

  isMobile$ = this.settingsSelector.selectDeviceIsMobileScreen.pipe(
    takeUntil(this._destroyerRef),
    tap(isMobile => (isMobile ? null : (this.autocomplete = null))),
  );

  isGetShopLoading?: boolean;

  isMapApiLoaded = false;

  clusterMaxZoom = 25;
  clusterMinimumSize = 3;
  clusterStyles = this.styles as ClusterIconStyle[];
  clusterImagePath =
    'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m';

  currentMarkerIcon = this.currentLocationMarkerConfig as
    | string
    | google.maps.Icon
    | google.maps.Symbol;
  currentMarkerPosition: DoodCoordinationPoint | null = null;

  selectedMarkerPosition: IMarker | null = null;
  selectedMarkerIcon = this.selectedMarkerMarkerConfig as
    | string
    | google.maps.Icon
    | google.maps.Symbol;
  selectedMarkerOptions = {
    draggable: false,
  };

  mapZoom = 6.8;
  mapMinZoom = 3;
  mapStyle = googleMapsSilverTheme;
  mapCenter = this.selectedMarkerPosition ?? this.currentMarkerPosition ?? this.marketplacePoint;
  mapOptions: google.maps.MapOptions = {
    zoom: this.mapZoom,
    styles: this.mapStyle,
    center: this.mapCenter,
    disableDefaultUI: true,
    minZoom: this.mapMinZoom,
  };

  private autocomplete: Subscription | null = null;

  get marketplacePoint(): DoodCoordinationPoint {
    return {
      lat: this.marketplaceSelector.marketplace?.address?.point?.coordinates?.[1] ?? 0,
      lng: this.marketplaceSelector.marketplace?.address?.point?.coordinates?.[0] ?? 0,
    };
  }

  constructor(
    private ngZone: NgZone,
    private readonly router: Router,
    private readonly modalsService: ModalsService,
    private settingsSelector: SettingsStoreSelector,
    private readonly entitiesService: EntitiesService,
    private readonly routerHelper: RouterHelperService,
    private readonly googleApiService: GoogleApiService,
    private googleMapsHelper: GoogleMapsApiHelper,
    private marketplaceSelector: MarketplaceStoreSelector,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly shopSearchParametersService: ShopSearchParametersService,
  ) {
    super();
    this.getProximityShops();
    this.updateCoordinates();
    this.googleMapsHelper.initialize();
    this.googleMapsHelper.onLoaded
      .pipe(
        filter(x => x),
        takeUntil(this._destroyerRef),
      )
      .subscribe(isLoaded => {
        this.isMapApiLoaded = isLoaded;
        if (isLoaded) {
          this._updateMapOptions();
        }
      });
  }

  ngAfterViewInit(): void {
    this.settingsSelector.selectParameters
      .pipe(
        takeUntil(this._destroyerRef),
        map(parameters => parameters.distribution_mode),
        tap(() => this.getProximityShops()),
      )
      .subscribe();
    this.shopSearchParametersService
      .getParameters$()
      .pipe(
        takeUntil(this._destroyerRef),
        tap(parameters => this.filterAddress$.next(parameters?.location?.address ?? '')),
        tap(() => (this.geolocateError = undefined)),
      )
      .subscribe();
    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewChecked(): void {
    this.initAddressAutocomplete();
    this.changeDetectorRef.detectChanges();
  }

  isMarkerVisible(marker: IMarker): boolean {
    const _point = this.selectedMarkerPosition ?? this.currentMarkerPosition;
    if (_point) {
      const { lat, lng } = _point;
      return marker.lat !== lat && marker.lng !== lng;
    }
    return true;
  }

  private getProximityShops(): void {
    this.isGetShopLoading = true;
    this.entitiesService
      .loadProximityEntities$()
      .pipe(take(1))
      .subscribe(entities => {
        this.isGetShopLoading = false;
        this.ngZone.run(() => (this.shops = entities));
        this.markers = this.mapEntityVenuesToMarkers(entities);
      });
  }

  private mapEntityVenuesToMarkers(entities: DoodEntityModel[]): IMarker[] {
    return entities
      .map(entity => {
        const coordinates =
          entity[EntityKeys.Venue]?.[VenueKeys.Point][VenuePointKeys.Coordinates] ||
          entity[EntityKeys.Address]?.[VenueKeys.Point][VenuePointKeys.Coordinates];
        if (coordinates && coordinates.length >= 2) {
          return {
            lat: coordinates[1],
            lng: coordinates[0],
          };
        }
      })
      .filter((coordinates): coordinates is IMarker => coordinates !== null);
  }

  private updateCoordinates(): void {
    this.shopSearchParametersService
      .getParameters$()
      .pipe(takeUntil(this._destroyerRef))
      .subscribe(parameters => {
        this.selectedMarkerPosition = null;
        if (parameters.location) {
          this.currentMarkerPosition = {
            lat: Number(parameters.location.lat),
            lng: Number(parameters.location.lng),
          };
        } else {
          this.currentMarkerPosition = null;
        }
        this._updateMapOptions();
      });
  }

  getCurrentPosition(): void {
    this.googleApiService
      .geolocateUser$()
      .pipe(
        take(1),
        tap(geolocationResult =>
          this.shopSearchParametersService.setLocation({
            address: geolocationResult.formatted_address,
            address_components: geolocationResult.address_components,
            lat: geolocationResult.geometry?.location.lat(),
            lng: geolocationResult.geometry?.location.lng(),
          }),
        ),
        tap(() => this.getProximityShops()),
        tap(() => (this.geolocateError = undefined)),
        catchError(error => {
          this.geolocateError = error;
          this.input?.clearValue();
          return of(error);
        }),
      )
      .subscribe();
  }

  private initAddressAutocomplete(): void {
    if (!this.autocomplete && this.input) {
      const input = this.input.addressSearchInput.nativeElement;
      this.autocomplete = this.googleApiService
        .getAutoCompleteAddress(input)
        .pipe(
          takeUntil(this._destroyerRef),
          tap(place =>
            this.shopSearchParametersService.setLocation({
              address: place.formatted_address,
              address_components: place.address_components,
              lat: place.geometry?.location.lat(),
              lng: place.geometry?.location.lng(),
            }),
          ),
          tap(() => this.getProximityShops()),
        )
        .subscribe();
    }
  }

  selectMarker(event: google.maps.MapMouseEvent): void {
    const lng = event.latLng?.lng() ?? this.marketplacePoint.lat;
    const lat = event.latLng?.lat() ?? this.marketplacePoint.lng;
    const shopAtMarker = this.shops.find(entity => {
      const coordinates =
        entity[EntityKeys.Venue]?.[VenueKeys.Point][VenuePointKeys.Coordinates] ||
        entity[EntityKeys.Address]?.[VenueKeys.Point][VenuePointKeys.Coordinates];
      return coordinates?.[1] === lat && coordinates?.[0] === lng;
    });

    this.selectedMarkerPosition = {
      lat,
      lng,
      labelOptions: {
        ...labelOptions,
        text: shopAtMarker?.[EntityKeys.Name],
      },
    };
    this.updateShopsDistances(this.selectedMarkerPosition);
    this._updateMapOptions();

    if (this.isShopSideScrollingListVisible && this.shopSideScrollingList) {
      this.shopSideScrollingList.nativeElement.scrollLeft = 0;
    } else {
      this.isShopSideScrollingListVisible = true;
    }
  }

  private updateShopsDistances(centerPoint: IMarker): void {
    this.shops = this.shops.map(shop => {
      const _shop: DoodEntityModel = { ...shop };
      const _venue = _shop[EntityKeys.Venue];
      if (_venue && _venue[VenueKeys.Point]) {
        _shop[EntityKeys.Distance] = this.calculateDistanceBetweenCoordinates(
          _venue[VenueKeys.Point],
          centerPoint,
        );
      }
      return _shop;
    });
    this.shops = sortBy(this.shops, [EntityKeys.Distance]);
  }

  private calculateDistanceBetweenCoordinates(
    shopCoordinates: DoodVenuePoint,
    markerCoordinates: IMarker,
  ): number {
    return DistanceUtils.calculateDistanceBetweenCoordinates(
      shopCoordinates[VenuePointKeys.Coordinates][1],
      shopCoordinates[VenuePointKeys.Coordinates][0],
      markerCoordinates.lat,
      markerCoordinates.lng,
    );
  }

  private _updateMapOptions(): void {
    this.mapCenter =
      this.selectedMarkerPosition ?? this.currentMarkerPosition ?? this.marketplacePoint;
    this.mapOptions = {
      ...this.mapOptions,
      zoom: this.mapZoom,
      styles: this.mapStyle,
      center: this.mapCenter,
    };
  }

  openShopSearchParametersModal(): void {
    this.modalsService.open(ShopSearchParametersModalComponent.handle);
  }

  openFiltersModal(): void {
    this.modalsService.open(FiltersModalComponent.handle);
  }

  navigateToShopList(): void {
    const searchParams = ParametersUtils.getQueryParameters(this.settingsSelector.parameters);
    this.router.navigate([this.routerHelper.translateRoute(Paths.Shops)], {
      queryParams: searchParams,
    });
  }

  navigateToShopPage(shop: DoodEntityModel): void {
    this.router.navigate([
      this.routerHelper.translateRoute(
        `shops/${SlugUtils.formatSlug(shop[EntityKeys.Type][EntityKeys.Name])}/${shop.slug}`,
      ),
    ]);
  }

  onClear(event: string): void {
    if (!event?.length) {
      this.filterAddress$.next(event);
    }
  }
}
