<div
  class="mx-auto"
  [ngClass]="{
    'max-w-640': !width || width === 'small',
    'w-full': width === 'full',
    'px-5 lg:px-12': !withoutPadding,
    container: !withoutContainer && (!width || width === 'small'),
    'pt-2': topMargin === 'small',
    'pt-5': topMargin === 'medium',
    'pt-7': topMargin === 'large',
    'pb-2': bottomMargin === 'small',
    'pb-5': bottomMargin === 'medium',
    'pb-7': bottomMargin === 'large',
  }"
  [style.color]="textColor | contentBuilderColor"
>
  <app-text-atom [content]="content"></app-text-atom>
</div>
