<div
  class="relative flex h-full flex-col justify-between overflow-hidden rounded-lg bg-white shadow-S"
  [class.opacity-50]="!asset.is_usable"
>
  <div>
    <img
      *ngIf="asset.image"
      [src]="asset.image"
      alt="img"
      class="h-24 max-h-24 w-full rounded-t-lg object-cover"
    />
  </div>
  <div class="flex h-full flex-col justify-start px-3 py-2 pb-3 text-sm">
    <p class="font-accent font-normal text-neutral-800">{{ asset.name }}</p>
    <div class="text-sm font-bold">
      {{ assetTagText(asset) | translate }}
    </div>
    <p class="line-clamp-3 pt-1 font-base text-xs font-normal text-neutral-600">
      {{ asset.description }}
    </p>
    <p
      *ngIf="!asset.is_usable"
      class="mt-2 font-bold"
    >
      {{
        'como.asset-available-in-points'
          | translate: { points: (asset.points_remaining ?? 0) / 100 }
      }}
    </p>
  </div>
  <div
    *ngIf="asset.is_usable && displayAddButton"
    class="mb-4 flex flex-col items-center justify-center"
  >
    <div
      *ngIf="isAssetInCart"
      class="mb-2 flex flex-row items-center justify-center text-sm font-normal leading-snug lg:text-base"
    >
      <i class="icon icon-check-circle mr-2 text-success-600"></i>
      {{ 'como.asset-added-to-cart' | translate }}
    </div>
    <app-button-atom
      *ngIf="!isAssetInCart"
      [label]="'como.use-voucher' | translate"
      (click)="onAssetAdd(asset)"
      icon="icon-plus"
      size="XS"
      thickSize="XS"
      type="outlined"
      color="neutral"
    ></app-button-atom>
    <app-button-atom
      *ngIf="isAssetInCart"
      [label]="'como.remove-voucher' | translate"
      (click)="onAssetRemove(asset)"
      icon="icon-c-remove"
      size="XS"
      thickSize="XS"
      type="outlined"
      color="neutral"
    ></app-button-atom>
  </div>
</div>
