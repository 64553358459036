import {
  Directive,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';

@Directive({
  selector: '[appScrollSpy]',
})
export class ScrollSpyDirective implements OnChanges {
  @Input() ids: string[] = [];
  @Input() offset?: number;
  @Output() sectionChange = new EventEmitter<string>();

  private currentSection!: string;
  private elements!: HTMLElement[];

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private settingsSelector: SettingsStoreSelector,
  ) {}

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    this.checkPosition();
  }

  checkPosition(): void {
    if (!this.ids?.length) {
      return;
    }
    let currentSection!: string;
    const pageYOffset = window.scrollY;
    const isDesktop = this.settingsSelector.isDesktopScreen;
    this.elements?.some((child: HTMLElement) => {
      const offsetTop =
        child?.offsetTop + child?.offsetHeight - ((this.offset ?? isDesktop) ? 70 : 55);
      if (pageYOffset < offsetTop) {
        currentSection = child?.id;
        return true;
      }
    });
    if (currentSection !== this.currentSection) {
      this.currentSection = currentSection;
      this.sectionChange.emit(this.currentSection);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { ids } = changes;
    if (ids && ids.currentValue) {
      setTimeout(() => {
        this.getElements(ids.currentValue);
        this.checkPosition();
      });
    }
  }

  private getElements(ids: string[]): void {
    this.elements = ids.map((tag: string) => this.document.getElementById(tag) as HTMLElement);
  }
}
