import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { OrderStatusLabels } from '@config/labels/order.labels';

@Component({
  selector: 'app-notification-order-atom',
  templateUrl: './notification-order-atom.component.html',
})
export class NotificationOrderAtomComponent implements OnChanges {
  @Input() firstName!: string;
  @Input() shopName!: string;
  @Input() orderStatus: keyof typeof OrderStatusLabels = 'READY';

  @Output() closeMessage = new EventEmitter<any>();
  @Output() clickMessage = new EventEmitter<any>();

  message!: string;

  get orderStatusLabels(): typeof OrderStatusLabels {
    return OrderStatusLabels;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.message = this.orderStatusLabels[this.orderStatus]?.message;
    const { firstName, shopName } = changes;
    if (firstName) {
      this.message = this.message?.replace('{firstName}', firstName.currentValue);
    }
    if (shopName) {
      this.message = this.message?.replace(
        '{shopName}',
        `<span class="font-bold">${shopName.currentValue}</span>`,
      );
    }
  }
}
