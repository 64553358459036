import { combineLatest, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';

import { BasketStoreRefiner } from '@app/common/refiners/basket.refiner';
import { AuthStoreSelector } from '@app/common/selectors/authentication.selector';
import { TransactionStoreSelector } from '@app/common/selectors/transaction.selector';

import { DoodUserModel } from '@store/authentication/authentication.model';
import { DoodTransactionStatus, DoodTransactionModel } from '@core/models/transaction.model';

export interface GroupedTransaction {
  user: string | DoodUserModel;
  transactions: DoodTransactionModel[];
}

@Injectable({ providedIn: 'root' })
export class TransactionStoreRefiner {
  constructor(
    private authSelector: AuthStoreSelector,
    private basketRefiner: BasketStoreRefiner,
    private selector: TransactionStoreSelector,
  ) {}

  // confirmedTransactions$
  selectConfirmedTransaction = this.selector.selectTransactions.pipe(
    map(_transactions =>
      _transactions.filter(_transaction => _transaction.status === DoodTransactionStatus.confirmed),
    ),
  );

  // transactionsGroupedByUser$
  selectTransactionsGroupedBtUser = this.selectConfirmedTransaction.pipe(
    map(_transactions =>
      _transactions.reduce((_result, _transaction) => {
        const { user } = _transaction;
        const transactionUser = _result.find(
          t => t.user === user || t.user === (user as DoodUserModel).id,
        );
        if (transactionUser) {
          transactionUser.transactions.push(_transaction);
        } else {
          const userId: string = (user as DoodUserModel).id
            ? (user as DoodUserModel).id
            : (user as string);
          _result.push({
            user: userId,
            transactions: [_transaction],
          });
        }
        return _result;
      }, Array<GroupedTransaction>()),
    ),

    switchMap(groupedTransactions =>
      combineLatest([of(groupedTransactions), this.basketRefiner.selectConfirmedUsers]),
    ),
    map(([transaction, users]) => {
      transaction.forEach(_transaction => {
        _transaction.user =
          users.find(_user => _user.user.id === _transaction.user)?.user || _transaction.user;
      });
      return transaction;
    }),
  );

  // transactionsTotalPaidAmount$
  selectTotalPaidAmount = this.selectConfirmedTransaction.pipe(
    map(_transactions =>
      _transactions.reduce((_total, _transaction) => _total + _transaction.amount, 0),
    ),
  );

  // transactionPaidByUser$
  selectAreTransactionsPaidByUser = combineLatest([
    this.selectConfirmedTransaction,
    this.authSelector.selectUser,
  ]).pipe(
    map(
      ([transactions, user]) =>
        !!transactions.find(_transaction => {
          const id =
            typeof _transaction.user !== 'string' ? _transaction.user.id : _transaction.user;
          if (user?.id === id) return _transaction;
        }),
    ),
  );
}
