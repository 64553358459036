import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of, throwError, timeout} from 'rxjs';
import {DoodOrderModel} from '@store/order/order.model';
import {DOOD_API} from '@config/ws.config';
import {mergeMap, retryWhen, take} from "rxjs/operators";

export interface YavinTransactionResponse {
  status: 'ok' | 'ko'; // Transaction status: 'ok' for success, 'ko' for failure.
  transactionId: string; // Unique transaction ID.
  amount: number; // The total amount of the transaction in cents (e.g., 1€ = 100).
  giftAmount: number; // The amount of any tips or donations in cents.
  currencyCode: string; // Currency code following ISO 4217 standards (e.g., EUR for Euro).
  cardToken: string; // Unique card token for the transaction.
  clientCardTicket: string; // The transaction receipt for the client.
  merchantCardTicket?: string; // Merchant's transaction receipt (ticket).
  idempotentUuid: string; // Unique idempotent identifier for the transaction.
  message?: string; // Optional message providing additional details.
}

@Injectable({
  providedIn: 'root',
})
export class YavinTerminalApiService {
  private terminalIpAddress = '';

  constructor(private readonly httpClient: HttpClient) {
  }

  setTerminalIpAddress(terminalIpAddress: string) {
    this.terminalIpAddress = terminalIpAddress;
  }

  abortTransaction$(): Observable<any> {
    return this.httpClient.get<any>(
      `http://127.0.0.1:5000/proxy/${this.terminalIpAddress}:16125/localapi/v4/abort`,
    );
  }

  startTransaction$(
    amount: number,
    orderNumber: string,
    orderId: string,
  ): Observable<YavinTransactionResponse> {
    const body = {
      amount,
      vendor: {
        softwareName: 'DOOD',
        softwareVersion: '1.0',
      },
      cartId: 'DOOD-' + orderNumber,
      idempotentUuid: orderId,
    };

    return this.httpClient
      .post<YavinTransactionResponse>(
        `http://127.0.0.1:5000/proxy/${this.terminalIpAddress}:16125/localapi/v4/payment`,
        body
      )
      .pipe(
        // 62 secondes timeout - mandatory for Yavin
        timeout(62000),
        // Handle timeout error, and retry
        retryWhen(errors =>
          errors.pipe(
            // Keep only timeout errors
            mergeMap((error, retryCount) => {
              if (error.name === 'TimeoutError') {
                console.warn(`Timeout occurred, retrying... Attempt #${retryCount + 1}`);
                return of(error);
              }
              return throwError(error);
            }),
            // Limit retries
            take(10)
          )
        )
      );
  }

  changeOrderStatusToPayment$(orderId: string, paymentRef: string): Observable<DoodOrderModel> {
    return this.httpClient.post<DoodOrderModel>(`${DOOD_API}/yavin-terminal/payment`, {
      order_id: orderId,
      terminal_payment_ref: paymentRef,
    });
  }
}
