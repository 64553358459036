import { Router } from '@angular/router';
import { combineLatest, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { ShopKeys } from '@config/keys/shop.keys';
import { OrderKeys } from '@config/keys/order.keys';
import { LineItemKeys } from '@config/keys/line-item.keys';

import { SlugUtils } from '@shared/utils/slug/slug.utils';
import { DateUtils } from '@shared/utils/date/date.utils';

import { Paths } from '@config/paths.config';
import { OrderTypeValues } from '@config/values/order.values';
import { OrderStatusLabels } from '@config/labels/order.labels';

import { DoodShopModel } from '@core/models/shop.model';
import { DoodOnSiteLocationModel } from '@core/models/on-site-location.model';

import { OrderStoreSelector } from '@common/selectors/order.selector';
import { OnSiteLocationStoreSelector } from '@common/selectors/on-site-location.selector';

import { HeaderService } from '@core/services/header/header.service';
import { RouterHelperService } from 'src/app/core/services/router-helper/router-helper.service';
import { OnSiteLocationsService } from '@core/services/on-site-locations/on-site-locations.service';
import { CartService } from '@core/services/cart/cart.service';
import { DoodOrderLineItemModel, DoodOrderModel } from '@store/order/order.model';
import { DoodLineItemModel } from '@core/models/line-item.model';

@Component({
  selector: 'app-order-details-block',
  templateUrl: './order-details-block.component.html',
})
export class OrderDetailsBlockComponent implements OnInit, OnDestroy {
  order$ = combineLatest([
    this.orderSelector.selectActive,
    this.translateService.get(['order-details.format', 'order-details.at']),
  ]).pipe(
    tap(([order, translations]) => {
      this.orderReadyAt = `${DateUtils.dayjsInMarketplaceTimezone(order?.ready_at).format('DD/MM/YYYY')} ${translations['order-details.at']} ${DateUtils.dayjsInMarketplaceTimezone(order?.ready_at).format(translations['order-details.format'])}`;
    }),
    map(([order]) =>
      order?.[OrderKeys.MultiShop]
        ? (order[OrderKeys.ChildOrders] as unknown as DoodOrderModel)
        : order,
    ), // ! it seems that the component does not handle childOrders display
  );

  onSiteLocationName$ = this.orderSelector.selectActive.pipe(
    switchMap(order => {
      if (order && order.onsite_location_id) {
        return this.onSiteLocationSelector.selectLocation(order.onsite_location_id);
      }
      return of(undefined);
    }),
    map((location: DoodOnSiteLocationModel | undefined) => (location ? location?.name : undefined)),
  );

  orderReadyAt!: string;

  orderKeys = OrderKeys;
  shopKeys = ShopKeys;
  lineItemKeys = LineItemKeys;
  orderStatusLabels = OrderStatusLabels;
  orderTypeValues = OrderTypeValues;

  constructor(
    private readonly router: Router,
    private orderSelector: OrderStoreSelector,
    private readonly headerService: HeaderService,
    private readonly routerHelper: RouterHelperService,
    private readonly translateService: TranslateService,
    private onSiteLocationSelector: OnSiteLocationStoreSelector,
    private readonly onSiteLocationsService: OnSiteLocationsService,
    private readonly cartService: CartService,
  ) {
    this.headerService.setTitle('order-details.order');
  }

  ngOnInit(): void {
    this.loadOrderShopSlots();
  }

  ngOnDestroy(): void {
    this.headerService.setTitle(undefined);
  }

  navigateToShop(shop: DoodShopModel, marketplaceId: string, isMultiShop: boolean): void {
    if (!isMultiShop) {
      this.router.navigate([
        this.routerHelper.translateRoute(
          `${Paths.Shops}/${SlugUtils.formatSlug(shop[ShopKeys.Name])}/${shop[ShopKeys.Slug]}`,
        ),
      ]);
      return;
    }
    this.router.navigate([this.routerHelper.translateRoute(Paths.Shops)]);
  }

  renewOrder(order: DoodOrderModel): void {
    this.cartService.createCartFromOrder(order).subscribe(() => {
      this.router.navigate([this.routerHelper.translateRoute(Paths.Cart)]);
    });
  }
  trackByOrderId(index: number, order: any): string {
    return order.id;
  }

  goToMap(): void {
    this.router.navigate([this.routerHelper.translateRoute(Paths.ShopsMap)]);
  }

  private loadOrderShopSlots(): void {
    this.orderSelector.selectActive
      .pipe(
        switchMap(order => {
          if (order?.onsite_location_id && order.shop) {
            return this.onSiteLocationsService.loadOnSiteLocations$(order?.shop?.id);
          }
          return of(null);
        }),
      )
      .subscribe();
  }

  protected asDoodLineItemModel(items: DoodOrderLineItemModel[]): DoodLineItemModel[] {
    return items as DoodLineItemModel[];
  }
}
