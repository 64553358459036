<div class="max-w-screen-sm mx-auto p-5 bg-neutral-100">
  <ng-container *ngIf="entities$ | async as entities">
    <div class="grid grid-cols-2 gap-4 flex flex-col">
      <div *ngFor="let entity of entities">
        <app-push-shop-card-atom
          *ngIf="entity && entity.name"
          [logoUrl]="apiUrl + entity.type?.thumbnail_url"
          [name]="entity.name"
          [slug]="entity.slug"
        ></app-push-shop-card-atom>
      </div>
    </div>
  </ng-container>
</div>
