import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';

import {
  SELECT_DECLINABLE_ACTIVE,
  SELECT_DECLINABLE_ACTIVE_ID,
  SELECT_DECLINABLE_ALL,
  SELECT_DECLINABLE_ENTITY,
  SELECT_DECLINABLE_ERROR,
} from '@store/declinable';
import { DoodDeclinableModel } from '@core/models/product.model';

@Injectable({ providedIn: 'root' })
export class DeclinableStoreSelector implements OnDestroy {
  private readonly _destroyerRef = new Subject<boolean>();

  declinables: DoodDeclinableModel[] = [];
  active: DoodDeclinableModel | null = null;

  // Declinables
  selectDeclinables = this.store.select(SELECT_DECLINABLE_ALL);
  selectDeclinable = (id: string): Observable<DoodDeclinableModel | undefined> =>
    this.store.select(SELECT_DECLINABLE_ENTITY(id));

  // Error
  selectError = this.store.select(SELECT_DECLINABLE_ERROR);

  // Active
  selectActive = this.store.select(SELECT_DECLINABLE_ACTIVE);
  selectActiveId = this.store.select(SELECT_DECLINABLE_ACTIVE_ID);

  constructor(private store: Store) {
    this.selectDeclinables.pipe(takeUntil(this._destroyerRef)).subscribe(_declinables => {
      this.declinables = _declinables;
    });
    this.selectActive.pipe(takeUntil(this._destroyerRef)).subscribe(_active => {
      this.active = _active;
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe();
  }

  private _unsubscribe(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
  }
}
