import { createFeature, createSelector } from '@ngrx/store';

import { AUTH_STORE_REDUCER } from './authentication.reducer';
import { AuthenticationStatusState, AUTH_STORE_KEY } from './authentication.state';

const AUTH_STORE_FEATURE = createFeature({
  name: AUTH_STORE_KEY,
  reducer: AUTH_STORE_REDUCER,
});

export const {
  selectUser: SELECT_AUTH_USER,
  selectError: SELECT_AUTH_ERROR,
  selectGuard: SELECT_AUTH_GUARD,
  selectStatus: SELECT_AUTH_STATUS,
  selectPermissions: SELECT_AUTH_PERMISSIONS,
  selectAuthenticationState: SELECT_AUTH_STATE,
  selectIsUserLoaded: SELECT_AUTH_USER_IS_LOADED,
  selectIsUserLoading: SELECT_AUTH_USER_IS_LOADING,
} = AUTH_STORE_FEATURE;

// User
export const SELECT_AUTH_USER_ID = createSelector(SELECT_AUTH_USER, user => user?.id);

export const SELECT_AUTH_USER_META_FIELDS = createSelector(
  SELECT_AUTH_USER,
  user => user?.metafields,
);

export const SELECT_AUTH_USER_IS_LOGGED_IN = createSelector(
  SELECT_AUTH_STATE,
  state => !!state.user,
);

export const SELECT_AUTH_USER_IS_ANONYMOUS = createSelector(
  SELECT_AUTH_STATE,
  state => state.user?.is_anonymous ?? false,
);

// Status
export const SELECT_AUTH_STATUS_STEP = createSelector(
  SELECT_AUTH_STATUS,
  (status: AuthenticationStatusState) => status.step,
);

export const SELECT_AUTH_STATUS_TOKEN = createSelector(
  SELECT_AUTH_STATUS,
  (status: AuthenticationStatusState) => status.token,
);

export const SELECT_AUTH_STATUS_WITH_REDIRECT = createSelector(
  SELECT_AUTH_STATUS,
  (status: AuthenticationStatusState) => status.redirect,
);

export const SELECT_AUTH_STATUS_HAS_TOKEN = createSelector(
  SELECT_AUTH_STATUS_TOKEN,
  token => !!token,
);
