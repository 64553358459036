import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-faded-carousel-atom',
  templateUrl: './faded-carousel-atom.component.html',
})
export class FadedCarouselAtomComponent implements OnInit {
  @Input() items!: any[];
  @Input() duration = 5000;
  @Input() background:
    | 'neutral'
    | 'success'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'highlight'
    | 'error' = 'neutral';

  index = 0;

  ngOnInit(): void {
    setInterval(() => {
      if (this.index === this.items?.length - 1) {
        this.index = 0;
      } else {
        this.index = this.index + 1;
      }
    }, this.duration);
  }
}
