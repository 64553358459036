export enum GroupedOrderModalsAttributes {
  ShareLink = 'share-link',
  GeneratedLink = 'generated-link',
  JoinOrder = 'join-order',
  CancelOrder = 'cancel-order',
  LeavingOrder = 'leaving-order',
  RemoveUserOrder = 'remove-user-order',
  ClearCart = 'clear-cart',
  NotValidOrder = 'not-valid-order',
  ChooseOrderType = 'choose-order-type',
}
