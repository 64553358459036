import { extend } from 'dayjs';
import { combineLatest, Subject } from 'rxjs';
import isToday from 'dayjs/plugin/isToday';
import { takeUntil, tap } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { DoodCartModel } from '@core/models/cart.model';
import { DoodOrderModel } from '@store/order/order.model';
import { ISimpleItem } from '@core/models/simple-item.model';

import { ShopStoreSelector } from '@common/selectors/shop.selector';
import { CartStoreSelector } from '@common/selectors/cart.selector';
import { OrderStoreSelector } from '@common/selectors/order.selector';

import { ModalsService } from '@core/services/modals/modals.service';
import { CartParametersService } from '@core/services/cart-parameters/cart-parameters.service';

import { CartParametersModalComponent } from '@shared/modals/cart-parameters-modal/cart-parameters-modal.component';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-cart-parameters-overview',
  templateUrl: './cart-parameters-overview.component.html',
})
export class CartParametersOverviewComponent implements OnInit, OnDestroy {
  parameters!: ISimpleItem[];

  @Input() textColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() iconBackgroundColor: IContentBuilderFieldColor = {
    value: 'primary-100',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() iconColor: IContentBuilderFieldColor = {
    value: 'primary-600',
    type: ColorFieldTypesValues.Palette,
  };

  private readonly _destroyerRef = new Subject<boolean>();

  constructor(
    private cartSelector: CartStoreSelector,
    private shopSelector: ShopStoreSelector,
    private orderSelector: OrderStoreSelector,
    private readonly modalsService: ModalsService,
    private readonly cartParametersService: CartParametersService,
  ) {
    extend(isToday);
  }

  ngOnInit(): void {
    combineLatest([
      this.orderSelector.selectActive,
      this.cartSelector.selectActive,
      this.shopSelector.selectSlots,
    ])
      .pipe(
        takeUntil(this._destroyerRef),
        tap(([order, cart, slots]) => {
          this.handleCartChanges(cart, order, slots);
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
  }

  openParametersModal(): void {
    this.modalsService.open(CartParametersModalComponent.handle);
  }

  private handleCartChanges(
    cart: DoodCartModel | null,
    order: DoodOrderModel | null,
    slots?: number[],
  ): void {
    if (!cart) {
      return;
    }
    this.parameters = this.cartParametersService.retrieveCartParametersValue(cart, order, slots);
  }
}
