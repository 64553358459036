import { RouterHelperService } from './../../../core/services/router-helper/router-helper.service';
import { Component, Input } from '@angular/core';
import {
  IContentBuilderFieldColor,
  IContentBuilderFieldImage,
} from '@core/models/content-builder-fields.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-big-push-block',
  templateUrl: './big-push-block.component.html',
})
export class BigPushBlockComponent {
  @Input() heading?: string;
  @Input() text?: string;
  @Input() buttonLabel?: string;
  @Input() buttonLink?: string;
  @Input() icon?: IContentBuilderFieldImage;
  @Input() backgroundColor?: IContentBuilderFieldColor;
  @Input() externalLink?: boolean;

  constructor(
    private readonly router: Router,
    private readonly routerHelper: RouterHelperService,
  ) {}

  buttonClick($event: Event): void {
    if (this.externalLink && this.buttonLink) {
      window.location.href = this.buttonLink;
    } else {
      this.router.navigate([
        this.buttonLink ? this.routerHelper.translateRoute(this.buttonLink) : this.buttonLink,
      ]);
    }
  }
}
