import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';

import {
  SELECT_PRODUCT_ALL,
  SELECT_PRODUCT_ENTITY,
  SELECT_PRODUCT_ERROR,
  SELECT_PRODUCT_ACTIVE,
  SELECT_PRODUCT_ACTIVE_CATEGORY,
} from '@store/product';
import { DoodProductModel } from '@core/models/product.model';

@Injectable({ providedIn: 'root' })
export class ProductStoreSelector implements OnDestroy {
  private readonly _destroyerRef = new Subject<boolean>();

  products: DoodProductModel[] = [];
  active: DoodProductModel | null = null;

  // Products
  selectProducts = this.store.select(SELECT_PRODUCT_ALL);
  selectProduct = (id: string): Observable<DoodProductModel | undefined> =>
    this.store.select(SELECT_PRODUCT_ENTITY(id));

  // Error
  selectError = this.store.select(SELECT_PRODUCT_ERROR);

  // Selected
  selectActive = this.store.select(SELECT_PRODUCT_ACTIVE);

  selectActiveCategory = this.store.select(SELECT_PRODUCT_ACTIVE_CATEGORY);

  constructor(private store: Store) {
    this.selectProducts.pipe(takeUntil(this._destroyerRef)).subscribe(_products => {
      this.products = _products;
    });
    this.selectActive.pipe(takeUntil(this._destroyerRef)).subscribe(_active => {
      this.active = _active;
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe();
  }

  private _unsubscribe(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
  }
}
