import { Router } from '@angular/router';
import { Observable, of, throwError, timer } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ISdkManagedPaymentIntent } from '@stripe/terminal-js';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { Paths } from '@config/paths.config';

import {
  IPaymentServiceStripe,
  StripeTerminalReaderConnectionStatus,
} from '@core/models/stripe.model';
import { IStripePaymentIntent } from '@core/models/payment.model';

import { DoodOrderModel } from '@store/order/order.model';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { DoodApiStripePaymentIntentResponse } from '@shared/interfaces/stripe.interface';

import { CartService } from '@core/services/cart/cart.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { PaymentService } from '@core/services/payment/payment.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { StripeTerminalService } from '@core/services/stripe-terminal/stripe-terminal.service';
import { PaymentStoreDispatcher } from '@common/dispatchers/payment.dispatcher';

// TODO: Check global messages and add i18n
@Component({
  selector: 'app-payment-panel-stripe-terminal',
  templateUrl: './payment-panel-stripe-terminal.component.html',
})
export class PaymentPanelStripeTerminalComponent
  extends DestroyerBase
  implements OnInit, OnDestroy
{
  private order: DoodOrderModel | null = null;

  statusMessage = '';
  isReaderConnected = false;
  isReaderConnectionInProgress = false;
  paymentIntent?: ISdkManagedPaymentIntent;
  paymentServiceStripe?: IPaymentServiceStripe;

  constructor(
    private readonly router: Router,
    private readonly cartService: CartService,
    private readonly ordersService: OrdersService,
    private readonly paymentService: PaymentService,
    private paymentDispatcher: PaymentStoreDispatcher,
    private readonly routerHelperService: RouterHelperService,
    private readonly stripeTerminalService: StripeTerminalService,
  ) {
    super();
    this.paymentDispatcher.updateUI({
      error: null,
      button: {
        isVisible: false,
      },
    });
  }

  ngOnInit(): void {
    this.stripeTerminalService.setConfig(this.paymentServiceStripe);
    this.statusMessage = 'Chargement en cours...';
    this.listenToStripeTerminalStatus();
  }

  ngOnDestroy(): void {
    this.stripeTerminalService.cancelCollectPaymentMethod$().subscribe();
    super.ngOnDestroy();
  }

  private listenToStripeTerminalStatus(): void {
    this.stripeTerminalService.readerConnectionChanged$
      .pipe(
        takeUntil(this._destroyerRef),
        tap(readerConnection => {
          if (readerConnection === StripeTerminalReaderConnectionStatus.CONNECTED) {
            this.onReaderConnected();
          }
          if (readerConnection === StripeTerminalReaderConnectionStatus.DISCONNECTED) {
            this.onReaderDisconnected();
          }
        }),
      )
      .subscribe();
  }

  private onReaderDisconnected(): void {
    this.isReaderConnected = false;
  }

  private onReaderConnected(): void {
    this.isReaderConnected = true;
    this.prepareOrder();
  }

  private prepareOrder(): void {
    this.stripeTerminalService
      .cancelCollectPaymentMethod$()
      .pipe(
        takeUntil(this._destroyerRef),
        switchMap(() => this.createOrderAndPaymentIntent$()),
        takeUntil(this._destroyerRef),
        tap(captureResult => {
          if (captureResult) {
            if (!('status' in captureResult)) {
              this.statusMessage = 'Une erreur est servenue pendant le paiement de votre commande';
              return;
            }
            if (captureResult.status !== 'succeeded') {
              this.statusMessage = `Une erreur est servenue pendant le paiement de votre commande (status: ${captureResult.status})`;
              return;
            }
          }
          if (!this.order) {
            this.statusMessage = 'Une erreur est servenue pendant le paiement de votre commande';
            return;
          }

          this.cartService.clearCart();
          this.router.navigate([
            this.routerHelperService.translateRoute(
              `${Paths.Orders}/${this.order.id}/${Paths.Status}`,
            ),
          ]);
          this.paymentDispatcher.updateUI({
            error: null,
            button: {
              isEnabled: false,
            },
            status: {
              isLoading: false,
              isInProgress: false,
            },
          });
        }),
      )
      .subscribe();
  }

  createOrderAndPaymentIntent$(): Observable<DoodApiStripePaymentIntentResponse | null> {
    console.log('[Stripe Terminal] Create order and payment intent');
    if (!this.isReaderConnected) {
      console.log('[Stripe Terminal] Error: Reader not connected');
      return of(null);
    }
    return this.ordersService.createOrder$('STRIPE_TERMINAL').pipe(
      takeUntil(this._destroyerRef),
      switchMap(order => {
        this.order = order;
        if (order) {
          return this.createPaymentIntent$(order);
        }
        return of(null);
      }),
    );
  }

  private createPaymentIntent$(
    order: DoodOrderModel,
  ): Observable<DoodApiStripePaymentIntentResponse> {
    return this.paymentService.createOrderPaymentIntent$<IStripePaymentIntent>(order.id).pipe(
      take(1),
      tap(() => (this.statusMessage = 'Suivez les instructions sur le terminal de paiement')),
      switchMap(paymentIntent => this.stripeTerminalService.collectPayment$(paymentIntent)),
      take(1),
      switchMap((result: any) => {
        this.statusMessage = 'En traitement';
        console.log('[Stripe Terminal] Collect payment method', result);
        if ('error' in result && result.error) {
          this.statusMessage = result.error.message;
          this.scheduleRetry();
          return throwError(() => result.error.message);
        }
        if (!('paymentIntent' in result)) {
          this.statusMessage = 'Une erreur est survenue';
          this.scheduleRetry();
          return throwError(() => '[Stripe Terminal] No attribute paymentIntent in result');
        }

        return this.stripeTerminalService.processPayment$(result.paymentIntent);
      }),
      switchMap(paymentResult => {
        this.statusMessage = 'Envoi de votre commande en cuisine';
        console.log('[Stripe Terminal] Process payment result', paymentResult);
        if ('error' in paymentResult) {
          if (paymentResult.error.code === 'card_declined') {
            this.statusMessage = 'Carte refusée';
            this.scheduleRetry();
            return throwError(() => 'Carte refusée');
          }
          this.statusMessage = `Le paiement a échoué (${paymentResult.error.code})`;
          this.scheduleRetry();
          return throwError(() => `Le paiement a échoué (${paymentResult.error.code})`);
        }
        return this.stripeTerminalService.capturePayment$(order);
      }),
    );
  }

  private scheduleRetry(): void {
    const delay = 1000;
    console.log('[Stripe Terminal] Retry in ' + delay);
    timer(delay)
      .pipe(
        takeUntil(this._destroyerRef),
        tap(() => this.prepareOrder()),
      )
      .subscribe();
  }
}
