import { Component, Input } from '@angular/core';

import { OrderStatusValues } from '@config/values/order.values';
import { OrderStatusLabels } from '@config/labels/order.labels';

@Component({
  selector: 'app-order-status-tag-atom',
  templateUrl: './order-status-tag-atom.component.html',
})
export class OrderStatusTagAtomComponent {
  @Input() orderStatus!: string;

  orderStatusValues = OrderStatusValues;
  orderStatusLabels = OrderStatusLabels;
}
