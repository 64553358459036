<div
  class="h-448px"
  *ngIf="address && isMapLoaded"
>
  <google-map
    width="100%"
    height="448px"
    class="w-full"
    [options]="mapOptions"
  >
    <map-marker
      [icon]="markerIcon"
      [options]="markerOptions"
      [position]="mapOptionCenter"
    ></map-marker>
  </google-map>
</div>
