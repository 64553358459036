export enum EntityKeys {
  Id = 'id',
  Available = 'available',
  AvailableAt = 'available_at',
  Slug = 'slug',
  Name = 'name',
  Type = 'type',
  ThumbnailUrl = 'thumbnail_url',
  ShortDescription = 'short_description',
  Images = 'images',
  Cover = 'cover',
  Pricing = 'pricing',
  Description = 'description',
  EatIn = 'eat_in',
  EdenredEnabled = 'edenred_enabled',
  Status = 'status',
  Distance = 'distance',
  EntityType = 'entity_type',
  Venue = 'venue',
  RelatedVenue = 'related_venue',
  OverviewImage = 'overview_image',
  Address = 'address',
  Schedules = 'schedules',
  Domains = 'domains',
  Currency = 'currency',
  DistributionModes = 'distribution_modes',
}
