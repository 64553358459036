import { Observable, of } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { map, switchMap, take } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { UserKeys } from '@config/keys/user.keys';
import { ModalsService } from '@core/services/modals/modals.service';

import { emailValidator } from '@shared/validators/email/email.validator';
import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { PaymentStoreSelector } from '@common/selectors/payment.selector';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';
import { PaymentStoreDispatcher } from '@common/dispatchers/payment.dispatcher';

@Component({
  selector: 'app-bancontact-payment-form-modal',
  templateUrl: './bancontact-payment-form-modal.component.html',
})
export class BancontactPaymentFormModalComponent implements OnInit {
  static handle = 'bacontact-payment-form-modal';

  index$: Observable<number> = this.modalSelector
    .selectModal(BancontactPaymentFormModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  form: UntypedFormGroup;

  userKeys = UserKeys;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private authSelector: AuthStoreSelector,
    private modalSelector: ModalStoreSelector,
    private paymentSelector: PaymentStoreSelector,
    private readonly modalsService: ModalsService,
    private paymentDispatcher: PaymentStoreDispatcher,
  ) {
    this.form = this.fb.group({
      [UserKeys.CompleteName]: ['', [Validators.required]],
      [UserKeys.Email]: ['', [emailValidator(), Validators.required]],
    });
  }

  ngOnInit(): void {
    /*
    combineLatest([this.paymentSelector.selectBancontact, this.authSelector.selectUser])
      .pipe(take(1))
      .subscribe(([bancontact, user]) => {
        const { complete_name, email } = bancontact;
        const hasBancontactInformation = complete_name && email;
        if (hasBancontactInformation) {
          this.form.patchValue(bancontact);
        } else if (user) {
          const { email, firstname, lastname } = user;
          const hasUserInformation = firstname && lastname;
          if (hasUserInformation) {
            this.form.patchValue({
              email: email,
              complete_name: `${lastname} ${firstname}`,
            });
          }
        }
      });
    */

    this.paymentSelector.selectBancontact
      .pipe(
        take(1),
        switchMap(bancontact => {
          const { complete_name, email } = bancontact;
          const hasBancontactInformation = complete_name && email;
          if (hasBancontactInformation) {
            this.form.patchValue(bancontact);
            return of(null);
          }
          return this.authSelector.selectUser;
        }),
      )
      .subscribe(user => {
        if (user) {
          const { email, firstname, lastname } = user;
          const hasUserInformation = firstname && lastname;
          if (hasUserInformation) {
            this.form.patchValue({
              email: email,
              complete_name: `${lastname} ${firstname}`,
            });
          }
        }
      });
  }

  save(): void {
    this.paymentDispatcher.updateBancontact({
      email: this.form.value.email,
      complete_name: this.form.value.complete_name,
    });
    this.close();
  }

  close(): void {
    this.modalsService.close(BancontactPaymentFormModalComponent.handle);
  }
}
