import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationHistoryService } from '@core/services/navigation-history/navigation-history.service';

@Component({
  selector: 'app-favorites-page',
  templateUrl: './favorites-page.component.html',
})
export class FavoritesPageComponent implements OnInit {
  @Input() body: any;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly navigationHistoryService: NavigationHistoryService,
  ) {}

  ngOnInit(): void {
    this.navigationHistoryService.pushStateFromActivatedRouteSnapshot(
      'user-favorite-shop-list',
      this.activatedRoute.snapshot,
    );

    // @todo: retrieve user favorite shops
  }
}
