import { createFeature, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';

import {
  DECLINABLE_STORE_KEY,
  DECLINABLE_STORE_ACTIVE_ADAPTER,
  DeclinableState,
} from './declinable.state';
import { DECLINABLE_STORE_REDUCER } from './declinable.reducer';

import { DoodDeclinableModel } from '@core/models/product.model';

const DECLINABLE_STORE_FEATURE = createFeature({
  name: DECLINABLE_STORE_KEY,
  reducer: DECLINABLE_STORE_REDUCER,
});
const { selectDeclinablesState: SELECT_DECLINABLE_STATE } = DECLINABLE_STORE_FEATURE;

export const {
  selectIds: SELECT_DECLINABLE_IDS,
  selectError: SELECT_DECLINABLE_ERROR,
  selectActive: SELECT_DECLINABLE_ACTIVE_ID,
  selectEntities: SELECT_DECLINABLE_ENTITIES,
  selectIsLoaded: SELECT_DECLINABLE_IS_LOADED,
  selectIsLoading: SELECT_DECLINABLE_IS_LOADING,
} = DECLINABLE_STORE_FEATURE;

export const { selectAll: SELECT_DECLINABLE_ALL } =
  DECLINABLE_STORE_ACTIVE_ADAPTER.getSelectors(SELECT_DECLINABLE_STATE);

export const SELECT_DECLINABLE_ENTITY = (
  id: string,
): MemoizedSelector<
  Record<string, any>,
  DoodDeclinableModel | undefined,
  DefaultProjectorFn<DoodDeclinableModel | undefined>
> =>
  createSelector(SELECT_DECLINABLE_STATE, (state: DeclinableState) => {
    return state.entities[id];
  });

export const SELECT_DECLINABLE_ACTIVE = createSelector(
  SELECT_DECLINABLE_ENTITIES,
  SELECT_DECLINABLE_ACTIVE_ID,
  (entities, id) => (id ? (entities[id] ?? null) : null),
);
