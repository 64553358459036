import { map, takeUntil, tap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { PriceUtils } from '@shared/utils/price/price.utils';
import { CurrencyUtils } from '@shared/utils/currency/currency.utils';

import { Paths } from '@config/paths.config';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { NavigationHistoryService } from '@core/services/navigation-history/navigation-history.service';

import { CheckTransactionSources, DoodCheckTransactionStatus } from '@core/models/check.model';
import { PaymentAtTableStoreRefiner } from '@common/refiners/payment-at-table.refiner';
import { PaymentAtTableStoreSelector } from '@common/selectors/payment-at-table.selector';
import { PaymentAtTableCheckError } from '@app/stores/payment-at-table/payment-at-table.state';
import { ShopsApiService } from '@core/services/api/shops/shops-api.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { TranslateService } from '@ngx-translate/core';
import { PatAvailabilityModalComponent } from '@shared/modals/pat-availability-modal/pat-availability-modal.component';

@Component({
  selector: 'app-pat-overview',
  templateUrl: './pat-overview-block.component.html',
})
export class PatOverviewBlockComponent extends DestroyerBase implements OnInit {
  shop$ = this.paymentAtTableSelector.selectCheckShop;
  items$ = this.paymentAtTableSelector.selectCheckItems;
  onsiteLocation$ = this.paymentAtTableSelector.selectCheckOnSiteLocation;
  checkId$ = this.paymentAtTableSelector.selectCheck.pipe(map(check => check.id));

  finalPrice$ = this.paymentAtTableSelector.selectCheckFinalPrice.pipe(
    map(PriceUtils.formatAndRoundUpPrice),
  );

  error: PaymentAtTableCheckError | null = null;
  errorMessage: string | null = null;
  checkTransactionSources = CheckTransactionSources;
  checkTransactionStatuses = DoodCheckTransactionStatus;

  error$ = this.paymentAtTableSelector.selectCheckError;
  errorMessage$ = this.paymentAtTableRefiner.selectErrorMessage;
  transactions$ = this.paymentAtTableSelector.selectCheckTransactions;

  outstandingAmount = '';
  currencySymbol!: string;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly routerHelper: RouterHelperService,
    private marketplaceSelector: MarketplaceStoreSelector,
    private paymentAtTableRefiner: PaymentAtTableStoreRefiner,
    private paymentAtTableSelector: PaymentAtTableStoreSelector,
    private readonly navigationHistoryService: NavigationHistoryService,
    private readonly shopApiService: ShopsApiService,
    private readonly modalsService: ModalsService,
    private readonly translateService: TranslateService,
  ) {
    super();

    this.paymentAtTableSelector.selectCheckError
      .pipe(takeUntil(this._destroyerRef))
      .subscribe(_error => {
        this.error = _error;
      });
    this.paymentAtTableRefiner.selectErrorMessage
      .pipe(takeUntil(this._destroyerRef))
      .subscribe(_message => {
        this.errorMessage = _message;
      });
    this.paymentAtTableSelector.selectCheckOutstandingAmount
      .pipe(takeUntil(this._destroyerRef), map(PriceUtils.formatAndRoundUpPrice))
      .subscribe(_price => {
        this.outstandingAmount = _price;
      });

    this.paymentAtTableSelector.selectCheck
      .pipe(
        takeUntil(this._destroyerRef),
        tap(check => {
          if (!check?.shop?.id || !check?.marketplace?.id) {
            return;
          }

          this.shopApiService
            .getShopById$(check?.shop?.id, check?.marketplace?.id)
            .subscribe(shop => {
              const patPlugin = shop.plugins.find(plugin => {
                return !plugin.plugin_enabled && plugin.type === 'PAY_AT_TABLE';
              });
              if (patPlugin === undefined) {
                return;
              }
              this.modalsService.open(PatAvailabilityModalComponent.handle);
              this.modalsService.setData(PatAvailabilityModalComponent.handle, {
                message: this.translateService.instant('pay-at-table.service-not-available'),
              });
            });
        }),
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.navigationHistoryService.pushStateFromActivatedRouteSnapshot(
      `${Paths.PayAtTable}/${Paths.PayAtTableOverview}`,
      this.activatedRoute.snapshot,
    );
    this.currencySymbol = CurrencyUtils.getCurrencySymbol(
      this.marketplaceSelector.marketplace.currency,
    );
  }

  goToChooseAmount(): void {
    this.router.navigate([
      this.routerHelper.translateRoute(`${Paths.PayAtTable}/${Paths.PayAtTableChooseAmount}`),
    ]);
  }

  forceType(data: any): any {
    return data as any;
  }
}
