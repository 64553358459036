<div [class.flex]="displayFlag">
  <app-header-language-flag-atom
    *ngIf="languageFlagUrl && displayFlag"
    [languageFlagUrl]="languageFlagUrl"
  ></app-header-language-flag-atom>
  <ng-container *ngIf="locales$ | async as locales">
    <select
      *ngIf="locales.length > 1"
      [(ngModel)]="selectLanguage"
      (change)="onChangeLanguage()"
      class="form-select cursor-pointer pl-4 py-2.5 block w-full bg-neutral-100 rounded-full font-base text-base font-normal"
    >
      <option
        *ngFor="let locale of locales"
        [ngValue]="locale.locale"
      >
        {{ locale.name | firstLetterUppercase }}
      </option>
    </select>
  </ng-container>
</div>
