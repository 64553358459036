import {Inject, Injectable} from '@angular/core';
import {ProductKeys} from '@config/keys/product.keys';
import {MarketplaceKeys, ShopKeys} from '@config/keys/shop.keys';
import {DoodProductModel} from '@core/models/product.model';
import {MarketplaceStoreSelector} from '@common/selectors/marketplace.selector';
import {ANALYTICS_WINDOW, AnalyticWindow} from '@shared/interfaces/window.interface';
import {DoodShopModel} from '@core/models/shop.model';
import {OrderKeys} from '@config/keys/order.keys';
import {DoodOrderModel, IOrderItem} from '@store/order/order.model';
import {AnalyticsInterface, jitsuAnalytics} from '@jitsu/js';
import {AuthStoreSelector} from '@common/selectors/authentication.selector';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {KioskService} from '@core/services/kiosk/kiosk.service';
import * as rrweb from 'rrweb';
import {v4 as uuidV4} from 'uuid';
import {OnSiteLocationStoreSelector} from '@common/selectors/on-site-location.selector';
import {environment} from '@env/environment';
import {pack} from '@rrweb/packer';

declare const dataLayer: any[] | undefined;
declare global {
  interface Window {
    doodWebappVersion?: string;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private jitsu: AnalyticsInterface = jitsuAnalytics({
    host: 'https://ingest.data.dood.com',
    writeKey: 'rPROogNjnYZ6tZi8TppxauUhw87gu08M:H5e877mTzPc94KhRVv8R6qWwz3CkPHU9',
  });

  private heartbeatFrequency = 15000;

  private sessionRecordingId = uuidV4();
  private sessionRecordingIncrement = 1;
  private sessionId: string;
  private static readonly SESSION_STORAGE_KEY = 'analytics_session';
  private static readonly SESSION_EXPIRATION = 6 * 60 * 60 * 1000; // 6 heures en millisecondes


  constructor(
    private readonly authSelector: AuthStoreSelector,
    private readonly marketplaceSelector: MarketplaceStoreSelector,
    private readonly kioskService: KioskService,
    private readonly onSiteLocationSelector: OnSiteLocationStoreSelector,
    private readonly router: Router,
    @Inject(ANALYTICS_WINDOW) private window: AnalyticWindow,
  ) {
    this.sessionId = this.getOrCreateSessionId();
    this.startSessionRecording();

    // Identify the user with Jitsu
    this.authSelector.selectUser.subscribe(user => {
      this.jitsu.identify(user?.id, {email: user?.email});
    });

    // Track page views
    this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const pageName = event.urlAfterRedirects;
        this.jitsuTrackPageView(pageName);
      });

    this.startHeartbeat();
    this.setupAppExitTracking();
  }

  private startHeartbeat(): void {
    setInterval(() => {
      this.jitsuTrackEvent('heartbeat');
    }, this.heartbeatFrequency);
  }

  private setupAppExitTracking(): void {
    window.addEventListener('beforeunload', () => {
      this.jitsuTrackEvent('app_exit');
    });
    document.addEventListener('visibilitychange', () => {
      this.jitsuTrackEvent('app_visibility_change', {state: document.visibilityState});
    });
  }

  productToItem(product: DoodProductModel, quantity: number = 1): any {
    const marketplace = this.marketplaceSelector.marketplace;
    return {
      currency: marketplace?.[MarketplaceKeys.Currency] || 'EUR',
      item_id: product[ProductKeys.Id],
      item_name: product[ProductKeys.Name],
      item_type: product[ProductKeys.Type],
      item_category: product[ProductKeys.Categories],
      item_sku:
        product[ProductKeys.ExternalId] ?? product[ProductKeys.Plu] ?? product[ProductKeys.Id],
      item_shop_id: product[ProductKeys.ShopId],
      price: product[ProductKeys.Price],
      quantity,
    };
  }

  orderItemToItem(product: IOrderItem, quantity: number = 1): any {
    const marketplace = this.marketplaceSelector.marketplace;
    return {
      currency: marketplace?.[MarketplaceKeys.Currency] || 'EUR',
      item_id: product.id,
      item_name: product.name,
      price: ((product.final_price ?? 0) / 100).toFixed(2),
      quantity,
    };
  }

  protected event(name: string, attributes: any = {}): void {
    if (this.window.dataLayer) {
      this.window.dataLayer.push({
        event: name,
        ...attributes,
      });
    }
  }

  protected clearPreviousEcommerce(): void {
    if (this.window.dataLayer) {
      this.window.dataLayer.push({
        ecommerce: null
      });
    }
  }

  trackEvent(eventName: string, attributes: any = {}): void {
    this.event(eventName, attributes);
    this.jitsuTrackEvent(eventName, attributes);
  }

  trackWebbaseInitialized(): void {
    this.trackEvent('webbase_initialized');
  }

  trackViewItem(product: DoodProductModel): void {
    this.clearPreviousEcommerce();
    this.event('view_item', {
      ecommerce: {
        items: [this.productToItem(product)]
      }
    });
    this.jitsuTrackEvent('view_item', this.productToItem(product));
  }

  trackAddToCart(product: DoodProductModel, quantity: number): void {
    this.clearPreviousEcommerce();
    this.event('add_to_cart', {
      ecommerce: {
        items: [this.productToItem(product, quantity)]
      }
    });

    this.jitsuTrackEvent('add_to_cart', this.productToItem(product, quantity));
  }

  trackPurchase(order: DoodOrderModel): void {
    this.clearPreviousEcommerce();
    this.event('purchase', {
      ecommerce: {
        transaction_id: order.id,
        value: order.total,
        currency: order.currency,
        items: order.products?.map((product: IOrderItem) => this.orderItemToItem(product, 1)),
        distribution_mode: order[OrderKeys.Type],
        shop_id: order[OrderKeys.Shop]?.[ShopKeys.Id],
        shop_name: order[OrderKeys.Shop]?.[ShopKeys.Name],
        shop_available: order[OrderKeys.Shop]?.[ShopKeys.Available],
        shop_slug: order[OrderKeys.Shop]?.[ShopKeys.Slug],
        shop_type: order[OrderKeys.Shop]?.[ShopKeys.Type]?.name,
        shop_latitude: order[OrderKeys.Shop]?.[ShopKeys.Venue]?.point?.coordinates[1],
        shop_longitude: order[OrderKeys.Shop]?.[ShopKeys.Venue]?.point?.coordinates[0],
      }
    });
    this.jitsuTrackEvent('purchase', {
      transaction_id: order.id,
      transaction_amount: order.total,
      transaction_currency: order.currency,
      transaction_items: order.products?.map((product: IOrderItem) =>
        this.orderItemToItem(product, 1),
      ),
      transaction_order_type: order[OrderKeys.Type],
      shop_id: order[OrderKeys.Shop]?.[ShopKeys.Id],
      shop_name: order[OrderKeys.Shop]?.[ShopKeys.Name],
      shop_available: order[OrderKeys.Shop]?.[ShopKeys.Available],
      shop_slug: order[OrderKeys.Shop]?.[ShopKeys.Slug],
      shop_type: order[OrderKeys.Shop]?.[ShopKeys.Type]?.name,
      shop_latitude: order[OrderKeys.Shop]?.[ShopKeys.Venue]?.point?.coordinates[1],
      shop_longitude: order[OrderKeys.Shop]?.[ShopKeys.Venue]?.point?.coordinates[0],
    });
  }

  trackViewShop(shop: DoodShopModel): void {
    this.trackEvent('view_shop', {
      shop_id: shop[ShopKeys.Id],
      shop_name: shop[ShopKeys.Name],
      shop_available: shop[ShopKeys.Available],
      shop_slug: shop[ShopKeys.Slug],
      shop_type: shop[ShopKeys.Type]?.name,
      shop_latitude: shop[ShopKeys.Venue]?.point?.coordinates[1],
      shop_longitude: shop[ShopKeys.Venue]?.point?.coordinates[0],
    });

  }

  trackSearchDeliveryAddress(
    latitude: number | undefined,
    longitude: number | undefined,
    address: string,
    isDelivereable: boolean,
  ): void {
    let payload = {
      latitude: latitude,
      longitude: longitude,
      address: address,
      is_delivereable: isDelivereable,
    };
    this.trackEvent('search_delivery_address', payload);
  }

  private getJitsuBaseAttributes(): any {
    const marketplace = this.marketplaceSelector.marketplace;
    const kioskId = this.kioskService.getKioskIdFromStore();
    const onSiteLocationId =
      this.onSiteLocationSelector.settings.id ??
      this.onSiteLocationSelector.settings.lastLocationId;
    return {
      environment: environment.name,
      app_version: window.doodWebappVersion ?? 'unknown',
      session_id: this.sessionId,
      session_recording_id: this.sessionRecordingId,
      session_recording_increment: this.sessionRecordingIncrement,
      marketplace_id: marketplace?.id,
      marketplace_name: marketplace?.name,
      organization_id: marketplace?.organization?.id,
      kiosk_id: kioskId,
      on_site_location_id: onSiteLocationId,
    };
  }

  jitsuTrackEvent(eventName: string, attributes: any = {}): void {
    let payload = {
      ...this.getJitsuBaseAttributes(),
      ...attributes,
    };
    this.jitsu.track(eventName, payload);
    // console.log('[JITSU] ' + eventName, payload);
  }

  private jitsuTrackPageView(pageName: string): void {
    this.jitsu.page(pageName, this.getJitsuBaseAttributes());
  }

  private startSessionRecording() {
    let events: any[] = [];
    rrweb.record({
      emit(event) {
        events.push(event);
      },
      sampling: {
        // do not record mouse movement
        mousemove: false,
        // set the interval of scrolling event
        scroll: 500, // do not emit twice in 150ms
        // set the interval of media interaction event
        media: 800,
        // set the timing of record input
        input: 'last', // When input mulitple characters, only record the final input
      },
      packFn: pack,
    });

    // save events every 10 seconds
    setTimeout(() => {
      if (events.length > 0) {
        this.sendRecordingEvents(events);
        events = [];
      }
    }, 2 * 1000);

    setInterval(() => {
      if (events.length > 0) {
        this.sendRecordingEvents(events);
        events = [];
      }
    }, 10 * 1000);

    window.addEventListener('beforeunload', () => {
      this.sendRecordingEvents(events);
      events = [];
    });
  }

  private sendRecordingEvents(events: any[]) {
    if (events.length === 0) return;
    let payload = {
      record_events: JSON.stringify(events),
      record_increment: this.sessionRecordingIncrement,
      ...this.getJitsuBaseAttributes(),
    };
    this.sessionRecordingIncrement++;
    console.log('[RRWEB]', {
      session: payload.session_id,
      inc: payload.record_increment,
      length: payload.record_events.length,
    });
    this.jitsu.track('rrweb-record', payload);
  }

  private getOrCreateSessionId(): string {
    const storedSession = localStorage.getItem(AnalyticsService.SESSION_STORAGE_KEY);

    if (storedSession) {
      try {
        const {id, timestamp} = JSON.parse(storedSession);
        if (Date.now() - timestamp < AnalyticsService.SESSION_EXPIRATION) {
          return id;
        }
      } catch (error) {
        console.error('Erreur de parsing du sessionId:', error);
      }
    }

    const newSessionId = uuidV4();
    this.storeSessionId(newSessionId);
    return newSessionId;
  }

  private storeSessionId(sessionId: string): void {
    const sessionData = JSON.stringify({id: sessionId, timestamp: Date.now()});
    localStorage.setItem(AnalyticsService.SESSION_STORAGE_KEY, sessionData);
  }
}
