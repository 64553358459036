@if (header) {
  <app-block-list [blockIdList]="header"></app-block-list>
}
@if (body) {
  <div class="min-h-screen w-full md:mx-auto md:max-w-md">
    <app-block-list [blockIdList]="body"></app-block-list>
    <div
      *ngIf="body?.[0]?.block"
      class="mt-24 hidden kiosk:block"
    >
      <div class="mx-auto max-w-md p-4 align-middle">
        <app-button-atom
          [size]="'L'"
          [type]="'primary'"
          (click)="submit()"
          [disabled]="!isFormValid"
          [label]="
            (isLastItem ? 'cart-funnel.confirm-my-order' : 'cart-funnel.button-continue')
              | translate
          "
        ></app-button-atom>
      </div>
    </div>
  </div>
}

<div
  *ngIf="body?.[0]?.block"
  class="fixed bottom-0 left-0 right-0 w-full bg-white shadow shadow-top md:sticky"
>
  <div class="mx-auto max-w-md p-4 align-middle">
    <app-button-atom
      [size]="'L'"
      [type]="'primary'"
      (click)="submit()"
      [disabled]="!isFormValid"
      [label]="
        (isLastItem ? 'cart-funnel.confirm-my-order' : 'cart-funnel.button-continue') | translate
      "
    ></app-button-atom>
  </div>
</div>
