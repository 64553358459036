import { map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Paths } from '@config/paths.config';

import { AuthModeValues } from '@config/values/order.values';

import { ModalsService } from '@core/services/modals/modals.service';
import { AuthFirebaseService } from '@core/services/api/auth-firebase/auth-firebase.service';

import { CartStoreSelector } from '@common/selectors/cart.selector';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';
import { AuthStoreDispatcher } from '@common/dispatchers/authentication.dispatcher';
import { AuthenticationModalComponent } from '@shared/modals/authentication-modal/authentication-modal.component';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private cartSelector: CartStoreSelector,
    private authSelector: AuthStoreSelector,
    private authDispatcher: AuthStoreDispatcher,
    private readonly modalsService: ModalsService,
    private readonly authFirebaseService: AuthFirebaseService,
  ) {}

  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (state.url) {
      this.authDispatcher.updateGuard({ requestedUrl: state.url });
    }
    return combineLatest([
      this.authFirebaseService.isAuthenticate$().pipe(
        take(1),
        map(v => !!v),
      ),
      this.authSelector.selectStatusToken.pipe(
        take(1),
        map(token => !!token),
      ),
      this.authSelector.selectUserId.pipe(take(1)),
    ]).pipe(
      map(([firebaseUser, firebaseToken, userId]) => firebaseUser && firebaseToken && userId),
      map(logged => {
        if (this.authFirebaseService.isAnonymous && state.url === `/${Paths.Account}`) {
          return false;
        }
        const active = this.cartSelector.active;
        // TODO: Merge the conditions together ?
        if (
          logged &&
          state.url === `/${Paths.Cart}` &&
          this.authFirebaseService.isAnonymous &&
          ![AuthModeValues.Anonymous, AuthModeValues.OnlyAnonymous].includes(
            active?.auth_mode as AuthModeValues,
          )
        ) {
          this.modalsService.open(AuthenticationModalComponent.handle);
          return false;
        }
        if (!logged) {
          this.modalsService.open(AuthenticationModalComponent.handle);
          return false;
        }
        return true;
      }),
    );
  }
}
