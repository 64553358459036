<div *ngIf="logo?.url">
  <img
    [src]="logo?.url"
    class="mx-auto"
    [ngClass]="{
      'w-3/12': !width || width === 'small',
      'w-6/12': width === 'medium',
      'w-9/12': width === 'large',
      'w-full': width === 'full',
      'pb-2': bottomMargin === 'small',
      'pb-5': bottomMargin === 'medium',
      'pb-7': bottomMargin === 'large',
    }"
  />
</div>
