<div class="md:container md:mx-auto px-5 mb-5">
  <app-button-atom
    class="mx-auto"
    [label]="label"
    [color]="color"
    [type]="type"
    [full]="true"
    [thickSize]="thickSize"
    [link]="url"
  ></app-button-atom>
</div>
