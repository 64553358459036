<p class="mb-2 text-center font-accent text-sm font-bold">
  {{ 'parameters.delivery-address' | translate }}
</p>
<div *ngIf="this.displayedValue; else addressInput">
  <div class="my-4 flex items-center justify-between">
    <div class="flex">
      <div class="flex items-center px-2">
        <i class="icon icon-pin"></i>
      </div>
      <div class="text-sm">
        <p>{{ displayedValue }}</p>
        @if (displayedCommentValue && firebaseToken) {
          <p class="italic text-neutral-600">{{ displayedCommentValue }}</p>
        }
      </div>
    </div>
    <div>
      <a
        class="cursor-pointer text-sm font-bold text-primary-600"
        (click)="openAddressesPanel()"
        >{{ 'parameters.edit' | translate }}</a
      >
    </div>
  </div>
</div>
<ng-template #addressInput>
  <div class="my-4">
    <app-input-text-atom
      *ngIf="!addressLoading && !!!this.addresses?.length; else loading"
      [label]="'parameters.location-placeholder' | translate"
      [iconRight]="'icon-position'"
      [value]="displayedValue ?? ''"
      [cross]="true"
      class="mb-3.5"
      (iconClick)="geolocalizeUser()"
      (valueChange)="valueChanged($event)"
    ></app-input-text-atom>
  </div>
  <app-button-atom
    type="light"
    size="S"
    thickSize="S"
    [full]="true"
    icon="icon-position"
    [label]="'parameters.geolocalize-button' | translate"
    (clickEventEmitter)="geolocalizeUser()"
  ></app-button-atom>
</ng-template>
<ng-template #loading>
  <div class="my-4 flex">
    <div class="p-2">
      <i class="icon icon-pin"></i>
    </div>
    <div class="w-full">
      <p class="mb-1.5 w-6/12 animate-pulse bg-neutral-100 py-1.5"></p>
      <p class="mb-1.5 w-4/12 animate-pulse bg-neutral-100 py-1.5"></p>
    </div>
  </div>
</ng-template>
<div
  *ngIf="geolocalizeError?.length"
  class="pt-2 font-base text-sm text-error-700"
>
  {{ geolocalizeError }}
</div>
