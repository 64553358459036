<div
  class="relative flex min-h-screen flex-col justify-center bg-neutral-800 px-5 pb-40 pt-20 text-center font-base text-base leading-5.5 text-white lg:px-0 lg:pb-20 xl:pb-40"
  [class.pt-6]="isNotched"
>
  <div class="lg:hidden">
    <div class="min-h-246px">
      <div class="mb-3">
        <app-faded-carousel-atom [items]="imageSlides ?? []"></app-faded-carousel-atom>
      </div>
      <div class="mb-3">
        <app-faded-carousel-atom [items]="textSlides ?? []"></app-faded-carousel-atom>
      </div>
      <p class="font-base">{{ leading }}</p>
    </div>
    <div class="mx-auto mb-3 mt-10 flex max-w-sm justify-center">
      <div
        *ngIf="(distributionModeItems?.length ?? 0) > 1 && (distributionModeItems?.length ?? 0) < 3"
        class="mx-auto w-full"
      >
        <app-parameters-distribution-mode-block
          [selectedDistributionMode]="selectedDistributionMode ?? ''"
          [isCartParameters]="true"
          [items]="distributionModeItems"
          (selectItem)="distributionModeChanged($event)"
        ></app-parameters-distribution-mode-block>
      </div>
      <div
        *ngIf="(distributionModeItems?.length ?? 0) >= 3"
        class="mx-auto w-full text-neutral-800"
      >
        <app-custom-select-atom
          [items]="distributionModeItems"
          [selectedItem]="selectedDistributionMode"
          (selectItem)="distributionModeChanged($event)"
        ></app-custom-select-atom>
      </div>
    </div>
    <div class="mx-auto mb-3.5 max-w-sm">
      <app-input-text-atom
        [label]="'parameters.location-placeholder' | translate"
        [iconRight]="'icon-position'"
        [buttonLabel]="'Go'"
        [value]="selectedAddress?.address ?? ''"
        [cross]="true"
        (enterPress)="goToSearchPage()"
        (buttonClick)="goToSearchPage()"
        (iconClick)="geolocalizeUser()"
        (valueChange)="resetValue()"
      ></app-input-text-atom>
    </div>
  </div>

  <div class="hidden flex-grow flex-row items-center px-32 font-accent lg:flex xl:px-40">
    <div
      class="pr-14 xl:pr-32 3xl:pr-40"
      [ngClass]="containerDevided ? 'w-6/12' : 'w-8/12'"
    >
      <h1 class="pb-7 text-left text-5.7xl font-bold leading-13">
        {{ heading }}
      </h1>
      <p class="mb-16 text-left font-base text-3xs">{{ leading }}</p>
      <div class="mb-3 flex max-w-sm justify-center">
        <div
          *ngIf="
            (distributionModeItems?.length ?? 0) > 1 && (distributionModeItems?.length ?? 0) < 3
          "
          class="mx-auto w-full"
        >
          <app-parameters-distribution-mode-block
            [selectedDistributionMode]="selectedDistributionMode ?? ''"
            [isCartParameters]="true"
            [items]="distributionModeItems"
            (selectItem)="distributionModeChanged($event)"
          ></app-parameters-distribution-mode-block>
        </div>
        <div
          *ngIf="(distributionModeItems?.length ?? 0) >= 3"
          class="mx-auto w-full text-neutral-800"
        >
          <app-custom-select-atom
            [items]="distributionModeItems"
            [selectedItem]="selectedDistributionMode"
            (selectItem)="distributionModeChanged($event)"
          ></app-custom-select-atom>
        </div>
      </div>
      <div class="mb-4 max-w-sm">
        <app-input-text-atom
          [label]="'parameters.location-placeholder' | translate"
          [iconRight]="'icon-position'"
          [buttonLabel]="'Go'"
          [value]="selectedAddress?.address ?? ''"
          [cross]="true"
          (enterPress)="goToSearchPage()"
          (buttonClick)="goToSearchPage()"
          (iconClick)="geolocalizeUser()"
          (valueChange)="resetValue()"
        ></app-input-text-atom>
        <div
          *ngIf="geolocalizeError?.length"
          class="py-6 font-base text-sm text-white"
        >
          {{ geolocalizeError }}
        </div>
      </div>
    </div>
    <div [ngClass]="containerDevided ? 'w-6/12' : 'w-4/12'">
      <app-faded-carousel-atom [items]="imageSlides ?? []"></app-faded-carousel-atom>
    </div>
  </div>
  <div class="absolute bottom-5 left-0 right-0">
    <a (click)="scrollTo()"><i class="icon icon-chevron-down cursor-pointer text-white"></i></a>
  </div>
</div>
<div id="bottom-dood-hero"></div>
