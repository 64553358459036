import { Component } from '@angular/core';

import { ShopKeys } from '@config/keys/shop.keys';
import { ShopStoreSelector } from '@common/selectors/shop.selector';

@Component({
  selector: 'app-shop-hero-simple-block',
  templateUrl: './shop-hero-simple-block.component.html',
  styleUrls: ['./shop-hero-simple-block.component.scss'],
})
export class ShopHeroSimpleBlockComponent {
  shopKeys = ShopKeys;

  shop$ = this.shopSelector.selectActive;

  constructor(private shopSelector: ShopStoreSelector) {}
}
