<ng-container *ngIf="isActive$ | async">
  <div *ngIf="title">
    <h1
      class="font-accent py-4 md:py-12 text-center font-bold text-2xl lg:text-3xl text-neutral-800"
    >
      {{ title }}
    </h1>
  </div>
  <div
    class="pt-2 md:pt-4 px-5 max-w-md mx-auto lg:max-w-full"
    [class.md:pt-32]="!title"
  >
    <app-block-list
      [blockIdList]="items"
      [injectData]="{ formId: blockId }"
    ></app-block-list>
  </div>
</ng-container>
