import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';

import {
  SELECT_AUTH_ERROR,
  SELECT_AUTH_GUARD,
  SELECT_AUTH_PERMISSIONS,
  SELECT_AUTH_STATUS,
  SELECT_AUTH_STATUS_HAS_TOKEN,
  SELECT_AUTH_STATUS_STEP,
  SELECT_AUTH_STATUS_TOKEN,
  SELECT_AUTH_STATUS_WITH_REDIRECT,
  SELECT_AUTH_USER,
  SELECT_AUTH_USER_ID,
  SELECT_AUTH_USER_IS_ANONYMOUS,
  SELECT_AUTH_USER_IS_LOADED,
  SELECT_AUTH_USER_IS_LOADING,
  SELECT_AUTH_USER_IS_LOGGED_IN,
  SELECT_AUTH_USER_META_FIELDS,
} from '@store/authentication/authentication.selection';
import {
  AuthenticationGuardState,
  AuthenticationStatusState,
  AUTH_STORE_INITIAL_STATUS_STATE,
} from '@store/authentication/authentication.state';
import { DoodUserModel } from '@store/authentication/authentication.model';

@Injectable({ providedIn: 'root' })
export class AuthStoreSelector implements OnDestroy {
  private readonly _destroyerRef = new Subject<boolean>();

  user: DoodUserModel | null = null;
  guard: AuthenticationGuardState | null = null;
  status: AuthenticationStatusState = AUTH_STORE_INITIAL_STATUS_STATE;

  // Error
  selectError = this.store.select(SELECT_AUTH_ERROR);

  // Guard
  selectGuard = this.store.select(SELECT_AUTH_GUARD);

  // Permission
  selectPermissions = this.store.select(SELECT_AUTH_PERMISSIONS);

  // User
  selectUser = this.store.select(SELECT_AUTH_USER);
  selectUserId = this.store.select(SELECT_AUTH_USER_ID);
  selectUserIsLoaded = this.store.select(SELECT_AUTH_USER_IS_LOADED);
  selectUserIsLoading = this.store.select(SELECT_AUTH_USER_IS_LOADING);
  selectUserMetafields = this.store.select(SELECT_AUTH_USER_META_FIELDS);
  selectUserIsAnonymous = this.store.select(SELECT_AUTH_USER_IS_ANONYMOUS);
  selectUserIsLoggedIn = this.store.select(SELECT_AUTH_USER_IS_LOGGED_IN);

  // Status
  selectStatus = this.store.select(SELECT_AUTH_STATUS);
  selectStatusStep = this.store.select(SELECT_AUTH_STATUS_STEP);
  selectStatusToken = this.store.select(SELECT_AUTH_STATUS_TOKEN);
  selectStatusHasToken = this.store.select(SELECT_AUTH_STATUS_HAS_TOKEN);
  selectStatusWithRedirect = this.store.select(SELECT_AUTH_STATUS_WITH_REDIRECT);

  constructor(private store: Store) {
    this.selectUser.pipe(takeUntil(this._destroyerRef)).subscribe(_user => {
      this.user = _user;
    });
    this.selectGuard.pipe(takeUntil(this._destroyerRef)).subscribe(_guard => {
      this.guard = _guard;
    });
    this.selectStatus.pipe(takeUntil(this._destroyerRef)).subscribe(_status => {
      this.status = _status;
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe();
  }

  private _unsubscribe(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
  }
}
