import { Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import { filter, switchMap, throttleTime } from 'rxjs/operators';

import { DoodCartModel } from '@core/models/cart.model';
import { MarketplaceKeys } from '@config/keys/shop.keys';

import { OrdersService } from '@core/services/orders/orders.service';
import { CartStoreSelector } from '@common/selectors/cart.selector';
import { ShopStoreSelector } from '@common/selectors/shop.selector';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

import { SuggestionsSetsApiService } from '@core/services/api/suggestions-sets/suggestions-sets-api.service';
import { MarketplaceCartScopeValues } from '@config/values/marketplace.values';

@Injectable({
  providedIn: 'root',
})
export class SuggestionsSetsService {
  constructor(
    private cartSelector: CartStoreSelector,
    private shopSelector: ShopStoreSelector,
    private readonly ordersService: OrdersService,
    private marketplaceSelector: MarketplaceStoreSelector,
    private readonly suggestionsSetsApi: SuggestionsSetsApiService,
  ) {}

  getSuggestionsSet$(suggestionsSetId: string) {
    return combineLatest([this.cartSelector.selectActive, this.shopSelector.selectActive]).pipe(
      filter(([cart, shop]) => {
        const marketplace = this.marketplaceSelector.marketplace;
        return (
          marketplace[MarketplaceKeys.CartScope] === MarketplaceCartScopeValues.Marketplace ||
          !!shop
        );
      }),
      throttleTime(2500, undefined, { leading: true, trailing: true }),
      switchMap(([cart, shop]) => {
        const marketplace = this.marketplaceSelector.marketplace;
        const order = this.ordersService.mapCartToOrderValues(cart as DoodCartModel, []);
        const context = {
          order,
          marketplace: marketplace[MarketplaceKeys.Id],
          ...(shop?.id ? { shop: shop.id } : {}),
        };
        return this.suggestionsSetsApi.getSuggestionsSet$(suggestionsSetId, context);
      }),
    );
  }
}
