import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import {
  PAT_STORE_COMPONENT_ACTIONS,
  PAT_STORE_LOCAL_ACTIONS,
} from '@store/payment-at-table/payment-at-table.action';
import {
  PaymentAtTableCheckState,
  PaymentAtTableChooseState,
} from '@store/payment-at-table/payment-at-table.state';

@Injectable({ providedIn: 'root' })
export class PaymentAtTableStoreDispatcher {
  constructor(private store: Store) {}

  // Check
  updateCheck(changes: Partial<PaymentAtTableCheckState>): void {
    this.store.dispatch(PAT_STORE_COMPONENT_ACTIONS.updateCheck({ changes }));
  }

  resetCheck(): void {
    this.store.dispatch(PAT_STORE_COMPONENT_ACTIONS.resetCheck());
  }

  // Choose
  updateChoose(changes: Partial<PaymentAtTableChooseState>): void {
    this.store.dispatch(PAT_STORE_COMPONENT_ACTIONS.updateChoose({ changes }));
  }

  resetChoose(): void {
    this.store.dispatch(PAT_STORE_COMPONENT_ACTIONS.resetChoose());
  }

  updateTip(tip: number | null): void {
    this.store.dispatch(PAT_STORE_LOCAL_ACTIONS.updateTip({ tip }));
  }

  updateAmount(amount: number): void {
    this.store.dispatch(PAT_STORE_LOCAL_ACTIONS.updateAmount({ amount }));
  }

  reset(): void {
    this.store.dispatch(PAT_STORE_LOCAL_ACTIONS.reset());
  }
}
