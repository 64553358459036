import { every } from 'lodash';
import { of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { StripeService } from 'ngx-stripe';
import { TranslateService } from '@ngx-translate/core';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import {
  PaymentRequestOptions,
  PaymentRequestPaymentMethodEvent,
  StripeCardCvcElement,
  StripeCardElementOptions,
  StripeCardExpiryElement,
  StripeCardNumberElement,
  StripeElementLocale,
  StripeElements,
  StripeElementsOptions,
  StripeError,
  StripeElementChangeEvent,
} from '@stripe/stripe-js';

import { StripeErrors } from '@config/errors.config';
import { IStripePaymentIntent } from '@core/models/payment.model';
import { CheckKeys } from '@config/keys/check.keys';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

import { PaymentService } from '@core/services/payment/payment.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';

import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { PaymentStoreDispatcher } from '@common/dispatchers/payment.dispatcher';
import { PaymentAtTableStoreSelector } from '@common/selectors/payment-at-table.selector';

interface CardElementItem {
  cardNumber: boolean;
  cardExpiry: boolean;
  cardCvc: boolean;
}

@Component({
  selector: 'app-pat-payment-panel-stripe-generic',
  templateUrl: './pat-payment-panel-stripe-generic.component.html',
  styleUrls: ['./pat-payment-panel-stripe-generic.component.scss'],
})
export class PatPaymentPanelStripeGenericComponent
  extends DestroyerBase
  implements OnInit, OnDestroy
{
  @Input() type!: string;
  @Input() payButtonText!: string;
  @Input() isPayButtonVisible = true;

  isLoading = true;

  elements?: StripeElements;
  cardNumber?: StripeCardNumberElement;
  cardExpiry?: StripeCardExpiryElement;
  cardCvc?: StripeCardCvcElement;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        fontSize: '18px',
        color: '#31325F',
        backgroundColor: '#F9FAFB',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  };

  paymentRequestOptions!: PaymentRequestOptions;

  private paymentIntent?: IStripePaymentIntent;

  private elementsOptions: StripeElementsOptions = {
    locale: this.settingsSelector.app.locale as StripeElementLocale,
  };

  private cardElementsValid: CardElementItem = {
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  };

  private redirectUrl: string | null | undefined;
  check = this.paymentAtTableSelector.check;

  constructor(
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly stripeService: StripeService,
    private settingsSelector: SettingsStoreSelector,
    private readonly paymentService: PaymentService,
    private paymentDispatcher: PaymentStoreDispatcher,
    private readonly routerHelper: RouterHelperService,
    private marketplaceSelector: MarketplaceStoreSelector,
    private paymentAtTableSelector: PaymentAtTableStoreSelector,
  ) {
    super();
    this.paymentAtTableSelector.selectCheck
      .pipe(takeUntil(this._destroyerRef))
      .subscribe(_check => {
        this.check = _check;
      });
  }

  ngOnInit(): void {
    this.getPaymentIntent();
    this.getRedirectUrl();
    this.mapCheckToPaymentRequestOptions();
  }

  ngOnDestroy(): void {
    this.paymentDispatcher.resetUI();
    this.paymentService.paymentIntent$.next(null);
    this._destroyerRef.next(true);
    this._destroyerRef.complete();

    if (this.cardNumber) {
      this.cardNumber.unmount();
      this.cardNumber.destroy();
    }
  }

  mountStripeElement(elements: StripeElements): void {
    this.elements = elements;
    if (!this.cardNumber || !this.cardExpiry || !this.cardCvc) {
      this.cardNumber = this.elements.create('cardNumber', this.cardOptions);
      this.cardNumber.mount('#card-number-element');
      this.cardExpiry = this.elements.create('cardExpiry', this.cardOptions);
      this.cardExpiry.mount('#card-expiry-element');
      this.cardCvc = this.elements.create('cardCvc', this.cardOptions);
      this.cardCvc.mount('#card-cvc-element');
    }

    this.cardNumber.on('change', event => this.onPayFormChanged('cardNumber', event));
    this.cardExpiry.on('change', event => this.onPayFormChanged('cardExpiry', event));
    this.cardCvc.on('change', event => this.onPayFormChanged('cardCvc', event));
  }

  onPayFormLoaded(): void {
    this.listenPayButton();

    this.paymentDispatcher.updateUI({
      error: null,
      button: {
        isEnabled: false,
      },
      status: {
        isLoading: false,
        isInProgress: false,
      },
    });

    this.isLoading = false;
  }

  handlePayment(): void {
    if (!this.paymentIntent || !this.cardNumber) {
      return;
    }

    this.paymentDispatcher.updateUI({
      error: null,
      button: {
        isEnabled: true,
      },
      status: {
        isInProgress: true,
      },
    });

    if (this.paymentIntent.payment_intent_client_secret) {
      this.stripeService
        .confirmCardPayment(this.paymentIntent.payment_intent_client_secret, {
          payment_method: {
            card: this.cardNumber,
          },
        })
        .pipe(
          tap(result => {
            if (result.error || !result.paymentIntent) {
              this.handlePaymentError(result.error);
              return;
            }

            this.paymentDispatcher.updateUI({
              error: null,
              button: {
                isEnabled: false,
              },
              status: {
                isLoading: false,
                isInProgress: false,
                isRetryInProgress: false,
              },
            });
            this.handleSuccessPayment();
          }),
        )
        .subscribe();
    }
  }

  private handleSuccessPayment(): void {
    this.router.navigate([
      this.routerHelper.translateRoute(!!this.redirectUrl ? `${this.redirectUrl}` : '/'),
    ]);
  }

  private getPaymentIntent(): void {
    this.paymentService.paymentIntent$.pipe(takeUntil(this._destroyerRef)).subscribe(intent => {
      if (intent) {
        this.initializeStripeElement(intent);
      }
    });
  }

  private initializeStripeElement(paymentIntent: IStripePaymentIntent): void {
    this.paymentIntent = paymentIntent;

    if (this.type !== 'STRIPE_APPLE_PAY' && this.type !== 'STRIPE_GOOGLE_PAY') {
      this.stripeService
        .elements(this.elementsOptions)
        .pipe(
          takeUntil(this._destroyerRef),
          take(1),
          tap(elements => this.mountStripeElement(elements)),
          tap(elements => this.onPayFormLoaded()),
        )
        .subscribe();
    }
  }

  private onPayFormChanged(
    inputName: keyof CardElementItem,
    event: StripeElementChangeEvent,
  ): void {
    this.cardElementsValid[inputName] = event.complete;

    this.paymentDispatcher.updateUI({
      button: {
        isEnabled: every(this.cardElementsValid, item => item),
      },
    });
  }

  private listenPayButton(): void {
    this.paymentService.pay$
      .pipe(
        takeUntil(this._destroyerRef),
        take(1),
        tap(() => {
          this.handlePayment();
        }),
      )
      .subscribe();
  }

  private getRedirectUrl(): void {
    this.paymentService.redirectUrl$
      .pipe(takeUntil(this._destroyerRef))
      .subscribe(redirectUrl => (this.redirectUrl = redirectUrl));
  }

  private handlePaymentError(error?: StripeError): void {
    const isConnectionError = error?.type === StripeErrors.ApiConnectionError;
    const errorMessage = error?.message ?? this.translate.instant('payment.unknown-error');

    this.paymentDispatcher.updateUI({
      error: errorMessage,
      button: {
        isEnabled: isConnectionError,
      },
      status: {
        isLoading: false,
        isInProgress: false,
        isRetryInProgress: isConnectionError,
      },
    });
  }

  onPaymentMethod(_event: PaymentRequestPaymentMethodEvent): void {
    if (this.paymentIntent && this.paymentIntent.payment_intent_client_secret) {
      this.stripeService
        .confirmCardPayment(
          this.paymentIntent.payment_intent_client_secret,
          { payment_method: _event.paymentMethod.id },
          { handleActions: false },
        )
        .pipe(
          switchMap(confirmResult => {
            if (confirmResult.error) {
              _event.complete('fail');
              return throwError(() => confirmResult.error);
            }

            this.paymentDispatcher.updateUI({
              error: null,
              button: {
                isEnabled: false,
              },
              status: {
                isInProgress: true,
              },
            });

            _event.complete('success');

            if (confirmResult.paymentIntent) {
              if (
                ['requires_action', 'requires_source_action'].includes(
                  confirmResult.paymentIntent.status,
                )
              ) {
                if (this.paymentIntent!.payment_intent_client_secret) {
                  return this.stripeService.confirmCardPayment(
                    this.paymentIntent!.payment_intent_client_secret,
                  );
                }
              }
            }

            return of(confirmResult);
          }),
          takeUntil(this._destroyerRef),
          take(1),
        )
        .subscribe(
          () => this.handleSuccessPayment(),
          error => this.handlePaymentError(error),
          () => {
            this.paymentDispatcher.updateUI({
              error: null,
              button: {
                isEnabled: false,
              },
              status: {
                isInProgress: false,
              },
            });
          },
        );
    }
  }

  private mapCheckToPaymentRequestOptions(): void {
    if (this.type !== 'STRIPE_APPLE_PAY' && this.type !== 'STRIPE_GOOGLE_PAY') {
      return;
    }

    this.paymentAtTableSelector.selectChoose.pipe(take(1)).subscribe(({ amount, tip }) => {
      const _amount = amount + (tip ?? 0);

      this.paymentRequestOptions = {
        country: this.marketplaceSelector.marketplace.country?.toUpperCase() || 'FR',
        currency: this.check[CheckKeys.Currency]?.toLocaleLowerCase() as string,
        total: {
          label: 'TOTAL',
          amount: _amount,
        },
        requestPayerName: false,
        requestPayerEmail: false,
        disableWallets: ['browserCard'],
      };
    });
  }

  onNotAvailable(): void {
    this.paymentDispatcher.updateError('payment.payment-mode-unavailable');
  }
}
