import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DOOD_API } from '@config/ws.config';

@Injectable({
  providedIn: 'root',
})
export class OrderPaymentsApiService {
  constructor(private client: HttpClient) {}

  public createPaymentIntent$<T>(orderId: string): Observable<T> {
    return this.client.post<T>(`${DOOD_API}/orders/${orderId}/payments`, {});
  }

  public createTransactionPaymentIntent$<T>(id: string): Observable<T> {
    return this.client.post<T>(`${DOOD_API}/transactions/${id}/payments`, {});
  }
}
