<h1 class="text-neutral-800 font-brand font-bold text-28px pb-5">
  {{ 'como.create-or-link-account.registration-step.title' | translate }}
</h1>
<form
  [formGroup]="form"
  class="flex flex-col gap-8 pb-4"
>
  <div class="flex items-start">
    <input
      id="legals"
      type="checkbox"
      formControlName="legals"
      class="m-3 form-checkbox h-6 w-6 border text-primary-600"
    />
    <label
      for="legals"
      class="mt-3 font-base text-sm leading-5 text-neutral-800"
    >
      <div [innerHTML]="'como.create-account.legals' | translate | safeHtml"></div>
      &nbsp;
    </label>
  </div>
  <div *ngIf="inputDateOfBirthRule === 'REQUIRED' || inputDateOfBirthRule === 'OPTIONAL'">
    <label
      for="dateOfBirth"
      class="block mb-2 text-xs font-base font-normal text-neutral-500"
    >
      {{ 'como.create-account.date-of-birth-label' | translate }}
    </label>
    <input
      id="dateOfBirth"
      type="date"
      formControlName="dateOfBirth"
      class="flex w-full py-8xs px-5 bg-neutral-50 text-base font-base font-normal leading-5 border border-solid border-neutral-300"
    />
  </div>
</form>

<div class="flex flex-col gap-2">
  <div
    *ngIf="errorMessage"
    class="self-center"
  >
    <span
      class="text-xs font-base font-normal text-error-700"
      [translate]="errorMessage"
    ></span>
  </div>
  <div class="flex flex-col gap-8">
    <div class="w-full">
      <app-button-atom
        [iconUrl]="isValidating ? 'assets/images/spinner.svg' : ''"
        [disabled]="isValidating || this.form.invalid"
        size="M"
        [label]="'como.validate' | translate"
        type="primary"
        [full]="true"
        (click)="onSubmit()"
      ></app-button-atom>
    </div>
    <div class="w-full">
      <app-button-atom
        [label]="
          'como.create-or-link-account.identification-step.continue-without-loyalty-button'
            | translate
        "
        [full]="true"
        (click)="onContinueWithoutLoyalty()"
        size="M"
        type="light"
      ></app-button-atom>
    </div>
  </div>
</div>
