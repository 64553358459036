import {Component, Input} from '@angular/core';
import {IContentBuilderFieldColor} from "@core/models/content-builder-fields.model";
import {ColorFieldTypesValues} from "@config/values/color-field-types.values";
import {Router} from "@angular/router";

@Component({
  selector: 'app-simple-header-text-item-block',
  templateUrl: './simple-header-text-item-block.component.html',
  styles: []
})
export class SimpleHeaderTextItemBlockComponent {

  @Input() text?: string;
  @Input() linkUrl?: string;
  @Input() linkTarget = '_self';
  @Input() textColor?: IContentBuilderFieldColor = {
    value: 'primary-600',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() activeTextColor?: IContentBuilderFieldColor = {
    value: 'primary-800',
    type: ColorFieldTypesValues.Palette,
  };

  constructor(private router: Router) {
  }

  isCurrentRoute(linkUrl?: string): boolean {
    if (!linkUrl) return false;

    const currentUrl = this.stripLanguageCode(this.router.url);
    const targetUrl = this.stripLanguageCode(linkUrl);
    return currentUrl === targetUrl;
  }

  /**
   * Supprime le code de langue à deux lettres au début de l'URL.
   */
  private stripLanguageCode(url: string): string {
    return url.replace(/^\/[a-z]{2}(\/|$)/i, '/');
  }
}
