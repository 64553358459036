import { Component, OnDestroy } from '@angular/core';
import { PaymentService } from '@core/services/payment/payment.service';

@Component({
  selector: 'app-pat-payment-panel-stripe-googlepay',
  templateUrl: './pat-payment-panel-stripe-googlepay.component.html',
})
export class PatPaymentPanelStripeGooglepayComponent implements OnDestroy {
  constructor(private readonly paymentService: PaymentService) {}

  ngOnDestroy(): void {
    this.paymentService.paymentIntent$.next(null);
  }
}
