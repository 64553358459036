<app-modal-filler-atom
  (click)="close()"
  [index]="(index$ | async) ?? 0"
></app-modal-filler-atom>
<div
  class="fixed left-0 right-0 overflow-y-auto shadow md:left-0 md:top-0 md:flex md:h-full md:w-full md:items-center md:justify-center mdMax:bottom-0 mdMax:mx-auto mdMax:max-h-85-screen mdMax:max-w-screen-sm"
  [style.z-index]="((index$ | async) ?? 0) + 50"
>
  <app-modal-filler-atom
    *ngIf="isDesktop$ | async"
    [withBackdrop]="false"
    (click)="close()"
    [index]="(index$ | async) ?? 0"
  ></app-modal-filler-atom>
  <div
    class="flex flex-col rounded-t-3xl bg-white p-5 md:max-w-lg md:rounded-3xl md:p-7"
    [style.z-index]="((index$ | async) ?? 0) + 50"
  >
    <div class="flex items-center justify-between pb-5">
      <h1
        class="font-brand text-28px font-bold text-neutral-800"
        p
        translate="zerosix.loyalty-program"
      ></h1>
      <a
        class="cursor-pointer"
        (click)="close()"
        ><i class="icon icon-cross"></i
      ></a>
    </div>
    <ng-container *ngIf="!this.isLoading && !this.accountExists">
      <div class="pb-7">
        <p class="pb-3 font-base text-base text-neutral-800">
          {{ 'zerosix.want-to-join-loyalty-program' | translate }}
        </p>
        <app-button-atom
          [type]="'primary'"
          [label]="'zerosix.create-loyalty-account' | translate"
          [size]="'L'"
          (click)="openCreateLoyaltyAccountModal()"
        ></app-button-atom>
      </div>
      <div class="pb-6">
        <p class="pb-3 font-base text-base text-neutral-800">
          {{ 'zerosix.already-member' | translate }}
        </p>
        <app-button-atom
          [type]="'primary'"
          [label]="'zerosix.connect-with-loyalty-account' | translate"
          [size]="'L'"
          (click)="openLinkWithLoyaltyAccountModal()"
        ></app-button-atom>
      </div>
      <div class="flex items-center pb-8">
        <div class="h-px w-full border border-neutral-400"></div>
        <span class="inline-block px-3 font-base text-base font-bold text-neutral-500">{{
          'divider.or' | translate
        }}</span>
        <div class="h-px w-full border border-neutral-400"></div>
      </div>
      <div class="pb-3">
        <app-button-atom
          [type]="'light'"
          [label]="'zerosix.continue-without-loyalty-account' | translate"
          [size]="'L'"
          (click)="close()"
        ></app-button-atom>
      </div>
    </ng-container>
    <ng-container *ngIf="!this.isLoading && this.accountExists">
      <div class="pb-7">
        <p class="pb-3 font-base text-base text-neutral-800">
          {{ 'zerosix.already-member-information' | translate }}
        </p>
      </div>
      <div class="pb-3">
        <app-button-atom
          [type]="'light'"
          [label]="'zerosix.cancel' | translate"
          [size]="'L'"
          (click)="close()"
        ></app-button-atom>
      </div>
    </ng-container>
    <ng-container *ngIf="this.isLoading">
      <div class="flex h-48 w-full flex-row items-center justify-center">
        <img
          src="assets/images/spinner.svg"
          class="pr-4"
        />
      </div>
    </ng-container>
  </div>
</div>
