import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {IContentBuilderFieldColor, IContentBuilderFieldImage,} from '@core/models/content-builder-fields.model';
import {RouterHelperService} from '@core/services/router-helper/router-helper.service';
import {ShopSearchParametersService} from '@core/services/shop-search-parameters/shop-search-parameters.service';
import {MarketplaceStoreSelector} from '@common/selectors/marketplace.selector';
import {TimeSlotsUtils} from '@shared/utils/time-slots/time-slots.utils';
import {DoodWantedAtType} from '@store/settings/settings.model';
import {OrderTypeService} from '@core/services/order-type/order-type.service';
import {DateUtils} from '@shared/utils/date/date.utils';
import {DistributionModeKeys} from '@config/keys/shop.keys';
import {DaySlotsUtils} from '@shared/utils/day-slots/day-slots.utils';
import {TranslateService} from '@ngx-translate/core';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import {SettingsParametersState} from '@store/settings';
import {ColorFieldTypesValues} from '@config/values/color-field-types.values';
import {takeUntil, tap} from "rxjs/operators";
import {DestroyerBase} from "@core/base/destroyer/destroyer.base";
import {ISimpleItem} from "@core/models/simple-item.model";
import {ParametersUtils} from "@shared/utils/parameters/parameters.utils";
import {IDistributionMode} from "@core/models/shop.model";

@Component({
  selector: 'app-kiosk-distribution-mode-picker-page',
  templateUrl: './kiosk-distribution-mode-picker-page.component.html',
  styleUrls: ['./kiosk-distribution-mode-picker-page.component.scss'],
})
export class KioskDistributionModePickerPageComponent extends DestroyerBase implements OnInit {
  @Input() body: any;
  @Input() header?: unknown[];
  @Input() title = 'kiosk-distribution-mode-picker.heading';
  @Input() linkUrl!: string;
  @Input() backgroundColor?: IContentBuilderFieldColor;
  @Input() backgroundImage?: IContentBuilderFieldImage;
  @Input() textColor?: IContentBuilderFieldColor;
  @Input() accentColor?: IContentBuilderFieldColor;

  @Input() activeCardBackgroundColor: IContentBuilderFieldColor = {
    value: 'primary-800',
    type: ColorFieldTypesValues.Hex,
  };
  @Input() activeCardTextColor: IContentBuilderFieldColor = {
    value: '#FFFFFF',
    type: ColorFieldTypesValues.Hex,
  };
  @Input() inactiveCardBackgroundColor: IContentBuilderFieldColor = {
    value: 'transparent',
    type: ColorFieldTypesValues.Hex,
  };
  @Input() inactiveCardTextColor: IContentBuilderFieldColor = {
    value: 'primary-800',
    type: ColorFieldTypesValues.Palette,
  };

  @Input() primaryButtonBackgroundColor!: IContentBuilderFieldColor;
  @Input() primaryButtonTextColor!: IContentBuilderFieldColor;
  @Input() secondaryButtonBackgroundColor!: IContentBuilderFieldColor;
  @Input() secondaryButtonTextColor!: IContentBuilderFieldColor;

  @HostBinding('class') classes = 'h-screen flex text-center';

  availableDistributionModeItems: ISimpleItem[] = [];
  availableDistributionMode: IDistributionMode[] = [];
  availableDatesSlots?: { value: Date; label: string }[];
  availableTimesSlots?: { value: number; label: string; slot: { start: Date; end: Date } }[];
  selectedDateSlot?: Date | null;
  selectedTimeSlot?: Date | null;
  selectedDistributionMode: string | null = null;
  selectedWantedAtType: DoodWantedAtType | null = null;

  private intervalMinuteTimeSlot = 15;

  constructor(
    private readonly router: Router,
    private readonly helperRouter: RouterHelperService,
    private readonly shopSearchParametersService: ShopSearchParametersService,
    private readonly marketplaceSelector: MarketplaceStoreSelector,
    private readonly orderTypeService: OrderTypeService,
    private readonly translateService: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void {
    dayjs.extend(isTomorrow);
    dayjs.extend(isToday);
    const savedParameters = this.shopSearchParametersService.getParameters();

    this.selectedDistributionMode = savedParameters.distribution_mode;

    this.marketplaceSelector.selectMarketplaceDistributionModes
      .pipe(
        takeUntil(this._destroyerRef),
        tap(distributionModes => {
          this.availableDistributionMode = distributionModes;
          this.availableDistributionModeItems = ParametersUtils.mapDistributionModes(distributionModes)
        })
      ).subscribe();

    let hour = 0;
    let minute = 0;

    let now = DateUtils.dayjsInMarketplaceTimezone();
    const preOrderingDelays = this.orderTypeService.getPreOrderDelays(
      this.selectedDistributionMode,
    );

    this.availableTimesSlots = TimeSlotsUtils.generateTimeSlots({
      day: this.selectedDateSlot ?? new Date(),
      start: hour,
      startWithMinute: minute,
      end: 24,
      interval: this.intervalMinuteTimeSlot,
      minDate: now.add(
        preOrderingDelays[DistributionModeKeys.PreorderingMinDelay],
        'seconds',
      ),
      maxDate: now.add(
        preOrderingDelays[DistributionModeKeys.PreorderingMaxDelay],
        'seconds',
      ),
    }).map(slot => {
      return {
        value: slot.id,
        label: `${DateUtils.dayjsInMarketplaceTimezone(slot.start).format(this.translateService.instant('parameters.wantedAt.slots-format'))} - ${DateUtils.dayjsInMarketplaceTimezone(slot.end).format(this.translateService.instant('parameters.wantedAt.slots-format'))}`,
        slot: {
          start: new Date(DateUtils.dayjsInMarketplaceTimezone(slot.start).format()),
          end: new Date(DateUtils.dayjsInMarketplaceTimezone(slot.end).format()),
        },
      };
    });
    this.selectedTimeSlot = this.availableTimesSlots[0].slot.start;

    this.updateDisponibleDateSlots();
    this.onWantedAtTypeChanged(this.selectedWantedAtType ?? DoodWantedAtType.asap);
  }

  onWantedAtTypeChanged(wantedAtType: DoodWantedAtType): void {
    this.selectedWantedAtType = wantedAtType;
    this.shopSearchParametersService.set({
      wanted_at_type: wantedAtType,
    });

    if (wantedAtType === DoodWantedAtType.later) {
      this.updateDisponibleDateSlots();
    } else {
      this.selectedDateSlot = null;
    }
  }

  private updateDisponibleDateSlots(): void {
    const preOrderingDelays = this.orderTypeService.getPreOrderDelays(
      this.selectedDistributionMode ?? undefined,
    );

    const minDate = DateUtils.dayjsInMarketplaceTimezone()
      .add(preOrderingDelays[DistributionModeKeys.PreorderingMinDelay], 'seconds')
      .startOf('day');
    const maxDate = DateUtils.dayjsInMarketplaceTimezone()
      .add(preOrderingDelays[DistributionModeKeys.PreorderingMaxDelay], 'seconds')
      .startOf('day');

    const today = DateUtils.dayjsInMarketplaceTimezone().startOf('day');
    const minDays = minDate.diff(today, 'days');
    const maxDays = maxDate.diff(today, 'days');
    this.availableDatesSlots = DaySlotsUtils.generateDaySlots({
      interval: 1,
      minDays: minDays,
      maxDays: maxDays,
    }).map((date: Date) => {
      return {
        value: date,
        label: DateUtils.dayjsInMarketplaceTimezone(date).isToday()
          ? this.translateService.instant('parameters.wantedAt.today')
          : DateUtils.dayjsInMarketplaceTimezone(date).isTomorrow()
            ? this.translateService.instant('parameters.wantedAt.tomorrow')
            : DateUtils.dayjsInMarketplaceTimezone(date).format('dddd D MMMM, YYYY'),
      };
    });

    this.selectedDateSlot = this.availableDatesSlots[0].value;
  }

  getBackgroundStyles() {
    return {
      'background-color': this.backgroundColor ? this.backgroundColor : null,
    };
  }

  canValidate() {
    return this.selectedDistributionMode != null;
  }

  selectDistributionMode(distributionModeItem: ISimpleItem) {
    this.selectedDistributionMode = distributionModeItem.value;

    if (this.preorderingAllowed()) {
      this.selectedWantedAtType = DoodWantedAtType.asap;
    } else {
      this.selectedWantedAtType = null;
    }
  }

  navigateto(link: string, set?: boolean) {
    if (set && this.selectedDistributionMode) {
      this.selectedDateSlot?.setHours(this.selectedTimeSlot?.getHours() ?? 0);

      this.shopSearchParametersService.setDistributionModeType(this.selectedDistributionMode, true);
      const toSave: Partial<SettingsParametersState> = {
        is_distribution_mode_defined: true,
        distribution_mode: this.selectedDistributionMode,
      };

      if (this.preorderingAllowed() && this.selectedWantedAtType === DoodWantedAtType.later) {
        toSave.wanted_at = this.selectedDateSlot;
      } else {
        toSave.wanted_at = null;
      }

      this.shopSearchParametersService.set(toSave);
    }
    this.router.navigate([this.helperRouter.translateRoute(link)]);
  }

  preorderingAllowed() {
    let distributionMode = this.availableDistributionMode.find(
      distributionMode => distributionMode[DistributionModeKeys.Type] === this.selectedDistributionMode,
    );

    if (!distributionMode || !distributionMode.preordering_allowed) {
      return false;
    }
    return true;
  }

  changeDate() {
    let hour = 0;
    let minute = 0;

    let now = DateUtils.dayjsInMarketplaceTimezone();
    const preOrderingDelays = this.orderTypeService.getPreOrderDelays(
      this.selectedDistributionMode ?? undefined,
    );

    this.availableTimesSlots = TimeSlotsUtils.generateTimeSlots({
      day: this.selectedDateSlot ?? new Date(),
      start: hour,
      startWithMinute: minute,
      end: 24,
      interval: this.intervalMinuteTimeSlot,
      minDate: now.add(
        preOrderingDelays[DistributionModeKeys.PreorderingMinDelay],
        'seconds',
      ),
      maxDate: now.add(
        preOrderingDelays[DistributionModeKeys.PreorderingMaxDelay],
        'seconds',
      ),
    }).map(slot => {
      return {
        value: slot.id,
        label: `${DateUtils.dayjsInMarketplaceTimezone(slot.start).format(this.translateService.instant('parameters.wantedAt.slots-format'))} - ${DateUtils.dayjsInMarketplaceTimezone(slot.end).format(this.translateService.instant('parameters.wantedAt.slots-format'))}`,
        slot: {
          start: new Date(DateUtils.dayjsInMarketplaceTimezone(slot.start).format()),
          end: new Date(DateUtils.dayjsInMarketplaceTimezone(slot.end).format()),
        },
      };
    });
    this.selectedTimeSlot = this.availableTimesSlots.find(
      timeSlot => timeSlot.slot.start.getHours() === this.selectedTimeSlot?.getHours(),
    )?.slot.start;

    if (!this.selectedTimeSlot) {
      this.selectedTimeSlot = this.availableTimesSlots[0].slot.start;
    }
  }
}
