import { timer } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { DoodShopModel } from '@core/models/shop.model';
import { ShopsService } from '@core/services/shops/shops.service';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { DistanceUtils } from '@shared/utils/distance/distance.utils';
import { CartStoreSelector } from '@common/selectors/cart.selector';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

export interface ILink {
  label: string;
  url: string;
}

@Component({
  selector: 'app-shop-informations-atom',
  templateUrl: './shop-informations-atom.component.html',
})
export class ShopInformationsAtomComponent extends DestroyerBase implements OnInit {
  @Input() shop!: DoodShopModel;
  @Input() name!: string;
  @Input() titleSize: 'XS' | 'S' | 'M' | 'L' = 'M';
  @Input() titleFont: 'accent' | 'base' | 'brand' = 'accent';
  @Input() isFavorite?: boolean;
  @Input() category?: string;
  @Input() stars?: string;
  @Input() address?: string;
  @Input() postcode?: string;
  @Input() city?: string;
  @Input() distance?: number;
  @Input() links?: ILink[];
  @Input() displayCategory = true;
  @Input() displayAddress = true;
  @Input() displayMoreInfoButton = true;
  @Input() displayGroupedOrderButton = true;
  @Input() displayImage = true;
  @Input() shortDescription?: string;
  @Input() isPreorderingAllowed?: boolean;
  @Input() displayBookButton = false;
  @Input() bookButtonLabelOverride?: string;
  @Input() basketId!: string;
  @Input() imageSrc?: string;
  @Input() contactEmail?: string;
  @Input() phone?: string;
  @Input() shopNameColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() textColor: IContentBuilderFieldColor = {
    value: 'neutral-600',
    type: ColorFieldTypesValues.Palette,
  };

  @Output() showMoreClick = new EventEmitter();
  @Output() groupOrderClick = new EventEmitter();
  @Output() bookButtonClick = new EventEmitter();
  @Output() clickLink = new EventEmitter<ILink>();
  @Output() favoriteChange = new EventEmitter<boolean>();
  @Output() openParameters = new EventEmitter();

  closedLabel?: string;
  formattedDistance!: string;

  constructor(
    private readonly shopsService: ShopsService,
    private readonly cartStoreSelector: CartStoreSelector,
  ) {
    super();
  }

  onFavoriteClick(): void {
    this.favoriteChange.emit(!this.isFavorite);
  }

  ngOnInit(): void {
    if (this.distance) {
      this.formattedDistance = DistanceUtils.formatDistance(this.distance);
    }

    this.updateClosedLabel();
  }

  openCartParameters(): void {
    this.openParameters.emit();
  }

  updateClosedLabel(): void {
    timer(1, 1000)
      .pipe(
        takeUntil(this._destroyerRef),
        tap(message => {
          const orderType = this.cartStoreSelector.active?.type;
          this.closedLabel = this.shopsService.getClosedMessage(this.shop, null, orderType);
        }),
      )
      .subscribe();
  }
}
