import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-block-list',
  templateUrl: './block-list.component.html',
  styleUrls: ['./block-list.component.scss'],
})
export class BlockListComponent implements OnInit, OnDestroy {
  @Input() injectData = {};

  @Input() blockIdList: unknown[] = [];

  isVisibleChanged = new EventEmitter<{ index: number | string; isVisible: boolean }>();

  blockVisibility: { [_index: number | string]: boolean } = {};

  private destroyed$ = new Subject<boolean>();

  ngOnInit(): void {
    this.isVisibleChanged
      .pipe(
        takeUntil(this.destroyed$),
        tap(value => {
          this.blockVisibility[value.index] = value.isVisible;
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  forceType(value: any): any {
    return value as any;
  }
}
