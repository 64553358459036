<div
  class="container mx-auto max-w-640 pb-5"
  *ngIf="iframeSrc"
>
  <iframe
    class="w-full video-player"
    [src]="iframeSrc"
    frameborder="0"
    allowfullscreen
  ></iframe>
</div>
