import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-heading-content-block',
  templateUrl: './heading-content-block.component.html',
})
export class HeadingContentBlockComponent {
  @Input() text!: string;
  @Input() heading!: string;
}
