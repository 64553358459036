import { createReducer } from '@ngrx/store';

import { ANCHOR_STORE_INITIAL_STATE } from './anchor.state';
import { ANCHOR_STORE_API_PIECE, ANCHOR_STORE_LOCAL_PIECE } from './anchor.piece';

export const ANCHOR_STORE_REDUCER = createReducer(
  ANCHOR_STORE_INITIAL_STATE,
  ...ANCHOR_STORE_API_PIECE,
  ...ANCHOR_STORE_LOCAL_PIECE,
);
