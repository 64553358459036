import { map, take } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';

import { ISimpleItem } from '@core/models/simple-item.model';
import { ShopStoreRefiner } from '@common/refiners/shop.refiner';
import { DistributionModeValues } from '@config/values/order.values';
import { DistributionModeKeys, MarketplaceKeys } from '@config/keys/shop.keys';

import { CartStoreSelector } from '@common/selectors/cart.selector';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

import { CartParametersService } from '@core/services/cart-parameters/cart-parameters.service';
import { NavigationHistoryService } from '@core/services/navigation-history/navigation-history.service';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-cart-header-block',
  templateUrl: './cart-header-block.component.html',
})
export class CartHeaderBlockComponent implements OnInit {
  @Input() textColor: IContentBuilderFieldColor = {
    value: 'neutral-900',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() backgroundColor: IContentBuilderFieldColor = {
    value: '#FFFFFF',
    type: ColorFieldTypesValues.Hex,
  };
  @Input() labelColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() iconColor: IContentBuilderFieldColor = {
    value: 'primary-600',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() iconBackgroundColor: IContentBuilderFieldColor = {
    value: 'primary-100',
    type: ColorFieldTypesValues.Palette,
  };

  parameters!: ISimpleItem[];

  hideCartParameters$: Observable<boolean> = combineLatest([
    this.shopRefiner.selectActiveShopEnabledDistributionMode,
    this.marketplaceSelector.selectMarketplace,
  ]).pipe(
    map(([shopDistributionModes, marketplace]) => {
      const isMutiShop = marketplace[MarketplaceKeys.CartScope] === 'MARKETPLACE';

      const distributionModes = isMutiShop
        ? marketplace[MarketplaceKeys.DistributionModes]?.filter(
            mode => mode?.[DistributionModeKeys.Enabled],
          )
        : shopDistributionModes;

      return (
        distributionModes?.length === 1 &&
        distributionModes[0][DistributionModeKeys.Type] === DistributionModeValues.EatIn
      );
    }),
  );

  constructor(
    private shopRefiner: ShopStoreRefiner,
    private cartSelector: CartStoreSelector,
    private marketplaceSelector: MarketplaceStoreSelector,
    private readonly cartParametersService: CartParametersService,
    private readonly navigationHistoryService: NavigationHistoryService,
  ) {}

  ngOnInit(): void {
    this.cartSelector.selectActive
      .pipe(
        take(1),
        map(cart => {
          if (cart) {
            this.parameters = this.cartParametersService.retrieveCartParametersValue(cart);
          }
        }),
      )
      .subscribe();
  }

  back(): void {
    this.navigationHistoryService.goBack();
  }
}
