import { ChangeDetectionStrategy, Component, inject, input, OnInit } from '@angular/core';
import { KeyboardListenerDirective } from '@core/directives/keyboard-listener.directive';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ModalsService } from '@core/services/modals/modals.service';
import { TranslateService } from '@ngx-translate/core';
import { ComoRegistrationModalComponent } from '@shared/modals/como/como-registration-modal/como-registration-modal.component';
import { RegistrationStep } from '@shared/modals/como/como-registration-modal/registration-step.enum';
import { KioskAlertModalComponent } from '@shared/modals/kiosk-alert-modal/kiosk-alert-modal.component';
import { filter, switchMap, tap } from 'rxjs/operators';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';
import { ComoSignOn } from '@core/models/como.model';
import { Observable, of } from 'rxjs';
import { ComoSsoLoadingModalComponent } from '@shared/modals/como/como-sso-loading-modal/como-sso-loading-modal.component';
import { AuthFirebaseService } from '@core/services/api/auth-firebase/auth-firebase.service';
import { ComoApiService } from '@core/services/api/como/como-api.service';
import { MarketplaceKeys } from '@config/keys/shop.keys';

@Component({
  selector: 'app-kiosk-code-scanner-block',
  imports: [KeyboardListenerDirective],
  template: `
    <input
      class="invisible"
      appKeyboardListener
      (textBuffered)="onCodeBuffered($event)"
      id="keyboardInput"
      type="text"
      [value]="typedText"
      readonly
    />
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KioskCodeScannerBlockComponent implements OnInit {
  private readonly comoApiService = inject(ComoApiService);
  private readonly modalsService = inject(ModalsService);
  private readonly translateService = inject(TranslateService);
  private readonly marketplaceSelector = inject(MarketplaceStoreSelector);
  private readonly authFirebaseService = inject(AuthFirebaseService);

  public accentColor = input<IContentBuilderFieldColor>();

  public redirectUrl = input<string>();

  private isValidating: boolean = false;
  private isErrorModalOpen: boolean = false;

  private registrationStep = RegistrationStep;
  public typedText = '';
  public errorMessage = '';

  ngOnInit() {
    this.modalsService
      .getData(KioskAlertModalComponent.handle)
      .pipe(filter(modal => !modal))
      .subscribe(() => {
        this.isErrorModalOpen = false;
      });
  }

  public onCodeBuffered(code: string) {
    if (this.isValidating || this.isErrorModalOpen) return;

    this.typedText = code;
    this.onSubmit(code);
  }

  private onSubmit(code: string): void {
    if (this.isValidating || this.isErrorModalOpen) return;

    this.isValidating = true;
    code = this.ensureCodeEncoding(code);

    this.comoApiService
      .getComoUserFromTemporaryToken$(
        code,
        this.marketplaceSelector.marketplace[MarketplaceKeys.Id],
        true,
        '',
      )
      .pipe(switchMap(comoSignOn => this.handleSingleSignOnResponse(comoSignOn)))
      .subscribe({
        error: () => this.handleErrorResponse(),
        next: () => this.redirectToUrl(),
      });

    // this.comoService.linkComoUser$({ phoneOrEmail: undefined, code }).subscribe({
    //   error: () => this.handleErrorResponse(),
    //   next: () => this.redirectToUrl(),
    // });
  }

  private handleSingleSignOnResponse(comoSignOn: ComoSignOn): Observable<any> {
    if (comoSignOn.firebase_sign_in_token) {
      return this.authFirebaseService
        .signInWithCustomToken(comoSignOn.firebase_sign_in_token)
        .pipe(tap(() => this.modalsService.close(ComoSsoLoadingModalComponent.handle)));
    }
    return of(null);
  }

  private redirectToUrl(): void {
    this.modalsService.open(ComoRegistrationModalComponent.handle, undefined, {
      goToURLOnClose: this.redirectUrl,
      activeStep: this.registrationStep.STEP_KIOSK_CONFIRMATION,
      accentColor: this.accentColor,
    });
  }

  private handleErrorResponse(): void {
    this.isValidating = false;
    this.isErrorModalOpen = true;

    this.showErrorDialog();
  }

  private showErrorDialog(): void {
    this.modalsService.open(KioskAlertModalComponent.handle, undefined, {
      icon: 'icon-warning-sign',
      message: this.translateService.instant('authentication.loyalty.unable-to-authenticate'),
      accentColor: this.accentColor,
    });
  }

  private ensureCodeEncoding(code: string): string {
    return this.restoreDashes({
      cleanString: this.replaceSpecialChars(code),
      positions: [8, 13, 18, 23],
    });
  }

  private replaceSpecialChars(input: string): string {
    const replacements: { [key: string]: string } = {
      '&': '1', // Touche 1 sans Shift
      'é': '2', // Touche 2 sans Shift
      '"': '3', // Touche 3 sans Shift
      '“': '3', // Variante de guillemet
      "'": '4', // Touche 4 sans Shift
      '(': '5', // Touche 5 sans Shift
      '-': '6', // Touche 6 sans Shift
      'è': '7', // Touche 7 sans Shift
      '_': '8', // Touche 8 sans Shift
      'ç': '9', // Touche 9 sans Shift
      'à': '0', // Touche 0 sans Shift
      ')': '0', // Touche 0 avec Shift
    };

    return input.replace(/./g, match => replacements[match] ?? match);
  }

  private restoreDashes({
    cleanString,
    positions,
  }: {
    cleanString: string;
    positions: number[];
  }): string {
    const result = cleanString
      .split('')
      .map((char, index) => (positions.includes(index) ? '-' : char))
      .join('');
    return result;
  }
}
