<div class="pt-3 px-5 lg:px-8 pb-5 mb-1 font-base font-normal text-neutral-600">
  <div class="font-accent text-md lg:text-2xl font-bold leading-6 text-black">
    {{ 'shop-informations.address' | translate }}
  </div>
  <div class="text-base lg:text-md">
    <span *ngIf="address">{{ address }},</span>
    <span *ngIf="postcode"> {{ postcode }}</span>
    <span *ngIf="city"> {{ city }}</span>
  </div>
  <div
    *ngIf="distance"
    class="text-base lg:text-md"
  >
    À {{ formattedDistance }}
  </div>
  <div class="mt-3">
    <app-button-atom
      *ngIf="localization"
      [size]="'S'"
      [color]="'primary'"
      [label]="'shop-informations.location' | translate"
      [type]="'light'"
      class="mr-3"
      (click)="getLocalization.emit($event)"
    ></app-button-atom>
    <app-button-atom
      *ngIf="itinerary"
      [size]="'S'"
      [color]="'primary'"
      [label]="'shop-informations.itinerary' | translate"
      [type]="'light'"
      (click)="getItinerary.emit($event)"
    ></app-button-atom>
  </div>
</div>
