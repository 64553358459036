import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { groupBy } from 'lodash';
import { Router } from '@angular/router';
import panzoom, { PanZoomOptions } from 'panzoom';
import { combineLatest, interval, merge, of, Subject, timer } from 'rxjs';
import { map, skipWhile, switchMap, takeUntil, tap } from 'rxjs/operators';

import {
  IContentBuilderFieldColor,
  IContentBuilderFieldImage,
} from '@core/models/content-builder-fields.model';

import { AuthStoreSelector } from '@common/selectors/authentication.selector';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

import { PushService } from '@core/services/push/push.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { RouterHelperService } from 'src/app/core/services/router-helper/router-helper.service';

@Component({
  selector: 'app-shops-custom-map-block',
  templateUrl: './shops-custom-map-block.component.html',
  styleUrls: ['./shops-custom-map-block.component.scss'],
})
export class ShopsCustomMapBlockComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('stage') stageElement!: ElementRef;
  @Input() shops!: any;
  @Input() image!: IContentBuilderFieldImage;
  @Input() backgroundColor?: IContentBuilderFieldColor;
  @Input() imageWidth = 2100;
  @Input() imageHeight = 2100;
  @Input() maxZoom = 1.5;
  @Input() initialPositionX = 0.6;
  @Input() initialPositionY = 0.08;
  @Input() initialZoom = 'cover';

  public windowWidth = window.innerWidth;
  public windowHeight = window.innerHeight - 80;
  public zoom = 1;
  public shopsWithOrderReady: string[] = [];

  private marketplaceId = this.marketplaceSelector.selectMarketplaceId;
  private initialPosition!: number[];
  private readonly destroyed$ = new Subject<boolean>();
  firstVisit!: boolean;

  public circlePosition?: number[];

  constructor(
    private readonly router: Router,
    private authSelector: AuthStoreSelector,
    private readonly pushService: PushService,
    private readonly ordersService: OrdersService,
    private readonly routerHelper: RouterHelperService,
    private marketplaceSelector: MarketplaceStoreSelector,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.initialPosition = [this.initialPositionX, this.initialPositionY];
    this.firstVisit = !JSON.parse(localStorage.getItem('map-visited') as string);
    if (this.firstVisit) {
      localStorage.setItem('map-visited', 'true');
    }

    this.loadOrders();
  }

  ngAfterViewInit(): void {
    let scale = 1;
    const minScale = Math.min(
      this.windowHeight / this.imageHeight,
      this.windowWidth / this.imageWidth,
    );

    if (this.initialZoom === 'contain') {
      scale = minScale;
    } else {
      scale = Math.max(this.windowHeight / this.imageHeight, this.windowWidth / this.imageWidth);
    }

    const options: PanZoomOptions = {
      maxZoom: scale * this.maxZoom,
      minZoom: minScale,
      initialX:
        this.windowWidth / 2 -
        (this.imageWidth * scale) / 2 +
        (this.initialPosition[0] - 0.5) * (this.windowWidth - this.imageWidth * scale),
      initialY:
        this.windowHeight / 2 -
        (this.imageHeight * scale) / 2 +
        (this.initialPosition[1] - 0.5) * (this.windowHeight - this.imageHeight * scale),
      initialZoom: 0.1,
    };

    panzoom(this.stageElement.nativeElement, options);

    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  polygonPointsToCoordinates(polygon: number[][]): string {
    return polygon.map(point => point.join(',')).join(' ');
  }

  goToShop(shop: any): void {
    this.router.navigateByUrl(this.routerHelper.translateRoute(`shops/${shop.shopSlug}`) as string);
  }

  clickOnStage(_event: Event): void {
    // this.circlePosition = [_event.offsetX, _event.offsetY];
    // _event.preventDefault();
  }

  private loadOrders(): void {
    combineLatest([this.authSelector.selectUserId, this.marketplaceId])
      .pipe(
        takeUntil(this.destroyed$),
        skipWhile(([userId]) => !userId),
      )
      .subscribe(([userId, marketplaceId]) => {
        const pushSubscription = this.pushService.addSubscription(`dood/users/${userId}/orders`);
        merge(interval(30000), timer(0), pushSubscription)
          .pipe(
            takeUntil(this.destroyed$),
            switchMap(() => {
              if (userId) {
                return this.ordersService.getActiveOrders$(userId, marketplaceId);
              }
              return of([]);
            }),
            map(orders => orders.filter(order => order.status === 'READY')),
            map(orders => Object.keys(groupBy(orders, 'shop.slug'))),
            tap(shops => (this.shopsWithOrderReady = shops)),
          )
          .subscribe();
      });
  }

  hasReadyOrder(shop: any): boolean {
    return this.shopsWithOrderReady.includes(shop.shopSlug);
  }
}
