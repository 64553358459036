import { ChangeDetectionStrategy, Component, computed, effect, inject, input } from '@angular/core';
import { CartDraftSimpleProductStore } from '@shared/local-store/cart/cart-draft-simple-product.store';
import { DoodProductModel } from '@core/models/product.model';
import { ICartItem } from '@core/models/cart.model';
import { outputFromObservable, toObservable } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';
import { forceSimpleProductWithAdditionsGroupStepperData } from '../kiosk-product-stepper/kiosk-category-stepper.component';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-kiosk-simple-product-details-organism',
  providers: [CartDraftSimpleProductStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    @if (draftSimpleProduct(); as draftSimpleProduct) {
      <app-kiosk-simple-product-stepper
        [simpleProduct]="draftSimpleProduct"
        [additionsByGroups]="simpleProductAdditionsByGroups()"
        (updateAdditionQuantity)="updateAdditionQuantity($event)"
      >
        <ng-template
          #stepperAdditionsGroupTpl
          let-dataUntyped
        >
          @if (dataUntyped) {
            @let data = forceSimpleProductWithAdditionsGroupStepperData(dataUntyped);
            @if (data) {
              <app-kiosk-select-additions-product-organism
                [backgroundColor]="backgroundColor()"
                [textColor]="textColor()"
                [cardColor]="cardColor()"
                [buttonBackgroundColor]="primaryButtonBackgroundColor()"
                [buttonTextColor]="primaryButtonTextColor()"
                [secondaryColor]="secondaryColor()"
                [primaryButtonBackgroundColor]="primaryButtonBackgroundColor()"
                [unavailableButtonBackground]="unavailableButtonBackground()"
                [unavailableButtonText]="unavailableButtonText()"
                [simpleProductAdditionsGroup]="data.additionsByGroup"
                (onChangeAdditionQuantity)="data?.onChangeAdditionQuantity($event)"
              >
              </app-kiosk-select-additions-product-organism>
            }
          }
        </ng-template>
      </app-kiosk-simple-product-stepper>
    }
  `,
})
export class KioskSimpleProductDetailsOrganism {
  private readonly cartDraftSimpleProductStore = inject(CartDraftSimpleProductStore);

  public readonly backgroundColor = input<IContentBuilderFieldColor>();
  public readonly textColor = input<IContentBuilderFieldColor>();
  public readonly cardColor = input<IContentBuilderFieldColor>();
  public readonly primaryButtonBackgroundColor = input<IContentBuilderFieldColor>();
  public readonly primaryButtonTextColor = input<IContentBuilderFieldColor>();
  public readonly secondaryColor = input<IContentBuilderFieldColor>();
  public readonly unavailableButtonBackground = input<IContentBuilderFieldColor>();
  public readonly unavailableButtonText = input<IContentBuilderFieldColor>();

  public readonly simpleProduct = input.required<DoodProductModel>();
  public readonly activeShopId = input.required<string>();
  public readonly initialCart = input.required<ICartItem | undefined>();

  protected readonly draftSimpleProduct = computed(() => {
    return this.cartDraftSimpleProductStore.selectors()?.draftSimpleProduct;
  });
  protected readonly simpleProductAdditionsByGroups = computed(() => {
    return this.cartDraftSimpleProductStore.selectors()?.draftAdditionGroups;
  });

  public readonly cartData = outputFromObservable(
    toObservable(this.cartDraftSimpleProductStore.selectors).pipe(
      map(selectors =>
        selectors
          ? {
              cart: selectors.finalCart,
              isValid: selectors.validity.isValid,
            }
          : undefined,
      ),
    ),
  );

  constructor() {
    effect(
      () => {
        this.cartDraftSimpleProductStore.init({
          shopId: this.activeShopId(),
          simpleProduct: this.simpleProduct(),
        });
      },
      {
        allowSignalWrites: true,
      },
    );

    effect(
      () => {
        const initialCart = this.initialCart();
        if (initialCart) {
          this.cartDraftSimpleProductStore.setExistingCart(initialCart);
        }
      },
      {
        allowSignalWrites: true,
      },
    );
  }

  protected updateAdditionQuantity(data: { additionId: string; quantity: number }) {
    this.cartDraftSimpleProductStore.updateAdditionQuantity(data);
  }

  protected forceSimpleProductWithAdditionsGroupStepperData =
    forceSimpleProductWithAdditionsGroupStepperData;
}
