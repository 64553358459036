import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DoodUserModel } from '@store/authentication/authentication.model';
import { DOOD_API } from '@config/ws.config';
import { HttpClient } from '@angular/common/http';
import { IPongoAsset, IPongoCustomer } from 'src/app/core/models/pongo.model';
import { DoodProductModel } from '@core/models/product.model';

@Injectable({
  providedIn: 'root',
})
export class PongoApiService {
  constructor(private readonly httpClient: HttpClient) {}

  getMarketplaceRewards$(marketplaceId: string): Observable<IPongoAsset[]> {
    return this.httpClient.get<IPongoAsset[]>(
      `${DOOD_API}/marketplaces/${marketplaceId}/pongo/rewards`,
    );
  }

  createCustomer$(
    marketplaceId: string,
    phoneNumber: string,
    hasOptin: Boolean,
  ): Observable<DoodUserModel> {
    return this.httpClient.post<DoodUserModel>(
      `${DOOD_API}/marketplaces/${marketplaceId}/pongo/customer`,
      {
        phone_number: phoneNumber,
        has_optin: hasOptin,
      },
    );
  }

  getCustomer$(marketplaceId: string, shopId?: string): Observable<IPongoCustomer> {
    let url = `${DOOD_API}/marketplaces/${marketplaceId}/pongo/customer`;
    if (shopId) {
      url += `?shop=${shopId}`;
    }

    return this.httpClient.get<IPongoCustomer>(url);
  }

  attachCustomer$(marketplaceId: string, phoneNumber: string): Observable<DoodUserModel> {
    return this.httpClient.post<DoodUserModel>(
      `${DOOD_API}/marketplaces/${marketplaceId}/pongo/attach-customer`,
      {
        phone_number: phoneNumber,
      },
    );
  }

  buyReward$(marketplaceId: string, rewardId: string): Observable<IPongoAsset> {
    return this.httpClient.post<IPongoAsset>(
      `${DOOD_API}/marketplaces/${marketplaceId}/pongo/buy-reward`,
      {
        reward_id: rewardId,
      },
    );
  }

  allowedProducts$(
    marketplaceId: string,
    externalIds: string[],
    shopId?: string,
  ): Observable<DoodProductModel[]> {
    const params = new URLSearchParams();
    externalIds.forEach(externalId => params.append('externalIds[]', externalId));
    if (shopId) {
      params.append('shopId', shopId);
    }

    const queryParamsString = params.toString();
    return this.httpClient.get<DoodProductModel[]>(
      `${DOOD_API}/marketplaces/${marketplaceId}/pongo/allowed-products?${queryParamsString}`,
    );
  }
}
