import { Observable, of, Subject } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';

import { ShopKeys } from '@config/keys/shop.keys';
import { EntityKeys } from '@config/keys/entity.keys';

import { DoodShopModel } from '@core/models/shop.model';
import { DoodEntityModel } from '@core/models/entity.model';

import { ModalsService } from '@core/services/modals/modals.service';
import { NativeService } from '@core/services/native/native.service';

import { ShopStoreSelector } from '@common/selectors/shop.selector';
import { EntityStoreSelector } from '@common/selectors/entity.selector';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';

import { ModalScrollBlockBase } from '@core/base/modalScrollBlock/modal-scroll-block.base';
import { ModalStoreSelector } from '@common/selectors/modal.selector';

@Component({
  selector: 'app-shop-informations-modal',
  templateUrl: './shop-informations-modal.component.html',
})
export class ShopInformationsModalComponent
  extends ModalScrollBlockBase
  implements OnInit, OnDestroy
{
  private _destroyerRef = new Subject<boolean>();

  static handle = 'shop-informations-modal';

  isDesktop$ = this.settingsSelector.selectDeviceIsDesktopScreen;

  index$: Observable<number> = this.modalSelector
    .selectModal(ShopInformationsModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  shop!: DoodShopModel;

  entity!: DoodEntityModel;

  socialTitle!: string;

  shopImageCover?: string;

  shopKeys = ShopKeys;

  entityKeys = EntityKeys;

  isNotched = false;

  constructor(
    private shopSelector: ShopStoreSelector,
    private modalSelector: ModalStoreSelector,
    private entitySelector: EntityStoreSelector,
    private readonly modalsService: ModalsService,
    private settingsSelector: SettingsStoreSelector,
  ) {
    super();
    this.shopSelector.selectActive
      .pipe(
        takeUntil(this._destroyerRef),
        switchMap(shop => {
          if (shop) {
            this.shop = shop;
            this.shopImageCover = shop?.images?.cover?.url;
            // Legacy image
            if (!this.shopImageCover && shop?.cover) {
              this.shopImageCover = 'https://api.dood.com' + shop?.cover;
            }
            this.socialTitle = `Suivez ${shop[ShopKeys.Name]}`;
            return this.entitySelector.selectEntity(shop?.[ShopKeys.Id]);
          } else {
            return of(null);
          }
        }),
        tap(entity => {
          if (entity?.[EntityKeys.Id]) {
            this.entity = entity;
          }
        }),
      )
      .subscribe();
  }

  async ngOnInit(): Promise<void> {
    this.isNotched = await NativeService.isNotched();

    if (this.isNotched) {
      await StatusBar.setStyle({ style: Style.Dark });
    }
  }

  ngOnDestroy(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
    super.ngOnDestroy();
  }

  close(): void {
    this.modalsService.close(ShopInformationsModalComponent.handle);
  }

  locateShop(shop: DoodShopModel): void {
    // const device = this.deviceQuery.getValue().device;
    if (Capacitor.isNativePlatform()) {
      NativeService.platform === 'ios'
        ? window.open(
            `maps://maps.google.com/maps?daddr=${shop.venue?.address?.street_line1}+${shop.venue?.address.post_code}+${shop.venue?.address.city}`,
          )
        : window.open(
            `https://maps.google.com/?q=${shop.venue?.address?.street_line1}+${shop.venue?.address.post_code}+${shop.venue?.address.city}`,
          );
    }
    if (shop[ShopKeys.Venue].address.street_line1) {
      window.open(
        `https://maps.google.com/?q=${shop.venue?.address?.street_line1}+${shop.venue?.address.post_code}+${shop.venue?.address.city}`,
      );
    }
  }
}
