import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { DoodAnchorModel } from './anchor.model';
import { DoodApiError } from '@shared/interfaces/error.interface';

export const ANCHOR_STORE_API_ACTIONS = createActionGroup({
  source: 'Dood/API/Anchor',
  events: {
    'Fetch Anchors': emptyProps(),
    'Fetch Anchors Success': props<{ anchors: DoodAnchorModel[] }>(),
    'Fetch Anchors Failure': props<{ error: DoodApiError }>(),
    'Fetch Anchors Complete': emptyProps(),
  },
});

export const ANCHOR_STORE_LOCAL_ACTIONS = createActionGroup({
  source: 'Dood/LOCAL/Anchor',
  events: {
    // Anchors
    // -- Multiple
    'Add Anchors': props<{ anchors: DoodAnchorModel[] }>(),
    'Remove Anchors': props<{ ids: string[] }>(),
    'Upsert Anchors': props<{ anchors: DoodAnchorModel[] }>(),

    // -- Single
    'Add Anchor': props<{ anchor: DoodAnchorModel }>(),
    'Remove Anchor': props<{ id: string }>(),
    'Upsert Anchor': props<{ anchor: DoodAnchorModel }>(),

    // Errors
    'Update Error': props<{ error: DoodApiError | null }>(),
    'Reset Error': emptyProps(),

    // Status
    'Update Is Loaded': props<{ isLoaded: boolean }>(),
    'Update Is Loading': props<{ isLoading: boolean }>(),

    // Misc
    'reset': emptyProps(),
  },
});
