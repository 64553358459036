<div
  class="flex max-w-screen-sm justify-between border-b border-neutral-300 py-4"
  [style.color]="textColor | contentBuilderColor"
>
  <div
    *ngIf="!isProductExists"
    class="mb-1.5 mr-2 flex items-center"
  >
    <div class="icon icon-warning-square text-error-800"></div>
    <span
      class="ml-2 inline-block font-base text-base font-bold leading-6 text-error-700 lg:text-md"
    >
      {{ 'errors.product-not-found-item' | translate }}
    </span>
    <app-button-atom
      size="XS"
      [label]="'Retirer'"
      color="primary"
      (click)="removeItem.emit(id)"
      icon="icon-cross"
      type="link"
    ></app-button-atom>
  </div>
  <div
    class="mr-2"
    *ngIf="isProductExists"
    (click)="isOwner && isProductExists ? updateItem.emit(id) : $event.preventDefault()"
  >
    <div class="mb-1.5 flex items-center">
      <app-quantity-atom [quantity]="quantity"></app-quantity-atom>
      <span
        class="ml-2 inline-block font-base text-base font-bold leading-6 lg:text-md"
        [style.color]="productNameColor | contentBuilderColor"
      >
        {{ name }}
      </span>
      <span
        *ngIf="isOwner"
        class="ml-2"
        ><i class="icon icon-pencil text-sm text-neutral-600"></i
      ></span>
    </div>
    <div
      *ngIf="childItems"
      class="flex flex-wrap"
      [style.color]="productDetailsColor | contentBuilderColor"
    >
      <span
        *ngFor="let item of childItems; let last = last"
        class="text-sm leading-4.5 lg:text-base"
        >{{ item.name }}<span *ngIf="!last">,&nbsp;</span></span
      >
    </div>
    <div
      *ngIf="groupAdditionsItems"
      class="flex flex-wrap"
      [style.color]="productDetailsColor | contentBuilderColor"
    >
      <span
        *ngFor="let item of groupAdditionsItems; let last = last"
        class="text-sm leading-4.5 lg:text-base"
        >{{ item.name }}<span *ngIf="!last">,&nbsp;</span>
        <span *ngFor="let additionOfItem of item.additions?.items; let lastAdditionOfItems"
          >{{ additionOfItem.name }} <span *ngIf="!lastAdditionOfItems">,&nbsp;</span></span
        ></span
      >
    </div>
    <div
      *ngIf="groupCompoundItems"
      class="flex flex-wrap"
      [style.color]="productDetailsColor | contentBuilderColor"
    >
      <li *ngFor="let item of groupCompoundItems; let last = last">
        <span
          *ngFor="let el of item.products"
          class="text-sm leading-4.5 lg:text-base"
          >{{ el.name
          }}<span *ngIf="!last"
            >,&nbsp;<span
              *ngFor="let additionsOfEl of el.additions.items; let lastAdditionsOfEl = last"
              >{{ additionsOfEl.name }} <span *ngIf="!lastAdditionsOfEl">,&nbsp;</span></span
            ></span
          ></span
        >
      </li>
    </div>
  </div>
  <div
    class="flex w-1/4 flex-shrink-0 flex-row justify-end text-sm"
    [style.color]="productDetailsColor | contentBuilderColor"
  >
    <app-price-atom
      *ngIf="isBasketOrder"
      [bold]="false"
      [amount]="price"
      [format]="formatPrice"
    ></app-price-atom>
    <app-price-atom
      *ngIf="!isBasketOrder"
      [bold]="false"
      [amount]="price * quantity"
      [format]="formatPrice"
    ></app-price-atom>
    <div
      class="flex-grow-0 pl-4"
      *ngIf="displayRemoveIcon"
    >
      <app-icon-button
        (onClick)="removeItem.emit(id)"
        icon="icon-cross text-base"
        color="error"
        [intensity]="300"
      ></app-icon-button>
    </div>
  </div>
</div>
