<app-modal-filler-atom
  (click)="close()"
  [index]="(index$ | async) ?? 0"
></app-modal-filler-atom>
<div
  class="fixed right-0 left-0 shadow overflow-y-auto mdMax:bottom-0 mdMax:max-h-85-screen mdMax:max-w-screen-sm mdMax:mx-auto md:flex md:justify-center md:items-center md:w-full md:h-full md:top-0 md:left-0"
  [style.z-index]="((index$ | async) ?? 0) + 50"
>
  <div
    class="bg-white p-5 md:p-7 rounded-t-3xl md:rounded-3xl md:max-w-lg md:w-2/5"
    [style.z-index]="((index$ | async) ?? 0) + 50"
  >
    <div class="flex justify-between items-center">
      <h1 class="text-neutral-800 font-brand font-bold text-28px pb-2">Bancontact</h1>
      <app-rounded-icon-button
        icon="icon-cross"
        (onClick)="close()"
      ></app-rounded-icon-button>
    </div>
    <p class="text-neutral-800 font-base text-base pb-2">
      {{ 'payment.bancontact.description-modal-form' | translate }}
    </p>
    <div class="flex-1 mr-2.5 pb-6">
      <form [formGroup]="form">
        <p class="mb-2 text-neutral-600 font-base">
          {{ 'payment.bancontact.complete-name' | translate }}
        </p>
        <div class="text-neutral-800 leading-5 pb-6">
          <input
            [formControlName]="userKeys.CompleteName"
            class="w-full border border-neutral-800 rounded py-4.5 px-5"
          />
        </div>
        <p class="mb-2 text-neutral-600 font-base">
          {{ 'payment.bancontact.email' | translate }}
        </p>
        <div class="text-neutral-800 leading-5 pb-6">
          <input
            [formControlName]="userKeys.Email"
            class="w-full border border-neutral-800 rounded py-4.5 px-5"
          />
        </div>
      </form>
    </div>

    <div class="flex flex-col gap-10">
      <app-button-atom
        [disabled]="form.invalid"
        size="L"
        [label]="'payment.bancontact.confirm-coordinates' | translate"
        type="primary"
        [full]="true"
        (click)="save()"
      ></app-button-atom>
    </div>
  </div>
</div>
