<div
  (click)="navigationClick.emit($event)"
  class="flex justify-between bg-white rounded-s py-2 px-4 items-center max-w-screen-sm cursor-pointer"
  [class.opacity-50]="!shopItem?.available || !isMarketPlaceAvailable"
>
  <div class="mr-6">
    <p class="font-base text-base">{{ shopItem[ShopItemKeys.Name] }}</p>
    <p class="font-brand text-1xsl">{{ shopItem[ShopItemKeys.Type] }}</p>
  </div>
  <div class="w-20 h-20 flex-shrink-0">
    <img
      [src]="shopItem[ShopItemKeys.Logo]"
      alt="img"
    />
  </div>
</div>
