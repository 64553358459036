import { Component, Input, OnInit } from '@angular/core';

import { CartFunnelStoreDispatcher } from '@common/dispatchers/cart-funnel.dispatcher';
import { CartFunnelStoreSelector } from '@common/selectors/cart-funnel.selector';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-kiosk-cart-funnel-radio-item-block',
  templateUrl: './kiosk-cart-funnel-radio-item-block.component.html',
})
export class KioskCartFunnelRadioItemBlockComponent implements OnInit {
  @Input() title!: string;
  @Input() required = false;
  @Input() options!: any;
  @Input() blockId!: string;
  @Input() formId!: string;
  @Input() color?: IContentBuilderFieldColor;
  @Input() textColor?: IContentBuilderFieldColor;
  @Input() buttonColor?: IContentBuilderFieldColor;
  @Input() activeTextColor?: IContentBuilderFieldColor;
  @Input() activeButtonColor?: IContentBuilderFieldColor;

  funnelUsername$: Observable<string | null> = this.cartFunnelSelector.selectfunnelUsername;

  constructor(
    private cartFunnelDispatcher: CartFunnelStoreDispatcher,
    private cartFunnelSelector: CartFunnelStoreSelector,
  ) {}

  ngOnInit(): void {
    this.cartFunnelDispatcher.addValue({
      id: this.blockId,
      title: this.title,
      formId: this.formId,
      required: this.required,
      displayTitle: true,
    });
  }
}
