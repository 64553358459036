<app-block-list [blockIdList]="forceType(header)"></app-block-list>
<div
  class="w-full flex-1 overflow-y-scroll px-32 pb-52 md:mx-auto"
  [style.backgroundColor]="backgroundColor | contentBuilderColor"
>
  <app-block-list [blockIdList]="forceType(body)"></app-block-list>
</div>
<div
  *ngIf="body?.[0]?.block"
  class="fixed bottom-0 left-0 right-0 w-full md:sticky"
  [style.background]="buttonsBackgroundColor | contentBuilderColor"
>
  <div class="grid grid-cols-2 gap-10 p-10">
    <app-button-atom
      size="XXL"
      thickSize="XXL"
      type="kiosk-light"
      [overrideBgColor]="cancelButtonBackgroundColor"
      [overrideTextColor]="cancelButtonTextColor"
      (click)="back()"
      [label]="'cart-funnel.buttons.cancel' | translate"
    ></app-button-atom>
    <app-button-atom
      size="XXL"
      thickSize="XXL"
      type="kiosk-primary"
      [overrideBgColor]="validateButtonBackgroundColor"
      [overrideTextColor]="validateButtonTextColor"
      (click)="submit()"
      [disabled]="!isFormValid"
      [label]="'cart-funnel.buttons.validate' | translate"
    ></app-button-atom>
  </div>
</div>
