import { Component, Input } from '@angular/core';

import { ILink } from '@shared/models/link';

@Component({
  selector: 'app-fs-hero-block',
  templateUrl: './fs-hero-block.component.html',
})
export class FsHeroBlockComponent {
  @Input() image?: ILink;
  @Input() text!: string;
  @Input() socialLinks!: unknown[];
}
