import { Observable, of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap, take } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';

import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { OrderTypeValues } from 'src/app/config/values/order.values';

import { OrderStoreSelector } from '@common/selectors/order.selector';
import { OnSiteLocationStoreSelector } from '@common/selectors/on-site-location.selector';

import { RouterHelperService } from 'src/app/core/services/router-helper/router-helper.service';
import { OnSiteLocationsService } from '@core/services/on-site-locations/on-site-locations.service';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-order-status-block',
  templateUrl: './order-status-block.component.html',
})
export class OrderStatusBlockComponent extends DestroyerBase implements OnInit {
  @Input() textColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() secondaryTextColor: IContentBuilderFieldColor = {
    value: 'neutral-600',
    type: ColorFieldTypesValues.Palette,
  };

  isMultiShop?: boolean;

  orderTypeValues = OrderTypeValues;
  orders$: Observable<any[] | undefined>;

  onSiteLocationName$ = this.orderSelector.selectActive.pipe(
    switchMap(order => {
      if (order && order.onsite_location_id) {
        return this.onSiteLocationSelector.selectLocation(order.onsite_location_id);
      }
      return of(null);
    }),
    map(location => location?.name),
  );

  @Input() displayShopContact = true;
  @Input() displayLocalizeButton = true;
  @Input() displayDetailsButton = true;
  @Input() displayCustomButton = false;
  @Input() customButtonText!: string;
  @Input() customButtonUrl!: string;

  constructor(
    readonly routerHelper: RouterHelperService,
    readonly orderSelector: OrderStoreSelector,
    private readonly activatedRoute: ActivatedRoute,
    readonly onSiteLocationSelector: OnSiteLocationStoreSelector,
    private readonly onSiteLocationsService: OnSiteLocationsService,
  ) {
    super();
    const orderId = this.activatedRoute.snapshot.params.orderId;

    this.orders$ = this.orderSelector.selectOrder(orderId).pipe(
      switchMap(order => {
        this.isMultiShop = false;
        if (!order) {
          return of([]);
        }
        if (order.multi_shop) {
          this.isMultiShop = order.multi_shop;
          return this.orderSelector.selectMultipleOrders((order.child_orders || []).map(c => c.id));
        }

        return of([order]);
      }),
    );
  }

  ngOnInit(): void {
    this.loadOrderShopSlots();
  }

  private loadOrderShopSlots(): void {
    const orderId = this.activatedRoute.snapshot.params.orderId;

    this.orderSelector
      .selectOrder(orderId)
      .pipe(
        take(1),
        switchMap(order => {
          if (order && order.onsite_location_id && order.shop) {
            return this.onSiteLocationsService.loadOnSiteLocations$(order.shop.id);
          }
          return of(null);
        }),
      )
      .subscribe();
  }
}
