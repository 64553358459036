import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-account-hero-atom',
  templateUrl: './account-hero-atom.component.html',
})
export class AccountHeroAtomComponent {
  @Input() name!: string;
  @Input() email!: string;

  constructor() {}
}
