<ng-container *ngIf="isLoading">
  <div class="flex flex-row items-center h-32">
    <img
      src="../../../../../assets/images/spinner.svg"
      class="pr-4"
    />
  </div>
</ng-container>

<ng-container *ngIf="!isLoading">
  <div class="flex justify-between items-center">
    <h1 class="text-neutral-800 font-brand font-bold text-28px pb-5">
      <!--        {{ 'como.link-account.welcome-name' | translate : {name:''} }}-->
      Bienvenue,
    </h1>
  </div>

  <div class="text-base font-accent pb-6">
    {{ 'como.link-account.create-password' | translate }}
  </div>

  <div class="pb-6">
    <div class="flex flex-col gap-5">
      <app-button-atom
        [type]="'outlined'"
        [size]="'L'"
        [label]="'authentication.google.button' | translate"
        [color]="'neutral'"
        [iconUrl]="'assets/images/auth-logos/google.png'"
        (click)="googleUpgrade()"
      ></app-button-atom>

      <div class="flex items-center">
        <div class="w-full h-px border-b-2 border-neutral-400"></div>
        <span class="inline-block px-3 font-base font-bold text-base text-neutral-500">{{
          'divider.or' | translate
        }}</span>
        <div class="w-full h-px border-b-2 border-neutral-400"></div>
      </div>

      <div
        [formGroup]="passwordForm"
        class="w-full"
      >
        <label
          for="password"
          class="text-neutral-600 py-2"
          >{{ 'account.new-password' | translate }}</label
        >
        <input
          id="password"
          class="text-neutral-800 px-4.5 py-5 bg-neutral-50 border border-neutral-300 rounded w-full"
          type="password"
          formControlName="password"
        />
        <span
          *ngIf="passwordErrorMessage"
          class="block mb-2 text-xs font-base font-normal text-error-700"
          >{{ passwordErrorMessage | translate }}</span
        >
      </div>
    </div>
  </div>

  <div class="flex flex-wrap xm:flex-nowrap justify-center">
    <div class="pt-2 xm:pt-0 xm:pl-1 w-full xm:w-1/2">
      <app-button-atom
        size="L"
        [label]="'como.register.otp.continue-button' | translate"
        type="primary"
        [full]="true"
        [iconUrl]="isPasswordChangeInProgress ? 'assets/images/spinner.svg' : ''"
        [disabled]="isPasswordChangeInProgress || !passwordForm.valid"
        (click)="changePassword()"
      ></app-button-atom>
    </div>
  </div>
</ng-container>
