import { Component, input } from '@angular/core';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-kiosk-selection-group-wrapper-atom',
  template: `
    <div
      class="mb-10 rounded-lg px-5 pb-3"
      [style.borderColor]="secondaryColor() | contentBuilderColor"
    >
      <ng-content> </ng-content>
    </div>
  `,
})
export class KioskSelectionGroupWrapperComponent {
  secondaryColor = input<IContentBuilderFieldColor>();
}
