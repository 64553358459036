<div
  class="bg-white rounded-m p-3.5 flex text-neutral-800"
  (click)="clickMessage.emit()"
>
  <span
    class="rounded-full flex items-center justify-center w-14 h-14 bg-neutral-500"
    [ngClass]="{
      'bg-error-500': orderStatusLabels[orderStatus]?.type === 'error',
      'bg-success-500': orderStatusLabels[orderStatus]?.type === 'success',
    }"
  >
    <i class="icon text-white text-xl {{ orderStatusLabels[orderStatus]?.icon }}"></i>
  </span>
  <p class="flex-1 mx-4 text-lg text-neutral-800">
    <span class="font-bold">{{ shopName }} <br /></span>{{ message | translate }}
  </p>

  <span>
    <i
      class="icon icon-cross cursor-pointer text-2xs"
      (click)="$event.preventDefault(); closeMessage.emit()"
    ></i>
  </span>
</div>
