import { DoodDeclinableModel } from '@core/models/product.model';
import { DoodApiError } from '@shared/interfaces/error.interface';
import { ActiveState, createActiveAdapter } from '@store/_abstract/active';

export interface DeclinableState extends ActiveState<DoodDeclinableModel, string> {
  isLoaded: boolean;
  isLoading: boolean;
  error: DoodApiError | null;
}

export const DECLINABLE_STORE_KEY = 'Declinables';

export const DECLINABLE_STORE_ACTIVE_ADAPTER = createActiveAdapter<DoodDeclinableModel, string>({
  selectId: d => d.store_id,
});

export const DECLINABLE_STORE_INITIAL_STATE: DeclinableState =
  DECLINABLE_STORE_ACTIVE_ADAPTER.getInitialState({
    error: null,
    active: null,
    isLoaded: false,
    isLoading: false,
  });
