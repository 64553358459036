<h1 class="text-neutral-800 font-brand font-bold text-28px">
  {{ step.title }}
</h1>
<app-card-carousel
  *ngIf="products.length"
  [cards]="products"
  [nbDisplayCards]="2"
  [carouselItem]="productTemplate"
></app-card-carousel>

<ng-template
  #productTemplate
  let-product
>
  <div
    class="mr-3 md:mr-4 lg:mr-6 xl:mr-8 min-w-10 w-10 lg:min-w-52 lg:w-52 flex-shrink-0 flex-grow-0"
  >
    <app-product-card-atom
      displayContext="loyalty"
      [product]="product"
      [isOrderingAllowed]="true"
      (productClick)="onNavigateTo(product)"
    ></app-product-card-atom>
  </div>
</ng-template>

<div
  *ngIf="!isProductsLoading && products.length === 0"
  class="py-4"
>
  {{ 'zerosix.offered-product-modal.no-product-available' | translate }}
</div>

<div
  *ngIf="isProductsLoading"
  class="py-4 w-full text-center"
>
  <img
    src="assets/images/spinner.svg"
    alt=""
    class="pr-2"
  />
</div>
