<app-modal-filler-atom
  (click)="close()"
  [index]="5"
></app-modal-filler-atom>
<div
  class="md:left-0' fixed bottom-0 left-0 right-0 mx-auto max-h-85-screen max-w-screen-sm overflow-y-auto md:top-0 md:flex md:h-full md:w-full md:items-center md:justify-center"
  [style.z-index]="750"
>
  <div
    class="p-5"
    [ngClass]="(isMobile$ | async) ? 'rounded-t-3xl' : 'rounded-3xl min-w-448'"
    [class.pb-8]="isNotched"
    [style.z-index]="((index$ | async) ?? 0) + 50"
    [style.backgroundColor]="backgroundColor | contentBuilderColor"
    [style.color]="textColor | contentBuilderColor"
  >
    <div class="flex items-center justify-between">
      <h1 class="pb-2 font-brand text-28px font-bold">
        <span *ngIf="(data$ | async)?.title as title">
          {{ title }}
        </span>
      </h1>
      <app-rounded-icon-button
        icon="icon-cross"
        [overrideColor]="buttonTextColor"
        [overrideBackgroundColor]="buttonBackgroundColor"
        (onClick)="close()"
      ></app-rounded-icon-button>
    </div>
    <div
      *ngIf="(data$ | async)?.message as message"
      class="py-6 font-accent text-base"
    >
      {{ message }}
    </div>
    <div>
      <app-button-atom
        [color]="'primary'"
        [label]="(data$ | async)?.confirmButtonText"
        [type]="'primary'"
        [size]="'L'"
        [disabled]="isLoading"
        [iconUrl]="isLoading ? 'assets/images/spinner.svg' : ''"
        [overrideBgColor]="buttonBackgroundColor"
        [overrideTextColor]="buttonTextColor"
        (click)="submit()"
      >
      </app-button-atom>
    </div>
  </div>
</div>
