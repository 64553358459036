<div [style.backgroundColor]="backgroundColor | contentBuilderColor: 'transparent'">
  <ng-container *ngIf="!(isShopLoading$ | async); else loading">
    <div
      class="lg:hidden"
      *ngIf="displayImage"
    >
      <app-shop-hero-image-atom
        *ngIf="shop"
        [shop]="shop"
        [imageSrc]="imageSrcUrl ?? ''"
        [isPreorderingAllowed]="isPreorderingAllowed"
        [isBigHeaderImage]="isBigHeaderImage"
        (openParameters)="onOpenCartParameters()"
        (clickBack)="goBack()"
      ></app-shop-hero-image-atom>
    </div>
    <div class="px-5 py-3 lg:relative lg:z-20 lg:px-0 lg:py-0 lg:shadow lg:shadow-bottom">
      <app-shop-informations-atom
        *ngIf="shop"
        [shop]="shop"
        [name]="shop[shopKeys.Name]"
        [titleSize]="titleSize"
        [titleFont]="titleFont"
        [category]="shop[shopKeys.Type]?.name"
        [address]="shop[shopKeys.Venue]?.address?.street_line1"
        [postcode]="shop[shopKeys.Venue]?.address?.postal_code"
        [city]="shop[shopKeys.Venue]?.address?.city"
        [phone]="shop[shopKeys.Phone]"
        [contactEmail]="shop[shopKeys.ContactEmail]"
        [distance]="entity?.distance"
        [links]=""
        [displayCategory]="displayCategory"
        [displayAddress]="displayAddress"
        [displayMoreInfoButton]="displayMoreInfoButton"
        [displayGroupedOrderButton]="!!isGroupedOrderAllowed"
        [isPreorderingAllowed]="isPreorderingAllowed"
        [imageSrc]="imageSrcUrl"
        [shortDescription]="shop[shopKeys.ShortDescription]"
        [displayBookButton]="displayBookButton"
        [displayImage]="displayImage"
        [basketId]="(basketId$ | async) ?? ''"
        [shopNameColor]="shopNameColor"
        [textColor]="textColor"
        (showMoreClick)="openShopInformationsModal()"
        (groupOrderClick)="openGroupOrderModal()"
        (bookButtonClick)="openBookUrl()"
        (openParameters)="onOpenCartParameters()"
      >
      </app-shop-informations-atom>
    </div>
  </ng-container>
</div>

<ng-template #loading>
  <div class="flex flex-col">
    <div class="h-40 w-full animate-pulse bg-neutral-300"></div>
    <div class="m-5">
      <div class="mb-3 h-8 w-3/4 animate-pulse bg-neutral-200"></div>
      <div class="mb-3 h-6 w-2/3 animate-pulse bg-neutral-50"></div>
      <div class="mb-3 h-6 w-1/2 animate-pulse bg-neutral-50"></div>
    </div>
  </div>
</ng-template>
