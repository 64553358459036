import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NativeService } from '@core/services/native/native.service';
import { StatusBar, Style } from '@capacitor/status-bar';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-back-header-atom',
  templateUrl: './back-header-atom.component.html',
})
export class BackHeaderAtomComponent implements OnInit {
  @Input() text?: string;
  @Input() icon?: string;
  @Input() menu = false;
  @Input() isProductHeader = false;
  @Input() textColor: IContentBuilderFieldColor = {
    value: 'neutral-900',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() backgroundColor: IContentBuilderFieldColor = {
    value: '#FFFFFF',
    type: ColorFieldTypesValues.Hex,
  };

  @Output() navigationClick = new EventEmitter<Event>();
  @Output() openNavigation = new EventEmitter<Event>();

  isNotched = false;

  constructor() {}

  async ngOnInit(): Promise<void> {
    this.isNotched = await NativeService.isNotched();

    if (this.isNotched) {
      await StatusBar.setStyle({ style: Style.Light });
    }
  }
}
