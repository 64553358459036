<div class="mx-auto min-h-screen relative">
  <div class="min-h-screen">
    <router-outlet></router-outlet>
  </div>
  <div
    class="w-full shadow-inner text-center text-xs p-8 font-base text-neutral-500"
    *ngIf="layout$ | async as layout"
  >
    <a
      [href]="layout.powered_by.url"
      target="_blank"
      *ngIf="layout.powered_by"
      >{{ layout.powered_by.text }}</a
    >
  </div>
  <app-notification-block></app-notification-block>
  <app-modals-container></app-modals-container>
</div>
