<ng-container *ngIf="pwaInstallAllowed">
  <div class="bg-neutral-100 px-5 pb-9">
    <app-push-text-button-atom
      *ngIf="textWithButtonItem"
      [title]="textWithButtonItem.title"
      [text]="textWithButtonItem.text ?? ''"
      [textButton]="textWithButtonItem.button?.text ?? ''"
      [backgroundUrl]="textWithButtonItem.background ?? ''"
      [imgUrl]="textWithButtonItem.img ?? ''"
      (buttonClick)="installPwa()"
    ></app-push-text-button-atom>
  </div>
</ng-container>
