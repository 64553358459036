import { Component, EventEmitter, Input, Output } from '@angular/core';

import { OrderKeys } from '@config/keys/order.keys';
import { OrderStatusLabels } from '@config/labels/order.labels';
import { DoodOrderModel } from '@store/order/order.model';

@Component({
  selector: 'app-order-card',
  templateUrl: './order-card.component.html',
})
export class OrderCardComponent {
  @Input() order!: DoodOrderModel;
  @Output() cardClick = new EventEmitter<DoodOrderModel>();

  orderKeys = OrderKeys;

  goToOrderDetails(): void {
    this.cardClick.emit(this.order);
  }

  satisfyTS(status: DoodOrderModel['status']): keyof typeof OrderStatusLabels {
    return status as keyof typeof OrderStatusLabels;
  }
}
