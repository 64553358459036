import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';

import { DoodShopModel } from '@core/models/shop.model';

import {
  SELECT_SHOP_ACTIVE,
  SELECT_SHOP_ACTIVE_ID,
  SELECT_SHOP_ALL,
  SELECT_SHOP_ENTITY,
  SELECT_SHOP_IS_LOADED,
  SELECT_SHOP_IS_LOADING,
  SELECT_SHOP_SLOTS,
} from '@store/shop/shop.selection';

@Injectable({ providedIn: 'root' })
export class ShopStoreSelector implements OnDestroy {
  private readonly _destroyerRef = new Subject<boolean>();

  slots: number[] = [];
  shops: DoodShopModel[] = [];
  active: DoodShopModel | null = null;

  selectShops = this.store.select(SELECT_SHOP_ALL);
  selectShop = (id: string): Observable<DoodShopModel | undefined> =>
    this.store.select(SELECT_SHOP_ENTITY(id));

  selectSlots = this.store.select(SELECT_SHOP_SLOTS);

  selectIsLoaded = this.store.select(SELECT_SHOP_IS_LOADED);
  selectIsLoading = this.store.select(SELECT_SHOP_IS_LOADING);

  selectActive = this.store.select(SELECT_SHOP_ACTIVE);
  selectActiveId = this.store.select(SELECT_SHOP_ACTIVE_ID);

  constructor(private store: Store) {
    this.selectSlots.pipe(takeUntil(this._destroyerRef)).subscribe(_slots => {
      this.slots = _slots;
    });
    this.selectShops.pipe(takeUntil(this._destroyerRef)).subscribe(_shops => {
      this.shops = _shops;
    });
    this.selectActive.pipe(takeUntil(this._destroyerRef)).subscribe(_active => {
      this.active = _active;
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe();
  }

  private _unsubscribe(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
  }
}
