<div class="mx-auto max-w-screen-sm px-5 pb-60 md:max-w-full">
  <h1 class="py-6 font-accent text-2xl font-bold">
    {{ 'pay-at-table.user-checks.title' | translate }}
  </h1>
  <div
    *ngIf="!isChecksLoading; else loading"
    class="grid-cols-2 gap-6 md:grid lg:grid-cols-3 xl:grid-cols-4"
  >
    <ng-container *ngIf="checks.length > 0; else noChecks">
      <div *ngFor="let check of checks">
        <app-check-card
          [check]="check"
          (cardClick)="goToCheckDetails(forceType($event).id)"
        ></app-check-card>
      </div>
    </ng-container>
    <ng-template #noChecks>
      <div class="ng-star-inserted py-2 text-center text-sm text-neutral-500">
        {{ 'orders.no-current-orders' | translate }}
      </div>
    </ng-template>
  </div>
  <ng-template #loading>
    <div class="mb-8xs">
      <ng-container *ngFor="let i of [].constructor(20)">
        <app-order-card-skeleton></app-order-card-skeleton>
      </ng-container>
    </div>
  </ng-template>
</div>
