import { of } from 'rxjs';
import { catchError, map, take, takeUntil, tap } from 'rxjs/operators';
import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';

import { PlaceResult } from '@core/models/google-maps.model';
import { ILocationParameter } from '@core/models/parameters.model';

import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { ParametersDistributionModeValues } from '@config/values/parameters.values';

import { ModalsService } from '@core/services/modals/modals.service';
import { GoogleApiService } from '@core/services/api/google/google-api.service';
import { ShopSearchParametersService } from '@core/services/shop-search-parameters/shop-search-parameters.service';

import { InputTextAtomComponent } from '@shared/atoms/input-text-atom/input-text-atom.component';
import { ShopSearchParametersModalComponent } from '@shared/modals/shop-search-parameters-modal/shop-search-parameters-modal.component';

@Component({
  selector: 'app-shop-search-location-input',
  templateUrl: './shop-search-location-input.component.html',
})
export class ShopSearchLocationInputComponent extends DestroyerBase implements AfterViewInit {
  @ViewChild(InputTextAtomComponent) input!: InputTextAtomComponent;

  geolocalizeError?: string;

  filterAddress$ = this.settingsSelector.selectParameters.pipe(
    map(parameters => parameters.location?.address),
  );

  constructor(
    private readonly modalsService: ModalsService,
    private settingsSelector: SettingsStoreSelector,
    private readonly googleApiService: GoogleApiService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly shopSearchParametersService: ShopSearchParametersService,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    let distributionMode = this.shopSearchParametersService.getDistributionMode();
    if (distributionMode !== ParametersDistributionModeValues.Delivery) {
      const input = this.input?.addressSearchInput.nativeElement;
      this.googleApiService
        .getAutoCompleteAddress(input)
        .pipe(
          takeUntil(this._destroyerRef),
          tap(place => this.placeChanged(place)),
        )
        .subscribe();
      this.changeDetectorRef.detectChanges();
    }
  }

  geolocalizeUser(): void {
    this.googleApiService
      .geolocateUser$()
      .pipe(
        take(1),
        takeUntil(this._destroyerRef),
        tap(place => this.placeChanged(place)),
        catchError(error => {
          this.geolocalizeError = error;
          this.input.clearValue();
          return of(error);
        }),
      )
      .subscribe();
  }

  onValueChange(): void {
    this.shopSearchParametersService.setLocation();
  }

  openModalIfDelivery($event: MouseEvent): void {
    let distributionMode = this.shopSearchParametersService.getDistributionMode();
    if (distributionMode === ParametersDistributionModeValues.Delivery) {
      $event.preventDefault();
      $event.stopPropagation();
      this.modalsService.open(ShopSearchParametersModalComponent.handle);
    }
  }

  private placeChanged(place: PlaceResult): void {
    let location: ILocationParameter | undefined;
    if (!place) {
      location = undefined;
      return;
    }

    location = {
      lat: place.geometry?.location.lat(),
      lng: place.geometry?.location.lng(),
      address: place.formatted_address,
      address_components: place.address_components,
    };

    this.geolocalizeError = undefined;
    this.shopSearchParametersService.setLocation(location);
  }
}
