import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-payment-panel-adyen-applepay',
  templateUrl: './payment-panel-adyen-applepay.component.html',
})
export class PaymentPanelAdyenApplepayComponent {
  @Input() clientKey?: string;
  @Input() googlePayMerchantName?: string;
  @Input() googlePayMerchantId?: string;
  @Input() googlePayGatewayMerchantId?: string;
  @Input() paymentMethodCbAvailable?: string;
  @Input() paymentMethodPaypalAvailable = false;
  @Input() paymentMethodGooglePayAvailable = false;
  @Input() paymentMethodApplePayAvailable = false;
}
