import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  effect,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  Signal,
  signal,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CartStoreRefiner } from '@common/refiners/cart.refiner';
import { ProductStoreRefiner } from '@common/refiners/product.refiner';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { DoodProductModel } from '@core/models/product.model';
import { CartService } from '@core/services/cart/cart.service';
import { ProductService } from '@core/services/product/product.service';
import { ProductsService } from '@core/services/products/products.service';
import {
  combineLatest,
  EMPTY,
  filter,
  map,
  of,
  shareReplay,
  Subject,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import {
  ICartDraftDeclinableProductItem,
  ICartDraftSimpleProductItem,
} from '@core/models/cart.model';
import {
  provideRootStepperStore,
  RootStepperStore,
} from '@shared/local-store/stepper/root-stepper.store';
import { ProductStoreSelector } from '@common/selectors/product.selector';
import { ShopStoreRefiner } from '@common/refiners/shop.refiner';
import { DeclinableProductCart } from '@shared/local-store/cart/cart-draft-declinable-product.store';

@Component({
  selector: 'app-kiosk-product-details-block',
  providers: [provideRootStepperStore()],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="relative flex h-full flex-col overflow-hidden">
      @if (activeShopId(); as activeShopIdValue) {
        @if (product()?.value(); as product) {
          @if (additionsByGroups(); as additionsByGroupsValue) {
            <h1 class="leading-tigh pb-12 text-center font-accent text-6xl font-semibold">
              {{ product.name }}
            </h1>

            <app-kiosk-draft-cart-composition-organism
              [product]="product"
              [cartData]="cartData()"
              [config]="{
                primaryButtonBackgroundColor: primaryButtonBackgroundColor,
                primaryButtonTextColor: primaryButtonTextColor,
                productBackgroundColor: cardColor,
                productTextColor: textColor,
                compositionBackgroundColor: compositionBackgroundColor,
              }"
            />

            <app-kiosk-stepper-navigation-atom
              [color]="primaryButtonBackgroundColor"
              [accentColor]="primaryButtonTextColor"
              [categoryName]="stepName()"
              [minStepCount]="minStepCount()"
              [maxStepCount]="maxStepCount()"
              [hasNextStep]="hasNextStep()"
              [isNextButtonEnable]="isNextButtonEnable()"
              [count]="count()"
              [isPreviousButtonEnable]="true"
              (previousStep)="onPreviousStep()"
              (nextStep)="onNextStep()"
            ></app-kiosk-stepper-navigation-atom>
            <div
              class="relative h-full overflow-y-auto"
              #scrollContainer
            >
              <app-kiosk-product-details-organism
                appVisibilityObserver
                (fullVisibilityChange)="onVisibilityChange($event)"
                [threshold]="1"
                [textColor]="textColor"
                [cardColor]="cardColor"
                [buttonBackgroundColor]="primaryButtonBackgroundColor"
                [buttonTextColor]="textColor"
                [secondaryColor]="secondaryColor"
                [backgroundColor]="backgroundColor"
                [primaryButtonBackgroundColor]="primaryButtonBackgroundColor"
                [unavailableButtonBackground]="unavailableButtonBackground"
                [unavailableButtonText]="unavailableButtonText"
                [primaryButtonTextColor]="primaryButtonTextColor"
                [secondaryColor]="secondaryColor"
                [activeShopId]="activeShopIdValue"
                [product]="product"
                [simpleProductAdditionsByGroups]="additionsByGroupsValue"
                (cartData)="cartData.set($event)"
                (additionGroupName)="onAdditionsByGroupChanged($event)"
              />
            </div>
            <div class="mx-auto items-center justify-center gap-6 pt-4">
              @if (!isElementVisible) {
                <div class="mb-6 flex flex-row gap-6">
                  <app-button-atom
                    #scrollUpIcon
                    size="M"
                    color="primary"
                    [icon]="'icon-chevron-down'"
                    [iconRotation]="'180'"
                    type="kiosk-primary"
                    thickSize="S"
                    [full]="false"
                    [aspectSquare]="true"
                    [disabled]="disableUpBtn"
                    [overrideBgColor]="primaryButtonBackgroundColor"
                    [overrideTextColor]="primaryButtonTextColor"
                    (pointerdown)="startScroll('up')"
                    (pointerup)="stopScroll()"
                    (pointerleave)="stopScroll()"
                  ></app-button-atom>
                  <app-button-atom
                    #scrollDownIcon
                    size="M"
                    color="primary"
                    [icon]="'icon-chevron-down'"
                    [iconRotation]="'0'"
                    type="kiosk-primary"
                    thickSize="S"
                    [full]="false"
                    [disabled]="disableDownBtn"
                    [overrideBgColor]="primaryButtonBackgroundColor"
                    [overrideTextColor]="primaryButtonTextColor"
                    [aspectSquare]="true"
                    (pointerdown)="startScroll('down')"
                    (pointerup)="stopScroll()"
                    (pointerleave)="stopScroll()"
                  ></app-button-atom>
                </div>
              }
              @if (!hasNextStep()) {
                <app-button-atom
                  size="XXL"
                  [label]="'declinable-product-stepper.confirm' | translate"
                  color="primary"
                  type="kiosk-primary"
                  [overrideBgColor]="primaryButtonBackgroundColor"
                  [overrideTextColor]="primaryButtonTextColor"
                  [iconRotation]="'180'"
                  [iconOnRight]="true"
                  [disabled]="!isNextButtonEnable()"
                  thickSize="M"
                  [full]="true"
                  (click)="onNextStep()"
                ></app-button-atom>
              }
            </div>
          }
        }
      }
    </div>
  `,
})
export class KioskProductDetailsBlockComponent implements OnInit, OnDestroy {
  @HostBinding('class') classes = 'h-full overflow-hidden';
  @ViewChild('scrollContainer') scrollContainer: ElementRef | undefined;
  @ViewChild('scrollUpIcon') scrollUpIcon: ElementRef | undefined;
  @ViewChild('scrollDownIcon') scrollDownIcon: ElementRef | undefined;

  protected readonly rootStepperStore = inject(RootStepperStore);

  @Input() primaryButtonBackgroundColor?: IContentBuilderFieldColor;
  @Input() primaryButtonTextColor?: IContentBuilderFieldColor;
  @Input() unavailableButtonBackground?: IContentBuilderFieldColor;
  @Input() unavailableButtonText?: IContentBuilderFieldColor;
  @Input() textColor?: IContentBuilderFieldColor;
  @Input() backgroundColor?: IContentBuilderFieldColor;
  @Input() secondaryColor?: IContentBuilderFieldColor;
  @Input() cardColor?: IContentBuilderFieldColor;
  @Input() compositionBackgroundColor?: IContentBuilderFieldColor;

  private readonly _destroyerRef = new Subject<boolean>();

  @Output() addToCart = new EventEmitter<{
    cartDraft: ICartDraftSimpleProductItem | ICartDraftDeclinableProductItem;
    validity: {
      isValid: boolean;
    };
  }>();

  protected readonly cartData = signal<
    | {
        cart: DeclinableProductCart | ICartDraftSimpleProductItem;
        isValid: boolean;
      }
    | undefined
  >(undefined);

  private readonly shopStoreRefiner = inject(ShopStoreRefiner);

  private readonly cartProducts$ = this.cartRefiner.selectActiveCartProducts;

  private readonly productId$ = this.route.params.pipe(
    switchMap(params => (params.productId ? of(params.productId as string) : EMPTY)),
    shareReplay(1),
  );

  private readonly activeShopId$ = this.cartService.getShopActive$().pipe(
    filter(shop => !!shop),
    map(shop => shop?.id as string),
    shareReplay(1),
  );

  protected readonly activeShopId = toSignal(this.activeShopId$);
  protected readonly product = toSignal(
    combineLatest({
      activeShopId: this.activeShopId$,
      productId: this.productId$,
    }).pipe(
      switchMap(({ activeShopId, productId }) => {
        return this.productsService.selectByShopAndProduct$(activeShopId, productId).pipe(take(1));
      }),
      // the returned signal enable to update the product. And each time the productId is changed, the product will be reset by the source
      map(product => ({
        value: signal<Readonly<DoodProductModel> | undefined>(
          product ? { ...product, quantity: 1 } : undefined,
        ),
      })),
    ),
  );

  // Only used by simple products
  protected readonly additionsByGroups = toSignal(
    this.productRefiner.selectAdditionsByGroups.pipe(shareReplay(1), takeUntil(this._destroyerRef)),
    {
      initialValue: undefined,
    },
  );

  currentStepper = this.rootStepperStore.selectors.currentStepper;
  stepperWithThePreviousStep = this.rootStepperStore.selectors.stepperWithThePreviousStep;
  stepperWithTheNextStep = this.rootStepperStore.selectors.stepperWithTheNextStep;

  protected readonly isNextButtonEnable: Signal<boolean | undefined> = computed(() => {
    const currentStepper = this.currentStepper?.();
    if (!currentStepper) {
      return false;
    }
    return currentStepper.step.isValid;
  });

  protected readonly stepName = toSignal(
    toObservable(this.currentStepper).pipe(
      switchMap(currentStepper => {
        if (!currentStepper) {
          return EMPTY;
        }
        const type = currentStepper?.step.type;
        if (type === 'category') {
          return this.getCategoryName$(currentStepper.step.category.store_id ?? '');
        }
        if (type === 'simpleProductWithAdditionsGroup') {
          return of(currentStepper.step.additionGroup.name);
        }
        return EMPTY;
      }),
    ),
  );

  protected readonly minStepCount: Signal<number | undefined> = computed(() => {
    const currentStepper = this.currentStepper?.();
    if (!currentStepper) {
      return undefined;
    }
    return currentStepper.step.minStepCount;
  });

  protected readonly maxStepCount: Signal<number | undefined> = computed(() => {
    const currentStepper = this.currentStepper?.();
    if (!currentStepper) {
      return undefined;
    }
    return currentStepper.step.maxStepCount;
  });

  protected readonly count: Signal<number | undefined> = computed(() => {
    const currentStepper = this.currentStepper?.();
    if (!currentStepper) {
      return undefined;
    }
    return currentStepper.step.count;
  });

  protected readonly hasNextStep: Signal<boolean> = computed(() => {
    return this.stepperWithTheNextStep()?.step.hasNextStep ?? false;
  });

  private readonly activeCategory$ = this.productSelector.selectActiveCategory;

  isElementVisible: boolean = true;
  scrollInterval: any;
  scrollAmount = 100;

  disableUpBtn: boolean = true;
  disableDownBtn: boolean = false;

  protected readonly additionGroupName = signal<string | undefined>(undefined);

  constructor(
    private readonly route: ActivatedRoute,
    private readonly cartService: CartService,
    private readonly productService: ProductService,
    private readonly productsService: ProductsService,
    private readonly cartRefiner: CartStoreRefiner,
    private readonly productRefiner: ProductStoreRefiner,
    private readonly router: Router,
    private readonly productSelector: ProductStoreSelector,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
  ) {
    effect(
      () => {
        const product = this.product()?.value();
        if (!product) {
          return;
        }
        this.productService.setProductToStore(product);
      },
      {
        allowSignalWrites: true,
      },
    );
    effect(
      () => {
        this.rootStepperStore.setRootProductId(this.product()?.value()?.id);
      },
      {
        allowSignalWrites: true,
      },
    );
  }

  ngOnDestroy() {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
  }

  ngOnInit() {
    // ? A quoi ça sert ?
    this.cartService.getCartLineItems$().pipe(takeUntil(this._destroyerRef)).subscribe();
  }

  ngAfterViewInit(): void {
    if (this.scrollContainer) {
      this.renderer.listen(this.scrollContainer.nativeElement, 'scroll', (event: Event) => {
        this.updateScrollIconsOpacity();
      });
      this.updateScrollIconsOpacity();
    }
  }

  protected readonly getCategoryName$ = (categoryId: string) => {
    return this.shopStoreRefiner.selectAllActiveShopStores.pipe(
      map(categories => {
        return categories?.find(category => category.id === categoryId)?.name ?? '';
      }),
    );
  };

  removeItemFromCart(productId: string): void {
    this.cartProducts$
      .pipe(
        take(1),
        switchMap(cart => {
          let tmpProduct = cart.find(product => product.item_id === productId);
          if (tmpProduct) {
            return this.cartService.removeItem$(tmpProduct.id as number);
          }
          return cart;
        }),
      )
      .subscribe();
  }

  onPreviousStep(): void {
    if (!this.stepperWithThePreviousStep()?.step.hasPreviousStep) {
      this.back();
    } else {
      this.rootStepperStore.previous$.next();
    }
    this.resetScroll();
  }

  private _addToCart() {
    const cartData = this.cartData();
    if (cartData?.cart && cartData.isValid) {
      this.addToCart.emit({
        cartDraft: cartData.cart,
        validity: { isValid: cartData.isValid },
      });
    }
  }

  onNextStep(): void {
    if (!this.hasNextStep()) {
      this._addToCart();
      return;
    }
    this.rootStepperStore.next$.next();
    this.resetScroll();
  }

  back(): void {
    const shopCity = this.route.snapshot.paramMap.get('shopCity');
    const shopType = this.route.snapshot.paramMap.get('shopType');
    const shopSlug = this.route.snapshot.paramMap.get('shopSlug');

    this.activeCategory$
      .pipe(
        map(id => {
          this.router.navigate([
            `/fr/${shopCity}/${shopType}/${shopSlug}${id ? `/categories/${id}` : ''}`,
          ]);
        }),
      )
      .subscribe();
  }

  onVisibilityChange(fullVisibilityChange: boolean): void {
    this.isElementVisible = fullVisibilityChange;
  }

  startScroll(direction: 'up' | 'down'): void {
    this.scrollInterval = setInterval(() => {
      this.scrollTo(direction);
    }, 10);
  }

  stopScroll(): void {
    clearInterval(this.scrollInterval);
  }

  scrollTo(direction: 'up' | 'down'): void {
    const container = this.scrollContainer?.nativeElement;
    if (container) {
      container.scrollBy({
        top: direction === 'up' ? -this.scrollAmount : this.scrollAmount,
        behavior: 'smooth',
      });
      this.updateScrollIconsOpacity();
    }
  }

  resetScroll(): void {
    const container = this.scrollContainer?.nativeElement;
    if (container) {
      container.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }

  updateScrollIconsOpacity(): void {
    const container = this.scrollContainer?.nativeElement;
    if (container && this.scrollUpIcon && this.scrollDownIcon) {
      const scrollTop = container.scrollTop;
      const maxScrollTop = container.scrollHeight - container.clientHeight;
      this.disableUpBtn = scrollTop === 0;
      this.disableDownBtn = scrollTop >= maxScrollTop - 1;
      this.cdr.markForCheck();
    }
  }

  onAdditionsByGroupChanged(newAdditions: string) {
    if (this.additionGroupName() === newAdditions) return;
    this.additionGroupName.set(newAdditions);
  }
}
