<div
  class="relative flex {{
    size === 'S' ? 'h-28 max-h-28' : size === 'M' ? 'h-44 max-h-44' : 'h-60 max-h-60'
  }} w-full items-center justify-center bg-center p-5"
  [ngStyle]="getBackgroundStyles()"
  [style.backgroundColor]="backgroundColor | contentBuilderColor"
>
  @if (displayHomeButton && !hideHomeBtn) {
    <div class="absolute left-5">
      <app-button-atom
        color="primary"
        [type]="'kiosk-light'"
        [size]="'M'"
        [label]="buttonHomeText ?? ''"
        [overrideBgColor]="buttonBackgroundColor"
        [overrideTextColor]="buttonTextColor"
        (click)="redirectoToHome()"
      >
      </app-button-atom>
    </div>
  }
  <img
    *ngIf="logoImageUrl"
    [src]="logoImageUrl"
    class="max-h-24"
  />
  <div class="absolute right-0">
    <app-header-language-flag-atom
      *ngIf="languageFlagUrl"
      [languageFlagUrl]="languageFlagUrl"
      (click)="onLanguageFlagClick()"
    ></app-header-language-flag-atom>
  </div>
</div>
<p
  class="p-5 text-3xl font-bold"
  *ngIf="headerLandingText"
>
  {{ headerLandingText }}
</p>
