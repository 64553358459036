import { Injectable } from '@angular/core';
import { isEmpty, keyBy, reject, uniqBy } from 'lodash';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { catchError, defaultIfEmpty, map, skipWhile, switchMap, take, tap } from 'rxjs/operators';

import { ProductKeys } from '@config/keys/product.keys';
import { OverridesPricesKeys } from '@config/keys/price.keys';

import { SettingsParametersState } from '@store/settings/settings.state';
import { GooglePlacesState } from '@common/states/google-places.state';

import { CartUtils } from '@app/utilities/cart.util';
import { DateUtils } from '@shared/utils/date/date.utils';
import { LocationUtils } from '@shared/utils/location/location.utils';
import { ParametersUtils } from '@shared/utils/parameters/parameters.utils';

import {
  DoodCartModel,
  DoodReconstructedCart,
  ICartDeclinableAdditionsItem,
  ICartItem,
  ICartUsePoint,
  ICoupon,
} from '@core/models/cart.model';
import { IOnBehalfUser } from '@core/models/order.model';
import { DoodOrderModel } from '@store/order/order.model';
import { ISimpleItem } from '@core/models/simple-item.model';
import { PlaceResult } from '@core/models/google-maps.model';
import { DoodMarketplaceModel } from '@core/models/marketplace.model';
import { DoodLocationParameters } from '@store/settings/settings.model';
import { DoodShopModel, IDistributionMode } from '@core/models/shop.model';
import { DoodOnSiteLocationModel } from '@core/models/on-site-location.model';
import { DoodProductModel, IOverridesPrices } from '@core/models/product.model';

import { ProductTypeValues } from '@config/values/product.values';
import { MarketplaceCartScopeValues } from '@config/values/marketplace.values';
import { DistributionModeValues, OrderTypeValues } from '@config/values/order.values';

import { CartStoreRefiner } from '@common/refiners/cart.refiner';
import { ShopStoreSelector } from '@common/selectors/shop.selector';
import { CartStoreSelector } from '@common/selectors/cart.selector';
import { CartStoreDispatcher } from '@common/dispatchers/cart.dispatcher';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { DeliveryStoreSelector } from '@common/selectors/delivery.selector';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';
import { OnSiteLocationStoreSelector } from '@common/selectors/on-site-location.selector';

import {
  CartItemKeys,
  CartKeys,
  ReconstructedCartItemKeys,
  ReconstructedCartKeys,
} from '@config/keys/cart.keys';
import { DistributionModeKeys, MarketplaceKeys, ShopKeys } from '@config/keys/shop.keys';

import { ShopsService } from '@core/services/shops/shops.service';
import { BasketService } from '@core/services/basket/basket.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { OrderTypeService } from '@core/services/order-type/order-type.service';
import { OnSiteLocationsService } from '@core/services/on-site-locations/on-site-locations.service';
import { EventTrackerService } from '@core/services/event-tracker/event-tracker.service';
import { Router } from '@angular/router';
import { Paths } from '@config/paths.config';
import { RouterHelperService } from '../router-helper/router-helper.service';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  constructor(
    private readonly router: Router,
    private cartRefiner: CartStoreRefiner,
    private cartSelector: CartStoreSelector,
    private shopSelector: ShopStoreSelector,
    private authSelector: AuthStoreSelector,
    private cartDispatcher: CartStoreDispatcher,
    private readonly shopsService: ShopsService,
    private googlePlacesState: GooglePlacesState,
    private readonly ordersService: OrdersService,
    private readonly basketService: BasketService,
    private settingsSelector: SettingsStoreSelector,
    private deliverySelector: DeliveryStoreSelector,
    private readonly routerHelper: RouterHelperService,
    private readonly orderTypeService: OrderTypeService,
    private marketplaceSelector: MarketplaceStoreSelector,
    private onSiteLocationSelector: OnSiteLocationStoreSelector,
    private readonly onSiteLocationsService: OnSiteLocationsService,
    private readonly eventTrackerService: EventTrackerService,
  ) {}

  private retrieveWantedAtFromShopSearchParameters(
    shopSearchParameters: SettingsParametersState,
  ): Date | null {
    const orderTypeCapabilities = this.orderTypeService.getCapabilities(
      shopSearchParameters.distribution_mode,
    );

    if (!orderTypeCapabilities.preorderingAllowed) {
      return null;
    }

    const wantedAt = shopSearchParameters.wanted_at;
    if (wantedAt) {
      return new Date(DateUtils.dayjsInMarketplaceTimezone(wantedAt).second(0).format());
    }

    return null;
  }

  initializeCart(): Observable<DoodCartModel | null> {
    return combineLatest([
      this.shopSelector.selectActive,
      this.authSelector.selectUserId,
      this.settingsSelector.selectDevice,
      this.marketplaceSelector.selectMarketplace,
      this.settingsSelector.selectParameters,
    ]).pipe(
      take(1),
      map(([shop, userId, device, marketplace, parameters]) => {
        if (shop) {
          const entity: DoodCartModel = {
            coupons: [],
            message: '',
            products: [],
            user: userId,
            wanted_at: null,
            multi_shop: true,
            white_label: false,
            marketplace: marketplace.id,
            app_platform: device?.platform,
            customer_device: device?.device,
            handle: CartUtils.generateId(marketplace, shop),
            type: parameters.distribution_mode ?? OrderTypeValues.EatIn,
          };
          return entity;
        }
        return null;
      }),
      map(entity => {
        if (entity) {
          this.cartDispatcher.addOne(entity);
        }
        return entity;
      }),
    );
  }

  createCartForActiveShop(orderType: string): void {
    const marketplace = this.marketplaceSelector.marketplace;
    const shop = this.shopSelector.active;
    const parameters = this.settingsSelector.parameters;
    const parametersDeliveryLocation: PlaceResult = {
      ...parameters.location,
      formatted_address: parameters.location?.address,
    };

    let place;
    if (orderType === OrderTypeValues.Delivery || orderType === OrderTypeValues.Shipping) {
      place = isEmpty(this.googlePlacesState.value)
        ? parametersDeliveryLocation
        : this.googlePlacesState.value;
    }

    const activeDeliveryAddress = this.deliverySelector.active;

    const isMultiShop = marketplace[MarketplaceKeys.CartScope] === 'MARKETPLACE';
    const rootEntityDistributionMode = isMultiShop
      ? marketplace[MarketplaceKeys.DistributionModes]
      : shop?.[ShopKeys.DistributionModes];

    if (!rootEntityDistributionMode) {
      console.error('Unable to create cart, root entity is unknown');
      return;
    }

    const authMode = rootEntityDistributionMode.find(mode => mode.type === orderType)?.[
      DistributionModeKeys.AuthMode
    ];
    const cartHandle = this.calculateCartHandle(marketplace, shop);

    const { device, platform } = this.settingsSelector.device;

    if (!cartHandle) {
      console.error("Unable to create cart, cart handle can't be calculated");
      return;
    }

    const cart: DoodCartModel = {
      [CartKeys.Handle]: cartHandle,
      [CartKeys.Shop]: shop?.[ShopKeys.Id],
      [CartKeys.Message]: '',
      [CartKeys.OnSiteLocationId]: this.onSiteLocationSelector.settings.id ?? undefined,
      [CartKeys.RegisterUserToComo]: false,
      [CartKeys.Type]: orderType,
      [CartKeys.AuthMode]: authMode,
      [CartKeys.WhiteLabel]: false,
      [CartKeys.Products]: [],
      [CartKeys.Marketplace]: marketplace[MarketplaceKeys.Id],
      [CartKeys.AppPlatform]: platform,
      [CartKeys.CustomerDevice]: device,
      [CartKeys.Coupons]: [],
      [CartKeys.MultiShop]: isMultiShop,
      [CartKeys.WantedAt]: this.retrieveWantedAtFromShopSearchParameters(parameters),
      [CartKeys.ShopSlug]: shop?.[ShopKeys.Slug],
      [CartKeys.DeliveryAddress]: activeDeliveryAddress
        ? LocationUtils.mapINewDeliveryAddressToOdDeliveryAddress(activeDeliveryAddress)
        : parameters.location_params?.deliveryStreetAddress
          ? LocationUtils.mapLocationParameterToDeliveryAddress(
              parameters.location_params as DoodLocationParameters,
            )
          : this.ordersService.placeResultToDeliveryAddress(place as PlaceResult),
    };
    this.cartDispatcher.insertActive(cart);
  }

  calculateCartHandle(
    marketplace: DoodMarketplaceModel,
    shop?: DoodShopModel | null,
  ): string | null {
    if (marketplace?.[MarketplaceKeys.CartScope] === 'MARKETPLACE') {
      return `marketplace_${marketplace[MarketplaceKeys.Id]}`;
    }
    if (shop) {
      return `shop_${shop?.[ShopKeys.Id]}`;
    }
    return null;
  }

  addCartItem$(item: Omit<ICartItem, 'id'>) {
    return combineLatest([
      this.cartRefiner.selectActiveCartProducts,
      this.cartRefiner.selectHasCart,
      this.marketplaceSelector.selectMarketplace,
      this.authSelector.selectUserId.pipe(defaultIfEmpty(undefined)),
      this.shopSelector.selectActive,
    ]).pipe(
      take(1),
      map(([cartProducts, isCartExist, marketplace, userId, shop]) => {
        if (!isCartExist) {
          this.initializeCart();
        }

        const cartItem: ICartItem = {
          ...item,
          id: cartProducts.length + 1,
        };

        this.cartDispatcher.upsertActive({
          id: CartUtils.generateId(marketplace, shop),
          changes: {
            marketplace: marketplace.id,
            products: [...cartProducts, cartItem],
          },
        });

        if (userId) {
          this.ordersService.checkCartIsValid$().pipe(take(1)).subscribe();
          // Add item to group cart api
          this.basketService.addBasketItem(item as ICartItem);
        }
        return cartItem;
      }),
    );
  }

  updateCartItem(item: ICartItem, navitage: boolean = true): void {
    this.cartRefiner.selectActiveCartProducts
      .pipe(
        take(1),
        map(products => {
          let updatedItems: ICartItem[];
          updatedItems = products.map(el => (el.id === item[CartItemKeys.Id] ? item : el));
          this.eventTrackerService.dispatch(
            EventTrackerService.EventProductsUpdatedInCart,
            updatedItems,
          );
          this.cartDispatcher.updateActive({
            products: updatedItems,
          });
          return products;
        }),
        tap(() => {
          this.basketService.updateBasketItem(item);
        }),
        switchMap(() => this.ordersService.checkCartIsValid$()),
        take(1),
      )
      .subscribe(() => {
        if (navitage) {
          this.router.navigate([this.routerHelper.translateRoute(Paths.Cart)]);
        }
      });
  }

  updateShopIdForActiveCart(shopId: string) {
    this.cartDispatcher.updateActive({
      shop: shopId,
    });
  }

  clearCart(): void {
    this.eventTrackerService.dispatch(EventTrackerService.EventCartCleared, {});
    this.cartDispatcher.resetAndClear();
  }

  createNewCart(cart: DoodCartModel | null, orderType: string): void {
    if (cart) {
      this.cartDispatcher.removeOne(cart.handle);
    }
    this.createCartForActiveShop(orderType);
    this.cartDispatcher.updateActive({
      [CartKeys.DeliveryAddress]: cart?.[CartKeys.DeliveryAddress],
    });
  }

  loadShopsOfCart$(): Observable<
    never[] | [DoodCartModel, (DoodShopModel | undefined)[], DoodOnSiteLocationModel[]]
  > {
    return this.cartSelector.selectActive.pipe(
      skipWhile(c => !c),
      take(1),
      // Load shops of this cart
      switchMap(cart => {
        if (!cart?.products.length) {
          return of([]);
        } else {
          const shopIds = [...new Set(cart.products.map(product => product[CartItemKeys.ShopId]))];
          const shops$ = shopIds.map(id => this.shopsService.loadShopById$(id));
          const isCartScopeShop = this.marketplaceSelector.marketplace?.cart_scope === 'SHOP';
          const onSiteLocations$ =
            cart[CartKeys.Type] === DistributionModeValues.OnSite && isCartScopeShop
              ? this.onSiteLocationsService.loadOnSiteLocations$(shopIds[0])
              : of([]);
          return combineLatest([of(cart), forkJoin(shops$), onSiteLocations$]);
        }
      }),
    );
  }

  getCartLineItems$(): Observable<DoodReconstructedCart[]> {
    return this.cartSelector.selectActive.pipe(
      switchMap(cart => {
        if (!cart?.products.length) {
          return of([]);
        } else {
          const shopIds = [...new Set(cart.products.map(product => product[CartItemKeys.ShopId]))];
          const shops$ = shopIds.map(id => this.shopsService.selectById$(id).pipe(take(1)));
          return combineLatest([of(cart), combineLatest(shops$)]);
        }
      }),

      // map cart items with shop products
      map(([cart, shops]) => {
        const shopById = keyBy(shops, 'id');
        const cartItemsByShopId: { [key: string]: DoodReconstructedCart } = {};
        let declinablePrice = 0;
        if (cart) {
          for (const item of cart[CartKeys.Products]) {
            const shopId = item[CartItemKeys.ShopId];
            const shop = shopById[shopId];
            const childCompoundItem: DoodProductModel[] = [];

            if (!shop) {
              continue;
            }

            const product = shop[ShopKeys.Products].find(
              p => p[ProductKeys.Id] === item[CartItemKeys.ItemId],
            ) as DoodProductModel;
            if (item?.additions?.length) {
              const shopAdditions = shop[ShopKeys.Products].filter(
                shopProduct => shopProduct[ProductKeys.Type] === ProductTypeValues.Addition,
              );
              const additionsById = keyBy(shopAdditions, 'id');
              for (const addition of item.additions) {
                childCompoundItem.push(additionsById[addition]);
              }
            }

            if (item?.[CartItemKeys.Products]) {
              for (const childItem of item?.[
                CartItemKeys.Products
              ] as ICartDeclinableAdditionsItem[]) {
                childItem[CartItemKeys.Additions]?.forEach(el => {
                  const childAdditions = shop[ShopKeys.Products].find(
                    p => p[ProductKeys.Id] === el,
                  ) as DoodProductModel;

                  if (childAdditions) {
                    childCompoundItem.push(childAdditions);
                  }
                });

                const childProduct = shop[ShopKeys.Products].find(
                  p => p[ProductKeys.Id] === childItem[CartItemKeys.Id],
                ) as DoodProductModel;
                if (childProduct) {
                  childCompoundItem.push(childProduct);
                }
              }
            }

            if (shop && item?.[CartItemKeys.Products]) {
              if (product[ProductKeys.PriceOverrides]?.length) {
                const shopAdditions = shop[ShopKeys.Products].filter(
                  shopProduct => shopProduct[ProductKeys.Type] === ProductTypeValues.Addition,
                );
                declinablePrice = this.calculateCompoundPrice(
                  item,
                  shopAdditions,
                  product[ProductKeys.FinalPrice] / 100,
                  product[ProductKeys.PriceOverrides],
                );
              } else {
                declinablePrice = product[ProductKeys.Price];
              }
            }

            if (!cartItemsByShopId[shopId]) {
              cartItemsByShopId[shopId] = {
                [ReconstructedCartKeys.Shop]: shop,
                [ReconstructedCartKeys.Items]: [],
              };
            }

            let lineUnitPrice = 0;
            if (product) {
              // tslint:disable-next-line:max-line-length
              lineUnitPrice = product[ProductKeys.Discount]
                ? (product[ProductKeys.Discount] as number)
                : product[ProductKeys.FinalPrice] / 100;
            }
            item[CartItemKeys.Additions]?.forEach(additionId => {
              const addition = shop[ShopKeys.Products].find(p => p[ProductKeys.Id] === additionId);
              if (!addition) {
                return;
              }
              lineUnitPrice += addition[ProductKeys.Discount]
                ? (addition[ProductKeys.Discount] as number)
                : addition[ProductKeys.FinalPrice] / 100;
            });

            cartItemsByShopId[shopId][ReconstructedCartKeys.Items].push({
              [ReconstructedCartItemKeys.Product]: product,
              ...(childCompoundItem.length && {
                [ReconstructedCartItemKeys.ChildCompoundItems]: childCompoundItem,
              }),
              [ReconstructedCartItemKeys.Item]: item,
              [ReconstructedCartItemKeys.UnitPrice]: item?.[CartItemKeys.Products]
                ? declinablePrice
                : lineUnitPrice,
            });
          }
        }

        return Object.values(cartItemsByShopId);
      }),
    );
  }

  removeItem$(cartItemId: number): Observable<DoodOrderModel | null> {
    return this.cartSelector.selectActive.pipe(
      take(1),
      map(cart => {
        if (!cart) {
          return;
        }

        let copyOfCartItems: ICartItem[];
        const removeItemIndex = cart[CartKeys.Products].findIndex(
          product => product[CartItemKeys.Id] === cartItemId,
        );
        if (removeItemIndex !== -1) {
          copyOfCartItems = [...cart[CartKeys.Products]];
          this.eventTrackerService.dispatch(
            EventTrackerService.EventProductRemovedFromCart,
            cart[CartKeys.Products][removeItemIndex],
          );
          copyOfCartItems.splice(removeItemIndex, 1);
          if (!copyOfCartItems.length) {
            this.getCartLineItems$();
          }
          this.cartDispatcher.updateActive({
            products: copyOfCartItems,
          });
        }
      }),
      switchMap(() => this.ordersService.checkCartIsValid$()),
      catchError(err => of(err)),
    );
  }

  getCouponsInCart(): ICoupon[] {
    return this.cartSelector.active?.coupons || [];
  }

  getShopId(): string | undefined {
    return this.cartSelector.active?.shop || undefined;
  }

  getShopActive$() {
    return this.shopSelector.selectActive;
  }

  getShopId$() {
    return this.cartSelector.selectActiveId;
  }

  getPaymentPhoneNumber(): string | undefined {
    return this.cartSelector.active?.PaymentPhoneNumber || undefined;
  }

  addCoupon(coupon: ICoupon): void {
    const couponsInCart = this.getCouponsInCart();
    const newCouponsList = uniqBy([...couponsInCart, coupon], item => {
      return item.code || item.key;
    });

    this.cartDispatcher.updateActive({
      [CartKeys.Coupons]: newCouponsList,
    });
  }

  removeCoupon(coupon: ICoupon): void {
    const couponsInCart = this.getCouponsInCart();
    const newCouponsList = reject<ICoupon>(couponsInCart, (c: any) => {
      return (
        (c.code && c.code.toUpperCase() === coupon.code) ||
        (c.code && c.code === coupon.code) ||
        (c.key && c.key.toUpperCase() === coupon.key) ||
        (c.key && c.key === coupon.key)
      );
    });
    this.cartDispatcher.updateActive({
      coupons: newCouponsList,
    });
  }

  setUsePoints(usePoints?: ICartUsePoint): void {
    this.cartDispatcher.updateActive({
      [CartKeys.UsePoints]: usePoints,
    });
  }

  setOnBehalfUser(user?: IOnBehalfUser): void {
    this.cartDispatcher.updateActive({
      [CartKeys.OnBehalfOf]: user,
    });
  }

  private calculateCompoundPrice(
    item: ICartItem,
    additions: DoodProductModel[],
    price: number,
    overridesPrices?: IOverridesPrices[],
  ): number {
    const indexedOverridePrices = keyBy(overridesPrices, 'product');
    const indexedAdditions = keyBy(additions, 'id');
    let basePrice = price * 100;
    item[CartItemKeys.Products]?.map(product => {
      const productPrice =
        overridesPrices
          ?.filter(overridePrice => overridePrice.product === product[CartItemKeys.Id])
          .filter(
            overridePrice =>
              overridePrice[OverridesPricesKeys.Store] === product[CartItemKeys.StoreId],
          )?.[0]?.[OverridesPricesKeys.AdditionnalPrice] ?? 0;

      basePrice += productPrice;
      product[CartItemKeys.Additions]?.map(additionId => {
        if (indexedOverridePrices[additionId]) {
          basePrice += indexedOverridePrices[additionId][OverridesPricesKeys.AdditionnalPrice];
        } else {
          basePrice += indexedAdditions[additionId][ProductKeys.Price]
            ? indexedAdditions[additionId][ProductKeys.Price] * 100
            : 0;
        }
      });
    });
    return basePrice / 100;
  }

  getCartEntity$(
    marketplace: DoodMarketplaceModel,
    shopId?: string,
  ): Observable<DoodShopModel | DoodMarketplaceModel | undefined> {
    if (marketplace.cart_scope === MarketplaceCartScopeValues.Marketplace) {
      return this.marketplaceSelector.selectMarketplace;
    } else if (shopId) {
      return this.shopsService.selectById$(shopId);
    }
    return of(undefined);
  }

  getCartEntityDistributionModes$(
    marketplace: DoodMarketplaceModel,
    shopId?: string,
  ): Observable<ISimpleItem[]> {
    return combineLatest([
      this.getCartEntity$(marketplace, shopId),
      this.settingsSelector.selectParameters.pipe(map(_search => _search.force_distribution_mode)),
    ]).pipe(
      map(([entity, forceDistributionMode]) => {
        let allowedDistributionModes = entity?.distribution_modes
          .filter((mode: IDistributionMode) => mode.enabled)
          .sort((a, b) => a[DistributionModeKeys.Priority] - b[DistributionModeKeys.Priority]);

        const allowedDistributionModeTypes = allowedDistributionModes?.map(mode => mode.type) || [];

        if (forceDistributionMode && allowedDistributionModeTypes.includes(forceDistributionMode)) {
          allowedDistributionModes = allowedDistributionModes?.filter(
            mode => mode.type === forceDistributionMode,
          );
        }

        return allowedDistributionModes;
      }),
      map(distributionModes => ParametersUtils.mapDistributionModes(distributionModes)),
    );
  }

  isAddressPreciseEnough(location: { city: string; street: string; country: string }): boolean {
    console.log('[Delivery] Location found', location);
    return !!location.city && !!location.street && !!location.country;
  }

  createCartFromOrder(order: DoodOrderModel): Observable<DoodCartModel> {
    return combineLatest([
      this.authSelector.selectUserId,
      this.marketplaceSelector.selectMarketplace,
      this.settingsSelector.selectDevice,
    ]).pipe(
      map(([userId, marketplace, deviceState]) => {
        const cartItems = order.products.map((item, index) => {
          const cartItem: ICartItem = {
            item_id: item.id,
            shop_id: item.shop_id,
            additions: [],
            products: [],
            quantity: 1,
            id: index + 1,
          };

          // Gestion des additions pour les items simples et complexes
          if (item.additions && item.additions?.products.length > 0) {
            cartItem.additions = item.additions.products;
          }

          // Gestion spéciale pour les items de type "DECLINABLE" avec des produits internes
          if (item.type === 'DECLINABLE' && item.categories) {
            const subProducts: any[] = [];
            item.categories.map(category => {
              subProducts.push(
                ...category.products.map(product => {
                  const subProduct: ICartDeclinableAdditionsItem = {
                    id: product.id,
                    additions: product.additions?.products,
                    quantity: 1,
                    shop_id: product.shop_id,
                    store_id: category.store_id,
                    separate_additions_by_group: product.additions?.items.map(addition => ({
                      id: addition.addition_group as string,
                      selected: [addition.id],
                    })),
                  };
                  return subProduct;
                }),
              );
            });
            cartItem.products = subProducts;
          }

          return cartItem;
        });

        let isMultiBasket = marketplace?.[MarketplaceKeys.CartScope] === 'MARKETPLACE';
        let cartHandle = isMultiBasket
          ? `marketplace_${marketplace[MarketplaceKeys.Id]}`
          : `shop_${order?.[ShopKeys.Id]}`;

        const newCart: DoodCartModel = {
          [CartKeys.Handle]: cartHandle,
          [CartKeys.User]: userId,
          [CartKeys.Type]: order.type,
          [CartKeys.WhiteLabel]: false,
          [CartKeys.Marketplace]: marketplace[MarketplaceKeys.Id],
          [CartKeys.MultiShop]: isMultiBasket,
          [CartKeys.Shop]: order.shop?.id,
          [CartKeys.CustomerDevice]: deviceState?.device,
          [CartKeys.AppPlatform]: deviceState?.platform,
          [CartKeys.Products]: cartItems,
          [CartKeys.Message]: order.message ?? undefined,
          [CartKeys.Coupons]: [],
          [CartKeys.WantedAt]: null,
        };

        this.cartDispatcher.insertActive(newCart);
        return newCart;
      }),
    );
  }

  /**
   * It returns a cart item that match the searchCartItem (but it does not compare the id)
   * It is useful if you want to know if a cart item is already in the cart
   */
  public findMatchingCartItem({
    cartItems,
    searchCartItem,
  }: {
    cartItems: ICartItem[];
    searchCartItem: Omit<ICartItem, 'id'>;
  }) {
    return cartItems.find(cartItem => {
      const sameId = cartItem.item_id === searchCartItem.item_id;
      if (!sameId) {
        return false;
      }
      if ('additions' in cartItem && 'additions' in searchCartItem) {
        const sameAdditions =
          [...cartItem.additions].sort().join() === [...searchCartItem.additions].sort().join(); // todotest

        return sameAdditions;
      }

      const searchCartItemsProducts = searchCartItem.products;
      if (cartItem.products && searchCartItemsProducts) {
        const sameNumberOfProducts = cartItem.products.length === searchCartItemsProducts.length;
        if (!sameNumberOfProducts) {
          return false;
        }
        const sameProducts = cartItem.products.every(product => {
          const sameProduct = searchCartItemsProducts.find(newProduct => {
            return product.id === newProduct.id;
          });
          if (!sameProduct) {
            return false;
          }
          const sameQuantity = product.quantity === sameProduct.quantity;
          if (!sameQuantity) {
            return false;
          }
          const productCartItemAdditions = product.additions;
          const productNewCartAdditions = sameProduct.additions;
          const sameNumberOfAdditions =
            productCartItemAdditions &&
            productNewCartAdditions &&
            productCartItemAdditions.length === productNewCartAdditions.length;
          if (!sameNumberOfAdditions) {
            return false;
          }
          const sameAdditions =
            [...productCartItemAdditions].sort().join() ===
            [...productNewCartAdditions].sort().join();
          return sameAdditions;
        });
        return sameProducts;
      }
    });
  }
}
