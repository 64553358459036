<div
  class="h-40 relative bg-neutral-100"
  [class.h-44]="isNotched"
>
  <img
    *ngIf="imageSrc"
    class="absolute object-cover h-40 w-full"
    [class.h-44]="isNotched"
    [src]="imageSrc"
    alt=""
  />
  <div
    class="absolute top-0 bottom-0 left-0 right-0 bg-gradient-to-b from-neutral-800 to-transparent opacity-30"
    [class.bg-black]="closedLabel"
    [class.opacity-60]="closedLabel"
  ></div>
  <div
    *ngIf="closedLabel && !isBigHeaderImage"
    class="absolute top-0 bottom-0 left-0 right-0 text-sm text-white font-bold flex flex-col justify-center items-center pt-20 pb-4 px-8 text-center"
  >
    <p>{{ closedLabel }}</p>
    <p
      *ngIf="isPreorderingAllowed"
      class="block p-2 mt-2 border border-white rounded-3xl cursor-pointer"
      (click)="openCartParameters()"
    >
      {{ 'shop-page.planning-for-later' | translate }}
    </p>
  </div>
  <div
    *ngIf="closedLabel && isBigHeaderImage"
    class="absolute bottom-5 right-5 text-sm text-center text-white font-bold pt-20 pb-4 px-8 text-center"
  >
    <p>{{ closedLabel }}</p>
    <p
      *ngIf="isPreorderingAllowed"
      class="block p-2 mt-2 border border-white rounded-3xl cursor-pointer"
      (click)="openCartParameters()"
    >
      {{ 'shop-page.planning-for-later' | translate }}
    </p>
  </div>
</div>
