import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-top-hero-atom',
  templateUrl: './top-hero-atom.component.html',
})
export class TopHeroAtomComponent {
  @Input() links!: { url: string; icon: string }[];
  @Input() logoImg!: string;
  @Input() brandText!: string;
}
