import { Meta } from '@angular/platform-browser';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { HeaderService } from '@core/services/header/header.service';

@Component({
  selector: 'app-shops-map-page',
  templateUrl: './shops-map-page.component.html',
})
export class ShopsMapPageComponent implements OnInit, OnDestroy {
  @Input() body: any;
  @Input() metaDescription?: string;

  constructor(
    private readonly meta: Meta,
    private readonly headerService: HeaderService,
  ) {}

  ngOnInit(): void {
    this.headerService.setTitle('Autour de vous');
    this.headerService.toggleShopSearchParametersVisibility(true);
    this.headerService.toggleShopSearchAddressVisibility(true);

    if (this.metaDescription) {
      this.meta.updateTag({
        name: 'description',
        content: this.metaDescription,
      });
    }
  }

  ngOnDestroy(): void {
    this.headerService.setTitle(undefined);
    this.headerService.toggleShopSearchParametersVisibility(false);
    this.headerService.toggleShopSearchAddressVisibility(false);
  }
}
