import { Store } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Injectable } from '@angular/core';

import {
  CART_STORE_ACTIVE_ACTIONS,
  CART_STORE_COMPONENT_ACTIONS,
  CART_STORE_HYDRATION_ACTIONS,
  CART_STORE_LOCAL_ACTIONS,
} from '@store/cart/cart.action';
import { CartState } from '@store/cart/cart.state';

import { DoodCartModel } from '@core/models/cart.model';

@Injectable({ providedIn: 'root' })
export class CartStoreDispatcher {
  constructor(private store: Store) {}

  // One
  addOne(entity: DoodCartModel): void {
    this.store.dispatch(CART_STORE_ACTIVE_ACTIONS.addOne({ entity }));
  }

  removeOne(id: string): void {
    this.store.dispatch(CART_STORE_ACTIVE_ACTIONS.removeOne({ id }));
  }

  // Store
  updateStore(changes: Partial<CartState>): void {
    this.store.dispatch(CART_STORE_COMPONENT_ACTIONS.updateState({ changes }));
  }

  // Active
  setActive(id: string | null): void {
    this.store.dispatch(CART_STORE_ACTIVE_ACTIONS.setActive({ active: id }));
  }

  insertActive(entity: DoodCartModel): void {
    this.store.dispatch(CART_STORE_ACTIVE_ACTIONS.insertActive({ entity }));
  }

  updateActive(changes: Partial<DoodCartModel>): void {
    this.store.dispatch(CART_STORE_ACTIVE_ACTIONS.updateActive({ changes }));
  }

  upsertActive(changes: Update<DoodCartModel>): void {
    this.store.dispatch(CART_STORE_ACTIVE_ACTIONS.upsertActive({ changes }));
  }

  resetActive(): void {
    this.store.dispatch(CART_STORE_ACTIVE_ACTIONS.resetActive());
  }

  // Last update

  checkLastCartUpdate(): void {
    this.store.dispatch(CART_STORE_COMPONENT_ACTIONS.checkLastCartUpdate());
  }

  reset(): void {
    this.store.dispatch(CART_STORE_LOCAL_ACTIONS.reset());
  }

  clear(): void {
    this.store.dispatch(CART_STORE_HYDRATION_ACTIONS.clear());
  }

  resetAndClear(): void {
    this.store.dispatch(CART_STORE_COMPONENT_ACTIONS.resetAndClear());
  }
}
