<div class="bg-white text-neutral-600 lg:bg-neutral-100">
  <div class="p-4.5 lg:py-0">
    <h2 class="mb-3 font-accent font-bold text-neutral-800">
      {{ 'order-details.items' | translate }}
    </h2>
    <ng-container *ngFor="let line of lineItems; let first = first">
      <div
        *ngIf="line[LineItemKeys.Type] !== 'tip'"
        class="flex justify-between"
        [class.border-0]="first"
        [ngClass]="
          !line[LineItemKeys.Level] ? 'py-4 border-t border-neutral-100 lg:border-neutral-300' : ''
        "
        [class.pt-4]="!line[LineItemKeys.Level]"
      >
        <div class="flex">
          <app-quantity-atom
            *ngIf="line[LineItemKeys.Type] !== 'coupon'"
            [quantity]="line[LineItemKeys.Quantity]"
            [dark]="true"
          ></app-quantity-atom>
          <p
            class="pl-3"
            [class.pl-6]="line[LineItemKeys.Level] === 1"
            [class.pl-12]="line[LineItemKeys.Level] === 2"
            [class.pl-18]="line[LineItemKeys.Level] === 3"
          >
            <span *ngIf="line[LineItemKeys.Level] > 0 && line[LineItemKeys.Subtype] === 'SIMPLE'"
              >&ndash;&nbsp;</span
            >
            <span *ngIf="line[LineItemKeys.Level] > 0 && line[LineItemKeys.Subtype] === 'ADDITION'"
              >+&nbsp;</span
            >
            <span
              *ngIf="line[LineItemKeys.Type] !== 'delivery'"
              [ngClass]="{
                'font-base font-bold text-neutral-800':
                  !line[LineItemKeys.Level] && line[LineItemKeys.Type] !== 'coupon',
              }"
              >{{ line[LineItemKeys.Title] }}</span
            >
            <span
              *ngIf="line[LineItemKeys.Type] === 'delivery'"
              [ngClass]="{
                'font-base font-bold text-neutral-800':
                  !line[LineItemKeys.Level] && line[LineItemKeys.Type] !== 'coupon',
              }"
              >{{ 'order.delivery' | translate }}</span
            >
          </p>
        </div>
        <div *ngIf="line[LineItemKeys.DeliveryPriceAmount]">
          <app-overview-cart-additional-atom
            [text]="'order-details.delivery-costs'"
            [icon]="'icon-bike'"
            [price]="line[LineItemKeys.DeliveryPriceAmount]"
            [mustTranslate]="true"
          ></app-overview-cart-additional-atom>
        </div>
        <app-price-atom
          *ngIf="line[LineItemKeys.Type] === 'coupon'"
          class="text-neutral-800"
          [amount]="line[LineItemKeys.Price] ?? 0"
          [bold]="false"
          [format]="true"
        ></app-price-atom>
        <app-price-atom
          class="text-neutral-800"
          [amount]="line[LineItemKeys.Price] ?? 0"
          [bold]="false"
          [format]="true"
          *ngIf="
            !line[LineItemKeys.PriceIncluded] &&
            ((line[LineItemKeys.Price] ?? 0) < 0 || (line[LineItemKeys.Price] ?? 0) > 0)
          "
        ></app-price-atom>
      </div>
    </ng-container>
  </div>
  <div
    *ngIf="tips"
    class="mx-4.5"
  >
    <app-overview-cart-additional-atom
      [text]="'order-details.tip'"
      [icon]="'icon-smile'"
      [price]="tips"
      [mustTranslate]="true"
    >
    </app-overview-cart-additional-atom>
  </div>
  <div class="mx-4.5 border-t border-neutral-300 py-4.5">
    <!--        TODO: tips-->
    <div class="flex justify-between text-neutral-800">
      <span class="font-accent text-md font-bold">Total</span>
      <app-price-atom
        [amount]="totalAmount"
        [format]="true"
      ></app-price-atom>
    </div>
    <div
      *ngIf="paidAmount"
      class="flex justify-between text-neutral-800"
    >
      <span class="font-accent text-md font-bold">{{ 'order-details.payment' | translate }}</span>
      <app-price-atom
        [amount]="paidAmount"
        [format]="true"
      ></app-price-atom>
    </div>
  </div>
</div>
