import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { DoodDeliveryAddressModel } from '@core/models/delivery-address.model';
import { GeocoderAddressComponent, PlaceResult } from '@core/models/google-maps.model';

import { DeliveryAddressKeys } from '@config/keys/delivery-address.keys';
import { DeliveryStoreDispatcher } from '@common/dispatchers/delivery.dispatcher';
import { DeliveryAddressApiService } from '@core/services/api/delivery-address/delivery-address-api.service';

@Injectable({
  providedIn: 'root',
})
export class DeliveryAddressService {
  constructor(
    private deliveryDispatcher: DeliveryStoreDispatcher,
    private readonly deliveryAddressApiService: DeliveryAddressApiService,
  ) {}

  getDeliveryAddresses$(): Observable<DoodDeliveryAddressModel[]> {
    this.deliveryDispatcher.updateIsLoading(true);
    return this.deliveryAddressApiService.getDeliveryAddresses$().pipe(
      tap(addresses => {
        this.deliveryDispatcher.setAll(addresses);
        this.deliveryDispatcher.updateIsLoading(false);
      }),
      catchError(error => {
        this.deliveryDispatcher.updateIsLoading(false);
        this.deliveryDispatcher.updateError(error);
        return throwError(() => error);
      }),
    );
  }

  addDeliveryAddress$(
    data: Partial<DoodDeliveryAddressModel>,
  ): Observable<DoodDeliveryAddressModel> {
    return this.deliveryAddressApiService.addDeliveryAddress$(data);
  }

  deleteDeliveryAddress$(id: string): Observable<void> {
    return this.deliveryAddressApiService.deleteDeliveryAddress$(id);
  }

  updateDeliveryAddress$(data: DoodDeliveryAddressModel): Observable<DoodDeliveryAddressModel> {
    data = {
      ...data,
      [DeliveryAddressKeys.IsDefault]: true,
    };
    return this.deliveryAddressApiService
      .updateDeliveryAddress$(data)
      .pipe(tap(address => this.deliveryDispatcher.upsertOne(address)));
  }

  placeResultToDeliveryAddress(place: PlaceResult): Partial<DoodDeliveryAddressModel> {
    const deliveryAddress: Partial<DoodDeliveryAddressModel> = {
      street: '',
      city: '',
      postal_code: '',
      country: '',
      point: {
        coordinates: [
          place.lng
            ? place.lng
            : ((typeof place?.geometry?.location.lng === 'function'
                ? place?.geometry?.location.lng()
                : place?.geometry?.location.lng) as number),
          place.lat
            ? place.lat
            : ((typeof place?.geometry?.location.lat === 'function'
                ? place?.geometry?.location.lat()
                : place?.geometry?.location.lat) as number),
        ],
      },
      address_components: place.address_components,
    };

    place.address_components?.forEach((component?: GeocoderAddressComponent) => {
      if (component) {
        const { long_name, short_name, types } = component;
        switch (types[0]) {
          case 'plus_code':
            deliveryAddress.street = deliveryAddress.street + ' ' + long_name;
            break;
          case 'street_number':
            deliveryAddress.street = deliveryAddress.street + ' ' + long_name;
            break;
          case 'route':
            deliveryAddress.street = deliveryAddress.street + ' ' + long_name;
            break;
          case 'locality':
            deliveryAddress.city = long_name;
            break;
          case 'postal_code':
            deliveryAddress.postal_code = long_name;
            break;
          case 'country':
            deliveryAddress.country = short_name;
        }
      }
    });
    deliveryAddress.street = deliveryAddress.street?.trim();
    return deliveryAddress;
  }
}
