import { Observable } from 'rxjs';
import { Component } from '@angular/core';
import { map, take } from 'rxjs/operators';

import { OrdersService } from '@core/services/orders/orders.service';
import { ModalsService } from '@core/services/modals/modals.service';

import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { CartStoreDispatcher } from '@common/dispatchers/cart.dispatcher';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';

@Component({
  selector: 'app-cart-tip-picker-modal',
  templateUrl: './cart-tip-picker-modal.component.html',
})
export class CartTipPickerModalComponent {
  static handle = 'cart-tip-picker-modal';

  index$: Observable<number> = this.modalSelector
    .selectModal(CartTipPickerModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  error = false;

  constructor(
    private modalSelector: ModalStoreSelector,
    private cartDispatcher: CartStoreDispatcher,
    private readonly modalsService: ModalsService,
    private readonly ordersService: OrdersService,
    readonly settingsSelector: SettingsStoreSelector,
  ) {}

  closeTipModal(): void {
    this.modalsService.close(CartTipPickerModalComponent.handle);
  }

  onApplyTip(tip: number): void {
    tip = Math.round(tip * 1e2) / 1e2;
    if (tip > 0 && tip < 100) {
      this.cartDispatcher.updateActive({ tip: tip * 100 });
      this.modalsService.close(CartTipPickerModalComponent.handle);
      this.ordersService.checkCartIsValid$().pipe(take(1)).subscribe();
      this.error = false;
      return;
    }
    this.error = true;
  }
}
