import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, Subject } from 'rxjs';

import { Paths } from '@config/paths.config';
import { IOnBehalfUser } from '@core/models/order.model';
import { DoodCartFunnelValueTarget } from '@store/cart-funnel';

import { BasketStoreSelector } from '@common/selectors/basket.selector';
import { CartFunnelStoreSelector } from '@common/selectors/cart-funnel.selector';
import { CartFunnelStoreDispatcher } from '@common/dispatchers/cart-funnel.dispatcher';

import { CartService } from '@core/services/cart/cart.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { NavigationHistoryService } from '@core/services/navigation-history/navigation-history.service';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

interface KioskCartFunnelPageBody {
  block: string;
}

@Component({
  selector: 'app-kiosk-cart-funnel-page',
  templateUrl: './kiosk-cart-funnel-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KioskCartFunnelPageComponent implements AfterViewInit, OnInit, OnDestroy {
  private _destroyerRef = new Subject<boolean>();

  @Input() body?: KioskCartFunnelPageBody[];
  @Input() header?: unknown[];

  @Input() backgroundColor?: IContentBuilderFieldColor;
  @Input() buttonsBackgroundColor?: IContentBuilderFieldColor;
  @Input() validateButtonBackgroundColor?: IContentBuilderFieldColor;
  @Input() validateButtonTextColor?: IContentBuilderFieldColor;
  @Input() cancelButtonBackgroundColor?: IContentBuilderFieldColor;
  @Input() cancelButtonTextColor?: IContentBuilderFieldColor;

  @HostBinding() class = 'relative flex h-screen flex-col overflow-hidden';

  isValid$: Observable<boolean> = this.cartFunnelSelector.selectActiveStep.pipe(
    switchMap(active => {
      return this.cartFunnelSelector.selectValues.pipe(
        map(values => values.filter(v => v.formId === active?.id)),
      );
    }),
    map(values => {
      if (values.find(f => f.type === 'radio')) {
        return !!values.filter(v => v.value).length;
      }
      const nbRequiredForms = values.filter(v => v.required).length;
      const formWithValue = [];
      values
        .filter(v => v.required)
        .forEach(f => {
          if (f.value) {
            formWithValue.push(f);
          }
        });
      return formWithValue.length === nbRequiredForms;
    }),
  );

  isLastItem = true;
  isFormValid = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private readonly router: Router,
    private readonly cartService: CartService,
    private basketSelector: BasketStoreSelector,
    private readonly ordersService: OrdersService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly routerHelper: RouterHelperService,
    private cartFunnelSelector: CartFunnelStoreSelector,
    private cartFunnelDispatcher: CartFunnelStoreDispatcher,
    private readonly navigationHistoryService: NavigationHistoryService,
  ) {
    this.cartFunnelSelector.selectIsLastStepActive
      .pipe(takeUntil(this._destroyerRef))
      .subscribe(_isLastItem => {
        this.isLastItem = _isLastItem;
        this.cdr.markForCheck();
      });
  }

  ngAfterViewInit(): void {
    /* If funnel has no form */
    const isSharedOrderExist = !!this.basketSelector.basket.id;
    const funnelHasBlock = this.body ? this.body[0]?.block : null;

    if (!funnelHasBlock || isSharedOrderExist) {
      this.skipFunnel();
      return;
    }

    this.addBlocksToStore();
  }

  ngOnInit(): void {
    this.cartFunnelSelector.selectActiveStep
      .pipe(
        switchMap(active => {
          return this.cartFunnelSelector.selectValues.pipe(
            map(values => values.filter(v => v.formId === active?.id)),
          );
        }),
        map(values => {
          if (values.find(f => f.type === 'radio')) {
            return !!values.filter(v => v.value).length;
          }
          const nbRequiredForms = values.filter(v => v.required).length;
          const formWithValue = [];
          values
            .filter(v => v.required)
            .forEach(f => {
              if (f.value) {
                formWithValue.push(f);
              }
            });
          return formWithValue.length === nbRequiredForms && nbRequiredForms !== 0;
        }),
        debounceTime(200),
        distinctUntilChanged(),
        takeUntil(this._destroyerRef),
      )
      .subscribe(_isValid => {
        this.isFormValid = _isValid;
        this.cdr.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
  }

  private addBlocksToStore(): void {
    this.body?.forEach((body: KioskCartFunnelPageBody, index: number) => {
      this.cartFunnelDispatcher.addStep({ id: body.block, index }, index === 0);
    });
  }

  private skipFunnel(): void {
    const navigationHistory = this.navigationHistoryService.getHistory();
    const isLastPagePayment =
      navigationHistory[navigationHistory.length - 1].identifier === 'payment';
    this.navigationHistoryService.pushStateFromActivatedRouteSnapshot(
      'cart-funnel',
      this.activatedRoute.snapshot,
    );

    if (isLastPagePayment) {
      this.router.navigate([this.routerHelper.translateRoute(`/${Paths.Cart}`)]);
      return;
    }

    this.router.navigate([this.routerHelper.translateRoute(`/${Paths.Payment}`)]);
  }

  submit(): void {
    combineLatest([
      this.cartFunnelSelector.selectNextStep,
      this.cartFunnelSelector.selectIsLastStepActive,
    ])
      .pipe(
        take(1),
        map(([_next, _isLastStep]) => {
          console.log('[submit] data: ', { _next, _isLastStep });
          if (_isLastStep) {
            this.goToPaymentPage();
          } else {
            if (_next) {
              this.cartFunnelDispatcher.setActive(_next.id);

              setTimeout(() => {
                const inputs = document.querySelectorAll('input');
                if (inputs[0]) {
                  inputs[0].focus();
                }
              }, 1);
            }
          }
        }),
        tap(() => this.useFormValues()),
      )
      .subscribe();
  }

  back(): void {
    combineLatest([this.cartFunnelSelector.selectSteps, this.cartFunnelSelector.selectActiveStep])
      .pipe(
        take(1),
        tap(([steps, active]) => {
          if (active?.index === 0) {
            this.router.navigate([this.routerHelper.translateRoute(Paths.Cart)]);
          }

          if (active?.index && active.index > 0) {
            const previous = steps.filter(f => f.index === active.index - 1)?.[0]?.id;
            this.cartFunnelDispatcher.setActive(previous);
          }
        }),
      )
      .subscribe();
  }

  private goToPaymentPage(): void {
    const isSharedOrderExist = this.basketSelector.basket.id;

    if (isSharedOrderExist) {
      this.router.navigate([
        this.routerHelper.translateRoute(`/${Paths.Validate}/${Paths.SharedOrder}`),
      ]);
    } else {
      this.router.navigate([this.routerHelper.translateRoute(`/${Paths.Payment}`)]);
    }
  }

  useFormValues(): void {
    this.cartFunnelSelector.selectValues
      .pipe(
        take(1),
        map(forms => {
          const orderCommentValues: string[] = [];
          let hasOnBehalfUserField = false;
          const onBehalfUser: IOnBehalfUser = {
            optin: false,
          };

          forms.forEach((form, index) => {
            const target = form.target ?? DoodCartFunnelValueTarget.OrderComment;
            if (target === DoodCartFunnelValueTarget.OrderComment && form.value) {
              let title = null;

              if (form.displayCustomTitleInComment) {
                title = form.customTitle;
              } else if (form.displayTitle) {
                title = form.title;
              }
              let value = form.value;
              if (value === true) {
                value = 'oui';
              }

              orderCommentValues.push([title, value].filter(i => i).join(' : '));
            }
            if (target === DoodCartFunnelValueTarget.OnBehalfUserFullName) {
              onBehalfUser.full_name = form.value as string;
              hasOnBehalfUserField = true;
            }
            if (target === DoodCartFunnelValueTarget.OnBehalfUserEmail) {
              onBehalfUser.email = form.value as string;
              hasOnBehalfUserField = true;
            }
            if (target === DoodCartFunnelValueTarget.OnBehalfUserPhone) {
              onBehalfUser.phone = form.value as string;
              hasOnBehalfUserField = true;
            }
            if (target === DoodCartFunnelValueTarget.OnBehalfUserOptin) {
              onBehalfUser.optin = form.value === true;
              hasOnBehalfUserField = true;
            }
          });

          this.cartService.setOnBehalfUser(hasOnBehalfUserField ? onBehalfUser : undefined);
          this.cartFunnelDispatcher.updateOrderComment(orderCommentValues.join(' ; '));
        }),
        switchMap(() => this.ordersService.checkCartIsValid$()),
      )
      .subscribe();
  }

  forceType(data: any): any {
    return data as any;
  }
}
