import { Router } from '@angular/router';
import { map, take, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { OrderKeys } from '@config/keys/order.keys';
import { ObjectUtils } from '@app/utilities/object.util';
import { DoodOrderModel } from '@store/order/order.model';
import { DateUtils } from '@shared/utils/date/date.utils';
import { OrderStatusLabels } from '@config/labels/order.labels';
import { OrderStatusValues, OrderTypeValues } from '@config/values/order.values';

import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { DateHelperService } from 'src/app/core/services/date-helper/date-helper.service';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-order-status-atom-v2',
  templateUrl: './order-status-atom-v2.component.html',
})
export class OrderStatusAtomV2Component implements OnChanges {
  @Input() shopName!: string;
  @Input() orderNumber!: string;
  @Input() orderStatus!: string;
  @Input() order!: DoodOrderModel;
  @Input() displayLocalizeButton = true;
  @Input() displayDetailsButton = true;
  @Input() displayCustomButton = false;
  @Input() customButtonText!: string;
  @Input() customButtonUrl!: string;
  @Input() locationName!: string;
  @Input() textColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() secondaryTextColor: IContentBuilderFieldColor = {
    value: 'neutral-600',
    type: ColorFieldTypesValues.Palette,
  };

  orderStatusValues = OrderStatusValues;
  orderStatusLabels = OrderStatusLabels;

  orderTypeValues = OrderTypeValues;

  readyAt?: string;
  createdAt?: string;
  estimatedArrival?: string;
  daysUntilReadyString?: string;
  daysUntilEstimatedTimeOfArrivalString?: string;

  constructor(
    private readonly router: Router,
    private settingsSelector: SettingsStoreSelector,
    private readonly routerHelper: RouterHelperService,
    private readonly translateService: TranslateService,
    private readonly dateHelperService: DateHelperService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.order) {
      this.translateService
        .get('order.format')
        .pipe(
          take(1),
          map(translation => {
            this.createdAt = this.getFormattedDateString(this.order.created_at, translation);
            this.readyAt = this.getFormattedDateString(this.order.ready_at, translation);
            this.estimatedArrival = this.getFormattedDateString(
              this.order[OrderKeys.EstimatedTimeOfArrival],
              translation,
            );
            this.dateHelperService
              .getDaysUntilString(this.order.ready_at)
              .pipe(
                take(1),
                tap(daysUntilString => (this.daysUntilReadyString = daysUntilString)),
              )
              .subscribe();
            this.dateHelperService
              .getDaysUntilString(this.order[OrderKeys.EstimatedTimeOfArrival])
              .pipe(
                take(1),
                tap(
                  daysUntilString => (this.daysUntilEstimatedTimeOfArrivalString = daysUntilString),
                ),
              )
              .subscribe();
          }),
        )
        .subscribe();
    }
  }

  goToOrderDetail(order: DoodOrderModel): void {
    this.router.navigate([this.routerHelper.translateRoute(`/orders/${order.id}/details`)]);
  }

  locateShop(order: DoodOrderModel): void {
    const { device } = this.settingsSelector.device;
    if (device === 'desktop') {
      if (order.pickup_location?.point?.coordinates) {
        window.open(
          `https://maps.google.com/?q=${order[OrderKeys.PickupLocation]?.point?.coordinates?.[0]},${order[OrderKeys.PickupLocation]?.point?.coordinates?.[1]}&amp;ll=`,
        );
      } else {
        window.open(
          `https://maps.google.com/?q=${order[OrderKeys.PickupLocation]?.street_line1}+${order[OrderKeys.PickupLocation]?.post_code}+${order[OrderKeys.PickupLocation]?.city}`,
        );
      }
    } else {
      if (order[OrderKeys.PickupLocation]?.point?.coordinates) {
        window.open(
          `maps://maps.google.com/maps?daddr=${order[OrderKeys.PickupLocation]?.point?.coordinates?.[0]},${order[OrderKeys.PickupLocation]?.point?.coordinates?.[1]}&amp;ll=`,
        );
      } else {
        window.open(
          `maps://maps.google.com/maps?daddr=${order[OrderKeys.PickupLocation]?.street_line1}+${order[OrderKeys.PickupLocation]?.post_code}+${order[OrderKeys.PickupLocation]?.city}`,
        );
      }
    }
  }

  // TODO: Check if date parameter can be undefined
  private getFormattedDateString(
    date: Date | string | undefined | null,
    format: string,
  ): string | undefined {
    if (ObjectUtils.isNotNil(date)) {
      return format !== 'order.format'
        ? DateUtils.dayjsInMarketplaceTimezone(date).format(format)
        : DateUtils.dayjsInMarketplaceTimezone(date).format('HH:mm');
    }
  }

  goToDeliveryTrackingUrl(url: string): void {
    window.open(url);
  }

  forceType(event: any): any {
    return event as any;
  }
}
