import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RouterHelperService } from 'src/app/core/services/router-helper/router-helper.service';

@Component({
  selector: 'app-redirect-page',
  template: ``,
})
export class RedirectPageComponent implements OnInit {
  @Input() redirectTo?: string;

  constructor(
    private readonly router: Router,
    private readonly routerHelper: RouterHelperService,
  ) {}

  ngOnInit(): void {
    if (!this.redirectTo) {
      this.redirectTo = '/';
    }

    if (this.redirectTo.startsWith('/')) {
      this.router.navigate([this.routerHelper.translateRoute(this.redirectTo)]);
    } else {
      window.location.href = this.redirectTo;
    }
  }
}
