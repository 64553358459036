import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { concatMap, delay, retryWhen, skipWhile, takeUntil, tap } from 'rxjs/operators';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { DOOD_API } from '@config/ws.config';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';
import { MarketplaceService } from '@core/services/marketplace/marketplace.service';
import { NoConnectionModalService } from '@core/services/no-connection-modal/no-connection-modal.service';

export const retryCount = 60;
export const retryWaitMilliSeconds = 5000;

@Injectable()
export class DoodApiInterceptor extends DestroyerBase implements HttpInterceptor {
  private language!: string;
  private firebaseToken: string | null = null;

  constructor(
    private authSelector: AuthStoreSelector,
    private readonly translationService: TranslateService,
    private readonly marketplaceService: MarketplaceService,
    private readonly noConnectionModalService: NoConnectionModalService,
  ) {
    super();
    this.getFirebaseToken();

    this.translationService.onLangChange
      .pipe(takeUntil(this._destroyerRef))
      .subscribe(v => (this.language = v.lang));
  }

  intercept(
    request: HttpRequest<Record<string, unknown>>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    // Epson ePOS printer API doesn't allow custom header
    if (request.url.includes('cgi-bin/epos/service.cgi')) {
      return next.handle(request);
    }

    request = request.clone({
      setHeaders: {
        'Firebase-tenant': this.marketplaceService?.getMarketplaceTenantId() ?? 'DEFAULT-TENANT',
      },
    });

    if (request.method === 'PATCH') {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/merge-patch+json',
        },
      });
    }

    if (request.url.startsWith(DOOD_API)) {
      request = request.clone({
        setHeaders: {
          Authorization: `${this.firebaseToken}`,
          'X-locale': this.language,
        },
      });
    }

    request = request.clone({
      setHeaders: {
        'ngsw-bypass': 'true',
      },
    });

    return next.handle(request).pipe(
      retryWhen(error =>
        error.pipe(
          concatMap((error, count) => {
            if (!request.url.startsWith(DOOD_API)) {
              return throwError(() => error);
            }

            if (count <= retryCount && error.status === 0) {
              this.noConnectionModalService.setDisplay(true);
              this.noConnectionModalService.setRetryInProgress(true);
              return of(error);
            }
            if (count > retryCount && error.status === 0) {
              this.noConnectionModalService.setRetryInProgress(false);
            }
            return throwError(() => error);
          }),
          delay(retryWaitMilliSeconds),
        ),
      ),
      tap(event => {
        if (event?.type === 0) {
          return;
        }
        this.noConnectionModalService.setDisplay(false);
        this.noConnectionModalService.setRetryInProgress(false);
      }),
    );
  }

  private getFirebaseToken(): void {
    this.authSelector.selectStatusToken
      .pipe(
        skipWhile(token => !token),
        tap(token => (this.firebaseToken = token)),
      )
      .subscribe();
  }
}
