import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ICartItem } from '@core/models/cart.model';
import { ComoAsset } from '@core/models/como.model';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { CartService } from '@core/services/cart/cart.service';
import { ComoService } from '@core/services/como/como.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { ComoProductsSelectionStep } from '@shared/modals/como/como-products-selection-modal/como-products-selection-step-model';
import { map, Observable, of, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
@Component({
  selector: 'app-kiosk-promo-select-product-block',
  templateUrl: './kiosk-promo-select-product-block.component.html',
})
export class KioskPromoSelectProductBlockComponent {
  @Input() backgroundColor!: IContentBuilderFieldColor;
  @Input() textColor!: IContentBuilderFieldColor;

  @Input() primaryButtonBackgroundColor!: IContentBuilderFieldColor;
  @Input() primaryButtonTextColor!: IContentBuilderFieldColor;
  @Input() secondaryButtonBackgroundColor!: IContentBuilderFieldColor;
  @Input() secondaryButtonTextColor!: IContentBuilderFieldColor;

  @Input() asset!: ComoAsset;
  @Input() isAssetInCart = false;
  @Input() displayAddButton = true;
  @Input() tagLabelGift = 'como.asset-list-gift-label';
  @Input() tagLabelPointShop = 'como.asset-list-point-shop-label';

  @Output() assetAdd = new EventEmitter<ComoAsset>();
  @Output() assetRemove = new EventEmitter<ComoAsset>();

  @HostBinding('class') classes = 'flex h-full';

  asset$?: Observable<ComoAsset | undefined>;

  private readonly destroyed$ = new Subject<boolean>();
  private assetId: string | null = null;

  steps?: ComoProductsSelectionStep[];
  productBuffer: { [stepIndex: number]: ICartItem[] } = {};

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly helperRouter: RouterHelperService,
    private readonly comoService: ComoService,
    private readonly cartService: CartService,
  ) {}

  ngOnInit() {
    this.initialize();

    this.asset$ = this.comoService.getComoUser$().pipe(
      takeUntil(this.destroyed$),
      map(comoUser => comoUser?.assets.find(asset => asset.key === this.assetId)),
      tap((asset: ComoAsset | undefined) => this.prepareSteps(asset)),
    );
  }

  private prepareSteps(asset: ComoAsset | undefined): void {
    this.steps = [];

    if (!asset) {
      return;
    }

    let discounts = asset.items_selection?.discounts ?? [];
    for (const discount of discounts) {
      for (const basketRequirement of discount.basketRequirements) {
        this.steps.push({
          title: basketRequirement.text,
          ids: basketRequirement.ids,
          conditions: basketRequirement.conditions,
        });
      }
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  initialize() {
    this.assetId = this.getAssetIdFromRoute(this.route);
  }

  private getAssetIdFromRoute(route: ActivatedRoute): string | null {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route.snapshot.paramMap.get('assetId');
  }

  onAssetAdd(asset: ComoAsset): void {
    if (!asset.is_usable) {
      return;
    }
    this.assetAdd.emit(asset);
  }

  onAssetRemove(asset: ComoAsset): void {
    this.assetRemove.emit(asset);
  }

  assetTagText(asset: ComoAsset): string {
    if (asset.key?.startsWith('ps_')) {
      return this.tagLabelPointShop;
    }

    return this.tagLabelGift;
  }

  changeBufferProduct(stepIndex: number, event: { product: ICartItem; deleteProduct: boolean }) {
    if (event.deleteProduct) {
      let index = this.productBuffer[stepIndex].reduceRight((acc, product, index) => {
        if (product.id === event.product.id && acc === -1) {
          return index;
        }
        return acc;
      }, -1);

      if (index === -1) {
        return;
      }
      if (event.product.quantity === 0) {
        this.productBuffer[stepIndex].splice(index, 1);
      } else {
        let tmpCartItem = this.productBuffer[stepIndex].find(
          cartItem => cartItem.id === event.product.id,
        );
        if (tmpCartItem) {
          tmpCartItem.quantity = event.product.quantity;
        }
      }
    } else {
      if (!this.productBuffer[stepIndex]) {
        this.productBuffer[stepIndex] = [];
      }
      let tmpCartItem = this.productBuffer[stepIndex].find(
        cartItem => cartItem.id === event.product.id,
      );
      if (tmpCartItem) {
        tmpCartItem.quantity = event.product.quantity;
      } else {
        this.productBuffer[stepIndex].push(event.product);
      }
    }
  }

  onCancel() {
    this.router.navigate([this.helperRouter.translateRoute('/cart')]);
  }

  sumCartItem(key: number) {
    let sum = 0;

    if (this.productBuffer[key]) {
      this.productBuffer[key].forEach(cartItem => {
        sum += cartItem.quantity;
      });
    }

    return sum;
  }

  allStepValid(): boolean {
    let valid = true;

    this.steps?.forEach((step, index) => {
      if (!this.productBuffer[index] && step.conditions && step.conditions.quantity.minimum > 0) {
        valid = false;
      } else if (
        step.conditions &&
        (this.sumCartItem(index) > step.conditions?.quantity.maximum ||
          this.sumCartItem(index) < step.conditions?.quantity.minimum)
      ) {
        valid = false;
      }
    });

    return valid;
  }

  onValidate() {
    this.asset$
      ?.pipe(
        take(1),
        switchMap(asset => {
          if (!asset) {
            return of(null);
          }
          return this.comoService.addRewardToCart$(asset);
        }),
        tap(() => {
          for (let key in this.productBuffer) {
            if (this.productBuffer[key]) {
              this.productBuffer[key].forEach(cartItem => {
                this.cartService.addCartItem$(cartItem as ICartItem).subscribe();
              });
            }
          }
        }),
      )
      .subscribe();
    this.router.navigate([this.helperRouter.translateRoute('/cart')]);
  }
}
