<div
  class="min-h-screen"
  [ngStyle]="getBackgroundStyles()"
>
  <div
    class="flex h-screen flex-col overflow-hidden"
    [ngStyle]="getBackgroundStyles()"
    [style.backgroundColor]="backgroundColor | contentBuilderColor"
    [style.color]="textColor | contentBuilderColor"
  >
    @if (header) {
      <div
        id="header overflow-hidden"
        class="flex-shrink-0"
      >
        <app-block-list [blockIdList]="header"></app-block-list>
      </div>
    }

    <div
      id="body"
      class="flex flex-1 flex-row gap-2.5 overflow-hidden px-5 py-5"
    >
      @if (!hideLeftBody) {
        <div
          id="body_left"
          class="flex-shrink-0"
        >
          <app-block-list [blockIdList]="forceType(sidebar)"></app-block-list>
        </div>
      }
      <div
        id="body_right"
        class="h-full flex-1 overflow-y-auto px-5 scrollbar-hide"
      >
        <router-outlet></router-outlet>
      </div>
    </div>
    @if (footer) {
      <div class="footer">
        <app-block-list [blockIdList]="footer"></app-block-list>
      </div>
    }
  </div>
</div>
