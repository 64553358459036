import { createFeature, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';

import { DoodDeliveryAddressModel } from '@core/models/delivery-address.model';

import { DELIVERY_STORE_REDUCER } from './delivery.reducer';
import { DELIVERY_STORE_KEY, DELIVERY_STORE_ACTIVE_ADAPTER } from './delivery.state';

const DELIVERY_STORE_FEATURE = createFeature({
  name: DELIVERY_STORE_KEY,
  reducer: DELIVERY_STORE_REDUCER,
});

export const {
  selectActive: SELECT_DELIVERY_ACTIVE_ID,
  selectEntities: SELECT_DELIVERY_ENTITIES,
  selectIsLoaded: SELECT_DELIVERY_IS_LOADED,
  selectDeliveryState: SELECT_DELIVERY_STATE,
  selectIsLoading: SELECT_DELIVERY_IS_LOADING,
} = DELIVERY_STORE_FEATURE;

export const { selectAll: SELECT_DELIVERY_ADDRESSES_ALL } =
  DELIVERY_STORE_ACTIVE_ADAPTER.getSelectors(SELECT_DELIVERY_STATE);

export const SELECT_DELIVERY_ACTIVE = createSelector(
  SELECT_DELIVERY_ENTITIES,
  SELECT_DELIVERY_ACTIVE_ID,
  (entities, id) => (id ? (entities[id] ?? null) : null),
);

export const SELECT_DELIVERY_ADDRESS_ENTITY = (
  id: string,
): MemoizedSelector<
  Record<string, any>,
  DoodDeliveryAddressModel | undefined,
  DefaultProjectorFn<DoodDeliveryAddressModel | undefined>
> =>
  createSelector(SELECT_DELIVERY_ENTITIES, entities => {
    return entities[id];
  });
