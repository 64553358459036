import { Component, Input, OnInit } from '@angular/core';
import { PwaService } from '@core/services/pwa/pwa.service';

export interface ITextWithButton {
  title: string;
  text?: string;
  button?: {
    text: string;
    url: string;
  };
  img?: string;
  background?: string;
}

@Component({
  selector: 'app-order-status-push-item-block',
  templateUrl: './order-status-push-item-block.component.html',
})
export class OrderStatusPushItemBlockComponent implements OnInit {
  @Input() textWithButtonItem?: ITextWithButton;

  pwaInstallAllowed = false;

  constructor(private readonly pwaService: PwaService) {}

  ngOnInit(): void {
    this.pwaInstallAllowed = !!this.pwaService.promptEvent;
  }

  installPwa(): void {
    this.pwaService.promptEvent?.prompt();
    this.pwaService.promptEvent = null;
  }
}
