import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { ModalsService } from '@core/services/modals/modals.service';
import { IContentBuilderFieldImage } from '@core/models/content-builder-fields.model';
import { SplashScreenImageModalComponent } from '@shared/modals/splash-screen-image-modal/splash-screen-image-modal.component';

@Component({
  template: ``,
  selector: 'app-splash-screen-image-modal-block',
})
export class SplashScreenImageModalBlockComponent implements OnInit, OnDestroy {
  @Input() url?: string;
  @Input() title?: string;
  @Input() maxWidth: string = '100dvw';
  @Input() maxHeight: string = '100dvh';
  @Input() content?: IContentBuilderFieldImage;
  @Input() closeDelay?: string;

  constructor(private modalService: ModalsService) {}

  ngOnInit(): void {
    this.modalService.open(SplashScreenImageModalComponent.handle, undefined, {
      url: this.url,
      content: this.content,
      maxWidth: this.maxWidth,
      maxHeight: this.maxHeight,
      closeDelay: this.closeDelay,
    });
  }

  ngOnDestroy(): void {
    this.modalService.close(SplashScreenImageModalComponent.handle);
  }
}
