<ng-container *ngIf="selectIsZeroSixPluginEnabled | async">
  <ng-container *ngIf="memberCard">
    <div
      class="mb-5 bg-neutral-100 lg:rounded lg:rounded-s"
      [ngClass]="{
        'max-w-md mx-auto lg:max-w-4xl': width === 'centered',
        'w-full': width === 'full',
      }"
      [style.color]="textColor | contentBuilderColor"
    >
      <div
        class="flex items-center justify-between px-5 pb-2 pt-6"
        *ngIf="heading"
      >
        <h2
          class="font-accent text-2xl font-bold lg:text-3xl"
          [style.color]="headingColor | contentBuilderColor"
        >
          {{ heading | translate }}
        </h2>
      </div>
      <div
        class="px-5 pb-2 font-base text-sm lg:text-base"
        *ngIf="subheading"
      >
        {{ subheading }}
      </div>
      <div class="flex flex-row items-center px-5 pb-2 font-base text-sm lg:text-base">
        <i class="icon icon-coins mt-0.5 pr-1.5"></i>
        <ng-container *ngIf="!memberCard.points_balance && memberCard.terms_accepted">
          <span
            [translate]="'zerosix.amount-of-0-point'"
            [translateParams]="{ value: 'world' }"
          ></span>
        </ng-container>
        <ng-container *ngIf="memberCard.points_balance && memberCard.terms_accepted">
          <span
            [translate]="'zerosix.amount-of-points'"
            [translateParams]="{
              points: memberCard.points_balance,
            }"
          ></span>
        </ng-container>
        <ng-container *ngIf="!memberCard.latent_points_balance && !memberCard.terms_accepted">
          <span
            [translate]="'zerosix.amount-of-0-point-latent'"
            [translateParams]="{ value: 'world' }"
          ></span>
        </ng-container>
        <ng-container *ngIf="memberCard.latent_points_balance && !memberCard.terms_accepted">
          <span
            [translate]="'zerosix.amount-of-points-latent'"
            [translateParams]="{
              points: memberCard.latent_points_balance,
            }"
          ></span>
        </ng-container>
        &nbsp;—&nbsp;
        <span
          [translate]="'zerosix.member-level'"
          [translateParams]="{ level: memberCard.current_level }"
        ></span>
      </div>
      <div
        class="flex flex-row items-center px-5 pb-2 font-base text-sm lg:text-base"
        *ngIf="!memberCard.terms_accepted"
      >
        {{ 'zerosix.terms-not-accepted-yet' | translate }}
      </div>
      <div
        class="px-5 pb-6 pt-2 font-base text-sm lg:text-base"
        *ngIf="!assets || assets.length === 0"
      >
        {{ 'zerosix.no-assets' | translate }}
      </div>
      <ng-container *ngIf="!isDesktop">
        <ul
          class="flex overflow-x-auto px-5 pb-8 pt-2 scrollbar-hide"
          *ngIf="assets.length > 0"
        >
          <li
            class="w-6/12 max-w-56 flex-shrink-0 flex-grow-0 pr-3"
            [ngClass]="{
              'opacity-50': !asset.is_usable,
            }"
            *ngFor="let asset of assets"
          >
            <div
              class="flex h-full flex-col justify-between overflow-hidden rounded-lg bg-white shadow-S"
            >
              <div class="flex h-full flex-col justify-between px-4 py-4 text-sm">
                <div>
                  <p
                    class="mb-2 pt-1 font-base text-base font-bold leading-5 lg:text-md"
                    [style.color]="assetNameColor | contentBuilderColor"
                  >
                    {{ asset.name }}
                  </p>
                  <div class="flex flex-row">
                    <app-tag-atom
                      *ngIf="asset.type === zerosixAssetType.VOUCHER"
                      color="success"
                      size="S"
                      [text]="getAssetTag(asset)"
                      class="pb-2 pr-2"
                    ></app-tag-atom>
                    <app-tag-atom
                      *ngIf="asset.type === zerosixAssetType.REWARD"
                      color="highlight"
                      size="S"
                      [text]="asset.level_name ?? ''"
                      class="pb-2 pr-2"
                    ></app-tag-atom>
                    <app-tag-atom
                      *ngIf="asset.points_required"
                      color="neutral"
                      size="S"
                      [text]="asset.points_required + ' points'"
                      class="pb-2 pr-2"
                    ></app-tag-atom>
                  </div>
                </div>
                <div>
                  <p
                    *ngIf="!asset.is_usable"
                    [style.color]="assetDetailsColor | contentBuilderColor"
                  >
                    {{ 'zerosix.accessible-in-points' | translate: asset }}
                  </p>
                </div>

                <div
                  class="mt-6 flex flex-col items-center justify-center"
                  *ngIf="asset.is_usable"
                >
                  <div
                    class="mb-2 flex flex-row items-center justify-center text-sm font-normal leading-snug lg:text-base"
                    *ngIf="isAssetInCart(asset)"
                  >
                    <i class="icon icon-check-circle mr-2 text-success-600"></i>
                    {{ 'zerosix.asset-added-to-cart' | translate }}
                  </div>
                  <app-button-atom
                    *ngIf="!isAssetInCart(asset)"
                    [label]="'zerosix.use-voucher' | translate"
                    (click)="useAsset(asset)"
                    icon="icon-plus"
                    size="XS"
                    thickSize="XS"
                    type="outlined"
                    color="neutral"
                  ></app-button-atom>
                  <app-button-atom
                    *ngIf="isAssetInCart(asset)"
                    [label]="'zerosix.remove-voucher' | translate"
                    (click)="removeAsset(asset)"
                    icon="icon-c-remove"
                    size="XS"
                    thickSize="XS"
                    type="outlined"
                    color="neutral"
                  ></app-button-atom>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </ng-container>
      <ng-container *ngIf="isDesktop">
        <div class="overflow-x-auto px-5 pb-8 pt-2">
          <app-card-carousel
            [carouselItem]="productTemplate1"
            [nbDisplayCards]="itemsToShowCardCarousel"
            [cards]="assets"
          >
          </app-card-carousel>
        </div>

        <ng-template
          #productTemplate1
          let-asset
        >
          <div class="flex-1 items-stretch overflow-hidden rounded-lg bg-white shadow-S">
            <div class="flex h-full flex-col justify-between px-4 py-4 text-sm">
              <div>
                <p
                  class="mb-2 pt-1 font-base text-base font-bold leading-5 lg:text-md"
                  [style.color]="assetNameColor | contentBuilderColor"
                >
                  {{ asset.name }}
                </p>
                <div class="flex flex-row">
                  <app-tag-atom
                    *ngIf="asset.type === zerosixAssetType.VOUCHER"
                    color="success"
                    size="S"
                    [text]="getAssetTag(asset)"
                    class="pb-2 pr-2"
                  ></app-tag-atom>
                  <app-tag-atom
                    *ngIf="asset.type === zerosixAssetType.REWARD"
                    color="highlight"
                    size="S"
                    [text]="asset.level_name"
                    class="pb-2 pr-2"
                  ></app-tag-atom>
                  <app-tag-atom
                    *ngIf="asset.points_required"
                    color="neutral"
                    size="S"
                    [text]="asset.points_required + ' points'"
                    class="pb-2 pr-2"
                  ></app-tag-atom>
                </div>
                <p
                  *ngIf="!asset.is_usable"
                  [style.color]="assetDetailsColor | contentBuilderColor"
                >
                  {{ 'zerosix.accessible-in-points' | translate: asset }}
                </p>
              </div>

              <div
                class="mt-6 flex flex-col items-center justify-center"
                *ngIf="asset.is_usable"
              >
                <div
                  class="mb-2 flex flex-row items-center justify-center text-sm font-normal leading-snug lg:text-base"
                  *ngIf="isAssetInCart(asset)"
                >
                  <i class="icon icon-check-circle mr-2 text-success-600"></i>
                  {{ 'zerosix.asset-added-to-cart' | translate }}
                </div>
                <app-button-atom
                  *ngIf="!isAssetInCart(asset)"
                  [label]="'zerosix.use-voucher' | translate"
                  (click)="useAsset(asset)"
                  icon="icon-plus"
                  size="XS"
                  thickSize="XS"
                  type="outlined"
                  color="neutral"
                ></app-button-atom>
                <app-button-atom
                  *ngIf="isAssetInCart(asset)"
                  [label]="'zerosix.remove-voucher' | translate"
                  (click)="removeAsset(asset)"
                  icon="icon-c-remove"
                  size="XS"
                  thickSize="XS"
                  type="outlined"
                  color="neutral"
                ></app-button-atom>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
