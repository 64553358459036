import { ReducerTypes, ActionCreator, on } from '@ngrx/store';

import {
  TransactionState,
  TRANSACTION_STORE_ACTIVE_ADAPTER,
  TRANSACTION_STORE_INITIAL_STATE,
  TRANSACTION_STORE_KEY,
} from './transaction.state';
import {
  TRANSACTION_STORE_ACTIVE_ACTIONS,
  TRANSACTION_STORE_HYDRATION_ACTIONS,
  TRANSACTION_STORE_LOCAL_ACTIONS,
} from './transaction.action';

import { createActiveStorePiece } from '@store/_abstract/active';
import { createHydratedStorePiece } from '@store/_abstract/hydrated';
import { DoodTransactionModel } from '@core/models/transaction.model';

export const TRANSACTION_STORE_API_PIECE: ReducerTypes<
  TransactionState,
  readonly ActionCreator[]
>[] = [];

export const TRANSACTION_STORE_LOCAL_PIECE: ReducerTypes<
  TransactionState,
  readonly ActionCreator[]
>[] = [
  // Amount
  on(TRANSACTION_STORE_LOCAL_ACTIONS.updateAmount, (state, { amount }): TransactionState => {
    return { ...state, amount };
  }),
  on(TRANSACTION_STORE_LOCAL_ACTIONS.resetAmount, (state): TransactionState => {
    return { ...state, amount: null };
  }),

  // Payment Mode
  on(TRANSACTION_STORE_LOCAL_ACTIONS.updatePaymentMode, (state, { mode }): TransactionState => {
    return { ...state, paymentMode: mode };
  }),
  on(TRANSACTION_STORE_LOCAL_ACTIONS.resetPaymentMode, (state): TransactionState => {
    return { ...state, paymentMode: null };
  }),

  // Status
  on(TRANSACTION_STORE_LOCAL_ACTIONS.updateIsLoaded, (state, { isLoaded }): TransactionState => {
    return { ...state, isLoaded };
  }),
  on(TRANSACTION_STORE_LOCAL_ACTIONS.updateIsLoading, (state, { isLoading }): TransactionState => {
    return { ...state, isLoading };
  }),

  // Error
  on(TRANSACTION_STORE_LOCAL_ACTIONS.updateError, (state, { error }): TransactionState => {
    return { ...state, error };
  }),
  on(TRANSACTION_STORE_LOCAL_ACTIONS.resetError, (state): TransactionState => {
    return { ...state, error: null };
  }),

  // Misc
  on(TRANSACTION_STORE_LOCAL_ACTIONS.reset, (): TransactionState => {
    return TRANSACTION_STORE_INITIAL_STATE;
  }),
];

export const TRANSACTION_STORE_ACTIVE_PIECE = createActiveStorePiece<
  TransactionState,
  DoodTransactionModel
>({
  adapter: TRANSACTION_STORE_ACTIVE_ADAPTER,
  actions: TRANSACTION_STORE_ACTIVE_ACTIONS,
});

export const TRANSACTION_STORE_HYDRATION_PIECE = createHydratedStorePiece<TransactionState>(
  TRANSACTION_STORE_KEY,
  TRANSACTION_STORE_HYDRATION_ACTIONS,
);
