import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-header-link-block',
  templateUrl: './header-link-block.component.html',
})
export class HeaderLinkBlockComponent {
  @Input() label?: string;
  @Input() linkUrl?: string;
}
