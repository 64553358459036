import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  input,
  OnDestroy,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import { NgModel } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { KioskVirtualKeyboardComponent } from '@shared/pages/kiosk/kiosk-virtual-keyboard/kiosk-virtual-keyboard.component';
import { Subject, Subscription, takeUntil } from 'rxjs';

@Directive({
  selector: '[appVirtualKeyboard]',
  host: {
    'readonly': 'true', // avoid to open the native keyboard
  },
})
export class VirtualKeyboardDirective implements OnDestroy {
  // todo review the logic to display/hide the keyboard
  public isOverFooter = input<boolean>();
  private keyboardVisible = false;
  private keyboardRef: ComponentRef<KioskVirtualKeyboardComponent> | null = null;
  private routerSubscription?: Subscription;

  backgroundColor = '#000';
  textColor = '#fff';

  @HostListener('focus')
  onFocus() {
    if (!this.keyboardVisible) {
      this.showVirtualKeyboard();
      this.keyboardVisible = true;
    }
  }

  constructor(
    private el: ElementRef,
    private viewRef: ViewContainerRef,
    private renderer: Renderer2,
    private ngModel: NgModel,
    private router: Router,
  ) {
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.hideVirtualKeyboard();
      }
    });
  }

  private showVirtualKeyboard() {
    if (!this.keyboardRef) {
      const keyboardRefDestroyed$ = new Subject<void>();
      this.keyboardRef = this.viewRef.createComponent(KioskVirtualKeyboardComponent);

      this.keyboardRef.instance.backgroundColor = this.backgroundColor;
      this.keyboardRef.instance.textColor = this.textColor;
      this.keyboardRef.instance.isOverFooter = this.isOverFooter();

      this.keyboardRef.instance.keyPress
        .pipe(takeUntil(keyboardRefDestroyed$))
        .subscribe(key => this.handleKeyPress(key));
      this.keyboardRef.instance.onBlurEvent.pipe(takeUntil(keyboardRefDestroyed$)).subscribe(() => {
        keyboardRefDestroyed$.next();
        this.hideVirtualKeyboard();
      });
      this.keyboardRef.instance.isFirstTouch = !this.el.nativeElement.value;
      document.body.appendChild(this.keyboardRef.location.nativeElement);
    }
  }

  ngOnDestroy() {
    this.hideVirtualKeyboard();
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  private hideVirtualKeyboard() {
    if (this.keyboardRef) {
      this.keyboardRef.location.nativeElement.remove();
      this.keyboardRef.destroy();
      this.keyboardRef = null;
      this.keyboardVisible = false;
    }
  }

  private handleKeyPress(key: string) {
    console.log(key);
    if (key.toUpperCase() === 'BACKSPACE') {
      this.renderer.setProperty(
        this.el.nativeElement,
        'value',
        this.el.nativeElement.value.slice(0, -1),
      );
    } else if (key.toUpperCase() === 'SUPPRIMER') {
      this.renderer.setProperty(this.el.nativeElement, 'value', '');
    } else if (key.toUpperCase() === 'ENTRER') {
      this.hideVirtualKeyboard();
    } else {
      this.renderer.setProperty(this.el.nativeElement, 'value', this.el.nativeElement.value + key);
    }
    if (this.ngModel && this.ngModel.control) {
      this.ngModel.control.setValue(this.el.nativeElement.value);
    }
    if (!this.el.nativeElement.value && this.keyboardRef) {
      this.keyboardRef.instance.isFirstTouch = true;
    }
  }
}
