import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-payment-panel-adyen-meal-voucher-natixis',
  templateUrl: './payment-panel-adyen-meal-voucher-natixis.component.html',
})
export class PaymentPanelAdyenMealVoucherNatixisComponent {
  @Input() clientKey?: string;
  @Input() paymentMethodMealVoucherNatixisAvailable = false;
}
