import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationHistoryService } from '@core/services/navigation-history/navigation-history.service';
import {
  IContentBuilderFieldColor,
  IContentBuilderFieldImage,
} from '@core/models/content-builder-fields.model';
import { Meta } from '@angular/platform-browser';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import {ColorFieldTypesValues} from "@config/values/color-field-types.values";

@Component({
  selector: 'app-kiosk-splash-screen-page',
  templateUrl: './kiosk-splash-screen-page.component.html',
  styleUrls: ['./kiosk-splash-screen-page.component.scss'],
})
export class KioskSplashScreenPageComponent implements OnInit {
  @Input() body: any;
  @Input() footerKiosk: any;

  @Input() title?: string;
  @Input() labelCallToAction?: string;
  @Input() linkUrl!: string;
  @Input() displayAccessibilityButton: boolean = true;
  @Input() labelAccessibilityButton?: string;
  @Input() backgroundColor?: IContentBuilderFieldColor = {
    value: 'primary-800',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() backgroundImage?: IContentBuilderFieldImage;
  @Input() textColor?: IContentBuilderFieldColor = {
    value: '#FFFFFF',
    type: ColorFieldTypesValues.Hex,
  };

  @Input() metaDescription?: string;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly navigationHistoryService: NavigationHistoryService,
    private el: ElementRef,
    private readonly meta: Meta,
    private router: Router,
    private helperRouter: RouterHelperService,
  ) {}

  ngOnInit(): void {
    this.navigationHistoryService.pushStateFromActivatedRouteSnapshot(
      '/' + this.activatedRoute.snapshot.url.join('/'),
      this.activatedRoute.snapshot,
    );

    if (this.textColor?.value) {
      this.setTextOverrideColor();
    }
    if (this.metaDescription) {
      this.meta.updateTag({
        name: 'description',
        content: this.metaDescription,
      });
    }
  }

  setTextOverrideColor(): void {
    const textColor = this.textColor?.value;
    this.el.nativeElement.style.setProperty('--my-class-bg-color', textColor);
  }

  getBackgroundStyles() {
    return {
      'background-color': this.backgroundColor ? this.backgroundColor : null,
      'background-image': this.backgroundImage ? `url(${this.backgroundImage.url})` : null,
      'background-size': this.backgroundImage ? 'cover' : null,
    };
  }

  redirect(): void {
    this.router.navigate([this.helperRouter.translateRoute(this.linkUrl)]);
  }

  onInnerClick(event: Event): void {
    event.stopPropagation();
  }
}
