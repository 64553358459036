import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ProductStoreDispatcher } from '@common/dispatchers/product.dispatcher';
import { CartStoreRefiner } from '@common/refiners/cart.refiner';
import { ProductStoreRefiner } from '@common/refiners/product.refiner';
import { ShopStoreRefiner } from '@common/refiners/shop.refiner';
import { DeclinableStoreSelector } from '@common/selectors/declinable.selector';
import { ProductStoreSelector } from '@common/selectors/product.selector';
import { CartItemKeys } from '@config/keys/cart.keys';
import { ICartItem } from '@core/models/cart.model';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { DoodProductModel } from '@core/models/product.model';
import { IAdditionGroup } from '@core/models/shop.model';
import { CartService } from '@core/services/cart/cart.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { staggeredListAnimation } from '@shared/animations/list.animation';
import { DoodCategoryWithProduct } from '@shared/interfaces/category.interface';
import { KioskProductOverviewModalComponent } from '@shared/modals/kiosk-product-overview-modal/kiosk-product-overview-modal.component';
import { filter, map, Observable, take, tap } from 'rxjs';

@Component({
  selector: 'app-kiosk-category-page',
  templateUrl: './kiosk-category-page.component.html',
  animations: [staggeredListAnimation],
})
export class KioskCategoryPageComponent implements OnInit {
  @Input() onCartItemAdded: (cartItem: ICartItem) => void = () => {};
  @Input() backgroundTitleColor?: IContentBuilderFieldColor;
  @Input() textColor?: IContentBuilderFieldColor;
  @Input() cardColor?: IContentBuilderFieldColor;
  @Input() secondaryColor?: IContentBuilderFieldColor;
  @Input() buttonBackgroundColor?: IContentBuilderFieldColor;
  @Input() buttonTextColor?: IContentBuilderFieldColor;
  @Input() unavailableButtonBackground?: IContentBuilderFieldColor;
  @Input() unavailableButtonText?: IContentBuilderFieldColor;
  categoryId: string | undefined = undefined;

  product$ = this.productSelector.selectActive;

  categoryWithProducts$?: Observable<DoodCategoryWithProduct | null>;
  declinables$ = this.declinableSelector.selectDeclinables;
  additionsByGroup$ = this.productRefiner.selectAdditionsByGroups;
  additions$ = this.productRefiner.selectAdditions.pipe(
    map(additions => additions.filter(v => !v.addition_group && v.id)),
  );
  selectedAdditions: string[] = [];
  selectedAdditionsByGroup: (IAdditionGroup & {
    selectedAdditions: string[];
  })[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private shopRefiner: ShopStoreRefiner,
    private readonly modalsService: ModalsService,
    private declinableSelector: DeclinableStoreSelector,
    private productRefiner: ProductStoreRefiner,
    private productDispatcher: ProductStoreDispatcher,
    private productSelector: ProductStoreSelector,
    private cartService: CartService,
    private cartStoreRefiner: CartStoreRefiner,
  ) {}

  ngOnInit(): void {
    this.updateCategoryId();

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        tap(() => this.updateCategoryId()),
      )
      .subscribe();

    this.route.params.subscribe(() => this.updateCategoryId());
  }

  addToCart(product: DoodProductModel): void {
    const newCartItem: Omit<ICartItem, 'id'> = {
      [CartItemKeys.ItemId]: product?.id,
      [CartItemKeys.ShopId]: product?.shop_id,
      [CartItemKeys.Additions]: this.selectedAdditions,
      [CartItemKeys.Quantity]: 1,
    };
    if (!newCartItem) return;

    this.cartStoreRefiner.selectActiveCartProducts.pipe(take(1)).subscribe(cartItems => {
      const itemInCart = this.cartService.findMatchingCartItem({
        cartItems,
        searchCartItem: newCartItem,
      });
      if (itemInCart) {
        this.cartService.updateCartItem(
          {
            ...itemInCart,
            quantity: itemInCart.quantity + 1,
          },
          false,
        );
      } else {
        this.cartService.addCartItem$({ ...newCartItem, quantity: 1 }).subscribe();
      }
    });
  }

  private updateCategoryId(): void {
    const categoryId = this.getCategoryIdFromRoute(this.route);
    if (categoryId) {
      this.categoryId = categoryId;
      this.categoryWithProducts$ = this.shopRefiner.selectCategoryById(categoryId);
    } else {
      this.categoryId = undefined;
    }
  }

  private getCategoryIdFromRoute(route: ActivatedRoute): string | null {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route.snapshot.paramMap.get('categoryId');
  }

  addProductDispatcher(product: DoodProductModel): void {
    if (product.type === 'SIMPLE' && product.additions.products.length === 0) {
      this.addToCart(product);
    } else {
      this.navigateToProduct(product);
    }
  }

  openProductModal(product: DoodProductModel): void {
    this.productDispatcher.insertActive(product);
    this.modalsService.open(KioskProductOverviewModalComponent.handle, undefined, {
      image: product.images?.cover?.url,
      name: product.name,
      description: product.description,
      allergens: product.allergens,
      type: product.type,
      backgroundColor: this.buttonTextColor,
      accentColor: this.secondaryColor,
    });
  }

  openAddProductModal(product: DoodProductModel): void {
    this.productDispatcher.insertActive(product);
    this.modalsService.open(KioskProductOverviewModalComponent.handle, undefined, {
      image: product.images?.cover?.url,
      name: product.name,
      description: product.description,
      allergens: product.allergens,
      price: product.price,
      type: product.type,
      backgroundColor: this.buttonTextColor,
      accentColor: this.secondaryColor,
      navigateToProduct: () => this.navigateToProduct(product),
    });
  }

  navigateToProduct(product: DoodProductModel): void {
    const shopCity = this.route.snapshot.paramMap.get('shopCity');
    const shopType = this.route.snapshot.paramMap.get('shopType');
    const shopSlug = this.route.snapshot.paramMap.get('shopSlug');

    this.productDispatcher.setActiveCategory(this.categoryId);

    this.router.navigate([
      `/fr/${shopCity}/${shopType}/${shopSlug}/product/${product.id}/product-add`,
    ]);
  }

  back(): void {
    const shopCity = this.route.snapshot.paramMap.get('shopCity');
    const shopType = this.route.snapshot.paramMap.get('shopType');
    const shopSlug = this.route.snapshot.paramMap.get('shopSlug');

    this.router.navigate([`/fr/${shopCity}/${shopType}/${shopSlug}`]);
  }
}
