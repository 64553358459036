/* eslint-disable ngrx/avoid-dispatching-multiple-actions-sequentially */
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import {
  AUTH_STORE_API_ACTIONS,
  AUTH_STORE_HYDRATION_ACTIONS,
  AUTH_STORE_LOCAL_ACTIONS,
  AuthenticationGuardState,
  AuthenticationPermissionsState,
  AuthenticationStatusState,
} from '@store/authentication';
import { DoodUserModel } from '@store/authentication/authentication.model';

@Injectable({ providedIn: 'root' })
export class AuthStoreDispatcher {
  constructor(private store: Store) {}

  // Api
  fetchUserSuccess(user: DoodUserModel): void {
    this.store.dispatch(AUTH_STORE_API_ACTIONS.fetchUserSuccess({ user }));
  }

  // Status
  updateStatus(status: Partial<AuthenticationStatusState>): void {
    this.store.dispatch(AUTH_STORE_LOCAL_ACTIONS.updateStatus({ status }));
  }

  // Guard
  updateGuard(guard: Partial<AuthenticationGuardState>): void {
    this.store.dispatch(AUTH_STORE_LOCAL_ACTIONS.updateGuard({ guard }));
  }

  // Permissions
  updatePermissions(permissions: Partial<AuthenticationPermissionsState>): void {
    this.store.dispatch(AUTH_STORE_LOCAL_ACTIONS.updatePermissions({ permissions }));
  }

  // Misc
  reset(): void {
    this.store.dispatch(AUTH_STORE_LOCAL_ACTIONS.reset());
  }

  resetAndClear(): void {
    this.store.dispatch(AUTH_STORE_LOCAL_ACTIONS.reset());
    this.store.dispatch(AUTH_STORE_HYDRATION_ACTIONS.clear());
  }
}
