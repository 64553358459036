import {Component, OnInit} from '@angular/core';
import {
  ReceiveReceiptModalComponent,
  ReceiveReceiptModalReceiptType,
} from '@shared/modals/receive-receipt-modal/receive-receipt-modal.component';
import {ModalsService} from '@core/services/modals/modals.service';
import {OrderStoreSelector} from '@common/selectors/order.selector';

@Component({
  selector: 'app-order-receive-receipt-block',
  templateUrl: './order-receive-receipt-block.component.html',
  styles: [],
})
export class OrderReceiveReceiptBlockComponent implements OnInit {
  constructor(
    private readonly modalsService: ModalsService,
    private readonly orderSelector: OrderStoreSelector,
  ) {
  }

  ngOnInit(): void {}

  openModalReceipt(): void {
    const order = this.orderSelector.active;
    const payment = order?.payments?.[0];
    if (!payment) {
      return;
    }

    this.modalsService.open(ReceiveReceiptModalComponent.handle, undefined, {
      receiptType: ReceiveReceiptModalReceiptType.payment,
      receiptId: payment.id,
    });
  }
}
