import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges,} from '@angular/core';
import {keyBy} from 'lodash';
import dayjs from 'dayjs';
import {Subject} from 'rxjs';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import {TranslateService} from '@ngx-translate/core';

import {DateUtils} from '@shared/utils/date/date.utils';
import {ITimeSlot} from '@shared/utils/time-slots/time-slots.utils';

import {ERRORS} from '@config/labels/errors.labels';
import {ParametersWantedAtTypeValues} from '@config/values/parameters.values';
import {ParametersWantedAtTypeLabels} from '@config/labels/parameters.labels';
import {ISimpleItem, ISimpleTimeSlotItem} from '@core/models/simple-item.model';
import {OrderTypeValues} from "@config/values/order.values";

@Component({
  selector: 'app-parameters-wanted-at-block',
  templateUrl: './parameters-wanted-at-block.component.html',
})
export class ParametersWantedAtBlockComponent implements OnInit, OnDestroy, OnChanges {
  readonly errorLabels = ERRORS;
  readonly parametersWantedAtTypeValues = ParametersWantedAtTypeValues;

  intervalMinuteTimeSlot = 15;
  parameters: ISimpleItem[] = [
    {
      value: ParametersWantedAtTypeValues.Asap,
      label: ParametersWantedAtTypeLabels.ASAP,
    },
    {
      value: ParametersWantedAtTypeValues.Later,
      label: ParametersWantedAtTypeLabels.LATER,
    },
  ];

  private destroyed$ = new Subject<boolean>();

  @Input() dateSlots?: Date[];
  @Input() timeSlots?: ITimeSlot[];
  @Input() wantedAtType!: ParametersWantedAtTypeValues;
  @Input() wantedAtDate?: Date;
  @Input() wantedAtTime?: Date;
  @Input() displayAsapForLaterPicker = true;
  @Input() distributionMode: string = '';

  @Output() typeChanged = new EventEmitter();
  @Output() dateChanged = new EventEmitter();
  @Output() timeChanged = new EventEmitter();

  displayedDateSlots?: ISimpleItem[];
  displayedTimeSlots?: ISimpleTimeSlotItem[];

  selectedWantedAtType?: string;
  selectedWantedAtDate?: string;
  selectedWantedAtTime?: number;
  timeSlotNotAvailable = false;
  newSlotSelected = false;

  constructor(private readonly translateService: TranslateService) {
    dayjs.extend(isToday);
    dayjs.extend(isTomorrow);
  }

  ngOnInit(): void {
    if (this.displayAsapForLaterPicker === false) {
      this.wantedAtType = this.parametersWantedAtTypeValues.Later;
    }
    this.updateDisplayedDateSlots();
    this.updateDisplayedTimeSlots();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dateSlots || changes.wantedAtDate) {
      this.updateDisplayedDateSlots();
    }
    if (changes.timeSlots || changes.wantedAtTime) {
      this.updateDisplayedTimeSlots();
    }
    if (changes.wantedAtType) {
      this.selectedWantedAtType = this.wantedAtType;
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onTypeChanged(type: ISimpleItem): void {
    this.typeChanged.emit(type.value);
  }

  onDateChanged(value: string): void {
    this.dateChanged.emit(value);
    this.newSlotSelected = true;
  }

  onTimeChanged(value: number): void {
    const slotsById = keyBy(this.displayedTimeSlots, 'value');
    const startTime = slotsById[value].slot.start;
    this.timeChanged.emit(startTime);
    this.newSlotSelected = true;
  }

  private updateDisplayedDateSlots(): void {
    if (!this.dateSlots) {
      this.displayedDateSlots = undefined;
      return;
    }
    this.displayedDateSlots = this.dateSlots.map(slot => {
      return {
        value: DateUtils.dayjsInMarketplaceTimezone(slot).format('MM/DD/YYYY'),
        label: DateUtils.dayjsInMarketplaceTimezone(slot).isToday()
          ? 'parameters.wantedAt.today'
          : DateUtils.dayjsInMarketplaceTimezone(slot).isTomorrow()
            ? 'parameters.wantedAt.tomorrow'
            : DateUtils.dayjsInMarketplaceTimezone(slot).format('dddd D MMMM, YYYY'),
      };
    });

    const selectedDate = this.displayedDateSlots.find(
      el =>
        el.value === DateUtils.dayjsInMarketplaceTimezone(this.wantedAtDate).format('MM/DD/YYYY'),
    );
    if (selectedDate) {
      this.selectedWantedAtDate = selectedDate.value;
    }
  }

  private updateDisplayedTimeSlots(): void {
    if (!this.timeSlots) {
      this.displayedTimeSlots = undefined;
      return;
    }


    if (this.dateSlots?.length && this.timeSlots) {
      this.timeSlotNotAvailable = this.checkTimeSlotsIsAvailableWithDateSlots(
        this.dateSlots,
        this.timeSlots,
      );
    }

    if (this.timeSlotNotAvailable) {
      this.displayedTimeSlots = undefined;
      return;
    }

    this.displayedTimeSlots = this.timeSlots?.map(slot => {
      // Add 15 min to time displayed if delivery
      const deltaDisplayed = this.distributionMode === OrderTypeValues.Delivery ? 900 : 0;
      const startDisplayed = slot.start.add(deltaDisplayed, 'second');
      const endDisplayed = slot.end.add(deltaDisplayed, 'second');

      return {
        value: slot.id,
        label: `${DateUtils.dayjsInMarketplaceTimezone(startDisplayed).format(this.translateService.instant('parameters.wantedAt.slots-format'))} - ${DateUtils.dayjsInMarketplaceTimezone(endDisplayed).format(this.translateService.instant('parameters.wantedAt.slots-format'))}`,
        slot: {
          start: new Date(DateUtils.dayjsInMarketplaceTimezone(slot.start).format()),
          end: new Date(DateUtils.dayjsInMarketplaceTimezone(slot.end).format()),
        },
      };
    });

    const selectedSlot = this.displayedTimeSlots?.find(el => {
      const startDate = DateUtils.dayjsInMarketplaceTimezone(el.slot.start).format();
      const wantedAtDate = DateUtils.dayjsInMarketplaceTimezone(this.wantedAtTime).format();
      return startDate === wantedAtDate;
    });

    if (selectedSlot) {
      this.selectedWantedAtTime = selectedSlot.value;
    }
  }

  private checkTimeSlotsIsAvailableWithDateSlots(dates: Date[], times: ITimeSlot[]): boolean {
    const lastAvailableDayAtEndOfTheDay = dayjs(dates?.[dates?.length - 1]).endOf('day');
    const firstAvailableTimeSlot = dayjs(times?.[0]?.start);
    return lastAvailableDayAtEndOfTheDay.isBefore(firstAvailableTimeSlot);
  }
}
