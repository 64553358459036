import { map } from 'rxjs/operators';
import { Component } from '@angular/core';
import { ViewportScroller } from '@angular/common';

import { ISimpleItem } from '@core/models/simple-item.model';
import { ShopStoreSelector } from '@common/selectors/shop.selector';
import { ShopStoreRefiner } from '@common/refiners/shop.refiner';

@Component({
  selector: 'app-shop-category-tabs-block',
  templateUrl: './shop-category-tabs-block.component.html',
})
export class ShopCategoryTabsBlockComponent {
  shop$ = this.shopSelector.selectActive;
  filters$ = this.shopRefiner.selectActiveShopEnabledStores.pipe(
    map(stores =>
      stores.map(store => {
        return {
          value: store?.id,
          label: store?.name,
        };
      }),
    ),
  );

  constructor(
    private shopRefiner: ShopStoreRefiner,
    private shopSelector: ShopStoreSelector,
    private readonly viewportScroller: ViewportScroller,
  ) {}

  onClickFilter(event: ISimpleItem): void {
    this.viewportScroller.scrollToAnchor(event.value);
  }
}
