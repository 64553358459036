import { v4 as uuidV4 } from 'uuid';
import { combineLatest, of, Subject } from 'rxjs';
import { ViewportScroller } from '@angular/common';
import { map, skipWhile, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Component, ElementRef, Input, OnDestroy, OnInit, Type, ViewChild } from '@angular/core';

import { DoodProductModel } from '@core/models/product.model';
import { ISimpleItem, ISubItem } from '@core/models/simple-item.model';

import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

import { ProductsService } from '@core/services/products/products.service';
import { ProductSelectorService } from '@core/services/product-selector/product-selector.service';
import { NavigationHistoryService } from '@core/services/navigation-history/navigation-history.service';
import { MarketplaceProductsService } from '@core/services/marketplace-products/marketplace-products.service';

import { MarketplaceCategoryHorizontalComponent } from '@shared/blocks/marketplace-products-block/marketplace-category-horizontal/marketplace-category-horizontal.component';
import { MarketplaceCategoryVerticalTwoLevelsComponent } from '@shared/blocks/marketplace-products-block/marketplace-category-vertical-two-levels/marketplace-category-vertical-two-levels.component';
import { ProductUtils } from '@app/utilities/product.util';

interface ProductCategory {
  id: string;
  title: string;
  products?: DoodProductModel[];
  componentClass: Type<unknown>;
  categories?: ProductCategory[];
  items?: Record<string, unknown>[];
  display: 'horizontal' | 'vertical' | 'vertical_two_levels';
}

@Component({
  selector: 'app-marketplace-products-block',
  templateUrl: './marketplace-products-block.component.html',
})
export class MarketplaceProductsBlockComponent implements OnInit, OnDestroy {
  @Input() categories: ProductCategory[] = [];

  @ViewChild('categoryTabsContainer') categoryTabsContainer?: ElementRef;

  isMarketplaceAvailable$ = this.marketplaceSelector.selectMarketplaceAvailable;

  public tab!: ISimpleItem;
  public idsCategories: string[] = [];
  public categoryBlocks: { componentClass: Type<unknown> }[] = [];
  public tabCategories: ISimpleItem[] = [];

  protected readonly destroyed$ = new Subject<boolean>();

  constructor(
    private readonly productsService: ProductsService,
    private marketplaceSelector: MarketplaceStoreSelector,
    private readonly viewportScroller: ViewportScroller,
    private readonly productSelector: ProductSelectorService,
    private readonly navigationHistoryService: NavigationHistoryService,
    private readonly marketplaceProductsService: MarketplaceProductsService,
  ) {}

  ngOnInit(): void {
    this.productsService
      .selectAll$()
      .pipe(
        takeUntil(this.destroyed$),
        skipWhile(products => !products || products.length === 0),
        map(products => products.filter(p => ProductUtils.isProductVisible(p))),
        map(products => this.populateCategories(products)),
        tap(categories => this.populateCategoriesForTabs(categories)),
        tap(categoryBlocks => (this.categoryBlocks = categoryBlocks)),
      )
      .subscribe();

    combineLatest([this.marketplaceSelector.selectMarketplaceId, this.isMarketplaceAvailable$])
      .pipe(
        takeUntil(this.destroyed$),
        switchMap(([marketplaceId, isAvailable]) =>
          isAvailable ? this.marketplaceProductsService.loadProducts$(marketplaceId) : of(null),
        ),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private populateCategories(products: DoodProductModel[]): ProductCategory[] {
    return this.categories
      .map(category => {
        switch (category.display) {
          case 'horizontal':
            const productsInCategory = this.productSelector.selectProducts(
              products,
              category.items ?? [],
            );
            if (!productsInCategory || productsInCategory.length === 0) {
              return null;
            }
            return {
              ...category,
              id: uuidV4(),
              products: productsInCategory,
              componentClass: MarketplaceCategoryHorizontalComponent,
            };
          case 'vertical_two_levels':
            const subcategories = (category.categories ?? [])
              .map(subcategory => {
                const productsInSubcategory = this.productSelector.selectProducts(
                  products,
                  subcategory.items ?? [],
                );
                if (!productsInSubcategory || productsInSubcategory.length === 0) {
                  return null;
                }
                return {
                  ...subcategory,
                  id: uuidV4(),
                  products: this.productSelector.selectProducts(products, subcategory.items ?? []),
                };
              })
              .filter((subcategory: unknown) => !!subcategory);

            if (!subcategories || subcategories.length === 0) {
              return null;
            }

            return {
              ...category,
              id: uuidV4(),
              categories: subcategories,
              componentClass: MarketplaceCategoryVerticalTwoLevelsComponent,
            };
          default:
            return null;
        }
      })
      .filter(category => !!category) as ProductCategory[];
  }

  onBackButtonClick(): void {
    this.navigationHistoryService.goBack();
  }

  private populateCategoriesForTabs(categories: ProductCategory[]): void {
    this.tabCategories = categories.map(category => {
      return {
        label: category.title,
        value: category.id,
      } as ISimpleItem;
    });
    this.idsCategories = categories.map(category => {
      return String(category.id);
    });
  }

  onTabClick(category: { item: ISimpleItem; subItem?: ISubItem }): void {
    if (this.categoryTabsContainer && this.categoryTabsContainer.nativeElement) {
      const topOffset = this.categoryTabsContainer.nativeElement.offsetHeight || 60;
      this.viewportScroller.setOffset([0, topOffset]);
    }
    category.subItem
      ? this.viewportScroller.scrollToAnchor(category.subItem.value)
      : this.viewportScroller.scrollToAnchor(category.item.value);
  }

  changeTab(id: string): void {
    const _foundTab = this.tabCategories?.find(tab => tab.value === id);
    if (_foundTab) {
      this.tab = _foundTab;
    }
  }
}
