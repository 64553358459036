import { Component, input, model, output, signal } from '@angular/core';
import { ProductKeys } from '@config/keys/product.keys';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { DoodProductModel, IOverridesPrices } from '@core/models/product.model';
import { Subject } from 'rxjs';
import { outputFromObservable } from '@angular/core/rxjs-interop';
import { ICartItem } from '@core/models/cart.model';
import { IAdditionGroup } from '@core/models/shop.model';
import { DeclinableProductCart } from '@shared/local-store/cart/cart-draft-declinable-product.store';

@Component({
  selector: 'app-kiosk-product-details-organism',
  template: `
    <div
      class="flex flex-col gap-5"
      [style.backgroundColo]="backgroundColor() | contentBuilderColor"
      [style.color]="textColor() | contentBuilderColor"
    >
      <div *ngIf="(product().allergens?.length ?? 0) > 0">
        <div
          class="gap-0 border-[1px] border-solid"
          [style.borderColor]="textColor() | contentBuilderColor"
        ></div>
        <div class="flex cursor-pointer items-center justify-between py-4 text-xl font-bold">
          Allergènes
        </div>
        <div class="mb-5">
          @for (allergen of product().allergens; track allergen.id; let last = $last) {
            {{ allergen.name }}{{ !last ? ', ' : '' }}
          }
        </div>
        <div
          class="border-[1px] border-solid"
          [style.borderColor]="textColor() | contentBuilderColor"
        ></div>
      </div>
      <div class="mt-5">
        @if (product().type === 'SIMPLE') {
          <div class="mb-10">
            <app-kiosk-simple-product-details-organism
              [textColor]="textColor()"
              [cardColor]="cardColor()"
              [secondaryColor]="secondaryColor()"
              [backgroundColor]="backgroundColor()"
              [primaryButtonBackgroundColor]="primaryButtonBackgroundColor()"
              [unavailableButtonBackground]="unavailableButtonBackground()"
              [unavailableButtonText]="unavailableButtonText()"
              [primaryButtonTextColor]="primaryButtonTextColor()"
              [secondaryColor]="secondaryColor()"
              [simpleProduct]="product()"
              [activeShopId]="activeShopId()"
              [initialCart]="initialCart()"
              (cartData)="cartChange$.next($event)"
            ></app-kiosk-simple-product-details-organism>
          </div>
        }

        @if (product().type === 'DECLINABLE') {
          <div class="mb-10">
            <app-kiosk-select-declinable-products-organism
              [textColor]="textColor()"
              [cardColor]="cardColor()"
              [buttonBackgroundColor]="buttonBackgroundColor()"
              [buttonTextColor]="buttonTextColor()"
              [secondaryColor]="secondaryColor()"
              [backgroundColor]="backgroundColor()"
              [primaryButtonBackgroundColor]="primaryButtonBackgroundColor()"
              [unavailableButtonBackground]="unavailableButtonBackground()"
              [unavailableButtonText]="unavailableButtonText()"
              [primaryButtonTextColor]="primaryButtonTextColor()"
              [secondaryColor]="secondaryColor()"
              [activeShopId]="activeShopId()"
              [declinableProduct]="product()"
              [initialCart]="initialCart()"
              (cartData)="cartChange$.next($event)"
              (additionGroupName)="onAdditionsByGroupChange($event)"
            ></app-kiosk-select-declinable-products-organism>
          </div>
        }
      </div>
    </div>
  `,
})
export class KioskProductDetailsOrganismComponent {
  protected readonly cartChange$ = new Subject<
    | {
        cart: DeclinableProductCart;
        isValid: boolean;
      }
    | undefined
  >();

  public readonly cardColor = input<IContentBuilderFieldColor>();
  public readonly primaryButtonBackgroundColor = input<IContentBuilderFieldColor>();
  public readonly primaryButtonTextColor = input<IContentBuilderFieldColor>();
  public readonly textColor = input<IContentBuilderFieldColor>();
  public readonly backgroundColor = input<IContentBuilderFieldColor>();
  public readonly secondaryColor = input<IContentBuilderFieldColor>();
  public readonly buttonBackgroundColor = input<IContentBuilderFieldColor>();
  public readonly buttonTextColor = input<IContentBuilderFieldColor>();
  public readonly unavailableButtonBackground = input<IContentBuilderFieldColor>();
  public readonly unavailableButtonText = input<IContentBuilderFieldColor>();
  public readonly displayPriceInfo = input<boolean>(true);
  public readonly initialCart = input<ICartItem | undefined>();

  public readonly product = model.required<Readonly<DoodProductModel>>();
  public readonly activeShopId = input.required<string>();
  public readonly simpleProductAdditionsByGroups = input.required<IAdditionGroup[]>(); // required for simple product
  public readonly simpleProductPriceOverrides = input<IOverridesPrices | undefined>();

  public readonly additionGroupName = output<string>();

  public readonly cartData = outputFromObservable(this.cartChange$);

  //TODO HANDLE LOADING

  protected readonly allergensToggled = signal(false);

  protected get ProductKeys(): typeof ProductKeys {
    return ProductKeys;
  }

  protected countChange(newQuantity: number) {
    this.product.update(state => {
      return {
        ...state,
        quantity: newQuantity,
      };
    });
  }

  protected onAdditionsByGroupChange(additionName: any) {
    this.additionGroupName.emit(additionName);
  }
}
