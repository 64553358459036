import { Observable, Subject } from 'rxjs';
import { map, skipWhile, takeUntil, tap } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { UserService } from '@core/services/user/user.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { ZerosixService } from '@core/services/zerosix/zerosix.service';

import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';

import { UserKeys } from '@config/keys/user.keys';
import { PatternUtil } from '@shared/utils/pattern/pattern.utils';
import { ModalScrollBlockBase } from '@core/base/modalScrollBlock/modal-scroll-block.base';
import { ZerosixLinkAccountModalComponent } from '@shared/modals/zerosix/zerosix-link-account-modal/zerosix-link-account-modal.component';

@Component({
  selector: 'app-zerosix-create-account-modal',
  templateUrl: './zerosix-create-account-modal.component.html',
})
export class ZerosixCreateAccountModalComponent
  extends ModalScrollBlockBase
  implements OnInit, OnDestroy
{
  private _destroyerRef = new Subject<boolean>();

  static handle = 'zerosix-create-account-modal';

  @Input() legalCheckboxLabel?: any;

  isDesktop$ = this.settingsSelector.selectDeviceIsDesktopScreen;

  index$: Observable<number> = this.modalSelector
    .selectModal(ZerosixCreateAccountModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  form: UntypedFormGroup;
  errorMessage?: string;
  phoneErrorMessage?: string;
  isValidating = false;
  step = 'join-form';

  constructor(
    private readonly fb: UntypedFormBuilder,
    private authSelector: AuthStoreSelector,
    private modalSelector: ModalStoreSelector,
    private readonly userService: UserService,
    private readonly modalsService: ModalsService,
    private settingsSelector: SettingsStoreSelector,
    private readonly zerosixService: ZerosixService,
  ) {
    super();
    this.form = this.fb.group({
      [UserKeys.Phone]: ['', [Validators.pattern(PatternUtil.phone), Validators.required]],
    });
  }

  ngOnInit(): void {
    this.authSelector.selectUser
      .pipe(
        takeUntil(this._destroyerRef),
        skipWhile(user => !user),
        tap(user => this.setPhoneNumber(user?.phone)),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
    super.ngOnDestroy();
  }

  close(): void {
    this.modalsService.close(ZerosixCreateAccountModalComponent.handle);
  }

  onSubmit(): void {
    if (this.form.invalid || this.isValidating) {
      return;
    }
    this.isValidating = true;
    this.errorMessage = undefined;
    this.phoneErrorMessage = undefined;

    this.zerosixService
      .createZerosixUser$(this.form.value[UserKeys.Phone])
      .pipe(
        tap(user => this.userService.setUser(user)),
        tap(() => this.goToConfirmationStep()),
      )
      .subscribe({
        error: error => this.handleErrorResponse(error.error),
        complete: () => (this.isValidating = false),
      });
  }

  private handleErrorResponse(error: any): void {
    this.isValidating = false;
    switch (error.code) {
      case 103:
        this.phoneErrorMessage = 'zerosix.create-account.errors.account-already-exists';
        break;
      case 100:
        this.phoneErrorMessage = 'signup.invalid-phone';
        break;
      default:
        this.errorMessage = 'zerosix.errors.generic-error';
    }
  }

  openLinkWithLoyaltyAccountModal(): void {
    this.close();
    this.modalsService.open(ZerosixLinkAccountModalComponent.handle);
  }

  private goToConfirmationStep(): void {
    this.step = 'confirmation';
  }

  private setPhoneNumber(phoneNumber?: string): void {
    this.form.setValue({
      [UserKeys.Phone]: phoneNumber,
    });
  }
}
