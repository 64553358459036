import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { DeviceService } from '@core/services/app/device.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { NavigationHistoryService } from '@core/services/navigation-history/navigation-history.service';

@Component({
  selector: 'app-order-details-page',
  templateUrl: './order-details-page.component.html',
})
export class OrderDetailsPageComponent implements OnInit, OnDestroy {
  @Input() body: any;

  interval: any;
  private destroyed$ = new Subject<boolean>();

  constructor(
    private readonly ordersService: OrdersService,
    private readonly deviceService: DeviceService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly navigationHistoryService: NavigationHistoryService,
  ) {
    this.activatedRoute.params
      .pipe(takeUntil(this.destroyed$))
      .subscribe(params => this.load(params.orderId));

    if (this.deviceService.isSafari()) {
      this.interval = setInterval(() => {
        this.getUrlOrderId();
      }, 30000);
    }
  }

  ngOnInit(): void {
    this.navigationHistoryService.pushStateFromActivatedRouteSnapshot(
      'order-detail',
      this.activatedRoute.snapshot,
    );
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.ordersService.clearOrders();
  }

  getUrlOrderId(): void {
    this.activatedRoute.params
      .pipe(takeUntil(this.destroyed$))
      .subscribe(params => this.load(params.orderId));
  }

  load(orderId: string): void {
    this.loadOrder(orderId);
  }

  loadOrder(orderId: string): void {
    this.ordersService.deActiveOrderToStore();
    this.ordersService.setLoadingToStore();
    this.ordersService
      .loadOrderById$(orderId)
      .pipe(
        takeUntil(this.destroyed$),
        tap(order => this.ordersService.setActiveOrderToStore(order)),
      )
      .subscribe();
  }
}
