import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {AnalyticsService} from "@core/services/app/analytics.service";
import {DoodProductModel} from "@core/models/product.model";
import {ProductKeys} from "@config/keys/product.keys";
import {CartStoreSelector} from "@common/selectors/cart.selector";
import {ProductService} from "@core/services/product/product.service";

@Injectable({
  providedIn: 'root'
})
export class ProductTrackerService {
  private productSubject = new Subject<any>();
  private displayedProducts: any[] = [];

  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly cartStoreSelector: CartStoreSelector,
    private readonly productService: ProductService,
  ) {
    this.productSubject.pipe(
      debounceTime(2000)
    ).subscribe(() => {
      if (this.displayedProducts.length > 0) {
        this.analyticsService.jitsuTrackEvent('products_displayed', {
          products: this.displayedProducts
        });
        console.log('products_displayed', {
          products: this.displayedProducts
        });

        // Réinitialiser la liste
        this.displayedProducts = [];
      }
    });
  }

  trackProductDisplayed(product: DoodProductModel, displayContext: string) {
    this.displayedProducts.push({
      display_context: displayContext,
      distribution_mode: this.cartStoreSelector.active?.type,
      product_sku: product[ProductKeys.ExternalId] ?? product[ProductKeys.Plu] ?? product[ProductKeys.Name] ?? product[ProductKeys.Id],
      product_id: product[ProductKeys.Id],
      product_name: product[ProductKeys.Name],
      product_external_id: product[ProductKeys.ExternalId],
      product_plu: product[ProductKeys.Plu],
      product_price: product[ProductKeys.FinalPrice],
      product_shop_id: product[ProductKeys.ShopId],
      product_available: this.productService.isAvailable(product),
    });

    this.productSubject.next(null);
  }
}
