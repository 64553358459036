import { createHydratedReducer } from '@store/_abstract/hydrated';

import {
  TRANSACTION_STORE_ACTIVE_PIECE,
  TRANSACTION_STORE_API_PIECE,
  TRANSACTION_STORE_HYDRATION_PIECE,
  TRANSACTION_STORE_LOCAL_PIECE,
} from './transaction.piece';
import { TRANSACTION_STORE_INITIAL_STATE, TRANSACTION_STORE_KEY } from './transaction.state';

export const TRANSACTION_STORE_REDUCER = createHydratedReducer({
  key: TRANSACTION_STORE_KEY,
  initialState: TRANSACTION_STORE_INITIAL_STATE,
  ons: [
    ...TRANSACTION_STORE_HYDRATION_PIECE,
    ...TRANSACTION_STORE_ACTIVE_PIECE,
    ...TRANSACTION_STORE_API_PIECE,
    ...TRANSACTION_STORE_LOCAL_PIECE,
  ],
});
