<div
  class="fixed bottom-0 left-0 right-0 w-full shadow shadow-top"
  [style.z-index]="50"
  [style.backgroundColor]="backgroundColor | contentBuilderColor"
  *ngIf="cart$ | async"
>
  <div class="mx-auto">
    <div *ngIf="(hideCartParameters$ | async) === false">
      <app-cart-parameters-overview
        [iconBackgroundColor]="iconBackgroundColor"
        [iconColor]="iconColor"
        [textColor]="labelColor"
      ></app-cart-parameters-overview>
    </div>

    <div
      *ngIf="(cartProducts$ | async)?.length"
      class="flex w-full items-center px-3 pb-3 kiosk:px-12 kiosk:py-8"
      [class.pb-6]="isNotched"
      [class.pt-2]="hideCartParameters$ | async"
    >
      <div class="mr-1.5 py-0.5 xm:mr-7.5 kiosk:hidden">
        <button
          class="h-12.5 w-13 rounded-full text-2xl"
          [style.backgroundColor]="buttonBackgroundColor | contentBuilderColor"
          [style.color]="buttonTextColor | contentBuilderColor"
          (click)="navigateToCart()"
        >
          {{ (cartProducts$ | async)?.length }}
        </button>
      </div>
      <div class="flex-1">
        <app-cart-footer-button-atom
          [label]="'shop-page.view-basket' | translate"
          [disabled]="isNavigationToCartInProgress"
          [iconUrl]="isNavigationToCartInProgress ? 'assets/images/spinner.svg' : ''"
          [icon]="!isNavigationToCartInProgress ? 'icon-shopping-bag' : ''"
          [buttonBackgroundColor]="buttonBackgroundColor"
          [buttonTextColor]="buttonTextColor"
          (click)="navigateToCart()"
        ></app-cart-footer-button-atom>
      </div>
      <div
        *ngIf="order$ | async as order"
        class="ml-7.5 hidden xm:block"
      >
        <app-price-atom
          [format]="true"
          [amount]="order?.final_price ?? 0"
        ></app-price-atom>
      </div>
    </div>
  </div>
</div>
