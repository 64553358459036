import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-marketplace-header-atom',
  templateUrl: './marketplace-header-atom.component.html',
})
export class MarketplaceHeaderAtomComponent {
  @Input() backButtonText = 'Retour';
  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() imgUrl!: string;

  @Output() backEvent = new EventEmitter();
}
