import { Injectable } from '@angular/core';
import { MarketplaceService } from '../../marketplace/marketplace.service';
import { MarketplaceKeys } from '@config/keys/shop.keys';
import { ProductKeys } from '@config/keys/product.keys';
import { EventTrackerService } from '../../event-tracker/event-tracker.service';
import { CookieService } from '../../user/cookie.service';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';
import { DoodUserModel } from '@store/authentication/authentication.model';
import { DoodProductModel } from '@core/models/product.model';

declare global {
  interface Window {
    initializeBrevoTracker: any;
    sendinblue: any;
    brevoUserIdentified: boolean;
  }
}

@Injectable({
  providedIn: 'root',
})
export class BrevoService {
  isEnabled = false;
  clientKey = false;
  isInitialized = false;
  isUserIdentified = false;

  constructor(
    private readonly marketplaceService: MarketplaceService,
    private readonly authStoreSelector: AuthStoreSelector,
    private readonly cookieService: CookieService,
  ) {
    const plugin = this.marketplaceService.getPluginByType('brevo');
    if (!plugin) {
      return;
    }

    if (!plugin.plugin_enabled) {
      return;
    }

    if (!plugin.api_client_key || plugin.api_client_key === '') {
      return;
    }

    this.isEnabled = true;
    this.clientKey = plugin.api_client_key;
    this.initializeEventListeners();
  }

  public trackCartClearedEvent(): void {
    if (!this.tryInitializing()) {
      return;
    }

    window.sendinblue.track('order_cart_cleared');
  }

  public trackOrderCreatedEvent({ id, total, currency, products }: any): void {
    if (!this.tryInitializing()) {
      return;
    }

    window.sendinblue.track(
      'order_created',
      {},
      {
        data: {
          transaction_id: id,
          value: total,
          currency,
          items: products.map((product: DoodProductModel) => this.productToItem(product, 1)),
        },
      },
    );
  }

  public trackProductsUpdatedInCartEvent(items: Array<any>): void {
    if (!this.tryInitializing()) {
      return;
    }

    window.sendinblue.track(
      'products_updated_in_cart',
      {},
      {
        data: {
          items,
        },
      },
    );
  }

  public trackProductRemovedFromCartEvent({ item_id, quantity }: any): void {
    if (!this.tryInitializing()) {
      return;
    }

    window.sendinblue.track(
      'product_removed_from_cart',
      {},
      {
        data: {
          item_id,
          quantity,
        },
      },
    );
  }

  public trackProductAddedToCartEvent(product: DoodProductModel, quantity: number): void {
    if (!this.tryInitializing()) {
      return;
    }

    window.sendinblue.track(
      'product_added_to_cart',
      {},
      {
        data: this.productToItem(product, quantity),
      },
    );
  }

  public isIdentified(): boolean {
    if (this.isUserIdentified) {
      return true;
    }

    return typeof window.brevoUserIdentified !== 'undefined';
  }

  public identifyUser(user: DoodUserModel | null): void {
    if (!this.isInitialized) {
      return;
    }

    if (this.isIdentified()) {
      return;
    }

    if (!user) {
      return;
    }

    if (user.email === '') {
      return;
    }

    this.isUserIdentified = true;
    window.sendinblue.identify(user.email, {
      FNAME: user.firstname,
      LNAME: user.lastname,
      OPT_IN: user.optin,
    });
  }

  public initializeTracker(): void {
    if (this.isInitialized) {
      return;
    }

    if (!this.isEnabled) {
      return;
    }

    if (!this.clientKey) {
      return;
    }

    if (!this.cookieService.isTrackingAccepted()) {
      return;
    }

    const user = this.authStoreSelector.user;
    window.initializeBrevoTracker(this.clientKey, user);

    if (user && user.email === '') {
      this.authStoreSelector.selectUser.subscribe(value => this.identifyUser(value));
    }

    this.isInitialized = true;
  }

  private initializeEventListeners(): void {
    window.addEventListener(EventTrackerService.EventProductAddedToCart, (event: any) => {
      this.trackProductAddedToCartEvent(
        event.detail.product as DoodProductModel,
        event.detail.quantity as number,
      );
    });

    window.addEventListener(EventTrackerService.EventProductRemovedFromCart, (event: any) => {
      this.trackProductRemovedFromCartEvent(event.detail);
    });

    window.addEventListener(EventTrackerService.EventOrderCreated, (event: any) => {
      this.trackOrderCreatedEvent(event.detail);
    });

    window.addEventListener(EventTrackerService.EventCartCleared, () => {
      this.trackCartClearedEvent();
    });

    window.addEventListener(EventTrackerService.EventProductsUpdatedInCart, (event: any) => {
      this.trackProductsUpdatedInCartEvent(event.detail);
    });
  }

  private tryInitializing(): boolean {
    if (!this.isInitialized) {
      this.initializeTracker();
    }

    return this.isInitialized;
  }

  private productToItem(product: DoodProductModel, quantity: number = 1): any {
    const marketplace = this.marketplaceService.getMarketplaceData();
    return {
      currency: marketplace?.[MarketplaceKeys.Currency] || 'EUR',
      item_id: product[ProductKeys.Id],
      item_name: product[ProductKeys.Name],
      item_category: product[ProductKeys.Type],
      price: product[ProductKeys.Price],
      quantity,
    };
  }
}
