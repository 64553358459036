<div
  [ngClass]="{
    'col-span-1': width === 'one',
    'col-span-2': width === 'two',
  }"
>
  <app-mosaic-push-atom
    [imageUrl]="backgroundImage?.url"
    [linkUrl]="linkUrl ?? ''"
    [linkTarget]="linkTarget"
    [title]="heading ?? ''"
    [aspectRatio]="aspectRatio ? aspectRatio : width === 'two' ? '100/32' : '100/64'"
    [rounded]="rounded"
    [shadow]="shadow"
    [backgroundColor]="backgroundColor"
    [font]="font"
    [fontSize]="fontSize"
    [backgroundStyleContain]="backgroundStyleContain"
    [textColor]="textColor"
    (clickEmitter)="onClick()"
  ></app-mosaic-push-atom>
</div>
