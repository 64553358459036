import { Injectable } from '@angular/core';
import { map, switchMap, take } from 'rxjs/operators';
import { combineLatest, iif, Observable, of } from 'rxjs';

import { UserKeys } from '@config/keys/user.keys';
import { MetafieldKeys } from '@config/keys/metafield.keys';

import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';
import { ZerosixApiService } from '@core/services/api/zerosix/zerosix-api.service';

import { ICoupon } from '@core/models/cart.model';
import { DoodUserModel } from '@store/authentication/authentication.model';
import { IZerosixAsset, IZerosixCard, ZerosixAssetType } from '@core/models/zerosix.model';

@Injectable({
  providedIn: 'root',
})
export class ZerosixService {
  constructor(
    private authSelector: AuthStoreSelector,
    private marketplaceSelector: MarketplaceStoreSelector,
    private readonly zerosixApiService: ZerosixApiService,
  ) {}

  createZerosixUser$(phoneNumber: string): Observable<DoodUserModel> {
    return this.marketplaceSelector.selectMarketplaceId.pipe(
      take(1),
      switchMap(marketplaceId =>
        this.zerosixApiService.createZerosixUser$(marketplaceId, phoneNumber),
      ),
    );
  }

  isUserZeroSixCardEnabledOnMarketplaceLevel$(): Observable<boolean> {
    return combineLatest([
      this.authSelector.selectUser,
      this.marketplaceSelector.selectMarketplaceId,
    ]).pipe(
      take(1),
      map(([user, marketplaceId]) => {
        if (!user || !user[UserKeys.Metafields] || !Array.isArray(user[UserKeys.Metafields])) {
          return false;
        }

        return !!user[UserKeys.Metafields]!.find(
          metafield =>
            metafield[MetafieldKeys.Handle] === `zerosix_card_id_marketplace_${marketplaceId}`,
        );
      }),
    );
  }

  sendOtp$(phoneNumber: string): Observable<any> {
    return this.marketplaceSelector.selectMarketplaceId.pipe(
      take(1),
      switchMap(marketplaceId => this.zerosixApiService.sendOtp$(marketplaceId, phoneNumber)),
    );
  }

  attachUserWithOtp$(code: string): Observable<any> {
    return this.marketplaceSelector.selectMarketplaceId.pipe(
      take(1),
      switchMap(marketplaceId => this.zerosixApiService.attachUserWithOtp$(marketplaceId, code)),
    );
  }

  getCard$(shopId?: string): Observable<IZerosixCard | undefined> {
    return this.isUserZeroSixCardEnabledOnMarketplaceLevel$().pipe(
      switchMap(zerosixEnabled =>
        iif(
          () => zerosixEnabled,
          this.marketplaceSelector.selectMarketplaceId.pipe(
            take(1),
            switchMap(marketplaceId => this.zerosixApiService.getCard$(marketplaceId, shopId)),
          ),
          of(undefined),
        ),
      ),
    );
  }

  calculateCouponFromAsset(asset: IZerosixAsset): ICoupon | null {
    if (asset.type === ZerosixAssetType.VOUCHER) {
      return { code: asset.code };
    } else if (asset.type === ZerosixAssetType.REWARD) {
      return { key: 'reward_' + asset.id };
    }
    console.error('[Zerosix] Unknown asset type', asset.type);
    return null;
  }
}
