<div
  class="aspect-ratio-3 w-full h-full bg-no-repeat bg-cover px-5 pt-5 pb-8 bg-primary-300"
  [style.background]="'url(' + imgUrl + ')'"
>
  <div>
    <app-button-atom
      type="outlined"
      (clickEventEmitter)="backEvent.emit()"
      [label]="backButtonText"
      [full]="false"
      size="S"
      icon="icon-arrow-left"
      color="primary"
    ></app-button-atom>
  </div>
  <h1 class="text-white font-brand font-extrabold text-5.4xl uppercase mt-11 pb-1 leading-12">
    {{ title }}
  </h1>
  <p class="text-white font-base text-base">{{ subtitle }}</p>
</div>
