<div class="max-w-screen-sm bg-white px-8 py-7">
  <div class="flex justify-between">
    <h2 class="font-accent text-2xl font-bold leading-none">
      {{ shopName }}
    </h2>
    <button
      class="icon icon-cross text-sm"
      (click)="closeCart.emit()"
    ></button>
  </div>
  <app-overview-cart-item-atom
    *ngFor="let product of products"
    [price]="product[ProductKeys.Price] ?? 'INVALID'"
    [name]="product[ProductKeys.Name] ?? 'INVALID'"
    [quantity]="product[ProductKeys.Quantity] ?? 0"
  ></app-overview-cart-item-atom>
  <app-overview-cart-additional-atom
    *ngFor="let additional of additionalItems"
    [text]="additional.label"
    [price]="forceType(additional.value)"
    [icon]="additional.icon ?? ''"
  ></app-overview-cart-additional-atom>
  <div class="flex justify-between py-3 text-1xsl">
    <p class="font-bold">Total</p>
    <p>
      <app-price-atom
        [amount]="totalAmount"
        [format]="true"
      ></app-price-atom>
    </p>
  </div>
  <div class="flex flex-col py-3">
    <textarea
      id="comment"
      rows="1"
      placeholder="Commentaire sur votre commande"
      class="rounded border border-neutral-300 bg-neutral-50 px-6 py-2.5 leading-5 text-neutral-500"
      [(ngModel)]="comment"
    ></textarea>
  </div>
  <div class="py-3">
    <div>
      <app-button-atom
        label="Commander"
        size="L"
        type="primary"
        (clickEventEmitter)="submitCart.emit(this.comment)"
      ></app-button-atom>
    </div>
    <div class="mt-2">
      <app-button-atom
        label="Ajouter des articles"
        size="L"
        type="light"
        (clickEventEmitter)="closeCart.emit()"
      ></app-button-atom>
    </div>
  </div>
</div>
