import { Component, OnInit } from '@angular/core';
import { interval, Observable, timer } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { ModalsService } from '@core/services/modals/modals.service';
import { ResetStateService } from '@core/services/reset-state.service';
import { ModalStoreSelector } from '@common/selectors/modal.selector';

import { WaitingModalComponent } from '../waiting-modal/waiting-modal.component';

@Component({
  selector: 'app-kiosk-cart-reset-timer-modal',
  templateUrl: './kiosk-cart-reset-timer-modal.component.html',
})
export class KioskCartResetTimerModalComponent extends DestroyerBase implements OnInit {
  static handle = 'kiosk-cart-reset-timer-modal';
  static delay?: number;

  index$: Observable<number> = this.modalSelector
    .selectModal(KioskCartResetTimerModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  remainingSeconds?: number;

  constructor(
    private modalSelector: ModalStoreSelector,
    protected readonly modalsService: ModalsService,
    protected readonly resetStateService: ResetStateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.startCounter();
  }

  close(): void {
    this.modalsService.close(KioskCartResetTimerModalComponent.handle);
  }

  startNewCart(): void {
    this.modalsService.open(WaitingModalComponent.handle);
    timer(1).subscribe(() => {
      this.resetStateService.refreshUI();
      this.close();
    });
  }

  private startCounter(): void {
    this.remainingSeconds = KioskCartResetTimerModalComponent.delay;
    interval(1000)
      .pipe(
        takeUntil(this._destroyerRef),
        tap(() => {
          if (this.remainingSeconds) {
            this.remainingSeconds -= 1;
          }
        }),
      )
      .subscribe();
  }
}
