import { Injectable } from '@angular/core';
import { HubspotApiService } from '../api/hubspot/hubspot-api.service';
import { Observable } from 'rxjs';
import { HUBSPOT_DELETE_USER_FORM_ID } from '@config/hubspot.config';
import { UserKeys } from '@config/keys/user.keys';

@Injectable({
  providedIn: 'root',
})
export class HubspotService {
  constructor(private readonly hubspotApiService: HubspotApiService) {}

  requestDeleteUserAccount(data: any): Observable<any> {
    const formatedData = {
      fields: [
        {
          name: UserKeys.LastName,
          value: data[UserKeys.LastName],
        },
        {
          name: UserKeys.FirstName,
          value: data[UserKeys.FirstName],
        },
        {
          name: UserKeys.Email,
          value: data[UserKeys.Email],
        },
      ],
    };
    return this.hubspotApiService.requestHubspot$(formatedData, HUBSPOT_DELETE_USER_FORM_ID);
  }
}
