import { map, takeUntil } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';

import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { IContentBuilderFieldImage } from '@core/models/content-builder-fields.model';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';

@Component({
  selector: 'app-image-of-slide-show-block',
  templateUrl: './image-of-slide-show-block.component.html',
  styleUrls: ['./image-of-slide-show-block.component.scss'],
})
export class ImageOfSlideShowBlockComponent extends DestroyerBase implements OnInit {
  @Input() image?: IContentBuilderFieldImage;
  @Input() mobileImageSize?: string;
  @Input() desktopImageSize?: string;

  imageWidthClass?: string;

  constructor(private settingsSelector: SettingsStoreSelector) {
    super();
  }

  ngOnInit(): void {
    this.settingsSelector.selectDeviceIsMobileScreen
      .pipe(
        takeUntil(this._destroyerRef),
        map(isMobile => {
          if (isMobile) {
            this.imageWidthClass =
              'w-' +
              (this.mobileImageSize !== 'full' ? this.mobileImageSize + '-screen' : 'screen');
          } else {
            this.imageWidthClass =
              'w-' +
              (this.desktopImageSize !== 'full' ? this.desktopImageSize + '-screen' : 'screen');
          }
        }),
      )
      .subscribe();
  }
}
