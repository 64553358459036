import { createFeature, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';

import { DoodAnchorModel } from './anchor.model';
import { ANCHOR_STORE_REDUCER } from './anchor.reducer';
import { ANCHOR_STORE_KEY, ANCHOR_STORE_ADAPTER, AnchorState } from './anchor.state';

const ANCHOR_STORE_FEATURE = createFeature({
  name: ANCHOR_STORE_KEY,
  reducer: ANCHOR_STORE_REDUCER,
});
export const { selectAnchorsState: SELECT_ANCHOR_STATE } = ANCHOR_STORE_FEATURE;

export const {
  selectIds: SELECT_ANCHORS_IDS,
  selectError: SELECT_ANCHORS_ERROR,
  selectEntities: SELECT_ANCHORS_ENTITIES,
  selectAnchorsState: SELECT_ANCHORS_STATE,
  selectIsLoaded: SELECT_ANCHORS_IS_LOADED,
  selectIsLoading: SELECT_ANCHORS_IS_LOADING,
} = ANCHOR_STORE_FEATURE;

export const { selectAll: SELECT_ANCHORS_ALL } =
  ANCHOR_STORE_ADAPTER.getSelectors(SELECT_ANCHOR_STATE);

export const SELECT_ANCHORS_ENTITY = (
  id: string,
): MemoizedSelector<
  Record<string, any>,
  DoodAnchorModel | undefined,
  DefaultProjectorFn<DoodAnchorModel | undefined>
> =>
  createSelector(SELECT_ANCHORS_STATE, (state: AnchorState) => {
    return state.entities[id];
  });
