import { Component, Input } from '@angular/core';

import { IContentBuilderFieldImage } from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-big-footer-body-logo-block',
  templateUrl: './big-footer-body-logo-block.component.html',
})
export class BigFooterBodyLogoBlockComponent {
  @Input() logo!: IContentBuilderFieldImage;
}
