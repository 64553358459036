import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-input-multi-atom',
  templateUrl: './input-multi-atom.component.html',
  styleUrls: ['./input-multi-atom.component.scss'],
})
export class InputMultiAtomComponent {
  @Input() label!: string;
  @Input() type!: string;
  @Input() blockId!: string;
  @Input() active = false;
  @Input() placeholder!: string;
  @Input() value!: string;
  @Input() boldLabel = false;

  @Output() valueEmitter = new EventEmitter<string | boolean>();

  onChangeValueRadio(event: string): void {
    this.valueEmitter.emit(event);
  }

  onChangeValueCheckbox(event: any): void {
    this.valueEmitter.emit(event?.target?.checked);
  }

  onChangeTextValue(event: any): void {
    this.valueEmitter.emit(event?.target?.value);
  }

  unfocus(element: any): void {
    element?.blur();
  }
}
