<h1 class="mb-5 font-brand text-4xl font-bold">
  {{ step.title }}
</h1>

<div
  *ngIf="errorMessage"
  class="p-5 text-red-500"
>
  {{ errorMessage }}
</div>

<div class="grid grid-cols-3 gap-5 p-4">
  <div
    *ngFor="let product of products"
    [ngClass]="{
      'shadow-equal shadow-primary-300': (product.quantity ?? 0) > 0,
    }"
  >
    <app-kiosk-product-card-atom
      class="mb-5 pb-5"
      [product]="product"
      [quantity]="product.quantity"
      [isProductAvailable]="!!product.available"
      [selectionnable]="product.available"
      (changeProductQuantity)="changeQuantity(product, $event)"
      [displayPrice]="false"
      [displayedQuantity]="getQuantityIfSelected(product)"
      [textColor]="textColor"
      [cardColor]="buttonTextColor"
      [buttonBackgroundColor]="buttonBackgroundColor"
      [buttonTextColor]="buttonTextColor"
      [maxProduct]="forceType(getMaxProduct(product))"
      [monoSelection]="isMonoSelection()"
    ></app-kiosk-product-card-atom>
  </div>
</div>

<div
  *ngIf="!isProductsLoading && products.length === 0"
  class="py-4"
>
  {{ 'zerosix.offered-product-modal.no-product-available' | translate }}
</div>

<div
  *ngIf="isProductsLoading"
  class="w-full py-4 text-center"
>
  <img
    src="assets/images/spinner.svg"
    alt=""
    class="pr-2"
  />
</div>
