<div class="relative flex overflow-x-auto whitespace-nowrap scrollbar-hide"
     [style.backgroundColor]="backgroundColor | contentBuilderColor"
     [style.height]="height+'px'"
>
  <div class="flex flex-shrink-0 items-center justify-between min-w-full gap-4 h-full max-h-full px-5 lg:px-12">

    <div class="flex-shrink-0 h-full max-h-full flex gap-6"
         *ngIf="leftItems && leftItems.length > 0"
    >
      <app-block-list [blockIdList]="leftItems"></app-block-list>
    </div>

    <div class="mx-auto h-full max-h-full flex gap-6"
         *ngIf="middleItems && middleItems.length > 0"
    >
      <app-block-list [blockIdList]="middleItems"></app-block-list>
    </div>

    <div class="flex-shrink-0 h-full max-h-full flex gap-6"
         *ngIf="rightItems && rightItems.length > 0"
    >
      <app-block-list [blockIdList]="rightItems"></app-block-list>
    </div>

  </div>
</div>
