<input
  type="radio"
  [id]="label"
  [checked]="active"
  (change)="onChangeValueRadio(value)"
  class="hidden"
/>
<label
  *ngIf="label"
  [for]="label"
  [style.borderColor]="textColor | contentBuilderColor"
  [style.backgroundColor]="active ? (buttonColor | contentBuilderColor) : ''"
  [style.color]="
    active ? (activeTextColor | contentBuilderColor) : (textColor | contentBuilderColor)
  "
  class="flex h-64 flex-1 cursor-pointer items-center justify-center rounded-lg border-6 text-3xl font-bold transition-colors duration-300"
>
  {{ label }}
</label>
