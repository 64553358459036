<div
  class="relative flex flex-col gap-2 overflow-x-auto rounded-m sm:overflow-x-clip"
  (mouseenter)="pauseAutoScroll()"
  (mouseleave)="resumeAutoScroll()"
>
  <div class="relative">
    <div
      class="flex gap-8"
      #carousel
    >
      <app-block-list [blockIdList]="cards"></app-block-list>
    </div>
  </div>

  @if ((cards?.length ?? 0) > 1 && !allCardsAreVisible) {
    <div class="-ml-12 hidden transform items-center justify-center gap-2 sm:flex">
      <button
        (click)="previous()"
        class="h-2 w-2 rounded-full bg-neutral-400 transition hover:bg-neutral-600 focus:outline-none"
        [ngClass]="{ 'opacity-20 cursor-none pointer-events-none': currentIndex === 0 }"
        aria-label="Go to previous"
      ></button>
      <div
        class="h-2 w-2 rounded-full"
        [style.backgroundColor]="$any(accentColor) | contentBuilderColor"
        aria-label="Current position"
      ></div>

      <button
        (click)="next()"
        class="h-2 w-2 rounded-full bg-neutral-400 transition hover:bg-neutral-600 focus:outline-none"
        aria-label="Go to next"
      ></button>
    </div>
  }
</div>
