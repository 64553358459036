import { Comparer, createEntityAdapter, EntityAdapter, IdSelector, Update } from '@ngrx/entity';

import { ActiveState } from './active.state';

export interface ActiveAdapter<Entity, Id> extends EntityAdapter<Entity> {
  // Initial State
  getInitialState<State extends object>(state?: State): ActiveState<Entity, Id> & State;
  // Active
  resetActive<State>(state: State): State;
  setActive<State>(active: Id | null, state: State): State;
  updateActive<State extends ActiveState<Entity, Id>>(
    changes: Partial<Entity>,
    state: State,
  ): State;
  insertActive<State extends ActiveState<Entity, Id>>(entity: Entity, state: State): State;
  upsertActive<State extends ActiveState<Entity, Id>>(changes: Update<Entity>, state: State): State;
}

export interface ActiveAdapterOptions<Entity> {
  selectId?: IdSelector<Entity>;
  sortComparer?: false | Comparer<Entity>;
}

export const createActiveAdapter = <Entity, Id>(
  options?: ActiveAdapterOptions<Entity>,
): ActiveAdapter<Entity, Id> => {
  const _entity = createEntityAdapter<Entity>(options);
  return {
    // Entity
    ..._entity,

    getInitialState: <State extends ActiveState<Entity, Id>>(state?: Partial<State>): State => {
      return {
        ...state,
        ..._entity.getInitialState(),
        active: null,
      } as State;
    },

    // Active
    setActive: <State>(active: Id | null, state: State): State => {
      return { ...state, active };
    },
    resetActive: <State>(state: State): State => {
      return { ...state, active: null };
    },
    updateActive: <State extends ActiveState<Entity, Id>>(
      changes: Partial<Entity>,
      state: State,
    ): State => {
      if (state.active === null) return state;
      const _id: Id = Object(state).active;
      const _state = _entity.updateOne(
        typeof _id === 'number'
          ? {
              changes,
              id: _id as number,
            }
          : {
              changes,
              id: Object(_id).toString(),
            },
        state,
      );
      return _state;
    },
    insertActive: <State extends ActiveState<Entity, Id>>(entity: Entity, state: State): State => {
      const id = _entity.selectId(entity);
      const _state = _entity.upsertOne(entity, state);
      return {
        ..._state,
        active: id,
      };
    },
    upsertActive: <State extends ActiveState<Entity, Id>>(
      changes: Update<Entity>,
      state: State,
    ): State => {
      const _state = _entity.updateOne(changes, state);
      return {
        ..._state,
        active: changes.id,
      };
    },
  };
};
