<div
  class="relative mb-6"
  *ngIf="
    type !== 'STRIPE_TWINT' &&
    type !== 'STRIPE_GOOGLE_PAY' &&
    type !== 'STRIPE_APPLE_PAY' &&
    type !== 'STRIPE_BANCONTACT'
  "
>
  <label class="mb-1 block font-base text-sm font-normal leading-5 text-neutral-600">{{
    'payment.stripe.card-number' | translate
  }}</label>
  <div class="mb-6 flex h-12 w-full border border-solid border-neutral-300 bg-neutral-50 leading-5">
    <div
      id="card-number-element"
      class="field"
    ></div>
  </div>

  <div class="mt-2 grid grid-cols-2 gap-2">
    <div>
      <label class="mb-1 block font-base text-sm font-normal leading-5 text-neutral-600">{{
        'payment.stripe.expiration-date' | translate
      }}</label>
      <div
        class="mb-6 flex h-12 w-full border border-solid border-neutral-300 bg-neutral-50 leading-5"
      >
        <div
          id="card-expiry-element"
          class="field"
        ></div>
      </div>
    </div>
    <div>
      <label class="mb-1 block font-base text-sm font-normal leading-5 text-neutral-600">{{
        'payment.stripe.security-code' | translate
      }}</label>
      <div
        class="mb-6 flex h-12 w-full border border-solid border-neutral-300 bg-neutral-50 leading-5"
      >
        <div
          id="card-cvc-element"
          class="field"
        ></div>
      </div>
    </div>
  </div>

  <div
    *ngIf="isLoading"
    class="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center bg-white"
  >
    <i class="icon icon-privacy animate-pulse text-4xl"></i>
  </div>
</div>

<div *ngIf="type === 'STRIPE_BANCONTACT'">
  <div class="mb-6 ml-3 flex-1">
    <div
      *ngIf="user"
      class="flex flex-row items-center justify-between"
    >
      <div *ngIf="!bancontact && user.firstname && user.lastname">
        <div class="flex items-center pb-2">
          <div class="text-neutral-800">
            <h4 class="font-base font-bold">
              {{ 'payment.bancontact.complete-name' | translate }}&#42;
            </h4>
            <p class="font-base">{{ user.firstname }} {{ user.lastname }}</p>
          </div>
        </div>
        <div class="flex items-center">
          <div
            *ngIf="user.email"
            class="text-neutral-800"
          >
            <h4 class="font-base font-bold">{{ 'payment.bancontact.email' | translate }}&#42;</h4>
            <p class="font-base">
              {{ user.email }}
            </p>
          </div>
        </div>
      </div>
      <div *ngIf="bancontact">
        <div class="flex items-center pb-2">
          <div
            *ngIf="bancontact?.complete_name"
            class="text-neutral-800"
          >
            <h4 class="font-base font-bold">
              {{ 'payment.bancontact.complete-name' | translate }}&#42;
            </h4>
            <p class="font-base">
              {{ bancontact?.complete_name }}
            </p>
          </div>
        </div>
        <div class="flex items-center">
          <div
            *ngIf="bancontact.email"
            class="text-neutral-800"
          >
            <h4 class="font-base font-bold">{{ 'payment.bancontact.email' | translate }}&#42;</h4>
            <p class="font-base">
              {{ bancontact.email }}
            </p>
          </div>
        </div>
      </div>
      <div
        *ngIf="!bancontact.email && !user.email"
        class="font-base text-neutral-800"
      >
        {{ 'payment.bancontact.description' | translate }}
      </div>
      <app-button-atom
        *ngIf="
          (user[userKeys.FirstName] && user[userKeys.LastName] && user[userKeys.Email]) ||
          (!user[userKeys.Email] && bancontact.email)
        "
        size="XS"
        thickSize="XS"
        [label]="'payment.bancontact.modify' | translate"
        type="light"
        (click)="openBancontactForm()"
      ></app-button-atom>
      <app-button-atom
        *ngIf="!bancontact.email && !user.email"
        size="XS"
        thickSize="XS"
        [label]="'payment.bancontact.add' | translate"
        type="primary"
        (click)="openBancontactForm()"
      ></app-button-atom>
    </div>
  </div>
</div>

<div
  class="relative mb-6"
  *ngIf="(type === 'STRIPE_GOOGLE_PAY' || type === 'STRIPE_APPLE_PAY') && paymentRequestOptions"
>
  <ngx-stripe-payment-request-button
    [paymentOptions]="paymentRequestOptions"
    (paymentMethod)="onPaymentMethod($event)"
    (notavailable)="onNotAvailable()"
  ></ngx-stripe-payment-request-button>
</div>
