import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UpdateService {
  constructor(private swUpdate: SwUpdate) {
    swUpdate.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(event => {
        console.log('[UpdateService] Current version is', event.currentVersion);
        console.log('[UpdateService] Available version is', event.latestVersion);
        console.log('[UpdateService] Activate new version');
        swUpdate.activateUpdate().then(() => document.location.reload());
      });
  }

  checkForUpdate(): void {
    if (!this.swUpdate.isEnabled) {
      console.log('[UpdateService] Service worker is not enabled in this environment');
      return;
    }
    console.log('[UpdateService] Check for update');
    this.swUpdate.checkForUpdate();
  }
}
