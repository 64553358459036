import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { distinctUntilChanged, map, skipWhile, switchMap, takeUntil, tap } from 'rxjs/operators';

import { ProductKeys } from '@config/keys/product.keys';
import { ProductUtils } from '@app/utilities/product.util';
import { DoodProductModel } from '@core/models/product.model';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

import { ProductService } from '@core/services/product/product.service';
import { ProductsService } from '@core/services/products/products.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { ProductSelectorService } from '@core/services/product-selector/product-selector.service';
import { MarketplaceProductsService } from '@core/services/marketplace-products/marketplace-products.service';

@Component({
  selector: 'app-cart-suggestions-block',
  templateUrl: './cart-suggestions-block.component.html',
})
export class CartSuggestionsBlockComponent implements OnInit, OnDestroy {
  @Input() items: any;

  public products: DoodProductModel[] = [];
  public isLoading = true;

  private readonly destroyed$ = new Subject<boolean>();

  constructor(
    private readonly router: Router,
    private routerHelper: RouterHelperService,
    private readonly productService: ProductService,
    private readonly productsService: ProductsService,
    private marketplaceSelector: MarketplaceStoreSelector,
    private readonly productSelector: ProductSelectorService,
    private readonly marketplaceProductsService: MarketplaceProductsService,
  ) {}

  ngOnInit(): void {
    this.marketplaceSelector.selectMarketplaceId
      .pipe(
        switchMap(marketplaceId => this.marketplaceProductsService.loadProducts$(marketplaceId)),
      )
      .subscribe();

    this.productsService
      .selectAll$()
      .pipe(
        takeUntil(this.destroyed$),
        skipWhile(products => !products || products.length === 0),
        distinctUntilChanged(),
        map(products => products.filter(p => ProductUtils.isProductVisible(p))),
        tap(products => this.populateProducts(products)),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private populateProducts(products: DoodProductModel[]): void {
    this.products = this.productSelector.selectProducts(products, this.items);
    this.isLoading = false;
  }

  onProductClick(product: DoodProductModel): void {
    this.productService.setProductToStore(product);
    this.router.navigate([
      this.routerHelper.translateRoute(
        `/cart/products/${product[ProductKeys.ShopId]}/${product[ProductKeys.Id]}`,
      ),
    ]);
  }
}
