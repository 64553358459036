<div class="py-5 px-4.5 border-neutral-400 border text-neutral-800 rounded rounded-s">
  <div class="flex items-center pb-5">
    <i class="icon icon-satisfied mr-3"></i>
    <span class="font-base font-bold lg:text-md">{{
      'basket.additonnal-informations.tip' | translate
    }}</span>
  </div>
  <div class="grid grid-cols-5 gap-x-1.5">
    <div>
      <app-button-square-atom
        [text]="'tip-modal.none' | translate"
        [value]="0"
        [full]="true"
        [isActive]="!selectedTip"
        (buttonClick)="onSelectTip($event)"
      ></app-button-square-atom>
    </div>
    <div *ngIf="choice1Amount && choice1Unit">
      <app-button-square-atom
        [value]="choice1Amount"
        [type]="choice1Unit"
        [totalAmount]="totalAmount"
        [full]="true"
        (buttonClick)="onSelectTip($event)"
        [isActive]="
          choice1Unit === tipTypeValues.Currency
            ? selectedTip === choice1Amount
            : selectedTip === calculateAmount(choice1Amount, totalAmount)
        "
      ></app-button-square-atom>
    </div>
    <div *ngIf="choice2Amount && choice2Unit">
      <app-button-square-atom
        [value]="choice2Amount"
        [type]="choice2Unit"
        [totalAmount]="totalAmount"
        [full]="true"
        (buttonClick)="onSelectTip($event)"
        [isActive]="
          choice2Unit === tipTypeValues.Currency
            ? selectedTip === choice2Amount
            : selectedTip === calculateAmount(choice2Amount, totalAmount)
        "
      ></app-button-square-atom>
    </div>
    <div *ngIf="choice3Amount && choice3Unit">
      <app-button-square-atom
        [value]="choice3Amount"
        [type]="choice3Unit"
        [totalAmount]="totalAmount"
        [full]="true"
        (buttonClick)="onSelectTip($event)"
        [isActive]="
          choice3Unit === tipTypeValues.Currency
            ? selectedTip === choice3Amount
            : selectedTip === calculateAmount(choice3Amount, totalAmount)
        "
      ></app-button-square-atom>
    </div>
    <div>
      <app-button-square-atom
        [text]="'tip-modal.other-amount' | translate"
        (click)="selectOtherTipAmount()"
        [isActive]="isOtherAmountSelected"
        [otherAmmount]="otherAmount"
      ></app-button-square-atom>
    </div>
  </div>
</div>
