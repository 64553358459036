import { ReducerTypes, ActionCreator, on } from '@ngrx/store';

import { BASKET_STORE_COMPONENT_ACTIONS, BASKET_STORE_LOCAL_ACTIONS } from './basket.action';
import {
  BasketState,
  BASKET_STORE_INITIAL_STATE,
  BASKET_STORE_BASKET_INITIAL_STATE,
} from './basket.state';

export const BASKET_STORE_API_PIECE: ReducerTypes<BasketState, readonly ActionCreator[]>[] = [];

export const BASKET_STORE_LOCAL_PIECE: ReducerTypes<BasketState, readonly ActionCreator[]>[] = [
  on(BASKET_STORE_LOCAL_ACTIONS.setBasket, (state, { basket }): BasketState => {
    return { ...state, basket };
  }),
  on(BASKET_STORE_LOCAL_ACTIONS.updateBasket, (state, { changes }): BasketState => {
    return { ...state, basket: { ...state.basket, ...changes } };
  }),
  on(BASKET_STORE_LOCAL_ACTIONS.resetBasket, (state): BasketState => {
    return { ...state, basket: BASKET_STORE_BASKET_INITIAL_STATE };
  }),

  // Misc
  on(BASKET_STORE_LOCAL_ACTIONS.reset, (state): BasketState => {
    return BASKET_STORE_INITIAL_STATE;
  }),
];

export const BASKET_STORE_COMPONENT_PIECE: ReducerTypes<BasketState, readonly ActionCreator[]>[] = [
  on(BASKET_STORE_COMPONENT_ACTIONS.updateState, (state, { changes }): BasketState => {
    return { ...state, ...changes };
  }),
  on(BASKET_STORE_COMPONENT_ACTIONS.resetState, (): BasketState => {
    return BASKET_STORE_INITIAL_STATE;
  }),
];
