export class ArrayUtils {
  static from(length: number): number[] {
    return Array.from(Array(length).keys());
  }

  static generate<T>(
    length: number,
    callbackfn: (value: number, index: number, array: number[]) => T,
  ): T[] {
    return ArrayUtils.from(length).map(callbackfn);
  }

  static isArrayOfStrings(_value: unknown[]): _value is string[] {
    return Array.isArray(_value) && _value.every(_item => typeof _item === 'string');
  }

  static isArrayOfNumbers(_value: unknown[]): _value is number[] {
    return Array.isArray(_value) && _value.every(_item => typeof _item === 'number');
  }
}
