import { Component, Input } from '@angular/core';
import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { CartItemKeys } from '@config/keys/cart.keys';
import { AdditionGroupKeys } from '@config/keys/shop.keys';
import { ProductTypeValues } from '@config/values/product.values';
import { IAllergens } from '@core/models/allergens.model';
import { ICartItem, ICartDeclinableSeparateAdditions } from '@core/models/cart.model';
import { DoodProductModel, ISelectedCompoundItem } from '@core/models/product.model';
import { EventTrackerService } from '@core/services/event-tracker/event-tracker.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { ContentBuilderFieldColors } from '@shared/interfaces/content-builder.interface';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  Subject,
  take,
  takeUntil,
} from 'rxjs';
import { ProductStoreSelector } from '@common/selectors/product.selector';
import { DeclinableStoreSelector } from '@common/selectors/declinable.selector';
import { CartService } from '@core/services/cart/cart.service';
import { ProductService } from '@core/services/product/product.service';
import { ProductStoreRefiner } from '@common/refiners/product.refiner';
import { IAdditionGroup } from '@core/models/shop.model';

@Component({
  selector: 'app-kiosk-product-overview-modal',
  templateUrl: 'kiosk-product-overview-modal.component.html',
})
export class KioskProductOverviewModalComponent {
  static handle = 'kiosk-add-product-overview-modal';

  index$: Observable<number> = this.modalSelector
    .selectModal(KioskProductOverviewModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  product$ = this.productSelector.selectActive;
  declinables$ = this.declinableSelector.selectDeclinables;

  @Input() replaceAddToCart?: (product: ICartItem) => void;
  @Input() onCartItemAdded: (cartItem: ICartItem) => void = () => {};
  @Input() navigateToProduct!: () => void;
  @Input() image?: string;
  @Input() name?: string;
  @Input() price?: string;
  @Input() description?: string;
  @Input() allergens?: IAllergens[];
  @Input() type?: ProductTypeValues;

  @Input() backgroundColor?: ContentBuilderFieldColors;
  @Input() accentColor?: ContentBuilderFieldColors;

  static onClose?: () => void;
  static onAdd?: () => void;

  quantity: number = 1;

  selectedAdditions: string[] = [];
  selectedAdditionsByGroup: (IAdditionGroup & {
    selectedAdditions: string[];
  })[] = [];

  private _destroyerRef = new Subject<boolean>();
  product: DoodProductModel | null = null;

  constructor(
    private readonly modalSelector: ModalStoreSelector,
    private readonly modalsService: ModalsService,
    private readonly productSelector: ProductStoreSelector,
    private readonly declinableSelector: DeclinableStoreSelector,
    private readonly cartService: CartService,
    private readonly productService: ProductService,
    private readonly eventTrackerService: EventTrackerService,
    private readonly productRefiner: ProductStoreRefiner,
  ) {}

  ngOnInit(): void {
    this.productRefiner.selectAdditionsByGroups
      .pipe(distinctUntilChanged(), takeUntil(this._destroyerRef))
      .subscribe(_additions => {
        this.selectedAdditionsByGroup = [];
        _additions.map(el => {
          if (el.id) {
            this.selectedAdditionsByGroup.push({
              ...el,
              selectedAdditions: [],
            });
          }
        });
      });

    this.productSelector.selectActive
      .pipe(
        filter(_product => !!_product),
        take(1),
      )
      .subscribe(product => {
        this.product = product;
        if (product) {
          this.eventTrackerService.dispatch(EventTrackerService.EventProductViewed, product);
        }
      });
  }

  close(): void {
    this.modalsService.close(KioskProductOverviewModalComponent.handle);
  }

  addToCart(): void {
    if (this.replaceAddToCart) {
      this.product$.pipe(take(1)).subscribe(product => {
        if (this.replaceAddToCart && product) {
          const cartItem: Partial<ICartItem> = {
            [CartItemKeys.ItemId]: product?.id,
            [CartItemKeys.ShopId]: product?.shop_id,
            [CartItemKeys.Additions]: this.selectedAdditions,
            [CartItemKeys.Quantity]: this.quantity,
          };

          this.replaceAddToCart(cartItem as ICartItem);
        }
      });
      this.close();

      return;
    }

    combineLatest([this.product$, this.declinableSelector.selectDeclinables])
      .pipe(
        take(1),
        map(([product, compoundItems]) => {
          if (product?.type === ProductTypeValues.Declinable) {
            const declinable: Partial<ICartItem> = {
              [CartItemKeys.Products]: [],
              [CartItemKeys.ItemId]: product.id,
              [CartItemKeys.ShopId]: product.shop_id,
              [CartItemKeys.Quantity]: this.quantity,
            };
            compoundItems.map(item => {
              if (!item.isValid) {
                this.productService.setInvalidCompoundProduct(item.store_id);
              } else {
                item.selected?.forEach((e: ISelectedCompoundItem) => {
                  const additions: string[] = [];
                  const separateAdditionsByGroups: ICartDeclinableSeparateAdditions[] = [];
                  e.additions_by_group?.forEach(group => {
                    group.selected?.map(el => additions.push(el));
                    separateAdditionsByGroups.push({
                      id: group[AdditionGroupKeys.Id],
                      selected: group.selected as string[],
                    });
                  });
                  declinable[CartItemKeys.Products]?.push({
                    [CartItemKeys.Id]: e.id,
                    [CartItemKeys.Additions]: additions,
                    [CartItemKeys.Quantity]: e.quantity,
                    [CartItemKeys.StoreId]: item.store_id,
                    [CartItemKeys.ShopId]: product.shop_id,
                    [CartItemKeys.SeparateAdditionsByGroup]: separateAdditionsByGroups,
                  });
                });
              }
            });
            this.cartService.addCartItem$(declinable as ICartItem).subscribe(this.onCartItemAdded);
            this.eventTrackerService.dispatch(EventTrackerService.EventProductAddedToCart, {
              product,
              quantity: this.quantity,
            });
            if (KioskProductOverviewModalComponent.onAdd) {
              KioskProductOverviewModalComponent.onAdd();
            }
            this.close();
            return;
          }
          // simple product
          const cartItem: Partial<ICartItem> = {
            [CartItemKeys.ItemId]: product?.id,
            [CartItemKeys.ShopId]: product?.shop_id,
            [CartItemKeys.Additions]: this.selectedAdditions,
            [CartItemKeys.Quantity]: this.quantity,
          };

          this.cartService.addCartItem$(cartItem as ICartItem).subscribe(this.onCartItemAdded);
          this.eventTrackerService.dispatch(EventTrackerService.EventProductAddedToCart, {
            product,
            quantity: this.quantity,
          });

          if (KioskProductOverviewModalComponent.onAdd) {
            KioskProductOverviewModalComponent.onAdd();
          }
          this.close();
        }),
      )
      .subscribe();
  }

  onNavigateToProduct(): void {
    this.navigateToProduct();
    this.close();
  }

  forceType(data: any): any {
    return data as any;
  }
}
