import { Component, Input } from '@angular/core';

import {
  IContentBuilderFieldColor,
  IContentBuilderFieldImage,
} from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-page-hero-block',
  templateUrl: './page-hero-block.component.html',
})
export class PageHeroBlockComponent {
  @Input() title?: string;
  @Input() description?: string;
  @Input() backgroundImage?: IContentBuilderFieldImage;
  @Input() backgroundColor?: IContentBuilderFieldColor;
}
