import { trigger, transition, query, style, stagger, animate } from '@angular/animations';

/**
 * List animation
 * @example
 * ```html
 * <div [@staggeredListAnimation]="{  value: items.length, animateEnterElementFromTopToBottom: true }">
 *  <div *ngFor="let item of items">{{ item }}</div>
 * </div>
 * ```
 */
export const staggeredListAnimation = trigger('staggeredListAnimation', [
  transition(
    (fromState, toState, element, params) => {
      return params?.['animateEnterElementFromTopToBottom'];
    },
    [
      query(
        ':enter',
        [style({ opacity: 0 }), stagger('60ms', animate('600ms ease-out', style({ opacity: 1 })))],
        { optional: true },
      ),
    ],
  ),
  transition(
    (fromState, toState, element, params) => {
      return !params?.['animateEnterElementFromTopToBottom'];
    },
    [
      query(
        ':enter',
        [style({ opacity: 0 }), stagger('-60ms', animate('600ms ease-out', style({ opacity: 1 })))],
        { optional: true },
      ),
    ],
  ),
]);

export const opacityListAnimation = trigger('opacityListAnimation', [
  transition('* <=> *', [
    query(
      ':enter',
      [
        style({ opacity: 0, transform: 'translateY(-10px)' }),
        stagger(
          '100ms',
          animate('500ms ease-out', style({ opacity: 1, transform: 'translateY(0)' })),
        ),
      ],
      { optional: true },
    ),

    query(
      ':leave',
      [animate('300ms ease-in', style({ opacity: 0, transform: 'translateY(-10px)' }))],
      { optional: true },
    ),
  ]),
]);
