import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IShopItem } from '@core/models/shop.model';
import { ShopItemKeys } from '@config/keys/shop.keys';

@Component({
  selector: 'app-shop-list-atom',
  templateUrl: './shop-list-atom.component.html',
})
export class ShopListAtomComponent {
  @Input() shopItem!: IShopItem;
  @Input() isMarketPlaceAvailable!: boolean;

  @Output() navigationClick = new EventEmitter<Event>();

  public get ShopItemKeys(): typeof ShopItemKeys {
    return ShopItemKeys;
  }
}
