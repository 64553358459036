import { ActivatedRoute } from '@angular/router';
import { skipWhile, take, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit } from '@angular/core';

import { DoodOrderModel } from '@store/order/order.model';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { OrderStoreSelector } from '@common/selectors/order.selector';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-order-status-follow-order-card-block',
  templateUrl: './order-status-follow-order-card-block.component.html',
})
export class OrderStatusFollowOrderCardBlockComponent implements OnInit {
  @Input() text?: string;
  @Input() linkText?: string;
  @Input() displayOnlyIfTypes = false;
  @Input() enabledForTypes: string[] = [];
  @Input() displayOnlyIfPaymentMethods = false;
  @Input() enabledForPaymentMethods: string[] = [];
  @Input() textColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() backgroundColor: IContentBuilderFieldColor = {
    value: 'neutral-100',
    type: ColorFieldTypesValues.Palette,
  };

  displayCard = false;

  constructor(
    private orderSelector: OrderStoreSelector,
    readonly routerHelper: RouterHelperService,
    private readonly translate: TranslateService,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    if (!this.text) {
      this.translate.get('order.follow-order').subscribe(text => (this.text = text));
    }
    if (!this.linkText) {
      this.translate.get('order.follow-my-order').subscribe(linkText => (this.linkText = linkText));
    }
    const orderId = this.activatedRoute.snapshot.params.orderId;
    this.orderSelector
      .selectOrder(orderId)
      .pipe(
        skipWhile(order => !order),
        take(1),
        tap(order => this.checkDisplayConditions(order)),
      )
      .subscribe();
  }

  private checkDisplayConditions(order?: DoodOrderModel): void {
    this.displayCard = false;
    if (!order) {
      return;
    }

    if (
      this.displayOnlyIfPaymentMethods &&
      !this.enabledForPaymentMethods.includes(order.payment_service)
    ) {
      return;
    }
    if (this.displayOnlyIfTypes && !this.enabledForTypes.includes(order.type)) {
      return;
    }

    this.displayCard = true;
  }
}
