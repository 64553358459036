import { timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';

import { MarketplaceKeys } from '@config/keys/shop.keys';
import { LanguageFlagsUrls } from '@config/language-flags.config';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { SettingsStoreDispatcher } from '@common/dispatchers/settings.dispatcher';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

@Component({
  selector: 'app-select-language-block',
  templateUrl: './select-language-block.component.html',
})
export class SelectLanguageBlockComponent implements OnInit {
  @Input()
  displayFlag = false;

  locales$ = this.marketplaceSelector.selectMarketplaceLocales;

  selectLanguage!: string;

  languageFlagUrl?: string;

  constructor(
    private settingsSelector: SettingsStoreSelector,
    private settingsDispatcher: SettingsStoreDispatcher,
    private marketplaceSelector: MarketplaceStoreSelector,
    private readonly localizeRouterService: LocalizeRouterService,
  ) {}

  ngOnInit(): void {
    this.selectLanguage = this.settingsSelector.app.locale;

    if (this.marketplaceSelector.marketplace[MarketplaceKeys.Locales]?.length > 1) {
      this.languageFlagUrl = LanguageFlagsUrls.get(this.settingsSelector.app.locale);
    }
  }

  onChangeLanguage(): void {
    this.settingsDispatcher.updateAppLocale(this.selectLanguage);
    this.localizeRouterService.changeLanguage(this.selectLanguage, {
      replaceUrl: true,
    });

    // Wait for new router config load because we can't access observable inside changeLanguage().
    timer(1000)
      .pipe(take(1))
      .subscribe(() => {
        window.location.reload();
      });
  }
}
