import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { DoodCartModel } from '@core/models/cart.model';
import { OrderTypeValues } from '@config/values/order.values';
import { ModalsService } from '@core/services/modals/modals.service';
import { CartStoreSelector } from '@common/selectors/cart.selector';
import { CartParametersModalComponent } from '@shared/modals/cart-parameters-modal/cart-parameters-modal.component';

@Component({
  selector: 'app-cart-on-site-location-block',
  templateUrl: './cart-on-site-location-block.component.html',
})
export class CartOnSiteLocationBlockComponent implements OnInit, OnDestroy {
  @Input() heading = this.translate.instant('basket.on-site.table-service');

  isOnSiteOrdering = false;
  onSiteLocationName?: string;

  private destroyed$ = new Subject<boolean>();

  constructor(
    private cartSelector: CartStoreSelector,
    private readonly translate: TranslateService,
    private readonly modalsService: ModalsService,
  ) {}

  ngOnInit(): void {
    this.cartSelector.selectActive.pipe(tap(cart => this.cartLoaded(cart))).subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  openOnSiteLocationPickerModal(): void {
    this.modalsService.open(CartParametersModalComponent.handle);
  }

  private cartLoaded(cart: DoodCartModel | null): void {
    if (!cart) {
      this.isOnSiteOrdering = false;
      return;
    }

    this.onSiteLocationName = cart.on_site_location_name;
    this.isOnSiteOrdering = cart.type === OrderTypeValues.OnSite;
  }
}
