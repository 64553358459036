<button
  [disabled]="disabled"
  class="flex flex-row justify-center items-center btn-primary py-4 full kiosk:text-3xl kiosk:py-6"
  [class.disabled]="disabled"
  [style.backgroundColor]="buttonBackgroundColor | contentBuilderColor"
  [style.borderColor]="buttonBackgroundColor | contentBuilderColor"
  [style.color]="buttonTextColor | contentBuilderColor"
  (click)="clickEventEmitter.emit($event)"
>
  <i
    *ngIf="icon"
    class="icon {{ icon }} pr-3 kiosk:pr-5"
  ></i>
  <img
    *ngIf="iconUrl"
    src="{{ iconUrl }}"
    [class.mr-3]="label"
  />
  {{ label }}
</button>
