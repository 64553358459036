<div class="relative h-screen w-full">
  <div
    class="absolute bottom-0 left-0 right-0 top-0 flex h-screen w-full items-center bg-black opacity-25"
  ></div>
  <div
    class="absolute bottom-0 left-0 right-0 top-0 z-10 mx-auto flex h-screen w-full items-center justify-center"
  >
    <div>
      <h1
        class="mx-auto mb-8 w-9/12 text-center font-accent text-32px font-bold leading-10 text-white lg:text-5.8xl lg:leading-14"
      >
        {{ title }}
      </h1>
      <div class="flex justify-center">
        <a [link]="linkUrl">
          <app-button-atom
            class="mx-auto"
            [color]="'primary'"
            [type]="'primary'"
            [label]="linkLabel ?? ''"
            [size]="'M'"
            [thickSize]="buttonSizeY"
          ></app-button-atom>
        </a>
      </div>
    </div>
  </div>
  <img
    class="h-full w-full object-cover"
    *ngIf="backgroundImage"
    [src]="backgroundImage.url"
    alt="Hero image"
  />
  <div class="absolute bottom-5 left-0 right-0 z-20 text-center">
    <a (click)="scrollTo()"><i class="icon icon-chevron-down cursor-pointer text-white"></i></a>
  </div>
</div>
