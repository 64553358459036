import { Component, Input } from '@angular/core';
import { KioskService } from '@core/services/kiosk/kiosk.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
})
export class MainLayoutComponent {
  @Input() footer: unknown[] = [];
  @Input() globalFeatures: unknown[] = [];
  @Input() displayDefaultDoodFooter = true;
  @Input() displayOrderStatusUpdateNotification = true;
  @Input() isKioskLayout = false;

  constructor(private readonly kioskService: KioskService) {}

  ngOnInit() {
    this.kioskService.saveKioskLayoutToStore(this.isKioskLayout);
  }
}
