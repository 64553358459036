<div
  class="relative mb-6"
  *ngIf="
    type !== 'STRIPE_GOOGLE_PAY' &&
    type !== 'STRIPE_APPLE_PAY' &&
    type !== 'STRIPE_BANCONTACT' &&
    type !== 'STRIPE_TWINT'
  "
>
  <label class="mb-1 block font-base text-sm font-normal leading-5 text-neutral-600">{{
    'payment.stripe.card-number' | translate
  }}</label>
  <div class="mb-6 flex h-12 w-full border border-solid border-neutral-300 bg-neutral-50 leading-5">
    <div
      id="card-number-element"
      class="field"
    ></div>
  </div>

  <div class="mt-2 grid grid-cols-2 gap-2">
    <div>
      <label class="mb-1 block font-base text-sm font-normal leading-5 text-neutral-600">{{
        'payment.stripe.expiration-date' | translate
      }}</label>
      <div
        class="mb-6 flex h-12 w-full border border-solid border-neutral-300 bg-neutral-50 leading-5"
      >
        <div
          id="card-expiry-element"
          class="field"
        ></div>
      </div>
    </div>
    <div>
      <label class="mb-1 block font-base text-sm font-normal leading-5 text-neutral-600">{{
        'payment.stripe.security-code' | translate
      }}</label>
      <div
        class="mb-6 flex h-12 w-full border border-solid border-neutral-300 bg-neutral-50 leading-5"
      >
        <div
          id="card-cvc-element"
          class="field"
        ></div>
      </div>
    </div>
  </div>

  <div
    *ngIf="isLoading"
    class="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center bg-white"
  >
    <i class="icon icon-privacy animate-pulse text-4xl"></i>
  </div>
</div>

<div
  class="relative mb-6"
  *ngIf="(type === 'STRIPE_GOOGLE_PAY' || type === 'STRIPE_APPLE_PAY') && paymentRequestOptions"
>
  <ngx-stripe-payment-request-button
    [paymentOptions]="paymentRequestOptions"
    (paymentMethod)="onPaymentMethod($event)"
    (notavailable)="onNotAvailable()"
  ></ngx-stripe-payment-request-button>
</div>
