import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-credit-card-atom',
  templateUrl: './add-credit-card-atom.component.html',
})
export class AddCreditCardAtomComponent implements OnChanges {
  @Input() error!: string;
  @Input() card!: {
    number: string;
    date: string;
    code: string;
  };
  @ViewChild('ccNumber') ccNumberField!: ElementRef;

  @Output() closeModal = new EventEmitter();
  @Output() saveCard = new EventEmitter();

  paymentForm!: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.card) {
      this.paymentForm = this.fb.group({
        cardNumber: [
          changes.card.currentValue.number,
          [Validators.required, Validators.pattern('^[ 0-9]*$'), Validators.minLength(17)],
        ],
        date: [changes.card.currentValue.date, [Validators.required]],
        code: [changes.card.currentValue.code, [Validators.required]],
        saveCard: true,
      });
    }
  }

  /* Insert spaces to enhance legibility of credit card numbers */
  creditCardNumberSpacing(): void {
    const input = this.ccNumberField.nativeElement;
    const { selectionStart } = input;
    const { cardNumber } = this.paymentForm.controls;

    let trimmedCardNum = cardNumber.value.replace(/\s+/g, '');

    if (trimmedCardNum.length > 16) {
      trimmedCardNum = trimmedCardNum.substr(0, 16);
    }

    /* Handle American Express 4-6-5 spacing */
    const partitions =
      trimmedCardNum.startsWith('34') || trimmedCardNum.startsWith('37') ? [4, 6, 5] : [4, 4, 4, 4];

    const numbers: any = [];
    let position = 0;
    partitions.forEach(partition => {
      const part = trimmedCardNum.substr(position, partition);
      if (part) {
        numbers.push(part);
      }
      position += partition;
    });

    cardNumber.setValue(numbers.join(' '));

    /* Handle caret position if user edits the number later */
    if (selectionStart < cardNumber.value.length - 1) {
      input.setSelectionRange(selectionStart, selectionStart, 'none');
    }
  }
}
