import { Component, Input } from '@angular/core';

import { ModalsService } from '@core/services/modals/modals.service';

@Component({
  selector: 'app-modal-header-block',
  templateUrl: './modal-header-block.component.html',
})
export class ModalHeaderBlockComponent {
  @Input() modalHandle!: string;
  @Input() heading?: string;
  @Input() displayCloseIcon = true;

  constructor(private readonly modalsService: ModalsService) {}

  close(): void {
    this.modalsService.close(this.modalHandle);
  }
}
