<ng-container *ngIf="selectIsPongoPluginEnabled | async">
  <ng-container *ngIf="customer">
    <div
      class="mb-5 bg-neutral-100 lg:rounded lg:rounded-s"
      [ngClass]="{
        'max-w-md mx-auto lg:max-w-4xl': width === 'centered',
        'w-full': width === 'full',
      }"
    >
      <div
        class="flex items-center justify-between px-5 pb-2 pt-6"
        *ngIf="heading"
      >
        <h2 class="font-accent text-2xl font-bold text-neutral-800 lg:text-3xl">
          {{ heading | translate }}
        </h2>
      </div>
      <div
        class="px-5 pb-2 font-base text-sm text-neutral-600 lg:text-base"
        *ngIf="subheading"
      >
        {{ subheading }}
      </div>
      <div
        class="flex flex-row items-center px-5 pb-2 font-base text-sm text-neutral-600 lg:text-base"
      >
        <i class="icon icon-coins mt-0.5 pr-1.5"></i>
        <ng-container *ngIf="!customer.points">
          <span
            [translate]="'pongo.amount-of-0-point'"
            [translateParams]="{ value: 'world' }"
          ></span>
        </ng-container>
        <ng-container *ngIf="customer.points">
          <span
            [translate]="'pongo.amount-of-points'"
            [translateParams]="{ points: customer.points }"
          ></span>
        </ng-container>
      </div>
      <div
        class="px-5 pb-6 pt-2 font-base text-sm text-neutral-600 lg:text-base"
        *ngIf="!assets || assets.length === 0"
      >
        {{ 'pongo.no-assets' | translate }}
      </div>
      <ng-container *ngIf="!isDesktop">
        <ul
          class="flex overflow-x-auto px-5 pb-8 pt-2 scrollbar-hide"
          *ngIf="assets.length > 0"
        >
          <li
            class="w-6/12 max-w-56 flex-shrink-0 flex-grow-0 pr-3"
            *ngFor="let asset of assets"
          >
            <ng-container *ngTemplateOutlet="assetCard; context: { asset }"></ng-container>
          </li>
        </ul>
      </ng-container>
      <ng-container *ngIf="isDesktop">
        <div class="overflow-x-auto px-5 pb-8 pt-2">
          <app-card-carousel
            [carouselItem]="productTemplate1"
            [nbDisplayCards]="itemsToShowCardCarousel"
            [cards]="assets"
          >
          </app-card-carousel>
        </div>

        <ng-template
          #productTemplate1
          let-asset
        >
          <div class="flex-1 items-stretch overflow-hidden rounded-lg bg-white shadow-S">
            <ng-container *ngTemplateOutlet="assetCard; context: { asset }"></ng-container>
          </div>
        </ng-template>
      </ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-template
  #assetCard
  let-asset="asset"
>
  <div
    class="flex h-full flex-col justify-between overflow-hidden rounded-lg bg-white shadow-S"
    [ngClass]="{
      'opacity-50': !asset.is_usable && !asset.is_buyable,
    }"
  >
    <div
      *ngIf="asset.picture_url"
      class="flex-shrink-0 bg-neutral-50"
      style="aspect-ratio: 1"
    >
      <img
        [src]="asset.picture_url"
        class="object-contain"
        style="aspect-ratio: 1"
      />
    </div>
    <div class="flex h-full flex-col justify-between px-4 py-4 text-sm">
      <div>
        <p class="mb-2 pt-1 font-base text-base font-bold leading-5 lg:text-md">
          {{ asset.name }}
        </p>
        <p *ngIf="!asset.is_usable && asset.is_buyable">
          {{ 'pongo.buy-for-points' | translate: asset }}
        </p>
        <p *ngIf="!asset.is_usable && !asset.is_buyable">
          {{ 'pongo.accessible-in-points' | translate: asset }}
        </p>
      </div>

      <div class="mt-6 flex flex-col items-center justify-center">
        <div
          class="mb-2 flex flex-row items-center justify-center text-sm font-normal leading-snug lg:text-base"
          *ngIf="isAssetInCart(asset)"
        >
          <i class="icon icon-check-circle mr-2 text-success-600"></i>
          {{ 'pongo.asset-added-to-cart' | translate }}
        </div>
        <app-button-atom
          *ngIf="asset.is_usable && !isAssetInCart(asset)"
          [label]="'pongo.use-voucher' | translate"
          (click)="useAsset(asset)"
          icon="icon-plus"
          size="XS"
          thickSize="XS"
          type="outlined"
          color="neutral"
        ></app-button-atom>
        <app-button-atom
          *ngIf="asset.is_usable && isAssetInCart(asset)"
          [label]="'pongo.remove-voucher' | translate"
          (click)="removeAsset(asset)"
          icon="icon-c-remove"
          size="XS"
          thickSize="XS"
          type="outlined"
          color="neutral"
        ></app-button-atom>
        <app-button-atom
          *ngIf="asset.is_buyable"
          [label]="'pongo.buy-voucher' | translate"
          (click)="buyAsset(asset)"
          [icon]="asset.buyInProcess ? '' : 'icon-plus'"
          [iconUrl]="asset.buyInProcess ? 'assets/images/spinner.svg' : ''"
          size="XS"
          thickSize="XS"
          type="outlined"
          color="neutral"
        ></app-button-atom>
      </div>
    </div>
  </div>
</ng-template>
