import { Component } from '@angular/core';
import { map } from 'rxjs/operators';
import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ModalsService } from '@core/services/modals/modals.service';
@Component({
  selector: 'app-pat-availability-modal',
  template: `
    <app-modal-filler-atom [index]="5"></app-modal-filler-atom>
    <div
      class="md:left-0' fixed bottom-0 left-0 right-0 mx-auto max-h-85-screen max-w-screen-sm overflow-y-auto md:top-0 md:flex md:h-full md:w-full md:items-center md:justify-center"
      [style.z-index]="750"
    >
      <div
        class="bg-white p-5"
        [ngClass]="(isMobile$ | async) ? 'rounded-t-3xl' : 'rounded-3xl min-w-448'"
        [style.z-index]="((index$ | async) ?? 0) + 50"
      >
        <div
          *ngIf="(data$ | async)?.message as message"
          class="py-6 font-accent text-base"
        >
          {{ message }}
        </div>
        <app-button-atom
          [type]="'primary'"
          [size]="'L'"
          [label]="'page-not-found.button-label' | translate"
          (click)="redirectToHomepage()"
        ></app-button-atom>
      </div>
    </div>
  `,
})
export class PatAvailabilityModalComponent {
  static handle = 'plugin-availability-modal';
  isMobile$ = this.settingsSelector.selectDeviceIsMobileScreen;
  data$: Observable<any> = this.modalSelector
    .selectModal(PatAvailabilityModalComponent.handle)
    .pipe(map(el => (el?.data ? el.data : {})));
  index$ = this.modalSelector
    .selectModal(PatAvailabilityModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));
  constructor(
    private readonly modalSelector: ModalStoreSelector,
    private readonly settingsSelector: SettingsStoreSelector,
    private readonly router: Router,
    private modalsService: ModalsService,
  ) {}
  redirectToHomepage(): void {
    this.router.navigate([`/`]);
    this.closeModal();
  }
  closeModal(): void {
    this.modalsService.close(PatAvailabilityModalComponent.handle);
  }
}
