import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';

import { IAdditionGroup } from '@core/models/shop.model';
import { ProductUtils } from '@app/utilities/product.util';
import { DoodProductModel } from '@core/models/product.model';
import { ShopStoreRefiner } from '@app/common/refiners/shop.refiner';
import { ShopStoreSelector } from '@app/common/selectors/shop.selector';
import { ProductService } from '@core/services/product/product.service';
import { ProductStoreSelector } from '@app/common/selectors/product.selector';

@Injectable({ providedIn: 'root' })
export class ProductStoreRefiner {
  constructor(
    private shopRefiner: ShopStoreRefiner,
    private productService: ProductService,
    private shopSelector: ShopStoreSelector,
    private productSelector: ProductStoreSelector,
  ) {}

  selectShopProductById(
    shopId: string | null,
    productId: string | null,
  ): Observable<DoodProductModel | undefined> {
    if (!shopId || !productId) {
      return of(undefined);
    }
    const id = ProductUtils.generateProductId(shopId, productId);
    return this.productSelector.selectProduct(id);
  }

  selectAdditions = combineLatest([
    this.shopRefiner.selectActiveShopProducts.pipe(
      map(products => products?.filter(product => product.available && !product.hidden)),
    ),
    this.productSelector.selectActive,
    this.productSelector.selectProducts,
  ]).pipe(
    map(([products, selectedProduct, marketplaceProducts]) =>
      ProductUtils.mapAdditions(
        products ? products : marketplaceProducts,
        selectedProduct?.additions?.products ?? [],
      ),
    ),
  );

  selectAdditionsByGroups = combineLatest([
    this.shopRefiner.selectActiveShopAdditionGroups,
    this.selectAdditions,
    this.shopSelector.selectShops.pipe(
      map(shops => {
        const additionGroupArr: IAdditionGroup[] = [];
        shops.map(shop => {
          shop.addition_groups.forEach(additionGroup => additionGroupArr.push(additionGroup));
        });
        return additionGroupArr;
      }),
    ),
  ]).pipe(
    map(([groups, additions, allAdditionsGroupMarketPlace]) => {
      if (groups) {
        return this.productService.getAdditionsByGroup(groups, additions);
      } else if (allAdditionsGroupMarketPlace) {
        return this.productService.getAdditionsByGroup(allAdditionsGroupMarketPlace, additions);
      } else {
        return [];
      }
    }),
    map(groups => {
      return groups.map(group => {
        return this.cloneGroupAndItemsWith0Quantity(group);
      });
    }),
  );

  getAdditionsByGroup$(additions: DoodProductModel[]) {
    return combineLatest([
      this.shopRefiner.selectActiveShopAdditionGroups,
      this.shopSelector.selectShops.pipe(
        map(shops => {
          const additionGroupArr: IAdditionGroup[] = [];
          shops.map(shop => {
            shop.addition_groups.forEach(additionGroup => additionGroupArr.push(additionGroup));
          });
          return additionGroupArr;
        }),
      ),
    ]).pipe(
      map(([groups, allAdditionsGroupMarketPlace]) => {
        if (groups) {
          return this.productService.getAdditionsByGroup(groups, additions);
        } else if (allAdditionsGroupMarketPlace) {
          return this.productService.getAdditionsByGroup(allAdditionsGroupMarketPlace, additions);
        } else {
          return [];
        }
      }),
      map(groups => {
        return groups.map(group => {
          return this.cloneGroupAndItemsWith0Quantity(group);
        });
      }),
    );
  }

  // Ensure that the quantity is set to 0 and it avoid to share the same reference, because the product quantity is mutated
  private cloneGroupAndItemsWith0Quantity(group: IAdditionGroup) {
    return {
      ...group,
      items: group.items.map(product => ({
        ...product,
        quantity: 0,
      })),
    };
  }
}
