import { createReducer } from '@ngrx/store';

import {
  DECLINABLE_STORE_ACTIVE_PIECE,
  DECLINABLE_STORE_API_PIECE,
  DECLINABLE_STORE_LOCAL_PIECE,
} from './declinable.piece';
import { DECLINABLE_STORE_INITIAL_STATE } from './declinable.state';

export const DECLINABLE_STORE_REDUCER = createReducer(
  DECLINABLE_STORE_INITIAL_STATE,
  ...DECLINABLE_STORE_ACTIVE_PIECE,
  ...DECLINABLE_STORE_API_PIECE,
  ...DECLINABLE_STORE_LOCAL_PIECE,
);
