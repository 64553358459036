<ng-container *ngIf="comoUser && !(basketId$ | async)">
  <div class="max-w-md mx-auto px-5 lg:max-w-full lg:px-0">
    <div
      class="mb-3 cursor-pointer"
      (click)="useCreditBalance()"
    >
      <div
        class="flex flex-row py-5 px-4.5 border-neutral-400 border text-neutral-800 rounded rounded-s"
      >
        <div class="flex flex-col flex-grow">
          <div class="font-base font-bold text-base lg:text-md">
            {{ 'como.credit-balance-heading' | translate }}
          </div>
          <div class="font-base text-neutral-600 text-sm lg:text-base py-1">
            {{ 'como.credit-balance-label' | translate }}
            <app-price-atom
              [amount]="comoUser.credit_balance.monetary"
              [format]="true"
              [bold]="false"
            ></app-price-atom>
          </div>
          <div class="font-base font-bold text-sm lg:text-base text-primary-600 cursor-pointer">
            {{ 'como.credit-balance-use-button' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
