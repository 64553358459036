import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, switchMap, tap } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { OrdersService } from '@core/services/orders/orders.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';

import { DoodOrderType } from '@store/order/order.model';
import { OrderStoreSelector } from '@common/selectors/order.selector';

import { CartUserInformationsModalComponent } from '@shared/modals/cart-user-informations-modal/cart-user-informations-modal.component';

@Injectable({
  providedIn: 'root',
})
export class OrderValidateGuard {
  constructor(
    private readonly router: Router,
    private readonly modalsService: ModalsService,
    private readonly ordersService: OrdersService,
    private readonly routerHelper: RouterHelperService,
    private readonly orderSelector: OrderStoreSelector,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.orderSelector.selectValidation.pipe(
      switchMap(order => {
        const queryParams = route.queryParams;
        const orderId = queryParams?.order_id;
        const paymentMethod = queryParams?.payment_method;

        if (orderId) {
          return this.orderSelector.selectOrder(orderId).pipe(map(_order => !!_order));
        }
        if (paymentMethod) {
          return of(true);
        }

        const orderType = order?.type;
        const isDeliveryOrShipping =
          orderType === DoodOrderType.delivery || orderType === DoodOrderType.shipping;
        const hasNoPhoneNumber = order?.contact_phone_number === null;

        if (order && isDeliveryOrShipping && hasNoPhoneNumber) {
          this.ordersService.setDefaultPhone();
          this.modalsService.open(CartUserInformationsModalComponent.handle);
          return of(false);
        }

        return of(!!order);
      }),
      tap(isValidated => {
        if (!isValidated) {
          this.router.navigate([this.routerHelper.translateRoute('/cart')]);
        }
      }),
    );
  }
}
