export class SlugUtils {
  static formatSlug(originalString: string): string {
    originalString = originalString?.replace(/^\s+|\s+$/g, ''); // trim
    originalString = originalString?.toLowerCase();

    // remove accents, swap ñ for n, etc
    const from = 'àáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
    const to = 'aaaaaeeeeiiiioooouuuunc------';

    for (let i = 0, l = from.length; i < l; i++) {
      originalString = originalString?.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    originalString = originalString
      ?.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

    return originalString;
  }
}
