import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { CartKeys } from '@config/keys/cart.keys';

import { OrdersService } from '@core/services/orders/orders.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { ErrorService } from '@core/services/error-service/error.service';

import { CartStoreRefiner } from '@common/refiners/cart.refiner';
import { OrderStoreSelector } from '@common/selectors/order.selector';
import { CartStoreDispatcher } from '@common/dispatchers/cart.dispatcher';

import { CartVoucherModalComponent } from '@shared/modals/cart-voucher-modal/cart-voucher-modal.component';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-cart-voucher-block',
  templateUrl: './cart-voucher-block.component.html',
})
export class CartVoucherBlockComponent implements OnInit, OnDestroy {
  @Input() headingColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };

  orderValidate$ = this.orderSelector.selectValidation;
  isValidVoucher$ = this.orderSelector.selectValidation.pipe(
    map(validation => validation?.coupons?.[0]?.valid ?? false),
  );

  voucherCode$ = this.orderSelector.selectValidation.pipe(
    map(validation => validation?.coupons?.[0]?.valid ?? false),
  );

  cartCoupon$ = this.cartRefiner.selectActiveCartCoupon;
  errorMessage$ = this.errorService.getErrorMessage$().pipe(map(message => message.length));

  private readonly destroyed$ = new Subject<boolean>();

  constructor(
    private cartRefiner: CartStoreRefiner,
    private cartDispatcher: CartStoreDispatcher,
    private readonly errorService: ErrorService,
    private readonly modalsService: ModalsService,
    private readonly ordersService: OrdersService,
    private readonly orderSelector: OrderStoreSelector,
  ) {}

  ngOnInit(): void {
    this.isValidVoucher$.pipe(takeUntil(this.destroyed$)).subscribe(v => {
      if (v) {
        this.modalsService.close(CartVoucherModalComponent.handle);
      }
    });
  }

  openInputVoucher(): void {
    this.modalsService.open(CartVoucherModalComponent.handle);
  }

  ngOnDestroy(): void {
    this.modalsService.close(CartVoucherModalComponent.handle);
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onRemoveVoucher(event: Event): void {
    this.cartDispatcher.updateActive({ [CartKeys.Coupons]: [] });
    // TODO: Check if this is unsubscribed correctly when done
    this.ordersService.checkCartIsValid$().subscribe();
  }
}
