import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { Component } from '@angular/core';

import { DoodModalModel } from '@core/models/modal.model';
import { DoodModalConfig } from '@core/models/marketplace.model';

import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { ContentStoreSelector } from '@common/selectors/content.selector';

@Component({
  selector: 'app-modals-container',
  templateUrl: './modals-container.component.html',
})
export class ModalsContainerComponent {
  modals: DoodModalConfig[] = [];

  modals$ = this.modalSelector.selectModals;
  modalConfigs$ = this.contentSelector.selectModals;
  modalList$ = combineLatest([this.modals$, this.modalConfigs$]).pipe(map(this.joinItems));

  constructor(
    private modalSelector: ModalStoreSelector,
    private contentSelector: ContentStoreSelector,
  ) {
    this.modalList$.subscribe(_modals => {
      this.modals = _modals;
    });
  }

  joinItems([modals, modalConfigs]: [DoodModalModel[], DoodModalConfig[]]): DoodModalConfig[] {
    const modalConfigsToDisplay = [];
    for (const modal of modals) {
      const modalConfig = modalConfigs.find(m => modal.handle === m.handle);
      if (!modalConfig) {
        console.error('Modal not found ' + modal.handle);
        continue;
      }
      modalConfigsToDisplay.push({ ...modalConfig, data: modal.data });
    }
    return modalConfigsToDisplay;
  }

  getModalData(modal: DoodModalModel): DoodModalModel {
    return { ...(modal.data || {}), handle: modal.handle };
  }

  itemTrackBy(index: number, modal: DoodModalModel): string {
    return modal.handle;
  }
}
