import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { OrderStoreSelector } from '@app/common/selectors/order.selector';
import { DOOD_ORDER_HISTORICAL_STATUS } from '@store/order/order.constant';
import { Observable } from 'rxjs';
import { OrdersService } from '@core/services/orders/orders.service';

@Injectable({ providedIn: 'root' })
export class OrderStoreRefiner {
  selectHasActiveOrder = this._selector.selectActive.pipe(map(entity => !!entity));
  selectSelectedOrderVouchers = this._selector.selectActive.pipe(map(o => o?.coupons));

  selectActiveOrders = this._selector.selectOrders.pipe(
    map(orders => orders.filter(_order => !DOOD_ORDER_HISTORICAL_STATUS.includes(_order.status))),
  );
  selectHistoricalOrders = this._selector.selectOrders.pipe(
    map(orders => orders.filter(_order => DOOD_ORDER_HISTORICAL_STATUS.includes(_order.status))),
  );

  constructor(
    private _selector: OrderStoreSelector,
    private readonly ordersService: OrdersService,
  ) {}

  public selectContainsAnyError(errorCodes: string[]): Observable<boolean> {
    return this._selector.selectErrors.pipe(
      map(error => this.ordersService.containsAnyError(errorCodes, error.violations)),
    );
  }
}
