import { Component, Input } from '@angular/core';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-social-links-block',
  templateUrl: './social-links-block.component.html',
})
export class SocialLinksBlockComponent {
  @Input() facebookUrl?: string;
  @Input() twitterUrl?: string;
  @Input() instagramUrl?: string;
  @Input() linkedinUrl?: string;
  @Input() tiktokUrl?: string;
  @Input() textColor?: IContentBuilderFieldColor;
}
