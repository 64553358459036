import { createHydratedReducer } from '@store/_abstract/hydrated';

import {
  SETTINGS_STORE_API_PIECE,
  SETTINGS_STORE_LOCAL_PIECE,
  SETTINGS_STORE_HYDRATION_PIECE,
} from './settings.piece';
import { SETTINGS_STORE_KEY, SETTINGS_STORE_INITIAL_STATE, SettingsState } from './settings.state';

export const SETTINGS_STORE_REDUCER = createHydratedReducer<SettingsState>({
  key: SETTINGS_STORE_KEY,
  initialState: SETTINGS_STORE_INITIAL_STATE,
  beforeLoad: (_state: SettingsState) => {
    let now = new Date();
    const parameters = { ..._state.parameters };

    // Ask distribution mode again after one hour
    let oneHour = 3600 * 1000;
    if (
      parameters.last_update &&
      now.getTime() - new Date(parameters.last_update).getTime() > oneHour
    ) {
      parameters.is_distribution_mode_defined = false;
    }
    if (parameters.wanted_at && new Date(parameters.wanted_at) < now) {
      parameters.wanted_at = null;
    }
    return {
      ..._state,
      parameters,
    };
  },
  ons: [
    ...SETTINGS_STORE_API_PIECE,
    ...SETTINGS_STORE_LOCAL_PIECE,
    ...SETTINGS_STORE_HYDRATION_PIECE,
  ],
});
