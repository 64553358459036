import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LocalizeRouterSettings } from '@gilsdav/ngx-translate-router';
import { LocalizeRouterHttpLoader } from '@gilsdav/ngx-translate-router-http-loader';
import { LOCALE_ID } from '@angular/core';

export const DOOD_DEFAULT_LOCALE = 'fr';
export const DOOD_DEFAULT_LOCALE_PROVIDER = { provide: LOCALE_ID, useValue: DOOD_DEFAULT_LOCALE };
export const AppHttpLoaderFactory = (http: HttpClient): TranslateHttpLoader => {
  return new TranslateHttpLoader(http, '/assets/i18n/', `.json?cb=${new Date().getTime()}`);
};

export const AppLocalizeHttpLoaderFactory = (
  translate: TranslateService,
  location: Location,
  settings: LocalizeRouterSettings,
  http: HttpClient,
): LocalizeRouterHttpLoader => {
  return new LocalizeRouterHttpLoader(
    translate,
    location,
    { ...settings, alwaysSetPrefix: true },
    http,
  );
};
