import { Component, OnInit } from '@angular/core';
import { ModalsService } from 'src/app/core/services/modals/modals.service';

import { ModalScrollBlockBase } from '@core/base/modalScrollBlock/modal-scroll-block.base';
import { ComoCreateOrLinkAccountModalComponent } from '@shared/modals/como/como-create-or-link-account-modal/como-create-or-link-account-modal.component';

@Component({
  selector: 'app-como-create-account-modal',
  templateUrl: './como-create-account-modal.component.html',
})
export class ComoCreateAccountModalComponent extends ModalScrollBlockBase implements OnInit {
  static handle = 'como-create-account-modal';

  constructor(private readonly modalsService: ModalsService) {
    super();
  }

  ngOnInit(): void {
    this.modalsService.open(ComoCreateOrLinkAccountModalComponent.handle);
    this.modalsService.close(ComoCreateAccountModalComponent.handle);
  }
}
