import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-big-footer-column-block',
  templateUrl: './big-footer-column-block.component.html',
})
export class BigFooterColumnBlockComponent {
  @Input() title!: string;
  @Input() items: unknown[] = [];
}
