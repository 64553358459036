import { camelCase } from 'lodash';

import { ObjectUtils } from '@app/utilities/object.util';

export class CamelCaseUtils {
  static convertAttributeNamesToCamelCase(data: unknown): Record<string, unknown> | null {
    if (!data || !ObjectUtils.isObject(data)) {
      return null;
    }

    const kebabCaseKeys: Record<string, unknown> = {};
    for (const [key, value] of Object.entries(data)) {
      kebabCaseKeys[camelCase(key)] = value;
    }
    return { ...data, ...kebabCaseKeys };
  }
}
