<div
  *ngIf="availablePaymentMethods"
  class="w-full md:mx-auto md:max-w-md"
  [style.color]="textColor | contentBuilderColor"
>
  <div
    class="mt-6 lg:mt-12 kiosk:mb-16 kiosk:mt-12"
    [ngClass]="remainingTime ? 'lg:mb-6' : 'lg:mb-12'"
  >
    <h1
      class="mb-3 mt-6 hidden text-center font-accent text-5xl font-bold md:block"
      [style.color]="headingColor | contentBuilderColor"
    >
      {{ 'payment.title' | translate }}
    </h1>
    <h2
      class="mb-3 mt-5 px-5 font-accent text-md font-bold md:mt-6 md:text-center"
      [style.color]="headingColor | contentBuilderColor"
      *ngIf="availablePaymentMethods.length > 1"
    >
      {{ 'payment.subtitle' | translate }}
    </h2>
    <p
      *ngIf="remainingTime"
      class="mx-auto mb-2 px-5 text-secondary-700 md:text-center"
    >
      {{ 'payment.payment-remaing-time-1' | translate }}
      {{ remainingTime }}
      {{ 'payment.payment-remaing-time-2' | translate }}
    </p>
  </div>
  <div
    class="border-t md:rounded-s md:border"
    [ngClass]="{
      'mb-4': bottomMargin === 'small',
      'mb-80': bottomMargin === 'large',
    }"
  >
    <div
      *ngFor="let paymentMethod of availablePaymentMethods"
      class="border-b border-neutral-300 bg-white"
      [class.disabled]="status.isRetryInProgress && paymentMethod !== selectedPaymentMethod"
    >
      <div class="flex cursor-pointer flex-col px-5">
        <div
          class="flex items-center justify-between py-4.5"
          (click)="
            availablePaymentMethods.length > 1
              ? selectPaymentMethod(paymentMethod)
              : $event.stopPropagation()
          "
        >
          <div class="flex w-4/6 items-center">
            <span
              class="pr-1.5 text-xs"
              *ngIf="availablePaymentMethods.length > 1"
            >
              <input
                type="radio"
                [ngClass]="{
                  'p-2.5 bg-primary-600 border-primary-600 m-1 shadow-custom':
                    paymentMethod === selectedPaymentMethod,
                }"
                class="form-radio m-2 border-2 border-neutral-400 bg-none p-1.5"
                [checked]="paymentMethod === selectedPaymentMethod"
              />
            </span>
            @if (
              paymentMethod.label === 'payment.on-site' &&
              (order$ | async)?.[orderKeys.FinalPrice] === 0
            ) {
              <span>{{ 'payment.on-site-zero' | translate }}</span>
            } @else if (
              paymentMethod.label === 'payment.on-site' &&
              forceType(paymentMethod.data)?.customer_group_label
            ) {
              <span>{{ forceType(paymentMethod.data)?.customer_group_label }}</span>
            } @else {
              <span>{{ paymentMethod.label | translate }}</span>
            }
          </div>
          <div class="flex w-2/6 justify-end">
            <img
              *ngFor="let logo of paymentMethod.logos"
              alt="logo"
              class="ml-2 max-h-6"
              [src]="logo"
            />
          </div>
        </div>
        <app-component-factory
          [componentClass]="paymentMethod.panelComponent"
          [data]="paymentMethod.data"
          *ngIf="paymentMethod === selectedPaymentMethod"
        ></app-component-factory>
      </div>
    </div>
    <div class="hidden md:block">
      <div
        *ngIf="order$ | async as order"
        class="pt-8 text-center"
      >
        <span class="mr-1.5 font-accent text-2xl font-bold">Total</span>
        <div class="md:hidden">
          <app-price-atom
            [amount]="amount ?? order?.[orderKeys.FinalPrice] ?? 0"
            [format]="true"
            [size]="'MD'"
          ></app-price-atom>
        </div>
        <div class="hidden md:inline-block">
          <app-price-atom
            [amount]="amount ?? order?.[orderKeys.FinalPrice] ?? 0"
            [format]="true"
            [size]="'2XL'"
          ></app-price-atom>
        </div>
      </div>

      <div
        class="flex justify-center px-4 pb-4 text-center"
        *ngIf="error"
      >
        <span class="font-base text-error-600">
          {{ error | translate }}
        </span>
      </div>
      <div *ngIf="hasErrorMessages">
        <p
          class="my-5 rounded bg-error-50 px-4 py-3 font-base text-sm text-error-700"
          *ngFor="let message of errorMessages"
        >
          {{ message }}
        </p>
      </div>

      <div class="mb-10 flex justify-center pt-4">
        <app-button-atom
          *ngIf="button.isVisible"
          [icon]="!(status.isInProgress || status.isLoading) ? 'icon-black-lock' : ''"
          [iconUrl]="status.isInProgress || status.isLoading ? 'assets/images/spinner.svg' : ''"
          [label]="
            (button.text === 'payment.validate-on-site' &&
            (order$ | async)?.[orderKeys.FinalPrice] === 0
              ? 'payment.validate-on-site-zero'
              : button.text
            ) | translate
          "
          [type]="'primary'"
          [size]="'M'"
          [disabled]="!button.isEnabled || status.isInProgress || hasErrorMessages"
          (click)="triggerPayment()"
        ></app-button-atom>
      </div>
    </div>
  </div>
</div>

<div class="fixed bottom-0 left-0 right-0 z-30 bg-white shadow shadow-top md:hidden">
  <div
    class="container mx-auto p-4 pt-4.5 align-middle"
    [class.pb-7]="isNotched"
  >
    <div
      *ngIf="error"
      class="flex justify-between pb-4"
    >
      <span class="font-base text-error-600">
        {{ error | translate }}
      </span>
    </div>
    <div
      class="flex items-center justify-center"
      *ngIf="order$ | async as order"
    >
      <span class="mr-1.5 font-accent text-md font-bold">Total</span>
      <app-price-atom
        [amount]="amount ?? order?.[orderKeys.FinalPrice] ?? 0"
        [format]="true"
        [size]="'MD'"
      ></app-price-atom>
    </div>
    <div *ngIf="hasErrorMessages">
      <p
        class="px-4 pt-4 text-base font-normal text-error-700"
        *ngFor="let message of errorMessages"
      >
        {{ message }}
      </p>
    </div>
    <div
      class="pt-4.5"
      *ngIf="button.isVisible"
    >
      <app-button-atom
        [icon]="!(status.isInProgress || status.isLoading) ? 'icon-black-lock' : ''"
        [iconUrl]="status.isInProgress || status.isLoading ? 'assets/images/spinner.svg' : ''"
        [label]="
          (button.text === 'payment.validate-on-site' &&
          (order$ | async)?.[orderKeys.FinalPrice] === 0
            ? 'payment.validate-on-site-zero'
            : button.text
          ) | translate
        "
        [type]="'primary'"
        [size]="'L'"
        [disabled]="!button.isEnabled || status.isInProgress || hasErrorMessages"
        (click)="triggerPayment()"
      ></app-button-atom>
    </div>
  </div>
</div>

<ng-container *ngIf="status.isInProgress">
  <app-payment-loading-screen-atom></app-payment-loading-screen-atom>
</ng-container>
