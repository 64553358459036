<app-modal-filler-atom [index]="(index$ | async) ?? 0"></app-modal-filler-atom>
<div
  class="fixed right-0 left-0 shadow overflow-y-auto mdMax:bottom-0 mdMax:max-h-85-screen mdMax:max-w-screen-sm mdMax:mx-auto md:flex md:justify-center md:items-center md:w-full md:h-full md:top-0 md:left-0"
  [style.z-index]="((index$ | async) ?? 0) + 50"
>
  <div
    class="bg-white p-5 md:p-7 rounded-t-3xl md:rounded-3xl md:max-w-lg md:w-2/5"
    [style.z-index]="((index$ | async) ?? 0) + 50"
  >
    <div class="flex justify-between items-center pb-5">
      <h1
        class="text-neutral-800 font-brand font-bold text-28px"
        translate="kiosk.reset-modal.heading"
      ></h1>
    </div>
    <div class="pb-7">
      <app-button-atom
        [type]="'primary'"
        [label]="'kiosk.reset-modal.continue-button' | translate"
        [size]="'L'"
        (click)="close()"
      ></app-button-atom>
    </div>
    <div class="pb-3">
      <app-button-atom
        [type]="'light'"
        [label]="('kiosk.reset-modal.new-cart-button' | translate) + ' (' + remainingSeconds + ')'"
        [size]="'L'"
        (click)="startNewCart()"
      ></app-button-atom>
    </div>
  </div>
</div>
