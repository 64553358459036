export class LocalStorageUtil {
  static get size(): number {
    return localStorage.length;
  }

  static get(key: string): string | null {
    return localStorage.getItem(key);
  }

  static getAll(keys: string[]): Record<string, unknown> {
    const res: Record<string, unknown> = {};
    for (const key of keys) {
      res[key] = LocalStorageUtil.get(key);
    }
    return res;
  }

  static set(key: string, value: unknown): void {
    if (typeof value === 'string') {
      return localStorage.setItem(key, value);
    }
    localStorage.setItem(key, JSON.stringify(value));
  }

  static setAll(values: { key: string; value: unknown }[]): void {
    return values.forEach(({ key, value }) => {
      LocalStorageUtil.set(key, value);
    });
  }

  static remove(key: string): void {
    return localStorage.removeItem(key);
  }

  static removeAll(keys: string[]): void {
    if (keys) {
      return keys.forEach((key: string) => {
        LocalStorageUtil.remove(key);
      });
    }
  }

  static clear(): void {
    return localStorage.clear();
  }
}
