import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-mosaic-push-atom',
  templateUrl: './mosaic-push-atom.component.html',
})
export class MosaicPushAtomComponent {
  @Input() imageUrl!: string;
  @Input() title!: string;
  @Input() linkUrl!: string;
  @Input() linkTarget = '_self';
  @Input() aspectRatio?: string;
  @Input() rounded?: string;
  @Input() shadow?: string;
  @Input() font?: string;
  @Input() fontSize?: string;
  @Input() textColor?: IContentBuilderFieldColor;
  @Input() backgroundColor?: IContentBuilderFieldColor;
  @Input() backgroundStyleContain?: boolean;
  @Output() clickEmitter = new EventEmitter();

  onClick(): void {
    this.clickEmitter.emit();
  }
}
