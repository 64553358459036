import { Component, Input } from '@angular/core';
import { IContentBuilderFieldColor } from 'src/app/core/models/content-builder-fields.model';

@Component({
  selector: 'app-mosaic-como-long-push-atom',
  templateUrl: './mosaic-como-long-push-atom.component.html',
  styleUrls: ['./mosaic-como-long-push-atom.component.scss'],
})
export class MosaicComoLongPushAtomComponent {
  @Input() heading!: string;
  @Input() text!: string;
  @Input() textColor: IContentBuilderFieldColor | undefined;
  @Input() backgroundColor?: IContentBuilderFieldColor;
  @Input() aspectRatio?: string;
  @Input() rounded?: string;
  @Input() shadow?: string;

  constructor() {}
}
