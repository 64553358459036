import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-payment-panel-adyen-meal-voucher-groupe-up',
  templateUrl: './payment-panel-adyen-meal-voucher-groupe-up.component.html',
})
export class PaymentPanelAdyenMealVoucherGroupeUpComponent {
  @Input() clientKey?: string;
  @Input() paymentMethodMealVoucherGroupeUpAvailable = false;
}
