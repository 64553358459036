<div
  (click)="onProductClick(product)"
  class="flex h-full border-separate cursor-pointer flex-col justify-between overflow-hidden rounded-lg bg-white text-sm font-bold shadow-S"
  [class.opacity-40]="!isAvailable(product) || !isOrderingAllowed"
  [class.cursor-pointer]="isAvailable(product) && isOrderingAllowed"
>
  <div
    *ngIf="product?.images?.thumbnail?.url"
    class="bg-neutral-50"
    [style.backgroundColor]="productImagePlaceholderColor | contentBuilderColor"
  >
    <img
      [src]="product?.images?.thumbnail?.url"
      class="h-36 max-h-36 w-full rounded-t-lg object-cover lg:h-52 lg:max-h-52"
      alt="img"
    />
  </div>
  <div class="flex h-full flex-col justify-between px-3 py-2 text-sm">
    <p
      class="break-words pb-1 pt-1 font-accent text-sm font-bold lg:pb-2 lg:text-base"
      [style.color]="productNameColor | contentBuilderColor"
    >
      {{ product[ProductKeys.Name] }}
    </p>
    <div
      *ngIf="!product[ProductKeys.PriceToDisplay]"
      class="text-right"
      [style.color]="productPriceColor | contentBuilderColor"
    >
      <ng-container *ngIf="product[ProductKeys.Discount]; else priceOnly">
        <app-price-atom
          class="p-1.5 pl-0"
          [amount]="product[ProductKeys.Discount] ?? 0"
        ></app-price-atom>
        <app-tag-atom
          *ngIf="product[ProductKeys.Price] > 0"
          class="p-1.5"
          color="success"
          text="{{
            product[ProductKeys.Price] | discountPercent: forceType(product[ProductKeys.Discount])
          }}"
        ></app-tag-atom>
      </ng-container>
      <ng-template #priceOnly>
        <app-price-atom
          class="pb-1 pl-0"
          [amount]="product[ProductKeys.Price]"
        ></app-price-atom>
      </ng-template>
    </div>
    <div
      *ngIf="product[ProductKeys.PriceToDisplay]"
      [style.color]="productPriceColor | contentBuilderColor"
    >
      <span> {{ product[ProductKeys.PriceToDisplay] }}</span>
    </div>
  </div>
</div>
