import { Router } from '@angular/router';
import { take, tap } from 'rxjs/operators';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { IOrder } from '@core/models/order.model';
import { OrderStatusValues } from '@config/values/order.values';
import { OrderStatusLabels } from '@config/labels/order.labels';

import { DateUtils } from '@shared/utils/date/date.utils';

import { DateHelperService } from '@core/services/date-helper/date-helper.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';

@Component({
  selector: 'app-order-status-atom',
  templateUrl: './order-status-atom.component.html',
})
export class OrderStatusAtomComponent implements OnChanges {
  @Input() shopName!: string;
  @Input() orderNumber!: string;
  @Input() orderStatus!: string;
  @Input() order!: IOrder;
  @Input() ready_at?: Date;

  readyAt?: string;
  daysUntilReadyString?: string;

  orderStatusValues = OrderStatusValues;
  orderStatusLabels = OrderStatusLabels;

  constructor(
    private readonly router: Router,
    private readonly routerHelper: RouterHelperService,
    private readonly dateHelperService: DateHelperService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ready_at) {
      this.readyAt = DateUtils.dayjsInMarketplaceTimezone(this.ready_at).format('HH[h]mm');
      this.dateHelperService
        .getDaysUntilString(this.ready_at)
        .pipe(
          take(1),
          tap(daysUntilString => (this.daysUntilReadyString = daysUntilString)),
        )
        .subscribe();
    }
  }

  goToOrderDetail(order: IOrder): void {
    this.router.navigate([this.routerHelper.translateRoute(`/orders/${order.id}/details`)]);
  }
}
