import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-event-item-atom',
  templateUrl: './event-item-atom.component.html',
})
export class EventItemAtomComponent {
  @Input() date!: string;
  @Input() title!: string;
  @Input() link!: string;
  @Input() imgUrl!: string;
}
