import { Component, Input } from '@angular/core';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

export interface IPushPicture {
  heading: string;
  buttonLabel?: string;
  buttonLink?: string;
  imgUrl?: string;
  backgroundColor?: IContentBuilderFieldColor;
}

@Component({
  selector: 'app-push-picture-atom',
  templateUrl: './push-picture-atom.component.html',
  styleUrls: ['./push-picture-atom.component.scss'],
})
export class PushPictureAtomComponent {
  @Input() heading!: string;
  @Input() buttonLabel!: string;
  @Input() buttonLink!: string;
  @Input() pictureUrl!: string;
  @Input() backgroundColor!: IContentBuilderFieldColor | undefined;
}
