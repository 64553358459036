import { Observable } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';

import { DestroyerBase } from '@core/base/destroyer/destroyer.base';

enum DoodNetworkInformationEffectiveType {
  slow2g = 'slow-2g',
  n2G = '2g',
  n3G = '3g',
  n4G = '4g',
}

interface DoodNetworkInformation extends EventTarget {
  readonly rtt: number;
  readonly downlink: number;
  readonly saveData: boolean;
  readonly downlinkMax: number;
  readonly onchange: (_event: Event) => void;
  readonly effectiveType: DoodNetworkInformationEffectiveType;
}

@Component({
  selector: 'app-debug-network-connection-block',
  templateUrl: './debug-network-connection-block.component.html',
})
export class DebugNetworkConnectionBlockComponent extends DestroyerBase implements OnInit {
  connection: DoodNetworkInformation | null = null;

  ngOnInit(): void {
    const connection$ = new Observable<DoodNetworkInformation>(observer => {
      if ('connection' in navigator) {
        const _connection = navigator.connection as DoodNetworkInformation;
        observer.next(_connection);
        const onConnectionChange = (): void => {
          observer.next(_connection);
        };
        _connection.addEventListener('change', onConnectionChange);
        _connection.addEventListener('change', _connection.onchange);
        return () => {
          _connection.removeEventListener('change', onConnectionChange);
          observer.complete();
        };
      }
    });

    connection$
      .pipe(
        takeUntil(this._destroyerRef),
        tap(connection => (this.connection = connection)),
      )
      .subscribe();
  }
}
