<div
  class="fixed bottom-0 left-0 right-0 z-50 grid gap-[1.5px]"
  [style.backgroundColor]="textColor"
  [class.bottom-[200px]]="isOverFooter"
>
  @for (row of keyboardLayout; track $index) {
    <div class="grid grid-cols-10 gap-[1.5px]">
      @for (item of row; track $index) {
        <div
          [ngClass]="getColSpanClass(item) + ' ' + getAspectClass(item)"
          class="flex items-center justify-center text-3xl"
          [style.backgroundColor]="backgroundColor"
          [style.color]="textColor"
          (click)="onKeyPress(getDisplayValue(item))"
        >
          {{ getDisplayValue(item) === ' ' ? 'ESPACE' : getDisplayValue(item) }}
        </div>
      }
    </div>
  }
  <div class="grid gap-[1.5px]">
    @for (item of spaceBarLayout; track $index) {
      <div
        [ngClass]="getColSpanClass(item) + ' ' + getAspectClass(item)"
        class="flex items-center justify-center text-3xl"
        [style.backgroundColor]="backgroundColor"
        [style.color]="textColor"
        (click)="onKeyPress(getDisplayValue(item))"
      >
        {{ getDisplayValue(item) === ' ' ? 'ESPACE' : getDisplayValue(item) }}
      </div>
    }
  </div>
</div>
