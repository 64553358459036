import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  output,
} from '@angular/core';
import { outputFromObservable, toObservable } from '@angular/core/rxjs-interop';
import { ICartItem } from '@core/models/cart.model';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { DoodProductModel } from '@core/models/product.model';
import { map } from 'rxjs';
import { SimpleProductWithAdditionsGroupStepperData } from '../kiosk-product-stepper/kiosk-simple-product-stepper.component';
import { CartDraftDeclinableProductStore } from '@shared/local-store/cart/cart-draft-declinable-product.store';
import { forceStepperCategoryTplData } from '../kiosk-product-stepper/kiosk-category-stepper.component';
import { DeclinableProductStepperStore } from '@shared/local-store/stepper/declinable-product-stepper.store';
import { DeclinableProductAutoNextStepService } from '@shared/local-store/stepper/auto-next-step/declinable-product-auto-next-step.service';

@Component({
  selector: 'app-kiosk-select-declinable-products-organism',
  providers: [
    CartDraftDeclinableProductStore,
    DeclinableProductStepperStore,
    DeclinableProductAutoNextStepService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ` @for (category of declinableProduct().categories; track category.store_id) {
    @if (category.store_id === declinableStepper()?.selectedCategoryId) {
      <app-kiosk-category-stepper [categoryId]="category.store_id ?? ''">
        <ng-template
          #categoryTpl
          let-categoryDataUntyped
        >
          @let categoryData = forceStepperCategoryTplData(categoryDataUntyped);
          <app-kiosk-selection-group-wrapper-atom [secondaryColor]="secondaryColor()">
            <app-kiosk-select-product-category-organism
              [textColor]="textColor()"
              [cardColor]="cardColor()"
              [buttonBackgroundColor]="buttonBackgroundColor()"
              [buttonTextColor]="buttonTextColor()"
              [backgroundColor]="backgroundColor()"
              [primaryButtonBackgroundColor]="primaryButtonBackgroundColor()"
              [primaryButtonTextColor]="primaryButtonTextColor()"
              [unavailableButtonBackground]="unavailableButtonBackground()"
              [unavailableButtonText]="unavailableButtonText()"
              [secondaryColor]="secondaryColor()"
              [categoryId]="category.store_id ?? ''"
              (showProductAdditions)="categoryData?.setSelectedProductId($event.selectedProductId)"
            />
          </app-kiosk-selection-group-wrapper-atom>
        </ng-template>
        <ng-template
          #additionsGroupTpl
          let-dataUntyped
        >
          @if (dataUntyped) {
            @let data = forceToSimpleProductWithAdditionsGroupStepperData(dataUntyped);
            @if (data) {
              <app-kiosk-selection-group-wrapper-atom [secondaryColor]="secondaryColor()">
                <app-kiosk-select-additions-product-organism
                  [backgroundColor]="backgroundColor()"
                  [textColor]="textColor()"
                  [cardColor]="cardColor()"
                  [buttonBackgroundColor]="primaryButtonBackgroundColor()"
                  [buttonTextColor]="primaryButtonTextColor()"
                  [secondaryColor]="secondaryColor()"
                  [primaryButtonBackgroundColor]="primaryButtonBackgroundColor()"
                  [unavailableButtonBackground]="unavailableButtonBackground()"
                  [unavailableButtonText]="unavailableButtonText()"
                  [simpleProductAdditionsGroup]="data.additionsByGroup"
                  (onChangeAdditionQuantity)="data.onChangeAdditionQuantity($event)"
                >
                </app-kiosk-select-additions-product-organism>
              </app-kiosk-selection-group-wrapper-atom>
            }
          }
        </ng-template>
      </app-kiosk-category-stepper>
    }
  }`,
})
export class KioskSelectDeclinableProductsComponent {
  private readonly cartDraftDeclinableProductStore = inject(CartDraftDeclinableProductStore);
  protected readonly declinableProductStepperStore = inject(DeclinableProductStepperStore);

  public readonly textColor = input<IContentBuilderFieldColor>();
  public readonly cardColor = input<IContentBuilderFieldColor>();
  public readonly buttonBackgroundColor = input<IContentBuilderFieldColor>();
  public readonly buttonTextColor = input<IContentBuilderFieldColor>();
  public readonly backgroundColor = input<IContentBuilderFieldColor>();
  public readonly primaryButtonBackgroundColor = input<IContentBuilderFieldColor>();
  public readonly primaryButtonTextColor = input<IContentBuilderFieldColor>();
  public readonly secondaryColor = input<IContentBuilderFieldColor>();
  public readonly unavailableButtonBackground = input<IContentBuilderFieldColor>();
  public readonly unavailableButtonText = input<IContentBuilderFieldColor>();

  public readonly initialCart = input<ICartItem | undefined>();
  public readonly activeShopId = input.required<string>();
  public readonly declinableProduct = input.required<Readonly<DoodProductModel>>();

  public readonly additionGroupName = output<string>();

  public readonly cartData = outputFromObservable(
    toObservable(this.cartDraftDeclinableProductStore.selectors).pipe(
      map(selectors =>
        selectors
          ? {
              cart: selectors.finalCart,
              isValid: selectors.validity.isDeclinableProductValid,
            }
          : undefined,
      ),
    ),
  );

  protected readonly declinableStepper = computed(() => {
    const stepper = this.declinableProductStepperStore.steppers()?.declinableStepper;
    return stepper;
  });

  constructor() {
    inject(DeclinableProductAutoNextStepService).initAutoNextStep();
    effect(
      () => {
        this.cartDraftDeclinableProductStore.init({
          shopId: this.activeShopId(),
          declinableProduct: this.declinableProduct(),
        });
      },
      {
        allowSignalWrites: true,
      },
    );
    effect(
      () => {
        const initialCart = this.initialCart();
        if (initialCart) {
          this.cartDraftDeclinableProductStore.setExistingCart(initialCart);
        }
      },
      {
        allowSignalWrites: true,
      },
    );
  }

  protected productPriceOverrides = (productId: string) => {
    const result = this.declinableProduct().price_overrides?.find(
      priceOverride => priceOverride.product === productId,
    );
    return result;
  };

  protected forceToSimpleProductWithAdditionsGroupStepperData(data: any) {
    this.onAdditionsChange(data.additionsByGroup.name);
    return data as SimpleProductWithAdditionsGroupStepperData;
  }

  protected forceStepperCategoryTplData = forceStepperCategoryTplData;

  protected onAdditionsChange(additionName: any) {
    this.additionGroupName.emit(additionName);
  }
}
