import { AppEnvironment } from './environment.model';

export const environment: AppEnvironment = {
  name: 'prod',
  baseUrl: '/',
  production: true,
  withServiceWorkers: true,
  api: {
    dood: {
      url: 'https://api.dood.com',
    },
    mercure: {
      url: 'https://ws.dood.com/.well-known/mercure',
    },
    firebase: {
      apiKey: 'AIzaSyB0R2ijCqRYNFXnJrcTJzrgTdo5bOAC3MQ',
      authDomain: 'dood-211115.firebaseapp.com',
      databaseURL: 'https://dood-211115.firebaseio.com',
      projectId: 'dood-211115',
      storageBucket: 'dood-211115.appspot.com',
      messagingSenderId: '990026542083',
      appId: '1:990026542083:web:12b258161ac036cc4bf236',
      measurementId: 'G-WQPVTK9ZBF',
    },
    maps: {
      key: 'AIzaSyBovuJRybfwwYXyNfadxLE0D-ns9yQksq8',
    },
    adyen: {
      environment: 'live',
      clientKey: 'live_VQWXLWJB7NA5HKR4ZS4EYG3UKQSD3RR4',
    },
    stripe: {
      key: 'pk_live_4Xnehre1I6RCSAMdMpTPLQ7p',
    },
    cmi: {
      url: 'https://payment.cmi.co.ma/fim/est3Dgate',
    },
    feeds: {
      url: 'https://api.dood.com', // PROD
    },
  },
  native: {
    marketplaceDomain: 'https://dood.com',
  },
};
