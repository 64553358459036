import { Component, OnDestroy, OnInit } from '@angular/core';

import { KioskService } from '@core/services/kiosk/kiosk.service';
import { ModalsService } from '@core/services/modals/modals.service';

import { KioskPickerModalComponent } from '@shared/modals/kiosk-picker-modal/kiosk-picker-modal.component';

@Component({
  template: ``,
  selector: 'app-kiosk-picker-modal-launcher-block',
})
export class KioskPickerModalLauncherBlockComponent implements OnInit, OnDestroy {
  constructor(
    private readonly kioskService: KioskService,
    private readonly modalsService: ModalsService,
  ) {}

  ngOnInit(): void {
    const kioskId = this.kioskService.getKioskIdFromStore();
    if (!kioskId) {
      this.modalsService.open(KioskPickerModalComponent.handle);
    }
  }

  ngOnDestroy(): void {
    this.modalsService.close(KioskPickerModalComponent.handle);
  }
}
