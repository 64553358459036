import { ScheduleDays } from 'src/app/core/models/schedule-day.model';

export const DAYS_SCHEDULE: ScheduleDays[] = [
  {
    index: 1,
    name: 'shop-page.schedules.days.monday',
    start: 1440,
    end: 2880,
    slug: 'monday',
  },
  {
    index: 2,
    name: 'shop-page.schedules.days.tuesday',
    start: 2880,
    end: 4320,
    slug: 'tuesday',
  },
  {
    index: 3,
    name: 'shop-page.schedules.days.wednesday',
    start: 4320,
    end: 5760,
    slug: 'wednesday',
  },
  {
    index: 4,
    name: 'shop-page.schedules.days.thursday',
    start: 5760,
    end: 7200,
    slug: 'thursday',
  },
  {
    index: 5,
    name: 'shop-page.schedules.days.friday',
    start: 7200,
    end: 8640,
    slug: 'friday',
  },
  {
    index: 6,
    name: 'shop-page.schedules.days.saturday',
    start: 8640,
    end: 10080,
    slug: 'saturday',
  },
  {
    index: 0,
    name: 'shop-page.schedules.days.sunday',
    start: 0,
    end: 1440,
    slug: 'sunday',
  },
];
