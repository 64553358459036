import { keyBy } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { DeclinableUtils } from '@app/utilities/declinable.util';
import { DeclinableStoreSelector } from '@app/common/selectors/declinable.selector';
import { ISelectedMappedAddition, ISelectedMappedCompoundItem } from '@core/models/product.model';

@Injectable({ providedIn: 'root' })
export class DeclinableStoreRefiner {
  constructor(private _selector: DeclinableStoreSelector) {}

  selectSelectedDeclinables(id: string): Observable<ISelectedMappedCompoundItem[] | undefined> {
    return this._selector.selectDeclinable(id).pipe(
      map(_declinable => {
        if (_declinable) {
          const { products, selected } = _declinable;
          if (selected) {
            const productsIndexed = keyBy(products, 'id');
            return selected
              .map(({ id, additions_by_group, quantity }) => {
                const additions: ISelectedMappedAddition[] = [];
                additions_by_group?.forEach(el => {
                  const additionsIndexed = keyBy(el.items, 'id');
                  if (el.selected?.length) {
                    DeclinableUtils.countAdditionOccurrences(el.selected).forEach(
                      ({ id, quantity }) => {
                        additions.push({
                          id,
                          quantity,
                          name: additionsIndexed[id].name,
                        });
                      },
                    );
                  }
                });
                return {
                  additions,
                  id: id,
                  quantity: quantity,
                  name: productsIndexed[id].name,
                };
              })
              .reduce((acc, item) => {
                const existingItem = acc.find(x => x.id === item.id);
                existingItem ? existingItem.quantity++ : acc.push({ ...item, quantity: 1 });
                return acc;
              }, [] as ISelectedMappedCompoundItem[]);
          }
        }
        return undefined;
      }),
    );
  }
}
