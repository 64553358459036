import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-big-footer-body-call-to-action-block',
  templateUrl: './big-footer-body-call-to-action-block.component.html',
})
export class BigFooterBodyCallToActionBlockComponent {
  @Input() title?: string;
  @Input() urlButton?: string;
  @Input() labelButton?: string;
  @Input() buttonSizeY: 'S' | 'M' | 'L' = 'M';
}
