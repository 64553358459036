import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { DoodBlockModel } from '@core/models/block.model';
import { DoodModalConfig } from '@core/models/marketplace.model';
import { DoodApiError } from '@shared/interfaces/error.interface';

import { ContentHeaderState, ContentProductModalState } from './content.state';

export const CONTENT_STORE_API_ACTIONS = createActionGroup({
  source: 'Dood/API/Content',
  events: {
    // Blocks
    'Fetch Blocks': emptyProps(),
    'Fetch Blocks Success': props<{ blocks: DoodBlockModel[] }>(),
    'Fetch Blocks Failure': props<{ error: DoodApiError }>(),
    'Fetch Blocks Complete': emptyProps(),
    // Modals Configs
    'Fetch Modals': emptyProps(),
    'Fetch Modals Success': props<{ modals: DoodModalConfig[] }>(),
    'Fetch Modals Failure': props<{ error: DoodApiError }>(),
    'Fetch Modals Complete': emptyProps(),
  },
});

export const CONTENT_STORE_LOCAL_ACTIONS = createActionGroup({
  source: 'Dood/LOCAL/Content',
  events: {
    // Blocks
    'Add Blocks': props<{ blocks: DoodBlockModel[] }>(),
    'Add Block': props<{ block: DoodBlockModel }>(),
    'Remove Blocks': props<{ ids: string[] }>(),
    'Remove Block': props<{ id: string }>(),
    'Upsert Blocks': props<{ blocks: DoodBlockModel[] }>(),
    'Upsert Block': props<{ block: DoodBlockModel }>(),
    'Set Blocks': props<{ blocks: DoodBlockModel[] }>(),
    // Status
    'Update Blocks Is Loaded': props<{ isLoaded: boolean }>(),
    'Update Blocks Is Loading': props<{ isLoading: boolean }>(),

    // Modals Configs
    'Add Modals': props<{ configs: DoodModalConfig[] }>(),
    'Add Modal': props<{ config: DoodModalConfig }>(),
    'Remove Modals': props<{ ids: string[] }>(),
    'Remove Modal': props<{ id: string }>(),
    'Upsert Modals': props<{ configs: DoodModalConfig[] }>(),
    'Upsert Modal': props<{ config: DoodModalConfig }>(),
    'Set Modals': props<{ configs: DoodModalConfig[] }>(),
    // Status
    'Update Modals Is Loaded': props<{ isLoaded: boolean }>(),
    'Update Modals Is Loading': props<{ isLoading: boolean }>(),

    // Header
    'Update Header': props<{ header: Partial<ContentHeaderState> }>(),
    'Reset Header': emptyProps(),

    // Header
    'Update Product': props<{ product: Partial<ContentProductModalState> }>(),
    'Reset Product': emptyProps(),

    // Errors
    'Update Error': props<{ error: DoodApiError }>(),
    'Reset Error': emptyProps(),

    // Misc
    'reset': emptyProps(),
  },
});
