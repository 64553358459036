import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-cart-item-atom',
  templateUrl: './cart-item-atom.component.html',
})
export class CartItemAtomComponent {
  @Input() itemId!: number | string;
  @Input() itemName!: string;
  @Input() imgUrl!: string;
  @Input() quantity!: number;
  @Input() amount!: number;

  @Output() updateItem = new EventEmitter<string | number>();
  @Output() removeItem = new EventEmitter<string | number>();

  public removeInProgress = false;

  onRemove(itemId: number | string): void {
    this.removeInProgress = true;
    this.removeItem.emit(itemId);
  }
}
