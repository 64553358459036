export enum CategoryKeys {
  Id = 'id',
  Name = 'name',
  Description = 'description',
  DisplayType = 'display_type',
  Position = 'position',
  Products = 'products',
  Course = 'course',
  ParentStore = 'parent_store',
  Available = 'available',
  Icon = 'icon',
}
