import { ShopKeys } from '@config/keys/shop.keys';
import { ProductKeys } from '@config/keys/product.keys';
import { CategoryKeys } from '@config/keys/category.keys';
import { OverridesPricesKeys } from '@config/keys/price.keys';

import { IAdditionGroup } from '@core/models/shop.model';
import { IAllergens } from '@core/models/allergens.model';
import { IContextualPrice } from '@core/models/price.model';

export interface DoodProductModel {
  _id: string;
  [ProductKeys.Id]: string;
  [ProductKeys.Plu]?: string;
  [ProductKeys.ShopId]: string;
  [ProductKeys.Name]: string;
  [ProductKeys.Description]?: string;
  [ProductKeys.Price]: number;
  [ProductKeys.FinalPrice]: number;
  [ProductKeys.Vat]: string;
  [ProductKeys.Discount]?: number;
  [ProductKeys.PreparationTime]?: number;
  [ProductKeys.Available]?: boolean;
  [ProductKeys.PayableWithMealVoucher]?: boolean;
  [ProductKeys.Hidden]?: boolean;
  [ProductKeys.Allergens]?: IAllergens[];
  [ProductKeys.Labels]?: IProductLabel[];
  [ProductKeys.Type]?: string;
  [ProductKeys.Icon]?: string;
  [ProductKeys.InventoryAvailable]?: number;
  [ProductKeys.InventoryTracked]?: boolean;
  [ProductKeys.MinCount]?: number;
  [ProductKeys.MaxCount]?: number;
  [ProductKeys.AdditionsGroup]: string;
  [ProductKeys.Categories]: IDeclinableCategory[];
  [ProductKeys.ParentProduct]?: null;
  [ProductKeys.ContextualPrices]?: IContextualPrice[];
  [ProductKeys.Metafields]?: [];
  [ProductKeys.Additions]: IAdditions;
  [ProductKeys.Store]?: ICategory[];
  [ProductKeys.Images]?: IProductImage;
  [ProductKeys.PriceOverrides]?: IOverridesPrices[];
  [ProductKeys.IsChecked]?: boolean;
  [ProductKeys.ShopSlug]?: string;
  [ProductKeys.ShopName]?: string;
  [ProductKeys.ShopAvailable]?: boolean;
  [ProductKeys.Quantity]?: number;
  [ProductKeys.PriceToDisplay]?: string;
  [ProductKeys.ExternalId]?: string;
}

export interface IDeclinableCategory {
  count: number;
  min_count: number;
  products: string[];
  store_id?: string;
  store: string;
}

export interface IAdditions {
  products: string[];
}

export interface ICategory {
  [CategoryKeys.Id]: string;
  [CategoryKeys.Name]: string;
  [CategoryKeys.Description]?: string;
  [CategoryKeys.Position]?: number;
  [CategoryKeys.Products]: string[];
  [CategoryKeys.Course]?: string;
  [CategoryKeys.ParentStore]?: string;
  [CategoryKeys.Available]: boolean;
  [CategoryKeys.DisplayType]: string;
  [CategoryKeys.Icon]?: string;
}

export enum CategoryDisplayType {
  HorizontalHighlight = 'horizontal_highlight',
  CardsWithoutName = 'cards_without_name',
  KioskGrid = 'kiosk_grid',
}

export interface IProductImage {
  cover: {
    colors: string[];
    height: number;
    url: string;
    width: number;
  };
  thumbnail: {
    colors: string[];
    height: number;
    url: string;
    width: number;
  };
}

export interface IProductLabel {
  id: string;
  handle: string;
  line_through: boolean;
  name: string;
  color: string;
  visible: boolean;
  filtereable: boolean;
  position: number;
  entity_owner: IEntityOwner;
}

export interface IOverridesPrices {
  [OverridesPricesKeys.Store]: string;
  [OverridesPricesKeys.Product]: string;
  [OverridesPricesKeys.AdditionnalPrice]: number;
  [OverridesPricesKeys.ContextualPrice]: any[];
}

export interface IAllProducts {
  [ShopKeys.Available]: boolean;
  description?: string;
  id: string;
  metafields?: any[];
  name: string;
  products: DoodProductModel[];
  short_description?: string;
  [ShopKeys.AdditionGroups]: IAdditionGroup[];
  slug: string;
}

export interface DoodDeclinableModel {
  count: number;
  min_count: number;
  description?: string;
  information?: string;
  name: string;
  products: IProductAdditionGroupInformation[];
  isValid?: boolean;
  selected?: ISelectedCompoundItem[];
  store_id: string;
  isVisited?: boolean;
}

export interface IProductWithSelectedAdditions extends DoodProductModel {
  additions_informations?: ICompoundAdditionsInformations[];
}

export interface ICompoundAdditionsInformations {
  group_id: string;
  products: DoodProductModel[];
}

export interface ISelectedCompoundItem {
  id: string;
  quantity: number;
  additions?: string[];
  additions_by_group?: IAdditionGroup[];
}

export interface IProductAdditionGroupInformation extends IProductWithSelectedAdditions {
  additions_group_informations?: IAdditionGroup[];
}

export interface ISelectedMappedCompoundItem {
  id: string;
  name: string;
  quantity: number;
  additions: ISelectedMappedAddition[];
}

export interface ISelectedMappedAddition {
  id: string;
  name: string;
  quantity: number;
}

export interface IEntityOwner {
  entity_id: string;
  entity_type: 'shop' | 'marketplace';
}
