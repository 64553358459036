export interface IPongoCustomer {
  id?: string;
  first_name?: string;
  last_name?: string;
  has_optin?: boolean;
  points?: number;
  rewards: IPongoAsset[];
}

export enum PongoAssetType {
  VOUCHER_FREE_DELIVERY = 'voucher_free_delivery',
  VOUCHER_DISCOUNT_PERCENT = 'voucher_discount_percent',
  VOUCHER_DISCOUNT_AMOUNT = 'voucher_discount_amount',
  FREE_PRODUCT = 'free_product',
}

export interface IPongoAsset {
  hash: string;
  id: string;
  type: PongoAssetType;
  picture_url?: string;
  name: string;
  value?: string;
  minimum_order_amount?: string;
  validity_in_days: number;
  code: string;
  expiration_date?: string;
  external_ids: string[];
  buyInProcess?: boolean;
  is_usable: boolean;
  is_buyable: boolean;
  points_required?: number;
}
