import { createFeature, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';

import { CART_FUNNEL_STORE_REDUCER } from './cart-funnel.reducer';
import { DoodCartFunnelStep, DoodCartFunnelValue } from './cart-funnel.model';
import { CART_FUNNEL_STORE_FUNNEL_ADAPTER, CART_FUNNEL_STORE_KEY } from './cart-funnel.state';

const CART_FUNNEL_STORE_FEATURE = createFeature({
  name: CART_FUNNEL_STORE_KEY,
  reducer: CART_FUNNEL_STORE_REDUCER,
});

export const {
  selectSteps: SELECT_CART_FUNNEL_STEPS_STATE,
  selectValues: SELECT_CART_FUNNEL_VALUES_STATE,
  selectCartFunnelState: SELECT_CART_FUNNEL_STATE,
  selectOrderComment: SELECT_CART_FUNNEL_ORDER_COMMENT,
} = CART_FUNNEL_STORE_FEATURE;

// Steps
const { selectAll: SELECT_CART_FUNNEL_STEPS_ALL_SELECTOR } =
  CART_FUNNEL_STORE_FUNNEL_ADAPTER.steps.getSelectors();

export const SELECT_CART_FUNNEL_STEPS_ALL = createSelector(
  SELECT_CART_FUNNEL_STEPS_STATE,
  SELECT_CART_FUNNEL_STEPS_ALL_SELECTOR,
);

export const SELECT_CART_FUNNEL_STEPS = createSelector(
  SELECT_CART_FUNNEL_STEPS_STATE,
  state => state,
);

export const SELECT_CART_FUNNEL_ACTIVE_STEP_ID = createSelector(
  SELECT_CART_FUNNEL_STATE,
  state => state.steps.active,
);

export const SELECT_CART_FUNNEL_NEXT_STEP_ID = createSelector(
  SELECT_CART_FUNNEL_STATE,
  SELECT_CART_FUNNEL_ACTIVE_STEP_ID,
  (state, active) => {
    let _index = -1;
    if (active !== null) {
      _index = (state.steps.ids as string[]).indexOf(active);
    }
    return state.steps.ids[_index + 1];
  },
);

export const SELECT_CART_FUNNEL_NEXT_STEP = createSelector(
  SELECT_CART_FUNNEL_STATE,
  SELECT_CART_FUNNEL_NEXT_STEP_ID,
  (state, id) => {
    return state.steps.entities[id] ?? null;
  },
);

export const SELECT_CART_FUNNEL_STEP = (
  id: string,
): MemoizedSelector<
  Record<string, any>,
  DoodCartFunnelStep | undefined,
  DefaultProjectorFn<DoodCartFunnelStep | undefined>
> =>
  createSelector(SELECT_CART_FUNNEL_STEPS, state => {
    return state.entities[id];
  });

export const SELECT_CART_FUNNEL_LAST_STEP_ID = createSelector(
  SELECT_CART_FUNNEL_STEPS_ALL,
  steps => {
    return steps.length <= 0 ? null : steps[steps.length - 1]?.id;
  },
);

export const SELECT_CART_FUNNEL_LAST_STEP = createSelector(
  SELECT_CART_FUNNEL_LAST_STEP_ID,
  SELECT_CART_FUNNEL_STEPS,
  (id, steps) => {
    return id ? steps.entities[id] : null;
  },
);

export const SELECT_CART_FUNNEL_ACTIVE_STEP = createSelector(SELECT_CART_FUNNEL_STEPS, steps => {
  return steps.active ? (steps.entities[steps.active] ?? null) : null;
});

export const SELECT_CART_FUNNEL_IS_LAST_STEP_ACTIVE = createSelector(
  SELECT_CART_FUNNEL_ACTIVE_STEP_ID,
  SELECT_CART_FUNNEL_LAST_STEP_ID,
  (active, last) => {
    return active !== null && active === last;
  },
);

// Values
const { selectAll: SELECT_CART_FUNNEL_VALUES_ALL_SELECTION } =
  CART_FUNNEL_STORE_FUNNEL_ADAPTER.values.getSelectors();

export const SELECT_CART_FUNNEL_VALUES_ALL = createSelector(
  SELECT_CART_FUNNEL_VALUES_STATE,
  SELECT_CART_FUNNEL_VALUES_ALL_SELECTION,
);

export const SELECT_CART_FUNNEL_VALUES = createSelector(
  SELECT_CART_FUNNEL_VALUES_STATE,
  state => state,
);

export const SELECT_CART_FUNNEL_USER_NAME = createSelector(
  SELECT_CART_FUNNEL_STATE,
  state => state.funnelUsername,
);

export const SELECT_CART_FUNNEL_VALUE = (
  id: string,
): MemoizedSelector<
  Record<string, any>,
  DoodCartFunnelValue | undefined,
  DefaultProjectorFn<DoodCartFunnelValue | undefined>
> =>
  createSelector(SELECT_CART_FUNNEL_VALUES, state => {
    return state.entities[id];
  });

// Comment
