import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DOOD_API } from '@config/ws.config';
import { HttpClient } from '@angular/common/http';
import { IAllProducts } from '@core/models/product.model';

@Injectable({
  providedIn: 'root',
})
export class MarketplaceProductsApiService {
  constructor(private readonly httpClient: HttpClient) {}

  getProducts$(marketplaceId: string): Observable<IAllProducts[]> {
    return this.httpClient.get<IAllProducts[]>(
      `${DOOD_API}/marketplaces/${marketplaceId}/products`,
    );
  }
}
