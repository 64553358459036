import { VenueUtils } from './../../utils/venue/venue.utils';
import { ScheduleKeys } from '@config/keys/shop.keys';
import { ScheduleDays } from '@core/models/schedule-day.model';
import { DAYS_SCHEDULE } from '@config/values/schedule-days.values';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ScheduleDayKeys } from 'src/app/config/keys/schedule-day.keys';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { IShopSchedule } from '@core/models/shop.model';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DateUtils } from '../../utils/date/date.utils';

interface IFormattedSchedule {
  day: ScheduleDays;
  start: number;
  end: number;
  address?: string;
  allowed_distribution_modes?: string[];
}

interface IDisplaySchedule {
  day: ScheduleDays;
  timeSlots: Array<{
    start: number;
    end: number;
    address?: string;
    allowed_distribution_modes?: string[];
  }>;
}

@Component({
  selector: 'app-shop-schedules-atom',
  templateUrl: './shop-schedules-atom.component.html',
  styleUrls: ['./shop-schedules-atom.component.scss'],
  animations: [
    trigger('isAccordionActive', [
      state(
        'hidden',
        style({
          height: '0',
          overflow: 'hidden',
        }),
      ),
      state(
        'visible',
        style({
          height: '*',
        }),
      ),
      transition('visible <=> hidden', [
        style({ overflow: 'hidden' }),
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'),
      ]),
      transition('void => *', animate(0)),
    ]),
  ],
})
export class ShopSchedulesAtomComponent implements OnChanges {
  @Input() schedules!: IShopSchedule[];
  @Input() isShopMoveable?: boolean = false;

  isAccordionActive = false;
  formattedSchedules: IFormattedSchedule[] = [];
  groupedSchedules!: IDisplaySchedule[];

  constructor(private readonly translateService: TranslateService) {
    dayjs.extend(relativeTime);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.schedules) {
      const { currentValue } = changes.schedules;
      this.groupedSchedules = this.formatSchedulesForDisplay(currentValue);
    }
  }

  private formatSchedulesForDisplay(schedules: IShopSchedule[]): IDisplaySchedule[] {
    const groupedSchedules: IDisplaySchedule[] = [];
    const existingDayGroups: number[] = [];

    schedules
      ?.filter(f => f.opened)
      .forEach(schedule => {
        const day = DAYS_SCHEDULE.find(
          day => day.start <= schedule.start && day.end >= schedule.end,
        );

        if (day) {
          const address = this.isShopMoveable
            ? VenueUtils.getVenueAddressString(schedule[ScheduleKeys.Venue])
            : '';
          const timeDiff = day[ScheduleDayKeys.Index] * 1440;
          const timeSlot = {
            start: schedule[ScheduleKeys.Start] - timeDiff,
            end: schedule[ScheduleKeys.End] - timeDiff,
            address,
            allowed_distribution_modes: schedule.allowed_distribution_modes?.map(
              d => d.distribution_mode,
            ),
          };

          this.formattedSchedules.push({
            day,
            ...timeSlot,
          });

          if (!existingDayGroups.includes(day.index)) {
            groupedSchedules.push({
              day,
              timeSlots: [],
            });
            existingDayGroups.push(day.index);
          }

          groupedSchedules.find(s => s.day.index === day.index)?.timeSlots.push(timeSlot);
        }
      });

    DAYS_SCHEDULE.forEach(schedule => {
      if (!groupedSchedules.find(d => d.day === schedule)) {
        groupedSchedules.push({ day: schedule, timeSlots: [] });
      }
    });

    groupedSchedules.sort((schedule1, schedule2) => {
      schedule1.timeSlots.sort((slot1, slot2) => slot1.start - slot2.start);

      if (!schedule1.day || !schedule2.day) {
        return 1;
      } else {
        const index1 =
          schedule1.day?.[ScheduleDayKeys.Index] === 0
            ? 8
            : schedule1.day?.[ScheduleDayKeys.Index] + 1;
        const index2 =
          schedule2.day?.[ScheduleDayKeys.Index] === 0
            ? 8
            : schedule2.day?.[ScheduleDayKeys.Index] + 1;
        return index1 - index2;
      }
    });

    return groupedSchedules;
  }

  getHeaderString$(): Observable<string> {
    return this.translateService
      .get([
        'shop-informations.schedules.open',
        'shop-informations.schedules.at',
        'shop-informations.schedules.open-until',
        'shop-informations.schedules.open-today-at',
        'shop-informations.schedules.format',
      ])
      .pipe(
        map(translations => {
          const minutesSinceStartOfDay = DateUtils.dayjsInMarketplaceTimezone().diff(
            DateUtils.dayjsInMarketplaceTimezone().startOf('day'),
            'minute',
          );
          const currentDayOfWeek = DateUtils.dayjsInMarketplaceTimezone().day();
          const schedule = this.formattedSchedules.find(
            s =>
              s.day.index === currentDayOfWeek &&
              s.end > minutesSinceStartOfDay &&
              s.start <= minutesSinceStartOfDay,
          );
          if (schedule) {
            return `${translations['shop-informations.schedules.open-until']} ${DateUtils.dayjsInMarketplaceTimezone().startOf('day').add(schedule.end!, 'minute').format(translations['shop-informations.schedules.format'])}`;
          } else {
            let nextSchedule = this.formattedSchedules.find(
              s =>
                s.day.index === currentDayOfWeek &&
                s.end > minutesSinceStartOfDay &&
                s.start >= minutesSinceStartOfDay,
            );
            if (nextSchedule) {
              return `${translations['shop-informations.schedules.open-today-at']} ${DateUtils.dayjsInMarketplaceTimezone().startOf('day').add(nextSchedule.start, 'minute').format(translations['shop-informations.schedules.format'])}`;
            }

            nextSchedule = this.formattedSchedules.find(
              s => s.day.index > currentDayOfWeek && s.start,
            );
            if (nextSchedule) {
              const nextScheduleStartDate = DateUtils.dayjsInMarketplaceTimezone()
                .startOf('day')
                .add(nextSchedule.day.index - currentDayOfWeek, 'day')
                .add(nextSchedule.start!, 'minute');
              return `${translations['shop-informations.schedules.open']} ${nextScheduleStartDate.fromNow()} ${translations['shop-informations.schedules.at']} ${nextScheduleStartDate.format(translations['shop-informations.schedules.format'])}`;
            }

            nextSchedule = this.formattedSchedules.find(
              s => s.day.index <= currentDayOfWeek && s.start,
            );
            const nextScheduleStartDate = DateUtils.dayjsInMarketplaceTimezone()
              .startOf('day')
              .add(7 + nextSchedule!.day.index - currentDayOfWeek, 'day')
              .add(nextSchedule!.start!, 'minute');
            return `${translations['shop-informations.schedules.open']} ${nextScheduleStartDate.fromNow()} ${translations['shop-informations.schedules.at']} ${nextScheduleStartDate.format(translations['shop-informations.schedules.format'])}`;
          }
        }),
      );
  }

  getHours(value: number): string {
    const hours = Math.floor(value / 60);
    return `${DateUtils.dayjsInMarketplaceTimezone().hour(hours).format(this.translateService.instant('shop-informations.schedules.hours-format'))}${this.translateService.instant('shop-informations.schedules.separate-hours-format')}${DateUtils.dayjsInMarketplaceTimezone()
      .minute(value % 60)
      .format(
        'mm',
      )}${this.translateService.instant('shop-informations.schedules.end-format') ? DateUtils.dayjsInMarketplaceTimezone().hour(hours).format(this.translateService.instant('shop-informations.schedules.end-format')) : ''}`;
  }

  toggleAccordion(): void {
    this.isAccordionActive = !this.isAccordionActive;
  }
}
