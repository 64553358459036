import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-vimeo-player-block',
  templateUrl: './vimeo-player-block.component.html',
  styleUrls: ['./vimeo-player-block.component.scss'],
})
export class VimeoPlayerBlockComponent implements OnInit {
  @Input() videoId?: string;
  iframeSrc?: SafeResourceUrl;

  constructor(private readonly sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    const url = 'https://player.vimeo.com/video/' + this.videoId;
    this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
