import { EntityKeys } from '@config/keys/entity.keys';

import { IDistributionMode, IShopScheduleDistributionMode, IShopVenue } from './shop.model';
import { DoodVenueAddress, DoodVenueModel, DoodVenuePoint } from './venue.model';
import * as dayjs from 'dayjs';

export enum DoodEntityType {
  Shop = 'shop',
  Marketplace = 'marketplace',
}

export interface DoodEntitySchedule {
  id?: string;
  start: number;
  end: number;
  venue?: IShopVenue;
  opened?: boolean;
  active?: boolean;
  allowed_distribution_modes?: IShopScheduleDistributionMode[];
}

export interface ScheduleDateTime {
  schedule: DoodEntitySchedule;
  startDateTime: dayjs.Dayjs;
  endDateTime: dayjs.Dayjs;
}

export interface DoodEntityModel {
  id: string;
  available: boolean;
  name: string;
  short_description: string;
  slug: string;
  type: DoodEntityPreviewType;
  eat_in: boolean;
  DoodEntityType?: string;
  images?: DoodEntityCover;
  pricing: string;
  status: string;
  distance: number;
  entity_type: DoodEntityType;
  venue?: DoodVenueModel;
  related_venue?: DoodVenuePoint;
  address?: DoodVenueAddress;
  cover?: string;
  edenred_enabled?: boolean;
  overview_image?: string;
  schedules?: DoodEntitySchedule[];
  domains?: string[];
  available_at?: string;
  distribution_modes?: IDistributionMode[];
}

export interface DoodEntityStatus {
  isVisible: boolean;
  isOrderingAvailable: boolean;
  orderingAvailableAt?: Date;
  isPreorderingAvailable: boolean;
}

export interface DoodEntityPreviewType {
  [EntityKeys.ThumbnailUrl]: string;
  [EntityKeys.Name]: string;
}

export interface DoodEntityCategory {
  active: boolean;
  icon: string;
  icon_url: string;
  id: string;
  name: string;
  thumbnail_url: string;
}

export interface DoodEntityCover {
  cover?: DoodEntityImage;
  overview?: DoodEntityImage;
}

export interface DoodEntityImage {
  url: string;
  width: number;
  height: number;
}
