import { Subject, timer } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';

import { DestroyerBase } from '@core/base/destroyer/destroyer.base';

import { ModalsService } from '@core/services/modals/modals.service';
import { ResetStateService } from '@core/services/reset-state.service';
import { AuthFirebaseService } from '@core/services/api/auth-firebase/auth-firebase.service';

import { WaitingModalComponent } from '@shared/modals/waiting-modal/waiting-modal.component';
import { KioskCartResetTimerModalComponent } from '@shared/modals/kiosk-cart-reset-timer-modal/kiosk-cart-reset-timer-modal.component';

@Component({
  selector: 'app-kiosk-cart-reset-timer-block',
  template: ``,
})
export class KioskCartResetTimerBlockComponent extends DestroyerBase implements OnInit, OnDestroy {
  @Input() refreshAfterModalDelay = 30;
  @Input() refreshWithoutUserEventDelay = 300;
  @Input() displayModalAfterUserEventDelay = 30;
  @Input() waitForUserInteractionToDisplayModal = true;

  protected timerEnabled = true;
  protected userEvents$ = new Subject<boolean>();

  constructor(
    protected readonly resetStateService: ResetStateService,
    protected readonly authFirebaseService: AuthFirebaseService,
    protected readonly modalsService: ModalsService,
    protected readonly router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        tap(event => {
          if (event instanceof NavigationEnd) {
            /* Prevent Timer on default url  */
            const segments = event.urlAfterRedirects.split('/').filter(segment => segment !== '');
            if (event.url.endsWith('payment') || segments.length === 1) {
              this.timerEnabled = false;
            } else {
              this.timerEnabled = true;
            }
          }
        }),
      )
      .subscribe();

    this.userEvents$
      .pipe(
        takeUntil(this._destroyerRef),
        debounceTime(this.displayModalAfterUserEventDelay * 1000),
        takeUntil(this._destroyerRef),
        tap(() => this.displayModal()),
      )
      .subscribe();

    if (!this.waitForUserInteractionToDisplayModal) {
      this.userEvents$.next(true);
    }

    timer(this.refreshWithoutUserEventDelay * 1000)
      .pipe(
        takeUntil(this._destroyerRef),
        takeUntil(this.userEvents$),
        tap(() => this.refreshUI()),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.modalsService.close(KioskCartResetTimerModalComponent.handle);
  }

  @HostListener('document:mousedown', ['$event'])
  @HostListener('document:touchstart', ['$event'])
  @HostListener('window:scroll', ['$event'])
  clickOnDocument(event: Event): void {
    this.userEvents$.next(true);
  }

  private displayModal(): void {
    if (!this.timerEnabled) {
      return;
    }
    KioskCartResetTimerModalComponent.delay = this.refreshAfterModalDelay;
    this.modalsService.open(KioskCartResetTimerModalComponent.handle);
    timer(this.refreshAfterModalDelay * 1000)
      .pipe(
        takeUntil(this._destroyerRef),
        takeUntil(this.userEvents$),
        tap(() => this.refreshUI()),
      )
      .subscribe();
  }

  private async refreshUI(): Promise<void> {
    if (!this.timerEnabled) {
      return;
    }
    this.modalsService.open(WaitingModalComponent.handle);
    timer(1).subscribe(() => {
      this.resetStateService.refreshUI();
      this.modalsService.close(KioskCartResetTimerModalComponent.handle);
    });
  }
}
