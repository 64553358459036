import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-social-item-atom',
  templateUrl: './social-item-atom.component.html',
})
export class SocialItemAtomComponent {
  @Input() links!: { url: string; icon: string }[];
  @Input() text!: string;
}
