import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
})
export class IconButtonComponent {
  @Input() icon!: string;
  @Input() color: 'neutral' | 'primary' | 'success' | 'info' | 'highlight' | 'error' = 'primary';
  @Input() intensity = 500;

  @Output() clickButton = new EventEmitter<any>();
}
