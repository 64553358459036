import {TranslateService} from '@ngx-translate/core';
import {Observable, of, Subject, throwError, timer} from 'rxjs';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {catchError, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';

import {DoodOrderModel} from '@store/order/order.model';
import {IVivaWalletPaymentIntent} from '@core/models/payment.model';
import {PaymentMethodHandles} from '@config/payment-methods.config';

import {OrderStoreDispatcher} from '@common/dispatchers/order.dispatcher';
import {PaymentStoreDispatcher} from '@common/dispatchers/payment.dispatcher';

import {OrdersService} from '@core/services/orders/orders.service';
import {PaymentService} from '@core/services/payment/payment.service';

@Component({
  template: ``,
  selector: 'app-payment-panel-viva-wallet',
})
export class PaymentPanelVivaWalletComponent implements OnInit, OnDestroy {
  @Input() type!: string;
  @Input() isUnique = false;
  protected readonly unsubscribe$ = new Subject<boolean>();
  order: DoodOrderModel | null = null;

  constructor(
    private orderDispatcher: OrderStoreDispatcher,
    private readonly ordersService: OrdersService,
    private readonly paymentService: PaymentService,
    private paymentDispatcher: PaymentStoreDispatcher,
    private readonly translateService: TranslateService,
  ) {
  }

  ngOnInit(): void {
    timer(1).subscribe(() => {
      this.paymentDispatcher.updateUI({
        button: {
          text: 'viva-wallet.pay-button',
          isEnabled: true,
        },
        status: {
          isLoading: false,
        },
      });
    });

    this.paymentService.pay$
      .pipe(takeUntil(this.unsubscribe$), take(1))
      .subscribe(() => this.handlePayment());

    if (this.isUnique) {
      timer(1).subscribe(() => {
        this.paymentDispatcher.updateButton({
          text: 'Redirection vers Viva Wallet...',
        });
      });
      this.handlePayment();
    }
  }

  ngOnDestroy(): void {
    this.paymentDispatcher.resetUI();
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  private handlePayment(): void {
    this.paymentDispatcher.updateUI({
      button: {
        isEnabled: false,
      },
      status: {
        isInProgress: true,
      },
    });

    this.ordersService
      .createOrder$(PaymentMethodHandles.VivaWallet)
      .pipe(
        tap(order => (this.order = order)),
        switchMap(order => (order ? this.getPaymentPageUrl(order) : of(null))),
        takeUntil(this.unsubscribe$),
        take(1),
        tap(() => this.orderDispatcher.resetErrors()),
        catchError(error => {
          this.orderDispatcher.resetValidation();
          this.orderDispatcher.updateErrors({
            error,
          });
          return throwError(() => error);
        }),
      )
      .subscribe(
        paymentPageUrl => {
          this.paymentDispatcher.updateUI({
            button: {
              isEnabled: true,
            },
            status: {
              isLoading: false,
            },
          });

          if (paymentPageUrl) {
            this.redirectToVivaWallet(paymentPageUrl);
          }
        },
        error => {
          this.translateService
            .get(['payment.payment-error'])
            .pipe(
              take(1),
              map(translation => {
                this.paymentDispatcher.updateUI({
                  error: translation['payment.payment-error'],
                  button: {
                    isEnabled: true,
                  },
                  status: {
                    isLoading: false,
                    isInProgress: false,
                  },
                });
              }),
            )
            .subscribe();
        },
      );
  }

  private redirectToVivaWallet(paymentPageUrl: string): void {
    window.location.href = paymentPageUrl;
  }

  private getPaymentPageUrl(order: DoodOrderModel): Observable<string> {
    return this.paymentService
      .createOrderPaymentIntent$<IVivaWalletPaymentIntent>(order.id)
      .pipe(map(result => result.payment_page_url));
  }
}
