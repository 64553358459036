<div
  class="py-5 border rounded border-neutral-400 rounded-s"
  [style.color]="textColor | contentBuilderColor"
  [class.border-secondary-700]="error"
>
  <div
    class="flex items-center px-4.5"
    [style.color]="headingColor | contentBuilderColor"
  >
    <i class="icon {{ icon }} mr-3"></i>
    <span class="font-bold font-base lg:text-md">{{ text }}</span>
  </div>
  <div
    *ngIf="value"
    class="flex items-center px-4.5 my-3"
  >
    <div class="flex flex-col">
      <span class="text-base font-semibold">{{ value }}</span>
      <span class="italic text-neutral-600">{{ instructions }}</span>
    </div>
  </div>
  <div
    *ngIf="error && errorText"
    class="flex items-center px-4.5 mt-2"
  >
    <div>
      <span class="text-sm font-base text-secondary-700">{{ errorText }}</span>
    </div>
  </div>
  <div class="px-4.5 font-base font-bold text-sm text-primary-600 cursor-pointer">
    {{ 'basket.comment-edit' | translate }}
  </div>
</div>
