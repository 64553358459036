export enum DeliveryAddressKeys {
  Id = 'id',
  IsDefault = 'is_default',
  Name = 'name',
  Street = 'street',
  PostalCode = 'postal_code',
  City = 'city',
  Country = 'country',
  Point = 'point',
  Instructions = 'instructions',
}
