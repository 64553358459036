<app-modal-filler-atom
  (click)="closeModal()"
  [index]="(index$ | async) ?? 0"
></app-modal-filler-atom>
<div
  class="fixed right-0 left-0 shadow"
  [ngClass]="
    isMobile
      ? 'bottom-0 max-h-85-screen max-w-screen-sm'
      : 'flex justify-center items-center w-full h-full top-0 left-0'
  "
  [style.z-index]="((index$ | async) ?? 0) + 50"
>
  <div
    class="bg-white py-5"
    [ngClass]="isMobile ? 'rounded-t-3xl' : 'rounded-3xl min-w-448'"
    [style.z-index]="((index$ | async) ?? 0) + 50"
  >
    <div class="flex justify-between items-center pb-7 px-5">
      <h2 class="font-accent font-bold text-3xs leading-8">
        {{ 'pay-at-table.select-products-modal.title' | translate }}
      </h2>
    </div>
    <div class="max-h-45-screen overflow-y-auto px-5">
      <div class="flex mb-3 pb-2 border-b border-neutral-300">
        <input
          type="checkbox"
          [id]="'toggle'"
          [name]=""
          class="form-checkbox mr-3.5 h-5 w-5 border text-primary-600"
          (change)="toggleSelect()"
        />
        <label
          [for]="'toggle'"
          class="text-sm font-base font-normal mr-4"
          >{{ 'pay-at-table.select-products-modal.select-all' | translate }}</label
        >
      </div>
      <div
        *ngFor="let item of items"
        class="flex justify-between mb-3"
      >
        <div class="flex">
          <div>
            <input
              #checkbox
              type="checkbox"
              [id]="item.id"
              [name]="item.name"
              [checked]="item.isChecked"
              [disabled]="!item.isEnabled || !!item.selected_by"
              (change)="onCheckboxChange(checkbox.checked, item)"
              class="form-checkbox mr-3.5 h-5 w-5 border text-primary-600"
            />
          </div>
          <div>
            <label
              [for]="item.id"
              class="font-base font-normal mr-4"
              [class.opacity-50]="!item.isEnabled || !!item.selected_by"
              [class.line-through]="!item.isEnabled || !!item.selected_by"
              >{{ item.name }}</label
            >
          </div>
        </div>
        <app-price-atom
          [bold]="false"
          [amount]="item.price"
          [format]="true"
          [class.opacity-50]="!item.isEnabled || !!item.selected_by"
          [class.line-through]="!item.isEnabled || !!item.selected_by"
        ></app-price-atom>
      </div>
    </div>
    <div class="flex justify-between py-4 px-5 shadow-top">
      <span class="font-accent font-bold text-md leading-6">{{
        'pay-at-table.select-products-modal.total-to-pay' | translate
      }}</span>
      <span class="font-accent font-bold text-base leading-6"
        ><app-price-atom
          [amount]="totalAmount"
          [format]="true"
        ></app-price-atom
      ></span>
    </div>
    <div class="pt-2 px-5 flex justify-between items-center">
      <div class="mr-2 xm:pr-1 w-full xm:w-1/2">
        <app-button-atom
          size="M"
          [label]="'pay-at-table.select-products-modal.cancel' | translate"
          color="primary"
          (click)="closeModal()"
          type="light"
          [full]="true"
        ></app-button-atom>
      </div>
      <div class="xm:pl-1 w-full xm:w-1/2">
        <app-button-atom
          size="M"
          [disabled]="!totalAmount || disableValidate"
          [label]="'pay-at-table.select-products-modal.validate' | translate"
          (click)="validate()"
          type="primary"
          [full]="true"
        ></app-button-atom>
      </div>
    </div>
  </div>
</div>
