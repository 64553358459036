<div
  class="min-h-screen"
  [style.backgroundColor]="backgroundColor | contentBuilderColor"
>
  <app-block-list [blockIdList]="top"></app-block-list>
  <div
    *ngIf="orderTimeWarningMessage"
    class="text-center text-red-500 pb-2 lg:pb-4"
  >
    {{ orderTimeWarningMessage }}
  </div>
  <div
    class="mt-6 max-w-full lg:max-w-4xl lg:mx-auto grid grid-cols-1 gap-6 md:gap-12 lg:grid-cols-2 lg:gap-6"
  >
    <div class="lg:p-6 lg:bg-neutral-100 rounded-s">
      <app-block-list [blockIdList]="left"></app-block-list>
    </div>
    <div>
      <app-block-list [blockIdList]="right"></app-block-list>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
