import { Observable } from 'rxjs';
import { Component } from '@angular/core';
import { map, take, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { SettingsStoreSelector } from '@common/selectors/settings.selector';

import { CartService } from '@core/services/cart/cart.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { NotificationService } from '@core/services/notification/notification.service';
import { ModalStoreSelector } from '@common/selectors/modal.selector';

@Component({
  selector: 'app-cart-voucher-modal',
  templateUrl: './cart-voucher-modal.component.html',
})
export class CartVoucherModalComponent {
  static handle = 'cart-voucher-modal';

  index$: Observable<number> = this.modalSelector
    .selectModal(CartVoucherModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  isValidating = false;
  errorVoucher: string | undefined = undefined;

  constructor(
    private modalSelector: ModalStoreSelector,
    private readonly cartService: CartService,
    private readonly ordersService: OrdersService,
    private readonly modalsService: ModalsService,
    readonly settingsSelector: SettingsStoreSelector,
    private readonly translateService: TranslateService,
    private readonly notificationService: NotificationService,
  ) {}

  applyVoucher(voucher: string): void {
    if (voucher) {
      voucher = voucher.trim();
      this.isValidating = true;
      this.cartService.addCoupon({ code: voucher });
      this.ordersService
        .checkCartIsValid$()
        .pipe(
          take(1),
          tap(order => {
            const coupon = order?.coupons?.find(el => el.code === voucher);
            if (coupon?.error) {
              this.errorVoucher = coupon.error;
              this.isValidating = false;
              this.cartService.removeCoupon({ code: voucher });
              this.ordersService.checkCartIsValid$().subscribe();
              return;
            }
            this.closeVoucher();
            this.notificationService.displayMessage(
              this.translateService.instant('basket.notification-valid-voucher'),
            );
          }),
        )
        .subscribe();
    }
  }

  closeVoucher(): void {
    this.modalsService.close(CartVoucherModalComponent.handle);
  }
}
