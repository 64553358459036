<div class="flex items-center justify-between">
  <h1
    class="pb-5 font-bold text-neutral-800 font-brand text-28px"
    translate="como.link-account.header"
  ></h1>
  <app-rounded-icon-button
    icon="icon-cross"
    (onClick)="onClose.emit($event)"
  ></app-rounded-icon-button>
</div>

<form [formGroup]="form">
  <label
    for="phone"
    class="block mb-1 text-sm font-normal leading-5 font-base text-neutral-600"
    translate="signup.phone"
  ></label>
  <label
    for="comoRegistrationPhoneInput"
    class="block mb-2 text-xs font-normal font-base text-neutral-500"
    translate="como.help-phone-format"
  >
  </label>

  <input
    id="comoRegistrationPhoneInput"
    type="tel"
    formControlName="phone"
    class="flex w-full px-5 text-base font-normal leading-5 border border-solid py-8xs bg-neutral-50 font-base border-neutral-300"
    [class.border-error-700]="errorMessage"
  />

  <div class="hidden kiosk:block">
    <app-numeric-keypad-atom
      (numberClick)="onKeypadNumberClick($event)"
      (deleteClick)="onKeypadDeleteClick()"
    ></app-numeric-keypad-atom>
  </div>

  <div
    class="mt-2 text-xs font-normal font-base text-error-700"
    *ngIf="errorMessage"
    [translate]="errorMessage"
  ></div>

  <div class="flex flex-col mt-10">
    <app-button-atom
      size="L"
      [label]="'como.validate' | translate"
      [iconUrl]="isValidating ? 'assets/images/spinner.svg' : ''"
      [disabled]="isValidating || !form.valid"
      type="primary"
      [overrideBgColor]="accentColor"
      [full]="true"
      (click)="onRegister()"
    ></app-button-atom>
  </div>
</form>
