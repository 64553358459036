import { ChangeDetectionStrategy, Component, inject, input, output } from '@angular/core';
import { ProductStoreDispatcher } from '@common/dispatchers/product.dispatcher';

import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { DoodProductModel } from '@core/models/product.model';
import { ModalsService } from '@core/services/modals/modals.service';
import { staggeredListAnimation } from '@shared/animations/list.animation';
import {
  DraftAdditionProductModel,
  DraftAdditionsGroup,
} from '@shared/local-store/cart/util/cart-simple-product-util';
import { KioskProductOverviewModalComponent } from '@shared/modals/kiosk-product-overview-modal/kiosk-product-overview-modal.component';

@Component({
  selector: 'app-kiosk-select-additions-product-organism',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [staggeredListAnimation],
  template: `
    <app-kiosk-selection-group-wrapper-atom [secondaryColor]="secondaryColor()">
      <div
        class="grid grid-cols-3 gap-10"
        [@staggeredListAnimation]="{
          value: simpleProductAdditionsGroup().items.length,
          animateEnterElementFromTopToBottom: false,
        }"
      >
        <ng-container
          *ngFor="let addition of simpleProductAdditionsGroup().items; trackBy: trackById"
        >
          <ng-container *ngIf="addition && !addition.hidden">
            <div
              class="rounded"
              [ngClass]="{
                'outline outline-[8px]': (addition.quantity ?? 0) > 0,
              }"
              [style.outlineColor]="buttonBackgroundColor() | contentBuilderColor"
            >
              <app-kiosk-product-card-atom
                [product]="addition"
                [quantity]="addition.quantity"
                (changeProductQuantity)="
                  onChangeAdditionQuantity.emit({ additionId: addition.id, quantity: $event })
                "
                [isProductAvailable]="!!addition.available"
                [selectionnable]="addition.available"
                [displayPrice]="false"
                [displayedQuantity]="addition.quantity ?? 0"
                [textColor]="textColor()"
                [cardColor]="cardColor()"
                [buttonBackgroundColor]="buttonBackgroundColor()"
                [buttonTextColor]="buttonTextColor()"
                [buttonBackgroundColor]="primaryButtonBackgroundColor()"
                [unavailableButtonBackground]="unavailableButtonBackground()"
                [unavailableButtonText]="unavailableButtonText()"
                [secondaryColor]="secondaryColor()"
                [maxProduct]="addition.remainingAuthorizedQuantity"
                [monoSelection]="simpleProductAdditionsGroup().max_count === 1"
                [displayedAddition]="addition | appFn: getDisplayedAdditionPrice"
                (showProduct)="openProductModal(addition)"
              ></app-kiosk-product-card-atom>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!simpleProductAdditionsGroup().items?.length">
          <div class="py-4">
            {{ 'zerosix.offered-product-modal.no-product-available' | translate }}
          </div>
        </ng-container>
      </div>
    </app-kiosk-selection-group-wrapper-atom>
  `,
})
export class KioskSelectAdditionsProductOrganismComponent {
  private readonly productDispatcher = inject(ProductStoreDispatcher);
  private readonly modalsService = inject(ModalsService);

  public readonly backgroundColor = input<IContentBuilderFieldColor>();
  public readonly secondaryColor = input<IContentBuilderFieldColor>();
  public readonly cardColor = input<IContentBuilderFieldColor>();
  public readonly textColor = input<IContentBuilderFieldColor>();
  public readonly primaryButtonBackgroundColor = input<IContentBuilderFieldColor>();
  public readonly buttonBackgroundColor = input<IContentBuilderFieldColor>();
  public readonly buttonTextColor = input<IContentBuilderFieldColor>();
  public readonly unavailableButtonBackground = input<IContentBuilderFieldColor>();
  public readonly unavailableButtonText = input<IContentBuilderFieldColor>();

  public readonly simpleProductAdditionsGroup = input.required<DraftAdditionsGroup>();

  public readonly onChangeAdditionQuantity = output<{ additionId: string; quantity: number }>();

  protected getDisplayedAdditionPrice = (addition: DraftAdditionProductModel): number => {
    return addition.uiPrice ? addition.uiPrice * 100 : 0;
  };

  protected trackById(index: number, item: { id: string }): string {
    return item.id;
  }

  openProductModal(product: DoodProductModel): void {
    this.productDispatcher.insertActive(product);
    this.modalsService.open(KioskProductOverviewModalComponent.handle, undefined, {
      image: product.images?.cover?.url,
      name: product.name,
      description: product.description,
      allergens: product.allergens,
      type: product.type,
      backgroundColor: this.buttonTextColor(),
      accentColor: this.secondaryColor(),
    });
  }
}
