export enum CheckKeys {
  Id = 'id',
  ClosedAt = 'closed_at',
  OpenedAt = 'opened_at',
  Items = 'items',
  Transactions = 'transactions',
  Shop = 'shop',
  Marketplace = 'marketplace',
  OnSiteLocation = 'onsite_location',
  FinalPrice = 'final_price',
  ActiveTransaction = 'active_transaction',
  OutstandingAmount = 'outstanding_amount',
  MercurePrivateKey = 'mercure_private_key',
  AvailablePaymentMethods = 'available_payment_methods',
  Currency = 'currency',
}

export enum CheckItemKeys {
  Id = 'id',
  Name = 'name',
  Price = 'price',
  Description = 'description',
  SelectedBy = 'selected_by',
}

export enum CheckTransactionKeys {
  Id = 'id',
  Amount = 'amount',
  PaidAmount = 'paid_amount',
  CancellationReason = 'cancellation_reason',
  Source = 'source',
  Status = 'status',
  Type = 'type',
  CreatedAt = 'created_at',
  User = 'user',
  PaymentIntendId = 'payment_intent_id',
  Items = 'items',
  Tip = 'tip',
}
