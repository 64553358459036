import { createHydratedReducer } from '@store/_abstract/hydrated';

import {
  OSL_STORE_API_PIECE,
  OSL_STORE_LOCAL_PIECE,
  OSL_STORE_HYDRATION_PIECE,
} from './on-site-location.piece';
import {
  OSL_STORE_KEY,
  OSL_STORE_INITIAL_STATE,
  OnSiteLocationState,
} from './on-site-location.state';
import { DoodOnSiteLocationSourceValues } from './on-site-location.enum';

export const OSL_STORE_REDUCER = createHydratedReducer({
  key: OSL_STORE_KEY,
  initialState: OSL_STORE_INITIAL_STATE,
  beforeLoad: (_state: OnSiteLocationState) => {
    const _result: OnSiteLocationState = {
      ..._state,
      settings: {
        ..._state.settings,
        id: null,
      },
    };
    if (_result.settings.source === DoodOnSiteLocationSourceValues.fromQrCode) {
      _result.settings = {
        ..._result.settings,
        id: _result.settings.lastLocationId,
      };
    }
    return _result;
  },
  ons: [...OSL_STORE_API_PIECE, ...OSL_STORE_LOCAL_PIECE, ...OSL_STORE_HYDRATION_PIECE],
});
