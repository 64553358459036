import { Observable, of } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { UserKeys } from '@config/keys/user.keys';
import { ComoSignOn } from '@core/models/como.model';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { ModalsService } from '@core/services/modals/modals.service';
import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { ComoApiService } from '@core/services/api/como/como-api.service';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';
import { AuthFirebaseService } from '@core/services/api/auth-firebase/auth-firebase.service';

@Component({
  selector: 'app-como-sso-loading-modal',
  templateUrl: './como-sso-loading-modal.component.html',
})
export class ComoSsoLoadingModalComponent extends DestroyerBase implements OnInit {
  static handle = 'como-sso-loading-modal';

  @Input() legalCheckboxLabel?: any;

  index$: Observable<number> = this.modalSelector
    .selectModal(ComoSsoLoadingModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  displayLoading = true;
  displayEmailForm = false;
  form: UntypedFormGroup;
  errorMessage?: string;
  isValidating = false;
  temporaryToken = '';
  emailErrorAlreadyUsed = false;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private modalSelector: ModalStoreSelector,
    private readonly modalsService: ModalsService,
    private readonly comoApiService: ComoApiService,
    private marketplaceSelector: MarketplaceStoreSelector,
    private readonly authFirebaseService: AuthFirebaseService,
  ) {
    super();
    this.form = this.fb.group({
      [UserKeys.Email]: [''],
      legals: [false, Validators.requiredTrue],
    });
  }

  ngOnInit(): void {
    const urlSearchParams = new URLSearchParams(window.location.search);
    if (!urlSearchParams.has('temporaryToken')) {
      return;
    }

    const temporaryToken = urlSearchParams.get('temporaryToken');

    if (!temporaryToken) {
      return;
    }

    this.temporaryToken = temporaryToken;

    console.log(
      '[Como] Connecting to loyalty account with temporaryToken "' + this.temporaryToken + '"',
    );
    this.signOn().subscribe();
  }

  private signOn(legalAccepted = false, email?: string): Observable<null | any> {
    return this.marketplaceSelector.selectMarketplaceId.pipe(
      switchMap(marketplace =>
        this.comoApiService.getComoUserFromTemporaryToken$(
          this.temporaryToken,
          marketplace,
          legalAccepted,
          email,
        ),
      ),
      switchMap(comoSignOn => this.handleSingleSignOnResponse(comoSignOn)),
      catchError(error => {
        console.log('[Como] Unable to sign on from Como member');
        this.modalsService.close(ComoSsoLoadingModalComponent.handle);
        return of(null);
      }),
    );
  }

  private handleSingleSignOnResponse(comoSignOn: ComoSignOn): Observable<any> {
    this.emailErrorAlreadyUsed = false;
    if (comoSignOn.firebase_sign_in_token) {
      return this.authFirebaseService
        .signInWithCustomToken(comoSignOn.firebase_sign_in_token)
        .pipe(tap(() => this.modalsService.close(ComoSsoLoadingModalComponent.handle)));
    }
    if (comoSignOn.como_user) {
      this.displayLoading = false;
      this.displayEmailForm = !comoSignOn.como_user.email;
    }
    if (comoSignOn.error && comoSignOn.error === 'email_already_used') {
      this.isValidating = false;
      this.emailErrorAlreadyUsed = true;
    }
    return of(null);
  }

  close(): void {
    this.modalsService.close(ComoSsoLoadingModalComponent.handle);
  }

  onSubmit(): void {
    if (this.form.invalid || this.isValidating) {
      return;
    }
    this.isValidating = true;
    const email = this.form.get('email')?.value.trim();
    this.signOn(true, email).subscribe();
  }
}
