import { SettingsParametersState } from '@store/settings';
import { IParametersQueryParams } from '@core/models/parameters.model';

export class ParametersUtils {
  static getQueryParameters(parameters: SettingsParametersState): IParametersQueryParams {
    const { wanted_at, location } = parameters;

    return {
      ...parameters,
      latitude: location?.lat,
      longitude: location?.lng,
      address: location?.address,
      distance: parameters.distance ?? undefined,
      distribution_mode: parameters.distribution_mode,
      wanted_at: wanted_at ? wanted_at.toISOString() : null,
    };
  }
}
