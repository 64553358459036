<div class="pt-8.5 text-center">
  <h1 class="flex justify-center">
    <img
      [src]="logoImg ? logoImg : '../../../../assets/images/food-society/food-logo.svg'"
      class="max-w-xs"
      alt="logo"
    />
  </h1>
  <h2 class="py-3 text-xl font-brand text-neutral-800 tracking-brand">
    {{ brandText }}
  </h2>
  <ul
    *ngIf="links"
    class="text-primary-600 flex justify-center pb-4.5"
  >
    <li
      *ngFor="let link of links"
      class="m-3 text-2xl"
    >
      <a
        class="icon icon-{{ link.icon }}"
        [href]="link.url"
        target="_blank"
      ></a>
    </li>
  </ul>
</div>
