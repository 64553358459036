import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { DoodOpenCheck } from '@core/models/check.model';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';

import { Paths } from '@config/paths.config';
import { PaymentMethodHandles } from '@config/payment-methods.config';
import { CheckKeys } from '@config/keys/check.keys';
import { OnSiteLocationsService } from '@core/services/on-site-locations/on-site-locations.service';
import { OnSiteLocationStoreSelector } from '@common/selectors/on-site-location.selector';

@Injectable({
  providedIn: 'root',
})
export class PatCheckGuard {
  constructor(
    private readonly router: Router,
    private readonly routerHelper: RouterHelperService,
    private readonly onSiteLocationService: OnSiteLocationsService,
    private readonly onSiteLocationSelector: OnSiteLocationStoreSelector,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkIfCheckExists();
  }

  private checkIfCheckExists(): Observable<boolean> {
    const { id, lastLocationId } = this.onSiteLocationSelector.settings;
    const onSiteLocationId = id ?? lastLocationId;
    !onSiteLocationId;

    if (!onSiteLocationId) {
      return of(false);
    }

    return this.onSiteLocationService
      .loadOnSiteLocationOpenCheck$(onSiteLocationId)
      .pipe(map(check => this.redirectIfCheckDoesNotExist(check)));
  }

  private redirectIfCheckDoesNotExist(check: DoodOpenCheck | null): boolean {
    if (
      check?.[CheckKeys.Id] ||
      check?.[CheckKeys.FinalPrice] ||
      this.checkIfPaymentParametersExist()
    ) {
      return true;
    } else {
      this.redirectToPatTableOverview();
      return false;
    }
  }

  private redirectToPatTableOverview(): void {
    const routePath = `${Paths.PayAtTable}/${Paths.PayAtTableOverview}`;
    this.router.navigate([this.routerHelper.translateRoute(routePath)]);
  }

  private checkIfPaymentParametersExist(): boolean {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const paymentMethodParam = urlSearchParams.get('payment_method');
    const availablePaymentMethods = [PaymentMethodHandles.Edenred];

    if (!paymentMethodParam) {
      return false;
    }

    return availablePaymentMethods.includes(paymentMethodParam as PaymentMethodHandles);
  }
}
