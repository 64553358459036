<div class="bg-white max-w-screen-sm">
  <div
    class="flex justify-between items-center"
    (click)="toggleAccordion()"
  >
    <h2 class="inline-block text-md font-bold font-accent leading-6">
      {{ getHeaderString$() | async }}
    </h2>
    <button class="icon icon-arrow-down-s {{ isAccordionActive ? 'close' : '' }}"></button>
  </div>
  <div [@isAccordionActive]="isAccordionActive ? { value: 'visible' } : { value: 'hidden' }">
    <div
      *ngFor="let schedule of groupedSchedules"
      class="flex items-start border-b border-neutral-300 py-2.5"
    >
      <div class="mr-3 leading-4.5 w-20">
        {{ schedule.day.name | translate }}
      </div>
      <ul class="flex flex-col">
        <li
          *ngFor="let timeSlot of schedule.timeSlots; let i = index; let last = last"
          class="text-neutral-600 leading-4.5 {{ last ? 'mb-0' : 'mb-2' }}  flex flex-row"
        >
          {{ getHours(timeSlot.start) }}—{{ getHours(timeSlot.end) }}
          <div *ngIf="timeSlot.address">
            {{ timeSlot.address }}
          </div>

          <div
            *ngFor="let distributionMode of timeSlot.allowed_distribution_modes ?? []"
            class="ml-2 text-sm font-bold px-1 border rounded bg-neutral-50"
          >
            {{ 'distribution-modes.' + distributionMode | translate }}
          </div>
        </li>
      </ul>
      <div
        class="text-neutral-600 leading-4.5"
        *ngIf="!schedule.timeSlots.length"
      >
        {{ 'shop-informations.closed' | translate }}
      </div>
    </div>
  </div>
</div>
