import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-push-text-button-atom',
  templateUrl: './push-text-button-atom.component.html',
})
export class PushTextButtonAtomComponent {
  @Input() title!: string;
  @Input() text!: string;
  @Input() textButton!: string;
  @Input() buttonLink!: string;
  @Input() imgUrl!: string;
  @Input() backgroundUrl!: string;

  @Output() buttonClick = new EventEmitter<Event>();
}
