import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-header-social-item-block',
  templateUrl: './header-social-item-block.component.html',
})
export class HeaderSocialItemBlockComponent {
  @Input() item?: string;
  @Input() linkUrl?: string;
}
