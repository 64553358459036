import { ChangeDetectionStrategy, Component, computed, inject, input, output } from '@angular/core';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { IDeclinableCategory } from '@core/models/product.model';
import { staggeredListAnimation } from '@shared/animations/list.animation';
import { CartDraftDeclinableProductStore } from '@shared/local-store/cart/cart-draft-declinable-product.store';

@Component({
  selector: 'app-kiosk-select-product-category-organism',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [staggeredListAnimation],
  template: `
    <div
      class="grid grid-cols-3 gap-10"
      [@staggeredListAnimation]="{
        value: draftProducts().length,
        params: { animateEnterElementFromTopToBottom: true },
      }"
    >
      @for (product of draftProducts(); track product.id) {
        @if (product.id && !product.hidden) {
          <app-simple-product-card-organism
            [textColor]="textColor()"
            [cardColor]="cardColor()"
            [buttonBackgroundColor]="buttonBackgroundColor()"
            [buttonTextColor]="buttonTextColor()"
            [backgroundColor]="backgroundColor()"
            [primaryButtonBackgroundColor]="primaryButtonBackgroundColor()"
            [primaryButtonTextColor]="primaryButtonTextColor()"
            [secondaryColor]="secondaryColor()"
            [unavailableButtonBackground]="unavailableButtonBackground()"
            [unavailableButtonText]="unavailableButtonText()"
            [simpleProduct]="product"
            [maxProductQuantity]="category()?.count"
            [isCartValid]="product.id | appFn: cartValidity2"
            (changeQuantity)="
              updateSimpleProductQuantity({
                quantity: $event,
                productId: product.id,
                categoryId: categoryId(),
              })
            "
            (showProductAdditions)="showProductAdditions.emit($event)"
          >
          </app-simple-product-card-organism>
        }
      }
    </div>
  `,
})
export class KioskSelectProductCategoryOrganismComponent {
  // todo check to remove the dependance to cartDraftDeclinableProductStore
  private readonly cartDraftDeclinableProductStore = inject(CartDraftDeclinableProductStore);

  public readonly textColor = input<IContentBuilderFieldColor>();
  public readonly cardColor = input<IContentBuilderFieldColor>();
  public readonly buttonBackgroundColor = input<IContentBuilderFieldColor>();
  public readonly buttonTextColor = input<IContentBuilderFieldColor>();
  public readonly backgroundColor = input<IContentBuilderFieldColor>();
  public readonly primaryButtonBackgroundColor = input<IContentBuilderFieldColor>();
  public readonly primaryButtonTextColor = input<IContentBuilderFieldColor>();
  public readonly secondaryColor = input<IContentBuilderFieldColor>();
  public readonly unavailableButtonBackground = input<IContentBuilderFieldColor>();
  public readonly unavailableButtonText = input<IContentBuilderFieldColor>();

  public readonly categoryId = input.required<string>();
  public readonly showProductAdditions = output<{ selectedProductId: string }>();

  protected readonly category = computed<IDeclinableCategory | undefined>(() => {
    const category = this.cartDraftDeclinableProductStore
      .selectors()
      ?.getCategoryById({ categoryId: this.categoryId() });
    return category;
  });

  protected readonly draftProducts = computed(() => {
    const selectors = this.cartDraftDeclinableProductStore.selectors();
    if (!selectors) {
      return [];
    }
    return selectors.draftProductsByCategoryId[this.categoryId()] ?? [];
  });

  protected updateSimpleProductQuantity(data: {
    productId: string;
    quantity: number;
    categoryId: string;
  }) {
    this.cartDraftDeclinableProductStore.updateSimpleProductQuantity({
      categoryId: data.categoryId,
      productId: data.productId,
      quantity: data.quantity,
    });
  }

  protected readonly cartValidity2 = (simpleProductId: string) => {
    return this.cartDraftDeclinableProductStore.selectors()?.getSimpleProductValidities({
      categoryId: this.categoryId(),
      productId: simpleProductId,
    });
  };
}
