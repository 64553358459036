<div
  class="flex justify-between items-center p-8xs rounded-lg cursor-pointer bg-white"
  (click)="buttonClick.emit()"
>
  <p
    *ngIf="text"
    class="font-bold font-accent text-neutral-800 text-md leading-normal"
  >
    {{ text }}
  </p>
  <i class="icon icon-{{ icon }}"></i>
</div>
