import dayjs from 'dayjs';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, of, Subject, throwError } from 'rxjs';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { catchError, skipWhile, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { SELECT_ORDER_VALIDATION } from '@store/order';
import { DoodOrderModel } from '@store/order/order.model';
import { ShopStoreSelector } from '@common/selectors/shop.selector';
import { BasketStoreSelector } from '@common/selectors/basket.selector';

import { DestroyerBase } from '@core/base/destroyer/destroyer.base';

import { PushService } from '@core/services/push/push.service';
import { CartService } from '@core/services/cart/cart.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { BasketService } from '@core/services/basket/basket.service';
import { OrderTypeService } from '@core/services/order-type/order-type.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { NavigationHistoryService } from '@core/services/navigation-history/navigation-history.service';

import { AlertModalComponent } from '@shared/modals/alert-modal/alert-modal.component';
import { CartFooterModalComponent } from '@shared/modals/cart-footer-modal/cart-footer-modal.component';
import { CartStoreSelector } from '@common/selectors/cart.selector';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-cart-page',
  templateUrl: './cart-page.component.html',
})
export class CartPageComponent extends DestroyerBase implements OnInit, OnDestroy {
  @Input() top: unknown[] = [];
  @Input() left: unknown[] = [];
  @Input() right: unknown[] = [];
  @Input() backgroundColor: IContentBuilderFieldColor = {
    value: '#FFFFFF',
    type: ColorFieldTypesValues.Hex,
  };

  pushSubscription!: Subject<any>;

  orderTimeWarningMessage = '';

  constructor(
    private store: Store,
    private readonly router: Router,
    private cartSelector: CartStoreSelector,
    private shopSelector: ShopStoreSelector,
    private readonly pushService: PushService,
    private readonly cartService: CartService,
    private basketSelector: BasketStoreSelector,
    private readonly basketService: BasketService,
    private readonly modalsService: ModalsService,
    private readonly ordersService: OrdersService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly routerHelper: RouterHelperService,
    private readonly orderTypeService: OrderTypeService,
    private readonly translateService: TranslateService,
    private readonly navigationHistoryService: NavigationHistoryService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.handleCmiPaymentErrorOnRedirect();

    this.navigationHistoryService.pushStateFromActivatedRouteSnapshot(
      'cart',
      this.activatedRoute.snapshot,
    );

    const selectedDistributionMode = this.cartSelector.active?.type;

    if (selectedDistributionMode) {
      let capabilities = this.orderTypeService.getCapabilities(selectedDistributionMode);

      if (capabilities.preorderingAllowed) {
        this.ordersService
          .loadShopSlots$()
          .pipe(
            take(1),
            switchMap(() => this.ordersService.checkCartIsValid$()),
          )
          .subscribe();
      } else {
        this.ordersService.checkCartIsValid$().subscribe();
      }
    }

    this.modalsService.open(CartFooterModalComponent.handle);

    this.store
      .select(SELECT_ORDER_VALIDATION)
      .pipe(
        takeUntil(this._destroyerRef),
        tap(orderValidate => {
          if (orderValidate) {
            this.getOrderTimeIndicateMessage(orderValidate as DoodOrderModel);
          }
        }),
      )
      .subscribe();

    this.subscribeToSharedOrderUpdates();
  }

  ngOnDestroy(): void {
    this.basketSelector.select.pipe(take(1)).subscribe(basket => {
      this.pushService.removeSubscription(`dood/carts/${basket.id}`, this.pushSubscription);
    });
    super.ngOnDestroy();
    this.modalsService.close(CartFooterModalComponent.handle);
  }

  private handleCmiPaymentErrorOnRedirect(): void {
    this.activatedRoute.queryParams
      .pipe(
        take(1),
        switchMap(queryParams => {
          if (queryParams.error) {
            return this.translateService
              .get(`payment.${queryParams.error}` || 'payment.unknown-error')
              .pipe(
                take(1),
                tap(translation => {
                  this.modalsService.open(AlertModalComponent.handle);
                  this.modalsService.setData(AlertModalComponent.handle, {
                    message: translation,
                  });
                }),
              );
          }
          return of();
        }),
      )
      .subscribe();
  }

  private subscribeToSharedOrderUpdates(): void {
    this.basketSelector.select
      .pipe(
        skipWhile(basket => !basket.id || !basket.share_code),
        take(1),
        switchMap(basket => this.basketService.getBasket$(basket.id, basket.share_code)),
        switchMap(basket => {
          return combineLatest([of(basket), this.ordersService.checkCartIsValid$()]);
        }),
        switchMap(([basket, cart]) => {
          if (basket.id) {
            this.pushSubscription = this.pushService.addSubscription(`dood/carts/${basket.id}`);
            return this.pushSubscription;
          }
          return of(null);
        }),
        takeUntil(this._destroyerRef),
        switchMap(subscription =>
          subscription.action === 'update'
            ? this.basketService.getBasket$(subscription.id, this.basketSelector.basket.share_code)
            : of(null),
        ),
        catchError(error => {
          if (error.status === 403) {
            this.translateService
              .get('group-order-modal.order-cancelled')
              .pipe(
                take(1),
                tap(translation => {
                  alert(translation);
                  this.ordersService.clearOrders();
                  this.basketService.clearBasket();
                  this.cartService.clearCart();
                  this.router.navigate([this.routerHelper.translateRoute('/')]);
                }),
              )
              .subscribe();
          }
          return throwError(() => error);
        }),
        skipWhile(result => !result),
        switchMap(() => this.ordersService.checkCartIsValid$()),
      )
      .subscribe();
  }

  private getOrderTimeIndicateMessage(order: DoodOrderModel): void {
    const wantedAtTime = order.wanted_at;

    if (wantedAtTime && !this.isFirstSlot(wantedAtTime)) {
      this.orderTimeWarningMessage = '';
      return;
    }

    const orderReadyAtTime = dayjs(order.ready_at);
    const isTodayOrder = orderReadyAtTime.isToday();
    const isOrderTimeIsAfterNext2Hours = orderReadyAtTime.isAfter(
      dayjs().add(1, 'hours').add(59, 'minutes'),
    );
    if (!isOrderTimeIsAfterNext2Hours) {
      return;
    }

    if (isTodayOrder) {
      const hoursDiff = orderReadyAtTime.diff(dayjs(), 'hours');
      if (hoursDiff >= 2) {
        this.orderTimeWarningMessage = `${this.translateService.instant('order.parameters-overview.order-is-today')} ${hoursDiff} ${this.translateService.instant('order.parameters-overview.hours')}`;
      }
      return;
    }

    const today = dayjs().startOf('day');
    const orderReadyAtTimeStartDay = dayjs(orderReadyAtTime).startOf('day');
    const daysDiff = orderReadyAtTimeStartDay.diff(today, 'days');

    if (daysDiff === 1) {
      this.orderTimeWarningMessage = `${this.translateService.instant('order.parameters-overview.order-is-tomorrow')}`;
    } else {
      this.orderTimeWarningMessage = `${this.translateService.instant('order.parameters-overview.order-is-later')} ${daysDiff} ${this.translateService.instant('order.parameters-overview.days')}`;
    }
  }

  private isFirstSlot(wantedAt: Date, marginInMinutes = 15): boolean {
    const slots = this.shopSelector.slots;

    const specificDate = dayjs(wantedAt);
    if (!slots || !slots.length) {
      return false;
    }

    return slots.some(timestamp => {
      const dateFromTimestamp = dayjs(timestamp * 1000);
      const differenceInMinutes = Math.abs(specificDate.diff(dateFromTimestamp, 'minute'));

      return differenceInMinutes <= marginInMinutes;
    });
  }
}
