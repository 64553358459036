<app-modal-filler-atom
  (click)="close()"
  [index]="(index$ | async) ?? 0"
></app-modal-filler-atom>
<div
  class="fixed inset-x-0 overflow-y-auto shadow md:left-0 md:top-0 md:flex md:h-full md:w-full md:items-center md:justify-center mdMax:bottom-0 mdMax:mx-auto mdMax:max-h-85-screen mdMax:max-w-screen-sm"
  [style.z-index]="((index$ | async) ?? 0) + 50"
>
  <app-modal-filler-atom
    *ngIf="isDesktop$ | async"
    [withBackdrop]="false"
    (click)="close()"
    [index]="(index$ | async) ?? 0"
  ></app-modal-filler-atom>
  <div
    class="flex flex-col gap-3 rounded-t-3xl bg-white px-5 py-5 md:max-w-lg md:rounded-3xl md:px-7 md:py-7 lg:gap-5"
    [style.z-index]="((index$ | async) ?? 0) + 50"
  >
    <div class="flex items-center justify-between">
      <h2 class="font-accent text-xl font-bold">
        {{ 'pay-at-table.payment-confirmation.receipt.receive-my-receipt-by-mail' | translate }}
      </h2>
    </div>
    <p class="font-base text-neutral-600">
      {{ 'pay-at-table.payment-confirmation.receipt.modal-description' | translate }}
    </p>
    <div class="leading-5 text-neutral-800">
      <label
        for="email"
        class="mb-1 block font-base text-sm font-bold leading-5 text-neutral-600"
        >{{ 'pay-at-table.payment-confirmation.receipt.email' | translate }}</label
      >
      <input
        id="email"
        [(ngModel)]="email"
        class="w-full rounded border border-neutral-800 px-5 py-4.5 lg:w-448px"
        autofocus
      />
    </div>
    <div class="mt-4 flex items-center justify-between">
      <div class="mr-2 w-full xm:w-1/2 xm:pr-1">
        <app-button-atom
          size="M"
          [label]="'comment-modal.cancel' | translate"
          color="primary"
          (click)="close()"
          type="light"
          [full]="true"
        ></app-button-atom>
      </div>
      <div class="w-full xm:w-1/2 xm:pl-1">
        <app-button-atom
          size="M"
          [label]="'comment-modal.validate' | translate"
          (click)="submit(email)"
          type="primary"
          [full]="true"
          [iconUrl]="submitInProgress ? 'assets/images/spinner.svg' : ''"
          [disabled]="submitInProgress"
        ></app-button-atom>
      </div>
    </div>
  </div>
</div>
