import { of } from 'rxjs';
import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import {
  CartItemKeys,
  ReconstructedCartItemKeys,
  ReconstructedCartKeys,
} from '@config/keys/cart.keys';
import { ProductKeys } from '@config/keys/product.keys';
import { DistributionModeKeys, ShopKeys } from '@config/keys/shop.keys';

import { ShopStoreSelector } from '@common/selectors/shop.selector';
import { CartStoreSelector } from '@common/selectors/cart.selector';
import { BasketStoreRefiner } from '@common/refiners/basket.refiner';
import { OrderStoreSelector } from '@common/selectors/order.selector';
import { BasketStoreSelector } from '@common/selectors/basket.selector';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';
import { BasketStoreDispatcher } from '@common/dispatchers/basket.dispatcher';

import { CartService } from '@core/services/cart/cart.service';
import { ShopsService } from '@core/services/shops/shops.service';
import { BasketService } from '@core/services/basket/basket.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';

import { Paths } from '@config/paths.config';
import { GroupedOrderModalsAttributes } from '@config/modals/grouped-order-modals.config';

import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { DeliveryDistributionModes } from '@config/values/order.values';
import { ICoupon, DoodReconstructedCart } from '@core/models/cart.model';
import { ShareGroupedOrderModalComponent } from '@shared/modals/share-grouped-order-modal/share-grouped-order-modal.component';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-cart-item-list-block',
  templateUrl: './cart-item-list-block.component.html',
})
export class CartItemListBlockComponent extends DestroyerBase implements OnInit {
  @Input() headingColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() textColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() secondaryTextColor: IContentBuilderFieldColor = {
    value: 'neutral-500',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() productNameColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() productDetailsColor: IContentBuilderFieldColor = {
    value: 'neutral-600',
    type: ColorFieldTypesValues.Palette,
  };

  isGroupedOrderAllowed?: boolean;

  userId$ = this.authSelector.selectUserId;
  reconstructedCart$ = this.cartService.getCartLineItems$();

  basket$ = this.basketSelector.select;
  basketId$ = this.basketSelector.select.pipe(map(basket => basket[CartItemKeys.Id]));

  orderValidationInProgress$ = this.orderSelector.selectIsValidating;
  reconstructedShareOrderBasket$ = this.basketRefiner.selectCartItemsGroupedByUser;

  shopKeys = ShopKeys;
  productKeys = ProductKeys;
  cartItemKeys = CartItemKeys;
  reconstructedCartKeys = ReconstructedCartKeys;
  reconstructedCartItemKeys = ReconstructedCartItemKeys;

  constructor(
    private readonly router: Router,
    private shopSelector: ShopStoreSelector,
    private cartSelector: CartStoreSelector,
    private authSelector: AuthStoreSelector,
    private orderSelector: OrderStoreSelector,
    private basketRefiner: BasketStoreRefiner,
    private readonly cartService: CartService,
    private basketSelector: BasketStoreSelector,
    private readonly shopsService: ShopsService,
    private readonly modalsService: ModalsService,
    private readonly basketService: BasketService,
    private readonly ordersService: OrdersService,
    private basketDispatcher: BasketStoreDispatcher,
    private readonly routerHelper: RouterHelperService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.cartService.loadShopsOfCart$().subscribe();
    this.setIsGroupOrderAllowed();
  }

  onRemoveCartItem(
    cartItemId: number,
    cart: DoodReconstructedCart,
    reconstructedCartArray: DoodReconstructedCart[],
  ): void {
    if (cart[ReconstructedCartKeys.Items].length === 1 && reconstructedCartArray.length === 1) {
      this.cartService.clearCart();
      window.location.href = '/';
      return;
    }

    this.cartService.removeItem$(cartItemId).subscribe();
  }

  onRemoveBasketItem(itemId: string | number): void {
    this.basketSelector.select
      .pipe(
        take(1),
        switchMap(basket => {
          if (basket.id) {
            return this.basketService.removeBasketItem$(itemId as string);
          } else {
            return this.cartService.removeItem$(itemId as number);
          }
        }),
      )
      .subscribe();
  }

  onUpdateItem(cartItemId: number, groupOrderItemId?: string): void {
    if (groupOrderItemId) {
      this.basketDispatcher.updateBasket({ itemEditId: groupOrderItemId });
    }
    this.router.navigate([
      this.routerHelper.translateRoute(`${Paths.Cart}/${Paths.Items}/${cartItemId}`),
    ]);
  }

  removeUserFromCart(id: string): void {
    this.basketDispatcher.updateBasket({ removeUserId: id });
    this.modalsService.open(
      ShareGroupedOrderModalComponent.handle,
      GroupedOrderModalsAttributes.RemoveUserOrder,
    );
  }

  openGroupOrderModal(): void {
    const basket = this.basketSelector.basket;
    if (basket.id) {
      this.modalsService.open(
        ShareGroupedOrderModalComponent.handle,
        GroupedOrderModalsAttributes.GeneratedLink,
      );
      return;
    }
    this.modalsService.open(
      ShareGroupedOrderModalComponent.handle,
      GroupedOrderModalsAttributes.ShareLink,
    );
  }

  onRemoveCoupon(coupon: ICoupon): void {
    coupon.removeInProgress = true;
    this.basketService
      .removeBasketCoupon$(coupon)
      .pipe(switchMap(() => this.ordersService.checkCartIsValid$()))
      .subscribe(v => (coupon.removeInProgress = false));
  }

  setIsGroupOrderAllowed(): void {
    // TODO: Add support for multi shop baskets
    this.shopSelector.selectActive
      .pipe(
        switchMap(shop => {
          if (shop?.id) {
            return of(shop);
          }

          const shopId = this.cartSelector.active?.shop;
          if (shopId) {
            return this.shopsService.loadShopById$(shopId);
          }

          return of(undefined);
        }),
        takeUntil(this._destroyerRef),
        tap(shop => {
          const distributionModeFound = shop?.[ShopKeys.DistributionModes].find(
            distributionMode =>
              DeliveryDistributionModes.includes(distributionMode.type) ||
              distributionMode.type === this.cartSelector.active?.type,
          );

          this.isGroupedOrderAllowed =
            distributionModeFound?.[DistributionModeKeys.AllowGroupedOrder] || false;
        }),
      )
      .subscribe();
  }

  forceType(data: any): any {
    return data as any;
  }
}
