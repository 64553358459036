<div class="mx-auto grid max-w-sm grid-cols-3 gap-3 p-4">
  <!-- Les chiffres 1-9 -->
  <ng-container *ngFor="let number of [1, 2, 3, 4, 5, 6, 7, 8, 9, 'empty', 0, 'delete']">
    <button
      *ngIf="number !== 'empty' && number !== 'delete'"
      class="flex h-14 w-full items-center justify-center rounded-lg bg-primary-600 text-2xl text-white shadow focus:outline-none focus:ring-2 focus:ring-blue-300"
      (click)="onNumberClick(forceType(number))"
    >
      {{ number }}
    </button>
    <!-- Espace vide pour l'alignement -->
    <div
      *ngIf="number === 'empty'"
      class="h-14"
    ></div>
    <!-- Bouton de suppression -->
    <button
      *ngIf="number === 'delete'"
      (click)="onDeleteClick()"
      class="flex h-14 w-full items-center justify-center rounded-lg bg-primary-600 text-2xl text-white shadow focus:outline-none focus:ring-2 focus:ring-blue-300"
    >
      <!-- Icône de suppression, ici une croix svg pour représenter le bouton delete -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        class="h-6 w-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>
  </ng-container>
</div>
