import { DoodShopModel } from '@core/models/shop.model';
import { DoodMarketplaceCartScope, DoodMarketplaceModel } from '@core/models/marketplace.model';

export class CartUtils {
  static generateId(marketplace: DoodMarketplaceModel, shop?: DoodShopModel | null): string {
    return marketplace.cart_scope === DoodMarketplaceCartScope.marketplace
      ? `marketplace_${marketplace.id}`
      : `shop_${shop?.id}`;
  }
}
