import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subject, throwError } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { catchError, map, take, takeUntil, tap } from 'rxjs/operators';

import { Paths } from '@config/paths.config';
import { OrderKeys } from '@config/keys/order.keys';

import { DoodOrderModel } from '@store/order/order.model';
import { IEdenredPaymentIntent } from '@core/models/payment.model';
import { IEdenredGetUserResponse } from '@core/models/edenred.model';

import { CartService } from '@core/services/cart/cart.service';
import { NativeService } from '@core/services/native/native.service';
import { BasketService } from '@core/services/basket/basket.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { PaymentService } from '@core/services/payment/payment.service';
import { EdenredService } from '@core/services/edenred/edenred.service';
import { RouterHelperService } from 'src/app/core/services/router-helper/router-helper.service';

import { AlertModalComponent } from '@shared/modals/alert-modal/alert-modal.component';
import { PaymentStoreDispatcher } from '@common/dispatchers/payment.dispatcher';
import { OrderStoreDispatcher } from '@common/dispatchers/order.dispatcher';

@Component({
  selector: 'app-payment-panel-edenred',
  templateUrl: './payment-panel-edenred.component.html',
})
export class PaymentPanelEdenredComponent implements OnInit, OnDestroy {
  edenredBalance?: number;
  processingError?: string | null;
  isLoading = true;

  isNative = NativeService.native;

  balance?: number;
  private order: DoodOrderModel | null = null;

  protected readonly unsubscribe$ = new Subject<boolean>();

  isUserLoggedIn = false;
  ssoEdenredUrl!: string;

  constructor(
    private readonly router: Router,
    private readonly cartService: CartService,
    private orderDispatcher: OrderStoreDispatcher,
    private readonly basketService: BasketService,
    private readonly modalsService: ModalsService,
    private readonly ordersService: OrdersService,
    private readonly paymentService: PaymentService,
    private readonly edenredService: EdenredService,
    private paymentDispatcher: PaymentStoreDispatcher,
    private readonly routerHelper: RouterHelperService,
    private readonly translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.processingError = null;

    combineLatest([this.edenredService.getUser$(), this.ordersService.createOrder$('EDENRED')])
      .pipe(
        takeUntil(this.unsubscribe$),
        take(1),
        tap(([user, order]) => (this.order = order)),
        tap(([user, order]) => this.handleGetUserResponse(user)),
        tap(() => (this.isLoading = false)),
        catchError(error => {
          this.orderDispatcher.updateErrors({ error });
          this.orderDispatcher.resetValidation();
          return throwError(() => error);
        }),
      )
      .subscribe();

    this.paymentService.pay$
      .pipe(
        takeUntil(this.unsubscribe$),
        take(1),
        tap(() => this.handlePayment()),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.paymentDispatcher.resetUI();
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  private handleGetUserResponse(edenredUser: IEdenredGetUserResponse): void {
    if (edenredUser?.balance) {
      this.checkBalance(edenredUser.balance.available_amount);
      this.isUserLoggedIn = true;
      return;
    }

    const redirectUri = document.location.href + '?payment_method=EDENRED';
    this.edenredService
      .edenredWebLogin$(redirectUri)
      .pipe(
        take(1),
        tap(response => {
          if (response.urledenred) {
            this.ssoEdenredUrl = response.urledenred;
          }
        }),
      )
      .subscribe();
  }

  private checkBalance(balance: number): void {
    let orderFinalPrice = 0;
    if (this.order) {
      orderFinalPrice = this.order[OrderKeys.FinalPrice] || 0;
    }
    this.balance = balance;

    if (balance < orderFinalPrice) {
      this.translateService
        .get(['payment.edenred-picker.insufficient-balance'])
        .pipe(
          take(1),
          map(translation => {
            this.processingError = translation['payment.edenred-picker.insufficient-balance'];
          }),
        )
        .subscribe();

      this.paymentDispatcher.updateUI({
        button: {
          isEnabled: false,
        },
        status: {
          isInProgress: false,
        },
      });
    } else {
      this.processingError = null;
      this.paymentDispatcher.updateButton({
        isEnabled: true,
      });
    }
    this.isLoading = false;
  }

  private handlePayment(): void {
    this.paymentDispatcher.updateStatus({ isInProgress: true });

    if (!this.order) {
      this.onPaymentError();
      return;
    }

    this.paymentService
      .createOrderPaymentIntent$<IEdenredPaymentIntent>(this.order.id)
      .pipe(
        takeUntil(this.unsubscribe$),
        take(1),
        tap(result => {
          if (result.success) {
            this.onPaymentSuccess();
          } else {
            this.onPaymentError();
          }
        }),
      )
      .subscribe();
  }

  private onPaymentSuccess(): void {
    this.paymentDispatcher.updateUI({
      button: {
        isEnabled: false,
      },
      status: {
        isInProgress: false,
      },
    });

    if (!this.order) {
      this.onPaymentError();
      return;
    }

    this.cartService.clearCart();
    this.basketService.clearBasket();
    this.router.navigate([
      this.routerHelper.translateRoute(`${Paths.Orders}/${this.order.id}/${Paths.Status}`),
    ]);
  }

  private onPaymentError(): void {
    this.paymentDispatcher.updateUI({
      button: {
        isEnabled: true,
      },
      status: {
        isInProgress: false,
      },
    });

    this.modalsService.open(AlertModalComponent.handle);
    this.modalsService.setData(AlertModalComponent.handle, {
      title: this.translateService.instant('payment.payment-failed'),
      message: this.translateService.instant('payment.alert-error'),
    });
  }

  redirectToSsoEdenred(): void {
    window.location.href = this.ssoEdenredUrl;
  }
}
