<div class="flex flex-nowrap pt-1">
  <div class="w-full pt-2">
    <app-button-atom
      size="M"
      [label]="label"
      (click)="click($event)"
      [type]="style"
      [size]="size"
      [full]="true"
      [link]="action === 'GO_TO_URL' ? linkUrl : ''"
    ></app-button-atom>
  </div>
</div>
