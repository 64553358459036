export enum Paths {
  Account = 'account',
  Cart = 'cart',
  CartResume = 'cart',
  Funnel = 'funnel',
  Items = 'items',
  Orders = 'orders',
  Status = 'status',
  Shops = 'shops',
  ShopsMap = 'map',
  Validate = 'validate',
  SharedOrder = 'shared-order',
  GroupPayment = 'group-payment',
  Payment = 'payment',
  PayAtTable = 'pay-at-table',
  PayAtTableOverview = 'overview',
  PayAtTableChooseAmount = 'choose-amount',
  PayAtTablePayment = 'payment',
  PaymentConfirmation = 'payment-confirmation',
}
