import { EntityMap, EntityMapOne, Update } from '@ngrx/entity';
import { ActionGroup } from '@store/_abstract/common/action.creator';
import { ActionCreatorProps, createActionGroup, emptyProps, props } from '@ngrx/store';

export enum ActiveStoreActionsKeys {
  // One
  addOne = 'Add One',
  mapOne = 'Map One',
  removeOne = 'Remove One',
  updateOne = 'Update One',
  upsertOne = 'Upsert One',

  // Multiple
  addMany = 'Add Many',
  mapMany = 'Map Many',
  removeMany = 'Remove Many',
  updateMany = 'Update Many',
  upsertMany = 'Upsert Many',

  // All
  setAll = 'Set All',
  removeAll = 'Remove All',

  // Active
  setActive = 'Set Active',
  setActiveCategory = 'Set Active Category',
  resetActive = 'Reset Active',
  updateActive = 'Update Active',
  insertActive = 'Insert Active',
  upsertActive = 'Upsert Active',

  // Misc
  reset = 'Reset',
}

export type ActiveStoreActions<Entity, Id> = {
  // One
  [ActiveStoreActionsKeys.addOne]: ActionCreatorProps<{ entity: Entity }>;
  [ActiveStoreActionsKeys.mapOne]: ActionCreatorProps<{ entity: EntityMapOne<Entity> }>;
  [ActiveStoreActionsKeys.removeOne]: ActionCreatorProps<{ id: Id }>;
  [ActiveStoreActionsKeys.updateOne]: ActionCreatorProps<{ update: Update<Entity> }>;
  [ActiveStoreActionsKeys.upsertOne]: ActionCreatorProps<{ entity: Entity }>;

  // Many
  [ActiveStoreActionsKeys.addMany]: ActionCreatorProps<{ entities: Entity[] }>;
  [ActiveStoreActionsKeys.mapMany]: ActionCreatorProps<{ entities: EntityMap<Entity> }>;
  [ActiveStoreActionsKeys.removeMany]: ActionCreatorProps<{ ids: Id[] }>;
  [ActiveStoreActionsKeys.updateMany]: ActionCreatorProps<{ updates: Update<Entity>[] }>;
  [ActiveStoreActionsKeys.upsertMany]: ActionCreatorProps<{ entities: Entity[] }>;

  // All
  [ActiveStoreActionsKeys.setAll]: ActionCreatorProps<{ entities: Entity[] }>;
  [ActiveStoreActionsKeys.removeAll]: ActionCreatorProps<void>;

  // Active
  [ActiveStoreActionsKeys.resetActive]: ActionCreatorProps<void>;
  [ActiveStoreActionsKeys.setActive]: ActionCreatorProps<{ active: Id | null }>;
  [ActiveStoreActionsKeys.setActiveCategory]: ActionCreatorProps<{
    activeCategory: string | undefined;
  }>;
  [ActiveStoreActionsKeys.updateActive]: ActionCreatorProps<{ changes: Partial<Entity> }>;
  [ActiveStoreActionsKeys.insertActive]: ActionCreatorProps<{ entity: Entity }>;
  [ActiveStoreActionsKeys.upsertActive]: ActionCreatorProps<{ changes: Update<Entity> }>;

  // Misc
  [ActiveStoreActionsKeys.reset]: ActionCreatorProps<void>;
};
export type ActiveStoreActionGroup<Entity, Id> = ActionGroup<
  string,
  ActiveStoreActions<Entity, Id>
>;

export const ACTIVE_STORE_KEY = 'Dood/ACTIVE';

const createActiveStoreSource = <Source extends string>(name: string): Source => {
  return `${ACTIVE_STORE_KEY}/${name}` as Source;
};

export const createActiveStoreActions = <Entity, Id extends string | number = string>(
  name: string,
): ActiveStoreActionGroup<Entity, Id> => {
  return createActionGroup({
    source: createActiveStoreSource(name),
    events: {
      // One
      [ActiveStoreActionsKeys.addOne]: props<{ entity: Entity }>(),
      [ActiveStoreActionsKeys.mapOne]: props<{ entity: EntityMapOne<Entity> }>(),
      [ActiveStoreActionsKeys.removeOne]: props<{ id: Id }>(),
      [ActiveStoreActionsKeys.updateOne]: props<{ update: Update<Entity> }>(),
      [ActiveStoreActionsKeys.upsertOne]: props<{ entity: Entity }>(),

      // Many
      [ActiveStoreActionsKeys.addMany]: props<{ entities: Entity[] }>(),
      [ActiveStoreActionsKeys.mapMany]: props<{ entities: EntityMap<Entity> }>(),
      [ActiveStoreActionsKeys.removeMany]: props<{ ids: Id[] }>(),
      [ActiveStoreActionsKeys.updateMany]: props<{ updates: Update<Entity>[] }>(),
      [ActiveStoreActionsKeys.upsertMany]: props<{ entities: Entity[] }>(),

      // All
      [ActiveStoreActionsKeys.setAll]: props<{ entities: Entity[] }>(),
      [ActiveStoreActionsKeys.removeAll]: emptyProps(),

      // Active
      [ActiveStoreActionsKeys.setActive]: props<{ active: Id | null }>(),
      [ActiveStoreActionsKeys.setActiveCategory]: props<{ activeCategory: string | undefined }>(),
      [ActiveStoreActionsKeys.resetActive]: emptyProps(),
      [ActiveStoreActionsKeys.updateActive]: props<{ changes: Partial<Entity> }>(),
      [ActiveStoreActionsKeys.insertActive]: props<{ entity: Entity }>(),
      [ActiveStoreActionsKeys.upsertActive]: props<{ changes: Update<Entity> }>(),

      // Misc
      [ActiveStoreActionsKeys.reset]: emptyProps(),
    },
  });
};
