<div
  class="flex flex-col"
  [style.backgroundColor]="backgroundColor | contentBuilderColor"
  [style.borderColor]="textColor | contentBuilderColor"
>
  <div class="fixed bottom-0 left-0 right-0 w-full md:sticky">
    <div class="grid grid-cols-{{ displaySecondButton ? '2' : '1' }} gap-10 p-5">
      <app-button-atom
        size="XXL"
        thickSize="XXL"
        [label]="firstButtonLabel ?? ''"
        [overrideBgColor]="firstButtonBackgroundColor"
        [overrideTextColor]="firstButtonTextColor"
        [disabled]="!!firstButtonDisabled"
        color="primary"
        type="kiosk-light"
        (click)="onFirstButtonClick.emit($event)"
        [full]="true"
      ></app-button-atom>

      <ng-container *ngIf="displaySecondButton">
        <app-button-atom
          size="XXL"
          [label]="secondButtonLabel ?? ''"
          thickSize="XXL"
          [overrideBgColor]="secondButtonBackgroundColor"
          [overrideTextColor]="secondButtonTextColor"
          [disabled]="!!secondButtonDisabled"
          type="kiosk-primary"
          (click)="onSecondButtonClick.emit($event)"
          [full]="true"
        ></app-button-atom>
      </ng-container>
    </div>
  </div>
</div>
