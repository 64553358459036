<div
  class="flex min-h-20 max-w-screen-sm cursor-pointer justify-between bg-white px-5 py-3"
  [class.opacity-40]="!isAvailable(product)"
  (click)="goToProduct(product)"
>
  <div class="pr-5">
    <p
      *ngIf="product[ProductKeys.ShopName]"
      class="pt-1.5 font-accent text-xs font-semibold leading-4.5 text-neutral-500"
    >
      <i class="icon icon-chef-hat"></i>
      {{ product[ProductKeys.ShopName] }}
    </p>
    <p class="pt-1.5 font-accent text-base font-bold leading-4.5">
      {{ product[ProductKeys.Name] }}
    </p>
    <p
      *ngIf="product[ProductKeys.Description]"
      class="line-clamp-2 pt-1.5 font-base text-sm"
    >
      {{ product[ProductKeys.Description] }}
    </p>
    <ul class="flex flex-wrap py-3">
      <li
        class="py-1 pl-0 pr-3"
        *ngIf="!isAvailable(product)"
      >
        <app-tag-atom
          color="neutral"
          text="Produit indisponible"
        ></app-tag-atom>
      </li>
      <li
        class="py-1 pl-0 pr-3"
        *ngFor="let label of visibleLabels(product[ProductKeys.Labels])"
      >
        <app-tag-atom
          [color]="forceType(label.color ? label.color : 'neutral')"
          [text]="label.name"
          [lineThrough]="label.line_through"
        ></app-tag-atom>
      </li>
    </ul>
    <ng-container *ngIf="product[ProductKeys.Discount]; else priceOnly">
      <app-price-atom
        class="p-1.5 pl-0"
        [amount]="product[ProductKeys.Discount] ?? 0"
      ></app-price-atom>
      <app-tag-atom
        class="p-1.5"
        color="success"
        text="{{
          product[ProductKeys.Price] | discountPercent: product[ProductKeys.Discount] ?? 0
        }}"
      ></app-tag-atom>
      <app-price-atom
        class="p-1.5"
        [amount]="product[ProductKeys.Price]"
        [discount]="true"
      ></app-price-atom>
    </ng-container>
    <ng-template #priceOnly>
      <app-price-atom
        class="p-1.5 pl-0"
        [amount]="product[ProductKeys.Price]"
      ></app-price-atom>
    </ng-template>
  </div>
  <div class="height-fit-content relative">
    <img
      *ngIf="product.images?.thumbnail?.url"
      class="push-image w-28 rounded object-cover"
      [src]="product.images?.thumbnail?.url"
      alt="image"
    />
    <app-add-button-atom
      *ngIf="isAvailable(product)"
      class="absolute bottom-1.5 right-1.5"
    ></app-add-button-atom>
  </div>
</div>
