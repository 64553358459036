import { Observable, Subject } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';
import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { find } from 'lodash';

import { ComoService } from '@core/services/como/como.service';

import { ComoAsset, ComoUser } from '@core/models/como.model';
import { CartService } from '@core/services/cart/cart.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-kiosk-cart-loyalty-block',
  templateUrl: './kiosk-cart-loyalty-block.component.html',
})
export class KioskCartLoyaltyBlockComponent
  extends DestroyerBase
  implements OnChanges, OnInit, OnDestroy
{
  @Input() primaryButtonBackgroundColor?: IContentBuilderFieldColor;
  @Input() primaryButtonTextColor?: IContentBuilderFieldColor;
  @Input() secondaryButtonBackgroundColor?: IContentBuilderFieldColor;
  @Input() secondaryButtonTextColor?: IContentBuilderFieldColor;
  @Input() textColor!: IContentBuilderFieldColor;
  @Input() backgroundColor!: IContentBuilderFieldColor;
  @Input() connexionHeading?: string;
  @Input() connexionCardLabel?: string;
  @Input() linkConnexion?: string;

  @Input() heading = 'como.loyalty-rewards';
  @Input() subheading?: string;
  @Input() displayCredits = true;
  @Input() displayPoints = true;
  @Input() displayRedeemableGifts = true;
  @Input() displayNotRedeemableGifts = true;
  @Input() displayRedeemablePointShop = true;
  @Input() displayNotRedeemablePointShop = true;
  @Input() displayNotUsablePointShop = true;

  @ViewChild('loyaltyCarousel') loyaltyCarousel!: ElementRef;

  scrollAmount = 172; // w-40 + mr-3

  public comoUser?: ComoUser | null;

  private readonly destroyed$ = new Subject<boolean>();

  redeemableAssets: ComoAsset[] = [];
  noRedeemableAssets: ComoAsset[] = [];
  redeemablePointShops: ComoAsset[] = [];
  noRedeemablePointShops: ComoAsset[] = [];
  pointShops: ComoAsset[] = [];

  positionSticky = false;
  positionStickyTop = 0;

  constructor(
    private readonly comoService: ComoService,
    private readonly cartService: CartService,
    private readonly ordersService: OrdersService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.comoService.loadComoUser();
    this.comoService
      .getComoUser$()
      .pipe(
        takeUntil(this.destroyed$),
        tap(comoUser => (this.comoUser = comoUser)),
        tap(() => this.ngOnChanges()),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onAssetAdd(asset: ComoAsset): void {
    this.comoService.confirmUsePointToAddAsset(asset, true);
  }

  onAssetRemove($event: ComoAsset): void {
    const coupon = { key: $event.key };
    this.cartService.removeCoupon(coupon);
    this.updateCart();
  }

  private updateCart(): void {
    this.ordersService.checkCartIsValid$().pipe(take(1)).subscribe();
  }

  ngOnChanges(): void {
    this.redeemableAssets = [];
    this.noRedeemableAssets = [];
    this.redeemablePointShops = [];
    this.noRedeemablePointShops = [];
    this.pointShops = [];

    if (!this.comoUser) {
      return;
    }

    for (const asset of this.comoUser?.assets) {
      const isPointShop = asset.key?.startsWith('ps_');
      if (asset.is_usable) {
        if (asset.status?.toLowerCase() !== 'active') {
          continue;
        }
        if (asset.redeemable === true) {
          if (isPointShop) {
            this.redeemablePointShops.push(asset);
          } else {
            this.redeemableAssets.push(asset);
          }
        } else {
          if (isPointShop) {
            this.noRedeemablePointShops.push(asset);
          } else {
            this.noRedeemableAssets.push(asset);
          }
        }
      } else {
        this.pointShops.push(asset);
      }
    }
  }

  isAssetInCart$(asset: ComoAsset): Observable<boolean> {
    return this.comoService.getComoUser$().pipe(
      takeUntil(this._destroyerRef),
      map(() => {
        const coupons = this.cartService.getCouponsInCart();

        return !!find(coupons, coupon => coupon.key && coupon.key === asset.key);
      }),
    );
  }

  scrollLeft() {
    this.loyaltyCarousel.nativeElement.scrollLeft -= this.scrollAmount * 2;
  }

  scrollRight() {
    this.loyaltyCarousel.nativeElement.scrollLeft += this.scrollAmount * 2;
  }
}
