export const ParametersWantedAtTypeLabels = {
  ASAP: `parameters.asap`,
  LATER: 'parameters.for-later',
};

export enum ParametersDistributionModeLabels {
  ClickAndCollect = 'home.hero.distribution-click-and-collect',
  Delivery = 'home.hero.distribution-delivery',
  OnSite = 'home.hero.distribution-on-site',
  EatIn = 'home.hero.distribution-eat-in',
  Shipping = 'home.hero.distribution-shipping',
}
