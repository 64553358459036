import { Router } from '@angular/router';
import { combineLatest, of, timer } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { switchMap, take, takeUntil, takeWhile, tap } from 'rxjs/operators';

import { Paths } from '@config/paths.config';
import { PaymentMethodHandles } from '@config/payment-methods.config';

import { CartService } from '@core/services/cart/cart.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { BasketService } from '@core/services/basket/basket.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { PaymentService } from '@core/services/payment/payment.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';

import { DoodOrderModel } from '@store/order/order.model';
import { DoodApiError } from '@shared/interfaces/error.interface';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { CartStoreRefiner } from '@common/refiners/cart.refiner';
import { CartStoreDispatcher } from '@common/dispatchers/cart.dispatcher';
import { PaymentStoreDispatcher } from '@common/dispatchers/payment.dispatcher';
import { LydiaPaymentPhoneNumberModalComponent } from '@shared/modals/lydia-payment-phone-number-modal/lydia-payment-phone-number-modal.component';

@Component({
  selector: 'app-payment-panel-lydia',
  templateUrl: './payment-panel-lydia.component.html',
})
export class PaymentPanelLydiaComponent extends DestroyerBase implements OnInit, OnDestroy {
  isOrderCancelled = false;
  isPaymentInProgress = false;
  paymentPhoneNumber?: string;
  displayPaymentLoadingScreen = false;

  constructor(
    private readonly router: Router,
    private cartRefiner: CartStoreRefiner,
    private readonly cartService: CartService,
    private cartDispatcher: CartStoreDispatcher,
    private readonly ordersService: OrdersService,
    private readonly modalsService: ModalsService,
    private readonly basketService: BasketService,
    private readonly paymentService: PaymentService,
    private paymentDispatcher: PaymentStoreDispatcher,
    private readonly routerHelper: RouterHelperService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.cartRefiner.selectActiveCartPaymentPhoneNumber
      .pipe(take(1))
      .subscribe(paymentPhoneNumber => {
        if (!paymentPhoneNumber) {
          this.openPhoneNumberModal();
        }
      });

    this.cartRefiner.selectActiveCartPaymentPhoneNumber
      .pipe(takeUntil(this._destroyerRef))
      .subscribe(paymentPhoneNumber => {
        this.paymentPhoneNumber = paymentPhoneNumber;
        this.paymentDispatcher.updateButton({
          isEnabled: !this.isPaymentInProgress && !!this.paymentPhoneNumber,
        });
      });

    timer(1).subscribe(() => {
      this.paymentDispatcher.updateUI({
        error: null,
        button: {
          isVisible: true,
          text: 'lydia.pay-button',
          isEnabled: !this.isPaymentInProgress && !!this.paymentPhoneNumber,
        },
        status: {
          isLoading: false,
        },
      });
    });

    this.paymentService.pay$.pipe(take(1)).subscribe(() => this.createOrderAndPay());
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.closePhoneNumberModal();
  }

  createOrderAndPay(): void {
    if (!this.paymentPhoneNumber) {
      return;
    }

    this.isPaymentInProgress = true;

    this.paymentDispatcher.updateUI({
      error: null,
      button: {
        isEnabled: false,
      },
      status: {
        isLoading: true,
      },
    });

    this.cartDispatcher.updateActive({
      PaymentPhoneNumber: this.paymentPhoneNumber,
    });

    this.ordersService
      .createOrder$(PaymentMethodHandles.Lydia)
      .pipe(
        // TODO: Check api response model
        switchMap((order: DoodOrderModel | null) =>
          combineLatest([
            of(order),
            order ? this.paymentService.createOrderPaymentIntent$(order.id) : of(null),
          ]),
        ),
        tap(([order]) => {
          this.displayPaymentLoadingScreen = true;
          if (order) {
            this.listenToOrderStatusChange(order);
          }
          // window.open(paymentData.mobile_url);
        }),
      )
      .subscribe({
        error: (response: { error: DoodApiError }) => {
          this.handlePaymentError(response.error);
        },
      });
  }

  changePaymentMethod(): void {
    this.router.navigate([this.routerHelper.translateRoute('/cart')]);
  }

  openPhoneNumberModal(): void {
    this.modalsService.open(LydiaPaymentPhoneNumberModalComponent.handle);
  }

  closePhoneNumberModal(): void {
    this.modalsService.close(LydiaPaymentPhoneNumberModalComponent.handle);
  }

  private listenToOrderStatusChange(order: DoodOrderModel): void {
    timer(5000, 5000)
      .pipe(
        takeUntil(this._destroyerRef),
        switchMap(() => this.ordersService.getOrderById$(order.id)),
        tap((updatedOrder: DoodOrderModel) => {
          if (updatedOrder.status === 'CANCELLED') {
            this.isOrderCancelled = true;
            return;
          }

          if (updatedOrder.status !== 'PAYMENT') {
            this.handlePaymentSuccess(updatedOrder);
            return;
          }
        }),
        takeWhile((updatedOrder: DoodOrderModel) => {
          return updatedOrder.status === 'PAYMENT';
        }),
      )
      .subscribe();
  }

  private handlePaymentSuccess(updatedOrder: DoodOrderModel): void {
    this.basketService.clearBasket();
    this.cartService.clearCart();
    this.router.navigate([
      this.routerHelper.translateRoute(`${Paths.Orders}/${updatedOrder.id}/${Paths.Status}`),
    ]);
    this.isPaymentInProgress = false;
    this.paymentDispatcher.updateUI({
      error: null,
      button: {
        isEnabled: false,
      },
      status: {
        isLoading: false,
        isInProgress: false,
        isRetryInProgress: false,
      },
    });
  }

  private handlePaymentError(error: Partial<DoodApiError>): void {
    const paymentErrorMessage =
      error.detail ?? 'Une erreur est survenue. Veuillez utiliser un autre moyen de paiement.';

    this.isPaymentInProgress = false;
    timer(1).subscribe(() => {
      this.paymentDispatcher.updateUI({
        error: paymentErrorMessage,
        button: {
          isEnabled: true,
        },
        status: {
          isLoading: false,
          isInProgress: false,
          isRetryInProgress: false,
        },
      });
    });
  }
}
