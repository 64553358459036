import { Component } from '@angular/core';

import { NavigationHistoryService } from '@core/services/navigation-history/navigation-history.service';

@Component({
  selector: 'app-order-details-page-header',
  templateUrl: './order-details-page-header.component.html',
})
export class OrderDetailsPageHeaderComponent {
  constructor(private readonly navigationHistoryService: NavigationHistoryService) {}

  back(): void {
    this.navigationHistoryService.goBack();
  }
}
