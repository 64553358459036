export class ObjectUtils {
  static isObject<D>(_value: unknown): _value is Record<string, unknown> {
    return typeof _value === 'object' && !Array.isArray(_value);
  }

  static isNull(_value: unknown): _value is null {
    return _value === null;
  }

  static isUndefined(_value: unknown): _value is undefined {
    return _value === undefined;
  }

  static isNil(_value: unknown): _value is null | undefined {
    return this.isNull(_value) || this.isUndefined(_value);
  }

  static isNotNil<T>(_value: T): _value is T extends null | undefined ? never : T {
    return !this.isNil(_value);
  }
}
