import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { from, of, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { catchError, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { StatusBar, Style } from '@capacitor/status-bar';

import { DistributionModeKeys } from '@config/keys/shop.keys';

import { ISimpleItem } from '@core/models/simple-item.model';
import { ILocationParameter } from '@core/models/parameters.model';
import { PlaceResult } from 'src/app/core/models/google-maps.model';

import { NativeService } from '@core/services/native/native.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

import { Paths } from '@config/paths.config';
import { ParametersUtils } from '@shared/utils/parameters/parameters.utils';
import { BASE_CUSTOM_SWITCH_VALUES } from '@config/values/parameters.values';

import { GoogleApiService } from '@core/services/api/google/google-api.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { ShopSearchParametersService } from '@core/services/shop-search-parameters/shop-search-parameters.service';

import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { InputTextAtomComponent } from '@shared/atoms/input-text-atom/input-text-atom.component';
import { SidebarNavigationModalComponent } from '@shared/modals/sidebar-navigation-modal/sidebar-navigation-modal.component';

@Component({
  selector: 'app-marketplace-hero-block',
  templateUrl: './marketplace-hero-block.component.html',
})
export class MarketplaceHeroBlockComponent implements AfterViewInit, OnDestroy {
  private destroyed$ = new Subject<boolean>();

  @Input() heading!: string;
  @Input() leading!: string;
  @Input() imageSlides?: any[];
  @Input() textSlides?: any[];
  @Input() containerDevided = false;

  @ViewChildren(InputTextAtomComponent) inputs!: QueryList<InputTextAtomComponent>;

  isNotched = false;

  distributionModeItems: ISimpleItem[] = [];
  customSwitchItems = BASE_CUSTOM_SWITCH_VALUES;

  geolocalizeError?: string;
  selectedDistributionMode?: string;
  selectedAddress: ILocationParameter | null = null;

  constructor(
    private readonly router: Router,
    private readonly modalsService: ModalsService,
    private settingsSelector: SettingsStoreSelector,
    private readonly googleApiService: GoogleApiService,
    private readonly routerHelper: RouterHelperService,
    private readonly viewportScroller: ViewportScroller,
    private marketplaceSelector: MarketplaceStoreSelector,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly shopSearchParametersService: ShopSearchParametersService,
  ) {
    from(NativeService.isNotched())
      .pipe(
        switchMap(_isNotched => {
          this.isNotched = _isNotched;
          return _isNotched ? from(StatusBar.setStyle({ style: Style.Dark })) : of();
        }),
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.setAllowedMarketplaceDistributionsMode();
    this.setDefaultParametersValue();

    this.inputs.forEach(input => {
      const inputNativeElement = input.addressSearchInput.nativeElement;
      this.googleApiService
        .getAutoCompleteAddress(inputNativeElement)
        .pipe(
          takeUntil(this.destroyed$),
          tap(place => this.placeChanged(place)),
        )
        .subscribe();
    });
    this.changeDetectorRef.detectChanges();
  }

  private setDefaultParametersValue(): void {
    this.selectedDistributionMode = this.settingsSelector.parameters.distribution_mode;
    if (!this.selectedDistributionMode) {
      this.selectedDistributionMode = this.distributionModeItems[0].value;
    }
    this.selectedAddress = this.settingsSelector.parameters.location;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  geolocalizeUser(): void {
    this.googleApiService
      .geolocateUser$()
      .pipe(
        take(1),
        tap(place => this.placeChanged(place)),
        catchError(error => {
          this.geolocalizeError = error;
          this.inputs.forEach(input => input.clearValue());
          return of(error);
        }),
      )
      .subscribe();
  }

  placeChanged(place?: PlaceResult): void {
    if (!place) {
      this.selectedAddress = null;
      return;
    }
    this.geolocalizeError = undefined;

    this.selectedAddress = {
      lat: place.geometry?.location.lat(),
      lng: place.geometry?.location.lng(),
      address: place.formatted_address,
      address_components: place.address_components,
    };
  }

  distributionModeChanged(item: string): void {
    this.selectedDistributionMode = item;
  }

  goToSearchPage(): void {
    if (!this.selectedAddress || !this.selectedDistributionMode) {
      return;
    }
    this.shopSearchParametersService.set({
      location: this.selectedAddress,
      is_distribution_mode_defined: true,
      distribution_mode: this.selectedDistributionMode,
    });

    this.router.navigate([this.routerHelper.translateRoute(Paths.Shops)]);
  }

  openSidebarNavigation(): void {
    this.modalsService.open(SidebarNavigationModalComponent.handle);
  }

  resetValue(): void {
    this.selectedAddress = null;
  }

  scrollTo(): void {
    this.viewportScroller.scrollToAnchor('bottom-dood-hero');
  }

  navigateToDoodCorporate(): void {
    window.location.href = 'https://www.dood.com/corporate/';
  }

  private setAllowedMarketplaceDistributionsMode(): void {
    this.marketplaceSelector.selectMarketplaceDistributionModes
      .pipe(
        map(distributionModes => {
          this.distributionModeItems = ParametersUtils.mapDistributionModes(
            distributionModes.filter(item => item[DistributionModeKeys.Enabled]),
          );
        }),
      )
      .subscribe();
  }
}
