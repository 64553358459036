import { DoodProductModel, IDeclinableCategory } from '@core/models/product.model';

export class AutoFillCartItemRulesCategory {
  // ! It only works with category that have only one product, but it can be extended to work with multiple products
  // ex of rules that can be applied: Category that must have a quantity of 2 and their is:
  // - case1: only product, it must be preselected with a quantity of 2
  // - case2: 2 products that have a maxQuantity to 1, they must be preselected with a quantity of 1
  static isExclusiveProductWithAdditions({
    categoryProducts,
    category,
  }: {
    categoryProducts: DoodProductModel[];
    category: IDeclinableCategory;
  }) {
    const isProductWithAdditions = (categoryProducts[0]?.additions.products.length ?? 0) > 0;

    return this.isExclusiveCategoryProduct({ category }) && isProductWithAdditions;
  }

  static isExclusiveCategoryProduct({ category }: { category: IDeclinableCategory }) {
    const minCountAndMaxCountAreEqual = category.count === category.min_count;
    const hasExclusiveProduct = category.products.length === 1;

    return minCountAndMaxCountAreEqual && hasExclusiveProduct;
  }
}
