import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';

import { PwaService } from '@core/services/pwa/pwa.service';
import { DeviceService } from '@core/services/app/device.service';
import { ModalsService } from '@core/services/modals/modals.service';

import { ITextWithButton } from '@shared/blocks/order-status-push-item-block/order-status-push-item-block.component';
import { ModalStoreSelector } from '@common/selectors/modal.selector';

@Component({
  selector: 'app-add-home-screen-modal',
  templateUrl: './add-home-screen-modal.component.html',
})
export class AddHomeScreenModalComponent implements OnInit {
  static handle = 'add-home-screen-modal';

  @Input() textWithButtonItem?: ITextWithButton;

  index$: Observable<number> = this.modalSelector
    .selectModal(AddHomeScreenModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  isSafari!: boolean;

  constructor(
    private readonly pwaService: PwaService,
    private modalSelector: ModalStoreSelector,
    private readonly modalsService: ModalsService,
    private readonly deviceService: DeviceService,
  ) {}

  ngOnInit(): void {
    this.isSafari = this.deviceService.isSafari();
  }

  close(): void {
    this.modalsService.close(AddHomeScreenModalComponent.handle);
  }

  installPwa(): void {
    this.pwaService.promptEvent?.prompt();
    this.pwaService.promptEvent = null;
    this.close();
  }
}
