import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  Renderer2,
} from '@angular/core';

@Component({
  selector: 'app-kiosk-virtual-keyboard',
  templateUrl: './kiosk-virtual-keyboard.component.html',
})
export class KioskVirtualKeyboardComponent implements OnDestroy {
  @Input() backgroundColor?: string;
  @Input() textColor?: string;
  @Input() isFirstTouch?: boolean = true;
  @Input() isOverFooter?: boolean = false;

  @Output() keyPress = new EventEmitter<string>();
  @Output() onBlurEvent = new EventEmitter();

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    event.stopPropagation();
  }

  private documentClickListener?: () => void;

  firstclick: boolean = true;

  keyboardLayout: (string | { key: string; colSpan: number })[][] = [
    ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'],
    ['a', 'z', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
    ['q', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'm'],
    [{ key: 'Supprimer', colSpan: 2 }, 'w', 'x', 'c', 'v', 'b', 'n', { key: 'Entrer', colSpan: 2 }],
  ];

  spaceBarLayout: { key: string; colSpan: number }[] = [{ key: ' ', colSpan: 10 }];

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private cd: ChangeDetectorRef,
  ) {}

  ngAfterViewInit() {
    this.cd.detectChanges();
    this.documentClickListener = this.renderer.listen('document', 'click', event => {
      if (this.firstclick) {
        this.firstclick = false;
        return;
      }

      if (!this.el.nativeElement.contains(event.target)) {
        this.onBlurEvent.emit();
      }
    });
  }

  ngOnDestroy() {
    if (this.documentClickListener) {
      this.documentClickListener();
    }
  }

  onKeyPress(key: string) {
    this.isFirstTouch = false;
    this.keyPress.emit(key);
  }

  isSpecialKey(item: string | { key: string; colSpan: number }) {
    return typeof item !== 'string';
  }

  getKeyValue(item: string | { key: string; colSpan: number }) {
    return typeof item === 'object' ? item.key : item;
  }

  getDisplayValue(item: string | { key: string; colSpan: number }) {
    const keyValue = this.getKeyValue(item);
    return this.isFirstTouch ? keyValue.toUpperCase() : keyValue;
  }

  getAspectClass(item: string | { key: string; colSpan: number }) {
    if (typeof item === 'object' && item.key === ' ') {
      return 'aspect-[10/1]';
    } else if (typeof item === 'string') {
      return 'aspect-square';
    }
    return '';
  }

  getColSpanClass(item: string | { key: string; colSpan: number }) {
    return typeof item === 'string' ? 'col-span-1' : `col-span-${item.colSpan}`;
  }
}
