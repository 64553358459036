<form
  #cmiPaymentForm
  method="post"
  [action]="actionUrl"
>
  <ng-container *ngFor="let element of paymentElements | keyvalue">
    <input
      type="hidden"
      [name]="element.key"
      [value]="element.value"
    />
  </ng-container>
</form>
