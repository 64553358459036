<div class="max-w-screen-sm mx-auto">
  {{ marketplaceId$ | async }}
  <ng-container *ngIf="entities$ | async as entities">
    <ul>
      <li
        *ngFor="let entity of entities"
        class="flex flex-col"
      >
        <a
          [link]="'/shops' + entity.slug"
          class="p-3 border rounded mb-1 hover:bg-neutral-500 text-white bg-neutral-600"
        >
          <div class="font-brand text-2xl font-normal">
            {{ entity.name }}
          </div>
        </a>
      </li>
    </ul>
  </ng-container>
</div>
