import { takeUntil, tap } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';

import { EntityKeys } from '@config/keys/entity.keys';
import { SettingsParametersState } from '@store/settings';
import { EntityStoreSelector } from '@common/selectors/entity.selector';

import { IContentBuilderFieldImage } from '@core/models/content-builder-fields.model';
import { DoodEntityType, DoodEntityModel, DoodEntityStatus } from '@core/models/entity.model';

import { ShopsService } from '@core/services/shops/shops.service';
import { EntitiesService } from '@core/services/entities/entities.service';
import { EntityStatusService } from '@core/services/entity-status/entity-status.service';
import { ShopSearchParametersService } from '@core/services/shop-search-parameters/shop-search-parameters.service';
import { TemplateLayoutKeys } from '@config/keys/template-layout.keys';

@Component({
  selector: 'app-shop-card-block',
  templateUrl: './shop-card-block.component.html',
})
export class ShopCardBlockComponent implements OnInit, OnDestroy {
  private _destroyerRef = new Subject<boolean>();

  @Input() shop: DoodEntityModel[] = [];
  @Input() displayOpenedShop = true;
  @Input() displayClosedShop = true;
  @Input() displayEntityImage = true;
  @Input() displayEntityDistance = true;
  @Input() displayEntityPricing = true;
  @Input() displayEntityAddress = false;
  @Input() displayEntityCategory = true;
  @Input() entityNameSize = 'lg';
  @Input() entityName?: string;
  @Input() entityCategory?: string;
  @Input() image?: IContentBuilderFieldImage;
  @Input() kioskCardAspectRatio = '16/9';
  @Input() templateLayout = TemplateLayoutKeys.DefaultLayout;

  entity?: DoodEntityModel;
  shopImage?: string;
  status?: DoodEntityStatus;
  entityKeys = EntityKeys;

  isVisible$ = new BehaviorSubject<boolean>(false);

  constructor(
    readonly entitiesService: EntitiesService,
    private readonly shopsService: ShopsService,
    readonly entitySelector: EntityStoreSelector,
    private readonly entityStatusService: EntityStatusService,
    private readonly shopSearchParametersService: ShopSearchParametersService,
  ) {}

  componentFactoryIsBlockVisible$(): Observable<boolean> {
    return this.isVisible$;
  }

  ngOnInit(): void {
    if (!this.shop?.[0]?.id) {
      return;
    }

    combineLatest([
      this.entitySelector.selectEntity(this.shop[0].id),
      this.shopSearchParametersService.getParameters$(),
    ])
      .pipe(
        takeUntil(this._destroyerRef),
        tap(([entity, parameters]) => this.loadEntity(entity, parameters)),
        tap(() => this.updateIsVisible()),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
  }

  getClosedLabel(entity: DoodEntityModel): string | null {
    if (entity[EntityKeys.EntityType] === DoodEntityType.Marketplace) {
      return null;
    }
    const distributionMode = this.shopSearchParametersService.getDistributionMode();
    return this.shopsService.getClosedMessage(entity, null, distributionMode);
  }

  isShopOpened(entity: DoodEntityModel): boolean {
    const label = this.getClosedLabel(entity);
    return !label;
  }

  updateIsVisible(): void {
    if (!this.entity) {
      this.isVisible$.next(false);
      return;
    }

    const isOpen = this.isShopOpened(this.entity);
    const isVisible = this.displayOpenedShop === isOpen && this.displayClosedShop !== isOpen;
    this.isVisible$.next(isVisible);
  }

  private loadEntity(entity?: DoodEntityModel, parameters?: SettingsParametersState): void {
    let wantedAt = null;
    if (parameters?.wanted_at) {
      wantedAt = parameters?.wanted_at;
    }
    if (!wantedAt) {
      wantedAt = new Date();
    }

    this.status = this.entityStatusService.calculateStatus(
      entity,
      parameters?.distribution_mode,
      wantedAt,
    );
    this.entity = entity;
    this.shopImage = entity?.images?.cover?.url;
    // Legacy image
    if (!this.shopImage && entity?.cover) {
      this.shopImage = 'https://api.dood.com' + entity?.cover;
    }
  }
}
