import { Component, HostBinding, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';

@Component({
  selector: 'app-kiosk-promo-select-product-page',
  templateUrl: './kiosk-promo-select-product-page.component.html',
})
export class KioskPromoSelectProductPageComponent {

  @Input() header: any;
  @Input() body: any;
  @Input() footer: any;

  @Input() backgroundColor?: IContentBuilderFieldColor;
  @Input() textColor?: IContentBuilderFieldColor;
  @Input() link?: string;
  
  @HostBinding('class') classes = 'h-screen flex';

  constructor(
    private readonly router: Router,
    private readonly routerHelper: RouterHelperService,
  ) {
  }

  ngOnInit(): void {
  }

  getBackgroundStyles() {
    return {
        'background-color': this.backgroundColor ? this.backgroundColor : null,
    };
  }

  back(): void {
    this.router.navigate([this.routerHelper.translateRoute(`/cart`)]);
  }

}
