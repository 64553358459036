import { Injectable } from '@angular/core';
import { PlaceResult } from '@core/models/google-maps.model';

@Injectable({ providedIn: 'root' })
export class GooglePlacesState {
  private _value: PlaceResult | null = null;

  get value(): PlaceResult | null {
    return this._value;
  }

  update(place: PlaceResult): void {
    this._value = place;
  }

  reset(): void {
    this._value = null;
  }
}
