import { Observable, Subject } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { catchError, map, take, takeUntil, tap } from 'rxjs/operators';

import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { ModalScrollBlockBase } from '@core/base/modalScrollBlock/modal-scroll-block.base';

import { ModalsService } from '@core/services/modals/modals.service';
import { ZerosixService } from '@core/services/zerosix/zerosix.service';
import { AuthFirebaseService } from '@core/services/api/auth-firebase/auth-firebase.service';

import { AuthenticationModalComponent } from '@shared/modals/authentication-modal/authentication-modal.component';
import { ZerosixLinkAccountModalComponent } from '@shared/modals/zerosix/zerosix-link-account-modal/zerosix-link-account-modal.component';
import { ZerosixCreateAccountModalComponent } from '@shared/modals/zerosix/zerosix-create-account-modal/zerosix-create-account-modal.component';

@Component({
  selector: 'app-zerosix-create-or-link-account-modal',
  templateUrl: './zerosix-create-or-link-account-modal.component.html',
})
export class ZerosixCreateOrLinkAccountModalComponent
  extends ModalScrollBlockBase
  implements OnInit, OnDestroy
{
  private _destroyerRef = new Subject<boolean>();

  static handle = 'zerosix-create-or-link-account-modal';

  isDesktop$ = this.settingsSelector.selectDeviceIsDesktopScreen;
  index$: Observable<number> = this.modalSelector
    .selectModal(ZerosixCreateOrLinkAccountModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  isLoading = true;
  accountExists = false;

  constructor(
    private modalSelector: ModalStoreSelector,
    private readonly modalsService: ModalsService,
    private readonly zerosixService: ZerosixService,
    private settingsSelector: SettingsStoreSelector,
    private readonly authFirebaseService: AuthFirebaseService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.authFirebaseService
      .isAuthenticate$()
      .pipe(
        take(1),
        map(v => !!v),
        tap(connected => {
          if (!connected) {
            this.modalsService.close(ZerosixCreateOrLinkAccountModalComponent.handle);
            this.modalsService.open(AuthenticationModalComponent.handle);
            return;
          } else {
            this.checkIfAccountAlreadyExists();
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
    super.ngOnDestroy();
  }

  close(): void {
    this.modalsService.close(ZerosixCreateOrLinkAccountModalComponent.handle);
  }

  openCreateLoyaltyAccountModal(): void {
    this.close();
    this.modalsService.open(ZerosixCreateAccountModalComponent.handle);
  }

  openLinkWithLoyaltyAccountModal(): void {
    this.close();
    this.modalsService.open(ZerosixLinkAccountModalComponent.handle);
  }

  private checkIfAccountAlreadyExists(): void {
    this.zerosixService
      .isUserZeroSixCardEnabledOnMarketplaceLevel$()
      .pipe(
        takeUntil(this._destroyerRef),
        tap(accountExists => {
          if (!accountExists) {
            this.isLoading = false;
            this.accountExists = false;
          } else {
            this.checkIfAccountStillExistsOnZerosix();
          }
        }),
      )
      .subscribe();
  }

  private checkIfAccountStillExistsOnZerosix(): void {
    this.zerosixService
      .getCard$()
      .pipe(
        takeUntil(this._destroyerRef),
        tap(card => {
          this.isLoading = false;
          this.accountExists = true;
        }),
        catchError(error => {
          this.isLoading = false;
          this.accountExists = false;
          return error;
        }),
      )
      .subscribe();
  }
}
