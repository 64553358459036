<div class="mx-auto max-w-md px-5 lg:max-w-full lg:px-0">
  <div class="mb-3">
    <app-comment-cart-atom
      [text]="'basket.additonnal-informations.comment-for-the-shop' | translate"
      [comment]="(message$ | async) ?? ''"
      [icon]="'icon-comment-2'"
      [headingColor]="headingColor"
      [textColor]="textColor"
      (click)="openInputComment('merchant')"
      (updadeComment)="openInputComment('merchant')"
      (removeComment)="onRemoveComment('merchant')"
    ></app-comment-cart-atom>
  </div>
  <!-- <div
    class="mb-3"
    *ngIf="
      (selectedDistribution$ | async) === orderTypeValues.Delivery ||
      (selectedDistribution$ | async) === orderTypeValues.Shipping
    "
  >
    <app-comment-cart-atom
      [text]="'basket.additonnal-informations.comment-for-the-deliverer' | translate"
      [comment]="(deliveryComment$ | async) ?? ''"
      [icon]="'icon-comment-2'"
      [headingColor]="headingColor"
      [textColor]="textColor"
      (click)="openInputComment('delivery')"
      (updadeComment)="openInputComment('delivery')"
      (removeComment)="onRemoveComment('delivery')"
    ></app-comment-cart-atom>
  </div> -->
  <div
    class="mb-3 cursor-pointer"
    *ngIf="(selectedDistribution$ | async) === orderTypeValues.Delivery"
  >
    <app-user-information-cart-atom
      [value]="(deliveryAddress$ | async) ?? ''"
      [instructions]="(instructions$ | async) ?? ''"
      [text]="'order.delivery-location' | translate"
      [icon]="'icon-home'"
      [headingColor]="headingColor"
      [textColor]="textColor"
      (click)="openCartParameters()"
    ></app-user-information-cart-atom>
  </div>
  <div
    class="mb-3 cursor-pointer"
    *ngIf="
      (selectedDistribution$ | async) === orderTypeValues.Delivery ||
      (selectedDistribution$ | async) === orderTypeValues.Shipping
    "
  >
    <app-user-information-cart-atom
      [value]="(contactPhone$ | async) ?? ''"
      [text]="'basket.additonnal-informations.phone-number' | translate"
      [icon]="'icon-phone'"
      [error]="(missingPhone$ | async) ?? false"
      [errorText]="'basket.please-fill-phone-number' | translate"
      [headingColor]="headingColor"
      [textColor]="textColor"
      (click)="openInputUserInformation()"
    ></app-user-information-cart-atom>
  </div>
</div>
