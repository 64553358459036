import { Injectable } from '@angular/core';

import { AuthStoreDispatcher } from '@common/dispatchers/authentication.dispatcher';
import { DoodAuthenticationStep } from '@store/authentication/authentication.model';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationScreenService {
  constructor(private authDispatcher: AuthStoreDispatcher) {}

  goToStep(step: DoodAuthenticationStep | null): void {
    this.authDispatcher.updateStatus({ step });
  }
}
