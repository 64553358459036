import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-chip-atom',
  templateUrl: './chip-atom.component.html',
})
export class ChipAtomComponent<T> {
  @Input() id?: string;
  @Input() label!: string;
  @Input() value!: T;
  @Input() color: 'neutral' | 'success' | 'primary' | 'info' | 'highligh' = 'primary';

  @Output() removeChip = new EventEmitter<{
    id?: string;
    value: T;
  }>();
}
