import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';

import { ModalsService } from '@core/services/modals/modals.service';
import { NativeService } from '@core/services/native/native.service';

import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';

import { ModalStoreDispatcher } from '@common/dispatchers/modal.dispatcher';
import { ModalScrollBlockBase } from '@core/base/modalScrollBlock/modal-scroll-block.base';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
})
export class ConfirmModalComponent extends ModalScrollBlockBase implements OnInit {
  static handle = 'confirm-modal';

  static backgroundColor?: IContentBuilderFieldColor = {
    value: '#FFFFFF',
    type: ColorFieldTypesValues.Hex
  };
  static textColor?: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Hex
  };
  static buttonBackgroundColor?: IContentBuilderFieldColor = undefined;
  static buttonTextColor?: IContentBuilderFieldColor = undefined;

  get backgroundColor(): IContentBuilderFieldColor | undefined {
    return ConfirmModalComponent.backgroundColor;
  }

  get textColor(): IContentBuilderFieldColor | undefined {
    return ConfirmModalComponent.textColor;
  }

  get buttonBackgroundColor(): IContentBuilderFieldColor | undefined {
    return ConfirmModalComponent.buttonBackgroundColor;
  }

  get buttonTextColor(): IContentBuilderFieldColor | undefined {
    return ConfirmModalComponent.buttonTextColor;
  }

  index$: Observable<number> = this.modalSelector
    .selectModal(ConfirmModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  data$: Observable<any> = this.modalSelector
    .selectModal(ConfirmModalComponent.handle)
    .pipe(map(el => (el?.data ? el.data : {})));

  isMobile$ = this.settingsSelector.selectDeviceIsMobileScreen;

  isNotched = false;

  isLoading = false;

  constructor(
    private modalSelector: ModalStoreSelector,
    private modalDispatcher: ModalStoreDispatcher,
    private readonly modalsService: ModalsService,
    private settingsSelector: SettingsStoreSelector,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.isNotched = await NativeService.isNotched();
  }

  close(): void {
    this.modalsService.close(ConfirmModalComponent.handle);
  }

  submit(): void {
    this.isLoading = true;
    this.modalDispatcher.mapModal({
      id: ConfirmModalComponent.handle,
      map: _modal => {
        return {
          ..._modal,
          data: {
            ..._modal.data,
            isConfirm: true,
          },
        };
      },
    });
  }
}
