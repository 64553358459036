import { from } from 'rxjs';
import { take } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ISimpleItem } from '@core/models/simple-item.model';
import { NativeService } from '@core/services/native/native.service';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-open-parameters-atom',
  templateUrl: './open-parameters-atom.component.html',
})
export class OpenParametersAtomComponent implements OnInit {
  isNotched = false;

  @Input() items!: ISimpleItem[];
  @Input() textColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() iconBackgroundColor: IContentBuilderFieldColor = {
    value: 'primary-100',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() iconColor: IContentBuilderFieldColor = {
    value: 'primary-600',
    type: ColorFieldTypesValues.Palette,
  };
  @Output() clickItem = new EventEmitter();
  @Output() clickAction = new EventEmitter();

  ngOnInit(): void {
    from(NativeService.isNotched())
      .pipe(take(1))
      .subscribe(isNotched => {
        this.isNotched = isNotched;
      });
  }
}
