<!-- TODO: Add translation here -->
<div
  class="px-5 pb-3 font-base lg:px-0"
  [style.color]="textColor | contentBuilderColor"
>
  <div
    *ngIf="deliveryAmount"
    class="flex justify-between border-b border-neutral-300 py-3"
  >
    <div class="text-sm font-normal leading-snug">
      {{ 'basket.delivery-costs' | translate }}
    </div>
    <div class="flex flex-row items-center justify-center">
      <div
        class="icon icon-bike pl-4"
        [style.color]="secondaryTextColor | contentBuilderColor"
      ></div>
      <div class="w-20 text-right text-sm leading-5">
        <app-price-atom
          [bold]="false"
          [amount]="deliveryAmount"
          [format]="true"
        ></app-price-atom>
      </div>
    </div>
  </div>
  <div
    *ngIf="tips"
    class="flex justify-between border-b border-neutral-300 py-3"
  >
    <div class="text-sm font-normal leading-snug">
      {{ 'basket.additonnal-informations.tip' | translate }}
    </div>
    <div class="flex flex-row items-center justify-center">
      <div
        class="icon icon-satisfied pl-4"
        [style.color]="secondaryTextColor | contentBuilderColor"
      ></div>
      <div class="w-20 text-right text-sm leading-5">
        <app-price-atom
          [bold]="false"
          [amount]="tips"
          [format]="true"
        ></app-price-atom>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!isBasketExist">
    <div
      *ngFor="let coupon of coupons"
      class="flex justify-between border-b border-neutral-300 py-3"
      [class.opacity-50]="coupon.removeInProgress"
    >
      <div class="text-sm font-normal leading-snug lg:text-base">
        <div>
          <ng-container *ngIf="coupon.name">
            {{ coupon.name }}
          </ng-container>
          <ng-container *ngIf="!coupon.name">
            {{ 'basket.discount-voucher' | translate }} «{{ coupon.code }}»
          </ng-container>
        </div>
        <div
          *ngIf="coupon.type === 'ERROR'"
          class="text-xs text-error-800"
        >
          <ng-container *ngIf="coupon.error">
            {{ 'voucher-modal.voucher-not-available' | translate }}
          </ng-container>
          <ng-container *ngIf="!coupon.error">
            {{ 'basket.invalid-voucher' | translate }}
          </ng-container>
        </div>
        <div>
          <a
            (click)="onRemoveCoupon(coupon)"
            class="cursor-pointer text-primary-600"
          >
            {{ 'basket.delete' | translate }}
          </a>
        </div>
      </div>
      <div class="flex flex-row items-center justify-end">
        <div
          class="icon icon-coupon-3 pl-4"
          [style.color]="secondaryTextColor | contentBuilderColor"
          *ngIf="coupon.type !== 'ERROR'"
        ></div>
        <div
          class="icon icon-warning-circle pl-4 text-error-800"
          *ngIf="coupon.type === 'ERROR'"
        ></div>
        <div
          class="w-20 text-right text-sm leading-5"
          *ngIf="coupon.type !== 'ERROR'"
        >
          –
          <app-price-atom
            [bold]="false"
            [amount]="coupon.amount_off"
            [format]="true"
          ></app-price-atom>
        </div>
      </div>
    </div>
  </ng-container>
  <div
    *ngIf="usePointsAmount > 0"
    class="flex justify-between border-b border-neutral-300 py-3"
  >
    <div class="text-sm font-normal leading-snug">
      <div>{{ 'basket.loyalty-credit' | translate }}</div>
      <div>
        <a
          (click)="removeUsePoints.emit()"
          class="text-primary-800"
        >
          {{ 'basket.remove' | translate }}
        </a>
      </div>
    </div>
    <span class="text-sm leading-5"
      >-<app-price-atom
        [bold]="false"
        [amount]="usePointsAmount"
        [format]="true"
      ></app-price-atom
    ></span>
  </div>
  <div
    class="flex justify-between py-3"
    [style.color]="headingColor | contentBuilderColor"
  >
    <span class="font-accent text-md font-bold leading-6">Total</span>
    <span class="font-accent text-base font-bold leading-6"
      ><app-price-atom
        [amount]="totalAmount"
        [format]="true"
      ></app-price-atom
    ></span>
  </div>
</div>
