<div
  class="py-5 px-4.5 border-neutral-400 border rounded rounded-s cursor-pointer"
  [style.color]="headingColor | contentBuilderColor"
>
  <div class="flex items-center">
    <i class="icon icon-coupon mr-3"></i>
    <span class="font-base font-bold lg:text-md">{{
      'basket.additonnal-informations.discount-voucher' | translate
    }}</span>
  </div>
  <div
    *ngIf="voucher"
    class="flex justify-between"
  >
    <span
      >{{ 'basket.additonnal-informations.discount-voucher' | translate }}
      {{ voucher }}
      {{ 'basket.additonnal-informations.applied' | translate }}</span
    >
    <i class="icon icon-check-plain text-success-600"></i>
  </div>
  <div
    *ngIf="voucher"
    class="flex"
  >
    <a
      (click)="$event.stopPropagation(); removeVoucher.emit()"
      class="mr-2 font-base font-bold text-sm text-primary-600 cursor-pointer"
      >{{ 'basket.delete' | translate }}</a
    >
  </div>
</div>
