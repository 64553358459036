<div
  class="fixed mx-auto h-full w-full md:bottom-0 md:left-0 md:flex md:flex-col md:items-center md:justify-center mdMax:bottom-0 mdMax:left-0 mdMax:right-0 mdMax:top-0"
  [style.z-index]="110"
>
  <app-modal-filler-atom
    class="hidden md:block"
    (click)="close($event)"
    [withBackdrop]="true"
    [index]="index"
  ></app-modal-filler-atom>
  <div
    class="fixed mx-auto h-full w-full md:bottom-0 md:left-0 md:flex md:flex-col md:items-center md:justify-center mdMax:bottom-0 mdMax:left-0 mdMax:right-0 mdMax:top-0"
    [style.z-index]="index + 100"
  >
    <app-modal-filler-atom
      class="hidden md:block"
      [withBackdrop]="false"
      (click)="close($event)"
      [index]="index"
    ></app-modal-filler-atom>
    <div
      class="flex h-screen flex-col overflow-y-auto bg-white pb-36 scrollbar-hide md:relative md:my-20 md:h-auto md:max-h-screen md:w-full md:max-w-screen-sm md:rounded-3xl md:pb-0 kiosk:md:max-h-[90vh] kiosk:md:max-w-[90vw]"
      [style.z-index]="index + 50"
      [style.backgroundColor]="backgroundColor | contentBuilderColor"
      [style.color]="textColor | contentBuilderColor"
      style="max-height: 1021px !important"
    >
      <ng-container *ngIf="product?.id">
        <header
          class="relative flex w-full justify-center md:rounded-t-3xl small-desktop-height:hidden"
          [class.py-8]="!product.images"
          [class.bg-primary-300]="product.images"
        >
          <img
            *ngIf="product.images?.cover?.url"
            [src]="product.images?.cover?.url"
            class="panel-image h-64 max-h-64 min-h-64 object-cover md:h-96 md:max-h-96 md:min-h-96 md:rounded-t-3xl"
            loading="lazy"
          />
          <app-rounded-icon-button
            class="absolute right-5 top-5"
            [class.top-8]="isNotched"
            [icon]="'icon-cross'"
            [size]="'sm'"
            [type]="'normal'"
            [overrideBackgroundColor]="buttonBackgroundColor"
            [overrideColor]="buttonTextColor"
            (click)="close($event)"
          ></app-rounded-icon-button>
        </header>

        <header
          class="relative flex hidden w-full md:rounded-t-3xl small-desktop-height:flex"
          [class.py-8]="!product.images"
        >
          <img
            *ngIf="product.images?.cover?.url"
            [src]="product.images?.cover?.url"
            class="m-4 h-40 w-40 rounded-2xl object-cover"
            loading="lazy"
          />
          <app-rounded-icon-button
            class="absolute right-5 top-5"
            [class.top-8]="isNotched"
            [icon]="'icon-cross'"
            [size]="'sm'"
            [type]="'normal'"
            [overrideBackgroundColor]="buttonBackgroundColor"
            [overrideColor]="buttonTextColor"
            (click)="close($event)"
          ></app-rounded-icon-button>
          <h1
            class="m-4 mr-20 flex items-center break-words font-accent text-2xl font-bold leading-8xs"
            [style.color]="textColor ?? headingColor | contentBuilderColor"
          >
            {{ product[productKeys.Name] }}
          </h1>
        </header>

        <div class="flex flex-col px-5 py-4 text-neutral-800 lg:pb-4">
          <h1
            class="mb-3 break-words font-accent text-2xl font-bold leading-8xs small-desktop-height:hidden"
            [style.color]="textColor ?? headingColor | contentBuilderColor"
          >
            {{ product[productKeys.Name] }}
          </h1>
          <p
            *ngIf="product[productKeys.Description]"
            class="mb-3 whitespace-pre-line font-base text-base leading-snug"
            [style.color]="textColor ?? descriptionColor | contentBuilderColor"
          >
            {{ product[productKeys.Description] }}
          </p>
          <div
            *ngIf="displayPrice"
            class="mb-3 font-accent text-sm font-bold"
          >
            <ng-container *ngIf="product[productKeys.PriceToDisplay]; else priceTemplate">
              <p
                class="p-1.5 pl-0 font-base font-normal lining-nums tabular-nums"
                [style.color]="textColor ?? priceColor | contentBuilderColor"
              >
                {{ product[productKeys.PriceToDisplay] }}
              </p>
            </ng-container>
            <ng-template #priceTemplate>
              <ng-container
                *ngIf="
                  product[productKeys.Discount] && !product[productKeys.PriceToDisplay];
                  else priceOnly
                "
              >
                <app-price-atom
                  class="p-1.5 pl-0"
                  [style.color]="textColor ?? priceColor | contentBuilderColor"
                  [amount]="product[productKeys.Discount] ?? 0"
                  [bold]="false"
                >
                </app-price-atom>
                <app-tag-atom
                  *ngIf="product[productKeys.Price] > 0"
                  class="p-1.5"
                  color="success"
                  text="{{
                    product[productKeys.Price]
                      | discountPercent: forceType(product[productKeys.Discount])
                  }}"
                >
                </app-tag-atom>
                <app-price-atom
                  *ngIf="product[productKeys.Price] > 0"
                  class="p-1.5"
                  [style.color]="textColor ?? priceColor | contentBuilderColor"
                  [amount]="product[productKeys.Price]"
                  [discount]="true"
                  [bold]="false"
                ></app-price-atom>
              </ng-container>
              <ng-template #priceOnly>
                <app-price-atom
                  class="p-1.5 pl-0"
                  [style.color]="textColor ?? priceColor | contentBuilderColor"
                  [amount]="product[productKeys.Price]"
                  [bold]="false"
                >
                </app-price-atom>
              </ng-template>
            </ng-template>
          </div>
          <ul class="flex flex-wrap pb-3">
            <li
              class="py-1 pl-0 pr-3"
              *ngFor="let label of visibleLabels(product[productKeys.Labels])"
            >
              <app-tag-atom
                [text]="label.name"
                [lineThrough]="label.line_through"
                [bold]="false"
              ></app-tag-atom>
            </li>
          </ul>
          <div
            class="mb-2"
            [style.color]="textColor ?? descriptionColor | contentBuilderColor"
            *ngIf="product[productKeys.Allergens]?.length"
          >
            <label class="font-base text-base leading-snug"
              >{{ 'product-add-modal.allergens' | translate }} :
            </label>
            <ul class="inline-block">
              <li
                class="mr-1 inline-block"
                *ngFor="let allergen of product[productKeys.Allergens]; let i = index"
              >
                {{ 'allergens.' + allergen.name | translate }}
                <span *ngIf="i === (product[productKeys.Allergens]?.length ?? 0) - 1">.</span>
                <span *ngIf="i !== (product[productKeys.Allergens]?.length ?? 0) - 1">,</span>
              </li>
            </ul>
          </div>
          <div
            *ngIf="orderedAdditionGroups?.length"
            [style.color]="textColor ?? descriptionColor | contentBuilderColor"
            class="pb-60 pt-5"
          >
            <div
              *ngFor="let additionGroup of orderedAdditionGroups"
              class="pb-8"
            >
              <h2 class="font-accent text-md font-bold">
                {{ additionGroup[additionGroupKeys.Name] }}
              </h2>
              @if (!hideAdditionGroupDetails) {
                <p
                  class="font-base text-base font-normal"
                  [style.color]="textColor ?? inactiveStateColor | contentBuilderColor"
                >
                  {{
                    additionGroupTitle(
                      additionGroup[additionGroupKeys.MinCount],
                    forceType(additionGroup[additionGroupKeys.MaxCount])
                    )
                  }}
                </p>
              }

              <div
                *ngFor="let addition of additionGroup[additionGroupKeys.Items]"
                class="flex justify-between py-2 align-middle"
                [ngClass]="{
                  'opacity-40': getMaxAdditionCount(additionGroup, addition) === 0,
                }"
              >
                <div
                  *ngIf="addition && addition[productKeys.Name]"
                  class="flex items-center"
                >
                  <div
                    class="flex flex-row items-center"
                    [ngClass]="{
                      'opacity-50': !addition[productKeys.Available],
                    }"
                  >
                    <input
                      name="{{ addition[productKeys.Name] }}"
                      type="checkbox"
                      [disabled]="
                        getMaxAdditionCount(additionGroup, addition) === 0 ||
                        !addition[productKeys.Available]
                      "
                      [(ngModel)]="addition[productKeys.IsChecked]"
                      (change)="selectAddition(addition)"
                      #checkbox
                      class="form-checkbox mr-4 h-6 w-6 border text-primary-600"
                    />
                    <div
                      (click)="
                        addition[productKeys.IsChecked] = !addition[productKeys.IsChecked];
                        selectAddition(addition)
                      "
                    >
                      <p>{{ addition[productKeys.Name] }}</p>
                      <div
                        *ngIf="addition[productKeys.Images]?.thumbnail?.url"
                        class="flex items-center space-x-4"
                      >
                        <app-counter-atom
                          *ngIf="
                            (!addition[productKeys.MaxCount] ||
                              (addition[productKeys.MaxCount] ?? 0) > 1) &&
                            addition[productKeys.IsChecked]
                          "
                          [size]="'S'"
                          [min]="
                            forceType(
                              addition[productKeys.MinCount] ? addition[productKeys.MinCount] : 1
                            )
                          "
                          [max]="getMaxAdditionCount(additionGroup, addition)"
                          [backgroundColor]="forceType(buttonBackgroundColor)"
                          [textColor]="forceType(buttonTextColor)"
                          [count]="
                            forceType(
                              addition[productKeys.Quantity]
                                ? addition[productKeys.Quantity]
                                : addition[productKeys.MinCount]
                            )
                          "
                          (countChange)="
                            changeNbAddition($event, addition[productKeys.Id], addition)
                          "
                        >
                        </app-counter-atom>
                        <span
                          class="text-right"
                          *ngIf="
                            addition &&
                            (addition[productKeys.Price] < 0 || addition[productKeys.Price] > 0)
                          "
                          [ngClass]="{
                            'opacity-50': !addition[productKeys.IsChecked],
                          }"
                        >
                          <app-price-atom
                            [amount]="addition[productKeys.Price]"
                            [discount]="!!addition[productKeys.Discount]"
                            [displayPlus]="true"
                            [style.color]="priceColor | contentBuilderColor"
                          >
                          </app-price-atom>
                        </span>
                        <span
                          *ngIf="addition[productKeys.Discount]"
                          class="ml-1 text-right"
                        >
                          <app-price-atom
                            [amount]="addition[productKeys.Discount] ?? 0"
                            [displayPlus]="true"
                            [style.color]="priceColor | contentBuilderColor"
                          ></app-price-atom>
                        </span>
                      </div>
                    </div>
                    <span
                      class="ml-1.5"
                      *ngIf="!addition[productKeys.Available]"
                      >- {{ 'product-add-modal.unavailable' | translate }}</span
                    >
                  </div>
                </div>
                <div
                  *ngIf="!addition[productKeys.Images]?.thumbnail?.url"
                  class="flex items-center space-x-4"
                >
                  <app-counter-atom
                    *ngIf="
                      (!addition[productKeys.MaxCount] ||
                        (addition[productKeys.MaxCount] ?? 0) > 1) &&
                      addition[productKeys.IsChecked]
                    "
                    [size]="'S'"
                    [min]="
                      forceType(addition[productKeys.MinCount] ? addition[productKeys.MinCount] : 1)
                    "
                    [max]="getMaxAdditionCount(additionGroup, addition)"
                    [backgroundColor]="forceType(buttonBackgroundColor)"
                    [textColor]="forceType(buttonTextColor)"
                    [count]="
                      forceType(
                        addition[productKeys.Quantity]
                          ? addition[productKeys.Quantity]
                          : addition[productKeys.MinCount]
                      )
                    "
                    (countChange)="changeNbAddition($event, addition[productKeys.Id], addition)"
                  >
                  </app-counter-atom>
                  <span
                    class="text-right"
                    *ngIf="
                      addition &&
                      (addition[productKeys.Price] < 0 || addition[productKeys.Price] > 0)
                    "
                    [ngClass]="{
                      'opacity-50': !addition[productKeys.IsChecked],
                    }"
                    [style.color]="priceColor | contentBuilderColor"
                  >
                    <app-price-atom
                      [amount]="addition[productKeys.Price]"
                      [discount]="!!addition[productKeys.Discount]"
                      [displayPlus]="true"
                    >
                    </app-price-atom>
                  </span>
                  <span
                    *ngIf="addition[productKeys.Discount]"
                    class="ml-1 text-right"
                    [ngClass]="{
                      'opacity-50': !addition[productKeys.IsChecked],
                    }"
                  >
                    <app-price-atom
                      [displayPlus]="true"
                      [amount]="addition[productKeys.Discount] ?? 0"
                    ></app-price-atom>
                  </span>
                </div>
                <div
                  *ngIf="addition[productKeys.Images]?.thumbnail?.url"
                  class="height-fit-content relative bg-neutral-50"
                >
                  <img
                    *ngIf="addition[productKeys.Images]?.thumbnail?.url"
                    class="push-image h-full rounded rounded-lg object-cover"
                    [src]="addition[productKeys.Images]?.thumbnail?.url"
                    alt="image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="product[productKeys.Type] === productTypeValues.Declinable">
          <div
            class="pb-60"
            #declinableOptionsElement
            *ngIf="compoundItems && compoundItems.length"
          >
            <div
              class="border-t border-neutral-300 px-5 last:border-b"
              *ngFor="let item of compoundItems"
            >
              <app-compound-product-item-atom
                [name]="item.name"
                [description]="item.description"
                [minCount]="item.min_count"
                [products]="forceType(item.selected)"
                [count]="item.count"
                [isValid]="forceType(item.isValid)"
                [storeId]="item.store_id"
                [headingColor]="headingColor"
                [descriptionColor]="descriptionColor"
                [inactiveStateColor]="inactiveStateColor"
                [hideAdditionGroupDetails]="hideAdditionGroupDetails"
                (openCompound)="openCompound.emit(item.store_id)"
              ></app-compound-product-item-atom>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!product?.id">
        <div class="w-full flex-grow md:max-w-screen-sm">
          <div class="h-60 w-full animate-pulse bg-neutral-100"></div>
          <div class="w-full px-5 py-5">
            <p class="mb-3 h-8 w-8/12 animate-pulse rounded bg-neutral-200"></p>
            <p class="mb-3 h-5 w-11/12 animate-pulse rounded bg-neutral-50"></p>
            <p class="mb-3 h-5 w-6/12 animate-pulse rounded bg-neutral-50"></p>
          </div>
        </div>
      </ng-container>
      <footer
        class="absolute bottom-0 mx-auto w-full bg-white px-5 py-4 shadow-top md:sticky md:flex md:items-center md:gap-6 md:px-6"
        *ngIf="allowAddToCart"
        [class.pb-6]="isNotched"
        [style.backgroundColor]="backgroundColor | contentBuilderColor"
      >
        <div class="flex flex-col gap-3 mdMax:pb-4">
          <div
            class="flex justify-center"
            [style.color]="textColor ?? descriptionColor | contentBuilderColor"
          >
            <app-counter-atom
              [type]="'light'"
              [size]="'L'"
              [min]="1"
              [max]="forceType(maxProduct)"
              [backgroundColor]="forceType(buttonBackgroundColor)"
              [textColor]="forceType(buttonTextColor)"
              [(count)]="quantity"
              (countChange)="changeNbProduct($event)"
            ></app-counter-atom>
          </div>
          <div
            *ngIf="isEdit"
            class="flex justify-center"
          >
            <app-button-atom
              [label]="'basket.delete' | translate"
              [size]="'S'"
              [type]="'light'"
              [overrideBgColor]="buttonBackgroundColor"
              [overrideTextColor]="buttonTextColor"
              [color]="'neutral'"
              [icon]="'icon-garbage'"
              (click)="removeProduct.emit(product[productKeys.Id])"
            ></app-button-atom>
          </div>
        </div>
        <app-button-atom
          *ngIf="
            !!shopOpensAt === false &&
            product[productKeys.Type] === productTypeValues.Declinable &&
            (!compoundItems || !compoundItems.length || isButtonDisable)
          "
          [type]="'primary'"
          [size]="'L'"
          [lookDisabled]="true"
          [overrideBgColor]="buttonBackgroundColor"
          [overrideTextColor]="buttonTextColor"
          class="md:flex-auto"
          [label]="
            ('product-add-modal.add' | translate) +
            ' ' +
            quantity +
            ' ' +
            ('product-add-modal.to-basket' | translate)
          "
          (clickEventEmitter)="scrollToOptions()"
        >
        </app-button-atom>
        <app-button-atom
          *ngIf="
            !!shopOpensAt === false &&
            product[productKeys.Type] === productTypeValues.Declinable &&
            !(!compoundItems || !compoundItems.length || isButtonDisable)
          "
          [disabled]="!compoundItems || !compoundItems.length || isButtonDisable"
          [type]="'primary'"
          [size]="'L'"
          class="md:flex-auto"
          [overrideBgColor]="buttonBackgroundColor"
          [overrideTextColor]="buttonTextColor"
          [label]="
            ('product-add-modal.add' | translate) +
            ' ' +
            quantity +
            ' ' +
            ('product-add-modal.to-basket' | translate)
          "
          (clickEventEmitter)="addToCart($event)"
        >
        </app-button-atom>
        <app-button-atom
          *ngIf="
            !!shopOpensAt === false && product[productKeys.Type] !== productTypeValues.Declinable
          "
          [disabled]="isButtonDisable"
          [type]="'primary'"
          [size]="'L'"
          class="md:flex-auto"
          [overrideBgColor]="buttonBackgroundColor"
          [overrideTextColor]="buttonTextColor"
          [label]="
            ('product-add-modal.add' | translate) +
            ' ' +
            quantity +
            ' ' +
            ('product-add-modal.to-basket' | translate)
          "
          (clickEventEmitter)="addToCart($event)"
        >
        </app-button-atom>
        <app-button-atom
          *ngIf="!!shopOpensAt"
          [disabled]="true"
          [type]="'primary'"
          [size]="'L'"
          [overrideBgColor]="buttonBackgroundColor"
          [overrideTextColor]="buttonTextColor"
          class="md:flex-auto"
          [label]="shopOpensAt"
        >
        </app-button-atom>
      </footer>
    </div>
  </div>
</div>
