import { Router } from '@angular/router';
import { map, take, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, from, Observable, Subject, timer } from 'rxjs';

import { Paths } from '@config/paths.config';
import { ShopStoreRefiner } from '@common/refiners/shop.refiner';
import { DistributionModeValues } from '@config/values/order.values';

import { OrderKeys } from '@config/keys/order.keys';
import { DistributionModeKeys, MarketplaceKeys } from '@config/keys/shop.keys';

import { CartStoreRefiner } from '@common/refiners/cart.refiner';
import { CartStoreSelector } from '@common/selectors/cart.selector';
import { OrderStoreSelector } from '@common/selectors/order.selector';
import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

import { NativeService } from '@core/services/native/native.service';
import { HeaderService } from '@core/services/header/header.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { RouterHelperService } from 'src/app/core/services/router-helper/router-helper.service';

import { CartParametersModalComponent } from '@shared/modals/cart-parameters-modal/cart-parameters-modal.component';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-cart-overview-modal',
  templateUrl: './cart-overview-modal.component.html',
})
export class CartOverviewModalComponent implements OnInit, OnDestroy {
  static handle = 'cart-overview-modal';

  @Input() goToCartButtonText = 'Go to cart';
  @Input() backgroundColor: IContentBuilderFieldColor = {
    value: '#FFFFFF',
    type: ColorFieldTypesValues.Hex,
  };
  @Input() labelColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() iconColor: IContentBuilderFieldColor = {
    value: 'primary-600',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() iconBackgroundColor: IContentBuilderFieldColor = {
    value: 'primary-100',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() buttonBackgroundColor: IContentBuilderFieldColor = {
    value: 'primary-600',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() buttonTextColor: IContentBuilderFieldColor = {
    value: '#FFFFFF',
    type: ColorFieldTypesValues.Hex,
  };

  private readonly destroyed$ = new Subject<boolean>();

  cart$ = this.cartSelector.selectActive;
  order$ = this.orderSelector.selectValidation;
  cartProducts$ = this.cartRefiner.selectActiveCartProducts;

  isNotched = false;

  orderKeys = OrderKeys;
  isNavigationToCartInProgress = false;

  hideCartParameters$: Observable<boolean> = combineLatest([
    this.shopRefiner.selectActiveShopEnabledDistributionMode,
    this.marketplaceSelector.selectMarketplace,
  ]).pipe(
    map(([shopDistributionModes, marketplace]) => {
      const isMutiShop = marketplace[MarketplaceKeys.CartScope] === 'MARKETPLACE';

      const distributionModes = isMutiShop
        ? marketplace[MarketplaceKeys.DistributionModes]?.filter(
            mode => mode?.[DistributionModeKeys.Enabled],
          )
        : shopDistributionModes;

      return (
        distributionModes?.length === 1 &&
        distributionModes[0][DistributionModeKeys.Type] === DistributionModeValues.EatIn
      );
    }),
  );

  constructor(
    private readonly router: Router,
    private cartRefiner: CartStoreRefiner,
    private shopRefiner: ShopStoreRefiner,
    private cartSelector: CartStoreSelector,
    private orderSelector: OrderStoreSelector,
    private modalSelector: ModalStoreSelector,
    private readonly modalsService: ModalsService,
    private readonly headerService: HeaderService,
    private settingsSelector: SettingsStoreSelector,
    private readonly routerHelper: RouterHelperService,
    private readonly translateService: TranslateService,
    private marketplaceSelector: MarketplaceStoreSelector,
  ) {}

  ngOnInit(): void {
    // TODO: Replace with constant
    this.modalSelector
      .selectModal('authentication-modal')
      .pipe(
        takeUntil(this.destroyed$),
        map(modal => {
          this.isNavigationToCartInProgress = !!modal;
        }),
      )
      .subscribe();

    timer(0).subscribe(() => {
      this.headerService.toggleCartParametersVisibility(true);
      this.headerService.toggleCartButtonVisibility(true);
    });

    from(NativeService.isNotched())
      .pipe(take(1))
      .subscribe(_isNotched => {
        this.isNotched = _isNotched;
      });

    const cart = this.cartSelector.active;
    const parameters = this.settingsSelector.parameters;

    if (
      cart &&
      parameters &&
      parameters.is_distribution_mode_defined &&
      cart.type !== parameters.distribution_mode
    ) {
      this.modalsService.open(CartParametersModalComponent.handle);
      this.modalsService.setData(CartParametersModalComponent.handle, {
        text: this.translateService.instant('parameters.warning-existing-cart'),
      });
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.headerService.toggleCartParametersVisibility(false);
    this.headerService.toggleCartButtonVisibility(false);
  }

  navigateToCart(): void {
    this.isNavigationToCartInProgress = true;
    this.router.navigate([this.routerHelper.translateRoute(Paths.Cart)]);
  }
}
