import { from, Observable, Subject, timer } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';

import { ISidebarNavigationItem } from '@shared/models/sidebar-navigation-item.model';

import { UserService } from '@core/services/user/user.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { NativeService } from '@core/services/native/native.service';
import { ResetStateService } from '@core/services/reset-state.service';
import { ShopSearchParametersService } from '@core/services/shop-search-parameters/shop-search-parameters.service';

import { WaitingModalComponent } from '@shared/modals/waiting-modal/waiting-modal.component';
import { AuthenticationModalComponent } from '@shared/modals/authentication-modal/authentication-modal.component';
import { CartParametersModalComponent } from '@shared/modals/cart-parameters-modal/cart-parameters-modal.component';

declare global {
  interface Window {
    doodWebappVersion?: string;
  }
}

@Component({
  selector: 'app-sidebar-navigation-modal',
  templateUrl: './sidebar-navigation-modal.component.html',
})
export class SidebarNavigationModalComponent implements OnInit, OnDestroy {
  constructor(
    private authSelector: AuthStoreSelector,
    private modalSelector: ModalStoreSelector,
    private readonly userService: UserService,
    private readonly modalsService: ModalsService,
    private settingsSelector: SettingsStoreSelector,
    private marketplaceSelector: MarketplaceStoreSelector,
    private readonly resetStateService: ResetStateService,
    private readonly shopSearchParametersService: ShopSearchParametersService,
  ) {}

  static handle = 'sidebar-navigation-modal';
  resetTapCount = 0;
  @Input() heading = 'Menu';
  @Input() customItems?: any[];
  @Input() displayPoweredByDood = true;
  @Input() footerMessage?: string;
  @Input() footerMessageUrl?: string;

  @Input() loggedInItems: ISidebarNavigationItem[] = [
    {
      title: 'sidebar.navigation.home',
      path: '/',
      icon: 'arrow-left',
    },
    {
      title: 'sidebar.navigation.account',
      path: '/account',
      icon: 'account',
    },
    {
      title: 'sidebar.navigation.orders',
      path: '/orders',
      icon: 'todo',
    },
    {
      title: 'sidebar.navigation.checks',
      path: '/checks',
      icon: 'receipt_long',
    },
  ];
  @Input() notLoggedInItems: ISidebarNavigationItem[] = [
    {
      title: 'sidebar.navigation.home',
      path: '/',
      icon: 'arrow-left',
    },
  ];

  index$: Observable<number> = this.modalSelector
    .selectModal(SidebarNavigationModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  userId$ = this.authSelector.selectUserId;
  firebaseToken$ = this.authSelector.selectStatusToken;
  isAnonymous$ = this.authSelector.selectUserIsAnonymous;
  forceDistributionMode$ = this.settingsSelector.selectParameters.pipe(
    map(parameters => parameters.force_distribution_mode),
  );
  locales$ = this.marketplaceSelector.selectMarketplaceLocales;

  isNotched = false;

  isLogoutInProgress = false;

  items: ISidebarNavigationItem[] = [];

  doodCorpoUrl = 'https://www.dood.com/corporate/';
  version = window.doodWebappVersion ? window.doodWebappVersion.substring(0, 7) : 'Unknown';

  private readonly destroyed$ = new Subject<boolean>();

  ngOnInit(): void {
    this.userId$
      .pipe(
        takeUntil(this.destroyed$),
        tap(userId => this.userStatusChanged(userId)),
      )
      .subscribe();

    from(NativeService.isNotched())
      .pipe(take(1))
      .subscribe(async _isNotched => {
        this.isNotched = _isNotched;

        if (this.isNotched) {
          await StatusBar.setStyle({ style: Style.Dark });
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  close(): void {
    // Tiny delay to prevent page change "flash"
    timer(10).subscribe(() => {
      this.modalsService.close(SidebarNavigationModalComponent.handle);
    });
  }

  openAuthenticationModal(): void {
    this.close();
    this.modalsService.open(AuthenticationModalComponent.handle);
  }

  async logout(): Promise<any> {
    this.modalsService.open(WaitingModalComponent.handle);
    this.isLogoutInProgress = true;
    this.resetStateService.refreshUI();
  }

  private userStatusChanged(userId: any): void {
    if (userId && !this.userService.user?.is_anonymous) {
      this.items = this.loggedInItems;
    } else {
      this.items = this.notLoggedInItems;
    }
  }

  async resetTap(): Promise<void> {
    this.resetTapCount++;
    if (this.resetTapCount > 5) {
      this.isLogoutInProgress = true;
      this.resetStateService.refreshUI();
    }
  }

  resetForceDistributionMode(): void {
    this.shopSearchParametersService.setForceDistributionMode(undefined);
    this.modalsService.open(CartParametersModalComponent.handle);
    this.close();
  }
}
