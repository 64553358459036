<a
  class="w-full flex items-center justify-center overflow-hidden bg-no-repeat bg-cover bg-center py-11 cursor-pointer bg-neutral-100"
  [class.rounded-sm]="rounded === 'small'"
  [class.rounded-md]="rounded === 'medium'"
  [class.rounded-lg]="!rounded || rounded === 'large'"
  [class.rounded-2xl]="rounded === '2xl'"
  [class.rounded-3xl]="rounded === '3xl'"
  [class.rounded-4xl]="rounded === '4xl'"
  [class.rounded-full]="rounded === 'full'"
  [class.shadow-S]="!shadow || shadow === 'small'"
  [class.shadow-md]="shadow === 'medium'"
  [class.shadow-lg]="shadow === 'large'"
  [style.aspect-ratio]="aspectRatio ? aspectRatio : '100/32'"
  [style.background]="'url(' + imageUrl + ')'"
  [link]="linkUrl"
>
</a>
