import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Component, HostListener, OnInit } from '@angular/core';
import { combineLatest, interval, merge, of, timer } from 'rxjs';
import { skipWhile, switchMap, take, takeUntil, takeWhile, tap } from 'rxjs/operators';

import { DoodOrderModel } from '@store/order/order.model';

import { SELECT_AUTH_USER } from '@store/authentication';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';

import { Paths } from '@config/paths.config';
import { PaymentMethodHandles } from '@config/payment-methods.config';

import { CartService } from '@core/services/cart/cart.service';
import { PushService } from '@core/services/push/push.service';
import { BasketService } from '@core/services/basket/basket.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { PaymentService } from '@core/services/payment/payment.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { PaymentStoreDispatcher } from '@common/dispatchers/payment.dispatcher';

@Component({
  selector: 'app-payment-panel-adyen-terminal',
  templateUrl: './payment-panel-adyen-terminal.component.html',
})
export class PaymentPanelAdyenTerminalComponent extends DestroyerBase implements OnInit {
  isPaymentInProgress = false;
  isOrderCancelled = false;
  displayPaymentLoadingScreen = false;
  documentWasHidden = false;
  longTimeSinceVisibleAgain = false;

  constructor(
    private store: Store,
    private readonly router: Router,
    private readonly cartService: CartService,
    private readonly pushService: PushService,
    private readonly ordersService: OrdersService,
    private readonly basketService: BasketService,
    private readonly paymentService: PaymentService,
    private paymentDispatcher: PaymentStoreDispatcher,
    private readonly routerHelper: RouterHelperService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.createOrderAndPay();
  }

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange(): void {
    if (!document.hidden && this.documentWasHidden) {
      timer(10000)
        .pipe(
          takeUntil(this._destroyerRef),
          tap(() => (this.longTimeSinceVisibleAgain = true)),
        )
        .subscribe();
    }

    if (document.hidden) {
      this.documentWasHidden = true;
    }
  }

  createOrderAndPay(): void {
    this.isPaymentInProgress = true;
    this.paymentDispatcher.updateUI({
      error: null,
      button: {
        isEnabled: false,
      },
      status: {
        isLoading: true,
      },
    });

    this.ordersService
      .createOrder$(PaymentMethodHandles.AdyenTerminal)
      .pipe(
        switchMap((order: DoodOrderModel | null) =>
          combineLatest([
            of(order),
            order ? this.paymentService.createOrderPaymentIntent$(order.id) : of(null),
          ]),
        ),
        tap(([order]) => {
          this.displayPaymentLoadingScreen = true;
          if (order) {
            this.listenToOrderStatusChange(order);
          }
        }),
      )
      .subscribe({
        error: error => {
          this.handlePaymentError(error);
        },
      });
  }

  changePaymentMethod(): void {
    this.router.navigate([this.routerHelper.translateRoute('/cart')]);
  }

  private listenToOrderStatusChange(order: DoodOrderModel): void {
    this.store
      .select(SELECT_AUTH_USER)
      .pipe(
        skipWhile(user => !user?.id),
        take(1),
        tap(user => {
          const pushSubscription = this.pushService.addSubscription(
            `dood/users/${user?.id}/orders`,
          );
          merge(interval(15000), pushSubscription)
            .pipe(
              takeUntil(this._destroyerRef),
              switchMap(() => this.ordersService.getOrderById$(order.id)),
              tap((updatedOrder: DoodOrderModel) => {
                if (updatedOrder.status === 'CANCELLED') {
                  this.isOrderCancelled = true;
                  return;
                }

                if (updatedOrder.status !== 'PAYMENT') {
                  this.handlePaymentSuccess(updatedOrder);
                  return;
                }
              }),
              takeWhile((updatedOrder: DoodOrderModel) => {
                return updatedOrder.status === 'PAYMENT';
              }),
            )
            .subscribe();
        }),
      )
      .subscribe();
  }

  private handlePaymentSuccess(updatedOrder: DoodOrderModel): void {
    this.basketService.clearBasket();
    this.cartService.clearCart();
    this.router.navigate([
      this.routerHelper.translateRoute(`${Paths.Orders}/${updatedOrder.id}/${Paths.Status}`),
    ]);
    this.isPaymentInProgress = false;
    this.paymentDispatcher.updateUI({
      error: null,
      button: {
        isEnabled: false,
      },
      status: {
        isLoading: false,
        isInProgress: false,
        isRetryInProgress: false,
      },
    });
  }

  private handlePaymentError(error: any): void {
    const paymentErrorMessage = error.error?.detail ?? 'Une erreur est survenue';

    this.isPaymentInProgress = false;
    // TODO: Check why using timer(1)
    timer(1).subscribe(() => {
      this.paymentDispatcher.updateUI({
        error: paymentErrorMessage,
        button: {
          isEnabled: true,
        },
        status: {
          isLoading: false,
          isInProgress: false,
          isRetryInProgress: false,
        },
      });
    });
  }

  public cancelOrder(): void {
    this.router.navigate([this.routerHelper.translateRoute(`/cart`)]);
  }
}
