import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICategory, DoodProductModel } from '@core/models/product.model';
import { CategoryKeys } from '@config/keys/category.keys';
import { ProductKeys } from '@config/keys/product.keys';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-product-category-horizontal-highlight-atom',
  templateUrl: './product-category-horizontal-highlight-atom.component.html',
})
export class ProductCategoryHorizontalHighlightAtomComponent {
  @Input() category!: ICategory;
  @Input() products!: DoodProductModel[];
  @Input() isOrderingAllowed = true;
  @Input() categoryNameColor: IContentBuilderFieldColor = {
    value: 'neutral-900',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() categoryDescriptionColor: IContentBuilderFieldColor = {
    value: 'neutral-600',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() highlightCategoryNameColor: IContentBuilderFieldColor = {
    type: ColorFieldTypesValues.Default,
    value: '',
  };
  @Input() highlightCategoryBackgroundColor: IContentBuilderFieldColor = {
    value: 'neutral-600',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() productNameColor: IContentBuilderFieldColor = {
    value: 'neutral-900',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() productDescriptionColor: IContentBuilderFieldColor = {
    value: 'neutral-900',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() productPriceColor: IContentBuilderFieldColor = {
    value: 'neutral-900',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() productImagePlaceholderColor: IContentBuilderFieldColor = {
    value: 'neutral-50',
    type: ColorFieldTypesValues.Palette,
  };

  @Output() navigateTo = new EventEmitter<DoodProductModel>();

  categoryKeys = CategoryKeys;
  productKeys = ProductKeys;

  onNavigateTo(product: DoodProductModel): void {
    this.navigateTo.emit(product);
  }

  colorOrFallback(color?: IContentBuilderFieldColor, fallback?: IContentBuilderFieldColor) {
    if (color?.type === ColorFieldTypesValues.Default || !color) {
      return fallback;
    }

    return color;
  }

  forceType(data: any): any {
    return data as any;
  }
}
