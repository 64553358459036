<app-modal-filler-atom
  (click)="close()"
  [index]="(index$ | async) ?? 0"
></app-modal-filler-atom>
<div
  class="fixed left-0 right-0 overflow-y-auto shadow md:left-0 md:top-0 md:flex md:h-full md:w-full md:items-center md:justify-center mdMax:bottom-0 mdMax:mx-auto mdMax:max-h-85-screen mdMax:max-w-screen-sm"
  [style.z-index]="((index$ | async) ?? 0) + 50"
>
  <app-modal-filler-atom
    *ngIf="isDesktop$ | async"
    [withBackdrop]="false"
    (click)="close()"
    [index]="(index$ | async) ?? 0"
  ></app-modal-filler-atom>
  <div
    class="rounded-t-3xl bg-white p-5 md:w-2/5 md:min-w-448 md:max-w-lg md:rounded-3xl md:p-7"
    [style.z-index]="((index$ | async) ?? 0) + 50"
  >
    <h1
      class="pb-5 font-brand text-28px font-bold text-neutral-800"
      translate="zerosix.link-account.header"
    ></h1>
    <ng-container *ngIf="step === 'link-form' || step === 'verification-code'">
      <form [formGroup]="form">
        <div class="relative pb-6">
          <ng-container *ngIf="step === 'link-form'">
            <label
              for="phone"
              class="mb-1 block font-base text-sm font-normal leading-5 text-neutral-600"
              translate="signup.phone"
            ></label>
            <label
              for="phone"
              class="mb-2 block font-base text-xs font-normal text-neutral-500"
            >
              {{ 'zerosix.help-phone-format' | translate }}
            </label>
            <input
              id="phone"
              type="tel"
              formControlName="phone"
              (keyup.enter)="onSubmit()"
              class="flex w-full border border-solid border-neutral-300 bg-neutral-50 px-5 py-8xs font-base text-base font-normal leading-5"
              [class.border-error-700]="
                phoneErrorMessage ||
                (form.controls?.phone?.errors &&
                  (form.controls?.phone?.dirty || form.controls?.phone?.touched))
              "
            />
            <ng-container
              *ngIf="
                form.controls?.phone?.invalid &&
                (form.controls?.phone?.dirty || form.controls?.phone?.touched)
              "
            >
              <span
                class="absolute bottom-1 block font-base text-xs font-normal text-error-700"
                *ngIf="form.controls?.phone?.errors"
                translate="signup.invalid-phone"
              ></span>
            </ng-container>
            <ng-container *ngIf="phoneErrorMessage">
              <span
                class="font-base text-xs font-normal text-error-700"
                [translate]="phoneErrorMessage"
              ></span>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="step === 'verification-code'">
            <label
              for="authCode"
              class="mb-1 block font-base text-sm font-normal leading-5 text-neutral-600"
              translate="zerosix.link-account.enter-auth-code"
            ></label>
            <input
              id="authCode"
              type="text"
              formControlName="authCode"
              (keyup.enter)="onSubmit()"
              class="flex w-full border border-solid border-neutral-300 bg-neutral-50 px-5 py-8xs font-base text-base font-normal leading-5"
              [class.border-error-700]="
                authCodeErrorMessage ||
                (form.controls?.authCode?.errors &&
                  (form.controls?.authCode?.dirty || form.controls?.authCode?.touched))
              "
            />
            <ng-container
              *ngIf="
                form.controls?.authCode?.invalid &&
                (form.controls?.authCode?.dirty || form.controls?.authCode?.touched)
              "
            >
              <span
                class="absolute bottom-1 block font-base text-xs font-normal text-error-700"
                *ngIf="form.controls?.authCode?.errors"
                translate="zerosix.link-account.errors.invalid-auth-code"
              ></span>
            </ng-container>
            <ng-container *ngIf="authCodeErrorMessage">
              <span
                class="font-base text-xs font-normal text-error-700"
                [translate]="authCodeErrorMessage"
              ></span>
            </ng-container>
          </ng-container>
          <div
            class="self-center"
            *ngIf="errorMessage"
          >
            <span
              class="font-base text-xs font-normal text-error-700"
              [translate]="errorMessage"
            ></span>
          </div>
        </div>
      </form>
      <div class="flex flex-col">
        <div
          class="pb-4"
          *ngIf="phoneErrorMessage === 'zerosix.link-account.errors.account-not-found'"
        >
          <app-button-atom
            size="M"
            [label]="'zerosix.link-account.create-new-account' | translate"
            (click)="openCreateLoyaltyAccountModal()"
            type="light"
            [full]="true"
          ></app-button-atom>
        </div>
        <div class="flex gap-2 pt-1 xm:flex-nowrap">
          <div class="w-full xm:w-1/2">
            <app-button-atom
              size="M"
              [label]="'zerosix.cancel' | translate"
              (click)="close()"
              type="light"
              [full]="true"
            ></app-button-atom>
          </div>
          <div class="w-full xm:w-1/2">
            <app-button-atom
              [iconUrl]="isValidating ? 'assets/images/spinner.svg' : ''"
              [disabled]="isValidating || this.form.invalid"
              size="M"
              [label]="'zerosix.validate' | translate"
              type="primary"
              [full]="true"
              (click)="onSubmit()"
            ></app-button-atom>
          </div>
        </div>
      </div>
    </ng-container>

    <div
      class="flex flex-col gap-10"
      *ngIf="step === 'confirmation'"
    >
      <p class="mb-0.5 flex items-center font-base text-base text-neutral-800">
        <span><i class="icon icon-n-check mr-1.5 mt-1 text-xl text-success-600"></i></span>
        {{ 'zerosix.link-account.success-message' | translate }}
      </p>
      <app-button-atom
        size="L"
        [label]="'zerosix.validate' | translate"
        type="primary"
        [full]="true"
        (click)="close()"
      ></app-button-atom>
    </div>
  </div>
</div>
