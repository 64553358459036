import { Component, Input } from '@angular/core';
import { IContentBuilderFieldImage } from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-image-and-call-to-actions-block',
  templateUrl: './image-and-call-to-actions-block.component.html',
})
export class ImageAndCallToActionsBlockComponent {
  @Input() anchor?: string;
  @Input() textOnImage?: string;
  @Input() textContent?: string;
  @Input() imagePosition?: string;
  @Input() buttons: unknown[] = [];
  @Input() urlButtonImage?: string;
  @Input() labelButtonImage?: string;
  @Input() image?: IContentBuilderFieldImage;
  @Input() buttonSizeY: 'S' | 'M' | 'L' = 'M';

  IMAGE_POSITION_RIGHT = 'right';
}
