import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Paths } from '@config/paths.config';

import { CartService } from '@core/services/cart/cart.service';
import { NotificationService } from '@core/services/notification/notification.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';

@Component({
  template: ``,
  selector: 'app-legacy-order-status-redirect',
})
export class LegacyOrderStatusRedirectComponent implements OnInit {
  constructor(
    private readonly router: Router,
    private readonly cartService: CartService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly routerHelper: RouterHelperService,
    private readonly notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    const {
      action: paygreenAction,
      result: paygreenResult,
      status,
      origin,
      transactionId,
    } = this.activatedRoute.snapshot.queryParams;

    const orderId: string = this.activatedRoute.snapshot.params.id;

    if (origin === 'pay-at-table') {
      this.handlePayAtTablePayment(paygreenAction, paygreenResult, status, orderId, transactionId);
      return;
    }

    if (!origin) {
      this.handleDefaultPayment(paygreenAction, paygreenResult, status, orderId);
    }
  }

  handlePayAtTablePayment(
    paygreenAction: string | null,
    paygreenResult: string | null,
    status: string | null,
    orderId: string,
    transactionId: string,
  ): void {
    if (this.isPaymentFailed(paygreenAction, paygreenResult, status)) {
      this.navigateToRoute(`/pay-at-table`);
      this.notificationService.displayMessage('Le paiement a échoué.<br>Merci de réessayer.');
      return;
    }
    this.navigateToRoute(`/${Paths.PayAtTable}/${Paths.PaymentConfirmation}/${transactionId}`, {
      paygreen_status: status,
      checkId: orderId,
      transactionId,
    });
  }

  handleDefaultPayment(
    paygreenAction: string | null,
    paygreenResult: string | null,
    status: string | null,
    orderId: string,
  ): void {
    if (this.isPaymentFailed(paygreenAction, paygreenResult, status)) {
      this.navigateToRoute(`/cart`);
      this.notificationService.displayMessage('Le paiement a échoué.<br>Merci de réessayer.');
      return;
    }
    this.cartService.clearCart();
    this.navigateToRoute(`/orders/${orderId}/status`);
  }

  isPaymentFailed(
    paygreenAction: string | null,
    paygreenResult: string | null,
    status: string | null = null,
  ): boolean {
    return (
      paygreenAction === 'returnToShop' ||
      paygreenResult === 'REFUSED' ||
      paygreenResult === 'refused' ||
      status === 'REFUSED' ||
      status === 'refused'
    );
  }

  navigateToRoute(path: string, queryParams?: any): void {
    if (queryParams) {
      this.router.navigate([this.routerHelper.translateRoute(path)], {
        queryParams,
      });
      return;
    }
    this.router.navigate([this.routerHelper.translateRoute(path)]);
  }
}
