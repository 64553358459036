import { Observable } from 'rxjs';
import { Injector } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

import { DoodMarketplaceModel } from '@core/models/marketplace.model';
import { MarketplaceService } from '@core/services/marketplace/marketplace.service';

export function ensureMarketplaceIsLoaded(
  marketplaceService: MarketplaceService,
  injector: Injector,
): () => Observable<DoodMarketplaceModel> {
  return () => {
    const localize = injector.get(LocalizeRouterService);
    return localize.hooks.initialized.pipe(switchMap(() => marketplaceService.loadMarketplace$()));
  };
}
