<div class="md:flex md:flex-col md:items-center">
  <div class="px-5 pb-3 md:w-8/12">
    <div
      [style.color]="textColor | contentBuilderColor"
      class="user-feedback flex flex-col gap-4 p-5 rounded-md"
      [style.background-color]="backgroundColor | contentBuilderColor"
    >
      <!-- Title -->
      <div
        *ngIf="title"
        class="user-feedback__title font-brand font-semibold text-lg lg:text-xl"
      >
        {{ title | translate }}
      </div>

      <!-- Starts -->
      <div class="user-feedback__stars flex justify-center px-4">
        <div class="user-feedback__list grid gap-5 grid-cols-{{ length }}">
          <ng-container *ngFor="let item of items; let isFirst = first; let isLast = last">
            <button
              (click)="onRated($event, item + 1)"
              [ngClass]="{ 'h-8': !isFirst && !isLast }"
              class="user-feedback__item flex flex-col items-center gap-2 w-8"
            >
              <i class="icon icon-star{{ rating > item ? '' : '-empty' }} text-2xl"></i>
              <span
                *ngIf="isFirst"
                class="user-feedback__item-title text-xs"
              >
                {{ minValueLabel | translate }}
              </span>
              <span
                *ngIf="isLast"
                class="user-feedback__item-title text-xs"
              >
                {{ maxValueLabel | translate }}
              </span>
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
