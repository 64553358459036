import {Component} from '@angular/core';

@Component({
  selector: 'app-payment-loading-screen-atom',
  templateUrl: './payment-loading-screen-atom.component.html',
  styleUrls: ['./payment-loading-screen-atom.component.scss'],
})
export class PaymentLoadingScreenAtomComponent {
  messages = [
    "payment-loading.messages.1",
    "payment-loading.messages.2",
    "payment-loading.messages.3",
    "payment-loading.messages.4",
  ];

  currentIndex = 0;
  isFadingOut = false;
  isFadingIn = false;
  intervalId: any;

  constructor() {
    this.startMessageRotation();
  }

  startMessageRotation() {
    this.intervalId = setInterval(() => {
      if (this.currentIndex >= this.messages.length - 1) {
        clearInterval(this.intervalId);
        return;
      }
      this.isFadingOut = true; // Début du fondu vers le haut
      this.isFadingIn = false;

      setTimeout(() => {
        this.currentIndex++;
        this.isFadingOut = false;
        this.isFadingIn = true; // Activation du fondu depuis le bas

        setTimeout(() => {
          this.isFadingIn = false;
        }, 250); // Temps de transition pour affichage complet
      }, 250); // Temps de transition pour disparition complète
    }, 5000); // 5 secondes de visibilité + 300ms de transition
  }
}

