import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-big-footer-block',
  templateUrl: './big-footer-block.component.html',
})
export class BigFooterBlockComponent {
  @Input() columns?: unknown[];
  @Input() leftBody?: unknown[];
}
