import {
  distinctUntilChanged,
  filter,
  map,
  skipWhile,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import { combineLatest, Observable, Subject } from 'rxjs';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { ProductKeys } from '@config/keys/product.keys';
import { CartItemKeys, CartKeys } from '@config/keys/cart.keys';
import { AdditionGroupKeys, ShopKeys } from '@config/keys/shop.keys';

import { ProductStoreRefiner } from '@common/refiners/product.refiner';
import { ProductStoreDispatcher } from '@common/dispatchers/product.dispatcher';

import { CartStoreSelector } from '@common/selectors/cart.selector';
import { ShopStoreSelector } from '@common/selectors/shop.selector';
import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { ProductStoreSelector } from '@common/selectors/product.selector';
import { ContentStoreSelector } from '@common/selectors/content.selector';
import { DeclinableStoreSelector } from '@common/selectors/declinable.selector';

import { IAdditionGroup } from '@core/models/shop.model';
import { DoodProductModel, ISelectedCompoundItem } from '@core/models/product.model';
import { ICartDeclinableSeparateAdditions, ICartItem } from '@core/models/cart.model';

import { CartService } from '@core/services/cart/cart.service';
import { ShopsService } from '@core/services/shops/shops.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { ProductService } from '@core/services/product/product.service';
import { ProductsService } from '@core/services/products/products.service';
import { OrderTypeService } from '@core/services/order-type/order-type.service';
import { EntityStatusService } from '@core/services/entity-status/entity-status.service';
import { EventTrackerService } from '@core/services/event-tracker/event-tracker.service';

import { ContentProductModalState } from '@store/content';
import { ProductUtils } from '@app/utilities/product.util';
import { ProductTypeValues } from '@config/values/product.values';
import { ModalScrollBlockBase } from '@core/base/modalScrollBlock/modal-scroll-block.base';
import { CompoundItemModalComponent } from '@shared/modals/compound-item-modal/compound-item-modal.component';
import { DeclinableStoreDispatcher } from '@common/dispatchers/declinable.dispatcher';
import { ShopStoreDispatcher } from '@common/dispatchers/shop.dispatcher';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

@Component({
  selector: 'app-product-add-modal',
  templateUrl: './product-add-modal.component.html',
})
export class ProductAddModalComponent extends ModalScrollBlockBase implements OnInit, OnDestroy {
  @Input() headingColor: IContentBuilderFieldColor = {
    value: 'neutral-900',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() descriptionColor: IContentBuilderFieldColor = {
    value: 'neutral-700',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() inactiveStateColor: IContentBuilderFieldColor = {
    value: 'neutral-500',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() priceColor: IContentBuilderFieldColor = {
    value: 'neutral-700',
    type: ColorFieldTypesValues.Palette,
  };

  @Input() displayPrice = true;
  @Input() replaceAddToCart?: (product: ICartItem) => void; // ! I think it was a bad idea to introduce that
  @Input() onCartItemAdded: (cartItem: ICartItem) => void = () => {};
  @Input() maxProduct?: number;
  @Input() backgroundColor?: IContentBuilderFieldColor = undefined;
  @Input() textColor?: IContentBuilderFieldColor = undefined;
  @Input() buttonBackgroundColor?: IContentBuilderFieldColor = undefined;
  @Input() buttonTextColor?: IContentBuilderFieldColor = undefined;
  @Input() hideAdditionGroupDetails = false;

  protected readonly tagColors$ =
    this.marketplaceStoreSelector.selectMarketPlaceProductLabelsTagColors;

  static handle = 'product-add-modal';

  static allowAddToCart = true;

  static onClose?: () => void;
  static onAdd?: () => void;

  private _destroyerRef = new Subject<boolean>();

  index$: Observable<number> = this.modalSelector
    .selectModal(ProductAddModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  product$ = this.productSelector.selectActive;
  declinables$ = this.declinableSelector.selectDeclinables;
  additionsByGroup$ = this.productRefiner.selectAdditionsByGroups;
  additions$ = this.productRefiner.selectAdditions.pipe(
    map(additions => additions.filter(v => !v.addition_group && v.id)),
  );
  selectedAdditions: string[] = [];
  selectedAdditionsByGroup: (IAdditionGroup & {
    selectedAdditions: string[];
  })[] = [];
  productKeys = ProductKeys;

  quantity = 1;
  isButtonValid = true;
  shopOpensAt!: string;
  product: DoodProductModel | null = null;

  constructor(
    private cartSelector: CartStoreSelector,
    private shopSelector: ShopStoreSelector,
    private modalSelector: ModalStoreSelector,
    private readonly cartService: CartService,
    private shopDispatcher: ShopStoreDispatcher,
    private readonly shopsService: ShopsService,
    private productRefiner: ProductStoreRefiner,
    private contentSelector: ContentStoreSelector,
    private productSelector: ProductStoreSelector,
    private readonly modalsService: ModalsService,
    private readonly productService: ProductService,
    private productDispatcher: ProductStoreDispatcher,
    private readonly productsService: ProductsService,
    private declinableSelector: DeclinableStoreSelector,
    private readonly orderTypeService: OrderTypeService,
    private declinableDispatcher: DeclinableStoreDispatcher,
    private readonly entityStatusService: EntityStatusService,
    private readonly eventTrackerService: EventTrackerService,
    private readonly marketplaceStoreSelector: MarketplaceStoreSelector,
  ) {
    super();

    const modal = this.contentSelector.productModal;
    if (!modal.shopId) {
      return;
    }
    this.loadShopProductsIfNeeded(modal);

    this.shopsService
      .selectById$(modal.shopId)
      .pipe(take(1))
      .subscribe(shop => {
        if (shop) {
          if (modal.shopIsNotActive) {
            this.shopDispatcher.insertActive(shop);
          }
          // Update Products
          const products = ProductUtils.denormalizeShopProducts(shop);
          this.productDispatcher.upsertMany(products);
        }
      });

    this.productsService
      .selectByShopAndProduct$(modal.shopId, modal.productId)
      .pipe(take(1))
      .subscribe(product => {
        if (!product) {
          return;
        }
        this.productService.setProductToStore(product);
        if (product.type === ProductTypeValues.Declinable) {
          this.productService.setCategories(
            product.shop_id,
            product.categories,
            product.price_overrides,
          );
        }
      });

    this.checkShopCapabilities();
  }

  ngOnInit(): void {
    this.checkIfCompoundProductIsValid();

    this.productRefiner.selectAdditionsByGroups
      .pipe(distinctUntilChanged(), takeUntil(this._destroyerRef))
      .subscribe(_additions => {
        this.selectedAdditionsByGroup = [];
        _additions.map(el => {
          if (el.id) {
            this.selectedAdditionsByGroup.push({
              ...el,
              selectedAdditions: [],
            });
          }
        });

        if (this.selectedAdditionsByGroup.length) {
          this.checkValidationProduct();
        }
      });

    this.productSelector.selectActive
      .pipe(
        filter(_product => !!_product),
        take(1),
      )
      .subscribe(product => {
        this.product = product;
        if (product) {
          this.eventTrackerService.dispatch(EventTrackerService.EventProductViewed, product);
        }
      });
  }

  private loadShopProductsIfNeeded(modal: ContentProductModalState): void {
    if (!modal.shopId) {
      return;
    }
    this.shopSelector
      .selectShop(modal.shopId)
      .pipe(
        skipWhile(s => !!s),
        take(1),
        switchMap(() => this.shopsService.loadShopById$(modal.shopId)),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.productService.removeProductToStore();
    this.declinableDispatcher.removeAll();
    // this.compoundItemStore.remove();
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
  }

  addToCart(): void {
    if (this.replaceAddToCart) {
      // for now it does not handle cart for declinable product
      this.product$.pipe(take(1)).subscribe(product => {
        if (this.replaceAddToCart && product) {
          const cartItem: Partial<ICartItem> = {
            [CartItemKeys.ItemId]: product?.id,
            [CartItemKeys.ShopId]: product?.shop_id,
            [CartItemKeys.Additions]: this.selectedAdditions,
            [CartItemKeys.Quantity]: this.quantity,
          };

          this.replaceAddToCart(cartItem as ICartItem);
        }
      });
      this.close();

      return;
    }

    combineLatest([this.product$, this.declinableSelector.selectDeclinables])
      .pipe(
        take(1),
        map(([product, compoundItems]) => {
          if (product?.type === ProductTypeValues.Declinable) {
            const declinable: Partial<ICartItem> = {
              [CartItemKeys.Products]: [],
              [CartItemKeys.ItemId]: product.id,
              [CartItemKeys.ShopId]: product.shop_id,
              [CartItemKeys.Quantity]: this.quantity,
            };
            compoundItems.map(item => {
              if (!item.isValid) {
                this.productService.setInvalidCompoundProduct(item.store_id);
              } else {
                item.selected?.forEach((e: ISelectedCompoundItem) => {
                  const additions: string[] = [];
                  const separateAdditionsByGroups: ICartDeclinableSeparateAdditions[] = [];
                  e.additions_by_group?.forEach(group => {
                    group.selected?.map(el => additions.push(el));
                    separateAdditionsByGroups.push({
                      id: group[AdditionGroupKeys.Id],
                      selected: group.selected as string[],
                    });
                  });
                  declinable[CartItemKeys.Products]?.push({
                    [CartItemKeys.Id]: e.id,
                    [CartItemKeys.Additions]: additions,
                    [CartItemKeys.Quantity]: e.quantity,
                    [CartItemKeys.StoreId]: item.store_id,
                    [CartItemKeys.ShopId]: product.shop_id,
                    [CartItemKeys.SeparateAdditionsByGroup]: separateAdditionsByGroups,
                  });
                });
              }
            });
            this.cartService.addCartItem$(declinable as ICartItem).subscribe(this.onCartItemAdded);
            this.eventTrackerService.dispatch(EventTrackerService.EventProductAddedToCart, {
              product,
              quantity: this.quantity,
            });
            if (ProductAddModalComponent.onAdd) {
              ProductAddModalComponent.onAdd();
            }
            this.close();
            return;
          }
          // simple product
          const cartItem: Partial<ICartItem> = {
            [CartItemKeys.ItemId]: product?.id,
            [CartItemKeys.ShopId]: product?.shop_id,
            [CartItemKeys.Additions]: this.selectedAdditions,
            [CartItemKeys.Quantity]: this.quantity,
          };

          this.cartService.addCartItem$(cartItem as ICartItem).subscribe(this.onCartItemAdded);
          this.eventTrackerService.dispatch(EventTrackerService.EventProductAddedToCart, {
            product,
            quantity: this.quantity,
          });

          if (ProductAddModalComponent.onAdd) {
            ProductAddModalComponent.onAdd();
          }
          this.close();
        }),
      )
      .subscribe();
  }

  onSelectAddition(addition: DoodProductModel): string[] {
    if (addition[ProductKeys.AdditionsGroup]) {
      this.onSelectAdditionsByGroup(addition);
    }
    this.selectedAdditions = ProductUtils.toggleItem(addition, this.selectedAdditions);
    return this.selectedAdditions;
  }

  onChangeNbProduct(quantity: number): number {
    return (this.quantity = quantity);
  }

  onChangeNbAddition(event: { quantity: number; id: string }): void {
    this.onChangeNbAdditionGroup(event);
    let count = 0;
    this.selectedAdditions.forEach(v => v === event.id && count++);
    for (let it = count; it < event.quantity; it++) {
      this.selectedAdditions.push(event.id);
    }
    for (let it = count; it > event.quantity; it--) {
      const index = this.selectedAdditions.indexOf(event.id);
      if (index !== -1) {
        this.selectedAdditions.splice(index, 1);
      }
    }
  }

  onChangeNbAdditionGroup(event: { quantity: number; id: string }): void {
    let count = 0;
    this.selectedAdditionsByGroup.forEach(group => {
      group.selectedAdditions.forEach(v => v === event.id && count++);
      group.items.forEach(addition => {
        if (addition[ProductKeys.Id] === event.id) {
          for (let it = count; it < event.quantity; it++) {
            group.selectedAdditions.push(event.id);
          }

          for (let it = count; it > event.quantity; it--) {
            const index = group.selectedAdditions.indexOf(event.id);
            if (index !== -1) {
              group.selectedAdditions.splice(index, 1);
            }
          }
        }
      });
    });
    this.checkValidationProduct();
  }

  close(): void {
    this.modalsService.close(ProductAddModalComponent.handle);
    if (ProductAddModalComponent.onClose) {
      ProductAddModalComponent.onClose();
    }
  }

  openCompound(item: any): void {
    this.declinableDispatcher.setActive(item);
    // this.compoundItemStore.setActive(item);
    this.modalsService.open(CompoundItemModalComponent.handle);
  }

  private onSelectAdditionsByGroup(addition: DoodProductModel): void {
    if (addition[ProductKeys.IsChecked]) {
      this.selectedAdditionsByGroup
        ?.find(el => el.id === addition[ProductKeys.AdditionsGroup])
        ?.selectedAdditions?.push(addition[ProductKeys.Id]);
    } else {
      const index = this.selectedAdditionsByGroup
        .find(el => el.id === addition[ProductKeys.AdditionsGroup])
        ?.selectedAdditions.indexOf(addition[ProductKeys.Id]) as number;
      this.selectedAdditionsByGroup
        .find(el => el.id === addition[ProductKeys.AdditionsGroup])
        ?.selectedAdditions.splice(index, 1);
    }

    this.checkValidationProduct();
  }

  private checkValidationProduct(): void {
    this.isButtonValid = true;
    this.selectedAdditionsByGroup.map(group => {
      if (
        group.selectedAdditions.length < group.min_count ||
        (group.max_count && group.selectedAdditions.length > group.max_count)
      ) {
        this.isButtonValid = false;
      }
    });
  }

  private checkIfCompoundProductIsValid(): void {
    this.declinableSelector.selectDeclinables
      .pipe(takeUntil(this._destroyerRef))
      .subscribe(items => {
        let hasInvalidValue = false;
        for (const item of items) {
          if (!item.isValid) {
            hasInvalidValue = !item.isValid;
          }
        }

        this.isButtonValid = !hasInvalidValue;
      });
  }

  private checkShopCapabilities(): void {
    combineLatest([this.cartSelector.selectActive, this.shopSelector.selectActive])
      .pipe(
        takeUntil(this._destroyerRef),
        map(([cart, shop]) => {
          if (cart && shop) {
            const orderTypeCapabilities = this.orderTypeService.getCapabilities(
              cart[CartKeys.Type],
            );
            if (
              !shop[ShopKeys.Schedules] ||
              (orderTypeCapabilities.useOpenHours &&
                !orderTypeCapabilities.preorderingAllowed &&
                !this.entityStatusService.isOpen(shop?.[ShopKeys.Schedules]))
            ) {
              const orderType = this.cartSelector.active?.type;
              this.shopOpensAt = this.shopsService.getClosedMessage(shop, null, orderType);
            }
          }
        }),
      )
      .subscribe();
  }

  get staticAllowAddToCart(): boolean {
    return ProductAddModalComponent.allowAddToCart;
  }
}
