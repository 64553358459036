import { Component, Input, OnInit } from '@angular/core';

import { ShopKeys } from '@config/keys/shop.keys';
import { ShopStoreSelector } from '@common/selectors/shop.selector';
import { IContentBuilderFieldColor } from 'src/app/core/models/content-builder-fields.model';
import { combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ShareGroupedOrderModalComponent } from '@shared/modals/share-grouped-order-modal/share-grouped-order-modal.component';
import { GroupedOrderModalsAttributes } from '@config/modals/grouped-order-modals.config';
import { AuthenticationModalComponent } from '@shared/modals/authentication-modal/authentication-modal.component';
import { AuthFirebaseService } from '@core/services/api/auth-firebase/auth-firebase.service';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';
import { BasketStoreRefiner } from '@common/refiners/basket.refiner';
import { ModalsService } from '@core/services/modals/modals.service';
import { DeliveryDistributionModes } from '@config/values/order.values';
import { CartStoreSelector } from '@common/selectors/cart.selector';

@Component({
  selector: 'app-shop-small-hero-block',
  templateUrl: './shop-small-hero-block.component.html',
})
export class ShopSmallHeroBlockComponent implements OnInit {
  @Input() displayHeading = true;
  @Input() displayCategory = true;
  @Input() displayDescription = true;
  @Input() titleSize: 'S' | 'M' | 'L' = 'M';
  @Input() backgroundColor?: IContentBuilderFieldColor;

  shopKeys = ShopKeys;
  shop$ = this.shopSelector.selectActive;
  isShopLoading$ = this.shopSelector.selectIsLoading;
  isGroupedOrderActive = false;
  displayGroupedOrderButton?: boolean;

  constructor(
    private shopSelector: ShopStoreSelector,
    private readonly authFirebaseService: AuthFirebaseService,
    private readonly authSelector: AuthStoreSelector,
    private readonly basketRefiner: BasketStoreRefiner,
    private readonly modalsService: ModalsService,
    private readonly cartSelector: CartStoreSelector,
  ) {}

  ngOnInit(): void {
    const shop = this.shopSelector.active;
    if (shop) {
      const distributionModeFound = shop?.[ShopKeys.DistributionModes].find(
        distributionMode =>
          DeliveryDistributionModes.includes(distributionMode.type) ||
          distributionMode.type === this.cartSelector.active?.type,
      );
      this.displayGroupedOrderButton = distributionModeFound?.allow_grouped_order || false;
    }
  }

  openGroupOrderModal(): void {
    this.isGroupedOrderActive = true;
    combineLatest([
      this.authFirebaseService.isAuthenticate$().pipe(
        take(1),
        map(v => !!v),
      ),
      this.authSelector.selectStatusToken.pipe(
        take(1),
        map(token => !!token),
      ),
      this.authSelector.selectUserId.pipe(
        take(1),
        map(id => !!id),
      ),
      this.basketRefiner.selectHasBasket,
    ])
      .pipe(
        take(1),
        map(([firebaseUser, firebaseToken, userId, isBasketExist]) => [
          firebaseUser && firebaseToken && userId,
          isBasketExist,
        ]),
        map(([logged, isBasketExist]) => {
          if (logged && isBasketExist) {
            this.modalsService.open(
              ShareGroupedOrderModalComponent.handle,
              GroupedOrderModalsAttributes.GeneratedLink,
            );
          }
          if (logged) {
            this.modalsService.open(
              ShareGroupedOrderModalComponent.handle,
              GroupedOrderModalsAttributes.ShareLink,
            );
          } else {
            this.modalsService.open(AuthenticationModalComponent.handle);
          }
        }),
      )
      .subscribe();
  }
}
