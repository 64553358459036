// TODO: This service should be replaced !
import { Injectable } from '@angular/core';
import { StripeService } from 'ngx-stripe';
import { Stripe } from '@stripe/stripe-js';
import { switchMap, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import {
  IAdyenPaymentIntent,
  ILyfPayPaymentIntent,
  IPaygreenPaymentIntent,
  IStripePaymentIntent,
  IYavinPaymentIntent,
  IVivaWalletPaymentIntent,
} from '@core/models/payment.model';

import { PaymentBancontactState } from '@store/payment/payment.state';
import { PaymentStoreDispatcher } from '@common/dispatchers/payment.dispatcher';

import { OrderPaymentsApiService } from '@core/services/api/order-payments/order-payments-api.service';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  static stripeApplePayAvailable = false;
  static stripeGooglePayAvailable = false;

  pay$ = new Subject<boolean>();

  paymentIntent$ = new BehaviorSubject<IStripePaymentIntent | null>(null);
  paymentAdyenIntent$ = new BehaviorSubject<IAdyenPaymentIntent | null>(null);
  paymentYavinIntent$ = new BehaviorSubject<IYavinPaymentIntent | null>(null);
  paymentVivaWalletIntent$ = new BehaviorSubject<IVivaWalletPaymentIntent | null>(null);
  paymentLyfpayIntent$ = new BehaviorSubject<ILyfPayPaymentIntent | null>(null);
  paymentPaygreenIntent$ = new BehaviorSubject<IPaygreenPaymentIntent | null>(null);

  redirectUrl$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  isTransactionMandatory$ = new BehaviorSubject<boolean>(false);

  private stripe?: Stripe;
  private emitStripeReady$ = new Subject<boolean>();

  constructor(
    private readonly stripeService: StripeService,
    private paymentDispatcher: PaymentStoreDispatcher,
    private marketplaceSelector: MarketplaceStoreSelector,
    private readonly orderPaymentService: OrderPaymentsApiService,
  ) {}

  triggerPayment(): void {
    this.pay$.next(true);
  }

  private getStripeInstance(i: number): void {
    this.stripe = this.stripeService.getInstance();

    if (i < 5 && this.stripe === undefined) {
      const j = i + 1;
      setTimeout(() => this.getStripeInstance(j), 1500);
    } else {
      this.emitStripeReady$.next(true);
    }
  }

  public checkDigitalWalletAvailable(): void {
    this.getStripeInstance(0);

    this.emitStripeReady$
      .pipe(
        switchMap(() => this.marketplaceSelector.selectMarketplaceCurrency),
        take(1),
      )
      .subscribe(currency => {
        const paymentRequestOptions = {
          currency: currency.toLocaleLowerCase(),
          country: this.marketplaceSelector.marketplace.country.toUpperCase() || 'FR',
          total: {
            label: 'check',
            amount: 0,
          },
          requestPayerName: true,
          requestPayerEmail: true,
        };
        const paymentRequest = this.stripe?.paymentRequest(paymentRequestOptions);
        paymentRequest?.canMakePayment().then(data => {
          if (data?.applePay || data?.googlePay) {
            PaymentService.stripeApplePayAvailable = data.applePay;
            PaymentService.stripeGooglePayAvailable = data.googlePay;
          }
        });
      });
  }

  setLoadingToStore(): void {
    this.paymentDispatcher.updateStatus({ isLoading: true });
  }

  setAdditionalData(bancontact: Partial<PaymentBancontactState>): void {
    this.paymentDispatcher.updateBancontact(bancontact);
  }

  unSetLoadingToStore(): void {
    this.paymentDispatcher.updateStatus({ isLoading: false });
  }

  createOrderPaymentIntent$<T>(orderId: string): Observable<T> {
    return this.orderPaymentService.createPaymentIntent$(orderId);
  }

  createTransactionPaymentIntent$<T>(orderId: string): Observable<T> {
    return this.orderPaymentService.createTransactionPaymentIntent$(orderId);
  }
}
