import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { DoodFilterModel } from '@core/models/filters.model';
import { DoodEntityCategory } from '@core/models/entity.model';

export interface FilterState extends EntityState<DoodFilterModel> {
  categories: DoodEntityCategory[];
}

export const FILTER_STORE_KEY = 'Filters';

export const FILTER_STORE_ADAPTER = createEntityAdapter<DoodFilterModel>();

export const FILTER_STORE_INITIAL_STATE: FilterState = FILTER_STORE_ADAPTER.getInitialState({
  categories: [],
});
