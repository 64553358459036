import {
  Action,
  ReducerTypes,
  ActionCreator,
  ActionReducer,
  createReducer,
  ActionType,
} from '@ngrx/store';
import { OnReducer } from '@ngrx/store/src/reducer_creator';

import {
  HydrationState,
  checkHydratedStoreState,
  updateHydratedStoreState,
} from './hydrated.state';
import { getHydratedStoreData } from './hydrated.storage';
import { HydratedStoreActionsKeys, HYDRATED_STORE_HYDRATION_KEY } from './hydrated.action';

interface HydrationActionsFilter<S = unknown> {
  other: ReducerTypes<S, readonly ActionCreator[]>[];
  hydration: ReducerTypes<S, readonly ActionCreator[]>[];
}

export interface HydrationReducerOpts<S> {
  key: string;
  ttl?: number;
  initialState: S;
  properties?: string[];
  ons: ReducerTypes<S, readonly ActionCreator[]>[];
  beforeLoad?: (_state: S) => S;
  beforeHydrate?: (_state: S) => S;
}

const filterHydrationActions = <S>(
  key: string,
  ...ons: ReducerTypes<S, readonly ActionCreator[]>[]
): HydrationActionsFilter<S> => {
  const _otherActions: ReducerTypes<S, readonly ActionCreator[]>[] = [];
  const _hydrationActions: ReducerTypes<S, readonly ActionCreator[]>[] = [];

  ons.forEach(on => {
    const { types } = on;
    const _keys = Object.values(HydratedStoreActionsKeys).map(_prop =>
      `[${HYDRATED_STORE_HYDRATION_KEY}/${key}] ${_prop}`.replace(
        /([\[\]&\/\\#,+()$~%.'":*?<>{}])/g,
        '\\$1',
      ),
    );

    const _hydration = types.some(_type => {
      return _keys.some(_key => new RegExp(_key).test(_type));
    });

    if (_hydration) {
      _hydrationActions.push(on);
    } else {
      _otherActions.push(on);
    }
  });

  return { hydration: _hydrationActions, other: _otherActions };
};

export const createHydratedReducer = <S extends HydrationState, A extends Action = Action>(
  options: HydrationReducerOpts<S>,
): ActionReducer<S, A> => {
  checkHydratedStoreState(options);

  const _data = getHydratedStoreData(options.key);
  let _initialState = { ...options.initialState, ...(_data as Partial<S>) };

  if (options.beforeLoad) {
    _initialState = options.beforeLoad(_initialState);
  }

  const _types = filterHydrationActions<S>(options.key, ...options.ons);
  const _ons: ReducerTypes<S, readonly ActionCreator[]>[] = [..._types.hydration];

  _types.other.forEach((_on: ReducerTypes<S, readonly ActionCreator[]>) => {
    const _reducer: OnReducer<S, readonly ActionCreator[]> = (
      state: S | undefined,
      action: ActionType<ActionCreator[][number]>,
    ) => {
      let _state = state;
      if (_state?._hydratable) {
        if (options.beforeHydrate) {
          _state = options.beforeHydrate({ ...(_state ?? options.initialState) });
        }
        if (_state) {
          return updateHydratedStoreState(options, _on.reducer(_state, action));
        }
      }
      return _state ?? options.initialState;
    };
    _ons.push({ ..._on, reducer: _reducer });
  });

  return createReducer(_initialState, ..._ons);
};
