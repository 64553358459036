/* eslint-disable ngrx/avoid-dispatching-multiple-actions-sequentially */
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import AdyenCheckout from '@adyen/adyen-web';
import { TranslateService } from '@ngx-translate/core';
import { PaymentMethods } from '@adyen/adyen-web/dist/types/types';
import { combineLatest, of, Subject, throwError, timer, from } from 'rxjs';
import { catchError, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { Paths } from '@config/paths.config';
import { environment } from '@env/environment';

import { IOrder } from '@core/models/order.model';
import { IAdyenPaymentIntent } from '@core/models/payment.model';

import { OrderStoreDispatcher } from '@common/dispatchers/order.dispatcher';
import { PaymentStoreDispatcher } from '@common/dispatchers/payment.dispatcher';

import { CartService } from '@core/services/cart/cart.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { PaymentService } from '@core/services/payment/payment.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';

@Component({
  selector: 'app-payment-panel-adyen-generic',
  templateUrl: './payment-panel-adyen-generic.component.html',
  styleUrls: ['./payment-panel-adyen-generic.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PaymentPanelAdyenGenericComponent implements OnInit, OnDestroy {
  @Input() payButtonText = '';
  @Input() isPayButtonVisible = true;
  @Input() type!: keyof PaymentMethods;
  @Input() paymentMethodHandle!: string;
  @Input() additionalAdyenConfiguration: object = {};

  @Input() clientKey?: string;
  @Input() googlePayMerchantName?: string;
  @Input() googlePayMerchantId?: string;
  @Input() googlePayGatewayMerchantId?: string;
  @Input() paymentMethodCbAvailable?: string;
  @Input() paymentMethodPaypalAvailable = false;
  @Input() paymentMethodGooglePayAvailable = false;
  @Input() paymentMethodApplePayAvailable = false;
  @Input() paymentMethodMealVoucherNatixisAvailable = false;
  @Input() paymentMethodMealVoucherSodexoAvailable = false;
  @Input() paymentMethodMealVoucherGroupeUpAvailable = false;

  @ViewChild('adyenContainer') adyenContainer?: ElementRef<HTMLElement>;

  isLoading = true;
  isPaymentInProgress = false;

  protected readonly unsubscribe$ = new Subject<boolean>();
  protected checkoutComponent?: InstanceType<PaymentMethods[keyof PaymentMethods]>;

  constructor(
    private readonly router: Router,
    private readonly cartService: CartService,
    private orderDispatcher: OrderStoreDispatcher,
    private readonly ordersService: OrdersService,
    private readonly paymentService: PaymentService,
    private paymentDispatcher: PaymentStoreDispatcher,
    private readonly routerHelper: RouterHelperService,
    private readonly translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;

    timer(1).subscribe(() => {
      this.paymentDispatcher.updateUI({
        error: null,
        button: {
          isEnabled: false,
          text: this.payButtonText,
          isVisible: Boolean(this.isPayButtonVisible),
        },
        status: {
          isLoading: true,
        },
      });
    });

    // For Adyen integration, it's mandatory to create order before displaying card form
    this.ordersService
      .createOrder$(this.paymentMethodHandle)
      .pipe(
        switchMap(order => {
          return combineLatest([
            of(order),
            order
              ? this.paymentService.createOrderPaymentIntent$<IAdyenPaymentIntent>(order.id)
              : of(null),
          ]);
        }),
        takeUntil(this.unsubscribe$),
        take(1),
        tap(([order, paymentIntent]) => {
          if (order && paymentIntent) {
            this.initializeAdyen(order, paymentIntent);
          }
        }),
        catchError(error => {
          this.orderDispatcher.resetValidation();
          this.orderDispatcher.updateErrors({ error });
          return throwError(() => error);
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    if (this.checkoutComponent) {
      this.checkoutComponent.remove();
    }
    this.paymentDispatcher.resetUI();
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  private handlePayment(): void {
    if (!this.checkoutComponent) {
      return;
    }

    this.isPaymentInProgress = true;
    this.paymentDispatcher.updateUI({
      button: {
        isEnabled: false,
      },
      status: {
        isInProgress: false,
      },
    });

    this.checkoutComponent.submit();
  }

  private initializeAdyen(order: IOrder, paymentIntent: IAdyenPaymentIntent): void {
    this.paymentDispatcher.updateStatus({ isLoading: false });

    this.paymentService.pay$
      .pipe(
        takeUntil(this.unsubscribe$),
        take(1),
        tap(() => this.handlePayment()),
      )
      .subscribe();

    const configuration = {
      ...environment.api.adyen,

      clientKey: this.clientKey,

      session: {
        id: paymentIntent.id,
        sessionData: paymentIntent.sessionData,
      },

      onPaymentCompleted: (result: any, component: any) =>
        this.onPaymentCompleted(result, component, order),
      onError: (error: any, component: any) => this.onPaymentError(error, component),
      onChange: (state: any, component: any) => this.onChange(state, component),

      paymentMethodsConfiguration: {
        card: {},
        applepay: {},
        paywithgoogle: {},
      },
    };

    if (this.paymentMethodCbAvailable) {
      configuration.paymentMethodsConfiguration.card = {
        hasHolderName: false,
        holderNameRequired: false,
        billingAddressRequired: false,
      };
    }

    if (this.paymentMethodApplePayAvailable) {
      configuration.paymentMethodsConfiguration.applepay = {
        buttonColor: 'black',
        buttonType: 'plain',
      };
    }

    if (this.paymentMethodGooglePayAvailable) {
      configuration.paymentMethodsConfiguration.paywithgoogle = {
        configuration: {
          merchantName: this.googlePayMerchantName,
          merchantId: this.googlePayMerchantId,
          gatewayMerchantId: this.googlePayGatewayMerchantId,
        },
        buttonType: 'order',
        buttonSizeMode: 'fill',
      };
    }

    if (!this.adyenContainer) {
      console.error('AdyenContainer not found in DOM');
      return;
    }

    from(AdyenCheckout(configuration))
      .pipe(
        takeUntil(this.unsubscribe$),
        take(1),
        tap(checkout => {
          this.isLoading = false;

          this.checkoutComponent = checkout.create(this.type);
          // @ts-ignore
          this.checkoutComponent.mount(this.adyenContainer.nativeElement);
        }),
      )
      .subscribe();
  }

  private onChange(state: any, component: any): void {
    this.paymentDispatcher.updateButton({
      isEnabled: state.isValid && !this.isPaymentInProgress,
    });
  }

  private onPaymentCompleted(result: any, component: any, order: IOrder): void {
    if (!result || result.resultCode !== 'Authorised') {
      this.onPaymentError();
      return;
    }

    this.paymentDispatcher.updateUI({
      error: null,
      button: {
        isEnabled: false,
      },
    });

    this.cartService.clearCart();

    this.router.navigate([
      this.routerHelper.translateRoute(`${Paths.Orders}/${order.id}/${Paths.Status}`),
    ]);
  }

  private onPaymentError(error?: any, component?: any): void {
    this.translateService
      .get(['payment.refusal-payment'])
      .pipe(take(1))
      .subscribe(translation => {
        this.isPaymentInProgress = false;
        this.paymentDispatcher.updateUI({
          error: translation['payment.refusal-payment'],
          button: {
            isEnabled: true,
          },
          status: {
            isInProgress: false,
          },
        });
      });

    console.error(error.name, error.message, error.stack, component);
  }
}
