import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SafePipe } from './pipes/safe.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { DiscountPercentPipe } from './pipes/discount-percent.pipe';
import { PaymentMethodLabelPipe } from './pipes/payment-method-label.pipe';
import { ContentBuilderColorPipe } from './pipes/content-builder-color.pipe';

import { LinkDirective } from './directives/link.directive';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { ComponentHostDirective } from './directives/component-host.directive';

import { BlockListComponent } from './components/block-list/block-list.component';
import { PageFactoryComponent } from './components/page-factory/page-factory.component';
import { BlockFactoryComponent } from './components/block-factory/block-factory.component';
import { QrCodeRedirectComponent } from './components/qr-code-redirect/qr-code-redirect.component';
import { ModalsContainerComponent } from './components/modals-container/modals-container.component';
import { ComponentFactoryComponent } from './components/component-factory/component-factory.component';
import { LegacyShopRedirectComponent } from './components/legacy-shop-redirect/legacy-shop-redirect.component';
import { LegacyOrderStatusRedirectComponent } from './components/legacy-order-status-redirect/legacy-order-status-redirect.component';
import { LegacyShopSmallUrlRedirectComponent } from './components/legacy-shop-small-url-redirect/legacy-shop-small-url-redirect.component';
import { LegacyShopDigitalMenuRedirectComponent } from './components/legacy-shop-digital-menu-redirect/legacy-shop-digital-menu-redirect.component';
import { APP_STORE_FEATURES } from '@app/app.store';
import { VirtualKeyboardDirective } from './directives/virtual-keyboard.directive';
import { VisibilityObserverDirective } from './directives/visibility-observer.directive';

const CORE_PIPES = [SafePipe, SafeHtmlPipe, DiscountPercentPipe, PaymentMethodLabelPipe];

const CORE_PIPES_STANDALONE = [ContentBuilderColorPipe];

const CORE_DIRECTIVES = [
  ComponentHostDirective,
  PhoneMaskDirective,
  LinkDirective,
  VirtualKeyboardDirective,
  VisibilityObserverDirective,
];

const CORE_COMPONENTS = [
  PageFactoryComponent,
  BlockFactoryComponent,
  BlockListComponent,
  ModalsContainerComponent,
  ComponentFactoryComponent,
  QrCodeRedirectComponent,
  LegacyShopRedirectComponent,
  LegacyOrderStatusRedirectComponent,
  LegacyShopSmallUrlRedirectComponent,
  LegacyShopDigitalMenuRedirectComponent,
];

@NgModule({
  imports: [CommonModule, APP_STORE_FEATURES, CORE_PIPES_STANDALONE],
  exports: [CORE_PIPES, CORE_COMPONENTS, CORE_DIRECTIVES, CORE_PIPES_STANDALONE],
  declarations: [CORE_PIPES, CORE_COMPONENTS, CORE_DIRECTIVES],
})
export class CoreModule {}
