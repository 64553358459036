import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DateUtils } from '@shared/utils/date/date.utils';
import { CheckKeys } from '@config/keys/check.keys';

@Component({
  selector: 'app-resume-check-atom',
  templateUrl: './resume-check-atom.component.html',
})
export class ResumeCheckAtomComponent implements OnInit, OnChanges {
  @Input() title?: string;
  @Input() shopName!: string;
  @Input() tableNumber!: string;
  @Input() checkNumber!: string;
  @Input() checkId!: string;
  @Input() checkAmount!: number;
  @Input() checkDate!: string;
  @Input() checkOutsandingAmount!: number;
  @Input() nbItems!: number;
  @Output() cardClick = new EventEmitter();

  checkKeys = CheckKeys;

  constructor() {}

  ngOnInit(): void {
    this.checkDate = this.formatDate(this.checkDate);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.orderDate) {
      this.checkDate = this.formatDate(this.checkDate);
    }
  }

  private formatDate(date: string): string {
    return DateUtils.dayjsInMarketplaceTimezone(date).format('DD/MM/YY à HH:mm');
  }
}
