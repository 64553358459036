import { Type } from '@angular/core';

import { AuthGuard } from '@core/guards/auth/auth.guard';
import { OrderValidateGuard } from '@core/guards/order-validate/order-validate.guard';
import { PatCheckGuard } from '@core/guards/check/pat-check.guard';

export const ALLOWED_GUARDS: Record<string, Type<unknown>> = {
  'AuthGuard': AuthGuard,
  'PatCheckGuard': PatCheckGuard,
  'OrderValidateGuard': OrderValidateGuard,
};
