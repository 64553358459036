import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-cart-total-amount-atom',
  templateUrl: './cart-total-amount-atom.component.html',
})
export class CartTotalAmountAtomComponent {
  @Input() deliveryAmount!: number;
  @Input() discount!: number;
  @Input() totalAmount!: number;
  @Input() coupons?: any[];
  @Input() usePointsAmount!: number;
  @Input() tips!: number;
  @Input() isBasketExist = false;
  @Input() headingColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() textColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() secondaryTextColor: IContentBuilderFieldColor = {
    value: 'neutral-500',
    type: ColorFieldTypesValues.Palette,
  };

  @Output() removeCoupon = new EventEmitter<any>();
  @Output() removeUsePoints = new EventEmitter<any>();

  onRemoveCoupon(coupon: any): void {
    this.removeCoupon.emit(coupon);
    coupon.removeInProgress = true;
  }
}
