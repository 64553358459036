import {catchError, debounceTime, delay, map, mergeMap, retryWhen, switchMap, take, tap,} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of, throwError} from 'rxjs';

import {DateUtils} from 'src/app/shared/utils/date/date.utils';
import {LocationUtils} from '@shared/utils/location/location.utils';

import {UserKeys} from '@config/keys/user.keys';
import {CartItemKeys, CartKeys} from '@config/keys/cart.keys';
import {MarketplaceKeys, ShopKeys} from '@config/keys/shop.keys';

import {CartStoreDispatcher} from '@common/dispatchers/cart.dispatcher';
import {ShopStoreDispatcher} from '@common/dispatchers/shop.dispatcher';
import {OrderStoreDispatcher} from '@common/dispatchers/order.dispatcher';
import {TransactionStoreDispatcher} from '@common/dispatchers/transaction.dispatcher';

import {CartStoreSelector} from '@common/selectors/cart.selector';
import {ShopStoreSelector} from '@common/selectors/shop.selector';
import {BasketStoreSelector} from '@common/selectors/basket.selector';
import {DeliveryStoreSelector} from '@common/selectors/delivery.selector';
import {AuthStoreSelector} from '@common/selectors/authentication.selector';
import {CartFunnelStoreSelector} from '@common/selectors/cart-funnel.selector';
import {MarketplaceStoreSelector} from '@common/selectors/marketplace.selector';

import {OrderStatusValues, OrderTypeValues} from '@config/values/order.values';

import {
  ICartValidateItem,
  ICartValidateItemProduct,
  IOrderValidate,
  IValidateDeliveryAddress,
  IViolations,
} from '@core/models/order.model';
import {
  DoodAddressModel,
  DoodAppDevice,
  DoodAppPlatform,
  DoodCouponModel,
  DoodCreateOrderQuery,
  DoodOrderModel,
  DoodOrderStatus,
  DoodOrderType,
  DoodOrderUserModel,
  DoodUsePointModel,
  DoodValidateOrderQuery,
  DoodValidateOrderResponse,
} from '@store/order/order.model';
import {DoodCartModel, ICartItem} from '@core/models/cart.model';
import {DoodTransactionModel} from '@core/models/transaction.model';
import {DoodUserModel} from '@store/authentication/authentication.model';
import {DoodDeliveryAddressModel} from '@core/models/delivery-address.model';
import {GeocoderAddressComponent, PlaceResult} from '@core/models/google-maps.model';

import {KioskService} from '@core/services/kiosk/kiosk.service';
import {ModalsService} from '@core/services/modals/modals.service';
import {ResetStateService} from '@core/services/reset-state.service';
import {AnalyticsService} from '@core/services/app/analytics.service';
import {ErrorService} from '@core/services/error-service/error.service';
import {OrdersApiService} from '@core/services/api/orders/orders-api.service';
import {OrderTypeService} from '@core/services/order-type/order-type.service';
import {EventTrackerService} from '@core/services/event-tracker/event-tracker.service';
import {AuthFirebaseService} from '@core/services/api/auth-firebase/auth-firebase.service';

import {CartParametersModalComponent} from '@shared/modals/cart-parameters-modal/cart-parameters-modal.component';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  cart$ = this.cartSelector.selectActive;
  checkCartIsValidCallInProgress$ = new BehaviorSubject<boolean>(false);

  constructor(
    private cartSelector: CartStoreSelector,
    private authSelector: AuthStoreSelector,
    private shopSelector: ShopStoreSelector,
    private basketSelector: BasketStoreSelector,
    private cartDispatcher: CartStoreDispatcher,
    private shopDispatcher: ShopStoreDispatcher,
    private readonly kioskService: KioskService,
    private readonly modalsService: ModalsService,
    private orderDispatcher: OrderStoreDispatcher,
    private deliverySelector: DeliveryStoreSelector,
    private cartFunnelSelector: CartFunnelStoreSelector,
    private readonly orderTypeService: OrderTypeService,
    private readonly ordersApiService: OrdersApiService,
    private marketplaceSelector: MarketplaceStoreSelector,
    private transactionDispatcher: TransactionStoreDispatcher,
    private readonly eventTrackerService: EventTrackerService,
    protected readonly authFirebaseService: AuthFirebaseService,
    protected readonly analyticsService: AnalyticsService,
    protected readonly resetStateService: ResetStateService,
  ) {
  }

  duplicateCartItem(items: ICartItem[]): ICartValidateItem[] {
    const newArr: ICartValidateItem[] = [];
    items.map(item => {
      for (let i = 0; i < item[CartItemKeys.Quantity]; ++i) {
        if (item[CartItemKeys.Products]?.length) {
          newArr.push({
            [CartItemKeys.Id]: item[CartItemKeys.ItemId],
            [CartItemKeys.ShopId]: item[CartItemKeys.ShopId],
            [CartItemKeys.Products]: item[CartItemKeys.Products] as ICartValidateItemProduct[],
          });
        } else {
          newArr.push({
            [CartItemKeys.Id]: item[CartItemKeys.ItemId],
            [CartItemKeys.ShopId]: item[CartItemKeys.ShopId],
            ...(item[CartItemKeys.Additions]?.length && {
              [CartItemKeys.Additions]: [...item[CartItemKeys.Additions]],
            }),
          });
        }
      }
    });
    return newArr;
  }

  private calculateWantedAt(cart: DoodCartModel, slots?: number[]): Date | null {
    const date = new Date();
    if (DateUtils.dayjsInMarketplaceTimezone(cart[CartKeys.WantedAt]).isAfter(date)) {
      return cart[CartKeys.WantedAt];
    }

    return null;
  }

  mapCartToOrderValues(
    cart: DoodCartModel | null,
    shopSlots?: number[],
    paymentMethod?: string,
    user?: DoodUserModel | null,
    basketId?: string,
    funnelComment?: string | null,
  ): DoodCreateOrderQuery | null {
    if (!cart) {
      return null;
    }

    const cartUser = cart[CartKeys.User];
    const orderType = cart[CartKeys.Type];
    const orderTypeCapabilities = this.orderTypeService.getCapabilities(orderType);
    const kioskId = this.kioskService.getKioskIdFromStore();

    const order: DoodCreateOrderQuery = {
      payment_service: paymentMethod ?? '',
      register_user_to_como: !!cart[CartKeys.RegisterUserToComo],
      type: orderType as DoodOrderType,
      marketplace: cart[CartKeys.Marketplace],
      multi_shop: cart[CartKeys.MultiShop],
      app_platform: cart[CartKeys.AppPlatform] as DoodAppPlatform,
      customer_device: cart[CartKeys.CustomerDevice] as DoodAppDevice,
      use_points: cart[CartKeys.UsePoints] as DoodUsePointModel,
      cart_id: cart[CartKeys.CartId],
      payment_phone_number: cart[CartKeys.PaymentPhoneNumber],
    };

    order.message = [cart[CartKeys.Message] ?? null, funnelComment ?? null]
      .filter(item => !!item)
      .join(' ; ');

    if (cart[CartKeys.Type] === OrderTypeValues.OnSite) {
      order.onsite_location_id = cart[CartKeys.OnSiteLocationId];
    }

    if (!basketId) {
      order.products = this.duplicateCartItem(cart[CartKeys.Products]);
      order.coupons = cart[CartKeys.Coupons] as DoodCouponModel[];
      order.cart_created_at = cart[CartKeys.CartCreatedAt];
    } else {
      order.cart = basketId;
    }

    if (cartUser) {
      order.user = cartUser;
    }

    if (!cart[CartKeys.MultiShop]) {
      order.shop = cart[CartKeys.Shop];
    }

    if (kioskId) {
      order.kiosk = kioskId;
    }

    if (orderTypeCapabilities.isMobilePhoneMandatory) {
      if (cart[CartKeys.ContactPhone]) {
        order.contact_phone_number = cart[CartKeys.ContactPhone];
      } else if (user?.[UserKeys.Phone]) {
        order.contact_phone_number = user[UserKeys.Phone];
      }
    }

    if (orderTypeCapabilities.preorderingAllowed) {
      order.wanted_at = this.calculateWantedAt(cart, shopSlots);
    } else {
      order.wanted_at = undefined;
    }

    if (!order.wanted_at) {
      order.wanted_at = undefined;
    }

    if (orderTypeCapabilities.isDeliveryAddressMandatory) {
      order.delivery_address = cart[CartKeys.DeliveryAddress] as Partial<DoodAddressModel>;
      if (
        this.deliverySelector.active &&
        !order.delivery_address?.post_code &&
        !order.delivery_address?.street_line_1
      ) {
        order.delivery_address = LocationUtils.mapINewDeliveryAddressToOdDeliveryAddress(
          this.deliverySelector.active,
        ) as DoodAddressModel;
      }
    }

    if (cart[CartKeys.DeliveryAddress]?.instructions) {
      order.delivery_comment = cart[CartKeys.DeliveryAddress]?.instructions;
    }

    if (cart[CartKeys.OnBehalfOf]) {
      order.on_behalf_of = (cart[CartKeys.OnBehalfOf] as DoodOrderUserModel) ?? null;
    }

    if (cart[CartKeys.Instructions]) {
      order.instructions = cart[CartKeys.Instructions];
    }

    const tipAmount = cart?.[CartKeys.Tip];
    if (tipAmount) {
      order.tip = {
        amount: Math.round(tipAmount),
      };
    }

    return order;
  }

  checkCartIsValid$(payment?: string): Observable<DoodValidateOrderResponse | null> {
    return this.authSelector.selectUser.pipe(
      map(user => {
        if (!user) {
          return of(null);
        }

        this.cartDispatcher.updateActive({
          ...(user?.id && {
            user: user?.id,
          }),
        });
        return of(user);
      }),
      switchMap(user$ => {
        return combineLatest([
          this.cart$,
          this.shopSelector.selectSlots,
          user$,
          this.basketSelector.selectId,
          this.cartFunnelSelector.selectOrderComment,
        ]);
      }),
      take(1),
      map(([cart, slots, user, basketId, funnelComment]) =>
        this.mapCartToOrderValues(cart, slots, payment, user, basketId, funnelComment),
      ),
      mergeMap(order => {
        const basket = this.basketSelector.basket;
        const productCount = order?.products?.length;

        if (basket?.[CartKeys.Id] && basket[CartKeys.CartItems]?.length < 1) return of(null);
        if (!basket?.[CartKeys.Id] && (!productCount || productCount < 1)) return of(null);
        if (!order || !order.user) return of(null);

        this.checkCartIsValidCallInProgress$.next(true);
        this.orderDispatcher.updateIsValidating(true);

        return this.ordersApiService.validateOrder$(order).pipe(
          tap(validation => {
            this.orderDispatcher.resetErrors();
            this.orderDispatcher.updateValidation(validation);
            this.orderDispatcher.updateIsValidating(false);
          }),
          tap(() => this.checkCartIsValidCallInProgress$.next(false)),
          catchError(error => {
            this.checkCartIsValidCallInProgress$.next(false);
            this.orderDispatcher.updateErrors(error.error);
            this.orderDispatcher.resetValidation();
            this.orderDispatcher.updateIsValidating(false);
            return throwError(() => error);
          }),
        );
      }),
      tap(cart => this.setCartId(cart)),
    );
  }

  checkAddressIsValid$(type?: string, location?: IValidateDeliveryAddress): Observable<boolean> {
    const marketplace = this.marketplaceSelector.marketplace;
    const shop = this.shopSelector.active;
    const isMultiShop = marketplace[MarketplaceKeys.CartScope] === 'MARKETPLACE';

    const cart = this.cartSelector.active;
    const deliveryAddress = location || cart?.[CartKeys.DeliveryAddress];
    const body = {
      type: type ?? cart?.type,
      multi_shop: isMultiShop,
      marketplace: marketplace.id,
      ...(!isMultiShop && {
        shop: shop?.id
      }),
      delivery_address: deliveryAddress,
    };
    return this.ordersApiService.validateAddress$(body).pipe(
      tap(result => this.trackSearchDeliveryAddress(deliveryAddress, result)),
      map(result => result?.is_address_valid === true),
      catchError(error => {
        this.trackSearchDeliveryAddress(deliveryAddress, {is_address_valid: false});
        throw error;
      }),
    );
  }

  private trackSearchDeliveryAddress(
    deliveryAddress?: IValidateDeliveryAddress,
    result?: { is_address_valid: boolean },
  ): void {
    this.analyticsService.trackSearchDeliveryAddress(
      deliveryAddress?.point?.coordinates?.[1],
      deliveryAddress?.point?.coordinates?.[0],
      JSON.stringify(deliveryAddress),
      result?.is_address_valid === true,
    );
  }

  createOrder$(payment: string): Observable<DoodOrderModel | null> {
    return combineLatest([
      this.cart$,
      this.shopSelector.selectSlots,
      this.authSelector.selectUser,
      this.basketSelector.selectId,
      this.cartFunnelSelector.selectOrderComment,
    ]).pipe(
      take(1),
      map(([cart, slots, user, basketId, funnelComment]) =>
        this.mapCartToOrderValues(cart, slots, payment, user, basketId, funnelComment),
      ),
      tap(cart => this.setCartId(cart)),
      switchMap(cart => (cart ? this.ordersApiService.createOrder$(cart) : of(null))),
      retryWhen(err => {
        return err.pipe(
          mergeMap((error, attempt) => {
            if (attempt > 5) {
              return throwError(() => 'An error occurred.');
            }
            if (
              this.containsAnyError(
                ['ANONYMOUS_ORDER_NOT_ALLOWED', 'MUST_BE_LOGGED_IN'],
                error?.error?.violations,
              )
            ) {
              console.warn('Not logged in - disconnect and restart');
              this.resetStateService.refreshUI().then(() => {
                window.location.reload();
              });
              return throwError(() => 'Not logged in');
            }
            return this.checkCartIsValid$().pipe(delay(5000));
          }),
        );
      }),
      tap(
        cart =>
          cart && this.eventTrackerService.dispatch(EventTrackerService.EventOrderCreated, cart),
      ),
      catchError(error => {
        this.orderDispatcher.updateErrors({error});
        this.orderDispatcher.resetValidation();
        if (this.containsAnyError(ErrorService.SLOT_ERRORS, error.error.violations)) {
          const wantedAt = this.cartSelector.active?.wanted_at;
          if (wantedAt) {
            this.modalsService.open(CartParametersModalComponent.handle);
          }
        }
        return throwError(() => error);
      }),
    );
  }

  loadOrderById$(id: string, shareCode?: string): Observable<DoodOrderModel> {
    return this.ordersApiService.getOrderById$(id, shareCode).pipe(
      tap(order => {
        this.orderDispatcher.upsertMany([order, ...(order.child_orders ?? [])]);
      }),
    );
  }

  getPrintedTicketById$(id: string, charByLine = 47): Observable<string[]> {
    return this.ordersApiService.getPrintedTicketById$(id, charByLine);
  }

  setActiveOrderToStore(order: DoodOrderModel): void {
    this.orderDispatcher.upsertOne(order);
    this.orderDispatcher.setActive(order.id);
  }

  loadShopSlots$(): Observable<number[]> {
    return combineLatest([
      this.shopSelector.selectActive,
      this.marketplaceSelector.selectMarketplace,
      this.cart$,
    ]).pipe(
      switchMap(([shop, marketplace, cartActive]) => {
        // TODO: Replace by enums and type definitions
        if (marketplace.cart_scope === 'MARKETPLACE' || shop) {
          return this.cartSelector.selectCart(
            marketplace.cart_scope === 'MARKETPLACE'
              ? `marketplace_${marketplace[MarketplaceKeys.Id]}`
              : `shop_${shop?.[ShopKeys.Id]}`,
          );
        } else {
          return of(cartActive);
        }
      }),
      debounceTime(1),
      switchMap(cart => {
        if (!cart) {
          return of([]);
        }
        return this.getOrderSlots$(cart, cart.wanted_at);
      }),
      tap(timeSlots => {
        this.shopDispatcher.setSlots(timeSlots ?? []);
      }),
    );
  }

  getOrderSlots$(
    cart: DoodCartModel | null,
    wantedAtDate?: Date | null,
    distributionMode?: string,
  ): Observable<number[]> {
    if (!cart) {
      return of([]);
    }

    if (!wantedAtDate) {
      wantedAtDate = new Date();
    }

    if (distributionMode) {
      cart = {
        ...cart,
        [CartKeys.Type]: distributionMode,
      };
    }

    wantedAtDate = new Date(
      DateUtils.dayjsInMarketplaceTimezone(wantedAtDate).startOf('day').format(),
    );
    const wantedAtDateUnix = Math.max(
      DateUtils.dayjsInMarketplaceTimezone(wantedAtDate).unix(),
      DateUtils.dayjsInMarketplaceTimezone().unix(),
    );

    const order = {
      time: wantedAtDateUnix,
      order: this.mapCartToOrderValues(cart),
    };

    if (!order.order) {
      return of([]);
    }
    return this.ordersApiService.getOrdersSlots$(order);
  }

  clearOrders(): void {
    this.deActiveOrderToStore();
    this.orderDispatcher.removeAll();
    this.orderDispatcher.clear();
  }

  removeOrderFromStore(id: string): void {
    this.orderDispatcher.removeOne(id);
  }

  deActiveOrderToStore(): void {
    this.orderDispatcher.resetActive();
  }

  setLoadingToStore(): void {
    this.orderDispatcher.updateIsLoading(true);
  }

  setDefaultPhone(): void {
    this.orderDispatcher.updateValidation(null);
    this.orderDispatcher.updateErrors({
      missing_number: true,
    });
  }

  removeDefaultPhone(): void {
    this.orderDispatcher.updateValidation(null);
    this.orderDispatcher.updateErrors({
      missing_number: false,
    });
  }

  getOrderById$(id: string): Observable<DoodOrderModel> {
    return this.ordersApiService.getOrderById$(id);
  }

  getActiveOrders$(userId: string, marketplaceId: string): Observable<DoodOrderModel[]> {
    return this.ordersApiService.getActiveOrders$(userId, marketplaceId).pipe(
      map(orders => orders.filter(order => this.isActiveOrder(order))),
      map(orders => this.unwindMultiBasketOrders(orders)),
    );
  }

  getHistoricalOrders$(userId: string, marketplaceId: string): Observable<DoodOrderModel[]> {
    return this.ordersApiService.getHistoricalOrders$(userId, marketplaceId).pipe(
      map(orders => orders.filter(order => this.isHistoricalOrder(order))),
      map(orders => this.unwindMultiBasketOrders(orders)),
    );
  }

  getOrdersTransactions$(id: string): Observable<DoodTransactionModel[]> {
    return this.ordersApiService
      .getOrdersTransactions$(id)
      .pipe(tap(transactions => this.transactionDispatcher.upsertMany(transactions)));
  }

  removeOrderErrors(): void {
    this.orderDispatcher.resetErrors();
  }

  mapIDeliveryAddress(address: Partial<DoodDeliveryAddressModel>): IValidateDeliveryAddress {
    return {
      street_line_1: address.street ?? '',
      point: address.point,
      city: address.city ?? '',
      post_code: address.postal_code ?? '',
      country: address.country ?? '',
      instructions: address.instructions ?? '',
    };
  }

  private unwindMultiBasketOrders(orders: DoodOrderModel[]): DoodOrderModel[] {
    const singleBasketOrders: DoodOrderModel[] = [];
    for (const mainOrder of orders) {
      if (mainOrder.multi_shop) {
        singleBasketOrders.push(...(mainOrder.child_orders ?? []));
      } else {
        singleBasketOrders.push(mainOrder);
      }
    }
    return singleBasketOrders;
  }

  private setCartId(cart: DoodValidateOrderQuery | IOrderValidate | null): void {
    if (!cart || !cart[CartKeys.CartCreatedAt] || !cart[CartKeys.CartId]) {
      return;
    }

    this.cartDispatcher.updateActive({
      cart_id: cart.cart_id,
      cart_created_at: cart.cart_created_at,
    });
  }

  private isActiveOrder(order: DoodOrderModel): boolean {
    const notActiveOrderStatus = [OrderStatusValues.Payment, OrderStatusValues.Cancelled];
    return !(notActiveOrderStatus as string[]).includes(order.status);
  }

  private isHistoricalOrder(order: DoodOrderModel): boolean {
    return order.status !== DoodOrderStatus.cancelled;
  }

  placeResultToDeliveryAddress(place?: PlaceResult): IValidateDeliveryAddress | undefined {
    if (!place) {
      return undefined;
    }

    const deliveryAddress: IValidateDeliveryAddress = {
      street_line_1: '',
      city: '',
      post_code: '',
      country: '',
      point: {
        coordinates: [
          (typeof place?.geometry?.location.lng === 'function'
            ? place?.geometry?.location.lng()
            : place.lng) as number,
          (typeof place?.geometry?.location.lat === 'function'
            ? place?.geometry?.location.lat()
            : place.lat) as number,
        ],
      },
      instructions: null,
    };
    place.address_components?.forEach((el: GeocoderAddressComponent) => {
      switch (el.types[0]) {
        case 'plus_code':
          deliveryAddress.street_line_1 = deliveryAddress.street_line_1 + ' ' + el.long_name;
          break;
        case 'street_number':
          deliveryAddress.street_line_1 = deliveryAddress.street_line_1 + ' ' + el.long_name;
          break;
        case 'route':
          deliveryAddress.street_line_1 = deliveryAddress.street_line_1 + ' ' + el.long_name;
          break;
        case 'locality':
          deliveryAddress.city = el.long_name;
          break;
        case 'postal_code':
          deliveryAddress.post_code = el.long_name;
          break;
        case 'country':
          deliveryAddress.country = el.short_name;
      }
    });
    deliveryAddress.street_line_1 = deliveryAddress.street_line_1.trim();
    return deliveryAddress;
  }

  // TODO Why not add 'code' property to IViolations instead of using type 'any' and risk not finding the 'code' property ?
  containsAnyError(errorCodes: string[], violations: IViolations[]): boolean {
    return (violations ?? []).some((violation: any) => {
      return errorCodes.includes(violation.code);
    });
  }
}
