<div class="flex justify-between items-center">
  <h1
    class="text-neutral-800 font-brand font-bold text-28px pb-5"
    translate="como.register.account-not-found.title"
  ></h1>
  <app-rounded-icon-button
    icon="icon-cross"
    (onClick)="onClose.emit($event)"
  ></app-rounded-icon-button>
</div>

<p
  class=""
  translate="como.register.account-not-found.text"
></p>

<div class="mt-10 flex flex-wrap xm:flex-nowrap justify-center">
  <div class="xm:pr-1 w-full xm:w-1/2">
    <app-button-atom
      size="L"
      [label]="'como.register.account-not-found.back-button' | translate"
      type="light"
      (click)="onGoToPhoneStep()"
      [full]="true"
    ></app-button-atom>
  </div>
  <!--    <div class="pt-2 xm:pt-0 xm:pl-1 w-full xm:w-1/2">-->
  <!--        <app-button-atom-->
  <!--            size="L"-->
  <!--            [label]="'como.register.account-not-found.register-button' | translate"-->
  <!--            type="primary"-->
  <!--            [full]="true"-->
  <!--            (click)="onRegister()"-->
  <!--        ></app-button-atom>-->
  <!--    </div>-->
</div>
