import { combineLatest, of } from 'rxjs';
import { StripeService } from 'ngx-stripe';
import { Component, OnInit } from '@angular/core';
import { switchMap, take, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

import { Paths } from '@config/paths.config';
import { OrderStoreSelector } from '@common/selectors/order.selector';

import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { StripeCustomService } from '@core/services/stripe-custom/stripe-custom.service';

@Component({
  selector: 'app-payment-confirm-page',
  templateUrl: './payment-confirm-page.component.html',
})
export class PaymentConfirmPageComponent implements OnInit {
  errorMessage!: string;

  constructor(
    private route: ActivatedRoute,
    private readonly router: Router,
    private orderSelector: OrderStoreSelector,
    private readonly stripeService: StripeService,
    private readonly routerHelper: RouterHelperService,
    private readonly stripeCustomService: StripeCustomService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        take(1),
        switchMap(params => {
          return combineLatest([
            of(params.payment_intent_client_secret),
            this.orderSelector.selectOrder(params.order_id),
          ]);
        }),
        take(1),
        switchMap(([intentClientSecret, order]) => {
          return combineLatest([
            this.stripeService.retrievePaymentIntent(intentClientSecret),
            of(order),
          ]);
        }),
        tap(([response, order]) => {
          if (response.paymentIntent?.status !== 'succeeded' || !order) {
            this.errorMessage = 'payment.bancontact.payment-error';
            setTimeout(() => {
              this.router.navigate([this.routerHelper.translateRoute(`/${Paths.Cart}`)]);
            }, 3000);
          }
          if (response.paymentIntent?.status === 'succeeded' && order) {
            this.stripeCustomService.handlePaymentResult(response, order);
          }
        }),
      )
      .subscribe();
  }
}
