import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DoodAppLoaderService {
  constructor(@Inject(DOCUMENT) private document: any) {}

  hide(): void {
    const el = this.document.querySelector('.dood-app-loader');
    if (el) {
      el.style.display = 'none';
    }
  }
}
