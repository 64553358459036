import {Router} from '@angular/router';
import {asyncScheduler, from, of} from 'rxjs';
import {StatusBar, Style} from '@capacitor/status-bar';
import {observeOn, switchMap, takeUntil} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {CartStoreRefiner} from '@common/refiners/cart.refiner';
import {ContentStoreSelector} from '@common/selectors/content.selector';
import {SettingsStoreSelector} from '@common/selectors/settings.selector';
import {AuthStoreSelector} from '@common/selectors/authentication.selector';

import {Paths} from '@config/paths.config';
import {DestroyerBase} from '@core/base/destroyer/destroyer.base';
import {IContentBuilderFieldColor} from '@core/models/content-builder-fields.model';

import {NativeService} from '@core/services/native/native.service';
import {RouterHelperService} from '@core/services/router-helper/router-helper.service';
import {NavigationHistoryService} from '@core/services/navigation-history/navigation-history.service';
import {ColorFieldTypesValues} from '@config/values/color-field-types.values';

@Component({
  selector: 'app-header-atom',
  templateUrl: './header-atom.component.html',
})
export class HeaderAtomComponent extends DestroyerBase implements OnInit {
  @Output() hamburgerClick = new EventEmitter<Event>();
  @Output() languageFlagClick = new EventEmitter<Event>();
  @Input() logoImageUrl: string | undefined;
  @Input() backgroundColor: IContentBuilderFieldColor = {
    value: 'transparent',
    type: ColorFieldTypesValues.Hex,
  };
  @Input() hamburgerColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() labelColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() iconColor: IContentBuilderFieldColor = {
    value: 'primary-600',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() iconBackgroundColor: IContentBuilderFieldColor = {
    value: 'primary-100',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() buttonTextColor: IContentBuilderFieldColor = {
    value: '#FFFFFF',
    type: ColorFieldTypesValues.Hex,
  };
  @Input() buttonBackgroundColor: IContentBuilderFieldColor = {
    value: 'primary-600',
    type: ColorFieldTypesValues.Palette,
  };

  @Input() displayHamburger = true;
  @Input() displayLogo = false;
  @Input() displayBackButton = true;
  @Input() backLinkUrl!: string;
  @Input() leftBlocks?: any;
  @Input() rightBlocks?: any;
  @Input() navigationLinks?: any[];
  @Input() title?: string;
  @Input() positionAbsoluteOnMobile = false;
  @Input() positionAbsoluteOnDesktop = false;
  @Input() languageFlagUrl?: string;

  title$ = this.contentSelector.selectHeaderTitle.pipe(observeOn(asyncScheduler));
  isShopSearchAddressVisible$ = this.contentSelector.selectHeaderIsShopSearchAddressVisible.pipe(
    observeOn(asyncScheduler),
  );
  isShopSearchParametersVisible$ =
    this.contentSelector.selectHeaderIsShopSearchParametersVisible.pipe(observeOn(asyncScheduler));
  isCartParametersVisible$ = this.contentSelector.selectHeaderIsCartParametersVisible.pipe(
    observeOn(asyncScheduler),
  );
  isCartButtonVisible$ = this.contentSelector.selectHeaderIsCartButtonVisible.pipe(
    observeOn(asyncScheduler),
  );

  cartProducts$ = this.cartRefiner.selectActiveCartProducts;
  isNavigationToCartInProgress = false;

  isOpenStaticNav = false;

  isNotched = false;
  isMobileScreenType = false;

  constructor(
    private readonly router: Router,
    private cartRefiner: CartStoreRefiner,
    private authSelector: AuthStoreSelector,
    private contentSelector: ContentStoreSelector,
    private settingsSelector: SettingsStoreSelector,
    private readonly routerHelper: RouterHelperService,
    private readonly navigationHistoryService: NavigationHistoryService,
  ) {
    super();
  }

  ngOnInit(): void {
    // Type
    this.settingsSelector.selectDeviceIsMobileScreen
      .pipe(takeUntil(this._destroyerRef))
      .subscribe(isMobileScreenType => {
        this.isMobileScreenType = isMobileScreenType;
      });

    // Navigation
    this.authSelector.selectStatusStep.pipe(takeUntil(this._destroyerRef)).subscribe(_step => {
      if (!_step) {
        this.isNavigationToCartInProgress = false;
      }
    });

    // Notch
    from(NativeService.isNotched())
      .pipe(
        takeUntil(this._destroyerRef),
        switchMap(_isNotched => {
          this.isNotched = _isNotched;
          return _isNotched ? from(StatusBar.setStyle({style: Style.Dark})) : of();
        }),
      )
      .subscribe();
  }

  onClick(): void {
    this.hamburgerClick.emit();
  }

  onLanguageFlagClick(): void {
    this.languageFlagClick.emit();
  }

  toggleStaticNav(): void {
    this.isOpenStaticNav = !this.isOpenStaticNav;
  }

  back(): void {
    if (!this.backLinkUrl) {
      this.navigationHistoryService.goBack();
    } else if (!this.routerHelper.isLinkExternal(this.backLinkUrl)) {
      this.router.navigate([
        this.backLinkUrl ? this.routerHelper.translateRoute(this.backLinkUrl) : this.backLinkUrl,
      ]);
    } else {
      window.location.href = this.backLinkUrl;
    }
  }

  navigateToCart(): void {
    this.isNavigationToCartInProgress = true;
    this.router.navigate([this.routerHelper.translateRoute(Paths.Cart)]);
  }
}
