<div class="pt-4">
  <label class="block font-accent font-bold mt-5 mb-3 md:mt-6 text-md"
    >{{ title }}
    <span *ngIf="required">&#42;</span>
  </label>
  <app-input-multi-atom
    [type]="'text'"
    [placeholder]="placeholder"
    [value]="value"
    (valueEmitter)="onValueChange($event)"
  ></app-input-multi-atom>
</div>
