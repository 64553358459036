import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-shop-means-of-payment-atom',
  templateUrl: './shop-means-of-payment-atom.component.html',
})
export class ShopMeansOfPaymentAtomComponent {
  @Input()
  set payments(payments: string[]) {
    this.paymentsString = payments?.join(', ');
  }

  paymentsString!: string;
}
