<ng-container *ngIf="displayLoading">
  <div
    class="bg-white fixed top-0 bottom-0 left-0 right-0 h-screen flex justify-center items-center"
    [style.z-index]="((index$ | async) ?? 0) + 50"
  >
    <div class="text-base flex flex-row items-center">
      <img
        src="assets/images/spinner.svg"
        alt=""
        class="mr-2"
      />
      {{ 'como.sso-loading-modal.connecting-loyalty-account' | translate }}
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!displayLoading">
  <app-modal-filler-atom (onClick)="close()"></app-modal-filler-atom>
  <div
    class="fixed right-0 left-0 shadow overflow-y-auto mdMax:bottom-0 mdMax:max-h-85-screen mdMax:max-w-screen-sm mdMax:mx-auto md:flex md:justify-center md:items-center md:w-full md:h-full md:top-0 md:left-0"
    [style.z-index]="((index$ | async) ?? 0) + 50"
  >
    <div
      class="bg-white p-5 md:p-7 rounded-t-3xl md:rounded-3xl md:max-w-lg md:w-2/5"
      [style.z-index]="((index$ | async) ?? 0) + 50"
    >
      <h1
        class="text-neutral-800 font-brand font-bold text-28px pb-5"
        translate="como.sso-loading-modal.connecting-loyalty-account"
      ></h1>
      <form [formGroup]="form">
        <div
          *ngIf="displayEmailForm"
          class="mb-6"
        >
          <label
            for="email"
            class="block mb-1 text-sm leading-5 font-base font-normal text-neutral-600"
            >Email</label
          >
          <input
            id="email"
            type="text"
            formControlName="email"
            (keyup.enter)="onSubmit()"
            appAutoFocus
            class="flex w-full px-5 py-8xs bg-neutral-50 text-base font-base font-normal leading-5 border border-solid border-neutral-300"
            [class.border-error-700]="emailErrorAlreadyUsed"
          />
          <div
            *ngIf="emailErrorAlreadyUsed"
            class="mt-2 text-xs font-base font-normal text-error-700"
          >
            {{ 'como.sso-loading-modal.email-aleady-used' | translate }}
          </div>
        </div>
        <div class="flex items-start">
          <input
            id="legals"
            type="checkbox"
            formControlName="legals"
            class="m-3 form-checkbox h-6 w-6 border text-primary-600"
          />
          <label
            for="legals"
            class="mt-3 font-base text-sm leading-5 text-neutral-800"
          >
            <span translate="signup.cgv.accept"></span>&nbsp;
            <a
              class="underline"
              target="_blank"
              href="https://www.dood.com/corporate/cgv"
            >
              <span>{{ 'signup.cgv.cgv' | translate }}</span> </a
            >&nbsp; <span translate="signup.cgv.and"></span>&nbsp;
            <a
              class="underline"
              target="_blank"
              href="https://www.dood.com/corporate/cgu/"
            >
              <span translate="signup.cgv.cgu"></span> </a
            >.
          </label>
        </div>
      </form>
      <div class="flex flex-col pt-6">
        <div class="pt-1 flex xm:flex-nowrap gap-2">
          <div class="w-full xm:w-1/2">
            <app-button-atom
              size="M"
              [label]="'como.cancel' | translate"
              (click)="close()"
              type="light"
              [full]="true"
            ></app-button-atom>
          </div>
          <div class="w-full xm:w-1/2">
            <app-button-atom
              [iconUrl]="isValidating ? 'assets/images/spinner.svg' : ''"
              [disabled]="isValidating || this.form.invalid"
              size="M"
              [label]="'como.validate' | translate"
              type="primary"
              [full]="true"
              (click)="onSubmit()"
            ></app-button-atom>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
