import { DoodShopModel } from '@core/models/shop.model';
import { DoodMarketplaceModel } from '@core/models/marketplace.model';
import { DoodCartApiResponse } from '@core/models/cart-api.model';
import { HydrationState, createHydratedAdapter } from '@store/_abstract/hydrated';

// TODO: Check how to improve types
export interface BasketCartState extends DoodCartApiResponse {
  coupons: string[];
  delivery_provider: string | null;
  marketplace: DoodMarketplaceModel | null;
  shop: string | DoodShopModel | null;
}

export interface BasketState extends HydrationState {
  basket: BasketCartState;
}

export const BASKET_STORE_KEY = 'Basket';

export const BASKET_STORE_HYDRATION_ADAPTER = createHydratedAdapter();

export const BASKET_STORE_BASKET_INITIAL_STATE: BasketCartState = {
  id: '',
  cart_items: [],
  currency: '',
  delivery_provider: null,
  marketplace: null,
  multi_shop: false,
  shared: false,
  share_code: '',
  type: '',
  shop: null,
  wanted_at: null,
  coupons: [],
  confirmed_by: [],
  sub_carts: [],
  total: 0,
  order: '',
  joined: false,
  rejected: false,
  created: false,
  itemEditId: '',
  removeUserId: '',
  user: {
    id: '',
    roles: [],
    firstname: '',
    is_anonymous: true,
  },
};

export const BASKET_STORE_INITIAL_STATE: BasketState =
  BASKET_STORE_HYDRATION_ADAPTER.getInitialState({
    basket: BASKET_STORE_BASKET_INITIAL_STATE,
  });
