<div class="flex justify-between items-center">
  <h1
    class="text-neutral-800 font-brand font-bold text-28px pb-5"
    translate="como.create-or-link-account.otp-step.title"
  ></h1>
  <app-rounded-icon-button
    icon="icon-cross"
    (onClick)="onClose.emit($event)"
  ></app-rounded-icon-button>
</div>

<form [formGroup]="form">
  <label
    for="comoRegistrationOtpInput"
    class="block mb-1 text-sm leading-5 font-base font-normal text-neutral-600"
    translate="como.link-account.enter-auth-code"
  ></label>
  <input
    id="comoRegistrationOtpInput"
    type="text"
    formControlName="authCode"
    (keyup.enter)="onSubmit()"
    class="flex w-full py-8xs px-5 bg-neutral-50 text-base font-base font-normal leading-5 border border-solid border-neutral-300"
    [class.border-error-700]="
      form.controls?.authCode?.errors &&
      (form.controls?.authCode?.dirty || form.controls?.authCode?.touched)
    "
  />

  <div class="hidden kiosk:block">
    <app-numeric-keypad-atom
      (numberClick)="onKeypadNumberClick($event)"
      (deleteClick)="onKeypadDeleteClick()"
    ></app-numeric-keypad-atom>
  </div>

  <div
    class="text-xs font-base font-normal text-error-700 mt-2"
    *ngIf="
      form.controls?.authCode?.invalid &&
      (form.controls?.authCode?.dirty || form.controls?.authCode?.touched)
    "
    translate="como.link-account.errors.invalid-auth-code"
  ></div>
  <div
    class="text-xs font-base font-normal text-error-700 mt-2"
    *ngIf="errorMessage"
    [translate]="errorMessage"
  ></div>
</form>

<div class="mt-10 flex flex-wrap xm:flex-nowrap">
  <div class="xm:pr-1 w-full xm:w-1/2">
    <app-button-atom
      size="L"
      [label]="'como.register.otp.back-button' | translate"
      type="light"
      (click)="onGoToPreviousStep()"
      [full]="true"
    ></app-button-atom>
  </div>
  <div class="pt-2 xm:pt-0 xm:pl-1 w-full xm:w-1/2">
    <app-button-atom
      size="L"
      [label]="'como.register.otp.continue-button' | translate"
      type="primary"
      [full]="true"
      [iconUrl]="isValidating ? 'assets/images/spinner.svg' : ''"
      [disabled]="isValidating || !form.valid"
      (click)="onSubmit()"
    ></app-button-atom>
  </div>
</div>
