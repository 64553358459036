<div class="flex flex-col h-screen flex-1 overflow-hidden">
  <div
    id="header"
    class="flex-shrink-0"
    [ngStyle]="getBackgroundStyles()"
    [style.backgroundColor]="backgroundColor | contentBuilderColor"
    [style.color]="textColor | contentBuilderColor"
  >
    <app-block-list [blockIdList]="header"></app-block-list>
  </div>
  <div class="flex flex-1 h-full overflow-hidden">
    <div
      class="w-full h-full flex flex-1 flex-col"
      [style.backgroundColor]="backgroundColor | contentBuilderColor"
      [style.color]="textColor | contentBuilderColor"
    >
      <div
        class="flex cursor-pointer items-center gap-2.5 mb-5"
        (click)="back()"
      >
        <i class="icon icon-chevron-down rotate-90 text-xs ml-5"></i>
        <div class="text-2xl font-bold">Retour</div>
      </div>
      <div class="flex-1 overflow-y-auto">
        <app-block-list [blockIdList]="body"></app-block-list>
      </div>
    </div>
  </div>
  <div
    [style.backgroundColor]="backgroundColor | contentBuilderColor"
    [style.color]="textColor | contentBuilderColor"
  >
    <app-block-list [blockIdList]="footer"></app-block-list>
  </div>
</div>
