import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';

import {
  SELECT_ANCHORS_ALL,
  SELECT_ANCHORS_ENTITY,
  SELECT_ANCHORS_ERROR,
} from '@store/anchor/anchor.selection';
import { DoodAnchorModel } from '@store/anchor/anchor.model';

@Injectable({ providedIn: 'root' })
export class AnchorStoreSelector implements OnDestroy {
  private readonly _destroyerRef = new Subject<boolean>();

  anchors: DoodAnchorModel[] = [];

  // Error
  selectError = this.store.select(SELECT_ANCHORS_ERROR);

  // Anchors
  selectAnchors = this.store.select(SELECT_ANCHORS_ALL);
  selectAnchor = (id: string): Observable<DoodAnchorModel | undefined> =>
    this.store.select(SELECT_ANCHORS_ENTITY(id));

  constructor(private store: Store) {
    this.selectAnchors.pipe(takeUntil(this._destroyerRef)).subscribe(_anchors => {
      this.anchors = _anchors;
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe();
  }

  private _unsubscribe(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
  }
}
