import { Injectable } from '@angular/core';
import { switchMap, mergeMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  DELIVERY_STORE_ACTIVE_ACTIONS,
  DELIVERY_STORE_API_ACTIONS,
  DELIVERY_STORE_LOCAL_ACTIONS,
} from '@store/delivery';
import { DeliveryAddressApiService } from '@core/services/api/delivery-address/delivery-address-api.service';

@Injectable({
  providedIn: 'root',
})
export class DeliveryEffects {
  constructor(
    private _actions: Actions,
    private _deliveryAddressApiService: DeliveryAddressApiService,
  ) {}

  fetchAddresses = createEffect(() => {
    return this._actions.pipe(
      ofType(DELIVERY_STORE_API_ACTIONS.fetchAddresses),
      switchMap(() => this._deliveryAddressApiService.getDeliveryAddresses$()),
      mergeMap(addresses => {
        return [
          DELIVERY_STORE_LOCAL_ACTIONS.updateIsLoading({ isLoading: true }),
          DELIVERY_STORE_API_ACTIONS.fetchAddressesSuccess({
            addresses,
          }),
        ];
      }),
    );
  });

  fetchAddressesSuccess = createEffect(() => {
    return this._actions.pipe(
      ofType(DELIVERY_STORE_API_ACTIONS.fetchAddressesSuccess),
      mergeMap(({ addresses }) => {
        return [
          DELIVERY_STORE_ACTIVE_ACTIONS.setAll({ entities: addresses }),
          DELIVERY_STORE_LOCAL_ACTIONS.updateIsLoaded({ isLoaded: true }),
          DELIVERY_STORE_API_ACTIONS.fetchAddressesComplete(),
        ];
      }),
    );
  });

  fetchAddressesFailure = createEffect(() => {
    return this._actions.pipe(
      ofType(DELIVERY_STORE_API_ACTIONS.fetchAddressesFailure),
      mergeMap(({ error }) => {
        return [
          DELIVERY_STORE_LOCAL_ACTIONS.updateError({ error }),
          DELIVERY_STORE_LOCAL_ACTIONS.updateIsLoaded({ isLoaded: false }),
          DELIVERY_STORE_API_ACTIONS.fetchAddressesComplete(),
        ];
      }),
    );
  });

  fetchAddressesComplete = createEffect(() => {
    return this._actions.pipe(
      ofType(DELIVERY_STORE_API_ACTIONS.fetchAddressesComplete),
      mergeMap(() => {
        return [DELIVERY_STORE_LOCAL_ACTIONS.updateIsLoading({ isLoading: false })];
      }),
    );
  });
}
