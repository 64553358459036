import { Component, Input } from '@angular/core';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-overview-cart-additional-atom',
  templateUrl: './overview-cart-additional-atom.component.html',
})
export class OverviewCartAdditionalAtomComponent {
  @Input() text!: string;
  @Input() icon!: string;
  @Input() price!: number;
  @Input() mustTranslate: boolean = false;
  @Input() textColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() secondaryTextColor: IContentBuilderFieldColor = {
    value: 'neutral-500',
    type: ColorFieldTypesValues.Palette,
  };
}
