<div
  class="flex shadow shadow-xs justify-center items-center text-center mx-auto"
  [style.backgroundColor]="categoriesBackgroundColor | contentBuilderColor"
>
  <app-rounded-icon-button
    *ngIf="!activeMenu && displayBurgerNavigation"
    class="px-5 lg:pl-12"
    [outlined]="true"
    [icon]="'icon-hamburger font-bold'"
    [size]="'sm'"
    [fontSize]="'text-xs'"
    [overrideBackgroundColor]="burgerBackgroundColor"
    [overrideColor]="burgerIconColor"
    (click)="toggleMenu()"
  ></app-rounded-icon-button>

  <div
    class="bg-white w-full overflow-x-auto pr-4 scrollbar-hide sticky-tab-list"
    [class.pt-10]="isNotched"
    [class.shadow-none]="isNotched"
    [class.border-b]="isNotched"
    [ngClass]="{
      'block bg-white fixed top-0 left-0 z-120 h-screen w-screen py-3': activeMenu,
      'flex items-center': !activeMenu,
      'px-5 lg:px-12': !displayBurgerNavigation,
      'lg:pr-12': displayBurgerNavigation,
    }"
    #scrollSection
  >
    <div
      *ngIf="activeMenu || search"
      class="w-full text-left"
      [ngClass]="{
        'px-5 lg:px-12': activeMenu,
      }"
    >
      <app-rounded-icon-button
        *ngIf="activeMenu"
        class="mr-4"
        [outlined]="true"
        [icon]="'icon-cross font-bold'"
        [size]="'sm'"
        [fontSize]="'text-xs'"
        [overrideBackgroundColor]="burgerBackgroundColor"
        [overrideColor]="burgerIconColor"
        (click)="toggleMenu()"
      ></app-rounded-icon-button>
      <app-rounded-icon-button
        *ngIf="search"
        class="mr-4"
        [outlined]="true"
        [icon]="'icon-zoom font-bold'"
        [size]="'sm'"
        [fontSize]="'text-base'"
        [overrideBackgroundColor]="burgerBackgroundColor"
        [overrideColor]="burgerIconColor"
        (click)="toggleSearch()"
      ></app-rounded-icon-button>
    </div>
    <div class="flex justify-between items-center w-full border-neutral-300">
      <div
        [ngClass]="{
          'block px-5 lg:px-12': activeMenu,
          'flex items-center': !activeMenu,
        }"
      >
        <button
          class="flex items-center relative border-b-4 font-accent font-semibold py-3 lg:py-5 mr-3 whitespace-nowrap"
          [class.scroll-target-item]="activeItem === item"
          [class.px-2]="!activeMenu"
          [style.borderColor]="
            activeItem === item && !activeMenu
              ? (categoriesActiveColor | contentBuilderColor)
              : 'transparent'
          "
          [style.color]="
            activeItem === item
              ? (categoriesActiveColor | contentBuilderColor)
              : (categoriesInactiveColor | contentBuilderColor)
          "
          *ngFor="let item of displayItems"
          (click)="tabClick(item)"
        >
          {{ item.label }}
          <span
            *ngIf="item.icon"
            class="text-8px transform rotate-180 icon icon-{{ item.icon }} ml-2"
          ></span>
        </button>
      </div>
    </div>
  </div>
</div>
