<label class="relative flex justify-between items-center group text-md w-full">
  {{ label }}
  <input
    (click)="toggleValue()"
    [checked]="checked"
    class="absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md"
    type="checkbox"
  />
  <span
    class="w-16 h-10 flex items-center flex-shrink-0 ml-4 p-1 bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-primary-600 after:w-8 after:h-8 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-6 group-hover:after:translate-x-1"
  ></span>
</label>
