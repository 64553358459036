<div
  class="z-50"
  [ngClass]="{
    sticky: positionSticky,
  }"
  [style.top]="positionSticky ? positionStickyTop + 'px' : ''"
  [style.backgroundColor]="backgroundColor | contentBuilderColor: 'bg-white'"
>
  <div class="flex overflow-x-auto px-5 pb-4 pt-4 scrollbar-hide lg:px-12 lg:pb-6 lg:pt-6">
    <ng-container *ngIf="slides">
      <app-block-list [blockIdList]="slides"></app-block-list>
    </ng-container>
  </div>
</div>
