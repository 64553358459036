import {Component, Input, OnInit} from '@angular/core';
import {IContentBuilderFieldColor} from "@core/models/content-builder-fields.model";

@Component({
  selector: 'app-simple-header-block',
  templateUrl: './simple-header-block.component.html',
  styles: []
})
export class SimpleHeaderBlockComponent implements OnInit {

  @Input() leftItems: any[] = [];
  @Input() middleItems: any[] = [];
  @Input() rightItems: any[] = [];

  @Input() height = '70'
  @Input() backgroundColor?: IContentBuilderFieldColor;


  constructor() {
  }

  ngOnInit(): void {
  }

}
