<div
  class="flex flex-col gap-4 rounded-m bg-white px-5 py-6 lg:gap-6"
  [style.color]="textColor | contentBuilderColor"
>
  <div>
    <app-order-status-tag-atom [orderStatus]="orderStatus"></app-order-status-tag-atom>
  </div>

  <ng-container *ngIf="orderStatus === 'PAYMENT'">
    <div
      class="fixed bottom-0 left-0 right-0 top-0 flex h-screen items-center justify-center bg-white"
      [style.z-index]="90"
    >
      <div class="flex flex-row items-center text-md md:text-xl lg:text-4xl">
        <img
          src="assets/images/spinner.svg"
          alt=""
          class="pr-2"
        />
        {{ 'order-status.confirmation-payment' | translate }}
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="orderStatus === 'LOOKING_FOR_DELIVERY'">
    <div
      class="fixed bottom-0 left-0 right-0 top-0 flex h-screen items-center justify-center bg-white"
      [style.z-index]="90"
    >
      <div class="flex flex-row items-center text-md md:text-xl lg:text-4xl">
        <img
          src="assets/images/spinner.svg"
          alt=""
          class="pr-2"
        />
        {{ 'order-status.looking-for-delivery' | translate }}
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="orderStatus === 'CANCELLED'">
    <p
      class="font-base text-sm font-normal md:text-md"
      [style.color]="secondaryTextColor | contentBuilderColor"
      [ngSwitch]="order.cancellation_reason"
    >
      <span *ngSwitchCase="'NO_COURIER_AVAILABLE'">
        Votre commande a été annulée car aucun livreur n'est disponible actuellement.
      </span>

      <span *ngSwitchCase="'AREA_NOT_DELIVERABLE'">
        Votre commande a été annulée car l'adresse ne peut être livrée.
      </span>

      <span *ngSwitchDefault> Votre commande a été annulée. </span>
    </p>
  </ng-container>

  <ng-container *ngIf="!['PAYMENT', 'LOOKING_FOR_DELIVERY', 'CANCELLED'].includes(orderStatus)">
    <div>
      <p
        *ngIf="order.type === orderTypeValues.ClickAndCollect"
        class="font-base text-sm font-normal md:text-md"
        [style.color]="secondaryTextColor | contentBuilderColor"
      >
        {{ 'order.pickup-location' | translate }}
      </p>
      <p
        *ngIf="order.type === orderTypeValues.OnSite || order.type === orderTypeValues.EatIn"
        class="font-base text-sm font-normal md:text-md"
        [style.color]="secondaryTextColor | contentBuilderColor"
      >
        {{ 'order.point-of-sale' | translate }}
      </p>

      <div
        *ngIf="
          order.type === orderTypeValues.ClickAndCollect ||
          order.type === orderTypeValues.EatIn ||
          order.type === orderTypeValues.OnSite
        "
      >
        <p class="font-accent text-md font-bold md:text-29px">
          {{ shopName }}
        </p>
        <p
          *ngIf="order.pickup_location?.street_line1"
          class="font-accent text-md font-bold md:text-29px"
        >
          {{ order.pickup_location?.street_line1 }}
          - {{ order.pickup_location?.post_code }}
          {{ order.pickup_location?.city }}
        </p>
      </div>
    </div>

    <div *ngIf="order.type === orderTypeValues.Delivery || order.type === orderTypeValues.Shipping">
      <p
        *ngIf="order.type === orderTypeValues.Delivery"
        class="font-base text-sm font-normal md:text-md"
        [style.color]="secondaryTextColor | contentBuilderColor"
      >
        {{ 'order.delivery-location' | translate }}
      </p>
      <p
        *ngIf="order.type === orderTypeValues.Shipping"
        class="font-base text-sm font-normal md:text-md"
        [style.color]="secondaryTextColor | contentBuilderColor"
      >
        {{ 'order.shipping-location' | translate }}
      </p>
      <div>
        <p class="font-accent text-md font-bold md:text-29px">
          {{ order.delivery_address?.addressee }}
        </p>
        <p class="font-accent text-md font-bold md:text-29px">
          {{ order.delivery_address?.post_code }}
          {{ order.delivery_address?.city }}
        </p>
      </div>
    </div>

    <div *ngIf="order.type === orderTypeValues.ClickAndCollect">
      <p
        class="font-base text-sm font-normal md:text-md"
        [style.color]="secondaryTextColor | contentBuilderColor"
      >
        {{ 'order.withdrawal-time' | translate }}
      </p>
      <p class="font-accent text-md font-bold md:text-29px">
        {{ daysUntilReadyString }} {{ 'order.at' | translate }}
        {{ readyAt }}
      </p>
    </div>

    <div *ngIf="order.type === orderTypeValues.EatIn">
      <p
        class="font-base text-sm font-normal md:text-md"
        [style.color]="secondaryTextColor | contentBuilderColor"
      >
        {{ 'order.planned-collection-at' | translate }}
      </p>
      <p class="font-accent text-md font-bold md:text-29px">
        {{ daysUntilReadyString }} {{ 'order.at' | translate }}
        {{ readyAt }}
      </p>
    </div>

    <div *ngIf="order.type === orderTypeValues.OnSite">
      <p
        class="font-base text-sm font-normal md:text-md"
        [style.color]="secondaryTextColor | contentBuilderColor"
      >
        {{ 'order.created_at' | translate }}
      </p>
      <p class="font-accent text-md font-bold md:text-29px">
        {{ daysUntilReadyString }} {{ 'order.at' | translate }}
        {{ readyAt }}
      </p>
    </div>

    <div *ngIf="order.type === orderTypeValues.Delivery && estimatedArrival">
      <p
        class="font-base text-sm font-normal md:text-md"
        [style.color]="secondaryTextColor | contentBuilderColor"
      >
        {{ 'order.delivery-time' | translate }}
      </p>
      <p class="font-accent text-md font-bold md:text-29px">
        {{ daysUntilEstimatedTimeOfArrivalString }}
        {{ 'order.at' | translate }} {{ estimatedArrival }}
      </p>
    </div>

    <div>
      <p
        class="font-base text-sm font-normal md:text-md"
        [style.color]="secondaryTextColor | contentBuilderColor"
      >
        {{ 'order.order-number' | translate }}
      </p>
      <p class="font-accent text-md font-bold md:text-29px">
        {{ orderNumber }}
      </p>
    </div>

    <div>
      <p
        *ngIf="locationName"
        class="font-base text-sm font-normal md:text-md"
        [style.color]="secondaryTextColor | contentBuilderColor"
      >
        {{ 'order.location-name' | translate }}
      </p>
      <p
        *ngIf="locationName"
        class="font-accent text-md font-bold md:text-29px"
      >
        {{ locationName }}
      </p>
    </div>

    <div class="flex flex-wrap">
      <div
        *ngIf="displayDetailsButton"
        class="mb-2 mr-2.5"
      >
        <app-button-atom
          [size]="'M'"
          [label]="'order.detail' | translate"
          [type]="'light'"
          [color]="'primary'"
          (clickEventEmitter)="goToOrderDetail(order)"
        ></app-button-atom>
      </div>
      <app-button-atom
        class="mb-2 mr-2.5"
        *ngIf="displayLocalizeButton && order.type === orderTypeValues.ClickAndCollect"
        [size]="'M'"
        [label]="'order.locate' | translate"
        [icon]="'icon-map-pin'"
        [type]="'light'"
        [color]="'primary'"
        (clickEventEmitter)="locateShop(order)"
      ></app-button-atom>
      <app-button-atom
        class="mb-2"
        *ngIf="displayCustomButton && customButtonText && customButtonUrl"
        [link]="customButtonUrl"
        [size]="'M'"
        [label]="customButtonText"
        [type]="'light'"
        [color]="'primary'"
      ></app-button-atom>
      <app-button-atom
        class="mb-2"
        *ngIf="forceType(order).uber_direct_tracking_u_r_l"
        [size]="'M'"
        [label]="'Suivre ma livraison'"
        [type]="'light'"
        [color]="'primary'"
        icon="icon-bike"
        (clickEventEmitter)="goToDeliveryTrackingUrl(forceType(order).uber_direct_tracking_u_r_l)"
      ></app-button-atom>
    </div>
  </ng-container>
</div>
