<div
  *ngIf="order"
  class="flex min-h-screen flex-col text-center"
>
  <div
    *ngIf="headerImage"
    class="h-720px w-full bg-cover bg-center"
    [ngStyle]="{ 'background-image': 'url(' + headerImage.url + ')' }"
  ></div>
  <div
    class="flex flex-1 flex-col items-center justify-between px-12 pb-14 pt-28"
    [ngStyle]="getBackgroundStyles()"
  >
    <div *ngIf="order.status === 'PAYMENT'">
      <p class="mb-5 text-center text-5xl">Confirmation du paiement</p>
      <p class="mb-5 text-center text-5xl">Veuillez patienter</p>
    </div>
    <ng-container *ngIf="order.status !== 'PAYMENT'">
      <div class="flex flex-col gap-8">
        <div class="text-5xl font-bold">
          {{ heading }}
        </div>
        <div>
          <p class="text-12xl font-bold">{{ order.number }}</p>
          <div *ngIf="order?.child_orders?.length">
            <div
              *ngFor="let childOrder of order.child_orders"
              class="mb-8 md:mb-16"
            >
              <p
                class="text-accent mb-5 text-center text-2xl font-bold text-neutral-800 md:text-4xl"
              >
                {{ childOrder.shop?.name }}
              </p>
              <p
                *ngIf="childOrder.shop?.kiosk_additional_information"
                class="mb-5 text-center text-2xl md:text-4xl"
              >
                {{ childOrder.shop?.kiosk_additional_information }}
              </p>
            </div>
          </div>
          <ng-container *ngIf="displayOrderReadyAt">
            <p
              *ngIf="order.ready_at"
              class="mb-5 text-center text-2xl md:text-4xl"
            >
              Heure de retrait
            </p>
            <p
              *ngIf="orderReadyAt"
              class="text-accent mb-8 text-center text-4xl font-bold text-neutral-800 md:mb-16 md:text-7xl"
            >
              {{ orderReadyAt }}
            </p>
          </ng-container>
        </div>
      </div>
      <div class="flex flex-col gap-4">
        <div class="text-5xl font-bold">
          <p *ngIf="footer">{{ footer }}</p>
          <p *ngIf="footer1">{{ footer1 }}</p>
          <p
            *ngIf="footer2"
            class="mt-4"
          >
            <app-text-atom [content]="footer2"></app-text-atom>
          </p>
        </div>
        <img
          *ngIf="logo?.url"
          [src]="logo?.url"
          class="logo mt-12 max-w-28"
          alt="img"
          loading="lazy"
        />
      </div>
    </ng-container>

    <div class="flex w-full flex-col self-end">
      <app-button-atom
        [label]="'Nouvelle commande'"
        type="kiosk-primary"
        [full]="true"
        [overrideBgColor]="buttonBackgroundColor"
        [overrideTextColor]="buttonTextColor"
        size="XXL"
        thickSize="XXL"
        (click)="goToHome()"
      ></app-button-atom>
    </div>
  </div>
</div>
