<div
  class="flex h-full flex-1 flex-col items-center justify-center"
>
  <h1 class="text-center text-6xl font-bold mb-16"
      [style.color]="headingTextColor | contentBuilderColor"
  >
    {{ title }}
  </h1>
  <div class="gap-12 flex flex-col flex-wrap items-center justify-center">
    <a [link]="firstCardLink">
      <app-kiosk-active-button-atom
        [inactiveBackground]="mainCardBackgroundColor"
        [inactiveTextColor]="mainCardTextColor"
        [label]="firstCardLabel"
      >
      </app-kiosk-active-button-atom>
    </a>
    <a [link]="secondCardLink">
      <app-kiosk-active-button-atom
        [inactiveBackground]="secondaryCardBackgroundColor"
        [inactiveTextColor]="secondaryCardTextColor"
        [label]="secondCardLabel"
      >
      </app-kiosk-active-button-atom>
    </a>
  </div>
</div>
