import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, interval, of, Subject } from 'rxjs';
import { startWith, switchMap, take, takeUntil, takeWhile } from 'rxjs/operators';
import { DoodOpenCheckTransaction } from '@core/models/check.model';

import { Paths } from 'src/app/config/paths.config';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';

import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { OnSiteLocationsService } from '@core/services/on-site-locations/on-site-locations.service';
import { PaymentAtTableStoreSelector } from '@common/selectors/payment-at-table.selector';
import { PaymentAtTableStoreDispatcher } from '@common/dispatchers/payment-at-table.dispatcher';
import { CheckTransactionKeys } from '@config/keys/check.keys';
import { ModalsService } from '@core/services/modals/modals.service';
import {
  ReceiveReceiptModalComponent,
  ReceiveReceiptModalReceiptType,
} from '@shared/modals/receive-receipt-modal/receive-receipt-modal.component';

@Component({
  selector: 'app-pat-payment-confirmation-page',
  templateUrl: './pat-payment-confirmation-page.component.html',
})
export class PatPaymentConfirmationPageComponent extends DestroyerBase implements OnInit {
  @Input() body: any;

  isLoading = false;
  outstandingAmount$ = this.paymentAtTableSelector.selectCheckOutstandingAmount;
  isTransactionCancelled = false;
  transaction!: DoodOpenCheckTransaction;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly routerHelper: RouterHelperService,
    private readonly paymentAtTableSelector: PaymentAtTableStoreSelector,
    private readonly paymentAtTableDispatcher: PaymentAtTableStoreDispatcher,
    private readonly onSiteLocationsService: OnSiteLocationsService,
    private readonly modalsService: ModalsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getOnSiteLocationCheckTransactions$();
  }

  goToOverview(): void {
    this.router.navigate([
      this.routerHelper.translateRoute(`/${Paths.PayAtTable}/${Paths.PayAtTableOverview}`),
    ]);
  }

  openModalReceipt(): void {
    this.modalsService.open(ReceiveReceiptModalComponent.handle, undefined, {
      receiptType: ReceiveReceiptModalReceiptType.checkTransaction,
      receiptId: this.transaction[CheckTransactionKeys.Id],
    });
  }

  private getOnSiteLocationCheckTransactions$(): void {
    this.isLoading = true;
    const stopPolling$ = new Subject<boolean>();
    interval(10000)
      .pipe(
        take(10),
        takeUntil(stopPolling$),
        startWith(0),
        switchMap(() => this.paymentAtTableSelector.selectCheckActiveTransaction.pipe(take(1))),
        switchMap(activeTransaction => {
          if (!activeTransaction) {
            const id = this.activatedRoute.snapshot.paramMap.get('id');
            return id
              ? this.onSiteLocationsService.getOnSiteLocationCheckTransactions$(id)
              : of(null);
          }
          return this.onSiteLocationsService.getOnSiteLocationCheckTransactions$(
            activeTransaction.id,
          );
        }),
        switchMap(transaction => {
          if (transaction && transaction.status === 'CAPTURED') {
            this.transaction = transaction;
            const checkId = this.paymentAtTableSelector.check.id;
            this.isLoading = false;
            return combineLatest([
              of(transaction),
              this.onSiteLocationsService.getOnSiteLocationCheck$(checkId),
            ]);
          }
          if (transaction && transaction.status === 'CANCELLED') {
            this.isTransactionCancelled = true;
            stopPolling$.next(true); // stop polling when status is 'CANCELLED'
            stopPolling$.complete();
          }

          this.resetPatChooseState();
          return combineLatest([of(transaction), of(null)]);
        }),
        takeUntil(this._destroyerRef),
        takeWhile(transaction => this.isLoading, true),
      )
      .subscribe();
  }

  private resetPatChooseState(): void {
    this.paymentAtTableDispatcher.resetChoose();
  }
}
