import { computed, inject, Injectable, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  ICartDeclinableSeparateAdditions,
  ICartItem,
  ICartSimpleProductItem,
} from '@core/models/cart.model';
import { DoodProductModel } from '@core/models/product.model';
import { BehaviorSubject, combineLatest, map, Observable, ReplaySubject, switchMap } from 'rxjs';
import { CartSimpleProductUtil } from './util/cart-simple-product-util';
import { CartUtilService } from './util/cart-util.service';

export type SimpleProductCart = Omit<ICartSimpleProductItem, 'id'> & {
  id: string | number | undefined;
  separate_additions_by_group?: ICartDeclinableSeparateAdditions[];
};

@Injectable()
export class CartDraftSimpleProductStore {
  private readonly cartUtilService = inject(CartUtilService);

  private readonly simpleProduct$ = new ReplaySubject<DoodProductModel>(1);
  private readonly simpleProduct = toSignal(this.simpleProduct$, {
    initialValue: undefined,
  });

  private readonly additionGroups$ = this.simpleProduct$.pipe(
    switchMap(simpleProduct =>
      this.cartUtilService.getProductAdditionsByGroup$(simpleProduct.additions?.products ?? []),
    ),
  );
  private readonly additionGroups = toSignal(this.additionGroups$, {
    initialValue: undefined,
  });

  private readonly shopId$ = new ReplaySubject<string>(1);
  private readonly existingCart$ = new BehaviorSubject<ICartItem | undefined>(undefined);

  public setExistingCart(cart: ICartItem) {
    this.existingCart$.next(cart);
  }

  public init({ shopId, simpleProduct }: { shopId: string; simpleProduct: DoodProductModel }) {
    this.shopId$.next(shopId);
    this.simpleProduct$.next(simpleProduct);
  }

  private readonly initialCartDraftState$: Observable<SimpleProductCart> = combineLatest({
    simpleProduct: this.simpleProduct$,
    shopId: this.shopId$,
    existingCart: this.existingCart$,
  }).pipe(
    map(({ simpleProduct, shopId, existingCart }) => {
      return {
        item_id: simpleProduct.id,
        quantity: existingCart?.quantity ?? 1,
        additions: existingCart?.additions ?? [],
        shop_id: shopId,
        id: existingCart?.id,
      } satisfies SimpleProductCart;
    }),
  );

  private readonly state = toSignal(
    combineLatest({
      initialCartDraftState: this.initialCartDraftState$,
    }).pipe(
      map(({ initialCartDraftState }) => {
        return signal({
          // todo make it readonly
          cartDraft: initialCartDraftState,
        });
      }),
    ),
    {
      initialValue: undefined,
    },
  );

  public readonly selectors = computed(() => {
    const state = this.state()?.();
    if (!state) {
      return undefined;
    }

    const simpleProduct = this.simpleProduct();
    if (!simpleProduct) {
      return undefined;
    }

    const additionGroups = this.additionGroups();
    if (!additionGroups) {
      return undefined;
    }
    const cartDraft = state.cartDraft;

    const draftAdditionGroups = additionGroups.map(additionGroup => {
      return CartSimpleProductUtil.calculateGroupAdditions({
        additionGroup,
        selectedAdditionIds: cartDraft.additions,
      });
    });

    const isSimpleProductValid = CartSimpleProductUtil.checkAdditionGroupValidity({
      selectedAdditionIds: cartDraft.additions,
      simpleProductAdditionsGroups: draftAdditionGroups,
    });

    const draftSimpleProduct = { ...simpleProduct, quantity: cartDraft.quantity };

    return {
      cartDraft,
      finalCart: cartDraft,
      draftSimpleProduct,
      draftAdditionGroups,
      validity: {
        ...isSimpleProductValid,
      },

      shopId: cartDraft.shop_id,
    }; // todo find a way to make it all readonly
  });

  public updateQuantity(quantity: number) {
    const state = this.state();
    state?.update(stateData => ({
      ...stateData,
      cartDraft: {
        ...stateData.cartDraft,
        quantity,
      },
    }));
  }

  public updateAdditionQuantity({
    additionId,
    quantity,
  }: {
    additionId: string;
    quantity: number;
  }) {
    this.state()?.update(state => {
      const simpleProductCartDraft = state.cartDraft;

      if (!simpleProductCartDraft) {
        return state;
      }
      const selectedAdditionIdsValue =
        simpleProductCartDraft.additions?.filter(id => id !== additionId) ?? [];

      const additionGroups = this.selectors()?.draftAdditionGroups ?? [];
      const { selectedAdditionIdsWithoutExcessQuantity, separate_additions_by_group } =
        CartSimpleProductUtil.updateAdditionSelections({
          selectedAdditionIdsValue,
          quantity,
          additionId,
          additionGroups,
        });
      return {
        ...state,
        cartDraft: {
          ...state.cartDraft,
          additions: selectedAdditionIdsWithoutExcessQuantity,
          separate_additions_by_group,
        },
      };
    });
  }
}
