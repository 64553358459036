import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-card-button-atom',
  templateUrl: './card-button-atom.component.html',
})
export class CardButtonAtomComponent {
  @Input() text!: string;
  @Input() icon!: string;

  @Output() buttonClick = new EventEmitter<Event>();
}
