import { Observable, Subject } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { Inject, Injectable, Injector } from '@angular/core';

import {
  IKiosk,
  IKioskHardwareAuresEtkPrinter,
  IKioskHardwareEpsonLocalPrinter,
  IKioskHardwareStripeTerminal,
  IKioskHardwareYavinTerminal,
  KioskHardwareType,
} from '@core/models/kiosk.model';

import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { SettingsStoreDispatcher } from '@common/dispatchers/settings.dispatcher';

import { AuresEtkService } from '@core/services/aures-etk/aures-etk.service';
import { KiosksApiService } from '@core/services/api/kiosks/kiosks-api.service';
import { StripeTerminalService } from '@core/services/stripe-terminal/stripe-terminal.service';
import { EpsonLocalPrinterService } from '@core/services/epson-local-printer.service';
import { DoodOrderModel } from '@store/order/order.model';
import { YavinTerminalService } from '@core/services/yavin-terminal/yavin-terminal.service';
import { OrdersService } from '@core/services/orders/orders.service';

@Injectable({
  providedIn: 'root',
})
export class KioskService {
  printLines$ = new Subject<string[]>();
  printerLineWidth = 48;

  constructor(
    private settingsSelector: SettingsStoreSelector,
    private readonly auresEtkService: AuresEtkService,
    private settingsDispatcher: SettingsStoreDispatcher,
    private readonly kiosksApiService: KiosksApiService,
    private readonly epsonLocalPrinterService: EpsonLocalPrinterService,
    private readonly stripeTerminalService: StripeTerminalService,
    private readonly yavinTerminalService: YavinTerminalService,
    @Inject(Injector) private readonly injector: Injector,
  ) {}

  getKiosks$(marketplaceId: string): Observable<IKiosk[]> {
    return this.kiosksApiService.getKiosks$(marketplaceId);
  }

  initializeKiosk(): void {
    let kioskId = this.getKioskIdFromQueryParams();

    if (!kioskId) {
      kioskId = this.getKioskIdFromStore();
    }

    if (!kioskId) {
      return;
    }

    console.log('[Kiosk] Kiosk ID: ' + kioskId);
    this.saveKioskIdToStore(kioskId);

    this.kiosksApiService
      .getKiosk$(kioskId)
      .pipe(
        take(1),
        tap(kiosk => this.initHardware(kiosk)),
      )
      .subscribe();
  }

  private getKioskIdFromQueryParams(): string | null {
    const urlSearchParams = new URLSearchParams(window.location.search);
    if (urlSearchParams.has('kiosk')) {
      return urlSearchParams.get('kiosk') as string;
    }
    return null;
  }

  getKioskIdFromStore(): string | null {
    return this.settingsSelector.kiosk.id;
  }

  printLines(lines: string[]) {
    this.printLines$.next(lines);
  }

  printOrder$(order: DoodOrderModel) {
    const ordersService = this.injector.get(OrdersService);
    return ordersService
      .getPrintedTicketById$(order.id, this.printerLineWidth)
      .pipe(tap(lines => this.printLines(lines)));
  }

  private initHardware(kiosk: IKiosk): void {
    for (const hardware of kiosk.kiosk_hardwares) {
      if (hardware.type === KioskHardwareType.STRIPE_TERMINAL) {
        this.stripeTerminalService.connectToReader(hardware as IKioskHardwareStripeTerminal);
      }
      if (hardware.type === KioskHardwareType.AURES_ETK_PRINTER) {
        this.auresEtkService.setPrinterConfiguration(hardware as IKioskHardwareAuresEtkPrinter);
      }
      if (hardware.type === KioskHardwareType.EPSON_LOCAL_PRINTER) {
        this.epsonLocalPrinterService.setPrinterConfiguration(
          hardware as IKioskHardwareEpsonLocalPrinter,
        );
        this.epsonLocalPrinterService.subscribeToPrintLines(this.printLines$);
        this.printerLineWidth = (hardware as IKioskHardwareEpsonLocalPrinter).line_width;
      }
      if (hardware.type === KioskHardwareType.YAVIN_TERMINAL) {
        this.yavinTerminalService.setConfiguration(hardware as IKioskHardwareYavinTerminal);
      }
    }
  }

  saveKioskIdToStore(kioskId?: string): void {
    this.settingsDispatcher.updateKioskId(kioskId ?? null);
  }

  saveKioskLayoutToStore(isKioskLayout?: boolean): void {
    this.settingsDispatcher.updateIsKioskLayout(isKioskLayout ?? false);
  }
}
