import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  templateUrl: './page-factory.component.html',
  styleUrls: ['./page-factory.component.scss'],
})
export class PageFactoryComponent implements OnInit, OnDestroy {
  public blockId: any;

  private readonly destroyed$ = new Subject<boolean>();

  constructor(private readonly activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.data
      .pipe(
        takeUntil(this.destroyed$),
        tap(data => (this.blockId = data.block)),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
