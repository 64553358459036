<app-payment-panel-adyen-generic
  type="mealVoucher_FR_groupeup"
  [isPayButtonVisible]="true"
  [payButtonText]="'payment.pay-with-groupeup' | translate"
  [clientKey]="clientKey"
  [paymentMethodMealVoucherGroupeUpAvailable]="paymentMethodMealVoucherGroupeUpAvailable"
  paymentMethodHandle="ADYEN_MEAL_VOUCHER_GROUPE_UP"
>
  <app-payment-panel-adyen-cb-skeleton loadingState></app-payment-panel-adyen-cb-skeleton>
</app-payment-panel-adyen-generic>
