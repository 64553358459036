import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CartStoreRefiner } from '@common/refiners/cart.refiner';
import { OrderStoreSelector } from '@common/selectors/order.selector';
import { Paths } from '@config/paths.config';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { NavigationHistoryService } from '@core/services/navigation-history/navigation-history.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';

@Component({
  selector: 'app-kiosk-cart-footer-block',
  templateUrl: './kiosk-cart-footer-block.component.html',
})
export class KioskCartFooterBlockComponent {
  @Input() primaryButtonBackgroundColor!: IContentBuilderFieldColor;
  @Input() primaryButtonTextColor!: IContentBuilderFieldColor;
  @Input() secondaryButtonBackgroundColor!: IContentBuilderFieldColor;
  @Input() secondaryButtonTextColor!: IContentBuilderFieldColor;

  isNavigationToCartInProgress = false;

  order$ = this.orderSelector.selectValidation;
  cartProducts$ = this.cartRefiner.selectActiveCartProducts;

  constructor(
    private readonly orderSelector: OrderStoreSelector,
    private readonly cartRefiner: CartStoreRefiner,
    private readonly navigationHistoryService: NavigationHistoryService,
    private readonly router: Router,
    private readonly routerHelper: RouterHelperService,
  ) {}

  navigateToFunnel(): void {
    this.isNavigationToCartInProgress = true;
    this.router.navigate([this.routerHelper.translateRoute(`/${Paths.Cart}/${Paths.Funnel}`)]);
  }

  back(): void {
    this.navigationHistoryService.goBack();
  }
}
