import {TranslateService} from '@ngx-translate/core';
import {Component, EventEmitter, Input, Output} from '@angular/core';

import {ArrayUtils} from '@app/utilities/array.util';

import {ModalsService} from '@core/services/modals/modals.service';

import {ColorFieldTypesValues} from '@config/values/color-field-types.values';
import {IContentBuilderFieldColor} from '@core/models/content-builder-fields.model';

import {
  ShareUserFeedbackModalComponent
} from '@shared/modals/share-user-feedback-modal/share-user-feedback-modal.component';
import {AnalyticsService} from "@core/services/app/analytics.service";

@Component({
  selector: 'app-user-feedback-block',
  templateUrl: './user-feedback-block.component.html',
  styleUrls: ['./user-feedback-block.component.scss'],
})
export class UserFeedbackBlockComponent {
  private _length = 5;

  items = ArrayUtils.from(this._length);

  @Input() title = 'user-feedback.title';
  @Input() positiveRatingThreshold = 3.5;
  @Input() minValueLabel = 'user-feedback.min-value-label';
  @Input() maxValueLabel = 'user-feedback.max-value-label';
  @Input() textColor: IContentBuilderFieldColor = ORDER_FEEDBACK_DEFAULT_TEXT_COLOR;
  @Input() backgroundColor: IContentBuilderFieldColor = ORDER_FEEDBACK_DEFAULT_BG_COLOR;

  @Input() modalPositiveRatingTitle = 'user-feedback.modal.positive.title';
  @Input() modalPositiveRatingContent: unknown[] = [];
  @Input() modalPositiveRatingMessage = 'user-feedback.modal.positive.rating-message';

  @Input() modalNegativeRatingTitle = 'user-feedback.modal.negative.title';
  @Input() modalNegativeRatingContent: unknown[] = [];
  @Input() modalNegativeRatingMessage = 'user-feedback.modal.negative.rating-message';

  @Input() rating = 0;
  @Output() ratingChange = new EventEmitter<number>();

  @Input()
  set length(_length: number) {
    this._length = _length;
    this.items = ArrayUtils.from(this._length);
  }

  get length(): number {
    return this._length;
  }

  constructor(
    private modalService: ModalsService,
    private translator: TranslateService,
    private analyticsService: AnalyticsService,
  ) {
    this.analyticsService.trackEvent('user_feedback_display');
  }

  onRated(_event: Event, _value: number): void {
    this._setRating(_value);
    const _isPositive = _value >= Number(this.positiveRatingThreshold);
    setTimeout(() => {
      this._openSuggestShareFeedbackModal(_isPositive);
    }, 200);
    this.analyticsService.trackEvent('user_feedback_rate', {
      user_feedback_rating: _value,
      user_feedback_is_positive: _isPositive
    });
  }

  private _setRating(_value: number): void {
    this.rating = _value;
    this.ratingChange.emit(this.rating);
  }

  private _openSuggestShareFeedbackModal(_isPositive: boolean): void {
    const _data = {
      title: _isPositive
        ? this.translator.instant(this.modalPositiveRatingTitle)
        : this.translator.instant(this.modalNegativeRatingTitle),
      description: _isPositive
        ? this.translator.instant(this.modalPositiveRatingMessage)
        : this.translator.instant(this.modalNegativeRatingMessage),
      items: _isPositive ? this.modalPositiveRatingContent : this.modalNegativeRatingContent,
    };
    this.modalService.open(ShareUserFeedbackModalComponent.handle, undefined, _data);
  }
}

const ORDER_FEEDBACK_DEFAULT_BG_COLOR: IContentBuilderFieldColor = {
  value: 'primary-600',
  type: ColorFieldTypesValues.Palette,
};

const ORDER_FEEDBACK_DEFAULT_TEXT_COLOR: IContentBuilderFieldColor = {
  value: 'primary-50',
  type: ColorFieldTypesValues.Palette,
};
