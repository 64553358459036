import { Router } from '@angular/router';
import { Component, Input } from '@angular/core';
import { LocationStrategy } from '@angular/common';

import { Paths } from '@config/paths.config';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { NavigationHistoryService } from '@core/services/navigation-history/navigation-history.service';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-payment-page-header-block',
  templateUrl: './payment-page-header-block.component.html',
})
export class PaymentPageHeaderBlockComponent {
  @Input() textColor: IContentBuilderFieldColor = {
    value: 'neutral-900',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() backgroundColor: IContentBuilderFieldColor = {
    value: '#FFFFFF',
    type: ColorFieldTypesValues.Hex,
  };

  constructor(
    private readonly location: LocationStrategy,
    private readonly router: Router,
    private readonly routerHelper: RouterHelperService,
    private readonly navigationService: NavigationHistoryService,
  ) {}

  back(): void {
    const history = this.navigationService.getHistory();
    if (history[history.length - 2].identifier === 'cart-funnel') {
      this.router.navigate([this.routerHelper.translateRoute(Paths.Cart)]);
    } else {
      this.location.back();
    }
  }
}
