import { DoodProductModel, ICategory } from '@core/models/product.model';
import { DoodCategoryWithProduct } from '@shared/interfaces/category.interface';

export class ShopUtils {
  static joinItems(
    categories: ICategory[],
    products: DoodProductModel[],
    shopId: string,
  ): DoodCategoryWithProduct[] {
    return categories?.map(store => {
      const productsInformation = store.products
        .map(_id => {
          const _found = products?.find(_product => _product.id === _id);
          if (!_found) return null;
          return {
            ..._found,
            shop_id: shopId,
          };
        })
        .filter(p => !!p) as DoodProductModel[];
      return {
        ...store,
        productsInformation,
      };
    });
  }
}
