import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';

import {
  SELECT_ORDER_ACTIVE,
  SELECT_ORDER_ALL,
  SELECT_ORDER_ENTITY,
  SELECT_ORDER_ERRORS,
  SELECT_ORDER_IS_VALIDATING,
  SELECT_ORDER_MULTIPLE_ENTITIES,
  SELECT_ORDER_VALIDATION,
  SELECT_ORDER_VIOLATIONS,
  SELECT_ORDER_ACTIVE_ID,
} from '@store/order/order.selection';
import { DoodOrderModel } from '@store/order/order.model';
import { DOOD_ORDER_HISTORICAL_STATUS } from '@store/order/order.constant';

@Injectable({ providedIn: 'root' })
export class OrderStoreSelector implements OnDestroy {
  private readonly _destroyerRef = new Subject<boolean>();

  orders: DoodOrderModel[] = [];
  active: DoodOrderModel | null = null;

  selectErrors = this.store.select(SELECT_ORDER_ERRORS);

  selectViolations = this.store.select(SELECT_ORDER_VIOLATIONS);
  selectHasViolations = this.selectViolations.pipe(map(violations => violations.length > 0));

  selectValidation = this.store.select(SELECT_ORDER_VALIDATION);
  selectIsValidating = this.store.select(SELECT_ORDER_IS_VALIDATING);

  selectOrders = this.store.select(SELECT_ORDER_ALL);
  selectActiveOrders = this.selectOrders.pipe(
    map(orders => orders.filter(_order => !DOOD_ORDER_HISTORICAL_STATUS.includes(_order.status))),
  );
  selectHistoricalOrders = this.selectOrders.pipe(
    map(orders => orders.filter(_order => DOOD_ORDER_HISTORICAL_STATUS.includes(_order.status))),
  );

  selectActive = this.store.select(SELECT_ORDER_ACTIVE);
  selectActiveId = this.store.select(SELECT_ORDER_ACTIVE_ID);
  selectHasActiveOrder = this.selectActive.pipe(map(entity => !!entity));

  selectOrder = (id: string): Observable<DoodOrderModel | undefined> =>
    this.store.select(SELECT_ORDER_ENTITY(id));

  selectMultipleOrders = (ids: string[]): Observable<DoodOrderModel[]> =>
    this.store.select(SELECT_ORDER_MULTIPLE_ENTITIES(ids));

  constructor(private store: Store) {
    this.selectOrders.pipe(takeUntil(this._destroyerRef)).subscribe(_orders => {
      this.orders = _orders;
    });
    this.selectActive.pipe(takeUntil(this._destroyerRef)).subscribe(_active => {
      this.active = _active;
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe();
  }

  private _unsubscribe(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
  }
}
