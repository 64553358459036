import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { UrlParamsKeys } from '@config/keys/url-params.keys';

import { CartStoreSelector } from '@common/selectors/cart.selector';
import { ShopStoreSelector } from '@common/selectors/shop.selector';
import { BasketStoreSelector } from '@common/selectors/basket.selector';

import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { ModalsService } from '@core/services/modals/modals.service';
import { GroupedOrderModalsAttributes } from '@config/modals/grouped-order-modals.config';
import { ShareGroupedOrderModalComponent } from '@shared/modals/share-grouped-order-modal/share-grouped-order-modal.component';

@Component({
  template: ``,
  selector: 'app-grouped-order-modal-launcher-block',
})
export class GroupedOrderModalLauncherBlockComponent extends DestroyerBase implements OnInit {
  static handle = 'GroupedOrderModalLauncherBlock';

  constructor(
    private cartSelector: CartStoreSelector,
    private shopSelector: ShopStoreSelector,
    private basketSelector: BasketStoreSelector,
    private readonly modalsService: ModalsService,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit(): void {
    combineLatest([
      this.activatedRoute.queryParams,
      this.shopSelector.selectActive,
      this.cartSelector.selectActive,
      this.basketSelector.select,
    ])
      .pipe(takeUntil(this._destroyerRef))
      .subscribe(([queryParams, shop, cart, basket]) => {
        const isGroupedOrderAllowed = shop?.distribution_modes.find(
          distributionMode => distributionMode.type === cart?.type,
        )?.allow_grouped_order;
        if (
          (!queryParams.hasOwnProperty(UrlParamsKeys.JoinCartId) ||
            !queryParams.hasOwnProperty(UrlParamsKeys.ShareCode)) &&
          cart?.handle &&
          isGroupedOrderAllowed &&
          shop?.id &&
          !basket?.id &&
          !basket?.rejected
        ) {
          this.modalsService.open(
            ShareGroupedOrderModalComponent.handle,
            GroupedOrderModalsAttributes.ChooseOrderType,
          );
        }
      });
  }
}
