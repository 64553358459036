<div
  *ngIf="isLoading"
  class="flex h-90-screen items-center justify-center px-5 pt-2 align-middle"
>
  <h1 class="font-accent text-xl font-bold text-neutral-800">
    {{ 'pay-at-table.payment-confirmation.confirming-payment' | translate }}
  </h1>
</div>
<div *ngIf="!isLoading">
  <!--  Success text -->
  <div class="flex justify-center px-5 pb-3 pt-7 md:w-8/12">
    <p class="text-accent mr-6 text-2xl font-bold leading-8 text-neutral-800 md:mr-7 md:text-5xl">
      {{ 'pay-at-table.payment-confirmation.payment-confirmed' | translate }}
    </p>
    <span><i class="icon icon-n-check text-4xl text-success-600 md:text-5.8xl"></i></span>
  </div>

  <!--  Remaining amount to pay -->
  <div
    *ngIf="((outstandingAmount$ | async) ?? 0) > 0"
    class="px-5 pb-3 md:w-8/12"
  >
    <div class="mb-3 flex flex-col rounded-m bg-neutral-100 p-5 text-center md:mb-8 md:p-6">
      <p class="font-accent text-md font-bold text-neutral-800 md:text-29px">
        {{ 'pay-at-table.payment-confirmation.waiting-guests' | translate }}
      </p>
    </div>
  </div>

  <!-- Custom blocks  -->
  <app-block-list [blockIdList]="body"></app-block-list>

  <!-- receive invoice -->
  <div
    *ngIf="transaction"
    class="px-5"
  >
    <app-email-receipt-atom
      [icon]="'icon-receipt_long'"
      [title]="'pay-at-table.payment-confirmation.receipt.receipt-by-email' | translate"
      [text]="'pay-at-table.payment-confirmation.receipt.description' | translate"
      (openModal)="openModalReceipt()"
    ></app-email-receipt-atom>
  </div>
</div>

<app-pat-base-modal *ngIf="!isTransactionCancelled && !isLoading">
  <p class="mb-3 text-center font-base text-neutral-800">
    {{ 'pay-at-table.overview.left-to-pay' | translate }} :
    <app-price-atom
      [amount]="(outstandingAmount$ | async) || 0"
      [bold]="false"
      [format]="true"
    ></app-price-atom>
  </p>
  <app-button-atom
    class="mx-auto"
    [label]="'pay-at-table.payment-confirmation.back-to-bill' | translate"
    [full]="true"
    (click)="goToOverview()"
  ></app-button-atom>
</app-pat-base-modal>

<app-pat-base-modal *ngIf="isTransactionCancelled">
  <p class="text-accent mb-3 mr-6 text-2xl font-bold leading-8 text-neutral-800">
    {{ 'pay-at-table.payment-confirmation.payment-refused' | translate }}
  </p>
  <p class="mb-3 text-center font-base text-neutral-600">
    {{ 'pay-at-table.payment-confirmation.payment-refused-desc' | translate }}
  </p>
  <app-button-atom
    class="mx-auto"
    [label]="'pay-at-table.payment-confirmation.continue' | translate"
    [full]="true"
    (click)="goToOverview()"
  ></app-button-atom>
</app-pat-base-modal>
