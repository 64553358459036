<p class="mb-2 text-center font-accent text-sm font-bold">
  {{ 'parameters.for-when' | translate }}
</p>
<div
  class="mb-2"
  *ngIf="displayAsapForLaterPicker"
>
  <app-custom-switch-atom
    [items]="parameters"
    [type]="'light'"
    [selectedValue]="selectedWantedAtType ?? ''"
    (switchItem)="onTypeChanged($event)"
  ></app-custom-switch-atom>
</div>
<ng-container *ngIf="wantedAtType === parametersWantedAtTypeValues.Later">
  <div class="mb-2">
    <app-custom-select-atom
      *ngIf="displayedDateSlots; else loadingSelect"
      [items]="displayedDateSlots"
      [selectedItem]="selectedWantedAtDate"
      (selectItem)="onDateChanged($event)"
    ></app-custom-select-atom>
  </div>
  <div class="mb-6">
    <div
      *ngIf="
        !timeSlotNotAvailable && (!displayedTimeSlots || displayedTimeSlots.length > 0);
        else noSlotAvalaible
      "
    >
      <app-custom-select-atom
        *ngIf="displayedTimeSlots; else loadingSelect"
        [items]="displayedTimeSlots"
        (selectItem)="onTimeChanged($event)"
        [isDisable]="timeSlotNotAvailable"
        [selectedItem]="
          timeSlotNotAvailable ? ('parameters.wantedAt.no-slot' | translate) : selectedWantedAtTime
        "
      ></app-custom-select-atom>
    </div>
  </div>
</ng-container>

<ng-template #noSlotAvalaible>
  <div
    class="mt-1 block w-full rounded-full bg-neutral-100 py-2.5 pl-4 font-base text-base font-normal"
  >
    {{ 'parameters.wantedAt.no-slot' | translate }}
  </div>
</ng-template>

<ng-template #loadingSelect>
  <div
    class="flex h-10 w-full animate-pulse items-center justify-start rounded rounded-full bg-neutral-100"
  >
    <img
      src="../../../../../assets/images/spinner.svg"
      class="pl-4"
    />
  </div>
</ng-template>
