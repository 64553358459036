import { ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ContentBuilderFieldColors } from '@shared/interfaces/content-builder.interface';
import { IHeroCardCarousel } from '@shared/models/hero-card-carousel';

@Component({
  selector: 'app-hero-carousel-block',
  templateUrl: './hero-carousel-block.component.html',
})
export class HeroCarouselBlockComponent {
  @ViewChild('carousel') carousel!: ElementRef;

  @Input() accentColor?: ContentBuilderFieldColors;
  @Input() cards!: Array<IHeroCardCarousel>;

  currentIndex = 0;

  isSmScreen = window.innerWidth <= 640;
  isUltraWideScreen = window.innerWidth > 1920;

  cardWidth = 240;

  private isTransitioning = false;

  private autoScrollIntervalId: number | undefined;


  allCardsAreVisible = true;

  safeCards: IHeroCardCarousel[] = []

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.safeCards = this.cards;
  }

  ngAfterViewInit() {
    this.checkElementsVisibility();
    window.addEventListener('resize', this.checkElementsVisibility.bind(this));
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.pauseAutoScroll();
    window.removeEventListener('resize', this.checkElementsVisibility.bind(this));
  }

  autoScroll(): void {
    this.autoScrollIntervalId = window.setInterval(() => {
      this.next();
    }, 4000);
  }

  pauseAutoScroll(): void {
    if (this.autoScrollIntervalId !== undefined) {
      clearInterval(this.autoScrollIntervalId);
      this.autoScrollIntervalId = undefined;
    }
  }

  resumeAutoScroll(): void {
    if (!this.autoScrollIntervalId) {
      const carouselElement = this.carousel.nativeElement;
      const visibleWidth = carouselElement.offsetWidth;
      const totalItemsWidth = this.cards.length * this.cardWidth ;
      if (totalItemsWidth > visibleWidth && !this.isSmScreen) {
        this.autoScroll();
      }
    }
  }

  private cloneCards(): void {
    const carouselElement = this.carousel.nativeElement;
    const cards = Array.from(carouselElement.children) as HTMLElement[];
  
    const firstClones = cards.slice(0, 2).map(card => {
      const clone = card.cloneNode(true) as HTMLElement;
      clone.classList.add('clone');
      return clone;
    });
    const lastClones = cards.slice(-2).map(card => {
      const clone = card.cloneNode(true) as HTMLElement;
      clone.classList.add('clone');
      return clone;
    });
  
    firstClones.forEach(clone => carouselElement.appendChild(clone));
    lastClones.forEach(clone => carouselElement.prepend(clone));
  }

  next(): void {
    if (this.isTransitioning) return;
    this.moveCarousel(1);
  }

  previous(): void {
    if (this.isTransitioning) return;
    this.moveCarousel(-1);
  }

  private moveCarousel(direction: number): void {
    const carouselElement = this.carousel.nativeElement;
    const totalItems = this.cards.length;
  
    this.currentIndex += direction;
  
    carouselElement.style.transition = 'transform 0.6s ease-in-out';
    carouselElement.style.transform = `translateX(-${this.currentIndex * this.cardWidth}px)`;
  
    carouselElement.addEventListener(
      'transitionend',
      () => {
        if (this.currentIndex >= totalItems) {
          this.currentIndex = 0;
          this.resetCarouselPosition();
        } else if (this.currentIndex < 0) {
          this.currentIndex = totalItems - 1;
          this.resetCarouselPosition();
        }
      },
      { once: true }
    );
  }
  
  private resetCarouselPosition(): void {
    const carouselElement = this.carousel.nativeElement;
  
    carouselElement.style.transition = 'none';
    carouselElement.style.transform = `translateX(-${this.currentIndex * this.cardWidth}px)`;
  
    carouselElement.offsetHeight;
    carouselElement.style.transition = 'transform 0.6s ease-in-out';
  }
  

  private checkElementsVisibility(): void {
    const isSmallScreen = window.innerWidth <= 640;
    const carouselElement = this.carousel.nativeElement;
    const visibleWidth = carouselElement.offsetWidth;
    const totalItemsWidth = this.cards.length * this.cardWidth;
  
    if (!isSmallScreen) {
      if (totalItemsWidth > visibleWidth) {
        this.cloneCards();
        this.resumeAutoScroll();
        this.allCardsAreVisible = false;
      } else {
        this.pauseAutoScroll();
        this.allCardsAreVisible = true;
        this.currentIndex = 0;
        this.isTransitioning = false;
        carouselElement.style.transition = 'none';
        carouselElement.style.transform = `translateX(0)`;
      }
    } else {
      this.removeClones();
      this.cards = this.safeCards;
      this.resetCarouselPosition();
      this.cdr.detectChanges();
    }
  }
  
  private removeClones(): void {
    const carouselElement = this.carousel.nativeElement;
    const allChildren = Array.from(carouselElement.children) as HTMLElement[];
    allChildren.forEach((child) => {
      if (child.classList.contains('clone')) {
        carouselElement.removeChild(child);
      }
    });
  }
}
