import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Injectable({
  providedIn: 'root',
})
export class RouterHelperService {
  constructor(private readonly localizeRouter: LocalizeRouterService) {}

  translateRoute(path: string | any[]): string | any[] {
    if (typeof path === 'string') {
      if (
        /^\/?[a-z]{2}$/.test(path) ||
        /^\/?[a-z]{2}\//.test(path) ||
        this.isLinkExternal(path) ||
        this.isAnchor(path)
      ) {
        return path;
      }
      const firstSlashIndex = path.indexOf('/');
      if (firstSlashIndex === -1 || firstSlashIndex > 0) {
        path = `/${path}`;
      }
    }

    return this.localizeRouter.translateRoute(path);
  }

  isLinkExternal(link?: string): boolean {
    return link?.startsWith('https://') || false;
  }

  isAnchor(link?: string): boolean {
    return link?.startsWith('#') || false;
  }

  getActivatedUrl(activatedRouteSnapshot: ActivatedRouteSnapshot): string {
    let currentActivatedRouteSnapshot: ActivatedRouteSnapshot | null = activatedRouteSnapshot;
    const urls = [];
    while (currentActivatedRouteSnapshot) {
      let url = currentActivatedRouteSnapshot.url.join('/');
      if (url) {
        urls.unshift(url);
      }
      currentActivatedRouteSnapshot = currentActivatedRouteSnapshot.parent;
    }
    return urls.join('/');
  }
}
