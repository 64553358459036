import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ContentChild,
  inject,
  input,
  output,
  TemplateRef,
} from '@angular/core';
import { DoodProductModel } from '@core/models/product.model';
import { DraftAdditionsGroup } from '@shared/local-store/cart/util/cart-simple-product-util';
import { SimpleProductAutoNextStepService } from '@shared/local-store/stepper/auto-next-step/simple-product-auto-next-step.service';
import { SimpleProductStepperStore } from '@shared/local-store/stepper/simple-product-stepper.store';
import { Stepper } from '@shared/local-store/stepper/stepper.type';
import { provideSimpleProductStepperStore } from '@shared/local-store/stepper/util/simple-product-stepper-store-provider';

export type SimpleProductWithAdditionsGroupStepperData =
  | {
      currentSimpleProductWithAdditionsGroupStepper: Stepper | undefined; // todoR check if needed again
      product: DoodProductModel;
      onChangeAdditionQuantity: (data: { additionId: string; quantity: number }) => void;
      additionsByGroup: DraftAdditionsGroup;
    }
  | undefined;

@Component({
  selector: 'app-kiosk-simple-product-stepper',
  standalone: true,
  imports: [CommonModule],
  providers: [provideSimpleProductStepperStore()],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ` <ng-container
    *ngTemplateOutlet="
      stepperAdditionsGroupTpl;
      context: {
        $implicit: stepperAdditionsGroupData(),
      }
    "
  ></ng-container>`,
})
export class KioskSimpleProductStepperComponent {
  private readonly simpleProductStepperStore = inject(SimpleProductStepperStore);

  public readonly simpleProduct = input.required<DoodProductModel>();
  public readonly additionsByGroups = input.required<DraftAdditionsGroup[] | undefined>();

  public readonly updateAdditionQuantity = output<{ additionId: string; quantity: number }>();

  @ContentChild('stepperAdditionsGroupTpl', { read: TemplateRef })
  stepperAdditionsGroupTpl!: TemplateRef<unknown>;

  constructor() {
    inject(SimpleProductAutoNextStepService).initAutoNextStep();
  }

  protected readonly stepperAdditionsGroupData =
    computed<SimpleProductWithAdditionsGroupStepperData>(() => {
      const stepper = this.simpleProductStepperStore.simpleProductStepper();
      if (!stepper) {
        return undefined;
      }
      const simpleProductId = this.simpleProduct()?.id;
      if (!simpleProductId) {
        return undefined;
      }

      const additionsByGroups = this.additionsByGroups();

      if (!additionsByGroups) {
        return undefined;
      }

      const additionsByGroup = additionsByGroups.find(group => group.id === stepper.selectedStepId);

      if (!additionsByGroup) {
        return undefined;
      }

      return {
        currentSimpleProductWithAdditionsGroupStepper: { step: stepper, childStepper: undefined },
        product: this.simpleProduct(),
        onChangeAdditionQuantity: data => {
          this.updateAdditionQuantity.emit(data);
        },
        additionsByGroup,
      } satisfies SimpleProductWithAdditionsGroupStepperData;
    });
}
