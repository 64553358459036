<div class="py-40 px-10 text-center text-xl text-bold">
  <div
    *ngIf="isLoading"
    class="flex justify-center items-center py-6"
  >
    <i class="icon icon-privacy text-4xl animate-pulse"></i>
  </div>

  <div>
    {{ statusMessage }}
  </div>

  <div
    *ngIf="errorMessage"
    class="mt-6 text-center text-base text-error-800"
  >
    {{ errorMessage }}
  </div>

  <ng-container *ngIf="isOnError">
    <div class="mt-6 flex flex-row justify-center">
      <app-button-atom
        size="M"
        label="Réessayer"
        (click)="createOrder()"
        type="primary"
      ></app-button-atom>
    </div>

    <div class="mt-4 flex flex-row justify-center">
      <app-button-atom
        size="XS"
        thickSize="S"
        label="Retourner au panier"
        (click)="backToCart()"
        type="light"
      ></app-button-atom>
    </div>
  </ng-container>
</div>

<app-kiosk-cart-reset-timer-block
  *ngIf="loadKioskResetTimer"
  [waitForUserInteractionToDisplayModal]="false"
></app-kiosk-cart-reset-timer-block>
