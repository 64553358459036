import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tag-status-atom',
  templateUrl: './tag-status-atom.component.html',
})
export class TagStatusAtomComponent {
  @Input() text!: string;
  @Input() icon!: string;
  @Input() type: 'success' | 'error' | 'neutral' | 'dark' = 'success';
}
