import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { map, takeUntil } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';

import { DoodAppScreen } from '@store/order/order.model';
import {
  SELECT_SETTINGS_APP,
  SELECT_SETTINGS_APP_LOCALE,
  SELECT_SETTINGS_DEVICE,
  SELECT_SETTINGS_DEVICE_SCREEN,
  SELECT_SETTINGS_KIOSK,
  SELECT_SETTINGS_PARAMETERS,
} from '@store/settings/settings.selection';
import {
  SETTINGS_STORE_INITIAL_APP_STATE,
  SETTINGS_STORE_INITIAL_DEVICE_STATE,
  SETTINGS_STORE_INITIAL_KIOSK_STATE,
  SETTINGS_STORE_INITIAL_PARAMETERS_STATE,
} from '@store/settings/settings.state';

@Injectable({ providedIn: 'root' })
export class SettingsStoreSelector implements OnDestroy {
  private readonly _destroyerRef = new Subject<boolean>();

  kiosk = SETTINGS_STORE_INITIAL_KIOSK_STATE;
  app = SETTINGS_STORE_INITIAL_APP_STATE;
  device = SETTINGS_STORE_INITIAL_DEVICE_STATE;
  parameters = SETTINGS_STORE_INITIAL_PARAMETERS_STATE;

  // Parameters
  selectParameters = this.store.select(SELECT_SETTINGS_PARAMETERS);

  // App
  selectApp = this.store.select(SELECT_SETTINGS_APP);
  selectAppLocale = this.store.select(SELECT_SETTINGS_APP_LOCALE);

  // Kiosk
  selectKiosk = this.store.select(SELECT_SETTINGS_KIOSK);
  selectKioskId = this.selectKiosk.pipe(map(_kiosk => _kiosk.id));

  // Device
  selectDevice = this.store.select(SELECT_SETTINGS_DEVICE);
  selectDeviceScreen = this.store.select(SELECT_SETTINGS_DEVICE_SCREEN);
  selectDeviceIsMobileScreen = this.selectDeviceScreen.pipe(map(s => s === DoodAppScreen.mobile));
  selectDeviceIsTabletScreen = this.selectDeviceScreen.pipe(map(s => s === DoodAppScreen.tablet));
  selectDeviceIsDesktopScreen = this.selectDeviceScreen.pipe(map(s => s === DoodAppScreen.desktop));

  get isMobileScreen(): boolean {
    return this.device.screen === DoodAppScreen.mobile;
  }

  get isTableScreen(): boolean {
    return this.device.screen === DoodAppScreen.tablet;
  }

  get isDesktopScreen(): boolean {
    return this.device.screen === DoodAppScreen.desktop;
  }

  constructor(private store: Store) {
    this.selectApp.pipe(takeUntil(this._destroyerRef)).subscribe(_app => {
      this.app = _app;
    });
    this.selectKiosk.pipe(takeUntil(this._destroyerRef)).subscribe(_kiosk => {
      this.kiosk = _kiosk;
    });
    this.selectDevice.pipe(takeUntil(this._destroyerRef)).subscribe(_device => {
      this.device = _device;
    });
    this.selectParameters.pipe(takeUntil(this._destroyerRef)).subscribe(_search => {
      this.parameters = _search;
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe();
  }

  private _unsubscribe(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
  }
}
