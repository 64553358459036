import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';

@Directive({
  selector: '[appVisibilityObserver]',
})
export class VisibilityObserverDirective {
  @Output() fullVisibilityChange = new EventEmitter<boolean>();
  @Input() threshold: number = 1.0; // Default: fully visible

  private observer: IntersectionObserver | null = null;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          const isFullyVisible = entry.intersectionRatio === this.threshold;
          this.fullVisibilityChange.emit(isFullyVisible);
        });
      },
      {
        threshold: [this.threshold],
      },
    );

    this.observer.observe(this.el.nativeElement);
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
