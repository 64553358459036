import { takeUntil, tap } from 'rxjs/operators';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { SettingsParametersState } from '@store/settings';
import { EntityStoreSelector } from '@common/selectors/entity.selector';

import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

import { DoodEntityModel, DoodEntityStatus } from '@core/models/entity.model';
import { IContentBuilderFieldImage } from '@core/models/content-builder-fields.model';

import { EntitiesService } from '@core/services/entities/entities.service';
import { EntityStatusService } from '@core/services/entity-status/entity-status.service';
import { ShopSearchParametersService } from '@core/services/shop-search-parameters/shop-search-parameters.service';

@Component({
  selector: 'app-shop-card-with-logo-block',
  templateUrl: './shop-card-with-logo-block.component.html',
})
export class ShopCardWithLogoBlockComponent implements OnInit, OnDestroy {
  @Input() displayOpenedShop = true;
  @Input() displayClosedShop = true;
  @Input() shop!: any;
  @Input() displayEntityImage = true;
  @Input() entityName!: string;
  @Input() entityCategory!: string;
  @Input() image?: IContentBuilderFieldImage;
  @Input() displayEntityCategory = true;
  @Input() kioskCardAspectRatio = '16/9';

  isMarketplaceAvailable$ = this.marketplaceSelector.selectMarketplaceAvailable;
  isMarketplaceAvailable = false;

  private readonly destroyed$ = new Subject<boolean>();

  status?: DoodEntityStatus;
  entity!: DoodEntityModel;
  shopImage?: string;

  constructor(
    private entitySelector: EntityStoreSelector,
    private readonly entitiesService: EntitiesService,
    private marketplaceSelector: MarketplaceStoreSelector,
    private readonly entityStatusService: EntityStatusService,
    private readonly shopSearchParametersService: ShopSearchParametersService,
  ) {}

  componentFactoryIsBlockVisible$(): Observable<boolean> {
    return of(this.displayOpenedShop);
  }

  ngOnInit(): void {
    if (!this.shop?.[0]?.id) {
      return;
    }

    combineLatest([
      this.entitySelector.selectEntity(this.shop[0].id),
      this.shopSearchParametersService.getParameters$(),
    ])
      .pipe(
        takeUntil(this.destroyed$),
        tap(([entity, parameters]) => this.loadEntity(entity, parameters)),
      )
      .subscribe();

    this.isMarketplaceAvailable$
      .pipe(
        takeUntil(this.destroyed$),
        tap(isAvailable => (this.isMarketplaceAvailable = isAvailable)),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  getShopUrl(entity: DoodEntityModel): string {
    return this.entitiesService.getUrl(entity);
  }

  private loadEntity(entity?: DoodEntityModel, parameters?: SettingsParametersState): void {
    let wantedAt = null;
    if (parameters?.wanted_at) {
      wantedAt = parameters?.wanted_at;
    }
    if (!wantedAt) {
      wantedAt = new Date();
    }

    this.status = this.entityStatusService.calculateStatus(
      entity,
      parameters?.distribution_mode,
      wantedAt,
    );
    this.entity = entity as DoodEntityModel;
    this.shopImage = entity?.images?.cover?.url;
    // Legacy image
    if (!this.shopImage && entity?.cover) {
      this.shopImage = 'https://api.dood.com' + entity?.cover;
    }
  }
}
