<ng-container *ngIf="isOnSiteOrdering">
  <div
    class="mb-3 px-5 lg:px-0 max-w-md lg:max-w-full mx-auto cursor-pointer"
    (click)="openOnSiteLocationPickerModal()"
  >
    <div
      class="flex flex-row py-5 px-4.5 border-neutral-400 border text-neutral-800 rounded rounded-s"
    >
      <div class="flex items-center justify-center flex-grow-0 pr-3">
        <i class="icon icon-seat"></i>
      </div>
      <div class="flex flex-col flex-grow">
        <div class="font-base font-bold">{{ heading }}</div>
        <div
          class="font-base text-neutral-600"
          *ngIf="onSiteLocationName"
        >
          {{ 'basket.on-site.location' | translate }}
          {{ onSiteLocationName }}
        </div>
        <div
          class="font-base text-error-600"
          *ngIf="!onSiteLocationName"
        >
          {{ 'basket.on-site.unknown-location-please-scan-qr' | translate }}
        </div>
        <!--                <div class="font-base font-bold text-sm text-primary-600 cursor-pointer">-->
        <!--                    Modifier-->
        <!--                </div>-->
      </div>
    </div>
  </div>
</ng-container>
