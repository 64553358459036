export enum VenueKeys {
  Point = 'point',
  Address = 'address',
}

export enum VenueAddressKeys {
  StreetAddress = 'street_address',
  Point = 'point',
  PostalCode = 'postal_code',
  Addressee = 'addressee',
  City = 'city',
  PostCode = 'post_code',
  StreetLine1 = 'street_line1',
  StreetLine2 = 'street_line2',
  Country = 'country',
  State = 'state',
}

export enum VenuePointKeys {
  Coordinates = 'coordinates',
  Type = 'type',
}

export enum VenueCountryKeys {
  Id = 'id',
  Name = 'name',
}
