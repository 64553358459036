import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-cart-information-button-atom',
  templateUrl: './cart-information-button-atom.component.html',
})
export class CartInformationButtonAtomComponent {
  @Input() quantity = 0;
  @Input() amount!: number;
  @Input() label!: string;

  @Output() buttonClick = new EventEmitter();
}
