import { map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { PluginTypes } from '@config/values/plugin-types.values';
import { MarketplaceStoreSelector } from '@app/common/selectors/marketplace.selector';

@Injectable({ providedIn: 'root' })
export class MarketplaceStoreRefiner {
  selectIsComoPluginEnabled = this._selector.selectMarketplacePlugins.pipe(
    take(1),
    map(plugins => plugins?.find(plugin => plugin.type === PluginTypes.Como)),
    map(plugin => plugin?.plugin_enabled),
  );

  selectIsZeroSixPluginEnabled = this._selector.selectMarketplacePlugins.pipe(
    take(1),
    map(plugins => plugins?.find(plugin => plugin.type === PluginTypes.Zerosix)),
    map(plugin => plugin?.plugin_enabled),
  );

  selectIsPongoPluginEnabled = this._selector.selectMarketplacePlugins.pipe(
    take(1),
    map(plugins => plugins?.find(plugin => plugin.type === PluginTypes.Pongo)),
    map(plugin => plugin?.plugin_enabled),
  );

  constructor(private _selector: MarketplaceStoreSelector) {}
}
