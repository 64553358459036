<p
  class="mt-10 text-4xl font-bold text-center"
  [style.color]="color | contentBuilderColor"
  *ngIf="funnelUsername$ | async as funnelUsername"
>
  {{ 'cart-funnel.welcome' | translate }} {{ funnelUsername }}
</p>
<div class="flex flex-col text-center gap-14 pt-44">
  <p
    class="text-6xl font-bold"
    [style.color]="color | contentBuilderColor"
  >
    {{ title }}
  </p>
  <p
    *ngIf="subtitle"
    class="text-3xl"
    [style.color]="color | contentBuilderColor"
  >
    {{ subtitle }}
  </p>
  <app-kiosk-pager-atom
    [color]="color"
    [value]="value"
    [pagerSize]="pagerSize"
    [minPagerSize]="minPagerSize"
    (valueEmitter)="onValueChange($event)"
  ></app-kiosk-pager-atom>
</div>
