<div
  class="map-container"
  [class.first-visit]="firstVisit"
  [style.background]="backgroundColor | contentBuilderColor: 'neutral-100'"
>
  <div
    class="notice-wrapper"
    (click)="firstVisit = false"
    (touchstart)="firstVisit = false"
  >
    <div class="notice">
      <div class="zoom"></div>
      <p class="text-white text-lg font-bold font-accent pt-5">Zoomez - Dézoomez</p>
    </div>
  </div>
  <div
    #stage
    class="stage"
  >
    <svg
      [attr.height]="imageHeight"
      [attr.width]="imageWidth"
      xmlns="http://www.w3.org/2000/svg"
      (click)="clickOnStage($event)"
    >
      <image
        x="0"
        y="0"
        [attr.href]="image.url"
        [attr.width]="imageWidth"
        [attr.height]="imageHeight"
      />

      <ng-container *ngFor="let shop of shops">
        <image
          *ngIf="hasReadyOrder(shop)"
          href="assets/images/mini-circle-check.png"
          class="mini-circle-check"
          [attr.x]="shop.notificationPoint.x - 18"
          [attr.y]="shop.notificationPoint.y - 18"
          height="36"
          width="36"
        />
        <circle
          *ngIf="hasReadyOrder(shop)"
          class="ripple-circle"
          [attr.cx]="shop.notificationPoint.x"
          [attr.cy]="shop.notificationPoint.y"
          r="250"
        />
        <polygon
          [attr.points]="polygonPointsToCoordinates(shop.polygon)"
          style="fill: transparent; stroke: transparent; stroke-width: 0"
          (click)="goToShop(shop)"
        />
      </ng-container>
    </svg>
  </div>
</div>
