<div class="max-w-screen-sm border-t border-neutral-300 bg-white pb-6">
  <div class="flex items-center justify-between py-4">
    <h2 class="font-accent font-bold leading-5">{{ name }}</h2>
    <button
      class="icon icon-arrow-down-s {{ isAccordionActive ? 'close' : '' }}"
      (click)="toggleAccordion()"
    ></button>
  </div>
  <div [@isAccordionActive]="isAccordionActive ? { value: 'visible' } : { value: 'hidden' }">
    <div *ngIf="type === 'checkbox' || type === 'radio'">
      <form [formGroup]="form">
        <div
          class="flex p-2.5"
          *ngFor="let item of items; let index = index"
        >
          <input
            *ngIf="type === 'checkbox'"
            type="checkbox"
            [id]="index + '_' + name"
            [formControlName]="forceType(type) === 'radio' ? 'radio' : item.value"
            (change)="updateCheckBox(id)"
            class="form-checkbox mr-3.5 h-5 w-5 border text-primary-600"
          />
          <input
            *ngIf="type === 'radio'"
            type="radio"
            [id]="index + '_' + name"
            formControlName="radio"
            (change)="selectRadio(id, item)"
            [value]="item.value"
            class="form-radio mr-3.5 h-5 w-5 border text-primary-600"
          />
          <label
            [for]="index + '_' + name"
            class="font-base text-sm leading-5 text-neutral-800"
          >
            {{ item.label | translate }}
          </label>
        </div>
      </form>
    </div>
    <div
      *ngIf="type === 'custom-radio'"
      class="flex gap-3"
    >
      <button
        (click)="selectCustomRadio(id, item)"
        class="px-4 py-1.5 rounded-full {{
          selectedItems && isCustomRadioValueSelected(item)
            ? 'bg-primary-600 text-white p'
            : 'bg-neutral-300 text-neutral-600'
        }}"
        *ngFor="let item of items; let index = index"
      >
        <span class="font-base text-base font-bold">{{ item.label | translate }}</span>
      </button>
    </div>
  </div>
</div>
