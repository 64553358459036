<div
  class="flex flex-col gap-4 border-t-6 p-5"
  [style.borderColor]="primaryButtonBackgroundColor | contentBuilderColor"
>
  <ng-container *ngIf="errorMessages">
    <p
      class="my-5 rounded bg-error-50 px-4 py-3 font-base text-error-700"
      *ngFor="let message of errorMessages"
    >
      {{ message }}
    </p>
  </ng-container>
  <div class="flex flex-row justify-between text-right">
    <div class="flex flex-row items-center">
      <div class="text-4xl font-bold">Votre commande :</div>
      <div class="ml-5 text-3xl">
        {{ (cartProducts$ | async)?.length }} article{{
          ((cartProducts$ | async)?.length ?? 0) > 1 ? 's' : ''
        }}
      </div>
    </div>
    <div class="flex flex-row items-center">
      <p class="mr-5 text-4xl font-bold">Total :</p>
      <div class="font-bold">
        @let order = order$ | async;
        <app-price-atom
          [format]="true"
          [amount]="
            order?.final_price && (cartProducts$ | async)?.length ? (order?.final_price ?? 0) : 0
          "
          size="3XL"
        ></app-price-atom>
      </div>
    </div>
  </div>

  <app-kiosk-horizontal-scroll
    [leftButtonTemplate]="leftButton"
    [rightButtonTemplate]="rightButton"
    [contentTemplate]="content"
    #horizontalScroll
  >
    <ng-template #leftButton>
      @let leftButtonData = horizontalScroll.leftButtonData;
      <app-button-atom
        size="S"
        color="neutral"
        [icon]="'icon-arrow-down-s'"
        [iconRotation]="'180'"
        type="kiosk-light"
        thickSize="S"
        [full]="false"
        [aspectSquare]="true"
        [disabled]="leftButtonData().disabled"
      ></app-button-atom>
    </ng-template>

    <ng-template #rightButton>
      @let rightButtonData = horizontalScroll.rightButtonData;
      <app-button-atom
        size="S"
        color="neutral"
        [icon]="'icon-arrow-down-s'"
        [iconRotation]="'270'"
        type="kiosk-light"
        thickSize="S"
        [full]="false"
        [disabled]="rightButtonData().disabled"
        [aspectSquare]="true"
      ></app-button-atom>
    </ng-template>

    <ng-template #content>
      <div>
        @if (!(reconstructedShareOrderBasket$ | async)?.length) {
          @let cartByShop = reconstructedCart$ | async;
          @if (cartByShop && cartByShop.length) {
            <div>
              @for (cart of cartByShop; track $index) {
                <div class="max-width-full flex flex-row">
                  <ul
                    class="flex flex-1 flex-row gap-8 pt-2.5"
                    #productList
                  >
                    @for (
                      product of cart[reconstructedCartKeys.Items];
                      let index = $index;
                      track $index
                    ) {
                      <li>
                        <app-kiosk-product-overview-cart-atom
                          [id]="product[reconstructedCartItemKeys.Item][cartItemKeys.Id]"
                          [name]="
                            product[reconstructedCartItemKeys.Product]?.[productKeys.Name] ??
                            'INVALID'
                          "
                          [quantity]="
                            product[reconstructedCartItemKeys.Item][cartItemKeys.Quantity]
                          "
                          [thumbnail]="
                            product[reconstructedCartItemKeys.Product].images?.thumbnail?.url
                          "
                          [dotColor]="primaryButtonBackgroundColor"
                          [dotTextColor]="primaryButtonTextColor"
                          [productBackgroundColor]="productBackgroundColor"
                          [productTextColor]="productTextColor"
                          (click)="onUpdateItem(product.item.id)"
                        ></app-kiosk-product-overview-cart-atom>
                      </li>
                    }
                  </ul>
                </div>
              }
            </div>
          }
        }
      </div>
    </ng-template>
  </app-kiosk-horizontal-scroll>

  <div
    class="mt-8 grid grid-cols-2 gap-10"
    [class.hidden]="hideButtons()"
  >
    <app-button-atom
      size="XXL"
      thickSize="XXL"
      [label]="'kiosk-footer-cart.cancel' | translate"
      [overrideBgColor]="secondaryButtonBackgroundColor"
      [overrideTextColor]="secondaryButtonTextColor"
      type="kiosk-light"
      (click)="clearCart()"
      [full]="true"
    ></app-button-atom>
    <app-button-atom
      size="XXL"
      [label]="'kiosk-footer-cart.continue-to-cart' | translate"
      thickSize="XXL"
      [overrideBgColor]="primaryButtonBackgroundColor"
      [overrideTextColor]="primaryButtonTextColor"
      [disabled]="!(order$ | async)?.final_price"
      type="kiosk-primary"
      (click)="navigateToCart()"
      [full]="true"
    ></app-button-atom>
  </div>
</div>
