import { Component, Input } from '@angular/core';
import {
  IContentBuilderFieldColor,
  IContentBuilderFieldImage,
} from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-carousel-slide-card-block',
  templateUrl: './carousel-slide-card-block.component.html',
  styleUrls: ['./carousel-slide-card-block.component.scss'],
})
export class CarouselSlideCardBlockComponent {
  @Input() slides?: any[];
  @Input() width = '3/12';
  @Input() height?: string;
  @Input() aspectRatio = 1;
  @Input() linkUrl?: string;
  @Input() tag?: string;
  @Input() backgroundImage?: IContentBuilderFieldImage;
  @Input() backgroundColor: IContentBuilderFieldColor = {
    value: 'neutral-200',
    type: ColorFieldTypesValues.Palette,
  };
}
