<div
  class="px-5 md:container md:mx-auto"
  [style.backgroundColor]="backgroundColor | contentBuilderColor"
  [ngClass]="{ 'max-w-full !pl-0 !pr-0 !m-0': fullWidth }"
>
  <div
    *ngIf="!legacyCards && cards"
    class="grid grid-cols-2 gap-4 lg:mx-auto"
    [ngClass]="{
      'pt-2': topMargin === 'small',
      'pt-5': topMargin === 'medium',
      'pt-7': topMargin === 'large',
      'pb-2': bottomMargin === 'small',
      'pb-5': bottomMargin === 'medium',
      'pb-7': bottomMargin === 'large',
      'lg:max-w-screen-sm': !fullWidth,
      'max-w-full md:max-w-full lg:max-w-full': fullWidth,
    }"
  >
    <app-block-list [blockIdList]="cards"></app-block-list>
  </div>
  <div
    *ngIf="legacyCards"
    class="grid grid-cols-2 gap-4 bg-neutral-100 pb-5 lg:gap-6 xl:gap-8"
  >
    <div
      *ngFor="let card of legacyCards"
      class="rounded-lg shadow-S"
      [ngClass]="{
        'col-span-1': card.width === '1',
        'col-span-2': card.width === '2',
        'col-span-3': card.width === '3',
      }"
    >
      <app-mosaic-push-atom
        *ngIf="card.width === '1'"
        [imageUrl]="card.backgroundImage ?? ''"
        [linkUrl]="card.link?.url ?? ''"
        [title]="card.heading ?? ''"
      ></app-mosaic-push-atom>
      <app-mosaic-long-push-atom
        *ngIf="card.width === '2'"
        [imageUrl]="card.backgroundImage"
        [linkUrl]="card.link?.url ?? ''"
        [title]="card.heading"
      ></app-mosaic-long-push-atom>
    </div>
  </div>
  <div
    *ngIf="items"
    class="pb-14 pt-5"
  >
    <div *ngFor="let push of items">
      <div *ngIf="push.type === 'push-text-button-2'">
        <div
          class="mb-4 grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-8 lg:mb-6 lg:gap-6 xl:mb-8 xl:gap-8"
        >
          <div *ngFor="let item of push.items">
            <app-push-text-button-v2-atom
              [heading]="item.heading"
              [buttonLabel]="item.buttonLabel"
              [backgroundColor]="item.backgroundColor"
            ></app-push-text-button-v2-atom>
          </div>
        </div>
      </div>
      <div *ngIf="push.type === 'push-picture'">
        <div class="grid grid-cols-2 gap-4 md:grid-cols-2 md:gap-8 lg:gap-6 xl:gap-8">
          <div *ngFor="let item of push.items">
            <app-push-picture-atom
              [heading]="item.heading"
              [pictureUrl]="item.imgUrl ?? ''"
              [buttonLink]="item.buttonLink ?? ''"
              [buttonLabel]="item.buttonLabel ?? ''"
              [backgroundColor]="item.backgroundColor"
            ></app-push-picture-atom>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
