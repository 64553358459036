import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { ComponentHostDirective } from '@core/directives/component-host.directive';
import { DoodProductModel, IProductLabel } from '@core/models/product.model';
import { takeUntil, tap } from 'rxjs/operators';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';
import { ProductKeys } from '@config/keys/product.keys';
import { ContentBuilderFieldColors } from '@shared/interfaces/content-builder.interface';

@Component({
  selector: 'app-category-factory',
  templateUrl: './category-factory.component.html',
})
export class CategoryFactoryComponent implements OnInit, OnDestroy, OnChanges {
  @Input() componentClass: any;
  @Input() category!: any;
  @Input() products: DoodProductModel[] = [];
  @Input() isOrderingAllowed = true;
  @Input() categoryNameColor: IContentBuilderFieldColor = {
    value: 'neutral-900',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() categoryDescriptionColor: IContentBuilderFieldColor = {
    value: 'neutral-600',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() highlightCategoryNameColor: IContentBuilderFieldColor = {
    type: ColorFieldTypesValues.Default,
    value: '',
  };
  @Input() highlightCategoryBackgroundColor: IContentBuilderFieldColor = {
    value: 'neutral-600',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() productNameColor: IContentBuilderFieldColor = {
    value: 'neutral-900',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() productDescriptionColor: IContentBuilderFieldColor = {
    value: 'neutral-900',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() productPriceColor: IContentBuilderFieldColor = {
    value: 'neutral-900',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() productImagePlaceholderColor: IContentBuilderFieldColor = {
    value: 'neutral-50',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() productBorderColor: IContentBuilderFieldColor = {
    value: 'neutral-100',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() productBackgroundColor: IContentBuilderFieldColor = {
    value: 'transparent',
    type: ColorFieldTypesValues.Hex,
  };
  @Input() selectedLabel?: IProductLabel;
  @Input({ required: true }) tagColors!: ContentBuilderFieldColors;

  @Output() navigateTo = new EventEmitter<DoodProductModel>();

  @ViewChild(ComponentHostDirective, { static: true })
  componentHost!: ComponentHostDirective;

  private destroyed$ = new Subject<boolean>();
  private componentRef?: ComponentRef<any>;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.componentRef) {
      let products = this.products;
      let selectedLabel = this.selectedLabel;

      if (selectedLabel) {
        products = products.filter(product => {
          return product[ProductKeys.Labels]?.some(label => label.id === selectedLabel.id);
        });
      }
      Object.assign(
        this.componentRef.instance,
        {},
        {
          category: products.length ? this.category : undefined,
          products: products,
          isOrderingAllowed: this.isOrderingAllowed,
        },
      );
    }
  }

  ngOnInit(): void {
    this.componentHost.viewContainerRef.clear();

    if (!this.componentClass) {
      return;
    }

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.componentClass,
    );

    const viewContainerRef = this.componentHost.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent<any>(componentFactory);

    if (this.products.length) {
      Object.assign(
        this.componentRef.instance,
        {},
        {
          category: this.products.length ? this.category : undefined,
          products: this.products,
          isOrderingAllowed: this.isOrderingAllowed,
          categoryNameColor: this.categoryNameColor,
          categoryDescriptionColor: this.categoryDescriptionColor,
          highlightCategoryNameColor: this.highlightCategoryNameColor,
          highlightCategoryBackgroundColor: this.highlightCategoryBackgroundColor,
          productNameColor: this.productNameColor,
          productDescriptionColor: this.productDescriptionColor,
          productPriceColor: this.productPriceColor,
          productImagePlaceholderColor: this.productImagePlaceholderColor,
          productBorderColor: this.productBorderColor,
          productBackgroundColor: this.productBackgroundColor,
          tagColors: this.tagColors,
        },
      );
    }

    this.componentRef.instance.navigateTo
      .pipe(
        takeUntil(this.destroyed$),
        tap(($event: any) => this.navigateTo.emit($event)),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
