import { mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  CONTENT_STORE_API_ACTIONS,
  CONTENT_STORE_LOCAL_ACTIONS,
} from '@store/content/content.action';

@Injectable({
  providedIn: 'root',
})
export class ContentEffects {
  constructor(private _actions: Actions) {}

  // Blocks
  fetchBlocks = createEffect(() => {
    return this._actions.pipe(
      ofType(CONTENT_STORE_API_ACTIONS.fetchBlocks),
      mergeMap(() => {
        return [CONTENT_STORE_LOCAL_ACTIONS.updateBlocksIsLoading({ isLoading: true })];
      }),
    );
  });

  fetchBlocksSuccess = createEffect(() => {
    return this._actions.pipe(
      ofType(CONTENT_STORE_API_ACTIONS.fetchBlocksSuccess),
      mergeMap(({ blocks }) => {
        return [
          CONTENT_STORE_LOCAL_ACTIONS.addBlocks({ blocks }),
          CONTENT_STORE_LOCAL_ACTIONS.updateBlocksIsLoaded({ isLoaded: true }),
          CONTENT_STORE_API_ACTIONS.fetchBlocksComplete(),
        ];
      }),
    );
  });

  fetchBlocksFailure = createEffect(() => {
    return this._actions.pipe(
      ofType(CONTENT_STORE_API_ACTIONS.fetchBlocksFailure),
      mergeMap(({ error }) => {
        return [
          CONTENT_STORE_LOCAL_ACTIONS.updateError({ error }),
          CONTENT_STORE_LOCAL_ACTIONS.updateBlocksIsLoaded({ isLoaded: false }),
          CONTENT_STORE_API_ACTIONS.fetchBlocksComplete(),
        ];
      }),
    );
  });

  fetchBlocksComplete = createEffect(() => {
    return this._actions.pipe(
      ofType(CONTENT_STORE_API_ACTIONS.fetchBlocksComplete),
      mergeMap(() => {
        return [CONTENT_STORE_LOCAL_ACTIONS.updateBlocksIsLoading({ isLoading: false })];
      }),
    );
  });

  // Modals
  fetchModals = createEffect(() => {
    return this._actions.pipe(
      ofType(CONTENT_STORE_API_ACTIONS.fetchModals),
      mergeMap(() => {
        return [CONTENT_STORE_LOCAL_ACTIONS.updateBlocksIsLoading({ isLoading: true })];
      }),
    );
  });

  fetchModalsSuccess = createEffect(() => {
    return this._actions.pipe(
      ofType(CONTENT_STORE_API_ACTIONS.fetchModalsSuccess),
      mergeMap(({ modals }) => {
        return [
          CONTENT_STORE_LOCAL_ACTIONS.addModals({ configs: modals }),
          CONTENT_STORE_LOCAL_ACTIONS.updateModalsIsLoaded({ isLoaded: true }),
          CONTENT_STORE_API_ACTIONS.fetchModalsComplete(),
        ];
      }),
    );
  });

  fetchModalsFailure = createEffect(() => {
    return this._actions.pipe(
      ofType(CONTENT_STORE_API_ACTIONS.fetchModalsFailure),
      mergeMap(({ error }) => {
        return [
          CONTENT_STORE_LOCAL_ACTIONS.updateError({ error }),
          CONTENT_STORE_LOCAL_ACTIONS.updateModalsIsLoaded({ isLoaded: false }),
          CONTENT_STORE_API_ACTIONS.fetchModalsComplete(),
        ];
      }),
    );
  });

  fetchModalsComplete = createEffect(() => {
    return this._actions.pipe(
      ofType(CONTENT_STORE_API_ACTIONS.fetchModalsComplete),
      mergeMap(() => {
        return [CONTENT_STORE_LOCAL_ACTIONS.updateModalsIsLoading({ isLoading: false })];
      }),
    );
  });
}
