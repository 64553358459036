import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-kiosk-language-flag-atom',
  templateUrl: './kiosk-language-flag-atom.component.html',
  styleUrls: ['./kiosk-language-flag-atom.component.scss'],
})
export class KioskLanguageFlagAtomComponent {
  @Input() languageFlagUrl!: string;
}
