import { find } from 'lodash';
import { Store } from '@ngrx/store';
import { Component, Input, OnInit } from '@angular/core';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { CartService } from '@core/services/cart/cart.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { ZerosixService } from '@core/services/zerosix/zerosix.service';

import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { SELECT_AUTH_USER_META_FIELDS } from '@store/authentication';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { MarketplaceStoreRefiner } from '@common/refiners/marketplace.refiner';
import { IZerosixAsset, IZerosixCard, ZerosixAssetType } from '@core/models/zerosix.model';
import { ZerosixOfferedProductPickerModalComponent } from '@shared/modals/zerosix/zerosix-offered-product-picker-modal/zerosix-offered-product-picker-modal.component';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-zerosix-asset-list-block',
  templateUrl: './zerosix-asset-list-block.component.html',
})
export class ZerosixAssetListBlockComponent extends DestroyerBase implements OnInit {
  @Input() heading = 'como.loyalty-rewards';
  @Input() subheading?: string;
  @Input() width: 'centered' | 'full' = 'centered';
  @Input() itemsToShowCardCarousel = 4;
  @Input() headingColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() textColor: IContentBuilderFieldColor = {
    value: 'neutral-600',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() assetNameColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() assetDetailsColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };

  selectIsZeroSixPluginEnabled = this.marketplaceRefiner.selectIsZeroSixPluginEnabled;

  memberCard?: IZerosixCard;
  assets: IZerosixAsset[] = [];
  compartSlides!: IZerosixAsset[][];
  zerosixAssetType = ZerosixAssetType;

  isDesktop = false;

  constructor(
    private store: Store,
    private readonly cartService: CartService,
    private readonly ordersService: OrdersService,
    private readonly modalsService: ModalsService,
    private readonly zerosixService: ZerosixService,
    protected settingsSelector: SettingsStoreSelector,
    private marketplaceRefiner: MarketplaceStoreRefiner,
  ) {
    super();

    this.settingsSelector.selectDeviceIsDesktopScreen
      .pipe(takeUntil(this._destroyerRef))
      .subscribe(_isDesktop => (this.isDesktop = _isDesktop));
  }

  ngOnInit(): void {
    const shopId = this.cartService.getShopId();
    this.store
      .select(SELECT_AUTH_USER_META_FIELDS)
      .pipe(
        takeUntil(this._destroyerRef),
        switchMap(() => this.zerosixService.getCard$(shopId)),
        tap(memberCard => {
          if (!memberCard) {
            return;
          }
          this.memberCard = memberCard;
          const vouchers = memberCard.available_vouchers;
          vouchers.forEach(voucher => {
            voucher.type = ZerosixAssetType.VOUCHER;
            voucher.is_usable = true;
          });

          const rewards = memberCard.rewards;
          rewards.forEach(reward => {
            reward.type = ZerosixAssetType.REWARD;
          });

          this.assets = [...vouchers, ...rewards];
        }),
      )
      .subscribe();
  }

  useAsset(asset: IZerosixAsset): void {
    const coupon = this.zerosixService.calculateCouponFromAsset(asset);
    if (!coupon) {
      return;
    }

    this.cartService.addCoupon(coupon);

    if (asset.external_ids.length > 0) {
      ZerosixOfferedProductPickerModalComponent.asset = asset;
      ZerosixOfferedProductPickerModalComponent.onProductSelected = (): void => {
        this.updateCart();
        ZerosixOfferedProductPickerModalComponent.onProductSelected = (): void => {};
      };

      this.modalsService.open(ZerosixOfferedProductPickerModalComponent.handle);
    } else {
      this.updateCart();
    }
  }

  removeAsset(asset: IZerosixAsset): void {
    const coupon = this.zerosixService.calculateCouponFromAsset(asset);
    if (!coupon) {
      return;
    }
    this.cartService.removeCoupon(coupon);
    this.updateCart();
  }

  getAssetTag(voucher: IZerosixAsset): string {
    let description = '';

    if (voucher.value) {
      description += '– ' + voucher.value + ' ';
    }
    if (voucher.unit) {
      description += voucher.unit + ' ';
    }
    return description.trim();
  }

  isAssetInCart(voucher: IZerosixAsset): boolean {
    const coupons = this.cartService.getCouponsInCart();

    return !!find(
      coupons,
      coupon =>
        (coupon.code && coupon.code === voucher.code) ||
        (coupon.key && coupon.key === 'reward_' + voucher.id),
    );
  }

  private updateCart(): void {
    this.ordersService.checkCartIsValid$().pipe(take(1)).subscribe();
  }
}
