import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { createHydratedStoreActions } from '@store/_abstract/hydrated/hydrated.action';

import {
  SettingsAppState,
  SettingsDeviceState,
  SettingsKioskState,
  SettingsParametersState,
  SETTINGS_STORE_KEY,
} from './settings.state';

export const SETTINGS_STORE_API_ACTIONS = createActionGroup({
  source: 'Dood/API/Settings',
  events: {},
});

export const SETTINGS_STORE_LOCAL_ACTIONS = createActionGroup({
  source: 'Dood/LOCAL/Settings',
  events: {
    // Search
    'Update Parameters': props<{ parameters?: Partial<SettingsParametersState> }>(),
    'Reset Parameters': emptyProps(),

    // Device
    'Calculate Device': emptyProps(),
    'Update Device': props<{ device?: Partial<SettingsDeviceState> }>(),
    'Reset Device': emptyProps(),

    // APP
    'Update App': props<{ app: Partial<SettingsAppState> }>(),
    'Update App Locale': props<{ locale: string }>(),
    'Reset App': emptyProps(),

    // Kiosk
    'Update Kiosk': props<{ kiosk: Partial<SettingsKioskState> }>(),
    'Update Kiosk Id': props<{ id: string | null }>(),
    'Update Is Kiosk Layout': props<{ isKioskLayout: boolean }>(),
    'Reset Kiosk': emptyProps(),

    // Misc
    'reset': emptyProps(),
  },
});

export const SETTINGS_STORE_HYDRATION_ACTIONS = createHydratedStoreActions(SETTINGS_STORE_KEY);
