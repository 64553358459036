<div [style.backgroundColor]="backgroundColor | contentBuilderColor: 'neutral-100'">
  <ng-container *ngIf="!(isShopLoading$ | async); else loading">
    <ng-container *ngIf="shop$ | async as shop; else loading">
      <app-simple-small-hero-atom
        [heading]="displayHeading ? shop[shopKeys.Name] : ''"
        [category]="displayCategory ? shop[shopKeys.ShortDescription] : ''"
        [description]="displayDescription ? shop[shopKeys.Description] : ''"
        [titleSize]="titleSize"
      ></app-simple-small-hero-atom>
      <div
        class="px-5 py-5 lg:px-12 kiosk:py-10"
        *ngIf="displayGroupedOrderButton"
      >
        <app-button-atom
          class="pr-2 pb-2"
          [size]="'XS'"
          [label]="
            (isGroupedOrderActive
              ? 'shop-page.share-my-grouped-order'
              : 'shop-page.create-my-grouped-order'
            ) | translate
          "
          type="light"
          [icon]="'icon-group-order'"
          (clickEventEmitter)="openGroupOrderModal()"
        ></app-button-atom>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #loading>
  <div class="animate-pulse bg-neutral-200 px-5 py-5 lg:px-12 w-full flex flex-col align-middle">
    <div class="animate-pulse bg-neutral-400 h-4 w-24 mb-4"></div>
    <div class="animate-pulse bg-neutral-400 h-9 w-56"></div>
  </div>
</ng-template>
