import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { EntityMapOne } from '@ngrx/entity';

import {
  DECLINABLE_STORE_ACTIVE_ACTIONS,
  DECLINABLE_STORE_LOCAL_ACTIONS,
} from '@store/declinable/declinable.action';
import { DoodDeclinableModel } from '@core/models/product.model';

@Injectable({ providedIn: 'root' })
export class DeclinableStoreDispatcher {
  constructor(private store: Store) {}

  // One
  mapOne(entity: EntityMapOne<DoodDeclinableModel>): void {
    this.store.dispatch(DECLINABLE_STORE_ACTIVE_ACTIONS.mapOne({ entity }));
  }

  // All
  setAll(entities: DoodDeclinableModel[]): void {
    this.store.dispatch(DECLINABLE_STORE_ACTIVE_ACTIONS.setAll({ entities }));
  }

  removeAll(): void {
    this.store.dispatch(DECLINABLE_STORE_ACTIVE_ACTIONS.removeAll());
  }

  // Active
  setActive(active: string): void {
    this.store.dispatch(DECLINABLE_STORE_ACTIVE_ACTIONS.setActive({ active }));
  }

  // State
  reset(): void {
    this.store.dispatch(DECLINABLE_STORE_LOCAL_ACTIONS.reset());
  }
}
