<div class="bg-neutral-100 px-11 pb-24">
  <app-social-item-atom
    [text]="baseline ?? ''"
    [links]="[
      { url: socialInstagramURL ?? '', icon: 'instagram' },
      { url: socialFacebookURL ?? '', icon: 'facebook' },
      { url: socialTiktokURL ?? '', icon: 'tiktok' },
      { url: socialLinkedinURL ?? '', icon: 'linkedin' },
    ]"
  ></app-social-item-atom>
</div>
