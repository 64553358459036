import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';

import {
  SELECT_TRANSACTION_ACTIVE,
  SELECT_TRANSACTION_ACTIVE_ID,
  SELECT_TRANSACTION_ALL,
  SELECT_TRANSACTION_AMOUNT,
  SELECT_TRANSACTION_ENTITY,
  SELECT_TRANSACTION_ERROR,
  SELECT_TRANSACTION_PAYMENT_MODE,
} from '@store/transaction/transaction.selection';
import { DoodTransactionModel } from '@core/models/transaction.model';

@Injectable({ providedIn: 'root' })
export class TransactionStoreSelector implements OnDestroy {
  private readonly _destroyerRef = new Subject<boolean>();

  transactions: DoodTransactionModel[] = [];
  active: DoodTransactionModel | null = null;

  // Transactions
  selectTransactions = this.store.select(SELECT_TRANSACTION_ALL);
  selectTransaction = (id: string): Observable<DoodTransactionModel | undefined> =>
    this.store.select(SELECT_TRANSACTION_ENTITY(id));

  // Error
  selectError = this.store.select(SELECT_TRANSACTION_ERROR);

  // Active
  selectActive = this.store.select(SELECT_TRANSACTION_ACTIVE);
  selectActiveId = this.store.select(SELECT_TRANSACTION_ACTIVE_ID);

  // Active
  selectAmount = this.store.select(SELECT_TRANSACTION_AMOUNT);

  // Mode
  selectPaymentMode = this.store.select(SELECT_TRANSACTION_PAYMENT_MODE);

  constructor(private store: Store) {
    this.selectTransactions.pipe(takeUntil(this._destroyerRef)).subscribe(_transactions => {
      this.transactions = _transactions;
    });
    this.selectActive.pipe(takeUntil(this._destroyerRef)).subscribe(_active => {
      this.active = _active;
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe();
  }

  private _unsubscribe(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
  }
}
