<div
  class="rounded-lg overflow-hidden relative {{
    backgroundColor
  }} lg:flex lg:w-full xl:justify-between"
>
  <div class="flex flex-col justify-between">
    <div
      class="absolute xl:static left-0 top-0 w-full h-full xl:h-auto flex xl:block items-center justify-center xl:mx-8 xl:my-10"
    >
      <span class="font-accent text-white text-lg xl:text-4.5xl font-bold">{{ heading }}</span>
    </div>
    <div class="mx-8 my-8 hidden xl:block">
      <app-button-atom
        size="S"
        type="outlined"
        [label]="buttonLabel"
      ></app-button-atom>
    </div>
  </div>
  <img
    [src]="pictureUrl"
    class="w-full xl:max-w-256"
    alt="img"
    loading="lazy"
    *ngIf="pictureUrl"
  />
</div>
