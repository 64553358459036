import { Component, Input, OnInit } from '@angular/core';
import { IMosaicBlockCard } from '../../models/mosaic-block-card';
import { IPushTextButtonV2 } from '../../atoms/push-text-button-v2-atom/push-text-button-v2-atom.component';
import { IPushPicture } from '../../atoms/push-picture-atom/push-picture-atom.component';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

export interface IPush {
  type: string;
  items: IPushTextButtonV2[] | IPushPicture[];
}

@Component({
  selector: 'app-mosaic-block',
  templateUrl: './mosaic-block.component.html',
  styleUrls: ['./mosaic-block.component.scss'],
})
export class MosaicBlockComponent implements OnInit {
  @Input() cards?: Array<IMosaicBlockCard> | Array<any>;
  @Input() items?: IPush[];
  @Input() topMargin = 'medium';
  @Input() bottomMargin = 'medium';
  @Input() backgroundColor: IContentBuilderFieldColor = {
    value: 'transparent',
    type: ColorFieldTypesValues.Hex,
  };
  @Input() fullWidth = false;

  legacyCards?: Array<IMosaicBlockCard>;

  constructor() {}

  ngOnInit(): void {
    if (!this.cards?.[0]?.block) {
      this.legacyCards = this.cards;
    }
  }
}
