<div class="relative flex flex-auto lg:flex-row mdMax:flex-col">
  <div
    *ngIf="isMobile$ | async"
    class="absolute flex w-full items-center justify-center gap-x-2 px-4 py-3"
    style="z-index: 1"
  >
    <div class="flex flex-col">
      <app-input-text-atom
        [label]="textInputLabel | translate"
        [iconRight]="textInputIcon"
        [cross]="true"
        [value]="(filterAddress$ | async) ?? ''"
        (iconClick)="getCurrentPosition()"
        (valueChange)="onClear($event)"
      >
      </app-input-text-atom>
      <div
        *ngIf="geolocateError?.length"
        class="mt-2 rounded bg-white py-2 text-center font-base text-sm text-error-700"
      >
        {{ geolocateError }}
      </div>
    </div>
    <app-rounded-icon-button
      [icon]="filterIcon"
      (onClick)="openFiltersModal()"
    ></app-rounded-icon-button>
    <!-- <app-rounded-icon-button [icon]='searchIcon'></app-rounded-icon-button> -->
  </div>

  <div
    *ngIf="!(isMobile$ | async)"
    class="flex min-w-1/4 max-w-3/7 flex-col overflow-y-auto p-6"
  >
    <div class="flex gap-3">
      <app-button-atom
        *ngIf="!(isMobile$ | async)"
        [type]="'light'"
        [icon]="filterIcon"
        [label]="filterButtonLabel | translate"
        (clickEventEmitter)="openFiltersModal()"
      >
      </app-button-atom>
      <!-- <app-rounded-icon-button [icon]='searchIcon'></app-rounded-icon-button> -->
    </div>
    <h1 class="leading-8.5 pb-4 pt-5 font-accent text-28px font-bold">
      {{ 'shop-list-header.title' | translate }}
    </h1>
    <div class="flex max-h-45-screen flex-col divide-y divide-neutral-300">
      <div
        *ngFor="let shop of shops"
        class="py-3 first:pt-0"
        (click)="navigateToShopPage(shop)"
      >
        <app-push-shop-atom
          [name]="shop[entityKeys.Name]"
          [category]="shop[entityKeys.Type]?.name ?? ''"
          [distance]="shop[entityKeys.Distance]"
          [budget]="shop.pricing"
          [shop]="shop"
        >
        </app-push-shop-atom>
      </div>
      <ng-container *ngIf="isGetShopLoading && !shops.length">
        <div
          *ngFor="let j of [].constructor(8)"
          class="flex"
        >
          <div class="my-2 mr-2 h-24 w-24 animate-pulse rounded bg-neutral-300"></div>
          <div class="my-2 w-2/4">
            <p class="mb-2 h-4 w-full animate-pulse bg-neutral-400"></p>
            <p class="mb-2 h-3 w-3/4 animate-pulse bg-neutral-300"></p>
            <p class="mb-2 h-2 w-1/4 animate-pulse bg-neutral-300"></p>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="flex-auto">
    <google-map
      width="100%"
      height="100%"
      class="h-full"
      *ngIf="isMapApiLoaded"
      [options]="mapOptions"
    >
      <map-marker
        [icon]="currentMarkerIcon"
        *ngIf="currentMarkerPosition"
        [position]="currentMarkerPosition"
      ></map-marker>
      <map-marker
        [icon]="selectedMarkerIcon"
        *ngIf="selectedMarkerPosition"
        [options]="selectedMarkerOptions"
        [position]="selectedMarkerPosition"
      ></map-marker>
      <map-marker-clusterer
        [styles]="clusterStyles"
        [maxZoom]="clusterMaxZoom"
        [imagePath]="clusterImagePath"
        [minimumClusterSize]="clusterMinimumSize"
      >
        <ng-container *ngFor="let marker of markers">
          <map-marker
            [position]="marker"
            [icon]="neutralMarkerConfig"
            *ngIf="isMarkerVisible(marker)"
            (mapClick)="selectMarker($event)"
          ></map-marker>
        </ng-container>
      </map-marker-clusterer>
    </google-map>
  </div>

  <div class="sticky bottom-10 flex flex-col">
    <div
      class="absolute right-0 flex flex-row-reverse p-4"
      [ngClass]="isShopSideScrollingListVisible && (isMobile$ | async) ? 'bottom-28' : 'bottom-4'"
    >
      <app-rounded-icon-button
        *ngIf="isMobile$ | async"
        type="normal"
        [icon]="bulletListIcon"
        (onClick)="navigateToShopList()"
      >
      </app-rounded-icon-button>
      <app-button-atom
        class="shrink-0"
        *ngIf="!(isMobile$ | async)"
        [type]="'primary'"
        [icon]="bulletListIcon"
        [label]="bulletListButtonLabel | translate"
        (clickEventEmitter)="navigateToShopList()"
      >
      </app-button-atom>
    </div>

    <div
      #shopSideScrollingList
      *ngIf="shops.length && isShopSideScrollingListVisible && (isMobile$ | async)"
      class="absolute bottom-4 flex w-full snap-x gap-2 overflow-x-auto"
    >
      <div
        *ngFor="let shop of shops"
        class="inline-block h-24 w-11/12 shrink-0 snap-center rounded first:pl-5 last:pr-5"
        (click)="navigateToShopPage(shop)"
      >
        <app-push-shop-atom
          [name]="shop[entityKeys.Name]"
          [category]="shop[entityKeys.Type]?.name ?? ''"
          [distance]="shop[entityKeys.Distance]"
          [budget]="shop.pricing"
          [shop]="shop"
        >
        </app-push-shop-atom>
      </div>
    </div>
  </div>

  <div class="sticky bottom-0 lg:hidden">
    <app-shop-search-parameters-overview></app-shop-search-parameters-overview>
  </div>
</div>
