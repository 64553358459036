<div
  class="flex h-full flex-col overflow-hidden"
  *ngIf="categoryWithProducts$ | async as category"
>
  <div
    class="flex w-full items-center gap-2.5 pb-8"
    (click)="back()"
  >
    <i class="icon icon-chevron-down rotate-90 text-xs"></i>
    <h1 class="font-accent text-3.5xl font-semibold">{{ category.name }}</h1>
  </div>
  <div
    class="flex flex-col gap-5 overflow-scroll scrollbar-hide"
    [@staggeredListAnimation]="{
      value: category.productsInformation?.length,
      animateEnterElementFromTopToBottom: false,
    }"
  >
    <div class="grid grid-cols-3 gap-5 overflow-y-scroll scrollbar-hide">
      <ng-container *ngFor="let product of category.productsInformation">
        <app-kiosk-product-card-atom
          *ngIf="product"
          [product]="product"
          [quantity]="product.quantity"
          (addProduct)="addProductDispatcher(product)"
          (showProduct)="openProductModal(product)"
          [textColor]="textColor"
          [cardColor]="cardColor"
          [isProductAvailable]="!!product.available"
          [buttonBackgroundColor]="buttonBackgroundColor"
          [unavailableButtonBackground]="unavailableButtonBackground"
          [unavailableButtonText]="unavailableButtonText"
          [buttonTextColor]="buttonTextColor"
        ></app-kiosk-product-card-atom>
      </ng-container>
    </div>
  </div>
</div>
