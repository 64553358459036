import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';

import {
  SELECT_CART_ACTIVE,
  SELECT_CART_ACTIVE_ID,
  SELECT_CART_ALL,
  SELECT_CART_COMMENT,
  SELECT_CART_EDIT_ITEM,
  SELECT_CART_ENTITY,
  SELECT_CART_STATE,
  SELECT_CART_TOTAL,
} from '@store/cart/cart.selection';
import { DoodCartModel } from '@core/models/cart.model';
import { CART_STORE_INITIAL_STATE } from '@store/cart';

@Injectable({ providedIn: 'root' })
export class CartStoreSelector implements OnDestroy {
  private readonly _destroyerRef = new Subject<boolean>();

  state = CART_STORE_INITIAL_STATE;
  carts: DoodCartModel[] = [];
  active: DoodCartModel | null = null;

  select = this.store.select(SELECT_CART_STATE);

  // Carts
  selectCarts = this.store.select(SELECT_CART_ALL);
  selectCartsTotal = this.store.select(SELECT_CART_TOTAL);
  selectCart = (id: string): Observable<DoodCartModel | undefined> =>
    this.store.select(SELECT_CART_ENTITY(id));

  // Active
  selectActive = this.store.select(SELECT_CART_ACTIVE);
  selectActiveId = this.store.select(SELECT_CART_ACTIVE_ID);

  // Edit items
  selectEditItem = this.store.select(SELECT_CART_EDIT_ITEM);

  // Comment
  selectComment = this.store.select(SELECT_CART_COMMENT);

  constructor(private store: Store) {
    this.select.pipe(takeUntil(this._destroyerRef)).subscribe(_state => {
      this.state = _state;
    });
    this.selectCarts.pipe(takeUntil(this._destroyerRef)).subscribe(_carts => {
      this.carts = _carts;
    });
    this.selectActive.pipe(takeUntil(this._destroyerRef)).subscribe(_active => {
      this.active = _active;
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe();
  }

  private _unsubscribe(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
  }
}
