<div
  class="carousel relative m-auto w-full pt-10"
  data-ride="carousel"
>
  <div class="carousel-inner relative flex w-full items-stretch gap-x-3 overflow-hidden pb-4">
    <ng-container *ngFor="let card of cards">
      <ng-container
        *ngIf="
          cards.indexOf(card) >= currentIndex && cards.indexOf(card) < currentIndex + nbDisplayCards
        "
      >
        <ng-container *ngTemplateOutlet="carouselItem; context: { $implicit: card }"></ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="cards.length < nbDisplayCards">
      <ng-container *ngFor="let card of [].constructor(nbDisplayCards - cards.length)">
        <div class="flex-1 items-stretch"></div>
      </ng-container>
    </ng-container>
  </div>
  <div class="absolute right-0 top-0">
    <app-rounded-icon-button
      [size]="'xs'"
      [disabled]="!currentIndex"
      [type]="'light'"
      [icon]="'icon-arrow-left'"
      (click)="prev()"
    ></app-rounded-icon-button>
    <app-rounded-icon-button
      class="ml-2"
      [disabled]="currentIndex === cards.length - nbDisplayCards || nbDisplayCards >= cards.length"
      [size]="'xs'"
      [type]="'light'"
      [icon]="'icon-arrow-right'"
      (click)="next()"
    ></app-rounded-icon-button>
  </div>
</div>
