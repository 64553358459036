import { Stepper } from '../stepper.type';

export class StepperUtil {
  static findStepperWithNextStep(stepper: Stepper | undefined) {
    if (!stepper) {
      return;
    }
    let lastStepperWithNextStep = stepper;
    let recursiveStepper = stepper;

    while (recursiveStepper.childStepper) {
      recursiveStepper = recursiveStepper.childStepper;
      if (recursiveStepper.step.hasNextStep) {
        lastStepperWithNextStep = recursiveStepper;
      }
    }
    return lastStepperWithNextStep;
  }
}
