import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DOOD_API } from '@config/ws.config';
import { HttpClient } from '@angular/common/http';
import {
  ICartApi,
  ICartApiItem,
  DoodCartApiResponse,
  ICartApiUpdate,
} from '@core/models/cart-api.model';
import { CartItemKeys } from '@config/keys/cart.keys';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CartApiService {
  constructor(private readonly httpClient: HttpClient) {}

  createCart$(cart: ICartApi): Observable<DoodCartApiResponse> {
    return this.httpClient.post<DoodCartApiResponse>(`${DOOD_API}/carts`, cart);
  }

  getCart$(id: string, shareCode?: string): Observable<DoodCartApiResponse> {
    if (shareCode) {
      return this.httpClient.get<DoodCartApiResponse>(
        `${DOOD_API}/carts/${id}?share_code=${shareCode}`,
      );
    }
    return this.httpClient.get<DoodCartApiResponse>(`${DOOD_API}/carts/${id}`);
  }

  updateCart$(cart: ICartApiUpdate, cartId: string): Observable<DoodCartApiResponse> {
    return this.httpClient.patch<DoodCartApiResponse>(`${DOOD_API}/carts/${cartId}`, cart);
  }

  addCartItem$(
    cartId: string,
    item: ICartApiItem,
    quantity: number,
    shareCode?: string,
  ): Observable<DoodCartApiResponse> {
    if (shareCode) {
      return this.httpClient.post<DoodCartApiResponse>(
        `${DOOD_API}/carts/${cartId}/items?share_code=${shareCode}`,
        { product: { ...item }, quantity },
      );
    }
    return this.httpClient.post<DoodCartApiResponse>(`${DOOD_API}/carts/${cartId}/items`, {
      product: { ...item },
      quantity,
    });
  }

  updateCartItem$(
    cartId: string,
    item: ICartApiItem,
    basketItemId: string,
    shareCode?: string,
  ): Observable<DoodCartApiResponse> {
    if (shareCode) {
      return this.httpClient.patch<DoodCartApiResponse>(
        `${DOOD_API}/carts/${cartId}/items/${basketItemId}?share_code=${shareCode}`,
        { product: { ...item }, quantity: item[CartItemKeys.Quantity] },
      );
    }
    return this.httpClient.patch<DoodCartApiResponse>(
      `${DOOD_API}/carts/${cartId}/items/${item[CartItemKeys.Id]}`,
      { product: { ...item } },
    );
  }

  removeCartItem$(cartId: string, itemId: string): Observable<DoodCartApiResponse> {
    return this.httpClient.delete<DoodCartApiResponse>(
      `${DOOD_API}/carts/${cartId}/items/${itemId}`,
    );
  }

  removeCartUser$(cartId: string, userId: string): Observable<DoodCartApiResponse> {
    return this.httpClient.delete<DoodCartApiResponse>(
      `${DOOD_API}/carts/${cartId}/users/${userId}`,
    );
  }

  confirmCart$(cartId: string): Observable<DoodCartApiResponse> {
    return this.httpClient.post<DoodCartApiResponse>(`${DOOD_API}/carts/${cartId}/confirm`, {});
  }

  addCartCoupon$(cartId: string, coupon: any): Observable<DoodCartApiResponse> {
    return this.httpClient.patch<DoodCartApiResponse>(
      `${DOOD_API}/carts/${cartId}/coupons`,
      coupon,
    );
  }

  removeCartCoupon$(cartId: string, coupon: any): Observable<DoodCartApiResponse> {
    return this.httpClient
      .request('delete', `${DOOD_API}/carts/${cartId}/coupons`, {
        body: coupon,
      })
      .pipe(switchMap(() => this.getCart$(cartId)));
  }
}
