import { DoodProductModel } from '@core/models/product.model';
import { DeclinableProductStep } from '../stepper.type';
import { StepInfo } from '@core/models/stepper.model';

export class DeclinableStepperUtil {
  static calculateDeclinableStepStates({
    declinableProduct,
    totalSelectedQuantity,
    isValid,
    selectedCategoryId,
    selectableCategoryIds,
    setSelectedStep,
  }: {
    declinableProduct: DoodProductModel;
    totalSelectedQuantity: number;
    selectedCategoryId: string | undefined;
    isValid: boolean;
    selectableCategoryIds: (string | undefined)[] | undefined;
    setSelectedStep: (stepsId: StepInfo) => void;
  }): DeclinableProductStep {
    const hasMaxQuantity = declinableProduct.max_count !== undefined;

    const isMaxCountReached = totalSelectedQuantity >= (declinableProduct.max_count ?? Infinity);

    const currentStepIndex = selectableCategoryIds?.findIndex(
      // todo check mais je pense qu'on peut supprimer
      selectableCategoryId => selectedCategoryId === selectableCategoryId, // todo verify the id is correct
    );

    const canMakeMoreChoices = !!(hasMaxQuantity ? !isMaxCountReached : false);
    const hasNextCategory =
      typeof currentStepIndex == 'number' &&
      currentStepIndex < (selectableCategoryIds?.length ?? 0) - 1;
    const hasPreviousCategory = typeof currentStepIndex == 'number' && currentStepIndex > 0;
    const nextStepId =
      typeof currentStepIndex == 'number'
        ? selectableCategoryIds?.[currentStepIndex + 1]
        : undefined;
    const previousStepId =
      typeof currentStepIndex == 'number'
        ? selectableCategoryIds?.[currentStepIndex - 1]
        : undefined;
    return {
      type: 'declinable',
      product: declinableProduct,
      selectedCategoryId,
      selectedStepId: selectedCategoryId,
      isValid,
      canMakeMoreChoices,
      minStepCount: declinableProduct.min_count ?? 0,
      maxStepCount: declinableProduct.max_count ?? Infinity,
      count: totalSelectedQuantity,
      hasNextStep: hasNextCategory,
      hasPreviousStep: hasPreviousCategory,
      previousStepId,
      nextStepId,
      skippable: false, // for now, there is no cases where the declinable product is preselected
      nextStep: hasNextCategory
        ? {
            categoryId: nextStepId,
          }
        : undefined,
      previousStep: hasPreviousCategory
        ? {
            categoryId: previousStepId,
          }
        : undefined,
      setSelectedStep,
    } satisfies DeclinableProductStep;
  }
}
