import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DoodMarketplaceModel } from '@core/models/marketplace.model';
import { Observable } from 'rxjs';
import { DOOD_API } from '@config/ws.config';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MarketplacesApiService {
  constructor(private client: HttpClient) {}

  public getMarketplaceByDomain$(domain: string): Observable<DoodMarketplaceModel | undefined> {
    console.log('[DOOD] Load marketplace ' + domain);
    return this.client
      .get<Array<DoodMarketplaceModel | undefined>>(`${DOOD_API}/marketplaces?domains=${domain}`)
      .pipe(
        map(marketplaces => {
          const marketplace = marketplaces[0];
          return marketplace;
        }),
      );
  }
}
