import { Pipe, PipeTransform } from '@angular/core';

import { PAYMENT_METHODS } from '@config/payment-methods.config';

@Pipe({
  name: 'paymentMethodLabel',
})
export class PaymentMethodLabelPipe implements PipeTransform {
  transform(value: string): string {
    return PAYMENT_METHODS.find(method => method.handle === value)?.label || value;
  }
}
