import { Component, Input } from '@angular/core';

import { ILink } from '@shared/models/link';

@Component({
  selector: 'app-food-society-fat-button-block',
  templateUrl: './food-society-fat-button-block.component.html',
})
export class FoodSocietyFatButtonBlockComponent {
  @Input() text?: string;
  @Input() link?: ILink;
  @Input() heading?: string;
}
