import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DOOD_API } from '@config/ws.config';

import {
  DoodCreateOrderQuery,
  DoodOrderModel,
  DoodValidateOrderQuery,
  DoodValidateOrderResponse,
} from '@store/order/order.model';
import { DoodTransactionModel } from '@core/models/transaction.model';

interface ValidateAddressResponse {
  is_address_valid: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class OrdersApiService {
  constructor(private readonly httpClient: HttpClient) {}

  validateOrder$(order: DoodValidateOrderQuery): Observable<DoodValidateOrderResponse> {
    return this.httpClient.post<DoodValidateOrderResponse>(`${DOOD_API}/orders/validate`, order);
  }

  validateAddress$(address: any): Observable<ValidateAddressResponse> {
    return this.httpClient.post<ValidateAddressResponse>(
      `${DOOD_API}/orders/validate-address`,
      address,
    );
  }

  createOrder$(order: DoodCreateOrderQuery): Observable<DoodOrderModel> {
    return this.httpClient.post<DoodOrderModel>(`${DOOD_API}/orders`, order);
  }

  getOrderById$(id: string, shareCode?: string): Observable<DoodOrderModel> {
    let url = `${DOOD_API}/orders/${id}`;

    if (shareCode) {
      url += '?share_code=' + shareCode;
    }
    return this.httpClient.get<DoodOrderModel>(url);
  }

  getPrintedTicketById$(orderId: string, charByLine = 47): Observable<string[]> {
    return this.httpClient.get<string[]>(
      `${DOOD_API}/orders/${orderId}/print?charByLine=${charByLine}`,
    );
  }

  getActiveOrders$(userId: string, marketplaceId: string): Observable<DoodOrderModel[]> {
    return this.httpClient.get<DoodOrderModel[]>(
      `${DOOD_API}/users/${userId}/active-orders?marketplace=${marketplaceId}`,
    );
  }

  getHistoricalOrders$(userId: string, marketplaceId: string): Observable<DoodOrderModel[]> {
    return this.httpClient.get<DoodOrderModel[]>(
      `${DOOD_API}/users/${userId}/historical-orders?marketplace=${marketplaceId}`,
    );
  }

  getOrdersSlots$(order: object): Observable<number[]> {
    return this.httpClient.post<number[]>(`${DOOD_API}/orders/get-slots`, order);
  }

  getOrdersTransactions$(id: string): Observable<DoodTransactionModel[]> {
    // TODO: Check if the passed object is useful
    return this.httpClient.get<DoodTransactionModel[]>(`${DOOD_API}/orders/${id}/transactions`, {});
  }
}
