import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DoodUserModel } from '@store/authentication/authentication.model';
import { DOOD_API } from '@config/ws.config';
import { HttpClient } from '@angular/common/http';
import { IZerosixCard } from '@core/models/zerosix.model';

@Injectable({
  providedIn: 'root',
})
export class ZerosixApiService {
  constructor(private readonly httpClient: HttpClient) {}

  createZerosixUser$(marketplaceId: string, phoneNumber: string): Observable<DoodUserModel> {
    return this.httpClient.post<DoodUserModel>(
      `${DOOD_API}/marketplaces/${marketplaceId}/zerosix/user-create`,
      {
        phone_number: phoneNumber,
      },
    );
  }

  sendOtp$(marketplaceId: string, phoneNumber: string): Observable<DoodUserModel> {
    return this.httpClient.post<DoodUserModel>(
      `${DOOD_API}/marketplaces/${marketplaceId}/zerosix/otp-send`,
      {
        phone_number: phoneNumber,
      },
    );
  }

  attachUserWithOtp$(marketplaceId: string, identificationCode: string): Observable<DoodUserModel> {
    return this.httpClient.post<DoodUserModel>(
      `${DOOD_API}/marketplaces/${marketplaceId}/zerosix/attach-user`,
      {
        identification_code: identificationCode,
      },
    );
  }

  getCard$(marketplaceId: string, shopId?: string): Observable<IZerosixCard> {
    const params: any = {};
    if (shopId) {
      params.shop_id = shopId;
    }
    const searchParams = new URLSearchParams(params);
    return this.httpClient.get<IZerosixCard>(
      `${DOOD_API}/marketplaces/${marketplaceId}/zerosix/card?${searchParams.toString()}`,
    );
  }
}
