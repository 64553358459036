import { createFeature, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';

import {
  TRANSACTION_STORE_KEY,
  TRANSACTION_STORE_ACTIVE_ADAPTER,
  TransactionState,
} from './transaction.state';
import { TRANSACTION_STORE_REDUCER } from './transaction.reducer';
import { DoodTransactionModel } from '@core/models/transaction.model';

const TRANSACTION_STORE_FEATURE = createFeature({
  name: TRANSACTION_STORE_KEY,
  reducer: TRANSACTION_STORE_REDUCER,
});
const { selectTransactionState: SELECT_TRANSACTION_STATE } = TRANSACTION_STORE_FEATURE;

export const {
  selectIds: SELECT_TRANSACTION_IDS,
  selectError: SELECT_TRANSACTION_ERROR,
  selectAmount: SELECT_TRANSACTION_AMOUNT,
  selectActive: SELECT_TRANSACTION_ACTIVE_ID,
  selectEntities: SELECT_TRANSACTION_ENTITIES,
  selectIsLoaded: SELECT_TRANSACTION_IS_LOADED,
  selectIsLoading: SELECT_TRANSACTION_IS_LOADING,
  selectPaymentMode: SELECT_TRANSACTION_PAYMENT_MODE,
} = TRANSACTION_STORE_FEATURE;

export const { selectAll: SELECT_TRANSACTION_ALL } =
  TRANSACTION_STORE_ACTIVE_ADAPTER.getSelectors(SELECT_TRANSACTION_STATE);

export const SELECT_TRANSACTION_ENTITY = (
  id: string,
): MemoizedSelector<
  Record<string, any>,
  DoodTransactionModel | undefined,
  DefaultProjectorFn<DoodTransactionModel | undefined>
> =>
  createSelector(SELECT_TRANSACTION_STATE, (state: TransactionState) => {
    return state.entities[id];
  });

export const SELECT_TRANSACTION_ACTIVE = createSelector(
  SELECT_TRANSACTION_ENTITIES,
  SELECT_TRANSACTION_ACTIVE_ID,
  (entities, id) => (id ? (entities[id] ?? null) : null),
);
