<div class="container px-5 flex flex-col items-center justify-center h-screen min-w-screen">
  <h1
    class="font-brand font-bold text-5xl text-neutral-800 mb-3 text-center"
    translate="page-not-found.header"
  ></h1>
  <h2
    class="font-brand font-bold text-29px text-neutral-600 text-center"
    translate="page-not-found.secondary-message"
  ></h2>
  <app-button-atom
    size="L"
    [label]="'page-not-found.button-label' | translate"
    (click)="navigateToHome()"
    type="primary"
    [full]="true"
    class="w-80 mt-16"
  ></app-button-atom>
</div>
