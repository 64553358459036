import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ISimpleItem, ISimpleTimeSlotItem } from '@core/models/simple-item.model';

@Component({
  selector: 'app-custom-select-atom',
  templateUrl: './custom-select-atom.component.html',
})
export class CustomSelectAtomComponent {
  @Input() isDisable = false;
  @Input() selectedItem?: string | number;
  @Input() items!: ISimpleItem[] | ISimpleTimeSlotItem[];

  @Output() selectItem = new EventEmitter();

  onChange(): void {
    this.selectItem.emit(this.selectedItem);
  }
}
