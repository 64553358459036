import { Router } from '@angular/router';
import { Component, Input } from '@angular/core';

import { DoodProductModel } from '@core/models/product.model';
import { ProductKeys } from '@config/keys/product.keys';

import { RouterHelperService } from '@core/services/router-helper/router-helper.service';

@Component({
  selector: 'app-marketplace-category-horizontal',
  templateUrl: './marketplace-category-horizontal.component.html',
})
export class MarketplaceCategoryHorizontalComponent {
  @Input() products!: DoodProductModel[];
  @Input() id?: string;
  @Input() title?: string;

  constructor(
    private readonly router: Router,
    private readonly routerHelper: RouterHelperService,
  ) {}

  onNavigateTo(product: DoodProductModel): void {
    this.router.navigate([
      this.routerHelper.translateRoute(
        `/products/${product[ProductKeys.ShopId]}/${product[ProductKeys.Id]}`,
      ),
    ]);
  }
}
