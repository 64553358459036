export class DeclinableUtils {
  static countAdditionOccurrences(additions: string[]): Occurrence[] {
    return Array.from(
      additions
        .reduce((map, item) => {
          map.set(item, (map.get(item) || 0) + 1);
          return map;
        }, new Map<string, number>())
        .entries(),
    ).map(([id, quantity]) => ({ id, quantity: quantity }));
  }
}

interface Occurrence {
  id: string;
  quantity: number;
}
