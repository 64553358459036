import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';

import { ModalsService } from '@core/services/modals/modals.service';
import { NativeService } from '@core/services/native/native.service';

import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { ModalScrollBlockBase } from '@core/base/modalScrollBlock/modal-scroll-block.base';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
})
export class AlertModalComponent extends ModalScrollBlockBase implements OnInit {
  static handle = 'alert-modal';

  isMobile$ = this.settingsSelector.selectDeviceIsMobileScreen;

  index$: Observable<number> = this.modalSelector
    .selectModal(AlertModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  data$: Observable<any> = this.modalSelector
    .selectModal(AlertModalComponent.handle)
    .pipe(map(el => (el?.data ? el.data : {})));

  isNotched = false;

  constructor(
    private modalSelector: ModalStoreSelector,
    private readonly modalsService: ModalsService,
    private settingsSelector: SettingsStoreSelector,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.isNotched = await NativeService.isNotched();
  }

  close(): void {
    this.modalsService.close(AlertModalComponent.handle);
  }
}
