<ng-container *ngIf="anchors$ | async as anchors">
  <div class="container mx-auto flex flex-col md:flex-row mb-2 lg:mb-0">
    <ng-container *ngFor="let anchor of anchors">
      <a
        class="px-2 py-2.5 lg:mr-4 font-accent font-bold text-17px text-white"
        [routerLink]="'.'"
        [fragment]="anchor.handle"
      >
        {{ anchor.name }}
      </a>
    </ng-container>
  </div>
</ng-container>
