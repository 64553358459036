import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-input-custom-number',
  templateUrl: './input-custom-number.component.html',
})
export class InputCustomNumberComponent implements AfterViewInit {
  value!: number;

  @Input() prefix = false;
  @Input() suffix = false;
  @Input() symbol!: string;
  @Input() error = false;
  @Input() isAutoFocused = false;

  @Output() valueEmitter = new EventEmitter<number>();

  @ViewChild('number') input!: ElementRef<HTMLInputElement>;

  ngAfterViewInit(): void {
    if (this.isAutoFocused) {
      this.input.nativeElement.focus();
    }
  }
}
