<footer class="flex flex-col lg:flex-row py-8 bg-neutral-800">
  <div
    *ngIf="leftBody && leftBody.length"
    class="px-5 lg:px-12 lg:flex lg:justify-between"
  >
    <div class="lg:pr-28 xl:pr-32">
      <app-block-list [blockIdList]="leftBody"></app-block-list>
    </div>
  </div>
  <div
    *ngIf="columns && columns.length"
    class="px-5 lg:px-12 lg:flex lg:justify-between"
  >
    <div class="grid grid-cols-1 text-center md:grid-cols-4 md:text-left">
      <app-block-list [blockIdList]="columns"></app-block-list>
    </div>
  </div>
</footer>
