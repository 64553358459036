import { Injectable } from '@angular/core';

import { ModalsService } from '@core/services/modals/modals.service';
import { BeforeInstallPromptEvent } from '@shared/interfaces/event.interface';
import { AddHomeScreenModalComponent } from '@shared/modals/add-home-screen-modal/add-home-screen-modal.component';

@Injectable({
  providedIn: 'root',
})
export class PwaService {
  promptEvent: BeforeInstallPromptEvent | null = null;

  constructor(private readonly modalsService: ModalsService) {
    this.initialize();
  }

  initialize(): void {
    window.addEventListener('beforeinstallprompt', event => {
      this.promptEvent = event as BeforeInstallPromptEvent;
      if (event) {
        this.modalsService.open(AddHomeScreenModalComponent.handle);
      }
    });
  }
}
