export enum CartCustomerDeviceValues {
  Mobile = 'mobile',
  Desktop = 'desktop',
}

export enum CartAppPlatformValues {
  IOS = 'ios',
  Android = 'android',
  Webapp = 'webapp',
}

export enum CartCommentTypeValues {
  Merchant = 'merchant',
  Delivery = 'delivery',
}

export enum CartTipTypeValues {
  Currency = 'currency',
  Percent = 'percent',
}
