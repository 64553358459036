import { Component, DestroyRef, inject, Input } from '@angular/core';
import {
  IContentBuilderFieldColor,
  IContentBuilderFieldImage,
} from '@core/models/content-builder-fields.model';
import { map, Observable } from 'rxjs';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';
import { ModalsService } from '@core/services/modals/modals.service';
import { IDistributionMode } from '@core/models/shop.model';
import { ISimpleItem } from '@core/models/simple-item.model';
import { ParametersUtils } from '@shared/utils/parameters/parameters.utils';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { Router } from '@angular/router';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { ShopSearchParametersModalComponent } from '@shared/modals/shop-search-parameters-modal/shop-search-parameters-modal.component';
import { ContentBuilderFieldColors } from '@shared/interfaces/content-builder.interface';

@Component({
  selector: 'app-shop-hero-template-block',
  templateUrl: './shop-hero-template-block.component.html',
  styles: [
    `
      @media (min-width: 1024px) and (min-height: 820px) {
        .lg-and-min-height-820-hover-upper-container {
          @apply absolute;
          @apply bottom-0;
        }
      }
    `,
  ],
})
export class ShopHeroTemplateBlockComponent {
  @Input() displayNavigation = true;
  @Input() navigationLogo?: IContentBuilderFieldImage;
  @Input() accentColor?: ContentBuilderFieldColors;

  @Input() redirectUrl?: string;

  @Input() heroImage?: IContentBuilderFieldImage;
  @Input() heroLogo?: IContentBuilderFieldImage;
  @Input() heroBaseline?: string;
  @Input() heroBaselineColor?: IContentBuilderFieldColor;
  @Input() heroDistributionButtonColor?: IContentBuilderFieldColor;
  @Input() heroDistributionButtonBackgroundColor?: IContentBuilderFieldColor;

  @Input() heroContextualHeading?: string;
  @Input() heroContextualHeadingColor?: IContentBuilderFieldColor;
  @Input() heroContextualContent?: string;

  @Input() displayCarousel = false;
  @Input() carouselCardColor?: IContentBuilderFieldColor;

  @Input() cards?: [];

  destroyRef = inject(DestroyRef);

  availableDistributionModes$?: Observable<ISimpleItem[]>;
  settingsParameters$? = this.settingsSelector.selectParameters;

  constructor(
    private readonly marketplaceSelector: MarketplaceStoreSelector,
    private readonly modalsService: ModalsService,
    private readonly settingsSelector: SettingsStoreSelector,
    private readonly router: Router,
    private readonly routerHelper: RouterHelperService,
  ) {}

  ngOnInit(): void {
    this.availableDistributionModes$ =
      this.marketplaceSelector.selectMarketplaceDistributionModes.pipe(
        map((distributions: IDistributionMode[]) =>
          ParametersUtils.mapDistributionModes(distributions),
        ),
      );

    this.modalsService.subject$.subscribe(eventData => {
      if (eventData && eventData.success) {
        this.redirectToAnotherPage();
      }
    });
  }

  openParametersModal(distributionMode: string): void {
    this.modalsService.open(ShopSearchParametersModalComponent.handle);
    this.modalsService.setData(ShopSearchParametersModalComponent.handle, {
      overrideSelectedDistributionMode: distributionMode,
    });
  }

  redirectToAnotherPage(): void {
    if (this.redirectUrl) {
      this.redirectUrl.startsWith('http') || this.redirectUrl.startsWith('https')
        ? window.open(this.redirectUrl)
        : this.router.navigate([this.routerHelper.translateRoute(this.redirectUrl)]);
    }
  }
}
