import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HeaderService } from '@core/services/header/header.service';
import { NavigationHistoryService } from '@core/services/navigation-history/navigation-history.service';

@Component({
  selector: 'app-check-details-page',
  templateUrl: './check-details-page.component.html',
})
export class CheckDetailsPageComponent implements OnInit, OnDestroy {
  @Input() body: any;
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly headerService: HeaderService,
    private readonly navigationHistoryService: NavigationHistoryService,
  ) {}

  ngOnInit(): void {
    this.headerService.setTitle('pay-at-table.user-checks.my-table-check');
    this.navigationHistoryService.pushStateFromActivatedRouteSnapshot(
      'check-detail',
      this.activatedRoute.snapshot,
    );
  }

  ngOnDestroy(): void {
    this.headerService.setTitle(undefined);
  }
}
