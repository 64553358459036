import { IPaymentMethodDescriptor } from '@core/models/payment.model';
import { CheckItemKeys, CheckTransactionKeys } from '@config/keys/check.keys';

export interface DoodOpenCheck {
  id: string;
  closed_at: string;
  opened_at: string;
  items: DoodOpenCheckItem[];
  transactions: DoodOpenCheckTransaction[];
  shop: DoodOpenCheckShop;
  marketplace: DoodOpenCheckMarketplace;
  onsite_location: DoodOpenCheckOnsiteLocation;
  final_price: number;
  active_transaction?: DoodOpenCheckTransaction;
  outstanding_amount: number;
  mercure_private_key: string;
  available_payment_methods: {
    valid: boolean;
    violations: unknown[];
    payment_method: IPaymentMethodDescriptor;
  }[];
  currency: string;
}

export interface DoodOpenCheckItem {
  [CheckItemKeys.Id]: string;
  [CheckItemKeys.Name]: string;
  [CheckItemKeys.Price]: number;
  [CheckItemKeys.Description]: string;
  [CheckItemKeys.SelectedBy]: {
    user: string;
    transaction: string;
  };
}

export interface DoodOpenCheckItemExtended extends DoodOpenCheckItem {
  isChecked: boolean;
  isEnabled: boolean;
}

export interface DoodOpenCheckTransaction {
  [CheckTransactionKeys.Id]: string;
  [CheckTransactionKeys.Amount]: number;
  [CheckTransactionKeys.CancellationReason]: string;
  [CheckTransactionKeys.PaidAmount]: number;
  [CheckTransactionKeys.Source]: string;
  [CheckTransactionKeys.Status]: string;
  [CheckTransactionKeys.Type]: string;
  [CheckTransactionKeys.User]: string;
  [CheckTransactionKeys.CreatedAt]: string;
  [CheckTransactionKeys.PaymentIntendId]: string;
  [CheckTransactionKeys.Items]?: string[];
  [CheckTransactionKeys.Tip]?: any;
}

export enum CheckTransactionSources {
  DOOD = 'dood',
  CashRegister = 'cash-register',
}

export enum DoodCheckTransactionStatus {
  Captured = 'CAPTURED',
  Cancelled = 'CANCELLED',
  Authorized = 'AUTHORIZED',
  Payment = 'PAYMENT',
  CapturedButInconsistentWithRemote = 'STATUS_CAPTURED_BUT_INCONSISTENT_WITH_REMOTE',
}

export interface DoodOpenCheckShop {
  id: string;
  name: string;
  current_user_roles: any[];
}

export interface DoodOpenCheckMarketplace {
  id: string;
  name: string;
  current_user_roles: any[];
}

export interface DoodOpenCheckOnsiteLocation {
  id: string;
  name: string;
}
