export interface DoodAddressComponent {
  types: string[];
  long_name: string;
  short_name: string;
}

export interface DoodAddressLocationModel {
  lat?: number;
  lng?: number;
  address_components?: DoodAddressComponent[];
}

export interface DoodLocationModel extends DoodAddressLocationModel {
  address?: string;
  instructions?: string | null;
}

export const DoodWantedAtType = {
  asap: 'asap',
  later: 'later',
} as const;

export type DoodWantedAtType = (typeof DoodWantedAtType)[keyof typeof DoodWantedAtType];

export interface DoodLocationParameters {
  deliveryLat: number;
  deliveryLng: number;
  deliveryCity: string;
  deliveryCountry: string;
  deliveryPostalCode: string;
  deliveryStreetAddress: string;
  deliveryInstructions: string;
}
