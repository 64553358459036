import { ReducerTypes, ActionCreator, on } from '@ngrx/store';

import { HydrationState, cleanHydratedStoreState } from './hydrated.state';
import { HydratedStoreActionGroup } from './hydrated.action';

export const createHydratedStorePiece = <S extends HydrationState>(
  key: string,
  actions: HydratedStoreActionGroup,
): ReducerTypes<S, readonly ActionCreator[]>[] => {
  return [
    // Hydratable
    on(actions.updateHydratable, (state, { hydratable }) => {
      return { ...state, _hydratable: hydratable };
    }),

    // Misc
    on(actions.disabledHydrationAndClear, state => {
      cleanHydratedStoreState(key);
      return { ...state, _hydratable: false };
    }),
    on(actions.clear, state => {
      cleanHydratedStoreState(key);
      return state;
    }),
  ];
};
