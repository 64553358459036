import { ReducerTypes, ActionCreator, on } from '@ngrx/store';

import { PAYMENT_STORE_COMPONENT_ACTIONS, PAYMENT_STORE_LOCAL_ACTIONS } from './payment.action';
import {
  PaymentState,
  PAYMENT_STORE_INITIAL_STATE,
  PAYMENT_STORE_METHODS_INITIAL_STATE,
  PAYMENT_STORE_TRANSACTION_INITIAL_STATE,
  PAYMENT_STORE_BANCONTACT_INITIAL_STATE,
  PAYMENT_STORE_BUTTON_INITIAL_STATE,
  PAYMENT_STORE_STATUS_INITIAL_STATE,
} from './payment.state';

export const PAYMENT_STORE_API_PIECE: ReducerTypes<PaymentState, readonly ActionCreator[]>[] = [];

export const PAYMENT_STORE_LOCAL_PIECE: ReducerTypes<PaymentState, readonly ActionCreator[]>[] = [
  // Status
  on(PAYMENT_STORE_LOCAL_ACTIONS.updateStatus, (state, { status }): PaymentState => {
    return { ...state, status: { ...state.status, ...status } };
  }),
  on(PAYMENT_STORE_LOCAL_ACTIONS.resetStatus, (state): PaymentState => {
    return { ...state, status: PAYMENT_STORE_STATUS_INITIAL_STATE };
  }),

  // Button
  on(PAYMENT_STORE_LOCAL_ACTIONS.updateButton, (state, { button }): PaymentState => {
    return { ...state, button: { ...state.button, ...button } };
  }),
  on(PAYMENT_STORE_LOCAL_ACTIONS.resetButton, (state): PaymentState => {
    return { ...state, button: PAYMENT_STORE_BUTTON_INITIAL_STATE };
  }),

  // Bancontact
  on(PAYMENT_STORE_LOCAL_ACTIONS.updateBancontact, (state, { bancontact }): PaymentState => {
    return { ...state, bancontact: { ...state.bancontact, ...bancontact } };
  }),
  on(PAYMENT_STORE_LOCAL_ACTIONS.resetBancontact, (state): PaymentState => {
    return { ...state, bancontact: PAYMENT_STORE_BANCONTACT_INITIAL_STATE };
  }),

  // Transaction
  on(PAYMENT_STORE_LOCAL_ACTIONS.updateTransaction, (state, { transaction }): PaymentState => {
    return { ...state, transaction: { ...state.transaction, ...transaction } };
  }),
  on(PAYMENT_STORE_LOCAL_ACTIONS.resetTransaction, (state): PaymentState => {
    return { ...state, transaction: PAYMENT_STORE_TRANSACTION_INITIAL_STATE };
  }),

  // Methods
  on(PAYMENT_STORE_LOCAL_ACTIONS.updateMethods, (state, { methods }): PaymentState => {
    return { ...state, methods };
  }),
  on(PAYMENT_STORE_LOCAL_ACTIONS.resetMethods, (state): PaymentState => {
    return { ...state, methods: PAYMENT_STORE_METHODS_INITIAL_STATE };
  }),

  // Amount
  on(PAYMENT_STORE_LOCAL_ACTIONS.updateAmount, (state, { amount }): PaymentState => {
    return { ...state, amount };
  }),
  on(PAYMENT_STORE_LOCAL_ACTIONS.resetAmount, (state): PaymentState => {
    return { ...state, amount: null };
  }),

  // Error
  on(PAYMENT_STORE_LOCAL_ACTIONS.updateError, (state, { error }): PaymentState => {
    return { ...state, error };
  }),
  on(PAYMENT_STORE_LOCAL_ACTIONS.resetError, (state): PaymentState => {
    return { ...state, error: null };
  }),

  // Misc
  on(PAYMENT_STORE_LOCAL_ACTIONS.reset, (): PaymentState => {
    return PAYMENT_STORE_INITIAL_STATE;
  }),
];

export const PAYMENT_STORE_COMPONENT_PIECE: ReducerTypes<PaymentState, readonly ActionCreator[]>[] =
  [
    // Misc
    on(PAYMENT_STORE_COMPONENT_ACTIONS.updateState, (state, { changes }): PaymentState => {
      return {
        ...state,
        ...changes,
        button: {
          ...state.button,
          ...changes.button,
        },
        status: {
          ...state.status,
          ...changes.status,
        },
        bancontact: {
          ...state.bancontact,
          ...changes.bancontact,
        },
        transaction: {
          ...state.transaction,
          ...changes.transaction,
        },
      };
    }),

    // UI
    on(PAYMENT_STORE_COMPONENT_ACTIONS.updateUI, (state, { changes }): PaymentState => {
      const { button, error, status } = changes;
      return {
        ...state,
        error: error ?? state.error,
        button: {
          ...state.button,
          ...button,
        },
        status: {
          ...state.status,
          ...status,
        },
      };
    }),
    on(PAYMENT_STORE_COMPONENT_ACTIONS.resetUI, (state): PaymentState => {
      return {
        ...state,
        error: null,
        button: PAYMENT_STORE_BUTTON_INITIAL_STATE,
        status: PAYMENT_STORE_STATUS_INITIAL_STATE,
      };
    }),

    // Data
    on(PAYMENT_STORE_COMPONENT_ACTIONS.updateData, (state, { changes }): PaymentState => {
      const { amount, methods, transaction } = changes;
      return {
        ...state,
        amount: amount ?? state.amount,
        methods: methods ?? state.methods,
        transaction: {
          ...state.transaction,
          ...transaction,
        },
      };
    }),
    on(PAYMENT_STORE_COMPONENT_ACTIONS.resetData, (state): PaymentState => {
      return {
        ...state,
        amount: null,
        bancontact: PAYMENT_STORE_BANCONTACT_INITIAL_STATE,
        transaction: PAYMENT_STORE_TRANSACTION_INITIAL_STATE,
      };
    }),
  ];
