import { Component, Input, OnInit } from '@angular/core';

import { CartFunnelStoreDispatcher } from '@common/dispatchers/cart-funnel.dispatcher';

@Component({
  selector: 'app-cart-funnel-radio-item-block',
  templateUrl: './cart-funnel-radio-item-block.component.html',
})
export class CartFunnelRadioItemBlockComponent implements OnInit {
  @Input() title!: string;
  @Input() required = false;
  @Input() customTitle?: string;
  @Input() displayCustomTitleInComment = false;
  @Input() displayTitleInComment = true;
  @Input() options!: any;
  @Input() blockId!: string;
  @Input() formId!: string;

  constructor(private cartFunnelDispatcher: CartFunnelStoreDispatcher) {}

  ngOnInit(): void {
    this.cartFunnelDispatcher.addValue({
      id: this.blockId,
      title: this.title,
      formId: this.formId,
      required: this.required,
      displayTitle: this.displayTitleInComment,
      customTitle: this.customTitle,
      displayCustomTitleInComment: this.displayCustomTitleInComment,
    });
  }
}
