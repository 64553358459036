import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-card-carousel',
  templateUrl: './card-carousel.component.html',
})
export class CardCarouselComponent {
  @Input() nbDisplayCards = 4;
  @Input() cards: unknown[] = [];
  @Input() scrollInMobile = false;
  @Input() carouselItem!: TemplateRef<unknown>;

  currentIndex = 0;

  prev(): void {
    this.currentIndex = this.currentIndex === 0 ? this.currentIndex : this.currentIndex - 1;
  }

  next(): void {
    this.currentIndex =
      this.currentIndex === this.cards?.length - this.nbDisplayCards
        ? this.currentIndex
        : this.currentIndex + 1;
  }
}
