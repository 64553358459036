import { ChangeDetectionStrategy, Component, computed, inject, input, output } from '@angular/core';
import { ProductStoreDispatcher } from '@common/dispatchers/product.dispatcher';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { DoodProductModel } from '@core/models/product.model';
import { ModalsService } from '@core/services/modals/modals.service';
import { ProductWithUIData } from '@shared/local-store/cart/cart-draft-declinable-product.store';
import { KioskProductOverviewModalComponent } from '@shared/modals/kiosk-product-overview-modal/kiosk-product-overview-modal.component';

@Component({
  selector: 'app-simple-product-card-organism',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    @if (simpleProduct(); as product) {
      <div
        class="h-full rounded"
        [ngClass]="{
          'outline outline-[8px]': product.quantity,
          'outline-red-600': isCartValid()?.isValid === false,
        }"
        [style.outlineColor]="buttonBackgroundColor() | contentBuilderColor"
      >
        <app-kiosk-product-card-atom
          (click)="onCardClicked()"
          [product]="product"
          [quantity]="product.quantity"
          [isProductAvailable]="!!product.available"
          (changeProductQuantity)="onChangeProductQuantity($event)"
          [selectionnable]="product.available"
          [displayPrice]="false"
          [displayedQuantity]="product.quantity ?? 0"
          [textColor]="textColor()"
          [cardColor]="cardColor()"
          [unavailableButtonBackground]="unavailableButtonBackground()"
          [unavailableButtonText]="unavailableButtonText()"
          [buttonBackgroundColor]="buttonBackgroundColor()"
          [buttonTextColor]="buttonTextColor()"
          [secondaryColor]="secondaryColor()"
          [maxProduct]="forceType(product).remainingAuthorizedQuantity"
          [monoSelection]="maxProductQuantity() === 1"
          [displayedAddition]="product?.uiPrice"
          (showProduct)="openProductModal(product)"
        ></app-kiosk-product-card-atom>
      </div>
    }
  `,
})
export class SimpleProductCardOrganismComponent {
  private readonly productDispatcher = inject(ProductStoreDispatcher);
  private readonly modalsService = inject(ModalsService);

  public readonly cardColor = input<IContentBuilderFieldColor>();
  public readonly textColor = input<IContentBuilderFieldColor>();
  public readonly buttonBackgroundColor = input<IContentBuilderFieldColor>();
  public readonly buttonTextColor = input<IContentBuilderFieldColor>();
  public readonly backgroundColor = input<IContentBuilderFieldColor>();
  public readonly primaryButtonBackgroundColor = input<IContentBuilderFieldColor>();
  public readonly primaryButtonTextColor = input<IContentBuilderFieldColor>();
  public readonly secondaryColor = input<IContentBuilderFieldColor>();
  public readonly unavailableButtonBackground = input<IContentBuilderFieldColor>();
  public readonly unavailableButtonText = input<IContentBuilderFieldColor>();

  public readonly simpleProduct = input.required<Readonly<ProductWithUIData>>();
  public readonly maxProductQuantity = input<number>();
  public readonly isCartValid = input<{ isValid: boolean }>();

  public readonly showProductAdditions = output<{ selectedProductId: string }>();
  public readonly changeQuantity = output<number>();

  private readonly hasAdditions = computed(() => {
    return this.simpleProduct()?.additions.products.length > 0;
  });

  protected onChangeProductQuantity(quantity: number) {
    this.changeQuantity.emit(quantity);
  }

  private onShowProductAdditions() {
    const additions = this.simpleProduct()?.additions.products;
    if (!additions || !additions.length) {
      return;
    }
    const product = this.simpleProduct();
    if (!product) {
      return;
    }
    this.showProductAdditions.emit({ selectedProductId: product.id });
  }

  protected onCardClicked() {
    if (this.hasAdditions()) {
      this.onShowProductAdditions();

      return;
    }
  }

  openProductModal(product: DoodProductModel): void {
    this.productDispatcher.insertActive(product);
    this.modalsService.open(KioskProductOverviewModalComponent.handle, undefined, {
      image: product.images?.cover?.url,
      name: product.name,
      description: product.description,
      allergens: product.allergens,
      type: product.type,
      backgroundColor: this.buttonTextColor(),
      accentColor: this.secondaryColor(),
    });
  }

  // todo fix
  forceType(data: any): any {
    return data as any;
  }
}
