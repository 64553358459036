import { ReducerTypes, ActionCreator, on } from '@ngrx/store';

import { FunnelState } from './funnel.state';
import { FunnelStoreActionGroup } from './funnel.action';
import { FunnelAdapter, createFunnelAdapter } from './funnel.adapter';

export interface FunnelPieceOptions<State extends FunnelState<Step, Value, Id>, Step, Value, Id> {
  adapter?: FunnelAdapter<Step, Value, Id>;
  actions: FunnelStoreActionGroup<Step, Value, Id>;
}

export const createFunnelStorePiece = <
  State extends FunnelState<Step, Value, Id>,
  Step,
  Value,
  Id = string,
>({
  actions,
  adapter,
}: FunnelPieceOptions<State, Step, Value, Id>): ReducerTypes<State, readonly ActionCreator[]>[] => {
  const _adapter = adapter ?? createFunnelAdapter();
  return [
    // Step
    on(actions.addStep, (state, { step }) => {
      return { ...state, steps: _adapter.steps.addOne(step, state.steps) };
    }),
    on(actions.addSteps, (state, { steps }) => {
      return { ...state, steps: _adapter.steps.addMany(steps, state.steps) };
    }),
    on(actions.removeStep, (state, { id }) => {
      return { ...state, steps: _adapter.steps.removeOne(id, state.steps) };
    }),
    on(actions.removeSteps, (state, { ids }) => {
      return { ...state, steps: _adapter.steps.removeMany(ids, state.steps) };
    }),
    on(actions.updateStep, (state, { step }) => {
      return { ...state, steps: _adapter.steps.updateOne(step, state.steps) };
    }),
    on(actions.updateSteps, (state, { steps }) => {
      return { ...state, steps: _adapter.steps.updateMany(steps, state.steps) };
    }),
    on(actions.resetSteps, state => {
      return { ...state, steps: _adapter.steps.removeAll(state.steps) };
    }),
    on(actions.resetSteps, state => {
      return { ...state, steps: _adapter.steps.removeAll(state.steps) };
    }),

    // Value
    on(actions.addValue, (state, { value }) => {
      return { ...state, values: _adapter.values.addOne(value, state.values) };
    }),
    on(actions.addValues, (state, { values }) => {
      return { ...state, values: _adapter.values.addMany(values, state.values) };
    }),
    on(actions.removeValue, (state, { id }) => {
      return { ...state, values: _adapter.values.removeOne(id, state.values) };
    }),
    on(actions.removeValues, (state, { ids }) => {
      return { ...state, values: _adapter.values.removeMany(ids, state.values) };
    }),
    on(actions.updateValue, (state, { value }) => {
      return { ...state, values: _adapter.values.updateOne(value, state.values) };
    }),
    on(actions.updateValues, (state, { values }) => {
      return { ...state, values: _adapter.values.updateMany(values, state.values) };
    }),
    on(actions.resetValues, state => {
      return { ...state, values: _adapter.values.getInitialState() };
    }),

    // Active
    on(actions.setActive, (state, { active }) => {
      return { ...state, steps: _adapter.steps.setActive(active, state.steps) };
    }),
    on(actions.resetActive, state => {
      return { ...state, steps: _adapter.steps.resetActive(state.steps) };
    }),
    on(actions.updateActive, (state, { step }) => {
      return { ...state, steps: _adapter.steps.updateActive(step, state.steps) };
    }),
    on(actions.insertActive, (state, { step }) => {
      return { ...state, steps: _adapter.steps.insertActive(step, state.steps) };
    }),
    on(actions.upsertActive, (state, { changes }) => {
      return { ...state, steps: _adapter.steps.upsertActive(changes, state.steps) };
    }),

    // Misc
    on(actions.reset, state => {
      return {
        ...state,
        ...adapter?.getInitialState(state),
        steps: _adapter.steps.getInitialState(),
        values: _adapter.values.getInitialState(),
      };
    }),
  ];
};
