<div class="max-w-screen-sm mx-auto">
  <ng-container *ngIf="heading">
    <h2
      class="font-brand text-neutral-900 text-2xl"
      [innerHTML]="heading"
    ></h2>
  </ng-container>
  <ng-container *ngIf="text">
    <div
      class="font-base text-neutral-600 text-sm"
      [innerHTML]="text"
    ></div>
  </ng-container>
</div>
