import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import { DoodModalConfig, IBlockConfig } from '@core/models/marketplace.model';
import { ContentHeaderState, ContentProductModalState } from '@store/content/content.state';

import {
  CONTENT_STORE_API_ACTIONS,
  CONTENT_STORE_LOCAL_ACTIONS,
} from '@store/content/content.action';
import { CART_FUNNEL_STORE_LOCAL_ACTIONS } from '@store/cart-funnel/cart-funnel.action';

@Injectable({ providedIn: 'root' })
export class ContentStoreDispatcher {
  constructor(private store: Store) {}

  // Api
  fetchBlocksSuccess(blocks: IBlockConfig[]): void {
    this.store.dispatch(CONTENT_STORE_API_ACTIONS.fetchBlocksSuccess({ blocks }));
  }

  // Header
  updateHeader(header: Partial<ContentHeaderState>): void {
    this.store.dispatch(CONTENT_STORE_LOCAL_ACTIONS.updateHeader({ header }));
  }

  // Modals
  setModals(configs: DoodModalConfig[]): void {
    this.store.dispatch(CONTENT_STORE_LOCAL_ACTIONS.setModals({ configs }));
  }

  // Product
  updateProduct(product: Partial<ContentProductModalState>): void {
    this.store.dispatch(CONTENT_STORE_LOCAL_ACTIONS.updateProduct({ product }));
  }

  // Misc
  reset(): void {
    this.store.dispatch(CART_FUNNEL_STORE_LOCAL_ACTIONS.reset());
  }
}
