import { Component, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { CartStoreDispatcher } from '@common/dispatchers/cart.dispatcher';
import { ContentStoreDispatcher } from '@common/dispatchers/content.dispatcher';
import { ProductStoreDispatcher } from '@common/dispatchers/product.dispatcher';
import {
  CartItemKeys,
  ReconstructedCartItemKeys,
  ReconstructedCartKeys,
} from '@config/keys/cart.keys';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import {
  DoodReconstructedCart,
  DoodReconstructedCartItem,
  ICartItem,
} from '@core/models/cart.model';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { DoodProductModel } from '@core/models/product.model';
import { CartService } from '@core/services/cart/cart.service';
import { EventTrackerService } from '@core/services/event-tracker/event-tracker.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { SuggestionsSetsService } from '@core/services/suggestions-sets/suggestions-sets.service';
import { ItemEditModalComponent } from '@shared/modals/item-edit-modal/item-edit-modal.component';
import { KioskProductOverviewModalComponent } from '@shared/modals/kiosk-product-overview-modal/kiosk-product-overview-modal.component';
import { ProductAddModalComponent } from '@shared/modals/product-add-modal/product-add-modal.component';
import { filter, map, of, shareReplay, take, takeUntil } from 'rxjs';

type ProductModalWithCartItem = DoodProductModel & { cartItem?: ICartItem };

@Component({
  selector: 'app-kiosk-suggestions-modal',
  templateUrl: './kiosk-suggestions-modal.component.html',
})
export class KioskSuggestionsModalComponent extends DestroyerBase implements OnInit {
  static heading = 'kiosk-suggestions-modal.heading';
  static buttonBackgroundColor?: IContentBuilderFieldColor;
  static buttonTextColor?: IContentBuilderFieldColor;
  static buttonLabel = 'kiosk-suggestions-modal.continue-button';
  static textColor?: IContentBuilderFieldColor;
  static cardColor?: IContentBuilderFieldColor;
  static backgroundColor?: IContentBuilderFieldColor;
  static secondaryColor?: IContentBuilderFieldColor;
  static suggestionsSet?: string;

  suggestions?: ProductModalWithCartItem[] = [];

  get heading() {
    return KioskSuggestionsModalComponent.heading;
  }

  get buttonBackgroundColor() {
    return KioskSuggestionsModalComponent.buttonBackgroundColor;
  }

  get buttonTextColor() {
    return KioskSuggestionsModalComponent.buttonTextColor;
  }

  get textColor() {
    return KioskSuggestionsModalComponent.textColor;
  }

  get backgroundColor() {
    return KioskSuggestionsModalComponent.backgroundColor;
  }

  get secondaryColor() {
    return KioskSuggestionsModalComponent.secondaryColor;
  }

  get suggestionsSet() {
    return KioskSuggestionsModalComponent.suggestionsSet;
  }

  get cardColor() {
    return KioskSuggestionsModalComponent.cardColor;
  }

  get buttonLabel() {
    return KioskSuggestionsModalComponent.buttonLabel;
  }

  static handle: string = 'kiosk-suggestions-modal';

  protected readonly activeShopId = toSignal(
    this.cartService.getShopActive$().pipe(
      filter(shop => !!shop),
      map(shop => shop?.id as string),
      shareReplay(1),
    ),
  );

  constructor(
    private readonly modalsService: ModalsService,
    private readonly suggestionsSetsService: SuggestionsSetsService,
    private readonly cartService: CartService,
    private readonly eventTrackerService: EventTrackerService,
    private readonly contentDispatcher: ContentStoreDispatcher,
    private readonly cartStoreDispatcher: CartStoreDispatcher,
    private readonly productDispatcher: ProductStoreDispatcher,
  ) {
    super();
  }

  ngOnInit() {
    if (!this.suggestionsSet) {
      this.close();
      return;
    }
    this.suggestionsSetsService
      .getSuggestionsSet$(this.suggestionsSet)
      .pipe(takeUntil(this._destroyerRef), take(1))
      .subscribe(suggestions => {
        this.suggestions = suggestions.map((s: { data: any }) => s.data);
      });
  }

  private addToCart(
    product: ProductModalWithCartItem | undefined,
    quantity: number,
    options: string[],
  ): void {
    if (!product) {
      return;
    }
    of(product)
      .pipe(
        take(1),
        map(product => {
          const cartItem: Partial<ICartItem> = {
            [CartItemKeys.ItemId]: product?.id,
            [CartItemKeys.ShopId]: product?.shop_id,
            [CartItemKeys.Additions]: options,
            [CartItemKeys.Quantity]: quantity,
          };
          this.cartService.addCartItem$(cartItem as ICartItem).subscribe(cartItem => {
            product.cartItem = cartItem;
          });
          this.eventTrackerService.dispatch(EventTrackerService.EventProductAddedToCart, {
            product,
            quantity,
          });
        }),
      )
      .subscribe();
  }

  protected changeQuantity(product: ProductModalWithCartItem, quantity: number): void {
    const previousQuantity = product.quantity ?? 0;
    product.quantity = quantity;

    const hasQuantity = (product.quantity ?? 0) >= 1;
    const isSimpleProductWithAdditions =
      product.type === 'SIMPLE' && product.additions?.products?.length > 0;
    const productIsDeclinable = product.type === 'DECLINABLE';
    const isProductWithOptions = isSimpleProductWithAdditions || productIsDeclinable;
    const isProductAdded = hasQuantity && !product.cartItem;
    const hasCart = !!product.cartItem;

    if (isProductWithOptions && hasCart && product.cartItem) {
      // it force to keep the quantity to 1 if the user tries to remove the product
      // otherwise, the product will be removed from the cart and all its options will be loose.
      // The product can be removed directly from the openCartItemEditPopup
      const isProductRemoved = product.quantity <= 0;

      product.quantity = product.quantity <= 0 ? 1 : product.quantity;
      this.cartService.updateCartItem(
        {
          ...product.cartItem,
          quantity: product.quantity,
        },
        false,
      );
      if (isProductRemoved) {
        this.openCartItemEditPopup(product);
      }
      return;
    }
    const removeCartItem = !hasQuantity && product.cartItem;
    if (removeCartItem && product.cartItem?.id) {
      this.cartService.removeItem$(product.cartItem.id as number).subscribe();
      delete product.cartItem;
      return;
    }

    if (isProductAdded) {
      const isProductWithoutOptions = !isProductWithOptions;
      if (isProductWithoutOptions && isProductAdded) {
        this.addToCart(product, quantity, []);
        return;
      }
      if (isProductAdded && isProductWithOptions) {
        // s'il faut ouvrir une popup
        this.contentDispatcher.updateProduct({
          shopIsNotActive: true,
          productId: product.id,
          shopId: product.shop_id,
        });
        ProductAddModalComponent.allowAddToCart = true;
        ProductAddModalComponent.onAdd = (): void => {
          ProductAddModalComponent.onAdd = (): void => {};
        };
        ProductAddModalComponent.onClose = (): void => {
          ProductAddModalComponent.onClose = (): void => {};
        };
        // reset the product quantity to 0 if the user closes the modal without adding the product
        product.quantity = 0;
        this.modalsService.open(ProductAddModalComponent.handle, undefined, {
          displayPrice: false,
          backgroundColor: this.backgroundColor,
          textColor: this.textColor,
          buttonBackgroundColor: this.buttonBackgroundColor,
          buttonTextColor: this.buttonTextColor,
          onCartItemAdded: (cartItem: ICartItem) => {
            product.cartItem = cartItem;
            product.quantity = cartItem.quantity;
          },
        });
        return;
      }
    }

    const hasQuantityChanged = previousQuantity !== quantity;
    if (hasQuantityChanged && product.cartItem) {
      this.cartService.updateCartItem(
        {
          ...product.cartItem,
          quantity,
        },
        false,
      );
      return;
    }
  }

  private openCartItemEditPopup(product: ProductModalWithCartItem) {
    ItemEditModalComponent.navigate = false;
    ItemEditModalComponent.backgroundColor = this.backgroundColor;
    ItemEditModalComponent.textColor = this.textColor;
    ItemEditModalComponent.buttonBackgroundColor = this.buttonBackgroundColor;
    ItemEditModalComponent.buttonTextColor = this.buttonTextColor;
    this.modalsService.open(ItemEditModalComponent.handle, undefined, {
      onCartItemRemoved: () => {
        delete product.cartItem;
        product.quantity = 0;
      },
      onCartItemUpdated: (cartItem: ICartItem) => {
        product.cartItem = cartItem;
        product.quantity = cartItem.quantity;
      },
    });
    this.cartService
      .getCartLineItems$()
      .pipe(
        takeUntil(this._destroyerRef),
        take(1),
        map(reconstructedCart =>
          this.lookForItemInCart(product?.cartItem?.id as number, reconstructedCart),
        ),
        map(cartItem => {
          if (cartItem) {
            this.cartStoreDispatcher.updateStore({ edit_item: cartItem });
          }
        }),
      )
      .subscribe();
  }

  openProductModal(product: DoodProductModel): void {
    this.productDispatcher.insertActive(product);
    this.modalsService.open(KioskProductOverviewModalComponent.handle, undefined, {
      image: product.images?.cover?.url,
      name: product.name,
      description: product.description,
      allergens: product.allergens,
      type: product.type,
      backgroundColor: this.buttonBackgroundColor,
      accentColor: this.secondaryColor,
    });
  }

  close(): void {
    this.modalsService.close(KioskSuggestionsModalComponent.handle);
  }

  valid(): void {
    this.close();
  }
  protected onProductCardClick(product: ProductModalWithCartItem): void {
    const hasCart = !!product.cartItem;
    if (!hasCart) {
      return;
    }
    this.openCartItemEditPopup(product);
  }

  private lookForItemInCart(
    itemId: number,
    reconstructedCart: DoodReconstructedCart[],
  ): null | DoodReconstructedCartItem {
    for (const cartOfShop of reconstructedCart) {
      const cartItem = cartOfShop[ReconstructedCartKeys.Items].find(
        elem => elem[ReconstructedCartItemKeys.Item][CartItemKeys.Id] === itemId,
      );
      if (cartItem) {
        return cartItem;
      }
    }

    return null;
  }
}
