<a
  class="flex-shrink-0 rounded-m"
  [style.backgroundColor]="backgroundColor | contentBuilderColor: 'neutral-100'"
  [style.color]="textColor | contentBuilderColor: '00000'"
  [target]="linkTarget"
  [link]="linkUrl"
  tabindex="-1"
>
  <div
    class="flex h-full flex-col rounded-m shadow-md"
    [style.backgroundColor]="backgroundColor | contentBuilderColor"
  >
    @if (backgroundImage) {
      <img
        [src]="$any(backgroundImage).url"
        class="h-40 w-52 object-cover"
        [ngClass]="heading ? 'rounded-t-m' : 'rounded-m'"
      />
    }
    @if (heading || content) {
      <div
        class="relative overflow-hidden px-6 py-4 text-neutral-50"
        [ngClass]="backgroundImage ? 'h-24' : 'flex-1'"
      >
        <h3
          class="text-base font-bold"
          [style.color]="textColor | contentBuilderColor"
        >
          {{ heading }}
        </h3>
        @if (content) {
          <app-text-atom
            [style.color]="textColor | contentBuilderColor"
            class="line-clamp-2 text-10px"
            [content]="content"
          ></app-text-atom>
        }
      </div>
    }
  </div>
</a>
