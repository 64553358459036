<div
  class="flex h-full flex-col gap-5 overflow-hidden rounded transition-transform duration-100 active:scale-105"
  [style.backgroundColor]="cardColor | contentBuilderColor"
  [style.color]="textColor | contentBuilderColor"
  (click)="selectionnable || !isProductAvailable ? null : addToCart()"
  [class.cursor-pointer]="isProductAvailable && isOrderingAllowed"
  [class.pointer-events-none]="!isProductAvailable || !isOrderingAllowed"
>
  <div class="relative flex h-full flex-col justify-between gap-5 pb-4">
    @if (product.description?.length || product.allergens?.length) {
      <i
        (click)="!isProductAvailable ? null : showProductModal(); $event?.stopPropagation?.()"
        class="icon icon-c-info-3 pointer-events-auto absolute right-2 top-2 z-10 content-center pb-3 pl-3 text-center text-2xl font-semibold"
        [style.color]="textColor | contentBuilderColor"
        [class.opacity-50]="!isProductAvailable || !isOrderingAllowed"
      ></i>
    }
    <div class="m-auto flex max-w-44 flex-col pb-8">
      <p
        class="my-2 line-clamp-2 h-12 content-end overflow-hidden px-1 text-center align-bottom font-accent text-base font-bold"
      >
        {{ product[ProductKeys.Name] }}
      </p>

      <div class="m-auto aspect-square h-44 w-44">
        @if (product.images?.thumbnail?.url) {
          <img
            class="h-44 w-44 object-cover"
            [src]="product.images?.thumbnail?.url"
            alt=" "
          />
        }
      </div>
      <div class="relative flex flex-col flex-wrap backdrop-grayscale">
        <div class="absolute -bottom-5 flex w-full justify-center text-center">
          @if (displayPrice) {
            <div
              class="flex flex-row px-2 py-1 text-2xl font-bold"
              [style.backgroundColor]="cardColor | contentBuilderColor"
            >
              <ng-container *ngIf="displayDiscount(product); else priceOnly">
                <app-price-atom
                  size="2XL"
                  class="mr-1"
                  [amount]="product[ProductKeys.Discount] ?? 0"
                  [semiBold]="true"
                ></app-price-atom>
                <app-price-atom
                  class="mr-1"
                  size="L"
                  [amount]="product[ProductKeys.Price]"
                  [discount]="true"
                  [semiBold]="true"
                  *ngIf="product[ProductKeys.Price]"
                ></app-price-atom>
              </ng-container>
              <ng-template #priceOnly>
                <app-price-atom
                  *ngIf="!product[ProductKeys.PriceToDisplay]; else overridePrice"
                  class="mr-1"
                  size="2XL"
                  [amount]="product[ProductKeys.Price]"
                  [semiBold]="true"
                ></app-price-atom>
              </ng-template>
              <ng-template #overridePrice>
                <app-price-atom
                  class="mr-1"
                  size="2XL"
                  [amount]="forceType(product[ProductKeys.PriceToDisplay])"
                  [semiBold]="true"
                ></app-price-atom>
              </ng-template>
            </div>
          }
          @if (!displayPrice && displayedAddition) {
            <div
              class="flex flex-row px-2 py-1 text-2xl font-bold"
              [style.backgroundColor]="cardColor | contentBuilderColor"
            >
              <span class="text-2xl font-semibold">+</span>
              <app-price-atom
                class="mr-1"
                size="2XL"
                [amount]="displayedAddition / 100"
                [semiBold]="true"
              ></app-price-atom>
            </div>
          }
        </div>
      </div>
    </div>
    @if (!isProductAvailable || !isOrderingAllowed) {
      <div class="absolute flex h-full w-full flex-row flex-wrap items-center justify-center gap-5">
        <!-- <ng-container *ngFor="let label of product?.labels">
          @if (label.visible && label.name) {
            <div class="mr-auto">
              {{ label.name }}
            </div>
          }
        </ng-container> -->
        <div
          *ngIf="!selectionnable"
          class="z-30"
        >
          @if (!isProductAvailable || !isOrderingAllowed) {
            <app-button-atom
              size="S"
              [label]="'Indisponible'"
              thickSize="XS"
              [overrideBgColor]="unavailableButtonBackground"
              [overrideTextColor]="unavailableButtonText"
              type="kiosk-primary"
              [full]="true"
            ></app-button-atom>
          }
        </div>
        <div
          class="pointer-events-none absolute h-full w-full opacity-80"
          [class.bg-neutral-400]="!isProductAvailable || !isOrderingAllowed"
        ></div>
      </div>
    }
    @if (selectionnable && !monoSelection) {
      @if ((displayedQuantity > 0 && maxProduct > 1) || displayedQuantity > 1) {
        <div class="flex flex-row items-center justify-center px-4">
          <app-counter-atom
            [type]="'kiosk'"
            [size]="'L'"
            [min]="0"
            [max]="maxProduct"
            [backgroundColor]="buttonBackgroundColor | contentBuilderColor"
            [textColor]="buttonTextColor | contentBuilderColor"
            [count]="displayedQuantity ?? 0"
            [acceptNoItem]="true"
            (countChange)="changeNbProduct($event); forceType($event)?.stopPropagation?.()"
          ></app-counter-atom>
        </div>
      } @else {
        <div class="invisible">
          <!-- Use to keep all the card at the same height, if the counter is visible or not -->
          <app-counter-atom
            [type]="'kiosk'"
            [size]="'L'"
            [min]="0"
            [max]="maxProduct"
            [backgroundColor]="buttonBackgroundColor | contentBuilderColor"
            [textColor]="buttonTextColor | contentBuilderColor"
            [count]="displayedQuantity ?? 0"
            [acceptNoItem]="true"
          ></app-counter-atom>
        </div>
      }
    }
    @if (
      selectionnable &&
      monoSelection &&
      (product?.quantity === 0 || !product.quantity) &&
      (!isProductAvailable || !isOrderingAllowed)
    ) {
      <div class="flex flex-row items-center justify-center px-4">
        <app-button-atom
          size="S"
          [label]="'Indisponible'"
          thickSize="XS"
          [overrideBgColor]="unavailableButtonBackground"
          [overrideTextColor]="unavailableButtonText"
          type="kiosk-light"
          [full]="true"
          (click)="changeNbProduct(1)"
        ></app-button-atom>
      </div>
    }
  </div>
</div>
