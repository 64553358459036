import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ComoService } from '@core/services/como/como.service';
import { tap } from 'rxjs/operators';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';

@Component({
  selector: 'app-como-create-or-link-account-modal-identification-step',
  templateUrl: './como-create-or-link-account-modal-identification-step.component.html',
  styleUrls: ['./como-create-or-link-account-modal-identification-step.component.scss'],
})
export class ComoCreateOrLinkAccountModalIdentificationStepComponent implements OnInit {
  @Input() comoPlugin: any;
  @Output() close = new EventEmitter<void>();
  @Output() goToStep = new EventEmitter<string>();
  @Output() setPhoneOrEmail = new EventEmitter<string>();

  form: UntypedFormGroup = this.fb.group({
    phoneOrEmail: ['', [Validators.required]],
  });
  phoneErrorMessage?: string;
  errorMessage?: string;
  isValidating = false;
  phoneOrEmail = '';
  identificationType = 'phoneNumber';

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly comoService: ComoService,
    private authStoreSelector: AuthStoreSelector,
  ) {}

  ngOnInit(): void {
    this.identificationType = this.comoPlugin['identification_type'];
    const user = this.authStoreSelector.user;
    if (this.identificationType === 'email') {
      this.form.patchValue({ 'phoneOrEmail': user?.email });
    } else {
      this.form.patchValue({ 'phoneOrEmail': user?.phone });
    }
  }

  onSubmit(): void {
    if (this.form.invalid || this.isValidating) {
      return;
    }
    this.isValidating = true;
    this.phoneErrorMessage = undefined;
    this.errorMessage = undefined;

    if (this.form.controls['phoneOrEmail']) {
      let phoneOrEmail = this.form.value['phoneOrEmail'];
      this.setPhoneOrEmail.next(phoneOrEmail);
      this.comoService
        .linkComoUser$({ phoneOrEmail })
        .pipe(
          tap(response => {
            if (response.otp_sent) {
              this.goToStep.next('otp');
            } else {
              this.goToStep.next('confirmation');
            }
          }),
        )
        .subscribe({
          error: error => this.handleErrorResponse(error.error),
          complete: () => (this.isValidating = false),
        });
      return;
    }
  }

  private handleErrorResponse(error: any): void {
    this.isValidating = false;
    switch (error.code) {
      case 202:
        this.goToStep.next('registration');
        break;
      default:
        this.errorMessage = 'como.errors.generic-error';
    }
  }

  onContinueWithoutLoyalty() {
    this.close.next();
  }
}
