import { Component, Input } from '@angular/core';
import {
  IContentBuilderFieldColor,
  IContentBuilderFieldImage,
} from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-image-variable-width-block',
  templateUrl: './image-variable-width-block.component.html',
})
export class ImageVariableWidthBlockComponent {
  @Input() image?: IContentBuilderFieldImage;
  @Input() property = 'percent';
  @Input() width = '50';
  @Input() backgroundColor: IContentBuilderFieldColor = {
    value: 'transparent',
    type: ColorFieldTypesValues.Hex,
  };
}
