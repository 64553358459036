import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { Component, Input, OnInit } from '@angular/core';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { combineLatest, from, Observable, of, throwError } from 'rxjs';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';

import { UserKeys } from '@config/keys/user.keys';
import { MarketplaceKeys } from '@config/keys/shop.keys';

import { CartStoreSelector } from '@common/selectors/cart.selector';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

import { DoodAuthenticationStep, DoodUserModel } from '@store/authentication/authentication.model';

import { ComoService } from '@core/services/como/como.service';
import { UserService } from '@core/services/user/user.service';
import { PongoService } from '@core/services/pongo/pongo.service';
import { NativeService } from '@core/services/native/native.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { ZerosixService } from '@core/services/zerosix/zerosix.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { AuthFirebaseService } from '@core/services/api/auth-firebase/auth-firebase.service';
import { NoConnectionModalService } from '@core/services/no-connection-modal/no-connection-modal.service';
import { AuthenticationScreenService } from '@core/services/authentication-screen/authentication-screen.service';

import { ERRORS } from '@config/labels/errors.labels';
import { AuthModeValues } from '@config/values/order.values';
import { emailValidator } from '@shared/validators/email/email.validator';
import { AuthenticationUserJourneyType } from '@shared/blocks/authentication-block/authentication-user-journey-type';

import { AuthenticationModalComponent } from '@shared/modals/authentication-modal/authentication-modal.component';
import { ComoCreateOrLinkAccountModalComponent } from '@shared/modals/como/como-create-or-link-account-modal/como-create-or-link-account-modal.component';
import { PongoCreateOrLinkAccountModalComponent } from '@shared/modals/pongo/pongo-create-or-link-account-modal/pongo-create-or-link-account-modal.component';
import { ZerosixCreateOrLinkAccountModalComponent } from '@shared/modals/zerosix/zerosix-create-or-link-account-modal/zerosix-create-or-link-account-modal.component';
import { MarketplaceStoreRefiner } from '@common/refiners/marketplace.refiner';
import { AuthenticationFormState } from '@common/states/authentication-form.state';
import { AuthStoreDispatcher } from '@common/dispatchers/authentication.dispatcher';
import { ComoRegistrationModalComponent } from '@shared/modals/como/como-registration-modal/como-registration-modal.component';
import { MarketplaceService } from '@core/services/marketplace/marketplace.service';
import { getAuth, getRedirectResult, UserCredential } from 'firebase/auth';
import { FirebaseError } from 'firebase/app';

@Component({
  selector: 'app-authentication-home-step',
  templateUrl: './authentication-home-step.component.html',
})
export class AuthenticationHomeStepComponent implements OnInit {
  @Input() userJourneyType = AuthenticationUserJourneyType.ASK_EMAIL_FIRST;
  @Input() firstStepHeading!: string;
  @Input() firstStepLoginButton!: string;
  @Input() firstStepSignupButton!: string;
  @Input() loginStepHeading!: string;
  @Input() authenticationProviderGoogle = true;
  @Input() authenticationProviderFacebook = true;
  @Input() authenticationProviderApple = false;

  authenticationProviderComo = false;
  authenticationUserJourneyType = AuthenticationUserJourneyType;
  isUserRegistered = false;
  emailResetSend = false;
  emailErrorMessage?: string;
  passwordErrorMessage?: string;
  isSignInMethodPasswordAllowed = false;
  isSignInMethodGoogleAllowed = false;
  isSignInMethodAppleAllowed = false;
  isSignInMethodFacebookAllowed = false;
  isUserRegisteredCheckInProgress = false;
  isUserEmailLoginInProgress = false;
  signInWithPopupInProgress = false;
  form: UntypedFormGroup;
  authMode?: string;
  authModeValues = AuthModeValues;

  isAnonymous = false;
  authenticationSubStep = 'HOME';
  anyErrorMessage?: string;

  authenticationEmailValue$ = this.authenticationForm.selectValue<string>('email');

  isNative = NativeService.native;

  signInWithRedirectLoading$ = this.authSelector.selectStatusWithRedirect;

  constructor(
    private readonly router: Router,
    private readonly fb: UntypedFormBuilder,
    private cartSelector: CartStoreSelector,
    private authSelector: AuthStoreSelector,
    private readonly userService: UserService,
    private readonly comoService: ComoService,
    private authDispatcher: AuthStoreDispatcher,
    private readonly pongoService: PongoService,
    private readonly modalsService: ModalsService,
    private readonly nativeService: NativeService,
    private readonly zerosixService: ZerosixService,
    private readonly routerHelper: RouterHelperService,
    private marketplaceRefiner: MarketplaceStoreRefiner,
    private authenticationForm: AuthenticationFormState,
    private marketplaceSelector: MarketplaceStoreSelector,
    private readonly authFirebaseService: AuthFirebaseService,
    private readonly noConnectionModalService: NoConnectionModalService,
    private readonly authenticationScreenService: AuthenticationScreenService,
    private readonly marketplaceService: MarketplaceService,
  ) {
    const active = this.cartSelector.active;
    this.authMode = active
      ? active.auth_mode
      : this.marketplaceSelector.marketplace?.[MarketplaceKeys.AuthMode];
    this.isAnonymous = this.authFirebaseService.isAnonymous;

    this.form = this.fb.group({
      provider: [''],
      firebaseUid: [''],
      [UserKeys.Email]: [
        '',
        {
          validators: [emailValidator(), Validators.required],
        },
      ],
      [UserKeys.Password]: ['', Validators.required],
      [UserKeys.FirstName]: [''],
      [UserKeys.LastName]: [''],
    });

    this.authenticationForm.upsert(this.form);
  }

  ngOnInit(): void {
    if (this.authMode === AuthModeValues.OnlyAnonymous) {
      this.anonymousLogin();
    }

    const isSignInWithRedirect = this.authSelector.status.redirect;

    if (isSignInWithRedirect) {
      const userCredential$ = from(getRedirectResult(getAuth())).pipe(
        switchMap(userCredential =>
          userCredential
            ? of(userCredential)
            : throwError(() => new Error('UserCredential is null')),
        ),
      );
      this.googleLoginAfterRedirect(userCredential$);
    }

    if (this.nativeService.isInAppBrowser()) {
      this.authenticationProviderGoogle = false;
    }

    const comoPlugin = this.marketplaceService.getPluginByType('como');
    if (comoPlugin && comoPlugin.plugin_enabled === true) {
      this.authenticationProviderComo = true;
    }
  }

  closeModal(): void {
    this.modalsService.close(AuthenticationModalComponent.handle);
  }

  goToEmailLoginStep(): void {
    this.authenticationSubStep = 'EMAIL_LOGIN';
  }

  emailLogin(): void {
    this.form.controls.provider.setValue('password');
    this.isUserEmailLoginInProgress = true;
    this.fetchSignInMethodsForEmail(this.form.value.email.trim())
      .then(isUserRegistered => {
        if (!isUserRegistered) {
          this.isUserEmailLoginInProgress = false;
          this.authenticationScreenService.goToStep(DoodAuthenticationStep.signup);
          return;
        }
        if (!this.isSignInMethodPasswordAllowed) {
          this.isUserEmailLoginInProgress = false;
          return;
        }
        const password = this.form.value.password;
        if (!password) {
          this.isUserEmailLoginInProgress = false;
          this.goToEmailLoginStep();
          return;
        }
        if (this.isSignInMethodPasswordAllowed) {
          this.passwordLogin(this.form.value.email.trim(), password);
          return;
        }
      })
      .catch(error => {
        this.isUserEmailLoginInProgress = false;
      });
  }

  googleLogin(): void {
    this.signInWithPopupInProgress = true;
    if (Capacitor.isNativePlatform()) {
      this.googleNativeLogin();
    } else {
      this.authFirebaseService
        .signInWithGoogle()
        .pipe(
          tap(() => this.form.controls.provider.setValue('google.com')),
          tap((userCredential: UserCredential) => this.saveFirebaseProfile(userCredential)),
          switchMap((userCredential: UserCredential) =>
            this.authFirebaseService.saveFirebaseTokenInSessionStore$(userCredential),
          ),
          switchMap(() => this.authFirebaseService.loadUser$()),
          tap(user => this.handleLoginResult(user)),
          catchError(error => this.signInCatchError(error)),
        )
        .subscribe({
          error: error => {
            this.signInWithPopupInProgress = false;
            this.emailErrorMessage = this.authFirebaseService.getEmailErrorMessage(error);
            this.passwordErrorMessage = this.authFirebaseService.getPasswordErrorMessage(error);
          },
          complete: () => (this.signInWithPopupInProgress = false),
        });
    }
  }

  signInCatchError(error: any): Observable<never> {
    switch (error.code) {
      case 'auth/network-request-failed':
        this.noConnectionModalService.setDisplay(true);
        this.noConnectionModalService.setRetryInProgress(false);
        break;
      case 'auth/wrong-password':
        break;
      default:
        this.anyErrorMessage = error.code;
        break;
    }
    return throwError(() => error);
  }

  googleLoginAfterRedirect(userCredential: Observable<UserCredential>): void {
    userCredential
      .pipe(
        tap(() => this.form.controls.provider.setValue('google.com')),
        switchMap(credentials =>
          this.authFirebaseService.saveFirebaseTokenInSessionStore$(credentials),
        ),
        switchMap(() => this.authFirebaseService.loadUser$()),
        tap(() => this.authFirebaseService.loadUser$()),
        tap(user => this.handleLoginResult(user)),
        tap(() => this.authDispatcher.updateStatus({ redirect: false })),
      )
      .subscribe();
  }

  comoLogin() {
    this.modalsService.open(ComoRegistrationModalComponent.handle);
    this.modalsService.close(AuthenticationModalComponent.handle);
  }

  googleNativeLogin(): void {
    from(FirebaseAuthentication.signInWithGoogle())
      .pipe(
        switchMap(userCredential => {
          if (userCredential) {
            this.form.controls.provider.setValue('google.com');
            this.authFirebaseService.saveNativeFirebaseTokenInSessionStore$(userCredential);
            return this.authFirebaseService.saveNativeFirebaseTokenInSessionStore$(userCredential);
          }
          return of(undefined);
        }),
        switchMap(() => this.authFirebaseService.loadUser$()),
        tap(user => {
          if (user) {
            this.handleLoginResult(user);
          }
        }),
      )
      .subscribe({
        error: error => {
          this.signInWithPopupInProgress = false;
          this.emailErrorMessage = this.authFirebaseService.getEmailErrorMessage(error);
          this.passwordErrorMessage = this.authFirebaseService.getPasswordErrorMessage(error);
        },
        complete: () => (this.signInWithPopupInProgress = false),
      });
  }

  googleUpgrade(): void {
    this.signInWithPopupInProgress = true;
    this.authFirebaseService
      .upgradeAnonymousWithGoogle()
      .pipe(
        tap((userCredential: UserCredential) => this.saveFirebaseProfile(userCredential)),
        switchMap((userCredential: UserCredential) =>
          this.authFirebaseService.saveFirebaseTokenInSessionStore$(userCredential),
        ),
        switchMap(() => this.authSelector.selectUser),
        take(1),
        switchMap(user => {
          const newUser = { ...this.form.value };
          delete newUser.provider;
          delete newUser.firebaseUid;
          return this.userService.upgradeAnonymousUser$(newUser, user?.id ?? '');
        }),
        switchMap(() => this.authFirebaseService.loadUser$()),
        tap(user => this.handleLoginResult(user)),
      )
      .subscribe({
        error: error => {
          this.signInWithPopupInProgress = false;
          this.emailErrorMessage = this.authFirebaseService.getEmailErrorMessage(error);
          this.passwordErrorMessage = this.authFirebaseService.getPasswordErrorMessage(error);
        },
        complete: () => (this.signInWithPopupInProgress = false),
      });
  }

  facebookLogin(): void {
    this.signInWithPopupInProgress = true;
    if (Capacitor.isNativePlatform()) {
      this.facebookNativeLogin();
    } else {
      this.authFirebaseService
        .signInWithFacebook()
        .pipe(
          tap(() => this.form.controls.provider.setValue('facebook.com')),
          tap((userCredential: UserCredential) => this.saveFirebaseProfile(userCredential)),
          switchMap((userCredential: UserCredential) =>
            this.authFirebaseService.saveFirebaseTokenInSessionStore$(userCredential),
          ),
          switchMap(() => this.authFirebaseService.loadUser$()),
          tap(user => this.handleLoginResult(user)),
          catchError(error => this.signInCatchError(error)),
        )
        .subscribe({
          error: error => {
            this.signInWithPopupInProgress = false;
            this.emailErrorMessage = this.authFirebaseService.getEmailErrorMessage(error);
            this.passwordErrorMessage = this.authFirebaseService.getPasswordErrorMessage(error);
          },
          complete: () => (this.signInWithPopupInProgress = false),
        });
    }
  }

  private facebookNativeLogin(): void {
    from(FirebaseAuthentication.signInWithFacebook())
      .pipe(
        tap(userCredential => {
          if (userCredential) {
            this.form.controls.provider.setValue('facebook.com');
            this.authFirebaseService.saveNativeFirebaseTokenInSessionStore$(userCredential);
            return this.authFirebaseService.saveNativeFirebaseTokenInSessionStore$(userCredential);
          }
        }),
        switchMap(() => this.authFirebaseService.loadUser$()),
        tap(user => this.handleLoginResult(user)),
      )
      .subscribe({
        error: error => {
          this.signInWithPopupInProgress = false;
          this.emailErrorMessage = this.authFirebaseService.getEmailErrorMessage(error);
          this.passwordErrorMessage = this.authFirebaseService.getPasswordErrorMessage(error);
        },
        complete: () => (this.signInWithPopupInProgress = false),
      });
  }

  facebookUpgrade(): void {
    this.signInWithPopupInProgress = true;
    this.authFirebaseService
      .upgradeAnonymousWithFacebook()
      .pipe(
        tap((userCredential: UserCredential) => this.saveFirebaseProfile(userCredential)),
        switchMap((userCredential: UserCredential) =>
          this.authFirebaseService.saveFirebaseTokenInSessionStore$(userCredential),
        ),
        switchMap(() => this.authSelector.selectUser),
        take(1),
        switchMap(user => {
          const newUser = { ...this.form.value };
          delete newUser.provider;
          delete newUser.firebaseUid;
          return this.userService.upgradeAnonymousUser$(newUser, user?.id ?? '');
        }),
        switchMap(() => this.authFirebaseService.loadUser$()),
        tap(user => this.handleLoginResult(user)),
      )
      .subscribe({
        error: error => {
          this.signInWithPopupInProgress = false;
          this.emailErrorMessage = this.authFirebaseService.getEmailErrorMessage(error);
          this.passwordErrorMessage = this.authFirebaseService.getPasswordErrorMessage(error);
        },
        complete: () => (this.signInWithPopupInProgress = false),
      });
  }

  appleLogin(): void {
    this.signInWithPopupInProgress = true;
    if (Capacitor.isNativePlatform()) {
      this.appleNativeLogin();
    } else {
      this.authFirebaseService
        .signInWithApple()
        .pipe(
          tap(() => this.form.controls.provider.setValue('apple.com')),
          tap((userCredential: UserCredential) => this.saveFirebaseProfile(userCredential)),
          switchMap((userCredential: UserCredential) =>
            this.authFirebaseService.saveFirebaseTokenInSessionStore$(userCredential),
          ),
          switchMap(() => this.authFirebaseService.loadUser$()),
          tap(user => this.handleLoginResult(user)),
          catchError(error => this.signInCatchError(error)),
        )
        .subscribe({
          error: error => {
            this.signInWithPopupInProgress = false;
            this.emailErrorMessage = this.authFirebaseService.getEmailErrorMessage(error);
            this.passwordErrorMessage = this.authFirebaseService.getPasswordErrorMessage(error);
          },
          complete: () => (this.signInWithPopupInProgress = false),
        });
    }
  }

  appleNativeLogin(): void {
    from(FirebaseAuthentication.signInWithApple())
      .pipe(
        switchMap(userCredential => {
          if (userCredential) {
            this.saveFirebaseProfile(userCredential);
            this.form.controls.provider.setValue('apple.com');
            this.authFirebaseService.saveNativeFirebaseTokenInSessionStore$(userCredential);
            return this.authFirebaseService.saveNativeFirebaseTokenInSessionStore$(userCredential);
          }
          return of(null);
        }),
        switchMap(() => this.authFirebaseService.loadUser$()),
        tap(user => this.handleLoginResult(user)),
      )
      .subscribe({
        error: error => {
          this.signInWithPopupInProgress = false;
          this.emailErrorMessage = this.authFirebaseService.getEmailErrorMessage(error);
          this.passwordErrorMessage = this.authFirebaseService.getPasswordErrorMessage(error);
        },
        complete: () => (this.signInWithPopupInProgress = false),
      });
  }

  appleUpgrade(): void {
    this.signInWithPopupInProgress = true;
    this.authFirebaseService
      .upgradeAnonymousWithApple()
      .pipe(
        tap((userCredential: UserCredential) => this.saveFirebaseProfile(userCredential)),
        switchMap((userCredential: UserCredential) =>
          this.authFirebaseService.saveFirebaseTokenInSessionStore$(userCredential),
        ),
        switchMap(() => this.authSelector.selectUser),
        take(1),
        switchMap(user => {
          const newUser = { ...this.form.value };
          delete newUser.provider;
          delete newUser.firebaseUid;
          return this.userService.upgradeAnonymousUser$(newUser, user?.id ?? '');
        }),
        switchMap(() => this.authFirebaseService.loadUser$()),
        tap(user => this.handleLoginResult(user)),
      )
      .subscribe({
        error: error => {
          this.signInWithPopupInProgress = false;
          this.emailErrorMessage = this.authFirebaseService.getEmailErrorMessage(error);
          this.passwordErrorMessage = this.authFirebaseService.getPasswordErrorMessage(error);
        },
        complete: () => (this.signInWithPopupInProgress = false),
      });
  }

  anonymousLogin(): void {
    this.signInWithPopupInProgress = true;
    this.authFirebaseService
      .signInAnonymously()
      .pipe(
        tap((userCredential: UserCredential) => this.saveFirebaseProfile(userCredential)),
        switchMap((userCredential: UserCredential) =>
          this.authFirebaseService.saveFirebaseTokenInSessionStore$(userCredential),
        ),
        switchMap(() => this.userService.createAnonymousUser$()),
        switchMap(() => this.authFirebaseService.loadUser$()),
        tap(user => this.handleLoginResult(user)),
        catchError(error => this.signInCatchError(error)),
      )
      .subscribe({
        error: error => (this.signInWithPopupInProgress = false),
        complete: () => (this.signInWithPopupInProgress = false),
      });
  }

  private fetchSignInMethodsForEmail(email: string): Promise<boolean | void> {
    this.isUserRegisteredCheckInProgress = true;
    return this.authFirebaseService
      .fetchSignInMethodsForEmail(email)
      .then(signInMethods => {
        if (!signInMethods || signInMethods.length === 0) {
          this.isUserRegistered = false;
          return false;
        }
        this.isUserRegisteredCheckInProgress = false;
        this.isUserRegistered = true;
        this.isSignInMethodPasswordAllowed = signInMethods.includes('password');
        this.isSignInMethodGoogleAllowed = signInMethods.includes('google.com');
        this.isSignInMethodAppleAllowed = signInMethods.includes('apple.com');
        this.isSignInMethodFacebookAllowed = signInMethods.includes('facebook.com');
        this.emailErrorMessage = undefined;
        this.passwordErrorMessage = undefined;
        return true;
      })
      .catch((error: FirebaseError) => {
        this.isUserRegisteredCheckInProgress = false;
        this.emailErrorMessage = this.authFirebaseService.getEmailErrorMessage(error);
      });
  }

  resetPassword(): void {
    this.authFirebaseService
      .resetPassword(this.form.value.email)
      .then(res => {
        if (res) {
          this.emailResetSend = true;
        } else {
          this.emailErrorMessage = ERRORS.LOGIN_INVALID;
        }
      })
      .catch(err => console.log(err));
  }

  private passwordLogin(email: string, password: string): void {
    this.authFirebaseService
      .signInWithPassword(email, password)
      .pipe(
        tap(user => this.handleLoginResult(user)),
        catchError(error => this.signInCatchError(error)),
      )
      .subscribe({
        error: error => {
          this.isUserEmailLoginInProgress = false;

          this.emailErrorMessage = this.authFirebaseService.getEmailErrorMessage(error);
          this.passwordErrorMessage = this.authFirebaseService.getPasswordErrorMessage(error);
        },
      });
  }

  private saveFirebaseProfile(userCredential: any): void {
    const tokenResponse = userCredential._tokenResponse;
    const profile: any = userCredential.additionalUserInfo?.profile;
    if (!profile && !tokenResponse) {
      return;
    }
    this.form.controls.firebaseUid.setValue(userCredential.user?.uid || tokenResponse?.idToken);
    this.form.controls.email.setValue(profile?.email || tokenResponse?.email);
    this.form.controls.firstname.setValue(profile?.given_name || tokenResponse?.firstName);
    this.form.controls.lastname.setValue(profile?.family_name || tokenResponse?.lastName);
  }

  private handleLoginResult(user?: DoodUserModel): void {
    this.emailErrorMessage = undefined;
    this.passwordErrorMessage = undefined;

    if (
      !user ||
      (user[UserKeys.IsAnonymous] &&
        this.authMode !== AuthModeValues.Anonymous &&
        this.authMode !== AuthModeValues.OnlyAnonymous)
    ) {
      this.authenticationScreenService.goToStep(DoodAuthenticationStep.signup);
      return;
    }

    const requestedUrl = this.authSelector.guard?.requestedUrl;
    if (requestedUrl) {
      this.router.navigate([this.routerHelper.translateRoute(requestedUrl)]);
    }

    this.displayLoyaltyAccountPrompt();
    this.modalsService.close(AuthenticationModalComponent.handle);
  }

  private displayLoyaltyAccountPrompt(): void {
    combineLatest([
      this.marketplaceRefiner.selectIsComoPluginEnabled,
      this.comoService.isUserComoAccountEnabledOnMarketplaceLevel$(),
    ])
      .pipe(
        take(1),
        tap(([isMarketplaceComoEnabled, isUserComoEnabled]) => {
          if (
            isMarketplaceComoEnabled &&
            !isUserComoEnabled &&
            !this.authFirebaseService.isAnonymous
          ) {
            this.modalsService.open(ComoCreateOrLinkAccountModalComponent.handle);
          }
        }),
      )
      .subscribe();

    combineLatest([
      this.marketplaceRefiner.selectIsZeroSixPluginEnabled,
      this.zerosixService.isUserZeroSixCardEnabledOnMarketplaceLevel$(),
    ])
      .pipe(
        take(1),
        tap(([isMarketplaceZeroSixComoEnabled, isUserZerosixEnabled]) => {
          if (
            isMarketplaceZeroSixComoEnabled &&
            !isUserZerosixEnabled &&
            !this.authFirebaseService.isAnonymous
          ) {
            this.modalsService.open(ZerosixCreateOrLinkAccountModalComponent.handle);
          }
        }),
      )
      .subscribe();

    combineLatest([
      this.marketplaceRefiner.selectIsPongoPluginEnabled,
      this.pongoService.isUserPongoCustomerEnabledOnMarketplaceLevel$(),
    ])
      .pipe(
        take(1),
        tap(([isMarketplacePongoComoEnabled, isUserZerosixEnabled]) => {
          if (
            isMarketplacePongoComoEnabled &&
            !isUserZerosixEnabled &&
            !this.authFirebaseService.isAnonymous
          ) {
            this.modalsService.open(PongoCreateOrLinkAccountModalComponent.handle);
          }
        }),
      )
      .subscribe();
  }

  goToEmailSignupStep(): void {
    this.form.controls.provider.setValue('password');
    this.authenticationScreenService.goToStep(DoodAuthenticationStep.signup);
  }
}
