import { ActionCreator, ReducerTypes, on } from '@ngrx/store';

import { DoodCartModel, DoodReconstructedCartItem } from '@core/models/cart.model';
import { createActiveStorePiece } from '@store/_abstract/active';
import { createHydratedStorePiece } from '@store/_abstract/hydrated';

import {
  CartState,
  CART_STORE_INITIAL_STATE,
  CART_STORE_KEY,
  CART_STORE_ACTIVE_ADAPTER,
} from './cart.state';
import {
  CART_STORE_ACTIVE_ACTIONS,
  CART_STORE_COMPONENT_ACTIONS,
  CART_STORE_HYDRATION_ACTIONS,
  CART_STORE_LOCAL_ACTIONS,
} from './cart.action';

export const CART_STORE_API_PIECE: ReducerTypes<CartState, readonly ActionCreator[]>[] = [];

export const CART_STORE_LOCAL_PIECE: ReducerTypes<CartState, readonly ActionCreator[]>[] = [
  // Comment
  on(CART_STORE_LOCAL_ACTIONS.setComment, (state, { comment }): CartState => {
    return { ...state, comment };
  }),
  on(CART_STORE_LOCAL_ACTIONS.updateComment, (state, { comment }): CartState => {
    return {
      ...state,
      comment: { ...(state.comment ?? { type: '' }), ...comment },
    };
  }),
  on(CART_STORE_LOCAL_ACTIONS.resetComment, (state): CartState => {
    return { ...state, comment: null };
  }),

  // Edit Item
  on(CART_STORE_LOCAL_ACTIONS.setEditItem, (state, { item }): CartState => {
    return { ...state, edit_item: item };
  }),
  on(CART_STORE_LOCAL_ACTIONS.updateEditItem, (state, { item }): CartState => {
    return {
      ...state,
      edit_item: { ...state.edit_item, ...(item as DoodReconstructedCartItem) },
    };
  }),
  on(CART_STORE_LOCAL_ACTIONS.resetComment, (state): CartState => {
    return { ...state, edit_item: null };
  }),

  // Last Update
  on(CART_STORE_LOCAL_ACTIONS.updateLastUpdate, (state, { last_update }): CartState => {
    return { ...state, last_update };
  }),
  on(CART_STORE_LOCAL_ACTIONS.resetComment, (state): CartState => {
    return { ...state, comment: null };
  }),

  // Misc
  on(CART_STORE_LOCAL_ACTIONS.reset, (): CartState => {
    return CART_STORE_INITIAL_STATE;
  }),
];

export const CART_STORE_COMPONENT_PIECE: ReducerTypes<CartState, readonly ActionCreator[]>[] = [
  on(CART_STORE_COMPONENT_ACTIONS.updateState, (state, { changes }): CartState => {
    return {
      ...state,
      ...changes,
    };
  }),
];

export const CART_STORE_ACTIVE_PIECE = createActiveStorePiece<CartState, DoodCartModel>({
  adapter: CART_STORE_ACTIVE_ADAPTER,
  actions: CART_STORE_ACTIVE_ACTIONS,
});

export const CART_STORE_HYDRATION_PIECE = createHydratedStorePiece<CartState>(
  CART_STORE_KEY,
  CART_STORE_HYDRATION_ACTIONS,
);
