import { Component, EventEmitter, Input, Output } from '@angular/core';

import { DoodCartApiResponse } from '@core/models/cart-api.model';
import { GroupedOrderModalsAttributes } from '@config/modals/grouped-order-modals.config';

@Component({
  selector: 'app-share-grouped-order-atom',
  templateUrl: './share-grouped-order-atom.component.html',
})
export class ShareGroupedOrderAtomComponent {
  @Input() attribute!: string;
  @Input() link!: string;
  @Input() basket!: DoodCartApiResponse;
  @Input() isProcessingRequest: boolean = false;
  @Input() isOrderOwner!: boolean;
  @Output() closeModal = new EventEmitter();
  @Output() orderAlone = new EventEmitter();
  @Output() shareLink = new EventEmitter<string>();
  @Output() joinOrder = new EventEmitter();
  @Output() cancelOrder = new EventEmitter();
  @Output() clearCart = new EventEmitter();
  @Output() leaveOrder = new EventEmitter();
  @Output() removeUser = new EventEmitter();
  @Output() declineInvitation = new EventEmitter();
  @Output() startShareOrder = new EventEmitter();

  groupedOrderModalsAttributes = GroupedOrderModalsAttributes;
}
