<div
  class="flex flex-row {{
    size === 'L' || size === 'M' ? 'space-x-4' : size === 'S' ? 'space-x-1' : ''
  }}"
>
  <button
    [disabled]="decreaseDisabled()"
    (click)="decreaseCount($event)"
    class="{{
      size === 'L'
        ? 'h-10 w-10 rounded-3xl text-1xs'
        : size === 'M'
          ? 'text-0xs rounded-full h-8 w-8'
          : 'text-2px rounded-full h-4 w-4'
    }}
    {{ decreaseDisabled() ? 'opacity-50' : '' }}
            flex justify-center items-center text-center"
    [ngClass]="{
      'bg-primary-800': !backgroundColor && type === 'kiosk',
    }"
    [style.backgroundColor]="
      type === 'kiosk' ? backgroundColor : type === 'light' ? '#e9e8fc' : '#504be7'
    "
  >
    <i class="icon icon-minus {{ type === 'light' ? 'text-primary-600' : 'text-white' }}"></i>
  </button>
  <p
    class="font-accent font-bold text-center lining-nums
    {{
      size === 'L' ? 'leading-9.5' : size === 'M' ? 'leading-8' : size === 'S' ? 'leading-4' : ''
    }}
    {{ size === 'L' ? 'text-2xl' : size === 'M' ? 'text-4.5xl' : size === 'S' ? 'text-lg' : '' }}"
  >
    {{ count }}
  </p>
  <button
    [disabled]="disableIncrease || increaseDisabled()"
    (click)="increaseCount($event)"
    class="{{
      size === 'L'
        ? 'h-10 w-10 rounded-3xl text-xl'
        : size === 'M'
          ? 'text-lg rounded-full h-8 w-8'
          : 'text-2xs rounded-full h-4 w-4'
    }}
            {{
      increaseDisabled() || disableIncrease ? 'opacity-50' : ''
    }} flex justify-center items-center text-center"
    [ngClass]="{
      'bg-primary-800': !backgroundColor && type === 'kiosk',
    }"
    [style.backgroundColor]="
      type === 'kiosk' ? backgroundColor : type === 'light' ? '#e9e8fc' : '#504be7'
    "
  >
    <i class="icon icon-plus {{ type === 'light' ? 'text-primary-600' : 'text-white' }}"></i>
  </button>
</div>
