import { map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';

import { LanguageFlagsUrls } from '@config/language-flags.config';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

import { SettingsStoreDispatcher } from '@common/dispatchers/settings.dispatcher';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

@Component({
  selector: 'app-kiosk-select-language-block',
  templateUrl: './kiosk-select-language-block.component.html',
})
export class KioskSelectLanguageBlockComponent implements OnInit {
  locales$ = this.marketplaceSelector.selectMarketplaceLocales;
  languages$?: any;

  constructor(
    private settingsDispatcher: SettingsStoreDispatcher,
    private marketplaceSelector: MarketplaceStoreSelector,
    private readonly localizeRouterService: LocalizeRouterService,
  ) {}

  ngOnInit(): void {
    this.languages$ = this.locales$.pipe(
      map(locales =>
        locales.map(language => ({
          locale: language.locale,
          flagUrl: LanguageFlagsUrls.get(language.locale),
        })),
      ),
    );
  }

  onChangeLanguage(selectedLanguage: string, event: Event): void {
    event.stopPropagation();
    this.settingsDispatcher.updateAppLocale(selectedLanguage);
    this.localizeRouterService.changeLanguage(selectedLanguage, {
      replaceUrl: true,
    });
  }
}
