import { Component, Input } from '@angular/core';
import { DoodRenderingBlock } from '@shared/interfaces/block.interface';

@Component({
  selector: 'app-slide-show-image-block',
  templateUrl: './slide-show-image-block.component.html',
})
export class SlideShowImageBlockComponent {
  @Input() title?: string;
  @Input() anchor?: string;
  @Input() images: Partial<DoodRenderingBlock>[] = [];
}
