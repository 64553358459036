import { Observable, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { ModalScrollBlockBase } from '@core/base/modalScrollBlock/modal-scroll-block.base';

import { CartService } from '@core/services/cart/cart.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { CartStoreDispatcher } from '@common/dispatchers/cart.dispatcher';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';

@Component({
  selector: 'app-lydia-payment-phone-number-modal',
  templateUrl: './lydia-payment-phone-number-modal.component.html',
})
export class LydiaPaymentPhoneNumberModalComponent extends ModalScrollBlockBase implements OnInit {
  static handle = 'lydia-payment-phone-number';

  @ViewChild('phoneNumber') phoneNumberElement!: ElementRef;

  @Input() legalCheckboxLabel?: any;

  index$: Observable<number> = this.modalSelector
    .selectModal(LydiaPaymentPhoneNumberModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  form: UntypedFormGroup;
  errorMessage?: string;
  phoneErrorMessage?: string;
  isValidating = false;
  step = 'join-form';

  constructor(
    private readonly fb: UntypedFormBuilder,
    private authSelector: AuthStoreSelector,
    private readonly cartService: CartService,
    private modalSelector: ModalStoreSelector,
    private cartDispatcher: CartStoreDispatcher,
    private readonly modalsService: ModalsService,
  ) {
    super();
    this.form = this.fb.group({
      phoneNumber: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.updatePhoneNumberFromCartOrUser();
    timer(1).subscribe(() => {
      this.phoneNumberElement.nativeElement.focus();
    });
  }

  save(): void {
    this.cartDispatcher.updateActive({
      PaymentPhoneNumber: this.form.get('phoneNumber')?.value,
    });
    this.close();
  }

  close(): void {
    this.modalsService.close(LydiaPaymentPhoneNumberModalComponent.handle);
  }

  private updatePhoneNumberFromCartOrUser(): void {
    const cartPaymentPhoneNumber = this.cartService.getPaymentPhoneNumber();

    if (cartPaymentPhoneNumber) {
      this.form.setValue({ phoneNumber: cartPaymentPhoneNumber });
    } else {
      this.authSelector.selectUser.pipe(take(1)).subscribe(user => {
        if (user?.phone) {
          this.form.setValue({ phoneNumber: user.phone });
        }
      });
    }
  }
}
