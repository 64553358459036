import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-quantity-atom',
  templateUrl: './quantity-atom.component.html',
})
export class QuantityAtomComponent {
  @Input() quantity!: number;
  @Input() dark!: boolean;
}
