import { inject } from '@angular/core';
import { DeclinableProductStepperStore } from '../declinable-product-stepper.store';
import { SimpleProductStepperStore } from '../simple-product-stepper.store';
import { SimpleProductAutoNextStepService } from '../auto-next-step/simple-product-auto-next-step.service';
import { DeclinableProductAutoNextStepService } from '../auto-next-step/declinable-product-auto-next-step.service';

export function provideSimpleProductStepperStore() {
  return [
    {
      provide: SimpleProductStepperStore,
      useFactory: () => {
        const declinableProductStepperStore = inject(DeclinableProductStepperStore, {
          optional: true,
          skipSelf: true,
        });
        if (declinableProductStepperStore) {
          return declinableProductStepperStore;
        }
        return new SimpleProductStepperStore();
      },
    },
    {
      provide: SimpleProductAutoNextStepService,
      useFactory: () => {
        const declinableProductAutoNextStepService = inject(DeclinableProductAutoNextStepService, {
          optional: true,
          skipSelf: true,
        });
        if (declinableProductAutoNextStepService) {
          return declinableProductAutoNextStepService;
        }
        return new SimpleProductAutoNextStepService();
      },
    },
  ];
}
