import { Injectable } from '@angular/core';

const MOBILE_MAX_WIDTH = 768; //Adjust as needed
const TABLET_MAX_WIDTH = 992; //Adjust as needed

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  getPlatform(): 'ios' | 'android' | 'web' {
    const ua = navigator.userAgent;
    if (ua.indexOf('Android') !== -1) {
      return 'android';
    }
    if (ua.indexOf('like Mac') !== -1) {
      return 'ios';
    }
    return 'web';
  }

  getDeviceType(): 'mobile' | 'tablet' | 'desktop' {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet';
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua,
      )
    ) {
      return 'mobile';
    }
    return 'desktop';
  }

  isSafari(): boolean {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  getDeviceScreenType(): 'mobile' | 'tablet' | 'desktop' {
    const width = window.innerWidth;
    if (width <= MOBILE_MAX_WIDTH) {
      return 'mobile';
    }

    if (width <= TABLET_MAX_WIDTH && width > MOBILE_MAX_WIDTH) {
      return 'tablet';
    }
    return 'desktop';
  }

  isMobileScreen(): boolean {
    return this.getDeviceScreenType() === 'mobile';
  }
}
