import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dev-block-container-atom',
  templateUrl: './dev-block-container-atom.component.html',
  styleUrls: ['./dev-block-container-atom.component.scss'],
})
export class DevBlockContainerAtomComponent {
  @Input() name!: string;
}
