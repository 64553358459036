import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DOOD_API } from '@config/ws.config';

@Injectable({
  providedIn: 'root',
})
export class InvoicesApiService {
  constructor(private readonly httpClient: HttpClient) {}

  requestCheckTransactionInvoice$(checkTransactionId: string, email: string): Observable<void> {
    return this.httpClient.post<void>(
      `${DOOD_API}/invoices/request/check-transaction/${checkTransactionId}`,
      {
        email,
      },
    );
  }

  requestPaymentInvoice$(paymentId: string, email: string): Observable<void> {
    return this.httpClient.post<void>(`${DOOD_API}/invoices/request/payment/${paymentId}`, {
      email,
    });
  }
}
