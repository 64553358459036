import { createHydratedReducer } from '@store/_abstract/hydrated';

import {
  ORDER_STORE_ACTIVE_PIECE,
  ORDER_STORE_API_PIECE,
  ORDER_STORE_HYDRATION_PIECE,
  ORDER_STORE_LOCAL_PIECE,
} from './order.piece';
import { ORDER_STORE_INITIAL_STATE, ORDER_STORE_KEY } from './order.state';

export const ORDER_STORE_REDUCER = createHydratedReducer({
  key: ORDER_STORE_KEY,
  initialState: ORDER_STORE_INITIAL_STATE,
  ons: [
    ...ORDER_STORE_HYDRATION_PIECE,
    ...ORDER_STORE_ACTIVE_PIECE,
    ...ORDER_STORE_API_PIECE,
    ...ORDER_STORE_LOCAL_PIECE,
  ],
});
