import { combineLatest } from 'rxjs';
import { Injectable } from '@angular/core';
import { distinctUntilChanged, map } from 'rxjs/operators';

import {
  DoodBasketCartItem,
  DoodBasketSubCart,
  DoodBasketUserCart,
} from '@store/basket/basket.model';
import { BasketStoreSelector } from '@app/common/selectors/basket.selector';
import { AuthStoreSelector } from '@app/common/selectors/authentication.selector';

@Injectable({ providedIn: 'root' })
export class BasketStoreRefiner {
  selectUserId = this._selector.selectUser.pipe(map(({ id }) => id));

  // isBasketExist$
  selectHasBasket = this._selector.selectId.pipe(
    map(id => !!id),
    distinctUntilChanged(),
  );

  // basketUsers$
  selectUsers = this._selector.select.pipe(
    map(basket =>
      basket.cart_items
        .map(item => item.user)
        .filter((user, index, self) => index === self.findIndex(el => el.id === user.id)),
    ),
  );

  // isBasketMainUser$
  selectIfBasketUserIsCurrentUser = combineLatest([
    this.selectUserId,
    this.userSelector.selectUserId,
  ]).pipe(
    map(([basketUserId, userId]) => {
      return basketUserId === userId;
    }),
  );

  // basketAllItemsId$
  selectCartItemsIds = this._selector.selectCartItems.pipe(
    map(items =>
      items.reduce((ids, item) => {
        return [...ids, item.id];
      }, new Array<string>()),
    ),
  );

  // basketConfirmedUsers$
  selectConfirmedUsers = this._selector.selectConfirmedBy;

  // basketItemsGroupByUser$
  selectCartItemsGroupedByUser = combineLatest([
    this._selector.selectCartItems,
    this.selectUsers,
    this.selectConfirmedUsers,
    this._selector.selectSubCarts,
  ]).pipe(
    map(([items, users, confirmedUsers, subCarts]) => {
      const itemsGroupedByUser = users.map(user => {
        return {
          user,
          total: 0,
          items: [],
          subCart: subCarts.find(cart => cart.user.id === user.id),
          isConfirmed: confirmedUsers.some(confirmedUser => confirmedUser.user.id === user.id),
        } as DoodBasketUserCart;
      });

      // TODO: Check if this part can be improved
      items.forEach((item, i) => {
        itemsGroupedByUser.map((el: DoodBasketUserCart) => {
          if (el.user.id === item.user.id) {
            el.items?.push({
              id: item.id,
              price: item.price,
              itemId: i + 1,
              quantity: item.quantity,
              product: item.product,
            });
            el.total = el.total + (item.price ? item.price : item.product.final_price);
          }
        });
      });

      return itemsGroupedByUser;
    }),
  );

  // reconstructedBasketSubCarts$
  selectReconstructedBasketSubCarts = combineLatest([
    this._selector.selectSubCarts,
    this.selectConfirmedUsers,
    this._selector.selectCartItems,
  ]).pipe(
    map(([subCarts, confirmedUsers, items]) => {
      const reconstructedSubCarts: DoodBasketSubCart[] = subCarts
        .map(subCart => {
          return {
            ...subCart,
            isConfirmed: confirmedUsers.some(_user => _user.user.id === subCart.user.id),
          };
        })
        .map(subCart => {
          return {
            ...subCart,
            items:
              items
                .filter(item => item.user.id === subCart.user.id)
                .map(item => item.quantity)
                .reduce(
                  (_previous, _current, _index) =>
                    _index === 0 ? [_current] : [..._previous, _current + _previous[_index - 1]],
                  new Array<number>(),
                )
                .slice(-1)
                .pop() ?? -1,
          };
        });
      return reconstructedSubCarts;
    }),
  );

  // basketTotalConfirmedUsers$:
  selectTotalOfConfirmedUsers = this.selectReconstructedBasketSubCarts.pipe(
    map(subCarts => subCarts.filter(cart => cart.isConfirmed)),
    map(carts => carts.reduce((total, cart) => (total = total + cart.total), 0)),
  );

  // basketItemsIdCurrentUser$
  selectCurrentUserCartItemsIds = combineLatest([
    this.selectCartItemsGroupedByUser,
    this.userSelector.selectUser,
  ]).pipe(
    map(([items, user]) => {
      return items.reduce((result, item) => {
        if (user?.id === item.user.id) {
          return [...result, ...item.items];
        }
        return result;
      }, new Array<DoodBasketCartItem>());
    }),
    map(items => {
      return items.reduce((result, { id }) => (id ? [...result, id] : result), new Array<string>());
    }),
  );

  // basketItemsOtherUsers$
  selectOtherUsersCartItems = combineLatest([
    this.selectCartItemsGroupedByUser,
    this.userSelector.selectUser,
  ]).pipe(
    map(([items, user]) => {
      return items.reduce((result, item) => {
        if (user?.id === item.user.id) {
          result = [...result, ...item.items];
        }
        return result;
      }, new Array<DoodBasketCartItem>());
    }),
    map(carts =>
      carts.reduce((result, cart) => {
        if (cart.id) {
          return [...result, cart.id];
        }
        return result;
      }, new Array<string>()),
    ),
  );

  // basketAllUsersConfirmedShareOrder$
  selectAreAllUsersConfirmed = this.selectCartItemsGroupedByUser.pipe(
    map(users => users.every(e => e.isConfirmed)),
  );

  constructor(
    private _selector: BasketStoreSelector,
    private userSelector: AuthStoreSelector,
  ) {}
}
