export enum ContextualPriceKeys {
  DistributionMode = 'distribution_mode',
  Currency = 'currency',
  Price = 'price',
  CompareAtPrice = 'compare_at_price',
  Vat = 'vat',
  Amount = 'amount',
}

export enum OverridesPricesKeys {
  Store = 'store',
  Product = 'product',
  AdditionnalPrice = 'additional_price',
  ContextualPrice = 'contextual_prices',
}
