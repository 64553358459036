import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { Component, Input } from '@angular/core';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';

import { Paths } from '@config/paths.config';
import { EntityStoreSelector } from '@common/selectors/entity.selector';

import { EntityKeys } from '@config/keys/entity.keys';
import { ISimpleItem } from '@core/models/simple-item.model';

import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

import { EntitiesService } from '@core/services/entities/entities.service';
import { RouterHelperService } from 'src/app/core/services/router-helper/router-helper.service';
import { NavigationHistoryService } from '@core/services/navigation-history/navigation-history.service';

@Component({
  selector: 'app-shop-header-navigation-block',
  templateUrl: './shop-header-navigation-block.component.html',
})
export class ShopHeaderNavigationBlockComponent {
  private _destroyerRef = new Subject<boolean>();

  @Input() backButtonRoute = ['/shops'];

  marketplaceId$ = this.marketplaceSelector.selectMarketplaceId;

  entities$: Observable<ISimpleItem[]> = this.entitySelector.selectEntities.pipe(
    takeUntil(this._destroyerRef),
    map(entities =>
      entities.map(entity => {
        return {
          value: entity[EntityKeys.Slug],
          label: entity[EntityKeys.ShortDescription],
        };
      }),
    ),
  );

  constructor(
    private readonly router: Router,
    private entitySelector: EntityStoreSelector,
    private readonly entitiesService: EntitiesService,
    private readonly routerHelper: RouterHelperService,
    private marketplaceSelector: MarketplaceStoreSelector,
    private readonly navigationHistoryService: NavigationHistoryService,
  ) {
    this.marketplaceId$
      .pipe(
        take(1),
        switchMap(marketplaceId => this.entitiesService.loadAll$(marketplaceId)),
      )
      .subscribe();
  }

  goBack(): void {
    this.navigationHistoryService.goBack();
  }

  navigateToShop(item: ISimpleItem): void {
    this.router.navigate([this.routerHelper.translateRoute(`${Paths.Shops}/${item.value}`)]);
  }
}
