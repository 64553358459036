import { Component, EventEmitter, Input, Output } from '@angular/core';

import { MarketplaceKeys } from '@config/keys/shop.keys';
import { CurrencyValues } from '@config/values/currency.values';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

@Component({
  selector: 'app-input-tip-picker-atom',
  templateUrl: './input-tip-picker-atom.component.html',
})
export class InputTipPickerAtomComponent {
  @Input() tip!: number;
  @Input() error = false;

  @Output() closeTip = new EventEmitter();
  @Output() applyTip = new EventEmitter<number>();

  currency =
    this.marketplaceSelector.marketplace?.[MarketplaceKeys.Currency] === CurrencyValues.EUR
      ? '€'
      : this.marketplaceSelector.marketplace?.[MarketplaceKeys.Currency] === CurrencyValues.CHF
        ? CurrencyValues.CHF
        : this.marketplaceSelector.marketplace?.[MarketplaceKeys.Currency] === CurrencyValues.MAD
          ? CurrencyValues.MAD
          : '€';

  constructor(private marketplaceSelector: MarketplaceStoreSelector) {}

  onTip($event: number): void {
    this.tip = $event;
  }
}
