import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ModalsService } from '@core/services/modals/modals.service';
import { ShopStoreSelector } from '@common/selectors/shop.selector';
import { OrderStoreSelector } from '@common/selectors/order.selector';
import { CartParametersModalComponent } from '@shared/modals/cart-parameters-modal/cart-parameters-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { PriceUtils } from '@shared/utils/price/price.utils';
import { CartUserInformationsModalComponent } from '@shared/modals/cart-user-informations-modal/cart-user-informations-modal.component';
import { CartStoreSelector } from '@common/selectors/cart.selector';
import { OrderTypeService } from '../order-type/order-type.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  shopSlots$ = this.shopSelector.selectSlots;

  static SLOT_ERRORS = [
    'GIVEN_TIME_SLOT_UNAVAILABLE',
    'ORDER_ALLOCATION_CAN_NOT_BE_PLACED',
    'ORDER_CAN_NOT_PICKED_IN_PAST',
    'PREORDERING_ALLOWED_GIVEN_TIME_SLOT_FAILED',
  ];

  constructor(
    private shopSelector: ShopStoreSelector,
    private cartSelector: CartStoreSelector,
    private orderSelector: OrderStoreSelector,
    private readonly modalsService: ModalsService,
    private readonly translateService: TranslateService,
    private readonly orderTypeService: OrderTypeService,
  ) {}

  getErrorMessage$(): Observable<string[]> {
    return this.orderSelector.selectErrors.pipe(
      map(error => {
        let errorMessages: string[] = [];
        let violations = error.violations ?? [];

        if (violations.length > 0) {
          console.warn('[Order validation errors]', violations);
        }

        violations.forEach((violation: any) => {
          switch (violation.code) {
            case 'INVALID_ADDRESS_FORMAT':
            case 'DELIVERY_NO_DELIVERY_ADDRESS':
            case 'DELIVERY_INVALID_DELIVERY_ADDRESS_FORMAT':
              errorMessages.push(this.translateService.instant('errors.delivery-address-invalid'));
              this.modalsService.open(CartParametersModalComponent.handle);
              break;
            case 'DELIVERY_NO_DELIVERY_ZONES_CONFIGURED':
            case 'DELIVERY_OUTSIDE_DELIVERY_ZONE':
              errorMessages.push(
                this.translateService.instant('errors.delivery-address-outside-delivery-zone'),
              );
              this.modalsService.open(CartParametersModalComponent.handle);
              break;
            case 'DELIVERY_UBER_DIRECT_TEMPORARY_NOT_DELIVERABLE':
            case 'DELIVERY_UBER_DIRECT_ADDRESS_UNDELIVERABLE':
            case 'DELIVERY_UBER_DIRECT_DROPOFF_ADDRESS_NOT_ACCEPTED':
              errorMessages.push(
                this.translateService.instant('errors.delivery-address-temporary-not-deliverable'),
              );
              this.modalsService.open(CartParametersModalComponent.handle);
              break;
            case 'MARKETPLACE_NOT_AVAILABLE':
            case 'SHOP_NOT_AVAILABLE':
            case 'SHOP_NOT_ACTIVE':
            case 'DISTRIBUTION_MODE_DISABLED':
              errorMessages.push(this.translateService.instant('errors.ordering-not-available'));
              break;
            case 'GIVEN_TIME_SLOT_UNAVAILABLE':
            case 'ORDER_CAN_NOT_PICKED_IN_PAST':
            case 'WANTED_AT_NOT_IN_PREORDERING_RANGE_HOURS':
              const cart = this.cartSelector.active;
              const preorderingAllowed = this.orderTypeService.getCapabilities(
                cart?.type,
              ).preorderingAllowed;
              if (preorderingAllowed) {
                errorMessages.push(
                  this.translateService.instant('errors.given-time-slot-not-available'),
                );
                this.modalsService.open(CartParametersModalComponent.handle);
              } else {
                errorMessages.push(this.translateService.instant('errors.no-slot-available'));
              }
              break;
            case 'PREORDERING_ALLOWED_GIVEN_TIME_SLOT_FAILED':
            case 'ORDER_ALLOCATION_CAN_NOT_BE_PLACED':
              errorMessages.push(this.translateService.instant('errors.no-slot-available'));
              break;
            case 'AT_LEAST_ONE_PRODUCT_REQUIRED':
              errorMessages.push(
                this.translateService.instant('errors.at-least-one-product-required'),
              );
              break;
            case 'ON_SITE_LOCATION_ID_REQUIRED':
              errorMessages.push(this.translateService.instant('errors.on-site-location-required'));
              break;
            case 'ON_SITE_LOCATION_ID_INVALID':
            case 'ON_SITE_LOCATION_NOT_FOUND':
              errorMessages.push(this.translateService.instant('errors.on-site-location-invalid'));
              break;
            case 'ON_SITE_LOCATION_NOT_AVAILABLE':
              errorMessages.push(
                this.translateService.instant('errors.on-site-location-not-available'),
              );
              break;
            case 'MINIMUM_AMOUNT_NOT_REACHED':
              errorMessages.push(
                this.translateService.instant('errors.order-minimum-amount-not-reached', {
                  amountWithCurrency:
                    PriceUtils.formatAndRoundUpPriceWithComa(violation.data.minimum) +
                    ' ' +
                    violation.data.currency,
                  remainingAmountWithCurrency:
                    PriceUtils.formatAndRoundUpPriceWithComa(
                      violation.data.minimum - violation.data.discountedPriceIncTax,
                    ) +
                    ' ' +
                    violation.data.currency,
                }),
              );
              break;
            case 'DELIVERY_MUST_HAVE_PHONE_NUMBER':
              errorMessages.push(this.translateService.instant('errors.delivery-phone-invalid'));
              this.modalsService.open(CartUserInformationsModalComponent.handle);
              break;
            case 'INVALID_PHONE_NUMBER':
            case 'DELIVERY_UBER_DIRECT_PHONE_NUMBER_NOT_ACCEPTED':
              errorMessages.push(this.translateService.instant('errors.phone-invalid'));
              this.modalsService.open(CartUserInformationsModalComponent.handle);
              break;
            case 'PREORDERING_NOT_ALLOWED':
              errorMessages.push(this.translateService.instant('errors.preordering-not-allowed'));
              break;
            case 'INVENTORY_INVALID_STOCK':
              errorMessages.push(
                this.translateService.instant('errors.product-insufficient-stock', {
                  productName: violation.data.name,
                }),
              );
              break;
            case 'PRODUCT_NOT_AVAILABLE':
            case 'PRODUCT_HIDDEN':
            case 'PRODUCT_NOT_AVAILABLE_FOR_DISTRIBUTION_MODE':
              errorMessages.push(
                this.translateService.instant('errors.product-not-available', {
                  productName: violation.data.name,
                }),
              );
              break;
            case 'PRODUCT_NOT_FOUND':
            case 'PRODUCT_SHOP_NOT_FOUND':
              errorMessages.push(this.translateService.instant('errors.product-not-found'));
              break;
            case 'UNKNOWN_SIMPLE_PRODUCT_ID_IN_STORE_IN_DECLINABLE_PRODUCT':
              errorMessages.push(
                this.translateService.instant('errors.unknown-option-selected-not-found'),
              );
              break;
            case 'SIMPLE_PRODUCT_ID_NOT_STORE_IN_DECLINABLE_PRODUCT':
              errorMessages.push(
                this.translateService.instant('errors.option-not-available-on-product', {
                  optionName: violation.data.simpleProductName,
                  parentProductName: violation.data.declinableName,
                }),
              );
              break;
            case 'ADDITION_PRODUCT_NOT_AVAILABLE_FOR_PRODUCT':
              errorMessages.push(
                this.translateService.instant('errors.option-not-available-on-product', {
                  optionName: violation.data.additionProductName,
                  parentProductName: violation.data.simpleProductName,
                }),
              );
              break;
            case 'STORE_ID_NOT_IN_DECLINABLE_PRODUCT':
              errorMessages.push(
                this.translateService.instant('errors.invalid-product-configuration', {
                  productName: violation.data.declinableName,
                }),
              );
              break;
            case 'NOT_ENOUGH_CHOSEN_PRODUCTS_IN_DECLINABLE':
            case 'TOO_MANY_CHOSEN_PRODUCTS_IN_DECLINABLE':
            case 'NOT_ENOUGH_ADDITION_PRODUCT_CHOSEN_IN_ADDITION_GROUP':
            case 'TOO_MANY_ADDITION_PRODUCTS_CHOSEN_IN_ADDITION_GROUP':
            case 'NOT_ENOUGH_ADDITION_PRODUCT_CHOSEN_IN_ORDER':
            case 'TOO_MANY_ADDITION_PRODUCTS_CHOSEN_IN_ORDER':
              errorMessages.push(
                this.translateService.instant('errors.invalid-product-configuration', {
                  productName: violation.data.name,
                }),
              );
              break;
            case 'ANONYMOUS_ORDER_NOT_ALLOWED':
            case 'MUST_BE_LOGGED_IN':
              errorMessages.push(
                this.translateService.instant('errors.authentication-required-to-order'),
              );
              break;
            default:
              errorMessages.push(
                this.translateService.instant('errors.unknown-error', {
                  errorCode: violation.code,
                }),
              );
          }
        });

        // Keep only unique messages
        errorMessages = Array.from(new Set(errorMessages));
        return errorMessages;
      }),
    );
  }
}
