import { createFeature, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';

import { DoodFilterModel } from '@core/models/filters.model';

import { FILTER_STORE_REDUCER } from './filter.reducer';
import { FILTER_STORE_KEY, FILTER_STORE_ADAPTER } from './filter.state';

const FILTER_STORE_FEATURE = createFeature({
  name: FILTER_STORE_KEY,
  reducer: FILTER_STORE_REDUCER,
});

export const {
  selectFiltersState: SELECT_FILTER_STATE,
  selectCategories: SELECT_FILTERS_CATEGORIES,
} = FILTER_STORE_FEATURE;

export const {
  selectIds: SELECT_FILTERS_IDS,
  selectAll: SELECT_FILTERS_ALL,
  selectTotal: SELECT_FILTERS_TOTAL,
  selectEntities: SELECT_FILTERS_ENTITIES,
} = FILTER_STORE_ADAPTER.getSelectors(SELECT_FILTER_STATE);

export const SELECT_FILTERS_ENTITY = (
  id: string,
): MemoizedSelector<
  Record<string, any>,
  DoodFilterModel | undefined,
  DefaultProjectorFn<DoodFilterModel | undefined>
> =>
  createSelector(SELECT_FILTERS_ENTITIES, entities => {
    return entities[id];
  });
