import { Injectable } from '@angular/core';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, Subject, throwError } from 'rxjs';

import { OnSiteLocationKeys } from '@config/keys/on-site-location.keys';

import { OnSiteLocationStoreDispatcher } from '@common/dispatchers/on-site-location.dispatcher';
import { PaymentAtTableStoreDispatcher } from '@common/dispatchers/payment-at-table.dispatcher';

import { DoodOnSiteLocationModel } from '@core/models/on-site-location.model';
import { IPaygreenPaymentIntent, IStripePaymentIntent } from '@core/models/payment.model';
import { DoodOpenCheck, DoodOpenCheckTransaction } from '@core/models/check.model';

import { PushService } from '@core/services/push/push.service';
import { OnSiteLocationsApiService } from '@core/services/api/on-site-locations/on-site-locations-api.service';

@Injectable({
  providedIn: 'root',
})
export class OnSiteLocationsService {
  checksSubscriptions$ = new Subject<unknown>();

  constructor(
    private readonly pushService: PushService,
    private onSiteLocationDispatcher: OnSiteLocationStoreDispatcher,
    private paymentAtTableDispatcher: PaymentAtTableStoreDispatcher,
    private readonly onSiteLocationsApiService: OnSiteLocationsApiService,
  ) {}

  loadOnSiteLocations$(shopId: string): Observable<DoodOnSiteLocationModel[]> {
    return this.onSiteLocationsApiService.getOnSiteLocations$(shopId).pipe(
      map(onSiteLocations =>
        onSiteLocations.filter(onSiteLocation => onSiteLocation[OnSiteLocationKeys.Enabled]),
      ),
      tap(onSiteLocations => this.onSiteLocationDispatcher.setLocations(onSiteLocations)),
    );
  }

  loadOnSiteLocationOpenCheck$(onSiteLocationId: string): Observable<DoodOpenCheck | null> {
    return this.onSiteLocationsApiService.getOnSiteLocationOpenCheck$(onSiteLocationId);
  }

  getOnSiteLocationCheck$(checkId: string): Observable<DoodOpenCheck> {
    return this.onSiteLocationsApiService.getOnSiteLocationCheck$(checkId).pipe(
      catchError(error => {
        this.paymentAtTableDispatcher.updateCheck({
          error: error && error.detail ? { detail: error.detail } : null,
        });
        return throwError(() => error);
      }),
      tap((result: DoodOpenCheck) => {
        this.paymentAtTableDispatcher.updateCheck({ ...result, error: null });
      }),
    );
  }

  createCheckTransaction$(
    checkId: string,
    transaction: Partial<DoodOpenCheckTransaction>,
  ): Observable<DoodOpenCheckTransaction> {
    return this.onSiteLocationsApiService.createCheckTransaction$(checkId, transaction).pipe(
      tap(result => {
        this.paymentAtTableDispatcher.updateCheck({ active_transaction: result });
      }),
    );
  }

  getOnSiteLocationCheckTransactions$(transactionId: string): Observable<DoodOpenCheckTransaction> {
    return this.onSiteLocationsApiService.getOnSiteLocationCheckTransactions$(transactionId);
  }

  getOnSiteLocationCheckTransactionsPayment$(
    transactionId: string,
  ): Observable<IStripePaymentIntent | IPaygreenPaymentIntent> {
    return this.onSiteLocationsApiService.getOnSiteLocationCheckTransactionsPayment$(transactionId);
  }

  subscribeToOnSiteLocationCheck$(checkId: string): Observable<unknown> {
    const _subscription = this.pushService.addSubscription(`dood/checks/${checkId}`);
    this.checksSubscriptions$ = _subscription;
    return _subscription;
  }

  removeOnSiteLocationCheck(checkId: string, subject$: Subject<unknown>): void {
    return this.pushService.removeSubscription(`dood/checks/${checkId}`, subject$);
  }

  sendCheckTransactionInvoice$(
    transactionId: string,
    data: { email: string; fullName: string },
  ): Observable<IStripePaymentIntent> {
    return this.onSiteLocationsApiService.sendCheckTransactionInvoice$(transactionId, data);
  }

  getUserChecks$(userId: string): Observable<DoodOpenCheck[]> {
    return this.onSiteLocationsApiService.getUserChecks$(userId);
  }

  getUserCheck$(checkId: string): Observable<DoodOpenCheck> {
    return this.onSiteLocationsApiService.getUserCheck$(checkId);
  }
}
