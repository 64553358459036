<div class="bg-white max-w-screen-sm">
  <div class="flex justify-between">
    <div class="flex-1">
      <p class="lg:mb-1 text-sm lg:text-md text-neutral-600">
        <span
          *ngIf="category && !shortDescription"
          class="dot"
          >{{ category }}</span
        >
        <span
          *ngIf="shortDescription"
          class="dot"
          >{{ shortDescription }}</span
        >
        <app-star-atom
          *ngIf="stars"
          [star]="stars"
        ></app-star-atom>
      </p>
      <h2 class="font-accent font-bold text-3.5xl lg:4.5xl text-neutral-800 leading-none">
        {{ name }}
      </h2>
    </div>
    <!-- <button
            [class.icon-heart]="!isFavorite" [class.icon-todo]="isFavorite"
            (click)="onFavoriteClick()"
            class="icon w-8 h-8 ml-4 rounded-full font-bold text-primary-600 bg-primary-100"
        ></button> -->
  </div>
  <p
    *ngIf="description"
    class="py-2 font-base text-base lg:text-md font-normal leading-5.5"
  >
    {{ description }}
  </p>
</div>
