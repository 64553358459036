import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

import {
  ICartDeclinableAdditionsItem,
  ICartDeclinableSeparateAdditions,
  ICartItem,
  ICoupon,
} from '@core/models/cart.model';
import {
  ICartApi,
  ICartApiItem,
  DoodCartApiResponse,
  ICartApiResponseItem,
  ICartApiUpdate,
} from '@core/models/cart-api.model';
import { DoodProductModel } from '@core/models/product.model';

import { UserKeys } from '@config/keys/user.keys';
import { ProductKeys } from '@config/keys/product.keys';
import { MarketplaceKeys } from '@config/keys/shop.keys';
import { CartItemKeys, CartKeys } from '@config/keys/cart.keys';

import { ProductTypeValues } from '@config/values/product.values';

import { OrdersService } from '@core/services/orders/orders.service';
import { CartApiService } from '@core/services/api/cart/cart-api.service';

import { CartStoreDispatcher } from '@common/dispatchers/cart.dispatcher';
import { BasketStoreDispatcher } from '@common/dispatchers/basket.dispatcher';

import { CartStoreSelector } from '@common/selectors/cart.selector';
import { BasketStoreSelector } from '@common/selectors/basket.selector';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';
import { OnSiteLocationStoreSelector } from '@common/selectors/on-site-location.selector';

@Injectable({
  providedIn: 'root',
})
export class BasketService {
  constructor(
    private cartSelector: CartStoreSelector,
    private authSelector: AuthStoreSelector,
    private basketSelector: BasketStoreSelector,
    private cartDispatcher: CartStoreDispatcher,
    private readonly ordersService: OrdersService,
    private readonly cartApiService: CartApiService,
    private basketDispatcher: BasketStoreDispatcher,
    private marketplaceSelector: MarketplaceStoreSelector,
    private onSiteLocationSelector: OnSiteLocationStoreSelector,
  ) {}

  createBasket$(share?: boolean): Observable<DoodCartApiResponse> {
    const activeCart = this.cartSelector.active;
    const marketplace = this.marketplaceSelector.marketplace;
    const isMultiShop = marketplace[MarketplaceKeys.CartScope] === 'MARKETPLACE';
    const onSiteLocation = activeCart?.on_site_location_id;

    const cart: ICartApi = {
      [CartKeys.Shop]: activeCart?.[CartKeys.Shop],
      [CartKeys.Type]: activeCart?.[CartKeys.Type] as string,
      [CartKeys.Products]: activeCart?.[CartKeys.Products]?.map(item => {
        return {
          [CartItemKeys.Id]: item[CartItemKeys.ItemId],
          [CartItemKeys.ShopId]: item[CartItemKeys.ShopId],
          [CartItemKeys.Quantity]: item[CartItemKeys.Quantity],
          [CartItemKeys.Additions]: item[CartItemKeys.Additions],
          [CartItemKeys.Products]: item[CartItemKeys.Products],
        };
      }) as ICartApiItem[],
      [CartKeys.Marketplace]: marketplace[MarketplaceKeys.Id],
      [CartKeys.MultiShop]: isMultiShop,
      ...(activeCart?.[CartKeys.WantedAt] && {
        [CartKeys.WantedAt]: activeCart?.[CartKeys.WantedAt],
      }),
      ...(onSiteLocation && {
        [CartKeys.OnSiteLocationId]: onSiteLocation,
      }),
      [CartKeys.Currency]: marketplace[MarketplaceKeys.Currency],
      [CartKeys.Shared]: !!share,
    };

    return this.cartApiService
      .createCart$(cart)
      .pipe(tap(result => this.basketDispatcher.updateBasket(result)));
  }

  updateBasket$(shared?: boolean): Observable<DoodCartApiResponse> {
    const activeCart = this.cartSelector.active;
    const basket = this.basketSelector.basket;
    const { id: onSiteLocation } = this.onSiteLocationSelector.settings;
    const cart: ICartApiUpdate = {
      [CartKeys.Type]: activeCart?.[CartKeys.Type] as string,
      ...(activeCart?.[CartKeys.WantedAt] && {
        [CartKeys.WantedAt]: activeCart?.[CartKeys.WantedAt],
      }),
      ...(onSiteLocation && {
        [CartKeys.OnSiteLocationId]: onSiteLocation,
      }),
      [CartKeys.Currency]: basket[CartKeys.Currency],
      [CartKeys.Shared]: typeof shared === 'boolean' ? shared : basket[CartKeys.Shared],
    };

    if (!basket[CartKeys.Id]) {
      throw new Error('Cart ID is empty');
    }

    return this.cartApiService
      .updateCart$(cart, basket[CartKeys.Id])
      .pipe(tap(result => this.basketDispatcher.updateBasket(result)));
  }

  getBasket$(id: string, shareCode?: string): Observable<DoodCartApiResponse> {
    if (!id) {
      throw new Error('Cart ID is empty');
    }

    return this.cartApiService.getCart$(id, shareCode).pipe(
      tap(changes => this.basketDispatcher.updateBasket(changes)),
      tap(result => {
        const cartItems = this.mapBasketItemsToCart(result.cart_items);
        this.cartDispatcher.updateActive({
          type: result.type,
          products: cartItems,
          ...(result.on_site_location_id && {
            on_site_location_id: result.on_site_location_id,
          }),
        });
      }),
    );
  }

  addBasketItem(item: ICartItem): void {
    const product: ICartApiItem = {
      [CartItemKeys.Id]: item[CartItemKeys.ItemId],
      [CartItemKeys.ShopId]: item[CartItemKeys.ShopId],
      [CartItemKeys.Products]: item[CartItemKeys.Products],
      [CartItemKeys.Additions]: item[CartItemKeys.Additions],
      [CartItemKeys.Quantity]: item[CartItemKeys.Quantity],
    };
    const basketId = this.basketSelector.basket[CartKeys.Id];
    const shareCode = this.basketSelector.basket[CartKeys.ShareCode];
    if (product && basketId) {
      this.cartApiService
        .addCartItem$(basketId, product, item[CartItemKeys.Quantity], shareCode)
        .pipe(
          take(1),
          tap(res => {
            const cartItems = this.mapBasketItemsToCart(res[CartKeys.CartItems]);
            this.cartDispatcher.updateActive({
              products: cartItems,
              ...(res.on_site_location_id && {
                on_site_location_id: res.on_site_location_id,
              }),
            });
          }),
          tap(result => this.basketDispatcher.updateBasket(result)),
          switchMap(() => this.ordersService.checkCartIsValid$()),
        )
        .subscribe();
    }
  }

  updateBasketItem(item: ICartItem): void {
    const basketItem = this.basketSelector.basket.itemEditId as string;
    const basketId = this.basketSelector.basket[CartKeys.Id];
    const shareCode = this.basketSelector.basket[CartKeys.ShareCode];
    const product: ICartApiItem = {
      [CartItemKeys.Id]: item[CartItemKeys.ItemId],
      [CartItemKeys.ShopId]: item[CartItemKeys.ShopId],
      [CartItemKeys.Products]: item[CartItemKeys.Products],
      [CartItemKeys.Additions]: item[CartItemKeys.Additions],
      [CartItemKeys.Quantity]: item[CartItemKeys.Quantity],
    };
    if (item && basketId) {
      this.cartApiService
        .updateCartItem$(basketId, product, basketItem, shareCode)
        .pipe(
          take(1),
          tap(res => {
            const cartItems = this.mapBasketItemsToCart(res[CartKeys.CartItems]);
            this.cartDispatcher.updateActive({
              products: cartItems,
              ...(res.on_site_location_id && {
                on_site_location_id: res.on_site_location_id,
              }),
            });
          }),
          tap(result => this.basketDispatcher.updateBasket(result)),
          switchMap(() => this.ordersService.checkCartIsValid$()),
        )
        .subscribe();
    }
  }

  removeBasketItem$(itemId?: string): Observable<DoodCartApiResponse> {
    const basketItem = this.basketSelector.basket.itemEditId as string;
    const basketId = this.basketSelector.basket[CartKeys.Id];
    const shareCode = this.basketSelector.basket[CartKeys.ShareCode];
    return this.cartApiService.removeCartItem$(basketId, itemId ? itemId : basketItem).pipe(
      switchMap(() => {
        return this.getBasket$(basketId, shareCode);
      }),
    );
  }

  removeBasketUser$(userId: string): Observable<DoodCartApiResponse> {
    const basketId = this.basketSelector.basket[CartKeys.Id];
    const shareCode = this.basketSelector.basket[CartKeys.ShareCode];
    return this.cartApiService.removeCartUser$(basketId, userId).pipe(
      switchMap(() => {
        return combineLatest([
          this.getBasket$(basketId, shareCode),
          this.authSelector.selectUserId,
        ]).pipe(
          map(([basket, user]) => {
            if (user !== basket[CartKeys.User][UserKeys.Id]) {
              this.basketDispatcher.reset();
              this.cartDispatcher.updateActive({
                products: [],
              });
            }
            return basket;
          }),
        );
      }),
    );
  }

  confirmBasket$(): Observable<DoodCartApiResponse> {
    const basketId = this.basketSelector.basket[CartKeys.Id];
    return this.cartApiService.confirmCart$(basketId);
  }

  clearBasket(): void {
    this.basketDispatcher.reset();
    this.basketDispatcher.clear();
  }

  addBasketCoupon$(coupon: string): Observable<DoodCartApiResponse> {
    const basketId = this.basketSelector.basket[CartKeys.Id];
    const formatCoupon = {
      key: coupon,
    };
    return this.cartApiService
      .addCartCoupon$(basketId, formatCoupon)
      .pipe(tap(result => this.basketDispatcher.updateBasket(result)));
  }

  removeBasketCoupon$(coupon: ICoupon): Observable<DoodCartApiResponse> {
    const basketId = this.basketSelector.basket[CartKeys.Id];
    const formatCoupon = {
      key: coupon.key,
    };
    return this.cartApiService
      .removeCartCoupon$(basketId, formatCoupon)
      .pipe(tap(result => this.basketDispatcher.updateBasket(result)));
  }

  private mapBasketItemsToCart(items: ICartApiResponseItem[]): ICartItem[] {
    return items?.map((item, index) => {
      return {
        [CartItemKeys.ItemId]: item.product[ProductKeys.Id],
        [CartItemKeys.ShopId]: item.product[ProductKeys.ShopId],
        [CartItemKeys.Additions]: item.product[ProductKeys.Additions]?.products,
        ...(item.product[ProductKeys.Type] === ProductTypeValues.Declinable && {
          [CartItemKeys.Products]: this.flatCategoriesItems(item),
        }),
        [CartItemKeys.Quantity]: item.quantity,
        [CartItemKeys.Id]: index + 1,
      };
    });
  }

  private flatCategoriesItems(item: ICartApiResponseItem): ICartDeclinableAdditionsItem[] {
    const emptyArr: ICartDeclinableAdditionsItem[] = [];
    item.product.categories.forEach(cat => {
      cat.products.forEach(el => {
        emptyArr.push({
          [CartItemKeys.Id]: el[ProductKeys.Id],
          [CartItemKeys.Additions]: el[ProductKeys.Additions].items?.map(
            val => val[ProductKeys.Id],
          ),
          [CartItemKeys.Quantity]: 1,
          [CartItemKeys.ShopId]: el[ProductKeys.ShopId],
          [CartItemKeys.StoreId]: cat.store_id,
          [CartItemKeys.SeparateAdditionsByGroup]: this.groupAdditionsByGroupId(el.additions.items),
        });
      });
    });
    return emptyArr;
  }

  private groupAdditionsByGroupId(items: DoodProductModel[]): ICartDeclinableSeparateAdditions[] {
    const additionsByGroup: ICartDeclinableSeparateAdditions[] = [];
    items.forEach(el => {
      if (additionsByGroup.some(val => val.id === el[ProductKeys.AdditionsGroup])) {
        additionsByGroup.find(v => v.selected.push(el[ProductKeys.Id]));
      } else {
        additionsByGroup.push({
          id: el[ProductKeys.AdditionsGroup]
            ? el[ProductKeys.AdditionsGroup]
            : 'custom-addition-group',
          selected: [el[ProductKeys.Id]],
        });
      }
    });
    return additionsByGroup;
  }
}
