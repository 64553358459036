import { Injectable } from '@angular/core';
import { EdenredApiService } from '../api/edenred/edenred-api.service';
import { Observable } from 'rxjs';
import { IEdenredGetUserResponse, IEdenredWebLoginResponse } from '../../models/edenred.model';

@Injectable({
  providedIn: 'root',
})
export class EdenredService {
  constructor(private readonly edenredApiService: EdenredApiService) {}

  getUser$(): Observable<IEdenredGetUserResponse> {
    return this.edenredApiService.getUser$();
  }

  edenredWebLogin$(redirectUri: string): Observable<IEdenredWebLoginResponse> {
    return this.edenredApiService.edenredWebLogin$(redirectUri);
  }
}
