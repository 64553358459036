<div class="md:mx-auto w-full md:max-w-md px-5">
  <h1
    *ngIf="!orderStatus"
    class="font-accent font-bold mt-5 mb-3 md:mt-6 text-md md:text-center"
  >
    {{ 'validate-shared-order.subtitle' | translate }}
  </h1>
  <h1
    *ngIf="orderStatus === orderStatusValues.Payment"
    class="font-accent font-bold mt-5 mb-3 md:mt-6 text-md md:text-center"
  >
    {{ 'validate-shared-order.subtitle-payment-in-progress' | translate }}
  </h1>
  <h1
    *ngIf="orderStatus === orderStatusValues.Cancelled"
    class="font-accent font-bold mt-5 mb-3 md:mt-6 text-md md:text-center"
  >
    {{ 'validate-shared-order.subtitle-payment-cancelled' | translate }}
  </h1>
  <div
    *ngIf="reconstructedBasketSubCarts$ | async as confirmedUsers"
    class="mb-80"
  >
    <ul>
      <li
        *ngFor="let confirmedUser of confirmedUsers"
        class="mb-10"
      >
        <p class="py-2 px-1 bg-neutral-800 text-white text-sm text-center mt-4 font-base font-bold">
          {{ confirmedUser.user.firstname }}
          <ng-container *ngIf="!confirmedUser.user.firstname">{{
            'basket.anonymous' | translate
          }}</ng-container>
          <span *ngIf="(userId$ | async) === confirmedUser.user.id"
            >({{ 'basket.you' | translate }})</span
          >
        </p>
        <p
          *ngIf="confirmedUser.isConfirmed"
          class="flex justify-between pt-4 font-bold font-base text-base"
        >
          <span>
            {{ confirmedUser.items }}
            <span *ngIf="confirmedUser.items === 1">{{
              'validate-shared-order.product' | translate
            }}</span>
            <span *ngIf="confirmedUser.items > 1">{{
              'validate-shared-order.products' | translate
            }}</span>
          </span>
          <span>
            <app-price-atom
              [amount]="confirmedUser.total"
              [format]="true"
            ></app-price-atom>
          </span>
        </p>
        <p
          *ngIf="!confirmedUser.isConfirmed"
          class="pt-4 font-bold font-base text-base"
        >
          {{ 'validate-shared-order.waiting-validation' | translate }}
        </p>
      </li>
    </ul>
    <p
      *ngIf="total$ | async as total"
      class="flex justify-between pt-6 font-bold font-base text-base"
    >
      <span>Total: </span>
      <span
        ><app-price-atom
          [format]="true"
          [amount]="total"
          [bold]="true"
        ></app-price-atom
      ></span>
    </p>
    <p
      *ngIf="!orderStatus"
      class="pt-6 font-base text-base"
    >
      {{ 'validate-shared-order.information' | translate }}
    </p>
    <p
      *ngIf="orderStatus === orderStatusValues.Payment"
      class="pt-6 font-base text-base"
    >
      {{ 'validate-shared-order.information-2' | translate }}
    </p>
    <div
      *ngIf="orderStatus === orderStatusValues.Cancelled"
      class="mt-6"
    >
      <app-button-atom
        size="M"
        [label]="'validate-shared-order.home-button' | translate"
        color="primary"
        (click)="goHome()"
        type="primary"
        [full]="true"
      ></app-button-atom>
    </div>
  </div>
</div>
