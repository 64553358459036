<!-- TODO: Avoid nested if/else in class -->
<button
  [disabled]="disabled"
  class="
        flex flex-row gap-3 justify-center items-center
        btn-{{ type }}
        {{ size }}
        {{
    !disabled ? 'kiosk:active:scale-105 kiosk:transition-transform kiosk:duration-100' : ''
  }}
        {{
    type === 'outlined' && color === 'neutral'
      ? 'border-' + color + '-300 text-' + color + '-600'
      : ''
  }}
                {{
    type === 'kiosk-outlined'
      ? 'text-' + overrideTextColor?.value + ' rounded-lg font-bold border-4'
      : ''
  }}
        {{ type === 'light' && !overrideBgColor?.value ? 'bg-' + color + '-100' : '' }}
        {{ type === 'light' && !overrideTextColor?.value ? 'text-' + color + '-600' : '' }}
        {{ type === 'kiosk-primary' ? 'rounded-lg font-bold' : null }}
        {{ type === 'kiosk-light' ? 'rounded-lg font-bold' : null }}
        {{
    type === 'light' && color === 'neutral' && !overrideTextColor?.value
      ? 'text-' + color + '-800'
      : ''
  }}
        {{ type === 'link' ? 'text-primary-800' : '' }}
        {{ type === 'link' && !overrideBgColor?.value ? 'bg-transparent' : '' }}
        {{ type === 'kiosk-outlined' && size === 'XXL' ? 'text-3xl' : '' }}
        {{
    thickSize === 'XS'
      ? 'py-1'
      : thickSize === 'S'
        ? type === 'kiosk-primary' || type === 'kiosk-light'
          ? 'py-1.5 !text-xl'
          : 'py-2'
        : thickSize === 'M'
          ? 'py-3.5'
          : thickSize === 'L'
            ? 'py-4'
            : thickSize === 'XXL'
              ? type === 'kiosk-primary' || type === 'kiosk-light' || type === 'kiosk-outlined'
                ? 'py-7.5 !text-4xl'
                : 'py-8'
              : ''
  }}
"
  [style.border-color]="type === 'kiosk-outlined' ? overrideTextColor?.value : ''"
  [style.backgroundColor]="overrideBgColor | contentBuilderColor"
  [style.color]="overrideTextColor | contentBuilderColor"
  [class.disabled]="disabled || lookDisabled"
  [class.aspect-square]="aspectSquare"
  [class.w-full]="full"
  (click)="clickEventEmitter.emit($event)"
>
  <i
    *ngIf="icon && !iconOnRight"
    class="icon {{ icon }} rotate-{{ iconRotation }}"
  ></i>
  <img
    *ngIf="iconUrl && !iconOnRight"
    src="{{ iconUrl }}"
  />
  @if (label) {
    <span>{{ label }}</span>
  }
  <i
    *ngIf="icon && iconOnRight"
    class="icon {{ icon }}"
  ></i>
</button>
