<a
  class="flex border-separate cursor-pointer flex-col overflow-hidden text-center text-sm font-bold shadow-S"
  [style.backgroundColor]="backgroundColor | contentBuilderColor: 'neutral-100'"
  [style.color]="textColor | contentBuilderColor: '00000'"
  [class.rounded-sm]="rounded === 'small'"
  [class.rounded-md]="rounded === 'medium'"
  [class.rounded-lg]="!rounded || rounded === 'large'"
  [class.rounded-2xl]="rounded === '2xl'"
  [class.rounded-3xl]="rounded === '3xl'"
  [class.rounded-4xl]="rounded === '4xl'"
  [class.rounded-full]="rounded === 'full'"
  [class.shadow-S]="!shadow || shadow === 'small'"
  [class.shadow-md]="shadow === 'medium'"
  [class.shadow-lg]="shadow === 'large'"
  [target]="linkTarget"
  [link]="linkUrl"
  (click)="onClick()"
>
  <div
    *ngIf="imageUrl"
    [style.aspect-ratio]="aspectRatio ? aspectRatio : '100/64'"
  >
    <img
      [src]="imageUrl"
      [style.aspect-ratio]="aspectRatio ? aspectRatio : '100/64'"
      class="h-full w-full rounded-t-lg"
      [ngClass]="backgroundStyleContain ? 'object-contain' : 'object-cover'"
      loading="lazy"
    />
  </div>
  <div
    *ngIf="title"
    [style.aspect-ratio]="aspectRatio && !imageUrl ? aspectRatio : ''"
    [class.font-accent]="!font || font === 'accent'"
    [class.font-brand]="font === 'brand'"
    [class.text-sm]="!fontSize || fontSize === 'sm'"
    [class.text-md]="fontSize === 'md'"
    [class.text-lg]="fontSize === 'lg'"
    [class.text-xl]="fontSize === 'xl'"
    [class.text-2xl]="fontSize === '2xl'"
    [class.text-3xl]="fontSize === '3xl'"
    [class.text-4xl]="fontSize === '4xl'"
    [class.text-5xl]="fontSize === '5xl'"
    [class.text-6xl]="fontSize === '6xl'"
    [class.text-7xl]="fontSize === '7xl'"
    [class.text-8xl]="fontSize === '8xl'"
    class="align-center flex w-full items-center justify-center p-2"
  >
    {{ title }}
  </div>
</a>
