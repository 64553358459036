import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShopStoreRefiner } from '@common/refiners/shop.refiner';
import { ProductStoreSelector } from '@common/selectors/product.selector';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

import { DoodProductModel } from '@core/models/product.model';
import { ProductsService } from '@core/services/products/products.service';
import { DoodCategoryWithProduct } from '@shared/interfaces/category.interface';
import { map, Observable, of, Subject, switchMap, takeUntil } from 'rxjs';

@Component({
  selector: 'app-kiosk-products-same-category-block',
  templateUrl: './kiosk-products-same-category-block.component.html',
})
export class KioskProductsSameCategoryBlockComponent implements OnInit, OnDestroy {
  @Input() heading = 'kiosk-shop-page.same-category-title';
  @Input() primaryButtonBackgroundColor?: IContentBuilderFieldColor;
  @Input() primaryButtonTextColor?: IContentBuilderFieldColor;
  @Input() secondaryButtonBackgroundColor?: string;
  @Input() secondaryButtonTextColor?: string;
  @Input() textColor?: IContentBuilderFieldColor;
  @Input() backgroundColor?: string;

  @ViewChild('carousel') carousel!: ElementRef;

  private destroyed$ = new Subject<boolean>();

  scrollAmount = 172; // w-40 + mr-3

  categoryWithProducts$?: Observable<DoodCategoryWithProduct | null>;
  products$?: Observable<DoodProductModel[] | null>;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly productSelector: ProductStoreSelector,
    private readonly shopRefiner: ShopStoreRefiner,
    private readonly productsService: ProductsService,
  ) {}

  ngOnInit(): void {
    this.products$ = this.productSelector.selectActiveCategory.pipe(
      takeUntil(this.destroyed$),
      switchMap(categoryId => {
        if (categoryId) {
          return this.shopRefiner.selectCategoryById(categoryId).pipe(
            switchMap((category: DoodCategoryWithProduct | null) => {
              if (category && this.isProductIncluded(category)) {
                return this.findProducts(category?.products);
              } else {
                return this.getProductsFromProductCategory();
              }
            }),
          );
        } else {
          return this.getProductsFromProductCategory();
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  isProductIncluded(category: DoodCategoryWithProduct): boolean {
    if (this.productSelector.active && category.products.includes(this.productSelector.active.id)) {
      return true;
    } else {
      return false;
    }
  }

  getProductsFromProductCategory(): Observable<DoodProductModel[] | null> {
    return this.shopRefiner.selectCategories.pipe(
      switchMap((categories: DoodCategoryWithProduct[] | null) => {
        if (categories) {
          let foundCategory = categories.find(category => {
            if (this.productSelector.active) {
              return category.products.includes(this.productSelector.active.id);
            }
            return false;
          });

          if (foundCategory) {
            return this.findProducts(foundCategory.products);
          }
        }
        return of(null);
      }),
    );
  }

  navigateToProduct(product: DoodProductModel): void {
    const shopCity = this.route.snapshot.paramMap.get('shopCity');
    const shopType = this.route.snapshot.paramMap.get('shopType');
    const shopSlug = this.route.snapshot.paramMap.get('shopSlug');

    this.router.navigate([
      `/fr/${shopCity}/${shopType}/${shopSlug}/product/${product.id}/product-add`,
    ]);
  }

  private getProductIdFromRoute(route: ActivatedRoute): string | null {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route.snapshot.paramMap.get('productId');
  }

  scrollLeft() {
    this.carousel.nativeElement.scrollLeft -= this.scrollAmount;
  }

  scrollRight() {
    this.carousel.nativeElement.scrollLeft += this.scrollAmount;
  }

  findProducts(productsId: string[]): Observable<DoodProductModel[] | null> {
    return this.productsService.selectAll$().pipe(
      map(products =>
        products.filter(
          product =>
            productsId.includes(product.id) && product.id != this.getProductIdFromRoute(this.route),
        ),
      ),
      map(products => {
        if (products.length === 0) {
          return null;
        }
        return products;
      }),
    );
  }
}
