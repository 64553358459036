import { Router } from '@angular/router';
import { of, throwError, timer } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { catchError, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { Paths } from '@config/paths.config';
import { OrderStatusValues } from '@config/values/order.values';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';

import { CartService } from '@core/services/cart/cart.service';
import { BasketService } from '@core/services/basket/basket.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { TransactionsService } from '@core/services/transactions/transactions.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';

import { BasketStoreSelector } from '@common/selectors/basket.selector';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';
import { TransactionStoreRefiner } from '@common/refiners/transaction.refiner';

import { DoodOrderModel } from '@store/order/order.model';
import { AddPaymentModalComponent } from '@shared/modals/add-payment-modal/add-payment-modal.component';

@Component({
  selector: 'app-group-payment-list-block',
  templateUrl: './group-payment-list-block.component.html',
  styleUrls: ['./group-payment-list-block.component.scss'],
})
export class GroupPaymentListBlockComponent extends DestroyerBase implements OnInit, OnDestroy {
  amountLeft = 0;
  totalAmount = 0;
  isLoaded = false;

  private orderStatus!: string;

  userId$ = this.authSelector.selectUserId;
  transactions$ = this.transactionRefiner.selectTransactionsGroupedBtUser;

  constructor(
    private readonly router: Router,
    private authSelector: AuthStoreSelector,
    private readonly cartService: CartService,
    private basketSelector: BasketStoreSelector,
    private readonly modalsService: ModalsService,
    private readonly ordersService: OrdersService,
    private readonly basketService: BasketService,
    private readonly routerHelper: RouterHelperService,
    private readonly translateService: TranslateService,
    private transactionRefiner: TransactionStoreRefiner,
    private readonly transactionsService: TransactionsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.transactionsService.clearTransactions();
    this.startTransactionListUpdateTimer();
    this.setRemainingAmount();
  }

  ngOnDestroy(): void {
    this.modalsService.close(AddPaymentModalComponent.handle);
    super.ngOnDestroy();
  }

  addPayment(): void {
    this.modalsService.open(AddPaymentModalComponent.handle);
  }

  private startTransactionListUpdateTimer(): void {
    timer(0, 10 * 1000)
      .pipe(
        takeUntil(this._destroyerRef),
        switchMap(() => this.basketSelector.select.pipe(take(1))),
        switchMap(basket => this.basketService.getBasket$(basket.id, basket.share_code)),
        switchMap(basket =>
          basket.order ? this.ordersService.getOrderById$(basket.order!) : of(undefined),
        ),
        tap(order => {
          if (order) {
            this.ordersService.setActiveOrderToStore(order);
            this.orderStatus = order.status;
            this.handleOrderStatusChange(order);
          }
          this.totalAmount = order?.final_price || 0;
        }),
        switchMap(order =>
          order?.id ? this.ordersService.getOrdersTransactions$(order.id!) : of([]),
        ),
        catchError(error => {
          if (error.status === 403) {
            alert(this.translateService.instant('group-order-modal.unknown-error'));
            this.ordersService.clearOrders();
            this.basketService.clearBasket();
            this.cartService.clearCart();
            this.router.navigate([this.routerHelper.translateRoute('/')]);
          }
          return throwError(() => error);
        }),
        tap(() => (this.isLoaded = true)),
      )
      .subscribe();
  }

  private setRemainingAmount(): void {
    this.transactionRefiner.selectTotalPaidAmount
      .pipe(takeUntil(this._destroyerRef))
      .subscribe(amountPaid => {
        this.amountLeft = this.totalAmount - amountPaid;
      });
  }

  private handleOrderStatusChange(order: DoodOrderModel): void {
    if (this.orderStatus === OrderStatusValues.Waiting) {
      this.clearGroupedOrderState();
      this.router.navigate([
        this.routerHelper.translateRoute(`${Paths.Orders}/${order.id}/${Paths.Status}`),
      ]);
      return;
    }

    if (this.orderStatus === OrderStatusValues.Cancelled) {
      this.translateService
        .get('group-payments.errors.order-invalid-status')
        .pipe(
          take(1),
          tap(translation => alert(translation)),
          tap(() => {
            this.clearGroupedOrderState();
            this.router.navigate([this.routerHelper.translateRoute('/')]);
          }),
        )
        .subscribe();
    }
  }

  private clearGroupedOrderState(): void {
    this.ordersService.clearOrders();
    this.cartService.clearCart();
    this.transactionsService.clearTransactions();
    this.basketService.clearBasket();
  }

  protected isString(value: any): value is string {
    return typeof value === 'string';
  }
}
