<div
  id="{{ anchor }}"
  class="flex flex-col lg:flex-row"
  [class.lg:flex-row-reverse]="this.imagePosition === this.IMAGE_POSITION_RIGHT"
>
  <div class="relative w-full lg:w-8/12">
    <div
      class="absolute bottom-0 left-0 right-0 top-0 z-10 flex h-full w-full items-center bg-black opacity-25"
    ></div>
    <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full">
      <img
        class="h-full w-full object-cover"
        *ngIf="image"
        [src]="image.url"
        alt="Hero image"
      />
    </div>
    <div class="relative z-20 h-full py-20 text-center lg:flex lg:flex-col lg:justify-center">
      <h2 class="mb-8 font-accent text-4.5xl font-bold leading-10 text-white lg:text-5.8xl">
        {{ textOnImage }}
      </h2>
      <div class="flex items-center">
        <app-button-atom
          [link]="urlButtonImage"
          class="mx-auto"
          [type]="'primary'"
          [color]="'primary'"
          [label]="labelButtonImage ?? ''"
          [thickSize]="buttonSizeY"
        ></app-button-atom>
      </div>
    </div>
  </div>
  <div class="bg-neutral-800 px-12 pb-16 pt-10 text-center lg:w-4/12 lg:pb-32 lg:pt-32">
    <div class="mx-auto max-w-screen-sm">
      <h2 class="mb-6 font-accent text-1xsl font-bold text-white">
        {{ textContent }}
      </h2>
      <app-block-list [blockIdList]="buttons"></app-block-list>
    </div>
  </div>
</div>
