import { ActiveState, createActiveAdapter } from '@store/_abstract/active';

import { DoodCartComment, DoodCartModel, DoodReconstructedCartItem } from '@core/models/cart.model';
import { HydrationState, createHydratedAdapter } from '@store/_abstract/hydrated';

export interface CartState extends ActiveState<DoodCartModel, string>, HydrationState {
  last_update: Date | null;
  comment: DoodCartComment | null;
  edit_item: DoodReconstructedCartItem | null;
}

export const CART_STORE_KEY = 'Cart';

export const CART_STORE_ACTIVE_ADAPTER = createActiveAdapter<DoodCartModel, string>({
  selectId: c => c.handle,
});

export const CART_STORE_HYDRATION_ADAPTER = createHydratedAdapter();

export const CART_STORE_INITIAL_STATE: CartState = CART_STORE_HYDRATION_ADAPTER.getInitialState({
  ...CART_STORE_ACTIVE_ADAPTER.getInitialState({
    comment: null,
    edit_item: null,
    last_update: null,
  }),
});
