import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  take,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';

import { CartKeys } from '@config/keys/cart.keys';
import { MetafieldKeys } from '@config/keys/metafield.keys';

import { DoodCartModel } from '@core/models/cart.model';
import { IOrder } from '@core/models/order.model';
import { DoodProductModel } from '@core/models/product.model';
import { ComoAsset, ComoAttachUserResponse, ComoUser } from '@core/models/como.model';
import { DoodUserModel } from '@store/authentication/authentication.model';

import { CartService } from '@core/services/cart/cart.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { BasketService } from '@core/services/basket/basket.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { ComoApiService } from '@core/services/api/como/como-api.service';

import { CartStoreSelector } from '@common/selectors/cart.selector';
import { BasketStoreSelector } from '@common/selectors/basket.selector';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

import { ComoSsoLoadingModalComponent } from '@shared/modals/como/como-sso-loading-modal/como-sso-loading-modal.component';
import { ComoConfirmUsePointModalComponent } from '@shared/modals/como/como-confirm-use-point-modal/como-confirm-use-point-modal.component';
import { ComoProductsSelectionModalComponent } from '@shared/modals/como/como-products-selection-modal/como-products-selection-modal.component';
import { AuthFirebaseService } from '@core/services/api/auth-firebase/auth-firebase.service';
import { UserService } from '@core/services/user/user.service';
import { Router } from '@angular/router';
import { RouterHelperService } from '../router-helper/router-helper.service';

@Injectable({
  providedIn: 'root',
})
export class ComoService {
  private comoUser$ = new BehaviorSubject<ComoUser | undefined | null>(undefined);
  private debouncedLoadComoUser$ = new Subject<boolean>();

  constructor(
    private readonly authSelector: AuthStoreSelector,
    private readonly cartSelector: CartStoreSelector,
    private readonly cartService: CartService,
    private readonly basketSelector: BasketStoreSelector,
    private readonly ordersService: OrdersService,
    private readonly modalsService: ModalsService,
    private readonly basketService: BasketService,
    private readonly comoApiService: ComoApiService,
    private readonly marketplaceSelector: MarketplaceStoreSelector,
    private readonly authFirebaseService: AuthFirebaseService,
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly routerHelper: RouterHelperService,
  ) {
    this.debouncedLoadComoUser$
      .pipe(
        debounceTime(50),
        switchMap(() =>
          combineLatest([
            this.authSelector.selectUser,
            this.marketplaceSelector.selectMarketplaceId,
            this.cartSelector.selectActive.pipe(distinctUntilChanged(), throttleTime(5000)),
          ]),
        ),
        debounceTime(50),
        switchMap(([user, marketplaceId, cart]) => {
          const order = this.ordersService.mapCartToOrderValues(cart as DoodCartModel);

          return this.isUserComoAccountEnabledOnMarketplaceLevel$().pipe(
            switchMap(isEnabled => {
              if (!user?.id || !isEnabled) {
                this.comoUser$.next(null);
                return of(null);
              }
              return this.updateComoUser$({
                ...order,
                user: user.id,
                marketplace: marketplaceId,
              });
            }),
          );
        }),
      )
      .subscribe();
  }

  loadComoUser(): void {
    this.debouncedLoadComoUser$.next(true);
  }

  getComoUser$(): BehaviorSubject<ComoUser | undefined | null> {
    return this.comoUser$;
  }

  sendPaymentVerificationCode$(): Observable<boolean> {
    return this.cartSelector.selectActive.pipe(
      take(1),
      map(cart => this.ordersService.mapCartToOrderValues(cart as DoodCartModel)),
      switchMap(cart => this.comoApiService.sendPaymentVerificationCode$(cart)),
      catchError(() => {
        return of(undefined);
      }),
      map(result => !!result),
    );
  }

  createComoUser$(
    phoneOrEmail: string,
    legalAccepted = false,
    dateOfBirth: string | null = null,
    marketingAccepted = false,
  ): Observable<DoodUserModel> {
    return this.marketplaceSelector.selectMarketplaceId.pipe(
      take(1),
      switchMap(marketplaceId =>
        this.comoApiService.createComoUser(
          marketplaceId,
          phoneOrEmail,
          legalAccepted,
          dateOfBirth,
          marketingAccepted,
        ),
      ),
    );
  }

  linkComoUser$({
    phoneOrEmail,
    code,
  }: { phoneOrEmail?: string; code?: string } = {}): Observable<ComoAttachUserResponse> {
    return this.marketplaceSelector.selectMarketplaceId.pipe(
      take(1),
      switchMap(marketplaceId =>
        this.comoApiService.linkComoUser$(marketplaceId, phoneOrEmail, code),
      ),
      switchMap(response => {
        if (response.custom_token) {
          return this.authFirebaseService.signInWithCustomToken(response.custom_token).pipe(
            switchMap(() => this.userService.loadUser$()),
            map(() => response),
          );
        } else if (response.result === true) {
          return this.userService.loadUser$().pipe(map(() => response));
        } else {
          return of(response);
        }
      }),
    );
  }

  attachUserWithEmail$(): Observable<boolean> {
    return this.marketplaceSelector.selectMarketplaceId.pipe(
      take(1),
      switchMap(marketplaceId => this.comoApiService.attachUserWithEmail$(marketplaceId)),
    );
  }

  isUserComoAccountEnabledOnMarketplaceLevel$(): Observable<boolean> {
    return combineLatest([
      this.authSelector.selectUserMetafields,
      this.marketplaceSelector.selectMarketplaceId,
    ]).pipe(
      take(1),
      map(([metafields, marketplaceId]) => {
        if (metafields && Array.isArray(metafields)) {
          return !!metafields!.find(metafield =>
            new RegExp(`^como_user_id_marketplace_${marketplaceId}$`).test(
              metafield[MetafieldKeys.Handle],
            ),
          );
        }
        return false;
      }),
    );
  }

  handleSingleSignOn(): void {
    const urlSearchParams = new URLSearchParams(window.location.search);
    if (!urlSearchParams.has('temporaryToken')) {
      return;
    }

    const temporaryToken = urlSearchParams.get('temporaryToken');

    if (!temporaryToken) {
      return;
    }

    this.modalsService.open(ComoSsoLoadingModalComponent.handle);
  }

  getErrorMessageFromCode(code: number): string {
    let errorMessage = "Une erreur inconnue s'est produite";
    switch (code) {
      case 103:
        // Phone number already exists
        errorMessage = 'como.create-account.errors.account-already-exists';
        break;
      case 10:
        // Invalid code
        errorMessage = 'como.link-account.errors.invalid-auth-code';
        break;
      case 202:
        errorMessage = 'como.link-account.errors.account-not-found-phone';
        break;
      case 0:
        errorMessage = 'como.errors.generic-error';
        break;
      case 101:
        // Como is already attached to user
        errorMessage = 'como.create-account.errors.user-has-loyalty-attached';
        break;
      case 201:
        // Unable to send verification code
        errorMessage = 'como.link-account.errors.unable-to-send-code';
        break;
      case 102:
        // Phone number is missing
        break;
      case 1:
        // Not logged in
        break;
      case 3:
        // Como not enabled
        errorMessage = 'como.errors.loyalty-not-enabled';
        break;
      default:
        errorMessage = 'como.errors.generic-error';
    }
    return errorMessage;
  }

  confirmUsePointToAddAsset(asset: ComoAsset, kiosk?: boolean): void {
    let isPointShopAsset = asset.key?.startsWith('ps_');
    if (isPointShopAsset) {
      this.modalsService.open(ComoConfirmUsePointModalComponent.handle, undefined, {
        asset: asset,
      });
    } else if (kiosk && asset.items_selection) {
      this.router.navigate([
        this.routerHelper.translateRoute(`/kiosk-promo-select-product-page/` + asset.key),
      ]);
    } else if (kiosk) {
      this.addRewardToCart$(asset).subscribe();
    } else {
      this.displayProductsSelectionModal(asset);
    }
  }

  allowedProducts$(externalIds: string[], shopId?: string): Observable<DoodProductModel[]> {
    return this.marketplaceSelector.selectMarketplaceId.pipe(
      take(1),
      switchMap(marketplaceId =>
        this.comoApiService.allowedProducts$(marketplaceId, externalIds, shopId),
      ),
    );
  }

  displayProductsSelectionModal(asset: ComoAsset): void {
    this.modalsService.open(ComoProductsSelectionModalComponent.handle, undefined, {
      asset: asset,
    });
  }

  addRewardToCart$(asset: ComoAsset): Observable<any> {
    this.cartService.addCoupon({ key: asset?.key });
    return this.ordersService.checkCartIsValid$().pipe(
      take(1),
      switchMap(validate => {
        const basket = this.basketSelector.basket[CartKeys.Id];
        if (asset?.key && basket) {
          return this.basketService.addBasketCoupon$(asset?.key as string);
        } else {
          return of(validate);
        }
      }),
    );
  }

  private updateComoUser$(
    order: Partial<IOrder> & {
      user: string;
      marketplace: string;
    },
  ): Observable<ComoUser | undefined | null> {
    return this.comoApiService.getComoUser(order).pipe(
      catchError(() => {
        return of(null);
      }),
      tap(comoUser => {
        this.comoUser$.next(comoUser);
      }),
    );
  }
}
