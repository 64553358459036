import { createReducer } from '@ngrx/store';

import {
  SHOP_STORE_ACTIVE_PIECE,
  SHOP_STORE_API_PIECE,
  SHOP_STORE_LOCAL_PIECE,
} from './shop.piece';
import { SHOP_STORE_INITIAL_STATE } from './shop.state';

export const SHOP_STORE_REDUCER = createReducer(
  SHOP_STORE_INITIAL_STATE,
  ...SHOP_STORE_ACTIVE_PIECE,
  ...SHOP_STORE_API_PIECE,
  ...SHOP_STORE_LOCAL_PIECE,
);
