import { DoodTransactionModel } from '@core/models/transaction.model';
import { ActiveState, createActiveAdapter } from '@store/_abstract/active';
import { HydrationState, createHydratedAdapter } from '@store/_abstract/hydrated';

export interface TransactionState
  extends ActiveState<DoodTransactionModel, string>,
    HydrationState {
  isLoaded: boolean;
  isLoading: boolean;
  amount: number | null;
  error: string | null;
  paymentMode: string | null;
}

export const TRANSACTION_STORE_KEY = 'Transaction';

export const TRANSACTION_STORE_HYDRATION_ADAPTER = createHydratedAdapter();

export const TRANSACTION_STORE_ACTIVE_ADAPTER = createActiveAdapter<DoodTransactionModel, string>();

export const TRANSACTION_STORE_INITIAL_STATE: TransactionState =
  TRANSACTION_STORE_HYDRATION_ADAPTER.getInitialState(
    TRANSACTION_STORE_ACTIVE_ADAPTER.getInitialState({
      error: null,
      active: null,
      amount: null,
      isLoaded: false,
      isLoading: false,
      paymentMode: null,
    }),
  );
