import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { OrderStatusLabels } from '@config/labels/order.labels';
import { INotification } from '../../models/notification.model';

@Injectable()
export class NotificationService {
  private subject = new Subject<INotification>();
  private idx = 0;
  private handleNotifications = true;

  constructor() {}

  getObservable(): Observable<INotification> {
    return this.subject.asObservable();
  }

  displayOrderStatusUpdate(
    firstName = '',
    shopName = '',
    orderStatus: keyof typeof OrderStatusLabels,
    timeout = 5000,
  ): Subject<any> {
    const clickSubject = new Subject<boolean>();

    if (!this.handleNotifications) {
      return clickSubject;
    }

    this.subject.next({
      id: this.idx++,
      data: { firstName, shopName, orderStatus },
      timeout,
      key: 'orderStatus',
      click: clickSubject,
    });

    return clickSubject;
  }

  displayMessage(message: string, timeout = 5000, styles = ''): Subject<any> {
    const clickSubject = new Subject<boolean>();

    if (!this.handleNotifications) {
      return clickSubject;
    }

    this.subject.next({
      id: this.idx++,
      data: {
        message,
        styles,
      },
      timeout,
      key: 'message',
      click: clickSubject,
    });

    return clickSubject;
  }

  setHandleNotifications(handle: boolean): void {
    this.handleNotifications = handle;
  }
}
