import { computed, Injectable, signal } from '@angular/core';
import { debounceTime, distinctUntilChanged, map, Subject } from 'rxjs';
import { Step, Stepper } from './stepper.type';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StepInfo } from '@core/models/stepper.model';

export function provideRootStepperStore() {
  return [RootStepperStore];
}

@Injectable()
export class RootStepperStore {
  // todo check if it is possible to calculate the correct chidlStepper from here ?
  private readonly rootProductId = signal<string | undefined>(undefined);

  protected readonly childStepper = computed(() => {
    // todo change to protected
    const rootProductId = this.rootProductId();
    // reset to undefined each time the product change (It will reset the stepper)
    if (!rootProductId) {
      return undefined;
    }

    return signal<Stepper | undefined>(undefined);
  });

  public setChildStepper(childStepper: Stepper | undefined) {
    this.childStepper()?.set(childStepper);
  }

  private readonly steps = computed(() => {
    // todo improve that
    const rootProductId = this.rootProductId();
    // reset to undefined each time the product change (It will reset the stepper)
    if (!rootProductId) {
      return undefined;
    }

    return signal<Step[]>([]);
  });

  public setSteppers(steps: Step[]) {
    this.steps()?.set(steps);
  }

  public readonly next$ = new Subject<void>();
  public readonly previous$ = new Subject<void>();

  private readonly nextStepper$ = this.next$.pipe(map(() => this.findNextStepper()?.step));
  private readonly previousStepper$ = this.previous$.pipe(
    map(() => this.stepperWithThePreviousStep()?.step),
  );

  private readonly _nextStep$ = new Subject<{
    id: string | undefined;
  }>();

  private readonly nextStepDistinct$ = this._nextStep$.pipe(
    // todo check to remove
    distinctUntilChanged((a, b) => a.id === b.id),
  );

  public setRootProductId(rootProductId: string | undefined) {
    this.rootProductId.set(rootProductId);
  }

  // todo merge currentStepper & stepperWithNext & previousStepper with appropriate value that handle next/previous
  private readonly currentStepper = computed(() => {
    const stepper = this.childStepper()?.();
    if (!stepper) {
      return;
    }
    let lastRecursiveStepper = stepper;
    while (lastRecursiveStepper.childStepper) {
      lastRecursiveStepper = lastRecursiveStepper.childStepper;
    }
    return lastRecursiveStepper;
  });

  private readonly stepperWithTheNextStep = computed(() => {
    return this.findNextStepper();
  });

  private readonly stepperWithThePreviousStep = computed(() => {
    const stepper = this.childStepper()?.();
    if (!stepper) {
      return;
    }
    let lastStepperWithPreviousStep = stepper.step.hasPreviousStep ? stepper : undefined;
    let recursiveStepper = stepper;

    while (recursiveStepper.childStepper) {
      recursiveStepper = recursiveStepper.childStepper;
      if (recursiveStepper.step.hasPreviousStep) {
        lastStepperWithPreviousStep = recursiveStepper;
      }
    }
    return lastStepperWithPreviousStep;
  });

  public readonly selectors = {
    currentStepper: this.currentStepper,
    stepperWithThePreviousStep: this.stepperWithThePreviousStep,
    stepperWithTheNextStep: this.stepperWithTheNextStep,
  };

  constructor() {
    // effect(() => console.log('currentStepper', this.currentStepper()));
    // effect(() => console.log('stepperWithThePreviousStep', this.stepperWithThePreviousStep()));
    // effect(() => console.log('stepperWithTheNextStep', this.stepperWithTheNextStep()));
    this.nextStepper$.pipe(debounceTime(300), takeUntilDestroyed()).subscribe(nextStepper => {
      const nextStep = nextStepper?.nextStep;
      if (!nextStep) {
        return;
      }
      nextStepper.setSelectedStep(nextStep);
    });
    this.previousStepper$
      .pipe(debounceTime(300), takeUntilDestroyed())
      .subscribe(previousStepper => {
        const previousStep = previousStepper?.previousStep;

        if (!previousStep) {
          return;
        }
        previousStepper.setSelectedStep(previousStep);
      });

    this.nextStepDistinct$.pipe(takeUntilDestroyed()).subscribe(() => {
      // todo check id still needed
      const nextStepper = this.findNextStepper()?.step;
      const nextStep = nextStepper?.nextStep;
      if (!nextStep) {
        return;
      }
      nextStepper.setSelectedStep(nextStep);
    });
  }

  public setSelectedStepId(data: StepInfo) {
    const childStepper = this.childStepper()?.();
    if (!childStepper) {
      return;
    }
    childStepper.step.setSelectedStep(data);
  }

  private findNextStepper() {
    const stepper = this.childStepper()?.();
    if (!stepper) {
      return;
    }
    let lastStepperWithNextStep = stepper;
    let recursiveStepper = stepper;

    while (recursiveStepper.childStepper) {
      recursiveStepper = recursiveStepper.childStepper;
      if (recursiveStepper.step.hasNextStep) {
        lastStepperWithNextStep = recursiveStepper;
      }
    }
    return lastStepperWithNextStep;
  }
}
