import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { of, throwError } from 'rxjs';
import { catchError, map, take, takeUntil, tap } from 'rxjs/operators';

import { PlaceResult } from '@core/models/google-maps.model';
import { ILocationParameter } from '@core/models/parameters.model';
import { DoodDeliveryAddressModel } from '@core/models/delivery-address.model';

import { LocationUtils } from '@shared/utils/location/location.utils';
import { DeliveryStoreSelector } from '@common/selectors/delivery.selector';
import { DestroyerBase } from 'src/app/core/base/destroyer/destroyer.base';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';

import { ModalsService } from '@core/services/modals/modals.service';
import { GoogleApiService } from '@core/services/api/google/google-api.service';
import { DeliveryAddressService } from '@core/services/delivery-address/delivery-address.service';
import { ShopSearchParametersService } from '@core/services/shop-search-parameters/shop-search-parameters.service';

import { InputTextAtomComponent } from '@shared/atoms/input-text-atom/input-text-atom.component';
import { DeliveryAddressesModalComponent } from '@shared/modals/delivery-addresses-modal/delivery-addresses-modal.component';

@Component({
  selector: 'app-parameters-location-block',
  templateUrl: './parameters-location-block.component.html',
})
export class ParametersLocationBlockComponent
  extends DestroyerBase
  implements OnInit, AfterViewInit
{
  @ViewChild(InputTextAtomComponent) input!: InputTextAtomComponent;

  @Input() selectedValue!: string;

  @Input() selectedCommentValue?: string | null;

  @Output() placeChanged = new EventEmitter<Partial<DoodDeliveryAddressModel>>();

  displayedValue?: string;

  displayedCommentValue?: string | null;

  geolocalizeError?: string;

  addressLoading = false;

  addresses!: DoodDeliveryAddressModel[];

  protected readonly firebaseToken = this.authSelector.status.token;

  constructor(
    private authSelector: AuthStoreSelector,
    private changeDetector: ChangeDetectorRef,
    private readonly modalsService: ModalsService,
    private deliverySelector: DeliveryStoreSelector,
    private readonly googleApiService: GoogleApiService,
    private readonly deliveryAddressService: DeliveryAddressService,
    private readonly shopSearchParametersService: ShopSearchParametersService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.displayedValue = this.selectedValue;
    this.displayedCommentValue = this.selectedCommentValue;
    if (this.firebaseToken) {
      this.addressLoading = true;
      this.getDeliveryAddresses();
    }

    this.deliverySelector.selectActive
      .pipe(
        takeUntil(this._destroyerRef),
        tap(newLocation => {
          if (!newLocation) {
            return;
          }
          this.displayedValue = LocationUtils.constructDisplayedDeliveryAddress(newLocation);
          this.onPlaceChanged(newLocation);
        }),
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.loadAddressSearchInput();
  }

  geolocalizeUser(): void {
    this.googleApiService
      .geolocateUser$()
      .pipe(
        take(1),
        map(place => this.deliveryAddressService.placeResultToDeliveryAddress(place)),
        tap(location => this.onPlaceChanged(location)),
        catchError(error => {
          this.geolocalizeError = error;
          this.input.clearValue();
          return of(error);
        }),
      )
      .subscribe({
        error: err => {
          this.geolocalizeError = err;
        },
      });
  }

  loadAddressSearchInput(): void {
    this.changeDetector?.detectChanges();
    const input = this.input?.addressSearchInput.nativeElement;
    this.googleApiService
      .getAutoCompleteAddress(input)
      .pipe(
        take(1),
        tap(place => this.setLocationToParameters(place)),
        map(place => this.deliveryAddressService.placeResultToDeliveryAddress(place)),
        tap(place => this.onPlaceChanged(place)),
      )
      .subscribe();
  }

  valueChanged($event: string): void {
    this.displayedValue = $event;
  }

  openAddressesPanel(): void {
    const firebaseToken = this.authSelector.status.token;
    if (!firebaseToken) {
      this.displayedValue = undefined;
      this.placeChanged.emit(undefined);
      this.loadAddressSearchInput();
      return;
    }

    this.modalsService.open(DeliveryAddressesModalComponent.handle);
  }

  private getDeliveryAddresses(): void {
    this.deliveryAddressService
      .getDeliveryAddresses$()
      .pipe(
        take(1),
        catchError(err => {
          this.addressLoading = false;
          return throwError(() => err);
        }),
      )
      .subscribe(addresses => {
        this.addresses = addresses;
        this.addressLoading = false;
        if (!addresses.length) {
          this.loadAddressSearchInput();
        }

        if (!this.displayedValue) {
          const defaultAddress = addresses.find(address => address.is_default);
          if (defaultAddress) {
            this.displayedValue = LocationUtils.constructDisplayedDeliveryAddress(defaultAddress);
            this.placeChanged.emit(defaultAddress);
          }
        }
      });
  }

  private onPlaceChanged(location: Partial<DoodDeliveryAddressModel> | undefined): void {
    this.displayedValue =
      LocationUtils.mapDeliveryAddressToLocation(location as DoodDeliveryAddressModel)?.address ||
      '';
    this.geolocalizeError = undefined;
    this.displayedCommentValue = location?.instructions;
    this.placeChanged.emit(location);
  }

  private setLocationToParameters(place: PlaceResult): void {
    let location: ILocationParameter | undefined;
    if (!place) {
      return;
    }

    location = {
      lat: place.geometry?.location.lat(),
      lng: place.geometry?.location.lng(),
      address: place.formatted_address,
      address_components: place.address_components,
    };

    this.geolocalizeError = undefined;
    this.shopSearchParametersService.setLocation(location);
  }
}
