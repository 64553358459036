import { IPageConfig } from '@core/models/marketplace.model';

export const DEFAULT_PAGES: Array<IPageConfig> = [
  {
    path: '',
    block: 'main-layout',
    children: [
      {
        path: '',
        redirect_to: 'shops',
      },
      {
        path: 'shops',
        block: 'shop-list-page',
      },
      {
        path: 'map',
        block: 'map-page',
      },
      {
        path: 'from-qr',
        block: 'from-qr-page',
      },
      {
        path: 'cart',
        block: 'cart-page',
        guards: ['AuthGuard'],
        children: [
          {
            path: 'items/:itemId',
            block: 'cart-item-edit-page',
          },
          {
            path: 'products/:shopId/:productId',
            block: 'product-add-page',
          },
        ],
      },
      {
        path: 'cart/funnel',
        block: 'cart-funnel-page',
        guards: ['AuthGuard'],
      },
      {
        path: 'validate/shared-order',
        block: 'validate-shared-order-page',
        guards: ['AuthGuard'],
      },
      {
        path: 'payment',
        block: 'payment-page',
        guards: ['AuthGuard', 'OrderValidateGuard'],
      },
      {
        path: 'payment-confirm',
        block: 'payment-confirm-page',
        guards: ['AuthGuard'],
      },
      {
        path: 'group-payment',
        block: 'group-payment-page',
        guards: ['AuthGuard', 'OrderValidateGuard'],
      },
      {
        path: 'orders/:orderId/status',
        block: 'order-status-page',
        guards: ['AuthGuard'],
      },
      {
        path: 'orders/:orderId/details',
        block: 'order-details-page',
        guards: ['AuthGuard'],
      },
      {
        path: 'account',
        block: 'account-page',
        guards: ['AuthGuard'],
      },
      {
        path: 'orders',
        block: 'orders-page',
        guards: ['AuthGuard'],
      },
      {
        path: 'checks',
        block: 'checks-page',
        guards: ['AuthGuard'],
      },
      {
        path: 'checks/:checkId/details',
        block: 'check-details-page',
        guards: ['AuthGuard'],
      },
      {
        path: 'pay-at-table',
        block: 'pay-at-table-page',
        children: [
          {
            path: '',
            redirect_to: 'overview',
          },
          {
            path: 'overview',
            block: 'pay-at-table-overview-page',
            guards: ['AuthGuard'],
          },
          {
            path: 'choose-amount',
            block: 'pay-at-table-choose-amount-page',
            guards: ['AuthGuard', 'PatCheckGuard'],
          },
          {
            path: 'payment',
            block: 'pay-at-table-payment-page',
            guards: ['AuthGuard', 'PatCheckGuard'],
          },
          {
            path: 'payment-confirmation/:id',
            block: 'pay-at-table-payment-confirmation-page',
            guards: ['AuthGuard', 'PatCheckGuard'],
          },
        ],
      },
      {
        path: ':shopCity/:shopType/:shopSlug',
        block: 'shop-page',
        children: [
          {
            path: 'products/:shopId/:productId',
            block: 'product-add-page',
          },
        ],
      },
    ],
  },
  {
    path: ':shopCity/:shopType/:shopSlug/menu',
    block: 'simple-layout',
    children: [
      {
        path: '',
        block: 'shop-digital-menu-page',
        children: [
          {
            path: 'products/:shopId/:productId',
            block: 'digital-menu-product-page',
          },
        ],
      },
    ],
  },
  {
    path: 'debug/network-connection',
    block: 'debug-network-connection-page',
  },
];
