import { ReducerTypes, ActionCreator, on } from '@ngrx/store';

import { MODAL_STORE_LOCAL_ACTIONS } from './modal.action';
import { ModalState, MODAL_STORE_ADAPTER, MODAL_STORE_INITIAL_STATE } from './modal.state';

export const MODAL_STORE_API_PIECE: ReducerTypes<ModalState, readonly ActionCreator[]>[] = [];

export const MODAL_STORE_LOCAL_PIECE: ReducerTypes<ModalState, readonly ActionCreator[]>[] = [
  // Multiple
  on(MODAL_STORE_LOCAL_ACTIONS.addModals, (state, { modals }): ModalState => {
    return MODAL_STORE_ADAPTER.addMany(modals, state);
  }),
  on(MODAL_STORE_LOCAL_ACTIONS.setModals, (state, { modals }): ModalState => {
    return MODAL_STORE_ADAPTER.setAll(modals, state);
  }),
  on(MODAL_STORE_LOCAL_ACTIONS.removeModals, (state, { ids }): ModalState => {
    return MODAL_STORE_ADAPTER.removeMany(ids, state);
  }),
  on(MODAL_STORE_LOCAL_ACTIONS.upsertModals, (state, { modals }): ModalState => {
    return MODAL_STORE_ADAPTER.upsertMany(modals, state);
  }),
  on(MODAL_STORE_LOCAL_ACTIONS.resetModals, (state): ModalState => {
    return MODAL_STORE_ADAPTER.removeAll(state);
  }),

  // Single
  on(MODAL_STORE_LOCAL_ACTIONS.addModal, (state, { modal }): ModalState => {
    return MODAL_STORE_ADAPTER.addOne(modal, state);
  }),
  on(MODAL_STORE_LOCAL_ACTIONS.updateModal, (state, { modal }): ModalState => {
    return MODAL_STORE_ADAPTER.updateOne(modal, state);
  }),
  on(MODAL_STORE_LOCAL_ACTIONS.removeModal, (state, { id }): ModalState => {
    return MODAL_STORE_ADAPTER.removeOne(id, state);
  }),
  on(MODAL_STORE_LOCAL_ACTIONS.upsertModal, (state, { modal }): ModalState => {
    return MODAL_STORE_ADAPTER.upsertOne(modal, state);
  }),
  on(MODAL_STORE_LOCAL_ACTIONS.mapModal, (state, { modal }): ModalState => {
    return MODAL_STORE_ADAPTER.mapOne(modal, state);
  }),

  // Misc
  on(MODAL_STORE_LOCAL_ACTIONS.reset, (): ModalState => MODAL_STORE_INITIAL_STATE),
];
