import { createActiveAdapter } from '@store/_abstract/active';

import { FunnelState } from '@store/_abstract/funnel/funnel.state';
import { createFunnelAdapter } from '@store/_abstract/funnel/funnel.adapter';

import { DoodCartFunnelStep, DoodCartFunnelValue } from './cart-funnel.model';

export const CART_FUNNEL_STORE_KEY = 'CartFunnel';

export interface CartFunnelState
  extends FunnelState<DoodCartFunnelStep, DoodCartFunnelValue, string> {
  orderComment: string | null;
  funnelUsername: string | null;
}

export const CART_FUNNEL_STORE_ACTIVE_ADAPTER = createActiveAdapter<DoodCartFunnelStep, string>({
  sortComparer: (_prev, _next) => _prev.index - _next.index,
});
export const CART_FUNNEL_STORE_FUNNEL_ADAPTER = createFunnelAdapter<
  DoodCartFunnelStep,
  DoodCartFunnelValue,
  string
>();

export const CART_FUNNEL_STORE_INITIAL_STATE: CartFunnelState =
  CART_FUNNEL_STORE_FUNNEL_ADAPTER.getInitialState<CartFunnelState>({
    orderComment: null,
    funnelUsername: null,
  });
