/* tslint:disable:max-line-length */
import { GenericPageComponent } from '@shared/pages/generic-page/generic-page.component';
import { ShopPageComponent } from '@shared/pages/shop-page/shop-page.component';
import { HeroBlockComponent } from '@shared/blocks/hero-block/hero-block.component';
import { ShopHeroSimpleBlockComponent } from '@shared/blocks/shop-hero-simple-block/shop-hero-simple-block.component';
import { HeaderBlockComponent } from '@shared/blocks/header-block/header-block.component';
import { HeadingContentBlockComponent } from '@shared/blocks/heading-content-block/heading-content-block.component';
import { ShopCategoryTabsBlockComponent } from '@shared/blocks/shop-category-tabs-block/shop-category-tabs-block.component';
import { ShopSmallOverviewListBlockComponent } from '@shared/blocks/shop-small-overview-list-block/shop-small-overview-list-block.component';
import { ShopSmallOverviewGridBlockComponent } from '@shared/blocks/shop-small-overview-grid-block/shop-small-overview-grid-block.component';
import { ShopProductListBlockComponent } from '@shared/blocks/shop-product-list-block/shop-product-list-block.component';
import { ShopsCustomMapPageComponent } from '@shared/pages/shops-custom-map-page/shops-custom-map-page.component';
import { CartPageComponent } from '@shared/pages/cart-page/cart-page.component';
import { CartHeaderBlockComponent } from '@shared/blocks/cart-header-block/cart-header-block.component';
import { CartItemListBlockComponent } from '@shared/blocks/cart-item-list-block/cart-item-list-block.component';
import { OrderStatusPageComponent } from '@shared/pages/order-status-page/order-status-page.component';
import { OrderStatusBlockComponent } from '@shared/blocks/order-status-block/order-status-block.component';
import { SocialNetworksFollowUsBlockComponent } from '@shared/blocks/social-networks-follow-us-block/social-networks-follow-us-block.component';
import { CartOverviewModalComponent } from '@shared/modals/cart-overview-modal/cart-overview-modal.component';
import { SidebarNavigationModalComponent } from '@shared/modals/sidebar-navigation-modal/sidebar-navigation-modal.component';
import { AuthenticationModalComponent } from '@shared/modals/authentication-modal/authentication-modal.component';
import { FavoritesPageComponent } from '@shared/pages/favorites-page/favorites-page.component';
import { OrdersPageComponent } from '@shared/pages/orders-page/orders-page.component';
import { AccountPageComponent } from '@shared/pages/account-page/account-page.component';
import { AccountPageBlockComponent } from '@shared/blocks/account-page-block/account-page-block.component';
import { OrdersPageBlockComponent } from '@shared/blocks/orders-page-block/orders-page-block.component';
import { FavoritesPageBlockComponent } from '@shared/blocks/favorites-page-block/favorites-page-block.component';
import { CartFooterModalComponent } from '@shared/modals/cart-footer-modal/cart-footer-modal.component';
import { PaymentPageComponent } from '@shared/pages/payment-page/payment-page.component';
import { PaymentPageHeaderBlockComponent } from '@shared/blocks/payment-page-header-block/payment-page-header-block.component';
import { ProductAddModalComponent } from '@shared/modals/product-add-modal/product-add-modal.component';
import { ProductAddPageComponent } from '@shared/pages/product-add-page/product-add-page.component';
import { FoodSocietyHeroBlockComponent } from '@shared/blocks/food-society/food-society-hero-block/food-society-hero-block.component';
import { CartMerchantAmountBlockComponent } from '@shared/blocks/cart-merchant-amount-block/cart-merchant-amount-block.component';
import { ItemEditModalComponent } from '@shared/modals/item-edit-modal/item-edit-modal.component';
import { ItemEditPageComponent } from '@shared/pages/item-edit-page/item-edit-page.component';
import { MosaicBlockComponent } from '@shared/blocks/mosaic-block/mosaic-block.component';
import { FoodSocietyFatButtonBlockComponent } from '@shared/blocks/food-society/food-society-fat-button-block/food-society-fat-button-block.component';
import { FoodSocietyShopListBlockComponent } from '@shared/blocks/food-society/food-society-shop-list-block/food-society-shop-list-block.component';
import { PaymentMethodPickerBlockComponent } from '@shared/blocks/payment-method-picker-block/payment-method-picker-block.component';
import { CartVoucherModalComponent } from '@shared/modals/cart-voucher-modal/cart-voucher-modal.component';
import { MarketplaceProductsBlockComponent } from '@shared/blocks/marketplace-products-block/marketplace-products-block.component';
import { OrderDetailsPageComponent } from '@shared/pages/order-details-page/order-details-page.component';
import { OrderDetailsBlockComponent } from '@shared/blocks/order-details-block/order-details-block.component';
import { OrderDetailsPageHeaderComponent } from '@shared/blocks/order-details-page-header/order-details-page-header.component';
import { ShopHeaderNavigationBlockComponent } from '@shared/blocks/shop-header-navigation-block/shop-header-navigation-block.component';
import { OrderStatusPushItemBlockComponent } from '@shared/blocks/order-status-push-item-block/order-status-push-item-block.component';
import { MarketplaceProductsPageComponent } from '@shared/pages/marketplace-products-page/marketplace-products-page.component';
import { TextBlockComponent } from '@shared/blocks/text-block/text-block.component';
import { CartSuggestionsBlockComponent } from '@shared/blocks/cart-suggestions-block/cart-suggestions-block.component';
import { AuthenticationBlockComponent } from '@shared/blocks/authentication-block/authentication-block.component';
import { AddHomeScreenModalComponent } from '@shared/modals/add-home-screen-modal/add-home-screen-modal.component';
import { MarketplaceProductsPreloaderBlockComponent } from '@shared/blocks/marketplace-products-preloader-block/marketplace-products-preloader-block.component';
import { MarketplaceHeroBlockComponent } from '@shared/blocks/marketplace-hero-block/marketplace-hero-block.component';
import { ShopTypePickerBlockComponent } from '@shared/blocks/shop-type-picker-block/shop-type-picker-block.component';
import { ShopSearchPageComponent } from '@shared/pages/shop-search-page/shop-search-page.component';
import { ShopAroundListBlockComponent } from '@shared/blocks/shop-around-list-block/shop-around-list-block.component';
import { ShopAroundListHeaderBlockComponent } from '@shared/blocks/shop-around-list-header-block/shop-around-list-header-block.component';
import { ShopAroundListBodyBlockComponent } from '@shared/blocks/shop-around-list-body-block/shop-around-list-body-block.component';
import { ShopSearchParametersModalComponent } from '@shared/modals/shop-search-parameters-modal/shop-search-parameters-modal.component';
import { ParametersWantedAtBlockComponent } from '@shared/blocks/parameters/parameters-wanted-at-block/parameters-wanted-at-block.component';
import { FiltersModalComponent } from '@shared/modals/filters-modal/filters-modal.component';
import { ShopsMapPageComponent } from '@shared/pages/shops-map-page/shops-map-page.component';
import { ShopHeroImageBlockComponent } from '@shared/blocks/shop-hero-image-block/shop-hero-image-block.component';
import { CompoundItemModalComponent } from '@shared/modals/compound-item-modal/compound-item-modal.component';
import { ParametersLocationBlockComponent } from '@shared/blocks/parameters/parameters-location-block/parameters-location-block.component';
import { ParametersDistributionModeBlockComponent } from '@shared/blocks/parameters/parameters-distribution-mode-block/parameters-distribution-mode-block.component';
import { CartParametersModalComponent } from '@shared/modals/cart-parameters-modal/cart-parameters-modal.component';
import { ComoHomeMosaicBlockComponent } from '@shared/blocks/como/como-home-mosaic-block/como-home-mosaic-block.component';
import { CartAdditionalInformationBlockComponent } from '@shared/blocks/cart-additional-information-block/cart-additional-information-block.component';
import { CartCommentModalComponent } from '@shared/modals/cart-comment-modal/cart-comment-modal.component';
import { ComoAssetListBlockComponent } from '@shared/blocks/como/como-asset-list-block/como-asset-list-block.component';
import { CartVoucherBlockComponent } from '@shared/blocks/cart-voucher-block/cart-voucher-block.component';
import { ComoCreditBalanceCardBlockComponent } from '@shared/blocks/como/como-credit-balance-card-block/como-credit-balance-card-block.component';
import { ComoUseCreditBalanceModalComponent } from '@shared/modals/como/como-use-credit-balance-modal/como-use-credit-balance-modal.component';
import { CartUserInformationsModalComponent } from '@shared/modals/cart-user-informations-modal/cart-user-informations-modal.component';
import { ShopsMapBlockComponent } from '@shared/blocks/shops-map-block/shops-map-block.component';
import { MosaicCardBlockComponent } from '@shared/blocks/mosaic-card-block/mosaic-card-block.component';
import { ComoJoinLoyaltyBlockComponent } from '@shared/blocks/como/como-join-loyalty-block/como-join-loyalty-block.component';
import { ShopCardsBlockComponent } from '@shared/blocks/shop-cards-block/shop-cards-block.component';
import { ShopCardBlockComponent } from '@shared/blocks/shop-card-block/shop-card-block.component';
import { ShopSearchParametersModalLauncherBlockComponent } from '@shared/blocks/modal-launchers/shop-search-parameters-modal-launcher-block/shop-search-parameters-modal-launcher-block.component';
import { CartParametersModalLauncherBlockComponent } from '@shared/blocks/modal-launchers/cart-parameters-modal-launcher-block/cart-parameters-modal-launcher-block.component';
import { ShopInformationsModalComponent } from '@shared/modals/shop-informations-modal/shop-informations-modal.component';
import { HeaderButtonBlockComponent } from '@shared/blocks/header-button-block/header-button-block.component';
import { CartOnSiteLocationBlockComponent } from '@shared/blocks/cart-on-site-location-block/cart-on-site-location-block.component';
import { BigFooterBlockComponent } from '@shared/blocks/footer/big-footer/big-footer-block/big-footer-block.component';
import { BigFooterBodyDownloadAppsBlockComponent } from '@shared/blocks/footer/big-footer/big-footer-body-download-apps-block/big-footer-body-download-apps-block.component';
import { BigFooterBodySocialNetworksBlockComponent } from '@shared/blocks/footer/big-footer/big-footer-body-social-networks-block/big-footer-body-social-networks-block.component';
import { BigFooterColumnBlockComponent } from '@shared/blocks/footer/big-footer/big-footer-column-block/big-footer-column-block.component';
import { BigFooterColumnItemBlockComponent } from '@shared/blocks/footer/big-footer/big-footer-column-item-block/big-footer-column-item-block.component';
import { LegalsFooterBlockComponent } from '@shared/blocks/footer/legals-footer/legals-footer-block/legals-footer-block.component';
import { LegalsFooterLinkItemBlockComponent } from '@shared/blocks/footer/legals-footer/legals-footer-link-block/legals-footer-link-item-block.component';
import { ImageFullWidthBlockComponent } from '@shared/blocks/image-full-width-block/image-full-width-block.component';
import { ImageTwoColsBlockComponent } from '@shared/blocks/image-two-cols-block/image-two-cols-block.component';
import { SocialLinksBlockComponent } from '@shared/blocks/social-links-block/social-links-block.component';
import { YoutubePlayerBlockComponent } from '@shared/blocks/youtube-player-block/youtube-player-block.component';
import { VimeoPlayerBlockComponent } from '@shared/blocks/vimeo-player-block/vimeo-player-block.component';
import { ImageAndTextBlockComponent } from '@shared/blocks/image-and-text-block/image-and-text-block.component';
import { HeaderBigLogoBlockComponent } from '@shared/blocks/header-big-logo-block/header-big-logo-block.component';
import { BigPushBlockComponent } from '@shared/blocks/big-push-block/big-push-block.component';
import { PushRowBlockComponent } from '@shared/blocks/push-row-block/push-row-block.component';
import { MainLayoutComponent } from '@shared/layouts/main-layout/main-layout.component';
import { RedirectPageComponent } from '@shared/pages/redirect-page/redirect-page.component';
import { SidebarNavigationModalItemBlockComponent } from '@shared/blocks/sidebar-navigation-modal-item-block/sidebar-navigation-modal-item-block.component';
import { NavigateToMapButtonModalComponent } from '@shared/modals/navigate-to-map-button-modal/navigate-to-map-button-modal.component';
import { AnchorBlockComponent } from '@shared/components/anchor-block/anchor-block.component';
import { AnchorButtonsListBlockComponent } from '@shared/blocks/anchor-buttons-list-block/anchor-buttons-list-block.component';
import { ShopCapabilitiesModalComponent } from '@shared/modals/shop-capabilities-modal/shop-capabilities-modal.component';
import { HeaderLinkBlockComponent } from '@shared/blocks/header-link-block/header-link-block.component';
import { HomeHeroShowcaseBlockComponent } from '@shared/blocks/home-hero-showcase-block/home-hero-showcase-block.component';
import { SlideShowImageBlockComponent } from '@shared/blocks/slide-show-image-block/slide-show-image-block.component';
import { ImageOfSlideShowBlockComponent } from '@shared/blocks/image-of-slide-show-block/image-of-slide-show-block.component';
import { ImageAndCallToActionsBlockComponent } from '@shared/blocks/image-and-call-to-actions-block/image-and-call-to-actions-block.component';
import { ButtonBlockComponent } from '@shared/blocks/button-block/button-block.component';
import { InformationsContainerBlockComponent } from '@shared/blocks/informations-container-block/informations-container-block.component';
import { InformationsColumnBlockComponent } from '@shared/blocks/informations-column-block/informations-column-block.component';
import { InformationsRowBlockComponent } from '@shared/blocks/informations-row-block/informations-row-block.component';
import { MapLocationBlockComponent } from '@shared/blocks/map-location-block/map-location-block.component';
import { BigFooterBodyLogoBlockComponent } from '@shared/blocks/footer/big-footer/big-footer-body-logo-block/big-footer-body-logo-block.component';
import { BigFooterBodyCallToActionBlockComponent } from '@shared/blocks/footer/big-footer/big-footer-body-call-to-action-block/big-footer-body-call-to-action-block.component';
import { HeaderAnchorListBlockComponent } from '@shared/blocks/header-anchor-list-block/header-anchor-list-block.component';
import { HeaderSocialItemBlockComponent } from '@shared/blocks/header-social-item-block/header-social-item-block.component';
import { MarketplaceHeroSlideImageBlockComponent } from '@shared/blocks/marketplace-hero-slide-image-block/marketplace-hero-slide-image-block.component';
import { MarketplaceHeroSlideTextBlockComponent } from '@shared/blocks/marketplace-hero-slide-text-block/marketplace-hero-slide-text-block.component';
import { SuggestionCardsBlockComponent } from '@shared/blocks/suggestion-cards-block/suggestion-cards-block.component';
import { ShopsCustomMapBlockComponent } from '@shared/blocks/shops-custom-map-block/shops-custom-map-block.component';
import { CartSuggestionCardsBlockComponent } from '@shared/blocks/cart-suggestion-cards-block/cart-suggestion-cards-block.component';
import { CartButtonBlockComponent } from '@shared/blocks/cart-button-block/cart-button-block.component';
import { MosaicComoCreateAccountCardBlockComponent } from '@shared/blocks/mosaic-como-create-account-card-block/mosaic-como-create-account-card-block.component';
import { MosaicComoLinkAccountCardBlockComponent } from '@shared/blocks/mosaic-como-link-account-card-block/mosaic-como-link-account-card-block.component';
import { ComoCreateAccountModalComponent } from '@shared/modals/como/como-create-account-modal/como-create-account-modal.component';
import { ComoLinkAccountModalComponent } from '@shared/modals/como/como-link-account-modal/como-link-account-modal.component';
import { OrderStatusSuccessHeaderBlockComponent } from '@shared/blocks/order-status-success-header-block/order-status-success-header-block.component';
import { OrderStatusFollowOrderCardBlockComponent } from '@shared/blocks/order-status-follow-order-card-block/order-status-follow-order-card-block.component';
import { OrderStatusMessageCardBlockComponent } from '@shared/blocks/order-status-message-card-block/order-status-message-card-block.component';
import { ShopDigitalMenuPageComponent } from '@shared/pages/shop-digital-menu-page/shop-digital-menu-page.component';
import { InformationsTextBlockComponent } from '@shared/blocks/informations-text-block/informations-text-block.component';
import { FsHeroBlockComponent } from '@shared/blocks/food-society/fs-hero-block/fs-hero-block.component';
import { SocialLinkBlockComponent } from '@shared/blocks/social-link-block/social-link-block.component';
import { PageHeroBlockComponent } from '@shared/blocks/page-hero-block/page-hero-block.component';
import { HighlightCardBlockComponent } from '@shared/blocks/highlight-card-block/highlight-card-block.component';
import { ShopCardWithLogoBlockComponent } from '@shared/blocks/shop-card-with-logo-block/shop-card-with-logo-block.component';
import { ShopSmallHeroBlockComponent } from '@shared/blocks/shop-small-hero-block/shop-small-hero-block.component';
import { StripeTerminalReaderPickerModalComponent } from '@shared/modals/stripe-terminal-reader-picker-modal/stripe-terminal-reader-picker-modal.component';
import { KioskOrderStatusBlockComponent } from '@shared/blocks/kiosk-order-status-block/kiosk-order-status-block.component';
import { TimerRedirectBlockComponent } from '@shared/blocks/timer-redirect-block/timer-redirect-block.component';
import { SpacerBlockComponent } from '@shared/blocks/spacer-block/spacer-block.component';
import { KioskCartResetTimerBlockComponent } from '@shared/blocks/kiosk-cart-reset-timer-block/kiosk-cart-reset-timer-block.component';
import { KioskCartResetTimerModalComponent } from '@shared/modals/kiosk-cart-reset-timer-modal/kiosk-cart-reset-timer-modal.component';
import { WaitingModalComponent } from '@shared/modals/waiting-modal/waiting-modal.component';
import { ShareGroupedOrderModalComponent } from '@shared/modals/share-grouped-order-modal/share-grouped-order-modal.component';
import { ValidateSharedOrderPageComponent } from '@shared/pages/validate-shared-order-page/validate-shared-order-page.component';
import { ValidateSharedOrderHeaderBlockComponent } from '@shared/blocks/validate-shared-order-header-block/validate-shared-order-header-block.component';
import { ValidateSharedOrderBodyBlockComponent } from '@shared/blocks/validate-shared-order-body-block/validate-shared-order-body-block.component';
import { AddPaymentModalComponent } from '@shared/modals/add-payment-modal/add-payment-modal.component';
import { GroupPaymentPageHeaderBlockComponent } from '@shared/blocks/group-payment-page-header-block/group-payment-page-header-block.component';
import { GroupPaymentListBlockComponent } from '@shared/blocks/group-payment-list-block/group-payment-list-block.component';
import { GroupPaymentPageComponent } from '@shared/pages/group-payment-page/group-payment-page.component';
import { ComoCreateOrLinkAccountModalComponent } from '@shared/modals/como/como-create-or-link-account-modal/como-create-or-link-account-modal.component';
import { ComoSsoLoadingModalComponent } from '@shared/modals/como/como-sso-loading-modal/como-sso-loading-modal.component';
import { ComoConfirmUsePointModalComponent } from '@shared/modals/como/como-confirm-use-point-modal/como-confirm-use-point-modal.component';
import { CartTipPickerBlockComponent } from '@shared/blocks/cart-tip-picker-block/cart-tip-picker-block.component';
import { CartTipPickerModalComponent } from '@shared/modals/cart-tip-picker-modal/cart-tip-picker-modal.component';
import { CarouselBlockComponent } from '@shared/blocks/carousel-block/carousel-block.component';
import { CarouselSlideCardBlockComponent } from '@shared/blocks/carousel-slide-card-block/carousel-slide-card-block.component';
import { ZerosixCreateOrLinkAccountModalComponent } from '@shared/modals/zerosix/zerosix-create-or-link-account-modal/zerosix-create-or-link-account-modal.component';
import { ZerosixCreateAccountModalComponent } from '@shared/modals/zerosix/zerosix-create-account-modal/zerosix-create-account-modal.component';
import { ZerosixLinkAccountModalComponent } from '@shared/modals/zerosix/zerosix-link-account-modal/zerosix-link-account-modal.component';
import { NestedPageBlockComponent } from '@shared/blocks/nested-page-block/nested-page-block.component';
import { ZerosixAssetListBlockComponent } from '@shared/blocks/zerosix/zerosix-asset-list-block/zerosix-asset-list-block.component';
import { ZerosixOfferedProductPickerModalComponent } from '@shared/modals/zerosix/zerosix-offered-product-picker-modal/zerosix-offered-product-picker-modal.component';
import { HtmlBlockComponent } from '@shared/blocks/html-block/html-block.component';
import { ScriptBlockComponent } from '@shared/blocks/script-block/script-block.component';
import { GenericModalComponent } from '@shared/modals/generic-modal/generic-modal.component';
import { DeliveryAddressesModalComponent } from '@shared/modals/delivery-addresses-modal/delivery-addresses-modal.component';
import { ModalButtonBlockComponent } from '@shared/blocks/modal-button-block/modal-button-block.component';
import { ModalHeaderBlockComponent } from '@shared/blocks/modal-header-block/modal-header-block.component';
import { LydiaPaymentPhoneNumberModalComponent } from '@shared/modals/lydia-payment-phone-number-modal/lydia-payment-phone-number-modal.component';
import { ComoPunchCardsBlockComponent } from '@shared/blocks/como/como-punch-cards-block/como-punch-cards-block.component';
import { DebugNetworkConnectionBlockComponent } from '@shared/blocks/debug/debug-network-connection-block/debug-network-connection-block.component';
import { GroupedOrderModalLauncherBlockComponent } from '@shared/blocks/modal-launchers/grouped-order-modal-launcher-block/grouped-order-modal-launcher-block.component';
import { CartFunnelPageComponent } from '@shared/pages/cart-funnel-page/cart-funnel-page.component';
import { CartFunnelFormBlockComponent } from '@shared/blocks/cart-funnel/cart-funnel-form-block/cart-funnel-form-block.component';
import { CartFunnelHeaderBlockComponent } from '@shared/blocks/cart-funnel/cart-funnel-header-block/cart-funnel-header-block.component';
import { CartFunnelRadioItemBlockComponent } from '@shared/blocks/cart-funnel/cart-funnel-radio-item-block/cart-funnel-radio-item-block.component';
import { CartFunnelTextItemBlockComponent } from '@shared/blocks/cart-funnel/cart-funnel-text-item-block/cart-funnel-text-item-block.component';
import { CartFunnelRadioOptionBlockComponent } from '@shared/blocks/cart-funnel/cart-funnel-radio-option-block/cart-funnel-radio-option-block.component';
import { HeaderDigitalMenuBlockComponent } from '@shared/blocks/header-digital-menu-block/header-digital-menu-block.component';
import { NoConnectionModalComponent } from '@shared/modals/no-connection-modal/no-connection-modal.component';
import { KioskPickerModalComponent } from '@shared/modals/kiosk-picker-modal/kiosk-picker-modal.component';
import { KioskPickerModalLauncherBlockComponent } from '@shared/blocks/modal-launchers/kiosk-picker-modal-launcher-block/kiosk-picker-modal-launcher-block.component';
import { CartFunnelOnBehalfUserPhoneItemBlockComponent } from '@shared/blocks/cart-funnel/cart-funnel-on-behalf-user-phone-item-block/cart-funnel-on-behalf-user-phone-item-block.component';
import { CartFunnelOnBehalfUserEmailItemBlockComponent } from '@shared/blocks/cart-funnel/cart-funnel-on-behalf-user-email-item-block/cart-funnel-on-behalf-user-email-item-block.component';
import { CartFunnelOnBehalfUserFullNameItemBlockComponent } from '@shared/blocks/cart-funnel/cart-funnel-on-behalf-user-full-name-item-block/cart-funnel-on-behalf-user-full-name-item-block.component';
import { PaymentConfirmPageComponent } from '@shared/pages/payment-confirm-page/payment-confirm-page.component';
import { BancontactPaymentFormModalComponent } from '@shared/modals/bancontact-payment-form-modal/bancontact-payment-form-modal.component';
import { CartFunnelCheckboxItemBlockComponent } from '@shared/blocks/cart-funnel/cart-funnel-checkbox-item-block/cart-funnel-checkbox-item-block.component';
import { CartFunnelOnBehalfUserOptinItemBlockComponent } from '@shared/blocks/cart-funnel/cart-funnel-on-behalf-user-optin-item-block/cart-funnel-on-behalf-user-optin-item-block.component';
import { ConfirmModalComponent } from '@shared/modals/confirm-modal/confirm-modal.component';
import { SplashScreenBlockComponent } from '@shared/blocks/splash-screen-block/splash-screen-block.component';
import { AlertModalComponent } from '@shared/modals/alert-modal/alert-modal.component';
import { ResetCartFunnelBlockComponent } from '@shared/blocks/reset-cart-funnel-block/reset-cart-funnel-block.component';
import { CookieConsentLinkItemBlockComponent } from '@shared/blocks/footer/legals-footer/cookie-consent-link-item-block/cookie-consent-link-item-block.component';
import { PongoCreateOrLinkAccountModalComponent } from '@shared/modals/pongo/pongo-create-or-link-account-modal/pongo-create-or-link-account-modal.component';
import { PadSplashScreenBlockComponent } from '@shared/blocks/pad-splash-screen-block/pad-splash-screen-block.component';
import { PongoAssetListBlockComponent } from '@shared/blocks/pongo/pongo-asset-list-block/pongo-asset-list-block.component';
import { AnonymousAuthenticationPreloaderBlockComponent } from '@shared/blocks/anonymous-authentication-preloader-block/anonymous-authentication-preloader-block.component';
import { PongoOfferedProductPickerModalComponent } from '@shared/modals/pongo/pongo-offered-product-picker-modal/pongo-offered-product-picker-modal.component';
import { ClassicButtonBlockComponent } from '@shared/blocks/classic-button-block/classic-button-block.component';
import { ImageLogoBlockComponent } from '@shared/blocks/image-logo-block/image-logo-block.component';
import { ImageVariableWidthBlockComponent } from '@shared/blocks/image-variable-width-block/image-variable-width-block.component';
import { ChecksPageComponent } from '@shared/pages/checks-page/checks-page.component';
import { ChecksPageBlockComponent } from '@shared/blocks/checks-page-block/checks-page-block.component';
import { CheckDetailsPageComponent } from '@shared/pages/check-details-page/check-details-page.component';
import { CheckDetailsBlockComponent } from '@shared/blocks/check-details-block/check-details-block.component';
import { CartPickupLocationBlockComponent } from '@shared/blocks/cart-pickup-location-block/cart-pickup-location-block.component';
import { ComoRegistrationModalComponent } from '@shared/modals/como/como-registration-modal/como-registration-modal.component';
import { ComoProductsSelectionModalComponent } from '@shared/modals/como/como-products-selection-modal/como-products-selection-modal.component';
import { PatPageComponent } from '@shared/pages/pat-page/pat-page.component';
import { PatChooseAmountPageComponent } from '@shared/pages/pat-choose-amount-page/pat-choose-amount-page.component';
import { PatPaymentPageComponent } from '@shared/pages/pat-payment-page/pat-payment-page.component';
import { PatPaymentConfirmationPageComponent } from '@shared/pages/pat-payment-confirmation-page/pat-payment-confirmation-page.component';
import { PatOverviewBlockComponent } from '@shared/blocks/pat-overview-block/pat-overview-block.component';
import { KioskSplashScreenPageComponent } from '@shared/pages/kiosk/kiosk-splash-screen-page/kiosk-splash-screen-page.component';
import { ExternalLinkButtonBlockComponent } from '@shared/blocks/external-link-button-block/external-link-button-block.component';
import { SplashScreenImageModalBlockComponent } from '@shared/blocks/splash-screen-image-modal-block/splash-screen-image-modal-block.component';
import { UserFeedbackBlockComponent } from '@shared/blocks/user-feedback-block/user-feedback-block.component';
import { UserFeedbackLinkBlockComponent } from '@shared/blocks/user-feedback-link-block/user-feedback-link-block.component';
import { PatDivideAmountModalComponent } from '@shared/modals/pat-divide-amount-modal/pat-divide-amount-modal.component';
import { PatFreeAmountModalComponent } from '@shared/modals/pat-free-amount-modal/pat-free-amount-modal.component';
import { PatSelectProductsModalComponent } from '@shared/modals/pat-select-products-modal/pat-select-products-modal.component';
import { ShareUserFeedbackModalComponent } from '@shared/modals/share-user-feedback-modal/share-user-feedback-modal.component';
import { SplashScreenImageModalComponent } from '@shared/modals/splash-screen-image-modal/splash-screen-image-modal.component';
import { ReceiveReceiptModalComponent } from '@shared/modals/receive-receipt-modal/receive-receipt-modal.component';
import { OrderReceiveReceiptBlockComponent } from '@shared/blocks/order-receive-receipt-block/order-receive-receipt-block.component';
import { KioskHeaderBlockComponent } from '@shared/blocks/kiosk/kiosk-header-block/kiosk-header-block.component';
import { KioskSelectLanguageBlockComponent } from '@shared/blocks/kiosk/kiosk-select-language-block/kiosk-select-language-block.component';
import { KioskShopCategoryListBlockComponent } from '@shared/blocks/kiosk/kiosk-shop-category-list-block/kiosk-shop-category-list-block.component';
import { KioskShopPageComponent } from '@shared/pages/kiosk/kiosk-shop-page/kiosk-shop-page.component';
import { KioskCategoryPageComponent } from '@shared/pages/kiosk/kiosk-category-page/kiosk-category-page.component';
import { KioskLightProductDetailsPageComponent } from '@shared/pages/kiosk/kiosk-light-product-details-page/kiosk-light-product-details-page.component';
import { KioskProductAddPageComponent } from '@shared/pages/kiosk/kiosk-product-add-page/kiosk-product-add-page.component';
import { KioskCartPageComponent } from '@shared/pages/kiosk/kiosk-cart-page/kiosk-cart-page.component';
import { KioskVirtualKeyboardComponent } from '@shared/pages/kiosk/kiosk-virtual-keyboard/kiosk-virtual-keyboard.component';
import { KioskCartPromoCodeBlockComponent } from '@shared/blocks/kiosk/kiosk-cart-promo-code-block/kiosk-cart-promo-code-block.component';
import { KioskCartProductsListBlockComponent } from '@shared/blocks/kiosk/kiosk-cart-products-list-block/kiosk-cart-products-list-block.component';
import { KioskCartLoyaltyBlockComponent } from '@shared/blocks/kiosk/kiosk-cart-loyalty-block/kiosk-cart-loyalty-block.component';
import { KioskCartFooterBlockComponent } from '@shared/blocks/kiosk/kiosk-cart-footer-block/kiosk-cart-footer-block.component';
import { KioskProductsSameCategoryBlockComponent } from '@shared/blocks/kiosk/kiosk-products-same-category-block/kiosk-products-same-category-block.component';
import { KioskProductDetailsBlockComponent } from '@shared/blocks/kiosk/kiosk-product-details-block/kiosk-product-details-block.component';
import { KioskPromoSelectProductPageComponent } from '@shared/pages/kiosk/kiosk-promo-select-product-page/kiosk-promo-select-product-page.component';
import { KioskPromoSelectProductBlockComponent } from '@shared/blocks/kiosk/kiosk-promo-select-product-block/kiosk-promo-select-product-blok.component';
import { KioskLoyaltyConnectionPageComponent } from '@shared/pages/kiosk/kiosk-loyalty-connection-page/kiosk-loyalty-connection-page.component';
import { KioskLoyaltyConnectionBlockComponent } from '@shared/blocks/kiosk/kiosk-loyalty-connection-block/kiosk-loyalty-connection-block.component';
import { KioskDoubleButtonFooterBlockComponent } from '@shared/blocks/kiosk/kiosk-double-button-footer-block/kiosk-double-button-footer-block.component';
import { KioskDistributionModePickerPageComponent } from '@shared/pages/kiosk/kiosk-distribution-mode-picker-page/kiosk-distribution-mode-picker-page.component';
import { KioskFooterCartBlockComponent } from '@shared/blocks/kiosk/kiosk-footer-cart-block/kiosk-footer-cart-block.component';
import { KioskCartFunnelHeaderBlockComponent } from '@shared/blocks/kiosk/cart-funnel/kiosk-cart-funnel-header-block/kiosk-cart-funnel-header-block.component';
import { KioskCartFunnelPageComponent } from '@shared/pages/kiosk/kiosk-cart-funnel-page/kiosk-cart-funnel-page.component';
import { KioskCartFunnelRadioItemBlockComponent } from '@shared/blocks/kiosk/cart-funnel/kiosk-cart-funnel-radio-item-block/kiosk-cart-funnel-radio-item-block.component';
import { KioskCartFunnelRadioOptionBlockComponent } from '@shared/blocks/kiosk/cart-funnel/kiosk-cart-funnel-radio-option-block/kiosk-cart-funnel-radio-option-block.component';
import { KioskCartFunnelFormBlockComponent } from '@shared/blocks/kiosk/cart-funnel/kiosk-cart-funnel-form-block/kiosk-cart-funnel-form-block.component';
import { KioskCartFunnelInputTextBlockComponent } from '@shared/blocks/kiosk/cart-funnel/kiosk-cart-funnel-input-text-block/kiosk-cart-funnel-input-text-block.component';
import { KioskCartFunnelPagerBlockComponent } from '@shared/blocks/kiosk/cart-funnel/kiosk-cart-funnel-pager-block/kiosk-cart-funnel-pager-blockcomponent';
import { KioskSuggestionsModalComponent } from '@shared/blocks/kiosk/kiosk-suggestions-modal/kiosk-suggestions-modal.component';
import { KioskSuggestionsModalBlockComponent } from '@shared/blocks/kiosk/kiosk-suggestions-modal-block/kiosk-suggestions-modal-block.component';
import { KioskPaymentMethodPickerBlockComponent } from '@shared/blocks/kiosk-payment-method-picker-block/kiosk-payment-method-picker-block.component';
import { KioskSelectSimpleProductModalComponent } from '@shared/modals/kiosk-select-simple-product-modal/kiosk-select-simple-product-modal.component';
import { KioskCartFunnelOnBehalfFieldBlockComponent } from '@shared/blocks/kiosk/cart-funnel/kiosk-cart-funnel-on-behalf-field-block/kiosk-cart-funnel-on-behalf-field-block.component';
import { PatAvailabilityModalComponent } from '@shared/modals/pat-availability-modal/pat-availability-modal.component';
import { SimpleHeaderBlockComponent } from '@shared/blocks/simple-header-block/simple-header-block.component';
import { SimpleHeaderImageItemBlockComponent } from '@shared/blocks/simple-header-image-item-block/simple-header-image-item-block.component';
import { SimpleHeaderTextItemBlockComponent } from '@shared/blocks/simple-header-text-item-block/simple-header-text-item-block.component';
import { StickyBlockComponent } from '@shared/blocks/sticky-block/sticky-block.component';
import { ParametersDeliveryInstructionsBlockComponent } from '@shared/blocks/parameters/parameters-delivery-instructions-block/parameters-delivery-instructions-block.component';
import { KioskProductOverviewModalComponent } from '@shared/modals/kiosk-product-overview-modal/kiosk-product-overview-modal.component';
import { ShopHeroTemplateBlockComponent } from '@shared/blocks/shop-hero-template-block/shop-hero-template-block.component';
import { HeroCarouselBlockComponent } from '@shared/blocks/hero-carousel-block/hero-carousel-block.component';
import { HeroCardCarouselBlockComponent } from '@shared/blocks/hero-card-carousel-block/hero-card-carousel-block.component';
import { KioskCodeScannerBlockComponent } from '@shared/blocks/kiosk/kiosk-code-scanner-block/kiosk-code-scanner.component';
import { KioskAlertModalComponent } from '@shared/modals/kiosk-alert-modal/kiosk-alert-modal.component';
import { CartFunnelSelectBlockComponent } from '@shared/blocks/cart-funnel/cart-funnel-select-block/cart-funnel-select-block.component';

export const ALLOWED_BLOCKS = {
  // Layout
  MainLayoutComponent,

  // Pages
  RedirectPageComponent,
  GenericPageComponent,
  ShopPageComponent,
  ShopsCustomMapPageComponent,
  CartPageComponent,
  CartFunnelPageComponent,
  PaymentPageComponent,
  PaymentConfirmPageComponent,
  OrderStatusPageComponent,
  AccountPageComponent,
  OrdersPageComponent,
  ChecksPageComponent,
  FavoritesPageComponent,
  ProductAddPageComponent,
  ItemEditPageComponent,
  OrderDetailsPageComponent,
  MarketplaceProductsPageComponent,
  ShopSearchPageComponent,
  ShopsMapPageComponent,
  ShopDigitalMenuPageComponent,
  ValidateSharedOrderPageComponent,
  GroupPaymentPageComponent,
  CheckDetailsPageComponent,
  PatPageComponent,
  PatChooseAmountPageComponent,
  PatPaymentPageComponent,
  PatPaymentConfirmationPageComponent,
  KioskSplashScreenPageComponent,
  KioskDistributionModePickerPageComponent,
  KioskLoyaltyConnectionPageComponent,
  KioskCartPageComponent,

  // Modals
  CartOverviewModalComponent,
  SidebarNavigationModalComponent,
  AuthenticationModalComponent,
  CartFooterModalComponent,
  CartVoucherModalComponent,
  CartCommentModalComponent,
  CartUserInformationsModalComponent,
  CartTipPickerModalComponent,
  ProductAddModalComponent,
  ItemEditModalComponent,
  AddHomeScreenModalComponent,
  ShopSearchParametersModalComponent,
  CartParametersModalComponent,
  FiltersModalComponent,
  CompoundItemModalComponent,
  ShopInformationsModalComponent,
  NavigateToMapButtonModalComponent,
  ShopCapabilitiesModalComponent,
  StripeTerminalReaderPickerModalComponent,
  KioskCartResetTimerModalComponent,
  WaitingModalComponent,
  ShareGroupedOrderModalComponent,
  ComoConfirmUsePointModalComponent,
  ComoProductsSelectionModalComponent,
  CartParametersModalLauncherBlockComponent,
  GroupedOrderModalLauncherBlockComponent,
  DeliveryAddressesModalComponent,
  NoConnectionModalComponent,
  KioskPickerModalComponent,
  KioskProductOverviewModalComponent,
  KioskSelectSimpleProductModalComponent,
  BancontactPaymentFormModalComponent,
  ConfirmModalComponent,
  AlertModalComponent,
  KioskAlertModalComponent,
  PatSelectProductsModalComponent,
  PatDivideAmountModalComponent,
  PatFreeAmountModalComponent,
  ShareUserFeedbackModalComponent,
  ReceiveReceiptModalComponent,
  PatAvailabilityModalComponent,

  // Blocks
  PatOverviewBlockComponent,
  ButtonBlockComponent,
  AuthenticationBlockComponent,
  HeroBlockComponent,
  PageHeroBlockComponent,
  HeaderBigLogoBlockComponent,
  ShopHeroSimpleBlockComponent,
  ShopHeroImageBlockComponent,
  ShopSmallHeroBlockComponent,
  HeaderBlockComponent,
  HeaderDigitalMenuBlockComponent,
  HeaderButtonBlockComponent,
  HeaderLinkBlockComponent,
  HeadingContentBlockComponent,
  HeaderAnchorListBlockComponent,
  HeaderSocialItemBlockComponent,
  HomeHeroShowcaseBlockComponent,
  MosaicBlockComponent,
  MosaicCardBlockComponent,
  MarketplaceProductsPreloaderBlockComponent,
  ParametersWantedAtBlockComponent,
  ParametersLocationBlockComponent,
  ParametersDistributionModeBlockComponent,
  SlideShowImageBlockComponent,
  ImageOfSlideShowBlockComponent,
  ImageAndCallToActionsBlockComponent,
  HtmlBlockComponent,
  ScriptBlockComponent,
  PadSplashScreenBlockComponent,
  ImageLogoBlockComponent,
  ParametersDeliveryInstructionsBlockComponent,
  ShopHeroTemplateBlockComponent,
  HeroCarouselBlockComponent,
  HeroCardCarouselBlockComponent,

  // Group payments
  GroupPaymentListBlockComponent,
  GroupPaymentPageHeaderBlockComponent,
  AddPaymentModalComponent,

  ShopAroundListBlockComponent,
  ShopAroundListHeaderBlockComponent,
  ShopAroundListBodyBlockComponent,

  MarketplaceProductsBlockComponent,
  ShopHeaderNavigationBlockComponent,
  ShopSmallOverviewListBlockComponent,
  ShopSmallOverviewGridBlockComponent,
  ShopCategoryTabsBlockComponent,
  ShopProductListBlockComponent,
  ShopsCustomMapBlockComponent,

  CartSuggestionsBlockComponent,
  CartHeaderBlockComponent,
  CartItemListBlockComponent,
  CartMerchantAmountBlockComponent,
  CartAdditionalInformationBlockComponent,
  CartVoucherBlockComponent,
  CartOnSiteLocationBlockComponent,
  CartTipPickerBlockComponent,
  CartPickupLocationBlockComponent,

  // Cart Funnel
  CartFunnelHeaderBlockComponent,
  CartFunnelFormBlockComponent,
  CartFunnelRadioItemBlockComponent,
  CartFunnelTextItemBlockComponent,
  CartFunnelRadioOptionBlockComponent,
  CartFunnelCheckboxItemBlockComponent,
  CartFunnelOnBehalfUserFullNameItemBlockComponent,
  CartFunnelOnBehalfUserEmailItemBlockComponent,
  CartFunnelOnBehalfUserOptinItemBlockComponent,
  CartFunnelOnBehalfUserPhoneItemBlockComponent,
  CartFunnelSelectBlockComponent,
  ResetCartFunnelBlockComponent,

  PaymentPageHeaderBlockComponent,
  PaymentMethodPickerBlockComponent,

  OrderStatusBlockComponent,
  OrderStatusPushItemBlockComponent,
  OrderStatusSuccessHeaderBlockComponent,
  OrderStatusFollowOrderCardBlockComponent,
  OrderStatusMessageCardBlockComponent,
  OrderReceiveReceiptBlockComponent,
  KioskOrderStatusBlockComponent,
  KioskCartResetTimerBlockComponent,
  KioskPaymentMethodPickerBlockComponent,
  TimerRedirectBlockComponent,
  SocialNetworksFollowUsBlockComponent,
  AccountPageBlockComponent,
  OrdersPageBlockComponent,
  ChecksPageBlockComponent,
  FavoritesPageBlockComponent,
  OrderDetailsBlockComponent,
  OrderDetailsPageHeaderComponent,
  InformationsTextBlockComponent,
  TextBlockComponent,
  ImageFullWidthBlockComponent,
  ImageVariableWidthBlockComponent,
  ImageTwoColsBlockComponent,
  ImageAndTextBlockComponent,
  SocialLinkBlockComponent,
  SocialLinksBlockComponent,
  YoutubePlayerBlockComponent,
  VimeoPlayerBlockComponent,
  AnchorBlockComponent,
  AnchorButtonsListBlockComponent,
  SpacerBlockComponent,
  ShopCardsBlockComponent,
  ShopCardBlockComponent,
  ShopCardWithLogoBlockComponent,
  SplashScreenBlockComponent,
  CheckDetailsBlockComponent,

  ShopsMapBlockComponent,

  PushRowBlockComponent,
  BigPushBlockComponent,
  SidebarNavigationModalItemBlockComponent,
  HighlightCardBlockComponent,
  InformationsContainerBlockComponent,
  InformationsColumnBlockComponent,
  InformationsRowBlockComponent,

  MapLocationBlockComponent,

  SuggestionCardsBlockComponent,
  CartSuggestionCardsBlockComponent,

  ValidateSharedOrderHeaderBlockComponent,
  ValidateSharedOrderBodyBlockComponent,
  CarouselBlockComponent,
  CarouselSlideCardBlockComponent,
  NestedPageBlockComponent,
  AnonymousAuthenticationPreloaderBlockComponent,

  // Feedback
  UserFeedbackBlockComponent,
  UserFeedbackLinkBlockComponent,
  ExternalLinkButtonBlockComponent,

  // Modal launchers
  ShopSearchParametersModalLauncherBlockComponent,
  CartButtonBlockComponent,
  KioskPickerModalLauncherBlockComponent,

  // Como
  ComoHomeMosaicBlockComponent,
  ComoAssetListBlockComponent,
  ComoCreditBalanceCardBlockComponent,
  ComoUseCreditBalanceModalComponent,
  ComoJoinLoyaltyBlockComponent,
  ComoLinkAccountModalComponent,
  ComoCreateAccountModalComponent,
  ComoPunchCardsBlockComponent,
  ComoCreateOrLinkAccountModalComponent,
  MosaicComoLinkAccountCardBlockComponent,
  MosaicComoCreateAccountCardBlockComponent,
  ComoSsoLoadingModalComponent,
  ComoRegistrationModalComponent,

  // Zerosix
  ZerosixCreateOrLinkAccountModalComponent,
  ZerosixCreateAccountModalComponent,
  ZerosixLinkAccountModalComponent,
  ZerosixAssetListBlockComponent,
  ZerosixOfferedProductPickerModalComponent,

  // Pongo
  PongoCreateOrLinkAccountModalComponent,
  PongoAssetListBlockComponent,
  PongoOfferedProductPickerModalComponent,

  // Lydia
  LydiaPaymentPhoneNumberModalComponent,

  // Food Society blocks
  FsHeroBlockComponent,
  FoodSocietyHeroBlockComponent,
  FoodSocietyFatButtonBlockComponent,
  FoodSocietyShopListBlockComponent,

  // Dood
  MarketplaceHeroBlockComponent,
  MarketplaceHeroSlideImageBlockComponent,
  MarketplaceHeroSlideTextBlockComponent,
  ShopTypePickerBlockComponent,

  // Footer
  BigFooterBlockComponent,
  BigFooterBodyDownloadAppsBlockComponent,
  BigFooterBodySocialNetworksBlockComponent,
  BigFooterColumnBlockComponent,
  BigFooterColumnItemBlockComponent,
  BigFooterBodyLogoBlockComponent,
  BigFooterBodyCallToActionBlockComponent,
  LegalsFooterBlockComponent,
  LegalsFooterLinkItemBlockComponent,
  CookieConsentLinkItemBlockComponent,

  // Generic modal
  GenericModalComponent,
  ModalHeaderBlockComponent,
  ModalButtonBlockComponent,

  // Splash
  SplashScreenImageModalComponent,
  SplashScreenImageModalBlockComponent,

  // Debug
  DebugNetworkConnectionBlockComponent,

  // Pay at table
  ClassicButtonBlockComponent,

  StickyBlockComponent,
  SimpleHeaderBlockComponent,
  SimpleHeaderImageItemBlockComponent,
  SimpleHeaderTextItemBlockComponent,

  //Kiosk Pages
  KioskShopPageComponent,
  KioskLightProductDetailsPageComponent,
  KioskProductAddPageComponent,
  KioskPromoSelectProductPageComponent,
  KioskCategoryPageComponent,
  //Kiosk
  KioskHeaderBlockComponent,
  KioskFooterCartBlockComponent,
  KioskCartLoyaltyBlockComponent,
  KioskProductsSameCategoryBlockComponent,
  KioskPromoSelectProductBlockComponent,
  KioskProductDetailsBlockComponent,
  KioskCartFooterBlockComponent,
  KioskCartPromoCodeBlockComponent,
  KioskDoubleButtonFooterBlockComponent,
  KioskLoyaltyConnectionBlockComponent,
  KioskCartProductsListBlockComponent,
  KioskSelectLanguageBlockComponent,
  KioskShopCategoryListBlockComponent,
  KioskSuggestionsModalComponent,
  KioskSuggestionsModalBlockComponent,
  KioskVirtualKeyboardComponent,
  KioskCartFunnelPageComponent,
  KioskCartFunnelFormBlockComponent,
  KioskCartFunnelHeaderBlockComponent,
  KioskCartFunnelInputTextBlockComponent,
  KioskCartFunnelRadioItemBlockComponent,
  KioskCartFunnelRadioOptionBlockComponent,
  KioskCartFunnelPagerBlockComponent,
  KioskCartFunnelOnBehalfFieldBlockComponent,
  KioskCodeScannerBlockComponent,
};
