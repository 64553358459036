export interface IPaymentServiceStripe {
  stripe_location_id: string;
  simulated_terminal: boolean;
}

export interface IStripeTerminalConnectionTokenResponse {
  secret: string;
}

export enum StripeTerminalReaderConnectionStatus {
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
}
