import { ReducerTypes, ActionCreator, on } from '@ngrx/store';

import {
  MARKETPLACE_STORE_MARKETPLACE_INITIAL_STATE,
  MARKETPLACE_STORE_INITIAL_STATE,
  MarketplaceState,
} from './marketplace.state';
import {
  MARKETPLACE_STORE_COMPONENT_ACTIONS,
  MARKETPLACE_STORE_LOCAL_ACTIONS,
} from './marketplace.action';

export const MARKETPLACE_STORE_API_PIECE: ReducerTypes<
  MarketplaceState,
  readonly ActionCreator[]
>[] = [];

export const MARKETPLACE_STORE_LOCAL_PIECE: ReducerTypes<
  MarketplaceState,
  readonly ActionCreator[]
>[] = [
  // Is Loading
  on(MARKETPLACE_STORE_LOCAL_ACTIONS.updateIsLoading, (state, { isLoading }): MarketplaceState => {
    return { ...state, isLoading };
  }),
  on(MARKETPLACE_STORE_LOCAL_ACTIONS.resetIsLoading, (state): MarketplaceState => {
    return { ...state, isLoading: false };
  }),

  // Is Loaded
  on(MARKETPLACE_STORE_LOCAL_ACTIONS.updateIsLoaded, (state, { isLoaded }): MarketplaceState => {
    return { ...state, isLoaded };
  }),
  on(MARKETPLACE_STORE_LOCAL_ACTIONS.resetIsLoaded, (state): MarketplaceState => {
    return { ...state, isLoaded: false };
  }),

  // Marketplace
  on(MARKETPLACE_STORE_LOCAL_ACTIONS.setMarketplace, (state, { marketplace }): MarketplaceState => {
    return { ...state, marketplace };
  }),
  on(MARKETPLACE_STORE_LOCAL_ACTIONS.updateMarketplace, (state, { changes }): MarketplaceState => {
    return { ...state, marketplace: { ...state.marketplace, ...changes } };
  }),
  on(MARKETPLACE_STORE_LOCAL_ACTIONS.resetMarketplace, (state): MarketplaceState => {
    return { ...state, marketplace: MARKETPLACE_STORE_MARKETPLACE_INITIAL_STATE };
  }),

  // Misc
  on(MARKETPLACE_STORE_LOCAL_ACTIONS.reset, (): MarketplaceState => {
    return MARKETPLACE_STORE_INITIAL_STATE;
  }),
];

export const MARKETPLACE_STORE_COMPONENT_PIECE: ReducerTypes<
  MarketplaceState,
  readonly ActionCreator[]
>[] = [
  on(MARKETPLACE_STORE_COMPONENT_ACTIONS.updateState, (state, { changes }): MarketplaceState => {
    return { ...state, ...changes };
  }),
  on(MARKETPLACE_STORE_COMPONENT_ACTIONS.resetState, (): MarketplaceState => {
    return MARKETPLACE_STORE_INITIAL_STATE;
  }),
];
