import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-kiosk-input-multi-atom',
  templateUrl: './kiosk-input-multi-atom.component.html',
})
export class KioskInputMultiAtomComponent {
  @Input() label!: string;
  @Input() type!: string;
  @Input() blockId!: string;
  @Input() active = false;
  @Input() boldLabel = false;
  @Input() value = '';
  @Input() placeholder?: string;
  @Input() textColor?: IContentBuilderFieldColor;
  @Input() color?: IContentBuilderFieldColor;
  @Input() isChecked: boolean = false;
  @Input() isOverFooter: boolean = false;
  @Output() valueEmitter = new EventEmitter<string | boolean>();

  constructor() {}

  onChangeValueRadio(event: string): void {
    this.valueEmitter.emit(event);
  }

  onChangeValueCheckbox(isChecked: boolean): void {
    this.valueEmitter.emit(isChecked);
  }

  onChangeTextValue(event: string): void {
    this.valueEmitter.emit(event);
  }

  unfocus(element: HTMLElement): void {
    element.blur();
  }
}
