import { Observable, of, Subject } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { ShopKeys } from '@config/keys/shop.keys';

import { IPongoAsset } from '@core/models/pongo.model';
import { DoodShopModel } from '@core/models/shop.model';
import { DoodProductModel } from '@core/models/product.model';

import { CartService } from '@core/services/cart/cart.service';
import { PongoService } from '@core/services/pongo/pongo.service';
import { ModalsService } from '@core/services/modals/modals.service';

import { CartStoreSelector } from '@common/selectors/cart.selector';
import { ShopStoreSelector } from '@common/selectors/shop.selector';
import { ModalStoreSelector } from '@common/selectors/modal.selector';

import { ContentStoreDispatcher } from '@common/dispatchers/content.dispatcher';
import { ModalScrollBlockBase } from '@core/base/modalScrollBlock/modal-scroll-block.base';
import { ProductAddModalComponent } from '@shared/modals/product-add-modal/product-add-modal.component';

@Component({
  selector: 'app-pongo-offered-product-picker-modal',
  templateUrl: './pongo-offered-product-picker-modal.component.html',
})
export class PongoOfferedProductPickerModalComponent
  extends ModalScrollBlockBase
  implements OnInit, OnDestroy
{
  private _destroyerRef = new Subject<boolean>();

  static handle = 'pongo-offered-product-picker-modal';
  static onProductSelected?: () => void;
  static asset?: IPongoAsset;

  index$: Observable<number> = this.modalSelector
    .selectModal(PongoOfferedProductPickerModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 6)));

  products: DoodProductModel[] = [];
  isProductsLoading = true;

  private shopId?: string;

  constructor(
    private cartSelector: CartStoreSelector,
    private shopSelector: ShopStoreSelector,
    private modalSelector: ModalStoreSelector,
    private readonly cartService: CartService,
    private readonly pongoService: PongoService,
    private readonly modalsService: ModalsService,
    private contentDispatcher: ContentStoreDispatcher,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getShopFromCart$()
      .pipe(
        takeUntil(this._destroyerRef),
        switchMap(shopId => this.getAllowedProducts$(shopId)),
      )
      .subscribe();
    this.getAllowedProducts$();
  }

  private getShopFromCart$(): Observable<undefined | string> {
    return this.cartSelector.selectActive.pipe(
      map(cart => {
        if (!cart) {
          return undefined;
        }
        return cart?.shop;
      }),
    );
  }

  private getAllowedProducts$(shopId?: string): Observable<DoodShopModel | undefined> {
    if (!shopId) {
      return of(undefined);
    }

    this.shopId = shopId;

    return this.shopSelector.selectShop(shopId).pipe(
      takeUntil(this._destroyerRef),
      tap(shop => {
        const assetExternalId = PongoOfferedProductPickerModalComponent.asset?.external_ids;
        if (!assetExternalId) {
          this.products = [];
          this.isProductsLoading = false;
          return;
        }
        this.pongoService
          .allowedProducts$(assetExternalId, shop?.[ShopKeys.Id])
          .subscribe(products => {
            this.products = products;
            this.isProductsLoading = false;
          });
      }),
    );
  }

  ngOnDestroy(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
    super.ngOnDestroy();
  }

  close(): void {
    this.modalsService.close(PongoOfferedProductPickerModalComponent.handle);
  }

  onNavigateTo(product: DoodProductModel): void {
    if (!this.shopId) {
      return;
    }

    this.contentDispatcher.updateProduct({
      shopIsNotActive: true,
      productId: product.id,
      shopId: product.shop_id,
    });
    this.close();
    ProductAddModalComponent.allowAddToCart = true;
    ProductAddModalComponent.onAdd = (): void => {
      this.close();
      if (PongoOfferedProductPickerModalComponent.onProductSelected) {
        PongoOfferedProductPickerModalComponent.onProductSelected();
      }
      ProductAddModalComponent.onAdd = (): void => {};
    };
    this.modalsService.open(ProductAddModalComponent.handle);
  }

  addVoucherWithoutProduct(): void {
    this.close();
    if (PongoOfferedProductPickerModalComponent.onProductSelected) {
      PongoOfferedProductPickerModalComponent.onProductSelected();
    }
  }

  cancel(): void {
    const asset = PongoOfferedProductPickerModalComponent.asset;
    if (!asset) {
      this.close();
      return;
    }
    const coupon = { code: asset.hash };
    if (!coupon) {
      return;
    }
    this.cartService.removeCoupon(coupon);
    this.close();
  }
}
