import dayjs from 'dayjs';
import { Router } from '@angular/router';
import { from, of, Subject, timer } from 'rxjs';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { map, switchMap, take, takeUntil, takeWhile, tap } from 'rxjs/operators';

import { OrderKeys } from '@config/keys/order.keys';
import { DoodOrderModel } from '@store/order/order.model';

import {
  PAYMENT_STORE_BUTTON_INITIAL_STATE,
  PAYMENT_STORE_STATUS_INITIAL_STATE,
} from '@store/payment/payment.state';

import { PaymentStoreRefiner } from '@common/refiners/payment.refiner';
import { OrderStoreDispatcher } from '@common/dispatchers/order.dispatcher';
import { PaymentStoreDispatcher } from '@common/dispatchers/payment.dispatcher';

import { OrderStoreSelector } from '@common/selectors/order.selector';
import { BasketStoreSelector } from '@common/selectors/basket.selector';
import { PaymentStoreSelector } from '@common/selectors/payment.selector';
import { TransactionStoreSelector } from '@common/selectors/transaction.selector';

import { Paths } from '@config/paths.config';
import { IPaymentMethod, PaymentMethodHandles } from '@config/payment-methods.config';
import { ORDER_ALREADY_PAID, TRANSACTION_EXCEEDS_TOTAL } from '@config/errors.config';

import { NativeService } from '@core/services/native/native.service';
import { PaymentService } from '@core/services/payment/payment.service';
import { ErrorService } from '@core/services/error-service/error.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-payment-method-picker-block',
  templateUrl: './payment-method-picker-block.component.html',
  styleUrls: ['./payment-method-picker-block.component.scss'],
})
export class PaymentMethodPickerBlockComponent implements OnInit, OnDestroy {
  @Input() bottomMargin = 'large';
  @Input() headingColor: IContentBuilderFieldColor = {
    value: 'neutral-900',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() textColor: IContentBuilderFieldColor = {
    value: 'neutral-600',
    type: ColorFieldTypesValues.Palette,
  };

  order$ = this.orderSelector.selectValidation;
  availablePaymentMethods$ = this.paymentRefiner.selectPaymentMethodsWithDescriptors;
  isPaymentRetryInProgress$ = this.paymentSelector.selectStatus.pipe(map(s => s.isRetryInProgress));
  activeOrderCreatedAt$ = this.order$.pipe(
    map(order => (order ? (order as DoodOrderModel).created_at : null)),
  );

  availablePaymentMethods?: IPaymentMethod[];
  selectedPaymentMethod: IPaymentMethod | null = null;
  orderKeys = OrderKeys;
  remainingTime!: string;

  isNotched = false;
  errorMessages: string[] = [];

  error: string | null = null;
  amount: number | null = null;
  button = PAYMENT_STORE_BUTTON_INITIAL_STATE;
  status = PAYMENT_STORE_STATUS_INITIAL_STATE;

  private _destroyerRef = new Subject<boolean>();

  get hasErrorMessages(): boolean {
    return this.errorMessages?.length > 0;
  }

  constructor(
    private router: Router,
    private orderSelector: OrderStoreSelector,
    private basketSelector: BasketStoreSelector,
    private readonly errorService: ErrorService,
    private paymentRefiner: PaymentStoreRefiner,
    private paymentSelector: PaymentStoreSelector,
    private orderDispatcher: OrderStoreDispatcher,
    private readonly paymentService: PaymentService,
    private paymentDispatcher: PaymentStoreDispatcher,
    private readonly routerHelper: RouterHelperService,
    private transactionSelector: TransactionStoreSelector,
  ) {
    this.paymentSelector.select
      .pipe(takeUntil(this._destroyerRef))
      .subscribe(({ amount, button, error, status }) => {
        this.status = status;
        this.button = button;
        this.amount = amount;
        this.error = error;
      });
  }

  ngOnInit(): void {
    this.paymentDispatcher.resetUI();

    this.errorService
      .getErrorMessage$()
      .pipe(
        takeUntil(this._destroyerRef),
        tap(messages => (this.errorMessages = messages)),
      )
      .subscribe();

    const parsedUrl = this.router.parseUrl(this.router.url);

    this.availablePaymentMethods$
      .pipe(
        tap(availablePaymentMethods => {
          availablePaymentMethods = availablePaymentMethods || [];
          availablePaymentMethods.forEach(a => {
            a.data = {
              ...a.data,
              isUnique: availablePaymentMethods.length === 1,
            };
          });
          this.availablePaymentMethods = availablePaymentMethods;

          if (availablePaymentMethods.length === 1) {
            this.selectedPaymentMethod = availablePaymentMethods[0];
          }

          if (parsedUrl.queryParams?.payment_method === PaymentMethodHandles.Edenred) {
            this.selectedPaymentMethod =
              availablePaymentMethods.find(pm => pm.handle === PaymentMethodHandles.Edenred) ||
              null;
          }
        }),
      )
      .subscribe();

    from(NativeService.isNotched()).subscribe(isNotched => {
      this.isNotched = isNotched;
    });

    this.redirectIfBasketShareOrderIsAlreadyPaid();

    // TODO: Change this by a selector ?
    if (this.orderSelector.active) {
      this.orderDispatcher.resetActive();
    }

    this.triggerTimeoutCreatedOrder();
  }

  selectPaymentMethod(paymentMethod: IPaymentMethod): void {
    this.isPaymentRetryInProgress$
      .pipe(
        take(1),
        tap(isPaymentRetryInProgress => {
          if (isPaymentRetryInProgress) {
            return;
          }
          if (this.selectedPaymentMethod === paymentMethod) {
            this.selectedPaymentMethod = null;
            this.paymentDispatcher.resetUI();
            return;
          }
          this.selectedPaymentMethod = paymentMethod;
        }),
      )
      .subscribe();
  }

  triggerPayment(): void {
    this.paymentService.triggerPayment();
  }

  ngOnDestroy(): void {
    this.remainingTime = '';
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
  }

  private redirectIfBasketShareOrderIsAlreadyPaid(): void {
    const basket = this.basketSelector.basket.id;
    if (basket) {
      this.transactionSelector.selectError
        .pipe(
          takeUntil(this._destroyerRef),
          map(error => {
            if (error === ORDER_ALREADY_PAID || error === TRANSACTION_EXCEEDS_TOTAL) {
              this.router.navigate([this.routerHelper.translateRoute(`${Paths.GroupPayment}`)]);
            }
          }),
        )
        .subscribe();
    }
  }

  private triggerTimeoutCreatedOrder(): void {
    this.activeOrderCreatedAt$
      .pipe(
        takeUntil(this._destroyerRef),
        switchMap(createdAt => {
          if (!createdAt) {
            return of(null);
          }
          const orderTimeout = dayjs(createdAt).add(10, 'minute');
          return timer(0, 1000).pipe(
            map(() => {
              const now = dayjs();
              const diffInSeconds = orderTimeout.diff(now, 'second');
              if (diffInSeconds <= 0) {
                this.router.navigate([this.routerHelper.translateRoute(`/${Paths.Cart}`)]);
                return '';
              } else {
                const hours = Math.floor(diffInSeconds / 3600);
                const minutes = Math.floor((diffInSeconds % 3600) / 60);
                const seconds = diffInSeconds % 60;
                return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
              }
            }),
            takeWhile(remainingTime => remainingTime !== '', true),
          );
        }),
      )
      .subscribe(remainingTime => {
        if (remainingTime) {
          this.remainingTime = remainingTime;
        }
      });
  }

  forceType(data: any): any {
    return data as any;
  }
}
