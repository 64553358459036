import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DOOD_API } from '@config/ws.config';
import { IKiosk } from '@core/models/kiosk.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KiosksApiService {
  constructor(private readonly client: HttpClient) {}

  getKiosk$(kioskId: string): Observable<IKiosk> {
    return this.client.get<IKiosk>(`${DOOD_API}/kiosks/${kioskId}`);
  }

  getKiosks$(marketplaceId: string): Observable<IKiosk[]> {
    return this.client.get<IKiosk[]>(`${DOOD_API}/kiosks?marketplace=${marketplaceId}`);
  }
}
