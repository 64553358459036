<div
  class="mx-auto max-w-screen-sm"
  *ngIf="form"
>
  <div class="flex justify-between">
    <h1 class="mb-6 font-accent text-3xs font-bold leading-8xs text-neutral-800">
      {{ signupStepHeading }}
    </h1>
    <app-rounded-icon-button
      [icon]="'icon-cross'"
      [color]="'primary'"
      (click)="closeModal()"
    ></app-rounded-icon-button>
  </div>
  <div
    class="mb-6 font-accent text-2xl text-neutral-800"
    *ngIf="signupStepSubheading"
  >
    {{ signupStepSubheading }}
  </div>
  <form [formGroup]="form">
    <div *ngIf="signupStepDisplayFirstname && form.controls.firstname">
      <label
        for="firstname"
        class="mb-1 block font-base text-sm font-bold font-normal leading-5 text-neutral-600"
      >{{ signupStepFirstnameLabel }}</label
      >
      <label
        for="firstname"
        *ngIf="signupStepFirstnameHelp"
        class="-mt-1 mb-1 block font-base text-sm font-normal leading-5 text-neutral-500"
      >{{ signupStepFirstnameHelp }}</label
      >
      <input
        id="firstname"
        type="text"
        formControlName="firstname"
        (keyup.enter)="onSubmit()"
        appAutoFocus
        autocomplete="given-name"
        class="mb-6 flex w-full border border-solid border-neutral-300 bg-neutral-50 px-5 py-8xs font-base text-base font-normal leading-5"
      />
    </div>
    <div *ngIf="signupStepDisplayLastname && form.controls.lastname">
      <label
        for="lastname"
        class="mb-1 block font-base text-sm font-bold font-normal leading-5 text-neutral-600"
      >{{ signupStepLastnameLabel }}</label
      >
      <label
        for="lastname"
        *ngIf="signupStepLastnameHelp"
        class="-mt-1 mb-1 block font-base text-sm font-normal leading-5 text-neutral-500"
      >{{ signupStepLastnameHelp }}</label
      >
      <input
        id="lastname"
        type="text"
        formControlName="lastname"
        (keyup.enter)="onSubmit()"
        autocomplete="family-name"
        class="mb-6 flex w-full border border-solid border-neutral-300 bg-neutral-50 px-5 py-8xs font-base text-base font-normal leading-5"
        [class.border-error-700]="
          form.controls?.lastname?.invalid &&
          (form.controls?.lastname?.dirty || form.controls?.lastname?.touched)
        "
      />
    </div>
    <div
      *ngIf="
        ((!(authenticationEmailValue$ | async) ||
          userJourneyType === authenticationUserJourneyType.ASK_LOGIN_OR_SIGNUP) &&
          signupWithEmailAndPassword) ||
        emailErrorMessage
      "
    >
      <label
        for="email"
        class="mb-1 block font-base text-sm font-bold font-normal leading-5 text-neutral-600"
      >{{ signupStepEmailLabel }}</label
      >
      <label
        for="email"
        *ngIf="signupStepEmailHelp"
        class="-mt-1 mb-1 block font-base text-sm font-normal leading-5 text-neutral-500"
      >{{ signupStepEmailHelp }}</label
      >
      <input
        id="email"
        type="email"
        name="email"
        formControlName="email"
        [class.border-error-700]="emailErrorMessage"
        (keyup.enter)="onSubmit()"
        appAutoFocus
        autocomplete="email"
        class="flex w-full border border-solid border-neutral-300 bg-neutral-50 px-5 py-8xs font-base text-base font-normal leading-5"
        [ngClass]="{
          'mb-3': emailErrorMessage || form?.controls?.email?.errors?.format,
          'mb-6': !emailErrorMessage && !form?.controls?.email?.errors?.format,
        }"
      />
      <p
        *ngIf="emailErrorMessage"
        class="mb-2 font-base text-xs font-normal text-error-700"
      >
        {{ emailErrorMessage | translate }}
      </p>
      <p
        *ngIf="form?.controls?.email?.errors?.format && form?.controls?.email?.touched"
        class="mb-2 font-base text-xs font-normal text-error-700"
      >
        {{ 'errors.email-not-valid' | translate }}
      </p>
    </div>
    <div
      class="relative pb-6"
      *ngIf="signupStepDisplayPhone"
    >
      <label
        for="phone"
        class="mb-1 block font-base text-sm font-bold font-normal leading-5 text-neutral-600"
      >{{ signupStepPhoneLabel }}</label
      >
      <label
        for="phone"
        *ngIf="signupStepPhoneHelp"
        class="-mt-1 mb-1 block font-base text-sm font-normal leading-5 text-neutral-500"
      >{{ signupStepPhoneHelp }}</label
      >
      <app-input-country-phone
        id="phone"
        [selectedIso]="
          ((marketplaceCountry$ | async) ? (marketplaceCountry$ | async) : countryIso.France) ??
          undefined
        "
        (updatePhone)="onUpdatePhone($event)"
      ></app-input-country-phone>
      <p
        *ngIf="form?.controls?.phone?.errors?.pattern && form?.controls?.phone?.pristine"
        class="absolute bottom-1 block font-base text-xs font-normal text-error-700"
      >
        {{ 'signup.invalid-email' | translate }}
      </p>
    </div>
    <div
      *ngIf="signupWithEmailAndPassword"
      class="pb-6"
    >
      <label
        for="password"
        class="mb-1 block font-base text-sm font-bold font-normal leading-5 text-neutral-600"
      >{{ signupStepPasswordLabel }}</label
      >
      <label
        for="password"
        class="-mt-1 mb-1 block font-base text-sm font-normal leading-5 text-neutral-500"
      >{{ signupStepPasswordHelp }}</label
      >
      <input
        id="password"
        type="password"
        formControlName="password"
        (keyup.enter)="onSubmit()"
        autocomplete="new-password"
        class="mb-1 flex w-full border border-solid border-neutral-300 bg-neutral-50 px-5 py-8xs font-base text-base font-normal leading-5"
        [class.border-error-700]="passwordErrorMessage"
      />
      <span
        *ngIf="!passwordErrorMessage"
        class="mb-2 block font-base text-xs font-normal text-neutral-600"
      >{{ 'authentication.weak-password' | translate }}</span
      >
      <span class="mb-2 block font-base text-xs font-normal text-error-700">{{
          passwordErrorMessage ?? '' | translate
        }}</span>
    </div>
    <div class="flex items-start">
      <input
        id="legals"
        type="checkbox"
        formControlName="legals"
        class="form-checkbox m-3 h-6 w-6 border text-primary-600"
      />
      <label
        for="legals"
        class="mt-3 font-base text-sm leading-5 text-neutral-800"
      >
        <ng-container *ngIf="!legalCheckboxLabel">
          <span translate="signup.cgv.accept"></span>&nbsp;
          <a
            class="underline"
            target="_blank"
            href="https://www.dood.com/corporate/cgv"
          >
            <span>{{ 'signup.cgv.cgv' | translate }}</span> </a
          >&nbsp; <span translate="signup.cgv.and"></span>&nbsp;
          <a
            class="underline"
            target="_blank"
            href="https://www.dood.com/corporate/cgu/"
          >
            <span translate="signup.cgv.cgu"></span> </a
          >.
        </ng-container>
        <app-text-atom
          [content]="legalCheckboxLabel"
          *ngIf="legalCheckboxLabel"
        ></app-text-atom>
      </label>
    </div>
    <div class="mb-6 flex items-start">
      <input
        id="newsletter"
        type="checkbox"
        formControlName="newsletter"
        class="form-checkbox m-3 h-6 w-6 border text-primary-600"
      />
      <label
        for="newsletter"
        class="mt-3 font-base text-sm leading-5 text-neutral-800"
      >
        <ng-container *ngIf="!optinCheckboxLabel">
          {{
            'signup.dood-communication' | translate: {marketplaceName$: marketplaceName$ | async}
          }}
        </ng-container>
        <app-text-atom
          [content]="optinCheckboxLabel"
          *ngIf="optinCheckboxLabel"
        ></app-text-atom>
      </label>
    </div>
  </form>
  <app-button-atom
    [disabled]="!form.valid || isSignUpInProgress"
    [iconUrl]="isSignUpInProgress ? 'assets/images/spinner.svg' : ''"
    [type]="'primary'"
    [label]="'signup.continue' | translate"
    [size]="'L'"
    (click)="onSubmit()"
  ></app-button-atom>

  <p
    class="my-2 font-base text-xs font-normal text-error-700"
    *ngIf="anyErrorMessage"
  >
    {{ anyErrorMessage }}
  </p>

  <app-block-list [blockIdList]="signupFooter"></app-block-list>
</div>
