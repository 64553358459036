<div class="sticky inline-block w-auto right-0 left-full bottom-15.5 pr-5 lg:hidden">
  <app-button-atom
    [type]="'primary'"
    [icon]="'icon-distance'"
    [size]="'S'"
    [color]="'primary'"
    [label]="'Voir sur la carte'"
    (clickEventEmitter)="navigateToMapPage()"
  ></app-button-atom>
</div>
