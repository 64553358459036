<ng-container *ngIf="comoUser">
  <div
    class="md:container md:mx-auto lg:mx-auto lg:max-w-screen-sm kiosk:mx-0 kiosk:max-w-full"
    *ngIf="displayAssets"
  >
    <app-como-asset-list-atom
      [assets]="comoUser.assets"
      [heading]="assetsHeading | translate"
      [subheading]="assetsSubheading"
      [creditBalance]="comoUser.credit_balance.monetary"
      [pointsBalance]="comoUser.points_balance.non_monetary"
      [displayCredits]="displayCredits"
      [displayPoints]="displayPoints"
      [displayAddButton]="false"
    ></app-como-asset-list-atom>
  </div>
</ng-container>
