<div
  *ngIf="displaySplashScreen"
  class="bg-white fixed top-0 bottom-0 left-0 right-0 h-screen px-6 py-12"
  [style.z-index]="90"
>
  <div class="flex flex-col h-full justify-between">
    <div>
      <div class="flex flex-row justify-start items-center mb-8 text-base">
        <img
          *ngIf="kioskInProgress"
          src="assets/images/spinner.svg"
          alt=""
          class="mr-4"
        />
        <i
          *ngIf="!kioskInProgress && !kiosk"
          class="icon icon-cross text-error-600 mr-4"
        ></i>
        <i
          *ngIf="!kioskInProgress && kiosk"
          class="icon icon-check-plain text-success-600 mr-4"
        ></i>
        Connexion au terminal
      </div>
      <div class="mb-8 text-base">
        <div class="flex flex-row justify-start items-center">
          <img
            *ngIf="!user"
            src="assets/images/spinner.svg"
            alt=""
            class="mr-4"
          />
          <i
            *ngIf="user"
            class="icon icon-check-plain text-success-600 mr-4"
          ></i>
          Création d'un profil utilisateur
        </div>
        <div
          *ngIf="user"
          class="ml-8 mt-2"
        >
          <p *ngIf="!user?.[userKeys.IsAnonymous]">
            {{ user?.[userKeys.FirstName] }}
            {{ user?.[userKeys.LastName] }}
          </p>
          <p *ngIf="user?.[userKeys.IsAnonymous]">
            {{ 'basket.anonymous' | translate }}
          </p>
        </div>
      </div>
    </div>

    <div
      *ngIf="kiosk"
      class="text-sm text-center mb-8"
    >
      <p class="font-bold">Terminal</p>
      <p>{{ kiosk.name }}</p>
    </div>
  </div>
</div>
