<div class="px-5 py-6 rounded-m bg-white">
  <div class="mb-3.5">
    <app-order-status-tag-atom [orderStatus]="orderStatus"></app-order-status-tag-atom>
  </div>
  <p class="font-base font-normal text-sm md:text-md text-neutral-600">
    {{ 'order.pickup-location' | translate }}
  </p>
  <p class="text-md md:text-29px font-accent font-bold text-neutral-800 mb-3 md:mb-7">
    {{ shopName }}
  </p>
  <ng-container *ngIf="ready_at">
    <p class="font-base font-normal text-sm md:text-md text-neutral-600">
      {{ 'order.withdrawal-time' | translate }}
    </p>
    <p class="text-md md:text-29px font-accent font-bold text-neutral-800 mb-6 md:mb-7">
      {{ daysUntilReadyString }} {{ 'order.at' | translate }}
      {{ readyAt }}
    </p>
  </ng-container>
  <p class="font-base font-normal text-sm md:text-md text-neutral-600">
    {{ 'order.order-number' | translate }}
  </p>
  <p class="text-md md:text-29px font-accent font-bold text-neutral-800 mb-6 md:mb-7">
    {{ orderNumber }}
  </p>
  <app-button-atom
    [size]="'L'"
    [label]="'order.view-detail' | translate"
    [type]="'light'"
    [color]="'primary'"
    (clickEventEmitter)="goToOrderDetail(order)"
  ></app-button-atom>
</div>
