import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ComoService } from '@core/services/como/como.service';
import { UserService } from '@core/services/user/user.service';
import { tap } from 'rxjs';

@Component({
  selector: 'app-kiosk-register-step',
  template: `
    <div class="flex items-center justify-between">
      <h1
        class="pb-5 font-brand text-28px font-bold text-neutral-800"
        translate="como.link-account.header"
      ></h1>
      <app-rounded-icon-button
        icon="icon-cross"
        (onClick)="onClose.emit()"
      ></app-rounded-icon-button>
    </div>

    <form [formGroup]="form">
      <label
        for="phone"
        class="mb-1 block font-base text-sm font-normal leading-5 text-neutral-600"
        translate="signup.phone"
      ></label>
      <label
        for="comoKioskRegistrationPhoneInput"
        class="mb-2 block font-base text-xs font-normal text-neutral-500"
        translate="como.help-phone-format"
      >
      </label>

      <input
        id="comoKioskRegistrationPhoneInput"
        type="tel"
        formControlName="phone"
        class="flex w-full border border-solid border-neutral-300 bg-neutral-50 px-5 py-8xs font-base text-base font-normal leading-5"
        [class.border-error-700]="errorMessage"
      />

      <div class="hidden kiosk:block">
        <app-numeric-keypad-atom
          (numberClick)="onKeypadNumberClick($event)"
          (deleteClick)="onKeypadDeleteClick()"
        ></app-numeric-keypad-atom>
      </div>

      <div
        class="mt-2 font-base text-xs font-normal text-error-700"
        *ngIf="errorMessage"
        [translate]="errorMessage"
      ></div>

      <div class="mt-10 flex flex-col">
        <app-button-atom
          size="L"
          [label]="'como.validate' | translate"
          [iconUrl]="isValidating ? 'assets/images/spinner.svg' : undefined"
          [disabled]="isValidating || !form.valid"
          [overrideBgColor]="accentColor"
          type="primary"
          [full]="true"
          (click)="onRegister()"
        ></app-button-atom>
      </div>
    </form>
  `,
})
export class KioskRegisterStepComponent extends DestroyerBase {
  @Output() onComplete = new EventEmitter<void>();
  @Output() onClose = new EventEmitter<void>();
  @Output() setPhone = new EventEmitter<string>();
  @Output() goToKioskRegisterConfirmationStep = new EventEmitter<void>();

  @Input() phone = '';
  @Input() accentColor?: IContentBuilderFieldColor;

  isValidating = false;
  errorMessage?: string;

  form: UntypedFormGroup;

  kioskParameters = this.settingsSelector.kiosk;

  constructor(
    private readonly comoService: ComoService,
    private readonly settingsSelector: SettingsStoreSelector,
    private readonly userService: UserService,
    private readonly formBuilder: FormBuilder,
  ) {
    super();
    this.form = this.formBuilder.group({
      ['phone']: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    this.form.controls['phone']?.setValue(this.phone);
  }

  onRegister(): void {
    this.comoService
      .createComoUser$(this.form.controls['phone'].value)
      .pipe(
        tap(user => this.userService.setUser(user)),
        tap(() => this.goToKioskRegisterConfirmationStep.next()),
      )
      .subscribe({
        error: error => this.handleErrorResponse(error.error),
        complete: () => (this.isValidating = false),
      });
  }

  private handleErrorResponse(error: any): void {
    this.isValidating = false;
    switch (error.code) {
      case 103:
        this.errorMessage = 'como.create-account.errors.account-already-exists-phone';
        break;
      case 0:
        // Generic error
        this.errorMessage = 'como.errors.generic-error';
        break;
      case 101:
        // Como is already attached to user
        this.errorMessage = 'como.create-account.errors.user-has-loyalty-attached';
        break;
      case 102:
        // Phone number is missing
        break;
      case 1:
        // Not logged in
        break;
      case 3:
        // Como not enabled
        this.errorMessage = 'como.errors.loyalty-not-enabled';
        break;
      default:
        this.errorMessage = 'como.errors.generic-error';
    }
  }

  onKeypadNumberClick(number: number): void {
    const currentValue = this.form.controls.phone.value || '';
    this.form.controls.phone.setValue(currentValue + number.toString());
  }

  onKeypadDeleteClick(): void {
    const currentValue = this.form.controls.phone.value;
    this.form.controls.phone.setValue(currentValue.slice(0, -1)); // Supprime le dernier caractère
  }
}
