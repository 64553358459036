<div class="fixed w-full bottom-0 z-50">
  <div
    *ngFor="let notification of notifications"
    class="mx-5 my-4 shadow-S overflow-hidden"
    [@messageState]="{
      value: 'visible',
      params: {
        showTransformParams: showTransformOptions,
        hideTransformParams: hideTransformOptions,
        showTransitionParams: showTransitionOptions,
        hideTransitionParams: hideTransitionOptions,
      },
    }"
  >
    <app-notification-order-atom
      *ngIf="notification?.key === 'orderStatus'"
      (closeMessage)="close(notification)"
      (clickMessage)="click(notification)"
      [firstName]="notification?.data?.firstName"
      [shopName]="notification?.data?.shopName"
      [orderStatus]="notification?.data?.orderStatus"
    ></app-notification-order-atom>

    <app-notification-message-atom
      *ngIf="notification?.key === 'message'"
      (closeMessage)="close(notification)"
      (clickMessage)="click(notification)"
      [message]="notification?.data?.message"
    ></app-notification-message-atom>
  </div>
</div>
