import { Component, OnInit } from '@angular/core';
import { ModalScrollBlockBase } from '../../../core/base/modalScrollBlock/modal-scroll-block.base';
import { switchMap, take, tap } from 'rxjs/operators';
import { KioskService } from '../../../core/services/kiosk/kiosk.service';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';
import { IKiosk } from '../../../core/models/kiosk.model';
import { ModalsService } from '../../../core/services/modals/modals.service';

@Component({
  selector: 'app-kiosk-picker-modal',
  templateUrl: './kiosk-picker-modal.component.html',
})
export class KioskPickerModalComponent extends ModalScrollBlockBase implements OnInit {
  static handle = 'kiosk-picker-modal';
  kiosks: IKiosk[] = [];
  selectedKioskId?: string;
  isLoading = true;

  constructor(
    private readonly modalsService: ModalsService,
    private marketplaceSelector: MarketplaceStoreSelector,
    private readonly kioskService: KioskService,
  ) {
    super();
  }

  ngOnInit(): void {
    let currentKioskId = this.kioskService.getKioskIdFromStore();
    if (currentKioskId) {
      this.selectedKioskId = currentKioskId;
    }

    this.marketplaceSelector.selectMarketplaceId
      .pipe(
        take(1),
        switchMap(marketplaceId => this.kioskService.getKiosks$(marketplaceId)),
        tap(kiosks => {
          this.kiosks = kiosks;
          this.isLoading = false;
        }),
      )
      .subscribe();
  }

  onChangeKiosk(): void {}

  save(): void {
    if (!this.selectedKioskId) {
      return;
    }
    this.kioskService.saveKioskIdToStore(this.selectedKioskId);
    console.log('[Kiosk] Save new kiosk ID, reload page');
    document.location.reload();
  }

  close(): void {
    this.modalsService.close(KioskPickerModalComponent.handle);
  }
}
