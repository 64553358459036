<div class="text-center lg:text-left">
  <h1 class="mb-4 font-accent font-light text-2xl text-white">
    {{ heading }}
  </h1>
  <div class="flex justify-center lg:justify-start pb-8">
    <div
      *ngIf="facebookLink"
      class="mr-2"
    >
      <app-rounded-icon-button
        *ngIf="facebookLink"
        [size]="'sm'"
        [type]="'normal'"
        icon="icon-facebook"
        (click)="navigateTo(facebookLink)"
      ></app-rounded-icon-button>
    </div>
    <div
      *ngIf="twitterLink"
      class="mr-2"
    >
      <app-rounded-icon-button
        *ngIf="twitterLink"
        [size]="'sm'"
        [type]="'normal'"
        icon="icon-x"
        (click)="navigateTo(twitterLink)"
      ></app-rounded-icon-button>
    </div>
    <div
      *ngIf="linkedinLink"
      class="mr-2"
    >
      <app-rounded-icon-button
        *ngIf="instagramLink"
        [size]="'sm'"
        [type]="'normal'"
        icon="icon-linkedin"
        (click)="navigateTo(linkedinLink)"
      ></app-rounded-icon-button>
    </div>
    <div *ngIf="instagramLink">
      <app-rounded-icon-button
        *ngIf="instagramLink"
        [size]="'sm'"
        [type]="'normal'"
        icon="icon-instagram"
        (click)="navigateTo(instagramLink)"
      ></app-rounded-icon-button>
    </div>
  </div>
</div>
