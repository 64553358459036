<div class="animate-pulse h-4 bg-neutral-300 rounded w-1/4 mb-2"></div>
<div class="border border-neutral-300 rounded-md p-4 max-w-sm w-full mx-auto"></div>
<div class="grid grid-cols-2 gap-2 mt-2">
  <div>
    <div class="animate-pulse h-4 bg-neutral-300 rounded w-1/4 mb-2"></div>
    <div class="border border-neutral-300 rounded-md p-4 max-w-sm w-full mx-auto"></div>
  </div>
  <div>
    <div class="animate-pulse h-4 bg-neutral-300 rounded w-1/4 mb-2"></div>
    <div class="border border-neutral-300 rounded-md p-4 max-w-sm w-full mx-auto"></div>
  </div>
</div>
