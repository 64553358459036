import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';

import { DoodModalModel } from '@core/models/modal.model';
import { SELECT_MODALS_ALL, SELECT_MODALS_ENTITY } from '@store/modal';

@Injectable({ providedIn: 'root' })
export class ModalStoreSelector implements OnDestroy {
  private readonly _destroyerRef = new Subject<boolean>();

  modals: DoodModalModel[] = [];

  selectModals = this.store.select(SELECT_MODALS_ALL);
  selectModal = (id: string): Observable<DoodModalModel | undefined> =>
    this.store.select(SELECT_MODALS_ENTITY(id));

  constructor(private store: Store) {
    this.selectModals.pipe(takeUntil(this._destroyerRef)).subscribe(_modals => {
      this.modals = _modals;
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe();
  }

  private _unsubscribe(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
  }
}
