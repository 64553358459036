import { forkJoin } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { OrdersService } from '@core/services/orders/orders.service';
import {
  ORDER_STORE_ACTIVE_ACTIONS,
  ORDER_STORE_API_ACTIONS,
  ORDER_STORE_LOCAL_ACTIONS,
} from '@store/order/order.action';

@Injectable({
  providedIn: 'root',
})
export class OrderEffects {
  constructor(
    private _actions: Actions,
    private _ordersService: OrdersService,
  ) {}

  fetchOrders = createEffect(() => {
    return this._actions.pipe(
      ofType(ORDER_STORE_API_ACTIONS.fetchOrders),
      switchMap(({ userId, marketplaceId }) =>
        forkJoin({
          active: this._ordersService.getActiveOrders$(userId, marketplaceId),
          historical: this._ordersService.getHistoricalOrders$(userId, marketplaceId),
        }),
      ),
      mergeMap(({ active, historical }) => {
        return [
          ORDER_STORE_LOCAL_ACTIONS.updateIsLoading({ isLoading: true }),
          ORDER_STORE_API_ACTIONS.fetchOrdersSuccess({ orders: [...active, ...historical] }),
        ];
      }),
    );
  });

  fetchOrdersSuccess = createEffect(() => {
    return this._actions.pipe(
      ofType(ORDER_STORE_API_ACTIONS.fetchOrdersSuccess),
      mergeMap(({ orders }) => {
        return [
          ORDER_STORE_ACTIVE_ACTIONS.setAll({ entities: orders }),
          ORDER_STORE_LOCAL_ACTIONS.updateIsLoaded({ isLoaded: true }),
          ORDER_STORE_API_ACTIONS.fetchOrdersComplete(),
        ];
      }),
    );
  });

  fetchOrdersFailure = createEffect(() => {
    return this._actions.pipe(
      ofType(ORDER_STORE_API_ACTIONS.fetchOrdersFailure),
      mergeMap(({ error }) => {
        return [
          ORDER_STORE_LOCAL_ACTIONS.updateErrors({ errors: { error } }),
          ORDER_STORE_LOCAL_ACTIONS.updateIsLoaded({ isLoaded: false }),
          ORDER_STORE_API_ACTIONS.fetchOrdersComplete(),
        ];
      }),
    );
  });

  fetchOrdersComplete = createEffect(() => {
    return this._actions.pipe(
      ofType(ORDER_STORE_API_ACTIONS.fetchOrdersComplete),
      mergeMap(() => {
        return [ORDER_STORE_LOCAL_ACTIONS.updateIsLoading({ isLoading: false })];
      }),
    );
  });
}
