import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { ProductUtils } from '@app/utilities/product.util';
import { DoodProductModel, IAllProducts } from '@core/models/product.model';
import { ProductStoreDispatcher } from '@common/dispatchers/product.dispatcher';
import { MarketplaceProductsApiService } from '@core/services/api/marketplace-products/marketplace-products-api.service';

@Injectable({
  providedIn: 'root',
})
export class MarketplaceProductsService {
  constructor(
    private productDispatcher: ProductStoreDispatcher,
    private readonly marketplaceProductsApiService: MarketplaceProductsApiService,
  ) {}

  loadProducts$(marketplaceId: string): Observable<IAllProducts[]> {
    return this.marketplaceProductsApiService.getProducts$(marketplaceId).pipe(
      tap(_products => {
        const products = _products.reduce((_result, shop) => {
          return [..._result, ...ProductUtils.denormalizeShopProducts(shop)];
        }, Array<DoodProductModel>());
        this.productDispatcher.upsertMany(products);
      }),
    );
  }
}
