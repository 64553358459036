import { createHydratedReducer } from '@store/_abstract/hydrated';

import {
  BASKET_STORE_API_PIECE,
  BASKET_STORE_COMPONENT_PIECE,
  BASKET_STORE_LOCAL_PIECE,
} from './basket.piece';
import { BASKET_STORE_INITIAL_STATE, BASKET_STORE_KEY, BasketState } from './basket.state';

export const BASKET_STORE_REDUCER = createHydratedReducer<BasketState>({
  key: BASKET_STORE_KEY,
  initialState: BASKET_STORE_INITIAL_STATE,
  ons: [...BASKET_STORE_API_PIECE, ...BASKET_STORE_LOCAL_PIECE, ...BASKET_STORE_COMPONENT_PIECE],
});
