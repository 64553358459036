<div
  (click)="navigationClick.emit(entity)"
  class="flex justify-between bg-white rounded-s border py-2 px-4 items-center max-w-screen-sm min-h-20 h-full"
  [class.opacity-50]="!entity?.available || !isMarketplaceAvailable"
>
  <div class="mr-6">
    <p class="font-base text-base whitespace-pre-line">
      {{ entityName ? entityName : entity?.[entityKeys.Name] }}
    </p>
    <p
      *ngIf="displayEntityCategory"
      class="font-brand text-1xsl"
    >
      {{ entityCategory ? entityCategory : entity?.[entityKeys.ShortDescription] }}
    </p>
  </div>
  <div
    *ngIf="(uploadedImage?.url || shopImage) && displayEntityImage"
    class="w-16 h-16 flex-shrink-0"
  >
    <img
      [src]="uploadedImage?.url ? uploadedImage.url : shopImage"
      alt="img"
    />
  </div>
</div>
