import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';

import { ShopKeys } from '@config/keys/shop.keys';
import { ProductKeys } from '@config/keys/product.keys';

import { IAdditionGroup } from '@core/models/shop.model';
import { DoodProductModel } from '@core/models/product.model';

import { ProductTypeValues } from '@config/values/product.values';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { ShopStoreRefiner } from '@common/refiners/shop.refiner';

import { ModalsService } from '@core/services/modals/modals.service';
import { ProductService } from '@core/services/product/product.service';

import { ShopStoreSelector } from '@common/selectors/shop.selector';
import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { ContentStoreSelector } from '@common/selectors/content.selector';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { DeclinableStoreSelector } from '@common/selectors/declinable.selector';
import { DeclinableStoreDispatcher } from '@common/dispatchers/declinable.dispatcher';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-compound-item-modal',
  templateUrl: './compound-item-modal.component.html',
})
export class CompoundItemModalComponent extends DestroyerBase implements OnInit {
  static handle = 'compound-item-modal';

  @Input() headingColor: IContentBuilderFieldColor = {
    value: 'neutral-900',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() descriptionColor: IContentBuilderFieldColor = {
    value: 'neutral-700',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() inactiveStateColor: IContentBuilderFieldColor = {
    value: 'neutral-500',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() priceColor: IContentBuilderFieldColor = {
    value: 'neutral-700',
    type: ColorFieldTypesValues.Palette,
  };

  @Input() hideAdditionGroupDetails = false;

  index$: Observable<number> = this.modalSelector
    .selectModal(CompoundItemModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  compoundActiveItem$ = this.declinableSelector.selectActive;

  isMobileScreen$ = this.settingsSelector.selectDeviceIsMobileScreen;

  parentIdOfAdditions!: string;

  additions!: DoodProductModel[];

  additionGroup?: IAdditionGroup;

  groupId!: string;

  shopProducts!: DoodProductModel[];

  shopAdditions!: DoodProductModel[];

  constructor(
    private shopRefiner: ShopStoreRefiner,
    private shopSelector: ShopStoreSelector,
    private modalSelector: ModalStoreSelector,
    private contentSelector: ContentStoreSelector,
    private readonly modalsService: ModalsService,
    private settingsSelector: SettingsStoreSelector,
    private readonly productService: ProductService,
    private declinableSelector: DeclinableStoreSelector,
    private declinableDispatcher: DeclinableStoreDispatcher,
  ) {
    super();
  }

  ngOnInit(): void {
    const modal = this.contentSelector.productModal;
    if (modal && modal.shopId) {
      this.shopSelector.selectShop(modal.shopId).subscribe(shop => {
        if (shop?.[ShopKeys.Products]) {
          this.shopProducts = shop[ShopKeys.Products];
          this.shopAdditions = shop[ShopKeys.Products].filter(
            product => product[ProductKeys.Type] === ProductTypeValues.Addition,
          );
        }
      });

      this.shopRefiner.selectActiveShopProducts
        .pipe(
          take(1),
          map(products => {
            if (products) {
              this.shopProducts = products;
              this.shopAdditions = products.filter(
                product => product[ProductKeys.Type] === ProductTypeValues.Addition,
              );
            }
          }),
        )
        .subscribe();
    }
  }

  openAdditionsPanel(productInformation: { productId: string; groupId: string }): void {
    this.declinableSelector.selectActive
      .pipe(
        take(1),
        map(item => item?.products.find(el => el.id === productInformation.productId)),
        map(product => {
          let group = product?.additions_group_informations?.find(
            group => group.id === productInformation.groupId,
          );
          this.additions = [];

          if (group != undefined) {
            product?.additions.products.forEach(productId => {
              let found = group.items.find(item => item.id === productId);
              if (found != undefined) {
                this.additions.push(found);
              }
            });
          }
          return group;
        }),
        tap(group => (this.additionGroup = group)),
      )
      .subscribe();
    this.groupId = productInformation.groupId;
    this.parentIdOfAdditions = productInformation.productId;
    /*
    this.compoundItemQuery
      .selectActive()
      .pipe(
        take(1),
        map(
          item =>
            item?.products.find(el => el.id === productInformation.productId)
              ?.additions_group_informations,
        ),
        map(groups => {
          this.additionGroup = groups?.find(group => group.id === productInformation.groupId);
          this.additions = this.additionGroup?.items as DoodProductModel[];
        }),
      )
      .subscribe();
    */
    this.groupId = productInformation.groupId;
    this.parentIdOfAdditions = productInformation.productId;
  }

  onUpdateProducts(
    products: { products: string[]; parent: string; groupId?: string },
    storeId: string,
  ): void {
    this.productService.updateSelectedCompoundProduct(products.products, storeId);
  }

  onUpdateAdditions(
    products: { products: string[]; parent: string; groupId?: string },
    storeId: string,
  ): void {
    this.productService.updateSelectedAdditionsCompoundProduct(
      products.products,
      storeId,
      products.parent,
      this.shopAdditions,
      products?.groupId ?? '',
    );
  }

  onValidItem(storeId: string): void {
    const _active = this.declinableSelector.active;
    if (_active) {
      this.declinableDispatcher.mapOne({
        id: _active.store_id,
        map: _declinable => ({ ..._declinable, isVisited: true }),
      });
    }
    // this.compoundItemStore.updateActive({ isVisited: true });

    this.declinableSelector.selectDeclinables.pipe(take(1)).subscribe(_declinables => {
      const currentIndex = _declinables.findIndex(store => store.store_id === storeId);
      const nextStore = _declinables[(currentIndex + 1) % _declinables.length];
      if (nextStore && !nextStore?.isVisited) {
        this.declinableDispatcher.setActive(nextStore.store_id);
        this.modalsService.open(CompoundItemModalComponent.handle);
      }
    });
    /*
    const compoundStores: DoodDeclinableModel[] = this.compoundItemQuery.getAll();
    const currentIndex = compoundStores.findIndex(store => store.store_id === storeId);
    const nextStore = compoundStores[(currentIndex + 1) % compoundStores.length];
    if (nextStore && !nextStore?.isVisited) {
      this.compoundItemStore.setActive(nextStore.store_id);
      this.modalsService.open(CompoundItemModalComponent.handle);
    }
    */
  }

  onCloseModal(): void {
    this.modalsService.close(CompoundItemModalComponent.handle);
  }

  onCloseAdditions(): void {
    this.additions = [];
  }
}
