import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { keyBy } from 'lodash';

import {
  DoodProductModel,
  IProductAdditionGroupInformation,
  ISelectedCompoundItem,
} from '@core/models/product.model';
import { IAdditionGroup } from '@core/models/shop.model';

import { ProductKeys } from '@config/keys/product.keys';
import { AdditionGroupKeys } from '@config/keys/shop.keys';

import { ProductTypeValues } from '@config/values/product.values';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-compound-item-panel-atom',
  templateUrl: './compound-item-panel-atom.component.html',
  styleUrls: ['./compound-item-panel-atom.component.scss'],
})
export class CompoundItemPanelAtomComponent implements OnInit, OnChanges {
  @Input() products!: IProductAdditionGroupInformation[];
  @Input() title!: string;
  @Input() count!: number;
  @Input() minCount!: number;
  @Input() selected!: ISelectedCompoundItem[];
  @Input() parentIdOfAdditions?: string;
  @Input() isAdditionPanel = false;
  @Input() isMobile = false;
  @Input() groupId!: string;
  @Input() index!: number;
  @Input() storeId!: string;
  @Input() headingColor: IContentBuilderFieldColor = {
    value: 'neutral-900',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() descriptionColor: IContentBuilderFieldColor = {
    value: 'neutral-700',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() inactiveStateColor: IContentBuilderFieldColor = {
    value: 'neutral-500',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() priceColor: IContentBuilderFieldColor = {
    value: 'neutral-700',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() hideAdditionGroupDetails = false;

  @Output() openAdditionsPanel = new EventEmitter<{
    productId: string;
    groupId: string;
  }>();

  @Output() updateProducts = new EventEmitter<{
    products: string[];
    parent: string;
    groupId?: string;
  }>();

  @Output() outStoreId = new EventEmitter<string>();

  @Output() closeModal = new EventEmitter<Event>();

  selectedProducts: string[] = [];

  productKeys = ProductKeys;

  productTypeValues = ProductTypeValues;

  constructor() {}

  ngOnInit(): void {
    if (this.isAdditionPanel) {
      this.selectedProducts = this.selected
        ?.find(el => el.id === this.parentIdOfAdditions)
        ?.additions_by_group?.find(el => el[AdditionGroupKeys.Id] === this.groupId)
        ?.selected as string[];
    } else {
      this.selectedProducts = this.selected ? this.selected.map(el => el.id) : [];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.isAdditionPanel) {
      this.selectedProducts = this.selected ? this.selected.map(el => el.id) : [];
    }
  }

  toggleCheckbox(event: any): void {
    const productsIndexed = keyBy(this.products, 'id');
    const toggledProduct = productsIndexed[event.target.id];
    if (this.isAdditionPanel && !this.count) {
      if (event.target.checked) {
        if (this.selectedProducts?.length) {
          this.selectedProducts = [...this.selectedProducts, event.target.id];
          if (toggledProduct.min_count && toggledProduct.min_count > 1) {
            for (let i = 1; i < toggledProduct.min_count; i++) {
              this.selectedProducts.push(event.target.id);
            }
          }
        } else {
          this.selectedProducts = [event.target.id];
          if (toggledProduct.min_count && toggledProduct.min_count > 1) {
            for (let i = 1; i < toggledProduct.min_count; i++) {
              this.selectedProducts.push(event.target.id);
            }
          }
        }
      } else {
        this.selectedProducts = this.selectedProducts.filter(el => el !== event.target.id);
      }
    } else {
      if (event.target.checked) {
        if (
          this.selectedProducts?.length &&
          this.count !== null &&
          this.count <= this.selectedProducts?.length &&
          this.count < this.products.length
        ) {
          this.selectedProducts = [...this.selectedProducts, event.target.id];
          this.selectedProducts.shift();
        } else if (this.selectedProducts?.length) {
          if (
            this.selectedProducts?.length < this.count ||
            (this.selectedProducts?.length && this.count === null)
          ) {
            this.selectedProducts = [...this.selectedProducts, event.target.id];
          } else {
            event.target.checked = false;
          }
        } else {
          this.selectedProducts = [event.target.id];
        }
      } else {
        if (this.selectedProducts.length === 1) {
          this.selectedProducts = [];
        } else {
          this.selectedProducts = this.selectedProducts.filter(
            productId => productId !== event.target.id,
          );
        }
      }
    }

    this.updateProducts.emit({
      products: this.selectedProducts,
      parent: this.parentIdOfAdditions as string,
      groupId: this.groupId,
    });
  }

  openAdditions(product: DoodProductModel, groupId: string): void {
    if (!this.selectedProducts.includes(product[ProductKeys.Id])) {
      if (
        this.selectedProducts?.length &&
        this.count <= this.selectedProducts?.length &&
        this.count < this.products.length
      ) {
        this.selectedProducts = [...this.selectedProducts, product[ProductKeys.Id]];

        this.selectedProducts.shift();
        this.updateProducts.emit({
          products: [...this.selectedProducts],
          parent: this.parentIdOfAdditions as string,
          groupId: this.groupId,
        });
      } else {
        this.updateProducts.emit({
          products: [...this.selectedProducts, product[ProductKeys.Id]],
          parent: this.parentIdOfAdditions as string,
          groupId: this.groupId,
        });
      }
    }
    this.openAdditionsPanel.emit({
      productId: product[ProductKeys.Id],
      groupId,
    });
  }

  isChecked(id: string): boolean {
    return this.selectedProducts?.some(product => product === id);
  }

  checkIfGroupIsNotValid(): boolean {
    let isNotValid = false;
    if (
      (this.minCount > 0 && !this.selected?.length) ||
      this.selected?.length < this.minCount ||
      (this.count > 0 && this.count !== null && this.selected?.length > this.count)
    ) {
      isNotValid = true;
    }
    this.products.forEach(product => {
      this.selected?.forEach(el => {
        if (product[ProductKeys.Id] === el.id) {
          product.additions_group_informations?.map(group => {
            if (group[AdditionGroupKeys.MinCount] > 0 && !group.isValid) {
              isNotValid = true;
            }
          });
        }
      });
    });

    return isNotValid;
  }

  calculateNbProduct(product: string): number {
    if (!this.isAdditionPanel) {
      const selected = this.selected.find(el => el.id === this.parentIdOfAdditions)?.additions;
      if (selected) {
        return selected ? selected.filter(el => el === product).length : 1;
      }

      return this.selected.filter(el => el.id === product).length;
    } else {
      const additionsByGroup = this.selected.find(
        el => el.id === this.parentIdOfAdditions,
      )?.additions_by_group;
      if (Array.isArray(additionsByGroup)) {
        const selectedAdditions = additionsByGroup?.find(
          el => el[AdditionGroupKeys.Id] === this.groupId,
        )?.selected;
        return selectedAdditions?.filter(el => el === product).length
          ? selectedAdditions?.filter(el => el === product).length
          : 1;
      } else {
        if (additionsByGroup) {
          return (additionsByGroup as IAdditionGroup).selected?.filter(el => el === product).length
            ? ((additionsByGroup as IAdditionGroup).selected?.filter(el => el === product)
                .length as number)
            : 1;
        }
      }
      return 1;
    }
  }

  changeNbProduct(value: number, product: string): void {
    if (!this.isAdditionPanel) {
      this.changeNbOfProduct(value, product);
    } else {
      this.changeNbProductOfAdditions(value, product);
    }
  }

  onValidate(): void {
    document.getElementById('container')?.scrollTo(0, 0);
    this.closeModal.emit();
    this.outStoreId.emit(this.storeId);
  }

  private changeNbOfProduct(value: number, product: string): string[] {
    let duplicate = this.selectedProducts?.filter(el => el === product).length;

    let copyOfSelectedProducts: string[] = [];
    this.selectedProducts?.forEach(el => {
      if (el === product && duplicate < value) {
        copyOfSelectedProducts = Object.assign([], this.selectedProducts);
        copyOfSelectedProducts.push(product);
        duplicate = duplicate + 1;
      } else if (el === product && duplicate > value) {
        const index = this.selectedProducts.indexOf(product);
        if (index > -1) {
          copyOfSelectedProducts = Object.assign([], this.selectedProducts);
          copyOfSelectedProducts.splice(index, 1);
        }
        duplicate = duplicate - 1;
      }
    });
    this.updateProducts.emit({
      products: copyOfSelectedProducts,
      parent: this.parentIdOfAdditions as string,
    });
    return copyOfSelectedProducts;
  }

  private changeNbProductOfAdditions(value: number, product: string): string[] {
    const countOccurrences = this.selectedProducts?.filter(el => el === product).length;
    let copyOfSelectedProducts: string[] = [];
    if (countOccurrences < value) {
      copyOfSelectedProducts = Object.assign([], this.selectedProducts);
      copyOfSelectedProducts.push(product);
    } else if (countOccurrences > value) {
      const index = this.selectedProducts.findIndex(el => el === product);
      copyOfSelectedProducts = Object.assign([], this.selectedProducts);
      copyOfSelectedProducts.splice(index, 1);
    }
    this.selectedProducts = copyOfSelectedProducts;
    this.updateProducts.emit({
      products: copyOfSelectedProducts,
      parent: this.parentIdOfAdditions as string,
      groupId: this.groupId,
    });
    return this.selectedProducts;
  }

  forceType(data: any): any {
    return data as any;
  }
}
