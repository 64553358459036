import { Component } from '@angular/core';
import { PaymentMethodHandles } from '@config/payment-methods.config';

@Component({
  selector: 'app-payment-panel-stripe',
  templateUrl: './payment-panel-stripe.component.html',
})
export class PaymentPanelStripeComponent {
  paymentMethodHandles = PaymentMethodHandles;
}
