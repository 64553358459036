<div
  [style.z-index]="index + 50"
  class="fixed top-0 bottom-0 left-0 right-0 h-screen"
>
  <div
    (click)="onClicked($event)"
    (keydown.escape)="onClicked($event)"
    [ngClass]="{
      'backdrop-blur': withBackdrop,
      'absolute inset-0 h-screen w-screen': true,
    }"
  ></div>
  <div class="relative h-screen w-screen pointer-events-none">
    <ng-content></ng-content>
  </div>
</div>
