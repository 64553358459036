import { ReducerTypes, ActionCreator, on } from '@ngrx/store';

import { DoodDeclinableModel } from '@core/models/product.model';
import { createActiveStorePiece } from '@store/_abstract/active';

import {
  DeclinableState,
  DECLINABLE_STORE_ACTIVE_ADAPTER,
  DECLINABLE_STORE_INITIAL_STATE,
} from './declinable.state';
import {
  DECLINABLE_STORE_ACTIVE_ACTIONS,
  DECLINABLE_STORE_LOCAL_ACTIONS,
} from './declinable.action';

export const DECLINABLE_STORE_API_PIECE: ReducerTypes<DeclinableState, readonly ActionCreator[]>[] =
  [];

export const DECLINABLE_STORE_LOCAL_PIECE: ReducerTypes<
  DeclinableState,
  readonly ActionCreator[]
>[] = [
  // Status
  on(DECLINABLE_STORE_LOCAL_ACTIONS.updateIsLoaded, (state, { isLoaded }): DeclinableState => {
    return { ...state, isLoaded };
  }),
  on(DECLINABLE_STORE_LOCAL_ACTIONS.updateIsLoading, (state, { isLoading }): DeclinableState => {
    return { ...state, isLoading };
  }),
  // Error
  on(DECLINABLE_STORE_LOCAL_ACTIONS.updateError, (state, { error }): DeclinableState => {
    return { ...state, error };
  }),
  on(DECLINABLE_STORE_LOCAL_ACTIONS.resetError, (state): DeclinableState => {
    return { ...state, error: null };
  }),
  // Misc
  on(DECLINABLE_STORE_LOCAL_ACTIONS.reset, (): DeclinableState => {
    return DECLINABLE_STORE_INITIAL_STATE;
  }),
];

export const DECLINABLE_STORE_ACTIVE_PIECE = createActiveStorePiece<
  DeclinableState,
  DoodDeclinableModel
>({
  adapter: DECLINABLE_STORE_ACTIVE_ADAPTER,
  actions: DECLINABLE_STORE_ACTIVE_ACTIONS,
});
