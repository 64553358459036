import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {IContentBuilderFieldColor} from "@core/models/content-builder-fields.model";
import {ColorFieldTypesValues} from "@config/values/color-field-types.values";
@Component({
  selector: 'app-kiosk-active-button-atom',
  templateUrl: './kiosk-active-button-atom.component.html',
  styleUrls: ['./kiosk-active-button-atom.component.scss'],
})
export class KioskActiveButtonAtomComponent {
  @Input() activeBackground: IContentBuilderFieldColor = {
    value: 'primary-800',
    type: ColorFieldTypesValues.Hex,
  };
  @Input() activeTextColor: IContentBuilderFieldColor = {
    value: '#FFFFFF',
    type: ColorFieldTypesValues.Hex,
  };
  @Input() inactiveBackground: IContentBuilderFieldColor = {
    value: 'transparent',
    type: ColorFieldTypesValues.Hex,
  };
  @Input() inactiveTextColor: IContentBuilderFieldColor = {
    value: 'primary-800',
    type: ColorFieldTypesValues.Palette,
  };


  @Input() label?: string;
  @Input() isActive = false;

  @Output() clickEmitter = new EventEmitter<any>();
}
