import { createReducer } from '@ngrx/store';

import {
  PAYMENT_STORE_API_PIECE,
  PAYMENT_STORE_COMPONENT_PIECE,
  PAYMENT_STORE_LOCAL_PIECE,
} from './payment.piece';
import { PAYMENT_STORE_INITIAL_STATE } from './payment.state';

export const PAYMENT_STORE_REDUCER = createReducer(
  PAYMENT_STORE_INITIAL_STATE,
  ...PAYMENT_STORE_API_PIECE,
  ...PAYMENT_STORE_LOCAL_PIECE,
  ...PAYMENT_STORE_COMPONENT_PIECE,
);
