<div class="px-5 pb-6 lg:px-8 xl:px-12">
  <div
    class="my-3 mix-blend-multiply lg:hidden"
    *ngIf="displayAddressSearchInput"
  >
    <app-shop-search-location-input></app-shop-search-location-input>
  </div>
  <div class="flex items-center lg:justify-between">
    <div
      *ngIf="this.displayTitle"
      class="hidden lg:block"
    >
      <h2 class="font-accent text-4.5xl font-bold text-neutral-800">
        {{ title | translate }}
      </h2>
    </div>
    <div class="flex items-center">
      <app-button-atom
        *ngIf="displayFiltersButton"
        [icon]="'icon-filter'"
        [size]="'S'"
        [type]="'light'"
        [label]="
          (nbFilters$ | async)
            ? ('shop-list-header.filter' | translate) + ' (' + (nbFilters$ | async) + ')'
            : ('shop-list-header.filter' | translate)
        "
        [color]="'primary'"
        class="mr-2"
        (clickEventEmitter)="openFilters()"
      ></app-button-atom>
      <app-button-atom
        *ngIf="displayMapButton"
        class="ml-2 hidden lg:block"
        [type]="'primary'"
        [icon]="'icon-distance'"
        [size]="'S'"
        [color]="'primary'"
        [overrideBgColor]="shopsMapButtonColor"
        [overrideTextColor]="shopsMapButtonTextColor"
        [label]="'shop-list-header.show-map' | translate"
        (clickEventEmitter)="navigateToMapPage()"
      ></app-button-atom>
    </div>
  </div>
  <div>
    <ng-container *ngIf="selectedFilters$ | async as filters">
      <div
        *ngIf="filters.length"
        class="mt-6"
      >
        <div
          class="inline-block"
          *ngFor="let filterValues of filters"
        >
          <div
            class="mb-3 mr-3 inline-block"
            *ngFor="let filter of filterValues.values"
          >
            <app-chip-atom
              [label]="filter.label"
              [value]="filter.value"
              [id]="filterValues.id"
              (removeChip)="removeFilter(filterValues, $event)"
            ></app-chip-atom>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="selectedCategories$ | async as categories">
      <div
        *ngIf="categories.length"
        class="mt-6"
      >
        <div
          class="mb-3 mr-3 inline-block"
          *ngFor="let category of categories"
        >
          <app-chip-atom
            [label]="category.name"
            [value]="category"
            [id]="category.id"
            (removeChip)="removeCategoryFilter($event)"
          ></app-chip-atom>
        </div>
      </div>
    </ng-container>
  </div>
</div>
