import {
  DiscoverResult,
  ErrorResponse,
  ICancelResponse,
  IPaymentIntent,
  ISdkManagedPaymentIntent,
  loadStripeTerminal,
  Reader,
  Terminal,
} from '@stripe/terminal-js';
import { Injectable } from '@angular/core';
import { map, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, of, throwError } from 'rxjs';

import {
  IPaymentServiceStripe,
  IStripeTerminalConnectionTokenResponse,
  StripeTerminalReaderConnectionStatus,
} from '@core/models/stripe.model';
import { IStripePaymentIntent } from '@core/models/payment.model';
import { IKioskHardwareStripeTerminal } from '@core/models/kiosk.model';

import { ModalsService } from '@core/services/modals/modals.service';
import { StripeTerminalApiService } from '@core/services/api/stripe-terminal/stripe-terminal-api.service';

import { DoodOrderModel } from '@store/order/order.model';
import { DoodApiStripePaymentIntentResponse } from '@shared/interfaces/stripe.interface';
import { StripeTerminalReaderPickerModalComponent } from '@shared/modals/stripe-terminal-reader-picker-modal/stripe-terminal-reader-picker-modal.component';

@Injectable({
  providedIn: 'root',
})
export class StripeTerminalService {
  private terminal?: Terminal;
  private selectedReaderId?: string;
  public readerConnectionChanged$ = new BehaviorSubject<StripeTerminalReaderConnectionStatus>(
    StripeTerminalReaderConnectionStatus.DISCONNECTED,
  );
  private selectedReader: Reader | undefined;
  private hardware?: IKioskHardwareStripeTerminal;

  constructor(
    private readonly stripeTerminalApiService: StripeTerminalApiService,
    private readonly modalsService: ModalsService,
  ) {}

  connectToReader(hardware: IKioskHardwareStripeTerminal): void {
    this.hardware = hardware;
    if (hardware.simulated_terminal) {
      this.selectedReaderId = 'SIMULATOR';
    } else {
      this.selectedReaderId = hardware.reader_id;
    }
    this.readerConnectionChanged$.subscribe(readerConnectionStatus => {
      console.log(
        '[Stripe Terminal] Reader connection status changed to ' + readerConnectionStatus,
      );
      if (readerConnectionStatus === StripeTerminalReaderConnectionStatus.DISCONNECTED) {
        this.connectToReader$().subscribe();
      }
    });
  }

  public getConnectionToken$(): Observable<IStripeTerminalConnectionTokenResponse> {
    return this.stripeTerminalApiService.getConnectionToken$();
  }

  public capturePayment$(order: DoodOrderModel): Observable<DoodApiStripePaymentIntentResponse> {
    return this.stripeTerminalApiService.capturePayment$(order.id);
  }

  public getStripeTerminal$(): Observable<Terminal> {
    if (this.terminal) {
      return of(this.terminal);
    }
    return from(loadStripeTerminal()).pipe(
      map(StripeTerminal => {
        if (!StripeTerminal) {
          throw new Error('Unable to load Stripe Terminal');
        }

        this.terminal = StripeTerminal.create({
          onFetchConnectionToken: () => {
            return this.getConnectionToken$()
              .toPromise()
              .then(result => result?.secret ?? '');
          },
          onUnexpectedReaderDisconnect: () => {
            this.readerConnectionChanged$.next(StripeTerminalReaderConnectionStatus.DISCONNECTED);
          },
        });

        return this.terminal;
      }),
    );
  }

  connectToReader$(): Observable<unknown> {
    console.log('[Stripe Terminal] Connecting to reader ' + this.selectedReaderId);
    return this.getStripeTerminal$().pipe(
      switchMap(terminal => this.discoverReaders$(terminal)),
      switchMap(discoverResult => {
        console.log('[Stripe Terminal] Discover readers result', discoverResult);
        if (!this.selectedReaderId) {
          console.log('[Stripe Terminal] No reader selected yet');
          this.modalsService.open(StripeTerminalReaderPickerModalComponent.handle);
          return of(false);
        }
        if (!('discoveredReaders' in discoverResult)) {
          console.log('[Stripe Terminal] No reader found');
          return of(false);
        }
        this.selectedReader = discoverResult.discoveredReaders.find(
          r => r.id === this.selectedReaderId,
        );
        if (!this.selectedReader) {
          console.log('[Stripe Terminal] Selected reader is not available', this.selectedReaderId);
          this.modalsService.open(StripeTerminalReaderPickerModalComponent.handle);
          return of(false);
        }
        console.log('[Stripe Terminal] Selected reader is ' + this.selectedReader?.id);

        return this.connectSelectedReader$();
      }),
    );
  }

  selectReader$(id?: string): Observable<unknown> {
    this.selectedReaderId = id;
    return this.connectToReader$();
  }

  private discoverReaders$(terminal: Terminal): Observable<DiscoverResult | ErrorResponse> {
    const config: Record<string, unknown> = {};
    if (this.hardware?.simulated_terminal) {
      config.simulated = true;
      terminal.setSimulatorConfiguration({
        testPaymentMethod: 'visa',
        testCardNumber: '4242424242424242',
      });
    } else {
      config.statusMessage = false;
      config.location = this.hardware?.stripe_location_id;
    }

    return from(terminal.discoverReaders(config)).pipe(
      map(discoverResult => {
        if ('error' in discoverResult && discoverResult.error) {
          console.log('[Stripe Terminal] Failed to discover: ', discoverResult.error);
        } else if (
          'discoveredReaders' in discoverResult &&
          discoverResult.discoveredReaders.length === 0
        ) {
          console.log('[Stripe Terminal] No available readers.');
        }

        return discoverResult;
      }),
    );
  }

  private connectSelectedReader$(): Observable<
    | ErrorResponse
    | {
        reader: Reader;
      }
  > {
    console.log('[Stripe Terminal] Connecting to selected reader');

    return this.disconnectIfNeeded$().pipe(
      switchMap(() => {
        if (!this.selectedReader) {
          console.log('[Stripe Terminal] No reader to connect');
          throw new Error('No reader connected');
        }
        if (!this.terminal) {
          throw new Error('Terminal is null');
        }
        return from(this.terminal.connectReader(this.selectedReader));
      }),
      tap(connectResult => {
        if ('reader' in connectResult) {
          console.log('[Stripe Terminal] Connected to selected reader');
          this.readerConnectionChanged$.next(StripeTerminalReaderConnectionStatus.CONNECTED);
          return;
        }
        console.log('[Stripe Terminal] Error while connecting to selected reader', connectResult);
        this.readerConnectionChanged$.next(StripeTerminalReaderConnectionStatus.DISCONNECTED);
      }),
    );
  }

  /*
  disconnectIfNeeded$(): Observable<any> {
    if (!this.terminal) {
      return of(false);
    }

    if (this.terminal.getConnectionStatus() === 'connected') {
      from(this.terminal.disconnectReader());
    }

    return of(true);
  }
  */

  disconnectIfNeeded$(): Observable<boolean> {
    return of(!!this.terminal).pipe(
      switchMap(_isTerminal => {
        if (_isTerminal && this.terminal) {
          return from(this.terminal.disconnectReader()).pipe(map(() => true));
        }
        return of(_isTerminal);
      }),
    );
  }

  collectPayment$(
    paymentIntent: IStripePaymentIntent,
  ): Observable<ErrorResponse | { paymentIntent: ISdkManagedPaymentIntent }> {
    if (!this.terminal) {
      throw new Error('Terminal is null');
    }
    if (!paymentIntent.payment_intent_client_secret) {
      throw new Error('Payment intent client secret is missing');
    }
    return from(this.terminal.collectPaymentMethod(paymentIntent.payment_intent_client_secret));
  }

  processPayment$(
    paymentIntent: ISdkManagedPaymentIntent,
  ): Observable<ErrorResponse | { paymentIntent: IPaymentIntent }> {
    if (!this.terminal) {
      throw new Error('Terminal is null');
    }
    return from(this.terminal.processPayment(paymentIntent));
  }

  setConfig(config: IPaymentServiceStripe | undefined): void {}

  cancelCollectPaymentMethod$(): Observable<ErrorResponse | ICancelResponse | boolean> {
    if (!this.terminal) {
      return throwError(() => 'Terminal is not connected');
    }
    if (this.terminal.getPaymentStatus() === 'waiting_for_input') {
      return from(this.terminal.cancelCollectPaymentMethod());
    }
    return of(true);
  }
}
