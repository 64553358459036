import { Observable, of, Subject } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { ShopKeys } from '@config/keys/shop.keys';
import { ProductKeys } from '@config/keys/product.keys';

import { DoodShopModel } from '@core/models/shop.model';
import { IZerosixAsset } from '@core/models/zerosix.model';
import { DoodProductModel } from '@core/models/product.model';

import { CartService } from '@core/services/cart/cart.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { ZerosixService } from '@core/services/zerosix/zerosix.service';

import { CartStoreSelector } from '@common/selectors/cart.selector';
import { ShopStoreSelector } from '@common/selectors/shop.selector';
import { ModalStoreSelector } from '@common/selectors/modal.selector';

import { ContentStoreDispatcher } from '@common/dispatchers/content.dispatcher';
import { ModalScrollBlockBase } from '@core/base/modalScrollBlock/modal-scroll-block.base';
import { ProductAddModalComponent } from '@shared/modals/product-add-modal/product-add-modal.component';

@Component({
  selector: 'app-zerosix-offered-product-picker-modal',
  templateUrl: './zerosix-offered-product-picker-modal.component.html',
})
export class ZerosixOfferedProductPickerModalComponent
  extends ModalScrollBlockBase
  implements OnInit, OnDestroy
{
  private _destroyerRef = new Subject<boolean>();

  static handle = 'zerosix-offered-product-picker-modal';
  static onProductSelected?: () => void;
  static asset?: IZerosixAsset;

  index$: Observable<number> = this.modalSelector
    .selectModal(ZerosixOfferedProductPickerModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 6)));

  products: DoodProductModel[] = [];
  private shopId?: string;

  constructor(
    private cartSelector: CartStoreSelector,
    private shopSelector: ShopStoreSelector,
    private modalSelector: ModalStoreSelector,
    private readonly cartService: CartService,
    private readonly modalsService: ModalsService,
    private readonly zerosixService: ZerosixService,
    private contentDispatcher: ContentStoreDispatcher,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getShopFromCart$()
      .pipe(
        takeUntil(this._destroyerRef),
        switchMap(shopId => this.getAllowedProducts$(shopId)),
      )
      .subscribe();
    this.getAllowedProducts$();
  }

  ngOnDestroy(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
    super.ngOnDestroy();
  }

  private getShopFromCart$(): Observable<undefined | string> {
    return this.cartSelector.selectActive.pipe(map(cart => cart?.shop));
  }

  private getAllowedProducts$(shopId?: string): Observable<DoodShopModel | undefined> {
    if (!shopId) {
      return of(undefined);
    }

    this.shopId = shopId;

    return this.shopSelector.selectShop(shopId).pipe(
      takeUntil(this._destroyerRef),
      tap(shop => {
        if (!shop) {
          this.products = [];
          return;
        }

        this.products = shop[ShopKeys.Products].filter(p => {
          const externalId = p[ProductKeys.ExternalId];
          if (externalId) {
            return ZerosixOfferedProductPickerModalComponent.asset?.external_ids?.includes(
              externalId,
            );
          }
          return false;
        });
      }),
    );
  }

  close(): void {
    this.modalsService.close(ZerosixOfferedProductPickerModalComponent.handle);
  }

  onNavigateTo(product: DoodProductModel): void {
    if (!this.shopId) {
      return;
    }

    this.contentDispatcher.updateProduct({
      productId: product.id,
      shopIsNotActive: false,
      shopId: this.shopId,
    });
    this.close();
    ProductAddModalComponent.allowAddToCart = true;
    ProductAddModalComponent.onAdd = (): void => {
      this.close();
      if (ZerosixOfferedProductPickerModalComponent.onProductSelected) {
        ZerosixOfferedProductPickerModalComponent.onProductSelected();
      }
      ProductAddModalComponent.onAdd = (): void => {};
    };
    this.modalsService.open(ProductAddModalComponent.handle);
  }

  addVoucherWithoutProduct(): void {
    this.close();
    if (ZerosixOfferedProductPickerModalComponent.onProductSelected) {
      ZerosixOfferedProductPickerModalComponent.onProductSelected();
    }
  }

  cancel(): void {
    const asset = ZerosixOfferedProductPickerModalComponent.asset;
    if (!asset) {
      this.close();
      return;
    }
    const coupon = this.zerosixService.calculateCouponFromAsset(asset);
    if (!coupon) {
      return;
    }
    this.cartService.removeCoupon(coupon);
    this.close();
  }
}
