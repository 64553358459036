import { IShopVenue } from '@core/models/shop.model';
import { VenueAddressKeys, VenueKeys } from 'src/app/config/keys/venue.keys';
import { DoodVenueModel } from 'src/app/core/models/venue.model';

export class VenueUtils {
  static getVenueAddressString(venue: DoodVenueModel | IShopVenue): string | undefined {
    return `${venue[VenueKeys.Address]?.[VenueAddressKeys.StreetAddress]?.[0]},
        ${venue[VenueKeys.Address]?.[VenueAddressKeys.PostCode]},
        ${venue[VenueKeys.Address]?.[VenueAddressKeys.City]}
        `;
  }
}
