import { get } from 'lodash';
import { Injectable } from '@angular/core';

import {
  IOrderTypeCapabilities,
  ORDER_TYPES_CAPABILITIES,
} from '@config/order-types-capabilities.config';

import { MarketplaceCartScopeValues } from '@config/values/marketplace.values';
import { DeliveryDistributionModes, OrderTypeValues } from '@config/values/order.values';

import { ShopStoreSelector } from '@common/selectors/shop.selector';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

import { DistributionModeKeys, MarketplaceKeys, ShopKeys } from '@config/keys/shop.keys';

import { DoodMarketplaceModel } from '@core/models/marketplace.model';
import { IDistributionMode, IPreOrderingDelay, DoodShopModel } from '@core/models/shop.model';

@Injectable({
  providedIn: 'root',
})
export class OrderTypeService {
  private readonly defaultCapabilities: IOrderTypeCapabilities = {
    label: '',
    icon: '',
    useOpenHours: false,
    displaySlot: true,
    preorderingAllowed: false,
    isDeliveryAddressMandatory: false,
    isOnSiteLocationMandatory: false,
    isMobilePhoneMandatory: false,
  };

  private readonly defaultPreOrdeingDelays: IPreOrderingDelay = {
    [DistributionModeKeys.PreorderingMinDelay]: 0,
    [DistributionModeKeys.PreorderingMaxDelay]: 2419200,
  };

  constructor(
    private shopSelector: ShopStoreSelector,
    private marketplaceSelector: MarketplaceStoreSelector,
  ) {}

  getCapabilities(orderType?: string, shop = this.shopSelector.active): IOrderTypeCapabilities {
    const marketplace = this.marketplaceSelector.marketplace;

    if (!orderType) {
      return this.defaultCapabilities;
    }

    let distributionMode;
    let distributionModePreorderingAllowed;

    if (shop && marketplace[MarketplaceKeys.CartScope] === MarketplaceCartScopeValues.Shop) {
      distributionMode = this.getShopDistributionModes(shop, orderType);
    }

    if (shop && marketplace[MarketplaceKeys.CartScope] === MarketplaceCartScopeValues.Marketplace) {
      distributionMode = this.getMarketplaceDistributionModes(marketplace, orderType);
    }

    if (!shop && marketplace) {
      distributionMode = this.getMarketplaceDistributionModes(marketplace, orderType);
    }

    if (distributionMode) {
      distributionModePreorderingAllowed = this.getPreorderingAllowed(distributionMode);
    }

    const capabilities = get(ORDER_TYPES_CAPABILITIES, orderType, this.defaultCapabilities);
    if (distributionModePreorderingAllowed) {
      capabilities.preorderingAllowed = true;
      // Force display slot if preordering is allowed
      capabilities.displaySlot = true;
    }

    const customName = distributionMode?.[DistributionModeKeys.CustomName];
    if (customName) {
      capabilities.label = customName;
    }

    return capabilities;
  }

  getPreOrderDelays(orderType?: string): IPreOrderingDelay {
    if (!orderType) {
      return this.defaultPreOrdeingDelays;
    }
    const shop = this.shopSelector.active;

    const marketplace = this.marketplaceSelector.marketplace;

    const isMultiBasket =
      this.marketplaceSelector.marketplace.cart_scope === MarketplaceCartScopeValues.Marketplace;

    let shopDistributionMode: IDistributionMode;

    if (shop && !isMultiBasket) {
      shopDistributionMode = this.getShopDistributionModes(shop, orderType);
      return {
        [DistributionModeKeys.PreorderingMinDelay]:
          shopDistributionMode?.[DistributionModeKeys.PreorderingMinDelay],
        [DistributionModeKeys.PreorderingMaxDelay]:
          shopDistributionMode?.[DistributionModeKeys.PreorderingMaxDelay],
      };
    }

    if (marketplace && isMultiBasket) {
      const marketplaceDistributionMode = this.getMarketplaceDistributionModes(
        marketplace,
        orderType,
      );
      return {
        [DistributionModeKeys.PreorderingMinDelay]:
          marketplaceDistributionMode[DistributionModeKeys.PreorderingMinDelay],
        [DistributionModeKeys.PreorderingMaxDelay]:
          marketplaceDistributionMode[DistributionModeKeys.PreorderingMaxDelay],
      };
    }

    return this.defaultPreOrdeingDelays;
  }

  private getShopDistributionModes(shop: DoodShopModel, orderType: string): IDistributionMode {
    const matchingDistributionModes = shop[ShopKeys.DistributionModes].filter(mode => {
      if (mode.type === orderType) {
        return true;
      }

      return (
        orderType === OrderTypeValues.Delivery && DeliveryDistributionModes.includes(mode.type)
      );
    });

    return matchingDistributionModes?.[0];
  }

  public convertOrderTypeToDistributionModes(orderType: string): string[] {
    if (orderType === OrderTypeValues.Delivery) {
      return DeliveryDistributionModes;
    }

    return [orderType];
  }

  private getMarketplaceDistributionModes(
    marketplace: DoodMarketplaceModel,
    orderType: string,
  ): IDistributionMode {
    let matchingDistributionModes = marketplace[ShopKeys.DistributionModes].filter(mode => {
      if (mode.type === orderType) {
        return true;
      }

      return (
        orderType === OrderTypeValues.Delivery && DeliveryDistributionModes.includes(mode.type)
      );
    });

    if (!matchingDistributionModes?.length) {
      matchingDistributionModes = marketplace[MarketplaceKeys.DistributionModes].filter(
        mode => mode[DistributionModeKeys.Enabled],
      );
    }

    return matchingDistributionModes?.[0];
  }

  private getPreorderingAllowed(distributionMode: IDistributionMode): boolean {
    return distributionMode[DistributionModeKeys.PreorderingAllowed];
  }
}
