<app-button-atom
  size="L"
  [label]="text ?? ''"
  type="primary"
  [full]="true"
  (click)="click()"
  [iconUrl]="icon?.url ?? ''"
  *ngIf="displayThisLink"
>
</app-button-atom>
