<ng-container *ngIf="(authenticationStep$ | async) === steps.login">
  <app-authentication-home-step
    [userJourneyType]="userJourneyType"
    [loginStepHeading]="loginStepHeading"
    [firstStepHeading]="firstStepHeading"
    [firstStepLoginButton]="firstStepLoginButton"
    [firstStepSignupButton]="firstStepSignupButton"
    [authenticationProviderGoogle]="authenticationProviderGoogle"
    [authenticationProviderFacebook]="authenticationProviderFacebook"
    [authenticationProviderApple]="authenticationProviderApple"
  ></app-authentication-home-step>
</ng-container>
<ng-container *ngIf="(authenticationStep$ | async) === steps.signup">
  <app-authentication-signup-step
    [userJourneyType]="userJourneyType"
    [legalCheckboxLabel]="legalCheckboxLabel"
    [optinCheckboxLabel]="optinCheckboxLabel"
    [signupStepHeading]="signupStepHeading"
    [signupStepSubheading]="signupStepSubheading"
    [signupStepDisplayFirstname]="signupStepDisplayFirstname"
    [signupStepFirstnameLabel]="signupStepFirstnameLabel"
    [signupStepFirstnameHelp]="signupStepFirstnameHelp"
    [signupStepDisplayLastname]="signupStepDisplayLastname"
    [signupStepLastnameLabel]="signupStepLastnameLabel"
    [signupStepLastnameHelp]="signupStepLastnameHelp"
    [signupStepEmailLabel]="signupStepEmailLabel"
    [signupStepEmailHelp]="signupStepEmailHelp"
    [signupStepDisplayPhone]="signupStepDisplayPhone"
    [signupStepPhoneLabel]="signupStepPhoneLabel"
    [signupStepPhoneHelp]="signupStepPhoneHelp"
    [signupStepPasswordLabel]="signupStepPasswordLabel"
    [signupStepPasswordHelp]="signupStepPasswordHelp"
    [signupFooter]="signupFooter"
  ></app-authentication-signup-step>
</ng-container>
