<div
  class="fixed bottom-0 left-0 right-0 w-full bg-white shadow shadow-top lg:hidden kiosk:block"
  [style.z-index]="index$ | async"
>
  <div *ngIf="errorMessages">
    <p
      class="mx-5 mt-5 rounded bg-error-50 px-4 py-3 font-base text-sm text-error-700"
      *ngFor="let message of errorMessages"
    >
      {{ message }}
    </p>
    <div *ngIf="missingLocationIdError">
      <p class="mx-5 mt-5 rounded bg-error-50 px-4 py-3 font-base text-sm text-error-700">
        {{ missingLocationIdError }}
      </p>
    </div>
    <div
      class="mx-auto max-w-md p-4 align-middle"
      [class.pb-7]="isNotched"
      *ngIf="
        (cartHasProduct$ | async) &&
        !(checkCartIsValidCallInProgress$ | async) &&
        (orderValidate$ | async) as orderValidate
      "
    >
      <app-button-atom
        *ngIf="!(basketId$ | async) && !!orderLength(orderValidate)"
        [label]="
          orderValidate[orderKeys.FinalPrice] === 0
            ? ('basket.payment_zero' | translate)
            : (goToPaymentButtonText | translate)
        "
        [type]="'primary'"
        [size]="'L'"
        (click)="goToPayment()"
        [disabled]="
          isGoToPaymentInProgress ||
          !!errorMessages.length ||
          !(orderHasProduct$ | async) ||
          (missingPhone$ | async) === true ||
          onSiteLocationIsMissing === true ||
          !!missingLocationIdError
        "
        [iconUrl]="isGoToPaymentInProgress ? 'assets/images/spinner.svg' : ''"
      ></app-button-atom>
      <div *ngIf="orderValidate$ | async as orderValidate">
        <app-button-atom
          *ngIf="
            !!orderLength(orderValidate) &&
            (basketId$ | async) &&
            (basket$ | async)?.user?.id === (userId$ | async)
          "
          [label]="'basket.payment' | translate"
          [type]="'primary'"
          [size]="'L'"
          (click)="confirmOrder()"
          [disabled]="
            isGoToPaymentInProgress ||
            !!errorMessages.length ||
            !(orderHasProduct$ | async) ||
            (missingPhone$ | async) === true ||
            !!missingLocationIdError
          "
          [iconUrl]="isGoToPaymentInProgress ? 'assets/images/spinner.svg' : ''"
        ></app-button-atom>
        <app-button-atom
          *ngIf="
            !!orderLength(orderValidate) &&
            (basketId$ | async) &&
            (basket$ | async)?.user?.id !== (userId$ | async)
          "
          [label]="'basket.validate-order' | translate"
          [type]="'primary'"
          [size]="'L'"
          (click)="confirmOrder()"
          [disabled]="
            isGoToPaymentInProgress ||
            !!errorMessages.length ||
            !(orderHasProduct$ | async) ||
            (missingPhone$ | async) === true ||
            !!missingLocationIdError
          "
          [iconUrl]="isGoToPaymentInProgress ? 'assets/images/spinner.svg' : ''"
        ></app-button-atom>
      </div>
    </div>
    <div
      *ngIf="!(orderValidate$ | async) || (checkCartIsValidCallInProgress$ | async)"
      class="mx-auto max-w-md p-4 align-middle"
      [class.pb-7]="isNotched"
    >
      <app-button-atom
        [label]="'basket.payment' | translate"
        [type]="'primary'"
        [size]="'L'"
        [disabled]="true"
        [iconUrl]="isGoToPaymentInProgress ? 'assets/images/spinner.svg' : ''"
      ></app-button-atom>
    </div>
  </div>
</div>
