import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';

import {
  SELECT_OSL_ACTIVE,
  SELECT_OSL_ACTIVE_ID,
  SELECT_OSL_ALL,
  SELECT_OSL_ENTITY,
  SELECT_OSL_IS_LOADED,
  SELECT_OSL_IS_LOADING,
  SELECT_OSL_SETTINGS,
} from '@store/on-site-location/on-site-location.selection';
import { DoodOnSiteLocationModel } from '@core/models/on-site-location.model';
import {
  OLS_STORE_SETTINGS_INITIAL_STATE,
  OnSiteLocationSettingsState,
} from '@store/on-site-location';

@Injectable({ providedIn: 'root' })
export class OnSiteLocationStoreSelector implements OnDestroy {
  private readonly _destroyerRef = new Subject<boolean>();

  locations: DoodOnSiteLocationModel[] = [];
  active: DoodOnSiteLocationModel | null = null;
  settings: OnSiteLocationSettingsState = OLS_STORE_SETTINGS_INITIAL_STATE;

  selectLocations = this.store.select(SELECT_OSL_ALL);
  selectLocation = (id: string): Observable<DoodOnSiteLocationModel | undefined> =>
    this.store.select(SELECT_OSL_ENTITY(id));

  selectIsLoaded = this.store.select(SELECT_OSL_IS_LOADED);
  selectIsLoading = this.store.select(SELECT_OSL_IS_LOADING);

  selectActive = this.store.select(SELECT_OSL_ACTIVE);
  selectActiveId = this.store.select(SELECT_OSL_ACTIVE_ID);

  selectSettings = this.store.select(SELECT_OSL_SETTINGS);

  constructor(private store: Store) {
    this.selectLocations.pipe(takeUntil(this._destroyerRef)).subscribe(_locations => {
      this.locations = _locations;
    });
    this.selectActive.pipe(takeUntil(this._destroyerRef)).subscribe(_active => {
      this.active = _active;
    });
    this.selectSettings.pipe(takeUntil(this._destroyerRef)).subscribe(_settings => {
      this.settings = _settings;
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe();
  }

  private _unsubscribe(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
  }
}
