import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import { DoodEntityModel } from '@core/models/entity.model';

import { SELECT_ENTITIES_ALL, SELECT_ENTITIES_ENTITY } from '@store/entity/entity.selection';

@Injectable({ providedIn: 'root' })
export class EntityStoreSelector {
  selectEntities = this.store.select(SELECT_ENTITIES_ALL);
  selectEntity = (id: string): Observable<DoodEntityModel | undefined> =>
    this.store.select(SELECT_ENTITIES_ENTITY(id));

  constructor(private store: Store) {}
}
