import { ReducerTypes, ActionCreator, on } from '@ngrx/store';

import { ENTITY_STORE_LOCAL_ACTIONS } from './entity.action';
import { DoodEntityState, ENTITY_STORE_ADAPTER, ENTITY_STORE_INITIAL_STATE } from './entity.state';

export const ENTITY_STORE_API_PIECE: ReducerTypes<DoodEntityState, readonly ActionCreator[]>[] = [];

export const ENTITY_STORE_LOCAL_PIECE: ReducerTypes<DoodEntityState, readonly ActionCreator[]>[] = [
  // Multiple
  on(ENTITY_STORE_LOCAL_ACTIONS.addEntities, (state, { entities }): DoodEntityState => {
    return ENTITY_STORE_ADAPTER.addMany(entities, state);
  }),
  on(ENTITY_STORE_LOCAL_ACTIONS.removeEntities, (state, { ids }): DoodEntityState => {
    return ENTITY_STORE_ADAPTER.removeMany(ids, state);
  }),
  on(ENTITY_STORE_LOCAL_ACTIONS.upsertEntities, (state, { entities }): DoodEntityState => {
    return ENTITY_STORE_ADAPTER.upsertMany(entities, state);
  }),
  on(ENTITY_STORE_LOCAL_ACTIONS.setEntities, (state, { entities }): DoodEntityState => {
    return ENTITY_STORE_ADAPTER.setAll(entities, state);
  }),
  // Single
  on(ENTITY_STORE_LOCAL_ACTIONS.addEntity, (state, { entity }): DoodEntityState => {
    return ENTITY_STORE_ADAPTER.addOne(entity, state);
  }),
  on(ENTITY_STORE_LOCAL_ACTIONS.removeEntity, (state, { id }): DoodEntityState => {
    return ENTITY_STORE_ADAPTER.removeOne(id, state);
  }),
  on(ENTITY_STORE_LOCAL_ACTIONS.upsertEntity, (state, { entity }): DoodEntityState => {
    return ENTITY_STORE_ADAPTER.upsertOne(entity, state);
  }),
  // Misc
  on(ENTITY_STORE_LOCAL_ACTIONS.reset, (): DoodEntityState => {
    return ENTITY_STORE_INITIAL_STATE;
  }),
];
