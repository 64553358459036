<div>
  <div
    *ngIf="displayPaymentLoadingScreen"
    class="fixed h-full top-0 bottom-0 left-0 right-0 bg-white z-50 flex flex-col"
  >
    <div
      class="flex flex-col items-center justify-center mx-auto p-8 text-center"
      style="flex-grow: 1"
    >
      <h1 class="font-accent font-bold text-2xl pb-4 text-neutral-800 font-bold">
        Ouvrez l'application Lydia pour payer
      </h1>
      <p class="text-sm pb-2 text-neutral-600">
        Ouvrez l'application Lydia sur votre mobile pour confirmer le réglement.
      </p>
      <p class="text-sm pb-2 text-neutral-600">
        Cette page se mettra automatiquement à jour quand ce sera fait.
      </p>
      <div
        *ngIf="!isOrderCancelled"
        class="flex flex-col items-center justify-center py-8"
      >
        <img
          src="assets/images/spinner.svg"
          alt=""
        />
      </div>
      <div
        *ngIf="isOrderCancelled"
        class="flex flex-col items-center justify-center py-8"
      >
        <p class="text-xl font-bold text-error-800 pb-2 text-neutral-600">Paiement abandonné</p>
      </div>
    </div>
    <div
      *ngIf="isOrderCancelled"
      class="flex flex-col items-center justify-center p-4 align-middle max-w-md mx-auto p-4 text-center"
    >
      <app-button-atom
        size="M"
        [type]="'primary'"
        (click)="changePaymentMethod()"
        label="Choisir un autre moyen de paiement"
      ></app-button-atom>
    </div>
  </div>

  <div class="flex-1 ml-3 mb-6">
    <div
      *ngIf="paymentPhoneNumber"
      class="flex flex-row items-center justify-between"
    >
      <div class="flex flex-row items-center">
        <div class="text-neutral-800">
          <h4 class="font-bold font-base">
            {{ 'lydia.phone-number-label' | translate }}
          </h4>
          <p class="font-base">
            {{ paymentPhoneNumber }}
          </p>
        </div>
      </div>
      <app-button-atom
        size="XS"
        type="light"
        thickSize="XS"
        (click)="openPhoneNumberModal()"
        [label]="'lydia.update-phone-number-button' | translate"
      ></app-button-atom>
    </div>
    <div
      *ngIf="!paymentPhoneNumber"
      class="text-neutral-800 leading-5 pb-6"
    >
      <app-button-atom
        size="L"
        type="primary"
        [label]="'lydia.add-phone-number-button' | translate"
        (click)="openPhoneNumberModal()"
      ></app-button-atom>
    </div>
  </div>
</div>
