import { Subject, timer } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { INotification } from '@core/models/notification.model';
import { NotificationService } from '@core/services/notification/notification.service';

import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-notification-block',
  templateUrl: './notification-block.component.html',
  animations: [
    trigger('messageState', [
      state(
        'visible',
        style({
          transform: 'translateX(0)',
          opacity: 1,
        }),
      ),
      transition('void => *', [
        style({ transform: '{{showTransformParams}}', opacity: 0 }),
        animate('{{showTransitionParams}}'),
      ]),
      transition('* => void', [
        animate(
          '{{hideTransitionParams}}',
          style({
            opacity: 0,
            transform: '{{hideTransformParams}}',
          }),
        ),
      ]),
    ]),
  ],
})
export class NotificationBlockComponent implements OnInit, OnDestroy {
  showTransformOptions = 'translateX(-100%)';
  hideTransformOptions = 'translateX(100%)';
  showTransitionOptions = '300ms ease-out';
  hideTransitionOptions = '250ms ease-out';

  notifications: INotification[] = [];
  private readonly destroyed$ = new Subject<boolean>();

  constructor(private notificationService: NotificationService) {}

  private addNotification(notification: INotification): void {
    this.notifications.pop();
    this.notifications.push(notification);

    if (notification.timeout) {
      timer(notification.timeout).subscribe(() => this.close(notification));
    }
  }

  ngOnInit(): void {
    this.notificationService
      .getObservable()
      .pipe(
        takeUntil(this.destroyed$),
        tap(notification => this.addNotification(notification)),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  click(notification: INotification): void {
    notification.click?.next(true);
    notification.click?.complete();
    this.close(notification);
  }

  close(notification: INotification): void {
    this.notifications = this.notifications.filter(
      (notif: INotification) => notif.id !== notification.id,
    );
  }
}
