import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { DeviceService } from '@core/services/app/device.service';

@Directive({
  selector: '[appAutoFocus]',
})
export class AutoFocusDirective implements AfterViewInit {
  constructor(
    private readonly host: ElementRef,
    private readonly deviceService: DeviceService,
  ) {}

  ngAfterViewInit(): void {
    if (this.deviceService.getDeviceType() === 'desktop') {
      this.host.nativeElement.focus();
    }
  }
}
