import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DOOD_API } from '@config/ws.config';

import { IStripePaymentIntent } from '@core/models/payment.model';
import { DoodOnSiteLocationModel } from '@core/models/on-site-location.model';
import { DoodOpenCheck, DoodOpenCheckTransaction } from '@core/models/check.model';

@Injectable({
  providedIn: 'root',
})
export class OnSiteLocationsApiService {
  constructor(private client: HttpClient) {}

  public getOnSiteLocations$(id: string): Observable<DoodOnSiteLocationModel[]> {
    return this.client.get<DoodOnSiteLocationModel[]>(`${DOOD_API}/shops/${id}/onsite-locations`);
  }

  public getOnSiteLocationOpenCheck$(id: string): Observable<DoodOpenCheck | null> {
    return this.client.get<DoodOpenCheck>(`${DOOD_API}/onsite-locations/${id}/open-check`);
  }

  public getOnSiteLocationCheck$(checkId: string): Observable<DoodOpenCheck> {
    return this.client.get<DoodOpenCheck>(`${DOOD_API}/checks/${checkId}/synced`);
  }

  public createCheckTransaction$(
    checkId: string,
    transaction: Partial<DoodOpenCheckTransaction>,
  ): Observable<DoodOpenCheckTransaction> {
    return this.client.post<DoodOpenCheckTransaction>(
      `${DOOD_API}/checks/${checkId}/transactions`,
      transaction,
    );
  }

  public getOnSiteLocationCheckTransactions$(
    transactionId: string,
  ): Observable<DoodOpenCheckTransaction> {
    return this.client.get<DoodOpenCheckTransaction>(
      `${DOOD_API}/check-transactions/${transactionId}`,
    );
  }

  public getOnSiteLocationCheckTransactionsPayment$(
    transactionId: string,
  ): Observable<IStripePaymentIntent> {
    return this.client.post<IStripePaymentIntent>(
      `${DOOD_API}/check-transactions/${transactionId}/payments`,
      {},
    );
  }

  public sendCheckTransactionInvoice$(
    transactionId: string,
    data: { email: string; fullName: string },
  ): Observable<IStripePaymentIntent> {
    return this.client.post<IStripePaymentIntent>(
      `${DOOD_API}/check-transactions/${transactionId}/send-invoice`,
      {
        email: data.email,
        fullName: data.fullName,
      },
    );
  }

  public getUserChecks$(userId: string): Observable<DoodOpenCheck[]> {
    return this.client.get<DoodOpenCheck[]>(`${DOOD_API}/users/${userId}/checks`);
  }

  public getUserCheck$(checkId: string): Observable<DoodOpenCheck> {
    return this.client.get<DoodOpenCheck>(`${DOOD_API}/checks/${checkId}`);
  }
}
