import { EntityMapOne, Update } from '@ngrx/entity';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { DoodProductModel } from '@core/models/product.model';
import { DoodApiError } from '@shared/interfaces/error.interface';
import { createActiveStoreActions } from '@store/_abstract/active';
import { PRODUCT_STORE_KEY } from './product.state';

export const PRODUCT_STORE_API_ACTIONS = createActionGroup({
  source: 'Dood/API/Product',
  events: {
    'Fetch Products': emptyProps(),
    'Fetch Products Success': props<{ products: DoodProductModel[] }>(),
    'Fetch Products Failure': props<{ error: DoodApiError }>(),
    'Fetch Products Complete': emptyProps(),
  },
});

export const PRODUCT_STORE_LOCAL_ACTIONS = createActionGroup({
  source: 'Dood/LOCAL/Product',
  events: {
    // Products
    // -- Multiple
    'Add Products': props<{ products: DoodProductModel[] }>(),
    'Remove Products': props<{ ids: string[] }>(),
    'Upsert Products': props<{ products: DoodProductModel[] }>(),
    'Set Products': props<{ products: DoodProductModel[] }>(),
    'Reset Products': emptyProps(),

    // -- Single
    'Add Product': props<{ product: DoodProductModel }>(),
    'Remove Product': props<{ id: string }>(),
    'Upsert Product': props<{ product: DoodProductModel }>(),
    'Update Product': props<{ product: Update<DoodProductModel> }>(),
    'Map Product': props<{ product: EntityMapOne<DoodProductModel> }>(),

    // Active
    'Update Active': props<{ active: DoodProductModel | null }>(),
    'Reset Active': emptyProps(),

    // Errors
    'Update Error': props<{ error: DoodApiError | null }>(),
    'Reset Error': emptyProps(),

    // Status
    'Update Is Loaded': props<{ isLoaded: boolean }>(),
    'Update Is Loading': props<{ isLoading: boolean }>(),

    // Misc
    'reset': emptyProps(),
  },
});

export const PRODUCT_STORE_ACTIVE_ACTIONS =
  createActiveStoreActions<DoodProductModel>(PRODUCT_STORE_KEY);
