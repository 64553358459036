import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-payment-panel-adyen-meal-voucher-sodexo',
  templateUrl: './payment-panel-adyen-meal-voucher-sodexo.component.html',
})
export class PaymentPanelAdyenMealVoucherSodexoComponent {
  @Input() clientKey?: string;
  @Input() paymentMethodMealVoucherSodexoAvailable = false;
}
