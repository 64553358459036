export const OrderStatusLabels = {
  PAYMENT: {
    label: 'order-status.confirmation-payment',
    icon: 'icon-clock',
    type: 'neutral',
    message: '',
  },
  LOOKING_FOR_DELIVERY: {
    label: 'order-status.looking-for-delivery',
    icon: 'icon-clock',
    type: 'neutral',
    message: '',
  },
  WAITING: {
    label: 'order-status.waiting-for-preparation',
    icon: 'icon-hourglass',
    type: 'neutral',
    message: 'order-status-messages.waiting-for-preparation',
  },
  PREPARATION: {
    label: 'order-status.in-preparation',
    icon: 'icon-chef-hat',
    type: 'dark',
    message: 'order-status-messages.in-preparation',
  },
  READY: {
    label: 'order-status.ready',
    icon: 'icon-n-check',
    type: 'success',
    message: 'order-status-messages.ready',
  },
  COLLECTED: {
    label: 'order-status.retired',
    icon: 'icon-n-check',
    type: 'neutral',
    message: 'order-status-messages.retired',
  },
  CANCELLED: {
    label: 'order-status.cancelled',
    icon: 'icon-c-remove',
    type: 'error',
    message: 'order-status-messages.cancelled',
  },
} as const;
