<app-modal-filler-atom
  (click)="close($event)"
  [index]="5"
></app-modal-filler-atom>
<div
  *ngIf="content"
  [style.z-index]="999"
  class="fixed inset-0 flex h-full w-full items-center justify-center p-0.5"
>
  <div class="relative flex max-h-full max-w-full items-center justify-center">
    <a
      *ngIf="url"
      class="block max-h-full w-full max-w-full"
      [attr.href]="url"
    >
      <img
        [src]="content.url"
        [ngStyle]="{ maxWidth, maxHeight }"
        class="h-full w-full object-cover shadow-lg"
      />
    </a>
    <div
      *ngIf="!url"
      class="block max-h-full w-full max-w-full"
      (click)="close($event)"
    >
      <img
        [src]="content.url"
        [ngStyle]="{ maxWidth, maxHeight }"
        class="h-full w-full object-cover shadow-lg"
      />
    </div>
    <button
      (click)="this.close($event)"
      [ngClass]="{
        'absolute top-1 right-1.5': true,
        'w-8 h-8 rounded-full bg-primary-200': true,
        'text-xs text-primary-900 icon icon-cross': true,
      }"
    ></button>
  </div>
  <!--
  <div
      class="bg-white p-5"
      [ngClass]="
          (isMobile$ | async) ? 'rounded-t-3xl' : 'rounded-3xl min-w-448'
      "
      [class.pb-8]="isNotched"
      [style.z-index]="((index$ | async) ?? 0) + 50"
  >
      <div class="flex items-center justify-between">
          <h1 class="pb-2 font-brand text-28px font-bold text-neutral-800">
              <span *ngIf="(data$ | async)?.title as title">
                  {{ title }}
              </span>
          </h1>
          <app-rounded-icon-button
              icon="icon-cross"
              (clickButton)="close()"
          ></app-rounded-icon-button>
      </div>
      <div
          *ngIf="(data$ | async)?.message as message"
          class="py-6 font-accent text-base"
      >
          {{ message }}
      </div>
      <div>
          <app-button-atom
              [color]="'primary'"
              [label]="(data$ | async)?.confirmButtonText"
              [type]="'primary'"
              [size]="'L'"
              [disabled]="isLoading"
              [iconUrl]="isLoading ? 'assets/images/spinner.svg' : ''"
              (click)="submit()"
          >
          </app-button-atom>
      </div>
  </div>
  -->
</div>
