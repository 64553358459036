import { timer } from 'rxjs';
import { find } from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { Component, Input } from '@angular/core';
import { skipWhile, switchMap, takeUntil, tap } from 'rxjs/operators';

import { DateUtils } from '@shared/utils/date/date.utils';
import { DoodOrderModel } from '@store/order/order.model';
import { OrderStoreSelector } from '@common/selectors/order.selector';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';

import { OrdersService } from '@core/services/orders/orders.service';
import { ResetStateService } from '@core/services/reset-state.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import {
  IContentBuilderFieldColor,
  IContentBuilderFieldImage,
} from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-kiosk-order-status-block',
  templateUrl: './kiosk-order-status-block.component.html',
  styleUrls: ['./kiosk-order-status-block.component.scss'],
})
export class KioskOrderStatusBlockComponent extends DestroyerBase {
  @Input() headerImage?: IContentBuilderFieldImage;
  @Input() backgroundImage?: IContentBuilderFieldImage;
  @Input() backgroundColor!: IContentBuilderFieldColor;
  @Input() buttonBackgroundColor: IContentBuilderFieldColor = {
    value: 'primary-800',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() buttonTextColor: IContentBuilderFieldColor = {
    value: '#FFFFFF',
    type: ColorFieldTypesValues.Hex,
  };
  @Input() logo?: IContentBuilderFieldImage;
  @Input() heading = 'Votre numéro de commande';
  @Input() footer = "N'oubliez pas votre ticket";
  @Input() footer1?: string;
  @Input() footer2?: string;
  @Input() displayOrderReadyAt = true;

  order?: DoodOrderModel;
  orderReadyAt!: string;
  receiptPrinted = false;

  constructor(
    private orderSelector: OrderStoreSelector,
    readonly routerHelper: RouterHelperService,
    private readonly ordersService: OrdersService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly resetStateService: ResetStateService,
  ) {
    super();
    const orderId = this.activatedRoute.snapshot.params.orderId;

    this.orderSelector
      .selectOrder(orderId)
      .pipe(
        takeUntil(this._destroyerRef),
        tap(order => (this.order = order as DoodOrderModel)),
        tap(order => {
          this.orderReadyAt = DateUtils.dayjsInMarketplaceTimezone(
            (order as DoodOrderModel)?.ready_at,
          ).format('HH[h]mm');
        }),
        tap(() => this.updateReceiptPrinted()),
      )
      .subscribe();

    timer(1000, 5000)
      .pipe(
        takeUntil(this._destroyerRef),
        skipWhile(() => !this.order || this.order?.status !== 'PAYMENT'),
        switchMap(() => this.ordersService.loadOrderById$(orderId)),
        tap(order => this.ordersService.setActiveOrderToStore(order)),
      )
      .subscribe();
  }

  goToHome(): void {
    this.resetStateService.refreshUI().then(() => {
      window.location.href = '/';
    });
  }

  private updateReceiptPrinted(): void {
    if (!this.order) {
      return;
    }
    this.receiptPrinted = !!find(this.order.metafields, {
      handle: 'kiosk_receipt_printed_at',
    });
  }

  getBackgroundStyles() {
    return {
      'background-color': this.backgroundColor ? this.backgroundColor : null,
      'background-image': this.backgroundImage ? `url(${this.backgroundImage.url})` : null,
      'background-size': this.backgroundImage ? 'cover' : null,
    };
  }
}
