import { Injectable } from '@angular/core';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { CartKeys } from '@config/keys/cart.keys';
import { TransactionKeys } from '@config/keys/transaction.keys';

import { DoodTransactionModel } from '@core/models/transaction.model';
import { PaymentModalAmountModes } from '@config/modals/add-payment-modal.config';

import { BasketStoreRefiner } from '@common/refiners/basket.refiner';
import { BasketStoreSelector } from '@common/selectors/basket.selector';
import { CartStoreDispatcher } from '@common/dispatchers/cart.dispatcher';
import { TransactionStoreSelector } from '@common/selectors/transaction.selector';
import { TransactionStoreDispatcher } from '@common/dispatchers/transaction.dispatcher';

import { OrdersService } from 'src/app/core/services/orders/orders.service';
import { TransactionsApiService } from '@core/services/api/transactions/transactions-api.service';

@Injectable({
  providedIn: 'root',
})
export class TransactionsService {
  constructor(
    private basketRefiner: BasketStoreRefiner,
    private basketSelector: BasketStoreSelector,
    private cartDispatcher: CartStoreDispatcher,
    private readonly ordersService: OrdersService,
    private transactionSelector: TransactionStoreSelector,
    private transactionDispatcher: TransactionStoreDispatcher,
    private readonly transactionApiService: TransactionsApiService,
  ) {}

  getTransaction$(id: string): Observable<DoodTransactionModel> {
    return this.transactionApiService.getTransaction$(id);
  }

  createTransaction$(transaction: Partial<DoodTransactionModel>): Observable<DoodTransactionModel> {
    return this.basketSelector.select.pipe(
      take(1),
      switchMap(basket => this.ordersService.getOrderById$(basket[CartKeys.Order]!)),
      map(order => {
        transaction[TransactionKeys.Order] = order?.id;
        transaction[TransactionKeys.Currency] = order?.currency;
        return transaction;
      }),
      switchMap(newTransaction => {
        return combineLatest([of(newTransaction), this.transactionSelector.selectPaymentMode]);
      }),
      take(1),
      switchMap(([newTransaction, paymentMode]) => {
        switch (paymentMode) {
          case PaymentModalAmountModes.Own:
            return combineLatest([
              of(newTransaction),
              this.basketRefiner.selectCurrentUserCartItemsIds,
            ]);
          case PaymentModalAmountModes.Full:
            return combineLatest([of(newTransaction), this.basketRefiner.selectCartItemsIds]);
          case PaymentModalAmountModes.Remaining:
            return combineLatest([
              of(newTransaction),
              this.basketRefiner.selectOtherUsersCartItems,
            ]);
          default:
            return combineLatest([of(newTransaction), this.basketRefiner.selectCartItemsIds]);
        }
      }),
      map(([newTransaction, basketItems]) => {
        return {
          ...newTransaction,
          cart_items: basketItems,
        };
      }),
      switchMap(newTransaction =>
        this.transactionApiService.createTransaction$(newTransaction).pipe(
          catchError(err => {
            const errMessage = err.error.violations?.[0].message
              ? err.error.violations[0].message
              : err.error.detail;
            if (errMessage) {
              this.setTransactionError(errMessage);
            }
            return throwError(() => err);
          }),
        ),
      ),
      tap(newTransaction => this.setActiveTransaction(newTransaction)),
    );
  }

  cancelTransaction$(id: string): Observable<any> {
    return this.transactionApiService.cancelTransaction$(id);
  }

  setTransactionAmount(amount: number | null): void {
    this.transactionDispatcher.updateAmount(amount);
  }

  setTransactionInstantAmountMode(mode: string): void {
    this.transactionDispatcher.updatePaymentMode(mode);
  }

  setActiveTransaction(transaction: DoodTransactionModel): void {
    this.transactionDispatcher.insertActive(transaction);
  }

  setTransactionError(error: any): void {
    this.transactionDispatcher.updateError(error);
  }

  clearTransactions(): void {
    this.setTransactionAmount(null);
    this.transactionDispatcher.reset();
    this.cartDispatcher.clear();
  }

  clearActiveTransaction(): void {
    this.transactionDispatcher.resetActive();
  }
}
