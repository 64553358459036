import dayjs from 'dayjs';
import { Route, Router } from '@angular/router';
import timezone from 'dayjs/plugin/timezone';
import { combineLatest, Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { inject, Injectable, Type } from '@angular/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { intersection, keyBy, keys, uniqueId, without } from 'lodash';
import { map, skipWhile, switchMap, take, tap } from 'rxjs/operators';

import { routes } from '@app/app-routing.module';

import { DEFAULT_FONTS } from '@config/fonts.config';
import { ALLOWED_GUARDS } from '@config/guards.config';
import { DEFAULT_COLORS } from '@config/colors.config';
import { DEFAULT_PAGES } from '@config/default-pages.config';
import { DEFAULT_BLOCKS } from '@config/default-blocks.config';
import { DEFAULT_MODALS } from '@config/default-modals.config';

import {
  DoodMarketplaceModel,
  ILayout,
  IPageConfig,
  IUIConfigFont,
} from '@core/models/marketplace.model';
import { DistributionModeKeys, MarketplaceKeys } from '@config/keys/shop.keys';
import { DistributionModeValues, OrderTypeValues } from '@config/values/order.values';

import { CartStoreSelector } from '@common/selectors/cart.selector';
import { CartStoreDispatcher } from '@common/dispatchers/cart.dispatcher';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';
import { MarketplaceStoreDispatcher } from '@common/dispatchers/marketplace.dispatcher';

import { PageFactoryComponent } from '@core/components/page-factory/page-factory.component';

import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { EventTrackerService } from '@core/services/event-tracker/event-tracker.service';
import { MarketplacesApiService } from '@core/services/api/marketplaces/marketplaces-api.service';
import { ShopSearchParametersService } from '@core/services/shop-search-parameters/shop-search-parameters.service';

import { ContentStoreDispatcher } from '@common/dispatchers/content.dispatcher';
import { Auth } from '@angular/fire/auth';
import { OrderTypeService } from '../order-type/order-type.service';

@Injectable({
  providedIn: 'root',
})
export class MarketplaceService {
  private auth: Auth = inject(Auth);

  static localStorageKey = 'marketplace_domain';
  readonly localizeRouterService = inject(LocalizeRouterService);
  readonly router = inject(Router);
  readonly routerHelperService = inject(RouterHelperService);

  constructor(
    private cartSelector: CartStoreSelector,
    private cartDispatcher: CartStoreDispatcher,
    private readonly translate: TranslateService,
    private contentDispatcher: ContentStoreDispatcher,
    private marketplaceSelector: MarketplaceStoreSelector,
    private marketplaceDispatcher: MarketplaceStoreDispatcher,
    private readonly eventTrackerService: EventTrackerService,
    private readonly marketplaceApiService: MarketplacesApiService,
    private readonly shopSearchParametersService: ShopSearchParametersService,
    private readonly orderTypeService: OrderTypeService,
  ) {
    dayjs.extend(timezone);
  }

  public loadMarketplace$(): Observable<DoodMarketplaceModel> {
    const domain = this.pickDomain();
    this.saveDomainToLocalStorage(domain);

    return this.marketplaceApiService.getMarketplaceByDomain$(domain).pipe(
      tap(marketplace => this.handleMarketplaceNotFound(marketplace)),
      skipWhile(marketplace => !marketplace),
      map(marketplace => marketplace as DoodMarketplaceModel),
      tap(marketplace => this.loadMarketplaceData(marketplace)),
      tap(marketplace => this.setHtmlLang(marketplace)),
      tap(marketplace => this.injectCustomTranslation(marketplace)),
      tap(marketplace => this.initializeDefaultDistributionMode(marketplace)),
      map(marketplace => this.convertToPageTree(marketplace)),
      map(marketplace => {
        const pages = this.injectDefaultPages(marketplace.pages, DEFAULT_PAGES);
        return { ...marketplace, pages } as DoodMarketplaceModel;
      }),
      map(marketplace => this.injectDefaultBlocks(marketplace)),
      map(marketplace => this.injectDefaultModals(marketplace)),
      tap(marketplace => this.loadBlocks(marketplace)),
      tap(marketplace => this.loadModals(marketplace)),
      tap(marketplace => this.initializeUI(marketplace)),
      tap(marketplace => this.createRoutes(marketplace)),
      tap(marketplace => this.setDefaultTimezone(marketplace)),
      tap(marketplace => this.setAuthMode(marketplace)),
      tap(marketplace => this.dispatchTrackEvent(marketplace)),
      tap(marketplace => this.setFirebaseTenantId(marketplace)),
      switchMap(marketplace =>
        combineLatest([of(marketplace), this.cartSelector.selectActive.pipe(take(1))]),
      ),
      tap(([marketplace, cart]) => {
        if (cart) {
          if (cart?.marketplace && cart.marketplace !== marketplace.id) {
            this.cartDispatcher.resetAndClear();
          }
        }
      }),
      map(([marketplace]) => marketplace),
    ) as Observable<DoodMarketplaceModel>;
  }

  handleMarketplaceNotFound(marketplace: DoodMarketplaceModel | undefined): void {
    if (marketplace) {
      return;
    }

    this.router.navigate(['404']);
  }

  public getLayout$(): Observable<ILayout | undefined> {
    return this.marketplaceSelector.selectMarketplaceLayout;
  }

  public getId$(): Observable<string> {
    return this.marketplaceSelector.selectMarketplaceId;
  }

  public getId(): string {
    return this.marketplaceSelector.marketplace?.id;
  }

  public getPluginByType(type: string): any {
    const marketplace = this.marketplaceSelector.marketplace;

    return marketplace?.[MarketplaceKeys.Plugins]?.filter(plugin => plugin?.type === type)[0];
  }

  public getFirebaseTenantId(): string | null | undefined {
    const marketplace = this.getMarketplaceData();
    if (
      marketplace[MarketplaceKeys.FirebaseTenant] !== undefined &&
      marketplace[MarketplaceKeys.FirebaseTenant]?.firebase_tenant_id !== undefined
    ) {
      return marketplace[MarketplaceKeys.FirebaseTenant]?.firebase_tenant_id;
    }

    return null;
  }

  public getMarketplaceTenantId(): string | null | undefined {
    const marketplace = this.getMarketplaceData();
    if (
      marketplace[MarketplaceKeys.FirebaseTenant] !== undefined &&
      marketplace[MarketplaceKeys.FirebaseTenant]?.id !== undefined
    ) {
      return marketplace[MarketplaceKeys.FirebaseTenant]?.id;
    }

    return null;
  }

  public getMarketplaceData(): DoodMarketplaceModel {
    return this.marketplaceSelector.marketplace;
  }

  private pickDomain(): string {
    // From CLI env var
    // if (process.env.NG_APP_MARKETPLACE_DOMAIN) {
    //   return process.env.NG_APP_MARKETPLACE_DOMAIN;
    // }

    // Domain from GET query param
    const urlSearchParams = new URLSearchParams(window.location.search);
    if (urlSearchParams.has('marketplace_domain')) {
      return urlSearchParams.get('marketplace_domain') as string;
    }

    // From local storage
    const localDomain = localStorage.getItem(MarketplaceService.localStorageKey);
    if (localDomain) {
      return localDomain;
    }

    if (window.location.hostname === 'localhost') {
      return 'dood.com';
    }

    // From hostname
    return window.location.hostname;
  }

  private saveDomainToLocalStorage(marketplaceDomain: string): void {
    localStorage.setItem(MarketplaceService.localStorageKey, marketplaceDomain);
  }

  private loadMarketplaceData(marketplace: DoodMarketplaceModel): void {
    if (!marketplace.id) {
      alert('Marketplace not found');
    }

    this.marketplaceDispatcher.updateMarketplace(marketplace);
  }

  private injectDefaultPages(
    _pages?: IPageConfig[],
    _default?: IPageConfig[],
    _index = 0,
  ): Array<IPageConfig> {
    let result: IPageConfig[] = _pages ?? [];

    if (_pages) {
      const defaultPages = keyBy(_default, 'path');
      const marketplacePages = keyBy(_pages, 'path');

      const defaultPaths = keys({ ...defaultPages });
      const marketplacePaths = keys({ ...marketplacePages });

      const uniquePaths = without(defaultPaths, ...marketplacePaths);
      const allPaths = intersection(defaultPaths, [...marketplacePaths]);

      result = uniquePaths.reduce((_pages, path) => {
        const page = defaultPages[path];
        return [..._pages, page];
      }, result);

      const _configs = Object.keys(marketplacePages).reduce((map, id) => {
        map.set(id, marketplacePages[id]);
        return map;
      }, new Map<string, IPageConfig>());

      allPaths.forEach(path => {
        const _default = defaultPages[path];

        if (_configs.has(path)) {
          let _page = _configs.get(path) as IPageConfig;

          if (!_page.children) {
            _page = {
              ..._page,
              children: [],
            };
          }

          _configs.set(path, {
            ..._page,
            children: this.injectDefaultPages(_page.children, _default.children, _index + 1),
          });
        }
      });

      result = result.map(_route => {
        const _config = _configs.get(_route.path);
        if (!_config) return _route;
        return { ..._route, children: _config.children };
      });
    }

    return result;
  }

  private injectDefaultBlocks(marketplace: DoodMarketplaceModel): DoodMarketplaceModel {
    const blocks = marketplace.blocks ?? [];
    return { ...marketplace, blocks: [...DEFAULT_BLOCKS, ...blocks] };
  }

  private injectDefaultModals(marketplace: DoodMarketplaceModel): DoodMarketplaceModel {
    let modals = [...(marketplace.modals ?? [])];
    let blocks = [...(marketplace.blocks ?? [])];

    const defaultModalsByHandle = keyBy(DEFAULT_MODALS, 'handle');
    const marketplaceModalsByHandle = keyBy(modals, 'handle');
    const defaultModalsHandles = keys(defaultModalsByHandle);
    const marketplaceModalsHandles = keys(marketplaceModalsByHandle);
    const defaultModalsHandlesToLoad = without(defaultModalsHandles, ...marketplaceModalsHandles);

    defaultModalsHandlesToLoad.forEach(handle => {
      const modal = defaultModalsByHandle[handle];
      const blockId = uniqueId();
      modals.push({
        handle,
        block: blockId,
      });
      blocks.push({
        id: blockId,
        type: modal.block.type,
        data: modal.block.data,
      });
    });

    return { ...marketplace, modals, blocks };
  }

  private loadBlocks(marketplace: DoodMarketplaceModel): void {
    this.contentDispatcher.fetchBlocksSuccess(marketplace.blocks ?? []);
  }

  private loadModals(marketplace: DoodMarketplaceModel): void {
    this.contentDispatcher.setModals(marketplace.modals ?? []);
  }

  private createRoutes(marketplace: DoodMarketplaceModel): void {
    if (!marketplace.pages) {
      return;
    }

    const marketplaceRoutes = marketplace.pages.map(page => this.createRoute(page));

    const wildcard = routes.find(route => route.path === '**');
    routes.splice(routes.indexOf(wildcard!), 1);

    const newRoutes = [...marketplaceRoutes, ...routes];
    const orderedRoutes = this.orderRoutes(newRoutes);
    if (orderedRoutes) {
      orderedRoutes.push(wildcard!);
      this.localizeRouterService.parser.load(orderedRoutes).then();
    }

    this.router.config[0].children?.push(...marketplaceRoutes);
  }

  private orderRoutes(newRoutes: Route[]): Route[] {
    function convertTo01(path?: string): string {
      if (!path) {
        return '2';
      }
      let split = path.split('/');
      split = split.map(s => s.replace(/:.*/g, '1'));
      split = split.map(s => (s === '1' ? '1' : '0'));
      return split.join('');
    }

    return newRoutes.sort((a, b) => {
      return convertTo01(a.path) < convertTo01(b.path) ? -1 : 1;
    });
  }

  private createRoute(page: IPageConfig): Route {
    let children: Array<Route> = [];

    if (page.redirect_to) {
      return {
        path: page.path,
        redirectTo: page.path
          ? (this.routerHelperService.translateRoute(page.redirect_to) as string)
          : page.redirect_to,
        pathMatch: 'full',
      };
    }

    if (page.children) {
      const childrenRoutes = page.children.map(child => this.createRoute(child));
      children = this.orderRoutes(childrenRoutes);
    }

    return {
      path: page.path,
      component: PageFactoryComponent,
      canActivate: this.mapGuards(page.guards),
      children,
      data: {
        block: page.block,
      },
    };
  }

  private mapGuards(guardHandles: Array<string | Type<unknown>> | undefined): Array<Type<unknown>> {
    if (!guardHandles) {
      return [];
    }
    const guards = [];
    for (const guardHandle of guardHandles) {
      const _key = guardHandle.toString();
      if (!ALLOWED_GUARDS[_key]) {
        console.error('Guard not found ' + _key);
        continue;
      }
      guards.push(ALLOWED_GUARDS[_key]);
    }

    return guards;
  }

  private assignStyleProperty(key: string, value: string): void {
    const root = document.documentElement;
    root.style.setProperty(key, value);
  }

  private initializeUI(marketplace: DoodMarketplaceModel): void {
    this.loadFonts(marketplace);
    this.loadColors(marketplace);
    this.loadCustomCSS(marketplace);
  }

  private loadFonts(marketplace: DoodMarketplaceModel): void {
    const fonts = {
      ...DEFAULT_FONTS,
      ...marketplace.user_interface?.fonts,
      ...marketplace.user_interface?.fonts,
    };
    if (!fonts) {
      return;
    }
    for (const [key, font] of Object.entries(fonts)) {
      this.importFontFamily(font);
      this.assignStyleProperty('--ui-font-' + key + '-family', font.family);
    }
  }

  private loadColors(marketplace: DoodMarketplaceModel): void {
    const colors = {
      ...DEFAULT_COLORS,
      ...marketplace.user_interface?.colors,
    };
    for (const [key, palette] of Object.entries(colors)) {
      if (!palette) {
        continue;
      }
      for (const [level, hex] of Object.entries(palette)) {
        this.assignStyleProperty('--ui-color-' + key + '-' + level, hex);
      }
    }
  }

  private loadCustomCSS(marketplace: DoodMarketplaceModel): void {
    const element = document.createElement('style');
    element.innerHTML = marketplace.user_interface?.custom_css?.join(' ') as string;
    document.head.appendChild(element);
  }

  private importFontFamily({ family, url, local }: IUIConfigFont): void {
    if (!url) {
      return;
    }
    const style = document.createElement('style');
    if (!local) {
      style.appendChild(document.createTextNode(`@import url('${url}')`));
    } else {
      style.appendChild(
        document.createTextNode(
          `@font-face {
                font-family: '${family}';
                src: url('${url}');
            }`,
        ),
      );
    }
    const head = document.querySelector('head');
    head?.appendChild(style);
  }

  private initializeDefaultDistributionMode(marketplace: DoodMarketplaceModel): void {
    const modes = [...marketplace.distribution_modes];
    const parameters = this.shopSearchParametersService.getParameters();
    const savedOrderType = parameters.distribution_mode;
    const savedDistributionModeEnabled = marketplace.distribution_modes?.filter(
      mode =>
        mode.enabled &&
        this.orderTypeService
          .convertOrderTypeToDistributionModes(savedOrderType)
          .includes(mode.type),
    );

    if (savedOrderType && savedDistributionModeEnabled.length) {
      return;
    }

    const prioritySortedModes = modes.sort((a, b) => a.priority - b.priority);
    const enabledModes = prioritySortedModes.filter(m => m.enabled);

    let distributionMode: DistributionModeValues | OrderTypeValues | undefined;

    if (enabledModes.length) {
      distributionMode = enabledModes[0]?.type as DistributionModeValues;
    }

    switch (distributionMode) {
      case DistributionModeValues.Stuart:
      case DistributionModeValues.UberDirect:
      case DistributionModeValues.Shipday:
      case DistributionModeValues.ZitiCity:
      case DistributionModeValues.OwnDelivery:
        distributionMode = OrderTypeValues.Delivery;
        break;
      case DistributionModeValues.OwnShipping:
        distributionMode = OrderTypeValues.Shipping;
        break;
    }
    if (!distributionMode) {
      return;
    }
    this.shopSearchParametersService.setDistributionModeType(distributionMode, false);
  }

  private convertToPageTree(marketplace: DoodMarketplaceModel): DoodMarketplaceModel {
    if (!marketplace.pages) {
      return marketplace;
    }

    const pages = marketplace.pages.map(page => {
      return { ...page, children: [] };
    });

    // Créer un objet où chaque clé est l'ID de la page
    const pagesById = pages.reduce(
      (acc, page) => {
        acc[page.id ?? 'n'] = page;
        return acc;
      },
      {} as { [key: string]: any },
    );

    // Filtrer les pages racines (celles qui n'ont pas de parent)
    const rootPages = pages.filter(page => !page.parent);

    // Construire l'arbre des pages
    pages.forEach(page => {
      if (!page.parent) {
        return;
      }

      let parent = pagesById[page.parent];

      if (!parent) {
        return;
      }

      if (!parent.children) {
        parent.children = [];
      }

      parent.children.push(page);
    });

    // Mettre à jour marketplace.pages avec les pages racines
    marketplace = { ...marketplace, pages: rootPages };
    return marketplace;
  }

  private setDefaultTimezone(marketplace: DoodMarketplaceModel): void {
    dayjs.tz.setDefault(marketplace[MarketplaceKeys.Timezone] ?? 'Europe/Paris');
  }

  private injectCustomTranslation(marketplace: DoodMarketplaceModel): void {
    if (!marketplace.user_interface || !('translations' in marketplace.user_interface)) {
      return;
    }

    for (const lang of Object.keys(marketplace.user_interface.translations)) {
      const translations = marketplace.user_interface.translations[lang];
      this.translate.setTranslation(lang, translations, true);
    }
  }

  private setHtmlLang(marketplace: DoodMarketplaceModel): void {
    document
      .querySelector('html')
      ?.setAttribute('lang', marketplace[MarketplaceKeys.DefaultLocale]?.locale ?? 'fr');
  }

  private setAuthMode(marketplace: DoodMarketplaceModel): void {
    const authMode = marketplace[MarketplaceKeys.DistributionModes].find(
      mode => mode[DistributionModeKeys.Enabled],
    )?.[DistributionModeKeys.AuthMode];
    if (authMode) {
      this.marketplaceDispatcher.updateMarketplace({
        auth_mode: authMode,
      });
    }
  }

  private dispatchTrackEvent(marketplace: DoodMarketplaceModel): void {
    this.eventTrackerService.dispatch(EventTrackerService.EventMarketplaceViewed, marketplace);
  }

  private setFirebaseTenantId(marketplace: DoodMarketplaceModel) {
    let tenantId = marketplace.firebase_tenant?.firebase_tenant_id;
    this.auth.tenantId = tenantId ?? null;
  }
}
