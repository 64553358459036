<div *ngIf="compoundActiveItem$ | async as item">
  <app-compound-item-panel-atom
    [title]="item.name"
    [products]="item.products"
    [count]="item.count"
    [minCount]="item.min_count"
    [parentIdOfAdditions]="parentIdOfAdditions"
    [selected]="item.selected ?? []"
    [isMobile]="(isMobileScreen$ | async) ?? false"
    [index]="(index$ | async) ?? 0"
    [storeId]="item.store_id"
    [headingColor]="headingColor"
    [descriptionColor]="descriptionColor"
    [inactiveStateColor]="inactiveStateColor"
    [priceColor]="priceColor"
    (openAdditionsPanel)="openAdditionsPanel($event)"
    (updateProducts)="onUpdateProducts($event, item.store_id)"
    (outStoreId)="onValidItem($event)"
    (closeModal)="onCloseModal()"
    [hideAdditionGroupDetails]="hideAdditionGroupDetails"
  ></app-compound-item-panel-atom>
  <div *ngIf="additions?.length">
    <app-compound-item-panel-atom
      [products]="additions"
      [title]="additionGroup?.name ?? ''"
      [parentIdOfAdditions]="parentIdOfAdditions"
      [isAdditionPanel]="true"
      [selected]="item.selected ?? []"
      [count]="additionGroup?.max_count ?? 0"
      [minCount]="additionGroup?.min_count ?? 0"
      [groupId]="groupId"
      [isMobile]="(isMobileScreen$ | async) ?? false"
      [index]="(index$ | async) ?? 0"
      [headingColor]="headingColor"
      [descriptionColor]="descriptionColor"
      [inactiveStateColor]="inactiveStateColor"
      [priceColor]="priceColor"
      (updateProducts)="onUpdateAdditions($event, item.store_id)"
      (closeModal)="onCloseAdditions()"
      [hideAdditionGroupDetails]="hideAdditionGroupDetails"
    ></app-compound-item-panel-atom>
  </div>
</div>
