<div
  class="py-5 border text-neutral-800 rounded rounded-lg border-neutral-300 flex flex-col gap-2 lg:gap-4"
>
  <div class="flex items-center px-5 font-base font-bold lg:text-md">
    <i class="icon {{ icon }} mr-3"></i>
    <span>{{ title }}</span>
  </div>
  <div class="flex items-center px-5">
    <span class="font-base">{{ text }}</span>
  </div>
  <div class="flex px-5">
    <a
      (click)="$event.stopPropagation(); openModal.emit()"
      class="mr-2 font-base font-bold text-sm md:text-base text-primary-600 cursor-pointer"
    >
      {{ 'pay-at-table.payment-confirmation.add-my-coordinates' | translate }}
    </a>
  </div>
</div>
