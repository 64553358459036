<a
  *ngIf="type === 'disclaimer'"
  class="block text-center cursor-pointer text-white md:mr-7 py-3 md:inline-block"
  (click)="openCookieConsent()"
  >{{ label }}</a
>
<a
  *ngIf="type === 'preferences'"
  class="block text-center cursor-pointer text-white md:mr-7 py-3 md:inline-block"
  (click)="openPreferencesCenter()"
  >{{ label }}</a
>
