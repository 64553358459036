<div class="pt-4">
  <label class="mb-3 mt-5 block font-accent text-md font-bold md:mt-6"
    >{{ title }}
    <span *ngIf="required">&#42;</span>
  </label>
  <app-input-multi-atom
    [type]="'text'"
    [placeholder]="placeholder ?? ''"
    [value]="value"
    (valueEmitter)="onValueChange($event)"
  ></app-input-multi-atom>
</div>
