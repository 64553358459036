import { Injectable } from '@angular/core';

import { DoodProductModel } from '@core/models/product.model';
import { DoodOrderModel } from '@store/order/order.model';
import { AnalyticsService } from '@core/services/app/analytics.service';

@Injectable({
  providedIn: 'root',
})
export class EventTrackerService {
  static EventShopViewed = 'DOOD_ShopViewed';
  static EventMarketplaceViewed = 'DOOD_MarketplaceViewed';
  static EventOrderCreated = 'DOOD_OrderCreated';
  static EventOrderPaid = 'DOOD_OrderPaid';
  static EventProductViewed = 'DOOD_ProductViewed';
  static EventProductAddedToCart = 'DOOD_ProductAddedToCart';
  static EventProductRemovedFromCart = 'DOOD_ProductRemovedFromCart';
  static EventProductsUpdatedInCart = 'DOOD_ProductsUpdatedInCart';
  static EventCartCleared = 'DOOD_CartCleared';

  constructor(private readonly analyticsService: AnalyticsService) {}

  dispatch(eventName: string, eventData: any = {}): void {
    console.log('[EventTracker] ' + eventName);
    const customEvent = new CustomEvent(eventName, { detail: eventData });
    window.dispatchEvent(customEvent);

    if (eventName === EventTrackerService.EventProductViewed) {
      this.analyticsService.trackViewItem(eventData as DoodProductModel);
    }
    if (eventName === EventTrackerService.EventProductAddedToCart) {
      this.analyticsService.trackAddToCart(
        eventData.product as DoodProductModel,
        eventData.quantity as number,
      );
    }
    if (eventName === EventTrackerService.EventOrderPaid) {
      this.analyticsService.trackPurchase(eventData as DoodOrderModel);
    }
  }
}
