
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';

@Component({
  selector: 'app-kiosk-double-button-footer-block',
  templateUrl: './kiosk-double-button-footer-block.component.html',
})
export class KioskDoubleButtonFooterBlockComponent {

  @Input() backgroundColor?: IContentBuilderFieldColor;
  @Input() textColor?: IContentBuilderFieldColor;
  @Input() primaryButtonBackgroundColor?: IContentBuilderFieldColor;
  @Input() primaryButtonTextColor?: IContentBuilderFieldColor;
  @Input() primaryButtonLabel?: string;
  @Input() primaryButtonLink?: string;
  @Input() secondaryButtonBackgroundColor?: IContentBuilderFieldColor;
  @Input() secondaryButtonTextColor?: IContentBuilderFieldColor;
  @Input() secondaryButtonLabel?: string;
  @Input() secondaryButtonLink?: string;
  @Input() displaySecondButton?: boolean = true;

  constructor(
    private readonly router: Router,
    private readonly helperRouter: RouterHelperService,
  ) {
  }

  navigateTo(navigateTo: string | undefined) {
    if (navigateTo) {
      this.router.navigate([this.helperRouter.translateRoute(navigateTo)]);
    }
  }
 
}
