<a
  class="cursor-pointer"
  [target]="linkTarget"
  [link]="linkUrl"
>
    <img
      [src]="image?.url"
      class="h-full max-h-full object-cover"
      loading="lazy"
    />
</a>
