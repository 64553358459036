<app-modal-filler-atom
  (click)="close()"
  [index]="(index$ | async) ?? 0"
></app-modal-filler-atom>
<div
  class="fixed top-0 mx-auto h-screen w-full lg:left-0 lg:flex lg:h-full lg:w-full lg:items-center lg:justify-center mdMax:max-w-screen-sm"
  [style.z-index]="((index$ | async) ?? 0) + 50"
>
  <app-modal-filler-atom
    *ngIf="isDesktop$ | async"
    [withBackdrop]="false"
    (click)="close()"
    [index]="(index$ | async) ?? 0"
  ></app-modal-filler-atom>
  <div
    class="w-full overflow-y-auto bg-white md:h-5/6 lg:max-h-85-screen lg:max-w-screen-sm lg:rounded-lg mdMax:h-full"
    [style.z-index]="((index$ | async) ?? 0) + 50"
  >
    <div class="relative h-64">
      <img
        *ngIf="shopImageCover"
        class="absolute h-64 w-full object-cover"
        src="{{ shopImageCover }}"
        alt=""
      />
      <div
        class="right absolute right-5 top-5 lg:right-8"
        [class.top-8]="isNotched"
      >
        <app-rounded-icon-button
          [size]="'sm'"
          [color]="'primary'"
          [type]="'normal'"
          icon="icon-cross"
          (onClick)="close()"
        ></app-rounded-icon-button>
      </div>
    </div>
    <div class="m-5 border-b border-neutral-300 pb-5 lg:mx-8">
      <app-shop-description-atom
        [name]="shop[shopKeys.Name]"
        [description]="shop[shopKeys.Description]"
        [shortDescription]="shop[shopKeys.ShortDescription]"
        [category]="shop[shopKeys.Type]?.name"
      ></app-shop-description-atom>
    </div>
    <div>
      <app-shop-address-atom
        [address]="shop[shopKeys.Venue]?.address?.street_line1 ?? ''"
        [postcode]="shop[shopKeys.Venue]?.address?.postal_code ?? ''"
        [city]="shop[shopKeys.Venue]?.address?.city ?? ''"
        [localization]="true"
        (getLocalization)="locateShop(shop)"
      ></app-shop-address-atom>
    </div>
    <div class="lg: mx-5 border-b border-neutral-300 lg:mx-8"></div>
    <div
      *ngIf="shop[shopKeys.Schedules]"
      class="mx-5 cursor-pointer border-b border-neutral-300 py-5 lg:mx-8"
    >
      <app-shop-schedules-atom
        [schedules]="shop[shopKeys.Schedules]"
        [isShopMoveable]="shop[shopKeys.Moveable]"
      ></app-shop-schedules-atom>
    </div>
    <div
      *ngIf="!shop[shopKeys.Schedules]"
      class="mx-5 cursor-pointer border-b border-neutral-300 py-5 lg:mx-8"
    >
      <p class="font-accent text-xl font-bold">
        {{ 'shop-informations.actually-closed' | translate }}
      </p>
    </div>
    <div
      *ngIf="shop[shopKeys.ContactEmail] || shop[shopKeys.Phone]"
      class="mb-1 px-5 pb-5 pt-3 font-base font-normal text-neutral-600 lg:px-8"
    >
      <div class="font-accent text-md font-bold leading-6 text-black lg:text-2xl">
        {{ 'shop-informations.contact' | translate }}
      </div>
      <div class="text-base lg:text-md">
        <p
          class="py-2"
          *ngIf="shop[shopKeys.ContactEmail]"
        >
          {{ 'shop-informations.email' | translate }} :
          <a [href]="'mailto:' + shop[shopKeys.ContactEmail]">{{ shop[shopKeys.ContactEmail] }}</a>
        </p>
        <p *ngIf="shop[shopKeys.Phone]">
          {{ 'shop-informations.phone' | translate }} :
          <a [href]="'tel:' + shop[shopKeys.Phone]">{{ shop[shopKeys.Phone] }}</a>
        </p>
      </div>
    </div>
  </div>
</div>
