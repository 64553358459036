// call-function.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appFn',
  standalone: true,
})
export class CallComponentMethodPipe implements PipeTransform {
  /**
   * Call a function from a component
   * @param value The value to pass to the function
   * @param fn The function to call
   * @param args The arguments to pass to the function
   * @returns The result of the function
   *
   * @example
   * <div>{{ product | appFn:isAvailable }}</div>
   *  */
  transform(value: any, fn: Function, ...args: any[]): any {
    if (typeof fn === 'function') {
      return fn(value, ...args);
    }
    return value;
  }
}
