import { Component, OnDestroy, OnInit } from '@angular/core';

import { ModalsService } from '@core/services/modals/modals.service';
import { CartStoreSelector } from '@common/selectors/cart.selector';
import { CartParametersModalComponent } from '@shared/modals/cart-parameters-modal/cart-parameters-modal.component';

@Component({
  template: ``,
  selector: 'app-cart-parameters-modal-launcher-block',
})
export class CartParametersModalLauncherBlockComponent implements OnInit, OnDestroy {
  static handle = 'CartParametersModalLauncherBlockComponent';

  constructor(
    private cartSelector: CartStoreSelector,
    private readonly modalsService: ModalsService,
  ) {}

  ngOnInit(): void {
    const cart = this.cartSelector.active;
    if (!cart || !cart.type || !cart?.is_user_selected_distribution_mode) {
      this.modalsService.open(CartParametersModalComponent.handle);
    }
  }

  ngOnDestroy(): void {
    this.modalsService.close(CartParametersModalComponent.handle);
  }
}
