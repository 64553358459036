/* eslint-disable ngrx/avoid-dispatching-multiple-actions-sequentially */
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { PaymentMethods } from '@adyen/adyen-web/dist/types/types';
import { from, skipWhile, timer } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { PaymentStoreDispatcher } from '@common/dispatchers/payment.dispatcher';
import { PaymentService } from '@core/services/payment/payment.service';
import { PaymentAtTableStoreSelector } from '@common/selectors/payment-at-table.selector';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { IAdyenPaymentIntent } from '@core/models/payment.model';
import { environment } from '@env/environment';
import AdyenCheckout from '@adyen/adyen-web';
import { Router } from '@angular/router';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-pat-payment-panel-adyen-generic',
  templateUrl: './pat-payment-panel-adyen-generic.component.html',
  styleUrls: ['./pat-payment-panel-adyen-generic.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PatPaymentPanelAdyenGenericComponent
  extends DestroyerBase
  implements OnInit, OnDestroy
{
  @Input() payButtonText = '';
  @Input() isPayButtonVisible = true;
  @Input() type!: keyof PaymentMethods;
  @Input() paymentMethodHandle!: string;
  @Input() additionalAdyenConfiguration: object = {};

  @Input() clientKey?: string;
  @Input() googlePayMerchantName?: string;
  @Input() googlePayMerchantId?: string;
  @Input() googlePayGatewayMerchantId?: string;
  @Input() paymentMethodCbAvailable?: string;
  @Input() paymentMethodPaypalAvailable = false;
  @Input() paymentMethodGooglePayAvailable = false;
  @Input() paymentMethodApplePayAvailable = false;
  @Input() paymentMethodMealVoucherNatixisAvailable = false;
  @Input() paymentMethodMealVoucherSodexoAvailable = false;
  @Input() paymentMethodMealVoucherGroupeUpAvailable = false;

  @ViewChild('adyenContainer') adyenContainer?: ElementRef<HTMLElement>;

  isLoading = true;
  isPaymentInProgress = false;
  check = this.paymentAtTableSelector.check;

  private redirectUrl: string | null | undefined;
  protected checkoutComponent?: InstanceType<PaymentMethods[keyof PaymentMethods]>;

  constructor(
    private readonly router: Router,
    // private readonly cartService: CartService,
    // private orderDispatcher: OrderStoreDispatcher,
    // private readonly ordersService: OrdersService,
    private readonly paymentService: PaymentService,
    private paymentDispatcher: PaymentStoreDispatcher,
    private readonly routerHelper: RouterHelperService,
    private readonly translateService: TranslateService,
    private readonly paymentAtTableSelector: PaymentAtTableStoreSelector,
  ) {
    super();
    this.paymentAtTableSelector.selectCheck
      .pipe(takeUntil(this._destroyerRef))
      .subscribe(_check => {
        this.check = _check;
      });
  }

  ngOnInit(): void {
    this.getPaymentIntent();
    this.getRedirectUrl();
    this.isLoading = true;

    timer(100).subscribe(() => {
      this.paymentDispatcher.updateUI({
        error: null,
        button: {
          isEnabled: false,
          text: this.payButtonText,
          isVisible: Boolean(this.isPayButtonVisible),
        },
        status: {
          isLoading: false,
          isInProgress: false,
        },
      });
    });
  }

  ngOnDestroy(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();

    if (this.checkoutComponent) {
      this.checkoutComponent.unmount();
      this.checkoutComponent.remove();
    }
    this.paymentDispatcher.resetUI();
    this.paymentService.paymentIntent$.next(null);
  }

  private getPaymentIntent(): void {
    this.paymentService.paymentAdyenIntent$
      .pipe(
        takeUntil(this._destroyerRef),
        skipWhile(intent => !intent),
        take(1),
      )
      .subscribe(intent => {
        if (intent) {
          this.initializeAdyen(intent);
        }
      });
  }

  private handlePayment(): void {
    if (!this.checkoutComponent) {
      return;
    }

    this.isPaymentInProgress = true;
    this.paymentDispatcher.updateUI({
      button: {
        isEnabled: false,
      },
      status: {
        isInProgress: true,
      },
    });

    this.checkoutComponent.submit();
  }

  private initializeAdyen(paymentIntent: IAdyenPaymentIntent): void {
    this.paymentService.pay$
      .pipe(
        takeUntil(this._destroyerRef),
        take(1),
        tap(() => this.handlePayment()),
      )
      .subscribe();

    const configuration = {
      ...environment.api.adyen,
      clientKey: this.clientKey,
      session: {
        id: paymentIntent.id,
        sessionData: paymentIntent.sessionData,
      },

      onPaymentCompleted: (result: any, component: any) =>
        this.onPaymentCompleted(result, component),
      onError: (error: any, component: any) => this.onPaymentError(error, component),
      onChange: (state: any, component: any) => this.onChange(state, component),

      paymentMethodsConfiguration: {
        card: {},
        applepay: {},
        paywithgoogle: {},
      },
    };

    if (this.paymentMethodCbAvailable) {
      configuration.paymentMethodsConfiguration.card = {
        hasHolderName: false,
        holderNameRequired: false,
        billingAddressRequired: false,
      };
    }

    if (this.paymentMethodApplePayAvailable) {
      configuration.paymentMethodsConfiguration.applepay = {
        buttonColor: 'black',
        buttonType: 'plain',
      };
    }

    if (this.paymentMethodGooglePayAvailable) {
      configuration.paymentMethodsConfiguration.paywithgoogle = {
        configuration: {
          merchantName: this.googlePayMerchantName,
          merchantId: this.googlePayMerchantId,
          gatewayMerchantId: this.googlePayGatewayMerchantId,
        },
        buttonType: 'order',
        buttonSizeMode: 'fill',
      };
    }

    if (!this.adyenContainer) {
      console.error('AdyenContainer not found in DOM');
      return;
    }

    from(AdyenCheckout(configuration))
      .pipe(
        takeUntil(this._destroyerRef),
        take(1),
        tap(checkout => {
          this.isLoading = false;

          this.checkoutComponent = checkout.create(this.type);
          // @ts-ignore
          this.checkoutComponent.mount(this.adyenContainer.nativeElement);
        }),
      )
      .subscribe();
  }

  private getRedirectUrl(): void {
    this.paymentService.redirectUrl$
      .pipe(takeUntil(this._destroyerRef))
      .subscribe(redirectUrl => (this.redirectUrl = redirectUrl));
  }

  private onChange(state: any, component: any): void {
    this.paymentDispatcher.updateUI({
      button: {
        isEnabled: state.isValid && !this.isPaymentInProgress,
      },
    });
  }

  private onPaymentCompleted(result: any, component: any): void {
    if (!result || result.resultCode !== 'Authorised') {
      this.onPaymentError();
      return;
    }

    this.paymentDispatcher.updateUI({
      error: null,
      button: {
        isEnabled: false,
      },
      status: {
        isInProgress: false,
      },
    });

    this.router.navigate([
      this.routerHelper.translateRoute(!!this.redirectUrl ? `${this.redirectUrl}` : '/'),
    ]);
  }

  private onPaymentError(error?: any, component?: any): void {
    this.isPaymentInProgress = false;
    this.translateService
      .get(['payment.refusal-payment'])
      .pipe(take(1))
      .subscribe(translation => {
        this.isPaymentInProgress = false;
        this.paymentDispatcher.updateUI({
          error: translation['payment.refusal-payment'],
          button: {
            isEnabled: false,
          },
          status: {
            isInProgress: false,
          },
        });
      });

    console.error(error.name, error.message, error.stack, component);
  }
}
