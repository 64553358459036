<a
  [link]="'/shops' + slug"
  class="block max-w-200 rounded-lg bg-white drop-shadow-card"
>
  <img
    src="{{ logoUrl }}"
    alt="{{ name }}"
    loading="lazy"
  />
  <p class="p-2 font-accent font-bold text-center text-sm text-neutral-800">
    {{ name }}
  </p>
</a>
