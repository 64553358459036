import { EMPTY, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit } from '@angular/core';
import { catchError, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import {
  DoodCreateOrderQuery,
  DoodOrderType,
  DoodValidateOrderQuery,
  IOrderItem,
} from '@store/order/order.model';

import { UserKeys } from '@config/keys/user.keys';
import { CartKeys } from '@config/keys/cart.keys';
import { OrderKeys } from '@config/keys/order.keys';
import { DistributionModeKeys, ShopKeys } from '@config/keys/shop.keys';

import { CartStoreRefiner } from '@common/refiners/cart.refiner';
import { ShopStoreSelector } from '@common/selectors/shop.selector';
import { CartStoreSelector } from '@common/selectors/cart.selector';
import { OrderStoreSelector } from '@common/selectors/order.selector';
import { BasketStoreSelector } from '@common/selectors/basket.selector';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';

import { Paths } from '@config/paths.config';
import { GroupedOrderModalsAttributes } from '@config/modals/grouped-order-modals.config';
import { ORDER_ALREADY_CREATED_WITH_CART, USER_IS_ALREADY_CONFIRMED } from '@config/errors.config';

import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { DistributionModeValues, OnSiteLocationPickModeValues } from '@config/values/order.values';

import { CartService } from '@core/services/cart/cart.service';
import { UserService } from '@core/services/user/user.service';
import { BasketService } from '@core/services/basket/basket.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { ErrorService } from '@core/services/error-service/error.service';
import { OrderTypeService } from '@core/services/order-type/order-type.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { NavigationHistoryService } from '@core/services/navigation-history/navigation-history.service';

import { ConfirmModalComponent } from '@shared/modals/confirm-modal/confirm-modal.component';
import { CartParametersModalComponent } from '@shared/modals/cart-parameters-modal/cart-parameters-modal.component';
import { ShareGroupedOrderModalComponent } from '@shared/modals/share-grouped-order-modal/share-grouped-order-modal.component';
import { CartUserInformationsModalComponent } from '@shared/modals/cart-user-informations-modal/cart-user-informations-modal.component';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';
import { EntitiesService } from '@core/services/entities/entities.service';
import { EntityStoreSelector } from '@common/selectors/entity.selector';

@Component({
  selector: 'app-cart-merchant-amount-block',
  templateUrl: './cart-merchant-amount-block.component.html',
})
export class CartMerchantAmountBlockComponent extends DestroyerBase implements OnInit {
  @Input() clearCartBackLastPage = false;
  @Input() headingColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() textColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() secondaryTextColor: IContentBuilderFieldColor = {
    value: 'neutral-500',
    type: ColorFieldTypesValues.Palette,
  };

  orderValidate$ = this.orderSelector.selectValidation;
  orderHasProduct$ = this.orderSelector.selectValidation.pipe(map(v => v?.products));
  missingPhone$ = this.orderSelector.selectErrors.pipe(map(errors => errors.missing_number));

  basket$ = this.basketSelector.select;
  userId$ = this.authSelector.selectUserId;
  basketId$ = this.basketSelector.select.pipe(map(basket => basket.id));
  cartHasProduct$ = this.cartRefiner.selectActiveCartProducts.pipe(map(p => !!p.length));

  orderKeys = OrderKeys;
  missingLocationIdError = '';
  errorMessages: string[] = [];
  isGoToPaymentInProgress = false;
  onSiteLocationIsMissing = false;

  private readonly redirectUrlAfterClearCart$ = this.shopSelector.selectActive.pipe(
    switchMap(shopActive => {
      if (!shopActive) {
        return of('/');
      }
      return this.entityStoreSelector
        .selectEntity(shopActive.id)
        .pipe(map(entity => (entity ? this.entitiesService.getUrl(entity) : '/')));
    }),
  );

  constructor(
    private readonly router: Router,
    private userService: UserService,
    private cartRefiner: CartStoreRefiner,
    private cartSelector: CartStoreSelector,
    private shopSelector: ShopStoreSelector,
    private authSelector: AuthStoreSelector,
    private orderSelector: OrderStoreSelector,
    private basketSelector: BasketStoreSelector,
    private readonly cartService: CartService,
    private readonly errorService: ErrorService,
    private readonly ordersService: OrdersService,
    private readonly modalsService: ModalsService,
    private readonly basketService: BasketService,
    private readonly routerHelper: RouterHelperService,
    private readonly translateService: TranslateService,
    private readonly orderTypeService: OrderTypeService,
    private readonly navigationHistoryService: NavigationHistoryService,
    private readonly entitiesService: EntitiesService,
    private readonly entityStoreSelector: EntityStoreSelector,
  ) {
    super();
  }

  ngOnInit(): void {
    this.errorService
      .getErrorMessage$()
      .pipe(
        takeUntil(this._destroyerRef),
        tap(messages => (this.errorMessages = messages)),
      )
      .subscribe();

    this.cartSelector.selectActive
      .pipe(
        takeUntil(this._destroyerRef),
        map(cart => cart?.on_site_location_id),
        tap(onSiteLocation => {
          if (onSiteLocation) {
            this.checkIfOnSiteLocationIsMissing();
          }
        }),
      )
      .subscribe();
  }

  removeCoupon($event: any): void {
    this.cartService.removeCoupon($event);
    this.ordersService.checkCartIsValid$().subscribe();
  }

  removeUsePoints(): void {
    this.cartService.setUsePoints(undefined);
    this.ordersService.checkCartIsValid$().subscribe();
  }

  goToPayment(): void {
    this.isGoToPaymentInProgress = true;
    this.ordersService
      .checkCartIsValid$()
      .pipe(
        take(1),
        map(order => {
          if (
            order &&
            order.type &&
            order.contact_phone_number === null &&
            (order.type === DoodOrderType.delivery || order.type === DoodOrderType.shipping)
          ) {
            this.ordersService.setDefaultPhone();
            this.modalsService.open(CartUserInformationsModalComponent.handle);
            return false;
          }
          return true;
        }),
      )
      .subscribe({
        next: value => {
          if (value) {
            this.router.navigate([
              this.routerHelper.translateRoute(`/${Paths.Cart}/${Paths.Funnel}`),
            ]);
          }
        },
        complete: () => {
          this.isGoToPaymentInProgress = false;
        },
      });
  }

  clearCart(): void {
    this.modalsService.open(ConfirmModalComponent.handle);
    this.modalsService.setData(ConfirmModalComponent.handle, {
      title: this.translateService.instant('basket.clear-basket-confirm-modal.title'),
      message: this.translateService.instant('basket.clear-basket-confirm-modal.message'),
      confirmButtonText: this.translateService.instant('basket.clear-basket-confirm-modal.confirm'),
      isConfirm: false,
    });

    this.modalsService
      .getData(ConfirmModalComponent.handle)
      .pipe(
        map(modal => modal?.data?.isConfirm),
        filter(isConfirm => Boolean(isConfirm)),
        switchMap(() => this.basketId$),
        take(1),
        switchMap(id => {
          if (id) {
            this.modalsService.open(
              ShareGroupedOrderModalComponent.handle,
              GroupedOrderModalsAttributes.ClearCart,
            );
            return EMPTY;
          }
          this.cartService.clearCart();

          if (this.clearCartBackLastPage) {
            this.navigationHistoryService.goBack();
            this.modalsService.close(ConfirmModalComponent.handle);
            return EMPTY;
          }

          return this.redirectUrlAfterClearCart$;
        }),
        tap(redirectUrl => {
          window.location.href = redirectUrl;
        }),
        takeUntil(this._destroyerRef),
      )
      .subscribe();
  }

  confirmOrder(): void {
    this.isGoToPaymentInProgress = true;
    this.basketService
      .getBasket$(this.basketSelector.basket.id, this.basketSelector.basket.share_code)
      .pipe(
        switchMap(() => this.basketService.confirmBasket$()),
        take(1),
        catchError(err => {
          this.isGoToPaymentInProgress = false;
          if (
            err?.error?.detail === USER_IS_ALREADY_CONFIRMED ||
            err?.error?.detail === ORDER_ALREADY_CREATED_WITH_CART
          ) {
            return of([]);
          }

          if (err.status === 403) {
            alert(this.translateService.instant('group-order-modal.order-cancelled'));
            this.ordersService.clearOrders();
            this.basketService.clearBasket();
            this.cartService.clearCart();
            this.router.navigate([this.routerHelper.translateRoute('/')]);
          }

          return throwError(() => err);
        }),
      )
      .subscribe(res => {
        this.isGoToPaymentInProgress = false;
        this.router.navigate([
          this.routerHelper.translateRoute(`/${Paths.Validate}/${Paths.SharedOrder}`),
        ]);
      });
  }

  exitGroupOrder(): void {
    this.modalsService.open(
      ShareGroupedOrderModalComponent.handle,
      GroupedOrderModalsAttributes.LeavingOrder,
    );
  }

  cancelGroupOrder(): void {
    this.modalsService.open(
      ShareGroupedOrderModalComponent.handle,
      GroupedOrderModalsAttributes.CancelOrder,
    );
  }

  getServiceCharges(
    orderValidate: DoodValidateOrderQuery | DoodCreateOrderQuery | null | undefined,
  ): IOrderItem[] {
    const products = orderValidate?.products ?? [];
    return products.filter(product => product.is_service_charge) as IOrderItem[];
  }

  private checkIfOnSiteLocationIsMissing(): boolean {
    const shop = this.shopSelector.active;
    const cart = this.cartSelector.active;
    const basket = this.basketSelector.basket;
    const user = this.userService.user;
    const distributionMode = cart?.[CartKeys.Type];
    const orderTypeCapabilities = this.orderTypeService.getCapabilities(distributionMode);

    const onsiteLocationPickMode = shop?.[ShopKeys.DistributionModes].filter(v => {
      return v[DistributionModeKeys.Type] === DistributionModeValues.OnSite;
    })?.[0]?.[DistributionModeKeys.OnsiteLocationPickMode];

    if (cart && orderTypeCapabilities.isOnSiteLocationMandatory) {
      this.onSiteLocationIsMissing = !!!cart[CartKeys.OnSiteLocationName];

      if (
        this.onSiteLocationIsMissing &&
        onsiteLocationPickMode === OnSiteLocationPickModeValues.Url &&
        cart?.[CartKeys.Type] === DistributionModeValues.OnSite
      ) {
        this.missingLocationIdError = this.translateService.instant('basket.please-scan-qr-code');
        return false;
      }

      if (shop && !!!cart[CartKeys.OnSiteLocationName] && !basket?.[CartKeys.Id]) {
        this.modalsService.open(CartParametersModalComponent.handle);
      }

      if (
        shop &&
        !!!cart[CartKeys.OnSiteLocationName] &&
        basket?.[CartKeys.User]?.[UserKeys.Id] === user?.[UserKeys.Id]
      ) {
        this.modalsService.open(CartParametersModalComponent.handle);
      }
      return !!cart[CartKeys.OnSiteLocationName];
    }

    return false;
  }

  forceType(data: any): any {
    return data as any;
  }
}
