import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { DoodEntityModel } from '@core/models/entity.model';

export const ENTITY_STORE_KEY = 'Entities';

export interface DoodEntityState extends EntityState<DoodEntityModel> {}

export const ENTITY_STORE_ADAPTER = createEntityAdapter<DoodEntityModel>();

export const ENTITY_STORE_INITIAL_STATE: DoodEntityState = ENTITY_STORE_ADAPTER.getInitialState();
