<app-modal-filler-atom
  (click)="closeTipModal()"
  [index]="(index$ | async) ?? 0"
></app-modal-filler-atom>
<div
  class="fixed right-0 left-0 shadow overflow-y-auto"
  [ngClass]="
    (settingsSelector.selectDeviceIsMobileScreen | async)
      ? 'bottom-0 max-h-85-screen max-w-screen-sm'
      : 'flex justify-center items-center w-full h-full top-0 left-0'
  "
  [style.z-index]="((index$ | async) ?? 0) + 50"
>
  <app-input-tip-picker-atom
    (applyTip)="onApplyTip($event)"
    (closeTip)="closeTipModal()"
    [error]="error"
  ></app-input-tip-picker-atom>
</div>
