<div
  class="md:flex md:flex-col md:items-center"
  *ngIf="displayCard"
>
  <div class="px-5 pb-3 md:w-8/12">
    <div
      class="flex flex-col p-5 md:p-6 mb-3 md:mb-8 rounded-m"
      [style.backgroundColor]="backgroundColor | contentBuilderColor"
    >
      <div class="flex flex-row justify-between items-center">
        <div
          class="text-md md:text-29px font-accent font-bold"
          [style.color]="textColor | contentBuilderColor"
        >
          <p>{{ text }}</p>
          <a
            class="font-base font-bold text-sm md:text-base text-primary-600 cursor-pointer"
            [routerLink]="routerHelper.translateRoute('/orders')"
            >{{ linkText }}</a
          >
        </div>
        <div class="flex-shrink-0 p-4 md:mr-6">
          <img
            src="assets/images/hotel-bell.svg"
            class="animate-wiggle"
            width="48"
            height="48"
            alt="bell"
          />
        </div>
      </div>
    </div>
  </div>
</div>
