import { Router } from '@angular/router';
import { takeUntil, tap } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';

import { DoodProductModel } from '@core/models/product.model';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { SuggestionsSetsService } from '@core/services/suggestions-sets/suggestions-sets.service';

@Component({
  selector: 'app-cart-suggestion-cards-block',
  templateUrl: './cart-suggestion-cards-block.component.html',
})
export class CartSuggestionCardsBlockComponent extends DestroyerBase implements OnInit {
  @Input() heading?: string;
  @Input() leading?: string;
  @Input() suggestionsSet?: string;

  isMobile = false;

  suggestions: DoodProductModel[] = [];

  constructor(
    private readonly router: Router,
    protected settingsSelector: SettingsStoreSelector,
    private readonly routerHelper: RouterHelperService,
    private readonly suggestionsSetsService: SuggestionsSetsService,
  ) {
    super();

    this.settingsSelector.selectDeviceIsMobileScreen
      .pipe(takeUntil(this._destroyerRef))
      .subscribe(_isMobile => (this.isMobile = _isMobile));
  }

  ngOnInit(): void {
    if (!this.suggestionsSet) {
      return;
    }
    this.suggestionsSetsService
      .getSuggestionsSet$(this.suggestionsSet)
      .pipe(
        takeUntil(this._destroyerRef),
        tap(suggestions => (this.suggestions = suggestions)),
      )
      .subscribe();
  }

  productClick(product: DoodProductModel): void {
    this.router.navigate([
      this.routerHelper.translateRoute(`cart/products/${product.shop_id}/${product.id}`),
    ]);
  }

  protected forceType(data: any): any {
    return data as any;
  }
}
