import { Router } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Component, OnDestroy } from '@angular/core';

import { OrderStoreSelector } from '@common/selectors/order.selector';
import { OrderStoreDispatcher } from '@common/dispatchers/order.dispatcher';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

import { DoodOrderModel } from '@store/order/order.model';
import { DOOD_ORDER_HISTORICAL_STATUS } from '@store/order/order.constant';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';

@Component({
  selector: 'app-orders-page-block',
  templateUrl: './orders-page-block.component.html',
})
export class OrdersPageBlockComponent implements OnDestroy {
  private destroyed$ = new Subject<boolean>();

  activeOrdersLoading = true;
  historicalOrdersLoading = true;

  orders: DoodOrderModel[] = [];
  activeOrders: DoodOrderModel[] = [];
  historicalOrders: DoodOrderModel[] = [];

  marketplaceId$ = this.marketplaceSelector.selectMarketplaceId;

  constructor(
    private readonly router: Router,
    private userSelector: AuthStoreSelector,
    private orderSelector: OrderStoreSelector,
    private orderDispatcher: OrderStoreDispatcher,
    private readonly routerHelper: RouterHelperService,
    private marketplaceSelector: MarketplaceStoreSelector,
  ) {
    this.orderSelector.selectOrders.pipe(takeUntil(this.destroyed$)).subscribe(_orders => {
      this.orders = _orders;
      this._filterOrders();
      this.activeOrdersLoading = false;
      this.historicalOrdersLoading = false;
    });
    combineLatest([this.userSelector.selectUserId, this.marketplaceId$])
      .pipe(
        takeUntil(this.destroyed$),
        tap(([userId, marketplaceId]) => {
          if (userId) {
            this._loadOrders(userId, marketplaceId);
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  goToOrderDetails(id: string): void {
    this.router.navigate([this.routerHelper.translateRoute(`/orders/${id}/details`)]);
  }

  private _filterOrders(): void {
    const _activeOrders: DoodOrderModel[] = [];
    const _historicalOrders: DoodOrderModel[] = [];

    this.orders.forEach(_order => {
      if (DOOD_ORDER_HISTORICAL_STATUS.includes(_order.status)) {
        _historicalOrders.push(_order);
        return;
      }
      _activeOrders.push(_order);
    });

    this.activeOrders = _activeOrders;
    this.historicalOrders = _historicalOrders;
  }

  private _loadOrders(userId: string, marketplaceId: string): void {
    this.activeOrdersLoading = true;
    this.historicalOrdersLoading = true;
    this.orderDispatcher.fetchOrders(userId, marketplaceId);
  }
}
