<app-kiosk-header-atom
  *ngIf="logo?.url as url"
  [logoImageUrl]="url"
  [displayLogo]="displayLogo"
  [languageFlagUrl]="languageFlagUrl"
  [backgroundColor]="backgroundColor"
  [backgroundImage]="backgroundImage"
  [headerLandingText]="headerLandingText"
  [displayHomeButton]="displayHomeButton"
  [buttonHomeText]="buttonHomeText"
  [buttonBackgroundColor]="buttonBackgroundColor"
  [buttonTextColor]="buttonTextColor"
  [size]="size"
></app-kiosk-header-atom>
