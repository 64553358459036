<div
  [id]="id"
  style="contain: paint style"
>
  <div
    *ngIf="title"
    class="px-5"
  >
    <h2 class="pt-10 font-brand text-3xl text-base text-neutral-900">
      {{ title }}
    </h2>
  </div>
  <div
    *ngIf="heading"
    class="mt-2 px-5"
  >
    <p class="font-accent">{{ heading }}</p>
  </div>
  <div
    *ngFor="let category of categories"
    class="mt-3 flex flex-col text-neutral-800"
  >
    <div
      class="mt-2 px-5"
      *ngIf="category.title"
    >
      <h3 class="font-accent text-2xl font-bold">{{ category.title }}</h3>
    </div>
    <div
      *ngIf="category.heading"
      class="mt-3 px-5"
    >
      <p class="font-accent">{{ category.heading }}</p>
    </div>
    <div class="mt-3">
      <app-product-push-list-atom
        displayContext="marketplace_page"
        [products]="category.products"
        [tagColors]="tagColors"
        (navigateTo)="onNavigateTo($event)"
      ></app-product-push-list-atom>
    </div>
  </div>
</div>
