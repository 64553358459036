import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-social-networks-follow-us-block',
  templateUrl: './social-networks-follow-us-block.component.html',
})
export class SocialNetworksFollowUsBlockComponent {
  @Input() baseline?: string;
  @Input() socialInstagramURL?: string;
  @Input() socialFacebookURL?: string;
  @Input() socialTiktokURL?: string;
  @Input() socialLinkedinURL?: string;
}
