import { Component, Input } from '@angular/core';
import { LineItemKeys } from '@config/keys/line-item.keys';
import { DoodLineItemModel } from '@core/models/line-item.model';

@Component({
  selector: 'app-detail-order-atom',
  templateUrl: './detail-order-atom.component.html',
})
export class DetailOrderAtomComponent {
  @Input() lineItems!: DoodLineItemModel[];
  @Input() totalAmount!: number;
  @Input() tips!: number;
  @Input() paidAmount!: number;

  get LineItemKeys(): typeof LineItemKeys {
    return LineItemKeys;
  }
}
