import { ActionGroup } from '@store/_abstract/common/action.creator';
import { ActionCreatorProps, createActionGroup, emptyProps, props } from '@ngrx/store';

export enum HydratedStoreActionsKeys {
  clear = 'Clear',
  updateHydratable = 'Update Hydratable',
  disabledHydratableAndClear = 'disabled Hydration And Clear',
}

export type HydratedStoreActions = {
  [HydratedStoreActionsKeys.clear]: ActionCreatorProps<void>;
  [HydratedStoreActionsKeys.updateHydratable]: ActionCreatorProps<{ hydratable: boolean }>;
  [HydratedStoreActionsKeys.disabledHydratableAndClear]: ActionCreatorProps<void>;
};
export type HydratedStoreActionGroup = ActionGroup<string, HydratedStoreActions>;

export const HYDRATED_STORE_HYDRATION_KEY = 'Dood/HYDRATION';

export const createHydratedStoreSource = <Source extends string>(name: string): Source => {
  return `${HYDRATED_STORE_HYDRATION_KEY}/${name}` as Source;
};

export const createHydratedStoreActions = (name: string): HydratedStoreActionGroup => {
  return createActionGroup({
    source: createHydratedStoreSource(name),
    events: {
      [HydratedStoreActionsKeys.clear]: emptyProps(),
      [HydratedStoreActionsKeys.updateHydratable]: props<{ hydratable: boolean }>(),
      [HydratedStoreActionsKeys.disabledHydratableAndClear]: emptyProps(),
    },
  });
};
