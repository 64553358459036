import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { catchError, switchMap, take, tap } from 'rxjs/operators';

import { SELECT_AUTH_USER_ID } from '@store/authentication';
import { DoodOpenCheck } from '@core/models/check.model';

import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { OnSiteLocationsService } from '@core/services/on-site-locations/on-site-locations.service';

@Component({
  selector: 'app-checks-page-block',
  templateUrl: './checks-page-block.component.html',
})
export class ChecksPageBlockComponent implements OnInit {
  checks!: DoodOpenCheck[];

  isChecksLoading = false;

  constructor(
    private store: Store,
    private readonly router: Router,
    private readonly routerHelper: RouterHelperService,
    private readonly onSiteLocationsService: OnSiteLocationsService,
  ) {}

  ngOnInit(): void {
    this.isChecksLoading = true;

    this.store
      .select(SELECT_AUTH_USER_ID)
      .pipe(
        take(1),
        switchMap(userId => {
          if (userId) {
            this.onSiteLocationsService.getUserChecks$(userId);
          }
          return of([]);
        }),
        catchError(() => {
          this.isChecksLoading = false;
          return [];
        }),
        tap(checks => (this.checks = checks)),
        tap(() => (this.isChecksLoading = false)),
      )
      .subscribe();
  }

  goToCheckDetails(id: string): void {
    this.router.navigate([this.routerHelper.translateRoute(`/checks/${id}/details`)]);
  }

  protected forceType(event: any): any {
    return event as any;
  }
}
