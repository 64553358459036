import { Component, Input, OnInit } from '@angular/core';

@Component({
  template: ``,
  selector: 'app-script-block',
})
export class ScriptBlockComponent implements OnInit {
  @Input() script?: string;
  @Input() scriptPosition?: string;

  constructor() {}

  ngOnInit(): void {
    if (!this.script) {
      return;
    }
    const fragment = document
      .createRange()
      .createContextualFragment('<script>' + this.script + '</script>');
    if (this.scriptPosition === 'head') {
      document.head.appendChild(fragment);
      return;
    }
    document.body.appendChild(fragment);
  }
}
