import { keyBy } from 'lodash';
import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import {
  ICompoundAdditionsInformations,
  DoodDeclinableModel,
  IDeclinableCategory,
  IOverridesPrices,
  DoodProductModel,
  IProductAdditionGroupInformation,
} from '@core/models/product.model';
import { IAdditionGroup } from '@core/models/shop.model';

import { ProductKeys } from '@config/keys/product.keys';
import { OverridesPricesKeys } from '@config/keys/price.keys';
import { AdditionGroupKeys, ShopKeys } from '@config/keys/shop.keys';

import { ProductTypeValues } from '@config/values/product.values';
import { ShopStoreSelector } from '@common/selectors/shop.selector';
import { DeclinableStoreSelector } from '@common/selectors/declinable.selector';

import { ProductStoreDispatcher } from '@common/dispatchers/product.dispatcher';
import { DeclinableStoreDispatcher } from '@common/dispatchers/declinable.dispatcher';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(
    private shopSelector: ShopStoreSelector,
    private readonly translate: TranslateService,
    private productDispatcher: ProductStoreDispatcher,
    private declinableSelector: DeclinableStoreSelector,
    private declinableDispatcher: DeclinableStoreDispatcher,
  ) {}

  setProductToStore(product: DoodProductModel | null | undefined): void {
    if (product) {
      // TODO: Check if we can  replace this by setActive instead if the product is already present in the store
      this.productDispatcher.insertActive(product);
    } else {
      this.productDispatcher.resetActive();
    }
  }

  getAdditionsByGroup(groups: IAdditionGroup[], additions: DoodProductModel[]): IAdditionGroup[] {
    const groupsIndexed = keyBy(groups, 'id');
    const defaultAdditionGroup: IAdditionGroup = {
      [AdditionGroupKeys.Id]: 'custom-addition-group',
      [AdditionGroupKeys.MinCount]: 0,
      [AdditionGroupKeys.Name]: this.translate.instant('product-add-modal.add-addition'),
      [AdditionGroupKeys.Position]: 100,
      [AdditionGroupKeys.Items]: [],
      [AdditionGroupKeys.Count]: 0,
    };
    const additionsByGroup: { [key: string]: IAdditionGroup } = {};

    for (const item of additions) {
      if (!item[ProductKeys.AdditionsGroup] && item[ProductKeys.Id]) {
        defaultAdditionGroup[AdditionGroupKeys.Items].push(item);
      }
      if (
        !additionsByGroup[item[ProductKeys.AdditionsGroup]] &&
        groupsIndexed[item[ProductKeys.AdditionsGroup]]
      ) {
        additionsByGroup[item[ProductKeys.AdditionsGroup]] = {
          ...groupsIndexed[item[ProductKeys.AdditionsGroup]],
          items: [],
        };
      }

      additionsByGroup[item[ProductKeys.AdditionsGroup]]?.items.push(item);
    }

    return Object.values(additionsByGroup);
  }

  isAvailable(product: DoodProductModel): boolean {
    const inventoryAvailable = product[ProductKeys.InventoryAvailable] || 0;

    return (
      !!product[ProductKeys.Available] &&
      (!product[ProductKeys.InventoryTracked] || inventoryAvailable > 0)
    );
  }

  removeProductToStore(): void {
    this.productDispatcher.resetActive();
  }

  setCategories(
    shopId: string,
    categories: IDeclinableCategory[],
    prices?: IOverridesPrices[],
  ): void {
    this.shopSelector
      .selectShop(shopId)
      .pipe(take(1))
      .subscribe(shop => {
        if (!shop) {
          return;
        }

        let products = shop[ShopKeys.Products];
        const shopAdditions = products.filter(
          product =>
            product[ShopKeys.Type] === ProductTypeValues.Addition && !product[ProductKeys.Hidden],
        );
        const indexedShopAdditions = keyBy(shopAdditions, 'id');
        let additionsByGroup: IAdditionGroup[] = [];
        if (shop[ShopKeys.AdditionGroups]?.length) {
          additionsByGroup = this.getAdditionsByGroup(shop[ShopKeys.AdditionGroups], shopAdditions);
        }

        const declinables: DoodDeclinableModel[] = categories.map(category => {
          const indexedOverridePrices = keyBy(
            prices?.filter(price => price[OverridesPricesKeys.Store] === category.store_id),
            'product',
          );
          const foundedStore = shop[ShopKeys.Store]?.find(store => store.id === category.store_id);
          const mappedProducts = category.products
            .map(
              productId =>
                products.find(
                  product => product[ProductKeys.Id] === productId,
                ) as IProductAdditionGroupInformation,
            )
            .filter(product => product)
            .map(product => {
              return {
                ...product,
                [ProductKeys.Price]:
                  indexedOverridePrices[product[ProductKeys.Id]]?.additional_price,
                ...(product[ProductKeys.Additions].products.length && {
                  additions_group_informations: [
                    {
                      [AdditionGroupKeys.Id]: 'additions',
                      [AdditionGroupKeys.Name]: this.translate.instant(
                        'product-add-modal.add-addition',
                      ),
                      [AdditionGroupKeys.Items]: [],
                      [AdditionGroupKeys.MinCount]: 0,
                    },
                  ],
                }),
              };
            });
          let compoundItem: DoodDeclinableModel = {
            ...category,
            store_id: category.store_id as string,
            name: foundedStore?.name as string,
            description: foundedStore?.description as string,
            products: mappedProducts,
          };

          if (
            category.min_count === null ||
            category.min_count <= 0 ||
            (mappedProducts.length === 1 &&
              category.min_count === 1 &&
              category.count === 1 &&
              !mappedProducts[0].additions_group_informations?.length)
          ) {
            compoundItem.isValid = true;
          }

          // If only one selectable item, select automatically
          if (
            mappedProducts.length === 1 &&
            category.min_count === 1 &&
            category.count === 1 &&
            !mappedProducts[0].additions_group_informations?.length &&
            this.isAvailable(mappedProducts[0])
          ) {
            compoundItem.selected = [
              {
                id: mappedProducts[0].id,
                quantity: 1,
              },
            ];
            compoundItem.isVisited = true;
          }

          return compoundItem;
        });

        declinables.forEach(compound => {
          compound.products.forEach(product => {
            additionsByGroup.forEach(group => {
              group[AdditionGroupKeys.Items].forEach(item => {
                if (
                  product[ProductKeys.Additions].products.includes(item[ProductKeys.Id]) &&
                  !product.additions_group_informations?.some(
                    el => el[AdditionGroupKeys.Id] === group[AdditionGroupKeys.Id],
                  )
                ) {
                  const items = group[AdditionGroupKeys.Items].filter(groupItem =>
                    product[ProductKeys.Additions].products.includes(groupItem[ProductKeys.Id]),
                  );

                  if (items.length === 0) {
                    return;
                  }

                  const positionOfFirstAdditionInParentProduct = product[
                    ProductKeys.Additions
                  ].products.indexOf(items[0][ProductKeys.Id]);
                  product.additions_group_informations?.push({
                    ...group,
                    items,
                    ...(group[AdditionGroupKeys.MinCount] > 0 && {
                      isValid: false,
                    }),
                    [AdditionGroupKeys.Position]: positionOfFirstAdditionInParentProduct,
                  });
                }
              });
            });

            product[ProductKeys.Additions].products.forEach(addition => {
              if (
                indexedShopAdditions[addition] &&
                !indexedShopAdditions[addition]?.addition_group
              ) {
                product.additions_group_informations
                  ?.find(el => el.id === 'additions')
                  ?.items.push(indexedShopAdditions[addition]);
              }
            });
            product.additions_group_informations?.sort((a, b) => {
              return (
                (a?.[AdditionGroupKeys.Position] ?? 0) - (b?.[AdditionGroupKeys.Position] ?? 0)
              );
            });
          });
        });

        this.declinableDispatcher.setAll(declinables);
      });
  }

  updateSelectedCompoundProduct(products: string[], storeId: string): void {
    this.declinableDispatcher.mapOne({
      id: storeId,
      map: _declinable => {
        const productList = _declinable.products.map(el => {
          if (products.includes(el[ProductKeys.Id])) {
            return {
              ...el,
            };
          } else {
            return {
              ...el,
              additions_group_informations: el.additions_group_informations?.map(group => {
                if (group.isValid) {
                  const { isValid, ...newGroup } = group;
                  return newGroup;
                } else {
                  return group;
                }
              }),
              additions_informations: [],
            };
          }
        });
        return {
          ..._declinable,
          products: productList,
          selected: products.map(product => {
            return {
              id: product,
              additions_by_group: _declinable.selected?.find(el => el.id === product)
                ?.additions_by_group,
              quantity: 1,
            };
          }),
          isValid:
            products.length === _declinable.min_count ||
            !!(
              products.length &&
              products.length >= _declinable.min_count &&
              products.length <= _declinable.count
            ) ||
            _declinable.min_count === null ||
            _declinable.min_count <= 0,
        };
      },
    });

    this.checkIfProductHasGroupInvalid(products, storeId);
  }

  setInvalidCompoundProduct(storeId: string): void {
    this.declinableDispatcher.mapOne({
      id: storeId,
      map: _declinable => {
        return {
          ..._declinable,
          isValid: false,
        };
      },
    });
  }

  updateSelectedAdditionsCompoundProduct(
    products: string[],
    storeId: string,
    productId: string,
    shopAdditions: DoodProductModel[],
    groupId: string,
  ): void {
    this.declinableDispatcher.mapOne({
      id: storeId,
      map: entity => {
        const groups = entity.products.find(
          e => e[ProductKeys.Id] === productId,
        )?.additions_group_informations;
        const indexedGroups = keyBy(groups, 'id');
        return {
          ...entity,
          products: entity.products?.map(product => {
            if (product[ProductKeys.Id] === productId) {
              const indexedShopAdditions = keyBy(shopAdditions, 'id');

              if (product.additions_informations) {
                return {
                  ...product,
                  additions_informations: this.retrieveAdditionsInformation(
                    product.additions_informations,
                    groupId,
                    products,
                    shopAdditions,
                  ),
                  additions_group_informations: product.additions_group_informations?.map(el => {
                    if (el[AdditionGroupKeys.Id] === groupId) {
                      return {
                        ...el,
                        count: products.length,
                        selectedItems: this.groupByIdAndAddQuantity(
                          products.map(id => indexedShopAdditions[id]),
                        ),
                      };
                    } else {
                      return el;
                    }
                  }),
                };
              } else {
                return {
                  ...product,
                  additions_informations: [
                    {
                      group_id: groupId,
                      products: products.map(id => indexedShopAdditions[id]),
                    },
                  ],
                  additions_group_informations: product.additions_group_informations?.map(el => {
                    if (el[AdditionGroupKeys.Id] === groupId) {
                      return {
                        ...el,
                        count: products.length,
                        selectedItems: this.groupByIdAndAddQuantity(
                          products.map(id => indexedShopAdditions[id]),
                        ),
                      };
                    } else {
                      return el;
                    }
                  }),
                };
              }
            } else {
              return {
                ...product,
              };
            }
          }),
          selected: entity.selected?.map(item => {
            if (item[ProductKeys.Id] === productId && indexedGroups && groupId) {
              if (item.additions_by_group?.length) {
                return {
                  ...item,
                  additions_by_group: item.additions_by_group.map(group => {
                    if (
                      group[AdditionGroupKeys.Id] === groupId &&
                      !item.additions_by_group?.length
                    ) {
                      return [
                        {
                          ...group,
                          selected: products,
                        },
                      ];
                    } else {
                      const groupAlreadyExist = item.additions_by_group?.find(
                        el => el.id === groupId,
                      );
                      const additionsByGroupAlreadySelected = item.additions_by_group;

                      const product = entity.products.find(el => el[ProductKeys.Id] === productId);
                      const addGroup = product?.additions_group_informations?.find(
                        el => el.id === groupId,
                      );
                      if (
                        !groupAlreadyExist &&
                        additionsByGroupAlreadySelected?.length &&
                        addGroup
                      ) {
                        return [
                          ...additionsByGroupAlreadySelected,
                          {
                            ...addGroup,
                            selected: products,
                          },
                        ];
                      } else if (groupAlreadyExist && additionsByGroupAlreadySelected) {
                        const result = additionsByGroupAlreadySelected.map(el =>
                          el[AdditionGroupKeys.Id] === groupAlreadyExist[AdditionGroupKeys.Id]
                            ? {
                                ...groupAlreadyExist,
                                selected: products,
                              }
                            : el,
                        );
                        return [...result];
                      }
                    }
                  })[0] as IAdditionGroup[],
                };
              } else {
                return {
                  ...item,
                  additions_by_group: [
                    {
                      ...indexedGroups[groupId],
                      selected: products,
                    },
                  ],
                };
              }
            } else {
              return {
                ...item,
              };
            }
          }),
        };
      },
    });

    this.checkIfGroupIsInvalid(storeId, productId, groupId);
  }

  private retrieveAdditionsInformation(
    additions: ICompoundAdditionsInformations[],
    groupId: string,
    products: string[],
    shopAdditions: DoodProductModel[],
  ): ICompoundAdditionsInformations[] {
    const indexedShopAdditions = keyBy(shopAdditions, 'id');
    const selectedByGroup = additions.find(el => el.group_id === groupId);
    if (selectedByGroup) {
      return additions.map(el => {
        if (el.group_id === groupId) {
          return {
            ...selectedByGroup,
            products: products
              .map(id => indexedShopAdditions[id])
              .reduce(
                (acc, item) => {
                  const existingItem = acc.find(x => x.id === item.id);
                  existingItem ? existingItem.quantity++ : acc.push({ ...item, quantity: 1 });
                  return acc;
                },
                [] as (DoodProductModel & { quantity: number })[],
              ),
          };
        } else {
          return el;
        }
      });
    } else {
      return [
        ...additions,
        {
          group_id: groupId,
          products: products.map(id => indexedShopAdditions[id]),
        },
      ];
    }
  }

  private checkIfProductHasGroupInvalid(products: string[], storeId: string): void {
    this.declinableSelector
      .selectDeclinable(storeId)
      .pipe(take(1))
      .subscribe(store => {
        let isValid = true;
        if (store && !store.isValid) {
          isValid = false;
        }
        if (store) {
          store.products.map(product => {
            product.additions_group_informations?.map(group => {
              if (
                products.includes(product[ProductKeys.Id]) &&
                group[AdditionGroupKeys.MinCount] > 0 &&
                !group.isValid
              ) {
                isValid = false;
              }
            });
          });
        }

        this.declinableDispatcher.mapOne({
          id: storeId,
          map: entity => {
            return {
              ...entity,
              isValid,
            };
          },
        });
      });
  }

  private checkIfGroupIsInvalid(storeId: string, productId: string, groupId: string): void {
    this.declinableSelector
      .selectDeclinable(storeId)
      .pipe(take(1))
      .subscribe(store => {
        const selectedGroup = store?.selected
          ?.find(el => el.id === productId)
          ?.additions_by_group?.find(el => el.id === groupId);
        if (
          selectedGroup &&
          selectedGroup.selected &&
          selectedGroup[AdditionGroupKeys.MinCount] > 0
        ) {
          const isValid =
            selectedGroup.selected?.length >= selectedGroup[AdditionGroupKeys.MinCount];

          this.declinableDispatcher.mapOne({
            id: storeId,
            map: entity => {
              return {
                ...entity,
                isValid,
                products: entity.products.map(product => {
                  if (product[ProductKeys.Id] === productId) {
                    return {
                      ...product,
                      additions_group_informations: product.additions_group_informations?.map(
                        group => {
                          if (group[AdditionGroupKeys.Id] === groupId) {
                            return {
                              ...group,
                              isValid,
                            };
                          } else {
                            return group;
                          }
                        },
                      ),
                    };
                  } else {
                    return product;
                  }
                }),
              };
            },
          });
        }
      });
  }

  private groupByIdAndAddQuantity(products: DoodProductModel[]): DoodProductModel[] {
    const groupedProducts: { [id: string]: DoodProductModel } = {};

    products.forEach(product => {
      if (!product) {
      } else if (groupedProducts[product.id]?.quantity) {
        // @ts-ignore
        groupedProducts[product.id].quantity++;
      } else {
        groupedProducts[product.id] = { ...product, quantity: 1 };
      }
    });

    return Object.values(groupedProducts);
  }
}
