import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal-filler-atom',
  templateUrl: './modal-filler-atom.component.html',
  styleUrls: ['./modal-filler-atom.component.scss'],
})
export class ModalFillerAtomComponent {
  @Input() index!: number;
  @Input() withBackdrop = true;

  // TODO: NEVER CALL OUTPUTS LIKE EXISTING JS EVENT, NEVER !
  @Output() click = new EventEmitter<Event>();
  @Output() onClick = new EventEmitter<Event>();

  onClicked(_event: Event): void {
    this.click.emit(_event);
    this.onClick.emit(_event);
  }
}
