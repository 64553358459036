import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { createActiveStoreActions } from '@store/_abstract/active';
import { createHydratedStoreActions } from '@store/_abstract/hydrated/hydrated.action';

import { CART_STORE_KEY, CartState } from './cart.state';
import { DoodCartComment, DoodCartModel, DoodReconstructedCartItem } from '@core/models/cart.model';

export const CART_STORE_API_ACTIONS = createActionGroup({
  source: 'Dood/API/Cart',
  events: {},
});

export const CART_STORE_LOCAL_ACTIONS = createActionGroup({
  source: 'Dood/LOCAL/Cart',
  events: {
    // Comment
    'Set Comment': props<{ comment: DoodCartComment }>(),
    'Update Comment': props<{ comment: Partial<DoodCartComment> }>(),
    'Reset Comment': emptyProps(),

    // Edit Item
    'Set Edit Item': props<{ item: DoodReconstructedCartItem }>(),
    'Update Edit Item': props<{ item: Partial<DoodReconstructedCartItem> }>(),
    'Reset Edit Item': emptyProps(),

    // Last Update
    'Update Last Update': props<{ last_update: Date }>(),
    'Reset Last Update': emptyProps(),

    // Misc
    'reset': emptyProps(),
  },
});

export const CART_STORE_COMPONENT_ACTIONS = createActionGroup({
  source: 'Dood/COMPONENT/Cart',
  events: {
    // State
    'Update State': props<{ changes: Partial<CartState> }>(),

    // Reset
    'Reset And Clear': emptyProps(),
    'Reset And Clear Cart Stores': emptyProps(),

    // Misc
    'Update On Timeout': emptyProps(),
    'Check Last Cart Update': emptyProps(),
  },
});

export const CART_STORE_ACTIVE_ACTIONS = createActiveStoreActions<DoodCartModel>(CART_STORE_KEY);

export const CART_STORE_HYDRATION_ACTIONS = createHydratedStoreActions(CART_STORE_KEY);
