import { Injectable } from '@angular/core';

import { ContentStoreDispatcher } from '@common/dispatchers/content.dispatcher';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  constructor(private contentDispatcher: ContentStoreDispatcher) {}

  setTitle(title?: string): void {
    this.contentDispatcher.updateHeader({
      title: title ?? null,
    });
  }

  toggleShopSearchAddressVisibility(isVisible: boolean): void {
    this.contentDispatcher.updateHeader({
      isShopSearchAddressVisible: isVisible,
    });
  }

  toggleShopSearchParametersVisibility(isVisible: boolean): void {
    this.contentDispatcher.updateHeader({
      isShopSearchParametersVisible: isVisible,
    });
  }

  toggleCartParametersVisibility(isVisible: boolean): void {
    this.contentDispatcher.updateHeader({
      isCartParametersVisible: isVisible,
    });
  }

  toggleCartButtonVisibility(isVisible: boolean): void {
    this.contentDispatcher.updateHeader({
      isCartButtonVisible: isVisible,
    });
  }
}
