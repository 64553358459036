<div class="text-center md:text-left">
  <h4
    class="w-full flex items-center justify-center md:justify-start mb-2 font-accent text-29px font-bold"
  >
    <i
      *ngIf="icon"
      class="icon icon-{{ icon }} mr-2"
    ></i>
    {{ title }}
  </h4>
  <app-block-list [blockIdList]="columns"></app-block-list>
</div>
