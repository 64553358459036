import { ReducerTypes, ActionCreator, on } from '@ngrx/store';

import {
  ContentState,
  CONTENT_STORE_BLOCK_ADAPTER,
  CONTENT_STORE_HEADER_INITIAL_STATE,
  CONTENT_STORE_INITIAL_STATE,
  CONTENT_STORE_MODAL_ADAPTER,
  CONTENT_STORE_PRODUCT_INITIAL_STATE,
} from './content.state';
import { CONTENT_STORE_LOCAL_ACTIONS } from './content.action';

export const CONTENT_STORE_API_PIECE: ReducerTypes<ContentState, readonly ActionCreator[]>[] = [];

export const CONTENT_STORE_LOCAL_PIECE: ReducerTypes<ContentState, readonly ActionCreator[]>[] = [
  // Blocks
  on(CONTENT_STORE_LOCAL_ACTIONS.addBlocks, (state, { blocks }): ContentState => {
    return { ...state, blocks: CONTENT_STORE_BLOCK_ADAPTER.addMany(blocks, state.blocks) };
  }),
  on(CONTENT_STORE_LOCAL_ACTIONS.removeBlocks, (state, { ids }): ContentState => {
    return { ...state, blocks: CONTENT_STORE_BLOCK_ADAPTER.removeMany(ids, state.blocks) };
  }),
  on(CONTENT_STORE_LOCAL_ACTIONS.upsertBlocks, (state, { blocks }): ContentState => {
    return { ...state, blocks: CONTENT_STORE_BLOCK_ADAPTER.upsertMany(blocks, state.blocks) };
  }),
  on(CONTENT_STORE_LOCAL_ACTIONS.setBlocks, (state, { blocks }): ContentState => {
    return { ...state, blocks: CONTENT_STORE_BLOCK_ADAPTER.setAll(blocks, state.blocks) };
  }),
  on(CONTENT_STORE_LOCAL_ACTIONS.addBlock, (state, { block }): ContentState => {
    return { ...state, blocks: CONTENT_STORE_BLOCK_ADAPTER.addOne(block, state.blocks) };
  }),
  on(CONTENT_STORE_LOCAL_ACTIONS.removeBlock, (state, { id }): ContentState => {
    return { ...state, blocks: CONTENT_STORE_BLOCK_ADAPTER.removeOne(id, state.blocks) };
  }),
  on(CONTENT_STORE_LOCAL_ACTIONS.upsertBlock, (state, { block }): ContentState => {
    return { ...state, blocks: CONTENT_STORE_BLOCK_ADAPTER.upsertOne(block, state.blocks) };
  }),
  on(CONTENT_STORE_LOCAL_ACTIONS.updateBlocksIsLoaded, (state, { isLoaded }): ContentState => {
    return { ...state, blocks: { ...state.blocks, isLoaded } };
  }),
  on(CONTENT_STORE_LOCAL_ACTIONS.updateBlocksIsLoading, (state, { isLoading }): ContentState => {
    return { ...state, blocks: { ...state.blocks, isLoading } };
  }),

  // Modals
  on(CONTENT_STORE_LOCAL_ACTIONS.addModals, (state, { configs }): ContentState => {
    return { ...state, modals: CONTENT_STORE_MODAL_ADAPTER.addMany(configs, state.modals) };
  }),
  on(CONTENT_STORE_LOCAL_ACTIONS.removeModals, (state, { ids }): ContentState => {
    return { ...state, modals: CONTENT_STORE_MODAL_ADAPTER.removeMany(ids, state.modals) };
  }),
  on(CONTENT_STORE_LOCAL_ACTIONS.upsertModals, (state, { configs }): ContentState => {
    return { ...state, modals: CONTENT_STORE_MODAL_ADAPTER.upsertMany(configs, state.modals) };
  }),
  on(CONTENT_STORE_LOCAL_ACTIONS.setModals, (state, { configs }): ContentState => {
    return { ...state, modals: CONTENT_STORE_MODAL_ADAPTER.setAll(configs, state.modals) };
  }),
  on(CONTENT_STORE_LOCAL_ACTIONS.addModal, (state, { config }): ContentState => {
    return { ...state, modals: CONTENT_STORE_MODAL_ADAPTER.addOne(config, state.modals) };
  }),
  on(CONTENT_STORE_LOCAL_ACTIONS.removeModal, (state, { id }): ContentState => {
    return { ...state, modals: CONTENT_STORE_MODAL_ADAPTER.removeOne(id, state.modals) };
  }),
  on(CONTENT_STORE_LOCAL_ACTIONS.upsertModal, (state, { config }): ContentState => {
    return { ...state, modals: CONTENT_STORE_MODAL_ADAPTER.upsertOne(config, state.modals) };
  }),
  on(CONTENT_STORE_LOCAL_ACTIONS.updateModalsIsLoaded, (state, { isLoaded }): ContentState => {
    return { ...state, modals: { ...state.modals, isLoaded } };
  }),
  on(CONTENT_STORE_LOCAL_ACTIONS.updateModalsIsLoading, (state, { isLoading }): ContentState => {
    return { ...state, modals: { ...state.modals, isLoading } };
  }),

  // header
  on(CONTENT_STORE_LOCAL_ACTIONS.updateHeader, (state, { header }): ContentState => {
    return { ...state, header: { ...state.header, ...header } };
  }),
  on(CONTENT_STORE_LOCAL_ACTIONS.resetHeader, (state): ContentState => {
    return { ...state, header: CONTENT_STORE_HEADER_INITIAL_STATE };
  }),

  // Product
  on(CONTENT_STORE_LOCAL_ACTIONS.updateProduct, (state, { product }): ContentState => {
    return { ...state, product: { ...state.product, ...product } };
  }),
  on(CONTENT_STORE_LOCAL_ACTIONS.resetProduct, (state): ContentState => {
    return { ...state, product: CONTENT_STORE_PRODUCT_INITIAL_STATE };
  }),

  // Error
  on(CONTENT_STORE_LOCAL_ACTIONS.updateError, (state, { error }): ContentState => {
    return { ...state, blocks: { ...state.blocks, error } };
  }),
  on(CONTENT_STORE_LOCAL_ACTIONS.resetError, (state): ContentState => {
    return { ...state, blocks: { ...state.blocks, error: null } };
  }),

  // Misc
  on(CONTENT_STORE_LOCAL_ACTIONS.reset, (): ContentState => {
    return CONTENT_STORE_INITIAL_STATE;
  }),
];
