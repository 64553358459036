import {map, takeUntil} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {PriceUtils} from '@shared/utils/price/price.utils';
import {DestroyerBase} from '@core/base/destroyer/destroyer.base';
import {ModalsService} from '@core/services/modals/modals.service';

import {ModalStoreSelector} from '@common/selectors/modal.selector';
import {SettingsStoreSelector} from '@common/selectors/settings.selector';
import {PaymentAtTableStoreSelector} from '@common/selectors/payment-at-table.selector';
import {PaymentAtTableStoreDispatcher} from '@common/dispatchers/payment-at-table.dispatcher';

import {DoodPaymentAtTableAmountType} from '@store/payment-at-table/payment-at-table.enum';

@Component({
  selector: 'app-pat-free-amount-modal',
  templateUrl: './pat-free-amount-modal.component.html',
})
export class PatFreeAmountModalComponent extends DestroyerBase implements OnInit {
  static handle = 'pat-free-amount-modal';

  isMobile = false;
  selectedAmount = 0;
  errorMessage!: string;

  index$ = this.modalSelector
    .selectModal(PatFreeAmountModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  constructor(
    private modalSelector: ModalStoreSelector,
    private readonly modalsService: ModalsService,
    protected settingsSelector: SettingsStoreSelector,
    private readonly translateService: TranslateService,
    protected paymentAtTableSelector: PaymentAtTableStoreSelector,
    protected paymentAtTableDispatcher: PaymentAtTableStoreDispatcher,
  ) {
    super();
    this.settingsSelector.selectDeviceIsMobileScreen
      .pipe(takeUntil(this._destroyerRef))
      .subscribe(_isMobile => (this.isMobile = _isMobile));
  }

  ngOnInit(): void {
    this.paymentAtTableDispatcher.updateChoose({
      amount: 0,
      type: DoodPaymentAtTableAmountType.Free,
    });
  }

  closeModal(): void {
    this.modalsService.close(PatFreeAmountModalComponent.handle);
  }

  validate(): void {
    this.paymentAtTableDispatcher.updateChoose({
      type: DoodPaymentAtTableAmountType.Free,
      amount: PriceUtils.roundUpToNearestWholeNumber(this.selectedAmount * 100),
    });
    this.modalsService.close(PatFreeAmountModalComponent.handle);
  }

  changeFreeAmount(amount: string | number): void {
    let _amount = 0;
    if (typeof amount === 'string' && amount.includes(',')) {
      _amount = Number(amount.replace(',', '.'));
    } else {
      _amount = Number(amount);
    }
    if (isNaN(_amount)) {
      return;
    }

    const totalAmount = Math.abs(this.paymentAtTableSelector.checkOutstandingAmount);
    this.selectedAmount = _amount;

    if (_amount > totalAmount / 100) {
      this.errorMessage = this.translateService.instant(
        'pay-at-table.free-amount-modal.error-max-amount',
      );
      return;
    }
    this.errorMessage = '';
  }
}
