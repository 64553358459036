import { Router } from '@angular/router';
import { Component, Input } from '@angular/core';

import { DoodProductModel } from '@core/models/product.model';
import { ProductKeys } from '@config/keys/product.keys';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { ContentBuilderFieldColors } from '@shared/interfaces/content-builder.interface';

@Component({
  selector: 'app-marketplace-category-vertical-two-levels',
  templateUrl: './marketplace-category-vertical-two-levels.component.html',
})
export class MarketplaceCategoryVerticalTwoLevelsComponent {
  @Input() displayContext = '';
  @Input() id?: string;
  @Input() title?: string;
  @Input() heading?: string;
  @Input() categories: { heading?: string; title?: string; products: DoodProductModel[] }[] = [];
  @Input({ required: true }) tagColors!: ContentBuilderFieldColors;

  constructor(
    private readonly router: Router,
    private readonly routerHelper: RouterHelperService,
  ) {}

  onNavigateTo(product: DoodProductModel): void {
    this.router.navigate([
      this.routerHelper.translateRoute(
        `/products/${product[ProductKeys.ShopId]}/${product[ProductKeys.Id]}`,
      ),
    ]);
  }
}
