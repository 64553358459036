import { DoodProductModel, IAllProducts } from '@core/models/product.model';

export class ProductUtils {
  static isProductVisible(product: DoodProductModel): boolean {
    return !!(product && product.shop_available && !product.hidden);
  }

  static mapAdditions(products: DoodProductModel[], additionsIds: string[]): DoodProductModel[] {
    const _additions = additionsIds.reduce((_result, id) => {
      const _found = products.find(p => p.id === id);
      if (!_found) return _result;
      return [
        ..._result,
        {
          ..._found,
          is_checked: false,
        },
      ];
    }, Array<DoodProductModel>());
    return _additions;
  }

  static denormalizeShopProducts(shop: IAllProducts): DoodProductModel[] {
    return shop.products
      .map((product: DoodProductModel) => {
        return {
          ...product,
          shop_id: shop.id,
          shop_name: shop.name,
          shop_slug: shop.slug,
          shop_available: shop.available,
          _id: ProductUtils.generateProductId(shop.id, product.id),
        };
      })
      .filter(p => !!p);
  }

  static generateProductId(shopId: string, productId: string): string {
    return shopId + '_' + productId;
  }

  static toggleItem(item: DoodProductModel, selectedItems: string[]): string[] {
    if (item.is_checked) {
      if (!selectedItems || !selectedItems.find(id => id === item.id)) {
        selectedItems.push(item.id);
      }
    } else {
      selectedItems = selectedItems.filter(id => id !== item.id);
    }
    return selectedItems;
  }
}
