<ul class="flex flex-wrap items-center w-full switch-{{ type }} rounded-2.5-l">
  <li
    *ngFor="let item of items; let index = index"
    class="switch-item flex items-center font-base text-sm font-bold leading-5 px-4"
    (click)="selectItem(index)"
    [ngClass]="{
      'w-6/12': items.length === 2,
      'w-4/12': items.length === 3,
      'w-3/12': items.length === 4,
    }"
    [class.selected]="selected === index"
  >
    <i
      class="icon {{ item.icon }} mr-2"
      *ngIf="item.icon"
    ></i>
    <span>{{ item.label | translate }}</span>
  </li>
</ul>
