import { timer } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';

import { MarketplaceKeys } from '@config/keys/shop.keys';
import { UserKeys } from 'src/app/config/keys/user.keys';

import { IKiosk } from '@core/models/kiosk.model';
import { DoodUserModel } from '@store/authentication/authentication.model';

import { KioskService } from '@core/services/kiosk/kiosk.service';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';

@Component({
  selector: 'app-pad-splash-screen-block',
  templateUrl: './pad-splash-screen-block.component.html',
})
export class PadSplashScreenBlockComponent extends DestroyerBase implements OnInit {
  userKeys = UserKeys;
  user: DoodUserModel | null = null;

  kioskInProgress = true;
  kiosk: IKiosk | undefined;
  displaySplashScreen = true;
  kioskId: string | null = null;

  constructor(
    private authSelector: AuthStoreSelector,
    private readonly kioskService: KioskService,
    private marketplaceSelector: MarketplaceStoreSelector,
  ) {
    super();
  }

  ngOnInit(): void {
    const marketplace = this.marketplaceSelector.marketplace;
    const kioskId = this.kioskService.getKioskIdFromStore();
    if (kioskId) {
      this.kioskService
        .getKiosks$(marketplace[MarketplaceKeys.Id])
        .pipe(
          tap(kiosks => {
            this.kiosk = kiosks.find(kiosk => kiosk.id === kioskId);
            if (!this.kiosk) {
              this.kioskService.saveKioskIdToStore(undefined);
            }
            this.kioskInProgress = false;
            this.shouldHideSplashScreen();
          }),
        )
        .subscribe();
    }

    this.authSelector.selectUser
      .pipe(
        takeUntil(this._destroyerRef),
        tap(user => (this.user = user)),
        tap(() => this.shouldHideSplashScreen()),
      )
      .subscribe();
  }

  shouldHideSplashScreen(): void {
    if (this.kiosk && this.user) {
      timer(1500).subscribe(() => {
        this.displaySplashScreen = false;
      });
    }
  }
}
