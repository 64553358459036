import { Component } from '@angular/core';

import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

@Component({
  selector: 'app-header-digital-menu',
  templateUrl: './header-digital-menu-block.component.html',
})
export class HeaderDigitalMenuBlockComponent {
  locales$ = this.marketplaceSelector.selectMarketplaceLocales;

  constructor(private marketplaceSelector: MarketplaceStoreSelector) {}
}
