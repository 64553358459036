<div
  *ngIf="!(reconstructedShareOrderBasket$ | async)?.length"
  class="flex flex-col"
  [style.backgroundColor]="backgroundColor | contentBuilderColor"
  [style.color]="textColor | contentBuilderColor"
>
  <div
    class="mb-5 pl-5 font-accent text-4.5xl font-bold"
    *ngIf="title"
  >
    {{ title }}
  </div>
  <ng-container *ngIf="reconstructedCart$ | async as cartByShop">
    <div *ngIf="cartByShop.length">
      <div *ngFor="let cart of cartByShop">
        <ul class="flex flex-row flex-wrap gap-12 p-5">
          <li
            *ngFor="let product of cart[reconstructedCartKeys.Items]; let index = index"
            class="w-full flex flex-row gap-8 {{
              isBeingUpdated(product.item.id) &&
              (product[reconstructedCartItemKeys.Item]?.quantity ?? 0) <= 1
                ? 'opacity-50'
                : ''
            }}"
          >
            <div class="relative h-32 w-32 min-w-32 rounded-lg">
              <img
                *ngIf="product[reconstructedCartItemKeys.Product]?.images?.thumbnail?.url"
                class="push-image h-full w-full rounded bg-center object-cover"
                [src]="product[reconstructedCartItemKeys.Product]?.images?.thumbnail?.url"
                alt="image"
              />
            </div>
            <div
              class="flex w-full flex-row justify-between rounded-l-lg px-8 py-6 font-bold"
              [style.backgroundColor]="primaryButtonTextColor | contentBuilderColor"
              [style.color]="primaryButtonBackgroundColor | contentBuilderColor"
            >
              <div
                class="flex flex-1 flex-row gap-5"
                (click)="onUpdateItem(product.item.id)"
              >
                <div class="flex flex-col justify-center gap-2 text-xl">
                  <span class="font-accent text-3xl"
                    >{{
                      product[reconstructedCartItemKeys.Product]?.[productKeys.Name] ?? 'INVALID'
                    }}
                  </span>
                  <ul
                    class="list-disc"
                    *ngIf="product[reconstructedCartItemKeys.Item]?.additions"
                  >
                    <li
                      *ngFor="let addition of product[reconstructedCartItemKeys.Item]?.additions"
                      class="ml-5 font-normal"
                    >
                      {{ findProductById(cart, addition) }}
                    </li>
                  </ul>
                  <div
                    *ngFor="
                      let declinableProduct of product[reconstructedCartItemKeys.Item]?.products
                    "
                    class="ml-5 font-bold"
                  >
                    ● {{ findByIdFromProducts(declinableProduct.id)?.name }}
                    <ng-container
                      *ngIf="declinableProduct.additions && declinableProduct.additions.length > 0"
                    >
                      <div
                        *ngFor="let addition of declinableProduct.additions"
                        class="ml-5 font-normal"
                      >
                        ● {{ findProductById(cart, addition) }}
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="flex flex-row items-center justify-center gap-10 pr-5">
                <div class="flex flex-col">
                  <div class="ml-5 min-w-20 text-2xl font-bold">
                    {{
                      (product[reconstructedCartItemKeys.UnitPrice] ?? 0) *
                        (product[reconstructedCartItemKeys.Item]?.quantity ?? 0)
                        | currency: 'EUR' : 'symbol' : '1.2-2'
                    }}
                  </div>
                  <div
                    *ngIf="(product[reconstructedCartItemKeys.Item]?.quantity ?? 0) > 1"
                    class="ml-5 min-w-20 text-xl font-normal opacity-50"
                  >
                    {{
                      product[reconstructedCartItemKeys.UnitPrice]
                        | currency: 'EUR' : 'symbol' : '1.2-2'
                    }}/u
                  </div>
                </div>
                <app-counter-atom
                  [type]="'kiosk'"
                  [size]="'L'"
                  [min]="0"
                  [backgroundColor]="primaryButtonBackgroundColor | contentBuilderColor"
                  [textColor]="primaryButtonTextColor | contentBuilderColor"
                  [(count)]="copyCart(product.item).quantity"
                  (countChange)="changeNbProduct($event, product.item)"
                ></app-counter-atom>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </ng-container>
</div>
