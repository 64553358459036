<div
  class="container mx-auto max-w-640 pb-5"
  *ngIf="imageLeft && imageRight"
>
  <div class="grid grid-cols-2 gap-5 px-5 sm:px-0">
    <img
      [src]="imageLeft.url"
      class="w-full"
    />
    <img
      [src]="imageRight.url"
      class="w-full"
    />
  </div>
</div>
