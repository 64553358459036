<div
  *ngIf="availablePaymentMethods"
  class="w-full md:mx-auto md:max-w-md"
>
  <div class="lg:mb-12 lg:mt-12 kiosk:mb-16 kiosk:mt-12">
    <h1 class="mb-3 mt-6 hidden text-center font-accent text-5xl font-bold md:block">
      {{ 'payment.title' | translate }}
    </h1>
    <h2
      *ngIf="availablePaymentMethods.length > 1"
      class="mb-3 mt-5 px-5 font-accent text-md font-bold md:mt-6 md:text-center"
    >
      {{ 'payment.subtitle' | translate }}
    </h2>
  </div>
  <div class="border-t md:rounded-s md:border">
    <div
      class="border-b border-neutral-300"
      *ngFor="let paymentMethod of availablePaymentMethods"
      [class.disabled]="status.isRetryInProgress && paymentMethod !== selectedPaymentMethod"
    >
      <div class="flex cursor-pointer flex-col px-5 text-neutral-600">
        <div
          class="flex items-center justify-between py-4.5"
          (click)="
            availablePaymentMethods.length > 1
              ? selectPaymentMethod(paymentMethod)
              : $event.stopPropagation()
          "
        >
          <div class="flex w-4/6 items-center">
            <span
              class="pr-1.5 text-xs"
              *ngIf="availablePaymentMethods.length > 1"
            >
              <input
                type="radio"
                [checked]="paymentMethod === selectedPaymentMethod"
                class="form-radio m-2 border-2 border-neutral-400 bg-none p-1.5"
                [ngClass]="{
                  'p-2.5 bg-primary-600 border-primary-600 m-1 shadow-custom':
                    paymentMethod === selectedPaymentMethod,
                }"
              />
            </span>
            <span *ngIf="paymentMethod.label !== 'payment.on-site'">{{
              paymentMethod.label | translate
            }}</span>
          </div>
          <div class="flex w-2/6 justify-end">
            <img
              alt="logo"
              [src]="logo"
              class="ml-2 max-h-6"
              *ngFor="let logo of paymentMethod.logos"
            />
          </div>
        </div>
        <app-component-factory
          [componentClass]="paymentMethod.panelComponent"
          [data]="paymentMethod.data"
          *ngIf="paymentMethod === selectedPaymentMethod"
        ></app-component-factory>
      </div>
    </div>
    <div class="hidden md:block">
      <div
        class="flex justify-between pt-4 text-center"
        *ngIf="error as paymentErrorMessage"
      >
        <span class="font-base text-error-600">
          {{ paymentErrorMessage | translate }}
        </span>
      </div>
      <div class="pt-8 text-center">
        <span class="mr-1.5 font-accent text-2xl font-bold">Total</span>
        <div class="md:hidden">
          <app-price-atom
            [format]="true"
            [size]="'MD'"
            [amount]="amount ?? 0"
          ></app-price-atom>
        </div>
        <div class="hidden md:inline-block">
          <app-price-atom
            [format]="true"
            [size]="'2XL'"
            [amount]="amount ?? 0"
          ></app-price-atom>
        </div>
      </div>

      <div class="mb-10 flex justify-center pt-4">
        <app-button-atom
          *ngIf="button.isVisible === true"
          [icon]="!(status.isInProgress || status.isLoading) ? 'icon-black-lock' : ''"
          [iconUrl]="status.isInProgress || status.isLoading ? 'assets/images/spinner.svg' : ''"
          [size]="'M'"
          [type]="'primary'"
          (click)="triggerPayment()"
          [label]="
            (button.text === 'payment.validate-on-site'
              ? 'payment.validate-on-site-zero'
              : button.text
            ) | translate
          "
          [disabled]="!button.isEnabled || status.isInProgress"
        ></app-button-atom>
      </div>
    </div>
  </div>
</div>

<div class="fixed bottom-0 left-0 right-0 z-30 bg-white shadow shadow-top md:hidden">
  <div class="container mx-auto p-4 pt-4.5 align-middle">
    <div
      class="flex justify-between"
      *ngIf="error"
    >
      <span class="font-base text-error-600">
        {{ error | translate }}
      </span>
    </div>
    <div
      *ngIf="amount"
      class="flex items-center justify-center"
    >
      <span class="mr-1.5 font-accent text-md font-bold">Total</span>
      <app-price-atom
        [format]="true"
        [size]="'MD'"
        [amount]="amount"
      ></app-price-atom>
    </div>
    <div *ngIf="hasErrorMessages">
      <p
        class="px-4 pt-4 text-base font-normal text-error-700"
        *ngFor="let message of errorMessages"
      >
        {{ message }}
      </p>
    </div>
    <div
      class="pt-4.5"
      *ngIf="button.isVisible === true"
    >
      <app-button-atom
        [icon]="!(status.isInProgress || status.isLoading) ? 'icon-black-lock' : ''"
        [iconUrl]="status.isInProgress || status.isLoading ? 'assets/images/spinner.svg' : ''"
        [size]="'L'"
        [type]="'primary'"
        (click)="triggerPayment()"
        [label]="button.text | translate"
        [disabled]="!button.isEnabled || status.isInProgress || hasErrorMessages"
      ></app-button-atom>
    </div>
  </div>
</div>
