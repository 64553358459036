<a
  *ngIf="linkUrl"
  [link]="linkUrl"
  class="w-100 h-full flex justify-between rounded-s items-center px-4 py-4 bg-center bg-cover bg-no-repeat"
  [ngStyle]="{ backgroundImage: 'url(' + image?.url + ')' }"
  [style.backgroundColor]="backgroundColor | contentBuilderColor"
  [style.color]="textColor | contentBuilderColor: '#FFFFFF'"
>
  <div>
    <p
      *ngIf="title"
      class="font-brand whitespace-pre-line"
      [ngClass]="{
        'text-sm': titleSize === 'S',
        'text-3xl': titleSize === 'M',
        'text-4xl': titleSize === 'L',
      }"
    >
      {{ title }}
    </p>
    <p
      *ngIf="description"
      class="font-base pr-3 whitespace-pre-line"
      [ngClass]="{
        'text-3xl': descriptionSize === 'S',
        'text-sm': descriptionSize === 'M' || descriptionSize === 'L',
      }"
    >
      {{ description }}
    </p>
  </div>
  <div *ngIf="displayCtaIcon">
    <app-next-button-atom></app-next-button-atom>
  </div>
</a>

<div
  *ngIf="!linkUrl"
  class="w-100 h-full flex justify-between rounded-s items-center px-4 py-4 bg-center bg-cover bg-no-repeat"
  [ngStyle]="{ backgroundImage: 'url(' + image?.url + ')' }"
  [style.backgroundColor]="backgroundColor | contentBuilderColor"
  [style.color]="textColor | contentBuilderColor: '#FFFFFF'"
>
  <div>
    <p
      class="font-brand whitespace-pre-line"
      [ngClass]="{
        'text-sm': titleSize === 'S',
        'text-3xl': titleSize === 'M',
        'text-4xl': titleSize === 'L',
      }"
    >
      {{ title }}
    </p>
    <p
      class="font-base pr-3 whitespace-pre-line"
      [ngClass]="{
        'text-3xl': descriptionSize === 'S',
        'text-sm': descriptionSize === 'M' || descriptionSize === 'L',
      }"
    >
      {{ description }}
    </p>
  </div>
  <div *ngIf="displayCtaIcon">
    <app-next-button-atom></app-next-button-atom>
  </div>
</div>
