import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Component, Input } from '@angular/core';

import { ComoAsset } from '@core/models/como.model';
import { ModalsService } from '@core/services/modals/modals.service';
import { ComoService } from 'src/app/core/services/como/como.service';
import { ModalStoreSelector } from '@common/selectors/modal.selector';

@Component({
  selector: 'app-como-confirm-use-point-modal',
  templateUrl: './como-confirm-use-point-modal.component.html',
})
export class ComoConfirmUsePointModalComponent {
  static handle = 'como-confirm-use-point-modal';
  @Input() asset!: ComoAsset;

  index$: Observable<number> = this.modalSelector
    .selectModal(ComoConfirmUsePointModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  isProcessing = false;

  constructor(
    private modalSelector: ModalStoreSelector,
    private readonly comoService: ComoService,
    private readonly modalsService: ModalsService,
  ) {}

  getAssetName(): string | undefined {
    return this.asset?.name;
  }

  confirm(): void {
    this.close();
    this.comoService.displayProductsSelectionModal(this.asset);
  }

  close(): void {
    this.modalsService.close(ComoConfirmUsePointModalComponent.handle);
  }
}
