import { ActionCreator, on, ReducerTypes } from '@ngrx/store';

import { createFunnelStorePiece } from '@store/_abstract/funnel/funnel.piece';

import {
  CartFunnelState,
  CART_FUNNEL_STORE_FUNNEL_ADAPTER,
  CART_FUNNEL_STORE_INITIAL_STATE,
  CART_FUNNEL_STORE_KEY,
} from './cart-funnel.state';
import {
  CART_FUNNEL_STORE_GENERATED_ACTIONS,
  CART_FUNNEL_STORE_HYDRATION_ACTIONS,
  CART_FUNNEL_STORE_LOCAL_ACTIONS,
} from './cart-funnel.action';
import { DoodCartFunnelStep, DoodCartFunnelValue } from './cart-funnel.model';
import { createHydratedStorePiece } from '@store/_abstract/hydrated';

export const CART_FUNNEL_STORE_FUNNEL_PIECE = createFunnelStorePiece<
  CartFunnelState,
  DoodCartFunnelStep,
  DoodCartFunnelValue
>({
  adapter: CART_FUNNEL_STORE_FUNNEL_ADAPTER,
  actions: CART_FUNNEL_STORE_GENERATED_ACTIONS,
});

export const CART_FUNNEL_STORE_API_PIECE: ReducerTypes<
  CartFunnelState,
  readonly ActionCreator[]
>[] = [];

export const CART_FUNNEL_STORE_LOCAL_PIECE: ReducerTypes<
  CartFunnelState,
  readonly ActionCreator[]
>[] = [
  // Step
  on(CART_FUNNEL_STORE_LOCAL_ACTIONS.addStep, (state, { step, setAsActive }) => {
    let _steps = CART_FUNNEL_STORE_FUNNEL_ADAPTER.steps.addOne(step, state.steps);
    if (setAsActive) {
      _steps = CART_FUNNEL_STORE_FUNNEL_ADAPTER.steps.setActive(step.id, _steps);
    }
    return { ...state, steps: _steps };
  }),

  // Order Comment
  on(CART_FUNNEL_STORE_LOCAL_ACTIONS.resetOrderComment, state => {
    return { ...state, orderComment: null };
  }),
  on(CART_FUNNEL_STORE_LOCAL_ACTIONS.updateOrderComment, (state, { comment }) => {
    return { ...state, orderComment: comment };
  }),
  on(CART_FUNNEL_STORE_LOCAL_ACTIONS.setFunnelUsername, (state, { funnelUsername }) => {
    return { ...state, funnelUsername };
  }),

  // Misc
  on(CART_FUNNEL_STORE_LOCAL_ACTIONS.reset, () => CART_FUNNEL_STORE_INITIAL_STATE),
];
export const CART_FUNNEL_STORE_HYDRATION_PIECE = createHydratedStorePiece(
  CART_FUNNEL_STORE_KEY,
  CART_FUNNEL_STORE_HYDRATION_ACTIONS,
);
