import { Component, Input } from '@angular/core';

import {
  IContentBuilderFieldColor,
  IContentBuilderFieldImage,
} from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-splash-screen-block',
  templateUrl: './splash-screen-block.component.html',
})
export class SplashScreenBlockComponent {
  @Input() image?: IContentBuilderFieldImage;
  @Input() isOverlay = false;
  @Input() linkUrl?: string;
  @Input() backgroundColor?: IContentBuilderFieldColor;

  isSplashScreenHidden = false;
  constructor() {}

  validateSplashScreen(): void {
    if (!this.linkUrl) {
      this.isSplashScreenHidden = true;
    }
  }
}
