import { createFeature, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';

import { DoodShopModel } from '@core/models/shop.model';

import { SHOP_STORE_REDUCER } from './shop.reducer';
import { SHOP_STORE_KEY, SHOP_STORE_ACTIVE_ADAPTER } from './shop.state';

const SHOP_STORE_FEATURE = createFeature({
  name: SHOP_STORE_KEY,
  reducer: SHOP_STORE_REDUCER,
});

export const {
  selectSlots: SELECT_SHOP_SLOTS,
  selectActive: SELECT_SHOP_ACTIVE_ID,
  selectEntities: SELECT_SHOP_ENTITIES,
  selectIsLoaded: SELECT_SHOP_IS_LOADED,
  selectShopsState: SELECT_SHOP_STATE,
  selectIsLoading: SELECT_SHOP_IS_LOADING,
} = SHOP_STORE_FEATURE;

export const { selectAll: SELECT_SHOP_ALL } =
  SHOP_STORE_ACTIVE_ADAPTER.getSelectors(SELECT_SHOP_STATE);

export const SELECT_SHOP_ACTIVE = createSelector(
  SELECT_SHOP_ENTITIES,
  SELECT_SHOP_ACTIVE_ID,
  (entities, id) => (id ? (entities[id] ?? null) : null),
);

export const SELECT_SHOP_ENTITY = (
  id: string,
): MemoizedSelector<
  Record<string, any>,
  DoodShopModel | undefined,
  DefaultProjectorFn<DoodShopModel | undefined>
> =>
  createSelector(SELECT_SHOP_ENTITIES, entities => {
    return entities[id];
  });
