import { Component, Input, OnInit } from '@angular/core';
import { ModalsService } from '@core/services/modals/modals.service';
import { map } from 'rxjs/operators';
import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { InvoicesApiService } from '@core/services/api/invoices/invoices-api.service';
import { UserService } from '@core/services/user/user.service';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import {AnalyticsService} from "@core/services/app/analytics.service";

export enum ReceiveReceiptModalReceiptType {
  checkTransaction = 'check-transaction',
  payment = 'payment',
}

@Component({
  selector: 'app-receive-receipt-modal',
  templateUrl: './receive-receipt-modal.component.html',
  styles: [],
})
export class ReceiveReceiptModalComponent implements OnInit {
  static handle = 'receive-receipt-modal';
  index$ = this.modalSelector
    .selectModal(ReceiveReceiptModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));
  isDesktop$ = this.settingsSelector.selectDeviceIsDesktopScreen;

  @Input() receiptType?: ReceiveReceiptModalReceiptType;
  @Input() receiptId?: string;
  email?: string;
  submitInProgress = false;

  constructor(
    private readonly modalsService: ModalsService,
    private readonly modalSelector: ModalStoreSelector,
    private readonly invoicesApiService: InvoicesApiService,
    private readonly userService: UserService,
    private readonly settingsSelector: SettingsStoreSelector,
    private readonly analyticsService: AnalyticsService,
  ) {
    this.email = this.userService.user?.email ?? '';
    this.analyticsService.trackEvent('receive_receipt_modal_display', {
      receive_receipt_id: this.receiptId,
      receive_receipt_type: this.receiptType
    });
  }

  ngOnInit(): void {}

  close(): void {
    this.modalsService.close(ReceiveReceiptModalComponent.handle);
  }

  submit(email?: string): void {
    let receiptId = this.receiptId;

    if (!receiptId || !email || !this.receiptType) {
      return;
    }

    this.analyticsService.trackEvent('receive_receipt_modal_submit', {
      receive_receipt_id: this.receiptId,
      receive_receipt_type: this.receiptType
    });

    this.submitInProgress = true;
    if (this.receiptType === ReceiveReceiptModalReceiptType.checkTransaction) {
      this.invoicesApiService
        .requestCheckTransactionInvoice$(receiptId, email)
        .subscribe(() => this.close());
    } else {
      this.invoicesApiService
        .requestPaymentInvoice$(receiptId, email)
        .subscribe(() => this.close());
    }
  }
}
