import {Component, HostBinding, Input} from '@angular/core';
import {IContentBuilderFieldColor} from '@core/models/content-builder-fields.model';
import {ColorFieldTypesValues} from "@config/values/color-field-types.values";

@Component({
  selector: 'app-kiosk-loyalty-connection-block',
  templateUrl: './kiosk-loyalty-connection-block.component.html',
})
export class KioskLoyaltyConnectionBlockComponent {
  @Input() textColor?: IContentBuilderFieldColor;
  @Input() backgroundColor?: IContentBuilderFieldColor;
  @Input() cardsColor?: IContentBuilderFieldColor;
  @Input() title?: string;
  @Input() firstCardLink?: string;
  @Input() firstCardLabel?: string;
  @Input() secondCardLink?: string;
  @Input() secondCardLabel?: string;
  @Input() headingTextColor: IContentBuilderFieldColor = {
    type: ColorFieldTypesValues.Palette,
    value: 'primary-800'
  };
  @Input() mainCardBackgroundColor: IContentBuilderFieldColor = {
    type: ColorFieldTypesValues.Palette,
    value: 'primary-800'
  };
  @Input() mainCardTextColor: IContentBuilderFieldColor = {
    type: ColorFieldTypesValues.Hex,
    value: '#FFFFFF'
  };
  @Input() secondaryCardBackgroundColor: IContentBuilderFieldColor = {
    type: ColorFieldTypesValues.Hex,
    value: 'transparent'
  };
  @Input() secondaryCardTextColor: IContentBuilderFieldColor = {
    type: ColorFieldTypesValues.Palette,
    value: 'primary-800'
  };


  @HostBinding('class') classes = 'h-full flex text-center';
}
