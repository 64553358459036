import { createFeature, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';

import {
  CONTENT_STORE_BLOCK_ADAPTER,
  CONTENT_STORE_KEY,
  CONTENT_STORE_MODAL_ADAPTER,
} from './content.state';
import { CONTENT_STORE_REDUCER } from './content.reducer';
import { DoodBlockModel } from '@core/models/block.model';
import { DoodModalConfig } from '@core/models/marketplace.model';

const CONTENT_STORE_FEATURE = createFeature({
  name: CONTENT_STORE_KEY,
  reducer: CONTENT_STORE_REDUCER,
});

export const {
  selectHeader: SELECT_CONTENT_HEADER,
  selectContentState: SELECT_CONTENT_STATE,
  selectBlocks: SELECT_CONTENT_BLOCKS_STATE,
  selectModals: SELECT_CONTENT_MODALS_STATE,
  selectProduct: SELECT_CONTENT_PRODUCT_MODAL,
} = CONTENT_STORE_FEATURE;

export const { selectAll: SELECT_CONTENT_BLOCKS_ALL, selectTotal: SELECT_CONTENT_BLOCKS_TOTAL } =
  CONTENT_STORE_BLOCK_ADAPTER.getSelectors(SELECT_CONTENT_BLOCKS_STATE);

export const { selectAll: SELECT_CONTENT_MODALS_ALL, selectTotal: SELECT_CONTENT_MODALS_TOTAL } =
  CONTENT_STORE_MODAL_ADAPTER.getSelectors(SELECT_CONTENT_MODALS_STATE);

// Blocks
export const SELECT_CONTENT_BLOCKS_ENTITY = (
  id: string,
): MemoizedSelector<
  Record<string, any>,
  DoodBlockModel | undefined,
  DefaultProjectorFn<DoodBlockModel | undefined>
> => createSelector(SELECT_CONTENT_BLOCKS_STATE, state => state.entities[id]);

// Modals
export const SELECT_CONTENT_MODALS_ENTITY = (
  id: string,
): MemoizedSelector<
  Record<string, any>,
  DoodModalConfig | undefined,
  DefaultProjectorFn<DoodModalConfig | undefined>
> => createSelector(SELECT_CONTENT_MODALS_STATE, state => state.entities[id]);
