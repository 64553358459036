import { createFeature, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';

import { DoodOrderModel } from './order.model';
import { ORDER_STORE_REDUCER } from './order.reducer';
import { ORDER_STORE_KEY, ORDER_STORE_ADAPTER, OrderState, OrderErrorState } from './order.state';

const ORDER_STORE_FEATURE = createFeature({
  name: ORDER_STORE_KEY,
  reducer: ORDER_STORE_REDUCER,
});

export const { selectOrdersState: SELECT_ORDER_STATE, selectActive: SELECT_ORDER_ACTIVE_ID } =
  ORDER_STORE_FEATURE;

export const {
  selectAll: SELECT_ORDER_ALL,
  selectTotal: SELECT_ORDER_TOTAL,
  selectEntities: SELECT_ORDER_ENTITIES,
} = ORDER_STORE_ADAPTER.getSelectors(SELECT_ORDER_STATE);

export const SELECT_ORDER_ENTITY = (
  id: string,
): MemoizedSelector<
  Record<string, any>,
  DoodOrderModel | undefined,
  DefaultProjectorFn<DoodOrderModel | undefined>
> =>
  createSelector(SELECT_ORDER_ENTITIES, entities => {
    return entities[id];
  });

export const SELECT_ORDER_MULTIPLE_ENTITIES = (
  ids: string[],
): MemoizedSelector<Record<string, any>, DoodOrderModel[], DefaultProjectorFn<DoodOrderModel[]>> =>
  createSelector(SELECT_ORDER_ENTITIES, entities => {
    return ids.map(id => entities[id]).filter(_entity => !!_entity) as DoodOrderModel[];
  });

export const SELECT_ORDER_ERRORS = createSelector(
  SELECT_ORDER_STATE,
  (state: OrderState) => state.errors,
);

export const SELECT_ORDER_VIOLATIONS = createSelector(
  SELECT_ORDER_ERRORS,
  (errors: OrderErrorState) => errors.violations,
);

export const SELECT_ORDER_VALIDATION = createSelector(
  SELECT_ORDER_STATE,
  (state: OrderState) => state.validation,
);

export const SELECT_ORDER_IS_VALIDATING = createSelector(
  SELECT_ORDER_STATE,
  (state: OrderState) => state.isValidating,
);

export const SELECT_ORDER_ACTIVE = createSelector(
  SELECT_ORDER_ENTITIES,
  SELECT_ORDER_ACTIVE_ID,
  (entities, id) => (id ? (entities[id] ?? null) : null),
);
