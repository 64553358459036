export const ColorFieldTypesValues = {
  Hex: 'hex',
  Palette: 'palette',
  Default: 'default',
} as const;
export type ColorFieldTypesValues =
  (typeof ColorFieldTypesValues)[keyof typeof ColorFieldTypesValues];

export const ColorPickerTypeLabels = {
  Hex: 'Hex',
  Palette: 'Palette',
  Default: 'Default',
} as const;
export type ColorPickerTypeLabels =
  (typeof ColorPickerTypeLabels)[keyof typeof ColorPickerTypeLabels];
