import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { combineLatest, from, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { map, switchMap, takeUntil } from 'rxjs/operators';

import { StatusBar, Style } from '@capacitor/status-bar';

import { ProductKeys } from '@config/keys/product.keys';
import { AdditionGroupKeys } from '@config/keys/shop.keys';

import { ProductTypeValues } from '@config/values/product.values';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { NativeService } from '@core/services/native/native.service';

import { IAdditionGroup } from '@core/models/shop.model';
import { DoodDeclinableModel, DoodProductModel, IProductLabel } from '@core/models/product.model';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';
import { ContentBuilderFieldColors } from '@shared/interfaces/content-builder.interface';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';
import { CartUtilService } from '@shared/local-store/cart/util/cart-util.service';

@Component({
  selector: 'app-product-panel-atom',
  templateUrl: './product-panel-atom.component.html',
  styleUrls: ['./product-panel-atom.component.scss'],
})
export class ProductPanelAtomComponent
  extends DestroyerBase
  implements OnInit, OnChanges, OnDestroy
{
  @Input() product!: DoodProductModel;
  @Input() additions!: DoodProductModel[];
  @Input() additionsByGroup!: IAdditionGroup[];
  @Input() compoundItems: DoodDeclinableModel[] = [];
  @Input() quantity = 1;
  @Input() isDisableButton = true;
  @Input() isEdit = false;
  @Input() shopOpensAt!: string;
  @Input() allowAddToCart = true;
  @Input() index!: number;
  @Input() displayPrice?: boolean = true;
  @Input() maxProduct?: number;
  @Input() headingColor: IContentBuilderFieldColor = {
    value: 'neutral-900',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() descriptionColor: IContentBuilderFieldColor = {
    value: 'neutral-700',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() inactiveStateColor: IContentBuilderFieldColor = {
    value: 'neutral-500',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() priceColor: IContentBuilderFieldColor = {
    value: 'neutral-700',
    type: ColorFieldTypesValues.Palette,
  };
  @Input({ required: true }) tagColors!: ContentBuilderFieldColors;

  @Input() backgroundColor?: IContentBuilderFieldColor = {
    value: '#FFFFFF',
    type: ColorFieldTypesValues.Hex,
  };

  @Input() textColor?: IContentBuilderFieldColor;

  @Input() buttonBackgroundColor?: IContentBuilderFieldColor;

  @Input() buttonTextColor?: IContentBuilderFieldColor;

  @Input() hideAdditionGroupDetails = false;

  @Output() closePanel = new EventEmitter<Event>();
  @Output() changeQuantity = new EventEmitter<number>();
  @Output() changeAdditionQuantity = new EventEmitter<{ quantity: number; id: string }>();
  @Output() toggleAddition = new EventEmitter<DoodProductModel>();
  @Output() addProduct = new EventEmitter<Event>();
  @Output() removeProduct = new EventEmitter<string>();
  @Output() openCompound = new EventEmitter<string>();

  @ViewChild('declinableOptionsElement')
  declinableOptionsElement?: ElementRef;

  additionGroupKeys = AdditionGroupKeys;

  productTypeValues = ProductTypeValues;

  isButtonDisable = true;

  chooseMessage?: string;

  isNotched = false;

  orderedAdditionGroups: IAdditionGroup[] = [];

  productKeys = ProductKeys;

  private readonly filterColors$ =
    this.marketplaceSelector.selectMarketPlaceProductLabelsFilterColors;

  protected readonly vm$ = combineLatest({
    filterColors: this.filterColors$,
  });

  constructor(
    private readonly translateService: TranslateService,
    private marketplaceSelector: MarketplaceStoreSelector,
  ) {
    super();
  }

  ngOnInit(): void {
    from(NativeService.isNotched())
      .pipe(
        takeUntil(this._destroyerRef),
        switchMap(_isNotched => {
          this.isNotched = _isNotched;

          if (this.isNotched) {
            return from(StatusBar.setStyle({ style: Style.Dark }));
          }
          return of(null);
        }),
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.checkIfButtonAddProductIsDisable();
    this.orderedAdditionGroups = this.additionsByGroup
      ? CartUtilService.mapAdditionGroupsWithPosition({
          additionsGroups: this.additionsByGroup,
          simpleProductAdditions: this.product.additions?.products ?? [],
        }).sort((a, b) => {
          return (a?.[AdditionGroupKeys.Position] ?? 0) - (b?.[AdditionGroupKeys.Position] ?? 0);
        })
      : [];
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  close(event: Event): void {
    this.closePanel.emit(event);
  }

  addToCart(event: Event): void {
    this.addProduct.emit(event);
  }

  scrollToOptions(): void {
    this.declinableOptionsElement?.nativeElement.scrollIntoView({
      behavior: 'smooth',
    });
  }

  selectAddition(addition: DoodProductModel): void {
    if (addition[ProductKeys.IsChecked]) {
      addition[ProductKeys.Quantity] = 1;
    } else {
      addition[ProductKeys.Quantity] = 0;
    }
    this.toggleAddition.emit(addition);
  }

  changeNbProduct(quantity: number): void {
    this.changeQuantity.emit(quantity);
  }

  changeNbAddition(quantity: number, id: string, addition: DoodProductModel): void {
    addition[ProductKeys.Quantity] = quantity;
    this.changeAdditionQuantity.emit({ quantity, id });
  }

  visibleLabels(labels: IProductLabel[] | undefined): IProductLabel[] {
    if (!labels) {
      return [];
    }

    // Filtrage des labels visibles et tri par position
    const sortedLabels = labels
      .filter(label => label.visible)
      .sort((a, b) => a.position - b.position);

    // Suppression des doublons basés sur `name`
    const uniqueLabelsMap = new Map<string, IProductLabel>();
    sortedLabels.forEach(label => {
      if (!uniqueLabelsMap.has(label.name)) {
        uniqueLabelsMap.set(label.name, label);
      }
    });

    return Array.from(uniqueLabelsMap.values());
  }

  additionGroupTitle(minCount: number, maxCount: number): string | undefined {
    this.translateService
      .get([
        'product-add-modal.choose',
        'product-add-modal.choose-at-least',
        'product-add-modal.choose-between',
        'product-add-modal.and',
      ])
      .pipe(
        takeUntil(this._destroyerRef),
        map(translation => {
          if (!maxCount && minCount === 0) {
            this.chooseMessage = '';
          } else if (!maxCount && minCount > 0) {
            this.chooseMessage = `${translation['product-add-modal.choose-at-least']} ${minCount}`;
          } else if (maxCount === minCount) {
            this.chooseMessage = `${translation['product-add-modal.choose']} ${minCount}`;
          } else if (maxCount && minCount) {
            this.chooseMessage = `${translation['product-add-modal.choose-between']} ${minCount} ${translation['product-add-modal.and']} ${maxCount}.`;
          } else if (maxCount && minCount === 0) {
            this.chooseMessage = `${translation['product-add-modal.choose']} ${maxCount} maximum.`;
          } else {
            this.chooseMessage = `${minCount} - ${maxCount}.`;
          }
        }),
      )
      .subscribe();

    return this.chooseMessage;
  }

  getMaxAdditionCount(additionGroup: IAdditionGroup, addition: DoodProductModel): number {
    const otherAdditionsQuantity = additionGroup[AdditionGroupKeys.Items].reduce((_total, item) => {
      if (item === addition) {
        return _total;
      }
      return _total + ((item[ProductKeys.IsChecked] && (item[ProductKeys.Quantity] || 1)) || 0);
    }, 0);

    let groupMaxCount;

    if (additionGroup[AdditionGroupKeys.MaxCount]) {
      groupMaxCount = Math.max(
        Math.min(
          (additionGroup[AdditionGroupKeys.MaxCount] || Infinity) - otherAdditionsQuantity,
          addition[ProductKeys.MaxCount] || Infinity,
        ),
        0,
      );
    } else {
      groupMaxCount = Math.max(Math.min(addition[ProductKeys.MaxCount] || Infinity), 0);
    }

    if (addition[ProductKeys.InventoryTracked]) {
      groupMaxCount = Math.min(groupMaxCount, addition[ProductKeys.InventoryAvailable] || 0);
    }

    return groupMaxCount;
  }

  checkIfButtonAddProductIsDisable(): void {
    this.isButtonDisable = !(
      !this.isDisableButton &&
      this.product[ProductKeys.Id] &&
      !this.shopOpensAt
    );
  }

  forceType(data: any): any {
    return data as any;
  }
}
