<app-product-panel-atom
  [product]="product"
  [additions]="additions"
  [quantity]="quantity"
  [additionsByGroup]="additionsByGroup"
  [compoundItems]="(declinables$ | async) ?? []"
  [isDisableButton]="!isButtonValid"
  [isEdit]="true"
  [index]="$any(index$ | async)"
  [headingColor]="headingColor"
  [descriptionColor]="descriptionColor"
  [inactiveStateColor]="inactiveStateColor"
  [priceColor]="priceColor"
  [backgroundColor]="backgroundColor"
  [textColor]="textColor"
  [buttonBackgroundColor]="buttonBackgroundColor"
  [buttonTextColor]="buttonTextColor"
  [tagColors]="$any(tagColors$ | async)"
  [hideAdditionGroupDetails]="hideAdditionGroupDetails"
  (addProduct)="updateCart()"
  (closePanel)="close()"
  (toggleAddition)="onSelectAddition($event)"
  (changeQuantity)="onChangeNbProduct($event)"
  (changeAdditionQuantity)="onChangeNbAddition($event)"
  (removeProduct)="onRemoveProduct()"
  (openCompound)="openCompound($event)"
></app-product-panel-atom>
