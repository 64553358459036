import { createFeature } from '@ngrx/store';

import { PAYMENT_STORE_KEY } from './payment.state';
import { PAYMENT_STORE_REDUCER } from './payment.reducer';

const PAYMENT_STORE_FEATURE = createFeature({
  name: PAYMENT_STORE_KEY,
  reducer: PAYMENT_STORE_REDUCER,
});

export const {
  selectError: SELECT_PAYMENT_ERROR,
  selectAmount: SELECT_PAYMENT_AMOUNT,
  selectButton: SELECT_PAYMENT_BUTTON,
  selectStatus: SELECT_PAYMENT_STATUS,
  selectMethods: SELECT_PAYMENT_METHODS,
  selectPaymentState: SELECT_PAYMENT_STATE,
  selectBancontact: SELECT_PAYMENT_BANCONTACT,
  selectTransaction: SELECT_PAYMENT_TRANSACTION,
} = PAYMENT_STORE_FEATURE;
