export enum WantedAtParametersKeys {
  Type = 'type',
  Date = 'date',
  Time = 'time',
}

export enum ApiParametersKeys {
  MaxDistance = 'maxDistance',
  Types = 'types',
  Pricing = 'pricing',
  Latitude = 'latitude',
  Longitude = 'longitude',
  DistributionMode = 'distribution_mode',
  WantedAt = 'wanted_at',
}
