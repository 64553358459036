<label class="block max-w-screen-sm">
  <select
    class="form-select mt-1 block w-full rounded-full bg-neutral-100 py-2.5 pl-4 font-base text-base font-normal"
    [(ngModel)]="selectedItem"
    [disabled]="isDisable"
    (change)="onChange()"
  >
    @for (item of items; track $index) {
      <option [ngValue]="item.value">
        {{ item.label | translate }}
      </option>
    }
  </select>
</label>
