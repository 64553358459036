import { ActiveState, createActiveAdapter } from '@store/_abstract/active';
import { DoodDeliveryAddressModel } from '@core/models/delivery-address.model';

export interface DeliveryState extends ActiveState<DoodDeliveryAddressModel, string> {
  isLoaded: boolean;
  isLoading: boolean;
}

export const DELIVERY_STORE_KEY = 'Delivery';

export const DELIVERY_STORE_ACTIVE_ADAPTER = createActiveAdapter<
  DoodDeliveryAddressModel,
  string
>();

export const DELIVERY_STORE_INITIAL_STATE: DeliveryState =
  DELIVERY_STORE_ACTIVE_ADAPTER.getInitialState({
    isLoaded: false,
    isLoading: false,
  });
