<app-product-panel-atom
  *ngIf="product$ | async as product"
  [product]="product"
  [additions]="(additions$ | async) ?? []"
  [isDisableButton]="!isButtonValid"
  [additionsByGroup]="(additionsByGroup$ | async) ?? []"
  [shopOpensAt]="shopOpensAt"
  [compoundItems]="(declinables$ | async) ?? []"
  [allowAddToCart]="staticAllowAddToCart"
  [index]="$any(index$ | async)"
  [headingColor]="headingColor"
  [descriptionColor]="descriptionColor"
  [inactiveStateColor]="inactiveStateColor"
  [priceColor]="priceColor"
  [displayPrice]="displayPrice"
  [maxProduct]="maxProduct"
  [backgroundColor]="backgroundColor"
  [textColor]="textColor"
  [buttonBackgroundColor]="buttonBackgroundColor"
  [buttonTextColor]="buttonTextColor"
  [tagColors]="$any(tagColors$ | async)"
  [hideAdditionGroupDetails]="hideAdditionGroupDetails"
  (addProduct)="addToCart()"
  (closePanel)="close()"
  (toggleAddition)="onSelectAddition($event)"
  (changeQuantity)="onChangeNbProduct($event)"
  (changeAdditionQuantity)="onChangeNbAddition($event)"
  (openCompound)="openCompound($event)"
></app-product-panel-atom>
