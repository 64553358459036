import { Component, Input, OnInit, signal } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CartStoreRefiner } from '@common/refiners/cart.refiner';
import { ProductStoreRefiner } from '@common/refiners/product.refiner';
import { ProductStoreSelector } from '@common/selectors/product.selector';
import {
  ICartDraftDeclinableProductItem,
  ICartDraftSimpleProductItem,
  ICartItem,
} from '@core/models/cart.model';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { DoodProductModel } from '@core/models/product.model';
import { IAdditionGroup } from '@core/models/shop.model';
import { CartService } from '@core/services/cart/cart.service';
import { EventTrackerService } from '@core/services/event-tracker/event-tracker.service';
import { ProductService } from '@core/services/product/product.service';
import { ProductsService } from '@core/services/products/products.service';
import { filter, map, Subject, take, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-kiosk-product-add-page',
  template: `
    @if (product(); as product) {
      <div class="relative block h-full overflow-hidden">
        <div
          class="ml-5 flex h-full flex-col gap-5"
          [style.backgroundColor]="backgroundColor | contentBuilderColor"
          [style.color]="textColor | contentBuilderColor"
        >
          <app-kiosk-product-details-block
            #productDetailsBlock
            [cardColor]="cardColor"
            [primaryButtonBackgroundColor]="buttonBackgroundColor"
            [primaryButtonTextColor]="buttonTextColor"
            [unavailableButtonBackground]="unavailableButtonBackground"
            [unavailableButtonText]="unavailableButtonText"
            [compositionBackgroundColor]="compositionBackgroundColor"
            [textColor]="textColor"
            [backgroundColor]="backgroundColor"
            [secondaryColor]="secondaryColor"
            (addToCart)="addToCart($event)"
          >
          </app-kiosk-product-details-block>
        </div>
      </div>
    }
  `,
})
export class KioskProductAddPageComponent implements OnInit {
  @Input() backgroundColor?: IContentBuilderFieldColor;
  @Input() secondaryColor?: IContentBuilderFieldColor;
  @Input() textColor?: IContentBuilderFieldColor;
  @Input() buttonBackgroundColor?: IContentBuilderFieldColor;
  @Input() buttonTextColor?: IContentBuilderFieldColor;
  @Input() unavailableButtonBackground?: IContentBuilderFieldColor;
  @Input() unavailableButtonText?: IContentBuilderFieldColor;
  @Input() cardColor?: IContentBuilderFieldColor;
  @Input() compositionBackgroundColor?: IContentBuilderFieldColor;

  private _destroyerRef = new Subject<boolean>();

  productId: string | null = null;
  shopId: string | null = null;

  protected readonly product = signal<DoodProductModel | null>(null);
  additionsByGroup$ = this.productRefiner.selectAdditionsByGroups.pipe(
    takeUntil(this._destroyerRef),
  );
  additions$ = this.productRefiner.selectAdditions.pipe(
    map(additions => additions.filter(v => !v.addition_group && v.id)),
  );

  protected readonly quantity = signal(1);

  reconstructedCart$ = this.cartService.getCartLineItems$();

  selectedAdditionsByGroup: (IAdditionGroup & {
    selectedAdditions: string[];
  })[] = [];
  selectedAdditions: string[] = [];

  private readonly activeCategory$ = this.productSelector.selectActiveCategory;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly cartService: CartService,
    private readonly productService: ProductService,
    private readonly productsService: ProductsService,
    private readonly productRefiner: ProductStoreRefiner,
    private readonly eventTrackerService: EventTrackerService,
    private readonly cartStoreRefiner: CartStoreRefiner,
    private readonly productSelector: ProductStoreSelector,
  ) {}

  ngOnInit(): void {
    this.additions$.subscribe();

    this.shopId = this.cartService.getShopId() ?? null;

    this.updateProductId();

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        tap(() => this.updateProductId()),
      )
      .subscribe();
    this.productsService
      .selectByShopAndProduct$(this.shopId, this.productId)
      .pipe(
        take(1),
        map(
          (product: DoodProductModel | undefined) =>
            product === undefined ? null : { ...product, quantity: 0 }, // ensure that the quantity is set to 0 and it avoid to share the same reference, because the product quantity is mutated
        ),

        tap(product => product && this.productService.setProductToStore(product)),
        tap(product => {
          this.product.set(product);
        }),
        takeUntil(this._destroyerRef),
      )
      .subscribe();
    this.route.params.pipe(take(1)).subscribe(() => this.updateProductId());
    this.productRefiner.selectAdditionsByGroups
      .pipe(
        tap(additionsGroups => {
          additionsGroups.forEach(additionGroup => {
            if (
              !this.selectedAdditionsByGroup.find(
                additionGroup => additionGroup.id === additionGroup.id,
              )
            ) {
              this.selectedAdditionsByGroup.push({ ...additionGroup, selectedAdditions: [] });
            }
          });
        }),
      )
      .subscribe();

    this.reconstructedCart$.subscribe();
  }

  private updateProductId(): void {
    const productId = this.getProductIdFromRoute(this.route);
    if (productId) {
      this.productId = productId;
    } else {
      this.productId = null;
    }
  }

  private getProductIdFromRoute(route: ActivatedRoute): string | null {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route.snapshot.paramMap.get('productId');
  }

  back(): void {
    const shopCity = this.route.snapshot.paramMap.get('shopCity');
    const shopType = this.route.snapshot.paramMap.get('shopType');
    const shopSlug = this.route.snapshot.paramMap.get('shopSlug');

    this.activeCategory$
      .pipe(
        map(id => {
          this.router.navigate([
            `/fr/${shopCity}/${shopType}/${shopSlug}${id ? `/categories/${id}` : ''}`,
          ]);
        }),
      )
      .subscribe();
  }

  navigateHome() {
    const shopCity = this.route.snapshot.paramMap.get('shopCity');
    const shopType = this.route.snapshot.paramMap.get('shopType');
    const shopSlug = this.route.snapshot.paramMap.get('shopSlug');

    this.router.navigate([
      `/fr/${shopCity}/${shopType}/${shopSlug}/product/${this.productId}/product-add`,
    ]);
  }

  addToCart({
    cartDraft,
    validity,
  }: {
    cartDraft: ICartDraftSimpleProductItem | ICartDraftDeclinableProductItem;
    validity: {
      isValid: boolean;
    };
  }): void {
    if (!validity.isValid) {
      return;
    }
    const newCartItem = cartDraft as unknown as Omit<ICartItem, 'id'>; // Once the type of the ICartItem is fixed, this cast will be removed
    if (!newCartItem) return;

    this.cartStoreRefiner.selectActiveCartProducts.pipe(take(1)).subscribe(cartItems => {
      const itemInCart = this.cartService.findMatchingCartItem({
        cartItems,
        searchCartItem: newCartItem,
      });
      if (itemInCart) {
        this.cartService.updateCartItem(
          {
            ...itemInCart,
            quantity: itemInCart.quantity + (this.quantity() || 1),
          },
          false,
        );
      } else {
        this.cartService
          .addCartItem$({ ...newCartItem, quantity: this.quantity() || 1 })
          .subscribe();
      }
      this.eventTrackerService.dispatch(EventTrackerService.EventProductAddedToCart, {
        product: this.product(),
        quantity: this.quantity() || 1,
      });
      this.navigateShopPage();
    });
  }

  private navigateShopPage() {
    const shopCity = this.route.snapshot.paramMap.get('shopCity');
    const shopType = this.route.snapshot.paramMap.get('shopType');
    const shopSlug = this.route.snapshot.paramMap.get('shopSlug');

    this.router.navigate([`/fr/${shopCity}/${shopType}/${shopSlug}`]);
  }
}
