<div class="flex h-full flex-1 flex-col overflow-x-hidden">
  @if (header) {
    <div
      id="header"
      class="flex-shrink-0"
      [ngStyle]="getBackgroundStyles()"
      [style.backgroundColor]="backgroundColor | contentBuilderColor"
      [style.color]="textColor | contentBuilderColor"
    >
      <app-block-list [blockIdList]="header"></app-block-list>
    </div>
  }
  <div class="flex h-full flex-1 overflow-hidden">
    <div
      class="flex h-full w-full flex-1 flex-col"
      [style.backgroundColor]="backgroundColor | contentBuilderColor"
      [style.color]="textColor | contentBuilderColor"
    >
      <div class="h-full overflow-y-auto">
        <app-block-list [blockIdList]="body"></app-block-list>
      </div>
    </div>
  </div>
  <div
    [style.backgroundColor]="backgroundColor | contentBuilderColor"
    [style.color]="textColor | contentBuilderColor"
  >
    <app-block-list [blockIdList]="footer"></app-block-list>
  </div>
</div>
