import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISimpleItem } from '@core/models/simple-item.model';

@Component({
  selector: 'app-search-parameters-atom',
  templateUrl: './search-parameters-atom.component.html',
})
export class SearchParametersAtomComponent {
  @Input() items!: ISimpleItem[];

  @Output() clickItem = new EventEmitter();
  @Output() clickAction = new EventEmitter();
}
