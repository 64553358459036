export class PriceUtils {
  static formatAndRoundUpPrice(price: number): string {
    let formattedPrice = price / 100;
    const decimalIndex = formattedPrice.toString().indexOf('.');
    const numOfCharactersAfterDot = formattedPrice.toString().length - decimalIndex - 1;

    if (numOfCharactersAfterDot >= 3) {
      formattedPrice = Math.ceil(formattedPrice * 100) / 100;
    }

    return formattedPrice.toFixed(2);
  }

  static formatAndRoundUpPriceWithComa(price: number): string {
    const formattedPrice = Math.ceil(price) / 100;

    return formattedPrice.toLocaleString('fr-FR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  static roundUpToNearestWholeNumber(value: number): number {
    return Math.ceil(value);
  }
}
