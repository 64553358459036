<ng-container *ngIf="comoUser && comoUser.punch_cards && comoUser.punch_cards.length > 0">
  <div class="mx-auto max-w-md px-5 lg:max-w-full lg:px-0">
    <div class="mb-3">
      <div
        class="flex flex-row rounded rounded-s border border-neutral-400 px-4.5 py-4 text-neutral-800"
      >
        <div class="flex flex-col">
          <div
            *ngFor="let punchCard of comoUser.punch_cards; let last = last"
            [ngClass]="{
              'border-b border-neutral-300 pb-2 mb-4': last === false,
            }"
          >
            <div class="flex flex-row items-center">
              <div
                class="flex-shrink-0 pr-3"
                *ngIf="punchCard.image"
              >
                <img
                  [src]="punchCard.image"
                  width="52"
                  height="52"
                  class="rounded-full"
                />
              </div>
              <div class="">
                <div class="font-base text-base font-bold lg:text-md">
                  {{ punchCard.name }}
                </div>
                <div class="pt-1 font-base text-sm text-neutral-600 lg:text-base">
                  {{ punchCard.description }}
                </div>
                <div class="flex flex-row pt-2">
                  <div
                    *ngFor="
                      let punchIndex of [].constructor(punchCard.required_punches);
                      let i = index
                    "
                  >
                    <i
                      *ngIf="(punchCard?.punches ?? 0) > i"
                      class="punched icon icon-check-circle mr-1 mt-1 text-xl text-success-600"
                    ></i>
                    <div
                      *ngIf="(punchCard?.punches ?? 0) <= i"
                      class="mr-1 mt-1 h-5 w-5 rounded-full bg-neutral-400"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
