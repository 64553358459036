import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import { DoodShopModel } from '@core/models/shop.model';
import { SHOP_STORE_ACTIVE_ACTIONS, SHOP_STORE_LOCAL_ACTIONS } from '@store/shop/shop.action';

@Injectable({ providedIn: 'root' })
export class ShopStoreDispatcher {
  constructor(private store: Store) {}

  // One
  upsertOne(entity: DoodShopModel): void {
    this.store.dispatch(SHOP_STORE_ACTIVE_ACTIONS.upsertOne({ entity }));
  }

  removeOne(id: string): void {
    this.store.dispatch(SHOP_STORE_ACTIVE_ACTIONS.removeOne({ id }));
  }

  // Active
  setActive(active: string): void {
    this.store.dispatch(SHOP_STORE_ACTIVE_ACTIONS.setActive({ active }));
  }

  insertActive(entity: DoodShopModel): void {
    this.store.dispatch(SHOP_STORE_ACTIVE_ACTIONS.insertActive({ entity }));
  }

  // Slots
  setSlots(slots: number[]): void {
    this.store.dispatch(SHOP_STORE_LOCAL_ACTIONS.setSlots({ slots }));
  }

  // Status
  updateIsLoading(isLoading: boolean): void {
    this.store.dispatch(SHOP_STORE_LOCAL_ACTIONS.updateIsLoading({ isLoading }));
  }

  reset(): void {
    this.store.dispatch(SHOP_STORE_LOCAL_ACTIONS.reset());
  }
}
