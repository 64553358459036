import { Injectable } from '@angular/core';
import { IKioskHardwareYavinTerminal } from '@core/models/kiosk.model';
import {
  YavinTerminalApiService,
  YavinTransactionResponse,
} from '@core/services/api/yavin-terminal/yavin-terminal-api.service';
import { Observable } from 'rxjs';
import { DoodOrderModel } from '@store/order/order.model';

@Injectable({
  providedIn: 'root',
})
export class YavinTerminalService {
  constructor(private readonly yavinTerminalApi: YavinTerminalApiService) {}

  setConfiguration(configuration: IKioskHardwareYavinTerminal) {
    this.yavinTerminalApi.setTerminalIpAddress(configuration.terminal_ip_address);
  }

  abortTransaction$() {
    return this.yavinTerminalApi.abortTransaction$();
  }

  startTransaction$(
    amount: number,
    orderNumber: string,
    orderId: string,
  ): Observable<YavinTransactionResponse> {
    return this.yavinTerminalApi.startTransaction$(amount, orderNumber, orderId);
  }

  changeOrderStatusToPayment$(orderId: string, paymentRef: string): Observable<DoodOrderModel> {
    return this.yavinTerminalApi.changeOrderStatusToPayment$(orderId, paymentRef);
  }
}
