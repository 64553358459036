import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';

import { DoodBlockModel } from '@core/models/block.model';
import { DoodModalConfig } from '@core/models/marketplace.model';

import {
  CONTENT_STORE_HEADER_INITIAL_STATE,
  CONTENT_STORE_PRODUCT_INITIAL_STATE,
} from '@store/content/content.state';
import {
  SELECT_CONTENT_BLOCKS_ALL,
  SELECT_CONTENT_BLOCKS_ENTITY,
  SELECT_CONTENT_HEADER,
  SELECT_CONTENT_MODALS_ALL,
  SELECT_CONTENT_MODALS_ENTITY,
  SELECT_CONTENT_PRODUCT_MODAL,
} from '@store/content/content.selection';

@Injectable({ providedIn: 'root' })
export class ContentStoreSelector implements OnDestroy {
  private readonly _destroyerRef = new Subject<boolean>();

  header = CONTENT_STORE_HEADER_INITIAL_STATE;
  productModal = CONTENT_STORE_PRODUCT_INITIAL_STATE;

  // Blocks
  selectBlocks = this.store.select(SELECT_CONTENT_BLOCKS_ALL);
  selectBlock = (id: string): Observable<DoodBlockModel | undefined> =>
    this.store.select(SELECT_CONTENT_BLOCKS_ENTITY(id));

  // Modals
  selectModals = this.store.select(SELECT_CONTENT_MODALS_ALL);
  selectModal = (id: string): Observable<DoodModalConfig | undefined> =>
    this.store.select(SELECT_CONTENT_MODALS_ENTITY(id));

  // Header
  selectHeader = this.store.select(SELECT_CONTENT_HEADER);
  selectHeaderTitle = this.selectHeader.pipe(map(header => header.title));
  selectHeaderIsShopSearchAddressVisible = this.selectHeader.pipe(
    map(header => header.isShopSearchAddressVisible),
  );
  selectHeaderIsShopSearchParametersVisible = this.selectHeader.pipe(
    map(header => header.isShopSearchParametersVisible),
  );
  selectHeaderIsCartParametersVisible = this.selectHeader.pipe(
    map(header => header.isCartParametersVisible),
  );
  selectHeaderIsCartButtonVisible = this.selectHeader.pipe(
    map(header => header.isCartButtonVisible),
  );

  // Product
  selectProductModal = this.store.select(SELECT_CONTENT_PRODUCT_MODAL);

  constructor(private store: Store) {
    this.selectHeader.pipe(takeUntil(this._destroyerRef)).subscribe(_header => {
      this.header = _header;
    });
    this.selectProductModal.pipe(takeUntil(this._destroyerRef)).subscribe(_product => {
      this.productModal = _product;
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe();
  }

  private _unsubscribe(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
  }
}
