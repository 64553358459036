import { Observable } from 'rxjs';
import { Component } from '@angular/core';
import { map, take, takeUntil } from 'rxjs/operators';

import { DestroyerBase } from '@core/base/destroyer/destroyer.base';
import { ModalsService } from '@core/services/modals/modals.service';
import { DoodPaymentAtTableAmountType } from '@store/payment-at-table/payment-at-table.enum';

import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { PaymentAtTableStoreSelector } from '@common/selectors/payment-at-table.selector';
import { PaymentAtTableStoreDispatcher } from '@common/dispatchers/payment-at-table.dispatcher';

@Component({
  selector: 'app-pat-divide-amount-modal',
  templateUrl: './pat-divide-amount-modal.component.html',
})
export class PatDivideAmountModalComponent extends DestroyerBase {
  static handle = 'pat-divide-amount-modal';

  index$: Observable<number> = this.modalSelector
    .selectModal(PatDivideAmountModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  count = 2;

  finalPrice = 0;
  totalAmount = 0;
  minCount!: number;

  isMobile = false;

  constructor(
    private modalSelector: ModalStoreSelector,
    private readonly modalsService: ModalsService,
    protected settingsSelector: SettingsStoreSelector,
    protected paymentAtTableSelector: PaymentAtTableStoreSelector,
    protected paymentAtTableDispatcher: PaymentAtTableStoreDispatcher,
  ) {
    super();

    this.settingsSelector.selectDeviceIsMobileScreen
      .pipe(takeUntil(this._destroyerRef))
      .subscribe(_isMobile => (this.isMobile = _isMobile));

    this.paymentAtTableSelector.selectCheck
      .pipe(take(1))
      .subscribe(({ final_price, outstanding_amount }) => {
        this.processData(final_price, outstanding_amount);
      });
  }

  closeModal(): void {
    this.modalsService.close(PatDivideAmountModalComponent.handle);
  }

  validate(): void {
    this.modalsService.close(PatDivideAmountModalComponent.handle);
  }

  onChangeCount(count: number): void {
    this.count = count;
    this.paymentAtTableSelector.selectCheck.pipe(take(1)).subscribe(({ outstanding_amount }) => {
      this.totalAmount = this.checkOutstandingAmount(
        this.calculateTotalPrice(this.finalPrice, count),
        outstanding_amount,
      );

      this.paymentAtTableDispatcher.updateChoose({
        parts: this.count,
        amount: this.totalAmount,
        type: DoodPaymentAtTableAmountType.Divide,
      });
    });
  }

  private calculateTotalPrice(finalPrice: number, count: number): number {
    return Math.ceil(Math.abs(finalPrice) / count);
  }

  private processData(finalPrice: number, outstandingAmount: number): void {
    this.finalPrice = Math.abs(finalPrice);

    this.count = 2;

    this.updateTotalAmount(finalPrice, outstandingAmount);
  }

  private updateTotalAmount(finalPrice: number, outstandingAmount: number): void {
    const amount = this.checkOutstandingAmount(
      this.calculateTotalPrice(finalPrice, this.count),
      outstandingAmount,
    );
    this.paymentAtTableDispatcher.updateChoose({
      amount,
      parts: this.count,
      type: DoodPaymentAtTableAmountType.Divide,
    });
    this.totalAmount = amount;
  }

  private checkOutstandingAmount(totalAmount: number, outstandingAmount: number): number {
    if (totalAmount > outstandingAmount) {
      return outstandingAmount;
    }
    return totalAmount;
  }
}
