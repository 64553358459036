import { Injector } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';

export class AppInjector {
  private static injector?: Injector = undefined;

  private static subject = new ReplaySubject<Injector>();

  static setInjector(injector: Injector): void {
    AppInjector.injector = injector;
    AppInjector.subject.next(AppInjector.injector);
  }

  static getInjector(): Observable<Injector> {
    return AppInjector.subject.pipe(
      filter(injector => !!injector),
      take(1),
    );
  }
}
