<div class="rounded-m border-separate bg-white cursor-pinter">
  <a [href]="link">
    <div>
      <img
        [src]="imgUrl"
        class="w-full rounded-t-m"
        alt="img"
      />
    </div>
    <div class="p-4">
      <p class="text-sm">{{ date }}</p>
      <p class="font-bold text-1xsl leading-6">{{ title }}</p>
      <p class="mt-2.5 text-sm font-bold text-primary-600">Plus d’infos</p>
    </div>
  </a>
</div>
