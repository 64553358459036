<div class="min-h-screen flex flex-col">
  <!--  Header -->
  <app-block-list [blockIdList]="header"></app-block-list>

  <!--  Choices -->
  <ng-container *ngIf="step === kioskPaymentMethodPickerStep.CHOICE">
    <div class="flex flex-col flex-grow items-center justify-center">

      <!-- Choice heading -->
      <h1
        class="text-center text-6xl font-bold mb-24"
        [style.color]="headingColor | contentBuilderColor"
      >
        {{ headingChoosePaymentMethod | translate }}
      </h1>

      <!-- Choice cards -->
      <div
        class="grid gap-12 pb-64"
        [ngClass]="{
          'grid-cols-2 w-8/12': paymentMethodCards.length > 1,
          'grid-cols-1 w-4/12': paymentMethodCards.length === 1
        }"
      >
        <div *ngFor="let card of paymentMethodCards">
          <div
            class="flex flex-col items-center justify-center gap-8 h-full w-full cursor-pointer rounded-lg border-4 border-solid px-10 py-12 text-4.5xl text-center"
            [style.color]="selectedCard === card ? (cardActiveTextColor | contentBuilderColor) :  (cardInactiveTextColor | contentBuilderColor) "
            [style.background-color]="selectedCard === card ? (cardActiveBackgroundColor | contentBuilderColor) : (cardInactiveBackgroundColor | contentBuilderColor)"
            [style.border-color]="selectedCard === card ? (cardActiveTextColor | contentBuilderColor) : (cardInactiveTextColor | contentBuilderColor)"
            (click)="selectCard(card)"
          >
            <i class="icon text-5xl"
               *ngIf="card.icon"
               [ngClass]="{
              'icon-kiosk-icon-coins': card.icon ==='icon-kiosk-icon-coins',
              'icon-kiosk-icon-card': card.icon ==='icon-kiosk-icon-card',
              }"
            ></i>
            {{ card.label | translate }}
          </div>
        </div>
      </div>


      <!-- Payment component without template (on site) -->
      <app-component-factory
        *ngIf="selectedCard && selectedCard.hasNextScreen === false"
        [componentClass]="selectedCard.paymentMethod.panelComponent"
        [data]="selectedCard.paymentMethod.data"
      ></app-component-factory>
    </div>
  </ng-container>


  <!--  Choices -->
  <ng-container *ngIf="step === kioskPaymentMethodPickerStep.TERMINAL">
    <div class="flex flex-col flex-grow items-center justify-center">
      <div class="w-8/12 flex flex-col items-center justify-center gap-12"
           [style.color]="headingColor | contentBuilderColor"
      >
        <app-component-factory
          *ngIf="selectedCard && selectedCard.hasNextScreen === true"
          [componentClass]="selectedCard.paymentMethod.panelComponent"
          [data]="selectedCard.paymentMethod.data"
        ></app-component-factory>

        <div *ngIf="payOnKioskText" class="text-3xl text-center">
          {{ payOnKioskText | translate }}
        </div>

        <img
          *ngIf="displayPayOnKioskImage && payOnKioskImage"
          [src]="payOnKioskImage.url"
          alt="img"
        />
      </div>

    </div>
  </ng-container>

  <!-- Footer -->
  <div class="grid grid-cols-2 gap-10 p-10">
    <app-button-atom
      size="XXL"
      thickSize="XXL"
      type="kiosk-light"
      [label]="'cart-funnel.buttons.cancel' | translate"
      (clickEventEmitter)="back()"
    ></app-button-atom>
    <app-button-atom
      *ngIf="displaySubmitButton"
      size="XXL"
      thickSize="XXL"
      type="kiosk-primary"
      [label]="'cart-funnel.buttons.validate' | translate"
      [disabled]="!selectedCard"
      (clickEventEmitter)="submit()"
    ></app-button-atom>
  </div>
</div>
