import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ISimpleItem } from '@core/models/simple-item.model';

@Component({
  selector: 'app-horizontal-anchor-list-atom',
  templateUrl: './horizontal-anchor-list-atom.component.html',
})
export class HorizontalAnchorListAtomComponent {
  @Input() items!: ISimpleItem[];
  @Input() text!: string;

  @Output() clickItem = new EventEmitter<ISimpleItem>();
}
