import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-discount-voucher-atom',
  templateUrl: './discount-voucher-atom.component.html',
})
export class DiscountVoucherAtomComponent {
  @Input() voucher!: string;
  @Input() isValid = false;
  @Input() headingColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };

  @Output() removeVoucher = new EventEmitter<Event>();
}
