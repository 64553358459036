import { Injectable } from '@angular/core';
import { map, switchMap, take } from 'rxjs/operators';
import { combineLatest, iif, Observable, of } from 'rxjs';

import { UserKeys } from '@config/keys/user.keys';
import { MetafieldKeys } from '@config/keys/metafield.keys';

import { PongoApiService } from '@core/services/api/pongo/pongo-api.service';

import { DoodUserModel } from '@store/authentication/authentication.model';
import { DoodProductModel } from '@core/models/product.model';
import { IPongoAsset, IPongoCustomer } from '@core/models/pongo.model';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';
import { DoodShopModel } from '@core/models/shop.model';

@Injectable({
  providedIn: 'root',
})
export class PongoService {
  constructor(
    private authSelector: AuthStoreSelector,
    private readonly pongoApiService: PongoApiService,
    private marketplaceSelector: MarketplaceStoreSelector,
  ) {}

  isUserPongoCustomerEnabledOnMarketplaceLevel$(): Observable<boolean> {
    return combineLatest([
      this.authSelector.selectUser,
      this.marketplaceSelector.selectMarketplaceId,
    ]).pipe(
      take(1),
      map(([user, marketplaceId]) => {
        if (!user || !user[UserKeys.Metafields] || !Array.isArray(user[UserKeys.Metafields])) {
          return false;
        }

        return !!user[UserKeys.Metafields]!.find(
          metafield =>
            metafield[MetafieldKeys.Handle] === `pongo_customer_id_marketplace_${marketplaceId}`,
        );
      }),
    );
  }

  getMarketplaceRewards$(): Observable<IPongoAsset[]> {
    return this.marketplaceSelector.selectMarketplaceId.pipe(
      take(1),
      switchMap(marketplaceId => this.pongoApiService.getMarketplaceRewards$(marketplaceId)),
    );
  }

  createCustomer$(phoneNumber: string, hasOptin: Boolean): Observable<DoodUserModel> {
    return this.marketplaceSelector.selectMarketplaceId.pipe(
      take(1),
      switchMap(marketplaceId =>
        this.pongoApiService.createCustomer$(marketplaceId, phoneNumber, hasOptin),
      ),
    );
  }

  getCustomer$(shop?: DoodShopModel | null): Observable<IPongoCustomer | undefined> {
    return this.isUserPongoCustomerEnabledOnMarketplaceLevel$().pipe(
      switchMap(pongoEnabled =>
        iif(
          () => pongoEnabled,
          this.marketplaceSelector.selectMarketplaceId.pipe(
            take(1),
            switchMap(marketplaceId => this.pongoApiService.getCustomer$(marketplaceId, shop?.id)),
          ),
          of(undefined),
        ),
      ),
    );
  }

  attachCustomer$(phoneNumber: string): Observable<DoodUserModel> {
    return this.marketplaceSelector.selectMarketplaceId.pipe(
      take(1),
      switchMap(marketplaceId => this.pongoApiService.attachCustomer$(marketplaceId, phoneNumber)),
    );
  }

  buyReward$(rewardId: string): Observable<IPongoAsset> {
    return this.marketplaceSelector.selectMarketplaceId.pipe(
      take(1),
      switchMap(marketplaceId => this.pongoApiService.buyReward$(marketplaceId, rewardId)),
    );
  }

  allowedProducts$(externalIds: string[], shopId?: string): Observable<DoodProductModel[]> {
    return this.marketplaceSelector.selectMarketplaceId.pipe(
      take(1),
      switchMap(marketplaceId =>
        this.pongoApiService.allowedProducts$(marketplaceId, externalIds, shopId),
      ),
    );
  }
}
