<div
  class="mt-28"
  *ngIf="products$ | async as products"
>
  <div class="mb-5 text-4xl font-bold">
    {{ heading | translate }}
  </div>
  <div
    class="grid grid-cols-3 gap-3 overflow-x-auto scroll-smooth scrollbar-hide"
    #carousel
  >
    <ng-container *ngFor="let product of products">
      <div class="h-full">
        <app-kiosk-product-card-atom
          *ngIf="product"
          class="flex h-full min-h-[250px] flex-col justify-between"
          [product]="product"
          [quantity]="product.quantity"
          [isProductAvailable]="!!product.available"
          [selectionnable]="product.available"
          (addProduct)="navigateToProduct(product)"
          [textColor]="textColor"
          [buttonBackgroundColor]="primaryButtonBackgroundColor"
          [buttonTextColor]="primaryButtonTextColor"
        ></app-kiosk-product-card-atom>
      </div>
    </ng-container>
  </div>
</div>
