<div
  class="fixed bottom-0 left-0 right-0 max-h-85-screen overflow-y-auto rounded-t-3xl bg-white shadow-up-L"
  [style.z-index]="((index$ | async) ?? 0) + 50"
>
  <app-push-modal-button-atom
    *ngIf="textWithButtonItem"
    [text]="textWithButtonItem.text ?? ''"
    [title]="textWithButtonItem.title"
    [imgUrl]="textWithButtonItem.img ?? ''"
    [isSafari]="isSafari"
    (yesButtonClick)="installPwa()"
    (noButtonClick)="close()"
  ></app-push-modal-button-atom>
</div>
