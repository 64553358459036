<div class="cursor-pointer {{ size }}">
  <span
    [class.light]="light"
    class="block w-full h-0.75 burger-line mb-1.6 rounded-full"
    [style.background]="color | contentBuilderColor: 'neutral-800'"
  ></span>
  <span
    [class.light]="light"
    class="block w-full h-0.75 burger-line mb-1.6 rounded-full"
    [style.background]="color | contentBuilderColor: 'neutral-800'"
  ></span>
  <span
    [class.light]="light"
    class="block w-full h-0.75 burger-line rounded-full"
    [style.background]="color | contentBuilderColor: 'neutral-800'"
  ></span>
</div>
