<p
  class="mt-10 text-center text-4xl font-bold"
  [style.color]="color | contentBuilderColor"
  *ngIf="funnelUsername$ | async as funnelUsername"
>
  {{ 'cart-funnel.welcome' | translate }} {{ funnelUsername }}
</p>
<div class="flex flex-col gap-14 pt-44">
  <p
    class="text-center text-6xl font-bold"
    [style.color]="color | contentBuilderColor"
  >
    {{ title }}
  </p>
  <div class="grid grid-cols-2 gap-10">
    <app-block-list
      [blockIdList]="options"
      [injectData]="{
        parentId: blockId,
        textColor: textColor,
        buttonColor: buttonColor,
        activeTextColor: activeTextColor,
        activeButtonColor: activeButtonColor,
      }"
    ></app-block-list>
  </div>
</div>
