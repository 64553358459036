import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-actions-info-shop-atom',
  templateUrl: './actions-info-shop-atom.component.html',
})
export class ActionsInfoShopAtomComponent implements OnChanges {
  @Input() isFavourite = false;
  @Input() shopName!: string;
  @Input() phoneNumber!: string;
  @Input() emailAddress!: string;
  @Input() facebookURL?: string;
  @Input() instagramURL?: string;
  @Input() twitterURL?: string;
  @Input() textColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };

  @Output() favouriteChanged = new EventEmitter<boolean>();

  toggleLabel!: string;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.toggleLabel = `Ajouter ${this.shopName} à mes favoris`;
  }

  toggleValue(): void {
    this.isFavourite = !this.isFavourite;
    this.favouriteChanged.emit(this.isFavourite);
  }

  navigateTo(url: string): void {
    window.open(url, '_blank');
  }
}
