import { Component, Input, OnInit } from '@angular/core';
import { IContentBuilderFieldImage } from '@core/models/content-builder-fields.model';
import { OrderStoreSelector } from '@common/selectors/order.selector';

enum ExternalLinkButtonType {
  text = 'text',
  light = 'light',
  normal = 'normal',
  outlined = 'outlined',
}

type ExternalLinkButtonTarget = '_blank' | '_self';

@Component({
  selector: 'app-user-feedback-link-block',
  templateUrl: './user-feedback-link-block.component.html',
  styleUrls: ['./user-feedback-link-block.component.scss'],
})
export class UserFeedbackLinkBlockComponent implements OnInit {
  constructor(private readonly orderSelector: OrderStoreSelector) {}

  @Input() url?: string;
  @Input() text?: string;
  @Input() target: ExternalLinkButtonTarget = '_blank';
  @Input() type: ExternalLinkButtonType = ExternalLinkButtonType.normal;
  @Input() icon?: IContentBuilderFieldImage;
  @Input() onlyOnShop?: Array<{ id: string }>;

  displayThisLink = false;

  ngOnInit(): void {
    const activeOrder = this.orderSelector.active;

    if (
      !this.onlyOnShop ||
      (activeOrder && this.onlyOnShop.some(shop => shop.id === activeOrder?.shop?.id))
    ) {
      this.displayThisLink = true;
    }
  }

  click(): void {
    if (this.url) {
      window.open(this.url, this.target);
    }
  }
}
