import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import { SETTINGS_STORE_LOCAL_ACTIONS, SettingsParametersState } from '@store/settings';

@Injectable({ providedIn: 'root' })
export class SettingsStoreDispatcher {
  constructor(private store: Store) {}

  calculateDevice(): void {
    this.store.dispatch(SETTINGS_STORE_LOCAL_ACTIONS.calculateDevice());
  }

  updateAppLocale(locale: string): void {
    this.store.dispatch(SETTINGS_STORE_LOCAL_ACTIONS.updateAppLocale({ locale }));
  }

  updateKioskId(id: string | null): void {
    this.store.dispatch(SETTINGS_STORE_LOCAL_ACTIONS.updateKioskId({ id }));
  }

  updateIsKioskLayout(isKioskLayout: boolean): void {
    this.store.dispatch(SETTINGS_STORE_LOCAL_ACTIONS.updateIsKioskLayout({ isKioskLayout }));
  }

  updateParameters(parameters: Partial<SettingsParametersState>): void {
    this.store.dispatch(SETTINGS_STORE_LOCAL_ACTIONS.updateParameters({ parameters }));
  }
}
