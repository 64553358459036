import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { ISimpleItem } from '@core/models/simple-item.model';
import { ModalsService } from '@core/services/modals/modals.service';
import { ShopSearchParametersService } from '@core/services/shop-search-parameters/shop-search-parameters.service';
import { ShopSearchParametersModalComponent } from '@shared/modals/shop-search-parameters-modal/shop-search-parameters-modal.component';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-shop-search-parameters-overview',
  templateUrl: './shop-search-parameters-overview.component.html',
})
export class ShopSearchParametersOverviewComponent implements OnInit, OnDestroy {
  parameters!: ISimpleItem[];
  @Input() textColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() iconBackgroundColor: IContentBuilderFieldColor = {
    value: 'primary-100',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() iconColor: IContentBuilderFieldColor = {
    value: 'primary-600',
    type: ColorFieldTypesValues.Palette,
  };

  private _destroyerRef = new Subject<boolean>();

  constructor(
    private readonly modalsService: ModalsService,
    private settingsSelector: SettingsStoreSelector,
    private readonly shopSearchParametersService: ShopSearchParametersService,
  ) {}

  ngOnInit(): void {
    this.settingsSelector.selectParameters
      .pipe(takeUntil(this._destroyerRef))
      .subscribe(parameters => {
        this.parameters = this.shopSearchParametersService.retrieveParametersValue(parameters);
      });
  }

  ngOnDestroy(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
  }

  openParameters(): void {
    this.modalsService.open(ShopSearchParametersModalComponent.handle);
  }
}
