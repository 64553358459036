export const ERRORS = {
  LOGIN_INVALID: `errors.email-not-valid`,
  PASSSWORD_INVALID: 'errors.wrong-password',
  USER_NOT_FOUND: 'errors.user-not-found',
  EMAIL_ALREADY_EXIST: `errors.email-already-exist`,
  WEAK_PASSWORD: `errors.weak-password`,
  WRONG_PASSWORD: 'errors.wrong-password',
  TIME_SLOT: `errors.slot-not-available-1`,
  TIME_SLOT_NO_LONGER_AVAILABLE: `errors.slot-is-no-longer-available`,
  MISSING_DELIVERY_ADDRESS: 'Missing or invalid, order delivery address.',
  ADDRESS_SHOULD_NOT_BE_BLANK: 'This value should not be blank.',
  DISABLED_USER: 'errors.disabled-user',
};
