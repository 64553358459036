import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit } from '@angular/core';

import { DoodOrderModel } from '@store/order/order.model';
import { OrderStoreSelector } from '@common/selectors/order.selector';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-order-status-success-header-block',
  templateUrl: './order-status-success-header-block.component.html',
})
export class OrderStatusSuccessHeaderBlockComponent implements OnInit {
  @Input() text?: string;
  @Input() textColor: IContentBuilderFieldColor = {
    value: 'neutral-800',
    type: ColorFieldTypesValues.Palette,
  };

  order: DoodOrderModel | null = null;

  constructor(
    private orderSelector: OrderStoreSelector,
    private readonly translate: TranslateService,
  ) {
    this.orderSelector.selectActive.subscribe(order => {
      this.order = order;
    });
  }

  ngOnInit(): void {
    if (!this.text) {
      this.translate.get('order.order-validate').subscribe(text => (this.text = text));
    }
  }
}
