import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Paths } from '@config/paths.config';
import { EntityKeys } from '@config/keys/entity.keys';
import { IPushShop } from '@core/models/marketplace.model';
import { EntityStoreSelector } from '@common/selectors/entity.selector';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

import { EntitiesService } from '@core/services/entities/entities.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { IShopItem } from '@core/models/shop.model';

@Component({
  selector: 'app-food-society-shop-list-block',
  templateUrl: './food-society-shop-list-block.component.html',
})
export class FoodSocietyShopListBlockComponent implements OnInit, OnDestroy {
  private _destroyerRef = new Subject<boolean>();

  @Input() shopItems!: IPushShop[];

  entities$ = this.entitySelector.selectEntities;
  marketplaceId$ = this.marketplaceSelector.selectMarketplaceId;
  isMarketplaceAvailable$ = this.marketplaceSelector.selectMarketplaceAvailable;

  isMarketplaceAvailable = false;

  constructor(
    private readonly router: Router,
    private entitiesService: EntitiesService,
    private entitySelector: EntityStoreSelector,
    private readonly routerHelper: RouterHelperService,
    private marketplaceSelector: MarketplaceStoreSelector,
  ) {}

  ngOnInit(): void {
    this.marketplaceId$
      .pipe(
        takeUntil(this._destroyerRef),
        switchMap(marketplaceId => this.entitiesService.loadAll$(marketplaceId)),
      )
      .subscribe();

    this.entities$
      .pipe(
        takeUntil(this._destroyerRef),
        tap(entities => {
          this.shopItems = this.shopItems.map(shop => {
            return {
              ...shop,
              [EntityKeys.Slug]: entities.find(entity => entity[EntityKeys.Id] === shop.shop)?.slug,
              [EntityKeys.Available]: entities.find(entity => entity[EntityKeys.Id] === shop.shop)
                ?.available,
            };
          });
        }),
      )
      .subscribe();

    this.isMarketplaceAvailable$
      .pipe(
        takeUntil(this._destroyerRef),
        tap(isAvailable => (this.isMarketplaceAvailable = isAvailable)),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
  }

  navigateToShop(slug: string | undefined): void {
    if (slug && this.isMarketplaceAvailable) {
      this.router.navigate([this.routerHelper.translateRoute(`${Paths.Shops}/${slug}`)]);
    }
  }

  protected forceType(shop: IPushShop): IShopItem {
    return shop as unknown as IShopItem;
  }
}
