import { map, switchMap, take } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { DoodProductModel } from '@core/models/product.model';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

import { ModalsService } from '@core/services/modals/modals.service';
import { SuggestionsSetsService } from '@core/services/suggestions-sets/suggestions-sets.service';

import { ContentStoreDispatcher } from '@common/dispatchers/content.dispatcher';
import { ProductAddModalComponent } from '@shared/modals/product-add-modal/product-add-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { staggeredListAnimation } from '@shared/animations/list.animation';
import { ProductService } from '@core/services/product/product.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { BehaviorSubject, of } from 'rxjs';
import { ProductStoreDispatcher } from '@common/dispatchers/product.dispatcher';
import { KioskProductOverviewModalComponent } from '@shared/modals/kiosk-product-overview-modal/kiosk-product-overview-modal.component';
import { CartItemKeys } from '@config/keys/cart.keys';
import { ICartItem } from '@core/models/cart.model';
import { CartStoreRefiner } from '@common/refiners/cart.refiner';
import { CartService } from '@core/services/cart/cart.service';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

@Component({
  selector: 'app-suggestion-cards-block',
  templateUrl: './suggestion-cards-block.component.html',
  animations: [staggeredListAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestionCardsBlockComponent {
  @Input() title?: string;
  @Input() description?: string;
  private _suggestionsSet$ = new BehaviorSubject<string | undefined>(undefined);
  @Input() set suggestionsSet(suggestionsSet: string | undefined) {
    this._suggestionsSet$.next(suggestionsSet);
  }
  @Input() displayType = 'grid';
  @Input() backgroundColor?: IContentBuilderFieldColor;

  @Input() textColor?: IContentBuilderFieldColor;
  @Input() cardColor?: IContentBuilderFieldColor;
  @Input() buttonBackgroundColor?: IContentBuilderFieldColor;
  @Input() buttonTextColor?: IContentBuilderFieldColor;
  @Input() secondaryColor?: IContentBuilderFieldColor;

  selectedAdditions: string[] = [];

  protected readonly suggestions = toSignal<DoodProductModel[]>(
    this._suggestionsSet$.pipe(
      switchMap(suggestionsSet => {
        if (!suggestionsSet) {
          return of([] as DoodProductModel[]);
        }
        return this.suggestionsSetsService.getSuggestionsSet$(suggestionsSet);
      }),
      map(suggestions => suggestions.map((s: { data: DoodProductModel[] }) => s.data)),
      map(suggestions => suggestions as DoodProductModel[]),
    ),
  );

  protected readonly tagColors$ =
    this.marketplaceStoreSelector.selectMarketPlaceProductLabelsTagColors;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private readonly modalsService: ModalsService,
    private contentDispatcher: ContentStoreDispatcher,
    private readonly suggestionsSetsService: SuggestionsSetsService,
    private readonly productService: ProductService,
    private readonly productDispatcher: ProductStoreDispatcher,
    private readonly cartStoreRefiner: CartStoreRefiner,
    private readonly cartService: CartService,
    private readonly marketplaceStoreSelector: MarketplaceStoreSelector,
  ) {}

  onNavigateTo(product: DoodProductModel): void {
    this.contentDispatcher.updateProduct({
      shopIsNotActive: true,
      productId: product.id,
      shopId: product.shop_id,
    });
    // TODO: Check why using static variables
    ProductAddModalComponent.allowAddToCart = true;
    this.modalsService.open(ProductAddModalComponent.handle);
  }

  addProductDispatcher(product: DoodProductModel): void {
    if (product.type === 'SIMPLE' && product.additions.products.length === 0) {
      this.addToCart(product);
    } else {
      this.navigateToProduct(product);
    }
  }

  addToCart(product: DoodProductModel): void {
    const newCartItem: Omit<ICartItem, 'id'> = {
      [CartItemKeys.ItemId]: product?.id,
      [CartItemKeys.ShopId]: product?.shop_id,
      [CartItemKeys.Additions]: this.selectedAdditions,
      [CartItemKeys.Quantity]: 1,
    };
    if (!newCartItem) return;

    this.cartStoreRefiner.selectActiveCartProducts.pipe(take(1)).subscribe(cartItems => {
      const itemInCart = this.cartService.findMatchingCartItem({
        cartItems,
        searchCartItem: newCartItem,
      });
      if (itemInCart) {
        this.cartService.updateCartItem(
          {
            ...itemInCart,
            quantity: itemInCart.quantity + 1,
          },
          false,
        );
      } else {
        this.cartService.addCartItem$({ ...newCartItem, quantity: 1 }).subscribe();
      }
    });
  }

  openProductModal(product: DoodProductModel): void {
    this.productDispatcher.insertActive(product);
    this.modalsService.open(KioskProductOverviewModalComponent.handle, undefined, {
      image: product.images?.cover?.url,
      name: product.name,
      description: product.description,
      allergens: product.allergens,
      type: product.type,
      backgroundColor: this.buttonTextColor,
      accentColor: this.secondaryColor,
    });
  }

  openAddProductModal(product: DoodProductModel): void {
    this.productDispatcher.insertActive(product);
    this.modalsService.open(KioskProductOverviewModalComponent.handle, undefined, {
      image: product.images?.cover?.url,
      name: product.name,
      description: product.description,
      allergens: product.allergens,
      price: product.price,
      type: product.type,
      backgroundColor: this.buttonTextColor,
      accentColor: this.secondaryColor,
      navigateToProduct: () => this.navigateToProduct(product),
    });
  }

  navigateToProduct(product: DoodProductModel): void {
    const shopCity = this.route.snapshot.paramMap.get('shopCity');
    const shopType = this.route.snapshot.paramMap.get('shopType');
    const shopSlug = this.route.snapshot.paramMap.get('shopSlug');

    this.router.navigate([
      `/fr/${shopCity}/${shopType}/${shopSlug}/product/${product.id}/product-add`,
    ]);
  }

  isProductAvailable(product: DoodProductModel): boolean {
    return this.productService.isAvailable(product);
  }
}
