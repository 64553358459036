import { EntityMapOne, Update } from '@ngrx/entity';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { DoodModalModel } from '@core/models/modal.model';
import { DoodApiError } from '@shared/interfaces/error.interface';

export const MODAL_STORE_API_ACTIONS = createActionGroup({
  source: 'Dood/API/Modal',
  events: {
    'Fetch Modals': props<{ userId: string; marketplaceId: string }>(),
    'Fetch Modals Success': props<{ modals: DoodModalModel[] }>(),
    'Fetch Modals Failure': props<{ error: DoodApiError }>(),
    'Fetch Modals Complete': emptyProps(),
  },
});

export const MODAL_STORE_LOCAL_ACTIONS = createActionGroup({
  source: 'Dood/LOCAL/Modal',
  events: {
    // Modals
    // -- Multiple
    'Set Modals': props<{ modals: DoodModalModel[] }>(),
    'Add Modals': props<{ modals: DoodModalModel[] }>(),
    'Remove Modals': props<{ ids: string[] }>(),
    'Upsert Modals': props<{ modals: DoodModalModel[] }>(),
    'Reset Modals': emptyProps(),

    // -- Single
    'Add Modal': props<{ modal: DoodModalModel }>(),
    'Remove Modal': props<{ id: string }>(),
    'Upsert Modal': props<{ modal: DoodModalModel }>(),
    'Update Modal': props<{ modal: Update<DoodModalModel> }>(),
    'Map Modal': props<{ modal: EntityMapOne<DoodModalModel> }>(),

    // Misc
    'reset': emptyProps(),
  },
});
