import { switchMap, take } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';

import { environment } from '@env/environment';

import { EntityStoreSelector } from '@common/selectors/entity.selector';
import { EntitiesService } from '@core/services/entities/entities.service';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

@Component({
  selector: 'app-shop-small-overview-grid-block',
  templateUrl: './shop-small-overview-grid-block.component.html',
})
export class ShopSmallOverviewGridBlockComponent implements OnInit {
  apiUrl = environment.api.dood.url;

  entities$ = this.entitySelector.selectEntities;
  marketplaceId$ = this.marketplaceSelector.selectMarketplaceId;

  constructor(
    private entitiesService: EntitiesService,
    private entitySelector: EntityStoreSelector,
    private marketplaceSelector: MarketplaceStoreSelector,
  ) {}

  ngOnInit(): void {
    this.marketplaceId$
      .pipe(
        take(1),
        switchMap(marketplaceId => this.entitiesService.loadAll$(marketplaceId)),
      )
      .subscribe();
  }
}
