import { computed, effect, inject, Injectable } from '@angular/core';
import { DeclinableProductStepperStore } from '../declinable-product-stepper.store';
import { StepperUtil } from '../util/stepper-util';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { combineLatest, map, scan } from 'rxjs';

@Injectable()
export class DeclinableProductAutoNextStepService {
  private readonly declinableProductStepperStore = inject(DeclinableProductStepperStore);

  constructor() {
    effect(
      () => {
        // go next step, if the step is valid and the user can not make more choices
        const blockAutoNextStep = this.blockAutoNextStep();
        if (blockAutoNextStep) {
          return;
        }

        const steppers = this.declinableProductStepperStore.steppers();
        if (!steppers) {
          return;
        }
        const currentStepper =
          steppers.simpleProductStepper ?? steppers.categoryStepper ?? steppers.declinableStepper;

        if (!currentStepper.isValid || currentStepper.canMakeMoreChoices) {
          return;
        }

        if (currentStepper.hasNextStep) {
          const nextStep = currentStepper.nextStep;
          if (nextStep) {
            currentStepper.setSelectedStep(nextStep);
          }
          return;
        }
        const nextStepper = StepperUtil.findStepperWithNextStep(
          this.declinableProductStepperStore.stepper(),
        );
        const nextStep = nextStepper?.step;

        if (!nextStep) {
          return;
        }

        if (nextStep.hasNextStep && nextStep.nextStep) {
          nextStep.setSelectedStep(nextStep.nextStep);
          return;
        }
        return;
      },
      {
        allowSignalWrites: true,
      },
    );
  }

  public initAutoNextStep() {}

  private readonly blockAutoNextStep = computed(() => {
    const selectedStepsId = this.declinableProductStepperStore.selectedStepsId();
    const currentCategoryId = selectedStepsId?.categoryId;
    if (!currentCategoryId) {
      return false;
    }
    const categoryIdStepBeforeBack = this.categoryIdStepBeforeBack();
    if (!categoryIdStepBeforeBack) {
      return false;
    }
    const currentCategoryPosition =
      categoryIdStepBeforeBack.selectableCategoryIds.indexOf(currentCategoryId);
    if (currentCategoryPosition < categoryIdStepBeforeBack.position) {
      return true;
    }

    if (currentCategoryPosition === categoryIdStepBeforeBack.position) {
      const isSameProduct =
        categoryIdStepBeforeBack.simpleProductId === selectedStepsId.simpleProductId;
      if (!isSameProduct) {
        // maybe if their is a category where there is multiples choice of product with additionGroup it will not working as expected
        return false;
      }
      const additionGroupPosition = categoryIdStepBeforeBack.additionsGroupPosition;
      const currentAdditionGroupPosition =
        categoryIdStepBeforeBack.additionsGroups?.findIndex(
          group => group.id === selectedStepsId.additionsGroupId,
        ) ?? -1;
      if (currentAdditionGroupPosition < additionGroupPosition) {
        return true;
      }
      return false;
    }
    return false;
  });

  private readonly selectedStepsId$ = toObservable(
    this.declinableProductStepperStore.selectedStepsId,
  );
  public readonly selectableCategoryIds$ = toObservable(
    this.declinableProductStepperStore.selectableCategoryIds,
  );

  private readonly additionsGroupOfTheCurrentStep$ = toObservable(
    this.declinableProductStepperStore.steppers,
  ).pipe(
    map(steppers => {
      return steppers?.simpleProductStepper?.additionsGroups;
    }),
  );

  private readonly categoryIdStepBeforeBack = toSignal(
    combineLatest({
      selectedStepsId: this.selectedStepsId$,
      selectableCategoryIds: this.selectableCategoryIds$,
      additionsGroups: this.additionsGroupOfTheCurrentStep$,
    }).pipe(
      map(({ selectedStepsId, selectableCategoryIds, additionsGroups }) => {
        if (!selectedStepsId || !selectableCategoryIds) {
          return undefined;
        }
        const position = selectableCategoryIds.indexOf(selectedStepsId.categoryId);
        const additionsGroupPosition =
          additionsGroups?.findIndex(group => group.id === selectedStepsId.additionsGroupId) ?? -1;
        const additionsGroupId = selectedStepsId.additionsGroupId;
        return {
          visitedStepCategoryId: selectedStepsId.categoryId,
          position,
          selectableCategoryIds,
          simpleProductId: selectedStepsId.simpleProductId,
          additionsGroupId,
          additionsGroupPosition,
          additionsGroups,
        };
      }),
      scan((acc, value) => {
        if (!value) {
          return acc;
        }
        if (!acc) {
          return value;
        }
        const isSameProduct = acc.simpleProductId === value.simpleProductId;
        if (
          value.position > acc.position ||
          (isSameProduct && value.additionsGroupPosition > acc.additionsGroupPosition)
        ) {
          return value;
        }
        return acc;
      }),
    ),
  );
}
