<div
  class="flex flex-col items-center justify-center gap-6 h-full w-full cursor-pointer rounded-lg border-4 border-solid px-10 py-12 text-4.5xl text-center font-normal"
  [style.borderColor]="(isActive ? activeBackground : inactiveTextColor) | contentBuilderColor"
  [style.color]="(isActive ? activeTextColor : inactiveTextColor) | contentBuilderColor"
  [style.backgroundColor]="(isActive ? activeBackground : inactiveBackground) | contentBuilderColor"
  (click)="clickEmitter.emit()"
>
  <ng-content></ng-content>
  {{ label }}
</div>
