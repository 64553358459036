<app-kiosk-footer-double-button-atom
  [backgroundColor]="backgroundColor"
  [textColor]="textColor"
  [firstButtonBackgroundColor]="primaryButtonBackgroundColor"
  [firstButtonTextColor]="primaryButtonTextColor"
  [firstButtonLabel]="primaryButtonLabel"
  (onFirstButtonClick)="navigateTo(primaryButtonLink)"
  [secondButtonBackgroundColor]="secondaryButtonBackgroundColor"
  [secondButtonTextColor]="secondaryButtonTextColor"
  [secondButtonLabel]="secondaryButtonLabel"
  (onSecondButtonClick)="navigateTo(secondaryButtonLink)"
  [displaySecondButton]="displaySecondButton"
>
</app-kiosk-footer-double-button-atom>
