import dayjs from 'dayjs';
import { Dayjs, extend, ConfigType } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

extend(utc);
extend(timezone);

export class DateUtils {
  static isLessThanFromNow(date: Date, ms: number): boolean {
    const msAgo = new Date(Date.now() - ms);
    return date > msAgo;
  }

  static dayjsInMarketplaceTimezone(date?: ConfigType): Dayjs {
    return dayjs.tz(dayjs(dayjs(date).format()).utc());
  }
}
