import { Component, OnDestroy } from '@angular/core';

import { PaymentService } from '@core/services/payment/payment.service';

@Component({
  selector: 'app-pat-payment-panel-stripe-applepay',
  templateUrl: './pat-payment-panel-stripe-applepay.component.html',
})
export class PatPaymentPanelStripeApplepayComponent implements OnDestroy {
  constructor(private readonly paymentService: PaymentService) {}

  ngOnDestroy(): void {
    this.paymentService.paymentIntent$.next(null);
  }
}
