export enum ShopKeys {
  Id = 'id',
  AdditionGroups = 'addition_groups',
  Available = 'available',
  Status = 'status',
  AvailableAt = 'available_at',
  Keywords = 'keywords',
  Catalogs = 'catalogs',
  ContactEmail = 'contact_email',
  EatIn = 'eat_in',
  OnSiteEnabled = 'onsite_enabled',
  FirebaseTopic = 'firebase_topic',
  StripeBusinessId = 'stripe_business_id',
  Active = 'active',
  Moveable = 'moveable',
  Name = 'name',
  Slug = 'slug',
  Account = 'account',
  Phone = 'phone',
  Store = 'store',
  Pricing = 'pricing',
  Cover = 'cover',
  OverviewImage = 'overview_image',
  EdenredEnabled = 'edenred_enabled',
  MaxOrders = 'max_orders',
  MaxAmountMealVoucher = 'max_amount_meal_voucher',
  FavoriteByCount = 'favorite_by_count',
  GroupShop = 'group_shop',
  Venue = 'venue',
  KioskAdditionalInformation = 'kiosk_additional_information',
  LinkInstagram = 'link_instagram',
  LinkFacebook = 'link_facebook',
  LinkWebsite = 'link_website',
  LegacyPrinterEmailAddress = 'legacy_printer_email_address',
  Owners = 'owners',
  Products = 'products',
  Plugins = 'plugins',
  MarketplaceShops = 'marketplace_shops',
  DistributionModes = 'distribution_modes',
  Type = 'type',
  Description = 'description',
  ShortDescription = 'short_description',
  EnabledCatalogs = 'enabled_catalogs',
  Schedules = 'schedules',
  Distance = 'distance',
  Stars = 'stars',
  Favorite = 'favorite',
  Images = 'images',
  DefaultMarketplace = 'default_marketplace',
  TipsAllowed = 'tips_allowed',
  LoadedOrderType = 'loaded_order_type',
}

export enum ShopItemKeys {
  Name = 'name',
  Logo = 'logo',
  Shop = 'shop',
  Type = 'type',
  Available = 'available',
}

export enum MarketplaceShopsKeys {
  Id = 'id',
  Marketplace = 'marketplace',
}

export enum MarketplaceKeys {
  Id = 'id',
  Name = 'name',
  Available = 'available',
  CartScope = 'cart_scope',
  DistributionModes = 'distribution_modes',
  WhiteLabel = 'white_label',
  Address = 'address',
  Plugins = 'plugins',
  Blocks = 'blocks',
  Locales = 'locales',
  DefaultLocale = 'default_locale',
  Currency = 'currency',
  Layout = 'layout',
  Timezone = 'timezone',
  Country = 'country',
  Domains = 'domains',
  AuthMode = 'auth_mode',
  TipsAllowed = 'tips_allowed',
  Capabilities = 'capabilities',
  FirebaseTenant = 'firebase_tenant',
  Colors = 'colors',
  Organization = 'organization',
  DeliveryProvider = 'delivery_provider',
}

export enum AdditionGroupKeys {
  Id = 'id',
  Name = 'name',
  MinCount = 'min_count',
  MaxCount = 'max_count',
  Position = 'position',
  Items = 'items',
  Count = 'count',
}

export enum ScheduleKeys {
  Id = 'id',
  Active = 'active',
  Start = 'start',
  End = 'end',
  Opened = 'opened',
  Venue = 'venue',
}

export enum DistributionModeKeys {
  Type = 'type',
  Enabled = 'enabled',
  Label = 'label',
  Message = 'message',
  CustomName = 'custom_name',
  AuthMode = 'auth_mode',
  AllowGroupedOrder = 'allow_grouped_order',
  PreparationStrategy = 'preparation_strategy',
  Priority = 'priority',
  PreorderingAllowed = 'preordering_allowed',
  PreorderingMaxDelay = 'preordering_max_delay',
  PreorderingMinDelay = 'preordering_min_delay',
  OnsiteLocationPickMode = 'on_site_location_pick_mode',
}
