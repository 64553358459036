import { Router } from '@angular/router';
import { throwError, timer } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { Paths } from 'src/app/config/paths.config';
import { PaymentMethodHandles } from '@config/payment-methods.config';

import { OrderKeys } from '@config/keys/order.keys';
import { IOrder } from 'src/app/core/models/order.model';
import { DoodOrderModel } from '@store/order/order.model';
import { DoodApiError } from '@shared/interfaces/error.interface';
import { DestroyerBase } from '@core/base/destroyer/destroyer.base';

import { CartService } from '@core/services/cart/cart.service';
import { BasketService } from '@core/services/basket/basket.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { AuresEtkService } from '@core/services/aures-etk/aures-etk.service';
import { AuresEtkApiService } from '@core/services/api/aures-etk/aures-etk-api.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { PaymentStoreDispatcher } from '@common/dispatchers/payment.dispatcher';

@Component({
  selector: 'app-payment-panel-aures-etk-terminal',
  templateUrl: './payment-panel-aures-etk-terminal.component.html',
})
export class PaymentPanelAuresEtkTerminalComponent
  extends DestroyerBase
  implements OnInit, OnDestroy
{
  isPaymentInProgress = false;
  isOrderCancelled = false;
  displayPaymentLoadingScreen = false;
  paymentPhoneNumber?: string;
  statusMessage?: string;
  errorMessage?: string;
  isPaid = false;
  isOnError = false;
  isLoading = true;
  loadKioskResetTimer = false;

  constructor(
    private readonly router: Router,
    private readonly cartService: CartService,
    private readonly ordersService: OrdersService,
    private readonly basketService: BasketService,
    private paymentDispatcher: PaymentStoreDispatcher,
    private readonly auresEtkService: AuresEtkService,
    private readonly routerHelper: RouterHelperService,
  ) {
    super();
  }

  ngOnInit(): void {
    timer(1).subscribe(() => {
      this.paymentDispatcher.updateUI({
        error: null,
        button: {
          isEnabled: false,
          isVisible: false,
        },
        status: {
          isLoading: false,
        },
      });
    });

    this.createOrder();
  }

  async ngOnDestroy(): Promise<void> {
    if (!this.isPaid) {
      this.statusMessage = 'ANNULATION';
      await this.auresEtkService.abortTransaction$().toPromise();
    }
    super.ngOnDestroy();
  }

  private createPaymentIntent(order: DoodOrderModel): void {
    const finalPrice = order[OrderKeys.FinalPrice];

    this.errorMessage = '';
    this.statusMessage = '';

    if (typeof finalPrice !== 'number') {
      this.errorMessage = 'Erreur : montant incorrect';
      return;
    }

    this.auresEtkService
      .createPaymentIntent$(finalPrice, order[OrderKeys.Number])
      .pipe(
        takeUntil(this._destroyerRef),
        tap(event => {
          console.log('[Aures ETK]', event);
          this.isLoading = false;

          if (event.code === AuresEtkApiService.ERROR_STATUS) {
            this.handlePaymentError(event.response);
          }

          if (event.code === AuresEtkApiService.STATUS_TICKET_SOLDE) {
            this.errorMessage = undefined;
            this.handlePaymentSuccess(order, event.orderUuid);
          }

          if (event.message) {
            this.statusMessage = event.message;
          }
        }),
        catchError(err => {
          this.handlePaymentError('Impossible de communiquer avec le terminal de paiement.');
          return throwError(() => err);
        }),
      )
      .subscribe();
  }

  private handlePaymentError(errorMessage: string): void {
    if (errorMessage.includes('cancelled')) {
      console.log('[AURES_ETK] Launch reset timer');
      errorMessage = 'Transaction annulée';
      this.loadKioskResetTimer = true;
    }

    this.isLoading = false;
    this.errorMessage = errorMessage;
    this.statusMessage = 'ERREUR';
    this.isOnError = true;
  }

  createOrder(): void {
    this.isOnError = false;
    this.isLoading = true;
    this.errorMessage = '';
    this.statusMessage = '';
    this.loadKioskResetTimer = false;

    this.ordersService
      .createOrder$(PaymentMethodHandles.AuresEtkTerminal)
      .pipe(
        takeUntil(this._destroyerRef),
        map(order => (order ? this.createPaymentIntent(order) : null)),
      )
      .subscribe({
        error: (response: { error: DoodApiError }) => {
          this.handleOrderError(response.error);
        },
      });
  }

  private handlePaymentSuccess(order: DoodOrderModel, paymentRef?: string): void {
    this.auresEtkService
      .changeOrderStatusToPayment$(order.id, paymentRef ?? 'UNKNOWN')
      .pipe(
        tap(() => (this.isPaid = true)),
        tap(order => this.printOrder(order)),
        tap(order => this.handleChangeOrderStatusToPaymentSuccess(order)),
      )
      .subscribe();
  }

  printOrder(order: DoodOrderModel): void {
    this.ordersService
      .getPrintedTicketById$(order.id, 42)
      .pipe(
        map(ticketLines =>
          this.auresEtkService.transformTicketLinesToPrintDocument(ticketLines, order),
        ),
        switchMap(document => this.auresEtkService.printDocument$(document)),
      )
      .subscribe();
  }

  private handleChangeOrderStatusToPaymentSuccess(order: IOrder): void {
    this.basketService.clearBasket();
    this.cartService.clearCart();
    this.router.navigate([
      this.routerHelper.translateRoute(`${Paths.Orders}/${order.id}/${Paths.Status}`),
    ]);
    this.isPaymentInProgress = false;
  }

  private handleOrderError(error: Partial<DoodApiError>): void {
    this.isLoading = false;
    this.errorMessage = error.detail ?? 'Une erreur est survenue.';
  }

  backToCart(): void {
    this.router.navigate([this.routerHelper.translateRoute(`${Paths.Cart}`)]);
  }
}
