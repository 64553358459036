<h1 class="text-neutral-800 font-brand font-bold text-28px pb-5">
  {{ 'como.create-or-link-account.identification-step.title' | translate }}
</h1>

<form
  [formGroup]="form"
  class="pb-6"
>
  <ng-container *ngIf="identificationType === 'phoneNumber'">
    <label
      for="phoneOrEmail"
      class="block mb-1 text-sm leading-5 font-base font-normal text-neutral-600"
      translate="signup.phone"
    ></label>
    <label
      for="phoneOrEmail"
      class="block mb-2 text-xs font-base font-normal text-neutral-500"
    >
      {{ 'como.help-phone-format' | translate }}
    </label>
  </ng-container>
  <ng-container *ngIf="identificationType === 'email'">
    <label
      for="phoneOrEmail"
      class="block mb-1 text-sm leading-5 font-base font-normal text-neutral-600"
      translate="signup.email"
    ></label>
    <label
      for="phoneOrEmail"
      class="block mb-2 text-xs font-base font-normal text-neutral-500"
    >
      {{ 'como.help-email-format' | translate }}
    </label>
  </ng-container>
  <input
    id="phoneOrEmail"
    type="tel"
    formControlName="phoneOrEmail"
    (keyup.enter)="onSubmit()"
    class="flex w-full py-8xs px-5 bg-neutral-50 text-base font-base font-normal leading-5 border border-solid border-neutral-300"
    [class.border-error-700]="
      phoneErrorMessage ||
      (form.controls?.phoneOrEmail?.errors &&
        (form.controls?.phoneOrEmail?.dirty || form.controls?.phoneOrEmail?.touched))
    "
  />
  <ng-container
    *ngIf="
      form.controls?.phoneOrEmail?.invalid &&
      (form.controls?.phoneOrEmail?.dirty || form.controls?.phoneOrEmail?.touched)
    "
  >
    <span
      class="block absolute bottom-1 text-xs font-base font-normal text-error-700"
      *ngIf="form.controls?.phoneOrEmail?.errors"
      translate="signup.invalid-phone"
    ></span>
  </ng-container>
  <ng-container *ngIf="phoneErrorMessage">
    <span
      class="text-xs font-base font-normal text-error-700"
      [translate]="phoneErrorMessage"
    ></span>
  </ng-container>
</form>

<div class="flex flex-col gap-2">
  <div
    *ngIf="errorMessage"
    class="self-center text-xs font-base font-normal text-error-700"
  >
    {{ errorMessage }}
  </div>
  <div class="flex flex-col gap-8">
    <div class="w-full">
      <app-button-atom
        [iconUrl]="isValidating ? 'assets/images/spinner.svg' : ''"
        [disabled]="isValidating || this.form.invalid"
        size="M"
        [label]="'como.create-or-link-account.identification-step.validate-button' | translate"
        type="primary"
        [full]="true"
        (click)="onSubmit()"
      ></app-button-atom>
    </div>
    <div class="w-full">
      <app-button-atom
        [label]="
          'como.create-or-link-account.identification-step.continue-without-loyalty-button'
            | translate
        "
        [full]="true"
        (click)="onContinueWithoutLoyalty()"
        size="M"
        type="light"
      ></app-button-atom>
    </div>
  </div>
</div>
