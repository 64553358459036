import { Component, Input } from '@angular/core';

import { IContentBuilderFieldImage } from '@core/models/content-builder-fields.model';
import { BehaviorSubject, combineLatest, map, ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-image-full-width-block',
  templateUrl: './image-full-width-block.component.html',
})
export class ImageFullWidthBlockComponent {
  @Input() image?: IContentBuilderFieldImage;
  @Input() displayKiosk?: boolean; // ! This property needs to be renamed to a more generic name (because this block can be used on other pages)
  @Input() set heightProperty(value: 'pixel' | 'percent' | 'none') {
    this._heightProperty$.next(value || 'none');
  }
  private readonly _heightProperty$ = new BehaviorSubject<'pixel' | 'percent' | 'none'>('none');
  @Input() set height(value: number) {
    this._height$.next(value);
  }
  private readonly _height$ = new ReplaySubject<number>(1);
  @Input() paddingTop = 'none';
  @Input() paddingBottom = 'small';

  protected readonly heightStyles$ = combineLatest([this._heightProperty$, this._height$]).pipe(
    map(([property, height]) => {
      if (property === 'none') {
        return {};
      }
      return {
        height: `${height}${property === 'percent' ? 'vh' : 'px'}`,
        'object-fit': 'cover',
      };
    }),
  );
}
