<app-modal-filler-atom
  (click)="onClose()"
  [index]="10"
></app-modal-filler-atom>
<div
  class="fixed left-0 right-0 overflow-y-auto shadow md:left-0 md:top-0 md:flex md:h-full md:w-full md:items-center md:justify-center mdMax:bottom-0 mdMax:mx-auto mdMax:max-h-85-screen mdMax:max-w-screen-sm"
  [style.z-index]="60"
>
  <app-modal-filler-atom
    class="hidden md:block"
    [withBackdrop]="false"
    (onClick)="onClose()"
    [index]="10"
  ></app-modal-filler-atom>
  <div
    class="rounded-t-3xl bg-white p-5 md:w-2/5 md:min-w-448 md:max-w-lg md:rounded-3xl md:p-7"
    [style.z-index]="60"
  >
    @if (activeStep === registrationStep.STEP_PHONE) {
      <app-phone-number-step
        [phone]="phone ?? ''"
        (setPhone)="setPhone($event)"
        (goToNextStep)="onGoToOtpStep()"
        (goToAuthenticationStep)="onGoToAuthenticationStep()"
        (goToAccountNotFoundStep)="onGoToAccountNotFoundStep()"
        (goToKioskAccountNotFoundStep)="onGoToKioskAccountNotFoundStep()"
        (onClose)="onClose()"
        [accentColor]="accentColor"
      ></app-phone-number-step>
    }
    @if (activeStep === registrationStep.STEP_OTP) {
      <app-otp-step
        [phone]="phone"
        (onClose)="onClose()"
        (goToPreviousStep)="onGoToPhoneStep()"
        (goToNextStep)="onGoToAuthenticationStep()"
      ></app-otp-step>
    }
    @if (activeStep === registrationStep.STEP_AUTHENTICATION) {
      <app-authentication-step
        (onClose)="onClose()"
        (onComplete)="onComplete()"
        (onKioskConfirm)="onGoToConfirmationStep()"
      ></app-authentication-step>
    }
    @if (activeStep === registrationStep.STEP_KIOSK_CONFIRMATION) {
      <app-kiosk-confirmation-step
        (onComplete)="onComplete()"
        [accentColor]="accentColor"
      ></app-kiosk-confirmation-step>
    }
    @if (activeStep === registrationStep.STEP_KIOSK_REGISTER) {
      <app-kiosk-register-step
        [phone]="phone ?? ''"
        (onClose)="onClose()"
        (setPhone)="setPhone($event)"
        (goToKioskRegisterConfirmationStep)="onGoToKioskRegisterConfirmationStep()"
        [accentColor]="accentColor"
      ></app-kiosk-register-step>
    }
    @if (activeStep === registrationStep.STEP_KIOSK_REGISTER_CONFIRMATION) {
      <app-kiosk-register-confirmation-step
        (onComplete)="onComplete()"
        [accentColor]="accentColor"
      ></app-kiosk-register-confirmation-step>
    }
    @if (activeStep === registrationStep.STEP_KIOSK_ACCOUNT_NOT_FOUND) {
      <app-kiosk-account-not-found-step
        [phone]="phone ?? ''"
        (goToPhoneStep)="onGoToPhoneStep()"
        (goToRegisterStep)="onGoToRegisterStep()"
        (onComplete)="onComplete()"
        [accentColor]="accentColor"
      ></app-kiosk-account-not-found-step>
    }
    @if (activeStep === registrationStep.STEP_ACCOUNT_NOT_FOUND) {
      <app-account-not-found-step
        [phone]="phone"
        (onClose)="onClose()"
        (goToPhoneStep)="onGoToPhoneStep()"
      ></app-account-not-found-step>
    }
  </div>
</div>
