import { createReducer } from '@ngrx/store';

import { ENTITY_STORE_INITIAL_STATE } from './entity.state';
import { ENTITY_STORE_API_PIECE, ENTITY_STORE_LOCAL_PIECE } from './entity.piece';

export const ENTITY_STORE_REDUCER = createReducer(
  ENTITY_STORE_INITIAL_STATE,
  ...ENTITY_STORE_API_PIECE,
  ...ENTITY_STORE_LOCAL_PIECE,
);
