import { EntityState, createEntityAdapter } from '@ngrx/entity';

import { OrderTypeValues } from '@config/values/order.values';
import { DoodApiError } from '@shared/interfaces/error.interface';
import { DoodOnSiteLocationModel } from '@core/models/on-site-location.model';
import { DoodOnSiteLocationSourceValues } from './on-site-location.enum';
import { HydrationState, createHydratedAdapter } from '@store/_abstract/hydrated';

export interface OnSiteLocationSettingsState {
  id: string | null;
  lastLocationId: string | null;
  orderType: OrderTypeValues | null;
  source: DoodOnSiteLocationSourceValues;
}

export interface OnSiteLocationState extends EntityState<DoodOnSiteLocationModel>, HydrationState {
  isLoaded: boolean;
  isLoading: boolean;
  active: string | null;
  error: DoodApiError | null;
  settings: OnSiteLocationSettingsState;
}

export const OSL_STORE_KEY = 'OnSiteLocation';

export const OLS_STORE_SETTINGS_INITIAL_STATE: OnSiteLocationSettingsState = {
  id: null,
  orderType: null,
  lastLocationId: null,
  source: DoodOnSiteLocationSourceValues.fromUser,
};

export const OSL_STORE_HYDRATION_ADAPTER = createHydratedAdapter();

export const OSL_ENTITY_STORE_ADAPTER = createEntityAdapter<DoodOnSiteLocationModel>();

export const OSL_STORE_INITIAL_STATE: OnSiteLocationState =
  OSL_STORE_HYDRATION_ADAPTER.getInitialState(
    OSL_ENTITY_STORE_ADAPTER.getInitialState({
      error: null,
      active: null,
      isLoaded: false,
      isLoading: false,
      settings: OLS_STORE_SETTINGS_INITIAL_STATE,
    }),
  );
