<div class="max-w-screen-sm mx-auto">
  <ng-container *ngIf="shop$ | async as shop; else loading">
    <app-simple-small-hero-atom
      [heading]="shop[shopKeys.Name]"
      [category]="shop[shopKeys.ShortDescription]"
      [description]="shop[shopKeys.Description]"
    ></app-simple-small-hero-atom>
  </ng-container>

  <ng-template #loading>
    <div class="animate-pulse bg-neutral-200 h-40 w-full"></div>
  </ng-template>
</div>
