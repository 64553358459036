import { Component } from '@angular/core';
import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'app-group-payment-page-header-block',
  templateUrl: './group-payment-page-header-block.component.html',
})
export class GroupPaymentPageHeaderBlockComponent {
  constructor(private readonly location: LocationStrategy) {}

  back(): void {
    this.location.back();
  }
}
