<app-modal-filler-atom
  (click)="close()"
  [index]="(index$ | async) ?? 0"
></app-modal-filler-atom>
<div
  class="fixed right-0 left-0 shadow overflow-y-auto mdMax:bottom-0 mdMax:max-h-85-screen mdMax:max-w-screen-sm mdMax:mx-auto md:flex md:justify-center md:items-center md:w-full md:h-full md:top-0 md:left-0"
  [style.z-index]="((index$ | async) ?? 0) + 50"
>
  <app-modal-filler-atom
    *ngIf="isDesktop$ | async"
    [withBackdrop]="false"
    (onClick)="close()"
    [index]="(index$ | async) ?? 0"
  ></app-modal-filler-atom>
  <div
    class="md:min-w-448 bg-white p-5 md:p-7 rounded-t-3xl md:rounded-3xl md:max-w-lg md:w-2/5"
    [style.z-index]="((index$ | async) ?? 0) + 50"
  >
    <app-como-create-or-link-account-modal-attach-with-email-step
      *ngIf="step === 'attach-with-email'"
      (goToStep)="goToStep($event)"
    ></app-como-create-or-link-account-modal-attach-with-email-step>

    <app-como-create-or-link-account-modal-identification-step
      *ngIf="step === 'identification'"
      [comoPlugin]="comoPlugin"
      (goToStep)="goToStep($event)"
      (setPhoneOrEmail)="setPhoneOrEmail($event)"
      (close)="close()"
    ></app-como-create-or-link-account-modal-identification-step>

    <app-como-create-or-link-account-modal-otp-step
      *ngIf="step === 'otp'"
      [phoneOrEmail]="phoneOrEmail"
      (goToStep)="goToStep($event)"
      (close)="close()"
    ></app-como-create-or-link-account-modal-otp-step>

    <app-como-create-or-link-account-modal-registration-step
      *ngIf="step === 'registration'"
      [comoPlugin]="comoPlugin"
      [phoneOrEmail]="phoneOrEmail"
      (goToStep)="goToStep($event)"
      (close)="close()"
    ></app-como-create-or-link-account-modal-registration-step>
  </div>
</div>
