import { createReducer } from '@ngrx/store';

import { MODAL_STORE_INITIAL_STATE } from './modal.state';
import { MODAL_STORE_API_PIECE, MODAL_STORE_LOCAL_PIECE } from './modal.piece';

export const MODAL_STORE_REDUCER = createReducer(
  MODAL_STORE_INITIAL_STATE,
  ...MODAL_STORE_API_PIECE,
  ...MODAL_STORE_LOCAL_PIECE,
);
