import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { DoodModalModel } from '@core/models/modal.model';

export interface ModalState extends EntityState<DoodModalModel> {}

export const MODAL_STORE_KEY = 'Modals';

export const MODAL_STORE_ADAPTER = createEntityAdapter<DoodModalModel>({
  selectId: _modal => _modal.handle,
});

export const MODAL_STORE_INITIAL_STATE: ModalState = MODAL_STORE_ADAPTER.getInitialState();
