<div
  class="md:px-5 lg:px-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 md:gap-4 lg:gap-6 xl:gap-8"
>
  <div *ngFor="let product of products">
    <div
      *ngIf="product"
      class="lg:min-h-176 h-full border-b md:border md:rounded-lg border-neutral-100"
      [style.borderColor]="productBorderColor | contentBuilderColor"
      [style.backgroundColor]="productBackgroundColor | contentBuilderColor"
    >
      <app-product-push-v2-atom
        *ngIf="product"
        [product]="product"
        [displayContext]="displayContext"
        [isOrderingAllowed]="isOrderingAllowed"
        [productNameColor]="productNameColor"
        [productDescriptionColor]="productDescriptionColor"
        [productPriceColor]="productPriceColor"
        [productImagePlaceholderColor]="productImagePlaceholderColor"
        [tagColors]="tagColors"
        (buttonClick)="onNavigateTo(product)"
      ></app-product-push-v2-atom>
    </div>
  </div>
</div>
