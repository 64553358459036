import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-numeric-keypad-atom',
  templateUrl: './numeric-keypad-atom.component.html',
})
export class NumericKeypadAtomComponent {
  @Output() deleteClick = new EventEmitter<void>();
  @Output() numberClick = new EventEmitter<number>();

  onNumberClick(number: number): void {
    this.numberClick.emit(number);
  }

  onDeleteClick(): void {
    this.deleteClick.emit();
  }

  forceType(data: any): any {
    return data as any;
  }
}
