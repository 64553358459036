import { IContentBuilderFieldColor } from './content-builder-fields.model';
// ! report the change to the dashboard project src/app/models/marketplace.model.ts

export type EditableColor = { color: IContentBuilderFieldColor; label: string };

type SubGroupColors<SubGroupKey extends string> = {
  readonly [key in SubGroupKey]: Readonly<EditableColor>[];
};

export const ProductLabelsTagColors: SubGroupColors<'tag'> = {
  tag: [
    {
      label: 'colors.product-labels.background-color-of-tags',
      color: {},
    },
    {
      label: 'colors.product-labels.text-color-of-tags',
      color: {},
    },
  ],
};

export const ProductLabelsFilterColors: SubGroupColors<'filter'> = {
  filter: [
    {
      label: 'colors.product-labels.background-color-of-filter-banner',
      color: {
        type: 'palette',
        value: 'neutral-100',
      },
    },
    {
      label: 'colors.product-labels.background-color-of-filters',
      color: {
        type: 'palette',
        value: 'neutral-300',
      },
    },
    {
      label: 'colors.product-labels.text-color-of-filters',
      color: {
        type: 'palette',
        value: 'neutral-800',
      },
    },
    {
      label: 'colors.product-labels.background-color-of-active-filters',
      color: {
        type: 'palette',
        value: 'primary-600',
      },
    },
    {
      label: 'colors.product-labels.text-color-of-active-filters',
      color: {
        type: 'palette',
        value: 'neutral-50',
      },
    },
  ],
};

export const ProductLabelsColors = {
  'products-labels': {
    ...ProductLabelsTagColors,
    ...ProductLabelsFilterColors,
  },
} as const;

export type ProductLabelsColors = (typeof ProductLabelsColors)['products-labels'];

export const ColorsGroup = {
  ...ProductLabelsColors,
} as const;

export type ColorsGroup = typeof ColorsGroup;

export type ColorsGroupKey = keyof typeof ColorsGroup;

export type SubGroupKeys<T extends ColorsGroupKey> = keyof ColorsGroup[T];
