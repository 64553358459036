export enum OrderKeys {
  Id = 'id',
  Shop = 'shop',
  Message = 'message',
  OnSiteLocationId = 'onsite_location_id',
  RegisterUserToComo = 'register_user_to_como',
  PaymentService = 'payment_service',
  User = 'user',
  Type = 'type',
  WhiteLabel = 'white_label',
  Marketplace = 'marketplace',
  MultiShop = 'multi_shop',
  CustomerDevice = 'customer_device',
  AppPlatform = 'app_platform',
  WantedAt = 'wanted_at',
  Products = 'products',
  Coupons = 'coupons',
  AvailablePaymentMethods = 'available_payment_methods',
  CartCreatedAt = 'cart_created_at',
  CartId = 'cart_id',
  Cart = 'cart',
  ContactPhoneNumber = 'contact_phone_number',
  CouponsAmountOff = 'coupons_amount_off',
  ChildOrders = 'child_orders',
  CreatedAt = 'created_at',
  DeliveryAddress = 'delivery_address',
  DeliveryComment = 'delivery_comment',
  DeliveryDistance = 'delivery_distance',
  DeliveryParticipationAmount = 'delivery_participation_amount',
  DeliveryPriceAmount = 'delivery_price_amount',
  DeliveryPriceCurrency = 'delivery_price_currency',
  DeliveryProvider = 'delivery_provider',
  DeliveryTax = 'delivery_tax',
  EstimatedDeliveryDuration = 'estimated_delivery_duration',
  DiscountedPriceExcTax = 'discounted_price_exc_tax',
  DiscountedPriceIncTax = 'discounted_price_inc_tax',
  DiscountedTax = 'discounted_tax',
  EstimatedTimeOfArrival = 'estimated_time_of_arrival',
  FinalPrice = 'final_price',
  Currency = 'currency',
  LineItems = 'line_items',
  MarketPlace = 'marketplace',
  Number = 'number',
  PreparationTime = 'preparation_time',
  ReadyAt = 'ready_at',
  RegularPriceExcTax = 'regular_price_exc_tax',
  RegularPriceIncTax = 'regular_price_inc_tax',
  RegularTax = 'regular_tax',
  Status = 'status',
  Tip = 'tip',
  Total = 'total',
  Validate = 'valdiate',
  ParentOrderId = 'parent_order_id',
  UsePoints = 'use_points',
  Venue = 'venue',
  PickupLocation = 'pickup_location',
  Kiosk = 'kiosk',
  Metafields = 'metafields',
  PaymentPhoneNumber = 'payment_phone_number',
  CancellationReason = 'cancellation_reason',
  OnBehalfOf = 'on_behalf_of',
  PaidAmount = 'paid_amount',
  Instructions = 'instructions',
}
