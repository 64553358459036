import { some } from 'lodash';
import { Subject } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { takeUntil, tap } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { ComoUser } from '@core/models/como.model';

import { ComoService } from '@core/services/como/como.service';
import { CartStoreDispatcher } from '@common/dispatchers/cart.dispatcher';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

@Component({
  selector: 'app-como-join-loyalty-block',
  templateUrl: './como-join-loyalty-block.component.html',
})
export class ComoJoinLoyaltyBlockComponent implements OnInit, OnDestroy {
  public registerUserToComo: UntypedFormControl;
  public comoUser?: ComoUser | null;
  public isLoading = true;

  private readonly destroyed$ = new Subject<boolean>();

  constructor(
    private readonly comoService: ComoService,
    private cartDispatcher: CartStoreDispatcher,
    private marketplaceSelector: MarketplaceStoreSelector,
  ) {
    this.registerUserToComo = new UntypedFormControl();
    this.registerUserToComo.valueChanges.subscribe(val => {
      this.cartDispatcher.updateActive({
        register_user_to_como: val,
      });
    });
  }

  ngOnInit(): void {
    const marketplace = this.marketplaceSelector.marketplace;
    const comoPluginEnabled = some(marketplace.plugins || [], plugin => {
      return plugin.type === 'como' && plugin.plugin_enabled;
    });

    if (comoPluginEnabled) {
      this.load();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private load(): void {
    this.comoService.loadComoUser();
    this.comoService
      .getComoUser$()
      .pipe(
        takeUntil(this.destroyed$),
        tap(comoUser => {
          this.comoUser = comoUser;
          this.isLoading = false;
        }),
      )
      .subscribe();
  }
}
