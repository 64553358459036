import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DoodDeliveryAddressModel } from '@core/models/delivery-address.model';
import { DOOD_API } from '@config/ws.config';

@Injectable({
  providedIn: 'root',
})
export class DeliveryAddressApiService {
  constructor(private httpClient: HttpClient) {}

  getDeliveryAddresses$(): Observable<DoodDeliveryAddressModel[]> {
    return this.httpClient.get<DoodDeliveryAddressModel[]>(`${DOOD_API}/delivery-addresses`);
  }

  addDeliveryAddress$(
    data: Partial<DoodDeliveryAddressModel>,
  ): Observable<DoodDeliveryAddressModel> {
    return this.httpClient.post<DoodDeliveryAddressModel>(`${DOOD_API}/delivery-addresses`, data);
  }

  updateDeliveryAddress$(data: DoodDeliveryAddressModel): Observable<DoodDeliveryAddressModel> {
    return this.httpClient.patch<DoodDeliveryAddressModel>(
      `${DOOD_API}/delivery-addresses/${data.id}`,
      data,
    );
  }

  deleteDeliveryAddress$(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${DOOD_API}/delivery-addresses/${id}`);
  }
}
