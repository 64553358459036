import { Component, inject, OnDestroy } from '@angular/core';
import { PaymentStoreDispatcher } from '@common/dispatchers/payment.dispatcher';
import { StripeErrors } from '@config/errors.config';
import { PaymentService } from '@core/services/payment/payment.service';
import { TranslateService } from '@ngx-translate/core';
import { StripeError } from '@stripe/stripe-js';
import { StripeService } from 'ngx-stripe';
import {
  catchError,
  combineLatest,
  EMPTY,
  from,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';

@Component({
  selector: 'app-pat-payment-panel-stripe-twint',
  template: ``,
})
export class PatPaymentPanelStripeTwintComponent implements OnDestroy {
  private readonly paymentService = inject(PaymentService);
  private readonly stripeService = inject(StripeService);
  private readonly paymentStoreDispatcher = inject(PaymentStoreDispatcher);
  private readonly translateService = inject(TranslateService);

  private readonly destroy$ = new Subject<void>();

  private readonly payWithTwint$ = this.paymentService.pay$.pipe(
    tap(() =>
      this.paymentStoreDispatcher.updateUI({
        error: null,
        button: {
          isEnabled: false,
        },
        status: {
          isLoading: false,
          isInProgress: false,
        },
      }),
    ),
    switchMap(() =>
      combineLatest({
        paymentIntent: this.paymentService.paymentIntent$,
        stripe: this.stripeService.stripe.stripe,
        redirectUrl: this.paymentService.redirectUrl$,
      }),
    ),
    switchMap(({ paymentIntent, stripe, redirectUrl }) => {
      const payment_intent_client_secret = paymentIntent?.payment_intent_client_secret;
      if (!payment_intent_client_secret) {
        return EMPTY;
      }
      return from(
        stripe.confirmTwintPayment(payment_intent_client_secret, {
          return_url: redirectUrl ?? window.location.href,
          payment_method: {}, // ! this field is mandatory, even if it is empty
        }),
      );
    }),
    tap({
      next: result => {
        if (result.error || !result.paymentIntent) {
          this.handlePaymentError(result.error);
          return;
        }
      },
      error: error => {
        this.handlePaymentError(error);
      },
    }),
    catchError(error => {
      // avoid to break the stream
      return of(null);
    }),
  );

  private handlePaymentError(error?: StripeError): void {
    const isConnectionError = error?.type === StripeErrors.ApiConnectionError;
    const errorMessage = error?.message ?? this.translateService.instant('payment.unknown-error');

    this.paymentStoreDispatcher.updateUI({
      error: errorMessage,
      button: {
        isEnabled: isConnectionError,
      },
      status: {
        isLoading: false,
        isInProgress: false,
        isRetryInProgress: isConnectionError,
      },
    });
  }

  constructor() {
    this.payWithTwint$.pipe(takeUntil(this.destroy$)).subscribe();
  }

  ngOnDestroy(): void {
    this.paymentStoreDispatcher.resetUI();
    this.paymentService.paymentIntent$.next(null);

    this.destroy$.next();
    this.destroy$.complete();
  }
}
