<app-modal-filler-atom
  (click)="close()"
  [index]="5"
></app-modal-filler-atom>
<div
  class="lg:left-0' fixed bottom-0 left-0 right-0 mx-auto max-h-85-screen max-w-screen-sm overflow-y-auto shadow lg:top-0 lg:flex lg:h-full lg:w-full lg:items-center lg:justify-center"
  [style.z-index]="750"
>
  <div class="rounded-t-3xl bg-white p-5 pt-10 md:p-7 lg:rounded-3xl">
    <app-block-list
      [blockIdList]="content"
      [injectData]="getData()"
    ></app-block-list>
  </div>
</div>
