<div
  class="w-100 flex justify-between rounded-s items-center px-4 py-4 bg-center bg-cover bg-no-repeat font-brand"
  [ngStyle]="{ backgroundImage: 'url(' + backgroundImage + ')' }"
  [ngClass]="backgroundColor"
>
  <div class="text-white">
    <p
      [ngClass]="{
        'text-sm font-base': headingSize === 'S',
        'text-3xl ': headingSize === 'M',
        'text-4xl font-brand': headingSize === 'L',
      }"
    >
      {{ topText }}
    </p>
    <p
      [ngClass]="{
        'text-3xl font-brand pr-3': headingSize === 'S',
        'text-sm font-base normal-case pr-3': headingSize === 'M' || headingSize === 'L',
      }"
    >
      {{ bottomText }}
    </p>
  </div>
  <div>
    <app-next-button-atom (buttonClick)="buttonClick.emit($event)"></app-next-button-atom>
  </div>
</div>
