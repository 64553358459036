import { createFeature, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';

import { DoodProductModel } from '@core/models/product.model';

import { PRODUCT_STORE_REDUCER } from './product.reducer';
import { PRODUCT_STORE_KEY, PRODUCT_STORE_ACTIVE_ADAPTER, ProductState } from './product.state';

const PRODUCT_STORE_FEATURE = createFeature({
  name: PRODUCT_STORE_KEY,
  reducer: PRODUCT_STORE_REDUCER,
});

const { selectProductsState: SELECT_PRODUCT_STATE } = PRODUCT_STORE_FEATURE;

export const {
  selectIds: SELECT_PRODUCT_IDS,
  selectError: SELECT_PRODUCT_ERROR,
  selectActive: SELECT_PRODUCT_ACTIVE_ID,
  selectEntities: SELECT_PRODUCT_ENTITIES,
  selectIsLoaded: SELECT_PRODUCT_IS_LOADED,
  selectIsLoading: SELECT_PRODUCT_IS_LOADING,
} = PRODUCT_STORE_FEATURE;

export const { selectAll: SELECT_PRODUCT_ALL } =
  PRODUCT_STORE_ACTIVE_ADAPTER.getSelectors(SELECT_PRODUCT_STATE);

export const SELECT_PRODUCT_ENTITY = (
  id: string,
): MemoizedSelector<
  Record<string, any>,
  DoodProductModel | undefined,
  DefaultProjectorFn<DoodProductModel | undefined>
> =>
  createSelector(SELECT_PRODUCT_STATE, (state: ProductState) => {
    return state.entities[id];
  });

export const SELECT_PRODUCT_ACTIVE = createSelector(
  SELECT_PRODUCT_ENTITIES,
  SELECT_PRODUCT_ACTIVE_ID,
  (entities, id) => (id ? (entities[id] ?? null) : null),
);

export const SELECT_PRODUCT_ACTIVE_CATEGORY = createSelector(
  SELECT_PRODUCT_STATE,
  (state: ProductState) => {
    return state.activeCategory;
  },
);
