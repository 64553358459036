import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-switch-atom',
  templateUrl: './toggle-switch-atom.component.html',
})
export class ToggleSwitchAtomComponent {
  @Input() checked: boolean = false;
  @Input() label?: string;

  @Output() valueChanged = new EventEmitter<boolean>();

  constructor() {}

  toggleValue(): void {
    this.checked = !this.checked;
    this.valueChanged.emit(this.checked);
  }
}
