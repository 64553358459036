import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import {
  DELIVERY_STORE_ACTIVE_ACTIONS,
  DELIVERY_STORE_LOCAL_ACTIONS,
} from '@store/delivery/delivery.action';
import { DoodDeliveryAddressModel } from '@core/models/delivery-address.model';
import { DoodApiError } from '@shared/interfaces/error.interface';

@Injectable({ providedIn: 'root' })
export class DeliveryStoreDispatcher {
  constructor(private store: Store) {}

  // All
  setAll(entities: DoodDeliveryAddressModel[]): void {
    this.store.dispatch(DELIVERY_STORE_ACTIVE_ACTIONS.setAll({ entities }));
  }

  // Many

  // One
  upsertOne(entity: DoodDeliveryAddressModel): void {
    this.store.dispatch(DELIVERY_STORE_ACTIVE_ACTIONS.upsertOne({ entity }));
  }

  // Active
  updateActive(active: string | null): void {
    this.store.dispatch(DELIVERY_STORE_ACTIVE_ACTIONS.setActive({ active }));
  }

  // Status
  updateIsLoading(isLoading: boolean): void {
    this.store.dispatch(DELIVERY_STORE_LOCAL_ACTIONS.updateIsLoading({ isLoading }));
  }

  // Error
  updateError(error: DoodApiError | null): void {
    this.store.dispatch(DELIVERY_STORE_LOCAL_ACTIONS.updateError({ error }));
  }

  reset(): void {
    this.store.dispatch(DELIVERY_STORE_LOCAL_ACTIONS.reset());
  }
}
