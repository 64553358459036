<div class="px-5 pb-60 max-w-screen-sm mx-auto md:max-w-full">
  <h1 class="py-6 font-accent font-bold text-2xl">
    {{ 'orders.current-orders' | translate }}
  </h1>

  <div
    *ngIf="!activeOrdersLoading; else loading"
    class="md:grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6"
  >
    <ng-container *ngIf="activeOrders.length; else noActieOrders">
      <div *ngFor="let order of activeOrders">
        <app-order-card
          [order]="order"
          (cardClick)="goToOrderDetails($event.id)"
        ></app-order-card>
      </div>
    </ng-container>
    <ng-template #noActieOrders>
      <div class="text-center text-neutral-500 text-sm ng-star-inserted py-2">
        {{ 'orders.no-current-orders' | translate }}
      </div>
    </ng-template>
  </div>

  <h1 class="py-6 font-accent font-bold text-2xl">
    {{ 'orders.old-orders' | translate }}
  </h1>

  <div
    *ngIf="!historicalOrdersLoading; else loading"
    class="md:grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6"
  >
    <div *ngFor="let order of historicalOrders">
      <app-order-card
        [order]="order"
        (cardClick)="goToOrderDetails($event.id)"
      ></app-order-card>
    </div>
  </div>

  <ng-container
    *ngIf="
      !activeOrdersLoading &&
      !historicalOrdersLoading &&
      !activeOrders?.length &&
      !historicalOrders?.length
    "
  >
    <div class="text-center text-neutral-500 text-sm ng-star-inserted py-10 pb-80">
      {{ 'orders.no-orders' | translate }}
    </div>
  </ng-container>

  <ng-template #loading>
    <div class="mb-8xs">
      <ng-container *ngFor="let i of [].constructor(20)">
        <app-order-card-skeleton></app-order-card-skeleton>
      </ng-container>
    </div>
  </ng-template>
</div>
