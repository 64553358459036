import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { ShopStoreSelector } from '@common/selectors/shop.selector';
import { ShopStoreDispatcher } from '@common/dispatchers/shop.dispatcher';

import { DateUtils } from '@shared/utils/date/date.utils';
import {
  IContentBuilderFieldColor,
  IContentBuilderFieldImage,
} from '@core/models/content-builder-fields.model';

import { HeaderService } from '@core/services/header/header.service';
import { NavigationHistoryService } from '@core/services/navigation-history/navigation-history.service';
import { ShopSearchParametersService } from '@core/services/shop-search-parameters/shop-search-parameters.service';

@Component({
  selector: 'app-shop-search-page',
  templateUrl: './shop-search-page.component.html',
})
export class ShopSearchPageComponent implements OnInit, OnDestroy {
  @Input() body: any;
  @Input() defaultLatitude?: number;
  @Input() defaultLongitude?: number;
  @Input() defaultMaxDistance?: number;
  @Input() backgroundColor?: IContentBuilderFieldColor;
  @Input() backgroundImage?: IContentBuilderFieldImage;
  @Input() metaDescription?: string;

  constructor(
    private readonly meta: Meta,
    private shopSelector: ShopStoreSelector,
    private shopDispatcher: ShopStoreDispatcher,
    private readonly headerService: HeaderService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly navigationHistoryService: NavigationHistoryService,
    private readonly shopSearchParametersService: ShopSearchParametersService,
  ) {}

  ngOnInit(): void {
    const shopActive = this.shopSelector.active;

    if (shopActive) {
      this.shopDispatcher.removeOne(shopActive.id);
    }

    this.headerService.toggleShopSearchParametersVisibility(true);

    this.shopSearchParametersService.setDefault({
      latitude: this.defaultLatitude,
      longitude: this.defaultLongitude,
      maxDistance: this.defaultMaxDistance,
    });

    if (this.metaDescription) {
      this.meta.updateTag({
        name: 'description',
        content: this.metaDescription,
      });
    }

    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams.latitude && queryParams.longitude) {
        this.shopSearchParametersService.set({
          location: {
            address: queryParams.address,
            lat: queryParams.latitude,
            lng: queryParams.longitude,
          },
        });
      }
      if (queryParams.distance) {
        this.shopSearchParametersService.set({
          distance: queryParams.distance,
        });
      }
      if (queryParams.distribution_mode) {
        this.shopSearchParametersService.set({
          distribution_mode: queryParams.distribution_mode,
        });
      }
      if (queryParams.wanted_at_type) {
        this.shopSearchParametersService.set({
          wanted_at: new Date(DateUtils.dayjsInMarketplaceTimezone(queryParams.wanted_at).format()),
        });
      }

      const urlQueryParams = new URLSearchParams(queryParams).toString();
      this.navigationHistoryService.pushState(
        'shop-search',
        '/' + this.activatedRoute.snapshot.url.join('/') + '?' + urlQueryParams,
      );
    });
  }

  ngOnDestroy(): void {
    this.headerService.toggleShopSearchParametersVisibility(false);
  }

  getBackgroundStyles() {
    return {
      'background-color': this.backgroundColor ? this.backgroundColor : null,
      'background-image': this.backgroundImage ? `url(${this.backgroundImage.url})` : null,
      'background-size': this.backgroundImage ? 'cover' : null,
    };
  }
}
