import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';
import { DeliveryStoreSelector } from '@common/selectors/delivery.selector';
import { DoodDeliveryAddressModel } from '@core/models/delivery-address.model';
import { tap } from 'rxjs';

@Component({
  selector: 'app-parameters-delivery-instructions-block',
  template: `<p class="mb-2 text-sm font-bold text-center font-accent">
      {{ 'parameters.delivery-instructions.title' | translate }}
    </p>
    <div class="placeholder:text-neutral-500 text-neutral-800 leading-5 pb-6">
      <textarea
        [(ngModel)]="instructions"
        class="w-full border bg-neutral-50 border-neutral-300 rounded py-2.5 px-4 resize-none"
        (ngModelChange)="this.instructionsChanged.emit(instructions)"
        [placeholder]="'parameters.delivery-instructions.placeholder' | translate"
      ></textarea>
    </div>`,
})
export class ParametersDeliveryInstructionsBlockComponent {
  @Input() instructions!: Partial<DoodDeliveryAddressModel>;

  @Output() instructionsChanged = new EventEmitter<Partial<DoodDeliveryAddressModel>>();

  addressLoading = false;

  constructor(
    private readonly deliverySelector: DeliveryStoreSelector,
    private readonly authSelector: AuthStoreSelector,
  ) {}

  ngOnInit() {
    const firebaseToken = this.authSelector.status.token;

    if (firebaseToken) {
      this.addressLoading = true;
      this.deliverySelector.selectActive
        .pipe(
          tap(newLocation => {
            if (!newLocation) {
              return;
            }
          }),
        )
        .subscribe();
    }
  }
}
