import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import { DoodOnSiteLocationModel } from '@core/models/on-site-location.model';
import { OSL_STORE_LOCAL_ACTIONS } from '@store/on-site-location/on-site-location.action';
import { OnSiteLocationSettingsState } from '@store/on-site-location/on-site-location.state';

@Injectable({ providedIn: 'root' })
export class OnSiteLocationStoreDispatcher {
  constructor(private store: Store) {}

  updateActive(active: string | null): void {
    this.store.dispatch(OSL_STORE_LOCAL_ACTIONS.updateActive({ active }));
  }

  updateSettings(settings: Partial<OnSiteLocationSettingsState>): void {
    this.store.dispatch(OSL_STORE_LOCAL_ACTIONS.updateSettings({ settings }));
  }

  setLocations(locations: DoodOnSiteLocationModel[]): void {
    this.store.dispatch(OSL_STORE_LOCAL_ACTIONS.setLocations({ locations }));
  }

  reset(): void {
    this.store.dispatch(OSL_STORE_LOCAL_ACTIONS.reset());
  }
}
