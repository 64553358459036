import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  public isTrackingAccepted(): boolean {
    const cookies = this.getCookies();
    if (!Array.isArray(cookies)) {
      // Handle the case where cookies are not an array
      console.error('Cookies are not in the expected format', cookies);
      return false;
    }

    let isTrackingAccepted = false;
    cookies.forEach((cookie: { name: string; value: any }) => {
      if (
        cookie.name === 'cookie_consent_level' &&
        typeof cookie.value === 'object' &&
        cookie.value !== null &&
        'tracking' in cookie.value
      ) {
        isTrackingAccepted = cookie.value.tracking;
        return;
      }
    });

    return isTrackingAccepted;
  }

  public getCookies(): { name: string; value: string | object }[] {
    const cookies: { name: string; value: string | object }[] = [];

    document.cookie.split(';').forEach(cookie => {
      // tslint:disable-next-line:prefer-const
      let [name, value] = cookie.split('=').map(c => c.trim());
      if (value && value.includes('%2C%22')) {
        value = JSON.parse(decodeURIComponent(value));
      }

      cookies.push({ name, value });
    });

    return cookies;
  }
}
