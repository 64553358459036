import { DefaultProjectorFn, MemoizedSelector, createFeature, createSelector } from '@ngrx/store';

import { DoodCartModel } from '@core/models/cart.model';

import { CART_STORE_REDUCER } from './cart.reducer';
import { CART_STORE_ACTIVE_ADAPTER, CART_STORE_KEY, CartState } from './cart.state';

const CART_STORE_FEATURE = createFeature({
  name: CART_STORE_KEY,
  reducer: CART_STORE_REDUCER,
});

export const {
  selectComment: SELECT_CART_COMMENT,
  selectCartState: SELECT_CART_STATE,
  selectActive: SELECT_CART_ACTIVE_ID,
  selectEntities: SELECT_CART_ENTITIES,
  selectEdit_item: SELECT_CART_EDIT_ITEM,
  selectLast_update: SELECT_CART_LAST_UPDATE,
} = CART_STORE_FEATURE;

export const { selectAll: SELECT_CART_ALL, selectTotal: SELECT_CART_TOTAL } =
  CART_STORE_ACTIVE_ADAPTER.getSelectors(SELECT_CART_STATE);

export const SELECT_CART_ENTITY = (
  id: string,
): MemoizedSelector<
  Record<string, any>,
  DoodCartModel | undefined,
  DefaultProjectorFn<DoodCartModel | undefined>
> =>
  createSelector(SELECT_CART_STATE, (state: CartState) => {
    return state.entities[id];
  });

export const SELECT_CART_ACTIVE = createSelector(
  SELECT_CART_ENTITIES,
  SELECT_CART_ACTIVE_ID,
  (entities, id) => (id ? (entities[id] ?? null) : null),
);
