export const PREFERRED_LANGUAGES = [
  {
    code: 'eng',
    name: 'English',
    countryCode: 'GB', // United Kingdom
    ietfCode: 'en-GB',
  },
  {
    code: 'zho',
    name: '中文',
    countryCode: 'CN', // China
    ietfCode: 'zh-CN',
  },
  {
    code: 'spa',
    name: 'Español',
    countryCode: 'ES', // Spain
    ietfCode: 'es-ES',
  },
  {
    code: 'hin',
    name: 'हिन्दी',
    countryCode: 'IN', // India
    ietfCode: 'hi-IN',
  },
  {
    code: 'ara',
    name: 'العربية',
    countryCode: 'SA', // Saudi Arabia
    ietfCode: 'ar-SA',
  },
  {
    code: 'por',
    name: 'Português',
    countryCode: 'PT', // Portugal
    ietfCode: 'pt-PT',
  },
  {
    code: 'rus',
    name: 'Русский',
    countryCode: 'RU', // Russia
    ietfCode: 'ru-RU',
  },
  {
    code: 'jpn',
    name: '日本語',
    countryCode: 'JP', // Japan
    ietfCode: 'ja-JP',
  },
  {
    code: 'deu',
    name: 'Deutsch',
    countryCode: 'DE', // Germany
    ietfCode: 'de-DE',
  },
  {
    code: 'fra',
    name: 'Français',
    countryCode: 'FR', // France
    ietfCode: 'fr-FR',
  },
  {
    code: 'ita',
    name: 'Italiano',
    countryCode: 'IT', // Italy
    ietfCode: 'it-IT',
  },
  {
    code: 'tur',
    name: 'Türkçe',
    countryCode: 'TR', // Turkey
    ietfCode: 'tr-TR',
  },
  {
    code: 'nld',
    name: 'Nederlands',
    countryCode: 'NL', // Netherlands
    ietfCode: 'nl-NL',
  },
  {
    code: 'pol',
    name: 'Polski',
    countryCode: 'PL', // Poland
    ietfCode: 'pl-PL',
  },
];
