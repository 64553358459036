import { Component, OnInit } from '@angular/core';
import { CartFunnelStoreDispatcher } from '@common/dispatchers/cart-funnel.dispatcher';

@Component({
  template: ``,
  selector: 'app-reset-cart-funnel-block',
})
export class ResetCartFunnelBlockComponent implements OnInit {
  constructor(private cartFunnelDispatcher: CartFunnelStoreDispatcher) {}

  ngOnInit(): void {
    this.cartFunnelDispatcher.reset();
  }
}
