<div
  *ngIf="prefix"
  class="flex items-center relative"
>
  <div class="prefix absolute left-0 pl-5">{{ symbol }}</div>
  <input
    #number
    [(ngModel)]="value"
    type="number"
    id="amount"
    class="w-full border border-neutral-800 rounded py-4.5 px-5 pl-8 lg:w-448px font-base"
    [max]="99"
    [min]="0"
    [maxlength]="2"
    (ngModelChange)="valueEmitter.emit(value)"
    [class.border-error-700]="error"
  />
</div>
<div
  *ngIf="suffix"
  class="flex items-center relative"
>
  <input
    #number
    [(ngModel)]="value"
    type="number"
    id="weight"
    class="w-full border border-neutral-800 rounded py-4.5 px-5 pr-8 lg:w-448px text-right font-base"
    [max]="99"
    [min]="0"
    [maxlength]="2"
    (ngModelChange)="valueEmitter.emit(value)"
    [class.border-error-700]="error"
  />
  <div class="suffix absolute right-0 pr-5">{{ symbol }}</div>
</div>
