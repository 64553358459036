import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DoodShopModel } from '@core/models/shop.model';
import { Observable } from 'rxjs';
import { DOOD_API } from '@config/ws.config';

@Injectable({
  providedIn: 'root',
})
export class ShopsApiService {
  constructor(private client: HttpClient) {}

  public getShopBySlug$(
    shopSlug: string,
    marketplaceId: string,
    orderType?: string,
    wantedAt?: Date | null,
  ): Observable<DoodShopModel> {
    if (!wantedAt) {
      wantedAt = new Date();
    }

    wantedAt = new Date(wantedAt.getTime());

    // Round to next minute to allow request caching
    wantedAt.setMinutes(wantedAt.getMinutes() + 1, 0, 0);

    const queryParams: any = {
      marketplace: marketplaceId,
      wanted_at: wantedAt.toISOString(),
    };

    if (orderType) {
      queryParams.order_type = orderType;
    }

    const queryParamsString = new URLSearchParams(queryParams).toString();
    return this.client.get<DoodShopModel>(
      `${DOOD_API}/shops/slug/${shopSlug}?${queryParamsString}`,
    );
  }

  public getShopById$(
    shopId: string,
    marketplaceId: string,
    orderType?: string,
    wantedAt?: Date | null,
  ): Observable<DoodShopModel> {
    if (!wantedAt) {
      wantedAt = new Date();
    }

    wantedAt = new Date(wantedAt.getTime());

    // Round to next minute to allow request caching
    wantedAt.setMinutes(wantedAt.getMinutes() + 1, 0, 0);

    const queryParams: any = {
      marketplace: marketplaceId,
      wanted_at: wantedAt.toISOString(),
    };

    if (orderType) {
      queryParams.order_type = orderType;
    }
    const queryParamsString = new URLSearchParams(queryParams).toString();
    return this.client.get<DoodShopModel>(`${DOOD_API}/shops/${shopId}?${queryParamsString}`);
  }

  public getOpeningDates$(shopId: string, deliveryModeType: string): Observable<string[]> {
    const queryParams: { order_type: string } = {
      order_type: deliveryModeType,
    };

    const queryParamsString = new URLSearchParams(queryParams).toString();
    return this.client.get<string[]>(
      `${DOOD_API}/shops/${shopId}/opening-dates?${queryParamsString}`,
    );
  }
}
