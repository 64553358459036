<ng-container *ngIf="isVisible$ | async">
  <app-push-shop-card-v2-atom
    [entity]="$any(entity)"
    [title]="$any(entityName ? entityName : entity?.[entityKeys.Name])"
    [category]="
      $any(entityCategory ? entityCategory : entity?.[entityKeys.Type]?.[entityKeys.Name])
    "
    [shortDescription]="$any(entity?.[entityKeys.ShortDescription])"
    [imgUrl]="$any(image ? image.url : shopImage)"
    [linkUrl]="entitiesService.getUrl($any(entity))"
    [displayEntityImage]="displayEntityImage"
    [displayEntityDistance]="displayEntityDistance"
    [displayEntityPricing]="displayEntityPricing"
    [displayEntityAddress]="displayEntityAddress"
    [displayEntityCategory]="displayEntityCategory"
    [entityNameSize]="entityNameSize"
    [kioskCardAspectRatio]="kioskCardAspectRatio"
    [templateLayout]="templateLayout"
  ></app-push-shop-card-v2-atom>
</ng-container>
