import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';

export interface IPushTextButtonV2 {
  heading: string;
  text?: string;
  buttonLabel?: string;
  buttonLink?: string;
  backgroundColor?: IContentBuilderFieldColor;
  imgUrl?: string;
}

@Component({
  selector: 'app-push-text-button-v2-atom',
  templateUrl: './push-text-button-v2-atom.component.html',
})
export class PushTextButtonV2AtomComponent {
  @Input() text?: string;
  @Input() heading?: string;
  @Input() imageUrl?: string;
  @Input() buttonLabel?: string;
  @Input() backgroundColor?: IContentBuilderFieldColor;

  @Output() buttonClick = new EventEmitter<Event>();
}
