<div
  class="bg-neutral-200"
  [id]="id"
>
  <app-product-card-list-atom
    displayContext="marketplace_page"
    [heading]="title"
    [products]="products"
    (productClick)="onNavigateTo($event)"
  ></app-product-card-list-atom>
</div>
