import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { InAppBrowser, InAppBrowserObject } from '@awesome-cordova-plugins/in-app-browser/ngx';

import { environment } from '@env/environment';

import { Paths } from '@config/paths.config';
import { InAppBrowserSetting } from '@config/in-app-browser.config';

import { DoodOrderModel } from '@store/order/order.model';
import { IPaygreenPaymentIntent } from '@core/models/payment.model';

import { OrderStoreDispatcher } from '@common/dispatchers/order.dispatcher';
import { PaymentStoreDispatcher } from '@common/dispatchers/payment.dispatcher';

import { NativeService } from '@core/services/native/native.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { PaymentService } from '@core/services/payment/payment.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';

@Component({
  template: ``,
  selector: 'app-payment-panel-paygreen-generic',
})
export class PaymentPanelPaygreenGenericComponent implements OnInit, OnDestroy {
  @Input() type!: string;

  protected readonly unsubscribe$ = new Subject<boolean>();

  private browser!: InAppBrowserObject;

  order: DoodOrderModel | null = null;

  constructor(
    private iab: InAppBrowser,
    private readonly router: Router,
    private orderDispatcher: OrderStoreDispatcher,
    private readonly ordersService: OrdersService,
    private readonly paymentService: PaymentService,
    private paymentDispatcher: PaymentStoreDispatcher,
    private readonly routerHelper: RouterHelperService,
    private readonly translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.paymentDispatcher.updateUI({
      button: {
        isEnabled: false,
      },
      status: {
        isLoading: true,
      },
    });

    this.ordersService
      .createOrder$(this.type)
      .pipe(
        tap(order => (this.order = order)),
        switchMap(order => (order ? this.getPaymentPageUrl(order) : of(order))),
        take(1),
        tap(() => this.orderDispatcher.resetErrors()),
        catchError(error => {
          this.orderDispatcher.resetValidation();
          this.orderDispatcher.updateErrors({ error });
          return throwError(() => error);
        }),
      )
      .subscribe(
        paymentPageUrl => {
          this.paymentDispatcher.updateUI({
            button: {
              isEnabled: true,
            },
            status: {
              isLoading: false,
            },
          });

          if (paymentPageUrl) {
            this.paymentService.pay$
              .pipe(take(1))
              .subscribe(() => this.handlePayment(paymentPageUrl));
          }
        },
        _ => {
          this.translateService
            .get(['payment.payment-error'])
            .pipe(take(1))
            .subscribe(translation => {
              this.paymentDispatcher.updateUI({
                error: translation['payment.payment-error'],
                button: {
                  isEnabled: true,
                },
                status: {
                  isLoading: false,
                  isInProgress: false,
                },
              });
            });
        },
      );
  }

  ngOnDestroy(): void {
    this.paymentDispatcher.resetUI();
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  private handlePayment(paymentPageUrl: string): void {
    this.paymentDispatcher.updateStatus({ isInProgress: true });

    if (Capacitor.isNativePlatform() && paymentPageUrl) {
      this.browser = this.iab.create(
        paymentPageUrl,
        '_blank',
        NativeService.platform === 'ios'
          ? InAppBrowserSetting.iosOptions
          : InAppBrowserSetting.androidOptions,
      );

      this.browser.on('loadstop').subscribe(event => {
        if (event.url.startsWith(environment.native.marketplaceDomain) && this.order) {
          this.browser.close();
          this.router.navigate([
            this.routerHelper.translateRoute(`${Paths.Orders}/${this.order.id}/${Paths.Status}`),
          ]);
        }
      });
    } else {
      window.location.href = paymentPageUrl;
    }
  }

  private getPaymentPageUrl(order: DoodOrderModel): Observable<string> {
    return this.paymentService
      .createOrderPaymentIntent$<IPaygreenPaymentIntent>(order.id)
      .pipe(map(result => result.payment_page_url));
  }
}
