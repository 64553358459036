<ng-container *ngIf="anchors$ | async as anchors">
  <div class="w-full px-5 lg:px-12 py-4 md:py-6 lg:py-8 flex flex-row">
    <div class="w-full flex overflow-x-auto flex-shrink-0 scrollbar-hide">
      <ng-container *ngFor="let anchor of anchors">
        <a
          class="py-2 px-4 rounded-full text-sm bg-neutral-100 whitespace-nowrap mr-2 font-bold border border-neutral-600"
          [routerLink]="'.'"
          [fragment]="anchor.handle"
        >
          {{ anchor.name }}
        </a>
      </ng-container>
    </div>
  </div>
</ng-container>
