import { Component, Input } from '@angular/core';

import { IContentBuilderFieldImage } from '@core/models/content-builder-fields.model';

@Component({
  selector: 'app-image-logo-block',
  templateUrl: './image-logo-block.component.html',
})
export class ImageLogoBlockComponent {
  @Input() width?: string;
  @Input() bottomMargin?: string;
  @Input() logo?: IContentBuilderFieldImage;
}
